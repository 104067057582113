import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
	memo,
} from 'react'
import { Avatar, Stack, Tooltip } from '@mui/material'
import { AuthContext } from '../../utils/contexts/AuthContext'
import { EAccountType } from '../../utils/app-models'
import { transformNameToInitialName } from './utils-avatar'
import { getIndexesInAlphabet } from '../../utils/string'
import { dynamicColor } from '../../utils/color'
import CustomLink from '../_overrideMui/Link/CustomLink'
import SlidePanelUserInfos from '../slidepanel/user'

interface IUserAvatarSlidePanel {
	firstName: string
	lastName: string
	userId?: string
	accountId?: string
	isContact?: boolean
	displayName?: boolean
	setIsClickDisabled?: any
	variantText?: string
}

const UserAvatarSlidePanel: React.FC<IUserAvatarSlidePanel> = ({
	firstName,
	lastName,
	userId,
	accountId,
	isContact,
	displayName = true,
	setIsClickDisabled,
	variantText = 'text1',
}) => {
	const { checkAccountsType, currentUser } = useContext(AuthContext)
	const [slidePanelIsOpen, setSlidePanelIsOpen] = useState(false)

	const isCurrentUserGuest = useMemo(
		() => checkAccountsType([EAccountType.guest]),
		[]
	)

	const isSlidePanelDisabled = useMemo(
		() =>
			!(userId || isContact) ||
			isCurrentUserGuest ||
			(accountId && currentUser.account.id !== accountId),
		[accountId, userId, isContact, isCurrentUserGuest, currentUser]
	)

	useEffect(() => {
		setIsClickDisabled &&
			setIsClickDisabled((p) => [...p, isSlidePanelDisabled])
	}, [isSlidePanelDisabled, setIsClickDisabled])

	const initialName = useMemo(
		() => transformNameToInitialName({ firstName, lastName }),
		[firstName, lastName]
	)

	const backgroundColor = useMemo(() => {
		const initialsIndexes = parseInt(
			`${getIndexesInAlphabet(initialName[0] as any)}${getIndexesInAlphabet(
				initialName[1] as any
			)}`
		)
		return dynamicColor(initialsIndexes, 400, false) as any
	}, [initialName])

	const AvatarCSS = useMemo(
		() => ({
			backgroundColor,
			width: 24,
			height: 24,
			fontSize: '9px',
			fontWeight: 500,
			cursor: isSlidePanelDisabled ? 'inherit' : 'pointer',
		}),
		[backgroundColor, isSlidePanelDisabled]
	)

	const toggleOpenSlide = useCallback(
		(open: boolean) => () => {
			setSlidePanelIsOpen(open)
		},
		[]
	)

	const openSlidePanel = useMemo(
		() => (isSlidePanelDisabled ? {} : { onClick: toggleOpenSlide(true) }),
		[isSlidePanelDisabled]
	)

	const TooltipTitle = useMemo(
		() =>
			!displayName && initialName ? `${firstName ?? ''} ${lastName ?? ''}` : '',
		[displayName, initialName, firstName, lastName]
	)

	return (
		<React.Fragment>
			<Tooltip title={TooltipTitle}>
				<Stack direction='row' spacing={0.5} alignItems='center'>
					{initialName ? (
						<Avatar {...openSlidePanel} sx={AvatarCSS}>
							{initialName}
						</Avatar>
					) : (
						'-'
					)}
					{displayName && (
						<CustomLink
							{...openSlidePanel}
							variant={variantText as any}
							variantlink='primaryLink'
							disabled={isSlidePanelDisabled}
						>
							{`${firstName ?? ''} ${lastName ?? ''}`}
						</CustomLink>
					)}
				</Stack>
			</Tooltip>

			{slidePanelIsOpen && (
				<SlidePanelUserInfos
					id={userId}
					state={slidePanelIsOpen}
					toggleDrawer={toggleOpenSlide}
					isContact={isContact}
				/>
			)}
		</React.Fragment>
	)
}

export default memo(UserAvatarSlidePanel)
