import React, { useMemo } from 'react'

import { Box } from '@mui/material'
import ModalAnnotationButton from './ModalAnnotationButton'

const containerPhotos = {
	flexWrap: 'wrap',
	'& > *': { mb: 1, mr: 1 },
} as const

interface IPicture {
	fieldTemplate?
	record
	onlyWithAlert?
}

const Picture: React.FC<IPicture> = ({
	record,
	fieldTemplate,
	onlyWithAlert = false,
}) => {
	const attributeTemplateParse = useMemo(
		() => JSON.parse(fieldTemplate.attributes[0]),
		[fieldTemplate]
	)
	const values = useMemo(() => record?.value, [record])

	return (
		<Box display='flex' pt={2}>
			<Box display='flex' sx={containerPhotos}>
				{attributeTemplateParse?.picturesList?.map(({ angleType }, i) => {
					return values[i]?.annotations?.length || !onlyWithAlert ? (
						<ModalAnnotationButton
							angleType={angleType}
							values={values[i] || null}
							key={i}
						/>
					) : (
						<></>
					)
				})}
			</Box>
		</Box>
	)
}

export default Picture
