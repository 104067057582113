import React, { useMemo } from 'react'

import { Grid } from '@mui/material'
import PermissionsHandler from '../../../../../../components/permissionsHandler'
import {
	EInterventionStatuses,
	EModule,
} from '../../../../../../utils/app-models'
import ServiceRequest from './serviceRequest'
import StatusDate from './StatusDate'
import Actors from './Actors'
import InterventionPlace from './interventionPlace'
import PublicDescription from './PublicDescription'
import InterventionsInstruction from './InterventionsInstruction'
import { useInterventionStatuses } from '../../../../../../api/gql/queries/interventionsQueries'

import { BannerFormModalIntervention } from './BannerFormModalIntervention'

const wrapperModalCSS = {
	p: 4,
	overflow: 'auto',
}

interface IInterventionModalControlInfos {}
const InterventionModalControlInfos: React.FC<
	IInterventionModalControlInfos
> = () => {
	const { interventionStatus } = useInterventionStatuses()

	const plannedStatus = useMemo(
		() =>
			interventionStatus?.find(
				(status) => status?.code === EInterventionStatuses.planned
			),
		[interventionStatus]
	)

	return (
		<>
			<BannerFormModalIntervention />

			<Grid container sx={wrapperModalCSS}>
				<Grid item xs={12} md={6} pr={4} pb={4}>
					<ServiceRequest />
				</Grid>

				<Grid item xs={12} md={6}>
					<StatusDate interventionStatus={interventionStatus} />
				</Grid>

				<Grid item xs={12} md={6} pr={4} pb={4}>
					<Actors plannedStatus={plannedStatus} />
				</Grid>

				<Grid item xs={12} md={6}>
					<InterventionPlace />
				</Grid>

				<Grid item xs={12} md={6} pr={4} pb={4}>
					<InterventionsInstruction />
				</Grid>

				<PermissionsHandler modulesName={[EModule.crm]}>
					<Grid item xs={12} md={6}>
						<PublicDescription />
					</Grid>
				</PermissionsHandler>
			</Grid>
		</>
	)
}
export default InterventionModalControlInfos
