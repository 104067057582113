import React, { useCallback, useMemo, memo } from 'react'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'

interface ISkeletonFormsBar {
	nbRow: number
}

const SkeletonFormsBar: React.FC<ISkeletonFormsBar> = ({ nbRow }) => {
	const generatedArray = useMemo(() => Array.from(Array(nbRow).keys()), [nbRow])

	const renderGeneratedArray = useCallback(
		(_, i) => (
			<Typography
				key={`${i}`}
				component='div'
				variant={'h2'}
				sx={{ opacity: 0.5 }}
			>
				<Skeleton animation='wave' key={`${i + 2}`} />
			</Typography>
		),
		[]
	)

	return <>{generatedArray?.map(renderGeneratedArray)}</>
}

export default memo(SkeletonFormsBar)
