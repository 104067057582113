/* eslint-disable react/display-name */

import React, { memo, useCallback, useMemo, useState } from 'react'

import ExportSnackbar from '../components/tableV2/tableToolBar/tableExport/exportSnackbar'
import ConfirmCancelExport from '../components/tableV2/tableToolBar/tableExport/exportSnackbar/ConfirmCancelExport'
import { useFormatMessage } from '../utils/hooks'
import { ExportContext } from '../utils/contexts/ExportContext'
import { ESeverityAlert } from '../utils/app-models'

interface IAuthProvider {
	children: React.ReactNode
}

const ExportProvider: React.FC<IAuthProvider> = memo(({ children }) => {
	const intlMsg = useFormatMessage()
	const [open, setOpen] = useState(false)
	const [isProcessingExport, setIsProcessingExport] = useState(false)
	const [severityAlert, setSeverityAlert]: any = useState(ESeverityAlert.info)
	const [exportConfig, setExportConfig]: any = useState(null)
	const messageProcessingExport = useMemo(
		() => ({
			[ESeverityAlert.info]: intlMsg('exportTable.messageInfo'),
			[ESeverityAlert.success]: intlMsg('exportTable.messageSuccess'),
			[ESeverityAlert.error]: intlMsg('exportTable.messageError'),
			[ESeverityAlert.warning]: intlMsg('exportTable.messageWarning'),
		}),
		[]
	)

	// ///////////////////////////////////////////////////////////
	// ACTIONS
	// ///////////////////////////////////////////////////////////

	const resetOnClose = useCallback(() => {
		setOpen(false)
		setIsProcessingExport(false)
		setSeverityAlert(ESeverityAlert.info)
		setExportConfig(null)
	}, [])

	const closeConfirmModal = useCallback(() => {
		setOpen(false)
	}, [])

	const onClose = useCallback(() => {
		severityAlert === ESeverityAlert.info ? setOpen(true) : resetOnClose()
	}, [severityAlert])

	// ///////////////////////////////////////////////////////////
	// Context
	// ///////////////////////////////////////////////////////////

	const context = useMemo(
		() => ({
			setIsProcessingExport,
			isProcessingExport,
			setExportConfig,
			resetOnClose,
		}),
		[isProcessingExport]
	)

	return (
		<ExportContext.Provider value={context}>
			{isProcessingExport && (
				<ExportSnackbar
					exportConfig={exportConfig}
					isProcessingExport={isProcessingExport}
					onClose={onClose}
					severityAlert={severityAlert}
					setSeverityAlert={setSeverityAlert}
					messageProcessingExport={messageProcessingExport}
					resetOnClose={resetOnClose}
				/>
			)}

			{open && (
				<ConfirmCancelExport
					open={open}
					closeConfirmModal={closeConfirmModal}
					cancelExport={resetOnClose}
				/>
			)}
			{children}
		</ExportContext.Provider>
	)
})
export default ExportProvider
