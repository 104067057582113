/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'

import {
	useBulkVehicleAction,
	useImportVehicles,
} from '../../../api/gql/mutations/vehicleMutation'
import {
	LIST_VEHICLES,
	useExportVehicles,
} from '../../../api/gql/queries/vehiclesQueries'
import FormBtnModal from '../../../components/forms/formModalBtn'
import listVehiclesViewTable from '../../../components/tableV2/config/dashboard/vehicles/listVehiclesTableConfig'
import { listVehiclesTableImport } from '../../../components/tableV2/config/dashboard/vehicles/listVehiclesTableImport'
import TableVehicleImportForm from '../../../components/tableV2/tableToolBar/tableImport/tableImportForms/TableVehicleImportForm'

import { EFormModalMode, EPermissionScope } from '../../../utils/app-models'
import {
	ITableToolBarActionConfig,
	ITableToolBarConfig,
} from '../../../utils/typescript/tableType'
import {
	importVehiclesValidationSchema,
	VehiclesValidationSchema,
} from '../../../utils/validationSchema'
import VehicleModal from './vehicleModal'

////////////////////////////////////////////////////////////////
// Global variable
////////////////////////////////////////////////////////////////

const queryConfig = {
	query: LIST_VEHICLES,
	queryName: 'vehicles',
}

////////////////////////////////////////////////////////////////
// tableToolBarAction CONFIG
////////////////////////////////////////////////////////////////

const refreshQuerie = ['getVehiclesList']

const deletedBulk = {
	mutation: useBulkVehicleAction,
	operation: 'remove',
	successMessageDelete: {
		singular: 'success.vehicleDeleted',
		plural: 'success.vehiclesDeleted',
	},
	messageConfirm: {
		title: 'confirmModal.deleteVehiclesTitle',
		message: 'confirmModal.deleteVehicleMessage',
	},
	deletePermissions: [
		EPermissionScope.vehicleRemove,
		EPermissionScope.vehicleDelete,
	],
}

const tableToolBarActionConfig: ITableToolBarActionConfig = {
	refreshQuerie,
	deletedBulk,
}

////////////////////////////////////////////////////////////////
// TableToolBarHeader CONFIG
////////////////////////////////////////////////////////////////

const tableToolBarConfig: ITableToolBarConfig = {
	title: 'misc.vehicles',
	stickyToolBar: true,
	tableConfig: listVehiclesViewTable,
	localStorageName: 'listVehiclesViewTableFilters',
	exportConfig: { exportQuery: useExportVehicles },
	importConfig: {
		templateExcelName: 'vehicles',
		TableImportForm: TableVehicleImportForm,
		columnsToImport: listVehiclesTableImport,
		watchThisFields: ['customer', 'site', 'importFile'],
		schemaValidation: importVehiclesValidationSchema,
		useMutationImport: useImportVehicles,
		otherInputForMutation: ['customer', 'site'],
		refetchQueries: ['getVehicle', 'getVehiclesNumbers', 'getVehiclesList'],
		permissions: [EPermissionScope.vehicleCreate],
		errorNestedAccess: 'data.importVehicle.vehicle.importErrors',
	},
	queryConfig,
	modalButton: {
		Btn: (
			<FormBtnModal
				modal={<VehicleModal modalUtils={{} as any} />}
				mode={EFormModalMode.create}
			/>
		),
	},
	validationSchemaFunction: VehiclesValidationSchema,
	createPermission: [EPermissionScope.vehicleCreate],
}

////////////////////////////////////////////////////////////////
// tableContainer CONFIG
////////////////////////////////////////////////////////////////

const tableContainerConfig = {
	tableConfig: listVehiclesViewTable,
	queryConfig,
}

////////////////////////////////////////////////////////////////
// -> EXPORT CONFIG MAIN TABLE
////////////////////////////////////////////////////////////////

export const customerVehicleConfigTable = {
	tableToolBarConfig,
	tableToolBarActionConfig,
	tableContainerConfig,
}
