import { object, string } from 'yup'
import { immatFormats } from '../../../../utils/regex'
import translateIntlMsgString from '../../../../utils/translateIntlMsgString'

export const VehicleValidationSchema = object()
	.shape({
		immat: string()
			.transform((value) =>
				value
					? value
							.trim()
							.toUpperCase()
							.replaceAll(/[–-]/g, '')
							.replaceAll(' ', '')
					: ''
			)
			.test(
				'is-valid-immat',
				translateIntlMsgString('errors.invalidImmat'),
				(value: any) => {
					if (!value) {
						return true
					}
					const immatNbCharacters = value?.length
					const validFormat = immatFormats.find(
						(format) =>
							format.maxLength >= immatNbCharacters && format.regex.test(value)
					)
					return !!validFormat
				}
			),
		vin: string(),
		site: object()
			.required(translateIntlMsgString('validationSchema.require'))
			.nullable(true),
		customer: object()
			.required(translateIntlMsgString('validationSchema.require'))
			.nullable(true),
		location: string()
			.max(25, translateIntlMsgString('validationSchema.maxCharacters'))
			.nullable(),
	})
	.test(
		'immat-or-vin-required',
		translateIntlMsgString('validationSchema.require'),
		(values) => {
			return !!(values.immat || values.vin)
		}
	)
