/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/display-name */
import React from 'react'

import Typography from '@mui/material/Typography'
import { Stack } from '@mui/material'

import LocationAddress from '../../../../locationAddress'
import BooleanColorPopover from '../../../../popover/BooleanColorPopover'
import OpenLinkCell from '../../../tableCell/OpenLinkCell'
import { useUpdateSite } from '../../../../../api/gql/mutations/customerSitesMutations'
import { EModule, EPermissionScope } from '../../../../../utils/app-models'
import { ROUTES } from '../../../../../routes/consts'
import PhoneCell from '../../../tableCell/PhoneCell'

const agenciesListSitesViewModalTable = [
	{
		columnLabel: 'misc.name',
		orderBy: 'name',
		stickyFirstCol: true,
		personalizedCell: ({ id, name, isSharedWithMe }) => (
			<OpenLinkCell
				viewModalLink={`${ROUTES.VIEWMODAL.SITE.url}${id}`}
				txt={name}
				isSharedWithMe={isSharedWithMe}
				maxCharacters={40}
			/>
		),
	},
	{
		columnLabel: 'misc.account',
		modules: [EModule.crm],
		orderBy: 'customer_name',
		personalizedCell: ({ customer }) => (
			<OpenLinkCell
				viewModalLink={`${ROUTES.VIEWMODAL.CUSTOMERS.url}${customer?.id}`}
				txt={customer?.name}
				maxCharacters={40}
			/>
		),
	},

	{
		columnLabel: 'misc.address',
		orderBy: 'address',
		personalizedCell: ({ address, city, zipcode }) => (
			<LocationAddress
				address={address}
				city={city}
				zipcode={zipcode}
				maxCharacters={40}
			/>
		),
	},

	{
		columnLabel: 'misc.phone',
		personalizedCell: (row: any) => (
			<Stack direction='row' alignItems='center' spacing={1}>
				<PhoneCell txt={row.phone} icon='phone' />
				<PhoneCell txt={row.cellphone} icon='cellphone' />
			</Stack>
		),
	},

	{
		columnLabel: 'misc.numberVehicle',
		personalizedCell: (row: any) => {
			return <div>{row.vehicles?.paginationInfo?.totalCount || '-'}</div>
		},
	},

	{
		columnLabel: 'misc.manager',
		orderBy: 'gestionnaire_lastName',
		personalizedCell: (row: any) => {
			return (
				<Typography variant='caption'>{`${row?.gestionnaire?.firstName || ''} ${
					row?.gestionnaire?.lastName || ''
				} `}</Typography>
			)
		},
	},

	{
		columnLabel: 'misc.active',
		orderBy: 'isActive',
		tableCellAlign: 'center',
		cellAlign: 'center',
		personalizedCell: (row: any) => {
			return (
				<BooleanColorPopover
					value={row.isActive}
					id={row.id}
					txt={{ true: 'misc.active', false: 'misc.desactive' }}
					titlePopover={'misc.siteStatusState'}
					queryConfig={{
						query: useUpdateSite,
						refreshQuery: ['getSitesList', 'getSitesNumbers'],
						successMessage: 'success.statusUpdate',
					}}
					permissions={[EPermissionScope.siteManage]}
				/>
			)
		},
	},
]

export default agenciesListSitesViewModalTable
