export const GlobalButtonTheme = {
	button: {
		size: {
			small: {
				padding: '4px 10px',
				fontSize: '12px',
				fontWeight: 500,
			},
			medium: {
				padding: '8px 20px',
				fontSize: '13px',
				fontWeight: 500,
			},
			large: {
				padding: '8px 24px',
				fontSize: '14px',
				fontWeight: 500,
			},
		},
		variant: {
			contained: {
				color: '#ffffff',
				backgroundColor: '#000000',
				borderColor: '#000000',
				'&:hover': {
					backgroundColor: '#333333',
					borderColor: '#444444',
				},
			},
			outlined: {
				color: '#000000',
				backgroundColor: '#ffffff',
				borderRadius: '4px',
				border: '1px solid rgba(0,0,0, 0.50)',
				'&:hover': {
					backgroundColor: '#f0f0f0',
					borderColor: '#e0e0e0',
				},
			},
			text: {
				color: '#000000',
				borderColor: 'rgba(0,0,0, 0.50)',
				'&:hover': {
					backgroundColor: 'rgba(0,0,0, 0.10)',
				},
			},
		},
	},
}
