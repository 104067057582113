export const mutationCustomerAttributes = `
id
`

export const customerPayloadAttributes = `
customer {
    ${mutationCustomerAttributes}
}
`

export const listCustomersAttributes = `
paginationInfo {
    totalCount
},
collection{
  id
  isSharedWithMe
  name
  isActive
  address
  zipcode
  siret
  tva
  city
  country
  socialName
  orderedInterventions {
    paginationInfo {
      totalCount
    }
  }
  sites {
    paginationInfo {
      totalCount
    }
  }
  gestionnaire {
    id
    lastName
    firstName
  }
  accountGestionnaire {
    id
    name
  }
}

`
export const listCustomerAttributes = `
  id
  name
  isActive
  isSharedWithMe
  address
  zipcode
  dateCreated
  siret
  tva
  city
  country
  socialName
  contacts(itemsPerPage: 99999) {
    collection {
      id
      firstName
      lastName
      isCustomerResp
    }    
  }
  gestionnaire {
    id
    lastName
    firstName
}
`
