import React, { useCallback, useContext, useEffect } from 'react'
import toast from 'react-hot-toast'

import Modal from '../../../../components/forms/v1'
import { ModalContext } from '../../../../utils/contexts/ModalContext'

import { EFormModalMode } from '../../../../utils/app-models'
import NetworkForm from './NetworkForm'

import {
	useCreateNetwork,
	useUpdateNetwork,
} from '../../../../api/gql/mutations/networkMutation'

interface IModalNetwork {
	row?
	defaultValue?
	setSelectedNetworkId
	setNetworkIsActive
}

const ModalNetwork: React.FC<IModalNetwork> = ({
	row,
	defaultValue,
	setSelectedNetworkId,
	setNetworkIsActive,
}) => {
	const {
		isVisible,
		closeModal,
		resetContext,
		setLoadingModal,
		mode,
		formUtils: { control, setValue },
	} = useContext(ModalContext)

	const [createNetwork] = useCreateNetwork()
	const [updateNetwork] = useUpdateNetwork()

	const handleCloseModal = useCallback(() => {
		closeModal()
		mode === EFormModalMode.create && resetContext()
	}, [resetContext, row])

	const validateModal = useCallback(
		async (data) => {
			setLoadingModal(true)

			const mutate =
				mode === EFormModalMode.edit ? updateNetwork : createNetwork

			await mutate({
				variables: {
					input: {
						...data,
					},
				},
				refetchQueries: ['getNetworks', 'getAgenciesAccounts'],
			})
				.then((res) => {
					if (mode === EFormModalMode.create) {
						setSelectedNetworkId(res?.data?.createNetwork?.network?.id)

						localStorage.setItem(
							'networksSelected',
							JSON.stringify(res?.data?.createNetwork?.network?.id)
						)
						setNetworkIsActive(data.isActive)
						resetContext()
						toast.success('success.networkCreate')
					} else {
						localStorage.removeItem('networksSelected')
						toast.success('success.networkUpdate')
					}

					setLoadingModal(false)
					closeModal()
				})
				.catch(() => setLoadingModal(false))
		},
		[row, mode, resetContext]
	)

	// If edit mode, set default values
	useEffect(() => {
		if (mode === EFormModalMode.edit) {
			const { name, id, isActive } = defaultValue
			setValue('name', name)
			setValue('id', id)
			setValue('isActive', isActive)
		}
	}, [])

	return (
		<>
			<Modal
				isVisible={isVisible}
				closeModal={handleCloseModal}
				modalName={
					mode === EFormModalMode.edit
						? 'network.editNetwork'
						: 'network.addNetwork'
				}
				validateModal={validateModal}
				maxWidth={350}
				minHeight='50px'
			>
				<NetworkForm control={control} />
			</Modal>
		</>
	)
}

export default ModalNetwork
