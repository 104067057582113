import React, { useMemo } from 'react'

import qs from 'qs'
import { Alert, Fade, Stack } from '@mui/material'

import { GET_FORM_PHOTOS } from '../../../../../api/gql/queries/interventionFormsQueries'
import SectionPhotosByVehicle from './SectionPhotosByVehicles'
import {
	infiniteScrollWithTrigger,
	useFormatMessage,
} from '../../../../../utils/hooks'
import {
	extractRecordInterventionWithPhotos,
	groupByVehicle,
} from '../formsPhotosUtils'

interface IPhotosGroupedByVehicle {
	queryPropertyName: string
}

const PhotosGroupedByVehicle: React.FC<IPhotosGroupedByVehicle> = ({
	queryPropertyName,
}) => {
	const intlMsg = useFormatMessage()
	const { varValue }: any = qs.parse(location.search, {
		ignoreQueryPrefix: true,
	})

	const { dataList, loading, setTrigger } = infiniteScrollWithTrigger({
		queryConfig: { query: GET_FORM_PHOTOS, queryName: 'picturedForms' },
		itemsPerPage: 50,
		params: { [queryPropertyName]: varValue },
	})

	const groupedData = useMemo(() => {
		const recordsWithPhotos = extractRecordInterventionWithPhotos(dataList)
		return groupByVehicle(recordsWithPhotos)
	}, [dataList])

	return (
		<Stack p={5} spacing={4}>
			{groupedData?.length ? (
				<SectionPhotosByVehicle
					form={groupedData}
					setTrigger={setTrigger}
					loading={loading}
				/>
			) : (
				<Fade in={!loading && !groupedData?.length} timeout={500}>
					<Alert severity='info'>{intlMsg('misc.nothingToDisplay')}</Alert>
				</Fade>
			)}
		</Stack>
	)
}

export default PhotosGroupedByVehicle
