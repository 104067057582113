export default {
	siteCaps: 'SITES',
	status: 'Status',
	active: 'Active',
	disabled: 'Disabled',
	workPosition: 'Job',
	role: 'Role',
	applicationAcces: 'Application Access',
	platformAcces: 'Platform Access',
	accessPlatform: 'Platform Access',
	accessApplication: 'Application Access',
	actions: 'Actions',
	updateProfil: 'Update My Profile',
	updatePassword: 'Change My Password',
	createUser: 'Add a User',
	editUser: 'Edit a User',
	addUser: 'Add a User',
	createTeam: 'Add a Team',
	editTeam: 'Edit a Team',
	addTeam: 'Add a Team',
	switchTeamLabel: 'ACTIVE TEAM',
	switchUserLabel: 'ACTIVE',
	dontShowAgain: 'Do not show again',
	userType: 'User Type',
	notificationPanelTitle: 'Notifications',
	notificationPanelSubTitle: 'Configure your notifications',
	editSite: 'Edit a Site',
	createSite: 'Add a Site',
	contactReferentSite: 'Site Referent Contact',
	manager: 'Manager',
	agency: 'Agency',
	comptes: 'Accounts',
	editCustomer: 'Edit an Account',
	createCustomer: 'Add an Account',
	editContact: 'Edit a Contact',
	createContact: 'Add a Contact',
	formTemplateList: 'Form Editor',
}
