import { Box } from '@mui/material'
import React from 'react'
import FiltersBtnProvider from '../../../../components/filters/filtersBtn'
import CalendarFiltersBtnComponent from './CalendarFiltersBtnComponent'

interface ICalendarFilters {}

const CalendarFiltersSX = {
	display: 'flex',
}

const CalendarFilters: React.FC<ICalendarFilters> = () => {
	return (
		<Box sx={CalendarFiltersSX}>
			<FiltersBtnProvider>
				<CalendarFiltersBtnComponent />
			</FiltersBtnProvider>
		</Box>
	)
}

export default CalendarFilters
