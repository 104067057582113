/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

export const getFromLS = (key) => {
	let ls = {}
	if (global.localStorage) {
		try {
			ls = JSON.parse(global.localStorage.getItem('widgetConfig') as any) || {}
		} catch (e) {}
	}
	return ls[key]
}

export const saveToLS = (key, value) => {
	if (global.localStorage) {
		global.localStorage.setItem(
			'widgetConfig',
			JSON.stringify({
				[key]: value,
			})
		)
	}
}
