/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/display-name */
import React from 'react'

import { ROUTES } from '../../../../../routes/consts'
import OpenLinkCell from '../../../tableCell/OpenLinkCell'
import ActionsBtn from '../../../tableCell/actionsBtn'
import {
	useRemoveIntervention,
	useUpdateInterventionStatuses,
} from '../../../../../api/gql/mutations/interventionsMutation'
import {
	EAccountType,
	EModule,
	EPermissionScope,
} from '../../../../../utils/app-models'
import InterventionTransportModal from '../../../../../view/dashboard/interventions/interventionTransport/interventionModal'
import { Stack, Typography } from '@mui/material'
import translateIntlMsgString from '../../../../../utils/translateIntlMsgString'
import ActionPopoverBtn from '../../../../popover/actionPopover'
import { useInterventionStatusesList } from '../../../../../api/gql/queries/interventionsQueries'
import ChipCountListPopover from '../../../../multiItemsPopoverList/popover/simpleListPopover'
import DateToFromTransport from '../../../../date/DateToFromTransport'
import TransportAddress from '../../../tableCell/TransportAddress'
import TasksTransport from '../../../../multiItemsPopoverList/popover/taskTransport'
import UserAvatarSlidePanel from '../../../../avatar/UserAvatarSlidePanel'
import UsersPopover from '../../../../multiItemsPopoverList/popover/usersPopover'

const vehicleListInterventionsTransportViewModalTable = [
	{
		columnLabel: 'interventions.ref',
		orderBy: 'intervention_reference',
		stickyFirstCol: true,
		personalizedCell: ({ intervention: { reference, id, isSharedWithMe } }) => (
			<>
				{reference ? (
					<OpenLinkCell
						viewModalLink={`${ROUTES.VIEWMODAL.INTERVENTION_TRANSPORT.url}${id}`}
						txt={reference}
						isSharedWithMe={isSharedWithMe}
					/>
				) : (
					'-'
				)}
			</>
		),
	},

	{
		columnLabel: 'interventions.refAirTableShort',
		modules: [EModule.airTable],
		dataKey: 'airTableReference',
	},

	{
		columnLabel: 'misc.account',
		modules: [EModule.crm],
		orderBy: 'intervention_orderingCustomer_name',
		personalizedCell: ({ intervention: { orderingCustomer } }) => (
			<OpenLinkCell
				viewModalLink={`${ROUTES.VIEWMODAL.CUSTOMERS.url}${orderingCustomer?.id}`}
				txt={orderingCustomer?.name}
				maxCharacters={40}
			/>
		),
	},

	{
		columnLabel: 'misc.site',
		orderBy: 'intervention_orderingSite_name',
		personalizedCell: ({ intervention: { orderingSite } }) => (
			<OpenLinkCell
				viewModalLink={`${ROUTES.VIEWMODAL.SITE.url}${orderingSite?.id}`}
				txt={orderingSite?.name}
				maxCharacters={40}
				modules={[EModule.site]}
			/>
		),
	},

	{
		columnLabel: 'misc.customerRef',
		orderBy: 'externalReference',
		accountsTypeDenied: [EAccountType.limited],

		personalizedCell: ({ intervention }) => (
			<Typography>
				{!intervention?.isSharedWithMe && intervention?.externalReference
					? intervention.externalReference
					: '-'}
			</Typography>
		),
	},

	{
		columnLabel: 'misc.type',
		orderBy: 'intervention_transportType_name',
		personalizedCell: ({ intervention: { interventionTransportType } }) => (
			<Typography>
				{translateIntlMsgString(
					`interventions.${interventionTransportType.code}`
				)}
			</Typography>
		),
	},

	{
		columnLabel: 'misc.createdBy',
		orderBy: 'createdByAccount_name',
		accountsTypeDenied: [EAccountType.guest, EAccountType.limited],
		personalizedCell: ({ intervention: { createdBy, isSharedWithMe } }) =>
			!isSharedWithMe ? (
				<UserAvatarSlidePanel
					firstName={createdBy?.firstName}
					lastName={createdBy?.lastName}
					userId={createdBy?.id}
					accountId={createdBy?.account?.id}
					displayName={false}
				/>
			) : (
				'-'
			),
	},

	{
		columnLabel: 'interventions.date',
		orderBy: 'interventionTransportTrips_date',
		personalizedCell: ({ intervention: { interventionTransportTrips } }) => (
			<DateToFromTransport
				removalDate={{
					removalDate: interventionTransportTrips[0]?.date,
					removalTimeFrom: interventionTransportTrips[0]?.timeFrom,
					removalTimeTo: interventionTransportTrips[0]?.timeTo,
				}}
				deliveryDate={{
					deliveryDate: interventionTransportTrips[1]?.date,
					deliveryTimeFrom: interventionTransportTrips[1]?.timeFrom,
					deliveryTimeTo: interventionTransportTrips[1]?.timeTo,
				}}
			/>
		),
	},

	{
		columnLabel: 'interventions.removalAddress',
		orderBy: 'interventionTransportTrips_city',
		personalizedCell: ({ intervention: { interventionTransportTrips } }) => (
			<TransportAddress
				name={interventionTransportTrips[0]?.name}
				address={interventionTransportTrips[0]?.address}
				zipCode={interventionTransportTrips[0]?.zipcode}
				city={interventionTransportTrips[0]?.city}
				country={interventionTransportTrips[0]?.country}
			/>
		),
	},
	{
		columnLabel: 'interventions.deliveryAddress',
		orderBy: 'interventionTransportTrips_city',
		personalizedCell: ({ intervention: { interventionTransportTrips } }) => (
			<TransportAddress
				name={interventionTransportTrips[1]?.name}
				address={interventionTransportTrips[1]?.address}
				zipCode={interventionTransportTrips[1]?.zipcode}
				city={interventionTransportTrips[1]?.city}
				country={interventionTransportTrips[1]?.country}
			/>
		),
	},

	{
		columnLabel: 'misc.status',
		orderBy: 'status_name',
		personalizedCell: ({ intervention: { status, id } }) => {
			return (
				<ActionPopoverBtn
					inputs={{ query: useInterventionStatusesList }}
					selected={{
						id: status?.id,
						color: status?.color,
						name: status?.name,
						code: status?.code,
					}}
					titlePopover={'misc.status'}
					keyTranslate='interventions'
					mutation={{
						id,
						query: useUpdateInterventionStatuses,
						refreshQuery: ['interventionsTransportList'],
						successMessage: 'success.transportStatusUpdate',
						attribute: 'status',
					}}
					permissions={[EPermissionScope.interventionUpdate]}
				/>
			)
		},
	},

	{
		columnLabel: 'interventions.task',
		orderBy: 'interventionTransportTrips_products_name',
		personalizedCell: ({ intervention: { interventionTransportTrips } }) => (
			<TasksTransport
				removalProducts={interventionTransportTrips[0]?.products?.collection}
				deliveryProducts={interventionTransportTrips[1]?.products?.collection}
			/>
		),
	},

	{
		columnLabel: 'interventions.managerMission',
		orderBy: 'intervention_manager_lastName',
		accountsTypeDenied: [EAccountType.guest],
		personalizedCell: ({ intervention: { manager } }) => (
			<UserAvatarSlidePanel
				firstName={manager?.firstName}
				lastName={manager?.lastName}
				userId={manager?.id}
				accountId={manager?.account?.id}
				displayName={false}
			/>
		),
	},

	{
		columnLabel: 'misc.agencies',
		modules: [EModule.network],
		orderBy: 'agencies_agencyCustomDetails_agencyName',
		personalizedCell: ({ intervention: { agencies } }) => (
			<Stack direction='row' alignItems='center'>
				<ChipCountListPopover
					text='misc.agencies'
					data={
						agencies?.collection?.map(({ agencyCustomDetails, id }) => {
							return { name: agencyCustomDetails[0]?.agencyName, id }
						}) || []
					}
					viewModalLink={ROUTES.VIEWMODAL.AGENCIES.url}
					permissions={[
						EPermissionScope.networkView,
						EPermissionScope.networkManage,
					]}
				/>
			</Stack>
		),
	},

	{
		columnLabel: 'interventions.conveyor',
		orderBy: 'intervention_actors_lastName',
		accountsTypeDenied: [EAccountType.guest],
		personalizedCell: ({
			intervention: {
				actors: { collection },
			},
		}) => (
			<UsersPopover
				data={collection?.map(({ id, lastName, firstName, account }) => ({
					firstName,
					lastName,
					id,
					account,
				}))}
				title='interventions.conveyors'
			/>
		),
	},

	{
		fixWidth: '50px',
		columnLabel: '',
		visible: true,
		tableCellAlign: 'center',
		cellAlign: 'center',
		sticky: true,
		personalizedCell: ({ intervention: { id } }: any) => (
			<ActionsBtn
				rowId={id}
				deleted={{
					query: useRemoveIntervention,
					refreshQuery: [
						'interventionsTransportList',
						'getInterventionTransportNumbersByCustomer',
						'getInterventionTransportNumbersBySite',
						'getInterventionTransportNumbersByVehicle',
						'IpvTransportList',
					],
					successMessageDelete: 'success.transportDeleted',
					messageConfirm: {
						title: 'confirmModal.deleteInterventionsTitle',
						message: 'confirmModal.deleteInterventionMessage',
					},
				}}
				editModal={
					<InterventionTransportModal
						interventionId={id}
						modalUtils={{} as any}
					/>
				}
				viewModalLink={`${ROUTES.VIEWMODAL.INTERVENTION_TRANSPORT.url}${id}`}
				permissions={{
					update: [EPermissionScope.interventionUpdate],
					remove: [EPermissionScope.interventionRemove],
					delete: [EPermissionScope.interventionDelete],
				}}
			/>
		),
	},
]

export default vehicleListInterventionsTransportViewModalTable
