import React from 'react'
import { Box, Typography } from '@mui/material'

interface IText {
	record
}

const Text: React.FC<IText> = ({ record }) => {
	return (
		<Box sx={{ ml: 0 }}>
			<Typography variant='body2'> {record?.value?.value || '-'}</Typography>
		</Box>
	)
}

export default Text
