import React, { useCallback, useContext, useMemo } from 'react'

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import { actionBtnCellCSS } from '../../../../../../../components/tableV2/tableCell/actionsBtn'
import {
	FormModalContext,
	IFormModalContext,
} from '../../../../../../../components/forms/formModal/FormModalContext'

interface IDeleteSelectedVehicleBtn {
	vehicle: any
}

const DeleteSelectedVehicleBtn: React.FC<IDeleteSelectedVehicleBtn> = ({
	vehicle,
}) => {
	const { setValues, data, setTabCounter } =
		useContext<IFormModalContext>(FormModalContext)

	const selectedVehicles = useMemo(
		() => data?.selectedVehicles || [],
		[data?.selectedVehicles]
	)

	const handleClick = useCallback(() => {
		const vehicleToDeleteIndex = selectedVehicles?.findIndex(
			(selectedVehicle) => selectedVehicle?.id === vehicle?.id
		)
		selectedVehicles?.splice(vehicleToDeleteIndex, 1)

		setTabCounter({
			nbVehiclesSelected: selectedVehicles?.length || 0,
		})

		setValues({
			selectedVehicles,
			[`products-${vehicle?.id}`]: null,
			[`formTemplates-${vehicle?.id}`]: null,
		})
	}, [selectedVehicles, data, vehicle])

	return (
		<Box sx={actionBtnCellCSS}>
			<IconButton size='small' onClick={handleClick}>
				<CloseIcon />
			</IconButton>
		</Box>
	)
}
export default DeleteSelectedVehicleBtn
