/* eslint-disable @typescript-eslint/no-unused-vars */
import * as colors from '@mui/material/colors'
// MUI hues array ["red","grenn",....] hues?.length = 16

const {
	deepPurple,
	lightBlue,
	lightGreen,
	lime,
	amber,
	deepOrange,
	teal,
	common,
	...rest
} = colors

const newColors = { ...rest }

export const limitedHues = Object.keys(newColors).slice(1, 12)
export const allHues = Object.keys(colors).slice(1, 17)

export const dynamicColor = (
	indexHue: number,
	shade: number | string | boolean,
	limitedColor = true
): number => {
	const index = indexHue % ((limitedColor as any) ? 13 : 16)
	// shade exist or shade between 300 and 800
	const finalShade = shade || (((indexHue as any) % 5) + 3) * 100

	return limitedColor
		? colors[limitedHues[index as number]][finalShade]
		: colors[allHues[index as number]][finalShade]
}

const regexHexa = /^#([0-9A-Fa-f]{3}){1,2}$/i

export const stringifiedColor = (
	color: string,
	shade: number | string
): number | string => {
	const isHexaCode = regexHexa.test(color)
	if (isHexaCode) return color
	return colors[color] ? colors[color][shade] : colors.grey[shade]
}
