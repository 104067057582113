import React, { useState, useEffect, useCallback, useContext } from 'react'

import { Stack } from '@mui/material'

import DashboardHeader from './dashBoardHeader'
import { dashBoardPeriodDefaultValue } from './dashBoardHeader/configFilter/dashBoardFilterDefaultValue'
import { dashboardFilterConfig } from './dashBoardHeader/configFilter/dashboardFilterConfig'
import DashboardWidgetZone from './dashboardWidgetZone'
import {
	filteredWidgetsPermission,
	initialWidgetsLayoutsConfig,
} from './dashboardUtils'
import { getFromLS } from '../../../utils/localStorage'
import { AuthContext } from '../../../utils/contexts/AuthContext'
import { EAccountType } from '../../../utils/app-models'

const localStorageKeyFilter = 'dashboardFilterPreference'

interface IViewDashboard {}

const ViewDashboard: React.FC<IViewDashboard> = () => {
	const { checkPermissions, checkModules, checkAccountsType } =
		useContext(AuthContext)
	const isAGuestUser = checkAccountsType([EAccountType.guest])
	const [layoutIsDraggable, setLayoutIsDraggable] = useState(false)
	const [widgets, setWidgets] = useState(
		filteredWidgetsPermission(
			getFromLS('layoutsDashboard') || initialWidgetsLayoutsConfig,
			checkPermissions,
			checkModules,
			isAGuestUser
		)
	)

	/////////////////////////////////////////////////////////////////////////////

	const [filtersQuerieParam, setFiltersQuerieParam] = useState(
		dashBoardPeriodDefaultValue
	)

	//	to get filter preference (local storage)
	useEffect(() => {
		const dashboardFilterPreference = JSON.parse(
			localStorage.getItem(localStorageKeyFilter) as string
		)
		if (!dashboardFilterPreference) {
			localStorage.setItem(
				localStorageKeyFilter,
				JSON.stringify(filtersQuerieParam)
			)
		} else {
			// formatNewDateWithPeriod updates local storage dates based on user's selected period.
			// ex : If user selects 'weekend' and logs in later, the function will automatically adjust stored dates to match the current dates.
			const updateFiltersQuerieParam = {
				...dashboardFilterPreference,
				// ...formatNewDateWithPeriod(dashboardFilterPreference, 'dateFrom'),
			}

			localStorage.setItem(
				localStorageKeyFilter,
				JSON.stringify(updateFiltersQuerieParam)
			)

			setFiltersQuerieParam(updateFiltersQuerieParam)
		}
	}, [])

	//////////////////////////////////////////////////////////////////////
	// Add/Remove widgets
	//////////////////////////////////////////////////////////////////////

	const onRemoveWidgets = useCallback(
		(newLayout) => {
			setWidgets({ lg: newLayout })
		},
		[widgets]
	)

	const onAddWidgets = useCallback(
		(newWidget) => {
			// eslint-disable-next-line no-unsafe-optional-chaining
			setWidgets({ lg: [...widgets?.lg, ...newWidget] })
		},
		[widgets]
	)

	return (
		<Stack sx={{ width: 1 }} spacing={2}>
			<DashboardHeader
				filterConfig={dashboardFilterConfig(
					setFiltersQuerieParam,
					filtersQuerieParam
				)}
				onRemoveWidgets={onRemoveWidgets}
				onAddWidgets={onAddWidgets}
				widgets={widgets}
				layoutIsDraggable={layoutIsDraggable}
				setLayoutIsDraggable={setLayoutIsDraggable}
			/>

			<DashboardWidgetZone
				widgets={widgets}
				setWidgets={setWidgets}
				filtersQuerieParam={filtersQuerieParam}
				layoutIsDraggable={layoutIsDraggable}
			/>
		</Stack>
	)
}
export default ViewDashboard
