import React from 'react'

import { Stack, Box, Tooltip } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import ChipColorStatus from '../../chip/ChipColorStatus'
import { EChipColorStatus } from '../../../utils/app-models'

const ChipColorStatusCSS = { width: '100px', textAlign: 'center' }

interface IStatusVehicle {
	status
	comment
}

const StatusVehicle: React.FC<IStatusVehicle> = ({ status, comment }) => {
	const { name, code, color } = status || {}

	//if there isn't an IPV, we fake a status by forcing values
	//todo need refinement
	return (
		<Stack
			direction='row'
			alignItems='center'
			maxWidth='200px'
			minWidth='100px'
		>
			<Box width='75%'>
				<ChipColorStatus
					label={name || 'A faire'}
					color={color || 'cyan'}
					code={code || 'TODO'}
					size='small'
					sx={ChipColorStatusCSS}
					type={EChipColorStatus.vehicle}
				/>
			</Box>
			<Box width='25%'>
				{comment && (
					<Tooltip title={comment}>
						<ErrorOutlineIcon />
					</Tooltip>
				)}
			</Box>
		</Stack>
	)
}

export default StatusVehicle
