import React, { useCallback, useContext, useMemo } from 'react'

import { Stack, Typography, Box } from '@mui/material'

import dayjs from 'dayjs'

import { useFormatMessage } from '../../../../../../utils/hooks'
import FormSelectSearch from '../../../../../../components/forms/formFields/formSelectSearch'
import {
	FormModalContext,
	IFormModalContext,
} from '../../../../../../components/forms/formModal/FormModalContext'
import FormDatePicker from '../../../../../../components/forms/formFields/FormDatePicker'
import FormTimePicker from '../../../../../../components/forms/formFields/FormTimePicker'

interface IStatusDate {
	interventionStatus: any
}

const StatusDate: React.FC<IStatusDate> = ({ interventionStatus }) => {
	const intlMsg = useFormatMessage()
	const { data, setValues } = useContext<IFormModalContext>(FormModalContext)

	const dateFrom = useMemo(() => data?.dateFrom || '', [data?.dateFrom])
	const dateFromHours = useMemo(
		() => data?.dateFromHours || '',
		[data?.dateFromHours]
	)
	const dateTo = useMemo(() => data?.dateTo || '', [data?.dateTo])
	const dateToHours = useMemo(
		() => data?.dateToHours || '',
		[data?.dateToHours]
	)

	const interventionStatusOptions = useMemo(
		() =>
			interventionStatus?.map((status) => ({
				key: status?.id,
				label: status?.name,
				value: status,
			})) || [],
		[interventionStatus]
	)

	//activeDate = curent field that is changing
	//passiveDate = field that need to change if dateFrom > dateTo or dateTo < DateFrom
	const handleChangeDate = useCallback(
		({ fieldName: activeDateName, date }) => {
			const activeDate = dayjs(date).startOf('d')
			if (dateFrom && dateTo) {
				const isDateFrom = activeDateName === 'dateFrom'
				const passiveDateName = isDateFrom ? 'dateTo' : 'dateFrom'
				const passiveDate = isDateFrom
					? dayjs(dateTo).startOf('d')
					: dayjs(dateFrom).startOf('d')

				if (
					dayjs(activeDate)[isDateFrom ? 'isAfter' : 'isBefore'](
						passiveDate,
						'm'
					)
				) {
					//if dateFrom isAfter dateTo

					const newHours = dayjs()
						.startOf('hours')
						.add(dayjs().hour() < 23 ? 1 : 0, 'hours')
					const hoursObj = {
						dateFromHours: dayjs(dateFromHours).isValid()
							? dateFromHours
							: newHours,
						dateToHours: dayjs(dateToHours).isValid()
							? dateToHours
							: dayjs(newHours)
									.startOf('hours')
									.add(dayjs().hour() < 23 ? 1 : 0, 'hours'),
					}

					setValues({
						[activeDateName]: activeDate,
						[passiveDateName]: activeDate,
						...hoursObj,
						[`${passiveDateName}Hours`]: isDateFrom
							? dayjs(hoursObj[`${activeDateName}Hours`]).hour() < 23
								? dayjs(hoursObj[`${activeDateName}Hours`]).add(1, 'h')
								: hoursObj[`${activeDateName}Hours`]
							: dayjs(hoursObj[`${activeDateName}Hours`]).hour() > 0
							? dayjs(hoursObj[`${activeDateName}Hours`]).subtract(1, 'h')
							: hoursObj[`${activeDateName}Hours`],
						[`${activeDateName}Hours`]: hoursObj[`${activeDateName}Hours`],
					})
				} else
					setValues({
						[activeDateName]: activeDate,
					})
			} else
				setValues({
					[activeDateName]: activeDate,
				})
		},
		[dateFrom, dateTo, dateFromHours, dateToHours, setValues]
	)

	const handleChangeHours = useCallback(
		({ fieldName: activeHoursName, date }) => {
			const activeHours = dayjs(date).startOf('m')

			if (dateFromHours && dateToHours) {
				const isHoursFrom = activeHoursName === 'dateFromHours'
				const passiveHoursName = isHoursFrom ? 'dateToHours' : 'dateFromHours'
				const passiveDate = isHoursFrom
					? dayjs(dateToHours).startOf('m')
					: dayjs(dateFromHours).startOf('m')

				if (
					dayjs(activeHours)[isHoursFrom ? 'isAfter' : 'isBefore'](
						passiveDate,
						'm'
					)
				) {
					setValues({
						[activeHoursName]: activeHours,
						[passiveHoursName]: isHoursFrom
							? dayjs(activeHours).hour() < 23
								? dayjs(activeHours).add(1, 'h')
								: activeHours
							: dayjs(activeHours).hour() > 0
							? dayjs(activeHours).subtract(1, 'h')
							: activeHours,
					})
				} else
					setValues({
						[activeHoursName]: activeHours,
					})
			} else
				setValues({
					[activeHoursName]: activeHours,
				})
		},
		[dateFromHours, dateToHours, setValues]
	)

	return (
		<Box>
			<Typography variant='h2' gutterBottom mb={2}>
				{intlMsg('interventions.dateAndStatus')}
			</Typography>

			<Box width='73.5%'>
				<FormSelectSearch
					name='status'
					label={intlMsg('interventions.status')}
					selectElements={interventionStatusOptions}
					context={FormModalContext}
					playwrightId='Playwright-SelectInterventionStatus'
					isRequired
					trigerred
					displayChip
					disabledSort
				/>
			</Box>

			<Stack spacing={1} mt={2}>
				<Stack direction='row' spacing={2}>
					<Box width='75%'>
						<FormDatePicker
							name='dateFrom'
							label={intlMsg('misc.dateStart')}
							specialOnChange={handleChangeDate}
							views={['day']}
							isStartOfDay={false}
							conserveHour
							context={FormModalContext}
							trigerred
						/>
					</Box>
					<Box width='25%'>
						<FormTimePicker
							name='dateFromHours'
							label={intlMsg('misc.hourStart')}
							context={FormModalContext}
							specialOnChange={handleChangeHours}
						/>
					</Box>
				</Stack>
			</Stack>

			<Stack spacing={1} mt={2}>
				<Stack direction='row' spacing={2}>
					<Box width='75%'>
						<FormDatePicker
							name='dateTo'
							label={intlMsg('misc.dateEnd')}
							specialOnChange={handleChangeDate}
							views={['day']}
							isStartOfDay={false}
							conserveHour
							context={FormModalContext}
							trigerred
						/>
					</Box>
					<Box width='25%'>
						<FormTimePicker
							name='dateToHours'
							label={intlMsg('misc.hourEnd')}
							context={FormModalContext}
							specialOnChange={handleChangeHours}
						/>
					</Box>
				</Stack>
			</Stack>
		</Box>
	)
}
export default StatusDate
