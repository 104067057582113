import React, { useCallback, useContext, useMemo } from 'react'

import dayjs from 'dayjs'
import { Stack, Typography } from '@mui/material'

import {
	FormModalContext,
	IFormModalContext,
} from '../../../../../../../components/forms/formModal/FormModalContext'
import FormTextField from '../../../../../../../components/forms/formFields/FormTextField'
import FormSelectSearch from '../../../../../../../components/forms/formFields/formSelectSearch'
import { vatListForSelect } from '../../../../../../dashboard/invoice/invoiceModal/invoiceModalUtils'
import FormTimePicker from '../../../../../../../components/forms/formFields/FormTimePicker'
import { EModule } from '../../../../../../../utils/app-models'
import { baseType, unitsOfWork } from '../../../../../../../utils/const'
import FormMultiSelectSearch from '../../../../../../../components/forms/formFields/formMultiSelectSearch'
import PersonalizedFormSelectSearch from '../../../../../../../components/forms/formFields/personalizedFormSelectSearch'
import translateIntlMsgString from '../../../../../../../utils/translateIntlMsgString'
import MoreOptionsCatalogProduct from './MoreOptionsCatalogProduct'
import { useGlobalActionData } from '../globalActionsUtils'
import { AuthContext } from '../../../../../../../utils/contexts/AuthContext'

const wrapperModalCSS = {
	p: 4,
	overflow: 'auto',
}
export const ResumeProductBase = ({
	title,
	value,
}: {
	title: string
	value: string
}) => (
	<Stack spacing={-0.5}>
		<Typography color='grey.500' fontWeight={400}>
			{translateIntlMsgString(title)}
		</Typography>
		<Typography variant='subtitle2' fontWeight={500}>
			{value || '-'}
		</Typography>
	</Stack>
)

const inputProps = { min: 0, step: 0.01 }

interface ICatalogueProductModalInfos {}

const CatalogueProductModalInfos: React.FC<
	ICatalogueProductModalInfos
> = () => {
	const { setValues, data } = useContext<IFormModalContext>(FormModalContext)
	const { checkModules } = useContext(AuthContext)

	const haveCrmModule = useMemo(
		() => checkModules([EModule.crm]),
		[checkModules]
	)

	const productBase = useMemo(
		() => (data?.productBase ? Object?.values(data?.productBase)?.[0] : {}),

		[data?.productBase]
	) as any

	const resumeProductBaseConfig = useMemo(
		() => [
			{
				title: 'misc.priceHt',
				value: `${
					productBase?.value?.rate
						? productBase?.value?.rate.toFixed(2) + ' €'
						: '-'
				} `,
			},
			{
				title: 'misc.duration',
				value: productBase?.value?.duration
					? dayjs(productBase.value.duration).format('HH:mm')
					: '',
			},
			{
				title: 'misc.type',
				value: baseType?.filter(
					({ value }) => value?.name === productBase?.value?.type
				)?.[0]?.label,
			},
			{
				title: 'misc.workUnit',
				value: unitsOfWork?.filter(
					({ value }) => value?.name === productBase?.value?.unitOfWork
				)?.[0]?.label,
			},
		],
		[productBase]
	)

	const {
		categoriesSelectElements,
		loadingProductCategories,
		customersSelectElements,
		productCategoriesOptions,
	} = useGlobalActionData(haveCrmModule)

	// pre-fill rate & duration if productBase is selected (need have CRM module)

	const handleProductBaseOnChange = useCallback(
		(optionName, optionValues) => {
			const optionValue: any = Object.values(optionValues)?.[0]
			setValues({
				[optionName]: optionValues,
				rate: data?.rate || optionValue?.value?.rate,
				duration: data?.duration || optionValue?.value?.duration,
				unitOfWork:
					data?.unitOfWork ||
					unitsOfWork?.find(
						({ value }) => value?.name === optionValue?.value?.unitOfWork
					),
			})
		},
		[data?.duration, data?.rate, setValues]
	)

	return (
		<Stack spacing={2} sx={wrapperModalCSS}>
			<FormTextField
				name='name'
				label={translateIntlMsgString('misc.commercialLib')}
				context={FormModalContext}
				isRequired
				trigerred
			/>

			<FormTextField
				name='commercialReference'
				label={translateIntlMsgString('misc.ref')}
				context={FormModalContext}
			/>

			<FormSelectSearch
				name='productCategory'
				label={translateIntlMsgString('catalogProduct.category')}
				selectElements={categoriesSelectElements}
				context={FormModalContext}
				isLoading={loadingProductCategories}
			/>

			{haveCrmModule && (
				<>
					<FormMultiSelectSearch
						name='customers'
						context={FormModalContext}
						options={customersSelectElements}
						label={translateIntlMsgString('misc.clientPart')}
						selectAllText={translateIntlMsgString('misc.selectAll')}
						nbChipsToDisplay={3}
						bordered
					/>
					<span>
						<PersonalizedFormSelectSearch
							name='productBase'
							specialOnChange={handleProductBaseOnChange}
							options={productCategoriesOptions}
							context={FormModalContext}
							label={translateIntlMsgString('catalogProduct.base')}
							selectAllText={translateIntlMsgString('misc.selectAll')}
							groupBy='categoryName'
							nbChipsToDisplay={3}
							bordered
						/>

						{productBase?.checked && (
							<Stack
								direction='row'
								justifyContent='space-between'
								p={2}
								bgcolor='grey.50'
							>
								{resumeProductBaseConfig?.map(({ title, value }, i) => (
									<ResumeProductBase key={i} title={title} value={value} />
								))}
							</Stack>
						)}
					</span>
				</>
			)}

			<Stack spacing={2}>
				<Stack direction='row' spacing={2}>
					<FormTextField
						name='rate'
						type='number'
						label={translateIntlMsgString('misc.priceHt')}
						context={FormModalContext}
						inputProps={inputProps}
					/>

					<FormSelectSearch
						name='vat'
						label={translateIntlMsgString('invoice.tva')}
						selectElements={vatListForSelect}
						context={FormModalContext}
						prefix='%'
					/>
				</Stack>
				<Stack direction='row' spacing={2}>
					<FormSelectSearch
						name='unitOfWork'
						label={translateIntlMsgString('misc.workUnit')}
						selectElements={unitsOfWork}
						context={FormModalContext}
						displayColorInput
						disabledSort
					/>

					<FormTimePicker
						name='duration'
						label={translateIntlMsgString('misc.duration')}
						context={FormModalContext}
					/>
				</Stack>
			</Stack>

			<MoreOptionsCatalogProduct haveCrmModule={haveCrmModule} />
		</Stack>
	)
}

export default CatalogueProductModalInfos
