/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
import listFormTemplateViewTable from '../components/tableV2/config/administration/formTemplateList/listFormTemplateViewTable'
import listTeamsViewTable from '../components/tableV2/config/administration/teams/listTeamsViewTable'
import listUsersViewTable from '../components/tableV2/config/administration/users/listUsersViewTable'
import listSitesViewTable from '../components/tableV2/config/dashboard/customerSites/listSitesViewTable'
import listAlertsViewTable from '../components/tableV2/config/dashboard/alerts/listAlertsViewTable'
import listCustomersViewTable from '../components/tableV2/config/dashboard/customerAccounts/listCustomersViewTable'
import listContactsViewTable from '../components/tableV2/config/dashboard/customerContacts/listContactsViewTable'
import listInterventionFormsViewTable from '../components/tableV2/config/dashboard/interventionForms/listInterventionControlFormsViewTable'
import listAgenciesViewTable from '../components/tableV2/config/dashboard/networkAgency/listAgenciesViewTable'
import listVehiclesViewTable from '../components/tableV2/config/dashboard/vehicles/listVehiclesTableConfig'
import listAdminPanelAccountViewTable from '../components/tableV2/config/adminPanel/listAdminPanelAccountViewTable'
import customerListAlertsViewModalTable from '../components/tableV2/config/dashboard/alerts/customerListAlertsViewModalTable'
import customerListInterventionsControlByRefViewModalTable from '../components/tableV2/config/dashboard/interventions/byReference/customerListInterventionsControlByRefViewModalTable'
import sitesListAlertsViewModalTable from '../components/tableV2/config/dashboard/alerts/sitesListAlertsViewModalTable'
import sitesListVehiclesViewModalTable from '../components/tableV2/config/dashboard/vehicles/sitesListVehiclesViewModalTable'
import sitesListContactViewModalTable from '../components/tableV2/config/dashboard/customerContacts/sitesListContactViewModalTable'
import vehiclesListAlertsViewModalTable from '../components/tableV2/config/dashboard/alerts/vehiclesListAlertsViewModalTable'
import vehiclesListInterventionFormsViewModalTable from '../components/tableV2/config/dashboard/interventionForms/vehiclesListInterventionControlFormsViewModalTable'
import interventionsListAlertsViewModalTable from '../components/tableV2/config/dashboard/alerts/interventionsListAlertsViewModalTable'
import agenciesListInterventionsControlByRefViewModalTable from '../components/tableV2/config/dashboard/interventions/byReference/agenciesListInterventionsControlByRefViewModalTable'
import agenciesListSitesViewModalTable from '../components/tableV2/config/dashboard/customerSites/agenciesListSitesViewModalTable'
import customerListVehiclesViewModalTable from '../components/tableV2/config/dashboard/vehicles/customerListVehiclesViewModalTable'
import customerListContactViewModalTable from '../components/tableV2/config/dashboard/customerContacts/customerListContactViewModalTable'
import customerListSitesViewModalTable from '../components/tableV2/config/dashboard/customerSites/customerListSitesViewModalTable'
import interventionsListInterventionFormsViewModalTable from '../components/tableV2/config/dashboard/interventionForms/interventionsListInterventionControlFormsViewModalTable'
import listInvoicesViewTable from '../components/tableV2/config/dashboard/invoices/listInvoicesViewTable'
import WlistInterventionsControlByReferenceViewWidget from '../components/tableV2/config/dashboard/interventions/byReference/WlistInterventionsControlByReferenceViewWidget'
import listCategoriesProductsTableConfig from '../components/tableV2/config/isolatedTables/listCategoriesProductsTableConfig'
import listDocumentsVehiclesViewTable from '../components/tableV2/config/dashboard/documentsVehicles/listDocumentsVehiclesViewTable'
import listDocumentsVehiclesViewModalTable from '../components/tableV2/config/dashboard/documentsVehicles/listDocumentsVehiclesViewModalTable'
import vehicleListInterventionsControlByReferenceViewModalTable from '../components/tableV2/config/dashboard/interventions/byReference/vehicleListInterventionsControlByReferenceViewModalTable'
import listInterventionsControlByVehicleViewTable from '../components/tableV2/config/dashboard/interventions/byVehicle/listInterventionsControlByVehicleViewTable'
import listInterventionsControlByReferenceViewTable from '../components/tableV2/config/dashboard/interventions/byReference/listInterventionsControlByReferenceViewTable'
import siteListInterventionsControlByReferenceViewModalTable from '../components/tableV2/config/dashboard/interventions/byReference/siteListInterventionsControlByReferenceViewModalTable'
import interventionsControlListVehiclesViewModalTable from '../components/tableV2/config/dashboard/vehicles/interventionsControlListVehiclesViewModalTable'
import listInterventionsTransportViewTable from '../components/tableV2/config/dashboard/interventionsTransport/listInterventionsTransportViewTable'
import customerListInterventionsTransportViewModalTable from '../components/tableV2/config/dashboard/interventionsTransport/customerListInterventionsTransportViewModalTable'
import siteListInterventionsTransportViewModalTable from '../components/tableV2/config/dashboard/interventionsTransport/siteListInterventionsTransportViewModalTable'
import vehicleListInterventionsTransportViewModalTable from '../components/tableV2/config/dashboard/interventionsTransport/vehicleListInterventionsTransportViewModalTable'
import transportListInterventionControlFormsViewModalTable from '../components/tableV2/config/dashboard/interventionForms/transportListInterventionControlFormsViewModalTable'
import transportListAlertsViewModalTable from '../components/tableV2/config/dashboard/alerts/transportListAlertsViewModalTable'
import transportListInvoicesViewTable from '../components/tableV2/config/dashboard/invoices/transportListInvoicesViewTable'
import transportListDocumentsViewModalTable from '../components/tableV2/config/dashboard/documentsVehicles/transportListDocumentsViewModalTable'
import agencyListInterventionsTransportViewTable from '../components/tableV2/config/dashboard/interventionsTransport/agencyListInterventionsTransportViewTable'
import interventionListInvoicesViewTable from '../components/tableV2/config/dashboard/invoices/interventionListInvoicesViewTable'
import { listProductCatalogueViewTable } from '../components/tableV2/config/administration/productCatalogue/listProductCatalogueViewTable'
import { listProductBaseCatalogueViewTable } from '../components/tableV2/config/administration/productCatalogue/listProductBaseCatalogueViewTable'
import { listProductSharedCatalogueViewTable } from '../components/tableV2/config/administration/productCatalogue/listProductSharedCatalogueViewTable'

// all tables with a filter column
const tablesConfig = [
	// ////////////////////////////////////////////////////////////////////////////////////////////////////////
	// WIDGETS
	// ////////////////////////////////////////////////////////////////////////////////////////////////////////

	// Interventions
	{
		tableConfig: WlistInterventionsControlByReferenceViewWidget,
		keyLocalStorage: 'listInterventionsByReferenceViewTableFiltersWidget',
	},
	// ////////////////////////////////////////////////////////////////////////////////////////////////////////
	// ADMIN PANEL
	// ////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Admin panel accounts
	{
		tableConfig: listAdminPanelAccountViewTable,
		keyLocalStorage: 'listAdminPanelAccountViewTableFilters',
	},

	// ////////////////////////////////////////////////////////////////////////////////////////////////////////
	// ADMINISTRATION
	// ////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Template forms
	{
		tableConfig: listFormTemplateViewTable,
		keyLocalStorage: 'listFormTemplateViewTableFilters',
	},
	// Teams
	{
		tableConfig: listTeamsViewTable,
		keyLocalStorage: 'listTeamsViewTableFilters',
	},
	// Users
	{
		tableConfig: listUsersViewTable,
		keyLocalStorage: 'listUsersViewTableFilters',
	},
	// Catalogue product
	{
		tableConfig: listProductCatalogueViewTable,
		keyLocalStorage: 'listCatalogueProductViewTableFilter',
	},
	{
		tableConfig: listProductBaseCatalogueViewTable,
		keyLocalStorage: 'listCatalogueProductBaseViewTableFilter',
	},
	{
		tableConfig: listProductSharedCatalogueViewTable,
		keyLocalStorage: 'listCatalogueProductSharedViewTableFilter',
	},

	// ////////////////////////////////////////////////////////////////////////////////////////////////////////
	// DASHBOARD
	// ////////////////////////////////////////////////////////////////////////////////////////////////////////

	// Alerts
	{
		tableConfig: listAlertsViewTable,
		keyLocalStorage: 'listAlertsViewTable',
	},
	// Customers
	{
		tableConfig: listCustomersViewTable,
		keyLocalStorage: 'listCustomersViewTableFilters',
	},
	// Contacts
	{
		tableConfig: listContactsViewTable,
		keyLocalStorage: 'listContactsViewTableFilters',
	},
	// sites
	{
		tableConfig: listSitesViewTable,
		keyLocalStorage: 'listSitesViewTableFilter',
	},
	// Interventions forms
	{
		tableConfig: listInterventionFormsViewTable,
		keyLocalStorage: 'listInterventionFormsViewTableFilters',
	},
	// Interventions by reference
	{
		tableConfig: listInterventionsControlByReferenceViewTable,
		keyLocalStorage: 'listInterventionsByReferenceViewTableFilters',
	},
	// Interventions by vehicle
	{
		tableConfig: listInterventionsControlByVehicleViewTable,
		keyLocalStorage: 'listInterventionsByVehicleViewTableFilters',
	},
	// Agencies
	{
		tableConfig: listAgenciesViewTable,
		keyLocalStorage: 'listAgenciesViewTableFilters',
	},
	// Vehicles
	{
		tableConfig: listVehiclesViewTable,
		keyLocalStorage: 'listVehiclesViewTableFilters',
	},
	// invoices
	{
		tableConfig: listInvoicesViewTable,
		keyLocalStorage: 'listInvoicesViewTableFilters',
	},
	// documents vehicles
	{
		tableConfig: listDocumentsVehiclesViewTable,
		keyLocalStorage: 'listVehiclesDocumentsViewTableFilters',
	},
	{
		tableConfig: listInterventionsTransportViewTable,
		keyLocalStorage: 'listInterventionsTransportViewTableFilters',
	},
	// ////////////////////////////////////////////////////////////////////////////////////////////////////////
	// VIEW MODAL
	// ////////////////////////////////////////////////////////////////////////////////////////////////////////

	// AGENCIES //////////////////////////////
	// -> interventions
	{
		tableConfig: agenciesListInterventionsControlByRefViewModalTable,
		keyLocalStorage: 'agenciesListInterventionsByRefViewModalTableFilters',
	},
	// -> transport
	{
		tableConfig: agencyListInterventionsTransportViewTable,
		keyLocalStorage: 'agenciesListInterventionsTransportViewModalTableFilters',
	},
	// -> sites
	{
		tableConfig: agenciesListSitesViewModalTable,
		keyLocalStorage: 'agenciesListSitesViewModalTableFilters',
	},
	// -> Shared products
	{
		tableConfig: listCategoriesProductsTableConfig,
		keyLocalStorage: 'agenciesListSharedProductViewModalTableFilters',
	},

	// CUSTOMER //////////////////////////////
	// -> sites
	{
		tableConfig: customerListSitesViewModalTable,
		keyLocalStorage: 'customerListSitesViewModalTableFilters',
	},
	// -> contact
	{
		tableConfig: customerListContactViewModalTable,
		keyLocalStorage: 'customerListContactViewModalTableFilters',
	},
	// -> vehicle
	{
		tableConfig: customerListVehiclesViewModalTable,
		keyLocalStorage: 'customerListVehiclesViewModalTableFilters',
	},
	// -> interventions
	{
		tableConfig: customerListInterventionsControlByRefViewModalTable,
		keyLocalStorage: 'customerListInterventionsByRefViewModalTableFilters',
	},
	// -> alerts
	{
		tableConfig: customerListAlertsViewModalTable,
		keyLocalStorage: 'customerListAlertsViewModalTableFilters',
	},

	// SITES //////////////////////////////
	// -> vehicle
	{
		tableConfig: sitesListVehiclesViewModalTable,
		keyLocalStorage: 'sitesListVehiclesViewModalTableFiler',
	},
	// -> contact
	{
		tableConfig: sitesListContactViewModalTable,
		keyLocalStorage: 'sitesListContactViewModalTableFilters',
	},
	// -> interventions
	{
		tableConfig: siteListInterventionsControlByReferenceViewModalTable,
		keyLocalStorage: 'siteListInterventionsByReferenceViewModalTableFilters',
	},
	// -> alerts
	{
		tableConfig: sitesListAlertsViewModalTable,
		keyLocalStorage: 'sitesListAlertsViewModalTableFilters',
	},

	// -> interventions transport
	{
		tableConfig: siteListInterventionsTransportViewModalTable,
		keyLocalStorage: 'siteListInterventionsTransportViewModalTableFilters',
	},
	// -> interventions transport
	{
		tableConfig: customerListInterventionsTransportViewModalTable,
		keyLocalStorage: 'customerListInterventionsTransportViewModalTableFilters',
	},
	// VEHICLES //////////////////////////////

	// -> interventions
	{
		tableConfig: vehicleListInterventionsControlByReferenceViewModalTable,
		keyLocalStorage: 'vehicleListInterventionsByReferenceViewModalTableFilters',
	},
	// -> alerts
	{
		tableConfig: vehiclesListAlertsViewModalTable,
		keyLocalStorage: 'vehiclesListAlertsViewModalTableFilters',
	},
	// -> Forms
	{
		tableConfig: vehiclesListInterventionFormsViewModalTable,
		keyLocalStorage: 'vehiclesListInterventionFormsViewModalTableFilters',
	},
	// -> invoices
	{
		tableConfig: listInvoicesViewTable,
		keyLocalStorage: 'vehiclesListInvoicesViewModalTableFilters',
	},
	// -> interventions transport
	{
		tableConfig: vehicleListInterventionsTransportViewModalTable,
		keyLocalStorage: 'vehicleListInterventionsTransportViewModalTableFilters',
	},
	// -> document
	{
		tableConfig: listDocumentsVehiclesViewModalTable,
		keyLocalStorage: 'vehiclesListDocumentsVehiclesViewModalTableFilters',
	},
	// INTERVENTIONS //////////////////////////////
	// -> vehicles
	{
		tableConfig: interventionsControlListVehiclesViewModalTable,
		keyLocalStorage: 'interventionsListVehiclesViewModalTableFilters',
	},
	// -> alerts
	{
		tableConfig: interventionsListAlertsViewModalTable,
		keyLocalStorage: 'interventionListAlertsViewModalTableFilters',
	},
	// -> forms
	{
		tableConfig: interventionsListInterventionFormsViewModalTable,
		keyLocalStorage: 'interventionsListInterventionFormsViewModalTableFilters',
	},
	// -> invoices
	{
		tableConfig: interventionListInvoicesViewTable,
		keyLocalStorage: 'interventionsListInvoicesViewModalTableFilters',
	},
	// -> documents
	{
		tableConfig: listDocumentsVehiclesViewModalTable,
		keyLocalStorage: 'interventionsListDocumentsViewModalTableFilters',
	},
	// TRANSPORT //////////////////////////////
	// -> alerts
	{
		tableConfig: transportListAlertsViewModalTable,
		keyLocalStorage: 'transportListAlertsViewModalTableFilters',
	},
	// -> forms
	{
		tableConfig: transportListInterventionControlFormsViewModalTable,
		keyLocalStorage: 'transportListInterventionFormsViewModalTableFilters',
	},
	// -> invoices
	{
		tableConfig: transportListInvoicesViewTable,
		keyLocalStorage: 'transportListInvoicesViewModalTableFilters',
	},
	// -> documents
	{
		tableConfig: transportListDocumentsViewModalTable,
		keyLocalStorage: 'transportListDocumentsViewModalTableFilters',
	},
] as any

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// To solve the problem of filtering columns (if a column in local storage no longer exists, or rename, etc.)
// look at the existing columns, compare with the configuration in local storage and update the local storage
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const compareAndUpdateTableColumnFilter = (
	checkAccountsType,
	checkModules
) => {
	tablesConfig.forEach(({ tableConfig, keyLocalStorage }) => {
		// get the current local storage table filter column
		const getCurrentLocalStorage = JSON.parse(
			localStorage.getItem(keyLocalStorage) as string
		)

		if (getCurrentLocalStorage) {
			// Possible column
			const possibleFilterColumn: any = tableConfig
				.filter(
					({ modules, accountsTypeDenied }) =>
						(!modules || checkModules(modules)) &&
						(!accountsTypeDenied || !checkAccountsType(accountsTypeDenied))
				)
				?.map(({ columnLabel }) => columnLabel) as any

			// compare if column in the local storage exist in the possibleFilterColumn
			// and we delete the one that does not exist
			const newConfig = getCurrentLocalStorage.filter((entry1) => {
				return possibleFilterColumn.some((entry2) => {
					return entry1 === entry2
				})
			})

			localStorage.setItem(keyLocalStorage, JSON.stringify(newConfig))
		}
	})
}
