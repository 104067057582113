import React, { Context, useContext, useEffect } from 'react'

import { IFormModalContext } from '../formModal/FormModalContext'
import FormTextField from './FormTextField'
import { useDebouncedState } from '../../../utils/hooks'
import { IStepperContext } from '../formStepper/StepperContext'

interface IDeboucedFormTextField {
	name: string
	context: Context<IFormModalContext> | Context<IStepperContext>
	label?: string
	multiline?: boolean
	minRows?: number
	isRequired?: boolean
	type?: string
	size?: 'small' | 'medium' | undefined
	isDisabled?: boolean
	hidden?: boolean
	onBlur?: any
	maxRows?: number
	specialOnChange?: any
	InputLabelProps?: any
	inputProps?: any
	InputProps?: any
}

const DeboucedFormTextField: React.FC<IDeboucedFormTextField> = ({
	name,
	label,
	isRequired,
	size,
	isDisabled,
	hidden,
	multiline = false,
	minRows = 0,
	maxRows = 4,
	type,
	onBlur,
	specialOnChange,
	InputLabelProps = null,
	inputProps = {},
	InputProps = {},
	context,
}) => {
	const { triggerError, errors } = useContext<
		IFormModalContext | IStepperContext
	>(context as any)
	const [debouncedState, setState, liveState] = useDebouncedState(
		{ [name]: '' },
		500
	)

	useEffect(() => {
		if (errors[name]) triggerError({ fieldName: name })
	}, [debouncedState])

	return (
		<FormTextField
			context={context}
			name={name}
			hidden={hidden}
			specialOnChange={specialOnChange}
			InputLabelProps={InputLabelProps}
			multiline={multiline}
			minRows={minRows}
			maxRows={maxRows}
			label={label}
			size={size || 'small'}
			isDisabled={isDisabled}
			type={type || 'text'}
			inputProps={inputProps}
			InputProps={InputProps}
			onBlur={onBlur}
			isRequired={isRequired}
			debouncedStateUtils={{ setState, liveState }}
			trigerred
		/>
	)
}

export default DeboucedFormTextField
