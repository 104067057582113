/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

import { globalConfigPDF } from '../globalConfigPDF'

const { stylePropertyAdditionalFooter, margin } = globalConfigPDF

export const blocksAdditionalFooter = (doc, text) => {
	// need to break page if there is no space to display the additional footer
	// pageBreakYposition(doc, yBreakPage)

	return doc.autoTable({
		// Body add only the text (we use didParseCell after to change the title color & didDrawCell to add text with another color or custom field)
		body: [
			{
				right: '',
				value: text,
				left: '',
			},
		],
		margin: { top: margin, bottom: margin },

		columnStyles: {
			0: {
				columnWidth: 2.5,
			},
			1: { valign: 'middle', halign: 'left' },
			2: {
				columnWidth: 2.5,
			},
		},
		tableLineColor: '#ddd',
		tableLineWidth: 0.5,

		// Block position start Y (default use the last block final position Y)
		startY: doc.lastAutoTable.finalY + 10,
		...stylePropertyAdditionalFooter,
	})
}
