import React, { useCallback } from 'react'

import { useToaster } from 'react-hot-toast'
import Alert from '@mui/material/Alert'
import Slide from '@mui/material/Slide'
import { Stack } from '@mui/material'

import { useFormatMessage } from '../../utils/hooks'
import { defaultContainerToastCss } from './utilsToast'

interface IAlertToasts {}

// to display alert example : toast.error('misc.traduction') || toast.success('misc.traduction')
// or use for example customToast('sendEmail', 'success.contactIsShare') | to create new customToasts -> customToast.tsx

// !important -> if className exist it's a customToast

const AlertToasts: React.FC<IAlertToasts> = () => {
	const intlMsg = useFormatMessage()
	const { toasts, handlers } = useToaster()
	const { startPause, endPause } = handlers

	const getToastSx = useCallback(
		(style) => () => ({
			...defaultContainerToastCss,
			...style,
		}),
		[]
	)

	return (
		<Stack sx={{ position: 'fixed', bottom: 15, left: 15, zIndex: 1500 }}>
			{toasts.map(({ id, type, message, icon, className, style }: any, i) => (
				<Slide direction='right' in={true} mountOnEnter unmountOnExit key={i}>
					<Alert
						key={id}
						severity={!className && type !== 'blank' ? type : 'success'}
						color={!className ? type : ''}
						icon={icon || ''}
						sx={getToastSx(style)}
						onMouseEnter={startPause}
						onMouseLeave={endPause}
						variant='outlined'
					>
						{intlMsg(message)}
					</Alert>
				</Slide>
			))}
		</Stack>
	)
}

export default AlertToasts
