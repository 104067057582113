/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/display-name */
import React from 'react'
import FilterDate from '../../../../../components/filters/filtersComponents/filterDate'
import { dashboardFilterDateConfig } from './dashboardFilterDateConfig'

export const dashboardFilterConfig = (
	setFiltersQuerieParam,
	filtersQuerieParam
) => [
	{
		filter: (
			<FilterDate
				setFiltersQuerieParam={setFiltersQuerieParam}
				filtersQuerieParam={filtersQuerieParam}
				customFilterDateConfig={dashboardFilterDateConfig}
				setLocalStorageOnchange
				customLocalStorageKey='dashboardFilterPreference'
				argumentQuerieName={'dateFrom'}
			/>
		),
	},
]
