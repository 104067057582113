/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import { COUNT_CATEGORIES_AND_PRODUCTS } from '../../../api/gql/queries/catalogProductQueries'

import { INTERVENTION_COUNT_BY_AGENCY } from '../../../api/gql/queries/interventionsQueries'
import { SITES_COUNT_BY_AGENCY } from '../../../api/gql/queries/sitesQueries'
import agenciesListSitesViewModalTable from '../../../components/tableV2/config/dashboard/customerSites/agenciesListSitesViewModalTable'
import agenciesListInterventionsControlByRefViewModalTable from '../../../components/tableV2/config/dashboard/interventions/byReference/agenciesListInterventionsControlByRefViewModalTable'
import SharedProductCategories from '../../../components/tableV2/_isolatedTables/sharedProductCategories'
import CustomerSites from '../../dashboard/customerSites'
import InterventionsByReference from '../../dashboard/interventions/interventionControl/byReference'
import InterventionTransport from '../../dashboard/interventions/interventionTransport'
import agencyListInterventionsTransportViewTable from '../../../components/tableV2/config/dashboard/interventionsTransport/agencyListInterventionsTransportViewTable'
import { INTERVENTION_TRANSPORT_COUNT_BY_AGENCY } from '../../../api/gql/queries/interventionsTransportQueries'
import { EModule, EPeriodFilter } from '../../../utils/app-models'

// ////////////////////////////////////////////////////////////////////////////////////////////////////////
// AGENCY TAB CONFIG
// ////////////////////////////////////////////////////////////////////////////////////////////////////////

export const agencyTabsConfig = [
	{
		label: 'misc.interventions',
		query: INTERVENTION_COUNT_BY_AGENCY,
		argumentQueryCount: 'agency',
		queryName: 'interventions',
		component: (
			<InterventionsByReference
				overrideConfigTable={{
					tableConfig: agenciesListInterventionsControlByRefViewModalTable,
					argumentQueryViewModal: 'agency',
					elevationNbr: 0,
					hidden: { tableNavTab: true },
					localStorageName:
						'agenciesListInterventionsByRefViewModalTableFilters',
					tableContainer: { height: '56vh' },
				}}
				overrideTableProvider={{
					customLocalStorageKeyFilter:
						'agenciesListInterventionsByRefViewModalFilters',
					customDefaultFilterConfig: {
						period: EPeriodFilter.thisMonth,
					},
				}}
				isInViewModal
			/>
		),
	},

	{
		label: 'interventions.transports',
		query: INTERVENTION_TRANSPORT_COUNT_BY_AGENCY,
		argumentQueryCount: 'agency',
		queryName: 'interventions',
		modules: [EModule.interventionTransport],
		component: (
			<InterventionTransport
				overrideConfigTable={{
					tableConfig: agencyListInterventionsTransportViewTable,
					argumentQueryViewModal: 'agency',
					elevationNbr: 0,
					hidden: { tableNavTab: true },
					localStorageName:
						'agenciesListInterventionsTransportViewModalTableFilters',
					tableContainer: { height: '56vh' },
				}}
				overrideTableProvider={{
					customLocalStorageKeyFilter:
						'agenciesListInterventionsTransportViewModalFilters',
					customDefaultFilterConfig: {
						period: EPeriodFilter.thisMonth,
					},
				}}
				isInViewModal
			/>
		),
	},

	{
		label: 'agencies.sharedSites',
		query: SITES_COUNT_BY_AGENCY,
		argumentQueryCount: 'agency',
		queryName: 'sites',
		component: (
			<CustomerSites
				overrideConfigTable={{
					title: 'agencies.shareSitesToAgency',
					tableConfig: agenciesListSitesViewModalTable,
					argumentQueryViewModal: 'agency',
					elevationNbr: 0,
					localStorageName: 'agenciesListSitesViewModalTableFilters',
					tableContainer: { height: '56vh', disabledCheckBox: true },
					hidden: {
						tableToolBar: {
							switchActive: true,
							tableExport: true,
							tableImport: true,
							tableBtnCreate: true,
						},
						tableToolBarAction: { all: true },
					},
				}}
				isInViewModal
			/>
		),
	},

	{
		label: 'agencies.sharedProducts',
		query: COUNT_CATEGORIES_AND_PRODUCTS,
		argumentQueryCount: 'sharedToAgencies',
		queryName: 'productCategories',
		component: (
			<SharedProductCategories
				overrideConfigTable={{
					argumentQueryViewModal: 'sharedToAgencies',
					elevationNbr: 0,
					localStorageName: 'agenciesListSharedProductViewModalTableFilters',
					tableContainer: { height: '56vh', disabledCheckBox: true },
					hidden: { tableToolBarAction: { all: true } },
				}}
				isInViewModal
			/>
		),
	},
]
