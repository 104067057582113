export enum TypographyButtonVariant {
	contained = 'contained',
	outlined = 'outlined',
	text = 'text',
}
export enum TypographyButtonColor {
	primary = 'primary',
	secondary = 'secondary',
	inherit = 'inherit',
	success = 'success',
	error = 'error',
	info = 'info',
	warning = 'warning',
}
export enum TypographyButtonSize {
	small = 'small',
	medium = 'medium',
	large = 'large',
}
export enum TypographyButtonType {
	button = 'button',
	submit = 'submit',
	reset = 'reset',
}
