import React from 'react'

import { Stack, Typography, Box } from '@mui/material'

import { stringifiedColor } from '../../../utils/color'
import { useFormatMessage } from '../../../utils/hooks'

const labelColorCSS = (color) => ({
	backgroundColor: stringifiedColor(color, 400) as any,
	borderRadius: 1,
	width: '10px',
	height: '10px',
	mr: 1,
})

interface ILabelColorCell {
	category
}

const LabelColorCell: React.FC<ILabelColorCell> = ({
	category: { name, code, color },
}) => {
	const intlMsg = useFormatMessage()

	return (
		<Stack direction='row' alignItems='center'>
			<Box sx={labelColorCSS(color)} />
			<Typography variant='caption'>
				{code ? intlMsg(`alerts.${code}`) : name}
			</Typography>
		</Stack>
	)
}

export default LabelColorCell
