/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'

import { useDeleteTeams } from '../../../api/gql/mutations/teamsMutations'
import { TEAMS_FILTER_LIST } from '../../../api/gql/queries/teamsQueries'
import FormBtnModal from '../../../components/forms/formModalBtn'
import listTeamsViewTable from '../../../components/tableV2/config/administration/teams/listTeamsViewTable'
import { EFormModalMode, EPermissionScope } from '../../../utils/app-models'
import {
	ITableToolBarActionConfig,
	ITableToolBarConfig,
} from '../../../utils/typescript/tableType'
import { TeamValidationSchema } from '../../../utils/validationSchema'
import TeamModal from './teamModal'

////////////////////////////////////////////////////////////////
// Global variable
////////////////////////////////////////////////////////////////

const queryConfig = {
	query: TEAMS_FILTER_LIST,
	queryName: 'teams',
}

////////////////////////////////////////////////////////////////
// tableToolBarAction CONFIG
////////////////////////////////////////////////////////////////

const refreshQuerie = ['getTeamsList']

const deletedBulk = {
	mutation: useDeleteTeams,
	operation: 'remove',
	successMessageDelete: {
		singular: 'success.teamDeleted',
		plural: 'success.teamsDeleted',
	},
	messageConfirm: {
		title: 'confirmModal.deleteTeamsTitle',
		message: 'confirmModal.deleteTeamsMessage',
	},
	deletePermissions: [EPermissionScope.teamManage],
}

const tableToolBarActionConfig: ITableToolBarActionConfig = {
	refreshQuerie,
	deletedBulk,
}

////////////////////////////////////////////////////////////////
// TableToolBarHeader CONFIG
////////////////////////////////////////////////////////////////

const tableToolBarConfig: ITableToolBarConfig = {
	title: 'misc.teams',
	stickyToolBar: true,
	tableConfig: listTeamsViewTable,
	localStorageName: 'listTeamsViewTableFilters',
	queryConfig,
	modalButton: {
		Btn: (
			<FormBtnModal
				modal={<TeamModal modalUtils={{} as any} />}
				mode={EFormModalMode.create}
			/>
		),
	},
	validationSchemaFunction: TeamValidationSchema,
	createPermission: [EPermissionScope.vehicleCreate],
}

////////////////////////////////////////////////////////////////
// tableContainer CONFIG
////////////////////////////////////////////////////////////////

const tableContainerConfig = {
	tableConfig: listTeamsViewTable,
	queryConfig,
}

////////////////////////////////////////////////////////////////
// -> EXPORT CONFIG MAIN TABLE
////////////////////////////////////////////////////////////////

export const teamConfigTable = {
	tableToolBarConfig,
	tableToolBarActionConfig,
	tableContainerConfig,
	displaySwitchOption: true,
}
