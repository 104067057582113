/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import React from 'react'

import { Stack } from '@mui/material'

import BooleanColorPopover from '../../../../popover/BooleanColorPopover'
import UserRole from '../../../tableCell/UserRole'
import { timePostAgo, formatDate } from '../../../../../utils/date'
import {
	useRemoveUsers,
	useUpdateUsers,
} from '../../../../../api/gql/mutations/usersMutations'
import { EPermission } from '../../../../../utils/app-models'
import OpenSlidePanelCell from '../../../tableCell/OpenSlidePanelCell'
import PhoneCell from '../../../tableCell/PhoneCell'
import EmailCell from '../../../tableCell/EmailCell'
import TimeCell from '../../../tableCell/TimeCell'
import ActionsBtn from '../../../tableCell/actionsBtn'
import UserModal from '../../../../../view/administration/users/userModal'
import { Box } from '@mui/material'
import UserAvatarSlidePanel from '../../../../avatar/UserAvatarSlidePanel'

//!  columnLabel is mandatory
//!  dataKey OR personalizedCell is mandatory
// use datakey if you want to just display data
// personalized Cell is a function taking row as parameter

const listUsersViewTable = [
	{
		columnLabel: 'misc.generalAccount',
		permissions: [EPermission.superMegaAdmin],
		personalizedCell: ({ account }) => <Box>{account?.name}</Box>,
	},

	{
		fixWidth: '50px',
		columnLabel: ' ',
		visible: true,
		stickyFirstCol: true,
		personalizedCell: (row: any) => (
			<UserAvatarSlidePanel
				firstName={row?.firstName}
				lastName={row?.lastName}
				userId={row?.id}
				displayName={false}
			/>
		),
	},
	{
		columnLabel: 'misc.firstname',
		orderBy: 'firstName',
		personalizedCell: ({ id, firstName }: any) => (
			<OpenSlidePanelCell txt={firstName} id={id} isContact={false} />
		),
	},
	{
		columnLabel: 'misc.lastname',
		orderBy: 'lastName',
		personalizedCell: ({ lastName, id }) => (
			<OpenSlidePanelCell txt={lastName} id={id} isContact={false} />
		),
	},
	{
		columnLabel: 'misc.email',
		orderBy: 'email',
		personalizedCell: (row: any) => <EmailCell txt={row.email} />,
	},
	{
		columnLabel: 'misc.phone',
		orderBy: 'phone',
		tableCellAlign: 'center',
		cellAlign: 'center',
		personalizedCell: (row: any) => (
			<Stack direction='row' alignItems='center' spacing={1}>
				<PhoneCell txt={row.phone} icon='phone' />
				<PhoneCell txt={row.cellphone} icon='cellphone' />
			</Stack>
		),
	},
	{
		columnLabel: 'administration.workPosition',
		orderBy: 'job',
		dataKey: 'job',
		maxCharacters: 15,
	},
	{
		columnLabel: 'administration.role',
		orderBy: 'userType_name',
		personalizedCell: (row: any) => <UserRole role={row?.userType?.name} />,
	},

	{
		columnLabel: 'administration.accessPlatform',
		tableCellAlign: 'center',
		cellAlign: 'center',
		personalizedCell: (row: any) => {
			return (
				<BooleanColorPopover
					value={row.canLogin}
					id={row.id}
					txt={{ true: 'misc.active', false: 'misc.desactive' }}
					titlePopover={'administration.accessPlatform'}
					queryConfig={{
						query: useUpdateUsers,
						refreshQuery: ['getUsersList'],
						successMessage: 'success.userAccess',
						attribute: 'canLogin',
					}}
				/>
			)
		},
	},

	{
		columnLabel: 'administration.active',
		orderBy: 'isActive',
		tableCellAlign: 'center',
		cellAlign: 'center',
		personalizedCell: (row: any) => {
			return (
				<BooleanColorPopover
					value={row.isActive}
					id={row.id}
					txt={{ true: 'misc.active', false: 'misc.desactive' }}
					titlePopover={'administration.active'}
					queryConfig={{
						query: useUpdateUsers,
						refreshQuery: ['getUsersList'],
						successMessage: 'success.userAccess',
						attribute: 'isActive',
					}}
				/>
			)
		},
	},

	{
		columnLabel: 'misc.addAt',
		orderBy: 'dateCreated',
		tableCellAlign: 'center',
		cellAlign: 'center',
		personalizedCell: (row) => (
			<TimeCell date={row?.dateCreated ? formatDate(row.dateCreated) : '-'} />
		),
	},
	{
		columnLabel: 'user.lastLogin',
		orderBy: 'lastLogin',
		tableCellAlign: 'center',
		cellAlign: 'center',
		personalizedCell: (row) => (
			<TimeCell date={row.lastLogin ? timePostAgo(row.lastLogin) : '-'} />
		),
	},

	{
		fixWidth: '50px',
		visible: true,
		columnLabel: '',
		tableCellAlign: 'center',
		cellAlign: 'center',
		sticky: true,
		personalizedCell: (row: any) => (
			<ActionsBtn
				rowId={row?.id}
				deleted={{
					query: useRemoveUsers,
					refreshQuery: ['getUsersList'],
					successMessageDelete: 'success.userDeleted',
					messageConfirm: {
						title: 'confirmModal.deleteUserTitle',
						message: 'confirmModal.deleteUserMessage',
					},
				}}
				editModal={<UserModal editedUserId={row?.id} modalUtils={{} as any} />}
				isContact={false}
				withSlide={true}
			/>
		),
	},
]

export default listUsersViewTable
