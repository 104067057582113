import React, { useCallback, useContext, useState } from 'react'

import { Stack, Input, Box, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import Typography from '@mui/material/Typography/Typography'
import Select from '@mui/material/Select/Select'
import MenuItem from '@mui/material/MenuItem/MenuItem'
import EditIcon from '@mui/icons-material/Edit'

import { TitleField } from '../fieldEditForms/editFormsField'
import { useFormatMessage } from '../../../../../../utils/hooks'
import Switch from '@mui/material/Switch/Switch'
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel'
import {
	EFormTemplateBasicFields,
	EFormTemplatePictureAngleType,
} from '../../../../../../utils/app-models'
import BtnAddCircle from '../../../../../../components/buttons/BtnAddCircle'
import { FormTemplateContext } from '../../../../../../utils/contexts/FormTemplateContext'

interface IFieldEditFormLayoutPicture {}

const editIconCSS = {
	ml: 1,
	color: ({ palette }) => palette.grey['500'],
}

const nameInputCSS = {
	border: 'none',
	background: 'none',
	'&:focus': {
		outline: 'none',
	},
}

const containerPhotoCSS = {
	pb: 2,
	pt: 2,
	borderBottom: ({ palette }) => `2px solid ${palette.grey[200]}`,
}

const FieldEditFormLayoutPicture: React.FC<
	IFieldEditFormLayoutPicture
> = () => {
	const intlMsg = useFormatMessage()
	const [changedIntoInput, setChangedIntoInput] = useState(-1)
	const [pictureName, setPictureName] = useState('')

	const {
		drawerUtils: { controlDrawer: control, watchDrawer, setValueDrawer },
		limitationFields,
		setIsOpenLimitModal,
	} = useContext(FormTemplateContext)
	const watchedPicturesList = watchDrawer('picturesList')
	const [initialPicturesListLength] = useState(watchedPicturesList?.length)

	const handleChangeIntoInput = useCallback(
		(i) => () => {
			setPictureName('')
			setChangedIntoInput(i)
		},
		[]
	)

	const handleChangeInput = useCallback(
		(e) => setPictureName(e.target.value),
		[]
	)

	const handleChangeChoiceInput = useCallback(
		(pictureIndex) => (e) => {
			if (e.target.value !== '') {
				const newPicturesList = watchedPicturesList.map((elem) => ({
					...elem,
				}))
				newPicturesList[pictureIndex].name = e.target.value
				setValueDrawer('picturesList', newPicturesList)
			}
			setChangedIntoInput(-1)
		},
		[watchedPicturesList]
	)

	const handleOnKeyDown = useCallback(
		(pictureIndex) => (key) => {
			if (key.code === 'Enter') {
				const newPicturesList = watchedPicturesList.map((elem) => ({
					...elem,
				}))
				newPicturesList[pictureIndex].name = pictureName
				setValueDrawer('picturesList', newPicturesList)
				setChangedIntoInput(-1)
			}
		},
		[pictureName]
	)

	const handleRemovePicture = useCallback(
		(pictureIndex) => () => {
			const newPicturesList = watchedPicturesList.map((elem) => ({ ...elem }))
			newPicturesList.splice(pictureIndex, 1)
			setValueDrawer('picturesList', newPicturesList)
		},
		[setValueDrawer, watchedPicturesList]
	)

	const handleChangeSwitch = useCallback(
		(pictureIndex) => () => {
			const newPicturesList = watchedPicturesList.map((elem) => ({ ...elem }))
			newPicturesList[pictureIndex].isRequired =
				!newPicturesList[pictureIndex].isRequired
			setValueDrawer('picturesList', newPicturesList)
		},
		[watchedPicturesList]
	)

	const handleAngleChange = useCallback(
		(pictureIndex) => (e) => {
			const newPicturesList = watchedPicturesList.map((elem) => ({ ...elem }))

			// If it's the default name = 'Photo 1', 'Photo 2'... we replace the default name per the angleType name selected
			if (
				newPicturesList[pictureIndex].name.match(/^Photo[ ]{1,1}[0-9]{1,4}$/)
			) {
				newPicturesList[pictureIndex].name = intlMsg(
					`formTemplate.${e.target.value}`
				)
			}
			// If it's the default name is a angletype name... we replace this angleType name per the new angleType name
			else if (
				Object.values(EFormTemplatePictureAngleType).find(
					(e) =>
						intlMsg(`formTemplate.${e}`) === newPicturesList[pictureIndex].name
				)
			) {
				newPicturesList[pictureIndex].name = intlMsg(
					`formTemplate.${e.target.value}`
				)
			}

			newPicturesList[pictureIndex].angleType = e.target.value
			setValueDrawer('picturesList', newPicturesList)
		},
		[watchedPicturesList]
	)

	const handleAddPicture = useCallback(() => {
		if (
			limitationFields[EFormTemplateBasicFields.picture].currentPhotos +
				(watchedPicturesList?.length - initialPicturesListLength) >=
			limitationFields[EFormTemplateBasicFields.picture].maxPhotos
		) {
			setIsOpenLimitModal(EFormTemplateBasicFields.picture)
		} else {
			const newControlList = [
				...watchedPicturesList,
				{
					name: `Photo ${watchedPicturesList?.length + 1 || 1}`,
					isRequired: false,
					angleType: EFormTemplatePictureAngleType.none,
				},
			]
			setValueDrawer('picturesList', newControlList)
		}
	}, [setValueDrawer, watchedPicturesList, limitationFields])

	return (
		<Stack spacing={2}>
			<TitleField control={control} />
			{/* {specificFieldType !== EFormTemplateModelFields.pictureVehicle && (
				<SelectVehicleTypeField control={control} />
			)} */}

			<Stack>
				{watchedPicturesList.map(({ name, isRequired, angleType }, i) => (
					<Stack key={`picturesList-${name}-${i}`} sx={containerPhotoCSS}>
						<Stack
							direction='row'
							justifyContent='space-between'
							alignItems='center'
						>
							<Box
								sx={{ cursor: 'pointer' }}
								onClick={handleChangeIntoInput(i)}
							>
								{changedIntoInput === i ? (
									<Input
										sx={nameInputCSS}
										type='text'
										onBlur={handleChangeChoiceInput(i)}
										autoFocus
										onChange={handleChangeInput}
										onKeyDown={handleOnKeyDown(i)}
									/>
								) : (
									<Stack direction='row' alignItems='center' mb={1}>
										<Typography variant='body2' sx={{ fontWeight: 'bold' }}>
											{name}
										</Typography>
										<EditIcon fontSize='small' sx={editIconCSS} />
									</Stack>
								)}
							</Box>

							<IconButton
								aria-label='delete'
								onClick={handleRemovePicture(i)}
								size='large'
							>
								<DeleteIcon fontSize='small' />
							</IconButton>
						</Stack>

						<FormControlLabel
							control={
								<Switch
									checked={isRequired}
									color='primary'
									onChange={handleChangeSwitch(i)}
								/>
							}
							label={intlMsg('formTemplate.formRequire')}
						/>

						<Select
							style={{ width: '100%' }}
							value={angleType}
							onChange={handleAngleChange(i)}
							variant='outlined'
						>
							{Object.values(EFormTemplatePictureAngleType).map((e, i) => (
								<MenuItem key={`${e}-${i}`} value={e}>
									{intlMsg(`formTemplate.${e}`)}
								</MenuItem>
							))}
						</Select>
					</Stack>
				))}
			</Stack>

			<Stack direction='row' justifyContent='center' pb={2}>
				<BtnAddCircle action={handleAddPicture} />
			</Stack>
		</Stack>
	)
}

export default FieldEditFormLayoutPicture
