import React from 'react'

import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { Divider, Grid, Box } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'

import { useFormatMessage } from '../../../../utils/hooks'
import { ListItemsData } from '../../../../components'
import { listCardViewModalCSS as css } from '../../../../styles/css/viewModal'
import { ROUTES } from '../../../../routes/consts'
import UserAvatar from '../../../../components/avatar/UserAvatar'

interface IactorsSite {
	contacts
	comment
	gestionnaire
	sharedToAgencies
}

const ActorsSite: React.FC<IactorsSite> = ({
	contacts,
	comment,
	gestionnaire,
	sharedToAgencies,
}) => {
	const intlMsg = useFormatMessage()

	const isThereAResp = contacts?.filter(
		({ isSiteResp }) => isSiteResp === true
	).length

	return (
		<Paper sx={css.radius}>
			<Box sx={{ mb: 2 }}>
				<Grid container spacing={1} sx={{ pt: 1, pb: 1 }}>
					<Grid item xs={12}>
						<Typography variant='body2' sx={css.ListSubheader}>
							{intlMsg('misc.contactRefSite')}
						</Typography>
					</Grid>
					{isThereAResp ? (
						contacts?.map(({ isSiteResp, lastName, firstName, id }, i) => {
							return (
								isSiteResp && (
									<Grid item xs={12} key={i}>
										<UserAvatar
											userName={{
												firstName,
												lastName,
											}}
											userId={id}
											displayName
											isContact
											isListItemButton
										/>
									</Grid>
								)
							)
						})
					) : (
						<Grid item xs={12}>
							<Typography variant='body2' sx={css.ListSubheader}>
								-
							</Typography>
						</Grid>
					)}
				</Grid>

				<Divider />

				<Grid container spacing={1} sx={{ pt: 1, pb: 1 }}>
					<Grid item xs={12}>
						<Typography variant='body2' sx={css.ListSubheader}>
							{intlMsg('misc.comment')}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant='caption' sx={css.comment}>
							<InfoIcon fontSize='small' sx={{ mr: 1 }} /> {comment || '-'}
						</Typography>
					</Grid>
				</Grid>

				<Divider />

				<Grid container spacing={1} sx={{ pt: 1, pb: 1 }}>
					<Grid item xs={12}>
						<Typography variant='body2' sx={css.ListSubheader}>
							{intlMsg('misc.manager')}
						</Typography>
					</Grid>

					<Grid item xs={6}>
						{gestionnaire?.id ? (
							<UserAvatar
								userName={{
									firstName: gestionnaire?.firstName as string,
									lastName: gestionnaire?.lastName as string,
								}}
								userId={gestionnaire?.id}
								accountId={gestionnaire?.account?.id}
								displayName
								isListItemButton
							/>
						) : (
							<Typography variant='caption' sx={css.ListSubheader}>
								-
							</Typography>
						)}
					</Grid>
				</Grid>

				<Divider />

				<Grid container spacing={1} sx={{ pt: 1 }}>
					<Grid item xs={12}>
						<Typography variant='body2' sx={css.ListSubheader}>
							{intlMsg('misc.agencyPartner')}
						</Typography>
					</Grid>
				</Grid>
				<Box sx={{ ...css.spaceRightLeft, mb: 1, pb: 2 }}>
					{sharedToAgencies?.length ? (
						<ListItemsData
							nbCol={6}
							items={sharedToAgencies.map(({ id, agencyCustomDetails }) => {
								return {
									primaryText: '',
									secondaryText: agencyCustomDetails[0]?.agencyName || '-',
									viewModalLink: `${ROUTES.VIEWMODAL.AGENCIES.url}${id}`,
									sizeColumn: 12,
								}
							})}
						/>
					) : (
						<Typography
							variant='caption'
							sx={{ ...css.ListSubheader, pl: 0, pt: 1 }}
						>
							{'-'}
						</Typography>
					)}
				</Box>
			</Box>
		</Paper>
	)
}

export default ActorsSite
