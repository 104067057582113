import React, { useCallback, useMemo } from 'react'

import toast from 'react-hot-toast'
import {
	useCreateVehicle,
	useUpdateVehicle,
} from '../../../../api/gql/mutations/vehicleMutation'
import {
	useLazyVehicle,
	useLazyVehicleModalInitForcedData,
} from '../../../../api/gql/queries/vehiclesQueries'
import FormModal from '../../../../components/forms/formModal'

import { EFormModalMode } from '../../../../utils/app-models'
import { IModalUtils, useFormatMessage } from '../../../../utils/hooks'
import vehicleModalConfig from './vehicleModalConfig'
import {
	formatInterventionModalOnValidateData,
	formatVehicleModalEditDefaultValues,
	formatCustomerVehicleModalCreateDefaultValues,
} from './vehicleModalUtils'

import { useLazyCustomer } from '../../../../api/gql/queries/customersQueries'
import { useLazySite } from '../../../../api/gql/queries/sitesQueries'

interface IVehicleModal {
	editedVehicleId?
	specialCloseModal?
	forcedCustomerId?: string | null
	forcedSiteId?: string | null
	forcedImmat?: string | null
	forcedVin?: string | null
	modalUtils: IModalUtils
	mode?: EFormModalMode
	getResponseVehicleMutation?: any
}

const VehicleModal: React.FC<IVehicleModal> = ({
	editedVehicleId,
	specialCloseModal = null,
	forcedCustomerId,
	forcedSiteId,
	forcedImmat,
	forcedVin,
	modalUtils,
	mode,
	getResponseVehicleMutation,
}) => {
	const [createVehicle] = useCreateVehicle()
	const [updateVehicle] = useUpdateVehicle()
	const intlMsg = useFormatMessage()
	const { getCustomer } = useLazyCustomer()
	const { getSite } = useLazySite()
	const { getVehicle } = useLazyVehicle()
	const { getVehicleForcedData } = useLazyVehicleModalInitForcedData()

	const queriesObject: any = useMemo(
		() => ({ customer: getCustomer, site: getSite, getVehicleForcedData }),
		[]
	)

	const onValidate = useCallback(
		async ({
			data,
			setValidationLoading,
			closeModal,
			mode,
			curentEditedElement,
		}) => {
			setValidationLoading(true)
			const mutate =
				mode === EFormModalMode.edit ? updateVehicle : createVehicle

			await mutate({
				variables: {
					input: formatInterventionModalOnValidateData({
						data,
						mode,
						editedVehicleId: curentEditedElement?.id,
					}),
				},
				refetchQueries: [
					'getVehiclesList',
					'getVehiclesNumbers',
					'getVehicle',
					'getWVehicleBox',
					'getWVehicle',
				],
			})
				.then((res) => {
					mode === EFormModalMode.create
						? toast.success('success.vehicleCreate')
						: toast.success('success.vehicleUpdate')
					setValidationLoading(false)

					getResponseVehicleMutation &&
						getResponseVehicleMutation(
							res.data[
								mode === EFormModalMode.create
									? 'createVehicle'
									: 'updateVehicle'
							].vehicle
						)

					if (specialCloseModal) specialCloseModal()
					else closeModal()
				})
				.catch(() => setValidationLoading(false))
		},
		[specialCloseModal]
	)

	const initializeData = useCallback(
		async ({ setValues, setLoading, setCurentEditedElement, mode }) => {
			if (mode === EFormModalMode.edit) {
				setLoading(true)
				getVehicle({ variables: { id: editedVehicleId } })
					.then((res) => {
						const data = res?.data?.vehicle

						setValues(formatVehicleModalEditDefaultValues({ data }))
						setCurentEditedElement(data)
						setLoading(false)
					})
					.catch(() => setLoading(false))
			} else {
				setValues(
					await formatCustomerVehicleModalCreateDefaultValues({
						queriesObject,
						forcedImmat,
						forcedVin,
						forcedCustomerId,
						forcedSiteId,
					})
				)
			}
		},
		[
			mode,
			editedVehicleId,
			forcedSiteId,
			forcedCustomerId,
			forcedImmat,
			forcedVin,
		]
	)

	const handleModalName = useCallback(
		({ mode }) =>
			intlMsg(mode === EFormModalMode.edit ? 'vehicle.edit' : 'vehicle.create'),
		[]
	)

	return (
		<FormModal
			modalUtils={modalUtils}
			formModalTabConfig={vehicleModalConfig}
			formGetModalName={handleModalName}
			initialMode={mode}
			onValidate={onValidate}
			validateBtnText={intlMsg('misc.validate')}
			cancelBtnText={intlMsg('misc.cancel')}
			initializeData={initializeData}
			editedElementId={editedVehicleId}
			maxWidth='50vw'
		/>
	)
}

export default VehicleModal
