/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/display-name */
import React from 'react'

import { Stack } from '@mui/material'

import { useUpdateInterventionStatuses } from '../../../../../../api/gql/mutations/interventionsMutation'
import { useRemoveIntervention } from '../../../../../../api/gql/mutations/interventionsMutation'
import { useInterventionStatusesList } from '../../../../../../api/gql/queries/interventionsQueries'
import DateToFrom from '../../../../../date/DateToFrom'
import ActionPopoverBtn from '../../../../../popover/actionPopover'
import TasksAndCategoriesPopover from '../../../../../multiItemsPopoverList/popover/tasksAndCategoriesPopover'
import VehiclesInterventionPopover from '../../../../../multiItemsPopoverList/popover/vehiclesInterventionPopover'

import { ROUTES } from '../../../../../../routes/consts'
import {
	EAccountType,
	EModule,
	EPermissionScope,
} from '../../../../../../utils/app-models'
import { TypeTableConfigColumn } from '../../../../../../utils/typescript/tableType'
import OpenLinkCell from '../../../../tableCell/OpenLinkCell'
import ChipCountListPopover from '../../../../../multiItemsPopoverList/popover/simpleListPopover'
import InterventionType from '../../../../tableCell/InterventionType'
import ActionsBtn from '../../../../tableCell/actionsBtn'
import InterventionModal from '../../../../../../view/dashboard/interventions/interventionControl/interventionModal'
import UsersPopover from '../../../../../multiItemsPopoverList/popover/usersPopover'

const WlistInterventionsControlByReferenceViewWidget: TypeTableConfigColumn[] =
	[
		{
			columnLabel: 'interventions.ref',
			orderBy: 'reference',
			stickyFirstCol: true,
			personalizedCell: ({ reference, id, isSharedWithMe }) => (
				<>
					{reference ? (
						<OpenLinkCell
							viewModalLink={`${ROUTES.VIEWMODAL.INTERVENTION_REFERENCES.url}${id}`}
							txt={reference}
							isSharedWithMe={isSharedWithMe}
						/>
					) : (
						'-'
					)}
				</>
			),
		},

		{
			columnLabel: 'misc.type',
			orderBy: 'interventionType',
			personalizedCell: ({ interventionType }) => (
				<InterventionType interventionType={interventionType} />
			),
		},

		{
			columnLabel: 'misc.account',
			modules: [EModule.crm],
			orderBy: 'orderingCustomer_name',
			personalizedCell: ({ orderingCustomer }) => (
				<OpenLinkCell
					viewModalLink={`${ROUTES.VIEWMODAL.CUSTOMERS.url}${orderingCustomer?.id}`}
					txt={orderingCustomer?.name}
					maxCharacters={15}
				/>
			),
		},

		{
			columnLabel: 'misc.site',
			orderBy: 'orderingSite_name',
			personalizedCell: ({ orderingSite }) => (
				<OpenLinkCell
					viewModalLink={`${ROUTES.VIEWMODAL.SITE.url}${orderingSite?.id}`}
					txt={orderingSite?.name}
					maxCharacters={15}
					modules={[EModule.site]}
				/>
			),
		},

		{
			columnLabel: 'interventions.date',
			orderBy: 'dateFrom',
			personalizedCell: ({ dateTo, dateFrom }) => (
				<DateToFrom dateTo={dateTo} dateFrom={dateFrom} />
			),
		},

		{
			columnLabel: 'misc.status',
			orderBy: 'status_name',
			tableCellAlign: 'center',
			cellAlign: 'center',
			personalizedCell: ({ status, id }) => {
				return (
					<ActionPopoverBtn
						inputs={{ query: useInterventionStatusesList }}
						selected={{
							id: status?.id,
							color: status?.color,
							name: status?.name,
							code: status?.code,
						}}
						titlePopover={'misc.status'}
						keyTranslate='interventions'
						mutation={{
							id,
							query: useUpdateInterventionStatuses,
							refreshQuery: ['interventions', 'getInterventionsTotalDone'],
							successMessage: 'success.interventionStatusUpdate',
							attribute: 'status',
						}}
						permissions={[EPermissionScope.interventionUpdate]}
					/>
				)
			},
		},

		{
			columnLabel: 'misc.vehicles',
			personalizedCell: ({
				interventionControlProgrammedVehicles: { collection },
			}) => <VehiclesInterventionPopover data={collection} />,
		},

		{
			columnLabel: 'interventions.task',
			personalizedCell: ({
				interventionControlProgrammedVehicles: { collection },
				globalProducts,
			}) => (
				<TasksAndCategoriesPopover
					tasks={collection}
					globalTasks={globalProducts}
					isIpv
				/>
			),
		},

		{
			columnLabel: 'interventions.intervenants',
			accountsTypeDenied: [EAccountType.guest],
			orderBy: 'actors_lastName',
			personalizedCell: ({ actors: { collection } }) => (
				<UsersPopover
					data={collection?.map(({ id, lastName, firstName, account }) => ({
						firstName,
						lastName,
						id,
						account,
					}))}
					title='interventions.actors'
				/>
			),
		},

		{
			columnLabel: 'misc.agencies',
			orderBy: 'agencies_agencyCustomDetails_agencyName',
			modules: [EModule.network],
			personalizedCell: ({ agencies }) => (
				<Stack direction='row' alignItems='center'>
					<ChipCountListPopover
						text='misc.agencies'
						data={
							agencies?.collection?.map(({ agencyCustomDetails, id }) => {
								return { name: agencyCustomDetails[0]?.agencyName, id }
							}) || []
						}
						viewModalLink={`${ROUTES.VIEWMODAL.AGENCIES.url}`}
						permissions={[
							EPermissionScope.networkView,
							EPermissionScope.networkManage,
						]}
					/>
				</Stack>
			),
		},

		{
			fixWidth: '50px',
			columnLabel: '',
			visible: true,
			tableCellAlign: 'center',
			cellAlign: 'center',
			sticky: true,
			personalizedCell: ({ id }: any) => (
				<ActionsBtn
					duplicate
					rowId={id}
					deleted={{
						query: useRemoveIntervention,
						refreshQuery: [
							'interventions',
							'getInterventionsTotalDone',
							'getInterventionsTotalNotAssigned',
						],
						successMessageDelete: 'success.interventionDeleted',
						messageConfirm: {
							title: 'confirmModal.deleteInterventionsTitle',
							message: 'confirmModal.deleteInterventionMessage',
						},
					}}
					editModal={
						<InterventionModal interventionId={id} modalUtils={{} as any} />
					}
					viewModalLink={`${ROUTES.VIEWMODAL.INTERVENTION_REFERENCES.url}${id}`}
					permissions={{
						update: [EPermissionScope.interventionUpdate],
						remove: [EPermissionScope.interventionRemove],
						delete: [EPermissionScope.interventionDelete],
					}}
				/>
			),
		},
	]

export default WlistInterventionsControlByReferenceViewWidget
