import React, { useCallback } from 'react'

import { IconButton, Button } from '@mui/material'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'

interface IBtnDownloadFormPDFV2Link {
	oldVersionReference: string
	isBtn?: boolean
}

const BtnDownloadFormPDFV2Link: React.FC<IBtnDownloadFormPDFV2Link> = ({
	oldVersionReference,
	isBtn = false,
}) => {
	const handleOnClick = useCallback(() => {
		window.open(
			`https://connect.hubeecar.com/documents/formPdf?formulaire=${oldVersionReference}`,
			'_blank'
		)
	}, [oldVersionReference])

	return (
		<>
			{isBtn ? (
				<Button
					variant='contained'
					color='primary'
					onClick={handleOnClick}
					startIcon={<PictureAsPdfIcon />}
				>
					PDF
				</Button>
			) : (
				<IconButton onClick={handleOnClick} size='small'>
					<PictureAsPdfIcon />
				</IconButton>
			)}
		</>
	)
}

export default BtnDownloadFormPDFV2Link
