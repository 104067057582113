import React, { useState, useContext } from 'react'
import { Box } from '@mui/material'

import InformationsSite from './InformationsSite'
import ActorsSite from './ActorsSite'
import { AuthContext } from '../../../../utils/contexts/AuthContext'
import { EAccountType } from '../../../../utils/app-models'

interface ISiteInformation {
	data
}

const SiteInformation: React.FC<ISiteInformation> = ({ data }) => {
	const { checkAccountsType } = useContext(AuthContext)
	const [currentUserIsGuest] = useState(checkAccountsType([EAccountType.guest]))

	return (
		<Box sx={{ p: 2 }}>
			<InformationsSite
				customer={data?.customer}
				phone={data?.phone}
				cellphone={data?.cellphone}
				address={data?.address}
				city={data?.city}
				zipcode={data?.zipcode}
				name={data?.name}
				siret={data?.siret}
			/>

			{!currentUserIsGuest && (
				<ActorsSite
					contacts={data?.contacts?.collection}
					comment={data?.comment}
					gestionnaire={data?.gestionnaire}
					sharedToAgencies={data?.sharedToAgencies?.collection}
				/>
			)}
		</Box>
	)
}

export default SiteInformation
