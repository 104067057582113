import { createContext } from 'react'

export type IViewModalContentContext = {
	viewModalInfos: any
	viewModalData: any
	setViewModalData?: any
	loading: boolean
	headerConfig: any
}

const defaultContextValues: IViewModalContentContext = {
	viewModalInfos: null,
	viewModalData: null,
	loading: false,
	headerConfig: {},
}

export const ViewModalContentContext: React.Context<IViewModalContentContext> =
	createContext(defaultContextValues)
