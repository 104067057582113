import React from 'react'

import ListAltIcon from '@mui/icons-material/ListAlt'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
//import DomainIcon from '@mui/icons-material/Domain'
// import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline'
//import EditIcon from '@mui/icons-material/Edit'

import { ROUTES } from '../../../routes/consts'
import { EModule, EPermissionScope } from '../../../utils/app-models'

const menuConfig = [
	{
		sectionTitle: 'navigation.myAccountTitle',
		subMenu: [
			{
				icon: <PersonOutlineIcon />,
				title: 'navigation.myProfile',
				path: ROUTES.ADMINISTRATION.MY_PROFILE.url,
			},
			// {
			// 	icon: <DomainIcon />,
			// 	title: 'navigation.myDomain',
			// 	path: ' ROUTES.ADMINISTRATION_MY_PROFILE',
			// },
			// {
			// 	icon: <EditIcon />,
			// 	title: 'navigation.whiteBrand',
			// 	path: 'ROUTES.ADMINISTRATION_MY_PROFILE',
			// },
			// {
			// 	icon: <NotificationsNoneIcon />,
			// 	title: 'navigation.notifications',
			// 	path: ROUTES.ADMINISTRATION_NOTIFICATIONS,
			// },
		],
	},
	{
		sectionTitle: 'navigation.myJobCapsLock',
		permissions: [EPermissionScope.formTemplateManage],
		subMenu: [
			{
				icon: <ListAltIcon />,
				title: 'navigation.formTemplateList',
				path: ROUTES.ADMINISTRATION.FORM_TEMPLATES.url,
				subPermissions: [EPermissionScope.formTemplateManage],
				subModules: [EModule.formTemplate],
			},
			{
				icon: <BookmarkBorderIcon />,
				title: 'navigation.catalogProduct',
				path: ROUTES.ADMINISTRATION.PRODUCT_CATALOGUE.CATALOGUE_PRODUCT.url,
				multiPaths: [
					ROUTES.ADMINISTRATION.PRODUCT_CATALOGUE.CATALOGUE_PRODUCT_BASE.url,
					ROUTES.ADMINISTRATION.PRODUCT_CATALOGUE.CATALOGUE_PRODUCT_SHARED.url,
				],
				subPermissions: [EPermissionScope.productManage],
				subModules: [EModule.productCatalog],
			},
		],
	},
	{
		sectionTitle: 'navigation.usersTitle',
		permissions: [EPermissionScope.userManage],
		subMenu: [
			{
				icon: <PersonOutlineIcon />,
				title: 'navigation.users',
				path: ROUTES.ADMINISTRATION.USERS.url,
			},
			{
				icon: <PeopleOutlineIcon />,
				title: 'navigation.groups',
				path: ROUTES.ADMINISTRATION.TEAMS.url,
			},
		],
	},
]

export default menuConfig as any
