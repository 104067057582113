import React from 'react'

import CatalogueProductBaseSideNavModalInfos from './CatalogueProductBaseSideNavModalInfos'
import catalogueProductBaseSideNavModalValidationSchema from './catalogueProductBaseSideNavModalValidationSchema'

const catalogueProductBaseSideNavModalConfig = [
	{
		component: <CatalogueProductBaseSideNavModalInfos />,
		schema: catalogueProductBaseSideNavModalValidationSchema,
	},
]

export default catalogueProductBaseSideNavModalConfig
