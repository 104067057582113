import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'

import { Stack, Box, Typography, Alert, Chip, IconButton } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useFormatMessage } from '../../../../../../utils/hooks'
import {
	FormModalContext,
	IFormModalContext,
} from '../../../../../../components/forms/formModal/FormModalContext'
import { AuthContext } from '../../../../../../utils/contexts/AuthContext'
import {
	EFormModalMode,
	EInterventionStatuses,
	EModule,
} from '../../../../../../utils/app-models'
import { useUsersForSelect } from '../../../../../../api/gql/queries/usersQueries'
import { useLazyGetAgenciesForSelect } from '../../../../../../api/gql/queries/agencyQueries'
import FormMultiSelectSearch from '../../../../../../components/forms/formFields/formMultiSelectSearch'
import FormSelectSearch from '../../../../../../components/forms/formFields/formSelectSearch'

const alertCSS = {
	padding: '1px 16px',
	marginTop: ' 0px !important',
	marginBottom: ' 16px !important',
}

interface IActors {
	plannedStatus: any
}

const Actors: React.FC<IActors> = ({ plannedStatus }) => {
	const intlMsg = useFormatMessage()
	const { data, curentEditedElement, mode, setValues } =
		useContext<IFormModalContext>(FormModalContext)
	const { checkModules, currentUser } = useContext(AuthContext)

	const currentStatus = useMemo(() => data?.status || null, [data?.status])

	const haveAgencyAccess = useMemo(() => checkModules([EModule.network]), [])
	const selectedSite = useMemo(
		() => data.finalSite?.value?.id || data.orderingSite?.value?.id || null,
		[data.finalSite, data.orderingSite]
	)

	const { users } = useUsersForSelect()
	const { agenciesAccounts, getAgencies } = useLazyGetAgenciesForSelect()

	const actors = useMemo(
		() =>
			users?.map((user) => ({
				key: user?.id,
				label: `${user?.firstName} ${user?.lastName}`,
				value: user,
			})) || [],
		[users]
	)

	const formatedAgencies = useMemo(
		() =>
			agenciesAccounts?.map((agenciesAccount) => ({
				key: agenciesAccount?.id,
				label: agenciesAccount?.agencyCustomDetails[0]?.agencyName,
				value: agenciesAccount,
			})) || [],
		[agenciesAccounts]
	)

	const isAccountOwner = useMemo(
		() =>
			mode !== EFormModalMode?.edit ||
			curentEditedElement?.createdByAccount?.id === currentUser?.account?.id,
		[curentEditedElement, currentUser]
	)

	useEffect(() => {
		if (selectedSite && haveAgencyAccess)
			getAgencies({
				variables: {
					sharedSites: selectedSite,
				},
			})
	}, [selectedSite])

	// //////////////////////////////////////////////////////////////////////////
	// Display/Hide information's
	// //////////////////////////////////////////////////////////////////////////

	const [isInfosDisplayed, setIsInfosDisplayed]: any = useState(false)

	const handleDisplayComment = useCallback(() => {
		setIsInfosDisplayed(!isInfosDisplayed)
		localStorage.setItem(
			'displayInformationIntervention',
			JSON.stringify({
				isInfosDisplayed: !isInfosDisplayed,
			})
		)
	}, [isInfosDisplayed])

	useEffect(() => {
		const pref = JSON.parse(
			localStorage.getItem('displayInformationIntervention') as string
		)
		if (pref) setIsInfosDisplayed(pref.isInfosDisplayed)
		else setIsInfosDisplayed(true)
	}, [])

	const renderChipsActorsLabel = useCallback(
		(value) => `${value?.firstName} ${value?.lastName}`,
		[]
	)

	const renderChipsAgenciesLabel = useCallback(
		(value) => value?.agencyCustomDetails?.[0]?.agencyName,
		[]
	)

	const handleActorsChange = useCallback(
		(fieldName, value) => {
			const newValues: any = {
				[fieldName]: value,
			}
			if (
				currentStatus?.value?.code === EInterventionStatuses?.toPlan &&
				Object?.values(value)?.find(({ checked }: any) => checked)
			)
				newValues.status = {
					key: plannedStatus?.id,
					label: plannedStatus?.name,
					value: plannedStatus,
				}

			setValues(newValues)
		},
		[currentStatus, setValues, plannedStatus]
	)

	return (
		<Box>
			<Stack direction='row' alignItems='flex-start' spacing={1}>
				<Typography variant='h2' gutterBottom mb={2}>
					{intlMsg('interventions.actors')}
				</Typography>

				{isInfosDisplayed ? (
					<Chip
						onClick={handleDisplayComment}
						size='small'
						icon={<InfoOutlinedIcon />}
						label={intlMsg('interventions.hideInformation')}
						color='info'
					/>
				) : (
					<IconButton size='small' onClick={handleDisplayComment}>
						<InfoOutlinedIcon fontSize='inherit' />
					</IconButton>
				)}
			</Stack>

			<Stack justifyContent='space-between' spacing={2}>
				<FormMultiSelectSearch
					name='actors'
					options={actors}
					specialOnChange={handleActorsChange}
					context={FormModalContext}
					label={intlMsg('interventions.actors')}
					selectAllText={intlMsg('misc.selectAll')}
					bordered
					renderChipsLabel={renderChipsActorsLabel}
					nbChipsToDisplay={3}
				/>
				{isInfosDisplayed && (
					<Alert severity='info' icon={false} sx={alertCSS}>
						{intlMsg('interventions.actorsAccessInterventionMobile')}
					</Alert>
				)}

				{haveAgencyAccess && (
					<Stack spacing={2}>
						<FormMultiSelectSearch
							name='agenciesList'
							options={formatedAgencies}
							context={FormModalContext}
							label={intlMsg('interventions.agencies')}
							selectAllText={intlMsg('misc.selectAll')}
							missingText={intlMsg('interventions.noAgencies')}
							renderChipsLabel={renderChipsAgenciesLabel}
							bordered
						/>
						{isInfosDisplayed && (
							<Alert severity='info' icon={false} sx={alertCSS}>
								{intlMsg('interventions.agencyAssignmentActors')}
							</Alert>
						)}
					</Stack>
				)}

				<Stack direction='row' alignItems='center' spacing={2}>
					<FormSelectSearch
						name='manager'
						label={intlMsg('interventions.manager')}
						selectElements={actors}
						context={FormModalContext}
						playwrightId='Playwright-SelectInterventionStatus'
						isDisabled={!isAccountOwner}
						isRequired
						trigerred
					/>
				</Stack>
			</Stack>
		</Box>
	)
}
export default Actors
