export default {
	users: 'Users',
	user: 'User',
	name: 'Name',
	firstname: 'First Name',
	lastname: 'Last Name',
	job: 'Job',
	email: 'Email',
	password: 'Password',
	actualPassword: 'Current Password',
	newPassword: 'New Password',
	confirmPassword: 'Confirm New Password',
	phone: 'Phone',
	cellphone: 'Mobile',
	reference: 'Reference',
	referent: 'Referent',
	referentAccount: 'Account Referent',
	referentSite: 'Site Referent',
	referentSiteAccount: 'Site/Account Referent',
	from: 'from',
	to: 'to',
	yes: 'Yes',
	no: 'No',
	cancel: 'Cancel',
	validate: 'Validate',
	save: 'Save',
	confirm: 'Confirm',
	backEdit: 'EXIT EDITOR',
	edit: 'Edit',
	deleted: 'Delete',
	duplicate: 'Duplicate',
	see: 'See',
	connexion: 'Login',
	sitesAccess: 'Sites Access',
	site: 'Site',
	sites: 'Sites',
	networksAccess: 'Networks Access',
	selectNetworks: 'Select one or more networks',
	defectFound: 'Alerts',
	members: 'Members',
	operators: 'Operators',
	active: 'Active',
	inactive: 'Inactive',
	desactive: 'Inactive',
	teamsMember: 'Teams List',
	userList: 'Users List',
	userListTooltips:
		'A team is a group of users whose access to the platform is limited to assigned sites and/or networks (Network Module). Administrators are not affected by this limitation.',
	teamManager: 'Team Manager',
	teamNotDefine: 'Undefined Manager',
	teamName: 'Team Name',
	sitesLabel: 'Sites',
	networksLabel: 'Networks',
	information: 'Information',
	informationContact: 'Contact Information',
	comment: 'Additional Information',
	comment2: 'Comment',
	contactRef: 'Referent Contacts',
	contactRefSite: 'Site Referent(s)',
	technicalInformation: 'Technical Information',
	teams: 'Teams',
	team: 'Team',
	teamStatusState: 'Active Team',
	siteStatusState: 'Active Site',
	customerStatusState: 'Active Account',
	add: 'Add',
	addAt: 'Added on',
	addBy: 'Added by',
	search: 'Search',
	connectWelcome: 'Please enter your credentials to log in to your account.',
	schedule: 'Schedule',
	dashboard: 'Dashboard',
	networks: 'Networks',
	network: 'Network',
	clients: 'Clients',
	directory: 'Directory',
	accounts: 'Accounts',
	account: 'Account',
	generalAccount: 'Account',
	agency: 'Agency',
	agencies: 'Agencies',
	agencyPartner: 'Associated Agencies',
	interveningAgency: 'Intervening Agencies',
	location: 'Location',
	contact: 'Contact',
	contacts: 'Contacts',
	siteName: 'Site Name',
	addressSite: 'Site Address',
	interventions: 'Interventions',
	intervention: 'Intervention',
	reportIntervention: 'Forms',
	vehicles: 'Vehicles',
	vehicle: 'Vehicle',
	vehicleStatusState: 'Active Vehicle',
	alerts: 'Alerts',
	permissions: 'Access Rights',
	nothingToDisplay: 'The page is empty',
	nothingToDisplayBold: 'No results match your search',
	nothingToDisplayparagraph: 'Modify the filters or enter another search',
	addVehiculesToIntervention:
		'Click ADD to assign vehicles to your intervention',
	noResultSearch: 'No results',
	platform: 'platform',
	updatedAt: 'Last updated on',
	address: 'Address',
	city: 'City,',
	country: 'Country',
	zipCode: 'Zip Code',
	mailSite: 'Email',
	numberVehicle: 'Number of Vehicles',
	infosComplementary: 'Additional Information',
	manager: 'Manager',
	phoneSite: 'Phone',
	mobileSite: 'Mobile Phone',
	siret: 'SIRET',
	localisation: 'Localization',
	nbSites: 'Number of Sites',
	nbInterventions: 'Number of Interventions',
	siretNumber: 'SIRET Number',
	tavNumber: 'VAT Number',
	zipcode: 'Zip Code',
	socialName: 'Company Name',
	dateMec: 'First Registration Date',
	dateParkEntry: 'Entry into Fleet Date',
	dateParkExit: 'Exit from Fleet Date',
	accountSite: 'Account / Site',
	visibleRows: 'Visible Columns',
	importData: 'Import Data',
	allExport: 'Export All',
	exportData: 'Export Data',
	exportDataToExcel: 'Export to Excel format:',
	export: 'Export',
	exportWaiting: 'Preparing your export, please do not leave the page.',
	close: 'Close',
	ref: 'Reference',
	poste: 'Position',
	createdAt: 'Creation Date',
	createdBy: 'Created by',
	goBackToDashboard: 'Go back to Dashboard',
	uploadFile: 'Click or drop a document',
	download: 'Download',
	selectedFile: 'Selected Document',
	dragFileHere: 'Drag the document here...',
	factures: 'Invoices',
	status: 'Status',
	progression: 'Progress',
	todo: 'To Do',
	returned: 'Returned',
	all: 'All',
	period: 'Period',
	allPeriod: 'All',
	thisWeek: 'This Week',
	today: 'Today',
	lastWeek: 'Last Week',
	thisMonth: 'This Month',
	lastMonth: 'Last Month',
	nextMonth: 'Next Month',
	customPeriod: 'Custom Range',
	custom: 'Custom',
	filter: 'Filters',
	customerAndSite: 'Account / Site',
	doneDate: 'Done Date',
	doBy: 'Done by',
	noElement: 'No Element',
	none: 'None',
	categorie: 'Categories',
	products: 'Products',
	month: 'Month',
	day: 'Day',
	days: 'Days',
	week: 'Week',
	displayBy: 'Display by',
	other: 'Other',
	others: 'Others',
	display: 'Display',
	leave: 'Leave',
	deleteFilters: 'Delete Filters',
	order: 'Order',
	empty: 'Empty',
	tooltipCustomerResp:
		'The contact is one of the responsible persons for the selected account',
	tooltipSiteResp:
		'The contact is one of the responsible persons for the selected site(s)',
	identification: 'Identification',
	Alert: 'Alert',
	Alerts: 'Alerts',
	title: 'Title',
	summary: 'Summary',
	next: 'Next',
	previous: 'Previous',
	saveAndQuit: 'Save and Quit',
	invalideSiret: 'Invalid SIRET',
	type: 'Type',
	limited: 'Limited',
	premium: 'Premium',
	invitation: 'Invitation',
	upgrade: 'Upgrade',
	downgrade: 'Downgrade',
	selected: 'Selected',
	select: 'Select',
	selection: 'Selection',
	bySites: 'By Site(s)',
	byNetworks: 'By Network(s)',
	affectation: 'Assignment',
	geolocalisation: 'Geolocation',
	notInformed: 'Not Informed',
	accountResponsable: 'Account Referent(s)',
	noEntry: 'No items available',
	loading: 'Loading',
	source: 'Source',
	owner: 'Assignment',
	inviteNotSend: 'No invitation sent',
	inviteSend: 'Invitation sent on',
	userNotRegister: 'User not registered',
	useRegister: 'Registered User',
	comingSoon: 'The feature is coming soon',
	shared: 'Shared',
	sharedBy: 'Shared by',
	selectAll: 'Select All',
	myInfos: 'My Information',
	passwordForgot: 'Forgot Password?',
	changeLayout: 'Change Layout',
	photos: 'Photos',
	allAccounts: 'All Accounts',
	share: 'Share',
	discoverHubee: 'Do you want to discover HubeeConnect?',
	askDemo: 'request a demo',
	createAccount: 'create an account',
	accountType: 'Account Type',
	toUpdate: 'To Update',
	dateStart: 'Start Date',
	dateEnd: 'End Date',
	hourStart: 'Start Time',
	hourEnd: 'End Time',
	resetAll: 'Reset All',
	byStatus: 'By Status',
	remove: 'Remove',
	lessOptions: 'Less options',
	moreOptions: 'More options',
	create: 'Create',
	emptySelection: 'Empty Selection',
	noDataFoundSearch: 'No data found for your search',
	french: 'French',
	english: 'English',
	customerRef: 'Customer Reference',
	publicDesc: 'Public description',
	showMore: 'Show more',
	showLess: 'Show less',
	clientPart: 'client part',
	driverPart: 'Driver part',
	service: 'Service',
	services: 'Services',
	orderService: 'Order a service',
	showAll: 'Show all',
	showSelectedElements: 'Display selected items',
	recipient: 'Recipient',
	dateMission: 'Mission date',
	missions: 'Missions',
	mission: 'Mission',
	note: 'Note',
	enteredBy: 'Entered by',
	planned: 'Planned',
	step: 'Step',
	conveyors: 'Conveyors',
	commercialLib: 'Code',
	lib: 'Code',
	label: 'label',
	duration: 'Duration',
	workUnit: 'Work unit',
	priceHt: 'Price HT',
	dateContractStart: 'Contract start date',
	dateContractEnd: 'Contract end date',
	validContractDate: 'Valid contract date',
	permanent: 'Permanent',
	missionType: 'Mission Type',
	swapPickupDeliveryAddresses: 'Swap pickup and delivery addresses',
	dateFrom: 'From',
	dateUntil: 'Until',
	bannerMessageFormModalIntervention:
		'This intervention was created by {name}, some elements are not editable.',
	bannerMessageFormModalTransport:
		'This transport was created by {name}, some elements are not editable.',
	downloadPdf: 'Download PDF',
	tomorrow: 'Tomorrow',
	yesterday: 'Yesterday',
	nextWeek: 'Next week',
}
