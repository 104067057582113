import React, { useContext, useMemo } from 'react'

import {
	FormModalContext,
	IFormModalContext,
} from '../../../../../../components/forms/formModal/FormModalContext'
import { Box } from '@mui/material'
import { Picture } from '../../../../../../components'
import SelectVehiclesModalBtn from './selectVehiclesModal/SelectVehiclesModalBtn'
import { useModal } from '../../../../../../utils/hooks'
import SelectedVehiclesModal from './selectVehiclesModal'
import SelectedVehiclesList from './selectedVehiclesList'
import Alert from '@mui/material/Alert'
import { useFormatMessage } from '../../../../../../utils/hooks'

interface IVehiclesAndServicesContent {}

const createBoxSx = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	height: '30vh',
	flexGrow: '1',

	'& >*:first-of-type': {
		marginBottom: '1rem',
	},
}

const vehiclesAndServicesContentSx = {
	marginTop: '2rem',
	flexGrow: '1',
	display: 'flex',
	flexDirection: 'column',
	'& > .css-1mexmm9': {
		flexGrow: '1',
		display: 'flex',
		flexDirection: 'column',
	},

	'& .css-1y6t3hn': {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
	},

	'& .css-1bu0egv': {
		padding: '0px !important',
	},
}
const VehiclesAndServicesContent: React.FC<
	IVehiclesAndServicesContent
> = () => {
	const { data } = useContext<IFormModalContext>(FormModalContext)
	const { isVisible, openModal, closeModal } = useModal()
	const intlMsg = useFormatMessage()

	const selectedVehicles = useMemo(
		() => data?.selectedVehicles || [],
		[data?.selectedVehicles]
	)

	return (
		<>
			<Box sx={vehiclesAndServicesContentSx}>
				{selectedVehicles?.length < 1 ? (
					<>
						<Alert severity='info'>
							{intlMsg('misc.addVehiculesToIntervention')}
						</Alert>
						<Box sx={createBoxSx}>
							<Picture width={130} src='/img/Ajout_vehicule.svg' />
							<SelectVehiclesModalBtn openModal={openModal} />
						</Box>
					</>
				) : (
					<SelectedVehiclesList openModal={openModal} />
				)}
			</Box>
			{isVisible && (
				<SelectedVehiclesModal isVisible={isVisible} closeModal={closeModal} />
			)}
		</>
	)
}

export default VehiclesAndServicesContent
