/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import OpenLinkCell from '../../../../../../../../components/tableV2/tableCell/OpenLinkCell'
import TimeCell from '../../../../../../../../components/tableV2/tableCell/TimeCell'
import { formatDate } from '../../../../../../../../utils/date'
import { ROUTES } from '../../../../../../../../routes/consts'

const selectVehiclesModalContentTableListConfig = [
	{
		columnLabel: 'vehicle.immat',
		orderBy: 'vehicleDetail_immat',
		dataKey: 'vehicleDetail.immat',
	},
	{
		columnLabel: 'vehicle.vin',
		orderBy: 'vehicleDetail_vin',
		personalizedCell: ({ id, vehicleDetail }) => (
			<>
				{vehicleDetail?.vin ? (
					<OpenLinkCell
						txt={vehicleDetail.vin}
						viewModalLink={`${ROUTES.VIEWMODAL.VEHICLES.url}${id}`}
					/>
				) : (
					'-'
				)}
			</>
		),
	},
	{
		columnLabel: 'vehicle.brand',
		orderBy: 'vehicleDetail_vehicleBrand_name',
		personalizedCell: ({ vehicleDetail }) =>
			vehicleDetail?.vehicleBrand?.name || '-',
	},
	{
		columnLabel: 'vehicle.model',
		orderBy: 'vehicleDetail_vehicleBrand_name',
		personalizedCell: ({ vehicleDetail }) =>
			vehicleDetail?.vehicleModelText || '-',
	},
	{
		columnLabel: 'vehicle.category',
		orderBy: 'vehicleDetail_vehicleCategory_name',
		dataKey: 'vehicle.vehicleDetail.vehicleCategory.name',
	},
	{
		columnLabel: 'statistics.lastIntervention',
		orderBy: 'programmedInterventions_completionDate',
		personalizedCell: ({ previousInterventionProgrammedVehicle }) =>
			previousInterventionProgrammedVehicle?.completionDate
				? formatDate(
						previousInterventionProgrammedVehicle?.completionDate,
						true
				  )
				: '-',
	},
	{
		columnLabel: 'misc.createdAt',
		orderBy: 'dateCreated',
		personalizedCell: ({ dateCreated }) => (
			<TimeCell date={dateCreated ? formatDate(dateCreated) : '-'} />
		),
	},
]

export default selectVehiclesModalContentTableListConfig
