import React, { useRef, useMemo, useState, useCallback } from 'react'

import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'

import { useFormatMessage } from '../../../../utils/hooks'
import CircularProgress from '@mui/material/CircularProgress'
import { Box, debounce, IconButton, InputLabel } from '@mui/material'

const CircularProgressCSS = {
	width: '25px',
	height: '20px',
	marginLeft: '0.5rem',
}

const inputSearchCSS = {
	display: 'inline-flex',
	alignItems: 'center',
	fontSize: ({ typography }) => typography.subtitle2.fontSize,
	p: 1,
	borderRadius: 1,
	fontWeight: ({ typography }) => typography.fontWeightRegular as any,
	background: ({ palette }) => palette.grey['100'],
	color: ({ palette }) => palette.grey['700'],
	height: '36.5px',
}

const InputLabelCSS = { position: 'absolute', zIndex: 1, top: '9px', right: 14 }
interface iSearchField {
	onChange
	placeholder?: string
	fullWidth?
	loading?: Boolean
	minCharacters?: number
	resetSearch?
}

const SearchField: React.FC<iSearchField> = ({
	onChange,
	placeholder,
	fullWidth,
	loading = false,
	minCharacters = 0,
	resetSearch,
}) => {
	const intlMsg = useFormatMessage()
	const valueRef: any = minCharacters ? useRef('') : null
	const [errorCharacters, setErrorCharacters] = useState(false)

	const checkMinCharacters = useMemo(() => {
		return debounce((nbCharacters) => {
			setErrorCharacters(nbCharacters < minCharacters && nbCharacters !== 0)
		}, 1000)
	}, [])

	const onChangeInput = useCallback((e) => {
		onChange(e)
		minCharacters && checkMinCharacters(valueRef?.current?.value?.length)
	}, [])

	const onResetSearch = useCallback(() => {
		resetSearch && resetSearch()
		valueRef.current.value = ''
	}, [valueRef])

	return (
		<Box sx={{ position: 'relative' }}>
			{errorCharacters && (
				<InputLabel sx={InputLabelCSS} color='error' error>
					{intlMsg('errors.minCharacters', { minCharacters })}
				</InputLabel>
			)}
			<InputBase
				sx={inputSearchCSS}
				placeholder={
					placeholder
						? !errorCharacters
							? intlMsg(placeholder)
							: ''
						: !errorCharacters
						? intlMsg('search.default')
						: ''
				}
				onChange={onChangeInput}
				startAdornment={<SearchIcon sx={{ mr: 1 }} />}
				endAdornment={
					loading ? (
						<CircularProgress style={CircularProgressCSS} />
					) : resetSearch &&
					  !errorCharacters &&
					  !loading &&
					  valueRef.current.value ? (
						<IconButton
							aria-label='upload picture'
							component='label'
							size='small'
							onClick={onResetSearch}
						>
							<ClearIcon fontSize='small' />
						</IconButton>
					) : (
						<div style={CircularProgressCSS} />
					)
				}
				fullWidth={fullWidth || false}
				error={true}
				{...(minCharacters && { inputRef: valueRef })}
			/>
		</Box>
	)
}

export default SearchField
