import React, { memo, useCallback, useState, useContext } from 'react'

import toast from 'react-hot-toast'

// import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton/IconButton'
import { Stack } from '@mui/material'

import IndicatorRows from './indicatorRows'

import PermissionsHandler from '../../permissionsHandler'
import { useModal } from '../../../utils/hooks'
import ConfirmModal from '../../modal/ConfirmModal'
import { TableContext } from '../../../utils/contexts/TableContext'

interface ITableToolBarAction {
	refreshQuerie?
	deletedBulk?: any
	hidden: any
}

const TableToolBarAction: React.FC<ITableToolBarAction> = ({
	refreshQuerie,
	deletedBulk,
	hidden,
}) => {
	const { setSelectedRow, selectedRow, setPage, page, visibleRows } =
		useContext(TableContext) || {}
	const [bulkAction] = !hidden?.deleteBulk ? deletedBulk?.mutation() || [] : []

	const deleted = useCallback(
		async (rows) => {
			await bulkAction({
				variables: {
					input: {
						IDs: rows?.map(({ id }) => id),
						operation: deletedBulk.operation,
					},
				},
				refetchQueries: refreshQuerie,
			}).then(() => {
				toast.success(
					rows?.length === 1
						? deletedBulk?.successMessageDelete?.singular
						: deletedBulk?.successMessageDelete?.plural
				)
			})

			if (
				page > 1 &&
				(visibleRows?.length === 1 ||
					visibleRows?.length === selectedRow?.length)
			) {
				setPage(page - 1)
			}

			setSelectedRow([])
		},
		[selectedRow, setSelectedRow, page, visibleRows]
	)

	const handleDelete = useCallback((rows) => {
		deleted(rows), closeModalWarning()
	}, [])

	////////////////////////////////////////////
	// Warning Modal
	////////////////////////////////////////////

	const {
		isVisible: isVisibleWarning,
		openModal: openModalWarning,
		closeModal: closeModalWarning,
	} = useModal()

	////////////////////////////////////////////
	// Open/close popover
	////////////////////////////////////////////

	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
	const handleClose = useCallback(() => setAnchorEl(null), [anchorEl])

	const validateModalWarning = useCallback(() => {
		handleClose()
		closeModalWarning()
	}, [])

	return (
		<>
			<Stack direction='row' justifyContent='space-between' alignItems='center'>
				<IndicatorRows
					selectedRow={selectedRow}
					setSelectedRow={setSelectedRow}
				/>

				<Stack direction='row' spacing={1}>
					{/* 
					// TODO export bulk action
					<IconButton size='large'>
						<CloudDownloadIcon />
					</IconButton> */}

					{bulkAction && (
						<PermissionsHandler
							permissionsName={deletedBulk?.deletePermissions}
						>
							<IconButton
								onClick={openModalWarning}
								size='large'
								sx={{ padding: '3px' }}
							>
								<DeleteIcon />
							</IconButton>
						</PermissionsHandler>
					)}
				</Stack>
			</Stack>

			{deletedBulk?.messageConfirm && (
				<ConfirmModal
					onClose={validateModalWarning}
					open={isVisibleWarning}
					handleConfirm={() => handleDelete(selectedRow)}
					img={
						deletedBulk.messageConfirm.img ||
						'/img/illustration/suppression.png'
					}
					message={{
						title: deletedBulk.messageConfirm.title,
						message: deletedBulk.messageConfirm.message,
					}}
				/>
			)}
		</>
	)
}

export default memo(TableToolBarAction)
