import React from 'react'
import { Rating } from '@mui/material'

interface IDataListNotation {
	note: string
}

const DataListNotation: React.FC<IDataListNotation> = ({ note }) => {
	return <Rating value={parseFloat(note)} precision={0.5} readOnly />
}

export default DataListNotation
