/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
	gql,
	OperationVariables,
	QueryHookOptions,
	useQuery,
} from '@apollo/client'

import { listNetworks } from '../attributes/networksAttributes'

export const LIST_NETWORKS = gql`
query getNetworks(
    $page: Int
    $itemsPerPage: Int
	$searchText: String
    $name: String
    $isActive: Boolean

) {
    networks(page: $page, itemsPerPage: $itemsPerPage, searchText: $searchText,name: $name,isActive: $isActive) {
        ${listNetworks} 
    }
}`

export const NETWORKS_FOR_SELECT_SITE = gql`
	query getNetworksSelectSites($isActive: Boolean) {
		networks(isActive: $isActive) {
			collection {
				id
				name
				agencies(itemsPerPage: 100000) {
					collection {
						id
						agencyCustomDetails {
							id
							agencyName
						}
					}
				}
			}
		}
	}
`

/**  ---------------------HOOKS---------------------- */

export const useListAgenciesNetworksForSite = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
): any => {
	const { loading, data } = useQuery(NETWORKS_FOR_SELECT_SITE, options)

	const formatAgenciesNetworks = [] as any[]

	data?.networks?.collection?.forEach(
		({ agencies: { collection: agenciesArr }, name: networkName }) => {
			agenciesArr?.forEach((agencie) => {
				formatAgenciesNetworks.push({
					key: agencie?.id,
					label: agencie?.agencyCustomDetails[0]?.agencyName,
					value: {
						...agencie,
						networkName,
					},
				})
			})
		}
	)

	return {
		loadingAgenciesNetworks: loading,
		agenciesNetworks: formatAgenciesNetworks,
	}
}
