import React, { useContext, useCallback } from 'react'

import { useNavigate } from 'react-router-dom'

import {
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Popover,
	Link,
	Box,
} from '@mui/material'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import WorkOutlineIcon from '@mui/icons-material/WorkOutline'
import PeopleIcon from '@mui/icons-material/People'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import AppsIcon from '@mui/icons-material/Apps'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'

import { AuthContext } from '../../../utils/contexts/AuthContext'
import { ROUTES } from '../../../routes/consts'
import { useFormatMessage } from '../../../utils/hooks'
import { maxStringDisplay } from '../../../utils/string'
import PermissionsHandler from '../../permissionsHandler'
import { EModule, EPermissionScope } from '../../../utils/app-models'

const ListItemTextHeaderCSS = {
	fontSize: '10px',
	'& .MuiListItemText-primary': { fontSize: '13px' },
	'& .MuiListItemText-secondary': {
		fontSize: '10px',
		color: 'white',
		textTransform: 'uppercase',
	},
}

const mainCSS = {
	width: 1,
	backgroundColor: ({ palette }) => palette.background.paper,
	top: `68px!important`,
	right: `15px!important`,
}

const menuCSS = {
	top: `68px!important`,
	right: `15px!important`,
	left: 'initial!important',
	border: 'solid 1px #dddddd59',
	boxShadow: '#ddd 3px 2px 5px 0px',
}

const ListItemHeaderCSS = { backgroundColor: '#132142', color: 'white' }

const ListItemIconCSS = { minWidth: '40px' }

interface INavTopProfileMenu {
	handleOpenProfileNav
	anchorElProfile
	displayedId
	emailUser
}

const NavTopProfileMenu: React.FC<INavTopProfileMenu> = ({
	handleOpenProfileNav,
	anchorElProfile,
	displayedId,
	emailUser,
}) => {
	const intlMsg = useFormatMessage()

	// Logout
	const { resetContext } = useContext(AuthContext)
	const logout = useCallback(() => resetContext(), [])

	// Nav
	const navigate = useNavigate()
	const handleClickMenu = useCallback(
		(newPath, handleOpenClose) => (e) => {
			handleOpenClose()
			e.preventDefault()
			e.ctrlKey ? window.open(newPath, '_blank') : navigate(newPath)
		},
		[navigate]
	)

	return (
		<Popover
			anchorEl={anchorElProfile}
			keepMounted
			open={Boolean(anchorElProfile)}
			onClose={handleOpenProfileNav}
			PaperProps={{ sx: menuCSS }}
		>
			<Box sx={mainCSS}>
				<List component='nav' sx={{ p: 0 }}>
					<ListItem sx={ListItemHeaderCSS}>
						<ListItemIcon sx={ListItemIconCSS}>
							<AccountCircleIcon sx={{ color: 'white' }} />
						</ListItemIcon>
						<ListItemText
							sx={ListItemTextHeaderCSS}
							primary={maxStringDisplay(emailUser, 30)}
							secondary={displayedId}
						/>
					</ListItem>

					<ListItem
						button
						onClick={handleClickMenu(
							ROUTES.ADMINISTRATION.MY_PROFILE.url,
							handleOpenProfileNav
						)}
					>
						<ListItemIcon sx={ListItemIconCSS}>
							<PersonOutlineIcon />
						</ListItemIcon>
						<ListItemText primary={intlMsg('navigation.myProfile')} />
					</ListItem>
					<PermissionsHandler
						permissionsName={[
							EPermissionScope.formTemplateManage,
							EPermissionScope.productManage,
						]}
						modulesName={[EModule.formTemplate, EModule.productCatalog]}
					>
						<ListItem
							button
							onClick={handleClickMenu(
								ROUTES.ADMINISTRATION.FORM_TEMPLATES.url,
								handleOpenProfileNav
							)}
						>
							<ListItemIcon sx={ListItemIconCSS}>
								<WorkOutlineIcon />
							</ListItemIcon>
							<ListItemText primary={intlMsg('navigation.myJob')} />
						</ListItem>
					</PermissionsHandler>

					<PermissionsHandler permissionsName={[EPermissionScope.userManage]}>
						<ListItem
							button
							onClick={handleClickMenu(
								ROUTES.ADMINISTRATION.USERS.url,
								handleOpenProfileNav
							)}
						>
							<ListItemIcon sx={ListItemIconCSS}>
								<PeopleIcon />
							</ListItemIcon>
							<ListItemText primary={intlMsg('navigation.users')} />
						</ListItem>
					</PermissionsHandler>
				</List>
				<Divider />
				<List component='nav' sx={{ p: 0 }}>
					<ListItem button>
						<Link
							sx={{ display: 'flex' }}
							rel='noreferrer'
							target='_blank'
							color='default'
							underline='none'
							href='https://hubeecar.gitbook.io/guides-utilisateur/'
						>
							<ListItemIcon sx={ListItemIconCSS}>
								<HelpOutlineIcon />
							</ListItemIcon>
							<ListItemText primary={intlMsg('navigation.helpApp')} />
						</Link>
					</ListItem>
					<ListItem
						button
						onClick={handleClickMenu(ROUTES.APP.url, handleOpenProfileNav)}
					>
						<ListItemIcon sx={ListItemIconCSS}>
							<AppsIcon />
						</ListItemIcon>
						<ListItemText primary={intlMsg('navigation.apps')} />
					</ListItem>
				</List>
				<Divider />
				<List component='nav' sx={{ p: 0 }}>
					<ListItem button onClick={logout}>
						<ListItemIcon sx={ListItemIconCSS}>
							<PowerSettingsNewIcon />
						</ListItemIcon>
						<ListItemText primary={intlMsg('navigation.logout')} />
					</ListItem>
				</List>
			</Box>
		</Popover>
	)
}

export default NavTopProfileMenu
