import React from 'react'

import Table from '../../../../../components/tableV2'
import {
	IOverrideConfigTable,
	IOverrideConfigTableWithArgument,
	IOverrideTableProvider,
} from '../../../../../utils/typescript/tableType'
import { interventionByRefConfigTable } from './interventionByRefConfigTable'

interface IInterventionsByReference {
	othersQueryParams?
	overrideConfigTable?: IOverrideConfigTable | IOverrideConfigTableWithArgument
	overrideTableProvider?: IOverrideTableProvider
	isInViewModal?: boolean
}

const InterventionsByReference: React.FC<IInterventionsByReference> = ({
	othersQueryParams,
	overrideConfigTable,
	overrideTableProvider,
	isInViewModal = false,
}) => {
	return (
		<Table
			tableConfig={interventionByRefConfigTable}
			overrideConfigTable={overrideConfigTable}
			othersQueryParams={othersQueryParams}
			overrideTableProvider={overrideTableProvider}
			isInViewModal={isInViewModal}
		/>
	)
}

export default InterventionsByReference
