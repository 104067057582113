import React, { cloneElement, useCallback } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { Box, IconButton } from '@mui/material'
import { EFormModalMode } from '../../../../utils/app-models'
import { IModalUtils, useModal } from '../../../../utils/hooks'

export const editBtnCellCSS = {
	height: '48px',
	width: '100%',
	borderLeft: 'solid #f0f0f0 1px',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	paddingRight: '5px!important',
	paddingLeft: '5px!important',
	background: 'white',
	zIndex: 100,
}

interface IEditModalButton {
	modal
}

const EditModalButton: React.FC<IEditModalButton> = ({ modal }) => {
	const modalUtils: IModalUtils = useModal(false)

	const handleOpenClick = useCallback(() => {
		modalUtils?.openModal()
	}, [modalUtils])

	return (
		<Box sx={editBtnCellCSS}>
			<IconButton
				aria-label='edit'
				component='span'
				size='small'
				onClick={handleOpenClick}
			>
				<EditIcon fontSize='small' />
			</IconButton>
			{modalUtils?.isVisible &&
				cloneElement(modal, { modalUtils, mode: EFormModalMode.edit })}
		</Box>
	)
}
export default EditModalButton
