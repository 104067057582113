/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react'
import { EFormModalMode } from '../../../utils/app-models'

export type IStepperContext = {
	isVisible: boolean
	openModal?
	loading: boolean
	closeModal?
	resetContext?
	setLoading?
	mode: EFormModalMode
	setMode?
	data: any
	stepperConfig?
	setCurrentStep?
	currentStep: number
	goNext?
	goPrevious?
	setValue?
	setValues?
	triggerError?
	canGoNext
	setCanGoNext?
	errors
	setErrors?
	startingData
	setStartingData?
	stepErrors
	formHaveError
	setFormHaveError?
	triggerAllErrors?
	thisStepHaveErrors?
}

export const StepperContext: React.Context<IStepperContext> = createContext(
	{} as IStepperContext
)
