/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'

import ActionsBtn, { actionBtnCellCSS } from '../../../tableCell/actionsBtn/v1'
import {
	useRemoveFormTemplate,
	useDuplicateFormTemplate,
	useUpdateStatusFormTemplate,
} from '../../../../../api/gql/mutations/formTemplateMutation'
import { ROUTES } from '../../../../../routes/consts'
import TimeCell from '../../../../../components/tableV2/tableCell/TimeCell'
import { formatDate } from '../../../../../utils/date'
import OpenLinkCell from '../../../../../components/tableV2/tableCell/OpenLinkCell'
import { EPermissionScope, EPermission } from '../../../../../utils/app-models'
import { Box } from '@mui/material'
import BooleanColorPopover from '../../../../popover/BooleanColorPopover'
import translateIntlMsgString from '../../../../../utils/translateIntlMsgString'
import UserAvatarSlidePanel from '../../../../avatar/UserAvatarSlidePanel'

const listFormTemplateViewTable = [
	{
		columnLabel: 'misc.generalAccount',
		permissions: [EPermission.superMegaAdmin],
		personalizedCell: ({ account }) => <Box>{account?.name}</Box>,
	},

	{
		columnLabel: 'misc.name',
		orderBy: 'name',
		stickyFirstCol: true,
		personalizedCell: (row: any) => (
			<OpenLinkCell
				viewModalLink={`${ROUTES.ADMINISTRATION.FORM_TEMPLATE_EDITION.url}?id=${row.id}`}
				txt={row?.name}
				maxCharacters={45}
				isSharedWithMe={row?.isSharedWithMe}
				sharedBy={row?.account?.name}
				notClickable={row?.isSharedWithMe}
			/>
		),
	},
	{
		columnLabel: 'formTemplate.title',
		orderBy: 'title',
		personalizedCell: (row: any) => (
			<OpenLinkCell
				viewModalLink={`${ROUTES.ADMINISTRATION.FORM_TEMPLATE_EDITION.url}?id=${row.id}`}
				txt={row?.title}
				maxCharacters={45}
				notClickable={row?.isSharedWithMe}
			/>
		),
	},

	{
		columnLabel: 'formTemplate.account',
		orderBy: 'customer_name',
		personalizedCell: ({ customer }: any) => (
			<>
				{!customer?.name ? (
					translateIntlMsgString(`misc.allAccounts`)
				) : (
					<OpenLinkCell
						viewModalLink={`${ROUTES.VIEWMODAL.CUSTOMERS.url}${customer.id}`}
						txt={customer.name}
						maxCharacters={45}
					/>
				)}
			</>
		),
	},

	{
		columnLabel: 'formTemplate.status',
		orderBy: 'isActive',
		personalizedCell: (row: any) => {
			return (
				<BooleanColorPopover
					value={row.isActive}
					id={row.id}
					txt={{ true: 'formTemplate.published', false: 'formTemplate.draft' }}
					titlePopover={'formTemplate.status'}
					queryConfig={{
						query: useUpdateStatusFormTemplate,
						refreshQuery: ['getFormTemplateFilterList'],
						successMessage: 'success.statusUpdate',
					}}
				/>
			)
		},
	},

	{
		columnLabel: 'misc.createdAt',
		orderBy: 'dateCreated',
		personalizedCell: (row) => (
			<TimeCell date={row?.dateCreated ? formatDate(row.dateCreated) : '-'} />
		),
	},

	{
		columnLabel: 'misc.createdBy',
		orderBy: 'createdBy_lastName',
		personalizedCell: (row: any) => (
			<UserAvatarSlidePanel
				firstName={row.createdBy?.firstName}
				lastName={row.createdBy?.lastName}
				userId={row.createdBy?.id}
				displayName
			/>
		),
	},
	{
		fixWidth: '50px',
		visible: true,
		columnLabel: '',
		tableCellAlign: 'center',
		cellAlign: 'center',
		sticky: true,
		personalizedCell: (row: any) => (
			<>
				{row?.isSharedWithMe ? (
					<Box sx={actionBtnCellCSS} />
				) : (
					<ActionsBtn
						rowId={row?.id}
						deleted={{
							query: useRemoveFormTemplate,
							refreshQuery: ['getFormTemplateFilterList'],
							successMessageDelete: 'success.formTemplateDeleted',
							messageConfirm: {
								title: 'confirmModal.deleteFormTemplateTitle',
								message: 'confirmModal.deleteFormTemplateMessage',
							},
						}}
						duplicate={{
							mutation: useDuplicateFormTemplate,
							refreshQuery: ['getFormTemplateFilterList'],
							redirect: {
								path: `${ROUTES.ADMINISTRATION.FORM_TEMPLATE_EDITION.url}?id=`,
								selectorResponse: 'duplicateFormTemplate.formTemplate.id',
							},
						}}
						editPath={`${ROUTES.ADMINISTRATION.FORM_TEMPLATE_EDITION.url}?id=${row.id}`}
						viewAction={false}
						permissions={{
							update: [EPermissionScope.formTemplateManage],
							remove: [EPermissionScope.formTemplateManage],
							delete: [EPermissionScope.formTemplateManage],
						}}
					/>
				)}
			</>
		),
	},
]

export default listFormTemplateViewTable
