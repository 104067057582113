export default {
	alertName: 'Nom alerte',
	alertCategory: 'Catégorie',
	alertOpen: 'Ouvert le',
	alertClose: 'Fermé le',
	treatedOn: 'Traitée le',
	alertSource: 'Source',
	LEGAL: 'Légal',
	OTHER: 'Autre',
	AESTHETIC: 'Esthétique',
	SECURITY: 'Sécurité',
	manualAdd: 'Création manuelle',
	categoryAlert: 'Catégorie d’alerte',
}
