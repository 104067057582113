/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const textFieldPDF = (
	doc,
	record,
	position,
	additionalValueRowCell?: any
) => {
	const {
		positionXtextRecord,
		positionYtextRecord,
		widthCell,
		widthTitleRowCell,
	} = position

	const Xtext = additionalValueRowCell?.url
		? positionXtextRecord + additionalValueRowCell?.w + 1.5
		: positionXtextRecord

	const Ytext = additionalValueRowCell?.url
		? positionYtextRecord
		: positionYtextRecord

	const Ximg = positionXtextRecord

	const Yimg = positionYtextRecord - additionalValueRowCell?.h / 2

	// addImage(imageData, format, x, y, width, height, alias, compression, rotation)
	additionalValueRowCell?.url &&
		doc.addImage(
			additionalValueRowCell?.url,
			'PNG',
			Ximg,
			Yimg,
			additionalValueRowCell?.w,
			additionalValueRowCell?.h
		)

	additionalValueRowCell?.textBold && doc.setFont('helvetica', 'bold')

	doc.setTextColor('black')
	doc.text(record || '', Xtext, Ytext, {
		maxWidth: widthCell - widthTitleRowCell,
		baseline: 'middle',
	})
}
