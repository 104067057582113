import React, { memo } from 'react'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

const btnCSS = {
	width: '100%',
	padding: '5px 10px',
	textTransform: 'inherit',
	boxShadow: 'none',
	borderRadius: 1,
}

interface IIconBtnProps {
	icon: React.ReactNode
	variant?: 'contained' | 'outlined' | 'text'
	color?:
		| 'inherit'
		| 'primary'
		| 'secondary'
		| 'success'
		| 'error'
		| 'info'
		| 'warning'
	onClick?: () => void
	disabled?: boolean
	loading?: boolean
	sx?: object
	dataTestId?: string
}

const IconBtn: React.FC<IIconBtnProps> = ({
	icon,
	variant = 'contained',
	color = 'primary',
	onClick,
	disabled = false,
	loading = false,
	sx = {},
	dataTestId = '',
}) => {
	return (
		<Button
			data-test-id={dataTestId}
			variant={variant}
			color={color}
			onClick={onClick}
			disabled={disabled || loading}
			sx={{ ...btnCSS, ...sx }}
		>
			{loading ? (
				<CircularProgress size={25} style={{ color: 'white' }} />
			) : (
				icon
			)}
		</Button>
	)
}

export default memo(IconBtn)
