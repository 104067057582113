import React from 'react'

import { Stack } from '@mui/material'
import UserAvatar from '../avatar/UserAvatar'

interface IUserAvatarOptionLabel {
	value: any
}

const UserAvatarOptionLabel: React.FC<IUserAvatarOptionLabel> = ({ value }) => {
	return (
		<Stack direction='row' alignItems='center'>
			<UserAvatar
				userName={{
					firstName: value?.firstName,
					lastName: value?.lastName,
				}}
				sx={{ mr: 1 }}
			/>

			{`${value?.firstName} ${value?.lastName}`}
		</Stack>
	)
}

export default UserAvatarOptionLabel
