import React, { useCallback, useContext, useMemo } from 'react'

import { useFormatMessage } from '../../../../../../../utils/hooks'
import FormMultiSelectSearch from '../../../../../../../components/forms/formFields/formMultiSelectSearch'
import {
	FormModalContext,
	IFormModalContext,
} from '../../../../../../../components/forms/formModal/FormModalContext'
import { useFormTemplates } from '../../../../../../../api/gql/queries/formTemplateQuery'

interface IFormTemplatesColumn {
	vehicleId: string
}

const FormTemplatesColumn: React.FC<IFormTemplatesColumn> = ({ vehicleId }) => {
	const intlMsg = useFormatMessage()
	const { setValues, data } = useContext<IFormModalContext>(FormModalContext)
	const { formTemplates } = useFormTemplates({
		fetchPolicy: 'cache-only', //cache-only because we already have query the list for global inputs
		variables: {
			page: 1,
			itemsPerPage: 100000, //todo change itemsPerPage
			isActive: true,
			isDeleted: false,
			customer:
				data?.finalCustomer?.value?.id ||
				data?.orderingCustomer?.value?.id ||
				'',
		},
	})

	const selectedVehicles = useMemo(
		() => data?.selectedVehicles || [],
		[data?.selectedVehicles]
	)

	const formTemplatesOptions = useMemo(
		() =>
			formTemplates?.map((formTemplate) => ({
				key: formTemplate?.id,
				label: formTemplate?.name,
				value: formTemplate,
			})),
		[formTemplates]
	)

	const handleSelectFormtemplates = useCallback(
		(fieldName, value) => {
			const selectedVehicleId = fieldName?.replace('formTemplates-', '')
			const selectedVehicleIndex = selectedVehicles?.findIndex(
				({ id }) => selectedVehicleId === id
			)
			selectedVehicles[selectedVehicleIndex].formTemplates = Object.values(
				value
			)
				.filter(({ checked }: any) => checked)
				.map(({ value }: any) => value?.id)
			setValues({
				//set new formTemplates for the field link to FormMultiSelectSearch
				[fieldName]: value,
				//set new formTemplates in selectedVehicles
				selectedVehicles,
			})
		},
		[setValues, selectedVehicles]
	)

	return (
		<FormMultiSelectSearch
			width='400px'
			name={`formTemplates-${vehicleId}`}
			options={formTemplatesOptions}
			context={FormModalContext}
			label={intlMsg('interventions.selectReport')}
			selectAllText={intlMsg('misc.selectAll')}
			specialOnChange={handleSelectFormtemplates}
			bordered
			nbChipsToDisplay={3}
		/>
	)
}
export default FormTemplatesColumn
