import React, { useCallback } from 'react'

import { Droppable, Draggable } from 'react-beautiful-dnd'
import { Alert, Box, Stack } from '@mui/material'

import { getPageFieldConfig } from './getPageFieldConfig'
import { useFormatMessage } from '../../../../../utils/hooks'

const getItemStyle = (isDragging) => ({
	userSelect: 'none',
	backgroundColor: isDragging ? '#f1f1f1' : '#fafafa',
	borderRadius: '7px',
})
const getListStyle = (isDraggingOver) => ({
	border: isDraggingOver ? '2px dashed rgb(233 233 233)' : '2px dashed white',
	minHeight: '500px',
})

interface IPageFieldList {
	pageFields
	removeField
	updateField
	insertField
}

const PageFieldList: React.FC<IPageFieldList> = ({
	pageFields,
	removeField,
	updateField,
	insertField,
}) => {
	const intlMsg = useFormatMessage()
	const deleteField = useCallback(
		(indexToDelete) => removeField(indexToDelete),
		[]
	)

	return (
		<Droppable droppableId={'fieldItems'}>
			{(provided, snapshot) => (
				<Box
					ref={provided.innerRef}
					{...provided.droppableProps}
					style={getListStyle(snapshot.isDraggingOver)}
				>
					{pageFields?.length ? (
						pageFields.map((field, i) => (
							<Draggable key={field.id} draggableId={field.id} index={i}>
								{(provided, snapshot) => (
									<Box
										ref={provided.innerRef}
										{...provided.draggableProps}
										{...provided.dragHandleProps}
										data-field={i}
										sx={{ mb: 2 }}
									>
										<Box style={getItemStyle(snapshot.isDragging) as any}>
											{getPageFieldConfig(
												field,
												deleteField,
												i,
												updateField,
												insertField
											)}
										</Box>
									</Box>
								)}
							</Draggable>
						))
					) : (
						<Stack alignItems='center' justifyContent='center'>
							<Alert severity='info' sx={{ mb: 8, width: 1 }}>
								{intlMsg('formTemplate.emptyPageForm')}
							</Alert>
							<img
								src='/img/form-template/emptyPage.svg'
								style={{ maxWidth: '100%' }}
							/>
						</Stack>
					)}

					{provided.placeholder}
				</Box>
			)}
		</Droppable>
	)
}

export default PageFieldList
