export default {
	report: 'Formulaire',
	reports: 'Formulaires',
	model: 'Modèle de formulaire',
	typeForm: 'Type de formulaire',
	horodatage: 'Horodatage',
	addBy: 'Rempli par',
	footer: 'Pied de page',
	displayAnnotations: 'Afficher les cerclages',
	spending: 'Dépenses',

	adviceCustomer: 'Avis client',
	commentIntervenant: 'Commentaire intervenant',
	signingIntervenant: 'Signature intervenant',
	commentCustomer: 'Commentaire client',
	signingCustomer: 'Signature client',
	noDefaults: "Aucun défaut n'a été détecté",

	consultForm: 'Consulter le formulaire',
	interventionNotFinishYet: "Status de l'intervention : ",
	fromOldRef: "Ce formulaire est issu d'une ancienne version. Vous pouvez le ",
	fromOldRefHere: 'visualiser ici.',
}
