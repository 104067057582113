/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { gql, useMutation } from '@apollo/client'

export const REMOVE_ALERT = gql`
	mutation removeAlert($input: removeAlertInput!) {
		removeAlert(input: $input) {
			alert {
				id
			}
		}
	}
`

export const DELETE_ALERT = gql`
	mutation deleteAlert($input: deleteAlertInput!) {
		deleteAlert(input: $input) {
			alert {
				id
			}
		}
	}
`

export const UPDATE_ALERT_STATUSES = gql`
	mutation updateStatusAlert($input: updateStatusAlertInput!) {
		updateStatusAlert(input: $input) {
			alert {
				id
			}
		}
	}
`

export const BULK_ALERT_ACTION = gql`
	mutation bulkOperationAlert($input: bulkOperationAlertInput!) {
		bulkOperationAlert(input: $input) {
			alert {
				id
			}
		}
	}
`

/**  ---------------------HOOKS---------------------- */

export const useRemoveAlert = () => useMutation(REMOVE_ALERT)
export const useDeleteAlert = () => useMutation(DELETE_ALERT)
export const useBulkAlertAction = () => useMutation(BULK_ALERT_ACTION)

export const useUpdateAlertStatuses = (): any =>
	useMutation(UPDATE_ALERT_STATUSES)
