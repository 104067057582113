import React from 'react'

import Popover from '@mui/material/Popover'
import List from '@mui/material/List'
import Radio from '@mui/material/Radio'
import ListItem from '@mui/material/ListItem'
import { ListItemText } from '@mui/material'
import { stringifiedColor } from '../../../../utils/color'

const buttonCSS = {
	padding: ({ spacing }) => spacing(0, 3),
	'&.Mui-selected': {
		background: stringifiedColor('grey', 100) as any,
	},
}

interface ICalendarDisplayByPopover {
	selectElements
	handleClose
	open
	anchorEl
	onChange
	currentView
}

const CalendarDisplayByPopover: React.FC<ICalendarDisplayByPopover> = ({
	selectElements,
	handleClose,
	open,
	anchorEl,
	onChange,
	currentView,
}) => {
	return (
		<Popover
			open={open}
			anchorEl={anchorEl}
			onClose={handleClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
		>
			<List>
				{selectElements?.map(({ label, value }) => {
					return (
						<ListItem
							key={label}
							button
							sx={buttonCSS}
							onClick={onChange(handleClose)}
							selected={currentView === value}
						>
							<ListItemText primary={label} />

							<Radio
								edge='end'
								size='small'
								color={'primary'}
								checked={currentView === value}
								value={value}
							/>
						</ListItem>
					)
				})}
			</List>
		</Popover>
	)
}

export default CalendarDisplayByPopover
