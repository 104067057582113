import React from 'react'
import { Box, Typography, Stack } from '@mui/material'

import { useFormatMessage } from '../../../../../../utils/hooks'
import FormTextField from '../../../../../../components/forms/formFields/FormTextField'
import { FormModalContext } from '../../../../../../components/forms/formModal/FormModalContext'
import CustomIconTooltips from '../../../../../../components/tooltips'

interface IInterventionsInstruction {}

const InterventionsInstruction: React.FC<IInterventionsInstruction> = () => {
	const intlMsg = useFormatMessage()

	return (
		<Box>
			<Stack direction='row' alignItems='center' mb={2}>
				<Typography variant='h2'>
					{intlMsg('interventions.internalComment')}
				</Typography>
				<CustomIconTooltips
					label='interventions.internalCommentInfo'
					type='help'
				/>
			</Stack>

			<FormTextField
				name='privateComment'
				label={intlMsg('interventions.comments')}
				context={FormModalContext}
				multiline={true}
				minRows={8}
				maxRows={8}
			/>
		</Box>
	)
}
export default InterventionsInstruction
