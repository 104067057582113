export const listinterventionForms = `
paginationInfo {
  totalCount
}
collection {
  id
  oldVersionReference
  reference
  dateCreated
  formTemplate {
    id
    name
    title
  }
  customer {
    id
    name
  }
  site {
    id
    name
  }
  createdByAccount {
    id
    name
  }
  vehicle {
    id
    designation
    vehicleDetail {
      id
      immat
      vin
    }
  }
  intervention {
    id
    reference
    interventionType
  }
  interventionTransportTrip {
    id
    listOrder
  }

  alerts(itemsPerPage: 100000) {
    collection {
      id
      name
    }
    paginationInfo {
      totalCount
    }
  }

  createdBy {
    id
    firstName
    lastName
    account{
      id
    }
  }
}
`
export const formInterventionAttributes = `
id
oldVersionReference
reference
geolocation
dateCreated
formTemplate {
  id
  name
  title
  footer
  pages(itemsPerPage: 99999) {
    collection {
      id
      name
      listOrder
      fields(itemsPerPage: 99999) {
        collection {
            id
            name
            attributes
            type
        }
      }
      
    }
  }
}

records(itemsPerPage: 99999) {
  collection {
    id
    value
    formTemplateField {
      id
      name
      attributes
      type
    }
  }
}

interventionTransportTrip {
  id
  listOrder
  customer {
    id
    name
  }
  site {
    id
    name
  }
  date
  timeFrom
  timeTo
  contactText
  contactPhone

}


intervention {
  id
  interventionType
  endingForm {
    id,
    value
  }
  status{
    id
    progression
    color
    code
  }
  reference
  externalReference
  interventionTransportType{
    id
    code
  }
  dateTo
  dateFrom
  isOrderEqualFinal
  orderingSite {
    id
    name
    address
    zipcode
    city
    country
  }
  orderingCustomer{
    id
    name
  }
  interventionControlFinalCustomer {
    id
    finalAddressText
    finalSite {
      id
      address
      zipcode
      city
      country
    }
  }

  interventionTransportTrips {
    id
    date
    timeFrom
    timeTo
    name
    address
    zipcode
    city
    country
  }

}
customer {
  id
  name
}
site {
  id
  name
}
vehicle {
  id
  designation
  vehicleModelText
  vehicleDetail {
    id
    vehicleBrand {
      id
      name
    }
    immat
    vin
  }
}

createdBy {
  id
  firstName
  lastName
}
`

export const formInterventionPhotosAttributes = `
paginationInfo {
  lastPage
}
collection {
  id
  intervention {
    id
    reference
    interventionType
  }
  formTemplate {
    id
    name
  }
  geolocation
  dateCreated
  vehicle {
    id
    vehicleDetail {
      id
      immat
    }
  }
  records(itemsPerPage: 99999) {
    collection {
      id
      value
      formTemplateField {
        id
        name
        attributes
        type
      }
    }
  } 
}
`
