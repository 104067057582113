/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { gql, useMutation } from '@apollo/client'

/**  ---------------------MUTATIONS---------------------- */

export const CREATE_NETWORK = gql`
	mutation createNetwork($input: createNetworkInput!) {
		createNetwork(input: $input) {
			network {
				id
			}
		}
	}
`

export const UPDATE_NETWORK = gql`
	mutation updateNetwork($input: updateNetworkInput!) {
		updateNetwork(input: $input) {
			network {
				id
			}
		}
	}
`

export const DELETE_NETWORK = gql`
	mutation deleteNetwork($input: deleteNetworkInput!) {
		deleteNetwork(input: $input) {
			network {
				id
			}
		}
	}
`

/**  ---------------------HOOKS---------------------- */

export const useCreateNetwork = () => useMutation(CREATE_NETWORK)
export const useUpdateNetwork = () => useMutation(UPDATE_NETWORK)
export const useDeleteNetwork = () => useMutation(DELETE_NETWORK)
