import React, { useCallback, useContext, useMemo } from 'react'

import { Typography, Stack, Box, Switch } from '@mui/material'
import { useFormatMessage } from '../../../../../../../utils/hooks'
import { FormModalContext } from '../../../../../../../components/forms/formModal/FormModalContext'
import SwitchButton from '../../../../../../../components/inputs/SwitchButton'
import ManualEntry from './ManualEntry'
import AutomaticEntry from './AutomaticEntry'
import translateIntlMsgString from '../../../../../../../utils/translateIntlMsgString'
import { AuthContext } from '../../../../../../../utils/contexts/AuthContext'
import { EFormModalMode } from '../../../../../../../utils/app-models'

const titleCSS = (isCheckedTitle) => ({
	fontSize: '12px',
	color: ({ palette }) =>
		isCheckedTitle ? palette.grey[500] ?? undefined : '#474747',
})

const switchButtonConfig = {
	primary: {
		text: translateIntlMsgString('interventions.automaticEntry'),
		value: false,
	},
	secondary: {
		text: translateIntlMsgString('interventions.manualEntry'),
		value: true,
	},
}

interface IInterventionPlace {}

const InterventionPlace: React.FC<IInterventionPlace> = () => {
	const intlMsg = useFormatMessage()
	const { data, setValues, setValue, mode, curentEditedElement } =
		useContext(FormModalContext)
	const { isOrderEqualFinal, isManualEntry } = data || {}
	const { currentUser } = useContext(AuthContext)

	const handleChangeSwitch = useCallback(() => {
		const newValues: any = {}
		if (!isOrderEqualFinal) newValues.isManualEntry = false

		setValues({
			...newValues,
			isOrderEqualFinal: !isOrderEqualFinal,
		})
	}, [isOrderEqualFinal, setValues])

	const isAccountOwner = useMemo(
		() =>
			mode !== EFormModalMode?.edit ||
			curentEditedElement?.createdByAccount?.id === currentUser?.account?.id,
		[curentEditedElement, currentUser]
	)

	const handleIsManualEntry = useCallback(() => {
		if (isOrderEqualFinal) return
		setValue('isManualEntry', !isManualEntry)
	}, [isOrderEqualFinal, isManualEntry, setValue])

	return (
		<Box>
			<Stack direction='row' alignItems='baseline' spacing={1} mb={1}>
				<Typography variant='h2' gutterBottom>
					{intlMsg('interventions.interventionPlace')}
				</Typography>

				<Stack
					direction='row'
					alignItems='center'
					spacing={1}
					sx={{ cursor: 'pointer' }}
				>
					<Switch
						checked={isOrderEqualFinal}
						onChange={handleChangeSwitch}
						color='primary'
						size='small'
						data-test-id='Playwright-InterventionPlace-isOrderEqualFinal'
						disabled={!isAccountOwner}
					/>
					<Typography variant='body1' sx={titleCSS(!isOrderEqualFinal)}>
						{intlMsg('interventions.sameLocation')}
					</Typography>
				</Stack>
			</Stack>

			{!isOrderEqualFinal && (
				<Stack direction='row' mb={2} spacing={2}>
					<SwitchButton
						config={switchButtonConfig}
						value={isManualEntry}
						onChange={handleIsManualEntry}
						fullWidth={false}
					/>
				</Stack>
			)}

			{isManualEntry ? (
				<ManualEntry />
			) : (
				<AutomaticEntry isAccountOwner={isAccountOwner} />
			)}
		</Box>
	)
}
export default InterventionPlace
