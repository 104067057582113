/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'

import CssBaseline from '@mui/material/CssBaseline'
import RoutesHandler from './routes/RoutesHandler'

import './app.css'
import './styles/css/table/table.css'

const App = () => (
	<>
		<CssBaseline />
		<RoutesHandler />
	</>
)
export default App
