import React, { useContext, useMemo } from 'react'

import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Stack } from '@mui/material'

import ViewModalEditBtn from '../ViewModalEditBtn'
import ExportBtn from '../../../components/pdfGenerator/ExportBtn'
import { ViewModalContext } from '../../../utils/contexts/ViewModalContext'
import { ViewModalContentContext } from '../../../utils/contexts/ViewModalContentContext'
import BtnDownloadFormPDFV2Link from '../../../components/pdfGenerator/btnDownloadPdf/BtnDownloadFormPDFV2Link'

interface IViewModalHeaderActions {}

const ViewModalHeaderActions: React.FC<IViewModalHeaderActions> = () => {
	const { goPrevViewModal, onViewModalClose } = useContext(ViewModalContext)
	const {
		viewModalData,
		viewModalInfos: { viewModalConfig },
	} = useContext(ViewModalContentContext)

	const isSharedIntervention = useMemo(
		() => viewModalData?.isSharedWithMe,
		[viewModalData?.isSharedWithMe]
	)
	return (
		<Stack direction='row' alignItems='center' spacing={1}>
			{viewModalConfig?.modal &&
				!isSharedIntervention &&
				isSharedIntervention !== undefined && (
					<ViewModalEditBtn viewModalConfig={viewModalConfig} />
				)}

			{viewModalConfig?.downloadPdf &&
				viewModalData &&
				(viewModalData?.oldVersionReference ? (
					<BtnDownloadFormPDFV2Link
						oldVersionReference={viewModalData?.oldVersionReference}
						isBtn
					/>
				) : (
					<ExportBtn data={viewModalData} />
				))}

			<IconButton aria-label='back' onClick={goPrevViewModal} size='large'>
				<ArrowBackIcon />
			</IconButton>

			<IconButton aria-label='close' onClick={onViewModalClose} size='large'>
				<CloseIcon />
			</IconButton>
		</Stack>
	)
}

export default ViewModalHeaderActions
