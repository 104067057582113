/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
	gql,
	OperationVariables,
	QueryHookOptions,
	useLazyQuery,
	useQuery,
} from '@apollo/client'

import {
	listContactsAttributes,
	contactInfosSlidepanelAttributes,
} from '../attributes/contactsAttributes'

/**  ---------------------QUERIES---------------------- */

export const LIST_CONTACTS = gql`
query getContactsList(
    $page: Int
    $itemsPerPage: Int
    $searchText: String
    $isDeleted:Boolean
	$customer: String
    $sites: String
	$orderBy: [ContactFilter_orderBy]
) {
    contacts(page: $page, itemsPerPage: $itemsPerPage,searchText: $searchText,isDeleted: $isDeleted, customer: $customer, sites: $sites, orderBy: $orderBy) {
        ${listContactsAttributes}
    }
}`

export const EXPORT_CONTACTS = gql`
	query exportContacts(
		$searchText: String
		$isDeleted: Boolean
		$customer: String
		$sites: String
		$timezone: Int
		$orderBy: [ContactFilter_orderBy]
	) {
		exportContacts(
			searchText: $searchText
			isDeleted: $isDeleted
			customer: $customer
			sites: $sites
			timezone: $timezone
			orderBy: $orderBy
		) {
			id
			exportFile
		}
	}
`

export const GET_INFOS_CONTACT_SLIDE_PANEL = gql`
query getInfosContactSlidePanel(
    $id: ID!
) {
    contact(id: $id) {
        ${contactInfosSlidepanelAttributes}
    }
}`

export const CONTACTS_NUMBERS = gql`
	query getContactsNumbers(
		$customer: String
		$sites: String
		$searchText: String
	) {
		contacts(
			page: 1
			itemsPerPage: 100000
			isDeleted: false
			customer: $customer
			sites: $sites
			searchText: $searchText
		) {
			paginationInfo {
				totalCount
			}
		}
	}
`
export const GET_CONTACT_LIST_FOR_SELECT = gql`
	query getContactListsForSelect($sites: String) {
		contacts(itemsPerPage: 100000, sites: $sites) {
			collection {
				id
				firstName
				lastName
			}
		}
	}
`

export const GET_CONTACT_FOR_MODAL = gql`
	query getContactForModal($id: ID!) {
		contact(id: $id) {
			canAccessMyContent
			id
			firstName
			lastName
			email
			cellphone
			job
			isCustomerResp
			isSiteResp
			phone
			customer {
				id
				name
				isActive
			}
			sites(itemsPerPage: 99999) {
				collection {
					id
					name
					isActive
				}
			}
		}
	}
`

/**  ---------------------HOOKS---------------------- */

export const useContactInfosSlidePanel = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { loading, data } = useQuery(GET_INFOS_CONTACT_SLIDE_PANEL, options)
	return { loading, data: data?.contact || null }
}

export const useContactsNumbers = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { loading, data } = useQuery(CONTACTS_NUMBERS, options)

	return {
		loading,
		numbers: data?.contacts?.paginationInfo.totalCount || null,
	}
}

export const useLazyContactForModal = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(
		GET_CONTACT_FOR_MODAL,
		options
	)
	return { loading, contact: data?.contact || null, getContact: getData }
}

export const useExportContacts: any = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { data, loading, error } = useQuery(EXPORT_CONTACTS, options)

	return {
		exportFile: data?.exportContacts?.[0]?.exportFile || null,
		loading,
		error,
	}
}
