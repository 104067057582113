/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { gql, useMutation } from '@apollo/client'
import {
	userPayloadAttributes,
	deletePayloadAttributes,
} from '../attributes/usersAttributes'

/**  ---------------------MUTATIONS---------------------- */

export const UPDATE_USER = gql`
	mutation updateUser($input: updateUserInput!) {
		updateUser(input: $input) {
			${userPayloadAttributes}
		}
	}
`

export const CHANGE_PASSWORD = gql`
	mutation changePasswordUser($input: changePasswordUserInput!) {
		changePasswordUser(input: $input) {
			${userPayloadAttributes} 
		}
	}`

export const CREATE_USER = gql`
	mutation createUser($input: createUserInput!) {
		createUser(input: $input) {
			${userPayloadAttributes}
		}
	}
`

export const REMOVE_USER = gql`
	mutation removeUser($input: removeUserInput!) {
		removeUser(input: $input) {
			${deletePayloadAttributes}
		}
	}
`

export const DELETE_USER = gql`
	mutation deleteUser($input: deleteUserInput!) {
		deleteUser(input: $input) {
			${deletePayloadAttributes}
		}
	}
`

export const BULK_USER_ACTION = gql`
	mutation bulkOperationUser($input: bulkOperationUserInput!) {
		bulkOperationUser(input: $input) {
			${deletePayloadAttributes}
		}
	}
`

/**  ---------------------HOOKS---------------------- */

export const useUpdateUsers = () => useMutation(UPDATE_USER)
export const useUpdateUsersPassword = () => useMutation(CHANGE_PASSWORD)
export const useRemoveUsers = () => useMutation(REMOVE_USER)
export const useCreateUsers = () => useMutation(CREATE_USER)
export const useDeleteUsers = () => useMutation(DELETE_USER)
export const useBulkUserstAction = () => useMutation(BULK_USER_ACTION)
