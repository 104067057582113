import React from 'react'

import { Button, Stack, Typography } from '@mui/material'

import { useFormatMessage } from '../../../utils/hooks'
import { Picture } from '../../../components'

interface IFeatureNotAvailable {
	onClose?
}

const FeatureNotAvailable: React.FC<IFeatureNotAvailable> = ({ onClose }) => {
	const intlMsg = useFormatMessage()
	return (
		<Stack
			direction='row'
			justifyContent='center'
			alignItems='center'
			sx={{ width: 1 }}
		>
			<Stack justifyContent='center' alignItems='center'>
				<Picture width={500} src='/img/illustration/new-features.jpg' />
				<Typography variant='body1' sx={{ textAlign: 'center' }}>
					{intlMsg('confirmModal.featureNotAvailable')}
				</Typography>

				<Button variant='contained' onClick={onClose}>
					{intlMsg('misc.close')}
				</Button>
			</Stack>
		</Stack>
	)
}
export default FeatureNotAvailable
