import React, { useCallback } from 'react'

import { Chip, Tooltip, Typography, styled } from '@mui/material'
import Badge, { BadgeProps } from '@mui/material/Badge'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import CustomLink from '../_overrideMui/Link/CustomLink'
import { maxStringDisplay } from '../../utils/string'

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
	'& .MuiBadge-badge': {
		right: '6px',
		top: '9px',
		padding: '5px 4px',
		height: '24px',
		width: '24px',
		borderRadius: '30px',
		border: 'none',
		backgroundColor: '#2196F314',
		color: '#000000DE',
		fontSize: '0.775rem',
		fontWeight: 500,
	},
}))

const ChipCSS = {
	border: 'none',
	'& .MuiChip-label': {
		fontSize: '0.775rem!important',
		ml: '4px',
		mr: '2px',
		textOverflow: 'initial',
	},
	'& .MuiChip-deleteIcon': { margin: '0 5px 0 -8px' },
	'&.MuiChip-root': { pl: '0px', mr: '-2px', height: '28px' },
	'&.MuiChip-root:hover': { height: '28px' },
}

interface IMultiItemsButton {
	nbItems: number
	label: any
	handleClick
	buttonType:
		| 'textLink'
		| 'simpleText'
		| 'chipCountClickable'
		| 'chip'
		| 'customButton'
		| 'empty'

	maxCharacters?: number
	haveAccess?: boolean
	disabledClick?: boolean
}

const MultiItemsButton: React.FC<IMultiItemsButton> = ({
	nbItems,
	label,
	handleClick,
	buttonType,
	maxCharacters = 15,
	haveAccess = true,
	disabledClick = false,
}) => {
	const RenderButtonByType = useCallback(() => {
		const selectedButtonType = nbItems ? buttonType : 'empty'
		const CustomButton = buttonType === 'customButton' && label // CustomButton render a component

		switch (selectedButtonType) {
			case 'empty':
				return <Typography>-</Typography>

			case 'simpleText':
				return (
					<Tooltip
						title={label}
						disableHoverListener={label?.length < maxCharacters}
					>
						<span>
							<Typography fontSize='0.775rem'>
								{maxStringDisplay(label, maxCharacters)}
							</Typography>
						</span>
					</Tooltip>
				)

			case 'textLink':
				return (
					<Tooltip
						title={label}
						disableHoverListener={label?.length < maxCharacters}
					>
						<span>
							<CustomLink
								variantlink='primaryLink'
								onClick={handleClick}
								disabled={!haveAccess || disabledClick}
							>
								{maxStringDisplay(label, maxCharacters)}
							</CustomLink>
						</span>
					</Tooltip>
				)

			case 'chipCountClickable':
				return (
					<Chip
						sx={ChipCSS}
						avatar={<StyledBadge badgeContent={nbItems} color='secondary' />}
						deleteIcon={<ArrowDropDownIcon />}
						label={label}
						variant='outlined'
						onClick={handleClick}
						onDelete={handleClick}
						size='small'
					/>
				)

			case 'chip':
				return (
					<Tooltip
						title={label}
						disableHoverListener={label?.length < maxCharacters}
					>
						<Chip
							label={maxStringDisplay(label, maxCharacters)}
							color='primary'
							variant='outlined'
							size='small'
						/>
					</Tooltip>
				)

			case 'customButton':
				return <CustomButton />
		}
	}, [label, handleClick, buttonType, nbItems, maxCharacters])

	return <RenderButtonByType />
}

export default MultiItemsButton
