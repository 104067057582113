/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'

import WTotalInterventionsDone from '../../../components/widget/WTotalInterventionsDone/WTotalInterventionsDone'
import WTotalInterventionsNotAssigned from '../../../components/widget/WTotalInterventionsNotAssigned/WTotalInterventionsNotAssigned'
import WTotalVehicleEntriePark from '../../../components/widget/WTotalVehicleEntriePark/WTotalVehicleEntriePark'
import WInterventionbyReference from '../../../components/widget/Wtable/WInterventionbyReference'
import WAlertsTreated from '../../../components/widget/WAlertsTreated/WAlertsTreated'
import WAlertsDetectedCategory from '../../../components/widget/WAlertsDetectedCategory/WAlertsDetectedCategory'
import { EPermission } from '../../../utils/app-models'

//////////////////////////////////////////
// List of available widgets
//////////////////////////////////////////

export const dashboardWidgets = (widgetName, filterQueries) =>
	({
		WInterventionsDone: (
			<WTotalInterventionsDone filterQueries={filterQueries} />
		),
		WAlertsDetectedCategory: (
			<WAlertsDetectedCategory filterQueries={filterQueries} />
		),
		WAlertsTreated: <WAlertsTreated filterQueries={filterQueries} />,
		WTotalVehicleEntriePark: (
			<WTotalVehicleEntriePark filterQueries={filterQueries} />
		),
		WTotalVehicleExitPark: (
			<WTotalVehicleEntriePark filterQueries={filterQueries} isExitPark />
		),
		WTotalInterventionsNotAssigned: (
			<WTotalInterventionsNotAssigned filterQueries={filterQueries} />
		),
		WInterventionbyReference: (
			<WInterventionbyReference filterQueries={filterQueries} />
		),
	}[widgetName])

//////////////////////////////////////////
// Initial widget config widgets
//////////////////////////////////////////
// Example config widget :
// {
// 	titleFilter: 'widget.toto',
// 	additionalArgumentsQuery: { isActive: false },
// 	i: 'WVehiclesInactive',
// 	x: 6,
// 	y: 0,
//  ect...
// },

export const initialWidgetsLayoutsConfig = {
	lg: [
		{
			titleFilter: 'widget.interventionNotAssigned',
			i: 'WTotalInterventionsNotAssigned',
			x: 0,
			y: 0,
			w: 2,
			h: 2,
			permissions: [EPermission.intervention],
			notForGest: true,
		},
		{
			titleFilter: 'widget.interventionDone',
			i: 'WInterventionsDone',
			x: 2,
			y: 0,
			w: 2,
			h: 2,
		},
		{
			titleFilter: 'widget.entriePark',
			i: 'WTotalVehicleEntriePark',
			x: 4,
			y: 0,
			w: 2,
			h: 2,
		},
		{
			titleFilter: 'widget.exitPark',
			i: 'WTotalVehicleExitPark',
			x: 6,
			y: 0,
			w: 2,
			h: 2,
		},
		{
			titleFilter: 'widget.alertDetected',
			i: 'WAlertsDetectedCategory',
			x: 8,
			y: 0,
			w: 4,
			h: 2,
			maxH: 4,
		},
		{
			titleFilter: 'widget.alertFinish',
			i: 'WAlertsTreated',
			x: 8,
			y: 0,
			w: 2,
			h: 2,
			maxH: 4,
		},
		{
			titleFilter: 'widget.interByRef',
			i: 'WInterventionbyReference',
			x: 0,
			y: 2,
			w: 8,
			h: 8,
			maxH: 8,
		},
	],
}

//////////////////////////////////////////
// merge layout widgets properties (to conserve properties additionalArgumentsQuery, titleFilter & additionalWidgetParams )
//////////////////////////////////////////
export const mergeLayoutWidgetsProperties = (oldProperty, newProperty) => {
	return {
		lg: newProperty.lg.map((widget, i) => ({
			...oldProperty.lg[i],
			...widget,
		})),
	}
}

//////////////////////////////////////////
// Filtered widgets with permissions
//////////////////////////////////////////

export const filteredWidgetsPermission = (
	widgets,
	checkPermissions,
	checkModules,
	isAGuestUser
) => {
	return {
		lg: widgets?.lg?.filter(
			({ permissions, modules, notForGest }) =>
				checkPermissions(permissions) &&
				checkModules(modules) &&
				(!isAGuestUser || !notForGest)
		),
	}
}
