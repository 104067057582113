/* eslint-disable @typescript-eslint/no-unused-vars */
import { EFormModalMode } from '../../../../utils/app-models'
import { getCountries, defaultCountry } from '../../../../utils/tabCountries'

export const formatSiteModalEditDefaultValues = ({ data, localLanguage }) => {
	const {
		id,
		__typename,
		sharedToAgencies,
		gestionnaire,
		country,
		customer,
		...values
	} = data
	const countryValues = getCountries(localLanguage)?.find(
		({ code }) => code === country
	)

	return {
		...values,
		customer: {
			key: customer?.id,
			label: customer?.name,
			value: customer,
		},
		gestionnaire: {
			key: gestionnaire?.id,
			label: `${gestionnaire?.firstName} ${gestionnaire?.lastName}`,
			value: gestionnaire,
		},
		country: {
			key: countryValues?.code,
			label: countryValues?.label,
			value: countryValues?.code,
		},
		sharedToAgencies: (sharedToAgencies?.collection || []).reduce(
			(acc, sharedToAgency) => {
				const sharedToAgencyId = sharedToAgency?.id
				if (sharedToAgencyId) {
					acc[sharedToAgencyId] = {
						checked: true,
						value: sharedToAgency,
					}
				}
				return acc
			},
			{}
		),
	}
}

export const formatSiteModalCreateDefaultValues = ({
	currentUser,
	dataPreFilling,
}) => {
	return {
		gestionnaire: {
			key: currentUser?.id,
			label: `${currentUser?.firstName} ${currentUser?.lastName}`,
			value: {
				id: currentUser?.id,
				lastName: currentUser?.lastName,
				firstName: currentUser?.firstName,
			},
		},
		customer: dataPreFilling
			? {
					key: dataPreFilling?.id,
					label: dataPreFilling?.name,
					value: { id: dataPreFilling?.id, name: dataPreFilling?.name },
			  }
			: null,
		country: {
			key: defaultCountry.code,
			label: defaultCountry.label,
			value: defaultCountry.code,
		},
		isActive: true,
	}
}

export const formatSiteModalOnValidateData = ({ data, mode, siteId }) => {
	const specificModeFields =
		mode === EFormModalMode.edit
			? {
					id: siteId,
			  }
			: {
					isActive: true,
			  }

	const { customer, gestionnaire, sharedToAgencies, country, ...restData } =
		data

	return {
		...specificModeFields,
		...restData,
		customer: customer?.value?.id || '',
		gestionnaire: gestionnaire?.value?.id || '',
		country: country?.value,
		sharedToAgencies: Object.values(sharedToAgencies || {})
			.filter(({ checked }) => checked)
			.map(({ value }) => value?.id),
	}
}
