import React from 'react'

import { Stack } from '@mui/material'
import { FormModalContext } from '../../../../../../../components/forms/formModal/FormModalContext'
import FormTextField from '../../../../../../../components/forms/formFields/FormTextField'
import { useFormatMessage } from '../../../../../../../utils/hooks'

interface IManualEntry {}

const ManualEntry: React.FC<IManualEntry> = () => {
	const intlMsg = useFormatMessage()

	return (
		<Stack spacing={2}>
			<FormTextField
				name='finalAddressText'
				label={intlMsg('interventions.finalAddressText')}
				context={FormModalContext}
				multiline={true}
				minRows={5}
			/>
			<FormTextField
				name='finalContactText'
				label={intlMsg('interventions.finalContactText')}
				context={FormModalContext}
			/>
		</Stack>
	)
}
export default ManualEntry
