import React, { useState, useCallback, useMemo } from 'react'

import {
	Accordion,
	AccordionSummary,
	Typography,
	AccordionDetails,
	Divider,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useFormatMessage } from '../../../utils/hooks'

const AccordionCSS = { borderRadius: 2, p: 0, '&::before': { opacity: 0 } }
const AccordionDetailsCSS = { p: '0px 16px 16px' }

interface IVmCardExpended {
	title: string
	defaultExpended?: boolean
	titleGutter?: boolean
	children
}

const VmCardExpended: React.FC<IVmCardExpended> = ({
	title,
	defaultExpended = true,
	titleGutter = false,
	children,
}) => {
	const intlMsg = useFormatMessage()

	const [expanded, setExpanded] = useState<boolean>(defaultExpended)
	const handleChange = useCallback(
		(_event: React.SyntheticEvent, isExpanded: boolean) => {
			setExpanded(isExpanded)
		},
		[]
	)

	const titleGutterCSS = useMemo(
		() => ({
			mb: titleGutter ? '16px' : 'inherit',
		}),
		[titleGutter]
	)

	return (
		<Accordion
			expanded={expanded}
			onChange={handleChange}
			sx={AccordionCSS}
			disableGutters
		>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography variant='text1'>{intlMsg(title)}</Typography>
				<Divider />
			</AccordionSummary>

			<AccordionDetails sx={AccordionDetailsCSS}>
				<Divider sx={titleGutterCSS} />
				{children}
			</AccordionDetails>
		</Accordion>
	)
}
export default VmCardExpended
