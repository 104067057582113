/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
	useMemo,
	useState,
	useCallback,
	useContext,
	useEffect,
} from 'react'
import { FiltersBtnContext } from './FiltersBtnContext'
import { FiltersContext } from '../filtersContext'
import FiltersBtnComponent from './FiltersBtnComponent'
import { getActiveFiltersNumber } from './filterPopoverUtils'

interface IFiltersBtnProvider {
	children: any
}

const FiltersBtnProvider: React.FC<IFiltersBtnProvider> = ({ children }) => {
	const { filters, defaultFiltersValue } = useContext(FiltersContext)
	const [tmpFilters, setTmpFilters] = useState(filters || {})
	const [anchorEl, setAnchorEl] = useState(null)

	useEffect(() => {
		setTmpFilters(filters)
	}, [filters])

	const activeFiltersNumber = useMemo(
		() => getActiveFiltersNumber(defaultFiltersValue, filters),
		[filters]
	)

	const setTmpFilter = useCallback(
		(newTmpFilter: any) => {
			setTmpFilters({ ...tmpFilters, newTmpFilter })
		},
		[tmpFilters]
	)

	const resetTmpFilters = useCallback(() => {
		setTmpFilters(filters)
	}, [filters])

	const handleOpen = useCallback((event) => {
		setAnchorEl(event.currentTarget)
	}, [])

	const handleClose = useCallback(() => {
		setAnchorEl(null)
	}, [])

	const context = useMemo(
		() => ({
			tmpFilters,
			setTmpFilters,
			setTmpFilter,
			resetTmpFilters,
			handleOpen,
			handleClose,
			activeFiltersNumber,
			anchorEl,
			filtersComponent: children,
		}),
		[tmpFilters, setTmpFilter, resetTmpFilters, activeFiltersNumber, anchorEl]
	)

	return (
		<FiltersBtnContext.Provider value={context}>
			<FiltersBtnComponent />
		</FiltersBtnContext.Provider>
	)
}

export default FiltersBtnProvider
