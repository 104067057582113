export default {
	dashboard: 'Tableau de bord',
	myProfile: 'Mon profil',
	myDomain: 'Mon entreprise',
	myAccountTitle: 'MON COMPTE',
	usersTitle: 'Utilisateurs',
	users: 'Utilisateurs',
	groups: 'Equipes',
	notifications: 'Notifications',
	myJob: 'Mon métier',
	myJobCapsLock: 'MON MÉTIER',
	helpApp: 'Aide et support',
	apps: 'Apps',
	logout: 'Déconnexion',
	add: 'Ajouter',
	agency: 'Agence',
	customer: 'Compte',
	contact: 'Contact',
	site: 'Site',
	vehicle: 'Véhicule',
	interventionControl: 'Intervention',
	interventionTransport: 'Transport',
	interventionLogistic: 'Intervention logistique',
	formTemplateList: 'Editeur de formulaires',
	catalogProduct: 'Catalogue produits',
	whiteBrand: 'Marque blanche',
	invoice: 'Dépense',
	document: 'Document',
}
