import React, { useMemo, useCallback } from 'react'
import { Stack, Typography, Box } from '@mui/material'
import { dayjsMethod, getSeparateDateAndHour } from '../../utils/date'

const timeCSS = { opacity: 0.7 }

interface IDateToFromReact {
	dateTo: Date
	dateFrom: Date
	fontMd?: boolean
	noSvg?: boolean
	canBeLate?: boolean
}

const DateToFrom: React.FC<IDateToFromReact> = ({
	dateTo,
	dateFrom,
	fontMd = false,
	noSvg = false,
	canBeLate = false,
}) => {
	const { date: dateDateFrom, hour: hourDateFrom } = useMemo(
		() => getSeparateDateAndHour(dateFrom),
		[dateFrom]
	)

	const { date: dateDateTo, hour: hourDateTo } = useMemo(
		() => getSeparateDateAndHour(dateTo),
		[dateTo]
	)

	const typoLineHeightCSS = useMemo(
		() => ({
			lineHeight: '1.38',
			color: canBeLate && dayjsMethod(dateTo).isBefore(new Date()) ? 'red' : '',
		}),
		[canBeLate, dateTo]
	)

	const renderSvg = useCallback(
		() => (
			<Stack alignItems='center' mr={noSvg ? 0 : 0.5}>
				<img src={noSvg ? undefined : '/icon/dateToFrom.svg'} />
			</Stack>
		),
		[noSvg]
	)

	const renderTypography = useCallback(
		(dateValue, hourValue) => (
			<Typography
				noWrap
				variant={fontMd ? 'body2' : 'caption'}
				sx={typoLineHeightCSS}
			>
				<Box component='span' sx={{ mr: 0.5 }}>
					{dateValue}
				</Box>
				<Box component='span' sx={timeCSS}>
					{hourValue}
				</Box>
			</Typography>
		),
		[fontMd, typoLineHeightCSS]
	)

	const renderSameDayTypography = useCallback(
		() => renderTypography(dateDateTo, `${hourDateFrom} - ${hourDateTo}`),
		[dateDateTo, hourDateFrom, hourDateTo, renderTypography]
	)

	const renderDifferentDayTypography = useCallback(
		() => (
			<>
				{renderTypography(dateDateFrom, `- ${hourDateFrom}`)}
				{renderTypography(dateDateTo, `- ${hourDateTo}`)}
			</>
		),
		[dateDateFrom, hourDateFrom, dateDateTo, hourDateTo, renderTypography]
	)

	return (
		<Stack direction='row' alignItems='center'>
			{!dayjsMethod(dateTo).isSame(dateFrom, 'day') ? (
				<>
					{!noSvg && renderSvg()}
					<Stack>{renderDifferentDayTypography()}</Stack>
				</>
			) : (
				<>
					<Stack alignItems='center' mr={1}>
						<img src='/icon/dateToFromSameDay.svg' />
					</Stack>
					<Stack alignItems='center'>{renderSameDayTypography()}</Stack>
				</>
			)}
		</Stack>
	)
}

export default DateToFrom
