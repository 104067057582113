import React, { memo, useCallback } from 'react'

import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Input from '@mui/material/Input'
import { Stack, Typography, Pagination, MenuItem } from '@mui/material'

import { useFormatMessage } from '../../../../utils/hooks'

const selectCSS = {
	border: 'none',
	'&::before': { border: 'none' },
	'&::after': { border: 'none' },
}
const containerCSS = { flexWrap: 'wrap', gap: 2 }
const TypographyCSS = { mr: 1 }
const FormControlCSS = { mr: 2 }

interface ITableFooter {
	page: number
	rowsPerPage: number
	count: number
	setPage: (nbr: number) => void
	setRowsPerPage: (nbr: number) => void
}

const TableFooter: React.FC<ITableFooter> = ({
	page,
	setPage,
	rowsPerPage,
	setRowsPerPage,
	count,
}) => {
	const intlMsg = useFormatMessage()

	const handleChangePage = useCallback(
		(_event, newPage) => {
			setPage(newPage)
		},
		[page]
	)

	const handleChangeRowsPerPage = useCallback((event) => {
		if (event.target.value[1]) {
			setRowsPerPage(parseInt(event.target.value[1], 10))
			setPage(1)
		}
	}, [])

	return (
		<Stack direction='row' justifyContent='end' mt={4} sx={containerCSS}>
			<Stack direction='row' justifyContent='end' alignItems='center'>
				<Typography sx={TypographyCSS} variant='body1'>
					{intlMsg('table.rowPerPage')} :
				</Typography>
				<FormControl sx={FormControlCSS}>
					<Select
						labelId='row-per-page'
						multiple
						value={[rowsPerPage]}
						onChange={handleChangeRowsPerPage}
						input={<Input />}
						defaultValue={[rowsPerPage]}
						sx={selectCSS}
					>
						{[10, 25, 50, 100].map((nb) => (
							<MenuItem key={nb} value={nb}>
								{nb === 1000000 ? intlMsg('misc.all') : nb}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<Typography sx={TypographyCSS} variant='body1'>
					{`${intlMsg('table.footerPagination1')} ${
						rowsPerPage * (page - 1) + 1
					} ${intlMsg('table.footerPagination2')} ${
						rowsPerPage * page > count ? count : rowsPerPage * page
					} ${intlMsg('table.footerPagination3')} ${count} ${intlMsg(
						'table.footerPagination4'
					)}`}
				</Typography>
			</Stack>

			<Pagination
				count={Math.ceil(count / rowsPerPage)}
				page={page}
				onChange={handleChangePage}
				defaultPage={3}
				siblingCount={0}
				shape={'rounded'}
				disabled={count <= rowsPerPage}
			/>
		</Stack>
	)
}

export default memo(TableFooter)
