import translateIntlMsgString from '../../../../utils/translateIntlMsgString'
import { string, object } from 'yup'

const CustomerAccountModalValidationSchema = object().shape({
	name: string().required(translateIntlMsgString('validationSchema.require')),
	siret: string().required(translateIntlMsgString('validationSchema.require')),
	gestionnaire: object()
		.required(translateIntlMsgString('validationSchema.require'))
		.nullable(),
})

export default CustomerAccountModalValidationSchema
