/* eslint-disable @typescript-eslint/no-unused-vars */
import { EFormModalMode } from '../../../../utils/app-models'
import { defaultCountry } from '../../../../utils/tabCountries'

export const formatCustomerContactModalEditDefaultValues = (data) => {
	const { id, __typename, sites, customer, ...values } = data

	return {
		...values,
		sites: sites?.collection?.reduce((accumulator, site) => {
			accumulator[site.id] = { checked: true, value: site }
			return accumulator
		}, {}),
		customer: {
			key: customer?.id,
			label: customer?.name,
			value: customer,
		},
	}
}

export const formatCustomerContactModalCreateDefaultValues = async ({
	dataPreFilling,
	isSite,
	forcedCustomerId,
	forcedSiteId,
	getCustomer,
	getSite,
}) => {
	const promiseQueries = [] as any

	if (forcedCustomerId)
		promiseQueries.push(
			getCustomer({
				variables: {
					id: forcedCustomerId,
				},
			})
		)

	if (forcedSiteId)
		promiseQueries.push(
			getSite({
				variables: {
					id: forcedSiteId,
				},
			})
		)

	const forcedData = await Promise.all(promiseQueries)
	const forcedCustomer = forcedData?.[0]?.data?.customer || null
	const forcedSite = forcedData?.[1]?.data?.site || null

	return {
		customer: forcedCustomer
			? {
					key: forcedCustomer?.id,
					label: forcedCustomer?.name,
					value: forcedCustomer,
			  }
			: dataPreFilling
			? {
					key: isSite ? dataPreFilling.customer.id : dataPreFilling.id,
					label: isSite ? dataPreFilling.customer.name : dataPreFilling.name,
					value: {
						id: isSite ? dataPreFilling.customer.id : dataPreFilling.id,
						name: isSite ? dataPreFilling.customer.name : dataPreFilling.name,
					},
			  }
			: null,
		sites: forcedSite
			? {
					[forcedSite?.id]: {
						checked: true,
						value: forcedSite,
					},
			  }
			: isSite
			? {
					[dataPreFilling?.id]: {
						checked: true,
						value: { id: dataPreFilling?.id, name: dataPreFilling?.name },
					},
			  }
			: null,
		isSiteResp: false,
		isCustomerResp: false,
		canAccessMyContent: false,
	}
}

export const formatCustomerContactModalOnValidateData = ({
	data,
	mode,
	contactId,
}) => {
	const specificModeFields =
		mode === EFormModalMode.edit
			? {
					id: contactId,
			  }
			: {
					isDeleted: false,
			  }

	const { customer, sites, ...restData } = data

	return {
		...specificModeFields,
		...restData,
		customer: customer?.value?.id || '',
		sites: Object.values(sites || {})
			.filter(({ checked }) => checked)
			.map(({ value }) => value?.id),
	}
}
