import React, { memo, useCallback, useMemo } from 'react'

import {
	Box,
	Checkbox,
	TableSortLabel,
	TableCell,
	TableHead,
	TableRow,
	Popover,
	Typography,
} from '@mui/material'

import { useFormatMessage } from '../../../../utils/hooks'

const mainCSS = {
	'& .MuiTableCell-head': {
		color: ({ palette }) => palette.text.secondary,
		fontSize: '11.5px',
		fontWeight: 700,
		wordWrap: 'break-word',
		whiteSpace: 'nowrap',
		lineHeight: '1.1rem',
		paddingRight: '12px !important',
	},
}

const checkBoxCSS = {
	borderLeft: '4px solid #00000000',
	'& .Mui-checked': {
		color: '#3F51B5',
	},
}

const visuallyHidden = {
	border: 0,
	clip: 'rect(0 0 0 0)',
	height: 1,
	margin: -1,
	overflow: 'hidden',
	padding: 0,
	position: 'absolute',
	top: 20,
	width: 1,
}

const stickyRightCSS = {
	position: 'sticky',
	right: '0px',
	background: 'white',
	boxShadow: '5px 2px 5px grey',
	paddingRight: '5px!important',
	paddingLeft: '5px!important',
}

const stickyLeftCSS = {
	position: 'sticky',
	left: '0px',
	background: 'white',
	paddingRight: '5px!important',
	paddingLeft: '5px!important',
	zIndex: 2,
}

const stickyCSS = (sticky, stickyFirstCol) => ({
	...(sticky && stickyRightCSS),
	...(stickyFirstCol && stickyLeftCSS),
	'& p': {
		margin: 0,
		p: 0,
		fontSize: '14px',
		fontWeight: 500,
		color: '#2E2E2E',
	},
	'& p::first-letter': {
		textTransform: 'uppercase',
	},
})

interface ITableHeader {
	activeColumns: any[]
	tableConfig
	selectedRow
	setSelectedRow
	rows
	onRequestSort
	currentOrderByName
	order
	disabledCheckbox?: boolean
}

const popoverCSS = {
	pointerEvents: 'none',
	'& .MuiPaper-root': {
		backgroundColor: '#616161e6',
		color: 'white',
	},
}

const TableHeader: React.FC<ITableHeader> = ({
	tableConfig,
	activeColumns = null,
	selectedRow,
	setSelectedRow,
	rows,
	currentOrderByName,
	onRequestSort,
	order,
	disabledCheckbox,
}) => {
	const intlMsg = useFormatMessage()

	const allChecked = useMemo(
		() =>
			rows.some((row) =>
				selectedRow.find((rowSelected) => rowSelected.id === row.id)
			),
		[selectedRow, rows]
	)

	const handleSelectAllRow = useCallback(() => {
		if (!allChecked) setSelectedRow([...selectedRow, ...rows])
		else
			setSelectedRow(
				selectedRow.filter(
					(rowSelected) =>
						!rows.map((selected) => selected.id).includes(rowSelected.id)
				)
			)
	}, [selectedRow, rows, allChecked])

	const handleSort = useCallback(
		(orderBy) => () => onRequestSort({ orderBy }),
		[onRequestSort]
	)

	const [mouseOverPopover, setMouseOverPopover] =
		React.useState<HTMLElement | null>(null)

	const overPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
		setMouseOverPopover(event.currentTarget)
	}

	const overPopoverClose = () => {
		setMouseOverPopover(null)
	}

	const openPopover = Boolean(mouseOverPopover)

	return (
		<TableHead sx={mainCSS}>
			<TableRow>
				{!disabledCheckbox && (
					<TableCell padding='checkbox'>
						<Box sx={checkBoxCSS}>
							<Checkbox
								indeterminate={allChecked}
								checked={allChecked}
								onChange={handleSelectAllRow}
								size='small'
								onMouseEnter={overPopoverOpen}
								onMouseLeave={overPopoverClose}
							/>

							<Popover
								sx={popoverCSS}
								open={openPopover}
								anchorEl={mouseOverPopover}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'center',
								}}
								transformOrigin={{
									vertical: 'bottom',
									horizontal: 'center',
								}}
								onClose={overPopoverClose}
								disableRestoreFocus
							>
								<Typography sx={{ p: 1 }}>
									{intlMsg('misc.selectAll')}
								</Typography>
							</Popover>
						</Box>
					</TableCell>
				)}

				{tableConfig.map(
					({
						columnLabel,
						fixWidth,
						orderBy,
						cellAlign,
						visible,
						sticky = false,
						stickyFirstCol = false,
					}) => {
						if (
							!activeColumns ||
							(activeColumns &&
								activeColumns?.find?.((e) => e === columnLabel)) ||
							visible
						)
							return (
								<TableCell
									key={`head-${columnLabel}`}
									style={{ width: fixWidth, textAlign: cellAlign }}
									sx={stickyCSS(sticky, stickyFirstCol)}
								>
									{orderBy && !sticky ? (
										<TableSortLabel
											active={orderBy === currentOrderByName}
											direction={orderBy === currentOrderByName ? order : 'asc'}
											onClick={handleSort(orderBy)}
											sx={{ width: 1 }}
										>
											{(columnLabel || columnLabel !== ' ') && !sticky ? (
												<p>{intlMsg(columnLabel)}</p>
											) : (
												''
											)}

											{orderBy === currentOrderByName ? (
												<Box component='span' sx={visuallyHidden}>
													{order === 'desc'
														? 'sorted descending'
														: 'sorted ascending'}
												</Box>
											) : null}
										</TableSortLabel>
									) : columnLabel && columnLabel !== ' ' && !sticky ? (
										<p>{intlMsg(columnLabel)}</p>
									) : (
										''
									)}
								</TableCell>
							)
					}
				)}
			</TableRow>
		</TableHead>
	)
}

export default memo(TableHeader)
