export default {
	default: 'Search',
	customer: 'Search for an account',
	contacts: 'Search for a contact',
	site: 'Search for a site',
	vehicle: 'Search for a vehicle',
	team: 'Search for a team',
	user: 'Search for a user',
	formTemplate: 'Search for a template',
	intervention: 'Search for an intervention',
	missingFormSelectSearchQuery:
		'No results match your search. (minimum 3 characters)',
}
