import React, { useMemo } from 'react'
import { Button, Stack } from '@mui/material'
import { stringifiedColor } from '../../../../../utils/color'

interface IControl {
	fieldTemplate
	record
}

const Control: React.FC<IControl> = ({ fieldTemplate, record }) => {
	const attributeTemplateParse = useMemo(
		() => JSON.parse(fieldTemplate.attributes[0]),
		[fieldTemplate.attributes]
	)

	// if typeof defaultValue === 'string' -> default value="" (not defined) -> set default value to 0 if field required
	const value = useMemo(() => {
		return (
			record?.value?.value ??
			(typeof attributeTemplateParse?.defaultValue === 'string' &&
			attributeTemplateParse?.isRequired
				? 0
				: attributeTemplateParse?.defaultValue)
		)
	}, [record?.value?.value, attributeTemplateParse?.defaultValue])

	return (
		<Stack direction='row'>
			{attributeTemplateParse?.controlList?.map(({ color, choice }, i) => {
				const hexColor = stringifiedColor(color, 500) as any
				return (
					<Button
						sx={{ mr: 1 }}
						variant='outlined'
						color='primary'
						key={i}
						disabled
						size='small'
						style={
							i === value
								? {
										color: 'white',
										backgroundColor: hexColor,
								  }
								: {
										color: hexColor,
										border: `1px solid ${hexColor}`,
								  }
						}
					>
						{choice}
					</Button>
				)
			})}
		</Stack>
	)
}

export default Control
