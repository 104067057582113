import React, { useState, useCallback, useEffect } from 'react'

import { fr } from 'react-date-range/dist/locale'
import { DateRange } from 'react-date-range'

import { Stack, Button } from '@mui/material'

import { EPeriodFilter } from '../../../../../utils/app-models'
import { useFormatMessage } from '../../../../../utils/hooks'
import { formatDateRange, setDateRangeDefaultValue } from './utilsDateFilter'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file

const ButtonCSS = { margin: '-0.7rem 1.3rem' }

interface IDateRangeFilter {
	setValueType
	valueType
	setFiltersQuerieParam
	filtersQuerieParam
	localStorageKeyFilter
	argumentQuerieName?
	setLocalStorageOnchange?: boolean
	handleClose
}

const DateRangeFilter: React.FC<IDateRangeFilter> = ({
	setValueType,
	valueType,
	setFiltersQuerieParam,
	filtersQuerieParam,
	localStorageKeyFilter,
	argumentQuerieName,
	setLocalStorageOnchange,
	handleClose,
}) => {
	const intlMsg = useFormatMessage()

	const [focus, setFocus] = useState()
	const [dateRange, setDateRange]: any = useState([
		{ startDate: new Date(), endDate: new Date(), key: 'selection' },
	])

	const onRangeFocusChange = useCallback((focus) => setFocus(focus), [])

	// Default value if it's date Range period
	useEffect(() => {
		if (
			EPeriodFilter.customPeriod === filtersQuerieParam?.period &&
			EPeriodFilter.customPeriod === valueType
		) {
			setDateRangeDefaultValue(
				argumentQuerieName,
				filtersQuerieParam,
				setDateRange
			)
		} else {
			setDateRange([
				{ startDate: new Date(), endDate: new Date(), key: 'selection' },
			])
		}
	}, [])

	// //////////////////////////////////////////////////////////////////////

	const onChangeDateRange = useCallback(
		({ selection }) => {
			setDateRange([selection])

			// if (focus?.[1]) {
			// 	const { startDate, endDate } = selection

			// 	const valuesDate = formatDateRange(
			// 		argumentQuerieName,
			// 		startDate,
			// 		endDate
			// 	)

			// 	setFiltersQuerieParam({
			// 		...filtersQuerieParam,
			// 		...valuesDate,
			// 		period: valueType,
			// 	})

			// 	setValueType(EPeriodFilter.customPeriod)
			// }
		},
		[focus]
	)

	// //////////////////////////////////////////////////////////////////////

	const onValidate = useCallback(() => {
		const { startDate, endDate } = dateRange?.[0] || {}
		const valuesDate = formatDateRange(argumentQuerieName, startDate, endDate)

		setFiltersQuerieParam({
			...filtersQuerieParam,
			...valuesDate,
			period: valueType,
		})

		setLocalStorageOnchange &&
			localStorage.setItem(
				localStorageKeyFilter,
				JSON.stringify({
					...filtersQuerieParam,
					...valuesDate,
					period: valueType,
				})
			)

		setValueType(EPeriodFilter.customPeriod)
		handleClose()
	}, [focus, filtersQuerieParam, valueType, dateRange])

	return (
		<Stack spacing={0}>
			<DateRange
				locale={fr}
				editableDateInputs
				onChange={onChangeDateRange}
				moveRangeOnFirstSelection={false}
				ranges={dateRange}
				onRangeFocusChange={onRangeFocusChange}
			/>
			<Button
				variant='outlined'
				size='small'
				sx={ButtonCSS}
				onClick={onValidate}
			>
				{intlMsg('misc.validate')}
			</Button>
		</Stack>
	)
}

export default DateRangeFilter
