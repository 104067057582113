import React from 'react'

import { Stack } from '@mui/material'

const imgCSS = {
	'& img': {
		maxWidth: '100%',
		minWidth: '100%',
		height: '100px',
		borderRadius: 1,
		transform: ' rotate(90deg)',
	},
}

interface ISignature {
	fieldTemplate?
	record
}

const Signature: React.FC<ISignature> = ({ record }) => {
	return (
		<Stack direction='row' sx={imgCSS}>
			{record?.value?.value ? <img src={record.value.value} /> : <></>}
		</Stack>
	)
}

export default Signature
