import React from 'react'

import { Stack, Typography } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import { useFormatMessage } from '../../../utils/hooks'

interface IAppListTitle {
	title?: string
	titleStrong?: string
}

const AppListTitle: React.FC<IAppListTitle> = ({ title, titleStrong }) => {
	const intlMsg = useFormatMessage()
	return (
		<Stack direction='row' alignItems='center' spacing={2}>
			<CheckIcon color='success' />
			<Typography sx={{ fontSize: '16px' }}>
				{titleStrong && <strong>{intlMsg(`app.${titleStrong}`)}</strong>}
				{title && intlMsg(`app.${title}`)}
			</Typography>
		</Stack>
	)
}
export default AppListTitle
