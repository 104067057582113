/* eslint-disable */
// ###### 4 => Local folder imports ######
import flattenObject from '../../utils/flattenObject'
import { ESupportedLanguages } from '../../utils/app-models'
import * as frLang from './translations/fr'
import * as enLang from './translations/en'

const languages = {
	[ESupportedLanguages.fr]: frLang,
	[ESupportedLanguages.en]: enLang,
}

const AppLocale = {
	...Object.entries(ESupportedLanguages).reduce(
		(prev, [, value]) => ({
			...prev,
			[value]: flattenObject(languages[value]),
		}),
		{}
	),
}

export default AppLocale
