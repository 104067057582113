import React, { memo } from 'react'
import { Box, Card, Skeleton, Stack } from '@mui/material'

import TableSideBarHeader from './tableSideBarHeader'
import TableSideNavList from './tableSideNavList'
import { ITableSideNav } from '../../../../utils/typescript/multiNavTableType'

const renderSkeleton = (nb) =>
	[...(Array(nb).keys() as any)].map((_, i) => (
		<Skeleton
			key={i}
			variant='rounded'
			animation='wave'
			width='100%'
			height={50}
		/>
	))

const cardCss = {
	borderRadius: 0,
	width: '100%',
	maxWidth: '275px',
	borderRight: 'solid 1px',
	borderColor: ({ colors }) => colors.greySeparator,
	display: 'flex',
	flexDirection: 'column',
	flexGrow: '1',
	pt: 1,
}

interface ITableSideNavProps {
	tableSideNavConfig: ITableSideNav
	queryDataSideNav: any
	loading: boolean
	sideNavIsActive: boolean
}

const TableSideNav: React.FC<ITableSideNavProps> = ({
	tableSideNavConfig,
	queryDataSideNav,
	loading,
	sideNavIsActive,
}) => {
	return (
		<Card elevation={0} sx={cardCss}>
			<TableSideBarHeader disabledStatus={tableSideNavConfig?.disabledStatus} />

			{queryDataSideNav?.length ? (
				<TableSideNavList navListData={queryDataSideNav} />
			) : loading ? (
				<Stack spacing={1} p={1} sx={{ opacity: 0.5 }}>
					{renderSkeleton(8)}
				</Stack>
			) : (
				<Box p={3}>{tableSideNavConfig?.emptyMessage()}</Box>
			)}
			{tableSideNavConfig?.modalCreate &&
				sideNavIsActive &&
				tableSideNavConfig.modalCreate()}
		</Card>
	)
}

export default memo(TableSideNav)
