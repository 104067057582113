/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { useBulkAgencyAction } from '../../../../api/gql/mutations/agencyMutation'
import {
	AGENCIES_FILTER_LIST,
	useExportAgencies,
} from '../../../../api/gql/queries/agencyQueries'
import listAgenciesViewTable from '../../../../components/tableV2/config/dashboard/networkAgency/listAgenciesViewTable'
import { EPermissionScope } from '../../../../utils/app-models'
import {
	ITableToolBarActionConfig,
	ITableToolBarConfig,
} from '../../../../utils/typescript/tableType'

////////////////////////////////////////////////////////////////
// Global variable
////////////////////////////////////////////////////////////////

const queryConfig = {
	query: AGENCIES_FILTER_LIST,
	queryName: 'agenciesAccounts',
}

////////////////////////////////////////////////////////////////
// tableToolBarAction CONFIG
////////////////////////////////////////////////////////////////

const refreshQuerie = ['getAgenciesAccounts', 'getNetworks']

const deletedBulk = {
	mutation: useBulkAgencyAction,
	operation: 'remove',
	successMessageDelete: {
		singular: 'success.agencyDeleted',
		plural: 'success.agencieDeleted',
	},
	messageConfirm: {
		title: 'confirmModal.deleteAgenciesTitle',
		message: 'confirmModal.deleteAgenciesMessage',
	},
	deletePermissions: [
		EPermissionScope.networkDelete,
		EPermissionScope.networkManage,
	],
}

const tableToolBarActionConfig: ITableToolBarActionConfig = {
	refreshQuerie,
	deletedBulk,
}

////////////////////////////////////////////////////////////////
// TableToolBarHeader CONFIG
////////////////////////////////////////////////////////////////

const tableToolBarConfig: ITableToolBarConfig = {
	title: 'agencies.agencies',
	stickyToolBar: true,
	tableConfig: listAgenciesViewTable,
	localStorageName: 'listAgenciesViewTableFilters',
	exportConfig: { exportQuery: useExportAgencies },
	queryConfig,
	createPermission: [EPermissionScope.networkManage],
}

////////////////////////////////////////////////////////////////
// tableContainer CONFIG
////////////////////////////////////////////////////////////////

const tableContainerConfig = {
	tableConfig: listAgenciesViewTable,
	queryConfig,
}

////////////////////////////////////////////////////////////////
// -> EXPORT CONFIG MAIN TABLE
////////////////////////////////////////////////////////////////

export const agencyConfigTable = {
	tableToolBarConfig: tableToolBarConfig,
	tableToolBarActionConfig,
	tableContainerConfig: tableContainerConfig,
	displaySwitchOption: true,
}
