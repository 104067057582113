import { grey } from '@mui/material/colors'

export interface IGlobalColorsTheme {
	delivery: string
	removal: string
	form: string
	agency: string
	intervention: string
	transport: string
	vehicle: string
	alert: string
	site: string
	customer: string
	navy: string
	greySeparator: string
	primary: string
	secondary: string
	productBase: {
		standard: string
		nonStandard: string
		option: string
	}
	workUnit: {
		day: string
		hour: string
		vehicle: string
		event: string
	}
	status: {
		running: string
		missing: string
		pending: string
		waiting: string
		done: string
		toPlan: string
		planned: string
		canceled: string
	}
	vehicleCarburants: {
		GAS: string
		GAZOL: string
		HYBRID: string
		ELECTRIC: string
		GAS_GPL: string
		GAS_BIO: string
		OTHER: string
	}
}

export const GlobalElementsColors = {
	delivery: '#FF4081',
	removal: '#348BD0',
	form: '#B9967F',
	agency: '#7CD9E6',
	intervention: '#B0FACD',
	transport: '#FFCC24',
	vehicle: '#FFC794',
	alert: '#F78787',
	site: '#EFD6FB',
	customer: '#B0CDFA',
	navy: '#132142', // logo's color
}

export const GlobalColorsTheme: IGlobalColorsTheme = {
	...GlobalElementsColors,
	greySeparator: grey[300],
	primary: '#007bff',
	secondary: '#6c757d',
	productBase: {
		standard: '#F9D5FF',
		nonStandard: '#E0E0E0',
		option: '#FFEEC3',
	},
	workUnit: {
		day: '#FFD5D5',
		hour: '#E0FFD5',
		vehicle: '#D5FCFF',
		event: '#D6CAF1',
	},
	status: {
		running: '#7E57C2',
		missing: '#F44336',
		pending: '#EC407A',
		waiting: '#FFCA28',
		done: '#4CAF50',
		toPlan: '#26C6DA',
		planned: '#29B6F6',
		canceled: '#BDBDBD',
	},
	vehicleCarburants: {
		GAS: '#4CAF50',
		GAZOL: '#FFCC24',
		HYBRID: '#5C6BC0',
		ELECTRIC: '#29B6F6',
		GAS_GPL: '#9CCC65',
		GAS_BIO: '#D4E157',
		OTHER: '#BDBDBD',
	},
}
