/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react'
import { EFormModalMode } from '../app-models'

export type IModalContext = {
	isVisible: boolean
	loadingModal: boolean
	canValidate: boolean
	openModal?: any
	closeModal?: any
	resetContext?: any
	setLoadingModal?: any
	setCanValidate?: any
	formUtils?: any
	mode: EFormModalMode
	setMode?: any
	handleDefaultValues?: any
	modalDefaultValues: any
	tabCount?: any
	setTabCount?: any
}

const defaultContextValues: IModalContext = {
	isVisible: false,
	loadingModal: false,
	canValidate: true,
	mode: EFormModalMode.edit,
	modalDefaultValues: null,
}

export const ModalContext: React.Context<IModalContext> =
	createContext(defaultContextValues)
