export default {
	bases: 'Gammes',
	base: 'Gamme',
	product: 'Catalogue produits',
	products: 'Produits',
	addCategoryProduct: 'Ajouter une catégorie de produits',
	editCategoryProduct: 'Modifier une catégorie de produits',
	addCategoryProductBase: 'Ajouter une catégorie de gammes',
	editCategoryProductBase: 'Modifier une catégorie de gammes',
	addProduct: 'Ajouter un produit',
	editProduct: 'Modifier un produit',
	addProductBase: 'Ajouter une gamme de produits',
	editProductBase: 'Modifier une gamme de produits',
	categoryProductTitle: 'Nom de la catégorie',
	categoryProductLabel: 'Catégorie',
	categoryColor: 'Couleur',
	nameProduct: 'Nom du produit',
	nameProductLabel: 'Produit',
	category: 'Catégorie',
	productActive: 'produit actif',
	productBaseActive: 'gamme active',
	addCategory: 'Ajouter une catégorie',
	needCategory: "Vous devez d'abord créer une catégorie",
	myCatalog: 'Mon catalogue',
	sharedCatalog: 'Catalogue partagé',
	descCategoryProduct: 'La description de la catégorie de produits',
	descCategoryProductBase: 'La description de la catégorie de gammes.',
	descProductBase: 'Description de la gamme',
	descProduct: 'Description du produit',
	idGestionProduct: 'ID du produit dans votre outil de gestion (ERP)',
	idGestionProductBase: 'ID de la gamme dans votre outil de gestion (ERP)',

	modalConfirmCategoryProduct: `<span>Désactivation catégorie de produits</span> <br><br>
	Les produits rattachés seront également désactivés, devenant ainsi inaccessibles lors de la création des missions et dans l'application mobile HubeeCheck.<br><br>
	Souhaitez-vous continuer ?
	`,

	modalConfirmCategoryProductBase: `<span>Desactivation d’une catégorie de gammes</span> <br><br>
	Les gammes de cette catégorie et les produits associés seront également désactivés, devenant ainsi inaccessibles lors de la création des missions et dans l'application mobile HubeeCheck.<br><br>
	Souhaitez-vous continuer ?
	`,

	modalConfirmProduct: `<span>Désactivation du produit</span> <br><br>
	Le produit ne sera plus visible par vos équipes.<br><br>
	Souhaitez-vous continuer ?
	`,

	modalConfirmProductBase: `<span>Desactivation de la gamme</span> <br><br>
	Les produits liés à cette gamme seront désactivés, devenant ainsi inaccessibles lors de la création des missions et dans l'application mobile HubeeCheck.<br><br>
	Souhaitez-vous continuer ?
	`,

	unitOfWorkVEH: 'Véhicule',
	unitOfWorkDAY: 'Jour',
	unitOfWorkHOUR: 'Heure',
	unitOfWorkEVENT: 'Évenement',

	baseTypeST: 'Standard',
	baseTypeHS: 'Hors standard',
	baseTypeOP: 'Option',

	emptyCategoriesProduct: 'Créez votre première catégorie de produits.',
	emptyCategoriesProductBase: 'Créez votre première catégorie de gammes.',
	emptyCategoriesProductShared: 'Aucune catégorie.',

	emptyProduct: `<span>Bienvenue dans le catalogue des produits !</span> <br><br>
	Avant de paramétrez vos produits, veuillez d’abord créer une catégorie.
	`,

	emptyProductBase: `<span>Les gammes, à quoi ça sert ?</span> <br><br> 
	Les gammes regroupent des produits identiques attribués à des clients différents, facilitant ainsi le suivi de l'activité générée par un produit catalogue.<br><br>
	Par exemple :<br>
	<strong>Gamme :</strong> Lavage intérieur prestige (durée : 60 min - tarif : 70€)<br>
	<strong>Produits :</strong><br>
	Lavage intérieur prestige (durée : 45 min - tarif : 50€ - clients : A et B)<br>
	Lavage intérieur prestige (durée : 50 min - tarif : 80€ - clients : C)<br><br>
`,

	emptyProductShared: `<span>Bienvenue dans le catalogue des produits !</span> <br><br>
	Aucun produit.
`,

	relatedProduct: 'Produits liés',
	refERP: 'Ref ERP',

	relatedProductBase: 'Produits liés à cette gamme',
	emptyProductBaseDrawer: 'Pas de produit lié à cette gamme',

	productBaseDetails: 'Détails de la gamme',
}
