/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { gql, useMutation } from '@apollo/client'

/**  ---------------------MUTATIONS---------------------- */

export const UPDATE_ADMIN_PANEL_ACCOUNT = gql`
	mutation updateAccount($input: updateAccountInput!) {
		updateAccount(input: $input) {
			account {
				id
			}
		}
	}
`

export const useUpdateAdminPanelAccount = () => useMutation(UPDATE_ADMIN_PANEL_ACCOUNT)
