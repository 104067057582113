/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { gql, useMutation } from '@apollo/client'

/**  ---------------------MUTATIONS---------------------- */

export const UPDATE_CONTACT = gql`
	mutation updateContact($input: updateContactInput!) {
		updateContact(input: $input) {
			contact {
				id
				firstName
				lastName
			}
		}
	}
`

export const CREATE_CONTACT = gql`
	mutation createContact($input: createContactInput!) {
		createContact(input: $input) {
			contact {
				id
				firstName
				lastName
			}
		}
	}
`

export const REMOVE_CONTACT = gql`
	mutation removeContact($input: removeContactInput!) {
		removeContact(input: $input) {
			contact {
				id
			}
		}
	}
`

export const DELETE_CONTACT = gql`
	mutation deleteContact($input: deleteContactInput!) {
		deleteContact(input: $input) {
			contact {
				id
			}
		}
	}
`

export const INVITE_CONTACT = gql`
	mutation inviteContact($input: inviteContactInput!) {
		inviteContact(input: $input) {
			contact {
				id
			}
		}
	}
`

export const BULK_CUSTOMER_CONTACT_ACTION = gql`
	mutation bulkOperationContact($input: bulkOperationContactInput!) {
		bulkOperationContact(input: $input) {
			contact {
				id
			}
		}
	}
`

/**  ---------------------HOOKS---------------------- */
export const useCreateContact = () => useMutation(CREATE_CONTACT)
export const useUpdateContact = () => useMutation(UPDATE_CONTACT)
export const useRemoveContact = () => useMutation(REMOVE_CONTACT)
export const useDeleteContact = () => useMutation(DELETE_CONTACT)
export const useInviteContact = () => useMutation(INVITE_CONTACT)
export const useBulkCustomerContactAction = () =>
	useMutation(BULK_CUSTOMER_CONTACT_ACTION)
