/* eslint-disable no-useless-escape */
import * as yup from 'yup'
import translateIntlMsgString from './translateIntlMsgString'

// const phoneRegExp =
// 	/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const passwordValidationSchema = (
	intlMsg: (str: string) => string
): any =>
	yup.object().shape({
		newPassword: yup
			.string()
			.matches(
				/^.*(?=.{7,})((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
				intlMsg('validationSchema.passwordFormat')
			)
			.required(intlMsg('validationSchema.requirePassword')),
		confirmPassword: yup
			.string()
			.oneOf(
				[yup.ref('newPassword'), null],
				intlMsg('validationSchema.unmatchPassword')
			)
			.required(intlMsg('validationSchema.requirePassword')),
	})

export const passwordChangeSchema = (intlMsg: (str: string) => string): any =>
	yup.object().shape({
		oldPassword: yup
			.string()
			.required(intlMsg('validationSchema.requirePassword')),
		newPassword: yup
			.string()
			.matches(
				/^.*(?=.{7,})((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
				intlMsg('validationSchema.passwordFormat')
			)
			.required(intlMsg('validationSchema.requirePassword')),
		newRetypedPassword: yup
			.string()
			.oneOf(
				[yup.ref('newPassword'), null],
				intlMsg('validationSchema.unmatchPassword')
			)
			.required(intlMsg('validationSchema.requirePassword')),
	})

// Page login *****************************************************
export const loginValidationSchema = (
	intlMsg: (str: string, o?: { value: string }) => string
): any =>
	yup.object().shape({
		email: yup
			.string()
			.email(intlMsg('validationSchema.email'))
			.required(intlMsg('validationSchema.email')),
		password: yup
			.string()
			.min(4, intlMsg('validationSchema.minCharacters', { value: '4' }))
			.required(intlMsg('validationSchema.requirePassword')),
	})

export const profileUpdateValidationSchema = (
	intlMsg: (str: string) => string
): any =>
	yup.object().shape({
		firstName: yup.string().required(intlMsg('validationSchema.require')),
		lastName: yup.string().required(intlMsg('validationSchema.require')),
		email: yup
			.string()
			.required(intlMsg('validationSchema.require'))
			.matches(
				/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
				intlMsg('validationSchema.invalidEmail')
			),
		functionUser: yup.string(),
		phone: yup.string().nullable(),
		cellphone: yup.string().nullable(),
		userType: yup
			.object()
			.required(intlMsg('validationSchema.require'))
			.nullable(),
	})

export const myProfileUpdateValidationSchema = (
	intlMsg: (str: string) => string
): any =>
	yup.object().shape({
		firstName: yup.string().required(intlMsg('validationSchema.require')),
		lastName: yup.string().required(intlMsg('validationSchema.require')),
		functionUser: yup.string(),
		phone: yup.string(),
		cellphone: yup.string(),
	})

export const TeamValidationSchema = (intlMsg: (str: string) => string): any =>
	yup.object().shape({
		name: yup.string().required(intlMsg('validationSchema.require')),
		sites: yup.array(),
		networks: yup.array(),
	})

export const CustomerAccountValidationSchema = (
	intlMsg: (str: string) => string
): any =>
	yup.object().shape({
		name: yup.string().required(intlMsg('validationSchema.require')),
		siret: yup.string().required(intlMsg('validationSchema.require')),
		gestionnaire: yup
			.object()
			.required(intlMsg('validationSchema.require'))
			.nullable(),
	})

export const siteValidationSchema = (intlMsg: (str: string) => string): any =>
	yup.object().shape({
		name: yup.string().required(intlMsg('validationSchema.require')),
		customer: yup
			.string()
			.required(intlMsg('validationSchema.require'))
			.nullable(),
	})

export const CustomerValidationSchema = (
	intlMsg: (str: string) => string
): any =>
	yup.object().shape({
		name: yup.string().required(intlMsg('validationSchema.require')),
	})

export const VehiclesValidationSchema = (
	intlMsg: (str: string, o?: { value: string }) => string
): any =>
	yup.object().shape({
		immat: yup.string().when(['vin'], {
			is: (vin) => !vin,
			then: yup.string().required(intlMsg('validationSchema.immatRequire')),
		}),
		site: yup.string().required(intlMsg('validationSchema.require')),
		customer: yup.string().required(intlMsg('validationSchema.require')),
		location: yup
			.string()
			.max(25, intlMsg('validationSchema.maxCharacters', { value: '25' }))
			.nullable(),
	})

export const ContactValidationSchema = (
	intlMsg: (str: string) => string
): any =>
	yup.object().shape({
		firstName: yup.string().required(intlMsg('validationSchema.require')),
		lastName: yup.string().required(intlMsg('validationSchema.require')),
		email: yup.string().email(intlMsg('validationSchema.email')),
		sites: yup
			.array()
			.required('validationSchema.require')
			.min(1, 'validationSchema.require'),
		customer: yup
			.string()
			.required(intlMsg('validationSchema.require'))
			.nullable(true),
	})

export const ForgottenPasswordFormRequestSchema = (
	intlMsg: (str: string) => string
): any =>
	yup.object().shape({
		email: yup
			.string()
			.email(intlMsg('validationSchema.email'))
			.required(intlMsg('validationSchema.email')),
	})

export const formTemplateValidationSchema = (
	intlMsg: (str: string) => string
): any =>
	yup.object().shape({
		name: yup.string().required(intlMsg('validationSchema.require')),
		title: yup.string().required(intlMsg('validationSchema.require')),
		interventionType: yup
			.string()
			.required(intlMsg('validationSchema.require'))
			.nullable(),
		isActive: yup
			.string()
			.required(intlMsg('validationSchema.require'))
			.nullable(),
	})

export const formTemplateFieldsValidationSchema = (
	intlMsg: (str: string) => string
): any =>
	yup.object().shape({
		titleField: yup.string().required(intlMsg('validationSchema.require')),

		// Field Control -----------------------------------------------------
		verificationType: yup.string().when('canAlert', {
			is: true,
			then: yup.string().required(intlMsg('validationSchema.require')),
		}),
		selectedChoiceToAlert: yup.string().when('canAlert', {
			is: true,
			then: yup.string().required(intlMsg('validationSchema.require')),
		}),
	})

export const importSitesValidationSchema = (
	intlMsg: (str: string) => string
): any =>
	yup.object().shape({
		customer: yup.string().required(intlMsg('validationSchema.require')),
	})

export const importVehiclesValidationSchema = (
	intlMsg: (str: string) => string
): any =>
	yup.object().shape({
		customer: yup.string().required(intlMsg('validationSchema.require')),
		site: yup.string().required(intlMsg('validationSchema.require')),
	})

export const invoiceValidationSchema = (
	intlMsg: (str: string) => string
): any =>
	yup.object().shape({
		reference: yup.string().required(intlMsg('validationSchema.require')),
		totalAmountET: yup.number().required(intlMsg('validationSchema.require')),
		vat: yup.number().required(intlMsg('validationSchema.require')),
	})

export const InterventionValidationSchema = (
	intlMsg: (str: string) => string
): any => {
	return yup.object().shape({
		orderingCustomer: yup
			.string()
			.required(intlMsg('validationSchema.require')),
		orderingSite: yup.string().required(intlMsg('validationSchema.require')),
		finalCustomer: yup
			.string()
			.nullable(true)
			.when('isOrderEqualFinal', {
				is: false,
				then: yup
					.string()
					.nullable(true)
					.when('isManualEntry', {
						is: false,
						then: yup
							.string()
							.nullable()
							.required(intlMsg('validationSchema.require'))
							.nullable(true),
					}),
			})
			.nullable(true),
		isOrderEqualFinal: yup.boolean(),
		isManualEntry: yup.boolean(),
		finalSite: yup
			.string()
			.nullable(true)
			.when('isOrderEqualFinal', {
				is: false,
				then: yup
					.string()
					.nullable(true)
					.when('isManualEntry', {
						is: false,
						then: yup
							.string()
							.nullable()
							.required(intlMsg('validationSchema.require'))
							.nullable(true),
					}),
			})
			.nullable(true),

		dateFrom: yup.date(),
		dateTo: yup.date().min(yup.ref('dateFrom'), intlMsg('errors.errorDateSup')),
	})
}

export const AdminPanelAccount = (): any => yup.object().shape({})

export const formCatalogProductCategorySchema = (
	intlMsg: (str: string) => string
): any => {
	return yup.object().shape({
		name: yup.string().required(intlMsg('validationSchema.require')),
	})
}

export const formCatalogProductSchema = (
	intlMsg: (str: string) => string
): any => {
	return yup.object().shape({
		name: yup.string().required(intlMsg('validationSchema.require')),
		productCategory: yup.string().required(intlMsg('validationSchema.require')),
	})
}

export const networkSchema = (intlMsg: (str: string) => string): any => {
	return yup.object().shape({
		name: yup.string().required(intlMsg('validationSchema.require')),
	})
}

export const mileageSchema = (intlMsg: (str: string) => string): any => {
	return yup.object().shape({
		mileage: yup.number().required(intlMsg('validationSchema.require')),
	})
}

export const agencyStepSchema = yup.object().shape({
	siret: yup
		.string()
		.required(translateIntlMsgString('validationSchema.require'))
		.matches(
			/^[0-9]+$/,
			translateIntlMsgString('validationSchema.invalidSiret')
		)
		.length(14, translateIntlMsgString('validationSchema.invalidSiret')),
	name: yup
		.string()
		.required(translateIntlMsgString('validationSchema.require'))
		.nullable(),
	agencyName: yup
		.string()
		.required(translateIntlMsgString('validationSchema.require'))
		.nullable(),
	address: yup
		.string()
		.required(translateIntlMsgString('validationSchema.require'))
		.nullable(),
	zipcode: yup
		.string()
		.required(translateIntlMsgString('validationSchema.require'))
		.nullable(),
	city: yup
		.string()
		.required(translateIntlMsgString('validationSchema.require'))
		.nullable(),
	country: yup
		.string()
		.required(translateIntlMsgString('validationSchema.require'))
		.nullable(),
})

export const agencyTypeStepSchema = (intlMsg: (str: string) => string): any => {
	return yup.object().shape({
		accountType: yup
			.string()
			.required(intlMsg('validationSchema.agencyTypeRequired')),
	})
}

export const agencyReferentStepSchema = yup.object().shape({
	adminFirstName: yup
		.string()
		.required(translateIntlMsgString('validationSchema.require')),
	adminLastName: yup
		.string()
		.required(translateIntlMsgString('validationSchema.require')),
	adminEmail: yup
		.string()
		.email(translateIntlMsgString('validationSchema.email'))
		.required(translateIntlMsgString('validationSchema.require')),
})

export const vehicleTrackingSchema = (
	intlMsg: (str: string) => string
): any => {
	return yup.object().shape({
		entryDate: yup.date().required(intlMsg('validationSchema.require')),
	})
}

export const locationVehicleSchema = (
	intlMsg: (str: string, o: { value: string }) => string
): any => {
	return yup.object().shape({
		location: yup
			.string()
			.max(25, intlMsg('validationSchema.maxCharacters', { value: '25' }))
			.nullable(),
	})
}

export const documentVehicleValidationSchema = (
	intlMsg: (str: string) => string
): any =>
	yup.object().shape({
		reference: yup.string().required(intlMsg('validationSchema.require')),
		vehicle: yup.string().required(intlMsg('validationSchema.require')),
	})
