import React, { useEffect, useState } from 'react'

import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent/DialogContent'
import DialogActions from '@mui/material/DialogActions'

import { useFormatMessage } from '../../utils/hooks'
import { useModal } from '../../utils/hooks'
import getViewModalAlertConfig from './getViewModalAlertConfig'
import ViewModalAlertFooter from './ViewModalAlertFooter'
import DialogTitle from '../../components/dialog/DialogTitle'
import DialogAlertContentLayout from './DialogAlertContentLayout'

const DialogCSS = {
	'& .MuiCircularProgress-colorPrimary': {
		color: 'white',
	},
}

interface IViewModalAlert {
	location?
}

const ViewModalAlert: React.FC<IViewModalAlert> = ({ location }) => {
	const intlMsg = useFormatMessage()
	const { openModal, redirectAfterCloseModal, isVisible } = useModal()
	const [viewModalAlertConfig, setViewModalAlertConfig] = useState<any>(null)

	useEffect(() => {
		setViewModalAlertConfig(
			getViewModalAlertConfig({ path: location.pathname })
		)
		openModal()
	}, [location])

	return (
		<Dialog
			sx={DialogCSS}
			onClose={redirectAfterCloseModal}
			aria-labelledby='customized-dialog-title'
			open={isVisible}
			fullWidth
			maxWidth='xl'
		>
			<DialogTitle id='modalAlert' onClose={redirectAfterCloseModal}>
				{viewModalAlertConfig && intlMsg(viewModalAlertConfig?.title)}
			</DialogTitle>
			<DialogContent dividers className='dialogContent '>
				<DialogAlertContentLayout
					viewModalAlertConfig={viewModalAlertConfig}
					onClose={redirectAfterCloseModal}
				/>
			</DialogContent>
			<DialogActions>
				<ViewModalAlertFooter />
			</DialogActions>
		</Dialog>
	)
}
export default ViewModalAlert
