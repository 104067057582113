import React from 'react'

import Table from '../../../../components/tableV2'
import {
	IOverrideConfigTable,
	IOverrideConfigTableWithArgument,
	IOverrideTableProvider,
} from '../../../../utils/typescript/tableType'
import { interventionTransportConfigTable } from './IpvTransportConfigTable'

interface IIpvTransport {
	othersQueryParams?
	overrideConfigTable?: IOverrideConfigTable | IOverrideConfigTableWithArgument
	overrideTableProvider?: IOverrideTableProvider
	isInViewModal?: boolean
}

const IpvTransport: React.FC<IIpvTransport> = ({
	othersQueryParams,
	overrideConfigTable,
	overrideTableProvider,
	isInViewModal = false,
}) => {
	return (
		<Table
			tableConfig={interventionTransportConfigTable}
			overrideConfigTable={overrideConfigTable}
			othersQueryParams={othersQueryParams}
			overrideTableProvider={overrideTableProvider}
			isInViewModal={isInViewModal}
		/>
	)
}

export default IpvTransport
