export default {
	log: 'Log',
	user: 'Users',
	site: 'Sites',
	customer: 'Accounts',
	contact: 'Contacts',
	vehicle: 'Vehicles',
	intervention: 'Interventions',
	tool: 'Tools',
	form: 'Forms',
	network: 'Networks',
	alert: 'Alerts',
	mega: 'Mega',
	invoice: 'Expenses',
	product: 'Products',
	document: 'Documents',

	'user:manage': 'User Management',
	'user:manage:explanation': 'Create, edit, and delete users',

	'site:manage': 'Site Management',
	'site:manage:explanation': 'Create, edit, and delete sites',
	'site:view': 'Site View',
	'site:view:explanation': 'View the list and details of sites',
	'site:remove': 'Remove Sites',
	'site:remove:explanation': 'Remove sites',

	'customer:manage': 'Account Management',
	'customer:manage:explanation': 'Create, edit, and delete accounts',
	'customer:view': 'Account View',
	'customer:view:explanation': 'View the list and details of accounts',
	'customer:delete': 'Delete Accounts',
	'customer:delete:explanation': 'Delete accounts',

	'contact:manage': 'Contact Management',
	'contact:manage:explanation': 'Create, edit, and delete contacts',
	'contact:view': 'Contact View',
	'contact:view:explanation': 'View the list and details of contacts',
	'contact:delete': 'Delete Contacts',
	'contact:delete:explanation': 'Delete contacts',
	'contact:invite': 'Contact Invitation',
	'contact:invite:explanation':
		'Share activity related to their account with contacts',

	'vehicle:create': 'Create Vehicles',
	'vehicle:create:explanation': 'Create vehicles',
	'vehicle:update': 'Update Vehicles',
	'vehicle:update:explanation': 'Update vehicles',
	'vehicle:remove': 'Remove Vehicles',
	'vehicle:remove:explanation': 'Remove vehicles',

	'intervention:create': 'Create Interventions',
	'intervention:create:explanation': 'Create interventions',
	'intervention:hot-create': 'Hot Create Interventions',
	'intervention:hot-create:explanation':
		'Create interventions directly from the mobile app',
	'intervention:update': 'Update Interventions',
	'intervention:update:explanation': 'Update interventions',
	'intervention:view': 'Intervention View',
	'intervention:view:explanation': 'View the list and details of interventions',
	'intervention:remove': 'Remove Interventions',
	'intervention:remove:explanation': 'Remove interventions',
	'intervention:cancel': 'Cancel Interventions',
	'intervention:cancel:explanation': 'Mark interventions as "cancelled"',

	'form_template:manage': 'Form Template Management',
	'form_template:manage:explanation': 'Create, edit, and delete form templates',
	'form_template:remove': 'Remove Form Templates',
	'form_template:remove:explanation': 'Remove form templates',
	'form:view': 'Form View',
	'form:view:explanation': 'View the list and results of forms',
	'form:remove': 'Remove Forms',
	'form:remove:explanation': 'Remove forms',

	'network:manage': 'Network and Agency Management',
	'network:manage:explanation':
		'Create, edit, and delete networks and agencies',
	'network:view': 'Network and Agency View',
	'network:view:explanation': 'View the list of networks and their agencies',

	'alert:create': 'Create Alerts',
	'alert:create:explanation': 'Create alerts for vehicles',
	'alert:update': 'Update Alerts',
	'alert:update:explanation': 'Update alerts',
	'alert:view': 'Alert View',
	'alert:view:explanation': 'View the list and details of alerts',
	'alert:remove': 'Archive Alerts',
	'alert:remove:explanation': 'Archive alerts',
	'alert:generate_intervention': 'Generate Interventions from Alerts',
	'alert:generate_intervention:explanation':
		'Generate interventions from alerts to improve tracking',
	'alert:close': 'Close Alerts',
	'alert:close:explanation': 'Mark an alert as resolved',
	'alert:delete': 'Delete Alerts',
	'alert:delete:explanation': 'Delete alerts',

	'invoice:manage': 'Expense Management',
	'invoice:manage:explanation': 'Create, edit, and delete expenses',
	'invoice:view': 'Expense View',
	'invoice:view:explanation': 'View the list and details of vehicle expenses',
	'invoice:remove': 'Remove Expenses',
	'invoice:remove:explanation': 'Remove expenses',

	'product:manage': 'Product Catalog Management',
	'product:manage:explanation':
		'Create, edit, and delete products in the catalog',
	'product:remove': 'Remove Products',
	'product:remove:explanation': 'Remove products',

	'product_base:manage': 'Product base Catalog Management',
	'product_base:manage:explanation':
		'Create, edit, and delete products base in the catalog',

	'movement:view': 'Entry/Exit View',
	'movement:view:explanation': 'View vehicle entry and exit records',
	'movement:manage': 'Entry/Exit Management',
	'movement:manage:explanation': 'Update vehicle entry and exit records',

	'document:manage': 'Document Management',
	'document:manage:explanation': 'Create, edit, and delete documents',
	'document:view': 'Document View',
	'document:view:explanation':
		'View the list and details of documents linked to vehicles',
	'document:remove': 'Remove Documents',
	'document:remove:explanation': 'Remove documents',
}
