/* eslint-disable @typescript-eslint/no-unused-vars */
import { EFormModalMode } from '../../../../../../utils/app-models'
import { baseType, unitsOfWork } from '../../../../../../utils/const'

export const formatProductBaseModalEditDefaultValues = (data) => {
	const {
		__typename,
		customers,
		productBaseCategory,
		reference,
		vat,
		unitOfWork,
		type,
		...rest
	} = data

	return {
		...rest,
		productBaseCategory: {
			key: productBaseCategory.id,
			label: productBaseCategory.name,
			value: productBaseCategory,
		},
		...(unitOfWork && {
			unitOfWork: unitsOfWork.filter(
				({ value }) => unitOfWork === value?.name
			)?.[0],
		}),
		...(type && {
			type: baseType.filter(({ value }) => type === value?.name)?.[0],
		}),
	}
}

export const formatProductBaseModalCreateDefaultValues = ({
	defaultCategory,
}) => {
	return {
		isActive: true,
		productBaseCategory: {
			key: defaultCategory.id,
			label: defaultCategory.name,
			value: defaultCategory,
		},
	}
}

export const formatProductBaseModalOnValidateData = ({
	data,
	mode,
	productBaseId,
}) => {
	const { type, productBaseCategory, unitOfWork, ...rest } = data || {}

	const specificModeFields =
		mode === EFormModalMode.edit ? { id: productBaseId } : {}

	return {
		...specificModeFields,
		...rest,
		...(productBaseCategory && {
			productBaseCategory: productBaseCategory?.value?.id,
		}),
		...(unitOfWork && { unitOfWork: unitOfWork?.value?.name }),
		...(type && { type: type?.value?.name }),
	}
}
