/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'

import toast from 'react-hot-toast'

import api from '../../api/axios/axios'
import { InputText, CheckBoxOrSwitch } from '../../components'

// Submit forms ****************************************************************
export const onSubmit = async (data, e, setLoading, getProfileUserId) => {
	e.preventDefault()

	const { email, password } = data

	setLoading(true)
	await api
		.post('/token_authentication', {
			username: email,
			password,
		})
		.then((response) => {
			localStorage.setItem('token', response.data.token)
			localStorage.setItem('refreshToken', response.data.refresh_token)
			getProfileUserId()
		})
		.catch((error) => {
			if (error?.response?.data?.detail === 'errors.userNotEnabled') {
				toast.error('errors.userNotEnabled')
			} else if (error?.response?.status === 401) {
				toast.error('errors.invalidCredentials')
			} else {
				toast.error('errors.genericError')
			}
		})
	setLoading(false)
}

// Input forms *****************************************************************
export const Email = ({ forms, ...rest }) => {
	return (
		<InputText
			key={'email'}
			name='email'
			control={forms}
			isrequired
			{...rest}
		/>
	)
}

export const Password = ({ forms, ...rest }) => {
	return (
		<InputText
			key={'password'}
			name='password'
			type='password'
			control={forms}
			isrequired
			{...rest}
		/>
	)
}

export const Remember = ({ forms, ...rest }) => {
	return (
		<CheckBoxOrSwitch name='rememberMe' control={forms} {...rest} isCheckbox />
	)
}
