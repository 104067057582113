/**
 * Configuration array for defining the view structure of an invoice list table.
 * Each object in the array represents a column in the table, with settings for
 * permissions, sorting, and custom rendering of the cell content.
 */

import React from 'react'
import { Box } from '@mui/material'

import { useRemoveInvoice } from '../../../../../api/gql/mutations/invoicesMutation'
import { EPermission, EPermissionScope } from '../../../../../utils/app-models'
import { formatDate } from '../../../../../utils/date'
import InvoiceModal from '../../../../../view/dashboard/invoice/invoiceModal'
import ActionsBtn from '../../../tableCell/actionsBtn'
import OpenLinkCell from '../../../tableCell/OpenLinkCell'
import TimeCell from '../../../tableCell/TimeCell'
import UploadAwsFile from '../../../tableCell/UploadAwsFile'
import InterventionType from '../../../tableCell/InterventionType'

import { vmLinkByInterventionType } from '../../../../../routes/linkViewModal'
import UserAvatarSlidePanel from '../../../../avatar/UserAvatarSlidePanel'
import { ROUTES } from '../../../../../routes/consts'

const listInvoicesViewTable = [
	{
		columnLabel: 'misc.generalAccount',
		permissions: [EPermission.superMegaAdmin],
		personalizedCell: ({ account }) => <Box>{account?.name}</Box>,
	},

	{
		columnLabel: 'invoice.title',
		orderBy: 'reference',
		stickyFirstCol: true,
		personalizedCell: ({ reference }) => <div>{reference}</div>,
	},

	{
		columnLabel: 'misc.mission',
		orderBy: 'intervention_reference',
		personalizedCell: ({ intervention }) =>
			intervention ? (
				<OpenLinkCell
					viewModalLink={vmLinkByInterventionType(
						intervention?.interventionType,
						intervention?.id
					)}
					txt={intervention?.reference || '-'}
				/>
			) : (
				'-'
			),
	},

	{
		columnLabel: 'misc.missionType',
		personalizedCell: ({ intervention }) => (
			<InterventionType interventionType={intervention?.interventionType} />
		),
	},

	{
		columnLabel: 'misc.vehicle',
		orderBy: 'invoiceVehicles_vehicle_vehicleDetail_immat',
		personalizedCell: ({ invoiceVehicles: { collection } }) => (
			<>
				{collection?.[0]?.vehicle?.vehicleDetail?.id ? (
					<OpenLinkCell
						txt={
							collection?.[0]?.vehicle?.vehicleDetail?.immat ||
							collection?.[0]?.vehicle?.vehicleDetail?.vin
						}
						viewModalLink={`${ROUTES.VIEWMODAL.VEHICLES.url}${collection?.[0]?.vehicle?.id}`}
					/>
				) : (
					'-'
				)}
			</>
		),
	},
	{
		columnLabel: 'invoice.priceHT',
		orderBy: 'totalAmountET',
		personalizedCell: ({ totalAmountET }) => (
			<>{`${totalAmountET ? totalAmountET.toFixed(2) + '€' : '-'} `} </>
		),
	},

	{
		columnLabel: 'invoice.priceTTC',
		// orderBy: 'totalAmountET',
		personalizedCell: ({ totalAmountET, vat }) => (
			<>
				{`${
					totalAmountET
						? (totalAmountET + (totalAmountET * vat) / 100).toFixed(2) + '€'
						: '-'
				} `}
			</>
		),
	},

	{
		columnLabel: 'invoice.tva',
		orderBy: 'vat',
		personalizedCell: ({ vat }) => <>{vat}%</>,
	},

	{
		columnLabel: 'misc.addAt',
		orderBy: 'dateCreated',
		personalizedCell: ({ dateCreated }) => (
			<TimeCell date={dateCreated ? formatDate(dateCreated) : '-'} />
		),
	},

	{
		columnLabel: 'invoice.dueDate',
		orderBy: 'dueDate',
		personalizedCell: ({ dueDate }) => (
			<TimeCell date={dueDate ? formatDate(dueDate) : '-'} />
		),
	},

	{
		columnLabel: 'invoice.addBy',
		orderBy: 'createdBy_lastName',
		personalizedCell: ({ createdBy }) => (
			<UserAvatarSlidePanel
				firstName={createdBy?.firstName}
				lastName={createdBy?.lastName}
				userId={createdBy?.id}
				displayName={false}
			/>
		),
	},

	{
		columnLabel: 'invoice.document',
		orderBy: 'file',
		personalizedCell: ({ id, file }) => file && <UploadAwsFile id={id} />,
	},

	{
		fixWidth: '50px',
		columnLabel: '',
		visible: true,
		tableCellAlign: 'center',
		cellAlign: 'center',
		sticky: true,
		personalizedCell: ({ id }: any) => (
			<ActionsBtn
				rowId={id}
				deleted={{
					// mutation to be called for delete action
					query: useRemoveInvoice,
					// list of queries to refetch after a successful delete
					refreshQuery: [
						'getInvoicesCountByVehicle',
						'getInvoicesList',
						'getInvoicesCountByIntervention',
					],
					successMessageDelete: 'success.invoiceDeleted',
					messageConfirm: {
						title: 'confirmModal.deleteInvoiceTitle',
						message: 'confirmModal.deleteInvoiceMessage',
					},
				}}
				viewAction={false}
				editModal={<InvoiceModal invoiceId={id} modalUtils={{} as any} />}
				permissions={{ update: [EPermissionScope.invoiceManage] }}
			/>
		),
	},
]

export default listInvoicesViewTable
