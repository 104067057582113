import {
	gql,
	OperationVariables,
	QueryHookOptions,
	useLazyQuery,
	useQuery,
} from '@apollo/client'
import translateIntlMsgString from '../../../utils/translateIntlMsgString'
import {
	listDocumentsVehiclesAttributes,
	documentVehicleForModalAttributes,
} from '../attributes/documentsVehiclesAttribute'

export const GET_DOCUMENTS_VEHICLES = gql`
query getDocumentsVehicles(
    $page: Int
    $itemsPerPage: Int
    $searchText: String
    $immatOrVin: String
	$dateCreated: [DocumentFilter_dateCreated]
    $documentVehicles_vehicle:String
    $isDeleted:Boolean
    $documentVehicles_vehicle_list:[String]
    $createdBy: String
    $createdBy_list: [String]
    $orderBy: [DocumentFilter_orderBy]
	$vehicle: String
	$intervention: String
	$intervention_interventionType: String
	$customer_list: Iterable
) {
    documents(
     page: $page, 
     itemsPerPage: $itemsPerPage,
     searchText: $searchText, 
     immatOrVin: $immatOrVin, 
     dateCreated:$dateCreated,
     isDeleted:$isDeleted,
     documentVehicles_vehicle:$documentVehicles_vehicle,
     documentVehicles_vehicle_list:$documentVehicles_vehicle_list,
     createdBy:$createdBy,
     createdBy_list:$createdBy_list
     orderBy:$orderBy
	 vehicle: $vehicle
	 intervention: $intervention
	 intervention_interventionType: $intervention_interventionType
	 customer_list: $customer_list
     ) {
        ${listDocumentsVehiclesAttributes}
    }
}`

export const DOCUMENTS_VEHICLES_COUNT_BY_VEHICLE = gql`
	query getDocumentsVehicleCountByVehicle($vehicle: String) {
		documents(page: 1, itemsPerPage: 100000, vehicle: $vehicle) {
			paginationInfo {
				totalCount
			}
		}
	}
`

export const DOCUMENTS_VEHICLES_COUNT_BY_INTERVENTION = gql`
	query getDocumentsVehicleCountByIntervention($intervention: String) {
		documents(page: 1, itemsPerPage: 100000, intervention: $intervention) {
			paginationInfo {
				totalCount
			}
		}
	}
`

export const DOCUMENT_VEHICLE_INVOICE_FOR_MODAL = gql`
	query documentForModal($id: ID!) {
		document(id: $id) {
			${documentVehicleForModalAttributes}
		}
	} 
`
export const GET_DOCUMENT_CATEGORIES_LIST = gql`
	query getDocumentCategoriesList {
		documentCategories {
			id
			name
			code
		}
	}
`

/**  ---------------------HOOKS---------------------- */

export const useDocumentCategoriesList = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { loading, data } = useQuery(GET_DOCUMENT_CATEGORIES_LIST, options)

	return {
		loading,
		documentCategories:
			data?.documentCategories?.map((category) => ({
				key: category.id,
				label: translateIntlMsgString(`documentVehicle.${category.code}`),
				value: category.id,
			})) || [],
	}
}

export const useLazyDocumentCategoriesList = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData] = useLazyQuery(GET_DOCUMENT_CATEGORIES_LIST, options)
	return { getCategoryDocumentVehicle: getData }
}

export const useLazyDocumentVehicleForModal: any = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(
		DOCUMENT_VEHICLE_INVOICE_FOR_MODAL,
		options
	)

	return {
		loading,
		documentVehicle: data?.document || null,
		getDocumentVehicle: getData,
	}
}
