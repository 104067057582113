import { yupResolver } from '@hookform/resolvers/yup'
import React, { memo, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { EFormModalMode } from '../utils/app-models'

import { ModalContext } from '../utils/contexts/ModalContext'
import { useFormatMessage, useModal } from '../utils/hooks'

interface IModalProviderProps {
	children?
	validationSchemaFunction: any
	formModalMode?
	open?: boolean
}

const ModalProvider: React.FC<IModalProviderProps> = ({
	children,
	validationSchemaFunction,
	formModalMode,
	open = false,
}) => {
	const [loadingModal, setLoadingModal] = useState(false)
	const [canValidate, setCanValidate] = useState(true)
	const [tabCount, setTabCount] = useState({}) // example : setTabCount({ nbVehiclesSelected: 15 }) , it's optional, to use on a tab, you need to edit getModalContentConfig and add under the label, the selector  :  selectorTabCount: 'nbVehiclesSelected',
	const { isVisible, openModal, closeModal } = useModal(open)
	const [modalDefaultValues, setModalDefaultValues] = useState<any>(null)
	const [mode, setMode] = useState(formModalMode || EFormModalMode.edit)
	const intlMsg = useFormatMessage()

	const formUtils = useForm({
		resolver: yupResolver(validationSchemaFunction(intlMsg)),
	})

	const handleDefaultValues = (newDefaultValues) => {
		setModalDefaultValues({ ...modalDefaultValues, ...newDefaultValues })
	}

	const resetContext = () => {
		formUtils.reset()
		setLoadingModal(false)
		setMode(mode || formModalMode)
	}

	const context = useMemo(
		() => ({
			isVisible,
			openModal,
			loadingModal,
			closeModal,
			resetContext,
			setLoadingModal,
			formUtils,
			canValidate,
			setCanValidate,
			mode,
			setMode,
			handleDefaultValues,
			modalDefaultValues,
			tabCount,
			setTabCount,
		}),
		[
			isVisible,
			loadingModal,
			formUtils,
			canValidate,
			mode,
			modalDefaultValues,
			handleDefaultValues,
			resetContext,
			tabCount,
		]
	)

	return (
		<ModalContext.Provider value={context}>{children}</ModalContext.Provider>
	)
}

export default memo(ModalProvider)
