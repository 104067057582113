import React, { useMemo } from 'react'
import { Box, Tooltip, IconButton } from '@mui/material'
import { useFormatMessage } from '../../utils/hooks'
import { stringifiedColor } from '../../utils/color'

const iconButtonCSS = { p: '7px', cursor: 'pointer' }

interface IIconTooltips {
	txt: string
	txtSize?: string
	Icon?: any
	color?: string
	fontSize?: string
	typeBtn?: boolean
}

const IconTooltips: React.FC<IIconTooltips> = ({
	txt,
	txtSize = '0.8rem',
	Icon,
	color,
	fontSize,
	typeBtn = true,
}) => {
	const intlMsg = useFormatMessage()

	const iconStyle = useMemo(
		() => ({
			color: stringifiedColor(color || 'grey', 600) as any,
		}),
		[color]
	)

	return (
		<>
			{txt ? (
				<Tooltip
					componentsProps={{ tooltip: { sx: { fontSize: txtSize } } }}
					title={intlMsg(txt)}
				>
					{typeBtn ? (
						<IconButton sx={iconButtonCSS} size='large'>
							<Icon fontSize={fontSize} style={iconStyle} />
						</IconButton>
					) : (
						<Icon fontSize={fontSize} style={iconStyle} />
					)}
				</Tooltip>
			) : (
				<Box sx={{ p: 1 }}>-</Box>
			)}
		</>
	)
}

export default IconTooltips
