import React from 'react'

import { List, Typography } from '@mui/material'

import { useFormatMessage } from '../../../../utils/hooks'
import ModalProvider from '../../../../providers/ModalProvider'
import { networkSchema } from '../../../../utils/validationSchema'
import { EFormModalMode, EPermissionScope } from '../../../../utils/app-models'
import PermissionsHandler from '../../../../components/permissionsHandler'
import NetworksListItem from './NetworksListItem'
import CreateNetworkButton from '../modaNetwork/CreateNetworkButton'

interface INetworksList {
	networks: any
	selectedNetworkId: string
	setSelectedNetworkId: string
	setNetworkIsActive: boolean
	changeNetworkPage
}

const NetworksList: React.FC<INetworksList> = ({
	networks,
	changeNetworkPage,
	selectedNetworkId,
	setSelectedNetworkId,
	setNetworkIsActive,
}) => {
	const intlMsg = useFormatMessage()

	return (
		<>
			<List sx={{ overflowY: 'auto', flexGrow: '1' }}>
				{networks?.map(({ name, id, isActive, agencies }) => (
					<div key={id}>
						<NetworksListItem
							canBeDeleted={!agencies?.paginationInfo?.totalCount}
							name={name}
							id={id}
							isActive={isActive}
							changeNetworkPage={changeNetworkPage}
							selectedNetworkId={selectedNetworkId}
							setSelectedNetworkId={setSelectedNetworkId}
							setNetworkIsActive={setNetworkIsActive}
						/>
					</div>
				))}
			</List>

			{!networks?.length ? (
				<Typography sx={{ textAlign: 'center' }} variant='body2'>
					{intlMsg('agencies.addNetwork')}
				</Typography>
			) : (
				<></>
			)}

			<PermissionsHandler permissionsName={[EPermissionScope.networkManage]}>
				<ModalProvider
					//TODO HERE OLD VERSION OF ModalProvider
					//WE NEED TO CHANGE THIS ON PRODUCTS REFACTO
					validationSchemaFunction={networkSchema}
					formModalMode={EFormModalMode.create}
				>
					<CreateNetworkButton
						setSelectedNetworkId={setSelectedNetworkId}
						setNetworkIsActive={setNetworkIsActive}
					/>
				</ModalProvider>
			</PermissionsHandler>
		</>
	)
}

export default NetworksList
