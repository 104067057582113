/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/display-name */
import React from 'react'

import { Chip, Typography } from '@mui/material'
import OpenLinkCell from '../../../tableCell/OpenLinkCell'
import { ROUTES } from '../../../../../routes/consts'
import { formatDate } from '../../../../../utils/date'
import TimeCell from '../../../tableCell/TimeCell'
import BtnDownloadFormPDF from '../../../../pdfGenerator/btnDownloadPdf/BtnDownloadFormPDF'
import { useRemoveInterventionForms } from '../../../../../api/gql/mutations/interventionFormsMutation'
import ActionsBtn from '../../../tableCell/actionsBtn'
import {
	EAccountType,
	EModule,
	EPermissionScope,
	EPermission,
} from '../../../../../utils/app-models'
import BtnDownloadFormPDFV2Link from '../../../../pdfGenerator/btnDownloadPdf/BtnDownloadFormPDFV2Link'
import { Box } from '@mui/material'
import { vmLinkByInterventionType } from '../../../../../routes/linkViewModal'
import UserAvatarSlidePanel from '../../../../avatar/UserAvatarSlidePanel'
import InterventionType from '../../../tableCell/InterventionType'

const listInterventionControlFormsViewTable = [
	{
		columnLabel: 'misc.generalAccount',
		permissions: [EPermission.superMegaAdmin],
		personalizedCell: ({ createdByAccount }) => (
			<Box>{createdByAccount?.name}</Box>
		),
	},
	{
		columnLabel: 'misc.reference',
		orderBy: 'reference',
		stickyFirstCol: true,
		personalizedCell: ({ reference, id }) => (
			<OpenLinkCell
				viewModalLink={`${ROUTES.VIEWMODAL.INTERVENTION_FORMS.url}${id}`}
				txt={reference || '-'}
			/>
		),
	},

	{
		columnLabel: 'misc.title',
		orderBy: 'formTemplate_title',
		personalizedCell: ({ formTemplate, id }) => (
			<OpenLinkCell
				viewModalLink={`${ROUTES.VIEWMODAL.INTERVENTION_FORMS.url}${id}`}
				txt={formTemplate?.title || '-'}
			/>
		),
	},

	{
		columnLabel: 'interventionForms.model',
		orderBy: 'formTemplate_name',
		personalizedCell: ({ formTemplate }) => (
			<Typography variant='caption'>{formTemplate?.name || '-'}</Typography>
		),
	},

	{
		columnLabel: 'misc.account',
		modules: [EModule.crm],
		orderBy: 'customer_name',
		personalizedCell: ({ customer }) => (
			<OpenLinkCell
				viewModalLink={`${ROUTES.VIEWMODAL.CUSTOMERS.url}${customer?.id}`}
				txt={customer?.name}
			/>
		),
	},

	{
		columnLabel: 'misc.site',
		orderBy: 'site_name',
		personalizedCell: ({ site }) => (
			<OpenLinkCell
				viewModalLink={`${ROUTES.VIEWMODAL.SITE.url}${site?.id}`}
				txt={site?.name}
				maxCharacters={40}
				modules={[EModule.site]}
			/>
		),
	},

	{
		columnLabel: 'misc.vehicle',
		orderBy: 'vehicle_vehicleDetail_immat',
		personalizedCell: ({
			vehicle: {
				id,
				vehicleDetail: { immat, vin },
			},
		}) => (
			<>
				{immat || vin ? (
					<OpenLinkCell
						txt={immat || vin}
						viewModalLink={`${ROUTES.VIEWMODAL.VEHICLES.url}${id}`}
					/>
				) : (
					'-'
				)}
			</>
		),
	},

	{
		columnLabel: 'misc.alerts',
		personalizedCell: ({
			alerts: {
				paginationInfo: { totalCount },
			},
		}) => (
			<Chip
				size='small'
				color={totalCount === 0 ? 'default' : 'warning'}
				disabled={totalCount === 0 || false}
				label={totalCount}
			/>
		),
	},

	{
		columnLabel: 'misc.addAt',
		orderBy: 'dateCreated',
		tableCellAlign: 'center',
		cellAlign: 'center',
		personalizedCell: ({ dateCreated }) => (
			<TimeCell date={dateCreated ? formatDate(dateCreated, true) : '-'} />
		),
	},

	{
		columnLabel: 'misc.doBy',
		orderBy: 'createdBy_lastName',
		accountsTypeDenied: [EAccountType.guest],
		personalizedCell: ({ createdBy }) => (
			<UserAvatarSlidePanel
				firstName={createdBy?.firstName}
				lastName={createdBy?.lastName}
				userId={createdBy?.id}
				accountId={createdBy?.account?.id}
				displayName={false}
			/>
		),
	},

	{
		columnLabel: 'misc.mission',
		orderBy: 'intervention_reference',
		personalizedCell: ({
			intervention: { reference, id, interventionType },
		}) => (
			<OpenLinkCell
				viewModalLink={vmLinkByInterventionType(interventionType, id)}
				txt={reference || '-'}
			/>
		),
	},

	{
		columnLabel: 'misc.missionType',
		personalizedCell: ({ intervention }) => (
			<InterventionType interventionType={intervention?.interventionType} />
		),
	},

	{
		columnLabel: 'misc.download',
		visible: true,
		personalizedCell: ({ id, reference, oldVersionReference }) => (
			<>
				{oldVersionReference ? (
					<BtnDownloadFormPDFV2Link oldVersionReference={oldVersionReference} />
				) : (
					<BtnDownloadFormPDF id={id} reference={reference} />
				)}
			</>
		),
	},

	{
		fixWidth: '50px',
		columnLabel: '',
		visible: true,
		tableCellAlign: 'center',
		cellAlign: 'center',
		sticky: true,
		personalizedCell: ({ id }: any) => (
			<ActionsBtn
				rowId={id}
				deleted={{
					query: useRemoveInterventionForms,
					refreshQuery: ['getListFormInterventions'],
					successMessageDelete: 'success.interventionFormDeleted',
					messageConfirm: {
						title: 'confirmModal.deleteInterventionFormTitle',
						message: 'confirmModal.deleteInterventionFormMessage',
					},
				}}
				viewModalLink={`${ROUTES.VIEWMODAL.INTERVENTION_FORMS.url}${id}`}
				permissions={{
					remove: [EPermissionScope.formRemove],
					delete: [EPermissionScope.formDelete],
				}}
			/>
		),
	},
]

export default listInterventionControlFormsViewTable
