/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react'

export type IFiltersBtnContext = {
	tmpFilters: any
	setTmpFilters: (newTmpFilters: any) => void
	setTmpFilter: (newTmpFilter: any) => void
	resetTmpFilters: () => void
	handleOpen: (e: any) => void
	handleClose: () => void
	activeFiltersNumber: number
	anchorEl: any
	filtersComponent: any
}

export const FiltersBtnContext: React.Context<IFiltersBtnContext> =
	createContext({} as IFiltersBtnContext)
