/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { gql, useMutation } from '@apollo/client'
import {
	removeFormTemplatePayloadAttributes,
	formTemplatePayload,
	updateFormTemplatePayloadAttributes,
} from '../attributes/formTemplateAttributes'

/**  ---------------------MUTATIONS---------------------- */

export const CREATE_FORM_TEMPLATE = gql`
	mutation createFormTemplate($input: saveFormTemplateInput!) {
		saveFormTemplate(input: $input) {
			${formTemplatePayload}
		}
	}`

export const UPDATE_STATUS_FORM_TEMPLATE = gql`
	mutation updateStatusFormTemplate($input: updateStatusFormTemplateInput!) {
		updateStatusFormTemplate(input: $input) {
			${updateFormTemplatePayloadAttributes}
		}
	}`

export const DELETE_FORM_TEMPLATE = gql`
	mutation removeFormTemplate($input: removeFormTemplateInput!) {
		removeFormTemplate(input: $input) {
			${removeFormTemplatePayloadAttributes}
		}
	}
`
export const DUPLICATE_FORM_TEMPLATE = gql`
	mutation duplicateFormTemplate($input: duplicateFormTemplateInput!) {
		duplicateFormTemplate(input: $input) {
			${removeFormTemplatePayloadAttributes}
		}
	}
`

export const BULK_FORM_TEMPLATE_ACTION = gql`
	mutation bulkOperationFormTemplate($input: bulkOperationFormTemplateInput!) {
		bulkOperationFormTemplate(input: $input) {
			${removeFormTemplatePayloadAttributes}
		}
	}
`

export const useCreateFormTemplate = () => useMutation(CREATE_FORM_TEMPLATE)
export const useUpdateStatusFormTemplate = () =>
	useMutation(UPDATE_STATUS_FORM_TEMPLATE)
export const useRemoveFormTemplate = () => useMutation(DELETE_FORM_TEMPLATE)
export const useDuplicateFormTemplate = () =>
	useMutation(DUPLICATE_FORM_TEMPLATE)
export const useBulkFormTemplatetAction = () =>
	useMutation(BULK_FORM_TEMPLATE_ACTION)
