import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'

import { useLazyQuery } from '@apollo/client'
import { Box, Stack, Typography } from '@mui/material'
import { useFormatMessage } from '../../../../../../../utils/hooks'
import { useGetCustomerListForSelect } from '../../../../../../../api/gql/queries/customersQueries'
import {
	FormModalContext,
	IFormModalContext,
} from '../../../../../../../components/forms/formModal/FormModalContext'
import { GET_SITES_BY_CUSTOMER_ID } from '../../../../../../../api/gql/queries/sitesQueries'
import { GET_CONTACT_LIST_FOR_SELECT } from '../../../../../../../api/gql/queries/contactsQueries'
import PermissionsHandler from '../../../../../../../components/permissionsHandler'
import {
	EFormModalMode,
	EModule,
	EPermissionScope,
} from '../../../../../../../utils/app-models'
import FormSelectSearch from '../../../../../../../components/forms/formFields/formSelectSearch'
import CreateContactLink from './CreateContactLink'
import { AuthContext } from '../../../../../../../utils/contexts/AuthContext'

interface IServiceRequest {}

const ServiceRequest: React.FC<IServiceRequest> = () => {
	const intlMsg = useFormatMessage()
	const { data, setValues, setTabCounter, curentEditedElement, mode } =
		useContext<IFormModalContext>(FormModalContext)
	const { currentUser } = useContext(AuthContext)

	const [selectedCustomer, setSelectedCustomer] = useState(
		data?.orderingCustomer?.value?.id || null
	)
	const [selectedSite, setSelectedSite] = useState(
		data?.orderingSite?.value?.id || null
	)

	const { customers } = useGetCustomerListForSelect()
	const [getSites, { data: dataSites }] = useLazyQuery(GET_SITES_BY_CUSTOMER_ID)
	const [getContacts, { data: dataContacts }] = useLazyQuery(
		GET_CONTACT_LIST_FOR_SELECT
	)

	const isAccountOwner = useMemo(
		() =>
			mode !== EFormModalMode?.edit ||
			curentEditedElement?.createdByAccount?.id === currentUser?.account?.id,
		[curentEditedElement, currentUser]
	)

	const customersOptions = useMemo(
		() =>
			customers?.map((customer) => ({
				key: customer?.id,
				label: customer?.name,
				value: customer,
			})) || [],
		[customers]
	)

	const sitesOptions = useMemo(
		() =>
			dataSites?.sites?.collection?.map((site) => ({
				key: site?.id,
				label: site?.name,
				value: site,
			})) || [],
		[dataSites]
	)

	const contactsOptions = useMemo(
		() =>
			dataContacts?.contacts?.collection?.map((contact) => ({
				key: contact?.id,
				label: `${contact?.firstName} ${contact?.lastName}`,
				value: contact,
			})) || [],
		[dataContacts]
	)

	useEffect(() => {
		if (selectedCustomer)
			getSites({
				variables: {
					customer: selectedCustomer,
				},
			})
	}, [selectedCustomer])

	useEffect(() => {
		if (selectedSite)
			getContacts({
				variables: {
					sites: selectedSite,
				},
			})
	}, [selectedSite])

	const handleSelect = useCallback(
		({ fieldName, option }) => {
			if (fieldName === 'orderingCustomer') {
				setValues({
					orderingCustomer: option,
					orderingSite: null,
					orderingContact: null,
					globalFormTemplates: null,
				})
				setSelectedCustomer(option?.value?.id)
				setTabCounter({ nbVehiclesSelected: 0 })
			} else if (fieldName === 'orderingSite') {
				setSelectedSite(option?.value?.id)
				setValues({
					orderingSite: option,
					orderingContact: null,
					vehicleList: [],
					selectedVehicles: [],
				})
				setTabCounter({ nbVehiclesSelected: 0 })
			}
		},
		[setValues]
	)

	return (
		<Box>
			<Typography variant='h2' gutterBottom mb={2}>
				{intlMsg('interventions.serviceRequest')}
			</Typography>

			<Stack spacing={2}>
				<FormSelectSearch
					name='orderingCustomer'
					label={intlMsg('misc.account')}
					specialOnChange={handleSelect}
					selectElements={customersOptions}
					context={FormModalContext}
					missingText={intlMsg('misc.noResultSearch')}
					isDisabled={!isAccountOwner}
					isRequired
					trigerred
				/>
				<FormSelectSearch
					name='orderingSite'
					label={intlMsg('misc.sites')}
					specialOnChange={handleSelect}
					selectElements={sitesOptions}
					context={FormModalContext}
					missingText={intlMsg('interventions.noKnownSite')}
					isDisabled={!data['orderingCustomer'] || !isAccountOwner}
					isRequired
					trigerred
				/>
				<PermissionsHandler modulesName={[EModule.crm]}>
					<FormSelectSearch
						name='orderingContact'
						label={intlMsg('misc.contact')}
						selectElements={contactsOptions}
						context={FormModalContext}
						missingText={intlMsg('interventions.noKnownContact')}
						isDisabled={!data['orderingSite'] || !isAccountOwner}
					/>
					<PermissionsHandler
						permissionsName={[EPermissionScope.contactManage]}
					>
						<CreateContactLink
							forcedCustomerId={data['orderingCustomer']?.value?.id}
							forcedSiteId={data['orderingSite']?.value?.id}
							name='orderingContact'
							isDisabled={!data['orderingSite'] || !isAccountOwner}
						/>
					</PermissionsHandler>
				</PermissionsHandler>
			</Stack>
		</Box>
	)
}
export default ServiceRequest
