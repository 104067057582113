/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'

import ActionsBtn from '../../../tableCell/actionsBtn/v1'
import { EAccountType, EPermissionScope } from '../../../../../utils/app-models'
import { formatDate } from '../../../../../utils/date'
import LabelColorCell from '../../../tableCell/LabelColor'
import ActionPopoverBtn from '../../../../popover/actionPopover'
import { useAlertStatusesList } from '../../../../../api/gql/queries/alertsQueries'
import SourceAlert from '../../../tableCell/SourceAlert'
import {
	useRemoveAlert,
	useUpdateAlertStatuses,
} from '../../../../../api/gql/mutations/alertesMutation'
import TimeCell from '../../../tableCell/TimeCell'
import UserAvatarSlidePanel from '../../../../avatar/UserAvatarSlidePanel'

//!  columnLabel is mandatory
//!  dataKey OR personalizedCell is mandatory
// use datakey if you want to just display data
// personalized Cell is a function taking row as parameter

const vehiclesListAlertsViewModalTable = [
	{
		columnLabel: 'misc.reference',
		orderBy: 'reference',
		dataKey: 'reference',
		maxCharacters: 15,
		stickyFirstCol: true,
	},

	{
		columnLabel: 'alerts.alertName',
		orderBy: 'name',
		dataKey: 'name',
		maxCharacters: 15,
	},

	{
		columnLabel: 'alerts.alertCategory',
		orderBy: 'category_name',
		personalizedCell: ({ category }) => <LabelColorCell category={category} />,
	},

	{
		columnLabel: 'alerts.alertOpen',
		orderBy: 'dateCreated',
		personalizedCell: ({ dateCreated }) => (
			<TimeCell date={dateCreated ? formatDate(dateCreated) : '-'} />
		),
	},

	{
		columnLabel: 'alerts.alertClose',
		orderBy: 'dateTreated',
		personalizedCell: ({ dateTreated }) => (
			<TimeCell date={dateTreated ? formatDate(dateTreated) : '-'} />
		),
	},

	{
		columnLabel: 'alerts.alertSource',
		orderBy: 'form_reference',
		personalizedCell: ({ form }) => <SourceAlert form={form} />,
	},

	{
		columnLabel: 'misc.createdBy',
		accountsTypeDenied: [EAccountType.guest],
		orderBy: 'createdBy_lastName',
		personalizedCell: ({ createdBy }) => (
			<UserAvatarSlidePanel
				firstName={createdBy?.firstName}
				lastName={createdBy?.lastName}
				userId={createdBy?.id}
				accountId={createdBy?.account?.id}
				displayName={false}
			/>
		),
	},

	{
		columnLabel: 'misc.status',
		orderBy: 'status_code',
		personalizedCell: ({
			id: statusAlert,
			status: { id, name, color, code },
		}) => {
			return (
				<ActionPopoverBtn
					inputs={{ query: useAlertStatusesList }}
					selected={{
						id,
						color,
						name,
						code,
					}}
					titlePopover={'misc.status'}
					keyTranslate='interventions'
					mutation={{
						id: statusAlert,
						query: useUpdateAlertStatuses,
						refreshQuery: ['getAlerts'],
						successMessage: 'success.alertUpdated',
						attribute: 'status',
					}}
					permissions={[
						EPermissionScope.alertUpdate,
						EPermissionScope.alertClose,
					]}
				/>
			)
		},
	},

	{
		fixWidth: '50px',
		visible: true,
		columnLabel: '',
		tableCellAlign: 'center',
		cellAlign: 'center',
		sticky: true,
		personalizedCell: ({ id }) => (
			<ActionsBtn
				rowId={id}
				deleted={{
					query: useRemoveAlert,
					refreshQuery: ['getAlerts'],
					successMessageDelete: 'success.alertDeleted',
					messageConfirm: {
						title: 'confirmModal.deleteAlertTitle',
						message: 'confirmModal.deleteAlertMessage',
					},
				}}
				permissions={{
					remove: [EPermissionScope.alertRemove],
					delete: [EPermissionScope.alertDelete],
				}}
				viewAction={false}
			/>
		),
	},
]

export default vehiclesListAlertsViewModalTable
