import React from 'react'

import catalogueProductModalValidationSchema from './catalogueProductModalValidationSchema'
import CatalogueProductModalInfos from './catalogProductModalInfos'

const catalogueProductModalConfig = [
	{
		component: <CatalogueProductModalInfos />,
		schema: catalogueProductModalValidationSchema,
	},
]

export default catalogueProductModalConfig
