import React, { useContext } from 'react'
import { Fade, Stack } from '@mui/material'

import ViewModalHeaderActions from './ViewModalHeaderActions'
import ViewModalHeaderTitle from './ViewModalHeaderTitle'
import ViewModalBreadcrumb from './viewModalBreadcrumb'
import { ViewModalContentContext } from '../../../utils/contexts/ViewModalContentContext'

const StackCSS = { w: 1, p: 1, pl: 2 }

interface IViewModalHeader {}

const ViewModalHeader: React.FC<IViewModalHeader> = () => {
	const { loading } = useContext(ViewModalContentContext) || {}

	return (
		<Stack
			direction='row'
			alignItems='center'
			justifyContent='space-between'
			sx={StackCSS}
		>
			<Fade in={!loading}>
				<Stack direction='row' spacing={2}>
					<ViewModalHeaderTitle />
					<ViewModalBreadcrumb />
				</Stack>
			</Fade>

			<ViewModalHeaderActions />
		</Stack>
	)
}

export default ViewModalHeader
