import React, { useContext } from 'react'

import { Stack } from '@mui/material'

import { FormTemplateContext } from '../../../../../../utils/contexts/FormTemplateContext'
import { InstructionField } from '../fieldEditForms/editFormsField'
import SwitchForm from '../../../../../../components/forms/v1/formFields/SwitchForm'
import { useFormatMessage } from '../../../../../../utils/hooks'

interface IFieldEditFormLayoutInstruction {}

const FieldEditFormLayoutInstruction: React.FC<
	IFieldEditFormLayoutInstruction
> = () => {
	const {
		drawerUtils: { controlDrawer: control },
	} = useContext(FormTemplateContext)
	const intlMsg = useFormatMessage()

	return (
		<Stack spacing={2}>
			<SwitchForm
				name='hiddenForOthers'
				label={intlMsg('formTemplate.dontShowForm')}
				control={control}
			/>
			<InstructionField control={control} />
		</Stack>
	)
}

export default FieldEditFormLayoutInstruction
