/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

import { formatGeneric } from '../../../../utils/string'

import { dataBlocksAllRecords, dataBlockLastAlerts } from '../data/dataCommon'

import {
	dataBlockForm,
	dataBlockControl,
	dataBlockSignature,
} from '../data/dataControl'

export const formatDataPdfControl = (data, intlMsg) => {
	// delete unused key add by Appolo
	const cleanDataKey = formatGeneric(data) as any

	// format the data to be display on block
	const blockForm = dataBlockForm(cleanDataKey, intlMsg)
	const blockControl = dataBlockControl(cleanDataKey, intlMsg)
	const blockSignature = dataBlockSignature(cleanDataKey, intlMsg)
	const blockLastAlerts = dataBlockLastAlerts(cleanDataKey, intlMsg)
	const blocksAllRecords = dataBlocksAllRecords(cleanDataKey)

	const blocksAdditionalFooter = data?.formTemplate?.footer
		? { blocksAdditionalFooter: data.formTemplate.footer }
		: {}

	const configDataPDF = {
		blockForm,
		blockControl,
		blockSignature,
		blockLastAlerts,
		blocksAllRecords,
		...blocksAdditionalFooter,
	}

	return configDataPDF
}
