import React, {
	memo,
	useMemo,
	createContext,
	useState,
	useEffect,
	useContext,
	ReactNode,
} from 'react'

import { useQuery } from '@apollo/client'
import { useLocation } from 'react-router-dom'

import { INTERVENTION_MODULES } from '../../../api/gql/queries/interventionsQueries'
import { ROUTES } from '../../../routes/consts'
import { AuthContext } from '../../../utils/contexts/AuthContext'

///////////////////////////////////////////////////////////////////////////
// CONTEXT
///////////////////////////////////////////////////////////////////////////

export type IProductCatalogueContext = {
	idModule: string
	additionalSideNavVariablesQuery: any
}

export const ProductCatalogueContext: React.Context<IProductCatalogueContext> =
	createContext({} as IProductCatalogueContext)

///////////////////////////////////////////////////////////////////////////
// PROVIDER
///////////////////////////////////////////////////////////////////////////

interface IProductCatalogueProvider {
	children: ReactNode
	moduleName: string
}

const ProductCatalogueProvider: React.FC<IProductCatalogueProvider> = ({
	children,
	moduleName,
}) => {
	const location = useLocation()
	const { currentUser } = useContext(AuthContext)
	const { data: listModules, loading } = useQuery(INTERVENTION_MODULES)
	const [idModule, setIdModule] = useState('')

	useEffect(() => {
		if (!loading && listModules) {
			const { id } =
				listModules?.interventionModules.find((module) => {
					return module.name.split('.')[1] === moduleName
				}) || {}
			setIdModule(id)
		}
	}, [loading, moduleName])

	const isSharedCatalog = useMemo(
		() =>
			location.pathname ===
			ROUTES.ADMINISTRATION.PRODUCT_CATALOGUE.CATALOGUE_PRODUCT_SHARED.url,
		[location.pathname]
	)

	const context = useMemo(
		() => ({
			idModule,
			additionalSideNavVariablesQuery: {
				sharedToAgencies: isSharedCatalog ? currentUser?.account?.id : null,
			},
		}),
		[idModule]
	)

	return (
		<ProductCatalogueContext.Provider value={context}>
			{children}
		</ProductCatalogueContext.Provider>
	)
}

export default memo(ProductCatalogueProvider)
