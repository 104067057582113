import React from 'react'

import Table from '../../../../components/tableV2'
import {
	IOverrideConfigTable,
	IOverrideConfigTableWithArgument,
} from '../../../../utils/typescript/tableType'
import CreateFormTemplate from './CreateFormTemplate'
import { formTemplateConfigTable } from './formTemplateConfigTable'

interface IFormTemplateList {
	othersQueryParams?
	overrideConfigTable?: IOverrideConfigTable | IOverrideConfigTableWithArgument
}

const FormTemplateList: React.FC<IFormTemplateList> = ({
	othersQueryParams,
	overrideConfigTable,
}) => {
	return (
		<Table
			tableConfig={formTemplateConfigTable}
			tableMainConfig={formTemplateConfigTable}
			overrideConfigTable={
				overrideConfigTable ?? {
					customBtnCreate: <CreateFormTemplate />,
				}
			}
			othersQueryParams={othersQueryParams}
		/>
	)
}

export default FormTemplateList
