import React, { useCallback } from 'react'

import { Divider, IconButton, Stack, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useFormatMessage } from '../../../../../../../utils/hooks'

interface ISelectVehiclesModalHeader {
	closeModal: any
}

const SelectVehiclesModalHeader: React.FC<ISelectVehiclesModalHeader> = ({
	closeModal,
}) => {
	const intlMsg = useFormatMessage()

	const handleClose = useCallback(() => {
		closeModal()
	}, [])

	return (
		<>
			<Stack
				direction='row'
				justifyContent='space-between'
				alignItems='center'
				sx={{ width: '100%', p: 3 }}
			>
				<Typography variant='h2'>
					{intlMsg('interventions.addVehicles')}
				</Typography>
				<IconButton
					aria-label='close'
					size='medium'
					sx={{ position: 'absolute', right: ({ spacing }) => spacing(2) }}
					onClick={handleClose}
				>
					<CloseIcon fontSize='inherit' />
				</IconButton>
			</Stack>

			<Divider variant='fullWidth' flexItem />
		</>
	)
}

export default SelectVehiclesModalHeader
