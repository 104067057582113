import React, { useContext } from 'react'
import { Box } from '@mui/material'

import FiltersBtnProvider from '../../../../components/filters/filtersBtn'
import AlertsFiltersBtnComponent from './AlertsFiltersBtnComponent'
import FilterDate from '../../../../components/filters/filtersComponents/filterDate'
import { FiltersContext } from '../../../../components/filters/filtersContext'

interface IAlertsFilters {}

const ByReferenceFiltersSX = {
	display: 'flex',
}

const AlertsFilters: React.FC<IAlertsFilters> = () => {
	const { setFilters, filters } = useContext(FiltersContext)

	return (
		<Box sx={ByReferenceFiltersSX}>
			<FilterDate
				setFiltersQuerieParam={setFilters}
				filtersQuerieParam={filters}
				argumentQuerieName='startFrom'
			/>

			<FiltersBtnProvider>
				<AlertsFiltersBtnComponent />
			</FiltersBtnProvider>
		</Box>
	)
}

export default AlertsFilters
