import React, { useCallback, useContext, useEffect, useState } from 'react'

import toast from 'react-hot-toast'

import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import { Stack, Button } from '@mui/material'

import { useFormatMessage } from '../../../../../utils/hooks'
import { getFieldEditConfig } from './getFieldEditConfig'
import { getFieldEditSubmitConfig } from './getFieldEditSubmitConfig'
import { FormTemplateContext } from '../../../../../utils/contexts/FormTemplateContext'
import { EFormTemplateBasicFields } from '../../../../../utils/app-models'

interface IFieldEdit {}

const FieldEdit: React.FC<IFieldEdit> = () => {
	const intlMsg = useFormatMessage()
	const {
		drawerUtils: {
			setStateDrawer,
			handleSubmitDrawer,
			fieldToUpdate: { index, name, fieldType, updateField, values },
			setValueDrawer,
		},
		setLimitationFields,
	} = useContext(FormTemplateContext)

	const submitDataDrawer = useCallback(
		async (data) => {
			let canSubmit = true

			switch (fieldType) {
				case EFormTemplateBasicFields.control:
					if (data?.controlList?.length < 2) {
						canSubmit = false
						toast.error('errors.formTemplateFieldControlList')
					}
					break

				case EFormTemplateBasicFields.selection:
					if (data?.selectionsList?.length < 1) {
						canSubmit = false
						toast.error('errors.formTemplateFieldSelectionsList')
					}
					break

				default:
					break
			}

			if (canSubmit) {
				getFieldEditSubmitConfig(
					updateField,
					fieldType,
					index,
					data,
					setLimitationFields,
					values
				)
				setStateDrawer('right', false)
			}
		},
		[setStateDrawer, updateField, fieldType, index]
	)

	const [valueDefault, setValueDefault] = useState(false)

	useEffect(() => {
		Object.entries(values).forEach(([key, value]) => {
			setValueDrawer(key, value)
			setValueDefault(true)
		})
	}, [setValueDrawer, setValueDefault])

	const handleKeyDownOnSubmit = useCallback((e) => {
		if (e.code === 'Enter') e.preventDefault()
	}, [])

	return (
		<form
			onSubmit={handleSubmitDrawer((data) => submitDataDrawer(data))}
			onKeyDown={handleKeyDownOnSubmit}
		>
			<Stack p={6} sx={{ width: '490px' }}>
				<Stack direction='row' spacing={1} mb={2}>
					<Typography variant='h2'>
						{intlMsg(`formTemplate.${fieldType}`)}
					</Typography>

					{intlMsg(`formTemplate.${fieldType}`) !== name && (
						<Typography variant='body1'>| {name}</Typography>
					)}
				</Stack>
				<Divider variant='fullWidth' sx={{ mb: 2 }} />
				{valueDefault && getFieldEditConfig(fieldType)}
				<Stack direction='row' justifyContent='flex-end' mt={2}>
					<Button variant='contained' type='submit'>
						{intlMsg('misc.confirm')}
					</Button>
				</Stack>
			</Stack>
		</form>
	)
}
export default FieldEdit
