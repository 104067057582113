/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

import { stars } from '../imgBase64'
import { mappingRatingStars } from '../utilPdf'

const heightStars = 5
const widthStars = 5

export const ratingFieldPDF = (doc, record, position, marginLeft = 0.9) => {
	const value =
		typeof record === 'object'
			? record?.value?.value ||
			  JSON.parse(record.formTemplateField.attributes[0])?.defaultValue
			: record

	const { positionYcell, positionXcell, widthTitleRowCell, heightCell } =
		position

	const indexMapping = value?.toString().split('.').join('')

	const centerPositionYStars = positionYcell + (heightCell - 5) / 2 - 0.25

	mappingRatingStars[indexMapping].forEach((imgKey, i) => {
		// 	HELP -> addImage(imageData, format, positionXcell, positionYcell, width, height, alias, compression, rotation)
		doc.addImage(
			stars[imgKey],
			'PNG',
			positionXcell + i * widthStars + widthTitleRowCell + marginLeft,
			centerPositionYStars,
			widthStars,
			heightStars
		)
	})
}
