import React, { useContext } from 'react'

import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'

import { ListItemsData } from '../../../components'
import { EAccountType, EFormModalMode } from '../../../utils/app-models'
import UserAvatar from '../../../components/avatar/UserAvatar'
import { Box, Stack } from '@mui/material'
import { useFormatMessage } from '../../../utils/hooks'
import { AuthContext } from '../../../utils/contexts/AuthContext'
import { formatAdressGoogleMap } from '../../../utils/string'
import FormBtnModal from '../../../components/forms/formModalBtn'
import EditUserModal from './editUserModal'
import EditUserPasswordModalBtn from './editUserPasswordModal/EditUserPasswordModalBtn'
import FormSelectNation from '../../../components/forms/formFields/formSelectNation'
import { languageProperties } from '../../../providers/IntlProviderWrapper/utils'

const mainContainerCSS = {
	p: 0,
	display: 'flex',
	width: '100%',
	flexDirection: { xs: 'column', lg: 'row' },
	overflow: 'auto',
}

const avatarContainerCSS = {
	p: 4,
	minHeight: '360px',
	borderRadius: '4px 0px 0px 4px',
	backgroundColor: '#132142',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	maxWidth: '267px',
	position: 'relative',

	'@media only screen and (max-width: 1200px)': {
		maxWidth: 'unset',
	},
}

const MyProfile: React.FC = () => {
	const { currentUser } = useContext(AuthContext)
	const intlMsg = useFormatMessage()

	return (
		<Card elevation={1} sx={mainContainerCSS}>
			<Box sx={avatarContainerCSS}>
				<UserAvatar
					userName={{
						firstName: currentUser?.firstName,
						lastName: currentUser?.lastName,
					}}
					size={'large'}
				/>
				<Typography variant='h3' sx={{ color: 'white', mt: 1 }}>
					{currentUser?.firstName} {currentUser?.lastName}
				</Typography>

				<EditUserPasswordModalBtn user={currentUser} />

				<FormSelectNation
					name={'selectLanguage'}
					label={'Langue'}
					options={languageProperties}
				/>
			</Box>

			<Grid container p={4}>
				<Grid item xs={12}>
					<Stack direction='row' justifyContent='space-between' mb={2}>
						<Typography variant='h2'>{intlMsg('misc.myInfos')}</Typography>

						<FormBtnModal
							modal={
								<EditUserModal modalUtils={{} as any} user={currentUser} />
							}
							mode={EFormModalMode.edit}
						/>
					</Stack>

					<ListItemsData
						nbCol={6}
						underlined
						items={[
							{
								primaryText: 'misc.email',
								secondaryText: currentUser?.email || '-',
							},
							{
								primaryText: 'misc.ref',
								secondaryText: currentUser?.displayedId || '-',
							},
							{
								primaryText: 'misc.phone',
								secondaryText: currentUser?.phone || '-',
							},
							{
								primaryText: 'misc.job',
								secondaryText: currentUser?.job || '-',
							},
							{
								primaryText: 'misc.cellphone',
								secondaryText: currentUser?.cellphone || '-',
							},
						]}
					/>
					{currentUser.account.accountType.code !== EAccountType.guest && (
						<>
							<Stack
								direction='row'
								justifyContent='space-between'
								mb={2}
								mt={7}
							>
								<Typography variant='h2'>
									{intlMsg('navigation.myDomain')}
								</Typography>
							</Stack>

							<ListItemsData
								nbCol={6}
								underlined
								items={[
									{
										primaryText: 'misc.name',
										secondaryText: currentUser?.account?.name || '-',
									},
									{
										primaryText: 'misc.phone',
										secondaryText: currentUser.account.adminPhone || '-',
									},
									{
										primaryText: 'misc.siret',
										secondaryText: currentUser?.account?.siret || '-',
									},
									{
										primaryText: 'misc.address',
										secondaryText: `${currentUser?.account?.address ?? ''} - ${
											currentUser?.account?.zipcode ?? ''
										} - ${currentUser?.account?.city ?? ''}`,
										...((currentUser?.account?.address ||
											currentUser?.account?.city ||
											currentUser?.account?.zipcode) && {
											externalLink: formatAdressGoogleMap(
												currentUser?.account?.address,
												currentUser?.account?.city,
												currentUser?.account?.zipcode
											),
										}),
									},
									{
										primaryText: 'misc.accountType',
										secondaryText: currentUser.account.accountType.code || '-',
										nbColItem: 12,
									},
									{
										primaryText: 'misc.network',
										secondaryText: currentUser.account.networks?.collection
											?.length
											? currentUser.account?.networks?.collection?.map(
													({ name }) => name + ' '
											  )
											: '-',
										nbColItem: 12,
									},
								]}
							/>
						</>
					)}
				</Grid>
			</Grid>
		</Card>
	)
}

export default MyProfile
