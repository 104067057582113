import React from 'react'

import DocumentVehicleModalInfos from './DocumentVehicleModalInfos'
import documentVehicleModalValidationSchema from './documentVehicleModalValidationSchema'

const documentVehicleModalConfig = [
	{
		component: <DocumentVehicleModalInfos />,
		schema: documentVehicleModalValidationSchema,
	},
]

export default documentVehicleModalConfig
