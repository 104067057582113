/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

export const dispatchTableProps = (
	intlMsg,
	configTable,
	overrideConfigTable
) => {
	const {
		tableToolBarActionConfig,
		tableToolBarConfig,
		tableContainerConfig,
		tableNavTabConfig,
	} = configTable || {}

	const {
		hidden,
		tableConfig,
		argumentQueryViewModal,
		localStorageName,
		othersQueryParams,
		stickyToolBar,
		customBtnCreate,
		onDataGetted,
		title,
		customTitle,
		numberItem,
		tableContainer,
	} = overrideConfigTable || {}

	//////////////////////////////////////////////////////////////
	// TableToolBarActionPROPS
	//////////////////////////////////////////////////////////////

	const tableNavTabPROPS = { ...tableNavTabConfig }
	//////////////////////////////////////////////////////////////
	// TableToolBarHeaderPROPS
	//////////////////////////////////////////////////////////////

	const TableToolBarPROPS = {
		...tableToolBarConfig,
		...overrideConfigTable,
		...(hidden?.tableToolBar && { hidden: hidden.tableToolBar }),
		...(tableConfig && { tableConfig }),
		...(tableToolBarConfig?.exportConfig?.exportQuery && {
			exportConfig: {
				...tableToolBarConfig.exportConfig,
				argumentQueryViewModal,
				othersQueryParams,
			},
		}),
		...(localStorageName && { localStorageName }),
		...(customBtnCreate && { customBtnCreate }),
		...(title && { title: intlMsg(title) }),
		...(customTitle && { customTitle }),
		...(numberItem && { numberItem: numberItem }),
	}

	//////////////////////////////////////////////////////////////
	// TableToolBarActionPROPS
	//////////////////////////////////////////////////////////////

	const TableToolBarActionPROPS = {
		hidden: hidden?.tableToolBarAction,
		...tableToolBarActionConfig,
	}

	//////////////////////////////////////////////////////////////
	// TablePROPS
	//////////////////////////////////////////////////////////////

	const tableContainerPROPS = {
		...(hidden?.tableContainer && { hidden: hidden.tableContainer }),
		...tableContainerConfig,
		...(tableConfig && { tableConfig }),
		...(othersQueryParams && { othersQueryParams }),
		...(tableToolBarConfig?.stickyToolBar && {
			stickyToolBar: tableToolBarConfig?.stickyToolBar,
		}),
		...(stickyToolBar && { stickyToolBar }),
		...(onDataGetted && { onDataGetted }),
		...(tableContainer && { ...tableContainer }),
		...(argumentQueryViewModal && { argumentQueryViewModal }),
		...(tableContainer?.disabledCheckBox && { disabledCheckbox: true }),
	}

	//////////////////////////////////////////////////////////////
	// Return component table Props
	//////////////////////////////////////////////////////////////

	return {
		tableNavTabPROPS:
			tableNavTabConfig && !hidden?.tableNavTab ? tableNavTabPROPS : null,
		TableToolBarActionPROPS: tableToolBarActionConfig
			? TableToolBarActionPROPS
			: null,
		TableToolBarPROPS: tableToolBarConfig ? TableToolBarPROPS : null,
		tableContainerPROPS: tableContainerConfig ? tableContainerPROPS : null,
	}
}
