import React from 'react'

import InvoiceModalInfos from './invoiceModalInfos'
import invoiceModalValidationSchema from './invoiceModalValidationSchema'

const invoiceModalConfig = [
	{
		component: <InvoiceModalInfos />,
		schema: invoiceModalValidationSchema,
	},
]

export default invoiceModalConfig
