import React, { useCallback, useState } from 'react'

import Typography from '@mui/material/Typography'

import SlidePanelUserInfos from '../../slidepanel/user'

interface IOpenSlidePanelCell {
	txt?: string
	id?
	isContact?: boolean
}

const OpenSlidePanelCell: React.FC<IOpenSlidePanelCell> = ({
	txt,
	id,
	isContact = true,
}) => {
	const [stateSlidePanel, setStateSlidePanel] = useState(false)
	const toggleDrawer = useCallback(
		(open: boolean) => () => setStateSlidePanel(open),
		[stateSlidePanel]
	)

	return (
		<>
			<Typography
				variant='caption'
				onClick={toggleDrawer(true)}
				sx={{ cursor: 'pointer' }}
			>
				{txt}
			</Typography>

			{stateSlidePanel && (
				<SlidePanelUserInfos
					id={id as string}
					state={stateSlidePanel}
					toggleDrawer={toggleDrawer}
					isContact={isContact}
				/>
			)}
		</>
	)
}

export default OpenSlidePanelCell
