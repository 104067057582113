export const adminPanelAccountAttributes = `
  paginationInfo {
    totalCount
  }
  collection {
    id
    siret
    name
    address
    isActive
    dateCreated
    zipcode
    city
    country
    accountType {
      id,
      name
    }
    customers(isActive:true) {
      paginationInfo {
        totalCount
      }
    }
    customersTotalNb : customers {
      paginationInfo {
        totalCount
      }
    }
    customModules{
      id
      name
      title
      description
    }
    createdForms {
      paginationInfo {
        totalCount
      }
    }
    executedInterventions {
      paginationInfo {
        totalCount
      }
    }
  }
`
