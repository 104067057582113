import React, { memo, useCallback, useState, useMemo } from 'react'

import Typography from '@mui/material/Typography'
import { Stack, Avatar, Popover, Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useFormatMessage } from '../../../utils/hooks'

const closeIconCSS = {
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'nowrap',
	justifyContent: 'flex-start',
	alignContent: 'stretch',
	alignItems: 'center',
	marginLeft: '10px',
	marginBottom: '5px',

	'& .MuiAvatar-circular': {
		backgroundColor: '#2196f314',
		color: '#00000099',
		fontSize: '14px',
		width: '28px',
		height: '28px',
		marginRight: '7px',
	},

	'& .svgContainer': {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		justifyContent: 'flex-start',
		alignContent: 'stretch',
		alignItems: 'center',

		'& svg': {
			width: '14px',
			height: '14px',
			fill: '#00000042',
			marginRight: '10px',

			'&:hover': {
				cursor: 'pointer',
			},
		},
	},

	'& p': {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		justifyContent: 'flex-start',
		alignContent: 'stretch',
		alignItems: 'center',
	},
}

const popoverCSS = {
	pointerEvents: 'none',
	'& .MuiPaper-root': {
		backgroundColor: '#616161e6',
		color: 'white',
	},
}

interface IIndicatorRows {
	setSelectedRow: any
	selectedRow: any
}

const IndicatorRows: React.FC<IIndicatorRows> = ({
	setSelectedRow,
	selectedRow,
}) => {
	const intlMsg = useFormatMessage()

	const [mouseOverPopover, setMouseOverPopover] = useState<HTMLElement | null>(
		null
	)
	////////////////////////////////////////////
	// Open/close popover
	////////////////////////////////////////////
	const overPopoverOpen = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			setMouseOverPopover(event.currentTarget)
		},
		[]
	)

	const overPopoverClose = useCallback(() => {
		setMouseOverPopover(null)
	}, [])

	const deleteSelectedRows = useCallback(() => {
		setSelectedRow([])
	}, [])

	const openPopover = useMemo(() => {
		return Boolean(mouseOverPopover)
	}, [mouseOverPopover])

	return (
		<Stack direction='row' sx={closeIconCSS}>
			<Box
				className='svgContainer'
				onMouseEnter={overPopoverOpen}
				onMouseLeave={overPopoverClose}
				onClick={deleteSelectedRows}
			>
				<CloseIcon />
			</Box>
			<Popover
				sx={popoverCSS}
				open={openPopover}
				anchorEl={mouseOverPopover}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				onClose={overPopoverClose}
				disableRestoreFocus
			>
				<Typography sx={{ p: 1 }}>{intlMsg('misc.emptySelection')}</Typography>
			</Popover>
			<Avatar>{`${selectedRow?.length} `}</Avatar>
			<Typography>
				{selectedRow?.length <= 1
					? intlMsg('table.selectRow')
					: intlMsg('table.selectRows')}
			</Typography>
		</Stack>
	)
}

export default memo(IndicatorRows)
