import React, { useCallback } from 'react'

import { useNavigate, useLocation } from 'react-router-dom'

import AddCircleIcon from '@mui/icons-material/AddCircle'
import {
	Box,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Popover,
} from '@mui/material'

import { useFormatMessage } from '../../../utils/hooks'
import { creationShortcutsConfig } from '../../crudModal/creationShortcutsConfig'
import PermissionsHandler from '../../permissionsHandler'

const mainCSS = {
	width: 1,
	backgroundColor: ({ palette }) => palette.background.paper,
	maxWidth: '180px',
}
const ListItemHeaderCSS = { backgroundColor: '#132142', color: 'white' }

const ListItemIconCSS = { minWidth: '40px' }

const ListItemTextHeaderCSS = {
	fontSize: '10px',
	'& .MuiListItemText-primary': { fontSize: '13px' },
}

const menuCSS = {
	top: `68px!important`,
	right: `0px!important`,
	maxWidth: '180px',
	border: 'solid 1px #dddddd59',
	boxShadow: '#ddd 3px 2px 5px 0px',
}

interface INavTopAddActionMenu {
	handleOpenAddAction: (event?: any) => void
	anchorElAddAction: undefined | HTMLElement
}

const NavTopAddActionMenu: React.FC<INavTopAddActionMenu> = ({
	handleOpenAddAction,
	anchorElAddAction,
}) => {
	const intlMsg = useFormatMessage()
	const navigate = useNavigate()
	const location = useLocation()

	const handleSelectedAddAction = useCallback(
		(actionName) => () => {
			navigate(`${location.pathname}?crudmodal=${actionName}`)
			handleOpenAddAction()
		},
		[location, navigate, handleOpenAddAction]
	)

	const renderActionListItem = useCallback(
		({ name, icon, text, createPermission, modules }, i) => (
			<PermissionsHandler
				permissionsName={createPermission}
				modulesName={modules}
				key={`${name}-${i}`}
			>
				<ListItem button onClick={handleSelectedAddAction(name)}>
					<ListItemIcon sx={ListItemIconCSS}>{icon}</ListItemIcon>
					<ListItemText primary={intlMsg(text)} />
				</ListItem>
			</PermissionsHandler>
		),
		[handleSelectedAddAction, intlMsg]
	)

	return (
		<Popover
			anchorEl={anchorElAddAction}
			keepMounted
			open={Boolean(anchorElAddAction)}
			onClose={handleOpenAddAction}
			PaperProps={{ sx: menuCSS }}
		>
			<Box sx={mainCSS}>
				<List component='nav' sx={{ p: 0 }}>
					<ListItem sx={ListItemHeaderCSS}>
						<ListItemIcon sx={ListItemIconCSS}>
							<AddCircleIcon sx={{ color: 'white' }} />
						</ListItemIcon>
						<ListItemText
							sx={ListItemTextHeaderCSS}
							primary={intlMsg('navigation.add')}
						/>
					</ListItem>

					{creationShortcutsConfig.map(renderActionListItem)}
				</List>
			</Box>
		</Popover>
	)
}

export default NavTopAddActionMenu
