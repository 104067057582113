import { Stack } from '@mui/material'
import React, { useContext } from 'react'
import { FiltersBtnContext } from '../../../../components/filters/filtersBtn/FiltersBtnContext'
import {
	useAlertCategoryList,
	useAlertStatusesList,
} from '../../../../api/gql/queries/alertsQueries'
import FilterValues from '../../../../components/filters/filtersComponents/filterValues'

interface IAlertsFiltersBtnComponent {}

const AlertsFiltersBtnComponent: React.FC<IAlertsFiltersBtnComponent> = () => {
	const { setTmpFilters, tmpFilters } = useContext(FiltersBtnContext)

	return (
		<Stack spacing={1}>
			<FilterValues
				setFiltersQuerieParam={setTmpFilters}
				filtersQuerieParam={tmpFilters}
				query={useAlertCategoryList}
				title='alerts.alertCategory'
				argumentQuerieName='category'
			/>
			<FilterValues
				setFiltersQuerieParam={setTmpFilters}
				filtersQuerieParam={tmpFilters}
				query={useAlertStatusesList}
				title='misc.status'
				argumentQuerieName='status'
			/>
		</Stack>
	)
}

export default AlertsFiltersBtnComponent
