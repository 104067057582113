import React from 'react'
import { Typography, Box } from '@mui/material'
import { useFormatMessage } from '../../../utils/hooks'
import OpenLinkCell from './OpenLinkCell'
import { ROUTES } from '../../../routes/consts'

interface ISourceAlert {
	form
}

const SourceAlert: React.FC<ISourceAlert> = ({ form }) => {
	const intlMsg = useFormatMessage()

	return (
		<Box sx={{ whiteSpace: 'nowrap' }}>
			{form?.id ? (
				<OpenLinkCell
					viewModalLink={`${ROUTES.VIEWMODAL.INTERVENTION_FORMS.url}${form.id}`}
					txt={form?.reference || '-'}
				/>
			) : (
				<Typography variant='caption'>{intlMsg(`alerts.manualAdd`)}</Typography>
			)}
		</Box>
	)
}

export default SourceAlert
