import React from 'react'

import { Box, Stack } from '@mui/material'

interface IDialogAlertContentLayout {
	viewModalAlertConfig
	onClose
}

const DialogAlertContentLayout: React.FC<IDialogAlertContentLayout> = ({
	viewModalAlertConfig: { AlertContent },
	onClose,
}) => {
	return (
		<Box sx={{ height: 1 }}>
			<Stack
				direction='row'
				justifyContent='center'
				alignItems='center'
				sx={{ width: 1, height: '70vh' }}
			>
				<Box sx={{ backgroundColor: 'white', width: 1, overflow: 'auto' }}>
					<AlertContent onClose={onClose} />
				</Box>
			</Stack>
		</Box>
	)
}

export default DialogAlertContentLayout
