import React, { useCallback, useContext, useMemo } from 'react'

import { Divider, IconButton, Stack, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { FormModalContext } from '../../FormModalContext'
import FormModalTabs from './formModalTabs'

interface IFormModalHeader {}

const FormModalHeader: React.FC<IFormModalHeader> = () => {
	const {
		modalUtils: { closeModal },
		formModalConfig: {
			formModalTabConfig,
			formModalName,
			formGetModalName,
			onClose,
		},
		setSelectedTab,
		mode,
	} = useContext(FormModalContext)

	const modalName = useMemo(
		() =>
			formModalName || (formGetModalName && formGetModalName({ mode })) || null,
		[mode, formGetModalName]
	)

	const handleClose = useCallback(() => {
		onClose && onClose()
		closeModal()
		setSelectedTab(0)
	}, [])

	return (
		<>
			<Stack
				direction='row'
				justifyContent='space-between'
				alignItems='center'
				sx={{ width: '100%', p: 3 }}
			>
				{modalName && <Typography variant='h2'>{modalName}</Typography>}
				<IconButton
					aria-label='close'
					size='medium'
					sx={{ position: 'absolute', right: ({ spacing }) => spacing(2) }}
					onClick={handleClose}
				>
					<CloseIcon fontSize='inherit' />
				</IconButton>
			</Stack>

			{formModalTabConfig?.length === 1 ? (
				<Divider variant='fullWidth' flexItem />
			) : (
				<FormModalTabs />
			)}
		</>
	)
}

export default FormModalHeader
