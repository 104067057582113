import React, { useMemo } from 'react'

import { Chip, Tooltip, Avatar } from '@mui/material'
import { useFormatMessage } from '../../../../utils/hooks'
import { maxStringDisplay } from '../../../../utils/string'

const avatarCSS = {
	bgcolor: ({ palette }) => `${palette.primary.main} !important`,
}

interface ITaskChip {
	numberProducts: number
	products
	handleClick
}

const TaskChip: React.FC<ITaskChip> = ({
	numberProducts,
	products,
	handleClick,
}) => {
	const intlMsg = useFormatMessage()
	const haveMoreProducts = useMemo(() => numberProducts > 1, [numberProducts])
	const label = useMemo(
		() =>
			haveMoreProducts ? intlMsg('interventions.task') : products[0]?.name,
		[haveMoreProducts, products]
	)

	const avatar = useMemo(
		() =>
			haveMoreProducts ? (
				<Avatar sx={avatarCSS}>{numberProducts}</Avatar>
			) : undefined,
		[haveMoreProducts]
	)

	return (
		<>
			{numberProducts ? (
				<Tooltip title={label} disableHoverListener={label?.length < 20}>
					<Chip
						clickable={haveMoreProducts}
						onClick={haveMoreProducts ? handleClick : undefined}
						label={maxStringDisplay(label, 20)}
						color='primary'
						variant='outlined'
						size='small'
						avatar={avatar}
					/>
				</Tooltip>
			) : (
				'-'
			)}
		</>
	)
}

export default TaskChip
