import React from 'react'
import { calendarDefaultFiltersConfig } from './calendarFilters/calendarDefaultFiltersConfig'
import FiltersProvider from '../../../components/filters/filtersProvider'
import CalendarContainer from './CalendarContainer'

interface ICalendarProvider {}

const CalendarProvider: React.FC<ICalendarProvider> = () => {
	return (
		<FiltersProvider filtersConfig={calendarDefaultFiltersConfig}>
			<CalendarContainer />
		</FiltersProvider>
	)
}

export default CalendarProvider
