import { Stack } from '@mui/material'
import React, { useContext, useMemo } from 'react'
import { FiltersBtnContext } from '../../../../components/filters/filtersBtn/FiltersBtnContext'
import FilterMultiselect from '../../../../components/filters/filtersComponents/filterMultiselect'
import { EModule } from '../../../../utils/app-models'
import { useGetCustomerListForSelect } from '../../../../api/gql/queries/customersQueries'
import FilterValues from '../../../../components/filters/filtersComponents/filterValues'
import { currentInterventionTypesForFilters } from '../../../../utils/const'

interface IAlertsFiltersBtnComponent {}

const AlertsFiltersBtnComponent: React.FC<IAlertsFiltersBtnComponent> = () => {
	const { setTmpFilters, tmpFilters } = useContext(FiltersBtnContext)
	const { customers, loading: customersLoading } = useGetCustomerListForSelect()

	const customersOptions = useMemo(
		() =>
			customers?.map((customer) => ({
				id: customer?.id,
				name: customer?.name,
				value: customer?.id,
			})),
		[customers]
	)

	const customersDefaultValue = useMemo(
		() =>
			customersOptions?.filter((option) =>
				tmpFilters?.customer_list?.find(
					(customerId) => option?.id === customerId
				)
			),
		[customersOptions]
	)

	return (
		<Stack spacing={1}>
			<FilterMultiselect
				loading={customersLoading}
				options={customersOptions}
				defaultValue={customersDefaultValue}
				label={'misc.accounts'}
				modules={[EModule.crm]}
				setFiltersQuerieParam={setTmpFilters}
				filtersQuerieParam={tmpFilters}
				argumentQuerieName='customer_list'
			/>
			<FilterValues
				setFiltersQuerieParam={setTmpFilters}
				filtersQuerieParam={tmpFilters}
				forcedData={currentInterventionTypesForFilters}
				title='misc.type'
				argumentQuerieName='intervention_interventionType'
				allId={null}
			/>
		</Stack>
	)
}

export default AlertsFiltersBtnComponent
