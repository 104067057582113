import React, { useCallback } from 'react'

import toast from 'react-hot-toast'

import { useUpdateUsersPassword } from '../../../../api/gql/mutations/usersMutations'
import FormModal from '../../../../components/forms/formModal'
import { EFormModalMode } from '../../../../utils/app-models'
import { IModalUtils, useFormatMessage } from '../../../../utils/hooks'
import { editUserPasswordModalConfig } from './editUserPasswordModalConfig'

interface IEditUserPasswordModal {
	user
	modalUtils: IModalUtils
}

const EditUserPasswordModal: React.FC<IEditUserPasswordModal> = ({
	user,
	modalUtils,
}) => {
	const intlMsg = useFormatMessage()
	const [changePasswordUser] = useUpdateUsersPassword()

	const validateModal = useCallback(
		async ({ data, setValidationLoading, closeModal }) => {
			setValidationLoading(true)
			await changePasswordUser({
				variables: {
					input: {
						id: user.id,
						...data,
					},
				},
				refetchQueries: ['getProfileUser'],
			})
				.then(() => {
					toast.success('success.passwordUpdate')
					setValidationLoading(false)
					closeModal()
				})
				.catch(() => setValidationLoading(false))
		},
		[user]
	)

	return (
		<FormModal
			modalUtils={modalUtils}
			formModalTabConfig={editUserPasswordModalConfig}
			formModalName={intlMsg('administration.updatePassword')}
			initialMode={EFormModalMode.edit}
			onValidate={validateModal}
			validateBtnText={intlMsg('misc.validate')}
			cancelBtnText={intlMsg('misc.cancel')}
			maxWidth='450px'
			minHeight='30px'
			editedElementId={user?.id}
		/>
	)
}

export default EditUserPasswordModal
