import React, { useMemo } from 'react'

import { Typography, Stack } from '@mui/material'

import { useFormatMessage } from '../../../../../../../utils/hooks'
import { FormModalContext } from '../../../../../../../components/forms/formModal/FormModalContext'
import { useInterventionStatuses } from '../../../../../../../api/gql/queries/interventionsQueries'
import FormSelectSearch from '../../../../../../../components/forms/formFields/formSelectSearch'
import { useInterventionTransportType } from '../../../../../../../api/gql/queries/interventionsTransportQueries'
import { EModule } from '../../../../../../../utils/app-models'
import PermissionsHandler from '../../../../../../../components/permissionsHandler'
import FormTextField from '../../../../../../../components/forms/formFields/FormTextField'

interface ITransportStatus {
	isSharedTransport: boolean
}

const TransportStatus: React.FC<ITransportStatus> = ({ isSharedTransport }) => {
	const intlMsg = useFormatMessage()
	const { interventionStatus } = useInterventionStatuses()
	const { interventionTransportType } = useInterventionTransportType()

	// /////////////////////////////////////////////////
	// Format input options
	// /////////////////////////////////////////////////

	const interventionStatusOptions = useMemo(
		() =>
			interventionStatus?.map((status) => ({
				key: status?.id,
				label: status?.name,
				value: status,
			})) || [],
		[interventionStatus]
	)

	const interventionTransportTypeOptions = useMemo(
		() =>
			interventionTransportType?.map((type) => ({
				key: type?.id,
				label: intlMsg(`interventions.${type?.code}`),
				value: type,
			})) || [],
		[interventionTransportType]
	)

	return (
		<Stack spacing={1.5}>
			<Typography variant='h2' gutterBottom mb={1}>
				{intlMsg('interventions.transport')}
			</Typography>

			<FormSelectSearch
				isDisabled={isSharedTransport}
				name='status'
				label={intlMsg('interventions.status')}
				selectElements={interventionStatusOptions}
				context={FormModalContext}
				isRequired
				trigerred
				displayChip
				disabledSort
			/>

			<FormSelectSearch
				isDisabled={isSharedTransport}
				name='interventionTransportType'
				label={intlMsg('interventions.transportType')}
				selectElements={interventionTransportTypeOptions}
				context={FormModalContext}
				isRequired
				trigerred
			/>

			<PermissionsHandler modulesName={[EModule.airTable]}>
				<FormTextField
					isDisabled={isSharedTransport}
					name='airTableReference'
					label={intlMsg('interventions.refAirTable')}
					context={FormModalContext}
				/>
			</PermissionsHandler>
		</Stack>
	)
}
export default TransportStatus
