export default {
	siteCaps: 'SITES',
	status: 'Statut',
	active: 'Actif',
	disabled: 'Désactivés',
	workPosition: 'Fonction',
	role: 'Rôle',
	applicationAcces: 'Accès Application',
	platformAcces: 'Accès plateforme',
	accessPlatform: 'Accès plateforme',
	accessApplication: 'Accès application',
	actions: 'Actions',
	updateProfil: 'Modifier mon profil',
	updatePassword: 'Changer mon mot de passe',
	createUser: 'Ajouter un utilisateur',
	editUser: 'Modifier un utilisateur',
	addUser: 'Ajouter un Utilisateur',
	createTeam: 'Ajouter une équipe',
	editTeam: 'Modifier une équipe',
	addTeam: 'Ajouter une équipe',
	switchTeamLabel: 'EQUIPE ACTIVE',
	switchUserLabel: 'ACTIF',
	dontShowAgain: 'Ne plus afficher ce message',
	userType: "Type d'utilisateur",
	notificationPanelTitle: 'Notifications',
	notificationPanelSubTitle: 'Paramétrez vos notifications',
	editSite: 'Modifier un site',
	createSite: 'Ajouter un site',
	contactReferentSite: 'Contact référent du site',
	manager: 'Gestion/responsable',
	agency: 'Agence',
	comptes: 'Comptes',
	editCustomer: 'Modifier un compte',
	createCustomer: 'Ajouter un compte',
	editContact: 'Modifier un contact',
	createContact: 'Ajouter un contact',
	formTemplateList: 'Editeur de formulaires',
}
