import React from 'react'
import { Link, Stack, Typography } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { grey } from '@mui/material/colors'
import { handleOpenViewModal } from '../../utils/utils'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTES } from '../../routes/consts'

const colorGrey600CSS = { color: grey[600] }
const checkCircleIconCSS = { fontSize: '17px', color: '#4caf50' }
const checkCircleOutlineIconCSS = { fontSize: '17px', color: grey[500] }

interface IChipWithStatesProps {
	title: string
	isDone: boolean
	id?: string
}

const ChipWithStates: React.FC<IChipWithStatesProps> = ({
	title,
	isDone = false,
	id,
}) => {
	const navigate = useNavigate()
	const location = useLocation()

	return (
		<Link
			variant='caption'
			display='block'
			color='inherit'
			underline={id ? 'hover' : 'none'}
			sx={{ cursor: id ? 'pointer' : 'inherit' }}
			onMouseDown={
				id
					? handleOpenViewModal(
							navigate,
							location,
							`${ROUTES.VIEWMODAL.INTERVENTION_FORMS.url}${id}`
					  )
					: undefined
			}
		>
			<Stack direction='row' spacing={0.5} alignItems='center'>
				{isDone ? (
					<CheckCircleIcon sx={checkCircleIconCSS} />
				) : (
					<CheckCircleOutlineIcon sx={checkCircleOutlineIconCSS} />
				)}
				<Typography variant='body2' sx={isDone ? {} : colorGrey600CSS}>
					{title}
				</Typography>
			</Stack>
		</Link>
	)
}

export default ChipWithStates
