import React, { useCallback } from 'react'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Button } from '@mui/material'

import { useFormatMessage, useModal } from '../../../utils/hooks'
import ConfirmModal from '../../../components/modal/ConfirmModal'
import { EFormModalMode } from '../../../utils/app-models'
import UserModal from './userModal'

interface ICreateUserButtonModal {}

const CreateUserButtonModal: React.FC<ICreateUserButtonModal> = () => {
	const intlMsg = useFormatMessage()
	const modalUtils = useModal(false)
	const {
		isVisible: isVisibleWarning,
		openModal: openModalWarning,
		closeModal: closeModalWarning,
	} = useModal()

	const warningCreateUserDontShowAgain = localStorage.getItem(
		'warningCreateUserDontShowAgain'
	)

	const validateModalWarning = useCallback(() => {
		closeModalWarning()
		modalUtils.openModal()
	}, [])

	const handleDontShowAgainClick = useCallback((e) => {
		localStorage.setItem('warningCreateUserDontShowAgain', e.target.checked)
	}, [])

	return (
		<>
			<Button
				variant='contained'
				onClick={
					warningCreateUserDontShowAgain
						? modalUtils.openModal
						: openModalWarning
				}
				startIcon={<AddCircleOutlineIcon />}
				sx={{ ml: 2 }}
			>
				{intlMsg('misc.add')}
			</Button>

			<ConfirmModal
				onClose={closeModalWarning}
				open={isVisibleWarning}
				handleConfirm={validateModalWarning}
				img='/img/illustration/addUser.svg'
				message={{
					title: 'confirmModal.addUserTitle',
					message: 'confirmModal.addUserTitleMessage',
				}}
				checkbox={{
					message: 'confirmModal.dontShowAgain',
					action: handleDontShowAgainClick,
				}}
				playwrightIdConfirmBtn='Playwright-CreateUserButtonModal-ConfirmModal'
			/>

			{modalUtils?.isVisible && (
				<UserModal modalUtils={modalUtils} mode={EFormModalMode.create} />
			)}
		</>
	)
}

export default CreateUserButtonModal
