/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import dayjs, { extend } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
import { EPeriodFilter } from './app-models'
import { getDefaultLocalLanguage } from './utils'

require('dayjs/locale/fr')
import localizedFormat from 'dayjs/plugin/localizedFormat'
extend(utc)

// const locale = navigator.languages[1]
// dayjs.locale(locale)

export const dayjsMethod = dayjs

export const dayjsLocalizedFormatMethod = () => {
	const local = getDefaultLocalLanguage()
	dayjs.locale(local)
	dayjs.extend(localizedFormat)
	return dayjs
}

export const timePostAgo = (date: any): string => {
	extend(relativeTime)
	const now = dayjs().utc().format()
	return dayjs(date).from(now)
}

export const formatDate = (date: any, withHours = false): string => {
	const local = getDefaultLocalLanguage()
	dayjs.locale(local)
	dayjs.extend(localizedFormat)

	// return example : 08/16/2018 8:02 PM (withHours && local = en)
	return date
		? `${dayjs(date).format('L')}${
				withHours ? ' ' + dayjs(date).format('LT') : ''
		  }`
		: ''
}

export const getSeparateDateAndHour = (date): any => {
	const local = getDefaultLocalLanguage()
	dayjs.locale(local)
	dayjs.extend(localizedFormat)

	return {
		date: dayjs(date).format('L'),
		hour: dayjs(date).format('LT'),
	} as any
}

export const dateToUtc = (date: any): string => dayjs(date).utc().format()
export const dateToIso = (date: any): string => dayjs(date).toISOString()

export const countNumberDaysPeriodInterval = (dateFrom, dateTo) => {
	return dayjs(dateTo).diff(dateFrom, 'days')
}

// /////////////////////////////////////////////////////////////////////////
// format NewDate With Period ("week",'month',..) to send as query argument
// /////////////////////////////////////////////////////////////////////////

export const getIntervalDateByPeriod = (period) => {
	switch (period) {
		case EPeriodFilter.thisWeek:
			return {
				endTo: dayjs().endOf('week').toDate(),
				startFrom: dayjs().startOf('week').toDate(),
			}
		case EPeriodFilter.lastWeek:
			return {
				endTo: dayjs().subtract(7, 'day').endOf('week').toDate(),
				startFrom: dayjs().subtract(7, 'day').startOf('week').toDate(),
			}

		case EPeriodFilter.thisMonth:
			return {
				endTo: dayjs().endOf('month').toDate(),
				startFrom: dayjs().startOf('month').toDate(),
			}

		case EPeriodFilter.lastMonth:
			return {
				endTo: dayjs().subtract(1, 'month').endOf('month').toDate(),
				startFrom: dayjs().subtract(1, 'month').startOf('month').toDate(),
			}

		case EPeriodFilter.nextMonth:
			return {
				endTo: dayjs().add(1, 'month').endOf('month').toDate(),
				startFrom: dayjs().add(1, 'month').startOf('month').toDate(),
			}

		case EPeriodFilter.today:
			return {
				endTo: dayjs().endOf('day').toDate(),
				startFrom: dayjs().startOf('day').toDate(),
			}

		case EPeriodFilter.yesterday:
			return {
				endTo: dayjs().subtract(1, 'day').endOf('day').toDate(),
				startFrom: dayjs().subtract(1, 'day').startOf('day').toDate(),
			}

		case EPeriodFilter.tomorrow:
			return {
				endTo: dayjs().add(1, 'day').endOf('day').toDate(),
				startFrom: dayjs().add(1, 'day').startOf('day').toDate(),
			}

		case EPeriodFilter.customPeriod:
			return {}

		case EPeriodFilter.allPeriod:
		default:
			return { startFrom: null, endTo: null }
	}
}

export const formatNewDateWithPeriod = (
	filterPreference,
	argumentDateKey:
		| 'intervention_dateFrom'
		| 'dateFrom'
		| 'startFromEndTo'
		| 'dateCreated'
) => {
	const { period, startFrom, endTo } = filterPreference || {}
	const { startFrom: start, endTo: end } = getIntervalDateByPeriod(period) || {}

	switch (argumentDateKey) {
		case 'intervention_dateFrom':
		case 'dateFrom':
		case 'dateCreated':
			// for customPeriod don't need to regenerate a new date
			if (EPeriodFilter.customPeriod === period) {
				return {
					period,
					[argumentDateKey]: [
						{
							start: filterPreference[argumentDateKey][0].start,
							end: filterPreference[argumentDateKey][0].end,
						},
					],
				}
			} else {
				return { period, [argumentDateKey]: [{ start, end }] }
			}

		case 'startFromEndTo':
			// for customPeriod don't need to regenerate a new date
			if (EPeriodFilter.customPeriod === period) {
				return { period, startFrom, endTo }
			} else {
				return { period, startFrom: end, endTo: start }
			}

		default:
			return {}
	}
}

export const replaceHoursFromDate = (date, dateWithHours) => {
	return dateToIso(
		dayjs(date)
			.set('h', dayjs(dateWithHours).hour())
			.set('m', dayjs(dateWithHours).minute())
	)
}
