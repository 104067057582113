import React, { useCallback } from 'react'

import { jsPDF } from 'jspdf'
require('jspdf-autotable')
import { UserOptions } from 'jspdf-autotable'
import { Button } from '@mui/material'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'

import { formatDataPdfControl } from './formatData/formatDataPdf/formatDataPdfControl'
import { useFormatMessage } from '../../utils/hooks'
import { generateBlockPdf } from './generateBlockPdf'
import { headerFooterPDF } from './utilPdf'
import { formatDataPdfTransport } from './formatData/formatDataPdf/formatDataPdfTransport'
import { EInterventionTypes } from '../../utils/app-models'

const formatDataPDF = {
	[EInterventionTypes.transport]: formatDataPdfTransport,
	[EInterventionTypes.control]: formatDataPdfControl,
	[EInterventionTypes.service]: formatDataPdfControl,
}

interface jsPDFCustom extends jsPDF {
	autoTable: (options: UserOptions) => void
}

interface IExportBtn {
	data
}

const ExportBtn: React.FC<IExportBtn> = ({ data }) => {
	const intlMsg = useFormatMessage()

	const generatePDF = useCallback(async () => {
		const interventionType = data?.intervention?.interventionType

		// init JSPDF
		const doc = new jsPDF() as jsPDFCustom as any

		const totalPagesExp = '{total_pages_count_string}' // need to calculated the current page

		// format data before display block
		const resultDataPDF = formatDataPDF[interventionType](data, intlMsg)

		// create block with the formated data
		generateBlockPdf(interventionType, resultDataPDF, doc)

		// Add Header & footer for each page
		const nbPage = doc.internal.getNumberOfPages()
		for (let i = 1; i <= nbPage; i++) {
			doc.setPage(i)
			doc.autoTable({
				didDrawPage: headerFooterPDF(
					doc,
					nbPage,
					i,
					data?.formTemplate?.title
				) as any,
			})
		}

		// Total page number plugin only available in jspdf v1.0+
		if (typeof doc.putTotalPages === 'function') {
			doc.putTotalPages(totalPagesExp)
		}

		////////////////////////////////////////////////////////////////////////
		// SAVE/OPEN PDF
		////////////////////////////////////////////////////////////////////////

		window.open(await doc.output('bloburl', { returnPromise: true }), '_blank')
	}, [])

	return (
		<Button
			variant='contained'
			color='primary'
			onClick={generatePDF}
			startIcon={<PictureAsPdfIcon />}
		>
			PDF
		</Button>
	)
}

export default ExportBtn
