import React, { useCallback, useContext, useEffect, useMemo } from 'react'

import { useLazyQuery } from '@apollo/client'
import { Stack } from '@mui/material'
import { useFormatMessage } from '../../../../../../../utils/hooks'
import { useGetCustomerListForSelect } from '../../../../../../../api/gql/queries/customersQueries'
import {
	FormModalContext,
	IFormModalContext,
} from '../../../../../../../components/forms/formModal/FormModalContext'
import { GET_SITES_BY_CUSTOMER_ID } from '../../../../../../../api/gql/queries/sitesQueries'
import { GET_CONTACT_LIST_FOR_SELECT } from '../../../../../../../api/gql/queries/contactsQueries'
import PermissionsHandler from '../../../../../../../components/permissionsHandler'
import {
	EModule,
	EPermissionScope,
} from '../../../../../../../utils/app-models'
import FormSelectSearch from '../../../../../../../components/forms/formFields/formSelectSearch'
import CreateContactLink from '../serviceRequest/CreateContactLink'

interface IAutomaticEntry {
	isAccountOwner: boolean
}

const AutomaticEntry: React.FC<IAutomaticEntry> = ({ isAccountOwner }) => {
	const intlMsg = useFormatMessage()
	const { data, setValues, setTabCounter } =
		useContext<IFormModalContext>(FormModalContext)

	const { customers } = useGetCustomerListForSelect()
	const [getSites, { data: dataSites }] = useLazyQuery(GET_SITES_BY_CUSTOMER_ID)
	const [getContacts, { data: dataContacts }] = useLazyQuery(
		GET_CONTACT_LIST_FOR_SELECT
	)

	const isOrderEqualFinal = useMemo(
		() => data?.isOrderEqualFinal || null,
		[data?.isOrderEqualFinal]
	)

	const customersOptions = useMemo(
		() =>
			customers?.map((customer) => ({
				key: customer?.id,
				label: customer?.name,
				value: customer,
			})) || [],
		[customers]
	)

	const sitesOptions = useMemo(
		() =>
			dataSites?.sites?.collection?.map((site) => ({
				key: site?.id,
				label: site?.name,
				value: site,
			})) || [],
		[dataSites]
	)

	const contactsOptions = useMemo(
		() =>
			dataContacts?.contacts?.collection?.map((contact) => ({
				key: contact?.id,
				label: `${contact?.firstName} ${contact?.lastName}`,
				value: contact,
			})) || [],
		[dataContacts]
	)

	const finalData = useMemo(
		() => ({
			customer: isOrderEqualFinal
				? data?.orderingCustomer
				: data?.finalCustomer,
			site: isOrderEqualFinal ? data?.orderingSite : data?.finalSite,
		}),
		[
			data?.finalCustomer,
			data?.finalSite,
			data?.orderingCustomer,
			data?.orderingSite,
		]
	)

	useEffect(() => {
		if (isOrderEqualFinal) {
			setValues({
				finalCustomer: data?.orderingCustomer || null,
				finalSite: data?.orderingSite || null,
				finalContact: data?.orderingContact || null,
			})
		}
	}, [
		isOrderEqualFinal,
		data?.orderingCustomer,
		data?.orderingSite,
		data?.orderingContact,
	])

	useEffect(() => {
		if (finalData?.customer)
			getSites({
				variables: {
					customer: finalData.customer?.value?.id,
				},
			})
	}, [finalData.customer])

	useEffect(() => {
		if (finalData?.site)
			getContacts({
				variables: {
					sites: finalData.site.value?.id,
				},
			})
	}, [finalData.site])

	const handleSelect = useCallback(
		({ fieldName, option }) => {
			if (fieldName === 'finalCustomer') {
				setValues({
					finalCustomer: option,
					finalSite: null,
					finalContact: null,
					globalFormTemplates: null,
				})
			} else if (fieldName === 'finalSite') {
				setValues({
					finalSite: option,
					finalContact: null,
					selectedVehicles: [],
				})
			}
			setTabCounter({ nbVehiclesSelected: 0 })
		},
		[setValues, setTabCounter]
	)

	return (
		<Stack sx={{ width: 1 }} spacing={2}>
			<FormSelectSearch
				name={'finalCustomer'}
				label={intlMsg('misc.account')}
				specialOnChange={handleSelect}
				selectElements={customersOptions}
				context={FormModalContext}
				missingText={intlMsg('misc.noResultSearch')}
				isDisabled={isOrderEqualFinal || !isAccountOwner}
				isRequired
				trigerred
			/>
			<FormSelectSearch
				name={'finalSite'}
				label={intlMsg('misc.site')}
				specialOnChange={handleSelect}
				selectElements={sitesOptions}
				context={FormModalContext}
				missingText={intlMsg('interventions.noKnownSite')}
				isDisabled={
					isOrderEqualFinal || !data['finalCustomer'] || !isAccountOwner
				}
				isRequired
				trigerred
			/>
			<PermissionsHandler modulesName={[EModule.crm]}>
				<FormSelectSearch
					name={'finalContact'}
					label={intlMsg('misc.contact')}
					selectElements={contactsOptions}
					context={FormModalContext}
					missingText={intlMsg('interventions.noKnownContact')}
					isDisabled={
						isOrderEqualFinal || !data['finalSite'] || !isAccountOwner
					}
				/>
			</PermissionsHandler>
			<PermissionsHandler
				modulesName={[EModule.crm]}
				permissionsName={[EPermissionScope.contactManage]}
			>
				<CreateContactLink
					forcedCustomerId={data['finalCustomer']?.value?.id}
					forcedSiteId={data['finalSite']?.value?.id}
					name={'finalContact'}
					isDisabled={
						isOrderEqualFinal || !data['finalSite'] || !isAccountOwner
					}
				/>
			</PermissionsHandler>
		</Stack>
	)
}
export default AutomaticEntry
