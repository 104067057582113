import React, { useState, useContext, useEffect, useCallback } from 'react'
import SwapVertIcon from '@mui/icons-material/SwapVert'

import TransportRemoval from '../transportTrip/removal'
import TransportDelivery from '../transportTrip/delivery'

import {
	FormModalContext,
	IFormModalContext,
} from '../../../../../../../components/forms/formModal/FormModalContext'
import { Button } from '@mui/material'
import { useFormatMessage } from '../../../../../../../utils/hooks'
import { swapDeliveryAndRemovalAddresses } from './transportTripsUtils'

interface ITransportTrips {
	isSharedTransport: boolean
}

const buttonSwapCSS = {
	margin: '12px auto 0 auto!important',
	width: 'fit-content!important',
	padding: '0 8px!important',
	color: '#00000099',
}

const TransportTrips: React.FC<ITransportTrips> = ({ isSharedTransport }) => {
	const intlMsg = useFormatMessage()
	const { data, setValues } = useContext<IFormModalContext>(FormModalContext)

	const [isOrderEqualFinal, setIsOrderEqualFinal] = useState({
		delivery: data?.isDeliveryAddressEqualOrder || false,
		removal: data?.isRemovalAddressEqualOrder || false,
	} as any)

	useEffect(() => {
		setIsOrderEqualFinal({
			delivery: data?.isDeliveryAddressEqualOrder,
			removal: data?.isRemovalAddressEqualOrder,
		})
	}, [data?.orderingCustomer, data?.orderingSite, data?.orderingContact])

	const handleSwapAddresses = useCallback(() => {
		swapDeliveryAndRemovalAddresses({ data, setValues })
	}, [data, setValues])

	return (
		<>
			<TransportRemoval
				isOrderEqualFinal={isOrderEqualFinal}
				setIsOrderEqualFinal={setIsOrderEqualFinal}
				isSharedTransport={isSharedTransport}
			/>
			{!isSharedTransport && (
				<Button
					startIcon={<SwapVertIcon />}
					size='small'
					sx={buttonSwapCSS}
					onClick={handleSwapAddresses}
				>
					{intlMsg('misc.swapPickupDeliveryAddresses')}
				</Button>
			)}
			<TransportDelivery
				isOrderEqualFinal={isOrderEqualFinal}
				setIsOrderEqualFinal={setIsOrderEqualFinal}
				isSharedTransport={isSharedTransport}
			/>
		</>
	)
}

export default TransportTrips
