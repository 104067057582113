import React, { useCallback, useState, useContext, useMemo } from 'react'

import { useNavigate, useLocation } from 'react-router-dom'

import { AuthContext } from '../../../../utils/contexts/AuthContext'
import { useFormatMessage } from '../../../../utils/hooks'
import { handleOpenViewModal } from '../../../../utils/utils'
import MultiItemsButton from '../../MultiItemsButton'
import MultiItemsPopover from '..'
import SimpleListItems from './SimpleListItems'

interface IChipCountListPopover {
	data?
	text?: string
	maxCharacters?: number
	viewModalLink?
	permissions?
	disabledClick?
}

const ChipCountListPopover: React.FC<IChipCountListPopover> = ({
	data,
	text,
	maxCharacters,
	viewModalLink = null,
	permissions,
	disabledClick = false,
}) => {
	const intlMsg = useFormatMessage()
	const navigate = useNavigate()
	const location = useLocation()
	const { checkPermissions } = useContext(AuthContext)
	const haveAccess = useMemo(
		() => (permissions ? checkPermissions(permissions) : true),
		[permissions]
	)
	const nbData = data?.length
	const [anchorEl, setAnchorEl] = useState(null)
	const open = useMemo(() => Boolean(anchorEl), [anchorEl])
	const handleClose = useCallback(() => setAnchorEl(null), [])

	/////////////////////////////////////////////////////////
	// Handle click
	/////////////////////////////////////////////////////////

	const handleClick = useCallback(
		(e) => {
			if (nbData === 1) {
				!disabledClick && viewModalLink && haveAccess
					? handleOpenViewModal(
							navigate,
							location,
							viewModalLink + data[0].id
					  )(e)
					: undefined
			} else {
				setAnchorEl(e.currentTarget)
			}
		},
		[disabledClick, navigate, location, viewModalLink, nbData, data]
	)

	/////////////////////////////////////////////////////////
	// Label
	/////////////////////////////////////////////////////////

	const label = useMemo(() => {
		return nbData > 1 ? intlMsg(text) : data[0]?.name
	}, [nbData, data])

	return (
		<>
			<MultiItemsButton
				buttonType={nbData > 1 ? 'chipCountClickable' : 'textLink'}
				nbItems={nbData}
				label={label}
				handleClick={handleClick}
				maxCharacters={maxCharacters}
				haveAccess={haveAccess}
				disabledClick={disabledClick}
			/>

			{open && (
				<MultiItemsPopover
					open={open}
					handleClose={handleClose}
					anchorEl={anchorEl}
				>
					<SimpleListItems
						data={data}
						viewModalLink={viewModalLink}
						haveAccess={haveAccess}
						disabledClick={disabledClick}
						handleClose={handleClose}
					/>
				</MultiItemsPopover>
			)}
		</>
	)
}

export default ChipCountListPopover
