import React, { memo } from 'react'

import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import { useFormatMessage } from '../../../../utils/hooks'
import { stringifiedColor } from '../../../../utils/color'

const colorLabelCSS = (color) => ({
	height: '10px',
	width: '10px',
	marginRight: '5px',
	marginLeft: '-5px',
	borderRadius: 1,
	backgroundColor: stringifiedColor(color, 400),
})

const iconCSS = { position: 'relative', top: '-1.5px', right: '-1px' }

interface IStatusButtonGroup {
	handleChangeStatus
	valueStatusGroup
	handleClick
	selectedStatus
}

const StatusButtonGroup: React.FC<IStatusButtonGroup> = ({
	handleChangeStatus,
	valueStatusGroup,
	handleClick,
	selectedStatus,
}) => {
	const intlMsg = useFormatMessage()
	const { code, color, name } = selectedStatus

	return (
		<ToggleButtonGroup
			color='primary'
			value={valueStatusGroup}
			exclusive
			onChange={handleChangeStatus}
			aria-label='group-status'
			fullWidth
			sx={{ '& .MuiToggleButton-root': { lineHeight: 0 } }}
		>
			<ToggleButton
				value='interventionCustom'
				sx={{ minWidth: '170px' }}
				onClick={handleClick}
			>
				{code && <Box sx={colorLabelCSS(color)} />}
				{intlMsg(
					code
						? code
							? `interventions.${code.toLowerCase().split(' ').join('')}`
							: name
						: 'misc.byStatus'
				)}

				<ArrowDropDownIcon sx={iconCSS} />
			</ToggleButton>
			<ToggleButton value='interventionActive'>
				{intlMsg('interventions.actives')}
			</ToggleButton>
			<ToggleButton value='interventionFinish'>
				{intlMsg('interventions.finished')}
			</ToggleButton>
			<ToggleButton value='interventionAll'>
				{intlMsg('interventions.all')}
			</ToggleButton>
		</ToggleButtonGroup>
	)
}

export default memo(StatusButtonGroup)
