import React, { useCallback, useState, memo, useContext } from 'react'

import List from '@mui/material/List'
import ListSubheader from '@mui/material/ListSubheader'
import Popover from '@mui/material/Popover'
import {
	Button,
	Checkbox,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
	Divider,
} from '@mui/material'
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize'

import { useFormatMessage } from '../../../../../utils/hooks'
import { initialWidgetsLayoutsConfig } from '../../dashboardUtils'
import { AuthContext } from '../../../../../utils/contexts/AuthContext'
import { EAccountType } from '../../../../../utils/app-models'

interface IpopoverFilterWidgets {
	onRemoveWidgets
	onAddWidgets
	widgets
}

const PopoverFilterWidgets: React.FC<IpopoverFilterWidgets> = ({
	onRemoveWidgets,
	onAddWidgets,
	widgets,
}) => {
	const [anchorEl, setAnchorEl] = useState(null)
	const { checkPermissions, checkAccountsType } = useContext(AuthContext)
	const intlMsg = useFormatMessage()
	const isAGuestUser = checkAccountsType([EAccountType.guest])

	//////////////////////////////////////////////////////////////////////
	// Open/close Popover
	//////////////////////////////////////////////////////////////////////

	const handleClick = useCallback((event) => {
		setAnchorEl(event.currentTarget)
	}, [])

	const handleClose = useCallback(() => {
		setAnchorEl(null)
	}, [])

	const open = Boolean(anchorEl)

	//////////////////////////////////////////////////////////////////////
	// Add/remove widget
	//////////////////////////////////////////////////////////////////////

	const handleCheck = useCallback(
		(columnTitle, isChecked) => () => {
			isChecked
				? onRemoveWidgets(
						widgets.lg.filter((widget) => widget.i !== columnTitle)
				  )
				: onAddWidgets(
						initialWidgetsLayoutsConfig.lg.filter(
							(widget) => widget.i === columnTitle
						)
				  )
		},
		[widgets]
	)

	const IsChecked = useCallback(
		(widgetName) => () => {
			return !!widgets?.lg?.find((widget) => widget?.i === widgetName)
		},
		[widgets]
	)

	//////////////////////////////////////////////////////////////////////
	// translate widget name to sort by alphabetic
	//////////////////////////////////////////////////////////////////////

	const columnsTitleSortByAlphabetic = [] as any
	initialWidgetsLayoutsConfig.lg
		.filter(
			({ permissions, notForGest }) =>
				checkPermissions(permissions) && (!isAGuestUser || !notForGest)
		)
		.forEach(({ titleFilter, i }) => {
			columnsTitleSortByAlphabetic.push({
				translate: intlMsg(titleFilter).toUpperCase(),
				i,
			})
		})

	return (
		<>
			<Button
				variant='outlined'
				size='medium'
				startIcon={<DashboardCustomizeIcon />}
				onClick={handleClick}
			>
				{intlMsg('misc.custom')}
			</Button>

			<Popover open={open} anchorEl={anchorEl} onClose={handleClose}>
				<List>
					<ListSubheader component='div'>
						<Typography variant='overline' display='block' gutterBottom>
							{intlMsg('widget.availableWidgets')}
						</Typography>
						<Divider />
					</ListSubheader>

					{columnsTitleSortByAlphabetic
						.sort((x, y) => x.translate.localeCompare(y.translate))
						.map(({ translate, i: widgetName }, i) => {
							const isChecked = IsChecked(widgetName)()
							return (
								<ListItem
									key={`columnFilters-${i}-${translate}`}
									role={undefined}
									dense
									button
									onClick={handleCheck(widgetName, isChecked)}
								>
									<ListItemIcon>
										<Checkbox
											edge='start'
											checked={isChecked}
											disableRipple
											color={'primary'}
										/>
									</ListItemIcon>
									<ListItemText id={'labelId'} primary={translate} />
								</ListItem>
							)
						})}
				</List>
			</Popover>
		</>
	)
}

export default memo(PopoverFilterWidgets)
