export default {
	immat: 'License Plate',
	brand: 'Brand',
	model: 'Model',
	dateImmat: 'Registration Date',
	commercialName: 'Commercial Name',
	vin: 'VIN',
	bodywork: 'Bodywork',
	vehicleType: 'Vehicle Category',
	fuel: 'Fuel',
	transmission: 'Transmission',
	powerCH: 'Power (HP)',
	powerCV: 'Power (CV)',
	displacement: 'Displacement',
	nbDoors: 'Number of Doors',
	nbSeats: 'Number of Seats',
	ptac: 'Gross Vehicle Weight',
	dimAv: 'Front Tire Size',
	dimAr: 'Rear Tire Size',
	dateParcEntry: 'Entry Date',
	dateParcExit: 'Exit Date',

	siteName: 'Site Name',
	address: 'Address',
	zipCode: 'Zip Code',
	city: 'City',
	country: 'Country',
	siret: 'SIRET',
	phone: 'Phone',
	cellphone: 'Mobile',
}
