import React, { useMemo } from 'react'
import { Checkbox, Radio, Typography, Stack } from '@mui/material'
import { EFormTemplateSelectionType } from '../../../../../utils/app-models'

const mainCSS = { marginLeft: '-15px', maxHeight: '20px' }

interface ISignature {
	fieldTemplate
	record
}

const Selection: React.FC<ISignature> = ({ fieldTemplate, record }) => {
	const attributeTemplateParse = useMemo(
		() => JSON.parse(fieldTemplate.attributes[0]),
		[fieldTemplate]
	)
	const value = useMemo(() => record?.value?.value, [record])

	return (
		<Stack direction='row' sx={mainCSS}>
			{attributeTemplateParse?.selectionsList.map(({ name }, i) => {
				const valueSelected =
					(value && value[i]?.selected) ||
					attributeTemplateParse?.selectionsList[i]?.selected

				return (
					<Stack
						key={`selectionListView-${name}-${i}`}
						direction='row'
						alignItems='center'
					>
						{attributeTemplateParse?.selectionType ===
						EFormTemplateSelectionType.multiples ? (
							<Checkbox
								checked={valueSelected}
								color='primary'
								disabled
								style={{ color: valueSelected ? '#2196F3' : '#5f5e5e' }}
							/>
						) : (
							<Radio
								checked={valueSelected}
								color='primary'
								disabled
								style={{ color: valueSelected ? '#2196F3' : '#5f5e5e' }}
							/>
						)}
						<Typography variant='body2'>{name}</Typography>
					</Stack>
				)
			})}
		</Stack>
	)
}

export default Selection
