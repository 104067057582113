import React from 'react'

import { Stack, Typography, Box } from '@mui/material'

import TitleField from './TitleField'
import { useFormatMessage } from '../../../../../../utils/hooks'
import { EFormTemplateFormatText } from '../../../../../../utils/app-models'

const fieldTxtCSS = (typeTxt) => ({
	width: 1,
	border: ({ palette }) => `2px solid ${palette.grey[300]}`,
	p: 1,
	mt: 1,
	color: ({ palette }) => palette.grey[400],
	borderRadius: 1,
	height: typeTxt === EFormTemplateFormatText.simpleText ? '40px' : '80px',
})

interface IFieldTxt {
	field
	deleteField
	index
	updateField
	insertField
	simpleText?
}

const FieldTxt: React.FC<IFieldTxt> = ({
	field: { id, name, values, fieldType },
	deleteField,
	index,
	updateField,
	insertField,
	simpleText = false,
}) => {
	const intlMsg = useFormatMessage()

	return (
		<Stack p={1}>
			<TitleField
				deleteField={deleteField}
				index={index}
				fieldToEdit={{
					id,
					name,
					values,
					fieldType,
					updateField,
					index,
				}}
				isRequired={values?.isRequired}
				insertField={insertField}
				values={values}
				fieldType={fieldType}
			/>
			<Box
				sx={fieldTxtCSS(
					simpleText
						? EFormTemplateFormatText.simpleText
						: values?.formatTextField
				)}
			>
				<Typography variant='body2'>
					{intlMsg('formTemplate.typeYourText')}
				</Typography>
			</Box>
		</Stack>
	)
}

export default FieldTxt
