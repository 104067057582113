import React, { useState, useCallback, useContext, useMemo } from 'react'

import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Box, IconButton } from '@mui/material'

import { ModalContext } from '../../../../../utils/contexts/ModalContext'
import PopoverActionsBtn from './PopoverActionsBtn'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../../../../utils/contexts/AuthContext'
export const actionBtnCellCSS = {
	height: '48px',
	width: '100%',
	boxShadow: ' #00000014 -5px 1px 3px 0px',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	paddingRight: '5px!important',
	paddingLeft: '5px!important',
	background: 'white',
}

interface IActionsBtn {
	rowId
	deleted?: any
	Edit?
	duplicate?
	viewModalLink?
	isContact?
	withSlide?
	viewAction?
	permissions?
	editPath?
	context?
}

const ActionsBtn: React.FC<IActionsBtn> = ({
	rowId,
	deleted,
	Edit = null,
	duplicate = null,
	viewModalLink,
	isContact,
	withSlide,
	viewAction = true,
	permissions = {},
	editPath = null,
	context = ModalContext,
}) => {
	const { checkPermissions } = useContext(AuthContext)
	// Open/close popover ///////////////////////////
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
	const open = Boolean(anchorEl)
	const handleClick = useCallback(
		(event: React.MouseEvent<HTMLButtonElement>) =>
			setAnchorEl(event.currentTarget),
		[anchorEl]
	)
	const handleClose = useCallback(() => setAnchorEl(null), [anchorEl])

	// Edit Modal ///////////////////////////////////////
	const navigate = useNavigate()
	const { openModal, isVisible }: any = useContext(context)
	const handleEditClick = useCallback(() => {
		if (editPath) navigate(editPath)
		else openModal()
		handleClose()
	}, [editPath, navigate])

	const havePermissions = useMemo(() => {
		const permissionsArray: any = Object.values(permissions).reduce(
			(acc: any, curr: any) => [...acc, ...curr],
			[]
		)

		return checkPermissions(permissionsArray?.legnth ? permissionsArray : null)
	}, [permissions, checkPermissions])

	return (
		havePermissions && (
			<>
				<Box sx={actionBtnCellCSS}>
					<IconButton
						size='small'
						onClick={handleClick}
						data-test-id='Playwright-ActionsBtn-moreAction'
					>
						<MoreVertIcon />
					</IconButton>
				</Box>

				{open && (
					<PopoverActionsBtn
						actions={{ duplicate, deleted, Edit, viewAction }}
						popover={{ open, anchorEl, handleClose }}
						rowId={rowId}
						viewModalLink={viewModalLink}
						permissions={permissions}
						editPath={editPath}
						handleEditClick={handleEditClick}
						isContact={isContact}
						withSlide={withSlide}
					/>
				)}

				{isVisible && Edit}
			</>
		)
	)
}

export default ActionsBtn
