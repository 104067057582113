/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const imageFieldPDF = (doc, record, position) => {
	const { positionYcell, positionXcell, widthImg, heightImg } = position

	// add image
	// 	HELP -> addImage(imageData, format, positionXcell, positionYcell, width, height, alias, compression, rotation)
	record &&
		doc.addImage(
			record,
			'JPEG',
			positionXcell,
			positionYcell,
			widthImg,
			heightImg
		)
}
