import React, { useCallback } from 'react'

import ModalAnnotationButton from '../interventionForms/componentsForm/Picture/ModalAnnotationButton'

interface IPhotosInterventions {
	attributeTemplateParse?
	indexImg
	record
	type
}

const PhotosInterventions: React.FC<IPhotosInterventions> = ({
	record,
	indexImg,
	attributeTemplateParse,
	type,
}) => {
	const propsByType = useCallback(() => {
		if (type === 'document') {
			return {
				name: attributeTemplateParse[indexImg]?.name,
				values: record || null,
				indexImg: indexImg,
				isDocument: true,
			}
		} else if (type === 'picture') {
			return {
				angleType: attributeTemplateParse[indexImg]?.angleType,
				name: attributeTemplateParse[indexImg]?.name,
				values: record || null,
				indexImg: `${attributeTemplateParse[indexImg]?.angleType}-${indexImg}`,
			}
		}
	}, [type, indexImg, attributeTemplateParse, record]) as any

	return <ModalAnnotationButton {...propsByType()} />
}

export default PhotosInterventions
