import React, { useCallback, useContext, useMemo } from 'react'

import { useQuery } from '@apollo/client'
import { Box, Stack, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'

import { IModalUtils, useModal } from '../../../../../../utils/hooks'
import {
	FormModalContext,
	IFormModalContext,
} from '../../../../../../components/forms/formModal/FormModalContext'
import FormTextField from '../../../../../../components/forms/formFields/FormTextField'
import FormSwitchButton from '../../../../../../components/forms/formFields/FormSwitchButton'
import FormSelectSearch from '../../../../../../components/forms/formFields/formSelectSearch'
import { GET_PRODUCT_BASE_CATEGORIES } from '../../../../../../api/gql/queries/catalogProductQueries'
import FormTimePicker from '../../../../../../components/forms/formFields/FormTimePicker'
import { baseType, unitsOfWork } from '../../../../../../utils/const'
import translateIntlMsgString from '../../../../../../utils/translateIntlMsgString'
import ConfirmModal from '../../../../../../components/modal/confirmModal/index'

const typoCSS = { color: grey[600], fontWeight: 500 }
const wrapperModalCSS = {
	p: 4,
	overflow: 'auto',
}
const inputProps = { min: 0, step: 0.01 }
const FormSwitchButtonConfig = {
	primary: { text: translateIntlMsgString('misc.active'), value: true },
	secondary: { text: translateIntlMsgString('misc.inactive'), value: false },
}

interface ICatalogueProductBaseModalInfos {}

const CatalogueProductBaseModalInfos: React.FC<
	ICatalogueProductBaseModalInfos
> = () => {
	const confirmStatusModalUtils: IModalUtils = useModal()
	const { setValue } = useContext<IFormModalContext>(FormModalContext)

	/////////////////////////////////////////////////////////
	// Selected elements
	/////////////////////////////////////////////////////////

	const {
		data: resultProductBaseCategories,
		loading: loadingProductBaseCategories,
	} = useQuery(GET_PRODUCT_BASE_CATEGORIES, { variables: { isActive: true } })

	const categoriesSelectElements = useMemo(
		() =>
			resultProductBaseCategories?.productBaseCategories?.collection?.map(
				(category) => ({
					key: category.id,
					label: category.name,
					value: category,
				})
			),

		[resultProductBaseCategories]
	)

	/////////////////////////////////////////////////////////
	// Status confirm modal
	/////////////////////////////////////////////////////////

	const onConfirmStatusModal = useCallback(() => {
		setValue('isActive', false)
		confirmStatusModalUtils.closeModal()
	}, [setValue])

	const handleSwitchIsActive = useCallback(
		({ value }) => {
			value ? setValue('isActive', true) : confirmStatusModalUtils.openModal()
		},
		[setValue]
	)

	return (
		<Stack sx={wrapperModalCSS} spacing={2}>
			<FormTextField
				name='name'
				label={translateIntlMsgString('misc.lib')}
				context={FormModalContext}
				isRequired
				trigerred
			/>

			<FormSelectSearch
				name='type'
				label={translateIntlMsgString('misc.type')}
				selectElements={baseType}
				context={FormModalContext}
				isRequired
				trigerred
				disabledSort
				displayColorInput
			/>

			<Stack direction='row' spacing={2}>
				<FormTextField
					name='rate'
					type='number'
					label={translateIntlMsgString('misc.priceHt')}
					context={FormModalContext}
					inputProps={inputProps}
				/>

				<FormSelectSearch
					name='unitOfWork'
					label={translateIntlMsgString('misc.workUnit')}
					selectElements={unitsOfWork}
					context={FormModalContext}
					displayColorInput
					disabledSort
				/>

				<FormTimePicker
					name='duration'
					label={translateIntlMsgString('misc.duration')}
					context={FormModalContext}
				/>
			</Stack>

			<Box>
				<Typography variant='body2' sx={typoCSS} gutterBottom>
					{translateIntlMsgString('formTemplate.description')}
				</Typography>
				<FormTextField
					name='description'
					label={translateIntlMsgString('catalogProduct.descProductBase')}
					context={FormModalContext}
					multiline={true}
					minRows={2}
					maxRows={2}
				/>
			</Box>

			<FormSelectSearch
				name='productBaseCategory'
				label={translateIntlMsgString('catalogProduct.category')}
				selectElements={categoriesSelectElements}
				context={FormModalContext}
				isLoading={loadingProductBaseCategories}
				isRequired
				trigerred
			/>

			<FormTextField
				name='idERP'
				label={translateIntlMsgString('catalogProduct.idGestionProductBase')}
				context={FormModalContext}
			/>

			<Box>
				<Typography variant='body2' sx={typoCSS} gutterBottom>
					{translateIntlMsgString('misc.status')}
				</Typography>

				<FormSwitchButton
					name='isActive'
					config={FormSwitchButtonConfig}
					specialOnChange={handleSwitchIsActive}
					context={FormModalContext}
				/>

				{confirmStatusModalUtils?.isVisible && (
					<ConfirmModal
						onClose={confirmStatusModalUtils?.closeModal}
						open={confirmStatusModalUtils?.isVisible}
						onConfirmStatusModal={onConfirmStatusModal}
						message={translateIntlMsgString(
							'catalogProduct.modalConfirmProductBase'
						)}
						severity='warning'
					/>
				)}
			</Box>
		</Stack>
	)
}

export default CatalogueProductBaseModalInfos
