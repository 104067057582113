/* eslint-disable no-case-declarations */
import React, { useMemo } from 'react'

import { Box, Fade } from '@mui/material'

import { stringifiedColor } from '../../../../../utils/color'
import InterventionControlMonthEvent from './InterventionControlMonthEvent'
import ConvoyingMonthEvent from './ConvoyingMonthEvent'

const mainCSS = (isSelected, searchText, borderColor, event) => ({
	height: 1,
	fontSize: '13px',
	opacity: (searchText ? (isSelected ? '1' : '0.1') : '1') + '!important',
	borderLeft: borderColor
		? `4px solid ${stringifiedColor(
				event?.resource?.status?.color || 'grey',
				400
		  )} !important`
		: '',
	paddingLeft: borderColor ? '2px' : '',
})

const renderMonthEventByInterventionType = (
	interventionType,
	transportType
) => {
	switch (interventionType) {
		case 'transport':
			if (transportType === 'CONVOY') return ConvoyingMonthEvent
			break

		case 'control':
			return InterventionControlMonthEvent
	}
}

interface ICustomMonthEvent {
	event?: any
	searchText?: string
	isSelected?: boolean
	handleClick?: any
	setIsMembersCellOpen?: any
	borderColor?: boolean
}

const CustomMonthEvent: React.FC<ICustomMonthEvent> = ({
	event,
	searchText,
	isSelected,
	handleClick,
	setIsMembersCellOpen,
	borderColor = false,
}) => {
	const { interventionType, interventionTransportType } = event?.resource || {}

	const CustomMonthComponent = useMemo(() => {
		return renderMonthEventByInterventionType(
			interventionType,
			interventionTransportType?.code
		)
	}, [interventionType, interventionTransportType?.code]) as any

	return (
		<Fade in={true}>
			<Box
				sx={mainCSS(isSelected, searchText, borderColor, event)}
				onClick={handleClick}
			>
				<CustomMonthComponent
					setIsMembersCellOpen={setIsMembersCellOpen}
					event={event}
				/>
			</Box>
		</Fade>
	)
}

export default CustomMonthEvent
