/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { gql, useMutation } from '@apollo/client'
import { teamPayloadAttributes } from '../attributes/teamsAttributes'

/**  ---------------------MUTATIONS---------------------- */

export const UPDATE_TEAM = gql`
	mutation updateTeam($input: updateTeamInput!) {
		updateTeam(input: $input) {
			${teamPayloadAttributes}
		}
	}
`

export const CREATE_TEAM = gql`
	mutation createTeam($input: createTeamInput!) {
		createTeam(input: $input) {
			${teamPayloadAttributes}
		}
	}
`

export const DELETE_TEAM = gql`
	mutation deleteTeam($input: deleteTeamInput!) {
		deleteTeam(input: $input) {
			team {
				id
			}
		}
	}
`

/**  ---------------------HOOKS---------------------- */

export const useUpdateTeams = () => useMutation(UPDATE_TEAM)
export const useCreateTeams = () => useMutation(CREATE_TEAM)
export const useDeleteTeams = () => useMutation(DELETE_TEAM)
