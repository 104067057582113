import React, { useCallback, useContext, useEffect } from 'react'

import MuiModal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import Box from '@mui/material/Box'

import SelectVehiclesModalHeader from './SelectVehiclesModalHeader'
import { modalCSS } from '../../../../../../../components/forms/modalSxCss'
import SelectVehiclesModalFooter from './SelectVehiclesModalFooter'
import SelectedVehiclesModalContent from './selectVehiclesModalContent'
import {
	FormModalContext,
	IFormModalContext,
} from '../../../../../../../components/forms/formModal/FormModalContext'

interface ISelectedVehiclesModal {
	closeModal: any
	isVisible: any
}

const SelectVehiclesModalSx = {
	...modalCSS,
	width: '90%',
	height: '95%',

	display: 'flex',
	flexDirection: 'column',
	flexWrap: 'nowrap',
	justifyContent: 'space-between',
	alignContent: 'stretch',
	alignItems: 'center',
}

const fadeCSS = {
	'& > div ': {
		flexGrow: '1',
	},
}

const SelectedVehiclesModal: React.FC<ISelectedVehiclesModal> = ({
	closeModal,
	isVisible,
}) => {
	const { data, setValue } = useContext<IFormModalContext>(FormModalContext)

	const handleCloseModal = useCallback(() => {
		closeModal()
	}, [])

	useEffect(() => {
		setValue('selectedRow', data?.selectedVehicles || [])
	}, [])

	return (
		<MuiModal
			open={isVisible}
			onClose={handleCloseModal}
			closeAfterTransition
			sx={fadeCSS}
		>
			<Fade in={isVisible}>
				<Box sx={SelectVehiclesModalSx}>
					<SelectVehiclesModalHeader closeModal={closeModal} />
					<SelectedVehiclesModalContent />
					<SelectVehiclesModalFooter closeModal={closeModal} />
				</Box>
			</Fade>
		</MuiModal>
	)
}
export default SelectedVehiclesModal
