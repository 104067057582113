import React, { memo } from 'react'
import { Box, Fade } from '@mui/material'
import Table from '../../../tableV2'

interface ITableViewProps {
	sideNavSelectedId
	tableViewConfig
}

const TableView: React.FC<ITableViewProps> = ({
	sideNavSelectedId,
	tableViewConfig,
}) => {
	const { tableConfig, othersQueryParams, emptyMessage } = tableViewConfig || {}

	return sideNavSelectedId ? (
		<Fade in timeout={500}>
			<Box component='span' overflow='auto' width='100%'>
				<Table
					tableConfig={tableConfig}
					overrideConfigTable={{
						elevationNbr: 0,
						othersQueryParams: { [othersQueryParams]: sideNavSelectedId },
						tableContainer: { disabledCheckBox: true },
					}}
				/>
			</Box>
		</Fade>
	) : (
		emptyMessage()
	)
}

export default memo(TableView)
