import React, { useCallback, useEffect, useMemo, useState } from 'react'

import TasksAndCategoriesListItem from './TasksAndCategoriesListItem'
import { deleteDuplicateCategoriesTask } from '../../../../utils/export/utilsExport'
import MultiItemsButton from '../../MultiItemsButton'
import { useFormatMessage } from '../../../../utils/hooks'
import MultiItemsPopover from '..'

interface ITasksAndCategoriesPopover {
	tasks
	isIpv?
	globalTasks?
}

const TasksAndCategoriesPopover: React.FC<ITasksAndCategoriesPopover> = ({
	tasks,
	isIpv = false,
	globalTasks = {},
}) => {
	const intlMsg = useFormatMessage()
	const [anchorEl, setAnchorEl] = useState(null)
	const handleClick = useCallback((e) => setAnchorEl(e.currentTarget), [])
	const handleClose = useCallback(() => setAnchorEl(null), [])
	const open = useMemo(() => Boolean(anchorEl), [anchorEl])
	const [formatValuesCategoriesTask, setFormatValuesCategoriesTask] =
		useState<any>([])
	const [numberProducts, setNumberProducts] = useState(0)

	useEffect(() => {
		if (isIpv) {
			const mergedTask =
				tasks?.flatMap(
					(value) =>
						value.products.collection?.map(({ product }) => product) ?? []
				) || []

			const tmpFormatValuesCategoriesTask = deleteDuplicateCategoriesTask([
				...mergedTask,
				...(globalTasks?.collection || []),
			])

			setFormatValuesCategoriesTask(tmpFormatValuesCategoriesTask)
			setNumberProducts(
				Object.values(tmpFormatValuesCategoriesTask).flat()?.length
			)
		} else {
			setFormatValuesCategoriesTask(
				deleteDuplicateCategoriesTask(tasks?.map(({ product }) => product))
			)
			setNumberProducts(tasks?.length)
		}
	}, [tasks, isIpv])

	const label = useMemo(() => {
		if (numberProducts > 1) return intlMsg('interventions.task')
		else {
			const [categorie, task] =
				Object.entries(formatValuesCategoriesTask)?.[0] || []
			return `${categorie} - ${task}`
		}
	}, [numberProducts, formatValuesCategoriesTask])

	return (
		<>
			<MultiItemsButton
				buttonType={numberProducts > 1 ? 'chipCountClickable' : 'chip'}
				nbItems={numberProducts}
				label={label}
				handleClick={handleClick}
			/>

			{open && (
				<MultiItemsPopover
					open={open}
					handleClose={handleClose}
					anchorEl={anchorEl}
				>
					<TasksAndCategoriesListItem data={formatValuesCategoriesTask} />
				</MultiItemsPopover>
			)}
		</>
	)
}

export default TasksAndCategoriesPopover
