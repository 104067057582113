import FeatureNotAvailable from './alertComponents/FeatureNotAvailable'

const getViewModalAlertConfig: any = ({ path }) => {
	switch (path) {
		case '/vehicles/fusion':
			return {
				AlertContent: FeatureNotAvailable,
				title: 'confirmModal.vehiclesFusionTitle',
			}

		default:
			return null
	}
}

export default getViewModalAlertConfig
