/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import Typography from '@mui/material/Typography'

import BooleanColorPopover from '../../../../popover/BooleanColorPopover'
import LocationAddress from '../../../../locationAddress'
import { useUpdateCustomer } from '../../../../../api/gql/mutations/customerMutation'
import OpenLinkCell from '../../../tableCell/OpenLinkCell'
import { useRemoveCustomer } from '../../../../../api/gql/mutations/customerMutation'
import { EPermission, EPermissionScope } from '../../../../../utils/app-models'
import { ROUTES } from '../../../../../routes/consts'
import CustomerAccountsModal from '../../../../../view/dashboard/customerAccounts/customerAccountsModal'
import { IModalUtils } from '../../../../../utils/hooks'
import ActionsBtn from '../../../tableCell/actionsBtn'
import { Box } from '@mui/material'
import UserAvatarSlidePanel from '../../../../avatar/UserAvatarSlidePanel'

//!  columnLabel is mandatory
//!  dataKey OR personalizedCell is mandatory
// use datakey if you want to just display data
// personalized Cell is a function taking row as parameter

const listCustomersViewTable = [
	{
		columnLabel: 'misc.generalAccount',
		permissions: [EPermission.superMegaAdmin],
		personalizedCell: ({ accountGestionnaire }) => (
			<Box>{accountGestionnaire?.name}</Box>
		),
	},
	{
		columnLabel: 'misc.name',
		orderBy: 'name',
		stickyFirstCol: true,
		personalizedCell: ({ id, name, isSharedWithMe }) => (
			<OpenLinkCell
				viewModalLink={`${ROUTES.VIEWMODAL.CUSTOMERS.url}${id}`}
				txt={name}
				isSharedWithMe={isSharedWithMe}
				maxCharacters={60}
			/>
		),
	},

	{
		columnLabel: 'misc.address',
		orderBy: 'address',
		personalizedCell: ({ address, city, zipcode }) => (
			<LocationAddress
				address={address}
				city={city}
				zipcode={zipcode}
				maxCharacters={40}
			/>
		),
	},

	{
		columnLabel: 'misc.manager',
		orderBy: 'gestionnaire_lastName',
		personalizedCell: ({ gestionnaire }) => (
			<UserAvatarSlidePanel
				firstName={gestionnaire?.firstName}
				lastName={gestionnaire?.lastName}
				userId={gestionnaire?.id}
				accountId={gestionnaire?.account?.id}
				variantText='inherit'
				displayName
			/>
		),
	},

	{
		columnLabel: 'misc.nbSites',
		// sortId: 'sites.paginationInfo.totalCount',
		personalizedCell: (row) => (
			<Typography variant='caption'>
				{row?.sites?.paginationInfo?.totalCount || '-'}
			</Typography>
		),
	},

	{
		columnLabel: 'misc.nbInterventions',
		// sortId: 'orderedInterventions.paginationInfo.totalCount',
		personalizedCell: ({
			orderedInterventions: {
				paginationInfo: { totalCount },
			},
		}) => <Typography variant='caption'>{totalCount || '-'}</Typography>,
	},

	{
		columnLabel: 'misc.active',
		orderBy: 'isActive',
		tableCellAlign: 'center',
		cellAlign: 'center',
		personalizedCell: (row: any) => {
			return (
				<BooleanColorPopover
					value={row.isActive}
					id={row.id}
					txt={{ true: 'misc.active', false: 'misc.desactive' }}
					titlePopover={'misc.customerStatusState'}
					queryConfig={{
						query: useUpdateCustomer,
						refreshQuery: ['getCustomersList'],
						successMessage: 'success.statusUpdate',
					}}
				/>
			)
		},
	},

	{
		fixWidth: '50px',
		// sortId: 'null',
		visible: true,
		columnLabel: '',
		tableCellAlign: 'center',
		cellAlign: 'center',
		sticky: true,
		personalizedCell: ({ id: customerId, isSharedWithMe }) => (
			<ActionsBtn
				rowId={customerId}
				deleted={{
					query: useRemoveCustomer,
					refreshQuery: ['getCustomersList'],
					successMessageDelete: 'success.accountDeleted',
					messageConfirm: {
						title: 'confirmModal.deleteCustomerAccountTitle',
						message: 'confirmModal.deleteCustomerAccountMessage',
					},
				}}
				editModal={
					<CustomerAccountsModal
						customerId={customerId}
						modalUtils={{} as IModalUtils}
					/>
				}
				viewModalLink={`${ROUTES.VIEWMODAL.CUSTOMERS.url}${customerId}`}
				isSharedWithMe={isSharedWithMe}
				permissions={{
					update: [EPermissionScope.customerManage],
					remove: [EPermissionScope.customerManage],
					delete: [EPermissionScope.customerDelete],
				}}
			/>
		),
	},
]

export default listCustomersViewTable
