import React, { useState, useCallback } from 'react'

import { Responsive as ResponsiveGridLayout } from 'react-grid-layout'
import { withSize } from 'react-sizeme'
import { Box } from '@mui/material'

import {
	dashboardWidgets,
	mergeLayoutWidgetsProperties,
} from '../dashboardUtils'
import { saveToLS } from '../../../../utils/localStorage'
import { layoutGridCSS } from '../../../../styles/css/dashboardGridLayout'

const containerWidgetCSS = (layoutIsDraggable) => ({
	...(layoutIsDraggable && {
		cursor: 'grab',
		'& #widget': { transition: 'all 0.2s ease-in' },
		'& #widget:hover': {
			transition: 'all 0.1s ease-out',
			boxShadow: ' 0 0 11px 1px rgb(0 0 0 / 19%)',
		},
	}),
})

interface IDashboardWidgetZone {
	size
	widgets
	setWidgets
	filtersQuerieParam
	layoutIsDraggable
}

const DashboardWidgetZone: React.FC<IDashboardWidgetZone> = ({
	size: { width },
	widgets,
	setWidgets,
	filtersQuerieParam,
	layoutIsDraggable,
}) => {
	const [globalArgumentsQuery] = useState({ itemsPerPage: 5000, page: 1 })

	const onLayoutChange = useCallback(
		(_, allLayouts) => {
			const newLayout = mergeLayoutWidgetsProperties(widgets, allLayouts)
			saveToLS('layoutsDashboard', newLayout)
			setWidgets(newLayout)
		},
		[widgets]
	)

	return (
		<Box sx={layoutGridCSS}>
			<ResponsiveGridLayout
				className='layout'
				layouts={widgets}
				breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
				cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
				rowHeight={50}
				width={width}
				onLayoutChange={onLayoutChange}
				containerPadding={[0, 0]}
				isDraggable={layoutIsDraggable}
			>
				{widgets.lg.map(({ i: widgetName, additionalArgumentsQuery }) => (
					<Box
						key={widgetName}
						className='widget'
						sx={containerWidgetCSS(layoutIsDraggable)}
						data-grid={{ w: 3, h: 2, x: 0, y: Infinity }}
					>
						{dashboardWidgets(widgetName, {
							...globalArgumentsQuery,
							...additionalArgumentsQuery,
							...filtersQuerieParam,
						})}
					</Box>
				))}
			</ResponsiveGridLayout>
		</Box>
	)
}

export default withSize({ refreshMode: 'debounce', refreshRate: 60 })(
	DashboardWidgetZone
)
