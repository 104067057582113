import React, { cloneElement, Children } from 'react'
import dayjs from 'dayjs'
import { stringifiedColor } from '../../../utils/color'

interface IColoredDateCellWrapper {
	children: any
	value: any
	selectedSlotsObj: any
}

const ColoredDateCellWrapper: React.FC<IColoredDateCellWrapper> = ({
	children,
	value,
	selectedSlotsObj,
}) => {
	const isSelected = dayjs(value).isBetween(
		dayjs(selectedSlotsObj?.start).subtract(1, 'm').toJSON(),
		selectedSlotsObj?.end
	)

	return cloneElement(Children.only(children), {
		style: {
			...children.style,
			backgroundColor: isSelected
				? stringifiedColor('grey', 400)
				: dayjs(value).startOf('day').toISOString() ===
				  dayjs().startOf('day').toISOString()
				? '#eaf6ff'
				: 'white',
		},
		id: isSelected ? `selected-slot` : 'unselected-slot',
	})
}

export default ColoredDateCellWrapper
