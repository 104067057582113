import React, { useCallback, useContext, useState } from 'react'
import toast from 'react-hot-toast'

import {
	useCreateIntervention,
	useUpdateIntervention,
} from '../../../../../api/gql/mutations/interventionsMutation'

import { IModalUtils, useFormatMessage } from '../../../../../utils/hooks'
import {
	EFormModalMode,
	EInterventionStatuses,
} from '../../../../../utils/app-models'
import {
	useLazyInterventionForModal,
	useLazyInterventionStatus,
} from '../../../../../api/gql/queries/interventionsQueries'
import FormModal from '../../../../../components/forms/formModal'
import interventionModalConfig from './interventionModalConfig'
import {
	formatInterventionModalCreateDefaultValues,
	formatInterventionModalEditDefaultValues,
	formatInterventionModalOnValidateData,
} from './interventionModalUtils'
import { AuthContext } from '../../../../../utils/contexts/AuthContext'
import { IFormModalOtherButtons } from '../../../../../components/forms/formModal/formModalType'
import { useLazyCustomer } from '../../../../../api/gql/queries/customersQueries'
import { useLazyVehicle } from '../../../../../api/gql/queries/vehiclesQueries'
import { useLazySite } from '../../../../../api/gql/queries/sitesQueries'
import qs from 'qs'

const otherBtns: IFormModalOtherButtons[] = [
	{
		switchTabIndex: 1,
		text: 'interventions.affectVehicle',
		position: 'middle',
	},
]

interface IInterventionInspectionModal {
	interventionId?
	startDate?: Date | null
	endDate?: Date | null
	isMonthSelection?: boolean
	onClose?: any
	mode?: EFormModalMode
	modalUtils: IModalUtils
}

const InterventionInspectionModal: React.FC<IInterventionInspectionModal> = ({
	interventionId,
	startDate = null,
	endDate = null,
	isMonthSelection = false,
	onClose = null,
	mode,
	modalUtils,
}) => {
	const intlMsg = useFormatMessage()
	const [createIntervention] = useCreateIntervention()
	const [updateIntervention] = useUpdateIntervention()
	const { getIntervention } = useLazyInterventionForModal()
	const { currentUser } = useContext(AuthContext)
	const { getStatus }: any = useLazyInterventionStatus()
	const { getCustomer } = useLazyCustomer()
	const { getVehicle } = useLazyVehicle()
	const { getSite } = useLazySite()

	const queriesObject: any = {
		customer: getCustomer,
		site: getSite,
		vehicle: getVehicle,
	}
	// used to store unowned actors (actors in other account)
	// allow unowned accounts to not appear in the dropdown and use them for the update
	const [unownedActors, setUnownedActors] = useState([])

	const onValidate = useCallback(
		async ({ data, setValidationLoading, closeModal }) => {
			setValidationLoading(true)
			const mutate =
				mode === EFormModalMode.edit ? updateIntervention : createIntervention

			mutate({
				variables: {
					input: formatInterventionModalOnValidateData({
						data,
						mode,
						interventionId,
						unownedActors,
					}),
				},
				refetchQueries: [
					'interventionsList',
					'interventionsListForCalendar',
					'interventionForModal',
					'getInterventionForViewModal',
					'interventionProgrammedVehicles',
					'getInterventionNumbersByCustomer',
					'getInterventionNumbers',
					'getInterventionNumbersByVehicle',
					'getInterventionNumbersBySite',
					'getInterventionFormsNumbersByIntervention',
					'getInterventionsTotalNotAssigned',
					'getWVehicleInterventions',
				],
			})
				.then(() => {
					mode !== EFormModalMode.edit
						? toast.success('success.interventionCreate')
						: toast.success('success.interventionUpdate')
					setValidationLoading(false)
					closeModal()
				})
				.catch(() => setValidationLoading(false))
		},
		[mode, interventionId, unownedActors]
	)

	const initializeData = useCallback(
		async ({
			setValues,
			setLoading,
			setCurentEditedElement,
			setTabCounter,
		}) => {
			setLoading(true)
			getStatus()
				.then(async (res) => {
					const plannedStatus = res?.data?.interventionStatuses?.find(
						(status) => status?.code === EInterventionStatuses.planned
					)
					// Edit intervention OR duplicate
					if (mode !== EFormModalMode.create) {
						getIntervention({ variables: { id: interventionId } })
							.then((res) => {
								const data = res?.data?.intervention || null
								setValues(
									formatInterventionModalEditDefaultValues({
										data,
										currentUserAccountId: currentUser?.account?.id,
										setUnownedActors,
										setTabCounter,
										forcedStatus:
											mode === EFormModalMode.duplicate ? plannedStatus : null,
									})
								)
								setCurentEditedElement(data)
								setLoading(false)
							})
							.catch(() => setLoading(false))
					}
					// Create transport
					else {
						setLoading(true)

						const { query, varValue } = qs.parse(location.search, {
							ignoreQueryPrefix: true,
						})
						let dataPreFilling = null

						if (
							mode === EFormModalMode.create &&
							query &&
							Object.keys(queriesObject).find(
								(querieName) => querieName === query
							)
						) {
							const result = await queriesObject[query as string]?.({
								variables: { id: varValue },
							})
							dataPreFilling = result?.data[query as string]
						}

						setValues(
							formatInterventionModalCreateDefaultValues({
								currentUser,
								plannedStatus,
								startDate,
								endDate,
								dataPreFilling,
								isSite: query === 'site',
								isVehicle: query === 'vehicle',
							})
						)
						setLoading(false)
					}
				})
				.catch(() => setLoading(false))
		},
		[mode, interventionId, startDate, endDate, isMonthSelection, currentUser]
	)

	return (
		<FormModal
			modalUtils={modalUtils}
			formModalTabConfig={interventionModalConfig}
			formModalName={intlMsg(
				mode === EFormModalMode.edit
					? 'interventions.edit'
					: 'interventions.create'
			)}
			initialMode={mode}
			onValidate={onValidate}
			validateBtnText={intlMsg('misc.validate')}
			cancelBtnText={intlMsg('misc.cancel')}
			initializeData={initializeData}
			onClose={onClose}
			width='95%'
			minHeight='79vH'
			editedElementId={interventionId}
			otherButtons={otherBtns}
		/>
	)
}

export default InterventionInspectionModal
