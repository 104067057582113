import React, { useCallback, useState, useContext } from 'react'

import { Box, ClickAwayListener, Stack, TextField } from '@mui/material'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

import { useFormatMessage } from '../../../../../utils/hooks'
import { verifyAndSetLimitationField } from '../formEditionsUtils/fieldsLimitation'
import { FormTemplateContext } from '../../../../../utils/contexts/FormTemplateContext'

const popperContentCSS = {
	position: 'absolute',
	top: 35,
	right: -50,
	zIndex: 1,
	p: 1,
	cursor: 'pointer',
	color: 'red',
	boxShadow: '0 0 11px -4px rgb(0 0 0 / 19%)',
	backgroundColor: ({ palette }) => palette.background.paper,
	width: '135px',
}

interface ITitlePageContent {
	pageNbr: number
	removePage
	updatePage
	setSelectedPageNbr
	selectedPageNbr
	pageName: string
	pageFields
}

const TitlePageContent: React.FC<ITitlePageContent> = ({
	pageNbr,
	removePage,
	updatePage,
	setSelectedPageNbr,
	selectedPageNbr,
	pageName,
	pageFields,
}) => {
	const [localPageName, setLocalPageName] = useState(pageName)
	const [editTitle, setEditTitle] = useState(false)
	const intlMsg = useFormatMessage()
	const [openPoper, setOpenPoper] = useState(false)
	const { setLimitationFields } = useContext(FormTemplateContext)

	const handleChangePageName = useCallback((e) => {
		setLocalPageName(e.target.value)
	}, [])

	const handleOnKeyPress = useCallback(
		(e) => {
			if (e.key === 'Enter') {
				updatePage(selectedPageNbr, {
					name: e.target.value,
					fields: pageFields,
				})
				setEditTitle(false)
			}
		},
		[pageFields]
	)

	const handleOnBLurPageName = useCallback(
		(e) => {
			updatePage(selectedPageNbr, {
				name: e.target.value,
				fields: pageFields,
			})
			setEditTitle(false)
		},
		[pageFields]
	)

	// EditMod title page
	const handleChangeEditTitle = useCallback(() => {
		setEditTitle(true)
	}, [editTitle])

	// Delete page
	const deletePage = useCallback(
		(removeIndex) => () => {
			removePage(removeIndex)
			setSelectedPageNbr(selectedPageNbr - 1 >= 0 ? selectedPageNbr - 1 : 0)
			verifyAndSetLimitationField({ setLimitationFields, pageFields })
		},
		[pageFields]
	)

	const handleClickPoper = useCallback(() => setOpenPoper((prev) => !prev), [])

	const handleClickAwayPoper = useCallback(() => setOpenPoper(false), [])

	return (
		<Stack
			direction='row'
			justifyContent='space-between'
			alignItems={'center'}
			sx={{ width: 1 }}
			mb={2}
			mt={1}
		>
			<Stack
				direction='row'
				alignItems='center'
				onClick={handleChangeEditTitle}
				sx={{ width: 1 }}
			>
				{editTitle ? (
					<TextField
						onBlur={handleOnBLurPageName}
						variant='outlined'
						autoFocus
						onChange={handleChangePageName}
						value={localPageName}
						onKeyPress={handleOnKeyPress}
						inputProps={{
							style: {
								padding: 10,
								fontSize: '20px',
								fontFamily: "'Roboto', sans-serif",
								fontWeight: 'bold',
								lineHeight: '1.2',
							},
						}}
					/>
				) : (
					<>
						<Typography variant='h2' sx={{ mr: 1 }}>
							{localPageName}
						</Typography>
						<IconButton aria-label='delete' size='small'>
							<EditIcon fontSize='small' />
						</IconButton>
					</>
				)}
			</Stack>
			<Stack direction='row' justifyContent='end' alignItems='center'>
				<ClickAwayListener onClickAway={handleClickAwayPoper}>
					<Box position='relative'>
						<IconButton
							aria-label='delete'
							onClick={handleClickPoper}
							size='small'
						>
							<DeleteIcon fontSize='small' />
						</IconButton>

						{openPoper ? (
							<Box sx={popperContentCSS} onClick={deletePage(pageNbr)}>
								<Typography variant='body2'>
									{intlMsg('formTemplate.deletePage')}
								</Typography>
							</Box>
						) : null}
					</Box>
				</ClickAwayListener>
			</Stack>
		</Stack>
	)
}

export default TitlePageContent
