/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import dayjs from 'dayjs'

import BooleanColorPopover from '../../../../popover/BooleanColorPopover'
import { useUpdateBaseProduct } from '../../../../../api/gql/mutations/catalogProductsMutation'
import { EPermissionScope } from '../../../../../utils/app-models'
import ChipUnitOfWork from '../../../../chip/ChipUnitOfWork'
import ChipProductBaseType from '../../../../chip/ChipProductBaseType'
import CountCircle from '../../../../count/CountCircle'
import CatalogueProductBaseModal from '../../../../../view/administration/productCatalogue/catalogueProductBase/table/modal'
import ProductBaseTableBtn from '../../../../../view/administration/productCatalogue/catalogueProductBase/table/productBaseTableBtn'
import PermissionsHandler from '../../../../permissionsHandler'
import translateIntlMsgString from '../../../../../utils/translateIntlMsgString'
import ProductBaseTitleDrawer from '../../../tableCell/productBaseDrawer'

export const listProductBaseCatalogueViewTable = [
	{
		columnLabel: 'misc.label',
		orderBy: 'name',
		personalizedCell: ({ name, description, id }) => (
			<ProductBaseTitleDrawer
				description={description}
				name={name}
				rowId={id}
				editModal={
					<CatalogueProductBaseModal
						modalUtils={{} as any}
						productBaseId={id}
					/>
				}
			/>
		),
	},

	{
		columnLabel: 'misc.type',
		orderBy: 'type',
		personalizedCell: ({ type }) => (
			<ChipProductBaseType type={type} size='medium' />
		),
	},

	{
		columnLabel: 'misc.priceHt',
		orderBy: 'rate',
		personalizedCell: ({ rate }) => (
			<>{`${rate ? rate.toFixed(2) + '€' : '-'} `} </>
		),
	},

	{
		columnLabel: 'misc.duration',
		orderBy: 'duration',
		personalizedCell: ({ duration }) => (
			<>{duration ? dayjs(duration).format('HH:mm') : '-'}</>
		),
	},

	{
		columnLabel: 'misc.workUnit',
		orderBy: 'unitOfWork',
		personalizedCell: ({ unitOfWork }) => (
			<ChipUnitOfWork unitOfWork={unitOfWork} size='medium' />
		),
	},
	{
		columnLabel: 'catalogProduct.relatedProduct',
		personalizedCell: ({ products }) => (
			<CountCircle
				count={
					products?.collection?.filter((product) => product?.isActive)?.length
				}
			/>
		),
	},

	{
		columnLabel: 'catalogProduct.refERP',
		orderBy: 'idERP',
		dataKey: 'idERP',
	},

	{
		columnLabel: 'misc.active',
		tableCellAlign: 'center',
		cellAlign: 'left',
		width: '20%',
		personalizedCell: (row: any) => {
			return (
				<BooleanColorPopover
					value={row.isActive}
					id={row.id}
					txt={{ true: 'misc.active', false: 'misc.desactive' }}
					titlePopover={'catalogProduct.productBaseActive'}
					queryConfig={{
						query: useUpdateBaseProduct,
						refreshQuery: ['getProductBaseCategories', 'getProductBasesList'],
						successMessage: 'success.statusUpdate',
					}}
					key={row.id}
					permissions={[EPermissionScope.productBaseManage]}
					confirmModal={{
						message: translateIntlMsgString(
							'catalogProduct.modalConfirmProductBase'
						),
						severity: 'warning',
						openOnValue: 'false',
					}}
				/>
			)
		},
	},

	{
		visible: true,
		columnLabel: '',
		tableCellAlign: 'center',
		cellAlign: 'center',
		sticky: true,
		personalizedCell: ({ id }: any) => (
			<PermissionsHandler
				permissionsName={[EPermissionScope.productBaseManage]}
			>
				<ProductBaseTableBtn
					rowId={id}
					editModal={
						<CatalogueProductBaseModal
							modalUtils={{} as any}
							productBaseId={id}
						/>
					}
				/>
			</PermissionsHandler>
		),
	},
]
