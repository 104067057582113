import React, { useCallback, memo } from 'react'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import { useFormatMessage } from '../../../utils/hooks'

const tabCSS = {
	borderColor: '#ececec',
	borderStyle: 'solid',
	borderWidth: '0px 1px 0px 1px',
	fontSize: '0.7rem',
	color: '#999',
	minHeight: '40px',
	minWidth: 160,
}
const tabsCSS = {
	minHeight: '40px',
}

interface ISlidePanelUserInfosTab {
	tab
	valueTab: number
	setValueTab
}

const SlidePanelUserInfosTab: React.FC<ISlidePanelUserInfosTab> = ({
	tab,
	valueTab,
	setValueTab,
}) => {
	const intlMsg = useFormatMessage()
	const handleChange = useCallback((e, newValue) => setValueTab(newValue), [])

	const renderTabs = useCallback(
		(item, i) => (
			<Tab
				key={`${i + 1}-tab`}
				label={intlMsg(item?.label)}
				icon={item?.icon}
				sx={tabCSS}
			/>
		),
		[]
	)

	return (
		<div>
			<Tabs
				value={valueTab}
				onChange={handleChange}
				indicatorColor='primary'
				textColor='primary'
				variant='scrollable'
				scrollButtons='auto'
				sx={tabsCSS}
			>
				{tab()?.map(renderTabs)}
			</Tabs>
		</div>
	)
}

export default memo(SlidePanelUserInfosTab)
