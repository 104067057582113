/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'

import { useBulkInterventionAction } from '../../../../../api/gql/mutations/interventionsMutation'
import {
	LIST_INTERVENTIONS,
	useExportIntervention,
} from '../../../../../api/gql/queries/interventionsQueries'
import {
	EFormModalMode,
	EModule,
	EPermissionScope,
} from '../../../../../utils/app-models'
import { InterventionValidationSchema } from '../../../../../utils/validationSchema'

import listInterventionsByReferenceViewTable from '../../../../../components/tableV2/config/dashboard/interventions/byReference/listInterventionsControlByReferenceViewTable'

import { byReferenceDefaultFiltersValues } from './byReferenceFilters/byReferenceDefaultFiltersConfig'

import {
	ITableToolBarActionConfig,
	ITableToolBarConfig,
} from '../../../../../utils/typescript/tableType'
import FormBtnModal from '../../../../../components/forms/formModalBtn'
import InterventionModal from '../interventionModal'
import { interventionMenuTabConfig } from '../interventionMenuTabConfig'
import ByReferenceFilters from './byReferenceFilters'

const localStorageKeyFilter = 'interventionByReferenceFilter'

const queryConfig = {
	query: LIST_INTERVENTIONS,
	queryName: 'interventions',
}

const tableNavTabConfig = {
	navTabConfig: interventionMenuTabConfig,
	sxTabs: { mb: 3 },
}

const refreshQuerie = [
	'interventionsList',
	'getInterventionNumbersByCustomer',
	'getInterventionNumbersBySite',
]

const deletedBulk = {
	mutation: useBulkInterventionAction,
	operation: 'remove',
	successMessageDelete: {
		singular: 'success.interventionDeleted',
		plural: 'success.interventionsDeleted',
	},
	messageConfirm: {
		title: 'confirmModal.deleteInterventionsTitle',
		message: 'confirmModal.deleteInterventionsMessage',
	},
	deletePermissions: [
		EPermissionScope.interventionDelete,
		EPermissionScope.interventionRemove,
	],
}

const tableToolBarActionConfig: ITableToolBarActionConfig = {
	refreshQuerie,
	deletedBulk,
}

const tableToolBarConfig: ITableToolBarConfig = {
	title: 'interventions.interventionLabel',
	stickyToolBar: true,
	tableConfig: listInterventionsByReferenceViewTable,
	localStorageKeyFilter,
	localStorageName: 'listInterventionsByReferenceViewTableFilters',
	exportConfig: { exportQuery: useExportIntervention },
	queryConfig,
	modalButton: {
		Btn: (
			<FormBtnModal
				modal={<InterventionModal modalUtils={{} as any} />}
				mode={EFormModalMode.create}
			/>
		),
	},
	validationSchemaFunction: InterventionValidationSchema,
	createPermission: [EPermissionScope.interventionCreate],
	modules: [EModule.interventionControl],
}

const tableContainerConfig = {
	//height: '59vh',
	tableConfig: listInterventionsByReferenceViewTable,
	queryConfig,
}

const defaultFiltersConfig = {
	componentsFiltersConfig: <ByReferenceFilters />,
	defaultFiltersValues: byReferenceDefaultFiltersValues,
	localStorageKeyFilter,
}

export const interventionByRefConfigTable = {
	tableNavTabConfig,
	tableToolBarConfig,
	tableToolBarActionConfig,
	tableContainerConfig,
	defaultFiltersConfig,
}
