import { EInterventionTypes, EViewModal } from '../utils/app-models'
import { ROUTES } from './consts'

const linkVm = {
	[EInterventionTypes.transport]: EViewModal.INTERVENTION_TRANSPORT,
	[EInterventionTypes.control]: EViewModal.INTERVENTION_REFERENCES,
	[EInterventionTypes.service]: EViewModal.INTERVENTION_REFERENCES,
}

export const vmLinkByInterventionType = (type, id) => {
	return `${ROUTES.VIEWMODAL?.[linkVm?.[type]]?.url}${id}`
}
