import React, { useMemo } from 'react'
import { Stack, Typography, Box } from '@mui/material'
import { dayjsLocalizedFormatMethod } from '../../utils/date'

const typoCSS = { lineHeight: '1.38' }
const timeCSS = { opacity: 0.7 }

interface IDateToFromTransport {
	removalDate: any
	deliveryDate: any
	fontMd?: boolean
	noSvg?: boolean
}

const formatDate = (dateString: string | null, format: string): string =>
	dateString ? dayjsLocalizedFormatMethod()(dateString).format(format) : ''

const DateToFromTransport: React.FC<IDateToFromTransport> = ({
	removalDate,
	deliveryDate,
	fontMd = false,
	noSvg = false,
}) => {
	const formattedDate = useMemo(() => {
		return {
			// removal ---------------------------------------
			removalDate: formatDate(removalDate?.removalDate, 'L'),
			removalTimeFrom: formatDate(removalDate?.removalTimeFrom, 'LT'),
			removalTimeTo: formatDate(removalDate?.removalTimeTo, 'LT'),
			// delivery ---------------------------------------
			deliveryDate: formatDate(deliveryDate?.deliveryDate, 'L'),
			deliveryTimeFrom: formatDate(deliveryDate?.deliveryTimeFrom, 'LT'),
			deliveryTimeTo: formatDate(deliveryDate?.deliveryTimeTo, 'LT'),
		}
	}, [removalDate, deliveryDate])

	const formattedRemovalTime = useMemo(() => {
		return `${formattedDate.removalTimeFrom} ${
			formattedDate?.removalTimeTo ? `- ${formattedDate.removalTimeTo}` : ''
		}`
	}, [formattedDate])

	const formattedDeliveryTime = useMemo(() => {
		return `${formattedDate.deliveryTimeFrom} ${
			formattedDate?.deliveryTimeTo ? `- ${formattedDate.deliveryTimeTo}` : ''
		}`
	}, [formattedDate])

	return (
		<Stack direction='row' alignItems='center'>
			{!noSvg && (
				<Stack alignItems='center' mr={0.5}>
					<img src='/icon/dateToFrom.svg' />
				</Stack>
			)}
			<Stack>
				<Typography noWrap variant={fontMd ? 'body2' : 'caption'} sx={typoCSS}>
					<Box component='span' mr={0.5}>
						{formattedDate.removalDate}
					</Box>
					<Box component='span' sx={timeCSS}>
						{formattedRemovalTime}
					</Box>
				</Typography>

				<Typography noWrap variant={fontMd ? 'body2' : 'caption'} sx={typoCSS}>
					<Box component='span' mr={0.5}>
						{formattedDate.deliveryDate}
					</Box>
					<Box component='span' sx={timeCSS}>
						{formattedDeliveryTime}
					</Box>
				</Typography>
			</Stack>
		</Stack>
	)
}

export default DateToFromTransport
