import { useMemo } from 'react'
import {
	GET_PRODUCT_BASE_CATEGORIES_FOR_SELECT,
	GET_PRODUCT_CATEGORIES,
} from '../../../../../../api/gql/queries/catalogProductQueries'
import { useQuery } from '@apollo/client'
import { useGetCustomerListForSelect } from '../../../../../../api/gql/queries/customersQueries'

export const useGlobalActionData = (haveCrmModule: boolean) => {
	/////////////////////////////////////////////////////////
	// Selected elements
	/////////////////////////////////////////////////////////

	// Categories -------------------------------------------

	const { data: resultProductCategories, loading: loadingProductCategories } =
		useQuery(GET_PRODUCT_CATEGORIES, { variables: { isActive: true } })

	const categoriesSelectElements = useMemo(
		() =>
			resultProductCategories?.productCategories?.collection?.map(
				(category) => ({
					key: category.id,
					label: category.name,
					value: category,
				})
			) || [],

		[resultProductCategories]
	)

	let customersSelectElements = []
	let productCategoriesOptions = []

	if (haveCrmModule) {
		// Customers ---------------------------------------------

		const { customers } = haveCrmModule
			? useGetCustomerListForSelect()
			: ({} as any)

		customersSelectElements = useMemo(
			() =>
				customers?.map((customer) => ({
					key: customer?.id,
					label: customer?.name,
					value: customer,
				})) || [],
			[customers]
		)

		// Categories & product Bases  ----------------------------

		const { data: productBasesCategories } = haveCrmModule
			? useQuery(GET_PRODUCT_BASE_CATEGORIES_FOR_SELECT, {
					variables: { isActive: true },
			  })
			: ({} as any)

		productCategoriesOptions = useMemo(() => {
			const tmp: any = []
			productBasesCategories?.productBaseCategories.collection?.forEach(
				({ productBases: { collection }, name: categoryName }) => {
					collection?.forEach((product) => {
						tmp.push({
							key: product?.id,
							label: product?.name,
							value: {
								...product,
								categoryName,
							},
						})
					})
				}
			)

			return tmp
		}, [productBasesCategories])
	}

	return {
		categoriesSelectElements,
		loadingProductCategories,
		customersSelectElements,
		productCategoriesOptions,
	}
}
