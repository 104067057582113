/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

import { checkbox } from '../imgBase64'

export const selectionFieldPDF = (doc, record, position) => {
	const { positionYcell, positionXcell, heightCell } = position

	const heightCheckbox = 4
	const widthCheckbox = 4

	const centerPositionYcheckbox =
		positionYcell + (heightCell - heightCheckbox) / 2

	// checkbox
	let marginX = 0
	record?.value?.value
		?.filter((val) => val?.selected)
		?.forEach(({ name, selected }, i) => {
			if (selected) {
				const { h: heigthTxt, w: widthTxt } = doc.getTextDimensions(
					record?.value?.value[i !== 0 ? i - 1 : 0].name // get previous record text width & height
				)
				const centerYtext = positionYcell + heightCell / 2 + heigthTxt / 2 - 0.5

				marginX += i !== 0 ? widthTxt + 2 : 1
				const checkboxXposition = positionXcell + i * widthCheckbox + marginX

				// 	HELP -> addImage(imageData, format, positionXcell, positionYcell, width, height, alias, compression, rotation)
				doc.addImage(
					checkbox[1],
					'PNG',
					checkboxXposition,
					centerPositionYcheckbox,
					widthCheckbox,
					heightCheckbox
				)

				doc.text(name, checkboxXposition + widthCheckbox + 1, centerYtext)
			}
		})
}
