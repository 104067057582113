import React, { useCallback, useMemo, useState } from 'react'

import TasksListItem from './TasksListItem'
import TaskChip from './TaskChip'

interface ITasksPopover {
	products
}

const TasksPopover: React.FC<ITasksPopover> = ({ products }) => {
	const [anchorEl, setAnchorEl] = useState(null)
	const handleClick = useCallback((e) => setAnchorEl(e.currentTarget), [])
	const handleClose = useCallback(() => setAnchorEl(null), [])
	const open = useMemo(() => Boolean(anchorEl), [anchorEl])
	return (
		<>
			<TaskChip
				numberProducts={products?.length}
				products={products}
				handleClick={handleClick}
			/>

			{open && (
				<TasksListItem
					open={open}
					handleClose={handleClose}
					anchorEl={anchorEl}
					products={products}
				/>
			)}
		</>
	)
}

export default TasksPopover
