/* eslint-disable @typescript-eslint/no-unused-vars */

import dayjs from 'dayjs'
import { EFormModalMode } from '../../../../../utils/app-models'
import { replaceHoursFromDate } from '../../../../../utils/date'
import { formatGeneric } from '../../../../../utils/string'
import translateIntlMsgString from '../../../../../utils/translateIntlMsgString'
import { getCountries, defaultCountry } from '../../../../../utils/tabCountries'

const renameTripsKeysWithPrefix = (array) => {
	return array.map((entry, index) => {
		const prefix = index === 0 ? 'removal' : 'delivery'
		const updatedEntry = {}

		for (const key in entry) {
			updatedEntry[prefix + key[0].toUpperCase() + key.slice(1)] = entry[key]
		}

		return updatedEntry
	})
}

/////////////////////////////////////////////////////////
// Format default value (edit)
/////////////////////////////////////////////////////////

export const formatInterventionModalEditDefaultValues = ({
	data,
	currentUserAccountId,
	forcedStatus,
	isDuplicateTransport,
	localLanguage,
	doNotDuplicate,
}) => {
	const {
		id,
		interventionTransportType,
		createdByAccount,
		interventionType,
		globalFormTemplates,
		globalProducts,
		interventionControlProgrammedVehicles,
		actors,
		agencies,
		dateFrom,
		dateTo,
		manager,
		interventionControlFinalCustomer,
		orderingContact,
		orderingCustomer,
		orderingSite,
		status,
		interventionTransportTrips,
		...values
	} = formatGeneric(data) as any
	const resetDateTrip = {
		...(isDuplicateTransport && {
			removalDate: dayjs(),
			removalTimeFrom: dayjs().set('hour', 9).set('minute', 0),
			removalTimeTo: null,
			deliveryDate: dayjs(),
			deliveryTimeFrom: dayjs().set('hour', 17).set('minute', 0),
			deliveryTimeTo: null,
		}),
	}

	const removalCountryValues = interventionTransportTrips?.[0]?.country
		? getCountries(localLanguage)?.find(
				({ code }) => code === interventionTransportTrips[0].country
		  )
		: null

	const deliveryCountryValues = interventionTransportTrips?.[1]?.country
		? getCountries(localLanguage)?.find(
				({ code }) => code === interventionTransportTrips[1].country
		  )
		: null

	const tripsWithPrefix = renameTripsKeysWithPrefix(interventionTransportTrips)

	/**
	 * Condition setting interventionTransportTripFormatted values based on duplication configuration
	 *
	 * @param {string} doNotDuplicate -The duplication configuration, can be 'removal' or 'delivery'.
	 * @param {Object} tripsWithPrefix - An object containing interventionTransportTrips informations.
	 * @param {Object} resetDateTrip - An object containing date and time information for removal and delivery.
	 */

	if (doNotDuplicate === 'removal') {
		Object.keys(tripsWithPrefix[0]).forEach((key) => {
			tripsWithPrefix[0][key] = null
		})

		resetDateTrip.removalDate = null
		resetDateTrip.removalTimeFrom = null
		resetDateTrip.removalTimeTo = null
	}

	if (doNotDuplicate === 'delivery') {
		Object.keys(tripsWithPrefix[1]).forEach((key) => {
			tripsWithPrefix[1][key] = null
		})
		resetDateTrip.deliveryDate = null
		resetDateTrip.deliveryTimeFrom = null
		resetDateTrip.deliveryTimeTo = null
	}

	const interventionTransportTripFormatted = {
		...tripsWithPrefix[0], // removal
		...tripsWithPrefix[1], // delivery
		isRemovalAddressEqualOrder: tripsWithPrefix[0].removalIsAddressEqualOrder,
		isDeliveryAddressEqualOrder: tripsWithPrefix[1].deliveryIsAddressEqualOrder,
		...resetDateTrip,

		// REMOVAL ////////////////////////////

		...(interventionTransportTrips[0]?.customer?.id && {
			removalAddressBookCustomer: {
				key: interventionTransportTrips[0]?.customer?.id,
				label: interventionTransportTrips[0]?.customer?.name,
				value: interventionTransportTrips[0]?.customer,
			},
		}),
		...(interventionTransportTrips[0]?.site?.id && {
			removalAddressBookSite: {
				key: interventionTransportTrips[0]?.site?.id,
				label: interventionTransportTrips[0]?.site?.name,
				value: interventionTransportTrips[0]?.site,
			},
		}),
		...(interventionTransportTrips[0]?.contact?.id && {
			removalAddressBookContact: {
				key: interventionTransportTrips[0]?.contact?.id,
				label: `${interventionTransportTrips[0]?.contact?.firstName} ${interventionTransportTrips[0]?.contact?.lastName}`,
				value: interventionTransportTrips[0]?.contact,
			},
		}),
		removalCountry: {
			key: removalCountryValues?.code,
			label: removalCountryValues?.label,
			value: removalCountryValues?.code,
		},
		removalProducts:
			interventionTransportTrips[0]?.products?.reduce((acc, removalP) => {
				if (removalP?.product) {
					acc[removalP?.product?.id] = {
						checked: true,
						value: removalP?.product,
					}
				}
				return acc
			}, {}) || {},

		removalFormTemplates:
			interventionTransportTrips[0]?.formTemplates?.reduce((acc, removalFt) => {
				if (removalFt) {
					acc[removalFt.id] = { checked: true, value: removalFt }
				}
				return acc
			}, {}) || {},
		// DELIVERY ////////////////////////////
		...(interventionTransportTrips[1]?.customer?.id && {
			deliveryAddressBookCustomer: {
				key: interventionTransportTrips[1]?.customer?.id,
				label: interventionTransportTrips[1]?.customer?.name,
				value: interventionTransportTrips[1]?.customer,
			},
		}),
		...(interventionTransportTrips[1]?.site?.id && {
			deliveryAddressBookSite: {
				key: interventionTransportTrips[1]?.site?.id,
				label: interventionTransportTrips[1]?.site?.name,
				value: interventionTransportTrips[1]?.site,
			},
		}),
		...(interventionTransportTrips[1]?.contact?.id && {
			deliveryAddressBookContact: {
				key: interventionTransportTrips[1]?.contact?.id,
				label: `${interventionTransportTrips[1]?.contact?.firstName} ${interventionTransportTrips[1]?.contact?.lastName}`,
				value: interventionTransportTrips[1]?.contact,
			},
		}),
		deliveryCountry: {
			key: deliveryCountryValues?.code,
			label: deliveryCountryValues?.label,
			value: deliveryCountryValues?.code,
		},

		deliveryProducts:
			interventionTransportTrips[1]?.products?.reduce((acc, removalP) => {
				if (removalP?.product) {
					acc[removalP?.product?.id] = {
						checked: true,
						value: removalP?.product,
					}
				}
				return acc
			}, {}) || {},
		deliveryFormTemplates:
			interventionTransportTrips[1]?.formTemplates?.reduce((acc, removalFt) => {
				if (removalFt) {
					acc[removalFt.id] = { checked: true, value: removalFt }
				}
				return acc
			}, {}) || {},
	}

	// isDuplicateTransport
	return {
		...values,

		actors: !isDuplicateTransport
			? actors?.reduce((acc, actor) => {
					if (actor?.account?.id === currentUserAccountId) {
						acc[actor.id] = {
							checked: true,
							value: actor,
						}
					}
					return acc
			  }, {})
			: null,

		agenciesList: !isDuplicateTransport
			? agencies?.reduce((acc, agency) => {
					if (agency) {
						acc[agency.id] = {
							checked: true,
							value: agency,
						}
					}
					return acc
			  }, {})
			: null,

		dateFrom,
		dateTo,
		manager: {
			key: manager?.id,
			label: `${manager?.firstName} ${manager?.lastName}`,
			value: manager,
		},
		orderingContact: orderingContact
			? {
					key: orderingContact?.id,
					label: `${orderingContact?.firstName} ${orderingContact?.lastName}`,
					value: orderingContact,
			  }
			: null,
		orderingCustomer: {
			key: orderingCustomer?.id,
			label: orderingCustomer?.name,
			value: orderingCustomer,
		},

		orderingSite: {
			key: orderingSite?.id,
			label: orderingSite?.name,
			value: orderingSite,
		},

		status: {
			key: forcedStatus?.id || status?.id,
			label: forcedStatus?.name || status?.name,
			value: forcedStatus || status,
		},

		interventionTransportType: {
			key: interventionTransportType?.id,
			label: translateIntlMsgString(
				`interventions.${interventionTransportType?.code}`
			),
			value: interventionTransportType,
		},

		...(!isDuplicateTransport && {
			selectedVehicles:
				interventionControlProgrammedVehicles?.map(({ vehicle }) => ({
					...vehicle?.vehicleDetail,
					id: vehicle?.id,
					vehicleBrand: vehicle?.vehicleDetail?.vehicleBrand?.name ?? '',
					vehicleCarburant: vehicle?.vehicleDetail?.vehicleCarburant ?? '',
					vehicleModelText: vehicle?.vehicleDetail?.vehicleModelText ?? '',
				})) || [],
		}),

		...interventionTransportTripFormatted,
	}
}

/////////////////////////////////////////////////////////
// Format default value (create)
/////////////////////////////////////////////////////////

export const formatInterventionModalCreateDefaultValues = ({
	plannedStatus,
	currentUser,
	dataPreFilling,
	isSite,
	isVehicle,
	startDate,
	endDate,
}) => {
	const now = new Date()
	const dateFrom = dayjs(startDate || now)
	const dateTo = dayjs(endDate || now)

	const dataKey = isSite
		? dataPreFilling?.customer?.id
		: isVehicle
		? dataPreFilling?.site?.customer?.id
		: dataPreFilling?.id
	const dataLabel = isSite
		? dataPreFilling?.customer?.name
		: isVehicle
		? dataPreFilling?.site?.customer?.name
		: dataPreFilling?.name

	return {
		removalCountry: {
			key: defaultCountry.code,
			label: defaultCountry.label,
			value: defaultCountry.code,
		},
		deliveryCountry: {
			key: defaultCountry.code,
			label: defaultCountry.label,
			value: defaultCountry.code,
		},
		status: {
			key: plannedStatus?.id,
			label: plannedStatus?.name,
			value: plannedStatus,
		},
		isDeliveryAddressEqualOrder: false,
		isRemovalAddressEqualOrder: false,
		manager: {
			key: currentUser?.id,
			label: `${currentUser?.firstName} ${currentUser?.lastName}`,
			value: {
				id: currentUser?.id,
				firstName: currentUser?.firstName,
				lastName: currentUser?.lastName,
			},
		},

		removalDate: dateFrom,
		removalTimeFrom:
			startDate || dayjs(dateFrom).set('hour', 9).set('minute', 0),
		deliveryDate: dateTo,
		deliveryTimeFrom: endDate || dayjs(dateTo).set('hour', 17).set('minute', 0),

		orderingCustomer: dataPreFilling
			? {
					key: dataKey,
					label: dataLabel,
					value: { id: dataKey, label: dataLabel },
			  }
			: null,
		orderingSite:
			dataPreFilling && (isSite || isVehicle)
				? {
						key: isSite ? dataPreFilling.id : dataPreFilling.site.id,
						label: isSite ? dataPreFilling.name : dataPreFilling.site.name,
						value: {
							id: isSite ? dataPreFilling.id : dataPreFilling.site.id,
							label: isSite ? dataPreFilling.name : dataPreFilling.site.name,
						},
				  }
				: null,

		selectedVehicles: isVehicle
			? [
					{
						id: dataPreFilling?.id,
						immat: dataPreFilling?.vehicleDetail?.immat,
						vin: dataPreFilling?.vehicleDetail?.vin,
						vehicleModelText: dataPreFilling?.vehicleDetail?.vehicleModelText,
						vehicleBrand: dataPreFilling?.vehicleDetail?.vehicleBrand?.name,
						vehicleCarburant: dataPreFilling?.vehicleDetail?.vehicleCarburant,
					},
			  ]
			: [],
	}
}

/////////////////////////////////////////////////////////
// Format data to send
/////////////////////////////////////////////////////////

export const formatInterventionModalOnValidateData = ({
	data,
	mode,
	interventionId,
}) => {
	const dateFrom = replaceHoursFromDate(
		data?.removalDate,
		data?.removalTimeFrom
	)
	const dateTo = replaceHoursFromDate(
		data?.deliveryDate,
		data?.deliveryTimeFrom
	)

	const actorsList = Object.values(data?.actors ?? {})
		.filter(({ checked }) => checked)
		.map(({ value }) => value?.id)
	const agenciesList = Object.values(data?.agenciesList ?? {})
		.filter(({ checked }) => checked)
		.map(({ value }) => value?.id)
	const removalFormTemplates = Object.values(data?.removalFormTemplates ?? {})
		.filter(({ checked }) => checked)
		.map(({ value }) => value?.id)
	const removalProducts = Object.values(data?.removalProducts ?? {})
		.filter(({ checked }) => checked)
		.map(({ value }) => value?.id)
	const deliveryFormTemplates = Object.values(data?.deliveryFormTemplates ?? {})
		.filter(({ checked }) => checked)
		.map(({ value }) => value?.id)
	const deliveryProducts = Object.values(data?.deliveryProducts ?? {})
		.filter(({ checked }) => checked)
		.map(({ value }) => value?.id)

	const interventionTransportTripInput = {
		// REMOVAL ////////////////////////////////////
		removalCustomer: data?.removalAddressBookCustomer?.value?.id ?? null,
		removalSite: data?.removalAddressBookSite?.value?.id ?? null,
		removalContact: data?.removalAddressBookContact?.value?.id ?? null,
		isRemovalAddressEqualOrder: data?.isRemovalAddressEqualOrder,
		removalName: data?.removalName ?? '',
		removalAddress: data?.removalAddress ?? '',
		removalZipcode: data?.removalZipcode ?? '',
		removalCity: data?.removalCity ?? '',
		removalCountry: data?.removalCountry?.value ?? '',
		removalDate: data?.removalDate ?? '',
		removalTimeFrom: data?.removalTimeFrom ?? '',
		removalTimeTo: data?.removalTimeTo ?? '',
		removalContactText: data?.removalContactText ?? '',
		removalContactPhone: data?.removalContactPhone ?? '',
		removalInstruction: data?.removalInstruction ?? '',
		removalFormTemplates,
		removalProducts,

		// DELIVERY ////////////////////////////////////
		deliveryCustomer: data?.deliveryAddressBookCustomer?.value?.id ?? null,
		deliverySite: data?.deliveryAddressBookSite?.value?.id ?? null,
		deliveryContact: data?.deliveryAddressBookContact?.value?.id ?? null,
		isDeliveryAddressEqualOrder: data?.isDeliveryAddressEqualOrder,
		deliveryName: data?.deliveryName ?? '',
		deliveryAddress: data?.deliveryAddress ?? '',
		deliveryZipcode: data?.deliveryZipcode ?? '',
		deliveryCity: data?.deliveryCity ?? '',
		deliveryCountry: data?.deliveryCountry?.value ?? '',
		deliveryDate: data?.deliveryDate ?? '',
		deliveryTimeFrom: data?.deliveryTimeFrom ?? '',
		deliveryTimeTo: data?.deliveryTimeTo ?? '',
		deliveryContactText: data?.deliveryContactText ?? '',
		deliveryContactPhone: data?.deliveryContactPhone ?? '',
		deliveryInstruction: data?.deliveryInstruction ?? '',
		deliveryFormTemplates,
		deliveryProducts,
	}

	return {
		...(mode === EFormModalMode.edit && { id: interventionId }),
		interventionType: 'transport',
		externalReference: data?.externalReference ?? '',
		airTableReference: data?.airTableReference ?? '',
		orderingCustomer: data?.orderingCustomer?.value?.id,
		orderingSite: data?.orderingSite?.value?.id,
		orderingContact: data?.orderingContact?.value?.id ?? null,
		status: data.status.value.id,
		dateFrom,
		dateTo,
		manager: data.manager.value.id,
		publicComment: data?.publicComment ?? '',
		actorsList,
		agenciesList,
		interventionTransportType:
			data?.interventionTransportType?.value?.id ?? null,
		interventionTransportTripInput,
		interventionControlProgrammedVehiclesListInput: [
			{
				vehicle: data?.selectedVehicles?.[0]?.id,
				formTemplates: [],
				products: [],
			},
		],
	}
}
