/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'

import { EPermissionScope } from '../../../../utils/app-models'
import {
	ITableToolBarActionConfig,
	ITableToolBarConfig,
} from '../../../../utils/typescript/tableType'
import { useBulkInterventionAction } from '../../../../api/gql/mutations/interventionsMutation'
import listInterventionsTransportViewTable from '../../config/dashboard/interventionsTransport/listInterventionsTransportViewTable'

import {
	LIST_IPV_TRANSPORT,
	useExportInterventionTransport,
} from '../../../../api/gql/queries/interventionsTransportQueries'
import IpvTransportFilters from './ipvTransportFilters'
import { ipvTransportDefaultFiltersValues } from './ipvTransportFilters/ipvTransportDefaultFiltersConfig'

////////////////////////////////////////////////////////////////
// Global variable
////////////////////////////////////////////////////////////////

const localStorageKeyFilter = 'interventionTransportFilter'

const queryConfig = {
	query: LIST_IPV_TRANSPORT,
	queryName: 'interventionProgrammedVehicles',
}

////////////////////////////////////////////////////////////////
// tableNavTab CONFIG
////////////////////////////////////////////////////////////////

// const tableNavTabConfig = {
// 	navTabConfig: interventionMenuTabConfig,
// 	sxTabs: { mb: 3 },
// }

////////////////////////////////////////////////////////////////
// tableToolBarAction CONFIG
////////////////////////////////////////////////////////////////

const refreshQuerie = [
	'interventionsTransportList',
	'getInterventionTransportNumbersByCustomer',
	'getInterventionTransportNumbersBySite',
	'getInterventionTransportNumbersByVehicle',
]

const deletedBulk = {
	mutation: useBulkInterventionAction,
	operation: 'remove',
	successMessageDelete: {
		singular: 'success.interventionDeleted',
		plural: 'success.interventionsDeleted',
	},
	messageConfirm: {
		title: 'confirmModal.deleteInterventionsTitle',
		message: 'confirmModal.deleteInterventionsMessage',
	},
	deletePermissions: [
		EPermissionScope.interventionDelete,
		EPermissionScope.interventionRemove,
	],
}

const tableToolBarActionConfig: ITableToolBarActionConfig = {
	refreshQuerie,
	deletedBulk,
}

////////////////////////////////////////////////////////////////
// TableToolBarHeader CONFIG
////////////////////////////////////////////////////////////////

const tableToolBarConfig: ITableToolBarConfig = {
	title: 'interventions.transports',
	stickyToolBar: true,
	tableConfig: listInterventionsTransportViewTable,
	localStorageKeyFilter,
	localStorageName: 'listInterventionsTransportViewTableFilters',
	exportConfig: { exportQuery: useExportInterventionTransport }, // TODO
	queryConfig,
}

////////////////////////////////////////////////////////////////
// tableContainer CONFIG
////////////////////////////////////////////////////////////////

const tableContainerConfig = {
	height: '59vh',
	tableConfig: listInterventionsTransportViewTable,
	queryConfig,
}

const defaultFiltersConfig = {
	componentsFiltersConfig: <IpvTransportFilters />,
	defaultFiltersValues: ipvTransportDefaultFiltersValues,
	localStorageKeyFilter,
}

export const interventionTransportConfigTable = {
	tableToolBarConfig,
	tableToolBarActionConfig,
	tableContainerConfig,
	defaultFiltersConfig,
}
