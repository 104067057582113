import React from 'react'

import { Divider, Box, Stack, Typography, Paper } from '@mui/material'

import { ListItemsData } from '../../../../components'
import { listCardViewModalCSS as css } from '../../../../styles/css/viewModal'
import { ROUTES } from '../../../../routes/consts'
import { formatAdressGoogleMap } from '../../../../utils/string'
import { EModule } from '../../../../utils/app-models'

interface IInformationsSite {
	phone
	cellphone
	customer
	address
	city
	zipcode
	name
	siret
}

const InformationsSite: React.FC<IInformationsSite> = ({
	phone,
	cellphone,
	customer,
	address,
	city,
	zipcode,
	siret,
	name,
}) => {
	return (
		<Paper sx={css.radius}>
			<Box sx={{ mb: 2 }}>
				<Stack
					direction='row'
					alignItems='center'
					justifyContent='space-between'
					sx={css.space}
				>
					<Typography variant='h3'>{name}</Typography>
				</Stack>

				<Divider />

				<Box sx={css.spaceRightLeft}>
					<ListItemsData
						nbCol={6}
						items={[
							{
								primaryText: 'misc.socialName',
								secondaryText: customer?.name || '-',
								viewModalLink: `${ROUTES.VIEWMODAL.CUSTOMERS.url}${customer?.id}`,
								sizeColumn: 6,
								modules: [EModule.crm],
							},
							{
								primaryText: 'misc.siretNumber',
								secondaryText: siret || '-',
								sizeColumn: 6,
							},

							{
								sizeColumn: 12,
								primaryText: 'misc.address',
								secondaryText: `${address ?? ''} - ${zipcode ?? ''} - ${
									city ?? ''
								}`,
								...((address || city || zipcode) && {
									externalLink: formatAdressGoogleMap(address, city, zipcode),
								}),
							},

							{
								primaryText: 'misc.phone',
								secondaryText: phone || '-',
								sizeColumn: 6,
								...(phone && {
									externalLink: `tel:${phone}`,
									externalLinkTarget: '_self',
								}),
							},

							{
								primaryText: 'misc.cellphone',
								secondaryText: cellphone || '-',
								sizeColumn: 6,
								...(cellphone && {
									externalLink: `tel:${cellphone}`,
									externalLinkTarget: '_self',
								}),
							},
						]}
					/>
				</Box>
			</Box>
		</Paper>
	)
}

export default InformationsSite
