import React, { useCallback, useMemo } from 'react'

import dayjs from 'dayjs'

import Radio from '@mui/material/Radio'
import FormControl from '@mui/material/FormControl'
import {
	Stack,
	Typography,
	List,
	ListItemButton,
	Box,
	Divider,
} from '@mui/material'

import { useFormatMessage } from '../../../../../utils/hooks'
import { EPeriodFilter } from '../../../../../utils/app-models'
import { filerPeriodType, filterDateConfig } from '../filterDateConfig'

const FormControlCSS = { width: 1 }
const RadioCSS = { mr: -2, ml: 5 }
const ListItemButtonCSS = { pt: 1.2, pb: 1.2 }

interface IPeriodFilter {
	handleClose
	setValueType
	valueType
	setFiltersQuerieParam
	filtersQuerieParam
	localStorageKeyFilter
	customFilterDateConfig
	argumentQuerieName?
	setLocalStorageOnchange?: boolean
}

const PeriodFilter: React.FC<IPeriodFilter> = ({
	handleClose,
	setValueType,
	valueType,
	setFiltersQuerieParam,
	filtersQuerieParam,
	localStorageKeyFilter,
	customFilterDateConfig,
	argumentQuerieName,
	setLocalStorageOnchange,
}) => {
	const intlMsg = useFormatMessage()

	const calculatedPeriod = useMemo(() => {
		const config = customFilterDateConfig ?? filterDateConfig

		return config.map((item) => {
			const matchingType = Object.keys(filerPeriodType).find((key) =>
				filerPeriodType[key].includes(item.period)
			)

			return {
				...item,
				type: matchingType || 'none', // null si aucune correspondance trouvée
			}
		})
	}, [])

	const updateValueType = useCallback(
		(type, date) => () => {
			if (date) {
				const valuesDate =
					argumentQuerieName === 'startFrom'
						? {
								startFrom:
									date?.dateFrom && dayjs(date?.dateFrom[0]?.after).toDate(),
								endTo:
									date?.dateFrom && dayjs(date?.dateFrom[0]?.before).toDate(),
						  }
						: {
								[argumentQuerieName]: [
									{
										after:
											date?.dateFrom &&
											dayjs(date?.dateFrom[0]?.after).toDate(),
										before:
											date?.dateFrom &&
											dayjs(date?.dateFrom[0]?.before).toDate(),
									},
								],
						  }

				const filterPref = JSON.parse(
					localStorage.getItem(localStorageKeyFilter) as string
				)

				setFiltersQuerieParam({
					...filtersQuerieParam,
					...filterPref,
					...valuesDate,
					period: type,
				})

				setLocalStorageOnchange &&
					localStorage.setItem(
						localStorageKeyFilter,
						JSON.stringify({
							...filterPref,
							...valuesDate,
							period: type,
						})
					)
			}
			// Don't close popover if it's a custom date range period
			if (EPeriodFilter.customPeriod !== type) handleClose()
			setValueType(type)
		},
		[setValueType, filtersQuerieParam]
	)

	return (
		<List>
			<FormControl component='fieldset' sx={FormControlCSS}>
				{calculatedPeriod.map(({ period, date, type }, i) => (
					<Box key={period}>
						<ListItemButton
							onClick={updateValueType(period, date)}
							selected={valueType === period}
							sx={ListItemButtonCSS}
						>
							<Stack
								direction='row'
								alignItems='center'
								justifyContent='space-between'
								width={175}
							>
								<Typography variant='caption'>
									{intlMsg(`misc.${period}`)}
								</Typography>

								<Radio
									sx={RadioCSS}
									disableRipple
									size='small'
									color='primary'
									checked={valueType === period}
								/>
							</Stack>
						</ListItemButton>
						{i < calculatedPeriod?.length - 1 &&
							type !== calculatedPeriod?.[i + 1]?.type && (
								<Divider sx={{ margin: '0px 10px' }} />
							)}
					</Box>
				))}
			</FormControl>
		</List>
	)
}

export default PeriodFilter
