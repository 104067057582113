import React, { useCallback, useContext, useMemo } from 'react'
import toast from 'react-hot-toast'

import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Grid from '@mui/material/Grid'
import { Button, Stack } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'

import { useFormatMessage } from '../../utils/hooks'
import { TableContext } from '../../utils/contexts/TableContext'

// CSS Constants
const confirmDeleteStyle = {
	imageCSS: {
		width: '180px',
	},
	titleCSS: {
		fontWeight: 'bold',
	},
	confirmDeleteCSS: {
		height: 1,
	},
	checkboxStackCSS: {
		direction: 'row',
		alignItems: 'center',
		spacing: 1,
	},
}

interface IConfirmDelete {
	rowId: any
	onClose: () => void
	open: boolean
	deleted: {
		messageConfirm: { message?: string; title?: string }
		query: any
		refreshQuery: any
		successMessageDelete: string
	}
	checkbox?: { message?: string; action?: any }
	cantValidate?: boolean
	handleClose: () => void
}

const ConfirmDelete: React.FC<IConfirmDelete> = ({
	rowId,
	onClose,
	open,
	deleted,
	checkbox,
	cantValidate = false,
	handleClose,
}) => {
	const intlMsg = useFormatMessage()
	const { setPage, page, visibleRows } = useContext(TableContext) || {}

	const { message, title } = deleted.messageConfirm

	const [deleteMutation] = deleted.query()
	const handleDeleteClick = useCallback(async () => {
		handleClose()
		await deleteMutation({
			variables: {
				input: {
					id: rowId,
				},
			},
			refetchQueries: deleted.refreshQuery,
		}).then(() => toast.success(deleted.successMessageDelete))

		if (page > 1 && visibleRows?.length === 1) {
			setPage(page - 1)
		}

		onClose()
	}, [rowId, visibleRows, page])

	const memoizedContent = useMemo(() => {
		return (
			<Stack
				justifyContent='center'
				spacing={1}
				sx={confirmDeleteStyle.confirmDeleteCSS}
			>
				{title && (
					<Typography variant='h4' sx={confirmDeleteStyle.titleCSS}>
						{intlMsg(title)}
					</Typography>
				)}
				{message && (
					<Typography variant='body2' gutterBottom>
						{intlMsg(message)}
					</Typography>
				)}

				{checkbox && (
					<Stack sx={confirmDeleteStyle.checkboxStackCSS}>
						<Checkbox color='primary' onChange={checkbox?.action} />
						<span>{intlMsg(checkbox?.message)}</span>
					</Stack>
				)}
			</Stack>
		)
	}, [intlMsg, title, message, checkbox, confirmDeleteStyle])

	return (
		<Dialog onClose={onClose} aria-labelledby='simple-dialog-title' open={open}>
			<DialogContent dividers>
				<Grid container spacing={4}>
					<Grid item xs={4}>
						<img
							src={'/img/illustration/suppression.png'}
							style={confirmDeleteStyle.imageCSS}
						/>
					</Grid>

					<Grid item xs={8}>
						{memoizedContent}
					</Grid>
				</Grid>
			</DialogContent>

			<DialogActions>
				<Stack direction='row' spacing={1} justifyContent='flex-end'>
					<Button variant='outlined' onClick={onClose}>
						{intlMsg('misc.cancel')}
					</Button>
					<Button
						variant='contained'
						onClick={handleDeleteClick}
						disabled={cantValidate}
					>
						{intlMsg('misc.validate')}
					</Button>
				</Stack>
			</DialogActions>
		</Dialog>
	)
}

export default ConfirmDelete
