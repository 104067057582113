/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'

import FieldControl from './pageFieldItems/FieldControl'
import FieldTxt from './pageFieldItems/FieldTxt'
import {
	EFormTemplateBasicFields,
	EFormTemplateModelFields,
	EFormTemplateVehicleFields,
} from '../../../../../utils/app-models'
import FieldSignature from './pageFieldItems/FieldSignature'
import FieldRating from './pageFieldItems/FieldRating'
import FieldSelection from './pageFieldItems/FieldSelection'
// import FieldMedia from './pageFieldItems/FieldMedia'
import FieldTitle from './pageFieldItems/FieldTitle'
import FieldDate from './pageFieldItems/FieldDate'
import FieldNumber from './pageFieldItems/FieldNumber'
import FieldSPicture from './pageFieldItems/FieldPicture'
import FieldInstruction from './pageFieldItems/FieldInstruction'
import FieldBarCode from './pageFieldItems/FieldBarCode'
import FieldDocument from './pageFieldItems/FieldDocument'

export const getPageFieldConfig = (
	field,
	deleteField,
	i,
	updateField,
	insertField
) => {
	switch (field?.fieldType) {
		case EFormTemplateBasicFields.control:
			return (
				<FieldControl
					key={field.id}
					field={field}
					deleteField={deleteField}
					index={i}
					updateField={updateField}
					insertField={insertField}
				/>
			)

		case EFormTemplateBasicFields.parkDate:
		case EFormTemplateBasicFields.date:
			return (
				<FieldDate
					key={field.id}
					field={field}
					deleteField={deleteField}
					index={i}
					updateField={updateField}
					insertField={insertField}
				/>
			)

		case EFormTemplateModelFields.pictureVehicle:
		case EFormTemplateModelFields.picture4anglesModel:
		case EFormTemplateModelFields.picture4anglesAndWheelsModel:
		case EFormTemplateBasicFields.picture:
			return (
				<FieldSPicture
					key={field.id}
					field={field}
					deleteField={deleteField}
					index={i}
					updateField={updateField}
					insertField={insertField}
				/>
			)

		case EFormTemplateModelFields.doubleSidedPhotoDocument:
		case EFormTemplateBasicFields.document:
			return (
				<FieldDocument
					key={field.id}
					field={field}
					deleteField={deleteField}
					index={i}
					updateField={updateField}
					insertField={insertField}
				/>
			)

		case EFormTemplateBasicFields.text:
			return (
				<FieldTxt
					key={field.id}
					field={field}
					deleteField={deleteField}
					index={i}
					updateField={updateField}
					insertField={insertField}
					simpleText
				/>
			)

		case EFormTemplateBasicFields.title:
			return (
				<FieldTitle
					key={field.id}
					field={field}
					deleteField={deleteField}
					index={i}
					updateField={updateField}
					insertField={insertField}
				/>
			)

		case EFormTemplateBasicFields.selection:
			return (
				<FieldSelection
					key={field.id}
					field={field}
					deleteField={deleteField}
					index={i}
					updateField={updateField}
					insertField={insertField}
				/>
			)

		case EFormTemplateBasicFields.numbers:
			return (
				<FieldNumber
					key={field.id}
					field={field}
					deleteField={deleteField}
					index={i}
					updateField={updateField}
					insertField={insertField}
				/>
			)

		case EFormTemplateBasicFields.signature:
			return (
				<FieldSignature
					key={field.id}
					field={field}
					deleteField={deleteField}
					index={i}
					updateField={updateField}
					insertField={insertField}
				/>
			)

		case EFormTemplateBasicFields.rating:
			return (
				<FieldRating
					key={field.id}
					field={field}
					deleteField={deleteField}
					index={i}
					updateField={updateField}
					insertField={insertField}
				/>
			)

		// case EFormTemplateBasicFields.media:
		// 	return (
		// 		<FieldMedia
		// 			key={field.id}
		// 			field={field}
		// 			deleteField={deleteField}
		// 			index={i}
		// 			updateField={updateField}
		// 			insertField={insertField}
		// 		/>
		// 	)

		case EFormTemplateBasicFields.instruction:
			return (
				<FieldInstruction
					key={field.id}
					field={field}
					deleteField={deleteField}
					index={i}
					updateField={updateField}
					insertField={insertField}
				/>
			)

		case EFormTemplateBasicFields.barCode:
			return (
				<FieldBarCode
					key={field.id}
					field={field}
					deleteField={deleteField}
					index={i}
					updateField={updateField}
					insertField={insertField}
				/>
			)

		case EFormTemplateVehicleFields.mileage:
			return (
				<FieldTxt
					key={field.id}
					field={field}
					deleteField={deleteField}
					index={i}
					updateField={updateField}
					insertField={insertField}
					simpleText
				/>
			)

		default:
			return <></>
	}
}
