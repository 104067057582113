/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'

import { ALERT_COUNT_BY_SITE } from '../../../api/gql/queries/alertsQueries'
import { CONTACTS_NUMBERS } from '../../../api/gql/queries/contactsQueries'
import { INTERVENTION_COUNT_BY_SITE } from '../../../api/gql/queries/interventionsQueries'
import { VEHICLES_NUMBERS } from '../../../api/gql/queries/vehiclesQueries'
import sitesListAlertsViewModalTable from '../../../components/tableV2/config/dashboard/alerts/sitesListAlertsViewModalTable'
import sitesListContactViewModalTable from '../../../components/tableV2/config/dashboard/customerContacts/sitesListContactViewModalTable'
import siteListInterventionsByReferenceViewModalTable from '../../../components/tableV2/config/dashboard/interventions/byReference/siteListInterventionsControlByReferenceViewModalTable'
import sitesListVehiclesViewModalTable from '../../../components/tableV2/config/dashboard/vehicles/sitesListVehiclesViewModalTable'
import { EModule, EPeriodFilter } from '../../../utils/app-models'
import Alerts from '../../dashboard/alerts'
import CustomerContacts from '../../dashboard/customerContacts/CustomerContacts'
import InterventionsByReference from '../../dashboard/interventions/interventionControl/byReference'
import ListVehicles from '../../dashboard/vehicles'
import InterventionTransport from '../../dashboard/interventions/interventionTransport'
import { INTERVENTION_TRANSPORT_COUNT_BY_SITE } from '../../../api/gql/queries/interventionsTransportQueries'
import siteListInterventionsTransportViewModalTable from '../../../components/tableV2/config/dashboard/interventionsTransport/siteListInterventionsTransportViewModalTable'

// ////////////////////////////////////////////////////////////////////////////////////////////////////////
// SITE TAB CONFIG
// ////////////////////////////////////////////////////////////////////////////////////////////////////////

export const siteTabsConfig = [
	{
		query: VEHICLES_NUMBERS,
		queryName: 'vehicles',
		argumentQueryCount: 'site',
		label: 'misc.vehicles',
		component: (
			<ListVehicles
				overrideConfigTable={{
					tableConfig: sitesListVehiclesViewModalTable,
					argumentQueryViewModal: 'site',
					elevationNbr: 0,
					localStorageName: 'sitesListVehiclesViewModalTableFilter',
					tableContainer: { height: '56vh' },
				}}
				isInViewModal
			/>
		),
	},

	{
		query: CONTACTS_NUMBERS,
		queryName: 'contacts',
		argumentQueryCount: 'sites',
		modules: [EModule.crm],
		label: 'misc.contacts',
		component: (
			<CustomerContacts
				overrideConfigTable={{
					tableConfig: sitesListContactViewModalTable,
					argumentQueryViewModal: 'sites',
					elevationNbr: 0,
					localStorageName: 'sitesListContactViewModalTableFilter',
					tableContainer: { height: '56vh' },
				}}
				isInViewModal
			/>
		),
	},

	{
		label: 'misc.interventions',
		query: INTERVENTION_COUNT_BY_SITE,
		argumentQueryCount: 'site',
		queryName: 'interventions',
		component: (
			<InterventionsByReference
				overrideConfigTable={{
					tableConfig: siteListInterventionsByReferenceViewModalTable,
					argumentQueryViewModal: 'site',
					elevationNbr: 0,
					hidden: {
						tableNavTab: true,
					},
					localStorageName:
						'siteListInterventionsByReferenceViewModalTableFilters',
					tableContainer: { height: '56vh' },
				}}
				overrideTableProvider={{
					customLocalStorageKeyFilter:
						'siteListInterventionsByReferenceViewModalFilters',
					customDefaultFilterConfig: {
						period: EPeriodFilter.thisMonth,
					},
				}}
				isInViewModal
			/>
		),
	},

	{
		label: 'interventions.transports',
		query: INTERVENTION_TRANSPORT_COUNT_BY_SITE,
		argumentQueryCount: 'site',
		queryName: 'interventions',
		modules: [EModule.interventionTransport],
		component: (
			<InterventionTransport
				overrideConfigTable={{
					tableConfig: siteListInterventionsTransportViewModalTable,
					argumentQueryViewModal: 'site',
					elevationNbr: 0,
					hidden: { tableNavTab: true },
					localStorageName:
						'siteListInterventionsTransportViewModalTableFilters',
					tableContainer: { height: '56vh' },
				}}
				overrideTableProvider={{
					customLocalStorageKeyFilter:
						'siteListInterventionsTransportViewModalFilters',
					customDefaultFilterConfig: {
						period: EPeriodFilter.thisMonth,
					},
				}}
				isInViewModal
			/>
		),
	},

	{
		label: 'misc.alerts',
		query: ALERT_COUNT_BY_SITE,
		argumentQueryCount: 'site',
		queryName: 'alerts',
		component: (
			<Alerts
				overrideConfigTable={{
					tableConfig: sitesListAlertsViewModalTable,
					argumentQueryViewModal: 'site',
					elevationNbr: 0,
					localStorageName: 'sitesListAlertsViewModalTableFilters',
					tableContainer: { height: '56vh' },
				}}
				overrideTableProvider={{
					customLocalStorageKeyFilter: 'sitesListAlertsViewModalFilters',
					customDefaultFilterConfig: {
						period: EPeriodFilter.thisMonth,
					},
				}}
				isInViewModal
			/>
		),
	},
]
