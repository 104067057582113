export const optionsDoughnut = {
	plugins: {
		title: {
			display: false,
		},
		legend: {
			display: false,
			position: 'right',
			align: 'center',
			labels: {
				color: 'black',
				boxWidth: 10,
			},
		},
	},
	cutout: '80%', // thickness
	radius: '95%', // size
	responsive: true,
	//maintainAspectRatio: false,

	layout: {
		padding: {
			bottom: 30,
			// right: 180,
			left: 0,
			top: 0,
		},
	},
}
