import React, { useMemo } from 'react'
import MuiLink, { LinkProps } from '@mui/material/Link'

type LinkType = 'primaryLink' | 'secondaryLink'

// extends LinkProps
interface CustomLinkProps extends LinkProps {
	variantlink?: LinkType
	disabled?: boolean
}

const CustomLink: React.FC<CustomLinkProps> = (props) => {
	const linkCSS = useMemo(
		() => ({
			...(props?.variantlink === 'primaryLink' && {
				cursor: 'pointer',
				color: 'inherit',
				transition: 'color 0.2s ease',
				textDecoration: 'none',
				'&:hover': {
					color: '#2F5DD1',
				},
			}),
			...(props?.disabled && {
				cursor: 'inherit',
				'&:hover': { color: 'inherit' },
			}),
		}),
		[props?.disabled, props?.variantlink]
	)

	return <MuiLink {...props} sx={linkCSS} />
}

export default CustomLink
