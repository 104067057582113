import React, { useCallback } from 'react'

import { Box, Chip, List, Typography, Popover, Stack } from '@mui/material'

import { useFormatMessage } from '../../../utils/hooks'

const h5CSS = {
	fontWeight: 600,
	textTransform: 'uppercase',
	mb: 2,
}
const popoverCSS = { p: 4, maxWidth: '400px' }
const containerCSS = {
	display: 'flex',
	flexWrap: 'wrap',
	'& > *': {
		margin: ({ spacing }) => spacing(0, 0.5, 0, 0.5),
	},
}

interface IListChipsModulePopover {
	handleClose
	open
	anchorEl
	defaultModules
}

const ListChipsModulePopover: React.FC<IListChipsModulePopover> = ({
	handleClose,
	open,
	anchorEl,
	defaultModules,
}) => {
	const intlMsg = useFormatMessage()

	const renderDefaultModules = useCallback(
		({ name }) => (
			<Stack spacing={1} key={name} mb={1}>
				<Chip
					size='small'
					variant='outlined'
					color='primary'
					label={intlMsg(name)}
				/>
			</Stack>
		),
		[]
	)

	return (
		<Popover open={open} anchorEl={anchorEl} onClose={handleClose}>
			<Box sx={popoverCSS}>
				<List>
					<Typography variant='body2' sx={h5CSS}>
						{intlMsg('adminPanel.modules')}
					</Typography>

					<Box sx={containerCSS}>
						{defaultModules.map(renderDefaultModules)}
					</Box>
				</List>
			</Box>
		</Popover>
	)
}

export default ListChipsModulePopover
