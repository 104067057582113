export default {
	modelEdition: 'Form Model Edition',
	modelName: 'Model Name',
	reportTitle: 'Form Title',
	description: 'Description',
	account: 'Account',
	footer: 'Footer',
	pageName: 'Page Name',
	vehicle: 'Vehicle',
	seizure: 'Seizure',
	specificField: 'Specific Fields',
	modelField: 'Models',
	setting: 'Settings',
	deletePage: 'Delete Page',
	published: 'Published',
	draft: 'Draft',
	control: 'Control',
	date: 'Date',
	parkDate: 'Park Date',
	layout: 'Layout',
	picture: 'Picture',
	pictureVehicle: 'Vehicle Picture',
	document: 'Document',
	doubleSidedPhotoDocument: 'Double-sided Photo Document',
	text: 'Text',
	title: 'Title',
	selection: 'Selection',
	numbers: 'Numbering',
	signature: 'Signature',
	rating: 'Rating',
	media: 'Media',
	instruction: 'Instruction',
	barCode: 'Bar Code',
	mileage: 'Mileage',

	picture4anglesModel: '4 Angles Vehicle Picture',
	picture4anglesAndWheelsModel: '4 Angles + Wheels Vehicle Picture',

	formTitleField: 'Title',
	formFormat: 'Format',
	longText: 'Long Text',
	simpleText: 'Simple Text',
	formRequire: 'Required',
	alert: 'Alert',
	formSummaryDisplay: 'Display in Summary',

	choice: 'Choice',
	color: 'Color',

	security: 'Security',
	legal: 'Legal',
	aesthetic: 'Aesthetic',
	other: 'Other',

	verificationType: 'Alert Type',
	canAlert: 'Trigger an alert',
	selectedChoiceToAlert: 'Trigger an alert on',
	controlFieldResume:
		'This control field will trigger a {type} alert on the vehicle if the value is {value}',
	selectionType: 'Selection Type',
	multiples: 'Multiple Choices',
	unique: 'Single Choice',

	slider: 'Slider',
	stars: 'Stars',
	min: 'Minimum',
	max: 'Maximum',
	step: 'Step',
	ratingType: 'Rating Type',

	car: 'Car',
	vul: 'VUL',
	bus: 'Bus',
	pl: 'PL',

	frontFace: 'Front Face',
	leftFace: 'Left Face',
	rightFace: 'Right Face',
	backFace: 'Back Face',
	wheel: 'Wheel',
	wheelFaceLeft: 'Left Front Wheel',
	wheelBackLeft: 'Left Back Wheel',
	wheelBackRight: 'Right Back Wheel',
	wheelFaceRight: 'Right Front Wheel',
	none: 'None',
	vehicleType: 'Classification',
	angleType: 'Specific Angle',

	littleTitle: 'Small',
	mediumTitle: 'Medium',
	bigTitle: 'Large',
	titleType: 'Character Size',

	typeYourText: 'Type your text',
	clickOrDrop: 'Click here or drop a document',
	import: 'IMPORT',
	dateFormat: 'MM/DD/YYYY',

	active: 'Active',
	inactive: 'Inactive',
	status: 'Status',

	displayTop: 'Display in Summary',
	displayNone: 'Do Not Display',
	displayBriefing: 'Display Alert Summary at the Top of the Form',
	defaultValue: 'Default Value',
	instructionText: 'Explanatory Text',

	fileImport: 'File Import',
	mediaType: 'Media Type',

	signZone: 'Signature Zone',

	dateType: 'Date Type',
	typedateParkExit: 'Park Exit Date',
	typedateParkEntry: 'Park Entry Date',
	typedate: 'Generic Date',

	limitReached: 'You have reached the limit of 10 photos.',
	unlimitedLimitReached: 'The maximum number of files has been reached.',
	limitReachedUpgraded: 'Upgrade to the next level!',
	addUnlimitedPicture: 'Add Unlimited Photos',
	addUnlimitedPicturePrice: '20€',
	addUnlimitedPicturePrice1: ' per month',
	subscribeOffer: 'subscribe to the offer',
	skipOffer: 'skip',

	SCRATCH: 'Scratch',
	BUMP: 'Dent / Bump',
	PAINT: 'Damaged Paint',
	BREAK: 'Break',
	GLASS: 'Glass Breakage',
	MISSING: 'Missing Piece',
	OTHER: 'Other',

	emptyPageForm:
		'Select the fields on the right side of the screen to build your form.',
	dontShowForm:
		'Ne pas afficher cette instruction dans le formulaire envoyé au client',
	missionType: 'Mission type',
}
