import React from 'react'

import PermissionsHandler from '../../../permissionsHandler'

interface ITableBtnCreate {
	modalButton
	customBtnCreate?
	createPermission
	modules
}

const TableBtnCreate: React.FC<ITableBtnCreate> = ({
	modalButton,
	customBtnCreate,
	createPermission = null,
	modules = null,
}) => {
	return (
		<PermissionsHandler
			permissionsName={createPermission}
			modulesName={modules}
		>
			{customBtnCreate ?? modalButton?.Btn}
		</PermissionsHandler>
	)
}

export default TableBtnCreate
