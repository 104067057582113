import React, { useMemo, memo } from 'react'

import * as colors from '@mui/material/colors'

import { Stack, Typography, Avatar } from '@mui/material'
import { TmuiColorPalette } from '../../../styles/type/colorType'
import { TshadeColor } from '../../../styles/type/shadeType'

const titleCSS = {
	fontSize: '1.5rem',
	fontWeight: 600,
	lineHeight: '30px',
}

const avatarWidgetCSS = (iconColor, iconColorShade) => ({
	bgcolor: colors[iconColor || 'grey'][iconColorShade || 50],
	width: 45,
	height: 45,
	color: colors[iconColor || 'grey'][700],
	fontWeight: 800,
	fontSize: '1.2rem',
})

const subtitleCSS = (iconColor) => ({
	color: colors[iconColor || 'grey'][700],
})

interface IWidgetIconTitle {
	icon: {
		Icon?
		iconText?: string
		iconColor?: TmuiColorPalette
		iconColorShade?: TshadeColor
	}
	title: string
	subTitle?: string
	unit?: string
	subtitleColor?: boolean
}

const WidgetIconTitle: React.FC<IWidgetIconTitle> = ({
	icon,
	title = '-',
	subTitle,
	unit,
	subtitleColor,
}) => {
	const { Icon, iconText, iconColor, iconColorShade } = icon || {}

	const selectedIcon = useMemo(
		() => (Icon ? <Icon /> : iconText),
		[Icon, iconText]
	)

	return (
		<Stack direction='row' spacing={1} alignItems='center'>
			<Avatar sx={avatarWidgetCSS(iconColor, iconColorShade)}>
				{selectedIcon}
			</Avatar>

			<Stack justifyContent='center'>
				<Typography variant='h4' sx={{ fontSize: '0.9rem!important' }}>
					{title}
				</Typography>

				<Stack
					direction='row'
					spacing={1}
					alignItems='baseline'
					sx={{ ...(subtitleColor && subtitleCSS(iconColor)) }}
				>
					<Typography sx={titleCSS}>{subTitle}</Typography>
					{unit && <Typography variant='body1'>{unit}</Typography>}
				</Stack>
			</Stack>
		</Stack>
	)
}

export default memo(WidgetIconTitle)
