import React, { lazy, useEffect, useMemo, Suspense } from 'react'
import { useModal } from '../../../utils/hooks'

const componentModalMap = {
	transport: lazy(
		() => import('../interventions/interventionTransport/interventionModal')
	),
	control: lazy(
		() => import('../interventions/interventionControl/interventionModal')
	),
}

interface ICalendarEventInfoModalIntervention {
	event: any
	handleClose: any
	openModalIntervention: any
	setOpenModalIntervention: any
	formModalEdit: any
}

const CalendarEventInfoModalIntervention: React.FC<
	ICalendarEventInfoModalIntervention
> = ({
	event,
	handleClose,
	openModalIntervention,
	setOpenModalIntervention,
	formModalEdit,
}) => {
	const modalUtils = useModal(false)
	const InterventionModal = useMemo(
		() =>
			modalUtils?.isVisible &&
			componentModalMap[event?.resource?.interventionType],
		[modalUtils?.isVisible]
	)

	useEffect(() => {
		if (openModalIntervention) {
			modalUtils?.openModal()
			handleClose()
			setOpenModalIntervention(false)
		}
	}, [openModalIntervention, modalUtils])

	return (
		<>
			{modalUtils?.isVisible && (
				<Suspense>
					<InterventionModal
						interventionId={event?.resource?.id}
						modalUtils={modalUtils}
						mode={formModalEdit}
					/>
				</Suspense>
			)}
		</>
	)
}

export default CalendarEventInfoModalIntervention
