import React, { useCallback, useContext, useEffect, useState } from 'react'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Box, Button } from '@mui/material'

import { useFormatMessage, useModal } from '../../../../../../../utils/hooks'
import CustomerContactModal from '../../../../../customerContacts/customerContactModal'
import {
	FormModalContext,
	IFormModalContext,
} from '../../../../../../../components/forms/formModal/FormModalContext'

interface ICreateContactLink {
	forcedCustomerId?: any
	forcedSiteId?: any
	name: string
	isDisabled?: boolean
}

const CreateContactLink: React.FC<ICreateContactLink> = ({
	forcedCustomerId = null,
	forcedSiteId = null,
	name,
	isDisabled = false,
}) => {
	const modalUtils = useModal(false)
	const { setValue } = useContext<IFormModalContext>(FormModalContext)
	const [createdContact, setCreatedContact] = useState<any>(null)
	const handleOpenClick = useCallback(() => modalUtils.openModal(), [])
	const intlMsg = useFormatMessage()

	useEffect(() => {
		if (createdContact) {
			setValue(name, {
				key: createdContact?.id,
				label: `${createdContact?.firstName} ${createdContact?.lastName}`,
				value: createdContact,
			})
			setCreatedContact(null)
		}
	}, [createdContact])

	return (
		<Box sx={{ margin: '0px!important' }}>
			<Button
				size='small'
				color='primary'
				startIcon={<AddCircleOutlineIcon />}
				onClick={handleOpenClick}
				sx={{ mt: 1 }}
				disabled={isDisabled}
			>
				{intlMsg('interventions.addContact')}
			</Button>

			{modalUtils?.isVisible && (
				<CustomerContactModal
					modalUtils={modalUtils}
					forcedCustomerId={forcedCustomerId}
					forcedSiteId={forcedSiteId}
					setCreatedContact={setCreatedContact}
				/>
			)}
		</Box>
	)
}

export default CreateContactLink
