import React, { useCallback, useContext, useMemo, useState } from 'react'

import {
	ClickAwayListener,
	Button,
	Grid,
	Grow,
	Paper,
	Popper,
	Typography,
	Tooltip,
	Box,
	Stack,
	IconButton,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { stringifiedColor } from '../../../../../utils/color'
import { maxStringDisplay } from '../../../../../utils/string'
import { useFormatMessage } from '../../../../../utils/hooks'
import { FormModalContext } from '../../../../../components/forms/formModal/FormModalContext'
import FormTextField from '../../../../../components/forms/formFields/FormTextField'
import ColorRadio from '../../../../../components/forms/formFields/colorRadio'

const positionIconCSS = {
	position: 'absolute',
	right: '30px',
	top: '25px',
}

const locationBtnCSS = (color) => {
	return {
		...(!color && { color: `${stringifiedColor('grey', 800)} !important` }),
		...(!color && {
			border: `solid 1px ${stringifiedColor('grey', 400)} !important`,
		}),

		backgroundColor: `${
			color
				? `${stringifiedColor(color, 500)} !important`
				: 'transparent  !important'
		}`,

		'&:hover': {
			background: `${stringifiedColor(
				color || 'grey',
				color ? 800 : 100
			)} !important`,
		},

		...(!color && {
			'&:hover': {
				backgroundColor: 'transparent',
				border: `solid 1px ${stringifiedColor('grey', 500)} !important`,
			},
		}),
	}
}

const locationBtnEmptyCSS = {
	backgroundColor: 'transparent !important',
	color: `${stringifiedColor('grey', 800)} !important`,
	border: `solid 1px ${stringifiedColor('grey', 400)} !important`,
	'&:hover': {
		backgroundColor: 'transparent',
		border: `solid 1px ${stringifiedColor('grey', 500)} !important`,
	},
}

const paperLocationVehicleCSS = {
	p: 4,
	maxWidth: '328px',
	zIndex: 1000,
	position: 'relative',
	backgroundColor: 'white',
}

const maxLength = 6

interface IVehicleLocationFormModal {}

const VehicleLocationFormModal: React.FC<IVehicleLocationFormModal> = () => {
	const intlMsg = useFormatMessage()
	const { data, setValues } = useContext(FormModalContext)

	const location = useMemo(() => data?.location || '', [data?.location])
	const color = useMemo(() => data?.color || '', [data?.color])

	// Color popover /////////////////////////////////////////////
	const [open, setOpen] = useState(false)
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

	const handleToggleColor = useCallback((e) => {
		setAnchorEl(e.currentTarget)
		setOpen((prevOpen) => !prevOpen)
	}, [])

	const handleClose = useCallback(() => {
		location?.length <= 25 && setOpen(false)
	}, [setAnchorEl, setOpen, location])

	// Delete location
	const deleteLocation = useCallback(() => {
		setValues({
			location: '',
			color: '',
		})
	}, [setValues])

	return (
		<>
			<Box
				sx={{ cursor: 'pointer' }}
				aria-describedby={'id'}
				onClick={handleToggleColor}
			>
				{location || color ? (
					<Button
						variant='contained'
						color='secondary'
						sx={{ ...locationBtnCSS(color) }}
						fullWidth
						size='medium'
					>
						{location?.length > maxLength ? (
							<Tooltip title={location} aria-label={location}>
								<span>{maxStringDisplay(location, maxLength)}</span>
							</Tooltip>
						) : (
							location || intlMsg('vehicle.locationShort')
						)}
					</Button>
				) : (
					<Button
						variant='contained'
						color='secondary'
						sx={locationBtnEmptyCSS}
						fullWidth
						size='medium'
					>
						{intlMsg('vehicle.location')}
					</Button>
				)}
			</Box>

			{open && (
				<Popper
					open={open}
					anchorEl={anchorEl}
					role={undefined}
					transition
					style={{ zIndex: 10000 }}
					disablePortal
				>
					{({ TransitionProps }) => (
						<Grow {...TransitionProps}>
							<Paper sx={paperLocationVehicleCSS}>
								<ClickAwayListener onClickAway={handleClose}>
									<Grid container spacing={2}>
										<Stack direction='row' mt={2}>
											<Typography variant='subtitle2' gutterBottom>
												{intlMsg('vehicle.locationTitle')}
											</Typography>
											<IconButton
												sx={positionIconCSS}
												aria-label='delete'
												size='small'
												onClick={deleteLocation}
											>
												<DeleteIcon />
											</IconButton>
										</Stack>
										<Grid item xs={12}>
											<FormTextField
												name='location'
												label={intlMsg('vehicle.location')}
												isRequired
												context={FormModalContext}
											/>
										</Grid>
										<Typography variant='subtitle2' gutterBottom sx={{ mt: 2 }}>
											{intlMsg('vehicle.locationColor')}
										</Typography>
										<Grid item xs={12}>
											<ColorRadio name='color' context={FormModalContext} />
										</Grid>
									</Grid>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			)}
		</>
	)
}

export default VehicleLocationFormModal
