import Alert from '@mui/material/Alert'
import React from 'react'
import { useFormatMessage } from '../../../../utils/hooks'

interface ITabPanelUserSchedule {}

const TabPanelUserSchedule: React.FC<ITabPanelUserSchedule> = () => {
	const intlMsg = useFormatMessage()
	return (
		<div>
			<Alert severity='info'>{intlMsg('misc.comingSoon')}</Alert>
		</div>
	)
}

export default TabPanelUserSchedule
