import React, { useMemo } from 'react'

import { Typography } from '@mui/material'
import { dayjsLocalizedFormatMethod } from '../../../utils/date'

interface IDateCell {
	date?
}

const DateCell: React.FC<IDateCell> = ({ date }) => {
	const formattedDate = useMemo(() => {
		const dayjs = dayjsLocalizedFormatMethod()
		return date ? dayjs(date).format('L') : '-'
	}, [date])

	return (
		<Typography color='inherit' variant='caption'>
			{formattedDate}
		</Typography>
	)
}

export default DateCell
