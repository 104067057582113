import { grey } from '@mui/material/colors'

import { EPermissionScope } from '../../../utils/app-models'

export const NavTopCss = {
	wrapperAvatarCSS: {
		display: 'flex',
		alignItems: 'center',
		ml: 2,
		cursor: 'pointer',
	},
	orderServiceButton: {
		alignItems: 'center',
		padding: '16px 26px',
		display: 'flex',
		textDecoration: 'none',

		'& .typo': {
			color: grey[500],
			textTransform: 'uppercase',
			fontWeight: '500',
			marginLeft: '8px',
		},

		'& .icon': {
			color: grey[500],
			width: '20px',
			height: '20px',
		},
	},
	addBtnIconCSS: {
		margin: '0px',
		padding: '22px',
		color: '#999999',
		'&:hover': { color: '#888787', backgroundColor: 'transparent' },
		borderRadius: 0,
		'& .MuiTouchRipple-root span': {
			backgroundColor: '#ddd',
			opacity: 0.3,
		},
		borderRight: '1px solid #ddd6',
	},
	menuButtonCSS: {
		marginRight: 5,
		color: '#999999',
		boxShadow: '#ddd 0px 0px 11px 0px',
		transform: 'rotate(180deg)',
		transitionDuration: '0.5s',
	},
	menuButtonTransformCSS: {
		marginRight: 5,
		color: '#999999',
		boxShadow: '#ddd 0px 0px 11px 0px',
		transform: 'rotate(0deg)',
		transitionDuration: '0.5s',
	},
	appBarCloseCSS: {
		backgroundColor: 'white',
		width: `calc(100% - 60px)`,
		zIndex: 999,
		transitionDuration: '0.5s',
	},
	appBarOpenCSS: {
		marginLeft: '225px',
		width: 'calc(100% - 225px)',
		transition: (theme) =>
			theme.transitions.create(['width', 'margin'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
	},
	toolbarSx: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	toolbarBoxSx: { display: 'flex', alignItems: 'center' },
}

//one of these permissions is required to display the shorcut button
export const NavTopCreatePermissions = [
	EPermissionScope.siteManage,
	EPermissionScope.customerManage,
	EPermissionScope.vehicleCreate,
	EPermissionScope.contactManage,
	EPermissionScope.networkManage,
	EPermissionScope.interventionCreate,
]
