import React, { useCallback, useMemo, memo } from 'react'
import { Box, Typography, Skeleton } from '@mui/material'

interface ISkeletonPanelUser {
	nbRow: number
}

const SkeletonPanelUser: React.FC<ISkeletonPanelUser> = ({ nbRow }) => {
	const generatedArray = useMemo(() => Array.from(Array(nbRow).keys()), [nbRow])

	const renderGeneratedArray = useCallback((_, i) => {
		return (
			<Typography
				key={`${i}`}
				component='div'
				variant={'h2'}
				sx={{ opacity: 0.5 }}
			>
				<Skeleton animation='wave' key={`${i + 2}`} />
			</Typography>
		)
	}, [])

	return (
		<>
			<Box sx={{ mb: 2 }}>
				<Skeleton
					animation='wave'
					variant='rectangular'
					width={500}
					height={500}
				/>
			</Box>

			<Box sx={{ p: 2 }}>
				<Box sx={{ mb: 2 }}>
					<Skeleton animation='wave' variant='rectangular' height={50} />
				</Box>

				{generatedArray?.map(renderGeneratedArray)}
			</Box>
		</>
	)
}

export default memo(SkeletonPanelUser)
