import React from 'react'

import Table from '../..'
import {
	IOverrideConfigTable,
	IOverrideConfigTableWithArgument,
} from '../../../../utils/typescript/tableType'

import { listCategoriesTableConfig } from './listCategoriesTableConfig'

interface ISharedProductCategories {
	othersQueryParams?
	overrideConfigTable?: IOverrideConfigTable | IOverrideConfigTableWithArgument
	isInViewModal?: boolean
}

const SharedProductCategories: React.FC<ISharedProductCategories> = ({
	othersQueryParams,
	overrideConfigTable,
	isInViewModal = false,
}) => {
	return (
		<Table
			tableConfig={listCategoriesTableConfig}
			overrideConfigTable={overrideConfigTable}
			othersQueryParams={othersQueryParams}
			isInViewModal={isInViewModal}
		/>
	)
}

export default SharedProductCategories
