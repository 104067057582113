import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, List, ListItem, ListItemText } from '@mui/material'

import { useFormatMessage } from '../../../../../utils/hooks'
import PermissionsHandler from '../../../../permissionsHandler'

const styles = {
	subItemText: {
		color: '#b2b6c6',
		marginLeft: '50px',
		'& span': { fontSize: '13px!important' },
	},
	listItem: {
		overflow: 'visible!important',
		backgroundColor: '#061538',
		padding: '15px 30px 15px 17px',
		'&:hover': {
			backgroundColor: '#192A54',
		},
	},
	line: {
		width: '1px',
		height: '100%',
		backgroundColor: '#ffffff1f',
		position: 'absolute',
		zIndex: 1,
		left: '41px',
	},
	isSelected: {
		position: 'absolute',
		borderLeft: `solid 4px #f13a30 !important`,
		top: '0px',
		right: '-5px',
		borderRadius: '268px',
		width: '9px',
		height: '57px',
	},
}

interface ISubNavItem {
	config: Array<{
		label: string
		path: string
		subPermissions: string[]
		subModules: string[]
	}>
	location: any
}

const SubNavItem: React.FC<ISubNavItem> = ({ config, location }) => {
	const intlMsg = useFormatMessage()
	const navigate = useNavigate()

	const handleClickMenu = useCallback(
		(newPath) => (e) => {
			e.preventDefault()
			e.ctrlKey ? window.open(newPath, '_blank') : navigate(newPath)
		},
		[navigate]
	)

	return (
		<List component='div' disablePadding>
			<Box sx={styles.line} />
			{config.map(({ label, path, subPermissions, subModules }) => (
				<PermissionsHandler
					key={`${label}-nav`}
					permissionsName={subPermissions}
					modulesName={subModules}
				>
					<ListItem button sx={styles.listItem} onClick={handleClickMenu(path)}>
						<ListItemText primary={intlMsg(label)} sx={styles.subItemText} />
						{location?.pathname === path && (
							<Box component='span' sx={styles.isSelected} />
						)}
					</ListItem>
				</PermissionsHandler>
			))}
		</List>
	)
}

export default SubNavItem
