import React, { useState, useCallback, useEffect } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import { useFormatMessage } from '../../../utils/hooks'
import PermissionsHandler from '../../permissionsHandler'
import { tabsCSS, tabCSS } from '../../../styles/sxCSS/tabs'

interface ITableNavTab {
	navTabConfig
	sxTabs?
}

const TableNavTab: React.FC<ITableNavTab> = ({ navTabConfig, sxTabs = {} }) => {
	const navigate = useNavigate()
	const location = useLocation()
	const intlMsg = useFormatMessage()

	const [value, setValue] = useState(0)

	const handleChange = useCallback(
		(_event, newValue) => {
			navigate(navTabConfig[newValue].route)
			setValue(newValue)
		},
		[navigate]
	)

	useEffect(() => {
		navTabConfig.find(({ route }, i) => {
			if (route === location.pathname) setValue(i)
		})
	}, [location])

	return (
		<Tabs
			value={value}
			indicatorColor='primary'
			textColor='primary'
			onChange={handleChange}
			TabIndicatorProps={{ children: <span /> }}
			sx={{ ...tabsCSS, '& .MuiTabs-scroller': { ml: -1 }, mt: -2, ...sxTabs }}
		>
			{navTabConfig?.map(({ label, permissionsName, modulesName }, i) => (
				<PermissionsHandler
					key={i}
					permissionsName={permissionsName}
					modulesName={modulesName}
				>
					<Tab
						value={i}
						key={i}
						label={intlMsg(label)}
						sx={{
							...tabCSS,
							color: i === value ? 'black!important' : 'inherit',
						}}
						disableRipple
						onChange={handleChange as any}
					/>
				</PermissionsHandler>
			))}
		</Tabs>
	)
}

export default TableNavTab
