import React, { useMemo } from 'react'

import { ListItemsData } from '../../../../components'

import PhoneIcon from '@mui/icons-material/Phone'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import EmailIcon from '@mui/icons-material/Email'
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew'

interface ITabPanelUserInformation {
	dataUser: {
		job?: string
		phone?: string
		cellphone?: string
		email?: string
	}
}
const TabPanelUserInformation: React.FC<ITabPanelUserInformation> = ({
	dataUser,
}) => {
	const items = useMemo(
		() => [
			{
				primaryText: 'misc.poste',
				secondaryText: dataUser?.job || '-',
				icon: <AccessibilityNewIcon />,
			},
			{
				primaryText: 'misc.email',
				secondaryText: dataUser?.email || '-',
				icon: <EmailIcon />,
				externalLink: `mailto:${dataUser?.email}`,
				externalLinkTarget: '_self',
			},
			{
				primaryText: 'misc.cellphone',
				secondaryText: dataUser?.cellphone || '-',
				icon: <PhoneAndroidIcon />,
				...(dataUser?.cellphone && {
					externalLink: `tel:${dataUser.cellphone}`,
					externalLinkTarget: '_self',
				}),
			},
			{
				primaryText: 'misc.phone',
				secondaryText: dataUser?.phone || '-',
				icon: <PhoneIcon fontSize='small' />,
				...(dataUser?.phone && {
					externalLink: `tel:${dataUser.phone}`,
					externalLinkTarget: '_self',
				}),
			},
		],
		[dataUser]
	)

	return (
		<>
			<ListItemsData items={items} nbCol={6} />
		</>
	)
}

export default TabPanelUserInformation
