import React from 'react'
import translateIntlMsgString from '../../../../utils/translateIntlMsgString'
import EditUserPasswordModalInfo from './EditUserPasswordModalInfo'
import { editUserPasswordModalValidationSchema } from './editUserPasswordModalValidationSchema'

export const editUserPasswordModalConfig = [
	{
		tabName: translateIntlMsgString('misc.team'),
		component: <EditUserPasswordModalInfo />,
		schema: editUserPasswordModalValidationSchema,
	},
]
