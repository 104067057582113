import React, { useMemo } from 'react'
import { Alert, AlertColor, Box, Typography } from '@mui/material'

const alertCSS = {
	'& span': { fontWeight: 'bold', fontSize: '16px' },
	'& a': { fontWeight: 'bold', color: '#2558a3' },
}

interface IAlerteMessage {
	message: string
	padding?: number
	icon?: boolean
	severity?: AlertColor
	variant?: any
	sx?: any
}

const AlerteMessage: React.FC<IAlerteMessage> = ({
	message,
	padding,
	icon,
	severity = 'info',
	variant = 'body2',
	sx,
}) => {
	const combinedStyles = useMemo(
		() => ({
			...alertCSS,
			...sx,
		}),
		[sx]
	)
	return (
		<Box p={padding} sx={combinedStyles}>
			<Alert icon={icon} severity={severity}>
				<Typography
					variant={variant}
					dangerouslySetInnerHTML={{ __html: message }}
				/>
			</Alert>
		</Box>
	)
}
export default AlerteMessage
