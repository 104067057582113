/* eslint-disable no-case-declarations */
import React from 'react'

import dayjs from 'dayjs'
import { Box, Stack, Typography } from '@mui/material'

import { useFormatMessage } from '../../../../../utils/hooks'
import MembersCell from '../../../../../components/tableV2/tableCell/MembersCell'
import MembersPopOver from '../../../../../components/tableV2/tableCell/tableCellPopOver/MembersPopOver'

const interventionTypeCSS: any = ({ colors }) => ({
	mt: '2px',
	p: '0px 3px',
	borderRadius: '2px',
	backgroundColor: colors.intervention,
	fontSize: '10px',
	fontWeight: 400,
	height: 'fit-content',
})

const timeCSS = { fontSize: '10px', mt: '2px' }
const orderingCSS = { fontSize: '10px', fontWeight: 500 }
const avatarCSS = {
	display: 'flex',
	alignItems: 'center',
	ml: 2,
	justifyContent: 'end',
}

interface IInterventionControlMonthEvent {
	event?: any
	setIsMembersCellOpen?: any
}

const InterventionControlMonthEvent: React.FC<
	IInterventionControlMonthEvent
> = ({ event, setIsMembersCellOpen }) => {
	const intlMsg = useFormatMessage()

	const {
		orderingCustomer: { name: customerName },
		orderingSite: { name: siteName },
		actors,
	} = event?.resource || {}

	return (
		<Stack direction='row' spacing={0.5}>
			<Box sx={interventionTypeCSS}>
				{intlMsg(`interventions.intervention`).substring(0, 5)}
			</Box>
			<Stack width={1}>
				<Typography sx={timeCSS}>{`${dayjs(event?.start).format(
					'HH:mm'
				)} - ${dayjs(event?.end).format('HH:mm')}`}</Typography>

				<Typography
					sx={orderingCSS}
				>{`${customerName} - ${siteName}`}</Typography>
			</Stack>

			{!!actors?.length && (
				<Box sx={avatarCSS}>
					<MembersCell
						data={actors?.map(({ id, lastName, firstName }) => ({
							firstName,
							lastName,
							id,
						}))}
						Popover={MembersPopOver}
						displayManager={false}
						setIsOpen={setIsMembersCellOpen}
					/>
				</Box>
			)}
		</Stack>
	)
}

export default InterventionControlMonthEvent
