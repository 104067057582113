import React, { Context, useCallback, useContext, memo } from 'react'
import { Box, Checkbox, Radio, Typography } from '@mui/material'
import getNestedObjectFromKey from 'lodash.get'
import { IStepperContext } from '../../formStepper/StepperContext'
import { IFormModalContext } from '../../formModal/FormModalContext'
import { personalizedFormSelectSearchCSS } from './personalizedFormSelectSearchCSS'

interface IOptionList {
	name: string
	context: Context<IFormModalContext> | Context<IStepperContext>
	options: Array<{
		key: string
		label: string
		value: {
			name: string
			categoryName: string
		}
	}>
	groupBy?: string
	sortedOptions: any
	values: any
	selectAllText: string
	missingText?: string
	specialOnChange?: any
	seeSelected: boolean
	isAllSelected: boolean
	isMultiple: boolean
}

const OptionList: React.FC<IOptionList> = ({
	name,
	context,
	sortedOptions,
	groupBy,
	values,
	selectAllText,
	missingText = '',
	specialOnChange,
	seeSelected,
	isAllSelected,
	isMultiple,
}) => {
	const { setValue } = useContext<IFormModalContext | IStepperContext>(
		context as any
	)

	const handleCheckBoxChange = useCallback(
		(option) => () => {
			const currentOption = values[option.key]
			if (currentOption && currentOption.isDisabled) {
				return
			}

			const newValues = isMultiple ? { ...values } : {}

			newValues[option.key] = {
				checked: !values[option.key]?.checked,
				value: option.value,
			}
			specialOnChange
				? specialOnChange(name, newValues)
				: setValue(name, newValues)
		},
		[name, setValue, values, specialOnChange, isMultiple]
	)

	const handleSelectAll = useCallback(() => {
		const newValues = { ...values }
		sortedOptions.forEach((option) => {
			newValues[option.key] = { checked: !isAllSelected, value: option.value }
		})
		specialOnChange
			? specialOnChange(name, newValues)
			: setValue(name, newValues)
	}, [sortedOptions, isAllSelected, values, setValue, specialOnChange])

	const renderOption = useCallback(
		(option, index) => {
			const optionGroupByValue = getNestedObjectFromKey(option?.value, groupBy)
			const groupByOptions =
				index === 0 ||
				optionGroupByValue !==
					getNestedObjectFromKey(sortedOptions[index - 1]?.value, groupBy)
			const checked = values[option.key]?.checked

			return (
				<Box
					key={option?.key}
					onClick={handleCheckBoxChange(option)}
					role='listitem'
				>
					<Box>
						{groupByOptions && optionGroupByValue && (
							<Typography
								className='PopoverBottom-sort'
								sx={{ textTransform: 'uppercase' }}
							>
								{optionGroupByValue}
							</Typography>
						)}
						<Box sx={personalizedFormSelectSearchCSS.SelectItemCSS}>
							<Box
								className={
									(checked ? 'PopoverBottom-box-checked ' : '') +
									'SelectItemBoxCSS'
								}
							>
								<Box className='selectItemCSS-left'>
									<Typography>{option.label}</Typography>
								</Box>
								<Box>
									{isMultiple ? (
										<Checkbox
											checked={checked || false}
											disabled={
												(values[option.key] && values[option.key].isDisabled) ||
												false
											}
											size='small'
											data-test-id={option.label}
										/>
									) : (
										<Radio
											checked={checked || false}
											color={checked ? 'primary' : 'default'}
											size='small'
											data-test-id={option.label}
										/>
									)}
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			)
		},
		[groupBy, handleCheckBoxChange, values, sortedOptions]
	)

	return (
		<>
			{sortedOptions?.length > 0 ? (
				<Box className='PopoverBottom' id='popover-multiselect'>
					{!seeSelected && sortedOptions?.length > 0 && isMultiple && (
						<Box
							sx={personalizedFormSelectSearchCSS.SelectItemAllCSS}
							onClick={handleSelectAll}
						>
							<>
								<Box>{selectAllText}</Box>
								<Box>
									<Checkbox checked={isAllSelected || false} size='small' />
								</Box>
							</>
						</Box>
					)}
					<Box
						className='PopoverBottom-box'
						sx={
							seeSelected && sortedOptions?.length
								? { paddingTop: '0px !important' }
								: {}
						}
					>
						{sortedOptions?.map(renderOption)}
					</Box>
				</Box>
			) : (
				<Box sx={personalizedFormSelectSearchCSS.missingTextCSS}>
					{missingText || 'No data found'}
				</Box>
			)}
		</>
	)
}

export default memo(OptionList)
