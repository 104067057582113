/* FormModalSelectSearchInput.jsx
This component renders a text field input with enhanced features for the FormModalSelectSearch component. It supports features like prefixing input, displaying loading indicators, chips for selected options, and color-coded status indicators based on selected values. It’s designed to be used as part of a search-select functionality within modals, accommodating dynamic content with custom visual feedback for selection and loading states. Validation and accessibility are also integrated, with options for required fields and disabled states. */

import React, { useMemo } from 'react'

import TextField from '@mui/material/TextField'
import { CircularProgress } from '@mui/material'

import FormSelectSearchAdorment from './FormSelectSearchAdorment'

interface IFormModalSelectSearchInput {
	params: any
	label: string
	handleInputValueChange: any
	inputValue: string
	error?: any
	prefix?: string
	isRequired?: boolean
	browserRequired?: boolean
	isDisabled?: boolean
	size: 'medium' | 'small' | undefined
	customHelper?: string
	hidden?: boolean
	isLoading?: boolean
	name: string
	displayChip?: boolean
	displayColorInput?: boolean
	selectedOption
}

const FormModalSelectSearchInput: React.FC<IFormModalSelectSearchInput> = ({
	params,
	handleInputValueChange,
	inputValue,
	prefix,
	label,
	isRequired,
	browserRequired,
	isDisabled,
	error,
	size,
	customHelper,
	hidden,
	isLoading,
	name,
	displayChip,
	displayColorInput,
	selectedOption,
}) => {
	//  handle the chip display logic and prefix attachment.
	const calculatedinputProps = useMemo(
		() => ({
			...params.inputProps,
			value:
				displayChip && selectedOption
					? inputValue === selectedOption?.label
						? ''
						: inputValue
					: `${inputValue}${prefix ?? ''}`,
		}),
		[inputValue, params.inputProps, selectedOption]
	)

	const calculatedInputProps = useMemo(
		() => ({
			...params.InputProps,
			// Custom adornments for displaying selected option chips or color indicators
			startAdornment: selectedOption ? (
				<FormSelectSearchAdorment
					selectedOption={selectedOption}
					params={params}
					displayChip={displayChip}
					displayColorInput={displayColorInput}
				/>
			) : (
				'' // !important don't use <></> but a empty string to display label inside input when value is empty
			),
			// Handling of the loading indicator for the end adornment of the input
			endAdornment: (
				<>
					{isLoading ? <CircularProgress color='inherit' size={20} /> : null}
					{!isLoading && params.InputProps.endAdornment}
				</>
			),
		}),
		[
			params.InputProps,
			isLoading,
			displayChip,
			displayColorInput,
			selectedOption,
		]
	)
	const calculatedLabel = useMemo(
		() => `${label} ${isRequired && !browserRequired ? '*' : ''}`,
		[browserRequired, isRequired]
	)
	const sx = useMemo(
		() => ({
			opacity: isDisabled ? '0.5' : '1',
			display: hidden ? 'none' : 'inherit',
		}),
		[]
	)

	return (
		<TextField
			{...params}
			onChange={handleInputValueChange}
			inputProps={calculatedinputProps}
			label={calculatedLabel}
			disabled={isDisabled}
			error={!!error}
			variant='outlined'
			size={size || 'small'}
			helperText={error || customHelper || ''}
			sx={sx}
			InputProps={calculatedInputProps}
			required={browserRequired && isRequired}
			name={name}
		/>
	)
}

export default FormModalSelectSearchInput
