/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { gql, useMutation } from '@apollo/client'

export const REMOVE_INTERVENTION_FORMS = gql`
	mutation removeForm($input: removeFormInput!) {
		removeForm(input: $input) {
			form {
				id
			}
		}
	}
`

export const DELETE_INTERVENTION_FORMS = gql`
	mutation deleteForm($input: deleteFormInput!) {
		deleteForm(input: $input) {
			form {
				id
			}
		}
	}
`

export const BULK_INTERVENTION_FORMS_ACTION = gql`
	mutation bulkOperationForm($input: bulkOperationFormInput!) {
		bulkOperationForm(input: $input) {
			form {
				id
			}
		}
	}
`

/**  ---------------------HOOKS---------------------- */

export const useRemoveInterventionForms = () =>
	useMutation(REMOVE_INTERVENTION_FORMS)
export const useDeleteInterventionForms = () =>
	useMutation(DELETE_INTERVENTION_FORMS)
export const useBulkInterventionFormsAction = () =>
	useMutation(BULK_INTERVENTION_FORMS_ACTION)
