import React, { useCallback, useState } from 'react'

import { Button, Stack, IconButton, Popover, List } from '@mui/material'
import ModeIcon from '@mui/icons-material/Mode'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DeleteIcon from '@mui/icons-material/Delete'
import FileCopyIcon from '@mui/icons-material/FileCopy'

import { useFormatMessage, useModal } from '../../../../utils/hooks'
import PermissionsHandler from '../../../../components/permissionsHandler'
import { EFormModalMode } from '../../../../utils/app-models'
import { footerModalCSS } from '../../../../components/forms/modalSxCss'
import ActionBtn from '../../../../components/tableV2/tableCell/actionsBtn/ActionBtn'
import ConfirmDelete from '../../../../components/modal/ConfirmDelete'

interface ICalendarEventFooter {
	permissionsName
	deleted
	handleEdit
	handleView
	interventionId
	handleCloseModalInfos
	setFormModalEdit
}

const CalendarEventFooter: React.FC<ICalendarEventFooter> = ({
	permissionsName,
	deleted,
	handleEdit,
	handleView,
	interventionId,
	handleCloseModalInfos,
	setFormModalEdit,
}) => {
	const intlMsg = useFormatMessage()

	/////////////////////////////////////////////////////////
	// Edit intervention
	/////////////////////////////////////////////////////////

	const onDuplicate = useCallback(() => {
		setFormModalEdit(EFormModalMode.duplicate)
		handleEdit()
	}, [])
	/////////////////////////////////////////////////////////
	// Duplicate intervention
	/////////////////////////////////////////////////////////

	const onEdit = useCallback(() => {
		setFormModalEdit(EFormModalMode.edit)
		handleEdit()
	}, [])
	/////////////////////////////////////////////////////////
	// Warning suppression Modal
	/////////////////////////////////////////////////////////

	const {
		isVisible: isVisibleWarning,
		openModal: openModalWarning,
		closeModal: closeModalWarning,
	} = useModal()

	/////////////////////////////////////////////////////////
	// More action
	/////////////////////////////////////////////////////////

	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
	const open = Boolean(anchorEl)
	const id = open ? 'simple-popover' : undefined
	const handleClick = useCallback(
		(event: React.MouseEvent<HTMLButtonElement>) => {
			setAnchorEl(event.currentTarget)
		},
		[]
	)
	const handleClose = useCallback(() => {
		setAnchorEl(null)
	}, [])

	const closeActions = useCallback(() => {
		handleClose()
		closeModalWarning()
		handleCloseModalInfos()
	}, [])

	return (
		<>
			<Stack direction='row' justifyContent='space-between' sx={footerModalCSS}>
				<IconButton aria-describedby={id} onClick={handleClick}>
					<MoreVertIcon />
				</IconButton>

				<Stack direction='row' spacing={1}>
					<PermissionsHandler permissionsName={permissionsName.update}>
						<Button
							variant='outlined'
							onClick={onEdit}
							startIcon={<ModeIcon />}
						>
							{intlMsg('misc.edit')}
						</Button>
					</PermissionsHandler>
					<Button variant='contained' color='primary' onClick={handleView}>
						{intlMsg('misc.display')}
					</Button>
				</Stack>
			</Stack>

			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
			>
				<List dense={false} component='nav'>
					<ActionBtn
						permissionsName={permissionsName?.create}
						onClick={onDuplicate}
						txtIntlMsg='misc.duplicate'
						Icon={FileCopyIcon}
					/>

					<ActionBtn
						permissionsName={permissionsName?.remove}
						onClick={openModalWarning}
						txtIntlMsg='misc.deleted'
						Icon={DeleteIcon}
					/>
				</List>
			</Popover>

			{isVisibleWarning && deleted?.messageConfirm && (
				<ConfirmDelete
					rowId={interventionId}
					handleClose={handleClose}
					onClose={closeActions}
					open={isVisibleWarning}
					deleted={deleted}
				/>
			)}
		</>
	)
}

export default CalendarEventFooter
