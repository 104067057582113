import React, { memo } from 'react'
import { Box } from '@mui/material'

interface ICountCircle {
	count: number
}

const CountCircle: React.FC<ICountCircle> = ({ count }) => {
	const countCSS = {
		background: !count ? '#ebebeb' : '#edf7fe',
		borderRadius: '50%',
		color: '#5e5e5e',
		display: 'inline-block',
		fontWeight: 'bold',
		lineHeight: '28px',
		textAlign: 'center',
		width: '28px',
	}

	return <Box sx={countCSS}>{count}</Box>
}

export default memo(CountCircle)
