import React from 'react'

import CustomLink from '../../../_overrideMui/Link/CustomLink'

interface IDataListMail {
	text: string
}

const DataListMail: React.FC<IDataListMail> = ({ text }) => {
	return (
		<CustomLink
			variant='text1Bold'
			variantlink='primaryLink'
			disabled={!text}
			href={text ? `mailto:${text}` : undefined}
		>
			{text || '-'}
		</CustomLink>
	)
}

export default DataListMail
