/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

import { EFormFieldsPDF } from '../../../../utils/app-models'
import { formatDate } from '../../../../utils/date'
import { dateToFrom, formatAddressIntervention } from '../../utilPdf'
import { row5CellTitle, row5CellValue } from './_formatDataUtils'

///////////////////////////////////////////////////////////////////////////////////////////////////////
// INFOS -> Data [] are use to generate table (row,cell,etc..)
///////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////
// FIRST PAGE : BLOCK FORM
///////////////////////////////////////////////////////////////////////////////////////////////////////

export const dataBlockForm = (data, intlMsg) => {
	const { reference, dateCreated, geolocation } = data

	// generate data row title
	const dataBlockFormsTITLE = [
		// 1
		row5CellTitle(),
		// 2
		row5CellTitle(intlMsg, 'pdfForms.form'),
		// 3
		row5CellTitle(intlMsg, 'pdfForms.ref', 'pdfForms.localeDate'),
		// 4
		row5CellTitle(intlMsg, 'pdfForms.geolocalisation', 'pdfForms.horodatage'),
		// 5
		row5CellTitle(),
	]

	// generate data row records value
	const dataBlockFormsVALUE = [
		// 1
		row5CellValue(null, null, true),
		// 2
		row5CellValue(null, null, true),
		// 3
		row5CellValue(
			{
				value: reference,
				type: EFormFieldsPDF.text,
			},
			{
				value: formatDate(dateCreated, true),
				type: EFormFieldsPDF.text,
			}
		),
		// 4
		row5CellValue(
			{
				value: geolocation?.latitude
					? `${Number.parseFloat(geolocation.latitude).toFixed(
							7
					  )}, ${Number.parseFloat(geolocation.longitude).toFixed(7)}`
					: '-',
				type: EFormFieldsPDF.text,
			},
			{
				value: dateCreated ? dateCreated.split('+')[0] + ' UTC' : '',
				type: EFormFieldsPDF.text,
			}
		),
		// 5
		row5CellValue(null, null, true),
	]

	return {
		title: dataBlockFormsTITLE,
		record: dataBlockFormsVALUE,
		option: {
			columnStyles: {
				0: { cellWidth: 9 },
				1: { cellWidth: 77.5 },
				2: { cellWidth: 9 },
				3: { cellWidth: 77.5 },
				4: { cellWidth: 9 },
			},
		},
	}
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
// FIRST PAGE : BLOCK INTERVENTION CONTROL
///////////////////////////////////////////////////////////////////////////////////////////////////////

export const dataBlockControl = (data, intlMsg) => {
	const {
		intervention,
		vehicle: {
			vehicleDetail: { immat, vehicleBrand, vin, vehicleModelText },
		},
	} = data

	// generate data row title
	const dataBlockFormsTITLE = [
		// 1
		row5CellTitle(),
		// 2
		row5CellTitle(intlMsg, 'pdfForms.intervention'),
		// 3
		row5CellTitle(intlMsg, 'pdfForms.ref', 'pdfForms.tentativeDate'),
		// 4
		row5CellTitle(intlMsg, 'pdfForms.transportRequest', 'pdfForms.site'),
		// 5
		row5CellTitle(intlMsg, 'pdfForms.tentativeAddress', 'pdfForms.vehicle'),
		// 6
		row5CellTitle(),
	]

	// generate data row records value
	const dataBlockFormsVALUE = [
		// 1
		row5CellValue(null, null, true),
		// 2
		row5CellValue(null, null, true),
		// 3
		row5CellValue(
			{
				value: intervention?.reference,
				type: EFormFieldsPDF.longText,
			},
			{
				value: dateToFrom(data, [
					'intervention.dateTo',
					'intervention.dateFrom',
				]),
				type: EFormFieldsPDF.longText,
			}
		),
		// 4
		row5CellValue(
			{
				value: intervention?.orderingCustomer?.name ?? '-',
				type: EFormFieldsPDF.longText,
			},

			{
				value: intervention?.orderingSite?.name ?? '-',
				type: EFormFieldsPDF.longText,
			}
		),
		// 5
		row5CellValue(
			{
				value: formatAddressIntervention(intervention),
				type: EFormFieldsPDF.longText,
			},
			{
				value: `${immat ?? ''} ${vin ?? ''} - ${vehicleBrand?.name ?? ''} ${
					vehicleModelText ?? ''
				}`,
				type: EFormFieldsPDF.longText,
			}
		),
		// 6
		row5CellValue(null, null, true),
	]

	return {
		title: dataBlockFormsTITLE,
		record: dataBlockFormsVALUE,
		option: {
			columnStyles: {
				0: { cellWidth: 9 },
				1: { cellWidth: 77.5 },
				2: { cellWidth: 9 },
				3: { cellWidth: 77.5 },
				4: { cellWidth: 9 },
			},
		},
	}
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
// FIRST PAGE :  BLOCK SIGNATURE
///////////////////////////////////////////////////////////////////////////////////////////////////////

export const dataBlockSignature = (data, intlMsg) => {
	// Data
	const { intervention } = data

	// generate data row title
	const dataBlockSignatureTITLE = [
		{
			value1: intlMsg('pdfForms.signOperator'),
			value2: intlMsg('pdfForms.signCustomer'),
		},
		{
			value1: '',
			value2: '',
		},
	]

	// generate data row records value
	const dataBlockSignatureVALUE = [
		{
			value1: {},
			value2: {},
		},

		{
			value1: {
				record: intervention?.endingForm?.value?.operator?.signature,
				type: EFormFieldsPDF.signature,
			},
			value2: {
				record: intervention?.endingForm?.value?.client?.signature,
				clientSkipped: intervention?.endingForm?.value?.client?.clientSkipped
					? intlMsg('pdfForms.controlSkipped')
					: '',
				contactName: intervention?.endingForm?.value?.client?.contact,
				type: EFormFieldsPDF?.signature,
			},
		},
	]

	return {
		title: dataBlockSignatureTITLE,
		record: dataBlockSignatureVALUE,
		option: {},
	}
}
