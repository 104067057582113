import React from 'react'
import { Badge, Box } from '@mui/material'
import { useFormatMessage } from '../../../utils/hooks'

const tabLabelCSS = {
	display: 'flex',
	'& >span:first-of-type': {
		marginRight: ({ spacing }) => spacing(1),
	},
} as const

const anchorOriginTopRightCircleCSS = {
	'& .MuiBadge-badge': {
		right: 4,
		top: 8,
		position: 'relative',
		transform: 'scale(0.8) translate(50%, -50%)',
	},
}

interface IModalPanelTabPanel {
	label: string
	count?: number
}

const ModalPanelTabPanel: React.FC<IModalPanelTabPanel> = ({
	label,
	count,
}) => {
	const intlMsg = useFormatMessage()

	return (
		<Box sx={tabLabelCSS}>
			{count ? (
				<Badge
					badgeContent={count}
					color='primary'
					sx={anchorOriginTopRightCircleCSS}
					max={999}
				>
					<>{intlMsg(label)}</>
				</Badge>
			) : (
				intlMsg(label)
			)}
		</Box>
	)
}

export default ModalPanelTabPanel
