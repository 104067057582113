/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'

import {
	useBulkSiteAction,
	useImportSite,
} from '../../../api/gql/mutations/customerSitesMutations'
import {
	LIST_SITES,
	useExportSites,
} from '../../../api/gql/queries/sitesQueries'
import FormBtnModal from '../../../components/forms/formModalBtn'
import { listSitesTableImport } from '../../../components/tableV2/config/dashboard/customerSites/listSitesTableImport'
import listSitesViewTable from '../../../components/tableV2/config/dashboard/customerSites/listSitesViewTable'
import TableSiteImportForm from '../../../components/tableV2/tableToolBar/tableImport/tableImportForms/TableSiteImportForm'
import { EFormModalMode, EPermissionScope } from '../../../utils/app-models'
import {
	ITableToolBarActionConfig,
	ITableToolBarConfig,
} from '../../../utils/typescript/tableType'
import {
	importSitesValidationSchema,
	siteValidationSchema,
} from '../../../utils/validationSchema'
import CustomerSiteModal from './customerSiteModal'

////////////////////////////////////////////////////////////////
// Global variable
////////////////////////////////////////////////////////////////

const queryConfig = {
	query: LIST_SITES,
	queryName: 'sites',
}

////////////////////////////////////////////////////////////////
// tableToolBarAction CONFIG
////////////////////////////////////////////////////////////////

const refreshQuerie = ['getSitesList']

const deletedBulk = {
	mutation: useBulkSiteAction,
	operation: 'remove',
	successMessageDelete: {
		singular: 'success.siteDeleted',
		plural: 'success.sitesDeleted',
	},
	messageConfirm: {
		title: 'confirmModal.deleteCustomersSiteTitle',
		message: 'confirmModal.deleteCustomersSiteMessage',
	},
	deletePermissions: [EPermissionScope.siteDelete, EPermissionScope.siteManage],
}

const tableToolBarActionConfig: ITableToolBarActionConfig = {
	refreshQuerie,
	deletedBulk,
}

////////////////////////////////////////////////////////////////
// TableToolBarHeader CONFIG
////////////////////////////////////////////////////////////////

const tableToolBarConfig: ITableToolBarConfig = {
	title: 'misc.sitesLabel',
	stickyToolBar: true,
	tableConfig: listSitesViewTable,
	localStorageName: 'listSitesViewTableFilter',
	exportConfig: { exportQuery: useExportSites },
	importConfig: {
		templateExcelName: 'sites',
		TableImportForm: TableSiteImportForm,
		columnsToImport: listSitesTableImport,
		watchThisFields: ['customer', 'importFile'],
		schemaValidation: importSitesValidationSchema,
		useMutationImport: useImportSite,
		otherInputForMutation: ['customer'],
		permissions: [EPermissionScope.siteManage],
		refetchQueries: [
			'getSitesList',
			'getSitesNumbers',
			'getSite',
			'getCustomer',
		],
	},
	queryConfig,
	modalButton: {
		Btn: (
			<FormBtnModal
				modal={<CustomerSiteModal modalUtils={{} as any} />}
				mode={EFormModalMode.create}
			/>
		),
	},
	validationSchemaFunction: siteValidationSchema,
	createPermission: [EPermissionScope.siteManage],
}

////////////////////////////////////////////////////////////////
// tableContainer CONFIG
////////////////////////////////////////////////////////////////

const tableContainerConfig = {
	tableConfig: listSitesViewTable,
	queryConfig,
}

////////////////////////////////////////////////////////////////
// -> EXPORT CONFIG MAIN TABLE
////////////////////////////////////////////////////////////////

export const customerSiteConfigTable = {
	tableToolBarConfig,
	tableToolBarActionConfig,
	tableContainerConfig,
	displaySwitchOption: true,
}
