import React, { useCallback, useContext } from 'react'
import EditIcon from '@mui/icons-material/Edit'

import { ModalContext } from '../../../../utils/contexts/ModalContext'
import ModalNetwork from '.'

interface IEditNetworkButton {
	defaultValue
	setSelectedNetworkId
	setNetworkIsActive
}

const EditNetworkButton: React.FC<IEditNetworkButton> = ({
	defaultValue,
	setSelectedNetworkId,
	setNetworkIsActive,
}) => {
	const { openModal, isVisible } = useContext(ModalContext)

	const handleEditCategory = useCallback(() => {
		openModal()
	}, [])

	return (
		<>
			<EditIcon
				data-test-id={`Playwright-EditNetworkButton-edit-${defaultValue.name}`}
				style={{ fontSize: 18 }}
				className='edit'
				onClick={handleEditCategory}
			/>

			{isVisible && (
				<ModalNetwork
					defaultValue={defaultValue}
					setSelectedNetworkId={setSelectedNetworkId}
					setNetworkIsActive={setNetworkIsActive}
				/>
			)}
		</>
	)
}

export default EditNetworkButton
