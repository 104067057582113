import React, { useCallback, useMemo } from 'react'

import MuiTextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'

interface ITextField {
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
	value: string
	label: string
	multiline?: boolean
	rowsMax?: number
	rows?: number
	isrequired?: boolean
	onBlur?: () => void
	isDisabled?: boolean
	InputLabelProps?: any
	error?: string | null
	customHelper?: string
	withIcon?: React.ReactNode
	size?: 'medium' | 'small'
	type?: string
	hidden?: boolean
	name?: string
}

const TextField: React.FC<ITextField> = ({
	onChange,
	value,
	label,
	multiline = false,
	rows = 0,
	rowsMax = 4,
	isrequired,
	onBlur,
	isDisabled = false,
	InputLabelProps = null,
	error = null,
	customHelper,
	type,
	withIcon,
	size = 'small',
	hidden = false,
	name,
}) => {
	const textFieldSx = useMemo(
		() => ({
			opacity: isDisabled ? '0.5' : '1',
			display: hidden ? 'none' : 'inherit',
		}),
		[isDisabled, hidden]
	)

	const preventDefault = useCallback(
		(e: React.SyntheticEvent) => e.preventDefault(),
		[]
	)

	const inputProps = useMemo(() => {
		if (withIcon)
			return {
				startAdornment: (
					<InputAdornment position='start'>{withIcon}</InputAdornment>
				),
			}

		return undefined
	}, [withIcon])

	const helperText = useMemo(() => {
		if (error) return error === 'noDisplay' ? '' : error

		return customHelper || ''
	}, [error, customHelper])

	return (
		<MuiTextField
			name={name}
			value={value}
			onClick={preventDefault}
			onChange={onChange}
			InputLabelProps={InputLabelProps || { shrink: !!value }}
			multiline={multiline}
			minRows={rows}
			maxRows={rowsMax}
			error={!!error}
			label={`${label} ${isrequired ? '*' : ''}`}
			variant='outlined'
			helperText={helperText}
			InputProps={inputProps}
			size={size}
			disabled={isDisabled}
			fullWidth
			type={type || 'text'}
			onBlur={onBlur}
			sx={textFieldSx}
		/>
	)
}

export default TextField
