/* VehicleModalInfos.jsx
This component serves as an information modal for vehicles within a form context. It utilizes a combination of form elements
and custom hooks to manage and validate vehicle-related data, such as registration (immatriculation), brand, and type. It also
includes conditionally rendered subcomponents for handling vehicle identification numbers (VIN), customer information for asynchronous
sites, and date pickers for entry logging. The modal supports different modes, including creation and editing of vehicle entries, with
dynamic validation based on the presence of key vehicle data. */

import React, { useContext, useState, useEffect, useMemo } from 'react'

import Grid from '@mui/material/Grid'

import SkeletonTable from '../../../../../components/skeleton/SkeletonTable'
import { useGetVehicleModalInfoModalData } from '../../../../../api/gql/queries/vehiclesQueries'

import { useFormatMessage } from '../../../../../utils/hooks'
import { FormModalContext } from '../../../../../components/forms/formModal/FormModalContext'
import VinAndImmatManager from './VinAndImmatManager'
import CustomersForAsyncSites from './CustomersForAsyncSites'
import FormDatePicker from '../../../../../components/forms/formFields/FormDatePicker'
import VehicleModalTitle from './VehicleModalTitle'
import VehicleLocationFormModal from './VehicleLocationFormModal'
import FormSelectSearch from '../../../../../components/forms/formFields/formSelectSearch'
import FormTextField from '../../../../../components/forms/formFields/FormTextField'
import { EFormModalMode } from '../../../../../utils/app-models'
import translateIntlMsgString from '../../../../../utils/translateIntlMsgString'

import DeboucedFormTextField from '../../../../../components/forms/formFields/DebouncedFormTextField'
import { Box } from '@mui/material'

const wrapperModalCSS = {
	p: 4,
	overflow: 'auto',
}

interface IVehicleModalInfos {}

/* This component manages the display and data interaction of a modal 
 dedicated to vehicle information within a form context. */
const VehicleModalInfos: React.FC<IVehicleModalInfos> = () => {
	const intlMsg = useFormatMessage()

	const { mode, setCanValidate, data } = useContext(FormModalContext)

	// State to manage the display of certain components based on the presence of 'immat' or 'vin'.
	const [canDisplayRest, setCanDisplayRest] = useState(data?.immat || data?.vin)

	const { vehicleCarburants, vehicleCategories, vehicleBrands } =
		useGetVehicleModalInfoModalData()

	const vehicleBrandsOptions = useMemo(
		() =>
			vehicleBrands?.map((vehicleBrand) => ({
				key: vehicleBrand.id,
				label: vehicleBrand.name,
				value: vehicleBrand,
			})) || [],
		[vehicleBrands]
	)

	const vehicleCategoriesOptions = useMemo(
		() =>
			vehicleCategories?.map((vehicleType) => ({
				key: vehicleType.id,
				label: translateIntlMsgString(`vehicle.${vehicleType?.code}`),
				value: vehicleType,
			})) || [],
		[vehicleCategories]
	)

	const vehicleCarburantsOptions = useMemo(
		() =>
			vehicleCarburants?.map((vehicleCarburant) => ({
				key: vehicleCarburant.id,
				label: translateIntlMsgString(`vehicle.${vehicleCarburant?.code}`),
				value: vehicleCarburant,
			})) || [],
		[vehicleCarburants]
	)

	// Effect to allow form validation if the state 'canDisplayRest' is true.
	useEffect(() => {
		setCanValidate(canDisplayRest)
	}, [canDisplayRest])

	return (
		<Box sx={wrapperModalCSS}>
			<VehicleModalTitle canDisplayRest={canDisplayRest} />
			<Grid container spacing={5}>
				<Grid item xs={12} lg={6}>
					<Grid container spacing={2}>
						<VinAndImmatManager
							setCanDisplayRest={setCanDisplayRest}
							canDisplayRest={canDisplayRest}
						/>
						{!canDisplayRest ? (
							<Grid item xs={12}>
								<SkeletonTable nbRow={4} />
							</Grid>
						) : (
							<>
								<CustomersForAsyncSites />
								{mode === EFormModalMode.create && (
									<Grid item xs={12}>
										<FormDatePicker
											name='dateParkEntry'
											label={intlMsg('misc.dateParkEntry')}
											views={['day']}
											context={FormModalContext}
										/>
									</Grid>
								)}
								<Grid item lg={12}>
									<VehicleLocationFormModal />
								</Grid>
							</>
						)}
					</Grid>
				</Grid>

				<Grid item xs={12} lg={6}>
					<Grid container spacing={2}>
						{!canDisplayRest ? (
							<Grid item xs={12}>
								<SkeletonTable nbRow={5} />
							</Grid>
						) : (
							<>
								<Grid item xs={12}>
									<FormSelectSearch
										name='vehicleBrand'
										label={intlMsg('vehicle.brand')}
										selectElements={vehicleBrandsOptions}
										context={FormModalContext}
										missingText={intlMsg('misc.noResultSearch')}
									/>
								</Grid>
								<Grid item xs={12}>
									<DeboucedFormTextField
										name='vehicleModelText'
										label={intlMsg('vehicle.model')}
										context={FormModalContext}
									/>
								</Grid>

								<Grid item xs={12}>
									<FormSelectSearch
										name='vehicleCategory'
										label={intlMsg('vehicle.vehicleCategory')}
										selectElements={vehicleCategoriesOptions}
										context={FormModalContext}
										missingText={intlMsg('misc.noResultSearch')}
									/>
								</Grid>

								<Grid item xs={12}>
									<FormTextField
										name='designation'
										label={intlMsg('vehicle.designation')}
										context={FormModalContext}
									/>
								</Grid>

								<Grid item xs={6}>
									<FormSelectSearch
										name='vehicleCarburant'
										label={intlMsg('vehicle.energy')}
										selectElements={vehicleCarburantsOptions}
										missingText={intlMsg('misc.noResultSearch')}
										context={FormModalContext}
										displayColorInput
										disabledSort
									/>
								</Grid>
							</>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Box>
	)
}

export default VehicleModalInfos
