/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
	gql,
	OperationVariables,
	QueryHookOptions,
	useLazyQuery,
	useQuery,
} from '@apollo/client'
import {
	formTemplateAttributes,
	listFormTemplateAttributes,
} from '../attributes/formTemplateAttributes'

export const GET_FORM_TEMPLATE = gql`
query getFormTemplate(
    $id: ID!
) {
    formTemplate(id: $id) {
        ${formTemplateAttributes}
    }
}`

export const FORM_TEMPLATE_FILTER_LIST = gql`
query getFormTemplateFilterList(
    $page: Int
    $itemsPerPage: Int
    $searchText: String
    $isActive: Boolean
	$isDeleted: Boolean
	$customer: String
	$orderBy: [FormTemplateFilter_orderBy]
) {
    formTemplates(page: $page, itemsPerPage: $itemsPerPage, searchText: $searchText, isActive: $isActive, isDeleted: $isDeleted, customer: $customer, orderBy: $orderBy) {
        ${listFormTemplateAttributes}
    }
}`

export const FROM_TEMPLATE_NUMBERS = gql`
	query getFormTemplatesNumbers(
		$searchText: String
		$isActive: Boolean
		$isDeleted: Boolean
	) {
		formTemplates(
			page: 1
			itemsPerPage: 100000
			searchText: $searchText
			isActive: $isActive
			isDeleted: $isDeleted
		) {
			paginationInfo {
				totalCount
			}
		}
	}
`

export const useLazyFormTemplate = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { data, loading }] = useLazyQuery(GET_FORM_TEMPLATE, options)

	return {
		loadingFormTemplateQuery: loading,
		getFormTemplate: getData,
		formTemplate: data?.formTemplate || null,
	}
}

export const useFormTemplates: any = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { loading, data } = useQuery(FORM_TEMPLATE_FILTER_LIST, options)

	return {
		loading,
		formTemplates: data?.formTemplates?.collection || null,
	}
}
