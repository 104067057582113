/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import translateIntlMsgString from '../../../../../utils/translateIntlMsgString'

export const listVehiclesTableImport = [
	{
		colName: translateIntlMsgString('importTable.immat'),
		argumentName: 'immat',
		type: 'string',
		required: true,
		conditionalRequire: translateIntlMsgString('importTable.vin'),
	},
	{
		colName: translateIntlMsgString('importTable.brand'),
		argumentName: 'vehicleBrand',
		type: 'string',
	},
	{
		colName: translateIntlMsgString('importTable.model'),
		argumentName: 'vehicleModelText',
		type: 'string',
	},
	{
		colName: translateIntlMsgString('importTable.dateImmat'),
		argumentName: 'dateMec',
		type: 'date',
	},
	{
		colName: translateIntlMsgString('importTable.commercialName'),
		argumentName: 'designation',
		type: 'string',
	},
	{
		colName: translateIntlMsgString('importTable.vin'),
		argumentName: 'vin',
		type: 'string',
		required: true,
		conditionalRequire: translateIntlMsgString('importTable.immat'),
	},
	{
		colName: translateIntlMsgString('importTable.bodywork'),
		argumentName: 'vehicleType', //
		type: 'string',
	},
	{
		colName: translateIntlMsgString('importTable.vehicleType'),
		argumentName: 'vehicleCategory',
		type: 'string',
	},
	{
		colName: translateIntlMsgString('importTable.fuel'),
		argumentName: 'vehicleCarburant',
		type: 'string',
	},
	{
		colName: translateIntlMsgString('importTable.transmission'),
		argumentName: 'transmission',
		type: 'string',
	},
	{
		colName: translateIntlMsgString('importTable.powerCH'),
		argumentName: 'horsePower',
		type: 'int',
	},
	{
		colName: translateIntlMsgString('importTable.powerCV'),
		argumentName: 'fiscPower',
		type: 'int',
	},
	{
		colName: translateIntlMsgString('importTable.displacement'),
		argumentName: 'cylindree',
		type: 'string',
	},
	{
		colName: translateIntlMsgString('importTable.nbDoors'),
		argumentName: 'nbDoors',
		type: 'int',
	},
	{
		colName: translateIntlMsgString('importTable.nbSeats'),
		argumentName: 'nbSeats',
		type: 'int',
	},
	{
		colName: translateIntlMsgString('importTable.ptac'),
		argumentName: 'ptac',
		type: 'string',
	},
	{
		colName: translateIntlMsgString('importTable.dimAv'),
		argumentName: 'tireSizeRear',
		type: 'string',
	},
	{
		colName: translateIntlMsgString('importTable.dimAr'),
		argumentName: 'tireSizeBack',
		type: 'string',
	},
	{
		colName: translateIntlMsgString('importTable.dateParcEntry'),
		argumentName: 'dateParcEntry',
		type: 'date',
	},
	{
		colName: translateIntlMsgString('importTable.dateParcExit'),
		argumentName: 'dateParcExit',
		type: 'date',
	},
]
