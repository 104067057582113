import React, {
	useState,
	useEffect,
	memo,
	useContext,
	useCallback,
	useMemo,
} from 'react'

import { useLazyQuery } from '@apollo/client'
import qs from 'qs'

import { TableContext } from '../../../../utils/contexts/TableContext'
import TableComponent from '../tableComponent'
import { FiltersContext } from '../../../filters/filtersContext'

interface IConnectedTableContainerProvider {
	queryConfig
	othersQueryParams?: any //allow to pass external and static queryParams
	onDataGetted?: (data: any) => void | null // allow to execute a function with getted data
	argumentQueryViewModal?: string
	height?: string
	colorBorderLeftSelected?: string
	stickyToolBar?: boolean
	disabledCheckbox?: boolean
	filteredTableConfig: any
}

const ConnectedTableContainerProvider: React.FC<
	IConnectedTableContainerProvider
> = ({
	queryConfig,
	othersQueryParams = null,
	onDataGetted = null,
	argumentQueryViewModal,
	height,
	colorBorderLeftSelected,
	stickyToolBar = false,
	disabledCheckbox = false,
	filteredTableConfig,
}) => {
	const {
		setVisibleRows,
		search,
		page,
		isInViewModal,
		currentOrderBy,
		setCurrentOrderBy,
		setLoader,
	} = useContext(TableContext) || {}
	const { filters } = useContext(FiltersContext)

	const [rowsPerPage, setRowsPerPage] = useState(10)

	const [getData, { data, loading }] = useLazyQuery(queryConfig.query, {
		fetchPolicy:
			search?.isActive || search.isActive === false
				? 'cache-and-network'
				: 'cache-first',
	})

	const currentOrderByName = useMemo(
		() => Object.keys(currentOrderBy || {})?.[0] || '',
		[currentOrderBy]
	)

	useEffect(() => {
		if (data && setVisibleRows) {
			setVisibleRows(data[queryConfig.queryName].collection)
		}
	}, [data, setVisibleRows, queryConfig])

	useEffect(() => {
		const { query, varValue } = qs.parse(location.search, {
			ignoreQueryPrefix: true,
		})

		const additionalQueryParam = othersQueryParams
			? { ...othersQueryParams }
			: {}

		if (isInViewModal && query && varValue) {
			argumentQueryViewModal
				? (additionalQueryParam[argumentQueryViewModal as string] = varValue)
				: (additionalQueryParam[query as string] = varValue)
		}

		getData({
			variables: {
				page: page,
				itemsPerPage: rowsPerPage,
				isActive: search?.isActive,
				searchText: search?.searchText,
				isDeleted: search?.isDeleted,
				orderBy: [currentOrderBy] || null,
				...filters,
				...additionalQueryParam,
			},
		})
	}, [
		page,
		rowsPerPage,
		search.isActive,
		filters,
		othersQueryParams,
		search?.searchText,
		currentOrderBy,
	])

	const count = data?.[queryConfig.queryName]?.paginationInfo?.totalCount || 0

	useEffect(() => {
		if (onDataGetted && !loading && data) onDataGetted(data)
		setLoader(loading)
	}, [onDataGetted, data, loading])

	const rows = data?.[queryConfig.queryName]?.collection || []

	const handleRequestSort = useCallback(
		({ orderBy }) => {
			setCurrentOrderBy({
				[orderBy]:
					currentOrderByName !== orderBy
						? 'asc'
						: currentOrderBy[currentOrderByName] === 'asc'
						? 'desc'
						: 'asc',
			})
		},
		[currentOrderByName, currentOrderBy]
	)

	return (
		<TableComponent
			height={height}
			colorBorderLeftSelected={colorBorderLeftSelected}
			stickyToolBar={stickyToolBar}
			disabledCheckbox={disabledCheckbox}
			data={data}
			filteredTableConfig={filteredTableConfig}
			handleRequestSort={handleRequestSort}
			rows={rows}
			count={count}
			rowsPerPage={rowsPerPage}
			setRowsPerPage={setRowsPerPage}
		/>
	)
}

export default memo(ConnectedTableContainerProvider)
