/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useCallback, useContext, useEffect, useMemo } from 'react'

import { Box, Grid } from '@mui/material'
import PhoneIcon from '@mui/icons-material/Phone'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

import { useFormatMessage } from '../../../../utils/hooks'
import { useGetCustomerListForSelect } from '../../../../api/gql/queries/customersQueries'
import {
	FormModalContext,
	IFormModalContext,
} from '../../../../components/forms/formModal/FormModalContext'
import DeboucedFormTextField from '../../../../components/forms/formFields/DebouncedFormTextField'
import FormTextField from '../../../../components/forms/formFields/FormTextField'
import FormCheckBoxOrSwitch from '../../../../components/forms/formFields/FormCheckBoxOrSwitch'
import FormSelectSearch from '../../../../components/forms/formFields/formSelectSearch'
import FormMultiSelectSearch from '../../../../components/forms/formFields/formMultiSelectSearch'
import { useLazySitesForSelect } from '../../../../api/gql/queries/sitesQueries'

interface ICustomerContactModalInfos {
	isLoading?: boolean
}

const canAccessMyContentToolTip = {
	text: 'user.shareUserLabelTooltips',
	icon: HelpOutlineIcon,
}

const isCustomerRespToolTip = {
	text: 'misc.tooltipCustomerResp',
	icon: HelpOutlineIcon,
}

const isSiteRespToolTip = {
	text: 'misc.tooltipSiteResp',
	icon: HelpOutlineIcon,
}
const wrapperModalCSS = {
	p: 4,
	overflow: 'auto',
}

const CustomerContactModalInfos: React.FC<ICustomerContactModalInfos> = () => {
	const intlMsg = useFormatMessage()
	const { data, setValues } = useContext<IFormModalContext>(FormModalContext)
	const { customers } = useGetCustomerListForSelect()
	const { getSites, sites } = useLazySitesForSelect()
	const selectedCustomer = useMemo(() => data['customer'], [data['customer']])

	const customersOptions = useMemo(
		() =>
			customers?.map((customer) => ({
				key: customer?.id,
				label: customer?.name,
				value: customer,
			})) || [],
		[customers]
	)

	const sitesOptions = useMemo(
		() =>
			sites.map((site) => ({
				key: site.id,
				label: site.name,
				value: site,
			})) || [],
		[sites]
	)

	useEffect(() => {
		if (selectedCustomer)
			getSites({ variables: { customer: selectedCustomer?.value?.id } })
	}, [selectedCustomer])

	//allow to delete selected sites when customer is changed
	const handleCustomerChange = useCallback(
		({ option }) => {
			setValues({ customer: option || '', sites: null })
		},
		[setValues]
	)

	return (
		<Box sx={wrapperModalCSS}>
			<Grid container spacing={5}>
				<Grid item xs={12} lg={6}>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<DeboucedFormTextField
								name='firstName'
								label={intlMsg('misc.firstname')}
								isRequired
								context={FormModalContext}
							/>
						</Grid>
						<Grid item xs={6}>
							<DeboucedFormTextField
								name='lastName'
								label={intlMsg('misc.lastname')}
								isRequired
								context={FormModalContext}
							/>
						</Grid>
						<Grid item xs={12}>
							<DeboucedFormTextField
								name='email'
								label={intlMsg('misc.email')}
								context={FormModalContext}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormTextField
								name='job'
								label={intlMsg('misc.job')}
								context={FormModalContext}
							/>
						</Grid>
						<Grid item xs={6}>
							<FormTextField
								name='phone'
								label={intlMsg('misc.phone')}
								context={FormModalContext}
								InputProps={{ startAdornment: <PhoneIcon /> }}
								InputLabelProps={{ shrink: true }}
							/>
						</Grid>
						<Grid item xs={6}>
							<FormTextField
								name='cellphone'
								label={intlMsg('misc.cellphone')}
								context={FormModalContext}
								InputProps={{ startAdornment: <PhoneAndroidIcon /> }}
								InputLabelProps={{ shrink: true }}
							/>
						</Grid>

						<Grid item xs={12}>
							<FormCheckBoxOrSwitch
								disabled={!data['email']}
								name='canAccessMyContent'
								label={intlMsg('user.shareUserLabel')}
								bordered
								context={FormModalContext}
								toolTip={canAccessMyContentToolTip}
							/>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={12} lg={6}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<FormSelectSearch
								name={'customer'}
								label={intlMsg('misc.account')}
								specialOnChange={handleCustomerChange}
								selectElements={customersOptions}
								context={FormModalContext}
								missingText={intlMsg('misc.noResultSearch')}
								isRequired
								trigerred
							/>
						</Grid>

						<Grid item xs={12}>
							<FormCheckBoxOrSwitch
								disabled={!data['customer']}
								name='isCustomerResp'
								label={intlMsg('misc.referentAccount')}
								bordered
								context={FormModalContext}
								toolTip={isCustomerRespToolTip}
							/>
						</Grid>

						<Grid item xs={12}>
							<FormMultiSelectSearch
								name='sites'
								isDisabled={!data['customer']}
								context={FormModalContext}
								options={sitesOptions}
								label={intlMsg('misc.sitesLabel')}
								selectAllText={intlMsg('misc.selectAll')}
								nbChipsToDisplay={2}
								isRequired
								trigerred
								bordered
							/>
						</Grid>

						<Grid item xs={12}>
							<FormCheckBoxOrSwitch
								disabled={!data['sites']}
								name='isSiteResp'
								label={intlMsg('misc.referentSite')}
								bordered
								context={FormModalContext}
								toolTip={isSiteRespToolTip}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	)
}

export default CustomerContactModalInfos
