import React, { cloneElement, useCallback } from 'react'

import { Stack } from '@mui/material'
import { IModalUtils, useModal } from '../../../../../utils/hooks'
import { EFormModalMode } from '../../../../../utils/app-models'
import BtnAddCircle from '../../../../buttons/BtnAddCircle'
import PermissionsHandler from '../../../../permissionsHandler'

interface ITableSideNavButtonCreate {
	modal
	permissions?
}

const TableSideNavButtonCreate: React.FC<ITableSideNavButtonCreate> = ({
	modal,
	permissions,
}) => {
	const modalUtils: IModalUtils = useModal(false)
	const handleEdit = useCallback(() => {
		modalUtils.openModal()
	}, [])

	return (
		<PermissionsHandler permissionsName={permissions}>
			<Stack
				direction='row'
				justifyContent='center'
				height='80px'
				mt={1}
				mb={2}
			>
				<BtnAddCircle
					action={handleEdit}
					playwrightId='Playwright-CreateCategory-BtnAddCircle'
				/>
			</Stack>
			{modalUtils?.isVisible &&
				cloneElement(modal, { modalUtils, mode: EFormModalMode.create })}
		</PermissionsHandler>
	)
}

export default TableSideNavButtonCreate
