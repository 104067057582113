// Init
const ALPHABET = 'abcdefghijklmnopqrstuvwxyz'

// Function to get th index of alphabet letter - ex : getIndexesInAlphabet("a") return 1
export const getIndexesInAlphabet = (chars: string): number => {
	if (chars) {
		return (chars as any).split('').map((char) => {
			const index = ALPHABET.indexOf(char.toLowerCase())
			return index + 1
		})[0]
	}
	return 4
}

// Function to display a max string exemple  : textLong...
export const maxStringDisplay = (
	string: string | number,
	maxString: number
): string => {
	const str = String(string) // conversion en chaîne de caractères
	if (str?.length <= maxString) {
		return str
	}
	return `${str?.substring(0, maxString)}...`
}

// Format address google map
export const formatAdressGoogleMap = (
	address: string,
	city: string,
	zipcode: string
): string => {
	const completeAddress = `${address ? `${address} ` : ''}${
		city ? `- ${city}` : ''
	}${zipcode ? ` - ${zipcode} ` : ''}`

	return `https://maps.google.com/?q=${completeAddress}`
}

// Filter search table
export const searchTable = (rows: [{ name: any }], search: string): [] => {
	return rows?.filter((row) => {
		if (search?.length as any) {
			return row?.name?.toLowerCase().includes(search?.toLowerCase()) as any
		}
		return rows as any
	}) as any
}

// Delete edge & node key

type TypeAny = {
	['key']: any
}

const toAvoid = ['collection', 'edges', 'node']
//used to clean queries results and avoid 'collection', 'edges', 'node'
export const formatGeneric = (
	obj: TypeAny,
	prevKey?: string,
	isSpecial?: boolean
): TypeAny => {
	let tmp: any = {}
	if (obj !== null && obj !== undefined && Array.isArray(obj)) {
		const arrayTmp = [...obj]
		obj.forEach((elem, index) => {
			arrayTmp[index] = formatGeneric(elem, prevKey, isSpecial)
		})
		if (prevKey === 'collection' && isSpecial) tmp[prevKey] = arrayTmp
		else tmp = arrayTmp
	} else if (obj !== null && obj !== undefined && typeof obj === 'object') {
		Object.keys(obj).forEach((key) => {
			if (key !== '__typename' && toAvoid.indexOf(key) < 0) {
				tmp[key] = formatGeneric(obj[key], key, isSpecial)
			} else if (key !== '__typename') {
				tmp = formatGeneric(obj[key], key, isSpecial)
			}
		})
	} else {
		tmp = obj
	}
	return tmp
}

// return array of object unique by key //////////////////////////
export const getArrOfUniqueObjKey = (arr, uniqueKey) => {
	const uniqueIds: any = []

	const unique = arr.filter((element) => {
		const isDuplicated = uniqueIds.includes(element[uniqueKey])

		if (!isDuplicated) {
			uniqueIds.push(element[uniqueKey])
			return true
		}
		return false
	})

	return unique
}
