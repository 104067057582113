/* eslint-disable @typescript-eslint/no-unused-vars */
import { EFormModalMode } from '../../../../../../utils/app-models'

export const formatCategoryProductBaseModalEditDefaultValues = (data) => {
	const { __typename, ...rest } = data
	return { ...rest }
}

export const formatCategoryProductBaseModalCreateDefaultValues = () => {
	return { isActive: true }
}

export const formatCategoryProductBaseModalOnValidateData = ({
	data,
	mode,
	categoryId,
	idModule,
}) => {
	const specificModeFields =
		mode === EFormModalMode.edit
			? { id: categoryId }
			: {
					isActive: true,
					module: idModule,
			  }
	return { ...specificModeFields, ...data }
}
