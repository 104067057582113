import React from 'react'
import Link from '@mui/material/Link'

interface IEmailCell {
	txt: string
}

const EmailCell: React.FC<IEmailCell> = ({ txt }) => {
	return (
		<>
			{txt ? (
				<Link href={`mailto:${txt}`} color='inherit' variant='caption'>
					{txt}
				</Link>
			) : (
				'-'
			)}
		</>
	)
}

export default EmailCell
