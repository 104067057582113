import React, { useCallback, useContext, useState } from 'react'
import dayjs from 'dayjs'

import { Stack, Grid, Button, Fade } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'

import { useFormatMessage } from '../../../../../../../utils/hooks'
import AddressBook from './AddressBook'
import {
	FormModalContext,
	IFormModalContext,
} from '../../../../../../../components/forms/formModal/FormModalContext'
import FormTextField from '../../../../../../../components/forms/formFields/FormTextField'
import FormSelectSearch from '../../../../../../../components/forms/formFields/formSelectSearch'
import FormDatePicker from '../../../../../../../components/forms/formFields/FormDatePicker'
import FormTimePicker from '../../../../../../../components/forms/formFields/FormTimePicker'
import FormMultiSelectSearch from '../../../../../../../components/forms/formFields/formMultiSelectSearch'
import { useTransportTripData } from './useTransportTripData'

const moreOptionsCSS = (isDisplayMoreOption) => ({
	padding: isDisplayMoreOption ? 'inherit' : '0px!important',
	height: isDisplayMoreOption ? 'inherit' : '0px!important',
})

interface ITransportTripForm {
	transportTripType: string
	isOrderEqualFinal: boolean
	setIsOrderEqualFinal: (param: any) => void
	isSharedTransport: boolean
}

const TransportTripForm: React.FC<ITransportTripForm> = ({
	transportTripType,
	isOrderEqualFinal,
	setIsOrderEqualFinal,
	isSharedTransport,
}) => {
	const intlMsg = useFormatMessage()
	const { setValue, setValues, data } =
		useContext<IFormModalContext>(FormModalContext)
	/////////////////////////////////////////////////////////
	// Options input
	/////////////////////////////////////////////////////////
	const { formTemplatesOptions, productCategoriesOptions, countriesOptions } =
		useTransportTripData()

	/////////////////////////////////////////////////////////
	// More/less options
	/////////////////////////////////////////////////////////

	const [isDisplayMoreOption, setIsDisplayMoreOption] = useState(false)

	const handleDisplayMoreOptions = useCallback(() => {
		setIsDisplayMoreOption(!isDisplayMoreOption)
	}, [isDisplayMoreOption])

	const handleSwitchOnchangeAddressValue = useCallback(
		({ fieldName, option }) => {
			setIsOrderEqualFinal({ delivery: false, removal: false })
			setValue(fieldName, option)
		},
		[setValue]
	)

	/////////////////////////////////////////////////////////
	// Handle change date
	/////////////////////////////////////////////////////////

	const handleChangeDate = useCallback(
		({ fieldName: currentDateNameSelected, date: currentDateSelected }) => {
			const valuesToUpdate = {
				[currentDateNameSelected]: currentDateSelected,
			} as any

			const isRemovalDateSelected = currentDateNameSelected === 'removalDate'

			const removalDate = dayjs(
				isRemovalDateSelected ? currentDateSelected : data?.removalDate
			).startOf('d')

			const deliveryDate = dayjs(
				isRemovalDateSelected ? data?.deliveryDate : currentDateSelected
			).startOf('d')

			if (
				dayjs(currentDateSelected)[
					isRemovalDateSelected ? 'isAfter' : 'isBefore'
				](isRemovalDateSelected ? deliveryDate : removalDate, 'm')
			) {
				valuesToUpdate[isRemovalDateSelected ? 'deliveryDate' : 'removalDate'] =
					currentDateSelected
			}

			setValues(valuesToUpdate)
		},
		[setValues, data]
	)

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={7}>
				<Stack spacing={1.5} paddingTop={isSharedTransport ? 1 : 0}>
					{!isSharedTransport && (
						<Stack
							direction='row'
							alignItems='center'
							justifyContent='flex-end'
							height={31}
						>
							{!isSharedTransport && (
								<AddressBook
									transportTripType={transportTripType}
									isOrderEqualFinal={isOrderEqualFinal}
								/>
							)}
						</Stack>
					)}

					<FormTextField
						name={`${transportTripType}Name`}
						label={intlMsg('interventions.nameOrSocialName')}
						context={FormModalContext}
						isDisabled={isSharedTransport}
					/>

					<FormTextField
						name={`${transportTripType}Address`}
						label={intlMsg('misc.address')}
						context={FormModalContext}
						specialOnChange={handleSwitchOnchangeAddressValue}
						isDisabled={isSharedTransport}
						isRequired
						trigerred
					/>

					<Stack spacing={1.5} direction='row'>
						<FormTextField
							name={`${transportTripType}Zipcode`}
							label={intlMsg('misc.zipCode')}
							context={FormModalContext}
							specialOnChange={handleSwitchOnchangeAddressValue}
							isDisabled={isSharedTransport}
							isRequired
							trigerred
						/>
						<FormTextField
							name={`${transportTripType}City`}
							label={intlMsg('misc.city')}
							context={FormModalContext}
							specialOnChange={handleSwitchOnchangeAddressValue}
							isDisabled={isSharedTransport}
							isRequired
							trigerred
						/>
						<FormSelectSearch
							name={`${transportTripType}Country`}
							label={intlMsg('misc.country')}
							selectElements={countriesOptions}
							context={FormModalContext}
							missingText={intlMsg('misc.noResultSearch')}
							specialOnChange={handleSwitchOnchangeAddressValue}
							isDisabled={isSharedTransport}
							isRequired
							trigerred
						/>
					</Stack>
					<Stack spacing={1.5} direction='row'>
						<FormTextField
							name={`${transportTripType}ContactText`}
							isDisabled={isSharedTransport}
							label={intlMsg('misc.contact')}
							context={FormModalContext}
						/>
						<FormTextField
							name={`${transportTripType}ContactPhone`}
							isDisabled={isSharedTransport}
							label={intlMsg('misc.phone')}
							context={FormModalContext}
						/>
					</Stack>
				</Stack>
			</Grid>

			<Grid item xs={12} md={5} mt={isSharedTransport ? 1 : 5.3}>
				<Stack spacing={1.5}>
					<FormDatePicker
						name={`${transportTripType}Date`}
						specialOnChange={handleChangeDate}
						label={intlMsg(`interventions.${transportTripType}Date`)}
						views={['day']}
						isStartOfDay={false}
						conserveHour
						context={FormModalContext}
						trigerred
					/>

					<Stack spacing={1.5} direction='row'>
						<FormTimePicker
							name={`${transportTripType}TimeFrom`}
							label={intlMsg('interventions.hourStart')}
							context={FormModalContext}
							trigerred
						/>
						<FormTimePicker
							name={`${transportTripType}TimeTo`}
							label={intlMsg('interventions.hourEnd')}
							context={FormModalContext}
							trigerred
						/>
					</Stack>
				</Stack>
			</Grid>

			<Grid item xs={12}>
				<FormMultiSelectSearch
					name={`${transportTripType}FormTemplates`}
					options={formTemplatesOptions}
					context={FormModalContext}
					label={intlMsg('misc.reportIntervention')}
					selectAllText={intlMsg('misc.selectAll')}
					nbChipsToDisplay={4}
					isDisabled={isSharedTransport}
					bordered
				/>

				<Button
					data-test-id={`Playwright-${transportTripType}MoreOption`}
					sx={{ mt: 2, mb: 1 }}
					size='small'
					color='primary'
					endIcon={
						!isDisplayMoreOption ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />
					}
					onClick={handleDisplayMoreOptions}
				>
					{intlMsg(
						`misc.${!isDisplayMoreOption ? 'moreOptions' : 'lessOptions'}`
					)}
				</Button>
			</Grid>

			<Grid item xs={12} sx={moreOptionsCSS(isDisplayMoreOption)}>
				<Fade in={isDisplayMoreOption} timeout={0}>
					<Stack spacing={1.5}>
						<FormMultiSelectSearch
							isDisabled={isSharedTransport}
							name={`${transportTripType}Products`}
							options={productCategoriesOptions}
							context={FormModalContext}
							label={intlMsg('interventions.task')}
							selectAllText={intlMsg('misc.selectAll')}
							groupBy='categoryName'
							nbChipsToDisplay={4}
							messageTopModal='Les services sélectionnés s’appliquent à tous les véhicules de
							l’intervention.'
							bordered
						/>

						<FormTextField
							isDisabled={isSharedTransport}
							name={`${transportTripType}Instruction`}
							label={intlMsg(`interventions.${transportTripType}Instruction`)}
							context={FormModalContext}
							multiline={true}
							minRows={3}
							maxRows={3}
						/>
					</Stack>
				</Fade>
			</Grid>
		</Grid>
	)
}
export default TransportTripForm
