import React, { useContext } from 'react'
import { Box } from '@mui/material'

import TableView from './tableView'
import TableMainNav from './tableMainNav'
import TableSideNav from './tableSideNav'
import { MultiNavTableContext } from '../MultiNavTableProvider'

const containerCSS = {
	width: 1,
	height: 'calc(100% - 48px)',
	display: 'flex',
	flexDirection: { xs: 'column', md: 'row' },
}

interface IMultiTableNavView {}

const MultiTableNavView: React.FC<IMultiTableNavView> = () => {
	const {
		sideNavSelectedId,
		tableViewConfig,
		tableSideNavConfig,
		queryDataSideNav,
		loading,
		sideNavIsActive,
	} = useContext(MultiNavTableContext) || {}

	return (
		<>
			<TableMainNav />
			<Box sx={containerCSS}>
				<TableSideNav
					tableSideNavConfig={tableSideNavConfig}
					queryDataSideNav={queryDataSideNav}
					loading={loading}
					sideNavIsActive={sideNavIsActive}
				/>
				<TableView
					sideNavSelectedId={sideNavSelectedId}
					tableViewConfig={tableViewConfig}
				/>
			</Box>
		</>
	)
}

export default MultiTableNavView
