export default {
	userAlreadyExists: 'This user already exists.',
	accountSirenAlreadyExists: 'This SIREN number already exists.',
	wrongPassword: 'Incorrect password.',
	passwordsDoNotMatch: 'Passwords do not match.',
	passwordsDoNotRegex: 'The password does not meet the required format.',
	genericError:
		'An error occurred, please contact support if the error persists.',
	vehicleAlreadyOwned: 'This vehicle already exists in one of your sites.',
	vehiclesNeedFusion:
		'The entered information corresponds to 2 different vehicles.',
	twoVehiclesAlreadyExist:
		'2 vehicles found. The VIN and license plate do not match the same vehicle. Check your information or contact support.',
	vehicleImmatTooLong:
		'The vehicle license plate must not exceed 10 characters.',
	vehicleDoNotExists: 'This vehicle does not exist',
	invalidImmat: 'Invalid license plate',
	formTemplateFieldControlList:
		'A "control" field must have at least 2 choices.',
	formTemplateFieldSelectionsList:
		'A "selection" field must have at least 1 option.',
	errorExcel: 'Import error. Check that the import Excel file is correct.',
	errorExcelRequiredValues: 'Please fill in all required fields',
	errorDateSup: 'The end date cannot be earlier than the start date',
	dateInvalid: 'Invalid date',
	invalidCredentials: 'Invalid credentials',
	userNotEnabled:
		'This user is not enabled. To activate your account, follow the link sent to you in a previous invitation email.',
	error404: 'This page is unknown',
	errorTypeFilesCSV: 'Only .csv, .xls, and .xlsx files are allowed',
	accountSiretAlreadyExists: 'This agency already exists in this network',
	errorAddFileInvoice: 'Error importing the invoice',
	errorFileIsCorrupted: 'The file is corrupted',
	errorCreateInvoice: 'Error creating the expense',
	errorUpdateInvoice: 'Error updating the expense',
	plateOrVINMandatory: 'License plate or VIN field is mandatory',
	brandAndModelMandatory: 'Brand and model field is mandatory',
	formatDateMec: 'The date is not in the correct format',
	minCharacters: 'Minimum {minCharacters} characters',
	contactAlreadyRegistered:
		'This contact is already registered on the platform',
	errorCreateDocumentVehicle: 'Error creating the document',
	errorUpdateDocumentVehicle: 'Error updating the document',
	errorAddFileDocumentVehicle: 'Error importing the document',
	customerAlreadyExists: 'The SIRET of this customer account already exists',
}
