export const listDocumentsVehiclesAttributes = `
paginationInfo {
    totalCount
},
collection{
  id
  reference
  file
  createdBy {
    id
    firstName
    lastName
    account{
      id
    }
  }
  dateCreated
  vehicle {
    id
    vehicleDetail {
      id
      immat
      vin
    }
  }
  intervention {
    id
    reference
    interventionType
    orderingCustomer {
      name
      id
    }
  }
  category {
    id
    code
  }
}
`
export const documentVehicleForModalAttributes = `
id
reference
dateCreated
file
vehicle {
  id
  vehicleDetail {
    id
    immat
    vin
  }
}
intervention {
  id
  reference
  interventionType
}
category {
  id
  code
}
`
