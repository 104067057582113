import React from 'react'

import TabPanelContactInformation from './../panel/TabPanelContactInformation'

const SlidePanelContactInfosConfig = (dataContact: {}): [{}] => [
	{
		label: 'misc.information',
		component: <TabPanelContactInformation dataContact={dataContact} />,
	},
]

export default SlidePanelContactInfosConfig
