import React, { useCallback, useContext } from 'react'

import { Button, Stack } from '@mui/material'

import { useFormatMessage } from '../../../utils/hooks'
import { footerModalCSS } from '../../forms/modalSxCss'
import { FiltersBtnContext } from './FiltersBtnContext'
import { FiltersContext } from '../filtersContext'

const stackCSS = { width: 1 }
const ButtonCSS = { mr: 1 }

interface IFooterPopover {}

const FooterPopover: React.FC<IFooterPopover> = () => {
	const intlMsg = useFormatMessage()
	const { handleClose, tmpFilters } = useContext(FiltersBtnContext)
	const { setFilters, resetFilters } = useContext(FiltersContext)

	const onValidate = useCallback(() => {
		setFilters(tmpFilters)
		handleClose()
	}, [tmpFilters])

	const onReset = useCallback(() => {
		resetFilters()
		handleClose()
	}, [])

	return (
		<Stack direction='row' spacing={2} sx={footerModalCSS}>
			<Stack
				direction='row'
				justifyContent='space-between'
				spacing={1}
				sx={stackCSS}
			>
				<Button
					variant='outlined'
					color='primary'
					onClick={onReset}
					sx={ButtonCSS}
				>
					{intlMsg('misc.resetAll')}
				</Button>

				<Button variant='contained' onClick={onValidate}>
					{intlMsg('misc.validate')}
				</Button>
			</Stack>
		</Stack>
	)
}

export default FooterPopover
