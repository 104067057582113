import React, { useCallback, useContext, useMemo } from 'react'

import { Typography, Stack, Button, Popover, IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DeleteIcon from '@mui/icons-material/Delete'

import {
	IModalUtils,
	useFormatMessage,
	useModal,
} from '../../../../../../../../utils/hooks'
import { FormModalContext } from '../../../../../../../../components/forms/formModal/FormModalContext'
import CustomChipColor from '../../../../../../../../components/chip/CustomChipColor'
import VehicleModal from '../../../../../../vehicles/vehicleModal'
import { EFormModalMode } from '../../../../../../../../utils/app-models'
import { VehiclesTransportContext } from '../VehiclesTransportProvider'
import ActionBtn from '../../../../../../../../components/tableV2/tableCell/actionsBtn/ActionBtn'

interface IVehicleAssignedDisplay {
	isSharedTransport: boolean
}

const btnSx = ({ colors }) => ({ color: colors.actions.disable })

const vehicleAssignedDisplayTypography =
	(isSharedTransport) =>
	({ colors }) => ({
		'& >p': {
			color: isSharedTransport ? colors.actions.disable : '',
		},
	})

const VehicleAssignedDisplay: React.FC<IVehicleAssignedDisplay> = ({
	isSharedTransport,
}) => {
	const intlMsg = useFormatMessage()
	const modalUtils: IModalUtils = useModal(false)
	const { data } = useContext(FormModalContext)

	/////////////////////////////////////////////////////////
	// Btn more action Popover remove/edit
	/////////////////////////////////////////////////////////

	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

	const handleClick = useCallback(
		(event: React.MouseEvent<HTMLButtonElement>) => {
			setAnchorEl(event.currentTarget)
		},
		[]
	)

	const handleClose = useCallback(() => {
		setAnchorEl(null)
	}, [])

	const open = useMemo(() => Boolean(anchorEl), [anchorEl])

	/////////////////////////////////////////////////////////
	// edit/remove actions
	/////////////////////////////////////////////////////////

	const { onClearVehicle, getResponseVehicleMutation } = useContext(
		VehiclesTransportContext
	)

	const handleEditClick = useCallback(() => {
		modalUtils.openModal()
		handleClose()
	}, [])

	return data?.selectedVehicles?.map(
		({ id, immat, vin, vehicleModelText, vehicleBrand, vehicleCarburant }) => (
			<div key={id}>
				<Stack
					direction='row'
					alignItems='center'
					justifyContent='space-between'
					sx={vehicleAssignedDisplayTypography(isSharedTransport)}
				>
					<Typography>{immat || intlMsg('vehicle.immat')}</Typography>

					<Typography>{vin || intlMsg('vehicle.vin')}</Typography>

					{vehicleBrand ? (
						<Typography>{vehicleBrand}</Typography>
					) : (
						<Button
							onClick={handleEditClick}
							size='small'
							variant='text'
							endIcon={<EditIcon />}
							sx={{ color: '#EF443E' }}
							disabled={isSharedTransport}
						>
							{intlMsg('vehicle.brand')}
						</Button>
					)}

					{vehicleModelText ? (
						<Typography>{vehicleModelText}</Typography>
					) : (
						<Button
							disabled={isSharedTransport}
							onClick={handleEditClick}
							size='small'
							variant='text'
							endIcon={<EditIcon />}
							sx={{ color: '#EF443E' }}
						>
							{intlMsg('vehicle.model')}
						</Button>
					)}

					{vehicleCarburant?.code ? (
						<CustomChipColor
							label={intlMsg(`vehicle.${vehicleCarburant.code}`)}
							Icon={LocalGasStationIcon}
							color={vehicleCarburant?.color}
						/>
					) : (
						<Button
							disabled={isSharedTransport}
							onClick={handleEditClick}
							size='small'
							variant='text'
							endIcon={<EditIcon />}
							sx={btnSx}
						>
							{intlMsg('vehicle.energy')}
						</Button>
					)}

					{!isSharedTransport && (
						<IconButton
							aria-describedby={id}
							onClick={handleClick}
							data-test-id={`Playwright-TransportVehicleMoreAction`}
						>
							<MoreVertIcon />
						</IconButton>
					)}
				</Stack>

				{modalUtils.isVisible && (
					//TODO REFACTO NEEDED HERE !!
					//we need to put VehicleModal outside this map !!!
					<VehicleModal
						key={`${id}Modal`}
						editedVehicleId={id}
						modalUtils={modalUtils}
						mode={EFormModalMode.edit}
						getResponseVehicleMutation={getResponseVehicleMutation}
					/>
				)}

				<Popover open={open} anchorEl={anchorEl} onClose={handleClose}>
					<ActionBtn
						onClick={handleEditClick}
						txtIntlMsg='misc.edit'
						Icon={EditIcon}
					/>
					<ActionBtn
						onClick={onClearVehicle}
						txtIntlMsg='misc.remove'
						Icon={DeleteIcon}
						dataTestId='Playwright-TransportRemoveVehicle'
					/>
				</Popover>
			</div>
		)
	)
}
export default VehicleAssignedDisplay
