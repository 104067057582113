export default {
	btnDownload: "Télécharger l'export",

	messageInfo: ' Votre export est en cours',
	messageError: "Une erreur est survenue lors de l'export",
	messageSuccess: 'Votre export est prêt',
	messageWarning: "Vous n'avez aucune donnée à exporter",

	DialogTitle: "Annulation de l'export",
	DialogContentText: "Etes-vous sûr de vouloir annuler l'export",

	cancelExport: "Annuler l'export",
	alertCurrentExport: "Un export est déjà en cours ou attend d'être téléchargé",
}
