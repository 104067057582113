import React, { cloneElement, useContext, useMemo } from 'react'

import { Box } from '@mui/material'

import { FormModalContext } from '../FormModalContext'
import LogoConnectIcon from '../../../../svgIcons/LogoConnectIcon'

const boxCSS = {
	height: '72vh',
	'& img': {
		height: '15vh !important',
	},
}

interface IFormModalContents {}

const FormModalContents: React.FC<IFormModalContents> = () => {
	const {
		loading,
		selectedTab,
		formModalConfig: { formModalTabConfig, modalStyle },
	} = useContext(FormModalContext)

	const wrapperModalCSS = useMemo(
		() => ({
			display: 'flex',
			flexDirection: 'column',
			width: 1,
			justifyContent: loading ? 'center' : 'start',
		}),
		[loading]
	)

	const { minHeight, height, maxHeight } = modalStyle || {}
	const formModalContentsSx = useMemo(
		() =>
			height
				? {
						...wrapperModalCSS,
						height: height,
				  }
				: {
						...wrapperModalCSS,
						minHeight: minHeight || '60vh',
						maxHeight: maxHeight || '60vh',
				  },
		[minHeight, height, maxHeight, wrapperModalCSS]
	)

	return (
		<Box sx={formModalContentsSx}>
			{loading ? (
				<Box sx={boxCSS}>
					<LogoConnectIcon
						height='100%'
						data-test-id='Playwright-FormModalContents-isLoading'
					/>
				</Box>
			) : (
				<>{cloneElement(formModalTabConfig[selectedTab]?.component)}</>
			)}
		</Box>
	)
}

export default FormModalContents
