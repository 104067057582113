import React, { useCallback } from 'react'

import CustomLink from '../../../_overrideMui/Link/CustomLink'

interface IDataListExternalLink {
	text: string
	link
}

const DataListExternalLink: React.FC<IDataListExternalLink> = ({
	text,
	link,
}) => {
	const openLink = useCallback(() => {
		link?.url && window.open(link?.url, link?.target ?? '_blank')
	}, [link])

	return (
		<CustomLink
			variant='text1Bold'
			variantlink='primaryLink'
			onMouseDown={openLink}
			disabled={!text || !link?.url}
		>
			{(link?.url && text) || '-'}
		</CustomLink>
	)
}

export default DataListExternalLink
