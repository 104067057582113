import React from 'react'

import { EViewModal, dataListType } from '../../../../utils/app-models'
import DataList from '../../../../components/list/dataList'
import DataListGrid from '../../../../components/list/dataList/DataListGrid'
import VmCardExpended from '../../../../components/viewModal/vmCardExpended'

interface IVehicleInfos {
	ipv
}

const VehicleInfos: React.FC<IVehicleInfos> = ({ ipv }) => {
	return (
		<VmCardExpended title='misc.vehicle' titleGutter>
			<DataListGrid>
				<DataList
					dataType={dataListType.vmLink}
					title='vehicle.immat'
					data={[
						{
							text: ipv?.vehicleDetail?.immat,
							link: { vm: EViewModal.VEHICLES, id: ipv?.id },
						},
					]}
				/>

				<DataList
					dataType={dataListType.vmLink}
					title='vehicle.vin'
					data={[
						{
							text: ipv?.vehicleDetail?.vin,
							link: { vm: EViewModal.VEHICLES, id: ipv?.id },
						},
					]}
				/>

				<DataList
					dataType={dataListType.simpleText}
					title='vehicle.brand'
					data={[{ text: ipv?.vehicleDetail?.vehicleBrand?.name }]}
				/>

				<DataList
					dataType={dataListType.simpleText}
					title='vehicle.model'
					data={[{ text: ipv?.vehicleDetail?.vehicleModelText }]}
				/>
			</DataListGrid>
		</VmCardExpended>
	)
}
export default VehicleInfos
