/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Stack, Typography } from '@mui/material'

import { Picture } from '../../components'
import { useFormatMessage } from '../../utils/hooks'
import { ROUTES } from '../../routes/consts'

interface IError404 {
	notFullScreen?: boolean
}

const Error404: React.FC<IError404> = ({ notFullScreen }) => {
	const intlMsg = useFormatMessage()
	const navigate = useNavigate()

	const handleClick = useCallback(() => {
		//TOTEST changed here
		navigate(ROUTES.HOME.url)
	}, [navigate])

	return (
		<Stack
			direction='row'
			alignItems='center'
			justifyContent='center'
			sx={{ width: 1, height: notFullScreen ? '100%' : '100vh' }}
		>
			<Stack alignItems='center' justifyContent='center'>
				<Picture width={500} src='/img/error/404.png' />
				<Typography variant='body1'>{intlMsg('errors.error404')}</Typography>
				<Button onClick={handleClick} sx={{ mt: 2 }}>
					{intlMsg('misc.goBackToDashboard')}
				</Button>
			</Stack>
		</Stack>
	)
}

export default Error404
