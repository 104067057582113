import translateIntlMsgString from '../../../../utils/translateIntlMsgString'
import { string, object } from 'yup'

const contactValidationSchema = object().shape({
	firstName: string().required(
		translateIntlMsgString('validationSchema.require')
	),
	lastName: string().required(
		translateIntlMsgString('validationSchema.require')
	),
	email: string()
		.email(translateIntlMsgString('validationSchema.email'))
		.nullable(true),
	sites: object()
		.required(translateIntlMsgString('validationSchema.require'))
		.nullable(true),

	customer: object()
		.required(translateIntlMsgString('validationSchema.require'))
		.nullable(true),
})

export default contactValidationSchema
