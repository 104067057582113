/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
	useCallback,
	useEffect,
	useMemo,
	useState,
	useLayoutEffect,
} from 'react'

import { useLazyQuery } from '@apollo/client'
import { useNavigate, useLocation } from 'react-router-dom'
import qs from 'qs'

import { useIntl } from 'react-intl'
import { debounce } from './utils'
import { ROUTES } from '../routes/consts'

export const useFormatMessage = (): any => {
	const intl = useIntl()
	const formatMessage = useCallback(
		(id: string, values?: any) => intl.formatMessage({ id }, values),
		[intl]
	)

	return formatMessage
}

export const useDebouncedState = (initialState, debounceTime = 1000) => {
	const [debouncedState, setdebouncedState] = useState(initialState)
	const [liveState, setLiveState] = useState(initialState)
	const debouncedStateUpdate = useMemo(() => {
		return debounce((newState) => {
			setdebouncedState(newState)
		}, debounceTime)
	}, [])
	const setState = useCallback(
		(newState) => {
			setLiveState(newState)
			debouncedStateUpdate(newState)
		},
		[debouncedStateUpdate]
	)
	return [debouncedState, setState, liveState]
}

export interface IModalUtils {
	openModal
	closeModal
	redirectAfterCloseModal
	isVisible: boolean
}

export const useModal = (
	initialState = false,
	onCloseActions: any = null
): IModalUtils => {
	const [visible, setVisible] = useState(initialState)
	const navigate = useNavigate()
	const location: any = useLocation()

	const closeModal = useCallback(() => {
		if (onCloseActions) onCloseActions()
		setVisible(false)
	}, [onCloseActions])

	const redirectAfterCloseModal = useCallback(() => {
		const previousPath: any = location?.state?.from

		const { query } = qs.parse(location.search, {
			ignoreQueryPrefix: true,
		})

		setVisible(false)

		//TOTEST here all path
		if (previousPath && previousPath?.substring(1) !== query) {
			navigate(-1)
		} else {
			switch (query) {
				case 'site':
					navigate(ROUTES.SITES.url)
					break
				case 'customer':
					navigate(ROUTES.ACCOUNTS.url)
					break
				case 'vehicle':
					navigate(ROUTES.VEHICLES.url)
					break
				default:
					navigate(-1)
			}
		}
	}, [navigate])

	const openModal = useCallback(() => setVisible(true), [])

	return {
		openModal,
		closeModal,
		redirectAfterCloseModal,
		isVisible: visible,
	}
}

export const useInfiniteScrollWithSearch = ({
	queryConfig,
	withSearch = true,
	itemsPerPage = 10,
}) => {
	const [getData, { data, loading, called }] = useLazyQuery(
		queryConfig.query,
		queryConfig.options || {}
	)
	const [page, setPage] = useState(1)
	const [dataList, setDataList] = useState<any>(null)
	const [newData, setNewData] = useState(null)
	const [debouncedState, setDebouncedState, liveState] = useDebouncedState({
		searchText: '',
	})

	useEffect(() => {
		getData({
			variables: {
				...(withSearch && { searchText: debouncedState.searchText }),
				itemsPerPage,
				page: 1,
			},
		})
		setPage(1)
		setDataList(null)
	}, [debouncedState.searchText])

	useEffect(() => {
		if (!loading && data) {
			setNewData(data)
		}
	}, [data, loading])

	useEffect(() => {
		if (newData) {
			const tmp = [
				...(dataList ?? []),
				...data[queryConfig.queryName].collection,
			]
			setDataList(tmp)
			setNewData(null)
		}
	}, [newData, dataList])

	const handleChangeSearch = useCallback((e) => {
		setDebouncedState({
			searchText: e.target.value,
		})
	}, [])

	const handleOnScroll = useCallback(
		(e) => {
			if (
				e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight &&
				page < data[queryConfig.queryName].paginationInfo?.lastPage
			) {
				getData({
					variables: {
						...(withSearch && { searchText: debouncedState.searchText }),
						itemsPerPage,
						page: page + 1,
					},
				})
				setPage(page + 1)
			}
		},
		[page, debouncedState.searchText, data]
	)

	return {
		handleOnScroll,
		handleChangeSearch,
		liveParams: liveState,
		dataList,
		loading,
		called,
	}
}

// Return true or false when element is in viewport ///////////////////////////////////////////
export const elementIsVisible = (element, rootMargin) => {
	const [isVisible, setState] = useState(false)

	useLayoutEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					setState(entry.isIntersecting)
					observer.unobserve(element.current)
				}
			},
			{
				rootMargin,
			}
		)

		element.current && observer.observe(element.current)

		return () => {
			observer.unobserve(element.current)
		}
	}, [element.current])

	return isVisible
}

// Infinite scroll with trigger //////////////////////////////////////////////////////////////
export const infiniteScrollWithTrigger = ({
	queryConfig,
	itemsPerPage = 10,
	params = {},
}) => {
	const [getData, { data, loading }] = useLazyQuery(queryConfig.query)
	const [page, setPage] = useState(1)
	const [dataList, setDataList] = useState<any[]>([])
	const [newData, setNewData] = useState(null)
	const [trigger, setTrigger]: any = useState({})

	//////////////////////////////////////////////

	useEffect(() => {
		getData({ variables: { itemsPerPage, ...params, page: 1 } })
	}, [])

	//////////////////////////////////////////////

	useEffect(() => {
		if (!loading && data?.[queryConfig.queryName]?.collection?.length) {
			setNewData(data)
		}
	}, [data, loading])

	//////////////////////////////////////////////

	useEffect(() => {
		if (newData) {
			const tmp = [...dataList, ...data[queryConfig.queryName].collection]
			setDataList(tmp)
			setNewData(null)
		}
	}, [newData, dataList])

	//////////////////////////////////////////////

	useLayoutEffect(() => {
		if (
			trigger?.inViewport &&
			page < data?.[queryConfig.queryName]?.paginationInfo?.lastPage
		) {
			getData({ variables: { itemsPerPage, ...params, page: page + 1 } })
			setPage(page + 1)
		}
	}, [trigger.ref, trigger?.inViewport])

	return {
		dataList,
		loading,
		setTrigger,
	}
}

/////////////////////////////////////////////////////////
// FORCE UPDATE
/////////////////////////////////////////////////////////

export const useForceUpdate = () => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_ignored, newState]: any = useState()
	return useCallback(() => newState({}), [])
}

/////////////////////////////////////////////////////////
// to update the component's style based on the maximum height
/////////////////////////////////////////////////////////

export const useResizeHeightStyle = (resizeRef, maxHeightUtils, dependency) => {
	useEffect(() => {
		const updateMaxHeight = () => {
			if (resizeRef.current) {
				const currentHeight = resizeRef.current.offsetHeight
				maxHeightUtils?.setMaxHeight((prevMaxHeight) =>
					Math.max(prevMaxHeight, currentHeight)
				)
			}
		}

		if (resizeRef.current) {
			const resizeObserver = new ResizeObserver(updateMaxHeight)
			resizeObserver.observe(resizeRef.current)
			requestAnimationFrame(updateMaxHeight)
			return () => {
				resizeObserver.disconnect()
			}
		}
	}, [maxHeightUtils?.setMaxHeight, resizeRef, ...dependency])

	const componentStyle = {
		minHeight:
			maxHeightUtils?.maxHeight > 0
				? maxHeightUtils?.maxHeight + 'px'
				: 'unset',
	}

	return componentStyle
}
