import React, { useMemo } from 'react'
import { Box, Slider, Stack, Rating } from '@mui/material'

import { EFormTemplateRatingType } from '../../../../../utils/app-models'

const valueLabelCSS = {
	'& .MuiSlider-valueLabel': {
		backgroundColor: 'transparent',
		top: '25px',
		color: 'white',
	},
}

interface INotation {
	fieldTemplate
	record
}

const Notation: React.FC<INotation> = ({ fieldTemplate, record }) => {
	const { min, max, ratingType, step, defaultValue } = useMemo(
		() => JSON.parse(fieldTemplate.attributes[0]),
		[fieldTemplate]
	)
	const value = useMemo(
		() => record?.value?.value || defaultValue,
		[record, defaultValue]
	)

	return (
		<Box sx={{ width: '50%' }}>
			<Box>
				{ratingType === EFormTemplateRatingType.slider ? (
					<Box>
						<Slider
							defaultValue={value}
							step={step}
							marks
							min={min}
							max={max}
							disabled
							style={{ color: '#2196F3' }}
							valueLabelDisplay='on'
							sx={valueLabelCSS}
						/>
					</Box>
				) : (
					<Rating value={value} precision={0.5} readOnly />
				)}
			</Box>
			{ratingType === EFormTemplateRatingType.slider && (
				<Stack direction='row' justifyContent='space-between'>
					<span>{min}</span>
					<span>{max}</span>
				</Stack>
			)}
		</Box>
	)
}

export default Notation
