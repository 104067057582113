export const mutationVehicleAttributes = `
    id
`

export const vehiclePayloadAttributes = `
vehicle {
    ${mutationVehicleAttributes}
}
`

export const listVehiclesAttributes = `
paginationInfo {
    totalCount
},
collection{
  id
  designation
  isActive
  dateCreated
  site {
    id
    name
    customer {
      id
      name
    }
  }
  vehicleMileages{
    id
    mileage
  }
  vehicleDetail {
    id
    immat
    vin
    actualMileage
   
    vehicleCategory{
      id
      name
    }
    vehicleBrand {
      id
      name
    }
    vehicleCarburant{
      id
      name
      code
      color
    }
    vehicleModelText
  }
  vehicleLocations(itemsPerPage: 99999){
    collection {
      id
      location
      color
      date
    }
  }
  lastVehicleMovement {
    id
    entryDate
    exitDate
  }
  previousInterventionProgrammedVehicle {
    id
    completionDate
    comment
    status {
      id
      code
      color
    }
  }
}
`

export const vehicleCategoriesAttributes = `
    id
    name
    code
`

export const vehicleTypesAttributes = `
    id
    name
    code
`

export const vehicleCarburantsAttributes = `
    id
    name
    code
    color
`

export const vehicleBrandsAttributes = `
  id
  name
`

export const vehicleViewModal = `
  id
  vehicleCategory{
    id
    name
    code
  }
  canEditInfos
  designation
  comment
  observation
  dateCreated
  isActive
  vehicleDetail {
    id
    immat
    vin
    vehicleModelText
    name
    denomCom
    actualMileage
    nextCT
    nextCPollution
    dateMec
    horsePower
    fiscPower
    cylindree
    transmission
    nbSeats
    nbDoors
    tireSizeRear
    tireSizeBack
    ptac
    picture
    vehicleCategory{
      id
      name
      code
    }
    vehicleBrand{
      id
      name
    }
    vehicleCarburant{
      id
      name
      code
      color
    }
    vehicleType{
      id
      name
      code
    }
  }
  site{
    id,
    name,
    customer {
      id
      socialName
      name
    }
  }
  vehicleLocations(itemsPerPage:1){
    collection {
      id
      location
      color
    }
  }
`
