import React, { useCallback, useContext, useMemo } from 'react'

import { ROUTES } from '../../../../routes/consts'
import CustomLink from '../../../_overrideMui/Link/CustomLink'
import { handleOpenViewModal } from '../../../../utils/utils'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../../../utils/contexts/AuthContext'

interface IDataListVmLink {
	link: { vm: string; id?: string; customVmLink?: string }
	text: string
	modules
}

const DataListVmLink: React.FC<IDataListVmLink> = ({ link, text, modules }) => {
	const { checkModules } = useContext(AuthContext)
	const navigate = useNavigate()
	const location = useLocation()

	const formattedLink = useMemo(() => {
		return text ? `${ROUTES.VIEWMODAL[link?.vm]?.url}${link?.id}` : null
	}, [text, link])

	const haveAccess = useMemo(
		() => (modules ? checkModules(modules) : true),
		[modules]
	)

	const openVm = useCallback(
		(event) => {
			if (formattedLink && haveAccess)
				handleOpenViewModal(navigate, location, formattedLink)(event)
		},
		[navigate, location, formattedLink, haveAccess]
	)

	return (
		<CustomLink
			variant='text1Bold'
			variantlink='primaryLink'
			onMouseDown={openVm}
			disabled={!haveAccess || !formattedLink}
		>
			{text || '-'}
		</CustomLink>
	)
}

export default DataListVmLink
