import React, { useCallback, useState, useContext } from 'react'

import Button from '@mui/material/Button'
import { Tooltip } from '@mui/material'

import { stringifiedColor } from '../../../utils/color'
import LocationVehicleForms from './LocationVehicleForms'
import { maxStringDisplay } from '../../../utils/string'
import { AuthContext } from '../../../utils/contexts/AuthContext'
import { useFormatMessage } from '../../../utils/hooks'

const locationBtnCSS = (color, permissions) => {
	return {
		...(!color && { color: `${stringifiedColor('grey', 800)} !important` }),
		...(!color && {
			border: `solid 1px ${stringifiedColor('grey', 100)} !important`,
		}),

		backgroundColor: `${
			color
				? `${stringifiedColor(color, 500)} !important`
				: 'transparent  !important'
		}`,

		'&:hover': {
			background: `${stringifiedColor(
				color || 'grey',
				color ? 800 : 100
			)} !important`,
		},

		'&.MuiButton-contained.Mui-disabled': {
			color: !permissions ? 'white !important' : 'inherit',
		},

		...(!color && {
			'&:hover': {
				backgroundColor: 'transparent',
				border: `solid 1px ${stringifiedColor('grey', 300)} !important`,
			},
		}),
	}
}

const locationBtnEmptyCSS = {
	backgroundColor: 'transparent !important',
	color: `${stringifiedColor('grey', 800)} !important`,
	border: `solid 1px ${stringifiedColor('grey', 100)} !important`,
	'&:hover': {
		backgroundColor: 'transparent',
		border: `solid 1px ${stringifiedColor('grey', 300)} !important`,
	},
}

interface ILocationVehiclePopover {
	id?: string
	color?: string | undefined
	location?: any
	permissions?
	displayEmptyLocationLongText?: boolean
}

const LocationVehiclePopover: React.FC<ILocationVehiclePopover> = ({
	id,
	color,
	location,
	permissions,
	displayEmptyLocationLongText,
}) => {
	const intlMsg = useFormatMessage()
	const { checkPermissions } = useContext(AuthContext)
	const haveAccess = checkPermissions(permissions)

	// Color popover /////////////////////////////////////////////
	const [open, setOpen] = useState(false)
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

	const handleToggleColor = useCallback((e) => {
		setAnchorEl(e.currentTarget)
		setOpen((prevOpen) => !prevOpen)
	}, [])

	const handleClose = useCallback(() => setOpen(false), [setAnchorEl, setOpen])

	return (
		<>
			{location || color ? (
				<Button
					onClick={handleToggleColor}
					variant='contained'
					color='secondary'
					sx={{ ...locationBtnCSS(color, haveAccess) }}
					fullWidth
					size='small'
					disabled={!haveAccess}
				>
					{location?.length > 6 ? (
						<Tooltip title={location} aria-label={location}>
							<span>{maxStringDisplay(location, 6)}</span>
						</Tooltip>
					) : (
						location || intlMsg('vehicle.locationShort')
					)}
				</Button>
			) : (
				<Button
					onClick={handleToggleColor}
					variant='contained'
					color='secondary'
					sx={locationBtnEmptyCSS}
					fullWidth
					size='small'
					disabled={!haveAccess}
				>
					{displayEmptyLocationLongText ? intlMsg('vehicle.location') : '-'}
				</Button>
			)}

			{open && haveAccess && (
				<LocationVehicleForms
					handleClose={handleClose}
					anchorEl={anchorEl}
					color={color}
					location={location}
					id={id}
					open={open}
				/>
			)}
		</>
	)
}

export default LocationVehiclePopover
