/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'

import BooleanColorPopover from '../../../../popover/BooleanColorPopover'
import { formatDate } from '../../../../../utils/date'
import { useUpdateTeams } from '../../../../../api/gql/mutations/teamsMutations'
import { useDeleteTeams } from '../../../../../api/gql/mutations/teamsMutations'
import { ROUTES } from '../../../../../routes/consts'
import ChipCountListPopover from '../../../../multiItemsPopoverList/popover/simpleListPopover'
import TimeCell from '../../../tableCell/TimeCell'
import ActionsBtn from '../../../tableCell/actionsBtn'
import TeamModal from '../../../../../view/administration/teams/teamModal'
import UsersPopover from '../../../../multiItemsPopoverList/popover/usersPopover'

//!  columnLabel is mandatory
//!  dataKey OR personalizedCell is mandatory
// use datakey if you want to just display data
// personalized Cell is a function taking row as parameter

const listTeamsViewTable = [
	{
		columnLabel: 'misc.name',
		orderBy: 'name',
		dataKey: 'name',
		stickyFirstCol: true,
	},

	{
		columnLabel: 'misc.sitesAccess',
		personalizedCell: (row: any) => (
			<ChipCountListPopover
				text={'misc.sites'}
				data={
					row?.sites?.collection?.map(({ name, id }) => ({ name, id })) || []
				}
				viewModalLink={ROUTES.VIEWMODAL.SITE.url}
			/>
		),
	},

	{
		columnLabel: 'misc.networksAccess',
		personalizedCell: (row: any) => (
			<ChipCountListPopover
				text={'misc.network'}
				data={
					row?.networks?.collection?.map(({ name, id }) => ({ name, id })) || []
				}
				disabledClick
			/>
		),
	},

	{
		columnLabel: 'misc.members',
		personalizedCell: (row: any) => (
			<UsersPopover
				data={row?.members?.map((item) => {
					return {
						firstName: item?.firstName,
						lastName: item?.lastName,
						id: item?.id,
						isManager: item?.isManager,
					}
				})}
				title='misc.members'
			/>
		),
	},

	{
		columnLabel: 'misc.active',
		personalizedCell: (row: any) => {
			return (
				<BooleanColorPopover
					value={row.isActive}
					id={row.id}
					txt={{ true: 'misc.active', false: 'misc.desactive' }}
					titlePopover={'misc.teamStatusState'}
					queryConfig={{
						query: useUpdateTeams,
						refreshQuery: ['getTeamsList'],
						successMessage: 'success.statusUpdate',
					}}
					key={row.id}
				/>
			)
		},
	},

	{
		columnLabel: 'misc.addAt',
		orderBy: 'dateCreated',
		personalizedCell: (row) => (
			<TimeCell
				date={row?.dateCreated ? formatDate(row.dateCreated) : '-'}
				key={row.id}
			/>
		),
	},

	{
		fixWidth: '50px',
		visible: true,
		columnLabel: '',
		tableCellAlign: 'center',
		cellAlign: 'center',
		sticky: true,
		personalizedCell: (row: any) => (
			<ActionsBtn
				rowId={row?.id}
				deleted={{
					query: useDeleteTeams,
					refreshQuery: ['getTeamsList'],
					successMessageDelete: 'success.teamDeleted',
					messageConfirm: {
						title: 'confirmModal.deleteTeamTitle',
						message: 'confirmModal.deleteTeamMessage',
					},
				}}
				editModal={<TeamModal editedTeamId={row?.id} modalUtils={{} as any} />}
				viewAction={false}
				key={row.id}
			/>
		),
	},
]

export default listTeamsViewTable
