import React from 'react'
import { TEAMS_FILTER_LIST_FOR_MODAL } from '../../../../api/gql/queries/teamsQueries'
import FormSearchCheckboxListIsManager from '../../../../components/formSearchCheckboxListIsManager'

interface IUserModalTeams {}

const UserModalTeams: React.FC<IUserModalTeams> = () => {
	const renderLeftPartTitle = (elem) => elem?.name

	return (
		<FormSearchCheckboxListIsManager
			query={TEAMS_FILTER_LIST_FOR_MODAL}
			queryName='teams'
			renderLeftPartTitle={renderLeftPartTitle}
			leftColumName='misc.teamsMember'
			rightColumnName='misc.teamManager'
			playwrightKeySelector='name'
		/>
	)
}

export default UserModalTeams
