import { createContext } from 'react'

export type ITableContext = {
	selectedRow?
	liveState?
	activeColumns?
	visibleRows?
	filtersQuerieParam?
	defaultFilterConfig?
	setActiveColumns?
	setSelectedRow?
	setDebouncedState?
	setVisibleRows?
	setFiltersQuerieParam?
	localStorageKeyFilter?
	debouncedState?
	search?
	switchOption?
	configIsLoading?
	customDefaultFilterConfig?
	page?
	setPage?
	isInViewModal?
	possibleColumns?
	setPossibleColumns?
	currentOrderBy?
	setCurrentOrderBy?
	customStateSelectedRow?
	forcedData?
	loader?
	setLoader?
	onMultiDelete?
}

const defaultContextValues: ITableContext = {}

export const TableContext: React.Context<ITableContext> =
	createContext(defaultContextValues)
