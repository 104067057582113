import React, { useMemo } from 'react'

import { Box, Typography } from '@mui/material'
import { useFormatMessage } from '../../../utils/hooks'

interface IDataListTitle {
	title?: string
	fullWidth?: boolean
	children
}

const DataListTitle: React.FC<IDataListTitle> = ({
	title,
	fullWidth,
	children,
}) => {
	const intlMsg = useFormatMessage()

	const containerCSS = useMemo(
		() => ({ gridColumn: fullWidth ? '1/4' : 'inherit' }),
		[fullWidth]
	)

	return (
		<Box sx={containerCSS}>
			{title && (
				<Typography variant='titleDataList' component='h3'>
					{intlMsg(title)}
				</Typography>
			)}
			{children}
		</Box>
	)
}

export default DataListTitle
