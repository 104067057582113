import { useContext, useMemo } from 'react'
import { useProductsAndCategoriesList } from '../../../../../../api/gql/queries/catalogProductQueries'
import { useFormTemplates } from '../../../../../../api/gql/queries/formTemplateQuery'
import {
	FormModalContext,
	IFormModalContext,
} from '../../../../../../components/forms/formModal/FormModalContext'

export const useGlobalActionData = () => {
	const { data } = useContext<IFormModalContext>(FormModalContext)
	const { productCategories } = useProductsAndCategoriesList({
		variables: {
			customer:
				data?.finalCustomer?.value?.id ||
				data?.orderingCustomer?.value?.id ||
				'',
		},
	})

	const { formTemplates } = useFormTemplates({
		variables: {
			page: 1,
			itemsPerPage: 100000, //todo change itemsPerPage
			isActive: true,
			isDeleted: false,
			customer:
				data?.finalCustomer?.value?.id ||
				data?.orderingCustomer?.value?.id ||
				'',
		},
	})

	const formTemplatesOptions = useMemo(
		() =>
			formTemplates?.map((formTemplate) => ({
				key: formTemplate?.id,
				label: formTemplate?.name,
				value: formTemplate,
			})),
		[formTemplates]
	)

	const productCategoriesOptions = useMemo(() => {
		const tmp: any = []

		productCategories?.forEach(
			({ products: { collection }, name: categoryName }) => {
				collection?.forEach((product) => {
					tmp.push({
						key: product?.id,
						label: product?.name,
						value: {
							...product,
							categoryName,
						},
					})
				})
			}
		)

		return tmp
	}, [productCategories])

	return {
		formTemplatesOptions,
		productCategoriesOptions,
	}
}
