import React, { Context, useCallback, useContext, useMemo } from 'react'
import frLocale from 'date-fns/locale/fr'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { TextField } from '@mui/material'
import dayjs from 'dayjs'
import { useFormatMessage } from '../../../utils/hooks'
import { IFormModalContext } from '../formModal/FormModalContext'
import { IStepperContext } from '../formStepper/StepperContext'
import { replaceHoursFromDate } from '../../../utils/date'
import { handleFormOnblurV2 } from '../formsUtils'

interface IFormDatePicker {
	name: string
	label?: string
	valuesDefault?: any
	isrequired?: boolean
	externalValue?: any
	specialOnChange?: (data: any) => void
	minDate?: Date
	maxDate?: Date
	openTo?: 'day' | 'month' | 'year'
	views?: ('day' | 'month' | 'year')[]
	shouldDisableDate?: (date: Date) => boolean
	shouldDisableMonth?: (date: Date) => boolean
	isStartOfDay?: boolean
	conserveHour?: boolean
	clearErrors?: (fieldName: string) => void
	context: Context<IFormModalContext> | Context<IStepperContext>
	trigerred?: boolean
	onBlur?: () => void
}

const FormDatePicker: React.FC<IFormDatePicker> = ({
	name,
	label,
	valuesDefault,
	isrequired,
	externalValue = null,
	specialOnChange = null,
	minDate,
	maxDate,
	openTo = 'day',
	views = ['day', 'month', 'year'],
	shouldDisableDate = undefined,
	shouldDisableMonth = undefined,
	isStartOfDay = true,
	conserveHour = false,
	clearErrors,
	context,
	trigerred,
	onBlur,
}) => {
	const intlMsg = useFormatMessage()
	const { setValue, data, errors, triggerError, setErrors } = useContext<
		IFormModalContext | IStepperContext
	>(context as any)

	const value = useMemo(() => data[name] || null, [data[name]])
	const error = useMemo(() => errors[name] || null, [errors[name]])

	const handleOnblur = useCallback(() => {
		return trigerred || onBlur
			? handleFormOnblurV2(trigerred, onBlur, name, triggerError)
			: undefined
	}, [trigerred, onBlur, name, triggerError])

	const handleOnChange = useCallback(
		(date: Date) => {
			if (specialOnChange) {
				specialOnChange({
					fieldName: name,
					date,
				})
			} else {
				setValue(name, date || '')
			}
		},
		[specialOnChange, setValue, name]
	)

	// this function is used to fix a WEIRD bug
	// when we fill a date with keyboard in the input,hours in the values Object is egal to 0
	// when converted into isostring, seems like -2h, so the day decreased
	// we add 2 hours with the fnc to fix this problem
	const addOneHours = useCallback(
		(date: Date) => {
			if (dayjs(date).isValid()) {
				if (errors[name])
					setErrors((prevErrors) => ({
						...prevErrors,
						[name]: null,
					}))

				let newDate: any = isStartOfDay
					? dayjs(date).startOf('day').add(2, 'h')
					: date

				if (conserveHour)
					newDate = replaceHoursFromDate(dayjs(newDate), dayjs(valuesDefault))

				handleOnChange(newDate)

				if (clearErrors) clearErrors(name)
			} else {
				// delete date
				setValue(name, null)
			}
		},
		[
			errors,
			setErrors,
			name,
			isStartOfDay,
			conserveHour,
			valuesDefault,
			handleOnChange,
			intlMsg,
			clearErrors,
		]
	)

	const renderInput = useCallback(
		(params: any) => (
			<TextField
				{...params}
				name={name}
				fullWidth
				size='small'
				error={!!error}
				helperText={error || ''}
				required={isrequired}
				onBlur={handleOnblur}
			/>
		),
		[name, error, isrequired, handleOnblur]
	)

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={frLocale}>
			<DesktopDatePicker
				shouldDisableMonth={shouldDisableMonth}
				shouldDisableDate={shouldDisableDate}
				orientation='landscape'
				openTo={openTo}
				views={views}
				onChange={addOneHours}
				value={externalValue || value || valuesDefault || null}
				renderInput={renderInput}
				inputFormat='DD/MM/YYYY'
				label={label}
				minDate={minDate}
				maxDate={maxDate}
			/>
		</LocalizationProvider>
	)
}

export default FormDatePicker
