import React, { useCallback, useContext, useState } from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import Radio from '@mui/material/Radio'
import Checkbox from '@mui/material/Checkbox/Checkbox'
import EditIcon from '@mui/icons-material/Edit'
import { Stack, Input, Typography, IconButton } from '@mui/material'

import { FormTemplateContext } from '../../../../../../utils/contexts/FormTemplateContext'
import {
	TitleField,
	RequireFieldTxt,
	SelectionTypeField,
} from '../fieldEditForms/editFormsField'
import { useFormatMessage } from '../../../../../../utils/hooks'
import { EFormTemplateSelectionType } from '../../../../../../utils/app-models'
import BtnAddCircle from '../../../../../../components/buttons/BtnAddCircle'

interface IFieldEditFormLayoutSelection {}

const editIconCSS = {
	ml: 1,
	color: ({ palette }) => palette.grey['500'],
	cursor: 'pointer',
}

const inputCSS = {
	border: 'none',
	background: 'none',
	'& :focus': {
		outline: 'none',
	},
}

const FieldEditFormLayoutSelection: React.FC<
	IFieldEditFormLayoutSelection
> = () => {
	const intlMsg = useFormatMessage()
	const {
		drawerUtils: { controlDrawer: control, setValueDrawer, watchDrawer },
	} = useContext(FormTemplateContext)
	const watchedSelectionType = watchDrawer('selectionType')
	const watchedSelectionsList = watchDrawer('selectionsList')
	const [changedIntoInput, setChangedIntoInput] = useState(-1)
	const [selectionName, setSelectionName] = useState('')

	const handleRemoveSelection = useCallback(
		(selectionIndex) => () => {
			const newSelectionsList = watchedSelectionsList.map((elem) => ({
				...elem,
			}))
			newSelectionsList.splice(selectionIndex, 1)
			setValueDrawer('selectionsList', newSelectionsList)
		},
		[watchedSelectionsList]
	)

	const handleChangeIntoInput = useCallback(
		(i) => () => {
			setSelectionName('')
			setChangedIntoInput(i)
		},
		[]
	)

	const handleChangeSelectionInput = useCallback(
		(selectionIndex) => (e) => {
			if (e.target.value !== '') {
				const newSelectionslList = watchedSelectionsList.map((elem) => ({
					...elem,
				}))

				newSelectionslList[selectionIndex].name = e.target.value
				setValueDrawer('selectionsList', newSelectionslList)
			}
			setChangedIntoInput(-1)
		},
		[watchedSelectionsList]
	)

	const handleOnKeyDown = useCallback(
		(selectionIndex) => (key) => {
			if (key.code === 'Enter') {
				const newSelectionslList = watchedSelectionsList.map((elem) => ({
					...elem,
				}))

				newSelectionslList[selectionIndex].name = selectionName
				setValueDrawer('selectionsList', newSelectionslList)
				setChangedIntoInput(-1)
			}
		},
		[selectionName]
	)

	const handleChangeInput = useCallback(
		(e) => setSelectionName(e.target.value),
		[]
	)

	const handleChangeSelectionType = useCallback(
		(e) => {
			setValueDrawer('selectionType', e.target.value)
			const newSelectionsList = watchedSelectionsList.map((e) => ({
				...e,
				selected: false,
			}))
			setValueDrawer('selectionsList', newSelectionsList)
		},
		[watchedSelectionsList]
	)

	const handleChangeSelectedSelection = useCallback(
		(selectionIndex) => () => {
			const newSelectionsList = watchedSelectionsList.map((e, i) =>
				watchedSelectionType === EFormTemplateSelectionType.multiples
					? { ...e }
					: {
							...e,
							selected: selectionIndex === i ? true : false,
					  }
			)
			if (watchedSelectionType === EFormTemplateSelectionType.multiples)
				newSelectionsList[selectionIndex].selected =
					!newSelectionsList[selectionIndex].selected
			setValueDrawer('selectionsList', newSelectionsList)
		},
		[watchedSelectionsList, watchedSelectionType]
	)

	const handleAddSelection = useCallback(() => {
		const newSelectionsList = [
			...watchedSelectionsList,
			{
				name: `Option ${watchedSelectionsList?.length + 1}`,
				selected: false,
			},
		]
		setValueDrawer('selectionsList', newSelectionsList)
	}, [watchedSelectionsList])

	return (
		<Stack spacing={2}>
			<RequireFieldTxt control={control} />
			<TitleField control={control} />
			<SelectionTypeField
				control={control}
				specialOnChange={handleChangeSelectionType}
				selectElements={Object.values(EFormTemplateSelectionType).map(
					(e, i) => ({
						key: `${e}-${i}`,
						label: intlMsg(`formTemplate.${e}`),
						value: e,
					})
				)}
			/>
			<Stack>
				{watchedSelectionsList.map(({ name, selected }, i) => (
					<>
						<Stack
							direction='row'
							alignItems='center'
							justifyContent='space-between'
							key={`selectionsList-${name}-${i}`}
							sx={{ mt: '0px!important' }}
						>
							<Stack direction='row' alignItems='center'>
								{watchedSelectionType ===
								EFormTemplateSelectionType.multiples ? (
									<Checkbox
										checked={selected}
										onChange={handleChangeSelectedSelection(i)}
										color='primary'
									/>
								) : (
									<Radio
										checked={selected}
										onChange={handleChangeSelectedSelection(i)}
										color='primary'
									/>
								)}
								<div onClick={handleChangeIntoInput(i)}>
									{changedIntoInput === i ? (
										<Input
											sx={inputCSS}
											type='text'
											onBlur={handleChangeSelectionInput(i)}
											autoFocus
											onChange={handleChangeInput}
											onKeyDown={handleOnKeyDown(i)}
										/>
									) : (
										<Stack direction='row' alignItems='center'>
											<Typography variant='body2'>{name}</Typography>
											<EditIcon fontSize='small' sx={editIconCSS} />
										</Stack>
									)}
								</div>
							</Stack>

							<IconButton
								aria-label='delete'
								onClick={handleRemoveSelection(i)}
								size='large'
							>
								<DeleteIcon />
							</IconButton>
						</Stack>
					</>
				))}
			</Stack>

			<Stack direction='row' justifyContent='center'>
				<BtnAddCircle
					action={handleAddSelection}
					playwrightId='Playwright-FieldEditFormLayoutSelection-BtnAddCircle'
				/>
			</Stack>
		</Stack>
	)
}

export default FieldEditFormLayoutSelection
