import { ROUTES } from '../../../../routes/consts'

export const interventionMenuTabConfig = [
	{
		label: 'interventions.byReference',
		route: ROUTES.INTERVENTION.REFERENCES.url,
	},
	{
		label: 'interventions.byVehicle',
		route: ROUTES.INTERVENTION.VEHICLES.url,
	},
]
