import React from 'react'

import Table from '../../../components/tableV2'
import {
	IOverrideConfigTable,
	IOverrideConfigTableWithArgument,
} from '../../../utils/typescript/tableType'
import { customerSiteConfigTable } from './customerSiteConfigTable'

interface ICustomerSites {
	othersQueryParams?
	overrideConfigTable?: IOverrideConfigTable | IOverrideConfigTableWithArgument
	isInViewModal?: boolean
}

const CustomerSites: React.FC<ICustomerSites> = ({
	othersQueryParams,
	overrideConfigTable,
	isInViewModal = false,
}) => {
	return (
		<Table
			tableConfig={customerSiteConfigTable}
			overrideConfigTable={overrideConfigTable}
			othersQueryParams={othersQueryParams}
			isInViewModal={isInViewModal}
		/>
	)
}

export default CustomerSites
