export const interventionsTransportListAttributes = `
paginationInfo {
  totalCount
}
collection {
  id
  isSharedWithMe
  createdByAccount {
    id
    name
  }
  createdBy {
    id
    lastName
    firstName
    account {
      id
    }
  }
  agencies(itemsPerPage: 100000) {
    collection {
      id
      agencyCustomDetails {
        id
        agencyName
      }
    }
  }
  reference
  externalReference
  airTableReference
  interventionTransportType {
    id
    code
  }
  dateCreated
  orderingCustomer {
    id
    name
  }
  orderingSite {
    id
    name
  }
  orderingContact {
    id
    firstName
    lastName
  }
  dateTo
  dateFrom
  manager {
    id
    lastName
    firstName
    account {
      id
    }
  }
  status {
    id
    name
    code
    color
    progression
  }
  actors(itemsPerPage: 100000) {
    collection {
      id
      lastName
      firstName
      account {
        id
      }
    }
  }
  alerts {
    paginationInfo {
      totalCount
    }
  }
  interventionControlProgrammedVehicles(itemsPerPage: 100000) {
    collection {
      vehicle {
        id
        vehicleDetail {
          id
          immat
          vin
          vehicleModelText
          vehicleBrand {
            id
            name
          }
          vehicleCarburant {
            id
            color
            code
          }
        }
      }
    }
  }

  interventionTransportType {
    id
    code
  }
  interventionTransportTrips {
    id
    customer {
      id
      name
    }
    site {
      id
      name
    }
    contact {
      id
      firstName
      lastName
    }
    name
    address
    zipcode
    city
    country
    date
    timeFrom
    timeTo
    contactText
    contactPhone
    products {
      collection {
        id
        product {
          id
          name
        }
      }
    }
  }
}
`

export const interventionTransportForModalAttributes = `
  id
  isSharedWithMe
  createdByAccount {
    id
    name
  }
  agencies(itemsPerPage:100000) {
    collection {
      id
      agencyCustomDetails {
        id
        agencyName
      }
    }
  }
  externalReference
  airTableReference
  interventionType
  dateCreated
  orderingCustomer {
    id
    name
  }
  orderingSite {
    id
    name
  }
  orderingContact {
    id
    firstName
    lastName
  }
  dateTo
  dateFrom
  manager {
    id
    lastName
    firstName
    account{
      id
    }
  }
  status {
    id
    name
    code
    color
  }
  actors(itemsPerPage: 100000) {
    collection {
      id
      lastName
      firstName
      account{
        id
      }
    }
  }
  interventionControlProgrammedVehicles(itemsPerPage:100000) {
    collection {
      vehicle {
        id
        vehicleDetail {
          id
          immat
          vin
          vehicleModelText
          vehicleBrand {
            id
            name
            
          }
          vehicleCarburant {
            id
            color
            code
          }    
        }
      }
    }
  }

  publicComment
  interventionTransportType {
    id
    code
  }
  
  interventionTransportTrips {
    id
    customer {
      id
      name
    }
    site {
      id
      name
    }
    contact {
      id
      firstName
      lastName
    }
    isAddressEqualOrder
    name
    address
    zipcode
    city
    country
    date
    timeFrom
    timeTo
    contactText
    contactPhone
    instruction
    formTemplates {
      collection {
        id
        name
      }
    }
    products {
      collection {
        id
        product {
          id
          name
        }
      }
    }
  }
`

export const ipvTransportListAttributes = `
paginationInfo {
  totalCount
}
collection {
  id
  intervention {
    id
    isSharedWithMe
    agencies(itemsPerPage: 100000) {
      collection {
        id
        agencyCustomDetails {
          id
          agencyName
        }
      }
    }
    createdBy{
      id,
      lastName,
      firstName
      account{
        id
      }
    }
    orderingCustomer {
      id
      name
    }
    orderingSite {
      id
      name
    }
    reference
    externalReference
    airTableReference
    status {
      id
      name
      code
      color
      progression
    }

    manager {
      id
      lastName
      firstName
      account {
        id
      }
    }

    actors(itemsPerPage: 100000) {
      collection {
        id
        lastName
        firstName
        account {
          id
        }
      }
    }
    externalReference
    airTableReference
    interventionTransportType {
      id
      code
    }

    interventionTransportTrips {
      id
      customer {
        id
        name
      }
      site {
        id
        name
      }
      name
      address
      zipcode
      city
      country
      date
      timeFrom
      timeTo
      contactText
      contactPhone
      products {
        collection {
          id
          product {
            id
            name
          }
        }
      }
    }
  }
}
`

export const interventionTransportForViewModalAttribute = `
id
isSharedWithMe
createdByAccount {
  id
  name
}
agencies(itemsPerPage:100000) {
  collection {
    id
    agencyCustomDetails {
      id
      agencyName
    }
  }
}
reference
externalReference
airTableReference
interventionType
dateCreated
orderingCustomer {
  id
  name
}
orderingSite {
  id
  name
}
orderingContact {
  id
  firstName
  lastName
  account{
    id
  }
}
manager {
  id
  lastName
  firstName
  account{
    id
  }
}
status {
  id
  name
  code
  color
}
actors(itemsPerPage: 100000) {
  collection {
    id
    lastName
    firstName
    account{
      id
    }
  }
}
interventionControlProgrammedVehicles(itemsPerPage:100000) {
  collection {
    id
    vehicle {
      id
      vehicleDetail {
        id
        immat
        vin    
        vehicleModelText
        vehicleBrand {
          id
          name          
        }
      }
    }
  }
}

publicComment
interventionTransportType {
  id
  code
}
interventionTransportTrips {
  id
  customer {
    id
    name
  }
  site {
    id
    name
  }
  contact {
    id
    firstName
    lastName
    account{
      id
    }
  }
  isAddressEqualOrder
  name
  address
  zipcode
  city
  country
  date
  timeFrom
  timeTo
  contactText
  contactPhone
  instruction
  formTemplates {
    collection {
      id
      title
    }
  }
  products {
    collection {
      id
      isDone
      product {
        id
        name
      }
    }
  }
  forms {
    collection {
      id
      formTemplate {
        id
        title
        originalFormTemplate {
          id
        }
      }
    }
  }
  completionDate
}

endingForm {
  id,
  value
}

forms {
  collection {
    id
    geolocation
  }
}

`
