/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { useDeleteTeams } from '../../../api/gql/mutations/teamsMutations'
import { GET_ADMIN_PANEL_ACCOUNT } from '../../../api/gql/queries/adminPanelAccounts'
// import listAdminPanelAccountsViewExport from '../../../components/tableV2/config/adminPanel/listAdminPanelAccountsViewExport'
import listAdminPanelAccountViewTable from '../../../components/tableV2/config/adminPanel/listAdminPanelAccountViewTable'
import {
	ITableToolBarActionConfig,
	ITableToolBarConfig,
} from '../../../utils/typescript/tableType'

////////////////////////////////////////////////////////////////
// Global variable
////////////////////////////////////////////////////////////////

const queryConfig = {
	query: GET_ADMIN_PANEL_ACCOUNT,
	queryName: 'accounts',
}

////////////////////////////////////////////////////////////////
// tableToolBarAction CONFIG
////////////////////////////////////////////////////////////////

const refreshQuerie = ['getTeamsList']

const deletedBulk = {
	mutation: useDeleteTeams,
	operation: 'remove',
	successMessageDelete: {
		singular: 'success.teamDeleted',
		plural: 'success.teamsDeleted',
	},
	messageConfirm: {
		title: 'confirmModal.deleteTeamsitle',
		message: 'confirmModal.deleteTeamsMessage',
	},
	deletePermissions: [],
}

const tableToolBarActionConfig: ITableToolBarActionConfig = {
	refreshQuerie,
	deletedBulk,
}

////////////////////////////////////////////////////////////////
// TableToolBarHeader CONFIG
////////////////////////////////////////////////////////////////

const tableToolBarConfig: ITableToolBarConfig = {
	title: 'adminPanel.adminAccounts',
	tableConfig: listAdminPanelAccountViewTable,
	localStorageName: 'listAdminPanelAccountViewTableFilters',
	// exportConfig: {
	// 	config: listAdminPanelAccountsViewExport(intlMsg),
	// },
	queryConfig,
}

////////////////////////////////////////////////////////////////
// tableContainer CONFIG
////////////////////////////////////////////////////////////////

const tableContainerConfig = {
	tableConfig: listAdminPanelAccountViewTable,
	queryConfig,
}

////////////////////////////////////////////////////////////////
// -> EXPORT CONFIG MAIN TABLE
////////////////////////////////////////////////////////////////

export const adminPanelAccountConfigTable = {
	tableToolBarConfig,
	tableToolBarActionConfig,
	tableContainerConfig,
	displaySwitchOption: true,
}
