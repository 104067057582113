export const listAgenciesAccounts = `
paginationInfo {
  totalCount
  }
  collection {
    id
    name
    agencyCustomDetails {
      id
      agencyName
    }
    address
    isActive
    zipcode
    adminFirstName
    adminLastName
    adminPhone
    adminCellphone
    city
    country
    dateCreated
    accountType{
      id
      name
    }
    networks(itemsPerPage: 99999){
      collection{
          id
          name
        }
      }
    createdInterventions {
      paginationInfo {
        totalCount
      }
    }
    createdByAccount {
      id
      name
    }
    executedInterventions {
      paginationInfo {
        totalCount
      }
    }
    customers {
      paginationInfo {
        totalCount
      }
    }
  }
`
