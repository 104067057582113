import React from 'react'

import { Stack, Typography } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import TitleField from './TitleField'

const fieldNumberCSS = {
	width: 1,
	p: 1.5,
	mt: 1,
	border: ({ palette }) => `2px solid ${palette.grey[300]}`,
	color: ({ palette }) => palette.grey[400],
	borderRadius: 1,
}
const iconCSS = {
	border: ({ palette }) => `1px solid ${palette.grey[300]}`,
}
const iconLeftCSS = {
	borderRadius: ' 8px 0px 0px 8px',
	pl: '7px',
	...iconCSS,
}
const iconRightCSS = {
	borderRadius: ' 0px 8px 8px 0px',
	pl: '7px',
	...iconCSS,
}

interface IFieldNumber {
	field
	deleteField
	index
	updateField
	insertField
	simpleText?
}

const FieldNumber: React.FC<IFieldNumber> = ({
	field: { id, name, values, fieldType },
	deleteField,
	index,
	updateField,
	insertField,
}) => {
	return (
		<Stack p={1}>
			<TitleField
				deleteField={deleteField}
				index={index}
				fieldToEdit={{
					id,
					name,
					values,
					fieldType,
					updateField,
					index,
				}}
				isRequired={values?.isRequired}
				insertField={insertField}
				values={values}
				fieldType={fieldType}
			/>
			<Stack
				direction='row'
				justifyContent='space-between'
				alignItems='center'
				sx={fieldNumberCSS}
			>
				<Typography variant='body2'>0</Typography>

				<Stack direction='row' spacing={0}>
					<ArrowBackIosIcon sx={iconLeftCSS} />
					<ArrowForwardIosIcon sx={iconRightCSS} />
				</Stack>
			</Stack>
		</Stack>
	)
}

export default FieldNumber
