import { EModule, EPermission } from './app-models'

export const getAccessByQueryName = (queryName) => {
	switch (queryName) {
		case 'form':
			return {
				permissions: null,
				modules: null,
			}

		case 'site':
			return {
				permissions: null,
				modules: [EModule.site],
			}

		case 'customer':
			return {
				permissions: null,
				modules: [EModule.crm],
			}

		case 'vehicle':
			return {
				permissions: null,
				modules: null,
			}

		case 'intervention':
			return {
				permissions: null,
				modules: [EModule.interventionControl],
			}

		case 'interventionTransport':
			return {
				permissions: null,
				modules: [EModule.interventionTransport],
			}

		case 'agencyAccount':
			return {
				permissions: [EPermission.network],
				modules: [EModule.network],
			}

		default:
			return {
				permissions: null,
				modules: null,
			}
	}
}
