export default {
	admin: 'Administrator',
	coordinator: 'Coordinator',
	operator: 'Operator',
	guest: 'Guest',
	descriptionoperator:
		'Can only view platform data and perform actions from the mobile app.',
	descriptioncoordinator: 'Has all rights except user management.',
	descriptionadmin: 'Has all rights.',
	descriptionguest: 'TODO',
}
