import React, { useContext, useEffect, useState } from 'react'

import { Stack } from '@mui/material'
import { StepperContext } from '../../../../../../../components/forms/formStepper/StepperContext'

import CustomerSites from '../../../../../customerSites'
import listSitesTableConfigForSitesStep from '../../../../../../../components/tableV2/config/dashboard/customerSites/listSitesTableConfigForSitesStep'

interface ISiteStep {}

const SiteStep: React.FC<ISiteStep> = () => {
	const { setValue, data } = useContext(StepperContext)
	const [selectedRow, setSelectedRow] = useState<any>(
		data?.sharedSites?.map((sharedSite) =>
			sharedSite?.id ? sharedSite : { id: sharedSite }
		) || []
	)
	useEffect(() => {
		setValue('sharedSites', selectedRow)
	}, [selectedRow])

	return (
		<Stack mb={2} sx={{ height: '100%' }}>
			<CustomerSites
				overrideConfigTable={{
					title: 'agencies.shareSitesToAgency',
					tableConfig: listSitesTableConfigForSitesStep,
					customStateSelectedRow: {
						selectedRow,
						setSelectedRow,
					},
					elevationNbr: 0,
					hidden: {
						tableToolBar: {
							columnFilter: true,
							switchActive: true,
							tableExport: true,
							tableImport: true,
							tableBtnCreate: true,
						},
						tableToolBarAction: {
							deleteBulk: true,
						},
					},
					tableContainer: { disabledCheckBox: false, height: 'auto' },
				}}
				isInViewModal
			/>
		</Stack>
	)
}

export default SiteStep
