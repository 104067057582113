import { grey, red } from '@mui/material/colors'

export const formMultiSelectSearchCSS = {
	formMultiSelectSearchPopoverCSS: {
		'& .MuiPaper-root': {
			paddingBottom: '10px',

			'& .PopoverTop': {
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				padding: '0px 10px',

				'& .css-79elbk': {
					width: '100%',
					'& .MuiInputBase-root': {
						width: '100%',
						padding: '4px',
					},
				},
			},

			'& .SelectOption': {
				display: 'flex',
				justifyContent: 'flex-end',
				padding: '10px 20px 10px 10px',

				'& .SelectOptionButton': {
					color: grey[400],
					fontWeight: 400,
					transition: 'all .25s ease',

					'&:hover': {
						cursor: 'pointer',
						color: '#2F5DD1',
					},
				},
			},

			'& .MuiInputBase-input': {
				fontSize: '16px',
				fontWeight: '500',
				color: '#404040',
			},

			'& .PopoverBottom': {
				maxHeight: '35vh !important',
				overflowX: 'auto',
				marginRight: '10px',
				position: 'relative',
				width: '500px',

				'& .PopoverBottom-sort': {
					backgroundColor: grey[100],
					padding: '4px 26px',
					marginRight: '12px',
					fontWeight: '500',
					color: '#626262',
				},

				'& .PopoverBottom-box': {
					paddingTop: '50px',

					'& .PopoverBottom-box-checked': {
						background: 'rgba(33, 150, 243, 0.08)',
					},
				},

				'::-webkit-scrollbar': {
					width: '10px !important',
				},
			},
		},
	},

	formMultiSelectSearchCSSLabel: {
		position: 'absolute',
		width: '100%',
	},

	typography: {
		padding: '18px 25px 18px 10px',
		maxWidth: '400px',
		fontWeight: '500',
	},

	checkBoxCSS: {
		display: 'flex',
		alignItems: 'center',
		minHeight: '48px',
		'& .Mui-checked': {
			color: '#3F51B5',
		},
	},

	errorParagraphSX: {
		fontSize: '0.775rem !important',
		fontFamily: '"Roboto",sans-serif !important',
		fontWeight: '400 !important',
		lineHeight: '1.66 !important',
		textAlign: 'left !important',
		marginTop: '4px !important',
		marginRight: '14px !important',
		marginBottom: '0 !important',
		marginLeft: '14px !important',
		color: red[700],
	},

	missingTextCSS: {
		padding: '16px',
	},

	SelectItemCSS: {
		padding: '5px 20px',
		paddingRight: '10px',
		paddingLeft: '20px',
		height: '50px',
		width: '490px',
		minWidth: '465px',

		'& .SelectItemBoxCSS': {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			paddingLeft: '10px',
		},

		'& .selectItemCSS-left': {
			display: 'flex',

			'& svg': {
				marginRight: '12px',
			},
		},
	},

	SelectItemAllCSS: {
		position: 'fixed',
		backgroundColor: 'white',
		zIndex: '10',

		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '15px 20px',
		paddingRight: '10px',
		paddingLeft: '30px',
		height: '50px',
		width: '490px',
		minWidth: '465px',

		'& .selectItemCSS-left': {
			display: 'flex',

			'& svg': {
				marginRight: '12px',
			},
		},
	},

	formMultiSelectSearchChip: (isDisabled) => ({
		maxHeight: '25px !important',
		marginRight: '5px',
		backgroundColor: '#2F5DD1',
		color: '#c0cef1',
		// maxWidth:
		//     checkedOptionsCount < nbChipsToDisplay
		//         ? `calc(100% / ${checkedOptionsCount})`
		//         : `calc(100% / ${nbChipsToDisplay})`,
		//minWidth: 'fit-content', // Ajout de cette ligne
		display: 'inline-flex',
		justifyContent: 'space-between',
		zIndex: '2',
		opacity: isDisabled ? '.5' : '1',

		'& svg': {
			fill: '#c0cef1 !important',

			'&:hover': {
				fill: '#ffffff !important',
			},
		},
	}),

	boxContainer: (checkedOptionsCount, bordered) => ({
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		justifyContent: 'center',
		alignItems: 'flex-end;',
		alignContent: 'center',
		position: 'relative',

		'&:hover': {
			'& .boxContainer-fieldset': {
				border: '1px solid #2E2E2E',
			},
		},

		'& .boxContainer-fieldset': {
			height: checkedOptionsCount < 1 ? '35.7px' : '43px',
			display: bordered ? 'block' : 'none',
		},

		'& .boxContainer-legend': {
			display: checkedOptionsCount < 1 ? 'none' : 'flex',
			color: 'transparent',
			fontSize: '0.7em',
			padding: '0px 5px',
		},
	}),

	formMultiSelectSearchLabel: (checkedOptionsCount) => ({
		position: 'absolute',
		transform:
			checkedOptionsCount < 1
				? 'translate(0px, 0px)'
				: 'translate(-19%, -18px) scale(0.7)',
		paddingLeft: checkedOptionsCount < 1 ? '' : '5px',
		transition: 'all .2s ease',
		zIndex: '0',
		bottom: checkedOptionsCount < 1 ? '' : '10px',
	}),

	formMultiSelectSearchAvatar: {
		width: '28px',
		height: '28px',
		fontSize: '14px',
		marginRight: '7px',
		backgroundColor: '#2F5DD1!important',
		color: 'white',
		zIndex: '1',
	},
}

export const mainCSS = (
	isVisible,
	width,
	isDisabled,
	error,
	bordered,
	isRequired,
	maxWidth
) => ({
	maxWidth: `${maxWidth} !important`,
	width: width ? `${width}` : undefined,

	'& .boxContainer-fieldset': {
		position: 'absolute',
		width: '100%',

		//height: bordered ? '35.7px' : '42px',
		border: isDisabled
			? '1px solid rgba(0, 0, 0, 0.26)'
			: error
			? `1px solid ${red[700]}`
			: bordered
			? '1px solid #bdbdbd'
			: undefined,

		borderRadius: bordered ? '4px' : '0',

		'&:hover': {
			border: isDisabled
				? '1px solid rgba(0, 0, 0, 0.26)'
				: error && isRequired
				? `1px solid ${red[700]}`
				: bordered
				? '1px solid #616161'
				: undefined,
		},
	},

	'& .button-label-anim': {
		zIndex: '12',
	},

	'&:hover': {
		'& .formMultiSelectSearchCSSButtonAvatar': {
			opacity: '1',
			'& svg': {
				opacity: '1',
			},
		},
	},

	'& .buttonField': {
		backgroundColor: 'transparent',
		color: error ? red[700] : isDisabled ? '#bdbdbd' : grey[700],
		justifyContent: bordered && 'space-between',
		padding: '0px',
		display: bordered ? 'flex' : undefined,
		alignItems: bordered ? 'center' : undefined,
		height: bordered ? '35.7px' : '40px',
		transition: 'none',
		width: '100%',
		textTransform: 'none',

		'&:hover': {
			backgroundColor: bordered ? 'transparent !important' : undefined,
			border: isDisabled ? '1px solid rgba(0, 0, 0, 0.26) !important' : '',
		},
		'& .overflow': {
			width: '99%',
			height: '100%',
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'nowrap',
			justifyContent: 'space-between',
			alignItems: 'center',
			alignContent: 'center',
			overflow: 'hidden',
			padding: bordered ? '6px 8px 6px 16px !important' : undefined,

			'& > div': {
				display: 'flex',
				alignItems: 'center',
				height: bordered ? '35.7px' : undefined,

				'&.buttonField-content': {
					width: 'calc(100% - 65px)',
				},

				'&.buttonField-indicator': {
					width: 'auto',
					zIndex: '11',
				},
			},

			'& .MuiSvgIcon-root': {
				transform: isVisible ? 'rotate(180deg)' : 'rotate(0deg)',
			},
		},
	},

	'& svg': {
		fill: isDisabled ? '#bdbdbd' : '#757575',
	},

	'& .MuiAvatar-root': {
		color: isDisabled ? '#bdbdbd !important' : undefined,
	},

	'& .messageBottom': {
		color: error ? `${red[700]} !important` : undefined,
	},

	'& .formMultiSelectSearchCSSButtonAvatar': {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: `center`,
		alignItems: `center`,
		width: '28px',
		height: '28px',
		fontSize: '14px',
		backgroundColor: 'transparent',
		opacity: '0',
		transition: 'opacity .1s ease',

		'&:hover': {
			backgroundColor: `${grey[100]} !important`,
		},

		'& svg': {
			margin: '0 !important',
			fill: '#616161',
			width: '20px',
			height: '20px',
			opacity: '0',
		},
	},
})
