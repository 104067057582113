/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import translateIntlMsgString from '../../../../../utils/translateIntlMsgString'
import { TypeTableConfigImport } from '../../../../../utils/typescript/tableType'

export const listSitesTableImport: TypeTableConfigImport[] = [
	{
		colName: translateIntlMsgString('importTable.siteName'),
		argumentName: 'name',
		type: 'string',
		required: true,
	},
	{
		colName: translateIntlMsgString('importTable.address'),
		argumentName: 'address',
		type: 'string',
		required: true,
	},
	{
		colName: translateIntlMsgString('importTable.zipCode'),
		argumentName: 'zipcode',
		type: 'string',
	},
	{
		colName: translateIntlMsgString('importTable.city'),
		argumentName: 'city',
		type: 'string',
	},
	{
		colName: translateIntlMsgString('importTable.country'),
		argumentName: 'country',
		type: 'string',
	},
	{
		colName: translateIntlMsgString('importTable.siret'),
		argumentName: 'siret',
		type: 'string',
	},
	{
		colName: translateIntlMsgString('importTable.phone'),
		argumentName: 'phone',
		type: 'string',
	},
	{
		colName: translateIntlMsgString('importTable.cellphone'),
		argumentName: 'cellphone',
		type: 'string',
	},
]
