import React, { cloneElement, useContext, useEffect, useState } from 'react'
import { ModalContext } from '../../../../utils/contexts/ModalContext'
import { AuthContext } from '../../../../utils/contexts/AuthContext'
import { useLazyVehicleMovementForModal } from '../../../../api/gql/queries/vehiclesQueries'
import { EFormModalMode } from '../../../../utils/app-models'

interface IWVehicleTrackingModalProvider {
	editedVehicleTrackingId?: string
	isVisible: boolean
	children: any
}

const WVehicleTrackingModalProvider: React.FC<
	IWVehicleTrackingModalProvider
> = ({ editedVehicleTrackingId = null, isVisible, children }) => {
	const { loading, vehicleMovement, getVehicleMovement } =
		useLazyVehicleMovementForModal()

	const { currentUser } = useContext(AuthContext)

	const {
		mode,
		formUtils: { setValue },
	} = useContext(ModalContext)

	const [isLoading, setIsLoading] = useState(
		editedVehicleTrackingId && mode === EFormModalMode.edit ? true : false
	)

	useEffect(() => {
		if (isVisible && mode === EFormModalMode.edit && editedVehicleTrackingId)
			getVehicleMovement({
				variables: { id: editedVehicleTrackingId },
			})
	}, [isVisible, editedVehicleTrackingId, mode])

	useEffect(() => {
		if (!loading && vehicleMovement) {
			setValue('entryDate', vehicleMovement?.entryDate)
			setValue('exitDate', vehicleMovement?.exitDate)
			setIsLoading(false)
		}
	}, [loading, vehicleMovement, currentUser])

	return cloneElement(children, {
		loading: isLoading,
		dateCreated: vehicleMovement?.dateCreated,
		entryBy: vehicleMovement?.entryBy,
	})
}

export default WVehicleTrackingModalProvider
