/* eslint-disable react/display-name */
import React, {
	memo,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ViewModalContext } from '../../utils/contexts/ViewModalContext'
import qs from 'qs'
import getViewModalConfig from './getViewModalConfig'
import { AuthContext } from '../../utils/contexts/AuthContext'
import ViewModal from '.'
import { getAccessByQueryName } from '../../utils/accessUtils'

interface IViewModalProvider {}

const ViewModalProvider: React.FC<IViewModalProvider> = memo(() => {
	const location = useLocation()
	const navigate = useNavigate()
	const [viewModalList, setViewModalList] = useState<any[]>([])
	const { checkPermissions, checkModules } = useContext(AuthContext)

	const onViewModalClose = useCallback(() => {
		const index = location.pathname.indexOf('modalview')
		if (index !== -1) navigate(location.pathname.slice(0, index - 1))
		else navigate(location.pathname)
		setViewModalList([])
	}, [location])

	const goPrevViewModal = useCallback(() => {
		if (viewModalList?.length < 2) onViewModalClose()
		else {
			const tmpViewModalList = [...viewModalList]
			tmpViewModalList.pop()
			setViewModalList(tmpViewModalList)

			navigate(
				`${location.pathname}${
					tmpViewModalList[tmpViewModalList?.length - 1].searchArgs
				}`
			)
		}
	}, [viewModalList, onViewModalClose])

	useEffect(() => {
		if (viewModalList?.at(-1)?.searchArgs !== location.search) {
			const { query, varType, varValue } = qs.parse(location.search, {
				ignoreQueryPrefix: true,
			})

			if (query && varType && varValue) {
				const { permissions, modules } = getAccessByQueryName(query)

				if (!checkPermissions(permissions) || !checkModules(modules))
					navigate(-1)
				else
					setViewModalList([
						...viewModalList,
						{
							searchArgs: location.search,
							viewModalConfig: getViewModalConfig({
								queryName: query,
								varType,
								varValue,
							}),
						},
					])
			}
		}
	}, [location, navigate, viewModalList])

	const context = useMemo(
		() => ({
			goPrevViewModal,
			onViewModalClose,
		}),
		[goPrevViewModal, onViewModalClose]
	)

	return (
		<ViewModalContext.Provider value={context}>
			{viewModalList.map((viewModalInfos, i) => (
				<ViewModal
					viewModalInfos={viewModalInfos}
					key={`${i}-${viewModalInfos.searchArgs}`}
					index={i}
				/>
			))}
		</ViewModalContext.Provider>
	)
})
export default ViewModalProvider
