import { gql, useMutation } from '@apollo/client'

export const UPDATE_DOCUMENT_VEHICLE = gql`
	mutation updateDocument($input: updateDocumentInput!) {
		updateDocument(input: $input) {
			document {
				id
			}
		}
	}
`

export const CREATE_DOCUMENT_VEHICLE = gql`
	mutation createDocument($input: createDocumentInput!) {
		createDocument(input: $input) {
			document {
				id
			}
		}
	}
`

export const DELETE_DOCUMENT_VEHICLE = gql`
	mutation deleteDocument($input: deleteDocumentInput!) {
		deleteDocument(input: $input) {
			document {
				id
			}
		}
	}
`
export const REMOVE_DOCUMENT_VEHICLE = gql`
	mutation removeDocument($input: removeDocumentInput!) {
		removeDocument(input: $input) {
			document {
				id
			}
		}
	}
`

export const BULK_DOCUMENT_VEHICLE_ACTION = gql`
	mutation bulkOperationDocument($input: bulkOperationDocumentInput!) {
		bulkOperationDocument(input: $input) {
			document {
				id
			}
		}
	}
`

export const useUpdateDocumentVehicle = () => useMutation(UPDATE_DOCUMENT_VEHICLE)
export const useCreateDocumentVehicle = () => useMutation(CREATE_DOCUMENT_VEHICLE)
export const useDeleteDocumentVehicle = (): any => useMutation(DELETE_DOCUMENT_VEHICLE)
export const useRemoveDocumentVehicle = (): any => useMutation(REMOVE_DOCUMENT_VEHICLE)
export const useBulkDocumentVehicleAction = (): any =>
	useMutation(BULK_DOCUMENT_VEHICLE_ACTION)
