import React, { useCallback, useMemo } from 'react'

import { useNavigate, useLocation } from 'react-router-dom'
import { Typography, Stack, Divider, Box } from '@mui/material'

import { useFormatMessage } from '../../../../../utils/hooks'
import { ROUTES } from '../../../../../routes/consts'
import { EPermissionScope } from '../../../../../utils/app-models'
import EventTransportDate from './EventTransportDate'
import translateIntlMsgString from '../../../../../utils/translateIntlMsgString'
import UserAvatar from '../../../../../components/avatar/UserAvatar'
import CalendarEventFooter from '../CalendarEventFooter'
import { useRemoveIntervention } from '../../../../../api/gql/mutations/interventionsMutation'
import ChipCountListPopover from '../../../../../components/multiItemsPopoverList/popover/simpleListPopover'
import TasksTransport from '../../../../../components/multiItemsPopoverList/popover/taskTransport'
import CalendarEventHeader from '../CalendarEventHeader'

const subtitle1CSS = { fontWeight: '500', lineHeight: 1.5 }
const body1CSS = { lineHeight: 1.5 }
const width33 = { width: '33%' }
const dividerCSS = {
	position: 'relative',
	width: ' 110%',
	marginLeft: '-5%',
	mt: 2,
	mb: 2,
}

interface IInterventionTransportEventInfo {
	event: any
	handleClose: any
	setOpenModalIntervention: any
	setFormModalEdit: any
}

const InterventionTransportEventInfo: React.FC<
	IInterventionTransportEventInfo
> = ({ event, handleClose, setOpenModalIntervention, setFormModalEdit }) => {
	const intlMsg = useFormatMessage()
	const navigate = useNavigate()
	const location = useLocation()

	const { vehicleDetail, vehicleModelText, vehicleBrand } =
		event?.resource?.interventionControlProgrammedVehicles[0]?.vehicle || {}

	const handleEdit = useCallback(() => {
		setOpenModalIntervention(true)
		handleClose()
	}, [])

	const handleView = useCallback(() => {
		navigate(
			`${location?.pathname}${ROUTES.VIEWMODAL.INTERVENTION_TRANSPORT.url}${event?.resource?.id}`
		)
		handleClose()
	}, [event, location?.pathname, navigate])

	const listAgencies = useMemo(
		() =>
			event?.resource?.agencies?.map(({ agencyCustomDetails, id }) => ({
				name: agencyCustomDetails[0]?.agencyName,
				id,
			})) || [],
		[event?.resource?.agencies]
	)

	return (
		<Stack sx={{ minWidth: '400px' }}>
			<Box p={2}>
				<CalendarEventHeader
					interType={translateIntlMsgString(
						`interventions.${event?.resource?.interventionTransportType?.code}`
					)}
					title={event?.title}
					status={event?.resource?.status?.name}
					statusColor={event?.resource?.status?.color}
					handleClose={handleClose}
				/>

				<Stack spacing={1}>
					<Stack direction='row'>
						<Typography color='inherit' variant='caption' sx={width33}>
							{intlMsg('misc.account')}
						</Typography>
						<Typography color='inherit' variant='caption'>
							{event?.resource?.orderingCustomer?.name}
						</Typography>
					</Stack>

					<Stack direction='row'>
						<Typography color='inherit' variant='caption' sx={width33}>
							{intlMsg('interventions.agencies')}
						</Typography>

						<ChipCountListPopover
							text='misc.agencies'
							data={listAgencies}
							viewModalLink={ROUTES.VIEWMODAL.AGENCIES.url}
							permissions={[
								EPermissionScope.networkView,
								EPermissionScope.networkManage,
							]}
						/>
					</Stack>

					<Stack direction='row'>
						<Typography color='inherit' variant='caption' sx={width33}>
							{intlMsg('interventions.actors')}
						</Typography>

						{event?.resource?.actors?.length ? (
							<Stack ml={-3} mt={0.75}>
								{event?.resource?.actors?.map(
									({ id, firstName, lastName, account }, i) => (
										<UserAvatar
											key={`actors-${i}`}
											userName={{ firstName, lastName }}
											userId={id}
											displayName
											accountId={account.id}
											listItemSX={{ pt: 0, mt: -1 }}
										/>
									)
								)}
							</Stack>
						) : (
							'-'
						)}
					</Stack>
				</Stack>

				<Divider flexItem sx={dividerCSS} variant='fullWidth' />

				<EventTransportDate
					interventionTransportTrips={
						event?.resource?.interventionTransportTrips
					}
				/>

				<Divider flexItem sx={dividerCSS} variant='fullWidth' />

				<Stack direction='row'>
					<Typography color='inherit' variant='caption' sx={width33}>
						{intlMsg('misc.vehicle')}
					</Typography>

					<Stack
						direction='row'
						alignItems='center'
						justifyContent='space-between'
					>
						<Stack direction='row' spacing={3}>
							<Stack justifyContent='center'>
								{vehicleDetail?.immat ? (
									<Typography variant='subtitle1' sx={subtitle1CSS}>
										{vehicleDetail.immat}
									</Typography>
								) : (
									'-'
								)}
								{vehicleDetail?.vin ? (
									<Typography variant='body1' sx={body1CSS}>
										{vehicleDetail.vin}
									</Typography>
								) : (
									'-'
								)}
							</Stack>

							<Stack>
								{vehicleBrand?.name ? (
									<Typography variant='subtitle1' sx={subtitle1CSS}>
										{vehicleBrand?.name}
									</Typography>
								) : (
									'-'
								)}
								{vehicleModelText ? (
									<Typography variant='body1' sx={body1CSS}>
										{vehicleModelText}
									</Typography>
								) : (
									'-'
								)}
							</Stack>
						</Stack>
					</Stack>
				</Stack>

				<Stack direction='row' mt={1}>
					<Typography color='inherit' variant='caption' sx={width33}>
						{intlMsg('misc.services')}
					</Typography>

					<TasksTransport
						removalProducts={
							event?.resource?.interventionTransportTrip?.removalProducts
								?.collection
						}
						deliveryProducts={
							event?.resource?.interventionTransportTrip?.deliveryProducts
								?.collection
						}
					/>
				</Stack>
			</Box>

			<CalendarEventFooter
				permissionsName={{
					update: [EPermissionScope.interventionUpdate],
					remove: [EPermissionScope.interventionRemove],
					duplicate: [EPermissionScope.interventionCreate],
				}}
				deleted={{
					query: useRemoveIntervention,
					refreshQuery: [
						'interventionsTransportList',
						'getInterventionTransportNumbersByCustomer',
						'getInterventionTransportNumbersBySite',
						'getInterventionTransportNumbersByVehicle',
						'interventionsListForCalendar',
					],
					successMessageDelete: 'success.interventionDeleted',
					messageConfirm: {
						title: 'confirmModal.deleteInterventionTitle',
						message: 'confirmModal.deleteInterventionMessage',
					},
				}}
				handleEdit={handleEdit}
				handleView={handleView}
				handleCloseModalInfos={handleClose}
				interventionId={event?.resource?.id}
				setFormModalEdit={setFormModalEdit}
			/>
		</Stack>
	)
}

export default InterventionTransportEventInfo
