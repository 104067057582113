/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { gql, useMutation } from '@apollo/client'

/**  ---------------------MUTATIONS---------------------- */

export const CREATE_AGENCY = gql`
	mutation createAgencyAccount($input: createAgencyAccountInput!) {
		createAgencyAccount(input: $input) {
			account {
				id
			}
		}
	}
`

export const UPDATE_AGENCY = gql`
	mutation updateAgencyAccount($input: updateAgencyAccountInput!) {
		updateAgencyAccount(input: $input) {
			account {
				id
			}
		}
	}
`

export const UPDATE_AGENCY_STATUS = gql`
	mutation updateStatusAgencyAccount($input: updateStatusAgencyAccountInput!) {
		updateStatusAgencyAccount(input: $input) {
			account {
				id
			}
		}
	}
`

export const REMOVE_AGENCY = gql`
	mutation removeAgencyAccount($input: removeAgencyAccountInput!) {
		removeAgencyAccount(input: $input) {
			account {
				id
			}
		}
	}
`

export const DELETE_AGENCY = gql`
	mutation deleteAgencyAccount($input: deleteAgencyAccountInput!) {
		deleteAgencyAccountInput(input: $input) {
			account {
				id
			}
		}
	}
`

export const BULK_AGENCY_ACTION = gql`
	mutation bulkOperationAgencyAccount(
		$input: bulkOperationAgencyAccountInput!
	) {
		bulkOperationAgencyAccount(input: $input) {
			account {
				id
			}
		}
	}
`

/**  ---------------------HOOKS---------------------- */

export const useCreateAgency = () => useMutation(CREATE_AGENCY)
export const useUpdateAgency = () => useMutation(UPDATE_AGENCY)
export const useUpdateAgencyStatus = () => useMutation(UPDATE_AGENCY_STATUS)

export const useRemoveAgency = () => useMutation(REMOVE_AGENCY)
export const useDeleteAgency = () => useMutation(DELETE_AGENCY)
export const useBulkAgencyAction = () => useMutation(BULK_AGENCY_ACTION)
