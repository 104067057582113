/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import {
	InputText,
	SwitchForm,
	SelectForm,
} from '../../../../../../components/forms/v1/formFields'

import {
	EFormTemplatePictureVehicleType,
	EFormTemplateRatingType,
	EFormTemplateTitleType,
} from '../../../../../../utils/app-models'
import { useFormatMessage } from '../../../../../utils../../../utils/hooks'

export const RequireFieldTxt = ({ control, ...rest }) => {
	const intlMsg = useFormatMessage()
	return (
		<SwitchForm
			name={'isRequired'}
			label={intlMsg('formTemplate.formRequire')}
			control={control}
			{...rest}
		/>
	)
}

export const TitleField = ({ control, ...rest }) => {
	const intlMsg = useFormatMessage()
	return (
		<InputText
			name='titleField'
			label={intlMsg('formTemplate.formTitleField')}
			control={control}
			{...rest}
		/>
	)
}

export const DefaultValueField = ({ control, selectElements, ...rest }) => {
	const intlMsg = useFormatMessage()
	return (
		<SelectForm
			name='defaultValue'
			label={intlMsg('formTemplate.defaultValue')}
			control={control}
			selectElements={selectElements}
			{...rest}
		/>
	)
}

export const VerificationTypeField = ({ control, selectElements, ...rest }) => {
	const intlMsg = useFormatMessage()
	return (
		<SelectForm
			name='verificationType'
			label={intlMsg('formTemplate.verificationType')}
			control={control}
			selectElements={selectElements}
			isRequired
			{...rest}
		/>
	)
}

export const CanAlertField = ({ control, ...rest }) => {
	const intlMsg = useFormatMessage()
	return (
		<SwitchForm
			name={'canAlert'}
			label={intlMsg('formTemplate.canAlert')}
			control={control}
			{...rest}
		/>
	)
}

export const SelectedChoiceToAlertField = ({
	control,
	selectElements,
	...rest
}) => {
	const intlMsg = useFormatMessage()
	return (
		<SelectForm
			name='selectedChoiceToAlert'
			label={intlMsg('formTemplate.selectedChoiceToAlert')}
			control={control}
			selectElements={selectElements}
			isRequired
			{...rest}
		/>
	)
}

export const SelectionTypeField = ({ control, selectElements, ...rest }) => {
	const intlMsg = useFormatMessage()
	return (
		<SelectForm
			isRequired
			name='selectionType'
			label={intlMsg('formTemplate.selectionType')}
			control={control}
			selectElements={selectElements}
			{...rest}
		/>
	)
}

export const SelectRatingTypeField = ({ control, ...rest }) => {
	const intlMsg = useFormatMessage()
	return (
		<SelectForm
			isRequired
			name='ratingType'
			label={intlMsg('formTemplate.ratingType')}
			control={control}
			selectElements={Object.values(EFormTemplateRatingType).map((e, i) => ({
				key: `${e}-${i}`,
				label: intlMsg(`formTemplate.${e}`),
				value: e,
			}))}
			{...rest}
		/>
	)
}

export const SelectVehicleTypeField = ({ control, ...rest }) => {
	const intlMsg = useFormatMessage()
	return (
		<SelectForm
			name='vehicleType'
			label={intlMsg('formTemplate.vehicleType')}
			control={control}
			selectElements={Object.values(EFormTemplatePictureVehicleType).map(
				(e, i) => ({
					key: `${e}-${i}`,
					label: intlMsg(`formTemplate.${e}`),
					value: e,
				})
			)}
			{...rest}
		/>
	)
}

export const SelectTitleTypeField = ({ control, ...rest }) => {
	const intlMsg = useFormatMessage()
	return (
		<SelectForm
			isRequired
			name='titleType'
			label={intlMsg('formTemplate.titleType')}
			control={control}
			selectElements={Object.values(EFormTemplateTitleType).map((e, i) => ({
				key: `${e}-${i}`,
				label: intlMsg(`formTemplate.${e}`),
				value: e,
			}))}
			{...rest}
		/>
	)
}

export const InstructionField = ({ control, ...rest }) => {
	const intlMsg = useFormatMessage()
	return (
		<InputText
			name='instructionText'
			label={intlMsg('formTemplate.instructionText')}
			control={control}
			rows={10}
			rowsMax={25}
			multiline
			{...rest}
		/>
	)
}
