import React, { useCallback, useContext, useState } from 'react'

import { useFieldArray } from 'react-hook-form'
import { DragDropContext } from 'react-beautiful-dnd'

import Paper from '@mui/material/Paper'

import PagesContent from './pagesContent/'
import PagesSelection from './pageSelection'
import { onDragEndPage } from './formEditionsUtils/dragAndDropUtils'
import { FormTemplateContext } from '../../../../utils/contexts/FormTemplateContext'

interface IFormsEdition {
	control
}

const FormsEdition: React.FC<IFormsEdition> = ({ control }) => {
	const [selectedPageNbr, setSelectedPageNbr] = useState(0)

	const {
		fields: pages,
		insert: insertPage,
		remove: removePage,
		update: updatePage,
	} = useFieldArray({
		control,
		name: 'pages',
	})

	const {
		formUtils: { setValue, getValues },
	} = useContext(FormTemplateContext)

	const handleMovePage = useCallback((source, destination) => {
		const newPages = getValues('pages')

		const tmpValue = newPages[source]
		newPages[source] = newPages[destination]
		newPages[destination] = tmpValue

		setValue('pages', [...newPages])
	}, [])

	return (
		<Paper sx={{ width: 1, minHeight: '45%', display: 'flex' }}>
			<DragDropContext
				onDragEnd={onDragEndPage(handleMovePage, setSelectedPageNbr)}
			>
				<PagesSelection
					selectedPageNbr={selectedPageNbr}
					setSelectedPageNbr={setSelectedPageNbr}
					pages={pages}
					insertPage={insertPage}
				/>
			</DragDropContext>

			{pages?.map(
				(page, i) =>
					i === selectedPageNbr && (
						<PagesContent
							key={page.id}
							pageNbr={i}
							removePage={removePage}
							selectedPageNbr={selectedPageNbr}
							setSelectedPageNbr={setSelectedPageNbr}
							control={control}
							page={page}
							updatePage={updatePage}
						/>
					)
			)}
		</Paper>
	)
}

export default FormsEdition
