import { Box } from '@mui/material'
import React, { useContext } from 'react'
import FilterDate from '../../../../../components/filters/filtersComponents/filterDate'
import { FiltersContext } from '../../../../../components/filters/filtersContext'
import FiltersBtnProvider from '../../../../../components/filters/filtersBtn'
import IpvTransportFiltersBtnComponent from './IpvTransportFiltersBtnComponent'

interface IIpvTransportFilters {}

const IpvTransportFiltersSX = {
	display: 'flex',
}

const IpvTransportFilters: React.FC<IIpvTransportFilters> = () => {
	const { setFilters, filters } = useContext(FiltersContext)

	return (
		<Box sx={IpvTransportFiltersSX}>
			<FilterDate
				setFiltersQuerieParam={setFilters}
				filtersQuerieParam={filters}
				argumentQuerieName='startFrom'
			/>
			<FiltersBtnProvider>
				<IpvTransportFiltersBtnComponent />
			</FiltersBtnProvider>
		</Box>
	)
}

export default IpvTransportFilters
