import React from 'react'
import { Box } from '@mui/material'
import { stringifiedColor } from '../../utils/color'

interface ISquareColor {
	statusColor: string
}

const SquareColor: React.FC<ISquareColor> = ({ statusColor }) => (
	<Box
		sx={{
			height: '11px',
			width: '11px',
			marginRight: '10px',
			borderRadius: 1,
			backgroundColor: stringifiedColor(statusColor, 400),
		}}
	/>
)

export default SquareColor
