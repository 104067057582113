import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'

import CircularProgress from '@mui/material/CircularProgress'
import { Grid, Stack } from '@mui/material'

import api from '../../api/axios/axios'
import SetPassword from './SetPassword'
import InvalidToken from './InvalidToken'
import LayoutLogout from '../../components/layout/LayoutLogout'
import { blockLeftCSS } from '../../styles/sxCSS/logout/logout'

interface IUserConfirmation {}

const UserConfirmation: React.FC<IUserConfirmation> = () => {
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(false)
	const { confirmationToken } = useParams()

	useEffect(() => {
		if (confirmationToken) {
			api
				.post('/_ctrl/users/confirm-token-check', {
					confirmationToken,
				})
				.then(() => {
					setLoading(false)
					setError(false)
				})
				.catch((error) => {
					toast.error(error.message)
					setError(true)
					setLoading(false)
				})
		} else {
			setError(true)
			setLoading(false)
		}
	}, [confirmationToken])

	return (
		<LayoutLogout>
			<Grid container sx={{ height: 1 }}>
				<Grid
					item
					xs={12}
					md={6}
					sx={{
						...blockLeftCSS,
						alignItems: 'left',
						justifyContent: 'left',
						pl: 0,
					}}
				>
					<Stack
						p={4}
						sx={{
							width: { xs: 300, md: 400 },
							alignItems: 'left',
							justifyContent: 'center',
							pl: 0,
						}}
					>
						<img src={`/img/illustration/invitation.svg`} />
					</Stack>
				</Grid>

				<Grid item xs={12} md={6}>
					<Stack p={4} spacing={2} justifyContent='center' sx={{ height: 1 }}>
						{loading ? (
							<CircularProgress size={24} sx={{ position: 'absolute' }} />
						) : error ? (
							<InvalidToken />
						) : (
							<SetPassword confirmationToken={confirmationToken} />
						)}
					</Stack>
				</Grid>
			</Grid>
		</LayoutLogout>
	)
}

export default UserConfirmation
