import React, { useCallback, useContext, useMemo } from 'react'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import { Box, Switch, ListSubheader, ListItemText } from '@mui/material'
import { useFormatMessage } from '../../../../utils/hooks'
import { FormModalContext } from '../../../../components/forms/formModal/FormModalContext'

const listCSS = {
	width: 1,
	backgroundColor: 'transparent',
	mb: 1,
	borderBottom: 'solid 1px #dddddd5c',
	'& div.MuiButtonBase-root': {
		borderRadius: 1,
	},
}
const wrapperModalCSS = {
	p: 4,
	overflow: 'auto',
}
const ListSubheaderCSS = {
	textTransform: 'uppercase',
	fontSize: '1rem',
	fontWeight: 700,
}

const ListItemTextCSS = {
	'& .MuiListItemText-primary': { fontSize: '0.9rem', fontWeight: 500 },
	'& .MuiListItemText-secondary': { fontSize: '0.8rem' },
}

interface IUserPermissions {}

const UserPermissions: React.FC<IUserPermissions> = () => {
	const intlMsg = useFormatMessage()
	const { data, setValue } = useContext(FormModalContext)
	const possibleUserPermissions = useMemo(
		() => data?.userType?.value?.permissions,
		[data?.userType]
	)

	const uniqueCategoryPermissions = useMemo(
		() =>
			Array.from(
				new Set(possibleUserPermissions?.map((perm: any) => perm.category))
			) || [],
		[possibleUserPermissions]
	)

	const handleOnClick = useCallback(
		(permissionId, isChecked) => () => {
			setValue(permissionId, isChecked)
		},
		[setValue]
	)

	return (
		<Box sx={wrapperModalCSS}>
			{uniqueCategoryPermissions?.map((uniqueCategory, i) => (
				<List
					key={`permissions-${i}`}
					subheader={
						<ListSubheader disableSticky={true} sx={ListSubheaderCSS}>
							{intlMsg(`permissions.${uniqueCategory}`) as string}
						</ListSubheader>
					}
					sx={listCSS}
				>
					{possibleUserPermissions?.map(({ category, id, name }) => {
						const checked = data?.[id]
						return (
							uniqueCategory === category && (
								<ListItem
									key={id}
									button
									role={undefined}
									onClick={handleOnClick(id, !data[id])}
								>
									<ListItemText
										primary={intlMsg(`permissions.${name}`)}
										secondary={intlMsg(`permissions.${name}:explanation`)}
										sx={ListItemTextCSS}
									/>
									<ListItemSecondaryAction>
										<Switch
											onChange={handleOnClick(id, !data[id])}
											checked={checked}
											size='small'
											color={checked ? 'primary' : 'default'}
											value={checked}
										/>
									</ListItemSecondaryAction>
								</ListItem>
							)
						)
					})}
				</List>
			))}
		</Box>
	)
}

export default UserPermissions
