import React, { useMemo } from 'react'

import { useFormatMessage } from '../../utils/hooks'
import { styled } from '@mui/material/styles'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { grey } from '@mui/material/colors'

const icons = { help: HelpOutlineIcon }

const HelpOutlineIconCSS = {
	width: '20px',
	height: '20px',
	margin: '0px 12px',
	color: '#2F5DD1',
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} arrow />
))(() => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: '#EAF4FC',
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#EAF4FC',
		color: grey[800],
		width: 'fit-content',
		maxWidth: '300px',
		borderRadius: '6px',
		padding: '10px 15px',
		fontWeight: '400',
		fontSize: '13px',
	},
}))

interface ICustomIconTooltips {
	label: string
	type: 'help'
	placement?:
		| 'right'
		| 'bottom'
		| 'left'
		| 'top'
		| 'bottom-end'
		| 'bottom-start'
		| 'left-end'
		| 'left-start'
		| 'right-end'
		| 'right-start'
		| 'top-end'
		| 'top-start'
		| undefined
}

const CustomIconTooltips: React.FC<ICustomIconTooltips> = ({
	label,
	type,
	placement = 'right',
}) => {
	const intlMsg = useFormatMessage()
	const Icon = useMemo(() => icons[type], [type])

	return (
		<LightTooltip title={intlMsg(label)} placement={placement}>
			<Icon sx={HelpOutlineIconCSS} />
		</LightTooltip>
	)
}
export default CustomIconTooltips
