import React, { useState, useCallback, useContext, useMemo } from 'react'
import qs from 'qs'

import Popover from '@mui/material/Popover'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import GetAppIcon from '@mui/icons-material/GetApp'
import { Box, Stack, Button, IconButton, Alert } from '@mui/material'

import { useFormatMessage } from '../../../../utils/hooks'
import { TableContext } from '../../../../utils/contexts/TableContext'
import { ExportContext } from '../../../../utils/contexts/ExportContext'
import { AuthContext } from '../../../../utils/contexts/AuthContext'
import { EUserTypes } from '../../../../utils/app-models'
import { FiltersContext } from '../../../filters/filtersContext'

const typographyCSS = { mb: 2, fontWeight: 500 }

interface ITableExport {
	exportConfig
	actif
}

const TableExport: React.FC<ITableExport> = ({ exportConfig, actif }) => {
	const intlMsg = useFormatMessage()
	const { filtersQuerieParam, search, currentOrderBy, isInViewModal } =
		useContext(TableContext) || {}
	const { filters } = useContext(FiltersContext)
	const { currentUser } = useContext(AuthContext)
	const [anchorEl, setAnchorEl] = useState(null)
	const handleClick = useCallback((e) => setAnchorEl(e.currentTarget), [])
	const handleClose = useCallback(() => {
		setAnchorEl(null)
	}, [])

	const { query, varValue } = qs.parse(location.search, {
		ignoreQueryPrefix: true,
	})

	const canExport = useMemo(
		() => currentUser?.userType?.name !== EUserTypes.operator,
		[currentUser?.userType]
	)

	// ///////////////////////////////////////////////////////////////////////////////
	// NEW EXPORT
	// ///////////////////////////////////////////////////////////////////////////////

	const {
		setIsProcessingExport,
		isProcessingExport,
		setExportConfig,
		resetOnClose,
	} = useContext(ExportContext) || {}

	const exportAllTable = useCallback(() => {
		setIsProcessingExport(true)

		const additionalQueryParam = {}

		if (isInViewModal && varValue)
			additionalQueryParam[query as string] = varValue

		const variables = {
			timezone: new Date().getTimezoneOffset(),
			isActive: actif,
			...additionalQueryParam,
			...(varValue && { [exportConfig.argumentQueryViewModal]: varValue }), // if varValue we are on viewModal
			...(exportConfig?.othersQueryParams && {
				...exportConfig.othersQueryParams,
			}),
			...(filters && { ...filters }),
			...(search && { ...search }),
			...(currentOrderBy && { orderBy: [currentOrderBy] }),
		}
		setExportConfig({ variables, exportQuery: exportConfig?.exportQuery })
		handleClose()
	}, [
		filtersQuerieParam,
		search,
		varValue,
		exportConfig,
		actif,
		currentOrderBy,
	])

	const cancelPreviousExport = useCallback(() => {
		resetOnClose()
	}, [])

	return (
		<>
			{canExport && (
				<Tooltip title={intlMsg('misc.exportData')}>
					<IconButton onClick={handleClick} size='medium'>
						<CloudDownloadIcon />
					</IconButton>
				</Tooltip>
			)}

			{anchorEl && (
				<Popover
					open={Boolean(anchorEl)}
					anchorEl={anchorEl}
					onClose={handleClose}
					sx={{ p: 5 }}
				>
					<Box
						sx={{
							p: 5,
							minHeight: !exportConfig?.exportQuery ? '240px' : 'inherit',
							maxWidth: !exportConfig?.exportQuery ? '260px' : '320px',
						}}
					>
						<Typography variant='body1' gutterBottom sx={typographyCSS}>
							{intlMsg('misc.exportDataToExcel')}
						</Typography>

						<Stack justifyContent='end' mt={2} spacing={2}>
							{isProcessingExport ? (
								<>
									<Alert severity='warning'>
										{intlMsg('exportTable.alertCurrentExport')}
									</Alert>

									<Button
										variant='outlined'
										color='primary'
										disableElevation
										onClick={cancelPreviousExport}
										fullWidth
									>
										{intlMsg('exportTable.cancelExport')}
									</Button>
								</>
							) : (
								<Button
									variant='contained'
									color='primary'
									disableElevation
									onClick={exportAllTable}
									endIcon={<GetAppIcon />}
									fullWidth
									disabled={isProcessingExport}
								>
									{intlMsg('misc.export')}
								</Button>
							)}
						</Stack>
					</Box>
				</Popover>
			)}
		</>
	)
}

export default TableExport
