import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Box } from '@mui/material'

const annotationCountCSS = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: '#dd3444',
	width: '20px',
	textAlign: 'center',
	borderRadius: '50%',
	color: 'white',
	position: 'absolute',
	right: '10px',
	bottom: '15px',
}

const annotationHandlerCSS = {
	position: 'absolute',
	zIndex: 1,
	outline: ' 2px solid #dc3545',
	borderRadius: ' 100%',
	transition: '0.3s',
	transitionDelay: '0.05s',

	'&.maskAnnotation': {
		outline: '2px solid rgba(220, 53, 70, 0)',
		transition: '0.4s',
		transitionDelay: '0.05s',

		'& .annotationNumber': {
			color: '#ffffff00',
			backgroundColor: '#dd344400',
			transition: '0.4s',
			transitionDelay: '0.05s',
		},
	},
}

const annotationNumberCSS = {
	'&.annotationNumber': {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: '#dd3444',
		width: '20px',
		textAlign: 'center',
		borderRadius: '50%',
		color: 'white',
		position: 'relative',
		left: 'calc(50% - 10px)',
		top: 'calc(0% - 25px)',
	},
}

const img = (isModal) => ({
	maxWidth: '100%',
	minWidth: '100%',
	height: 'auto',
	borderRadius: 1,
	maxHeight: !isModal ? '170px' : 'inherit',
	minHeight: !isModal ? '170px' : 'inherit',
})

const containerGabarit = (isModal) => ({
	maxHeight: !isModal ? '170px' : 'inherit',
	minHeight: !isModal ? '170px' : 'inherit',
	maxWidth: !isModal ? '226.66px' : '100%',
	minWidth: !isModal ? '226.66px' : '100%',
	aspectRatio: '4/3',
	backgroundColor: 'whitesmoke',
	display: 'flex',
	justifyContent: 'center',
	borderRadius: '3px',
})

const imgGabarit = {
	width: '80%',
	height: '80%',
	maxWidth: '100%',
	maxHeight: '100%',
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
}

interface IPictureAnnotation {
	handleImageLoaded
	imgURL
	countAnnotations?
	annotationNumber?
	isModal?
	angleType?: string
}

const PictureAnnotation: React.FC<IPictureAnnotation> = ({
	handleImageLoaded,
	imgURL,
	countAnnotations = false,
	annotationNumber = false,
	isModal = false,
	angleType,
}) => {
	const [annotationPosition, setAnnotationPosition] = useState([]) as any
	const [isUrlCorrupted, setIsUrlCorrupted] = useState(false)
	const refImg = useRef(null)

	// Handle image error event
	const handleImageError = useCallback(() => {
		setIsUrlCorrupted(true)
	}, [])

	// if resize screen, reload the handleImageLoaded function to resize annotations (only inside modal, because size is responsive)
	useEffect(() => {
		imgURL &&
			isModal &&
			window.addEventListener(
				'resize',
				handleImageLoaded(setAnnotationPosition, refImg)
			)
	}, [])

	return (
		<>
			{countAnnotations && annotationPosition?.length ? (
				<Box sx={annotationCountCSS}>{annotationPosition?.length}</Box>
			) : (
				<></>
			)}

			{annotationPosition?.map(({ top, left, width, height }, i) => (
				<Box
					id={`annotation-${i}`}
					key={i}
					sx={annotationHandlerCSS}
					className={`${isModal ? 'annotationForms' : ''}`}
					style={{ top, left, width, height }}
				>
					{annotationNumber && (
						<Box sx={annotationNumberCSS} className={'annotationNumber'}>
							{i + 1}
						</Box>
					)}
				</Box>
			))}

			{imgURL ? (
				isUrlCorrupted ? (
					<Box sx={containerGabarit(isModal)} ref={refImg}>
						<img
							style={imgGabarit as any}
							onLoad={handleImageLoaded(setAnnotationPosition, refImg)}
							src={
								angleType
									? `/img/gabarit-vehicle/car-${angleType}.svg`
									: `/img/gabarit-vehicle/car-none.svg`
							}
						/>
					</Box>
				) : (
					<img
						ref={refImg}
						style={img(isModal)}
						onLoad={handleImageLoaded(setAnnotationPosition, refImg)}
						src={imgURL}
						onError={handleImageError}
					/>
				)
			) : (
				<img
					style={{ backgroundColor: '#dddddd40', ...img(isModal) }}
					src={`/icon/template-form/corrupt.svg`}
				/>
			)}
		</>
	)
}

export default PictureAnnotation
