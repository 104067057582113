import React, { Context, useCallback, useContext, useMemo } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import ButtonBase from '@mui/material/ButtonBase'
import DoneIcon from '@mui/icons-material/Done'
import ClearIcon from '@mui/icons-material/Clear'
import { dynamicColor, limitedHues } from '../../../../utils/color'
import { IFormModalContext } from '../../formModal/FormModalContext'
import { IStepperContext } from '../../formStepper/StepperContext'

const formControlLabelCSS = {
	height: '36px',
	width: '36px',
	margin: '4px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '50px',
}

interface IColorRadio {
	name: string
	specialOnChange?: any
	notEmptyColor?: any
	context: Context<IFormModalContext> | Context<IStepperContext>
}

const colorRadioSX = {
	radioGroup: {
		flexDirection: 'row',
		'& .MuiRadio-root': { color: '#fff0' },
	},
	buttonBase: { borderRadius: '50px' },
}

const ColorRadio: React.FC<IColorRadio> = ({
	name,
	specialOnChange,
	notEmptyColor,
	context,
}) => {
	const { setValue, data } = useContext<IFormModalContext | IStepperContext>(
		context as any
	)

	const value = useMemo(() => data[name] || '', [data[name]])

	const handleChange = useCallback(
		(e) => {
			const newValue = e.target.value
			if (specialOnChange) specialOnChange(name, newValue)
			else setValue(name, newValue)
		},
		[name, specialOnChange, setValue]
	)

	const renderRadioButtons = useMemo(() => {
		const radioButtons = limitedHues.map((hue, i) => (
			<ButtonBase
				focusRipple
				sx={{ borderRadius: '50px' }}
				key={`colorRadio-${i}`}
			>
				<FormControlLabel
					value={hue}
					control={<Radio checkedIcon={<DoneIcon sx={{ color: 'white' }} />} />}
					label=''
					sx={{
						...formControlLabelCSS,
						backgroundColor: dynamicColor(i, 500) as any,
					}}
				/>
			</ButtonBase>
		))

		if (!notEmptyColor) {
			radioButtons.unshift(
				<ButtonBase focusRipple sx={colorRadioSX.buttonBase} key='clear'>
					<FormControlLabel
						value={''}
						control={
							<Radio
								checkedIcon={
									<ClearIcon
										sx={{ color: ({ palette }) => palette.grey[500] }}
									/>
								}
							/>
						}
						label=''
						sx={{
							...formControlLabelCSS,
							backgroundColor: 'white' as any,
							border: ({ palette }) => ` solid 1px ${palette.grey[500]}`,
						}}
					/>
				</ButtonBase>
			)
		}

		return radioButtons
	}, [notEmptyColor])

	return (
		<RadioGroup
			name='colorLocation'
			onChange={handleChange}
			value={value}
			sx={colorRadioSX.radioGroup}
		>
			{renderRadioButtons}
		</RadioGroup>
	)
}

export default ColorRadio
