import React, { useContext } from 'react'
import FiltersBtnProvider from '../../../../components/filters/filtersBtn'
import InvoiceFiltersBtnComponent from './InvoiceFiltersBtnComponent'
import { Box } from '@mui/material'
import FilterDate from '../../../../components/filters/filtersComponents/filterDate'
import { FiltersContext } from '../../../../components/filters/filtersContext'
import PermissionsHandler from '../../../../components/permissionsHandler'
import { EModule } from '../../../../utils/app-models'

interface IInvoiceFilters {}

const ByReferenceFiltersSX = {
	display: 'flex',
}

const InvoiceFilters: React.FC<IInvoiceFilters> = () => {
	const { setFilters, filters } = useContext(FiltersContext)

	return (
		<Box sx={ByReferenceFiltersSX}>
			<FilterDate
				setFiltersQuerieParam={setFilters}
				filtersQuerieParam={filters}
				argumentQuerieName='dateCreated'
			/>
			<PermissionsHandler modulesName={[EModule.crm]}>
				<FiltersBtnProvider>
					<InvoiceFiltersBtnComponent />
				</FiltersBtnProvider>
			</PermissionsHandler>
		</Box>
	)
}

export default InvoiceFilters
