import React, { memo, useMemo } from 'react'

import { Stack, Paper, Fade } from '@mui/material'
import BuildIcon from '@mui/icons-material/Build'

import { useFormatMessage } from '../../../utils/hooks'
import WidgetIconTitle from '../elements/WidgetIconTitle'
import { useWTotalInterventionsDone } from '../../../api/gql/queries/interventionsQueries'
import CircularProgressWidget from '../elements/CircularProgressWidget'

interface IWTotalInterventionsDone {
	filterQueries
}

const WTotalInterventionsDone: React.FC<IWTotalInterventionsDone> = ({
	filterQueries,
}) => {
	const intlMsg = useFormatMessage()

	const { totalInterventions, status, loading } = useWTotalInterventionsDone({
		variables: {
			...filterQueries,
		},
	})

	const subTitle = useMemo(
		() =>
			`${
				status?.filter(({ status }) => status?.code === 'DONE')?.length
			}/${totalInterventions}`,
		[status, totalInterventions]
	)

	return (
		<Paper elevation={1} sx={{ height: 1 }} id='widget'>
			<Stack p={4} justifyContent='center' sx={{ height: 1 }}>
				<Fade in={!loading}>
					<Stack
						direction='row'
						justifyContent='space-between'
						alignItems='center'
					>
						<WidgetIconTitle
							icon={{
								iconColor: 'indigo',
								Icon: BuildIcon,
							}}
							title={intlMsg('widget.interventionDone')}
							subTitle={subTitle}
						/>
					</Stack>
				</Fade>
				{loading && <CircularProgressWidget />}
			</Stack>
		</Paper>
	)
}

export default memo(WTotalInterventionsDone)
