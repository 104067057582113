import React, { useCallback, useContext } from 'react'

import { FormTemplateContext } from '../../../../../../utils/contexts/FormTemplateContext'
import {
	TitleField,
	RequireFieldTxt,
	SelectRatingTypeField,
} from '../fieldEditForms/editFormsField'
import { useFormatMessage } from '../../../../../../utils/hooks'
import InputText from '../../../../../../components/forms/v1/formFields/InputText'
import { EFormTemplateRatingType } from '../../../../../../utils/app-models'
import { Stack } from '@mui/material'

interface IFieldEditFormLayoutRating {}

const FieldEditFormLayoutRating: React.FC<IFieldEditFormLayoutRating> = () => {
	const intlMsg = useFormatMessage()

	const {
		drawerUtils: { controlDrawer: control, watchDrawer, setValueDrawer },
	} = useContext(FormTemplateContext)

	const watchedRatingType = watchDrawer('ratingType')
	const watchedMin = watchDrawer('min')
	const watchedMax = watchDrawer('max')
	const watchedStep = watchDrawer('step')

	const handleRatingChange = useCallback(
		(name) => (e) => {
			const value =
				parseFloat(e.target.value) > 1000 ? 999 : parseFloat(e.target.value)
			if (name === 'min') {
				if (isNaN(value)) setValueDrawer(name, 0)
				else setValueDrawer(name, value)

				if (value >= parseFloat(watchedMax)) setValueDrawer('max', value + 1)
			} else {
				if (isNaN(value)) setValueDrawer(name, parseFloat(watchedMin) + 1)
				else setValueDrawer(name, value)

				if (value <= parseFloat(watchedMin)) setValueDrawer('min', 0)
			}
		},
		[watchedMin, watchedMax]
	)

	const handleChangeDefaultValue = useCallback(
		(e) => {
			const value = parseFloat(e.target.value)

			if (watchedRatingType === EFormTemplateRatingType.stars) {
				if (value > 5) setValueDrawer('defaultValue', 5)
				else if (value < 0) setValueDrawer('defaultValue', 0)
				else if (value % 0.5 != 0)
					setValueDrawer('defaultValue', value - (value % 0.5))
				else setValueDrawer('defaultValue', value)
			} else {
				if (value > parseFloat(watchedMax))
					setValueDrawer('defaultValue', watchedMin)
				else if (value === 0 || value % parseFloat(watchedStep) === 0)
					setValueDrawer('defaultValue', value)
				else
					setValueDrawer(
						'defaultValue',
						value - (value % parseFloat(watchedStep))
					)
			}
		},
		[watchedStep, watchedMin, watchedRatingType]
	)

	const handleChangeStep = useCallback(
		(e) => {
			const value = parseFloat(e.target.value)

			if (value < 0 || value > watchedMax) setValueDrawer('step', 1)
			else if (watchedMax % value !== 0 || watchedMin % value !== 0) {
				let cpt = value > 1000 ? 999 : Number(value)
				while (cpt > 0) {
					if (watchedMax % cpt === 0 && watchedMin % cpt === 0) break
					cpt = Number(cpt - 0.5)
				}
				setValueDrawer('step', cpt === 0 ? 1 : cpt)
			} else setValueDrawer('step', value)
		},
		[watchedMin, watchedMax]
	)

	return (
		<Stack spacing={2}>
			<RequireFieldTxt control={control} />
			<TitleField control={control} />
			<SelectRatingTypeField control={control} />

			{watchedRatingType === EFormTemplateRatingType.slider && (
				<>
					<InputText
						name='min'
						label={intlMsg('formTemplate.min')}
						control={control}
						type='number'
						InputLabelProps={{ shrink: true }}
						onBlur={handleRatingChange('min')}
					/>

					<InputText
						name='max'
						label={intlMsg('formTemplate.max')}
						control={control}
						type='number'
						InputLabelProps={{ shrink: true }}
						onBlur={handleRatingChange('max')}
					/>

					<InputText
						name='step'
						label={intlMsg('formTemplate.step')}
						control={control}
						type='number'
						InputLabelProps={{ shrink: true }}
						onBlur={handleChangeStep}
					/>
				</>
			)}

			<InputText
				name='defaultValue'
				label={intlMsg('formTemplate.defaultValue')}
				control={control}
				type='number'
				InputLabelProps={{ shrink: true }}
				onBlur={handleChangeDefaultValue}
			/>
		</Stack>
	)
}

export default FieldEditFormLayoutRating
