import React from 'react'

import AgencyModal from './agencyModal'
import FormBtnModal from '../../../../components/forms/formModalBtn'
import { EFormModalMode } from '../../../../utils/app-models'

interface ICreateAgencyBtnModal {
	selectedNetwork?
}

const CreateAgencyBtnModal: React.FC<ICreateAgencyBtnModal> = ({
	selectedNetwork,
}) => {
	return (
		<FormBtnModal
			modal={<AgencyModal selectedNetwork={selectedNetwork} modalUtils={{}} />}
			mode={EFormModalMode.create}
		/>
	)
}

export default CreateAgencyBtnModal
