/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'

import {
	EFormTemplateBasicFields,
	EFormTemplateVehicleFields,
} from '../../../../utils/app-models'
import { formatGeneric } from '../../../../utils/string'
import FieldInterventionForm from './componentsForm'
import Control from './componentsForm/Control'
import DateField from './componentsForm/Date'
import Notation from './componentsForm/Notation'
import Picture from './componentsForm/Picture'
import Signature from './componentsForm/Signature'
import Selection from './componentsForm/Selection'
import Text from './componentsForm/Text'
import { Alert, Link, Stack } from '@mui/material'
import Instruction from './componentsForm/Instruction'
import DocumentField from './componentsForm/Document'
import translateIntlMsgString from '../../../../utils/translateIntlMsgString'

//////////////////////////////////////////////////////////////
// utils for filter data to display 'summary page'
//////////////////////////////////////////////////////////////

// Check if text is displayed on summary
const textIsDisplayedOnSummary = (attributes) => {
	const { summaryDisplayField } = JSON.parse(attributes)
	return summaryDisplayField
}

// Check if field control have alert (return true or false)
const fieldControlHaveAlert = (value, attributes) => {
	const { canAlert, selectedChoiceToAlert } = JSON.parse(attributes)
	if (canAlert && selectedChoiceToAlert === value.value) {
		return true
	}
	return false
}

// Check if field photos have annotation (return true or false)
const fieldPhotoHaveAnnotations = (values) => {
	if (values?.length) {
		return values.filter(({ annotations }) => {
			return annotations?.length
		}).length
			? true
			: false
	} else {
		return false
	}
}

// Filter fields who return an alert or fields photo with annotation ( Only type control or photo can have alert or annotations) && text displayed on summary
export const FilterFieldsWithAlertOrAnnotations = (fieldsRecords, pages) => {
	const filteringRecordSummary = fieldsRecords?.filter((record) => {
		const { value, formTemplateField } = record || {}
		if (formTemplateField?.type === EFormTemplateBasicFields.control) {
			return fieldControlHaveAlert(value, formTemplateField?.attributes)
		} else if (formTemplateField?.type === EFormTemplateBasicFields.picture) {
			return fieldPhotoHaveAnnotations(value)
		} else if (formTemplateField?.type === EFormTemplateBasicFields.text) {
			return textIsDisplayedOnSummary(formTemplateField?.attributes)
		}
		return false
	})

	const recordsAndFormstemplate = [] as any

	// for each filteringRecordSummary, join the fields template form
	filteringRecordSummary?.forEach(
		({ formTemplateField: { id } }, indexRecord) => {
			pages.every(({ fields }) => {
				if (fields.find((field) => field.id === id)) {
					recordsAndFormstemplate.push({
						record: filteringRecordSummary[indexRecord],
						fieldTemplate:
							filteringRecordSummary[indexRecord].formTemplateField,
					})
					return false
				}

				return true
			})
		}
	)

	return recordsAndFormstemplate
}

//////////////////////////////////////////////////////////////
// Generate tabs for each page
//////////////////////////////////////////////////////////////

export const generateTabs = (fieldsRecords, pages) => {
	const tabsRecordsAndFormstemplate = [] as any

	pages?.forEach((page) => {
		const tabs: any = { label: page?.name, dataTab: [] }

		page?.fields
			?.filter(({ attributes, type }) => {
				if (type === EFormTemplateBasicFields.instruction) {
					const { hiddenForOthers } =
						typeof attributes[0] === 'string'
							? JSON.parse(attributes)
							: attributes || {}
					return !hiddenForOthers
				}

				return true
			})
			?.forEach((fieldTemplate) => {
				const matchRecordFieldWithTemplateField = fieldsRecords.find(
					(record) => record?.formTemplateField.id === fieldTemplate?.id
				)

				return tabs.dataTab.push({
					record: matchRecordFieldWithTemplateField || null,
					fieldTemplate: fieldTemplate,
				})
			})

		tabsRecordsAndFormstemplate.push(tabs)
	})

	return tabsRecordsAndFormstemplate
}

//////////////////////////////////////////////////////////////
// Define all field components
//////////////////////////////////////////////////////////////

const fieldForm = {
	[EFormTemplateBasicFields.control]: Control,
	[EFormTemplateBasicFields.date]: DateField,
	[EFormTemplateBasicFields.parkDate]: DateField,
	[EFormTemplateBasicFields.picture]: Picture,
	[EFormTemplateBasicFields.document]: DocumentField,
	[EFormTemplateBasicFields.text]: Text,
	[EFormTemplateBasicFields.selection]: Selection,
	[EFormTemplateBasicFields.instruction]: Instruction,
	[EFormTemplateBasicFields.signature]: Signature,
	[EFormTemplateBasicFields.numbers]: Text,
	[EFormTemplateBasicFields.rating]: Notation,
	[EFormTemplateBasicFields.barCode]: Text,
	[EFormTemplateVehicleFields.mileage]: Text,
}

const EmptyComponent = () => <></>

const selectComponentField = (
	confFields,
	intlMsg,
	isSummary,
	oldVersionReference
) => {
	if (confFields?.length) {
		return confFields?.map(({ record, fieldTemplate }, i) => {
			const FieldInterventionValue =
				fieldForm[fieldTemplate?.type] || EmptyComponent

			const additionalParams =
				fieldTemplate?.type === EFormTemplateBasicFields.picture && isSummary
					? { onlyWithAlert: true }
					: {}

			return (
				<FieldInterventionForm
					title={fieldTemplate?.name}
					key={fieldTemplate?.name + i}
					fieldType={fieldTemplate?.type}
					attributes={record?.formTemplateField?.attributes}
				>
					<FieldInterventionValue
						record={record}
						fieldTemplate={fieldTemplate}
						{...additionalParams}
					/>
				</FieldInterventionForm>
			)
		})
	} else {
		return (
			<Stack pb={0}>
				<Alert severity='success'>
					{oldVersionReference ? (
						<>
							{intlMsg('interventionForms.fromOldRef')}
							<Link
								target='_blank'
								href={`https://connect.hubeecar.com/documents/formPdf?formulaire=${oldVersionReference}`}
							>
								{intlMsg('interventionForms.fromOldRefHere')}
							</Link>
						</>
					) : (
						<>{intlMsg('interventionForms.noDefaults')}</>
					)}
				</Alert>
			</Stack>
		)
	}
}

/*
<>

</> : {intlMsg('interventionForms.noDefaults')}
*/
//////////////////////////////////////////////////////////////
// Export tab config
//////////////////////////////////////////////////////////////

export const interventionFormsTabsConfig = (
	{ records, formTemplate, oldVersionReference },
	intlMsg
) => {
	//TODO HERE TOO MUCH RENDER, need to  see why to memoise all const in this coponent
	const formatData: any = formatGeneric({ ...formTemplate, records })

	const dataTabSummary = FilterFieldsWithAlertOrAnnotations(
		formatData?.records,
		formatData?.pages
	)

	const dataTabs = generateTabs(formatData?.records, formatData?.pages)

	return [
		{
			label: translateIntlMsgString('misc.summary'),
			component: (
				<div className='bgFormField' key='bgFormField'>
					{selectComponentField(
						dataTabSummary,
						intlMsg,
						true,
						oldVersionReference
					)}
				</div>
			),
		},
		...dataTabs.map(({ label, dataTab }, i) => {
			return {
				label,
				component: (
					<div className='bgFormField' key={`bgFormField-${i}`}>
						{selectComponentField(dataTab, intlMsg, false, oldVersionReference)}
					</div>
				),
			}
		}),
	]
}
