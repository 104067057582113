import React, { memo, useMemo } from 'react'
import InterventionsByReference from '../../../../view/dashboard/interventions/interventionControl/byReference'
import { overrideConfigTable } from './overrideConfigTable'

interface IWInterventionbyReference {
	filterQueries
}

const WInterventionbyReference: React.FC<IWInterventionbyReference> = ({
	filterQueries,
}) => {
	// When the dashboard is loaded, the filterQueries are empty,
	// so we need to set the default values
	const otherQueryParams = useMemo(
		() => ({
			dateFrom: null,
			startFrom: filterQueries.dateFrom
				? filterQueries.dateFrom?.[0]?.after
				: filterQueries.date.dateFrom?.[0]?.after,
			endTo: filterQueries.dateFrom
				? filterQueries.dateFrom?.[0]?.before
				: filterQueries.date.dateFrom?.[0]?.before,
			period: filterQueries.period,
		}),
		[filterQueries]
	)

	return (
		<InterventionsByReference
			othersQueryParams={otherQueryParams}
			overrideConfigTable={overrideConfigTable}
			overrideTableProvider={{
				customLocalStorageKeyFilter:
					'listInterventionsByReferenceFiltersWidget',
			}}
		/>
	)
}

export default memo(WInterventionbyReference)
