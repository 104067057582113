import React, { useCallback, useState } from 'react'

import {
	Card,
	CardActions,
	CardContent,
	Collapse,
	IconButton,
	IconButtonProps,
	Stack,
	Typography,
	styled,
} from '@mui/material'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const cardCSS = {
	border: ({ palette }) => `4px solid ${palette.grey[100]}`,
	borderRadius: 1,
}

const headerCSS = {
	backgroundColor: ({ palette }) => palette.grey[100],
	paddingTop: '0px',
	paddingBottom: '0px',
	height: '40px',
}

interface ExpandMoreProps extends IconButtonProps {
	expand: boolean
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ExpandMore = styled(({ expand, ...other }: ExpandMoreProps) => {
	return <IconButton {...other} />
})(({ theme, expand }) => ({
	transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
	marginLeft: 'auto',
	transition: theme.transitions.create('transform', {
		duration: theme.transitions.duration.shortest,
	}),
}))

interface ICustomAccordion {
	children: any
	icon?: any
	title: string
	AfterTitleCustomBlock?: any
	disabledExpand?: boolean
}

const CustomAccordion: React.FC<ICustomAccordion> = ({
	children,
	icon,
	title,
	AfterTitleCustomBlock,
	disabledExpand = false,
}) => {
	const [expanded, setExpanded] = useState(true)
	const handleExpandClick = useCallback(() => {
		setExpanded(!expanded)
	}, [expanded])

	return (
		<Card elevation={0} raised sx={cardCSS}>
			<CardActions disableSpacing sx={headerCSS}>
				<Stack
					direction='row'
					spacing={1}
					alignItems='center'
					justifyContent='space-between'
					width={1}
				>
					<Stack direction='row' spacing={1} alignItems='center' width={1}>
						{icon && icon}
						<Typography variant='h2' pr={3}>
							{title}
						</Typography>
						{AfterTitleCustomBlock && AfterTitleCustomBlock}
					</Stack>

					{!disabledExpand && (
						<ExpandMore
							expand={expanded}
							onClick={handleExpandClick}
							aria-expanded={expanded}
							aria-label='show more'
						>
							<ExpandMoreIcon />
						</ExpandMore>
					)}
				</Stack>
			</CardActions>
			<Collapse in={expanded} timeout='auto' unmountOnExit>
				<CardContent sx={{ padding: '10px 22px!important', height: '100% ' }}>
					{children}
				</CardContent>
			</Collapse>
		</Card>
	)
}
export default CustomAccordion
