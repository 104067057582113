import React, { useContext, useEffect } from 'react'

import { DragDropContext } from 'react-beautiful-dnd'
import { Stack } from '@mui/material'

import TitlePageContent from './TitlePageContent'
import PageFieldList from './PageFieldList'
import InputsList from '../formTemplateFieldsList'
import { FormTemplateContext } from '../../../../../utils/contexts/FormTemplateContext'
import { useFormatMessage } from '../../../../../utils/hooks'
import { onDragEndField } from '../formEditionsUtils/dragAndDropUtils'
import { useSticky } from '../../../../../utils/utils'

const positionFixedCSS = {
	width: 'calc(100% - 528px)',
	marginLeft: '128px',
} as const

const pageCSS = {
	width: 'calc(100% - 400px)',
	p: 2,
	paddingTop: '15px',
} as const

interface IPagesContent {
	pageNbr
	removePage
	setSelectedPageNbr
	selectedPageNbr
	control
	page
	updatePage
}

const PagesContent: React.FC<IPagesContent> = ({
	pageNbr,
	removePage,
	setSelectedPageNbr,
	selectedPageNbr,
	control,
	page,
	updatePage,
}) => {
	const intlMsg = useFormatMessage()

	const {
		fieldUtils,
		setLimitationFields,
		limitationFields,
		setIsOpenLimitModal,
	} = useContext(FormTemplateContext)
	const {
		fields: pageFields,
		insert: insertField,
		append: appendField,
		remove: removeField,
		update: updateField,
		move: moveField,
	} = fieldUtils(selectedPageNbr, control)
	const { isElementFixed, handleScroll } = useSticky(408)

	useEffect(() => {
		document.addEventListener('scroll', handleScroll)
		return () => {
			document.removeEventListener('scroll', handleScroll)
		}
	}, [])

	return (
		<Stack
			direction='row'
			p={1}
			sx={{ width: 1, overflowY: 'scroll', maxHeight: '80vh' }}
		>
			<DragDropContext
				onDragEnd={onDragEndField(
					insertField,
					moveField,
					intlMsg,
					setLimitationFields,
					limitationFields,
					setIsOpenLimitModal
				)}
			>
				<Stack sx={[pageCSS, isElementFixed && positionFixedCSS]}>
					<TitlePageContent
						pageNbr={pageNbr}
						removePage={removePage}
						setSelectedPageNbr={setSelectedPageNbr}
						selectedPageNbr={selectedPageNbr}
						updatePage={updatePage}
						pageName={page?.name || ''}
						pageFields={pageFields}
					/>
					<PageFieldList
						pageFields={pageFields}
						removeField={removeField}
						updateField={updateField}
						insertField={insertField}
					/>
				</Stack>
				<InputsList appendField={appendField} />
			</DragDropContext>
		</Stack>
	)
}

export default PagesContent
