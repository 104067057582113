import React, { useMemo, memo } from 'react'

import { Stack, Paper, Fade } from '@mui/material'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'

import { useFormatMessage } from '../../../utils/hooks'
import WidgetIconTitle from '../elements/WidgetIconTitle'
import { useWVehicleEntriePark } from '../../../api/gql/queries/vehiclesQueries'
import CircularProgressWidget from '../elements/CircularProgressWidget'

interface IWTotalVehicleEntriePark {
	filterQueries
	isExitPark?: boolean
}

const WTotalVehicleEntriePark: React.FC<IWTotalVehicleEntriePark> = ({
	filterQueries,
	isExitPark,
}) => {
	const intlMsg = useFormatMessage()

	const { vehicles, loading } = useWVehicleEntriePark({
		variables: {
			...filterQueries,
			[isExitPark ? 'exitDate' : 'entryDate']: [
				{ before: filterQueries.dateFrom?.[0].before },
				{ after: filterQueries.dateFrom?.[0].after },
			],
		},
	})

	const subTitle = useMemo(
		() =>
			vehicles?.filter(({ entryDate, exitDate }) =>
				isExitPark ? exitDate?.length : entryDate?.length
			)?.length,
		[vehicles, isExitPark]
	)

	return (
		<Paper elevation={1} sx={{ height: 1 }} id='widget'>
			<Stack p={4} justifyContent='center' sx={{ height: 1 }}>
				<Fade in={!loading}>
					<Stack
						direction='row'
						justifyContent='space-between'
						alignItems='center'
					>
						<WidgetIconTitle
							icon={{
								iconColor: 'yellow',
								Icon: DirectionsCarIcon,
							}}
							title={intlMsg(
								isExitPark ? 'widget.exitPark' : 'widget.entriePark'
							)}
							subTitle={subTitle}
						/>
					</Stack>
				</Fade>
				{loading && <CircularProgressWidget />}
			</Stack>
		</Paper>
	)
}

export default memo(WTotalVehicleEntriePark)
