import React, {
	useState,
	useCallback,
	cloneElement,
	useMemo,
	useContext,
} from 'react'

import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Box, IconButton } from '@mui/material'

import { useNavigate } from 'react-router-dom'
import { IModalUtils, useModal } from '../../../../utils/hooks'
import { EFormModalMode } from '../../../../utils/app-models'
import PopoverActionsBtn from './PopoverActionsBtn'
import { AuthContext } from '../../../../utils/contexts/AuthContext'

export const actionBtnCellCSS = {
	height: '48px',
	width: '100%',
	boxShadow: ' #00000014 -5px 1px 3px 0px',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	paddingRight: '5px!important',
	paddingLeft: '5px!important',
	background: 'white',
	zIndex: 100,
}

interface IActionsBtn {
	rowId
	isSharedWithMeEditException?: boolean
	deleted?: any
	editModal?
	duplicate?: boolean | any
	viewModalLink?
	isContact?
	withSlide?
	viewAction?
	permissions?
	editPath?
	isSharedWithMe?: boolean
}

const ActionsBtn: React.FC<IActionsBtn> = ({
	rowId,
	isSharedWithMeEditException = false,
	deleted,
	editModal = null,
	duplicate = null,
	viewModalLink,
	isContact,
	withSlide,
	viewAction = true,
	permissions = {},
	editPath = null,
	isSharedWithMe = false,
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
	const { checkPermissions } = useContext(AuthContext)
	const open = Boolean(anchorEl)
	const navigate = useNavigate()
	const modalUtils: IModalUtils = useModal(false)
	const [mode, setMode] = useState(EFormModalMode.edit)

	const [doNotDuplicate, setDoNotDuplicate] = useState<any>(null)

	const handleClick = useCallback(
		(event: React.MouseEvent<HTMLButtonElement>) =>
			setAnchorEl(event.currentTarget),
		[anchorEl]
	)
	const havePermissions = useMemo(() => {
		const permissionsArray: any = Object.values(permissions).reduce(
			(acc: any, curr: any) => [...acc, ...curr],
			[]
		)

		return checkPermissions(permissionsArray?.legnth ? permissionsArray : null)
	}, [permissions])

	const handleClose = useCallback(() => setAnchorEl(null), [anchorEl])

	const handleEdit = useCallback(
		(mode = EFormModalMode.edit) => {
			if (editPath) navigate(editPath)
			else {
				setMode(mode)
				modalUtils.openModal()
			}
			handleClose()
		},
		[editPath, navigate]
	)
	return (
		<>
			{havePermissions && (
				<>
					<Box sx={actionBtnCellCSS}>
						<IconButton
							size='small'
							onClick={handleClick}
							data-test-id='Playwright-ActionsBtn-moreAction'
						>
							<MoreVertIcon />
						</IconButton>
					</Box>

					{open && (
						<PopoverActionsBtn
							actions={{ duplicate, deleted, edit: !!editModal, viewAction }}
							popover={{ open, anchorEl, handleClose }}
							rowId={rowId}
							viewModalLink={viewModalLink}
							permissions={permissions}
							isSharedWithMeEditException={isSharedWithMeEditException}
							editPath={editPath}
							handleEdit={handleEdit}
							isContact={isContact}
							withSlide={withSlide}
							isSharedWithMe={isSharedWithMe}
							setDoNotDuplicate={setDoNotDuplicate}
						/>
					)}

					{modalUtils?.isVisible &&
						cloneElement(editModal, { modalUtils, mode, doNotDuplicate })}
				</>
			)}
		</>
	)
}

export default ActionsBtn
