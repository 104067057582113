import React from 'react'
import { Typography } from '@mui/material'

import { useRemoveDocumentVehicle } from '../../../../../api/gql/mutations/documentsVehiclesMutation'
import { EAccountType, EPermissionScope } from '../../../../../utils/app-models'
import { formatDate } from '../../../../../utils/date'
import translateIntlMsgString from '../../../../../utils/translateIntlMsgString'
import VehicleDocumentModal from '../../../../../view/dashboard/vehiclesDocuments/vehicleDocumentModal'
import TimeCell from '../../../tableCell/TimeCell'
import UploadAwsFile from '../../../tableCell/UploadAwsFile'
import OpenLinkCell from '../../../tableCell/OpenLinkCell'
import ActionsBtn from '../../../tableCell/actionsBtn'
import UserAvatarSlidePanel from '../../../../avatar/UserAvatarSlidePanel'
import { ROUTES } from '../../../../../routes/consts'

const transportListDocumentsViewModalTable = [
	{
		columnLabel: 'misc.reference',
		orderBy: 'reference',
		stickyFirstCol: true,
		dataKey: 'reference',
		maxCharacters: 30,
	},

	{
		columnLabel: 'misc.vehicle',
		orderBy: 'vehicle_vehicleDetail_immat',
		stickyFirstCol: true,
		personalizedCell: ({ vehicle: { id, vehicleDetail } }) => (
			<OpenLinkCell
				txt={vehicleDetail?.immat || vehicleDetail?.vin}
				viewModalLink={`${ROUTES.VIEWMODAL.VEHICLES.url}${id}`}
			/>
		),
	},

	{
		columnLabel: 'documentVehicle.type',
		orderBy: 'category_name',
		personalizedCell: ({ category }) => (
			<Typography>
				{translateIntlMsgString(`documentVehicle.${category.code}`)}
			</Typography>
		),
	},

	{
		columnLabel: 'misc.addAt',
		orderBy: 'dateCreated',
		personalizedCell: ({ dateCreated }) => (
			<TimeCell date={dateCreated ? formatDate(dateCreated) : '-'} />
		),
	},

	{
		columnLabel: 'invoice.addBy',
		orderBy: 'createdBy_lastName',
		accountsTypeDenied: [EAccountType.guest],
		personalizedCell: ({ createdBy }) => (
			<UserAvatarSlidePanel
				firstName={createdBy?.firstName}
				lastName={createdBy?.lastName}
				userId={createdBy?.id}
				displayName={false}
			/>
		),
	},

	{
		columnLabel: 'misc.download',
		orderBy: 'file',
		personalizedCell: ({ id, file }) => file && <UploadAwsFile id={id} />,
	},

	{
		fixWidth: '50px',
		columnLabel: '',
		visible: true,
		tableCellAlign: 'center',
		cellAlign: 'center',
		sticky: true,
		personalizedCell: ({ id }: any) => (
			<ActionsBtn
				rowId={id}
				deleted={{
					query: useRemoveDocumentVehicle,
					refreshQuery: [
						'getDocumentsVehicles',
						'getDocumentsVehicleCountByVehicle',
						'getDocumentsVehicleCountByIntervention',
					],
					successMessageDelete: 'success.documentVehicleDeleted',
					messageConfirm: {
						title: 'confirmModal.deleteDocumentVehicleTitle',
						message: 'confirmModal.deleteDocumentVehicleMessage',
					},
				}}
				viewAction={false}
				editModal={
					<VehicleDocumentModal documentVehicleId={id} modalUtils={{} as any} />
				}
				permissions={{ update: [EPermissionScope.documentManage] }}
			/>
		),
	},
]

export default transportListDocumentsViewModalTable
