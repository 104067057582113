/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const modalCSS = {
	overflow: 'hidden',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	position: 'absolute',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: 'white',
	width: '100%',
	borderRadius: 1,
}

export const formModalTabsCSS = {
	backgroundColor: 'white',
	borderBottom: 'solid 1px #eaeaea',
	'&  .MuiTabs-indicator': {
		backgroundColor: 'transparent',
		display: 'flex',
		justifyContent: 'center',
		height: '4px',
	},
}

export const formModalTabCSS = ({ isSelected }) => ({
	marginRight: 1,
	textTransform: 'uppercase',
	fontWeight: 500,
	borderRadius: '3px',

	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'nowrap',
	justifyContent: 'space-between',
	alignContent: 'stretch',
	alignItems: 'flex-start',

	fontSize: ({ typography }) => typography.pxToRem(14),
	'& > div': {
		color: isSelected ? '#2F5DD1' : '#979797',
		margin: '0px 5px',
	},
	borderBottom: ({ palette }) =>
		isSelected ? `4px solid ${palette.primary.main}` : '4px solid transparent',

	'&:focus': {
		opacity: 1,
	},
})

export const footerModalCSS = {
	p: 2,
	boxShadow: ({ shadows }) => shadows[1],
	width: '100%',
}
