import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import {
	Box,
	ListItem,
	ListItemIcon,
	ListItemText,
	Collapse,
} from '@mui/material'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

import { useFormatMessage } from '../../../../../utils/hooks'
import SubNavItem from './SubNavItem'

const styles = {
	listItem: {
		fontSize: '12px',
		overflow: 'visible!important',
		padding: '15px 30px 15px 17px',
		'&:hover': {
			backgroundColor: '#179BE7',
		},
	},
	listItemOpen: {
		padding: '15px 30px 15px 30px',
		'&:hover': {
			backgroundColor: '#192A54',
		},
	},
	listItemText: {
		color: '#b2b6c6',
		marginTop: '1px',
		'& span': {
			fontSize: '13px!important',
			textTransform: 'uppercase',
			whiteSpace: 'nowrap',
		},
	},
	expandIcon: {
		marginRight: '-10px',
		color: '#b2b6c6',
	},
	isSelected: {
		position: 'absolute',
		borderLeft: `solid 4px #f13a30 !important`,
		top: '0px',
		right: '-5px',
		borderRadius: '268px',
		width: '9px',
		height: '57px',
	},
}

interface INavItem {
	config: {
		label: string
		path?: string
		subnav?: Array<{
			label: string
			path: string
			subPermissions: string[]
			subModules: string[]
		}>
		icon: React.ReactNode
	}
}

//todo check why openSubNav mechanism and css are so confused here and try to optimise them
const NavItem: React.FC<INavItem> = ({ config }) => {
	const intlMsg = useFormatMessage()
	const [openSubNav, setOpenSubNav] = useState(false)

	const navigate = useNavigate()
	const location = useLocation()

	const handleClick = useCallback(
		() => setOpenSubNav(!openSubNav),
		[openSubNav]
	)

	const handleClickMenu = useCallback(
		(newPath) => (e) => {
			e.preventDefault()
			e.ctrlKey ? window.open(newPath, '_blank') : navigate(newPath)
		},
		[navigate]
	)

	const selectedPathValues = useMemo(
		() => ({
			isSelected: config.path === location?.pathname || false,
			parentNavIsSelected: config?.subnav?.some(
				({ path }) => path === location?.pathname
			),
		}),
		[location]
	)

	const uniqueNavIsSelected =
		config?.path && config?.path === location?.pathname

	return (
		<>
			<ListItem
				button
				sx={{
					...styles.listItemOpen,
					...(selectedPathValues.isSelected &&
						config?.subnav &&
						styles.isSelected),
				}}
				onClick={config.subnav ? handleClick : handleClickMenu(config?.path)}
			>
				<ListItemIcon>
					<Box sx={{ color: '#fafafa91' }}>{config.icon}</Box>
				</ListItemIcon>

				<ListItemText
					primary={intlMsg(config.label)}
					sx={{
						...styles.listItemText,
						marginLeft: '-20px',
					}}
				/>

				{config.subnav !== undefined &&
					(openSubNav ? (
						<ExpandLess sx={styles.expandIcon} />
					) : (
						<ExpandMore sx={styles.expandIcon} />
					))}

				{(selectedPathValues.parentNavIsSelected || uniqueNavIsSelected) &&
					!openSubNav && <Box component='span' sx={styles.isSelected} />}
			</ListItem>

			{config.subnav && (
				<Collapse in={openSubNav} timeout='auto' unmountOnExit>
					<SubNavItem config={config.subnav} location={location} />
				</Collapse>
			)}
		</>
	)
}

export default NavItem
