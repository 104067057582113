import React, { useEffect, useState, useCallback } from 'react'

import { Controller } from 'react-hook-form'
import { isEmpty } from 'lodash'
import getNestedObjectFromKey from 'lodash.get'

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { Stack } from '@mui/material'

import { useFormatMessage } from '../../../../utils/hooks'

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

interface ISelectBox {
	name: string
	label: string
	options: Array<{
		id: string
		name: string
		value: string | number | boolean
	}>
	control
	customHelper?
	valuesDefault?
	size?: 'medium' | 'small' | undefined
	isDisabled?: boolean
	hidden?: boolean
	onBlur?
	multiple?: boolean
	setValue
	getValues
	missingText?: string
	groupedBy?: string
	noEntryText?: string
	loading?: boolean
}

const SelectBox: React.FC<ISelectBox> = ({
	control,
	name,
	options,
	label,
	customHelper,
	valuesDefault,
	size,
	isDisabled,
	hidden,
	multiple,
	setValue,
	getValues,
	missingText,
	groupedBy,
	noEntryText = null,
	loading = false,
}) => {
	const intlMsg = useFormatMessage()
	const currentValue = getValues(name)
	const [selectedData, setSelectedData] = useState(
		isEmpty(currentValue) ? (valuesDefault ? valuesDefault : []) : currentValue
	)
	const filter = createFilterOptions()

	const handleToggleSelectAll = () => {
		const filteredOption: any = []
		//allow to get filtered options by dom elements

		document.querySelectorAll('[id=select-option]').forEach((Element) => {
			const entry = options.find(
				({ id }) => id === Element.className.split(' ')[0]
			)
			if (entry) filteredOption.push(entry)
		})

		if (filteredOption?.length === selectedData?.length) setSelectedData([])
		else setSelectedData(filteredOption)
	}

	const handleChange = (_event, selectedOptions, reason) => {
		if (reason === 'selectOption' || reason === 'removeOption') {
			if (selectedOptions.find((option) => option.id === 'select-all')) {
				handleToggleSelectAll()
			} else {
				setSelectedData(selectedOptions)
			}
		} else if (reason === 'clear') {
			setSelectedData([])
		}
	}

	useEffect(() => {
		setValue(name, selectedData)
	}, [selectedData])

	const filterGroupedBy = useCallback(
		() =>
			options
				.map((option) => ({
					category: getNestedObjectFromKey(option, groupedBy),
					name: option.name,
					id: option.id,
				}))
				.sort((a, b) => -b.category.localeCompare(a.category)),
		[options]
	)

	return (
		<>
			{options?.length ? (
				<Controller
					name={name}
					control={control}
					render={({ fieldState: { error } }) => {
						return (
							<>
								<Autocomplete
									multiple={multiple}
									size='small'
									limitTags={2}
									fullWidth
									options={groupedBy ? filterGroupedBy() : options}
									groupBy={(option) => (groupedBy ? option.category : null)}
									noOptionsText={
										missingText ? missingText : intlMsg('misc.noResultSearch')
									}
									value={selectedData}
									disableCloseOnSelect
									getOptionLabel={(option) => option.name}
									isOptionEqualToValue={(option, anotherOption) =>
										option.id === anotherOption.id
									}
									filterOptions={(options, params) => {
										const filtered = filter(options, params)
										if (options?.length) {
											return [
												{ name: intlMsg('misc.selectAll'), id: 'select-all' },
												...filtered,
											]
										} else return []
									}}
									onChange={handleChange}
									disabled={isDisabled}
									renderOption={(props, option, { selected }) => {
										const selectAllProps =
											option.id === 'select-all' // To control the state of 'select-all' checkbox
												? { checked: options?.length === selectedData?.length }
												: {}

										return (
											<li {...props}>
												<Stack
													id='select-option'
													className={option?.id}
													direction='row'
													justifyContent='space-between'
													alignItems='center'
													sx={{ width: 1 }}
												>
													{option?.name}
													<Checkbox
														icon={icon}
														checkedIcon={checkedIcon}
														sx={{ mr: 1 }}
														checked={selected}
														color={selected ? 'primary' : 'default'}
														{...selectAllProps}
													/>
												</Stack>
											</li>
										)
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											variant='outlined'
											error={!!error}
											helperText={
												error ? error.message : customHelper ? customHelper : ''
											}
											style={{
												opacity: isDisabled ? '0.5' : '1',
												display: hidden ? 'none' : 'inherit',
											}}
											disabled={isDisabled}
											label={label}
											size={size || 'small'}
										/>
									)}
								/>
							</>
						)
					}}
				/>
			) : (
				<TextField
					value={
						loading
							? `${intlMsg('misc.loading')}...`
							: noEntryText || intlMsg('misc.noEntry')
					}
					variant='outlined'
					sx={{ width: 1 }}
					disabled
					size='small'
				/>
			)}
		</>
	)
}

export default SelectBox
