import React, { useContext, useMemo } from 'react'

import { Box, Typography } from '@mui/material'
import { useFormatMessage } from '../../../utils/hooks'
import { ViewModalContentContext } from '../../../utils/contexts/ViewModalContentContext'

interface IViewModalHeaderTitle {}

const ViewModalHeaderTitle: React.FC<IViewModalHeaderTitle> = () => {
	const intlMsg = useFormatMessage()
	const {
		headerConfig: { title, bgColorTitle },
	} = useContext(ViewModalContentContext) || {}

	const chipTitleCSS = useMemo(
		() => ({
			minWidth: '100px',
			p: '0px, 10px, 0px, 10px',
			borderRadius: '2px',
			height: '24px',
			backgroundColor: bgColorTitle ?? '#fff',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		}),
		[bgColorTitle]
	)

	return (
		<Box sx={chipTitleCSS}>
			<Typography variant='text2'>{intlMsg(title)}</Typography>
		</Box>
	)
}

export default ViewModalHeaderTitle
