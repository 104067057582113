import React from 'react'

import { Stack } from '@mui/material'
import TitleField from './TitleField'

interface IFieldTitle {
	field
	deleteField
	index
	updateField
	insertField
}

const FieldTitle: React.FC<IFieldTitle> = ({
	field: { id, name, values, fieldType },
	deleteField,
	index,
	updateField,
	insertField,
}) => {
	return (
		<Stack p={1}>
			<TitleField
				deleteField={deleteField}
				index={index}
				fieldToEdit={{
					id,
					name,
					values,
					fieldType,
					updateField,
					index,
				}}
				isRequired={values?.isRequired}
				insertField={insertField}
				values={values}
				fieldType={fieldType}
				titleType={values?.titleType}
			/>
		</Stack>
	)
}

export default FieldTitle
