export default {
	lastMileage: 'Last Mileage',
	previousMileage: 'Previous Mileage',
	updateMileage: 'Update Mileage',
	historicMileage: 'MILEAGE HISTORY',
	locationMonitoring: 'Location Monitoring',
	dateEntry: 'Entry Date',
	releaseDate: 'Release Date',
	parkTime: 'Time on Park',
	interventions: 'Interventions',
	lastIntervention: 'Last mission',
	nextIntervention: 'Next Intervention',
	alertFinish: 'Resolved Alerts',
	alertDetected: 'Detected Alert Categories',

	interventionDone: 'Interventions Done',
	interventionNotAssigned: 'Unassigned Interventions',
	vehiclesActive: 'Active Vehicles',
	availableWidgets: 'Available Widgets',
	entriePark: 'Park Entries',
	exitPark: 'Park Exits',
	interByRef: 'Interventions by Reference',
	alertsDetectedCategory: 'Detected Alert Categories',
	zeroAlert: 'No Alerts',
	presenceTime: 'Time on Park',
	addTracking: 'New Park Entry',
	vehicleTrackingEdit: 'Edit Park Entry / Exit',
	vehicleTrackingCreate: 'Add Park Entry / Exit',
	entrieDate: 'Entry Date',
	exitDate: 'Exit Date',
}
