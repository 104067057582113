import React from 'react'

import translateIntlMsgString from '../../../../../utils/translateIntlMsgString'
import InterventionModalControlInfos from './interventionModalControlInfos'
import { interventionModalInfoValidationSchema } from './interventionModalValidationSchema'
import VehiclesAndServices from './vehiclesAndServices'

const interventionModalConfig = [
	{
		tabName: translateIntlMsgString('interventions.planning'),
		component: <InterventionModalControlInfos />,
		schema: interventionModalInfoValidationSchema,
		validationOnSwitch: true,
	},
	{
		tabName: translateIntlMsgString('interventions.vehiclesAndServices'),
		selectorTabCounter: 'nbVehiclesSelected',
		component: <VehiclesAndServices />,
	},
]

export default interventionModalConfig
