import React, { memo } from 'react'

import {
	ListItemText,
	Box,
	ListItemButton,
	Radio,
	List,
	Popover,
} from '@mui/material'
import { useFormatMessage } from '../../../../utils/hooks'
import { stringifiedColor } from '../../../../utils/color'
import { formatGeneric } from '../../../../utils/string'

const buttonCSS = (color) => ({
	'&.Mui-selected': {
		background: stringifiedColor(color, 50) as any,
	},
})

const colorCSS = (color) => ({
	height: '11px',
	width: '11px',
	marginRight: '10px',
	marginLeft: '-5px',
	borderRadius: 1,
	border: 'none',
	backgroundColor: stringifiedColor(color || 'grey', 400) as any,
})

interface IStatusInterventionPopover {
	handleClose
	open
	anchorEl
	handleSelectOneStatus
	statusInterventionList
	selectedStatus
}

const StatusInterventionPopover: React.FC<IStatusInterventionPopover> = ({
	handleClose,
	open,
	anchorEl,
	handleSelectOneStatus,
	statusInterventionList,
	selectedStatus,
}) => {
	const intlMsg = useFormatMessage()
	const { code: codeSelected }: any = selectedStatus

	return (
		<Popover
			open={open}
			anchorEl={anchorEl}
			onClose={handleClose}
			role='popover-status-intervention'
		>
			<List>
				{(formatGeneric(statusInterventionList) as any)?.map(
					({ name, color, id, code }) => {
						return (
							<ListItemButton
								key={name}
								sx={buttonCSS(codeSelected !== 'all' ? color : 'none')}
								onClick={handleSelectOneStatus(id)}
								selected={code === codeSelected}
								id={`Playwright-StatusInterventionPopover-${code}`}
							>
								<Box sx={colorCSS(color)}></Box>
								{/* // if code is null it's a custom title (without traduction) */}
								<ListItemText
									primary={intlMsg(
										code
											? `interventions.${code
													.toLowerCase()
													.split(' ')
													.join('')}`
											: name
									)}
								/>

								<Radio
									edge='end'
									size='small'
									color={'primary'}
									checked={code === codeSelected}
								/>
							</ListItemButton>
						)
					}
				)}
			</List>
		</Popover>
	)
}

export default memo(StatusInterventionPopover)
