/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'

import { useBulkUserstAction } from '../../../api/gql/mutations/usersMutations'
import {
	useExportUsers,
	USERS_FILTER_LIST,
} from '../../../api/gql/queries/usersQueries'
import listUsersViewTable from '../../../components/tableV2/config/administration/users/listUsersViewTable'
import { EPermissionScope } from '../../../utils/app-models'
import {
	ITableToolBarActionConfig,
	ITableToolBarConfig,
} from '../../../utils/typescript/tableType'
import { profileUpdateValidationSchema } from '../../../utils/validationSchema'
import CreateUserButtonModal from './CreateUserButtonModal'

////////////////////////////////////////////////////////////////
// Global variable
////////////////////////////////////////////////////////////////

const queryConfig = {
	query: USERS_FILTER_LIST,
	queryName: 'users',
}

////////////////////////////////////////////////////////////////
// tableToolBarAction CONFIG
////////////////////////////////////////////////////////////////

const refreshQuerie = ['getUsersList']

const deletedBulk = {
	mutation: useBulkUserstAction,
	operation: 'remove',
	successMessageDelete: {
		singular: 'success.userDeleted',
		plural: 'success.usersDeleted',
	},
	messageConfirm: {
		title: 'confirmModal.deleteUsersTitle',
		message: 'confirmModal.deleteUsersMessage',
	},
	deletePermissions: [EPermissionScope.userManage],
}

const tableToolBarActionConfig: ITableToolBarActionConfig = {
	refreshQuerie,
	deletedBulk,
}

////////////////////////////////////////////////////////////////
// TableToolBarHeader CONFIG
////////////////////////////////////////////////////////////////

const tableToolBarConfig: ITableToolBarConfig = {
	title: 'misc.users',
	stickyToolBar: true,
	tableConfig: listUsersViewTable,
	localStorageName: 'listUsersViewTableFilters',
	exportConfig: { exportQuery: useExportUsers },
	queryConfig,
	modalButton: {
		Btn: <CreateUserButtonModal />,
	},
	validationSchemaFunction: profileUpdateValidationSchema,
	createPermission: [EPermissionScope.vehicleCreate],
}

////////////////////////////////////////////////////////////////
// tableContainer CONFIG
////////////////////////////////////////////////////////////////

const tableContainerConfig = {
	tableConfig: listUsersViewTable,
	queryConfig,
}

////////////////////////////////////////////////////////////////
// -> EXPORT CONFIG MAIN TABLE
////////////////////////////////////////////////////////////////

export const usersConfigTable = {
	tableToolBarConfig,
	tableToolBarActionConfig,
	tableContainerConfig,
	displaySwitchOption: true,
}
