import dayjs from 'dayjs'
import { EPeriodFilter } from '../../../../../utils/app-models'

export const ipvTransportDefaultFiltersValues = {
	period: EPeriodFilter.thisWeek,
	category: 'all',
	startFrom: dayjs().startOf('week').toDate(),
	endTo: dayjs().endOf('week').toDate(),
	customer_list: null,
	intervention_actors_list: null,
	isWithoutActor: false,
	agency_list: null,
	intervention_status_list: null,
	valueStatusGroup: 'interventionAll',
}
