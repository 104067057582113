import React from 'react'

import { Typography, Stack, Chip, Box, Divider } from '@mui/material'

import { useFormatMessage } from '../../../../utils/hooks'
import ChipColorStatus from '../../../../components/chip/ChipColorStatus'
import VmCard from '../../../../components/viewModal/vmCard'
import { EChipColorStatus, dataListType } from '../../../../utils/app-models'
import DataList from '../../../../components/list/dataList'

interface ITransportRef {
	interRef
	transportType
	status
	interventionTransportTrips
}

const TransportRef: React.FC<ITransportRef> = ({
	interRef,
	transportType,
	status,
	interventionTransportTrips,
}) => {
	const intlMsg = useFormatMessage()

	return (
		<VmCard>
			<Stack direction='row' alignItems='center' justifyContent='space-between'>
				<Typography variant='h3'> {interRef || '-'}</Typography>
				<Box>
					<Chip
						variant='outlined'
						color='primary'
						label={intlMsg(`interventions.${transportType}`)}
						size='small'
						sx={{ marginRight: '10px' }}
					/>

					<ChipColorStatus
						size='small'
						label={status?.label}
						color={status?.color}
						code={status?.code}
						type={EChipColorStatus.intervention}
					/>
				</Box>
			</Stack>

			<Divider sx={{ mt: 2, mb: 2 }} />

			<DataList
				dataType={dataListType.period}
				data={[
					{
						dateStart: {
							date: interventionTransportTrips[0]?.date,
							timeFrom: interventionTransportTrips[0]?.timeFrom,
							timeTo: interventionTransportTrips[0]?.timeTo,
						},
						dateEnd: {
							date: interventionTransportTrips[1]?.date,
							timeFrom: interventionTransportTrips[1]?.timeFrom,
							timeTo: interventionTransportTrips[1]?.timeTo,
						},
					},
				]}
				fullWidth
			/>
		</VmCard>
	)
}
export default TransportRef
