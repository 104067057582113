export default {
	lostPasswordTitle: 'Password Reset',
	lostPasswordText: 'Please enter your email address to reset your password.',
	checkEmailBoxTitle: 'Check Your Emails',
	checkEmailBoxText:
		'An email has been sent. Follow the instructions to reset your password.',
	lastLogin: 'Last Login',
	searchUser: 'Search for a user',
	searchTeam: 'Search for a team',
	errorTitle: 'Invalid Link',
	errorText:
		'The link for confirming your account is incorrect or no longer valid.',
	validatedTitle: 'Final Step',
	validatedText:
		'The number of free users has been exceeded. Adding this new user will modify the price of your subscription.',
	neverLog: 'Never Logged In',
	shareUserLabel:
		'Share my activity related to their account with this contact',
	shareUserLabelTooltips:
		'This contact will receive an invitation to view your activity performed on their account, sites, and vehicles.',
	shareUserActivity: 'Share My Activity',
	sendInvitation: 'Resend Invitation',
	newPasswordTitle: 'New Password',
	newPasswordSubTitle: 'Enter and confirm your new password.',
	successPasswordUpdated: 'Your password has been successfully reset.',
	welcomeHubee: 'Welcome to HubeeConnect',
	activeAccount: 'Activate Your Account',
	rememberMe: 'Remember Me',
	enterPassword: 'Enter Password',
	confirmPassword: 'Confirm Password',
	explainActivationTitle:
		'Your user will receive an email to activate his account.',
	explainActivationParagraph:
		'Account activation is required to access the web platform and mobile application.',
	readMore: 'Read more',
}
