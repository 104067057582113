import React from 'react'

import { Alert } from '@mui/material'

import { GET_PRODUCT_CATEGORIES } from '../../../../../api/gql/queries/catalogProductQueries'
import TableSideNavItemTitle from '../../../../../components/multiNavTable/multiTableNavView/tableSideNav/tableSideNavList/NavItems/TableSideNavItemTitle'
import TableSideNavItemColor from '../../../../../components/multiNavTable/multiTableNavView/tableSideNav/tableSideNavList/NavItems/TableSideNavItemColor'
import TableSideNavItemCount from '../../../../../components/multiNavTable/multiTableNavView/tableSideNav/tableSideNavList/NavItems/TableSideNavItemCount'
import TableSideNavItemComment from '../../../../../components/multiNavTable/multiTableNavView/tableSideNav/tableSideNavList/NavItems/TableSideNavItemComment'
import translateIntlMsgString from '../../../../../utils/translateIntlMsgString'
import { TSideItemBlock } from '../../../../../utils/typescript/multiNavTableType'

/////////////////////////////////////////////////////////
// ListItemConfig
/////////////////////////////////////////////////////////

const blockLeft: TSideItemBlock[] = [
	({ color }) => <TableSideNavItemColor color={color} />,
	({ name }, isSelected) => (
		<TableSideNavItemTitle title={name} isSelected={isSelected} />
	),
	({ description }, isSelected) => (
		<TableSideNavItemComment comment={description} isSelected={isSelected} />
	),
]

const blockRight: TSideItemBlock[] = [
	(data, isSelected) => (
		<TableSideNavItemCount
			count={data?.productsActive?.paginationInfo?.totalCount || 0}
			isSelected={isSelected}
		/>
	),
]

/////////////////////////////////////////////////////////
// Export Configs
/////////////////////////////////////////////////////////

export const catalogueProductSharedConfigSideNav = {
	query: GET_PRODUCT_CATEGORIES,
	queryName: 'productCategories',
	listItemConfig: { blockLeft, blockRight },
	emptyMessage: () => (
		<Alert icon={false} severity='info'>
			{translateIntlMsgString('catalogProduct.emptyCategoriesProductShared')}
		</Alert>
	),
	disabledStatus: true,
}
