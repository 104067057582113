import { gql, useMutation } from '@apollo/client'

export const AWS_POST_MEDIA = gql`
	mutation postMedia($input: postAwsMediaUrlInput!) {
		postAwsMediaUrl(input: $input) {
			awsMediaUrl {
				url
			}
		}
	}
`

export const usePostMedia = () => useMutation(AWS_POST_MEDIA)
