/* eslint-disable no-useless-escape */
import { string, object } from 'yup'
import translateIntlMsgString from '../../../../utils/translateIntlMsgString'

export const userModalInfoValidationSchema = object().shape({
	firstName: string().required(
		translateIntlMsgString('validationSchema.require')
	),
	lastName: string().required(
		translateIntlMsgString('validationSchema.require')
	),
	email: string()
		.required(translateIntlMsgString('validationSchema.require'))
		.matches(
			/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
			translateIntlMsgString('validationSchema.invalidEmail')
		),
	functionUser: string(),
	phone: string().nullable(),
	cellphone: string().nullable(),
	userType: object()
		.required(translateIntlMsgString('validationSchema.require'))
		.nullable(),
})
