export default {
	admin: 'Administrateur',
	coordinator: 'Coordinateur',
	operator: 'Opérateur',
	guest: 'Invité',
	descriptionoperator:
		"Peut uniquement consulter les données de la plateforme et intervenir depuis l'application mobile.",
	descriptioncoordinator: 'A tous les droits sauf la gestion des utilisateurs.',
	descriptionadmin: 'A tous les droits.',
	descriptionguest: 'TODO',
}
