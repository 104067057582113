import React, { useCallback, useContext } from 'react'

import toast from 'react-hot-toast'

import {
	useCreateCustomer,
	useUpdateCustomer,
} from '../../../../api/gql/mutations/customerMutation'
import { useLazyCustomerForModal } from '../../../../api/gql/queries/customersQueries'
import FormModal from '../../../../components/forms/formModal'
import { EFormModalMode } from '../../../../utils/app-models'
import { AuthContext } from '../../../../utils/contexts/AuthContext'
import { IModalUtils, useFormatMessage } from '../../../../utils/hooks'
import {
	formatCustomerAccountModalCreateDefaultValues,
	formatCustomerAccountModalEditDefaultValues,
	formatCustomerAccountModalOnValidateData,
} from './customerAccountModalUtils'
import customerAccountsModalConfig from './customerAccountsModalConfig'
import { client } from '../../../../apolloClient'

interface ICustomerAccountsModal {
	customerId?: string | null
	modalUtils: IModalUtils
	mode?: EFormModalMode
	onClose?: any
}

const CustomerAccountsModal: React.FC<ICustomerAccountsModal> = ({
	modalUtils,
	mode,
	customerId,
	onClose,
}) => {
	const intlMsg = useFormatMessage()
	const [updateCustomer] = useUpdateCustomer()
	const [createCustomer] = useCreateCustomer()
	const { getCustomer } = useLazyCustomerForModal()
	const { currentUser, localLanguage } = useContext(AuthContext)

	const onValidate = useCallback(
		async ({ data, setValidationLoading, closeModal }) => {
			setValidationLoading(true)

			const mutate =
				mode === EFormModalMode.edit ? updateCustomer : createCustomer

			await mutate({
				variables: {
					input: formatCustomerAccountModalOnValidateData({
						data,
						mode,
						customerId,
					}),
				},
				refetchQueries: ['getCustomersList', 'getCustomer'],
			})
				.then(() => {
					mode === EFormModalMode.edit
						? toast.success('success.accountUpdate')
						: toast.success('success.accountCreate')

					setValidationLoading(false)
					closeModal()
					// redirectOnClose && navigate(location.pathname)
				})
				.catch((error) => {
					if (
						error?.graphQLErrors[0]?.extensions?.message ===
							'errors.existingCustomerUpdated' &&
						mode === EFormModalMode.create
					) {
						toast.success('success.customerAccountRestored')
						client.refetchQueries({
							include: ['getCustomersList', 'getCustomer'],
						})
						closeModal()
					}
					setValidationLoading(false)
				})
		},
		[customerId, mode]
	)

	const initializeData = useCallback(
		async ({ setValues, setLoading }) => {
			if (mode === EFormModalMode.edit) {
				setLoading(true)
				getCustomer({ variables: { id: customerId } })
					.then((res) => {
						const data = res?.data?.customer
						if (data) {
							setValues(
								formatCustomerAccountModalEditDefaultValues(data, localLanguage)
							)
						}

						setLoading(false)
					})
					.catch(() => setLoading(false))
			} else
				setValues(formatCustomerAccountModalCreateDefaultValues(currentUser))
		},
		[mode, localLanguage]
	)

	return (
		<FormModal
			modalUtils={modalUtils}
			formModalTabConfig={customerAccountsModalConfig}
			formModalName={intlMsg(
				mode === EFormModalMode.edit
					? 'administration.editCustomer'
					: 'administration.createCustomer'
			)}
			initialMode={mode}
			onValidate={onValidate}
			validateBtnText={intlMsg('misc.validate')}
			cancelBtnText={intlMsg('misc.cancel')}
			maxWidth='550px'
			minHeight='100%'
			initializeData={initializeData}
			onClose={onClose}
		/>
	)
}

export default CustomerAccountsModal
