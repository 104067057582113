import React, { useCallback, useState } from 'react'
import { Chip, Stack } from '@mui/material'
import ListChipsModulePopover from './ListChipsModulePopover'

interface IListChipsmodule {
	defaultModules?: any
}

const ListChipsmodule: React.FC<IListChipsmodule> = ({ defaultModules }) => {
	const [open, setOpen] = useState(false)
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

	const handleToggle: any = useCallback(
		(e: React.MouseEvent<HTMLButtonElement>) => {
			setOpen((prevOpen) => !prevOpen)
			setAnchorEl(e.currentTarget)
		},
		[]
	)

	const handleClose = useCallback(() => setOpen(false), [])

	const renderChips = useCallback(({ name }: { name: string }, i: number) => {
		if (i < 2)
			return (
				<Chip
					size='small'
					variant='outlined'
					color='primary'
					key={name}
					label={name}
					sx={{ mr: 0.5 }}
				/>
			)

		return <></>
	}, [])

	return (
		<>
			<Stack direction='row' sx={{ cursor: 'pointer' }}>
				{defaultModules?.map(renderChips)}

				{defaultModules?.length > 2 && (
					<Chip
						size='small'
						variant='outlined'
						color='primary'
						label={'...'}
						sx={{ mr: 0.5 }}
						onClick={handleToggle}
					/>
				)}
			</Stack>

			{open && (
				<ListChipsModulePopover
					handleClose={handleClose}
					anchorEl={anchorEl}
					defaultModules={defaultModules}
					open={open}
				/>
			)}
		</>
	)
}

export default ListChipsmodule
