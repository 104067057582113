export default {
	invoice: 'Invoices',
	create: 'Create Invoice',
	edit: 'Edit Invoice',
	reference: 'Reference / Title',
	title: 'Title',
	priceHT: 'Price excl. Tax',
	priceTTC: 'Price incl. Tax',
	tva: 'VAT',
	dueDate: 'Due Date',
	addBy: 'Added By',
	document: 'Document',
	fromVehicle: 'From Vehicle',
	fromIntervention: 'From mission',
	searchIntervention: 'Enter a reference, account, or site',
	searchVehicle: 'Enter a license plate or VIN',
}
