/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/display-name */
import React from 'react'

import { Chip } from '@mui/material'

import TasksAndCategoriesPopover from '../../../../multiItemsPopoverList/popover/tasksAndCategoriesPopover'
import OpenLinkCell from '../../../tableCell/OpenLinkCell'
import { formatDate } from '../../../../../utils/date'
import StatusVehicle from '../../../tableCell/StatusVehicle'
import UserAvatarSlidePanel from '../../../../avatar/UserAvatarSlidePanel'
import { ROUTES } from '../../../../../routes/consts'
import FormsListPopover from '../../../../multiItemsPopoverList/popover/formsListPopover'

const interventionsControlListVehiclesViewModalTable = [
	{
		columnLabel: 'vehicle.immat',
		orderBy: 'vehicle_vehicleDetail_immat',
		stickyFirstCol: true,
		personalizedCell: ({ vehicle: { id, vehicleDetail } }) => (
			<>
				{vehicleDetail?.immat ? (
					<OpenLinkCell
						txt={vehicleDetail.immat}
						viewModalLink={`${ROUTES.VIEWMODAL.VEHICLES.url}${id}`}
					/>
				) : (
					'-'
				)}
			</>
		),
	},

	{
		columnLabel: 'vehicle.vin',
		orderBy: 'vehicle_vehicleDetail_vin',
		personalizedCell: ({ vehicle: { id, vehicleDetail } }) => (
			<>
				{vehicleDetail?.vin ? (
					<OpenLinkCell
						txt={vehicleDetail.vin}
						viewModalLink={`${ROUTES.VIEWMODAL.VEHICLES.url}${id}`}
					/>
				) : (
					'-'
				)}
			</>
		),
	},

	{
		columnLabel: 'vehicle.brand',
		orderBy: 'vehicle_vehicleDetail_vehicleBrand_name',
		dataKey: 'vehicle.vehicleDetail.vehicleBrand.name',
	},

	{
		columnLabel: 'vehicle.model',
		orderBy: 'vehicle_vehicleDetail_vehicleModelText',
		dataKey: 'vehicle.vehicleDetail.vehicleModelText',
	},

	{
		columnLabel: 'vehicle.category',
		orderBy: 'vehicle_vehicleDetail_vehicleCategory_name',
		dataKey: 'vehicle.vehicleDetail.vehicleCategory.name',
	},

	{
		columnLabel: 'vehicle.km',
		orderBy: 'vehicleMileage_mileage',
		dataKey: 'vehicleMileage.mileage',
	},

	{
		columnLabel: 'interventions.task',
		personalizedCell: ({ products: { collection } }) => (
			<TasksAndCategoriesPopover tasks={collection} />
		),
	},

	{
		columnLabel: 'misc.reportIntervention',
		personalizedCell: ({ forms, formTemplates }) => (
			<FormsListPopover
				text='misc.reportIntervention'
				data={{ forms, formTemplates }}
			/>
		),
	},

	{
		columnLabel: 'misc.alerts',
		personalizedCell: ({ countAlerts }) => (
			<Chip
				size='small'
				color={countAlerts === 0 ? 'default' : 'warning'}
				disabled={countAlerts === 0 || false}
				label={countAlerts}
			/>
		),
	},

	{
		columnLabel: 'vehicle.statusVehicle',
		orderBy: 'status_code',
		cellAlign: 'center',
		personalizedCell: ({ status, comment }) => (
			<StatusVehicle status={status} comment={comment} />
		),
	},

	{
		columnLabel: 'misc.doBy',
		// orderBy: 'date', TODO waiting for back
		personalizedCell: ({ completionUser }) => (
			<UserAvatarSlidePanel
				firstName={completionUser?.firstName}
				lastName={completionUser?.lastName}
				userId={completionUser?.id}
				accountId={completionUser?.account?.id}
				displayName={false}
			/>
		),
	},

	{
		columnLabel: 'misc.doneDate',
		// orderBy: 'date', TODO waiting for back
		personalizedCell: ({ completionDate }) => (
			<>{completionDate ? formatDate(completionDate, true) : '-'}</>
		),
	},
]

export default interventionsControlListVehiclesViewModalTable
