import { makeStyles } from '@mui/styles'

export const FormSelectNationCSS = {
	height: '57px',
	color: 'white',
	borderRadius: 1,
	backgroundColor: '#ffffff0a',
	'&:hover': { backgroundColor: '#ffffff17' },

	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	minWidth: '235px',
	position: 'relative',

	'& svg': {
		position: 'absolute',
		right: '15px',
		zIndex: 1,
	},

	'& .selectCSS': {
		position: 'absolute',
		height: '100%',
		width: '100%',
		zIndex: 2,

		'& .css-cveggr-MuiListItemIcon-root': {
			display: 'none',
		},

		'& .css-tlelie-MuiListItemText-root': {
			display: 'none',
		},
		
		'& .css-ztf18dm-MuiButtonBase-root-MuiRadio-root': {
			display: 'none',
		},
		
		'& .css-ztf8dm-MuiButtonBase-root-MuiRadio-root': {
			display: 'none',
		},

		'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
			zIndex: '2'
		}
	},

	'& .textLayout': {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'start',
		justifyContent: 'center',

		height: '100%',
		width: '100%',
		padding: '0px 24px',

		'& .subtitle': {
			fontSize: '13px',
			fontWeight: '400'
		},

		'& .title': {
			fontSize: '16px',
			fontWeight: '500'
		}
	}
}

export const useStyles = makeStyles({
    flagIcon: {
        width: '25px',
        height: '25px',
    },

	whiteIcon: {
        color: 'white',
    },
})