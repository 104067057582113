import React from 'react'

import {
	ListItemText,
	Box,
	Radio,
	List,
	Popover,
	ListItemButton,
} from '@mui/material'
import { useFormatMessage } from '../../../../utils/hooks'
import { stringifiedColor } from '../../../../utils/color'
import { formatGeneric } from '../../../../utils/string'
import { isValidHexColor } from '../../../../utils/utils'

const buttonCSS = (color) => ({
	'&.Mui-selected': {
		background: stringifiedColor(color, 50) as any,
	},
})

const colorCSS = (color) => ({
	height: '11px',
	width: '11px',
	marginRight: '10px',
	marginLeft: '-5px',
	borderRadius: 1,
	border: 'none',
	backgroundColor: isValidHexColor(color)
		? color
		: (stringifiedColor(color || 'grey', 400) as any),
})

interface IFilterValuesPopover {
	handleClose
	open
	anchorEl
	handleRadioChange
	inputList
	selectedStatus
	uncolored: boolean
}

const FilterValuesPopover: React.FC<IFilterValuesPopover> = ({
	handleClose,
	open,
	anchorEl,
	handleRadioChange,
	inputList,
	selectedStatus,
	uncolored,
}) => {
	const intlMsg = useFormatMessage()
	const { code: codeSelected, color, categoryAll }: any = selectedStatus || {}

	return (
		<Popover open={open} anchorEl={anchorEl} onClose={handleClose}>
			<List>
				<ListItemButton
					sx={buttonCSS(categoryAll ? color : 'none')}
					onClick={handleRadioChange()}
					id={'all'}
					selected={categoryAll}
				>
					{!uncolored && <Box sx={colorCSS} />}

					<ListItemText primary={intlMsg('interventions.all')} />

					<Radio
						edge='end'
						size='small'
						color={'primary'}
						id={'all'}
						checked={categoryAll}
					/>
				</ListItemButton>

				{(formatGeneric(inputList) as any)?.map(({ name, color, id, code }) => {
					return (
						<ListItemButton
							key={name}
							sx={buttonCSS(codeSelected !== 'all' ? color : 'none')}
							onClick={handleRadioChange(id)}
							id={id}
							selected={code === codeSelected}
						>
							{!uncolored && <Box sx={colorCSS(color)}></Box>}
							{/* // if code is null it's a custom title (without traduction) */}
							<ListItemText
								primary={intlMsg(
									code
										? `interventions.${code.toLowerCase().split(' ').join('')}`
										: name
								)}
							/>

							<Radio
								edge='end'
								size='small'
								color={'primary'}
								checked={code === codeSelected}
							/>
						</ListItemButton>
					)
				})}
			</List>
		</Popover>
	)
}

export default FilterValuesPopover
