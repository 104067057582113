const queriesPaginationList = [
	'interventions',
	'agenciesAccounts',
	'alerts',
	'contacts',
	'customers',
	'formTemplates',
	'forms',
	'picturedForms',
	'interventionProgrammedVehicles',
	'invoices',
	'sites',
	'users',
	'notificationTypes',
	'teams',
	'vehicles',
]

const generateMergedQueriesPaginationObject = () => {
	const mergedQueriesPaginationObject = {}

	queriesPaginationList.forEach(
		(queryName) => (mergedQueriesPaginationObject[queryName] = { merge: true })
	)

	return mergedQueriesPaginationObject
}

const inMemoryCacheOptionsGenerator = () => ({
	typePolicies: {
		Query: {
			fields: {
				...generateMergedQueriesPaginationObject(),
			},
		},
		InterventionCollection: {
			fields: {
				interventionControlProgrammedVehicles: { merge: true },
			},
		},
	},
})

export default inMemoryCacheOptionsGenerator
