import React from 'react'

import { Stack } from '@mui/material'
import ArrowDownwardSharpIcon from '@mui/icons-material/ArrowDownwardSharp'
import DisplayTransportDate from './DisplayTransportDate'

const iconCSS = { margin: '6px' }

interface IEventTransportDate {
	interventionTransportTrips: any
}

const EventTransportDate: React.FC<IEventTransportDate> = ({
	interventionTransportTrips,
}) => {
	return (
		<Stack>
			<DisplayTransportDate
				itTrip={interventionTransportTrips[0]}
				transportTripType='removal'
			/>

			<ArrowDownwardSharpIcon sx={iconCSS} color='disabled' />

			<DisplayTransportDate
				itTrip={interventionTransportTrips[1]}
				transportTripType='delivery'
			/>
		</Stack>
	)
}

export default EventTransportDate
