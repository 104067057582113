import React, { useCallback, useContext } from 'react'
import toast from 'react-hot-toast'

import {
	formatProductBaseModalCreateDefaultValues,
	formatProductBaseModalEditDefaultValues,
	formatProductBaseModalOnValidateData,
} from './catalogueProductBaseModalUtils'
import {
	useCreateBaseProduct,
	useUpdateBaseProduct,
} from '../../../../../../api/gql/mutations/catalogProductsMutation'
import { IModalUtils, useFormatMessage } from '../../../../../../utils/hooks'
import { EFormModalMode } from '../../../../../../utils/app-models'
import FormModal from '../../../../../../components/forms/formModal'
import catalogueProductModalConfig from './catalogueProductBaseModalConfig'
import {
	useLazyCategoryBaseFormModal,
	useLazyProductBaseFormModal,
} from '../../../../../../api/gql/queries/catalogProductQueries'
import { MultiNavTableContext } from '../../../../../../components/multiNavTable/MultiNavTableProvider'

interface ICatalogueProductBaseModal {
	modalUtils: IModalUtils
	mode?: EFormModalMode
	onClose?: any
	productBaseId?: string
}

const CatalogueProductBaseModal: React.FC<ICatalogueProductBaseModal> = ({
	modalUtils,
	mode,
	productBaseId,
	onClose,
}) => {
	const intlMsg = useFormatMessage()
	const { sideNavSelectedId: categoryId } =
		useContext(MultiNavTableContext) || {}
	const [updateProductBase] = useUpdateBaseProduct()
	const [createProductBase] = useCreateBaseProduct()
	const { productBaseCategory } = useLazyCategoryBaseFormModal()
	const { getProductBase } = useLazyProductBaseFormModal()

	const onValidate = useCallback(
		async ({ data, setValidationLoading, closeModal }) => {
			setValidationLoading(true)
			const mutate =
				mode === EFormModalMode.edit ? updateProductBase : createProductBase

			await mutate({
				variables: {
					input: formatProductBaseModalOnValidateData({
						data,
						mode,
						productBaseId,
					}),
				},
				refetchQueries: [
					'getProductBasesList',
					'getProductBaseCategories',
					'getProductBaseDrawer',
				],
			})
				.then(() => {
					mode === EFormModalMode.edit
						? toast.success('success.productBaseUpdate')
						: toast.success('success.productBaseCreate')
					setValidationLoading(false)
					closeModal()
				})
				.catch(() => setValidationLoading(false))
		},
		[productBaseId, mode]
	)

	const initializeData = useCallback(
		async ({ setValues, setLoading }) => {
			if (mode === EFormModalMode.edit) {
				setLoading(true)
				getProductBase({ variables: { id: productBaseId } })
					.then((res) => {
						const data = res?.data?.productBase
						if (data) {
							setValues(formatProductBaseModalEditDefaultValues(data))
						}
						setLoading(false)
					})
					.catch(() => setLoading(false))
			} else {
				setLoading(true)

				const defaultCategory = await productBaseCategory({
					variables: { id: categoryId },
				})

				setValues(
					formatProductBaseModalCreateDefaultValues({
						defaultCategory: defaultCategory?.data?.productBaseCategory,
					})
				)
				setLoading(false)
			}
		},
		[mode, categoryId, productBaseId]
	)

	return (
		<FormModal
			modalUtils={modalUtils}
			formModalTabConfig={catalogueProductModalConfig}
			formModalName={intlMsg(
				mode === EFormModalMode.edit
					? 'catalogProduct.editProductBase'
					: 'catalogProduct.addProductBase'
			)}
			initialMode={mode}
			onValidate={onValidate}
			validateBtnText={intlMsg('misc.validate')}
			cancelBtnText={intlMsg('misc.cancel')}
			maxWidth='595px'
			width='50%'
			minHeight='50px'
			initializeData={initializeData}
			onClose={onClose}
		/>
	)
}

export default CatalogueProductBaseModal
