import React, { useCallback } from 'react'

import List from '@mui/material/List'

import NavItemsConfig from '../NavItemsConfig'
import PermissionsHandler from '../../../../permissionsHandler'
import NavItemClose from './NavItemClose'

const NavItemCloseListSx = { mt: 2, pt: '15px', pb: '15px' }

const NavItemsClose: React.FC = () => {
	const renderNavItems = useCallback(
		(item: any, i) => (
			<PermissionsHandler
				key={`${item?.label}-${i}`}
				permissionsName={item?.permissions}
				modulesName={item?.modules}
			>
				<NavItemClose config={item} key={i} />
			</PermissionsHandler>
		),
		[]
	)

	return (
		<List sx={NavItemCloseListSx}>{NavItemsConfig.map(renderNavItems)}</List>
	)
}

export default NavItemsClose
