import React from 'react'
import { Box, Typography } from '@mui/material'
import { useFormatMessage } from '../../utils/hooks'

const mainCSS = {
	maxWidth: '400px',
	display: 'flex',
	flexDirection: 'column',
	flexGrow: '1',
	justifyContent: 'center',
	height: '100%',
	padding: '0rem 2rem',
}

const InvalidToken: React.FC = () => {
	const intlMsg = useFormatMessage()

	return (
		<Box sx={mainCSS}>
			<div>
				<Typography
					variant='h6'
					sx={{ textTransform: 'uppercase', mb: 2, color: '#DF6574' }}
				>
					{intlMsg('user.errorTitle')}
				</Typography>
			</div>
			<div>
				<Typography variant='body2'>{intlMsg('user.errorText')}</Typography>
			</div>
		</Box>
	)
}

export default InvalidToken
