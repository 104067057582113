import React, { useCallback, useContext, useEffect } from 'react'

import { Fade, Stack, Grid, Typography, Paper } from '@mui/material'

import { useFormatMessage } from '../../../../utils/hooks'
import {
	Footer,
	ReportTitle,
	SelectCustomerAccount,
	SelectInterventionTypes,
	SelectStatus,
	TemlateFormsName,
} from './formInputs'

import { FormTemplateContext } from '../../../../utils/contexts/FormTemplateContext'

import { useGetCustomerListForSelect } from '../../../../api/gql/queries/customersQueries'

interface IModelEdition {}

const ModelEdition: React.FC<IModelEdition> = () => {
	const intlMsg = useFormatMessage()
	const {
		formUtils: {
			control,
			setValue,
			trigger,
			watch,
			getValues,
			formState: { errors },
		},
	} = useContext(FormTemplateContext)

	// Get customer list
	const { customers } = useGetCustomerListForSelect({
		fetchPolicy: 'network-only',
		variables: { mycust: true },
	})

	useEffect(() => {
		if (!getValues('customer')) {
			setValue('customer', 'all')
		}
	}, [customers])

	const handleSelect = useCallback(
		(name) => (e) => {
			setValue(name, e.target.value)
		},
		[]
	)

	const handleTrigger = useCallback((name) => () => trigger(name), [])

	return (
		<Paper sx={{ width: 1, p: 5, mb: 4 }}>
			<Fade in={true} timeout={100}>
				<Stack>
					<Stack direction='row' mb={3}>
						<Typography variant='h2'>
							{intlMsg('formTemplate.modelEdition')}
						</Typography>
					</Stack>

					<Grid container spacing={5}>
						<Grid item xs={12} lg={6}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TemlateFormsName
										control={control}
										onBlur={handleTrigger('name')}
									/>
								</Grid>

								<Grid item xs={12}>
									<SelectCustomerAccount
										formUtils={{ errors, watch, setValue }}
										customers={customers}
									/>
								</Grid>
								<Grid item xs={6}>
									<SelectInterventionTypes
										control={control}
										specialOnChange={handleSelect('interventionType')}
										onBlur={handleTrigger('interventionType')}
									/>
								</Grid>
								<Grid item xs={6}>
									<SelectStatus
										control={control}
										specialOnChange={handleSelect('isActive')}
										onBlur={handleTrigger('isActive')}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} lg={6}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<ReportTitle
										control={control}
										onBlur={handleTrigger('title')}
									/>
								</Grid>
								<Grid item xs={12}>
									<Footer control={control} />
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Stack>
			</Fade>
		</Paper>
	)
}

export default ModelEdition
