import React from 'react'

import Grid from '@mui/material/Grid'
import AccordionDetails from '@mui/material/AccordionDetails'

import { Draggable } from 'react-beautiful-dnd'

import FieldElement from './FieldElement'

interface IFieldsList {
	appendField
	snapshot
	getIcon
	fields
	fullWidth?: boolean
}

const FieldsList: React.FC<IFieldsList> = ({
	appendField,
	snapshot,
	getIcon,
	fields,
	fullWidth,
}) => {
	////////////////////////////////////////////

	return (
		<AccordionDetails>
			<Grid container spacing={2}>
				{fields?.map(({ fieldType, fieldModel }, i) =>
					fieldType === snapshot.draggingFromThisWith ? (
						<Grid key={`basicFieldsList-${fieldType}-${i + 1}`} item xs={6}>
							<FieldElement
								type={fieldType}
								fieldModel={fieldModel}
								Icon={getIcon(fieldType)}
								key={fieldType as any}
								appendField={appendField}
								draggableZone={false}
							/>
						</Grid>
					) : (
						<Draggable
							key={fieldModel || fieldType}
							draggableId={fieldModel || fieldType}
							index={i}
						>
							{(provided) => (
								<Grid
									key={`basicFieldsList-${fieldModel || fieldType}-${i}`}
									item
									xs={fullWidth ? 12 : 6}
									ref={provided.innerRef}
									{...provided.draggableProps}
									style={{ transform: 'none !important' }}
								>
									<div>
										<FieldElement
											type={fieldType}
											fieldModel={fieldModel}
											Icon={getIcon(fieldType)}
											key={fieldType as any}
											appendField={appendField}
											draggableZone={{ ...provided.dragHandleProps }}
										/>
									</div>
								</Grid>
							)}
						</Draggable>
					)
				)}
			</Grid>
		</AccordionDetails>
	)
}

export default FieldsList
