import React, { useRef, useEffect } from 'react'

import { useNavigate, useLocation } from 'react-router-dom'

import { Stack, Typography, Box, Divider } from '@mui/material'
import DateRangeIcon from '@mui/icons-material/DateRange'
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder'
import FmdGoodIcon from '@mui/icons-material/FmdGood'
import AssignmentIcon from '@mui/icons-material/Assignment'

import PhotosInterventions from '../PhotosInterventions'
import { getSeparateDateAndHour } from '../../../../../utils/date'
import { ROUTES } from '../../../../../routes/consts'
import { elementIsVisible, useFormatMessage } from '../../../../../utils/hooks'
import { handleOpenViewModal } from '../../../../../utils/utils'
import CustomLink from '../../../../../components/_overrideMui/Link/CustomLink'
import { blue, grey } from '@mui/material/colors'

const borderBottomCSS = {
	borderBottom: 'solid 1px',
	borderColor: ({ colors }) => colors.greySeparator,
}
const containerPhotos = {
	flexWrap: 'wrap',
	'& > *': { mb: 1, mr: 1 },
} as const

interface ISectionPhotosByVehicle {
	form
	setTrigger
	loading
}

const linkCSS = {
	textTransform: 'uppercase',
	display: 'flex',
	alignItems: 'center',
	fontSize: '0.875rem',
	gap: '4px',
	color: grey[600],
	'&:hover': {
		color: blue[700],

		'& a': {
			color: blue[700],
		},
	},
}

const iconWidth = {
	width: '1rem',
	height: '1rem',
}

const SectionPhotosByVehicle: React.FC<ISectionPhotosByVehicle> = ({
	form,
	setTrigger,
	loading,
}) => {
	const intlMsg = useFormatMessage()
	const navigate = useNavigate()
	const location = useLocation()

	// Trigger when element is visible (load new query - infiniteScroll )
	const ref: any = useRef(null)
	const inViewport = elementIsVisible(ref, '0px')

	useEffect(() => {
		setTrigger({ ref, inViewport })
	}, [inViewport, loading, ref])

	return (
		<>
			{form.map((itemVehicle, index) => (
				<Box key={index} sx={borderBottomCSS} ref={ref}>
					<Typography
						variant='title1'
						sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}
					>
						<CustomLink
							variant='title1'
							variantlink='primaryLink'
							onMouseDown={handleOpenViewModal(
								navigate,
								location,
								`${ROUTES.VIEWMODAL.VEHICLES.path}${itemVehicle?.vehicle?.id}`
							)}
						>
							{itemVehicle?.vehicle?.immat}
						</CustomLink>
					</Typography>

					{itemVehicle.form.map((itemVehicleDetail, index) => {
						const { date, hour } = getSeparateDateAndHour(
							itemVehicleDetail?.dateCreated
						)

						return (
							<Stack key={index} spacing={2} sx={{ mb: '20px' }}>
								<Stack direction='row' spacing={2} alignItems='center'>
									<>
										<Typography variant='text1' sx={linkCSS}>
											<AssignmentIcon sx={iconWidth} />
											<CustomLink
												variant='text1'
												variantlink='primaryLink'
												onMouseDown={handleOpenViewModal(
													navigate,
													location,
													`${ROUTES.VIEWMODAL.INTERVENTION_FORMS.url}${itemVehicleDetail?.idForm}`
												)}
											>
												{itemVehicleDetail?.name}
											</CustomLink>
										</Typography>

										<Divider orientation='vertical' flexItem />
									</>

									<CustomLink
										variant='text1'
										variantlink='primaryLink'
										href={`https://www.google.com/maps/?q=${itemVehicleDetail?.geolocation?.latitude},${itemVehicleDetail?.geolocation?.longitude}`}
										target='_blank'
									>
										<Typography variant='text1' sx={linkCSS}>
											<FmdGoodIcon sx={iconWidth} />
											{intlMsg('misc.localisation')}
										</Typography>
									</CustomLink>

									<Divider orientation='vertical' flexItem />

									<Typography variant='text1' sx={linkCSS}>
										<DateRangeIcon sx={iconWidth} />
										{date}
									</Typography>

									<Divider orientation='vertical' flexItem />

									<Typography variant='text1' sx={linkCSS}>
										<QueryBuilderIcon sx={iconWidth} />
										{hour}
									</Typography>
								</Stack>

								<Box display='flex' sx={containerPhotos}>
									{itemVehicleDetail?.blockPhotos?.map(
										({ attributeTemplateParse, record }, index) =>
											attributeTemplateParse?.map((_value, i) => (
												<PhotosInterventions
													record={record[i]}
													attributeTemplateParse={attributeTemplateParse}
													type={itemVehicleDetail?.blockPhotos[index].type}
													indexImg={i}
													key={i}
												/>
											))
									)}
								</Box>
							</Stack>
						)
					})}
				</Box>
			))}
		</>
	)
}

export default SectionPhotosByVehicle
