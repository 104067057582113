import React from 'react'

import translateIntlMsgString from '../../../../utils/translateIntlMsgString'
import TeamModalInfo from './TeamModalInfo'
import TeamModalUsers from './TeamModalUsers'
import { teamModalValidationSchema } from './teamModalValidationSchema'

export const teamModalConfig = [
	{
		tabName: translateIntlMsgString('misc.team'),
		component: <TeamModalInfo />,
		schema: teamModalValidationSchema,
	},
	{
		tabName: translateIntlMsgString('misc.users'),
		component: <TeamModalUsers />,
	},
]
