import React, { useCallback, useContext } from 'react'
import toast from 'react-hot-toast'
import qs from 'qs'

import {
	useCreateIntervention,
	useUpdateIntervention,
} from '../../../../../api/gql/mutations/interventionsMutation'

import { IModalUtils, useFormatMessage } from '../../../../../utils/hooks'
import { EFormModalMode } from '../../../../../utils/app-models'
import { useLazyInterventionStatus } from '../../../../../api/gql/queries/interventionsQueries'
import FormModal from '../../../../../components/forms/formModal'
import interventionModalConfig from './interventionTransportModalConfig'
import {
	formatInterventionModalCreateDefaultValues,
	formatInterventionModalEditDefaultValues,
	formatInterventionModalOnValidateData,
} from './interventionTransportModalUtils'
import { AuthContext } from '../../../../../utils/contexts/AuthContext'
import { useLazyInterventionTransportForModal } from '../../../../../api/gql/queries/interventionsTransportQueries'
import { useLazyCustomer } from '../../../../../api/gql/queries/customersQueries'
import { useLazyVehicle } from '../../../../../api/gql/queries/vehiclesQueries'
import { useLazySite } from '../../../../../api/gql/queries/sitesQueries'

interface IInterventionTransportModal {
	interventionId?
	startDate?: Date | null
	endDate?: Date | null
	isMonthSelection?: boolean
	onClose?: any
	mode?: EFormModalMode
	modalUtils: IModalUtils
	doNotDuplicate?: any
}

const InterventionTransportModal: React.FC<IInterventionTransportModal> = ({
	interventionId,
	startDate = null,
	endDate = null,
	isMonthSelection = false,
	onClose = null,
	mode,
	modalUtils,
	doNotDuplicate,
}) => {
	const intlMsg = useFormatMessage()

	const [createIntervention] = useCreateIntervention()
	const [updateIntervention] = useUpdateIntervention()
	const { getIntervention } = useLazyInterventionTransportForModal()
	const { currentUser, localLanguage } = useContext(AuthContext)
	const { getStatus } = useLazyInterventionStatus()
	const { getCustomer } = useLazyCustomer()
	const { getVehicle } = useLazyVehicle()
	const { getSite } = useLazySite()

	const queriesObject: any = {
		customer: getCustomer,
		site: getSite,
		vehicle: getVehicle,
	}

	const onValidate = useCallback(
		async ({ data, setValidationLoading, closeModal }) => {
			setValidationLoading(true)
			const mutate =
				mode === EFormModalMode.edit ? updateIntervention : createIntervention

			mutate({
				variables: {
					input: formatInterventionModalOnValidateData({
						data,
						mode,
						interventionId,
					}),
				},
				refetchQueries: [
					'interventionsTransportList',
					'interventionsListForCalendar',
					'getInterventionTransportForViewModal',
					'getInterventionTransportNumbersByCustomer',
					'getInterventionTransportNumbersBySite',
					'getInterventionTransportNumbersByVehicle',
					'IpvTransportList',
				],
			})
				.then(() => {
					mode === EFormModalMode.create
						? toast.success('success.transportCreate')
						: toast.success('success.transportUpdate')
					setValidationLoading(false)
					closeModal()
				})
				.catch(() => setValidationLoading(false))
		},
		[mode, interventionId]
	)

	const initializeData = useCallback(
		async ({ setValues, setLoading, setCurentEditedElement }) => {
			setLoading(true)
			getStatus()
				.then(async (res) => {
					const plannedStatus = res?.data?.interventionStatuses?.find(
						(status) => status?.name === 'A planifier'
					)

					// Edit intervention transport OR duplicate
					if (mode !== EFormModalMode.create) {
						getIntervention({ variables: { id: interventionId } })
							.then((res) => {
								const data = res?.data?.intervention || null

								setValues(
									formatInterventionModalEditDefaultValues({
										data,
										doNotDuplicate,
										currentUserAccountId: currentUser?.account?.id,
										isDuplicateTransport: mode === EFormModalMode.duplicate,
										forcedStatus:
											mode === EFormModalMode.duplicate ? plannedStatus : null,
										localLanguage,
									})
								)
								setCurentEditedElement(data)
								setLoading(false)
							})
							.catch(() => {
								setLoading(false)
							})
					}
					// Create intervention transport
					else {
						const { query, varValue } = qs.parse(location.search, {
							ignoreQueryPrefix: true,
						})
						let dataPreFilling = null

						if (mode === EFormModalMode.create && query) {
							const result = await queriesObject[query as string]({
								variables: { id: varValue },
							})
							dataPreFilling = result?.data[query as string]
						}

						setValues(
							formatInterventionModalCreateDefaultValues({
								currentUser,
								plannedStatus,
								dataPreFilling,
								startDate,
								endDate,
								isSite: query === 'site',
								isVehicle: query === 'vehicle',
							})
						)

						setLoading(false)
					}
				})
				.catch(() => setLoading(false))
		},
		[
			mode,
			interventionId,
			startDate,
			endDate,
			isMonthSelection,
			currentUser,
			localLanguage,
			doNotDuplicate,
		]
	)

	return (
		<FormModal
			modalUtils={modalUtils}
			formModalTabConfig={interventionModalConfig}
			formModalName={intlMsg(
				mode === EFormModalMode.edit
					? 'interventions.editTransport'
					: 'interventions.createTransport'
			)}
			initialMode={mode}
			onValidate={onValidate}
			validateBtnText={intlMsg('misc.validate')}
			cancelBtnText={intlMsg('misc.cancel')}
			initializeData={initializeData}
			onClose={onClose}
			width='95%'
			minHeight='79vH'
			editedElementId={interventionId}
		/>
	)
}

export default InterventionTransportModal
