import React from 'react'

import { EModule, EViewModal, dataListType } from '../../../../utils/app-models'
import VmCardExpended from '../../../../components/viewModal/vmCardExpended'
import PermissionsHandler from '../../../../components/permissionsHandler'
import DataList from '../../../../components/list/dataList'
import DataListGrid from '../../../../components/list/dataList/DataListGrid'

interface ITransportActors {
	actors
	agencies
	manager
	isSharedWithMe: boolean
}

const TransportActors: React.FC<ITransportActors> = ({
	actors,
	agencies,
	manager,
	isSharedWithMe,
}) => {
	return (
		<VmCardExpended title='interventions.interventionManager' titleGutter>
			<DataListGrid>
				<DataList
					dataType={dataListType.avatar}
					title='interventions.managerMission'
					data={[
						{
							firstName: manager?.firstName,
							lastName: manager?.lastName,
							userId: manager?.id,
							accountId: manager?.account?.id,
						},
					]}
					fullWidth
				/>

				<PermissionsHandler modulesName={[EModule.network]}>
					{!isSharedWithMe && (
						<DataList
							dataType={dataListType.vmLink}
							title='interventions.agence'
							data={agencies?.map(({ id, agencyCustomDetails }) => ({
								text: agencyCustomDetails[0]?.agencyName,
								link: { vm: EViewModal.CUSTOMERS, id },
							}))}
							modules={[EModule.network]}
							fullWidth
						/>
					)}
				</PermissionsHandler>

				<DataList
					dataType={dataListType.avatar}
					title='interventions.conveyor'
					data={actors.map(({ firstName, lastName, id, account }) => ({
						firstName,
						lastName,
						userId: id,
						accountId: account?.id,
					}))}
					fullWidth
				/>
			</DataListGrid>
		</VmCardExpended>
	)
}
export default TransportActors
