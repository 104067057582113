import React from 'react'

import catalogueProductBaseModalValidationSchema from './catalogueProductBaseModalValidationSchema'
import CatalogueProductBaseModalInfos from './CatalogueProductBaseModalInfos'

const catalogueProductBaseModalConfig = [
	{
		component: <CatalogueProductBaseModalInfos />,
		schema: catalogueProductBaseModalValidationSchema,
	},
]

export default catalogueProductBaseModalConfig
