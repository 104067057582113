import React, { useMemo } from 'react'
import { Radio, Stack } from '@mui/material'

interface IFormSelectSearchQueryRenderOption {
	props
	option
	selectedValue
	size
}

const FormSelectSearchQueryRenderOption: React.FC<
	IFormSelectSearchQueryRenderOption
> = ({ props, option, selectedValue, size }) => {
	const isOptionSelected = useMemo(
		() => option?.key === selectedValue?.key,
		[option, selectedValue]
	)
	return (
		<li {...props}>
			<Stack
				direction='row'
				justifyContent='space-between'
				alignItems='center'
				sx={{ width: 1 }}
			>
				{option?.label}
				<Radio
					sx={{ mr: 1 }}
					checked={isOptionSelected}
					color={isOptionSelected ? 'primary' : 'default'}
					size={size || 'small'}
				/>
			</Stack>
		</li>
	)
}

export default FormSelectSearchQueryRenderOption
