import React, { Fragment, useContext, useEffect, useState } from 'react'

import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { Grid, Box, Divider } from '@mui/material'

import { useFormatMessage } from '../../../../utils/hooks'
import { AuthContext } from '../../../../utils/contexts/AuthContext'
import { listCardViewModalCSS as css } from '../../../../styles/css/viewModal'
import UserAvatar from '../../../../components/avatar/UserAvatar'
import { ListItemsData } from '../../../../components'
import { ROUTES } from '../../../../routes/consts'
import { EModule } from '../../../../utils/app-models'

interface IActorsInfos {
	actors: any
	manager
	agencies
}

const ActorsInfos: React.FC<IActorsInfos> = ({ actors, manager, agencies }) => {
	const intlMsg = useFormatMessage()
	const { currentUser, checkModules } = useContext(AuthContext)
	const [accountsUsersList, setAccountsUsersList] = useState<any>([])

	useEffect(() => {
		const tmpCurrentAccountUsers: any = {}
		const tmpAccountsUsersList: any = []

		actors?.forEach(({ id, account, lastName, firstName }) => {
			if (account?.id === currentUser?.account?.id) {
				if (!tmpCurrentAccountUsers?.id) {
					tmpCurrentAccountUsers.name = currentUser?.account?.name
					tmpCurrentAccountUsers.id = currentUser?.account?.id
					tmpCurrentAccountUsers.users = [{ userId: id, lastName, firstName }]
				} else
					tmpCurrentAccountUsers.users.push({ userId: id, lastName, firstName })
			} else {
				const index = tmpAccountsUsersList?.findIndex(
					({ id }) => id === account?.id
				)

				if (index === -1) {
					tmpAccountsUsersList.push({
						name: account?.name,
						id: account?.id,
						users: [
							{ userId: id, lastName, firstName, accountId: account?.id },
						],
					})
				} else {
					tmpAccountsUsersList[index]?.users.push({
						userId: id,
						lastName,
						firstName,
						accountId: account?.id,
					})
				}
			}
			setAccountsUsersList(
				[tmpCurrentAccountUsers].concat(tmpAccountsUsersList)
			)
		})
	}, [currentUser, actors])

	return (
		<>
			<Paper sx={css.radius}>
				<Box sx={{ mb: 2 }}>
					<Grid container spacing={1} sx={{ pt: 1 }}>
						<Grid item xs={12}>
							<Typography variant='body2' sx={css.ListSubheader}>
								{intlMsg('interventions.interventionManager')}
							</Typography>
						</Grid>

						<Grid item xs={12}>
							{manager?.id ? (
								<UserAvatar
									userName={{
										firstName: manager?.firstName as string,
										lastName: manager?.lastName as string,
									}}
									userId={manager?.id}
									accountId={manager?.account?.id}
									displayName
									isListItemButton
								/>
							) : (
								<Typography variant='caption' sx={css.ListSubheader}>
									-
								</Typography>
							)}
						</Grid>
					</Grid>

					<Divider />

					<Grid container spacing={1} sx={{ pt: 1, pb: 1 }}>
						<Grid item xs={12}>
							<Typography variant='body2' sx={css.ListSubheader}>
								{intlMsg('interventions.intervenants')}
							</Typography>
						</Grid>

						{accountsUsersList?.length ? (
							accountsUsersList.map(({ name, users }, i) => (
								<React.Fragment key={name + i}>
									<Grid item xs={12}>
										<Typography variant='caption' sx={css.ListSubheader}>
											{name}
										</Typography>
									</Grid>

									{users?.map(
										({ userId, firstName, lastName, accountId }, i) => {
											return (
												<Grid item xs={12} key={i}>
													<UserAvatar
														userName={{
															firstName,
															lastName,
														}}
														userId={userId}
														displayName
														accountId={accountId}
														isListItemButton
													/>
												</Grid>
											)
										}
									)}
								</React.Fragment>
							))
						) : (
							<Grid item xs={12}>
								<Typography variant='caption' sx={css.ListSubheader}>
									-
								</Typography>
							</Grid>
						)}
					</Grid>
					{checkModules([EModule.network]) && (
						<>
							<Grid container spacing={1} sx={{ pt: 1 }}>
								<Grid item xs={12}>
									<Typography variant='body2' sx={css.ListSubheader}>
										{intlMsg('misc.interveningAgency')}
									</Typography>
								</Grid>
							</Grid>

							<Box sx={{ ...css.spaceRightLeft, mb: 1, pb: 2 }}>
								{agencies?.length ? (
									<ListItemsData
										nbCol={12}
										items={agencies.map(({ id, agencyCustomDetails }) => {
											return {
												primaryText: '',
												secondaryText:
													agencyCustomDetails[0]?.agencyName || '-',
												viewModalLink: `${ROUTES.VIEWMODAL.AGENCIES.url}${id}`,
												sizeColumn: 12,
												modules: [EModule.network],
											}
										})}
									/>
								) : (
									<Typography
										variant='caption'
										sx={{ ...css.ListSubheader, pl: 0, pt: 1 }}
									>
										{'-'}
									</Typography>
								)}
							</Box>
						</>
					)}
				</Box>
			</Paper>
		</>
	)
}

export default ActorsInfos
