import React, { useCallback, useContext, useEffect } from 'react'

import isEmpty from 'lodash.isempty'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'

import { ModalContext } from '../../../utils/contexts/ModalContext'
import { tabsCSS, tabCSS } from '../../../styles/sxCSS/tabs'
import ModalPanelTabPanel from './ModalPanelTabPanel'

const containerTabsCSS = {
	...tabsCSS,
	backgroundColor: ({ palette }) => palette.grey[100],
	'& .MuiTabs-scroller': { ml: 2 },
}

const BoxCSS = { width: 1 }

interface IModalPanel {
	selectedPanel: number
	setSelectedPanel: (num: number) => void
	modalContentConfig: any
	switchPanel: any
	setSwitchPanel: any
}

const ModalPanel: React.FC<IModalPanel> = ({
	modalContentConfig,
	selectedPanel,
	setSelectedPanel,
	switchPanel,
	setSwitchPanel,
}) => {
	const {
		formUtils: {
			formState: { errors },
			trigger,
		},
		tabCount,
	} = useContext(ModalContext)

	useEffect(() => {
		//alow to check error befor switching panel because errors is not up to date
		//in setTimeout
		if (switchPanel >= 0 && isEmpty(errors)) {
			setSelectedPanel(switchPanel)
		}
		setSwitchPanel(-1)
	}, [switchPanel, errors])

	const handleSelectPanel = useCallback(
		(i) => () => {
			trigger(modalContentConfig?.currentFields)
			setTimeout(() => {
				setSwitchPanel(i)
			}, 200)
		},
		[errors, trigger]
	)

	return (
		<Box sx={BoxCSS}>
			<Tabs
				value={selectedPanel}
				indicatorColor='primary'
				textColor='primary'
				variant='scrollable'
				scrollButtons='auto'
				sx={containerTabsCSS}
				TabIndicatorProps={{ children: <span /> }}
			>
				{modalContentConfig?.map(
					({ label, canNotAccess, selectorTabCount }, i) =>
						!canNotAccess && (
							<Tab
								key={`${i + 1}-tab`}
								label={
									<ModalPanelTabPanel
										label={label}
										count={tabCount?.[selectorTabCount]}
									/>
								}
								onClick={handleSelectPanel(i)}
								sx={tabCSS}
								disableRipple
							/>
						)
				)}
			</Tabs>
		</Box>
	)
}

export default ModalPanel
