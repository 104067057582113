import React from 'react'

import { Checkbox, Radio, Typography, Stack } from '@mui/material'

import TitleField from './TitleField'
import { EFormTemplateSelectionType } from '../../../../../../utils/app-models'

interface IFieldSelection {
	field
	deleteField
	index
	updateField
	insertField
}

const FieldSelection: React.FC<IFieldSelection> = ({
	field: { id, name, values, fieldType },
	deleteField,
	index,
	updateField,
	insertField,
}) => {
	const { selectionType, selectionsList } = values

	const fieldToEdit = {
		id,
		name,
		values,
		fieldType,
		updateField,
		index,
	}

	return (
		<Stack p={1}>
			<TitleField
				deleteField={deleteField}
				index={index}
				fieldToEdit={fieldToEdit}
				isRequired={values?.isRequired}
				insertField={insertField}
				values={values}
				fieldType={fieldType}
			/>
			<Stack>
				{selectionsList.map(({ name, selected }, i) => (
					<Stack
						key={`selectionListView-${name}-${i}`}
						direction='row'
						alignItems='center'
					>
						{selectionType === EFormTemplateSelectionType.multiples ? (
							<Checkbox
								checked={selected}
								color='primary'
								disabled
								style={{ color: selected ? '#2196F3' : '#5f5e5e' }}
							/>
						) : (
							<Radio
								checked={selected}
								color='primary'
								disabled
								style={{ color: selected ? '#2196F3' : '#5f5e5e' }}
							/>
						)}
						<Typography variant='body2' sx={{ ml: 1 }}>
							{name}
						</Typography>
					</Stack>
				))}
			</Stack>
		</Stack>
	)
}

export default FieldSelection
