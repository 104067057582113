import { EPeriodFilter } from '../../../../../../utils/app-models'

export const byVehicleDefaultFiltersValues = {
	period: EPeriodFilter.thisWeek,
	category: 'all',
	customer_list: null,
	intervention_actors_list: null,
	isWithoutActor: false,
	agency_list: null,
	intervention_status_list: null,
	valueStatusGroup: 'interventionAll',
}
