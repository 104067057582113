/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'

import ActionsBtn from '../../../tableCell/actionsBtn/v1'
import OpenLinkCell from '../../../tableCell/OpenLinkCell'
import { ROUTES } from '../../../../../routes/consts'
import { formatDate } from '../../../../../utils/date'
import LabelColorCell from '../../../tableCell/LabelColor'
import { useRemoveAlert } from '../../../../../api/gql/mutations/alertesMutation'
import { EModule, EPermissionScope } from '../../../../../utils/app-models'
import TimeCell from '../../../tableCell/TimeCell'

//!  columnLabel is mandatory
//!  dataKey OR personalizedCell is mandatory
// use datakey if you want to just display data
// personalized Cell is a function taking row as parameter

const transportListAlertsViewModalTable = [
	{
		columnLabel: 'misc.reference',
		orderBy: 'reference',
		dataKey: 'reference',
		maxCharacters: 15,
		stickyFirstCol: true,
	},

	{
		columnLabel: 'misc.vehicle',
		orderBy: 'vehicle_vehicleDetail_immat',
		personalizedCell: ({ vehicle }) => (
			<OpenLinkCell
				txt={vehicle?.vehicleDetail?.immat || vehicle?.vehicleDetail?.vin}
				viewModalLink={`${ROUTES.VIEWMODAL.VEHICLES.url}${vehicle?.id}`}
			/>
		),
	},

	{
		columnLabel: 'vehicle.brand',
		orderBy: 'vehicle_vehicleDetail_vehicleBrand_name',
		dataKey: 'vehicle.vehicleDetail.vehicleBrand.name',
	},

	{
		columnLabel: 'vehicle.model',
		orderBy: 'vehicle_vehicleDetail_vehicleModelText',
		dataKey: 'vehicle.vehicleDetail.vehicleModelText',
	},

	{
		columnLabel: 'misc.account',
		modules: [EModule.crm],
		orderBy: 'customer_name',
		personalizedCell: ({ customer }) => (
			<OpenLinkCell
				viewModalLink={`${ROUTES.VIEWMODAL.CUSTOMERS.url}${customer?.id}`}
				txt={customer?.name}
				maxCharacters={40}
			/>
		),
	},

	{
		columnLabel: 'misc.site',
		orderBy: 'site_name',
		personalizedCell: ({ site }) => (
			<OpenLinkCell
				viewModalLink={`${ROUTES.VIEWMODAL.SITE.url}${site?.id}`}
				txt={site?.name}
				maxCharacters={40}
				modules={[EModule.site]}
			/>
		),
	},

	{
		columnLabel: 'alerts.alertName',
		orderBy: 'name',
		dataKey: 'name',
		maxCharacters: 15,
	},

	{
		columnLabel: 'alerts.alertCategory',
		orderBy: 'category_name',
		personalizedCell: ({ category }) => <LabelColorCell category={category} />,
	},

	{
		columnLabel: 'alerts.alertOpen',
		orderBy: 'dateCreated',
		personalizedCell: ({ dateCreated }) => (
			<TimeCell date={dateCreated ? formatDate(dateCreated) : '-'} />
		),
	},

	{
		fixWidth: '50px',
		visible: true,
		columnLabel: '',
		tableCellAlign: 'center',
		cellAlign: 'center',
		sticky: true,
		personalizedCell: ({ id }) => (
			<ActionsBtn
				rowId={id}
				deleted={{
					query: useRemoveAlert,
					refreshQuery: ['getAlerts'],
					successMessageDelete: 'success.alertDeleted',
					messageConfirm: {
						title: 'confirmModal.deleteAlertTitle',
						message: 'confirmModal.deleteAlertMessage',
					},
				}}
				permissions={{
					remove: [EPermissionScope.alertRemove],
					delete: [EPermissionScope.alertDelete],
				}}
				viewAction={false}
			/>
		),
	},
]

export default transportListAlertsViewModalTable
