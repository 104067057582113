import React, { useCallback, useContext, useState } from 'react'

import { Divider, FormControlLabel, Switch } from '@mui/material'
import { useFormatMessage } from '../../../../../utils/hooks'
import { MultiNavTableContext } from '../../../MultiNavTableProvider'

interface ITableSideBarStatus {}

const TableSideBarStatus: React.FC<ITableSideBarStatus> = () => {
	const intlMsg = useFormatMessage()
	const { setSideNavIsActive, sideNavIsActive, setSideNavSelectedId } =
		useContext(MultiNavTableContext) || {}
	const [checked, setChecked] = useState(sideNavIsActive)

	const handleChangeStatus = useCallback(() => {
		setChecked(!checked)
		setSideNavIsActive(!checked)
		setSideNavSelectedId(null)
	}, [checked, setChecked, setSideNavSelectedId])

	return (
		<>
			<Divider orientation='vertical' flexItem />
			<FormControlLabel
				control={
					<Switch
						checked={checked}
						onChange={handleChangeStatus}
						size='small'
						color='primary'
					/>
				}
				label={intlMsg(checked ? 'misc.active' : 'misc.inactive')}
			/>
		</>
	)
}

export default TableSideBarStatus
