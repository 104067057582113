import React, { useContext } from 'react'

import { Controller } from 'react-hook-form'

import Skeleton from '@mui/material/Skeleton'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import { Stack, Switch, ListItemText, ListItem, List } from '@mui/material'

import { ModalContext } from '../../../../utils/contexts/ModalContext'
import { getAdminPanelAccountListModule } from '../../../../api/gql/queries/adminPanelAccounts'

const listCSS = {
	width: 1,
	backgroundColor: 'transparent',
	marginBottom: 1,
	borderBottom: 'solid 1px #dddddd5c',
	'& div.MuiButtonBase-root': {
		borderRadius: 1,
	},
}

const ListItemTextCSS = {
	'& .MuiListItemText-primary': { fontSize: '0.9rem', fontWeight: 500 },
	'& .MuiListItemText-secondary': { fontSize: '0.8rem' },
}

interface IAccountPermissions {
	editedAccount?
}

const AccountPermissions: React.FC<IAccountPermissions> = ({
	editedAccount,
}) => {
	const {
		formUtils: { control },
	} = useContext(ModalContext)

	const { data: listModule, loading: loadListModule } =
		getAdminPanelAccountListModule()

	const checkValueDefault = (idChecked) => {
		const valueMatch = editedAccount.customModules?.filter(
			({ id }) => id === idChecked
		)
		return valueMatch?.length === 1
	}

	return (
		<Stack p={2}>
			{!listModule || loadListModule ? (
				<Skeleton animation='wave' variant='rectangular' height={50} />
			) : (
				<List sx={listCSS}>
					{listModule?.map(({ title, description, id, name }) => (
						<Controller
							key={name}
							name={id}
							control={control}
							defaultValue={checkValueDefault(id)}
							render={({ field: { onChange, value, ...params } }) => (
								<ListItem
									button
									role={undefined}
									onClick={() => onChange(!value)}
								>
									<ListItemText
										primary={title}
										secondary={description}
										sx={ListItemTextCSS}
									/>

									<ListItemSecondaryAction>
										<Switch
											onChange={(e) => onChange(e.target.checked)}
											checked={value}
											size='small'
											{...params}
											color={value ? 'primary' : 'default'}
										/>
									</ListItemSecondaryAction>
								</ListItem>
							)}
						/>
					))}
				</List>
			)}
		</Stack>
	)
}

export default AccountPermissions
