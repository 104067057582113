/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import {
	ITableToolBarActionConfig,
	ITableToolBarConfig,
} from '../../../../../utils/typescript/tableType'
import { GET_PRODUCTS_LIST } from '../../../../../api/gql/queries/catalogProductQueries'
import { listProductSharedCatalogueViewTable } from '../../../../../components/tableV2/config/administration/productCatalogue/listProductSharedCatalogueViewTable'
import CustomTableTitle from '../../../../../components/multiNavTable/multiTableNavView/tableView/CustomTableTitle'

////////////////////////////////////////////////////////////////
// Global variable
////////////////////////////////////////////////////////////////

const queryConfig = {
	query: GET_PRODUCTS_LIST,
	queryName: 'products',
}

////////////////////////////////////////////////////////////////
// tableToolBarAction CONFIG
////////////////////////////////////////////////////////////////

const refreshQuerie = ['getProducts']

const tableToolBarActionConfig: ITableToolBarActionConfig = {
	refreshQuerie,
}

////////////////////////////////////////////////////////////////
// TableToolBarHeader CONFIG
////////////////////////////////////////////////////////////////

const tableToolBarConfig: ITableToolBarConfig = {
	customTitle: CustomTableTitle,
	stickyToolBar: true,
	tableConfig: listProductSharedCatalogueViewTable,
	localStorageName: 'listCatalogueProductSharedViewTableFilter',
	queryConfig,
}

////////////////////////////////////////////////////////////////
// tableContainer CONFIG
////////////////////////////////////////////////////////////////

const tableContainerConfig = {
	tableConfig: listProductSharedCatalogueViewTable,
	queryConfig,
}

////////////////////////////////////////////////////////////////
// -> EXPORT CONFIG MAIN TABLE
////////////////////////////////////////////////////////////////

export const catalogueProductShareConfigTable = {
	tableToolBarConfig,
	tableToolBarActionConfig,
	tableContainerConfig,
	displaySwitchOption: false,
}
