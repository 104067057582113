import React, { useCallback, useContext, useMemo } from 'react'

import Box from '@mui/material/Box'
import {
	FormModalContext,
	IFormModalContext,
} from '../../../../../../../components/forms/formModal/FormModalContext'

import Table from '../../../../../../../components/tableV2'
import { selectedVehiclesListConfig } from './selectedVehiclesListConfig'
import SelectVehiclesModalBtn from '../selectVehiclesModal/SelectVehiclesModalBtn'

const overflowCSS = {
	overflow: 'auto',
	'& .cellCSS:has(.MuiAutocomplete-root)': {
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'nowrap',
		justifyContent: 'flex-end',
		alignContent: 'stretch',
		alignItems: 'flex-start',
	},
}

interface ISelectedVehiclesList {
	openModal: any
}

const SelectedVehiclesList: React.FC<ISelectedVehiclesList> = ({
	openModal,
}) => {
	const { data, setValue } = useContext<IFormModalContext>(FormModalContext)

	const selectedVehicles = useMemo(
		() => data?.selectedVehicles || [],
		[data?.selectedVehicles]
	)

	const handleMultiDelete = useCallback(
		(selectedRow) => {
			const newSelectedVehicles = selectedVehicles.filter(
				(selectedVehicle) =>
					!selectedRow.some((row) => row?.id === selectedVehicle?.id)
			)
			setValue('selectedVehicles', newSelectedVehicles)
		},
		[selectedVehicles, setValue]
	)

	return (
		<Box sx={overflowCSS}>
			<Table
				onMultiDelete={handleMultiDelete}
				tableConfig={selectedVehiclesListConfig}
				forcedData={selectedVehicles}
				overrideConfigTable={{
					customBtnCreate: <SelectVehiclesModalBtn openModal={openModal} />,
					numberItem: selectedVehicles?.length,
				}}
			/>
		</Box>
	)
}

export default SelectedVehiclesList
