/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

import { formatGeneric } from '../../../../utils/string'
import { dataBlocksAllRecords, dataBlockLastAlerts } from '../data/dataCommon'

import {
	dataBlockForm,
	dataBlockTransport,
	dataBlockSignature,
} from '../data/dataTransport'

export const formatDataPdfTransport = (data, intlMsg) => {
	// delete unused key add by Appolo
	const cleanDataKey = formatGeneric(data) as any
	const isDelivery = cleanDataKey?.interventionTransportTrip?.listOrder === 1

	// format the data to be display on block
	const blockForm = dataBlockForm(cleanDataKey, intlMsg)
	const blockTransport = dataBlockTransport(cleanDataKey, intlMsg)
	const blockSignature = dataBlockSignature(cleanDataKey, intlMsg)
	const blockLastAlerts = dataBlockLastAlerts(cleanDataKey, intlMsg)
	const blocksAllRecords = dataBlocksAllRecords(cleanDataKey)

	const blocksAdditionalFooter = data?.formTemplate?.footer
		? { blocksAdditionalFooter: data.formTemplate.footer }
		: {}

	const configDataPDF = {
		blockForm,
		blockTransport,
		...(isDelivery && { blockSignature }),
		blockLastAlerts,
		blocksAllRecords,
		...blocksAdditionalFooter,
	}

	return configDataPDF
}
