import React, { useMemo } from 'react'

import { Typography, Stack, Divider, Box, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { stringifiedColor } from '../../../../utils/color'

const dividerCSS = {
	position: 'relative',
	width: ' 110%',
	marginLeft: '-5%',
	mt: 2,
	mb: 2,
}

interface ICalendarEventHeader {
	interType: string
	title: string
	status: string
	statusColor: string
	handleClose
}

const CalendarEventHeader: React.FC<ICalendarEventHeader> = ({
	interType,
	title,
	status,
	statusColor,
	handleClose,
}) => {
	const statusCSS = useMemo(
		() => ({
			background: stringifiedColor(statusColor || 'grey', 400),
			padding: ({ spacing }) => spacing(0.25, 1.5),
			color: 'white',
			borderRadius: 5,
		}),
		[statusColor]
	)

	return (
		<>
			<Stack
				direction='row'
				spacing={1}
				alignItems='center'
				justifyContent='space-between'
			>
				<Stack direction='row' spacing={2} alignItems='baseline'>
					<Typography variant='h2'>{interType}</Typography>
					<Typography
						color='inherit'
						variant='caption'
						sx={{ fontSize: '1rem' }}
					>
						{title}
					</Typography>

					<Box sx={statusCSS}>
						<Typography color='inherit' variant='caption'>
							{status}
						</Typography>
					</Box>
				</Stack>
				<IconButton aria-label='close' onClick={handleClose} sx={{ p: 0 }}>
					<CloseIcon />
				</IconButton>
			</Stack>

			<Divider flexItem sx={dividerCSS} variant='fullWidth' />
		</>
	)
}

export default CalendarEventHeader
