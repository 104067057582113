import { object, string } from 'yup'

import translateIntlMsgString from '../../../../utils/translateIntlMsgString'

export const editUserModalVaidationSchema = object().shape({
	firstName: string().required(
		translateIntlMsgString('validationSchema.require')
	),
	lastName: string().required(
		translateIntlMsgString('validationSchema.require')
	),
	functionUser: string(),
	phone: string(),
	cellphone: string(),
})
