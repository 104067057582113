import React, { useCallback, useMemo, useState } from 'react'

import { Box, Link, Popover, Stack, Typography } from '@mui/material'

import { useFormatMessage } from '../../../../utils/hooks'
import { grey } from '@mui/material/colors'
import ChipWithStates from '../../../chip/ChipWithStates'

interface IWTransportProducts {
	itTrip
	nbProductsToDisplay?: number
}

const WTransportProducts: React.FC<IWTransportProducts> = ({
	itTrip,
	nbProductsToDisplay = 5,
}) => {
	const intlMsg = useFormatMessage()

	const nbProducts = useMemo(
		() => itTrip?.products?.collection?.length || 0,
		[itTrip?.products?.collection]
	)

	/////////////////////////////////////////////////////////
	// Popover more products
	/////////////////////////////////////////////////////////

	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

	const handleClick = useCallback((event) => {
		setAnchorEl(event.currentTarget)
	}, [])

	const handleClose = useCallback(() => {
		setAnchorEl(null)
	}, [])

	const open = useMemo(() => Boolean(anchorEl), [anchorEl])

	return (
		<Stack spacing={1} width='50%'>
			<Typography variant='body2' sx={{ color: grey[600] }}>
				{intlMsg('interventions.task')}
			</Typography>
			<Box>
				<Stack spacing={0.5}>
					{nbProducts ? (
						<>
							{itTrip.products.collection
								.slice(0, nbProductsToDisplay)
								.map((product) => (
									<ChipWithStates
										key={product.id}
										isDone={product?.isDone}
										title={product.product.name}
									/>
								))}

							{nbProducts > nbProductsToDisplay && (
								<>
									<Link
										href='#'
										onClick={handleClick}
										underline='hover'
										fontWeight={700}
										fontSize={12}
									>
										{`${nbProducts - nbProductsToDisplay} ${intlMsg(
											`misc.other${
												nbProducts - nbProductsToDisplay > 1 ? 's' : ''
											}`
										)}`}
									</Link>

									<Popover
										open={open}
										anchorEl={anchorEl}
										onClose={handleClose}
										anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
									>
										<Stack spacing={0.5} p={2}>
											{itTrip.products.collection
												.slice(nbProductsToDisplay, nbProducts)
												.map((product) => (
													<ChipWithStates
														key={product.id}
														isDone={product?.isDone}
														title={product.product.name}
													/>
												))}
										</Stack>
									</Popover>
								</>
							)}
						</>
					) : (
						<Typography variant='body2' sx={{ color: grey[600] }}>
							-
						</Typography>
					)}
				</Stack>
			</Box>
		</Stack>
	)
}

export default WTransportProducts
