export const optionsDoughnut = {
	plugins: {
		title: {
			display: false,
		},
		legend: {
			display: false,
		},
	},
	cutout: '88%', // thickness
	radius: '85%', // size
	responsive: true,
	maintainAspectRatio: false,
	rotation: 270,
	circumference: 180,

	layout: {
		padding: {
			bottom: 0,
			right: 0,
			left: 0,
			top: 0,
		},
	},
}
