import React from 'react'

import ModalProvider from '../../../providers/ModalProvider'
import { vehicleTrackingSchema } from '../../../utils/validationSchema'
import WVehicleTrackingContent from './WVehicleTrackingContent'

interface IWVehicleTracking {
	vehicleId: string
}

const WVehicleTracking: React.FC<IWVehicleTracking> = ({ vehicleId }) => {
	//TODO HERE OLD VERSION OF ModalProvider
	//WE NEED TO CHANGE THIS
	return (
		<ModalProvider validationSchemaFunction={vehicleTrackingSchema}>
			<WVehicleTrackingContent vehicleId={vehicleId} />
		</ModalProvider>
	)
}

export default WVehicleTracking
