import React, { cloneElement, useCallback } from 'react'
import Dialog from '@mui/material/Dialog'

interface IConfirmModal {
	children
	onClose
	open
}

const InformationModal: React.FC<IConfirmModal> = ({
	children,
	onClose,
	open = false,
}) => {
	const handleClose = useCallback(() => {
		onClose()
	}, [])

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperProps={{ style: { borderRadius: 16, maxWidth: '100%' } }}
		>
			{children && cloneElement(children, { handleClose })}
		</Dialog>
	)
}

export default InformationModal
