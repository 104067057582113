import React, { useCallback } from 'react'

import qs from 'qs'
import toast from 'react-hot-toast'

import { usePostMedia } from '../../../../api/gql/mutations/awsMutation'
import { useVehicleInvoice } from '../../../../api/gql/queries/vehiclesQueries'
import FormModal from '../../../../components/forms/formModal'
import { EFormModalMode } from '../../../../utils/app-models'
import { IModalUtils, useFormatMessage } from '../../../../utils/hooks'
import invoiceModalConfig from './documentVehicleModalConfig'

import {
	formatDocumentVehicleModalEditDefaultValues,
	formatDocumentVehicleModalOnValidateData,
} from './vehicleDocumentModalUtils'
import {
	useCreateDocumentVehicle,
	useRemoveDocumentVehicle,
	useUpdateDocumentVehicle,
} from '../../../../api/gql/mutations/documentsVehiclesMutation'
import {
	useLazyDocumentCategoriesList,
	useLazyDocumentVehicleForModal,
} from '../../../../api/gql/queries/documentsVehiclesQueries'
import { useInterventionInvoice } from '../../../../api/gql/queries/interventionsQueries'

interface IVehicleDocumentModal {
	documentVehicleId?: string | null
	modalUtils: IModalUtils
	mode?: EFormModalMode
	onClose?: any
}

const VehicleDocumentModal: React.FC<IVehicleDocumentModal> = ({
	modalUtils,
	mode,
	documentVehicleId,
	onClose,
}) => {
	const intlMsg = useFormatMessage()

	const { query, varValue } = qs.parse(location.search, {
		ignoreQueryPrefix: true,
	}) as any

	// !!! For intervention type "transport", you need to use the queryName = "interventionTransport" in the condition on getViewModalConfig.ts, but "transport" currently uses the query 'intervention'.
	// !!! To find similar problems, search for #currentQueryNameTransport
	const currentQueryName = ['interventionTransport'].includes(query)
		? 'intervention'
		: query

	const { getDocumentVehicle } = useLazyDocumentVehicleForModal()
	const [updateDocumentVehicle] = useUpdateDocumentVehicle()
	const [createDocumentVehicle] = useCreateDocumentVehicle()
	const { getVehicle } = useVehicleInvoice()
	const { getIntervention } = useInterventionInvoice()
	const { getCategoryDocumentVehicle } = useLazyDocumentCategoriesList()
	const [postMedia] = usePostMedia()

	const onValidate = useCallback(
		async ({ data, setValidationLoading, closeModal }) => {
			if (data?.importFile?.[0] && !data.importFile[0]?.type) {
				return toast.error('errors.errorFileIsCorrupted')
			}

			setValidationLoading(true)
			const mutate =
				mode === EFormModalMode.edit
					? updateDocumentVehicle
					: createDocumentVehicle

			try {
				// Create or update invoice ///////////////////////////////////////////////////
				const resultMutateDocumentVehicle = await mutate({
					variables: {
						input: formatDocumentVehicleModalOnValidateData({
							data,
							documentVehicleId,
							mode,
						}),
					},
					refetchQueries: [
						'getDocumentsVehicles',
						'getDocumentsVehicleCountByVehicle',
						'getDocumentsVehicleCountByIntervention',
					],
				})

				const idDocumentVehicleCreation =
					mode === EFormModalMode.edit
						? resultMutateDocumentVehicle.data?.updateDocument?.document?.id
						: resultMutateDocumentVehicle.data?.createDocument?.document?.id

				// Successfully create or update document & if file exist /////////////////////////
				if (idDocumentVehicleCreation && data?.importFile?.[0]?.type) {
					const { size, name, type } = data.importFile[0]

					// Get url AWS to send file
					const resultPostMedia = await postMedia({
						variables: {
							input: {
								name,
								iri: idDocumentVehicleCreation,
								contentType: type,
								contentLength: size.toString(),
							},
						},
					})

					const urlAwsBucketToPost =
						resultPostMedia?.data?.postAwsMediaUrl?.awsMediaUrl?.url

					// If get the urlAws, send image to AWS bucket
					if (urlAwsBucketToPost) {
						const resultUrlAwsBucketToPost = await fetch(urlAwsBucketToPost, {
							method: 'PUT',
							headers: { 'Content-Type': type },
							body: data.importFile[0],
						})

						// Delete the document if error for upload file (only for creation document)
						if (
							resultUrlAwsBucketToPost.status !== 200 &&
							EFormModalMode.create
						) {
							const [deleteDocumentVehicle] = useRemoveDocumentVehicle()

							await deleteDocumentVehicle({
								variables: { input: { id: idDocumentVehicleCreation } },
								refetchQueries: [
									'getDocumentsVehicles',
									'getDocumentsVehicleCountByVehicle',
								],
							}).then(() => {
								toast.error('errors.errorAddFileDocumentVehicle')
							})
						}
					}
				}

				mode === EFormModalMode.edit
					? toast.success('success.documentVehicleUpdate')
					: toast.success('success.documentVehicleCreate')

				closeModal()
			} catch (error) {
				mode === EFormModalMode.edit
					? toast.error('errors.errorUpdateDocumentVehicle')
					: toast.error('errors.errorCreateDocumentVehicle')
			}

			setValidationLoading(false)
		},
		[documentVehicleId, mode]
	)

	const initializeData = useCallback(
		async ({ setValues, setLoading }) => {
			// Edit document
			if (mode === EFormModalMode.edit) {
				setLoading(true)

				getDocumentVehicle({ variables: { id: documentVehicleId } })
					.then(async (res) => {
						const data = res?.data?.document
						if (data) {
							const categories = await getCategoryDocumentVehicle()
							setValues(
								formatDocumentVehicleModalEditDefaultValues(data, categories)
							)
						}
						setLoading(false)
					})
					.catch(() => setLoading(false))
			}
			// if currentQueryName we are in viewModal
			else if (currentQueryName) {
				setLoading(true)
				const currentQuery =
					currentQueryName === 'intervention' ? getIntervention : getVehicle
				const { data } = await currentQuery({ variables: { id: varValue } })
				setValues({ [currentQueryName]: data[currentQueryName]?.id })
				setLoading(false)
			}
		},
		[mode, documentVehicleId]
	)

	return (
		<FormModal
			modalUtils={modalUtils}
			formModalTabConfig={invoiceModalConfig}
			formModalName={intlMsg(
				mode === EFormModalMode.edit
					? 'documentVehicle.editDocument'
					: 'documentVehicle.addDocument'
			)}
			initialMode={mode}
			onValidate={onValidate}
			validateBtnText={intlMsg('misc.validate')}
			cancelBtnText={intlMsg('misc.cancel')}
			maxWidth='700px'
			width='70%'
			minHeight='50px'
			initializeData={initializeData}
			onClose={onClose}
		/>
	)
}

export default VehicleDocumentModal
