export default {
	documents: 'Documents',

	ref: 'Intitulé',
	type: 'Type',
	typeDocument: 'Type de document',

	addDocument: 'Ajouter un document',
	editDocument: 'Editer un document',

	// categories documents
	PV: 'Avis de contravention',
	CG: "Certificat d'immatriculation",
	CSA: 'Certificat de situation administrative',
	CAS: 'Certificat d’assurance',
	CTA: 'Contrat d’assurance',
	EAS: 'Échéancier assurance',
	CA: 'Constat amiable',
	CLOC: 'Contrat de location',
	ELOC: 'Échéancier location',
	CT: 'Contrôle technique',
	DV: 'Devis',
	OTHER: 'Autres',
	CCS: 'Certificat de cession',
	PVB: 'Procès verbal',
}
