import React, { useCallback, useContext, useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'

import { Stack, Typography, Link, Grid } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import { onSubmit, Email, Password, Remember } from './loginForms'
import { useLazyProfileUserId } from '../../api/gql/queries/usersQueries'
import { ROUTES } from '../../routes/consts'
import { AuthContext } from '../../utils/contexts/AuthContext'
import { useFormatMessage } from '../../utils/hooks'
import { loginValidationSchema } from '../../utils/validationSchema'
import LayoutLogout from '../../components/layout/LayoutLogout'
import { blockLeftCSS } from '../../styles/sxCSS/logout/logout'

interface ILogin {}

const Login: React.FC<ILogin> = () => {
	const intlMsg = useFormatMessage()

	const navigate = useNavigate()
	const { setCurrentUserId } = useContext(AuthContext)
	const [loading, setLoading] = useState(false)
	const { getProfileUserId, profileUserId, loadingProfileUserId } =
		useLazyProfileUserId({ fetchPolicy: 'network-only' })

	useEffect(() => {
		if (!loadingProfileUserId && profileUserId) {
			setCurrentUserId(profileUserId)
			setLoading(false)
		}
	}, [profileUserId, loadingProfileUserId, setCurrentUserId])

	const { handleSubmit, control, watch, trigger } = useForm({
		resolver: yupResolver(loginValidationSchema(intlMsg)),
	})

	const handleTrigger = useCallback((name) => () => trigger(name), [])

	const emailCustomer = watch('email')

	const addEmailToStorage = useCallback(() => {
		emailCustomer && localStorage.setItem('lostPasswordEmail', emailCustomer)
		//TOTEST changed here
		navigate(ROUTES.FORGOTTEN_PASSWORD.url)
	}, [emailCustomer, navigate])

	return (
		<LayoutLogout displaySubActions>
			<Grid container sx={{ height: 1 }}>
				<Grid item xs={12} md={6} sx={blockLeftCSS}>
					<Stack p={4} sx={{ width: { xs: 300, md: 500 } }}>
						<img src={'/img/sessions/connection.svg'} />
					</Stack>
				</Grid>

				<Grid item xs={12} md={6}>
					<Stack p={8} spacing={2} justifyContent='center' sx={{ height: 1 }}>
						<Typography variant='h1'>{intlMsg('misc.connexion')}</Typography>
						<Typography variant='body2'>
							{intlMsg('misc.connectWelcome')}
						</Typography>

						<form
							onSubmit={handleSubmit((data, e) =>
								onSubmit(data, e, setLoading, getProfileUserId)
							)}
						>
							<Stack spacing={2} mb={4} mt={4}>
								<Email
									forms={control}
									onBlur={handleTrigger}
									label={intlMsg('misc.email')}
								/>
								<Password
									forms={control}
									onBlur={handleTrigger}
									label={intlMsg('misc.password')}
								/>
							</Stack>

							<Stack
								direction='row'
								alignItems='center'
								justifyContent='space-between'
								mb={1}
							>
								<Remember forms={control} label={intlMsg('user.rememberMe')} />
								<Link
									underline={'hover'}
									sx={{ cursor: 'pointer' }}
									color='primary'
									onClick={addEmailToStorage}
								>
									{intlMsg('misc.passwordForgot')}
								</Link>
							</Stack>

							<LoadingButton
								variant='contained'
								type='submit'
								loading={loading}
								fullWidth
							>
								{intlMsg('misc.connexion')}
							</LoadingButton>
						</form>
					</Stack>
				</Grid>
			</Grid>
		</LayoutLogout>
	)
}

export default Login
