import React, { useCallback, useContext } from 'react'

import toast from 'react-hot-toast'
import {
	useCreateSite,
	useUpdateSite,
} from '../../../../api/gql/mutations/customerSitesMutations'
import { useLazySiteForModal } from '../../../../api/gql/queries/sitesQueries'

import FormModal from '../../../../components/forms/formModal'
import { EFormModalMode } from '../../../../utils/app-models'
import { AuthContext } from '../../../../utils/contexts/AuthContext'
import { IModalUtils, useFormatMessage } from '../../../../utils/hooks'
import customerSiteModalConfig from './customerSiteModalConfig'
import {
	formatSiteModalCreateDefaultValues,
	formatSiteModalEditDefaultValues,
	formatSiteModalOnValidateData,
} from './customerSiteModalUtils'

import { useLazyCustomer } from '../../../../api/gql/queries/customersQueries'
import qs from 'qs'

interface ICustomerSiteModal {
	siteId?: string | null
	modalUtils: IModalUtils
	mode?: EFormModalMode
	onClose?: any
}

const CustomerSiteModal: React.FC<ICustomerSiteModal> = ({
	modalUtils,
	mode,
	siteId,
	onClose,
}) => {
	const intlMsg = useFormatMessage()
	const [updateSite] = useUpdateSite()
	const [createSite] = useCreateSite()
	const { currentUser, localLanguage } = useContext(AuthContext)
	const { getSite } = useLazySiteForModal()
	const { getCustomer } = useLazyCustomer()

	const queriesObject: any = {
		customer: getCustomer,
		site: getSite,
	}

	const onValidate = useCallback(
		async ({ data, setValidationLoading, closeModal }) => {
			setValidationLoading(true)
			const mutate = mode === EFormModalMode.edit ? updateSite : createSite

			await mutate({
				variables: {
					input: formatSiteModalOnValidateData({ data, mode, siteId }),
				},
				refetchQueries: [
					'getSitesList',
					'getSitesNumbers',
					'getSite',
					'getCustomer',
					'getSitesAndNetworksList',
				],
			})
				.then(() => {
					mode === EFormModalMode.edit
						? toast.success('success.siteUpdate')
						: toast.success('success.siteCreate')
					setValidationLoading(false)
					closeModal()

					// redirectOnClose && navigate(location.pathname)
				})
				.catch(() => setValidationLoading(false))
		},
		[siteId, mode]
	)

	const initializeData = useCallback(
		async ({ setValues, setLoading }) => {
			if (mode === EFormModalMode.edit) {
				setLoading(true)
				getSite({ variables: { id: siteId } })
					.then((res) => {
						const data = res?.data?.site
						if (data)
							setValues(
								formatSiteModalEditDefaultValues({ data, localLanguage })
							)
						setLoading(false)
					})
					.catch(() => setLoading(false))
			} else {
				let dataPreFilling = null
				const { query, varValue } = qs.parse(location.search, {
					ignoreQueryPrefix: true,
				})
				if (mode === EFormModalMode.create && query) {
					const result = await queriesObject[query as string]({
						variables: { id: varValue },
					})
					dataPreFilling = result?.data[query as string]
				}

				setLoading(true)
				setValues(
					formatSiteModalCreateDefaultValues({
						currentUser,
						dataPreFilling,
					})
				)
				setLoading(false)
			}
		},
		[mode, siteId, currentUser, localLanguage]
	)

	return (
		<FormModal
			modalUtils={modalUtils}
			formModalTabConfig={customerSiteModalConfig}
			formModalName={intlMsg(
				mode === EFormModalMode.edit
					? 'administration.editSite'
					: 'administration.createSite'
			)}
			initialMode={mode}
			onValidate={onValidate}
			validateBtnText={intlMsg('misc.validate')}
			cancelBtnText={intlMsg('misc.cancel')}
			maxWidth='1000px'
			width='70%'
			minHeight='50px'
			initializeData={initializeData}
			onClose={onClose}
		/>
	)
}

export default CustomerSiteModal
