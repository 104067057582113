export const selectsIDstatusByGroup = (
	setValueStatusGroup,
	valueStatusGroup,
	statusInterventionList,
	setCurrentFilter,
	argumentQuerieName
) => {
	switch (valueStatusGroup) {
		case 'interventionAll':
			setValueStatusGroup(valueStatusGroup)
			setCurrentFilter({
				valueStatusGroup,
				[argumentQuerieName]: null,
			})
			break

		case 'interventionActive':
			setValueStatusGroup(valueStatusGroup)
			setCurrentFilter({
				valueStatusGroup,
				[argumentQuerieName]: statusInterventionList
					.filter(({ code }) => code !== 'DONE' && code !== 'CANCELED')
					.map(({ id }) => id),
			})

			break

		case 'interventionFinish':
			setValueStatusGroup(valueStatusGroup)
			setCurrentFilter({
				valueStatusGroup,
				[argumentQuerieName]: statusInterventionList
					.filter(({ code }) => code === 'DONE' || code === 'CANCELED')
					.map(({ id }) => id),
			})
			break

		case 'interventionCustom':
			break

		default:
			break
	}
}
