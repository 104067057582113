/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

import { EFormFieldsPDF } from '../../../../utils/app-models'
import { formatDate } from '../../../../utils/date'

// Format records
export const formatRecord = (type, record) => {
	switch (type) {
		case EFormFieldsPDF.control:
			return ''

		case EFormFieldsPDF.parkDate:
		case EFormFieldsPDF.date:
			return record?.value?.value
				? formatDate(record?.value?.value, false)
				: '-'

		case EFormFieldsPDF.instruction:
			return record || '-'

		case EFormFieldsPDF.text:
			return record?.value?.value || '-'

		case EFormFieldsPDF.title:
			return record?.value?.value || ''

		case EFormFieldsPDF.barCode:
			return record?.value?.value || '-'

		case EFormFieldsPDF.signature:
			return record?.value?.value ? '' : '-'

		case EFormFieldsPDF.selection:
			return ''

		case EFormFieldsPDF.rating:
			return ''

		case EFormFieldsPDF.picture:
			return '-'

		case EFormFieldsPDF.numbers:
			return record?.value?.value?.toString() || '-'

		// return empty text when record need special display (draw rating, multiSelect,... )
		default:
			return record?.value?.value ? '' : '-'
	}
}

/////////////////////////////////////////////////////////
// 3 CELL
/////////////////////////////////////////////////////////

// Generate title - row & 3 cell (1 right & left cell to add padding)
export const row3CellTitle = (intlMsg?: any, title?: string | null) => {
	return {
		left: '',
		value1: title && intlMsg ? intlMsg(title) : '',
		right: '',
	}
}

// Generate value record - row & 3 cell (1 right & left cell to add padding)
export const row3CellValue = (record?: any | null, blankValue?: boolean) => {
	return {
		left: '',
		value1: {
			record: record?.value ? record?.value : blankValue ? '' : '-',
			type: record?.type,
			additionalValue: record?.additionalValue,
		},
		right: '',
	}
}

/////////////////////////////////////////////////////////
// 4 CELL
/////////////////////////////////////////////////////////

// Generate title - row & 4 cell (1 right & left cell to add padding)
export const row4CellTitle = (
	intlMsg?: any,
	title1?: string | null,
	title2?: string
) => {
	return {
		left: '',
		value1: title1 && intlMsg ? intlMsg(title1) : '',
		value2: title2 && intlMsg ? intlMsg(title2) : '',
		right: '',
	}
}

// Generate value record - row & 4 cell (1 right & left cell to add padding)
export const row4CellValue = (
	record1?: any | null,
	record2?: any | null,
	blankValue?: boolean
) => {
	return {
		left: '',
		value1: {
			record: record1?.value ? record1?.value : blankValue ? '' : '-',
			type: record1?.type,
			additionalValue: record1?.additionalValue,
		},
		value2: {
			record: record2?.value ? record2?.value : blankValue ? '' : '-',
			type: record2?.type,
			additionalValue: record2?.additionalValue,
		},
		right: '',
	}
}

/////////////////////////////////////////////////////////
// 5 CELL
/////////////////////////////////////////////////////////

// Generate title - row & 5 cell (2 for values & 3 right/center/left cell to add padding)
export const row5CellTitle = (
	intlMsg?: any,
	title1?: string | null,
	title2?: string
) => {
	return {
		left: '',
		value1: title1 && intlMsg ? intlMsg(title1) : '',
		center: '',
		value2: title2 && intlMsg ? intlMsg(title2) : '',
		right: '',
	}
}

// Generate value record - row & 5 cell (2 for values & 3 right/center/left cell to add padding)
export const row5CellValue = (
	record1?: any | null,
	record2?: any | null,
	blankValue?: boolean
) => {
	return {
		left: '',
		value1: {
			record: record1?.value ? record1?.value : blankValue ? '' : '-',
			type: record1?.type,
			additionalValue: record1?.additionalValue,
		},
		center: '',
		value2: {
			record: record2?.value ? record2?.value : blankValue ? '' : '-',
			type: record2?.type,
			additionalValue: record2?.additionalValue,
		},
		right: '',
	}
}
