import React, { useCallback, useMemo, useState } from 'react'

import { Stack, Popover, Typography } from '@mui/material'

import { useFormatMessage } from '../../../utils/hooks'
import CustomEvent from './CustomEvent'
import { EInterventionTypes } from '../../../utils/app-models'

const showMoreBtnCSS = { cursor: 'pointer', color: '#2F5DD1' }

const CustomEventCSS = {
	'& >*': {
		borderRadius: '4px 4px 4px 4px',
		border: '1px solid #F2F2F2',
		cursor: 'pointer',
		background: '#FAFAFA',
	},
}

const popoverCSS = { maxHeight: '420px', minWidth: '250px', maxWidth: '300px' }

const anchorOrigin = { vertical: 'bottom', horizontal: 'left' } as const

interface ICalendarShowMore {
	eventsNumber: number
	selectedShowMoreEvents: any[] | null
	selectedInter: string[]
	searchText: string
}

const CalendarShowMore: React.FC<ICalendarShowMore> = ({
	eventsNumber,
	selectedShowMoreEvents,
	selectedInter,
	searchText,
}) => {
	const intlMsg = useFormatMessage()
	const [anchorEl, setAnchorEl] = useState(null)

	/////////////////////////////////////////////////////////
	// sorted interventions by interventionType
	/////////////////////////////////////////////////////////

	const sortedInterventions = useMemo(() => {
		return Object.keys(EInterventionTypes)
			.filter((interventionType) =>
				selectedShowMoreEvents?.some(
					({ resource }) => resource?.interventionType === interventionType
				)
			)
			.map((interventionType) => ({
				interventionType,
				interventions: selectedShowMoreEvents?.filter(
					({ resource }) => resource?.interventionType === interventionType
				),
			}))
	}, [selectedShowMoreEvents])

	/////////////////////////////////////////////////////////
	// popover
	/////////////////////////////////////////////////////////

	const handleClick = useCallback((event) => {
		setAnchorEl(event.currentTarget)
	}, [])

	const handleClose = useCallback(() => {
		setAnchorEl(null)
	}, [])

	const open = useMemo(() => Boolean(anchorEl), [anchorEl])
	const id = useMemo(() => (open ? 'simple-popover' : undefined), [open])

	return (
		<>
			<Stack sx={showMoreBtnCSS} onClick={handleClick}>
				{`+ ${eventsNumber} ${intlMsg(
					eventsNumber > 1 ? 'misc.others' : 'misc.other'
				)}`}
			</Stack>

			{open && (
				<Popover
					id={id}
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={anchorOrigin}
				>
					<Stack p={2} pt={1} sx={popoverCSS} spacing={2}>
						{sortedInterventions?.map(
							(
								{
									interventionType,
									interventions,
								}: { interventionType: string; interventions: any },
								i
							) => (
								<Stack key={`${interventionType}-${i}`}>
									<Stack
										direction='row'
										spacing={0.5}
										alignItems='center'
										mb={0.5}
									>
										<Typography variant='body1'>
											{`${intlMsg(`interventions.${interventionType}`)}s :`}
										</Typography>
										<Typography variant='h2'>
											{interventions?.length}
										</Typography>
									</Stack>

									<Stack spacing={0.25} sx={CustomEventCSS}>
										{interventions?.map((intervention, i) => (
											<CustomEvent
												key={`${intervention?.title}-${i}`}
												event={intervention}
												currentView='month'
												selectedInter={selectedInter}
												searchText={searchText}
											/>
										))}
									</Stack>
								</Stack>
							)
						)}
					</Stack>
				</Popover>
			)}
		</>
	)
}

export default CalendarShowMore
