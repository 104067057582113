import dayjs from 'dayjs'
import {
	EFormModalMode,
	EInterventionTypes,
} from '../../../../../utils/app-models'
import { replaceHoursFromDate } from '../../../../../utils/date'

/* eslint-disable @typescript-eslint/no-unused-vars */

export const formatInterventionModalOnValidateData = ({
	data,
	mode,
	interventionId,
	unownedActors,
}) => {
	const { isManualEntry } = data
	const dateFrom = replaceHoursFromDate(data?.dateFrom, data?.dateFromHours)
	const dateTo = replaceHoursFromDate(data?.dateTo, data?.dateToHours)
	const orderingCustomerId = data?.orderingCustomer?.value?.id ?? null
	const orderingSiteId = data?.orderingSite?.value?.id ?? null
	const orderingContactId = data?.orderingContact?.value?.id ?? null
	const managerId = data?.manager?.value?.id ?? null
	const statusId = data?.status?.value?.id

	const specificModeFields =
		mode === EFormModalMode.edit
			? {
					id: interventionId,
			  }
			: {}

	// const actorsList = (data?.actors ?? []).map(({ value }) => value?.id)

	const actorsList = Object.values(data?.actors ?? {})
		.filter(({ checked }) => checked)
		.map(({ value }) => value?.id)
		.concat(unownedActors)

	const agenciesList = Object.values(data?.agenciesList ?? {})
		.filter(({ checked }) => checked)
		.map(({ value }) => value?.id)

	const globalProducts = Object.values(data?.globalProducts ?? {})
		.filter(({ checked }) => checked)
		.map(({ value }) => value?.id)

	const globalFormTemplates = Object.values(data?.globalFormTemplates ?? {})
		.filter(({ checked }) => checked)
		.map(({ value }) => value?.id)

	const interventionControlProgrammedVehiclesListInput = (
		data?.selectedVehicles ?? []
	).map((vehicle) => ({
		uuid: vehicle?.vehicleId ? vehicle?.id : undefined,
		vehicle: vehicle?.vehicleId || vehicle?.id,

		formTemplates: vehicle?.formTemplates ?? [],

		products: vehicle?.products ?? [],
	}))

	return {
		...specificModeFields,
		interventionType: EInterventionTypes.control,
		orderingCustomer: orderingCustomerId,
		orderingSite: orderingSiteId,
		orderingContact: orderingContactId,
		status: statusId,
		manager: managerId,
		publicComment: data?.publicComment,
		privateComment: data?.privateComment,
		isOrderEqualFinal: data?.isOrderEqualFinal,
		actorsList,
		agenciesList,
		interventionControlFinalCustomerInput: {
			finalCustomer: !isManualEntry ? data?.finalCustomer?.value?.id : null,
			finalSite: !isManualEntry ? data?.finalSite?.value?.id : null,
			finalContact: !isManualEntry ? data?.finalContact?.value?.id : null,
			finalAddressText: isManualEntry ? data?.finalAddressText : null,
			finalContactText: isManualEntry ? data?.finalContactText : null,
		},
		dateFrom,
		dateTo,
		globalProducts,
		globalFormTemplates,
		interventionControlProgrammedVehiclesListInput,
	}
}

export const formatInterventionModalEditDefaultValues = ({
	data,
	currentUserAccountId,
	setUnownedActors,
	setTabCounter,
	forcedStatus,
}) => {
	const {
		id,
		__typename,
		createdByAccount,
		interventionType,
		globalFormTemplates,
		globalProducts,
		interventionControlProgrammedVehicles,
		actors,
		agencies,
		dateFrom,
		dateTo,
		manager,
		interventionControlFinalCustomer,
		orderingContact,
		orderingCustomer,
		orderingSite,
		status,
		...values
	} = data

	const multiSelectValues =
		interventionControlProgrammedVehicles?.collection?.reduce((acc, ipv) => {
			const ipvId = ipv?.id

			if (ipvId) {
				acc[`products-${ipvId}`] =
					ipv?.products?.collection?.reduce((productAcc, ipvProduct) => {
						if (ipvProduct?.product) {
							productAcc[ipvProduct.product.id] = {
								checked: true,
								isDisabled:
									ipvProduct?.createdByAccount?.id !== currentUserAccountId,
								value: ipvProduct.product,
							}
						}
						return productAcc
					}, {}) || {}

				acc[`formTemplates-${ipvId}`] =
					ipv?.formTemplates?.collection?.reduce(
						(formTemplateAcc, ipvFormTemplates) => {
							if (ipvFormTemplates?.formTemplate) {
								formTemplateAcc[ipvFormTemplates.formTemplate.id] = {
									checked: true,
									value: ipvFormTemplates.formTemplate,
								}
							}
							return formTemplateAcc
						},
						{}
					) || {}
			}

			return acc
		}, {})

	setTabCounter(
		{
			nbVehiclesSelected:
				interventionControlProgrammedVehicles?.collection?.length,
		} || 0
	)

	setUnownedActors(
		actors?.collection
			?.filter(({ account }) => account?.id !== currentUserAccountId)
			?.map(({ id }) => id) || []
	)

	return {
		...multiSelectValues,
		...values,
		actors: actors?.collection?.reduce((acc, actor) => {
			if (actor?.account?.id === currentUserAccountId) {
				acc[actor.id] = {
					checked: true,
					value: actor,
				}
			}
			return acc
		}, {}),
		agenciesList: agencies?.collection?.reduce((acc, agency) => {
			if (agency) {
				acc[agency.id] = {
					checked: true,
					value: agency,
				}
			}
			return acc
		}, {}),

		dateFrom,
		dateTo,
		dateFromHours: dateFrom,
		dateToHours: dateTo,
		manager: {
			key: manager?.id,
			label: `${manager?.firstName} ${manager?.lastName}`,
			value: manager,
		},
		finalAddressText: interventionControlFinalCustomer?.finalAddressText || '',
		finalContactText: interventionControlFinalCustomer?.finalContactText || '',
		finalCustomer: {
			key: interventionControlFinalCustomer?.finalCustomer?.id,
			label: interventionControlFinalCustomer?.finalCustomer?.name,
			value: interventionControlFinalCustomer?.finalCustomer,
		},
		finalSite: {
			key: interventionControlFinalCustomer?.finalSite?.id,
			label: interventionControlFinalCustomer?.finalSite?.name,
			value: interventionControlFinalCustomer?.finalSite,
		},
		finalContact: {
			key: interventionControlFinalCustomer?.finalContact?.id,
			label: `${interventionControlFinalCustomer?.finalContact?.firstName} ${interventionControlFinalCustomer?.finalContact?.lastName}`,
			value: interventionControlFinalCustomer?.finalContact,
		},
		isManualEntry: interventionControlFinalCustomer?.finalCustomer
			? false
			: interventionControlFinalCustomer?.finalAddressText
			? true
			: false,
		orderingContact: orderingContact
			? {
					key: orderingContact?.id,
					label: `${orderingContact?.firstName} ${orderingContact?.lastName}`,
					value: orderingContact,
			  }
			: null,
		orderingCustomer: {
			key: orderingCustomer?.id,
			label: orderingCustomer?.name,
			value: orderingCustomer,
		},
		orderingSite: {
			key: orderingSite?.id,
			label: orderingSite?.name,
			value: orderingSite,
		},
		status: {
			key: forcedStatus?.id || status?.id,
			label: forcedStatus?.name || status?.name,
			value: forcedStatus || status,
		},
		globalFormTemplates:
			globalFormTemplates?.collection?.reduce((acc, gloabalFt) => {
				if (gloabalFt) {
					acc[gloabalFt.id] = {
						checked: true,
						value: gloabalFt,
					}
				}
				return acc
			}, {}) || {},
		globalProducts:
			globalProducts?.collection?.reduce((acc, globalP) => {
				if (globalP) {
					acc[globalP.id] = {
						checked: true,
						value: globalP,
					}
				}
				return acc
			}, {}) || {},
		selectedVehicles:
			interventionControlProgrammedVehicles?.collection?.map((ipv) => ({
				...ipv?.vehicle,
				id: ipv?.id,
				vehicleId: ipv?.vehicle?.id,
				status: forcedStatus || ipv?.status,
				comment: forcedStatus ? '' : ipv?.comment,
				products:
					ipv?.products?.collection?.map(({ product }) => product?.id) || [],
				formTemplates:
					ipv?.formTemplates?.collection?.map(
						({ formTemplate }) => formTemplate?.id
					) || [],
			})) || [],
	}
}

export const formatInterventionModalCreateDefaultValues = ({
	currentUser,
	plannedStatus,
	startDate,
	endDate,
	dataPreFilling,
	isSite,
	isVehicle,
}) => {
	const now = new Date()
	const dateFrom = dayjs(startDate || now)
	const dateTo = dayjs(endDate || now)

	const dataKey = isSite
		? dataPreFilling?.customer?.id
		: isVehicle
		? dataPreFilling?.site?.customer?.id
		: dataPreFilling?.id
	const dataLabel = isSite
		? dataPreFilling?.customer?.name
		: isVehicle
		? dataPreFilling?.site?.customer?.name
		: dataPreFilling?.name

	return {
		isOrderEqualFinal: true,
		manager: {
			key: currentUser?.id,
			label: `${currentUser?.firstName} ${currentUser?.lastName}`,
			value: {
				id: currentUser?.id,
				firstName: currentUser?.firstName,
				lastName: currentUser?.lastName,
			},
		},
		status: {
			key: plannedStatus?.id,
			label: plannedStatus?.name,
			value: plannedStatus,
		},
		isManualEntry: false,
		dateFrom,
		dateTo,
		dateFromHours:
			startDate || dayjs(dateFrom).startOf('hours').add(1, 'hours'),
		dateToHours: endDate || dayjs(dateTo).startOf('hours').add(2, 'hours'),
		orderingCustomer: dataPreFilling
			? {
					key: dataKey,
					label: dataLabel,
					value: { id: dataKey, label: dataLabel },
			  }
			: null,
		orderingSite:
			dataPreFilling && (isSite || isVehicle)
				? {
						key: isSite ? dataPreFilling.id : dataPreFilling.site.id,
						label: isSite ? dataPreFilling.name : dataPreFilling.site.name,
						value: {
							id: isSite ? dataPreFilling.id : dataPreFilling.site.id,
							label: isSite ? dataPreFilling.name : dataPreFilling.site.name,
						},
				  }
				: null,
		selectedVehicles: isVehicle ? [dataPreFilling] : [],
	}
}
