import React from 'react'

import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { Grid, Divider, Box, Stack } from '@mui/material'

import { useFormatMessage } from '../../../../utils/hooks'
import ListItemsData from '../../../../components/list/ListItemsData'
import { listCardViewModalCSS as css } from '../../../../styles/css/viewModal'
import { formatAdressGoogleMap } from '../../../../utils/string'
import UserAvatar from '../../../../components/avatar/UserAvatar'

interface IInformationsAccount {
	gestionnaire
	siret
	tva
	socialName
	city
	zipcode
	address
	name
	customerResp
}

const InformationsAccount: React.FC<IInformationsAccount> = ({
	gestionnaire,
	siret,
	tva,
	socialName,
	city,
	zipcode,
	address,
	name,
	customerResp,
}) => {
	const intlMsg = useFormatMessage()

	return (
		<Paper sx={css.radius}>
			<Box sx={{ mb: 2 }}>
				<Stack
					direction='row'
					alignItems='center'
					justifyContent='space-between'
					sx={css.space}
				>
					<Typography variant='h3'>
						<Stack
							direction='row'
							alignItems='center'
							justifyContent='space-between'
						>
							{name}
						</Stack>
					</Typography>
				</Stack>

				<Divider />

				<Box sx={css.spaceRightLeft}>
					<ListItemsData
						nbCol={6}
						items={[
							{
								sizeColumn: 12,
								primaryText: 'misc.address',
								secondaryText: `${address ?? ''} - ${zipcode ?? ''} - ${
									city ?? ''
								}`,
								...((address || city || zipcode) && {
									externalLink: formatAdressGoogleMap(address, city, zipcode),
								}),
							},
							{
								primaryText: 'misc.socialName',
								secondaryText: socialName || '-',
								sizeColumn: 12,
							},
							{
								primaryText: 'misc.siretNumber',
								secondaryText: siret || '-',
							},

							{ primaryText: 'misc.tavNumber', secondaryText: tva || '-' },
						]}
					/>
				</Box>

				<Divider />

				{/* accountResponsable */}

				<Grid container spacing={1} sx={{ pt: 1, pb: 1 }}>
					<Grid item xs={12}>
						<Typography variant='body2' sx={css.ListSubheader}>
							{intlMsg('misc.accountResponsable')}
						</Typography>
					</Grid>
					{customerResp?.collection?.filter(
						({ isCustomerResp }) => isCustomerResp
					)?.length ? (
						customerResp.collection.map(
							({ isCustomerResp, lastName, firstName, id }, i) => {
								return (
									isCustomerResp && (
										<Grid item xs={12} key={i}>
											<UserAvatar
												userName={{
													firstName,
													lastName,
												}}
												userId={id}
												displayName
												isContact
												isListItemButton
											/>
										</Grid>
									)
								)
							}
						)
					) : (
						<Grid item xs={12}>
							<Typography variant='caption' sx={css.ListSubheader}>
								-
							</Typography>
						</Grid>
					)}
				</Grid>

				<Divider />

				{/* GESTIONNAIRE */}

				<Grid container spacing={1} sx={{ pt: 1, pb: 1 }}>
					<Grid item xs={12}>
						<Typography variant='body2' sx={css.ListSubheader}>
							{intlMsg('misc.manager')}
						</Typography>
					</Grid>

					<Grid item xs={12}>
						{gestionnaire?.id ? (
							<UserAvatar
								userName={{
									firstName: gestionnaire?.firstName as string,
									lastName: gestionnaire?.lastName as string,
								}}
								userId={gestionnaire?.id}
								displayName
								isListItemButton
							/>
						) : (
							<Typography variant='caption' sx={css.ListSubheader}>
								-
							</Typography>
						)}
					</Grid>
				</Grid>
			</Box>
		</Paper>
	)
}

export default InformationsAccount
