import React, {
	useCallback,
	useState,
	useEffect,
	useContext,
	useMemo,
} from 'react'

import { useInterventionStatusesList } from '../../../../api/gql/queries/interventionsQueries'
import StatusInterventionPopover from './StatusInterventionPopover'
import StatusButtonGroup from './StatusButtonGroup'
import { selectsIDstatusByGroup } from './filterStatusInterventionUtils'
import { FiltersContext } from '../../filtersContext'

interface IFilterStatusIntervention {
	setFiltersQuerieParam
	filtersQuerieParam
	argumentQuerieName
}

const FilterStatusIntervention: React.FC<IFilterStatusIntervention> = ({
	setFiltersQuerieParam,
	filtersQuerieParam,
	argumentQuerieName,
}) => {
	const { localStorageKeyFilter } = useContext(FiltersContext) || {}
	const { data: statusInterventionList } = useInterventionStatusesList()
	const [currentFilter, setCurrentFilter]: any = useState(null)
	const [valueStatusGroup, setValueStatusGroup]: any = useState()

	// /////////////////////////////////////////////////////////////////////////
	// OPEN/CLOSE popover
	// /////////////////////////////////////////////////////////////////////////

	const [anchorEl, setAnchorEl] = useState(null)
	const handleClick = useCallback((e) => {
		setAnchorEl(e.currentTarget)
	}, [])
	const handleClose = useCallback(() => setAnchorEl(null), [])
	const popoverIsOpen = Boolean(anchorEl)

	// /////////////////////////////////////////////////////////////////////////
	// handle Select One intervention Status
	// /////////////////////////////////////////////////////////////////////////

	const handleSelectOneStatus = useCallback(
		(id) => () => {
			setValueStatusGroup('interventionCustom')

			setCurrentFilter({
				valueStatusGroup: 'interventionCustom',
				[argumentQuerieName]: [id],
			})

			handleClose()
		},
		[filtersQuerieParam, argumentQuerieName]
	)

	// /////////////////////////////////////////////////////////////////////////
	// handle Change Status group intervention
	// /////////////////////////////////////////////////////////////////////////

	const handleChangeStatus = useCallback(
		(_event: React.MouseEvent<HTMLElement>, newValueGroup: string) => {
			selectsIDstatusByGroup(
				setValueStatusGroup,
				newValueGroup,
				statusInterventionList,
				setCurrentFilter,
				argumentQuerieName
			)
		},
		[statusInterventionList]
	)

	// /////////////////////////////////////////////////////////////////////////
	// Init default value
	// /////////////////////////////////////////////////////////////////////////

	useEffect(() => {
		const filterPreference = JSON.parse(
			localStorage.getItem(localStorageKeyFilter) as string
		)

		// get local preference
		if (filterPreference) {
			setCurrentFilter({
				[argumentQuerieName]: filterPreference[argumentQuerieName],
				valueStatusGroup: filterPreference?.valueStatusGroup,
			})
			setValueStatusGroup(filterPreference?.valueStatusGroup)
		}
	}, [])

	// /////////////////////////////////////////////////////////////////////////
	// send to query params
	// /////////////////////////////////////////////////////////////////////////

	useEffect(() => {
		if (currentFilter) {
			setFiltersQuerieParam({
				...filtersQuerieParam,
				[argumentQuerieName]: currentFilter?.[argumentQuerieName],
				valueStatusGroup: currentFilter?.valueStatusGroup,
			})
		}
	}, [currentFilter])

	// /////////////////////////////////////////////////////////////////////////
	// Selected status
	// /////////////////////////////////////////////////////////////////////////

	const selectedStatus = useMemo(() => {
		return (
			(valueStatusGroup === 'interventionCustom' &&
				statusInterventionList?.filter(
					({ id }) => id === currentFilter?.[argumentQuerieName]?.[0]
				)[0]) ||
			{}
		)
	}, [valueStatusGroup, statusInterventionList, currentFilter])

	return (
		<>
			<StatusButtonGroup
				handleChangeStatus={handleChangeStatus}
				valueStatusGroup={valueStatusGroup}
				handleClick={handleClick}
				selectedStatus={selectedStatus}
			/>

			{popoverIsOpen && (
				<StatusInterventionPopover
					handleClose={handleClose}
					open={popoverIsOpen}
					anchorEl={anchorEl}
					handleSelectOneStatus={handleSelectOneStatus}
					statusInterventionList={statusInterventionList}
					selectedStatus={selectedStatus}
				/>
			)}
		</>
	)
}

export default FilterStatusIntervention
