import React from 'react'

import FieldEditFormLayoutTxt from './fieldEditFormsLayout/FieldEditFormLayoutTxt'
import FieldEditFormLayoutNumbering from './fieldEditFormsLayout/FieldEditFormLayoutNumbering'
import {
	EFormTemplateBasicFields,
	EFormTemplateModelFields,
	EFormTemplateVehicleFields,
} from '../../../../../utils/app-models'
import FieldEditFormLayoutControl from './fieldEditFormsLayout/FieldEditFormLayoutControl'
import FieldEditFormLayoutRating from './fieldEditFormsLayout/FieldEditFormLayoutRating'
import FieldEditFormLayoutSelection from './fieldEditFormsLayout/FieldEditFormLayoutSelection'
import FieldEditFormLayoutPicture from './fieldEditFormsLayout/FieldEditFormLayoutPicture'
import FieldEditFormLayoutTitle from './fieldEditFormsLayout/FieldEditFormLayoutTitle'
import FieldEditFormLayoutSignature from './fieldEditFormsLayout/FieldEditFormLayoutSignature'
import FieldEditFormLayoutDate from './fieldEditFormsLayout/FieldEditFormLayoutDate'
import FieldEditFormLayoutMileage from './fieldEditFormsLayout/FieldEditFormLayoutMileage'
// import FieldEditFormLayoutMedia from './fieldEditFormsLayout/FieldEditFormLayoutMedia'
import FieldEditFormLayoutInstruction from './fieldEditFormsLayout/FieldEditFormLayoutInstruction'
import FieldEditFormLayoutBarCode from './fieldEditFormsLayout/FieldEditFormLayoutBarCode'
import FieldEditFormLayoutDocument from './fieldEditFormsLayout/FieldEditFormLayoutDocument'

export const getFieldEditConfig = (fieldType: string): any => {
	switch (fieldType) {
		case EFormTemplateBasicFields.text:
			return <FieldEditFormLayoutTxt />

		case EFormTemplateBasicFields.numbers:
			return <FieldEditFormLayoutNumbering />

		case EFormTemplateBasicFields.control:
			return <FieldEditFormLayoutControl />

		case EFormTemplateBasicFields.selection:
			return <FieldEditFormLayoutSelection />

		case EFormTemplateBasicFields.rating:
			return <FieldEditFormLayoutRating />

		case EFormTemplateModelFields.pictureVehicle:
		case EFormTemplateModelFields.picture4anglesModel:
		case EFormTemplateModelFields.picture4anglesAndWheelsModel:
		case EFormTemplateBasicFields.picture:
			return <FieldEditFormLayoutPicture />

		case EFormTemplateBasicFields.title:
			return <FieldEditFormLayoutTitle />

		case EFormTemplateBasicFields.signature:
			return <FieldEditFormLayoutSignature />

		case EFormTemplateBasicFields.parkDate:
		case EFormTemplateBasicFields.date:
			return <FieldEditFormLayoutDate />

		case EFormTemplateModelFields.doubleSidedPhotoDocument:
		case EFormTemplateBasicFields.document:
			return <FieldEditFormLayoutDocument />
		// case EFormTemplateBasicFields.media:
		// 	return <FieldEditFormLayoutMedia />

		case EFormTemplateBasicFields.instruction:
			return <FieldEditFormLayoutInstruction />

		case EFormTemplateBasicFields.barCode:
			return <FieldEditFormLayoutBarCode />

		case EFormTemplateVehicleFields.mileage:
			return <FieldEditFormLayoutMileage />

		default:
			return <></>
	}
}
