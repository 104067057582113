import React, { useMemo } from 'react'

import qs from 'qs'
import { Alert, Fade, Stack } from '@mui/material'

import { GET_FORM_PHOTOS } from '../../../../../api/gql/queries/interventionFormsQueries'
import SectionPhotosByForm from './SectionPhotosByForm'
import {
	infiniteScrollWithTrigger,
	useFormatMessage,
} from '../../../../../utils/hooks'
import { extractRecordInterventionWithPhotos } from '../formsPhotosUtils'

interface IPhotosGroupedByForm {
	displayInterventionRef?: boolean
	queryPropertyName: string
}

const PhotosGroupedByForm: React.FC<IPhotosGroupedByForm> = ({
	displayInterventionRef = false,
	queryPropertyName,
}) => {
	const intlMsg = useFormatMessage()
	const { varValue }: any = qs.parse(location.search, {
		ignoreQueryPrefix: true,
	})

	const { dataList, loading, setTrigger } = infiniteScrollWithTrigger({
		queryConfig: {
			query: GET_FORM_PHOTOS,
			queryName: 'picturedForms',
		},
		itemsPerPage: 50,
		params: { [queryPropertyName]: varValue },
	})

	const groupedData = useMemo(
		() => extractRecordInterventionWithPhotos(dataList),
		[dataList]
	)

	return (
		<Stack p={5} spacing={4}>
			{groupedData?.length ? (
				groupedData.map((form, i) => (
					<Fade in={!!form} timeout={500 + i * 100} key={i}>
						<div>
							<SectionPhotosByForm
								displayInterventionRef={displayInterventionRef}
								form={form}
								setTrigger={setTrigger}
								loading={loading}
							/>
						</div>
					</Fade>
				))
			) : (
				<Fade in={!loading && !groupedData?.length} timeout={500}>
					<Alert severity='info'>{intlMsg('misc.nothingToDisplay')}</Alert>
				</Fade>
			)}
		</Stack>
	)
}

export default PhotosGroupedByForm
