import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'

import { Box, Button, Popover, Stack } from '@mui/material'
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar'

import { useFormatMessage } from '../../../../../../../utils/hooks'

import { footerModalCSS } from '../../../../../../../components/forms/modalSxCss'
import FormSelectSearch from '../../../../../../../components/forms/formFields/formSelectSearch'
import { useGetCustomerListForSelect } from '../../../../../../../api/gql/queries/customersQueries'
import { useLazyQuery } from '@apollo/client'
import { GET_SITES_BY_CUSTOMER_ID } from '../../../../../../../api/gql/queries/sitesQueries'
import { GET_CONTACT_LIST_FOR_SELECT } from '../../../../../../../api/gql/queries/contactsQueries'
import {
	FormModalContext,
	IFormModalContext,
} from '../../../../../../../components/forms/formModal/FormModalContext'
import {
	useLazyTransportTripsContact,
	useLazyTransportTripsSite,
} from '../../../../../../../api/gql/queries/interventionsTransportQueries'
import { getFinalOrder } from './transportTripsUtils'
import { AuthContext } from '../../../../../../../utils/contexts/AuthContext'

const stackCSS = { width: 1 }
const ButtonCSS = { mr: 1 }
const PaperPropsCSS = { sx: { minWidth: 500, maxWidth: 500 } }

interface IAddressBook {
	transportTripType
	isOrderEqualFinal: boolean
}

const AddressBook: React.FC<IAddressBook> = ({
	transportTripType,
	isOrderEqualFinal,
}) => {
	const intlMsg = useFormatMessage()
	const { data, setValues, triggerError } =
		useContext<IFormModalContext>(FormModalContext)
	const [selectedCustomer, setSelectedCustomer] = useState(null)
	const [selectedSite, setSelectedSite] = useState(null)
	const { localLanguage } = useContext(AuthContext)

	const { customers } = useGetCustomerListForSelect()
	const [getSites, { data: dataSites }] = useLazyQuery(GET_SITES_BY_CUSTOMER_ID)
	const [getContacts, { data: dataContacts }] = useLazyQuery(
		GET_CONTACT_LIST_FOR_SELECT
	)
	const { getTransportTripSite } = useLazyTransportTripsSite()
	const { getTransportTripContact } = useLazyTransportTripsContact()

	// /////////////////////////////////////////////////
	// Format input options
	// /////////////////////////////////////////////////

	const customersOptions = useMemo(
		() =>
			customers?.map((customer) => ({
				key: customer?.id,
				label: customer?.name,
				value: customer,
			})) || [],
		[customers]
	)

	const sitesOptions = useMemo(
		() =>
			dataSites?.sites?.collection?.map((site) => ({
				key: site?.id,
				label: site?.name,
				value: site,
			})) || [],
		[dataSites]
	)

	const contactsOptions = useMemo(
		() =>
			dataContacts?.contacts?.collection?.map((contact) => ({
				key: contact?.id,
				label: `${contact?.firstName} ${contact?.lastName}`,
				value: contact,
			})) || [],
		[dataContacts]
	)

	useEffect(() => {
		if (selectedCustomer)
			getSites({
				variables: {
					customer: selectedCustomer,
				},
			})
	}, [selectedCustomer])

	useEffect(() => {
		if (selectedSite)
			getContacts({
				variables: {
					sites: selectedSite,
				},
			})
	}, [selectedSite])

	const handleSelect = useCallback(
		({ fieldName, option }) => {
			if (fieldName === `${transportTripType}AddressBookCustomer`) {
				setValues({
					[`${transportTripType}AddressBookCustomer`]: option,
					[`${transportTripType}AddressBookSite`]: null,
					[`${transportTripType}AddressBookContact`]: null,
				})
				setSelectedCustomer(option?.value?.id)
			} else if (fieldName === `${transportTripType}AddressBookSite`) {
				setSelectedSite(option?.value?.id)
				setValues({
					[`${transportTripType}AddressBookSite`]: option,
					[`${transportTripType}AddressBookContact`]: null,
				})
			}
		},
		[setValues]
	)

	////////////////////////////////////////////
	// OPEN/CLOSE POPOVER
	////////////////////////////////////////////

	const [anchorEl, setAnchorEl] = useState(null)
	const handleClick = useCallback((event) => {
		setAnchorEl(event.currentTarget)
	}, [])
	const handleClose = useCallback(() => {
		setAnchorEl(null)
	}, [])
	const open = Boolean(anchorEl)
	const id = open ? 'addressBook-popover' : undefined

	const onClosePopover = useCallback(() => {
		handleClose()
	}, [])

	////////////////////////////////////////////
	// Submit address book
	////////////////////////////////////////////

	const onSubmitAddressBook = useCallback(async () => {
		const newValues = await getFinalOrder({
			transportTripType,
			getTransportTripSite,
			getTransportTripContact,
			data,
			isFromAddressBook: true,
			localLanguage,
		})

		setValues(newValues)
		triggerError({ specificData: newValues })

		handleClose()
	}, [setValues, data, transportTripType, triggerError, localLanguage])

	return (
		<Box sx={{ margin: '0px!important' }}>
			<Button
				size='small'
				color='primary'
				startIcon={<PermContactCalendarIcon />}
				onClick={handleClick}
				disabled={isOrderEqualFinal[transportTripType]}
				data-test-id={`Playwright-${transportTripType}AddressBook`}
			>
				{intlMsg('interventions.addressBook')}
			</Button>

			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={onClosePopover}
				PaperProps={PaperPropsCSS}
			>
				<Stack p={2} spacing={2}>
					<FormSelectSearch
						name={`${transportTripType}AddressBookCustomer`}
						label={intlMsg('misc.account')}
						specialOnChange={handleSelect}
						selectElements={customersOptions}
						context={FormModalContext}
						missingText={intlMsg('misc.noResultSearch')}
						isRequired
						trigerred
					/>
					<FormSelectSearch
						name={`${transportTripType}AddressBookSite`}
						label={intlMsg('misc.sites')}
						specialOnChange={handleSelect}
						selectElements={sitesOptions}
						context={FormModalContext}
						missingText={intlMsg('interventions.noKnownSite')}
						isDisabled={!data[`${transportTripType}AddressBookCustomer`]}
						isRequired
						trigerred
					/>

					<FormSelectSearch
						name={`${transportTripType}AddressBookContact`}
						label={intlMsg('misc.contact')}
						selectElements={contactsOptions}
						context={FormModalContext}
						missingText={intlMsg('interventions.noKnownContact')}
						isDisabled={!data[`${transportTripType}AddressBookSite`]}
					/>
				</Stack>

				<Stack direction='row' spacing={2} sx={footerModalCSS}>
					<Stack
						direction='row'
						justifyContent='flex-end'
						spacing={1}
						sx={stackCSS}
					>
						<Button
							variant='outlined'
							color='primary'
							onClick={handleClose}
							sx={ButtonCSS}
						>
							{intlMsg('misc.cancel')}
						</Button>

						<Button
							variant='contained'
							type='submit'
							onClick={onSubmitAddressBook}
						>
							{intlMsg('misc.validate')}
						</Button>
					</Stack>
				</Stack>
			</Popover>
		</Box>
	)
}

export default AddressBook
