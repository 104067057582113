/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const sliderField = (doc, data, position) => {
	const { positionYcell, positionXcell, heightCell, widthCell } = position

	const attribute = JSON.parse(data.formTemplateField.attributes[0])
	const record = data?.value?.value || attribute?.defaultValue

	const heightSlider = 2
	const marginSlider = 8
	const radiusCircleRecord = 2.5

	const widthRecordSliderBar =
		((widthCell - marginSlider * 2) * ((record - attribute.min) * 100)) /
		(attribute.max - attribute.min) /
		100

	const widthTxtRecord = doc.getTextDimensions(record.toString()).w
	const widthTxtMax = doc.getTextDimensions(attribute.max.toString()).w
	const widthTxtMin = doc.getTextDimensions(attribute.min.toString()).w

	const centerYtext = positionYcell + heightCell / 2 + heightSlider / 2
	const centerPositionYSlider = positionYcell + (heightCell - heightSlider) / 2
	const centerPositionYSliderRecord = positionYcell + heightCell / 2

	// Draw bg slider ///////////////////////////////////////////////
	doc.setDrawColor('#a6d8ff')
	doc.setFillColor('#a6d8ff')

	// HELP -> roundedRect(positionXcell, positionYcell, w, h, rx, ry, style)
	doc.roundedRect(
		positionXcell + marginSlider,
		centerPositionYSlider,
		widthCell - marginSlider * 2,
		heightSlider,
		1,
		1,
		'FD'
	)

	// Draw bg slider value record //////////////////////////////////
	doc.setDrawColor('#42a4f2')
	doc.setFillColor('#42a4f2')

	// HELP -> roundedRect(positionXcell, positionYcell, w, h, rx, ry, style)
	doc.roundedRect(
		positionXcell + marginSlider,
		centerPositionYSlider,
		widthRecordSliderBar,
		heightSlider,
		1,
		1,
		'FD'
	)

	// add min & max slider value ////////////////////////////////////
	doc.text(
		attribute.min.toString(),
		positionXcell + widthTxtMin / 2,
		centerYtext
	)
	doc.text(
		attribute.max.toString(),
		positionXcell + widthCell - widthTxtMax,
		centerYtext
	)

	// display result value record on slider /////////////////////////
	doc.setLineWidth(0.5)
	doc.setDrawColor(255, 255, 255)
	doc.setFillColor('#42a4f2')

	// HELP ->  circle(positionXcell, positionYcell, r, styleopt)
	doc.circle(
		positionXcell + marginSlider + widthRecordSliderBar,
		centerPositionYSliderRecord,
		radiusCircleRecord,
		'FD'
	)
	doc.setTextColor('white')
	doc.setFontSize(9)

	doc.text(
		record.toString(),
		positionXcell + marginSlider + widthRecordSliderBar - widthTxtRecord / 2,
		centerYtext
	)
}
