import React, { useCallback, useState, useMemo } from 'react'

import ListChipsNetworksPopover from './ListChipsNetworksPopover.'
import { useFormatMessage } from '../../../utils/hooks'
import MultiItemsButton from '../../multiItemsPopoverList/MultiItemsButton'

interface IListChipsNetworks {
	networks?
}

const ListChipsNetworks: React.FC<IListChipsNetworks> = ({ networks }) => {
	const intlMsg = useFormatMessage()
	const [open, setOpen] = useState(false)
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

	const handleClick = useCallback((e) => {
		setOpen((prevOpen) => !prevOpen)
		setAnchorEl(e.currentTarget)
	}, [])

	const handleClose = useCallback(() => setOpen(false), [setAnchorEl, setOpen])
	const nbNetworks = useMemo(() => networks?.length, [networks])

	const label = useMemo(
		() => (nbNetworks > 1 ? intlMsg('misc.networks') : networks?.[0]?.name),
		[nbNetworks, networks]
	)

	const buttontype = useMemo(
		() => (nbNetworks > 1 ? 'chipCountClickable' : 'simpleText'),
		[nbNetworks]
	)

	return (
		<>
			<MultiItemsButton
				buttonType={buttontype}
				nbItems={nbNetworks}
				label={label}
				handleClick={handleClick}
			/>

			{open && (
				<ListChipsNetworksPopover
					handleClose={handleClose}
					anchorEl={anchorEl}
					networks={networks}
					open={open}
				/>
			)}
		</>
	)
}

export default ListChipsNetworks
