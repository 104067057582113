/* eslint-disable react/display-name */
import { useApolloClient, useLazyQuery } from '@apollo/client/react'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'

import {
	GET_PROFILE_USER,
	useLazyProfileUserId,
} from '../api/gql/queries/usersQueries'
import LogoConnectIcon from '../svgIcons/LogoConnectIcon'
import { AuthContext } from '../utils/contexts/AuthContext'
import { getDefaultLocalLanguage, logout } from '../utils/utils'
import { useNavigate } from 'react-router-dom'
import { compareAndUpdateTableColumnFilter } from '../utils/updateTableColumnLocalStorage'
import { purgeUnusedTableFilterFromLocalStorage } from '../utils/updateTableFilterLocalStorage'

interface IAuthProvider {
	children: React.ReactNode
}

const AuthProvider: React.FC<IAuthProvider> = memo(({ children }) => {
	const navigate = useNavigate()
	const [currentUserId, setCurrentUserId] = useState<any>(null)
	const [currentUser, setCurrentUser] = useState<any>(null)
	const [navIsOpen, setNavIsOpen] = useState(true) // TODO a deplacer dans un provider global
	const [storedToken, setStoredToken] = useState(localStorage.getItem('token'))
	const [localLanguage, setLocalLanguage] = useState(getDefaultLocalLanguage())

	const [getUser, { data: userData, loading }] = useLazyQuery(
		GET_PROFILE_USER,
		{ fetchPolicy: 'network-only' }
	)

	const { getProfileUserId, profileUserId, loadingProfileUserId } =
		useLazyProfileUserId({
			onError: () => {
				//todo catch gql error "invalid token" to change the message
				setStoredToken(null)
				logout(navigate)
			},
		})

	useEffect(() => {
		const token = localStorage.getItem('token')
		if (token && !currentUserId) {
			setStoredToken(token)
			getProfileUserId()
		}
	}, [currentUserId])

	useEffect(() => {
		if (!loadingProfileUserId && profileUserId) setCurrentUserId(profileUserId)
	}, [profileUserId, loadingProfileUserId])

	useEffect(() => {
		if (currentUserId) {
			getUser({
				variables: {
					id: currentUserId,
				},
			})
		}
	}, [currentUserId])

	const client = useApolloClient()
	const resetContext = () => {
		client.clearStore()
		logout()
		setCurrentUserId(null)
		setCurrentUser(null)
		setStoredToken(null)
	}

	const checkModules = (modulesName: string[] | null) => {
		if (!modulesName || !currentUser?.accountModules) return true

		return modulesName.some((name) =>
			currentUser.accountModules.some((module) => module.includes(name))
		)
	}

	const checkAllModules = (modulesName: string[] | null) => {
		if (!modulesName || !currentUser?.accountModules) return false

		return modulesName.every((name) =>
			currentUser.accountModules.some((module) => module.includes(name))
		)
	}

	const checkPermissions = useCallback(
		(permissionsName: any) => {
			if (!permissionsName || !currentUser?.permissions) return true

			return permissionsName.some((name) =>
				currentUser.permissions.some(
					({ name: permissionName }) =>
						permissionName === 'supermegaadmin' || permissionName.includes(name)
				)
			)
		},
		[currentUser]
	)

	const checkAccountsType = useCallback(
		(accountTypes: any) => {
			return accountTypes.some(
				(accountType) => currentUser?.account?.accountType?.code === accountType
			)
		},
		[currentUser]
	)

	useEffect(() => {
		if (!loading && userData) {
			setCurrentUser(userData.profileUser)
		}
	}, [userData])

	useEffect(() => {
		if (currentUser) {
			compareAndUpdateTableColumnFilter(checkAccountsType, checkModules)

			purgeUnusedTableFilterFromLocalStorage()
		}
	}, [currentUser])

	const context = useMemo(
		() => ({
			checkPermissions,
			checkAccountsType,
			currentUserId,
			currentUser,
			setCurrentUserId,
			resetContext,
			setNavIsOpen,
			navIsOpen,
			checkModules,
			storedToken,
			localLanguage,
			setLocalLanguage,
			checkAllModules,
		}),
		[
			currentUserId,
			setCurrentUserId,
			currentUser,
			checkPermissions,
			navIsOpen,
			localLanguage,
		]
	)

	return (
		<AuthContext.Provider value={context}>
			{currentUser && storedToken && !loading && !loadingProfileUserId ? (
				children
			) : storedToken ? (
				<LogoConnectIcon />
			) : (
				children
			)}
		</AuthContext.Provider>
	)
})
export default AuthProvider
