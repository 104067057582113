import React, { useCallback, useMemo, useState } from 'react'

import { Box, Link, Popover, Stack, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'

import { useFormatMessage } from '../../../../utils/hooks'
import ChipWithStates from '../../../chip/ChipWithStates'

const colorGrey600CSS = { color: grey[600] }

interface IWTransportForms {
	itTrip
	nbFormsToDisplay?: number
}

const WTransportForms: React.FC<IWTransportForms> = ({
	itTrip,
	nbFormsToDisplay = 5,
}) => {
	const intlMsg = useFormatMessage()

	/////////////////////////////////////////////////////////
	// format, Merge array & Sort by done
	/////////////////////////////////////////////////////////

	const sortedFormTemplates = useMemo(() => {
		// All completed forms (create directly from app mobile or app desktop)
		const allCompletedForms = itTrip?.forms?.collection?.map((form) => ({
			...form,
			isDone: true,
		}))

		const incomplete = itTrip?.formTemplates?.collection
			?.filter(
				({ id }) =>
					!itTrip.forms.collection.some(
						({ formTemplate }) => formTemplate.originalFormTemplate.id === id
					)
			)
			?.map((form) => ({ ...form, isDone: false }))

		const merged = [...allCompletedForms, ...incomplete]

		return merged
	}, [itTrip.formTemplates.collection, itTrip.forms.collection])

	const nbForms = useMemo(
		() => sortedFormTemplates?.length || 0,
		[sortedFormTemplates]
	)

	/////////////////////////////////////////////////////////
	// Popover more forms
	/////////////////////////////////////////////////////////

	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

	const handleClick = useCallback((event) => {
		setAnchorEl(event.currentTarget)
	}, [])

	const handleClose = useCallback(() => {
		setAnchorEl(null)
	}, [])

	const open = useMemo(() => Boolean(anchorEl), [anchorEl])

	return (
		<Stack spacing={1} width='50%'>
			<Typography variant='body2' sx={colorGrey600CSS}>
				{intlMsg('interventionForms.reports')}
			</Typography>

			<Box>
				<Stack spacing={0.5}>
					{nbForms ? (
						<>
							{sortedFormTemplates.slice(0, nbFormsToDisplay).map((form) => (
								<ChipWithStates
									key={form.id}
									isDone={form?.isDone}
									title={form?.isDone ? form?.formTemplate?.title : form?.title}
									id={form?.isDone ? form.id : ''}
								/>
							))}

							{nbForms > nbFormsToDisplay && (
								<>
									<Link
										href='#'
										onClick={handleClick}
										underline='hover'
										fontWeight={700}
										fontSize={12}
									>
										{`${nbForms - nbFormsToDisplay} ${intlMsg(
											`misc.other${nbForms - nbFormsToDisplay > 1 ? 's' : ''}`
										)}`}
									</Link>

									<Popover
										open={open}
										anchorEl={anchorEl}
										onClose={handleClose}
										anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
									>
										<Stack spacing={0.5} p={2}>
											{sortedFormTemplates
												.slice(nbFormsToDisplay, nbForms)
												.map((form) => (
													<ChipWithStates
														key={form.id}
														isDone={form?.isDone}
														title={
															form?.isDone
																? form?.formTemplate?.title
																: form?.title
														}
														id={form?.isDone ? form.id : ''}
													/>
												))}
										</Stack>
									</Popover>
								</>
							)}
						</>
					) : (
						<Typography variant='body2' sx={{ color: grey[600] }}>
							-
						</Typography>
					)}
				</Stack>
			</Box>
		</Stack>
	)
}

export default WTransportForms
