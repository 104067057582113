import React, { cloneElement, useMemo } from 'react'

import PermissionsHandler from '../../components/permissionsHandler'
import StepperProvider from '../../components/forms/formStepper/StepperProvider'
import { EFormModalMode } from '../../utils/app-models'
import agencyStepperConfig from '../dashboard/networkAgency/agencies/agencyModal/agencyStepperConfig'
import FormBtnModal from '../../components/forms/formModalBtn'

interface IViewModalEditBtn {
	viewModalConfig: any
}

const ViewModalEditBtn: React.FC<IViewModalEditBtn> = ({ viewModalConfig }) => {
	const modal = useMemo(
		() =>
			cloneElement(viewModalConfig.modal.component, {
				[viewModalConfig.modal.varName]:
					viewModalConfig.queryConfig.variables[
						viewModalConfig.modal.fieldName
					],
			}),
		[]
	)

	if (viewModalConfig.modal?.isStepper)
		return (
			<PermissionsHandler permissionsName={viewModalConfig.modal?.permissions}>
				<StepperProvider
					stepperConfig={agencyStepperConfig}
					stepperMode={EFormModalMode.edit}
				>
					<FormBtnModal modal={modal} mode={EFormModalMode.edit} />
				</StepperProvider>
			</PermissionsHandler>
		)
	else
		return (
			<PermissionsHandler permissionsName={viewModalConfig.modal?.permissions}>
				<FormBtnModal modal={modal} mode={EFormModalMode.edit} />
			</PermissionsHandler>
		)
}

export default ViewModalEditBtn
