/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import {
	gql,
	OperationVariables,
	QueryHookOptions,
	useLazyQuery,
	useQuery,
} from '@apollo/client'

import { listAgenciesAccounts } from '../attributes/agencyAttributes'
/**  ---------------------QUERIES---------------------- */

export const AGENCIES_FILTER_LIST = gql`
query getAgenciesAccounts(
    $page: Int
    $itemsPerPage: Int
    $isActive: Boolean
    $searchText: String
    $networks: String
	$orderBy: [AccountFilter_orderBy]
) {
    agenciesAccounts(page: $page, itemsPerPage: $itemsPerPage, isActive: $isActive, searchText: $searchText,networks: $networks, orderBy: $orderBy){
        ${listAgenciesAccounts}
    }
}`

export const EXPORT_AGENCIES = gql`
	query exportAgencies(
		$isActive: Boolean
		$searchText: String
		$networks: String
		$timezone: Int
		$orderBy: [AccountFilter_orderBy]
	) {
		exportAgenciesAccounts(
			isActive: $isActive
			searchText: $searchText
			networks: $networks
			timezone: $timezone
			orderBy: $orderBy
		) {
			id
			exportFile
		}
	}
`

export const AGENCIES_NUMBERS = gql`
	query getAgenciesNumbers($isActive: Boolean, $searchText: String) {
		agenciesAccounts(
			page: 1
			itemsPerPage: 100000
			isActive: $isActive
			searchText: $searchText
		) {
			paginationInfo {
				totalCount
			}
		}
	}
`

export const AGENCY = gql`
	query getAgency($id: ID!) {
		agencyAccount(id: $id) {
			id
			name
			agencyCustomDetails {
				id
				agencyName
			}
			siret
			tva
			adminFirstName
			adminLastName
			adminPhone
			adminCellphone
			adminEmail
			adminPhone
			adminCanLogin
			address
			zipcode
			dateCreated
			city
			country
			isActive
			accountType {
				id
			}
			createdByAccount {
				id
				name
			}
			networks(itemsPerPage: 99999) {
				collection {
					id
					name
				}
			}
			sharedSites(itemsPerPage: 99999) {
				collection {
					id
				}
			}
		}
	}
`

export const AGENCY_FOR_MODAL = gql`
	query getAgency($id: ID!) {
		agencyAccount(id: $id) {
			id
			name
			agencyCustomDetails {
				id
				agencyName
			}
			siret
			tva
			adminFirstName
			adminLastName
			adminPhone
			adminCellphone
			adminEmail
			adminPhone
			adminCanLogin
			address
			zipcode
			dateCreated
			city
			country
			isActive
			networks(itemsPerPage: 99999) {
				collection {
					name
					id
				}
			}
			sharedSites(itemsPerPage: 99999) {
				collection {
					id
				}
			}
			sharedProductCategories(itemsPerPage: 99999) {
				collection {
					id
				}
			}
		}
	}
`

export const ALL_AGENCIES_FOR_MODAL = gql`
	query getAllAgenciesForModal {
		agenciesAccounts(page: 1, itemsPerPage: 100000) {
			collection {
				id
				agencyCustomDetails {
					agencyName
				}
			}
		}
	}
`

export const AGENCY_FOR_SELECT = gql`
	query getAgencyForSelect($sharedSites: String) {
		agenciesAccounts(page: 1, itemsPerPage: 100000, sharedSites: $sharedSites) {
			collection {
				id
				agencyCustomDetails {
					id
					agencyName
				}
			}
		}
	}
`

export const CHECK_SIREN_ACCOUNT = gql`
	query checkSirenAccount($siret: String!, $country: String!) {
		checkSirenAccount(siret: $siret, country: $country) {
			id
			city
			zipcode
			name
			address
			tva
		}
	}
`

export const GET_ACCOUNT_TYPES = gql`
	query getAccountTypes {
		accountTypes {
			id
			code
			name
			description
			price
		}
	}
`

export const GET_AGENCY_NAME = gql`
	query getAgencyName($id: ID!) {
		agencyAccount(id: $id) {
			id
			agencyCustomDetails {
				id
				agencyName
			}
		}
	}
`

/**  ---------------------HOOKS---------------------- */

export const useGetAccountTypes = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { loading, data } = useQuery(GET_ACCOUNT_TYPES, options)

	return {
		loadingAccountTypes: loading,
		accountTypes: data?.accountTypes || null,
	}
}

export const useGetAllAgenciesForModal = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { data, loading } = useQuery(ALL_AGENCIES_FOR_MODAL, options)

	return {
		agenciesAccounts: data?.agenciesAccounts?.collection || null,
		agenciesAccountsLoading: loading,
	}
}

export const useLazyGetAgenciesForSelect = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(AGENCY_FOR_SELECT, options)
	return {
		loadingAgenciesAccounts: loading,
		agenciesAccounts: data?.agenciesAccounts?.collection || null,
		getAgencies: getData,
	}
}

export const useLazyCheckSirenAccount = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(
		CHECK_SIREN_ACCOUNT,
		options
	)
	return {
		loadingCheckSiren: loading,
		sirenAccount: data?.checkSirenAccount || null,
		checkSirenAccount: getData,
	}
}

export const useLazyAgencyForModal = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(AGENCY_FOR_MODAL, options)
	return {
		loadingAgency: loading,
		agency: data?.agencyAccount || null,
		getAgency: getData,
	}
}

export const useLazyAgency = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(GET_AGENCY_NAME, options)
	return {
		loading,
		agencyName: data?.agencyAccount || null || null,
		getAgencyName: getData,
	}
}

export const useExportAgencies: any = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { data, loading, error } = useQuery(EXPORT_AGENCIES, options)

	return {
		exportFile: data?.exportAgenciesAccounts?.[0]?.exportFile || null,
		loading,
		error,
	}
}
