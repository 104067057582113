import { Stack } from '@mui/material'
import React, { useContext, useMemo } from 'react'
import { FiltersBtnContext } from '../../../../components/filters/filtersBtn/FiltersBtnContext'
import FilterMultiselect from '../../../../components/filters/filtersComponents/filterMultiselect'
import { EModule } from '../../../../utils/app-models'
import { useGetCustomerListForSelect } from '../../../../api/gql/queries/customersQueries'

interface IInvoiceFiltersBtnComponent {}

const InvoiceFiltersBtnComponent: React.FC<
	IInvoiceFiltersBtnComponent
> = () => {
	const { setTmpFilters, tmpFilters } = useContext(FiltersBtnContext)
	const { customers, loading: customersLoading } = useGetCustomerListForSelect()

	const customersOptions = useMemo(
		() =>
			customers?.map((customer) => ({
				id: customer?.id,
				name: customer?.name,
				value: customer?.id,
			})),
		[customers]
	)

	const customersDefaultValue = useMemo(
		() =>
			customersOptions?.filter((option) =>
				tmpFilters?.customer_list?.find(
					(customerId) => option?.id === customerId
				)
			),
		[customersOptions]
	)

	return (
		<Stack spacing={1}>
			<FilterMultiselect
				loading={customersLoading}
				options={customersOptions}
				defaultValue={customersDefaultValue}
				label={'misc.accounts'}
				modules={[EModule.crm]}
				setFiltersQuerieParam={setTmpFilters}
				filtersQuerieParam={tmpFilters}
				argumentQuerieName='customer_list'
			/>
		</Stack>
	)
}

export default InvoiceFiltersBtnComponent
