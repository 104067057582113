import React, { useCallback, useMemo } from 'react'

import { ListItemsData } from '../../../../components'
import { useInviteContact } from '../../../../api/gql/mutations/customerContactMutation'
import customToast from '../../../../components/alert/customToast'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import SendIcon from '@mui/icons-material/Send'

import PhoneIcon from '@mui/icons-material/Phone'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import EmailIcon from '@mui/icons-material/Email'
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useFormatMessage } from '../../../../utils/hooks'

const btnCSS = {
	marginRight: '8px',
}

interface ITabPanelContactInformation {
	dataContact: {
		job?: string
		phone?: string
		cellphone?: string
		email?: string
		canAccessMyContent?: boolean
		id?: string
	}
}
const TabPanelContactInformation: React.FC<ITabPanelContactInformation> = ({
	dataContact,
}) => {
	const intlMsg = useFormatMessage()
	const [inviteContact] = useInviteContact()

	const items = useMemo(
		() => [
			{
				primaryText: 'misc.poste',
				secondaryText: dataContact?.job || '-',
				icon: <AccessibilityNewIcon />,
			},
			{
				primaryText: 'misc.email',
				secondaryText: dataContact?.email || '-',
				icon: <EmailIcon />,
				externalLink: `mailto:${dataContact?.email}`,
				externalLinkTarget: '_self',
			},
			{
				primaryText: 'misc.cellphone',
				secondaryText: dataContact?.cellphone || '-',
				icon: <PhoneAndroidIcon />,
				...(dataContact?.cellphone && {
					externalLink: `tel:${dataContact.cellphone}`,
					externalLinkTarget: '_self',
				}),
			},
			{
				primaryText: 'misc.phone',
				secondaryText: dataContact?.phone || '-',
				icon: <PhoneIcon fontSize='small' />,
				...(dataContact?.phone && {
					externalLink: `tel:${dataContact.phone}`,
					externalLinkTarget: '_self',
				}),
			},
			{
				primaryText: 'user.shareUserActivity',
				sizeColumn: 12,
				secondaryText: dataContact?.canAccessMyContent
					? intlMsg('misc.active')
					: intlMsg('misc.inactive'),
				icon: (
					<CheckCircleIcon
						color={
							(dataContact?.canAccessMyContent ? 'enabled' : 'disabled') as any
						}
					/>
				),
			},
		],
		[dataContact]
	)

	const contactInvitation = useCallback(() => {
		inviteContact({
			variables: {
				input: {
					id: dataContact?.id,
				},
			},
		}).then(() => {
			customToast('sendEmail', 'success.contactIsShare')
		})
	}, [dataContact])

	return (
		<>
			<ListItemsData items={items} nbCol={6} />
			{dataContact?.canAccessMyContent && (
				<Box>
					<Button variant='outlined' onClick={contactInvitation}>
						<SendIcon sx={btnCSS} />
						{intlMsg('user.sendInvitation')}
					</Button>
				</Box>
			)}
		</>
	)
}

export default TabPanelContactInformation
