import React, { useCallback, useContext, useMemo } from 'react'

import { useNavigate } from 'react-router-dom'
import { Tab, Tabs } from '@mui/material'

import { useFormatMessage } from '../../../../utils/hooks'
import { MultiNavTableContext } from '../../MultiNavTableProvider'
import { tabCSS, tabsCSS } from '../../../../styles/sxCSS/tabs'

interface ITableMainNav {}

const TableMainNav: React.FC<ITableMainNav> = () => {
	const intlMsg = useFormatMessage()
	const navigate = useNavigate()
	const { multiTableNavConfig, currentIndexConfig, setSideNavSelectedId } =
		useContext(MultiNavTableContext) || {}

	const handleChange = useCallback(
		(_event, newValue) => {
			navigate(multiTableNavConfig[newValue]?.tableMainNav.route)
			setSideNavSelectedId(null)
		},
		[navigate, setSideNavSelectedId, multiTableNavConfig]
	)

	const TabsCSS = useMemo(
		() => ({
			...tabsCSS,
			p: 2,
			pb: 0,
			mt: -2,
			'& .MuiTabs-scroller': { ml: -1 },
		}),
		[tabsCSS]
	)

	return (
		<Tabs
			value={currentIndexConfig}
			indicatorColor='primary'
			textColor='primary'
			onChange={handleChange}
			TabIndicatorProps={{ children: <span /> }}
			sx={TabsCSS}
		>
			{multiTableNavConfig?.map(({ tableMainNav }, i) => (
				<Tab
					key={i}
					value={i}
					label={intlMsg(tableMainNav?.label)}
					sx={{
						...tabCSS,
						color: i === currentIndexConfig ? 'black!important' : 'inherit',
					}}
					disableRipple
					onChange={handleChange as any}
				/>
			))}
		</Tabs>
	)
}

export default TableMainNav
