export default {
	immat: 'Registration',
	vin: 'VIN',
	km: 'km',
	KM: 'KM',
	brand: 'Brand',
	model: 'Model',
	designation: 'Designation',
	box: 'Location',
	lastPass: 'Last passage',
	nextPass: 'Next passage',
	location: 'Vehicle Location',
	locationShort: 'Location',
	locationTitle: 'Vehicle Location',
	locationColor: 'Color',
	vehicleCategory: 'Category',
	category: 'Category',
	vehicleType: 'Classification',
	horsePower: 'Engine Power (HP)',
	fiscPower: 'Fiscal Power (CV)',
	carburant: 'Fuel',
	energy: 'Energy',
	complementaryInfos: 'Additional Information',
	tireSizeBack: 'Rear Tire Size',
	tireSizeRear: 'Front Tire Size',
	ptac: 'GVWR',
	nbDoors: 'Number of Doors',
	nbSeats: 'Number of Seats',
	transmission: 'Transmission',
	vehicleDetailsDataFound: 'A vehicle has been found in the database',
	vehicleDetailsDataNotFound: 'No vehicle found in the database',
	vehicleDetailsDataNotFoundWithoutPermissions:
		"No vehicle found in the database. However, you don't have the necessary permissions to create a new one.",
	create: 'Add a vehicle',
	edit: 'Edit a vehicle',
	identificationTitle: 'Vehicle Identification',
	enterTitle: 'Enter the VIN and/or registration to continue',
	denominationTitle: 'Vehicle Denomination',
	technicalInfos: 'Technical Information',
	statusPresence: 'Status during last intervention',
	presence: 'Present',
	restitute: 'Returned',
	absent: 'Absent',
	technicalFile: 'Technical File',
	dateImmat: 'Registration Date',
	updateConfirmModalTitle: 'Update Vehicle?',
	updateConfirmModalText:
		'A vehicle matching the entered information has been found.<br/>Do you want to update it?',
	updateConfirmModalTextWithoutPermissions:
		'A vehicle matching the entered information has been found. However, you do not have the necessary permissions to modify it.',
	createVehicle: 'Create a Vehicle',
	removeSelectedVehicles: 'Remove selected vehicles',

	fusionConfirmModalTitle: 'Vehicle Fusion',
	// TODO delete comment when fusion vehicle will be operational (search #fusion_vehicle in the project)
	// fusionConfirmModalText:
	// 	'The entered information corresponds to 2 different vehicles. Do you want to merge them?',
	fusionConfirmModalText:
		'Two different vehicles were found with this information, please contact support at <strong><a href="mailto:support@hubeecar.com" >support@hubeecar.com</a></strong>',

	fusionConfirmModalTextWithoutPermissions:
		'The entered information corresponds to 2 different vehicles. However, you do not have the necessary permissions to merge them',
	statusVehicle: 'Last Status',
	todo: 'To Do',
	planned: 'Planned',
	pending: 'Pending',
	returned: 'Returned',
	done: 'Done',
	noshow: 'No Show',
	canceled: 'Canceled',
	parkEntry: 'Park Entry',
	parkExit: 'Park Exit',
	time: 'Time',

	// fuel type
	GAZOL: 'Diesel',
	GAS: 'Petrol',
	HYBRID: 'Hybrid',
	ELECTRIC: 'Electric',
	GAS_GPL: 'Petrol / LPG',
	GAS_BIO: 'Petrol / Bioethanol',
	OTHER: 'Other',

	// Vehicle type
	VP: 'PV - Passenger Car',
	CTTE: 'LGV - Light Commercial Vehicle',
	CAM: 'HGV - Heavy Goods Vehicle',
	QM: 'QM - Quadricycle motor',
	VASP: 'VASP - Special Purpose Vehicle',
	MAGA: 'MAGA - Agricultural Machine',
	REX: 'REX - Trailer',
	MIAR: 'MIAR - Machine and Trailer',

	// Transmission
	manual: 'Manual',
	automatic: 'Automatic',
	semiAutomatic: 'Semi-Automatic',
	sequential: 'Sequential',

	mileageImmat: 'Mileage from',
	mileage: 'Mileage',
	dateAdd: 'Added on',
	by: 'By',

	// Category vehicle
	HATCHBACK: 'Hatchback',
	SEDAN: 'Sedan',
	CABRIOLET: 'Convertible',
	WAGON: 'Station Wagon',
	MINIVAN: 'Minivan',
	MINIBUS: 'Minibus',
	SUV: 'SUV',
	LIMO: 'Limousine',
	VU3M3: '3m3 Van',
	VU6M3: '6m3 Van',
	VU9M3: '9m3 Van',
	VU10M3: '10m3 Van',
	VU11M3: '11m3 Van',
	VU12M3: '12m3 Van',
	VU13M3: '13m3 Van',
	VU14M3: '14m3 Van',
	VU15M3: '15m3 Van',
	VU20M3: '20m3 Van',
	BUS: 'Bus',
	STAKEBODY: 'Flatbed',
	DUMP: 'Dump Truck',
	TRACTOR: 'Tractor',
	HEAVY: 'Heavy Vehicle',
	TRAILER: 'Trailer',
}
