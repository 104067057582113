export default {
	dontShowAgain: 'Do not show again',
	deleteCustomerAccountTitle: 'Delete Account',
	deleteCustomerAccountMessage: 'Are you sure you want to delete this account?',
	deleteFormTemplateTitle: 'Delete Form Template',
	deleteFormTemplateMessage: 'Are you sure you want to delete this form?',
	deleteFormTemplatesTitle: 'Delete Form Templates',
	deleteFormTemplatesMessage: 'Are you sure you want to delete these forms?',

	addUserTitle: 'Add User',
	addUserTitleMessage:
		'Adding this new user will modify the price of your subscription.',
	deleteUserTitle: 'Delete User',
	deleteUserMessage: 'Are you sure you want to delete this user?',
	deleteUsersTitle: 'Delete Users',
	deleteUsersMessage: 'Are you sure you want to delete these users?',

	deleteTeamTitle: 'Delete Team',
	deleteTeamMessage: 'Are you sure you want to delete this team?',
	deleteTeamsTitle: 'Delete Teams',
	deleteTeamsMessage: 'Are you sure you want to delete these teams?',

	deleteContactTitle: 'Delete Contact',
	deleteContactMessage: 'Are you sure you want to delete this contact?',
	deleteContactsTitle: 'Delete Contacts',
	deleteContactsMessage: 'Are you sure you want to delete these contacts?',

	deleteCustomerSiteTitle: 'Delete Site',
	deleteCustomerSiteMessage: 'Are you sure you want to delete this site?',
	deleteCustomersSiteTitle: 'Delete Sites',
	deleteCustomersSiteMessage: 'Are you sure you want to delete these sites?',

	deleteCustomersAccountTitle: 'Delete Accounts',
	deleteCustomersAccountMessage:
		'Are you sure you want to delete these accounts?',

	deleteInterventionsTitle: 'Delete Interventions',
	deleteInterventionsMessage:
		'Are you sure you want to delete these interventions?',
	deleteInterventionTitle: 'Delete Intervention',
	deleteInterventionMessage:
		'Are you sure you want to delete this intervention?',

	deleteTransportsTitle: 'Delete transports',
	deleteTransportsMessage: 'Are you sure you want to delete these transports?',
	deleteTransportTitle: 'Delete transport',
	deleteTransportMessage: ' Are you sure you want to delete this transport?',

	deleteVehicleSiteTitle: 'Delete Vehicles',
	deleteVehicleSiteMessage: 'Are you sure you want to delete this vehicle?',

	vehiclesFusionTitle: 'Merge Vehicle Information',
	featureNotAvailable: 'This feature is not yet available',

	deleteAlertTitle: 'Delete Alert',
	deleteAlertMessage: 'Are you sure you want to delete this alert?',

	deleteInterventionFormTitle: 'Delete Form',
	deleteInterventionFormMessage: 'Are you sure you want to delete this form?',

	deleteAgencyTitle: 'Delete Agency',
	deleteAgencyMessage: 'Are you sure you want to delete this agency?',
	deleteAgenciesTitle: 'Delete Agencies',
	deleteAgenciesMessage: 'Are you sure you want to delete these agencies?',

	deleteInvoiceTitle: 'Delete Expense',
	deleteInvoiceMessage: 'Are you sure you want to delete this expense?',
	deleteInvoicesTitle: 'Delete Expenses',
	deleteInvoicesMessage: 'Are you sure you want to delete these expenses?',

	deleteVehiclesTitle: 'Delete Vehicles',
	deleteVehicleMessage: 'Are you sure you want to delete these vehicles?',

	deleteDocumentVehicleTitle: 'Delete Document',
	deleteDocumentVehicleMessage:
		'Are you sure you want to delete this document?',
	deleteIDocumentsVehiclesTitle: 'Delete Documents',
	deleteDocumentsVehiclesMessage:
		'Are you sure you want to delete these documents?',

	confirmQuitFOrmTemplateEditorTitle: 'Unsaved Changes',
	confirmQuitFOrmTemplateEditorMessage:
		'Changes made to the form template have not been saved.',
	quitWithoutSave: 'Quit without saving',
	return: 'Return',
}
