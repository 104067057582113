import React from 'react'

import { useRemoveInvoice } from '../../../../../api/gql/mutations/invoicesMutation'
import {
	EAccountType,
	EPermission,
	EPermissionScope,
} from '../../../../../utils/app-models'
import { formatDate } from '../../../../../utils/date'
import InvoiceModal from '../../../../../view/dashboard/invoice/invoiceModal'
import ActionsBtn from '../../../tableCell/actionsBtn'
import TimeCell from '../../../tableCell/TimeCell'
import UploadAwsFile from '../../../tableCell/UploadAwsFile'
import { Box } from '@mui/material'
import OpenLinkCell from '../../../tableCell/OpenLinkCell'
import UserAvatarSlidePanel from '../../../../avatar/UserAvatarSlidePanel'
import { ROUTES } from '../../../../../routes/consts'

const transportListInvoicesViewTable = [
	{
		columnLabel: 'misc.generalAccount',
		permissions: [EPermission.superMegaAdmin],
		personalizedCell: ({ account }) => <Box>{account?.name}</Box>,
	},

	{
		columnLabel: 'misc.reference',
		orderBy: 'reference',
		stickyFirstCol: true,
		personalizedCell: ({ reference }) => <div>{reference}</div>,
	},

	{
		columnLabel: 'misc.vehicle',
		orderBy: 'invoiceVehicles_vehicle_vehicleDetail_immat',
		personalizedCell: ({ invoiceVehicles: { collection } }) => (
			<>
				{collection?.[0]?.vehicle?.vehicleDetail?.id ? (
					<OpenLinkCell
						txt={
							collection?.[0]?.vehicle?.vehicleDetail?.immat ||
							collection?.[0]?.vehicle?.vehicleDetail?.vin
						}
						viewModalLink={`${ROUTES.VIEWMODAL.VEHICLES.url}${collection?.[0]?.vehicle?.id}`}
					/>
				) : (
					'-'
				)}
			</>
		),
	},

	{
		columnLabel: 'invoice.priceHT',
		orderBy: 'totalAmountET',
		personalizedCell: ({ totalAmountET }) => (
			<>{`${totalAmountET ? totalAmountET.toFixed(2) + '€' : '-'} `} </>
		),
	},

	{
		columnLabel: 'invoice.priceTTC',
		// orderBy: 'totalAmountET',
		personalizedCell: ({ totalAmountET, vat }) => (
			<>
				{`${
					totalAmountET
						? (totalAmountET + (totalAmountET * vat) / 100).toFixed(2) + '€'
						: '-'
				} `}
			</>
		),
	},

	{
		columnLabel: 'invoice.tva',
		orderBy: 'vat',
		personalizedCell: ({ vat }) => <>{vat}%</>,
	},

	{
		columnLabel: 'misc.addAt',
		orderBy: 'dateCreated',
		personalizedCell: ({ dateCreated }) => (
			<TimeCell date={dateCreated ? formatDate(dateCreated) : '-'} />
		),
	},

	{
		columnLabel: 'invoice.dueDate',
		orderBy: 'dueDate',
		personalizedCell: ({ dueDate }) => (
			<TimeCell date={dueDate ? formatDate(dueDate) : '-'} />
		),
	},

	{
		columnLabel: 'invoice.addBy',
		orderBy: 'createdBy_lastName',
		accountsTypeDenied: [EAccountType.guest],
		personalizedCell: ({ createdBy }) => (
			<UserAvatarSlidePanel
				firstName={createdBy?.firstName}
				lastName={createdBy?.lastName}
				userId={createdBy?.id}
				displayName={false}
			/>
		),
	},

	{
		columnLabel: 'misc.download',
		orderBy: 'file',
		personalizedCell: ({ id, file }) => file && <UploadAwsFile id={id} />,
	},

	{
		fixWidth: '50px',
		columnLabel: '',
		visible: true,
		tableCellAlign: 'center',
		cellAlign: 'center',
		sticky: true,
		personalizedCell: ({ id }: any) => (
			<ActionsBtn
				rowId={id}
				deleted={{
					query: useRemoveInvoice,
					refreshQuery: [
						'getInvoicesCountByVehicle',
						'getInvoicesList',
						'getInvoicesCountByIntervention',
					],
					successMessageDelete: 'success.invoiceDeleted',
					messageConfirm: {
						title: 'confirmModal.deleteInvoiceTitle',
						message: 'confirmModal.deleteInvoiceMessage',
					},
				}}
				viewAction={false}
				editModal={<InvoiceModal invoiceId={id} modalUtils={{} as any} />}
				permissions={{ update: [EPermissionScope.invoiceManage] }}
			/>
		),
	},
]

export default transportListInvoicesViewTable
