export const tabCSS = {
	textTransform: 'uppercase',
	maxHeight: '50px',
	fontWeight: 500,
	fontSize: ({ typography }) => typography.pxToRem(14),
	marginRight: 1,
	'&:focus': {
		opacity: 1,
	},
} as const

export const tabsCSS = {
	backgroundColor: 'white',
	borderBottom: 'solid 1px #eaeaea',
	boxSizing: 'content-box',
	
	'& .tableContainerCSS': {
		height: '0px',
    	flexGrow: 1,
		'& > div': {
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
		}
	},
	
	'&  .MuiTabs-indicator': {
		backgroundColor: 'transparent',
		display: 'flex',
		justifyContent: 'center',
		height: '4px',
		
		'& > span': {
			width: '100%',
			// maxWidth: 90,
			backgroundColor: ({ palette }) => palette.primary.main,
			borderTopRightRadius: '9px',
			borderTopLeftRadius: '9px',
		},
	},
} as const
