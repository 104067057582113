import React, { cloneElement, useCallback, useContext } from 'react'

import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Avatar, Stack, Typography } from '@mui/material'

import { SearchField } from '../../forms/v1/formFields'
import ColumnFilter from './columnFilter'
import TableExport from './tableExport'
import TableImport from './tableImport'
import { TableContext } from '../../../utils/contexts/TableContext'
import TableBtnCreate from './tableBtnCreate'
import { AuthContext } from '../../../utils/contexts/AuthContext'
import { useFormatMessage } from '../../../utils/hooks'
import { FiltersContext } from '../../filters/filtersContext'

const periodCSS = {
	mr: 2,
	borderLeft: '1px solid rgba(0, 0, 0, 0.38)',
	padding: '10px',
	maxHeight: '28px',
	display: 'flex',
	alignItems: 'center',

	fontStyle: 'normal',
	fontWeight: '500',
	fontSize: '16px',
	lineHeight: '140%',
	color: 'rgba(0, 0, 0, 0.6)',
}

const numberSelectedItems = {
	width: '38px',
	height: '38px',
	marginRight: '10px',
	backgroundColor: '#2196F314',
	color: '#000000DE',
	fontWeight: '700',
}

interface ITableToolBar {
	title?: { text?: any; cssClasse?: string; border?: string }
	customTitle?: any
	numberItem?: number
	liveState
	tableConfig
	modalButton?
	localStorageName?
	searchPlaceholder?
	exportConfig?
	importConfig?
	createPermission?: string[] | null
	modules?: string[] | null
	customBtnCreate?
	hidden?
	othersQueryParams?
}

const TableToolBar: React.FC<ITableToolBar> = ({
	title,
	customTitle,
	numberItem,
	liveState,
	tableConfig,
	modalButton,
	localStorageName,
	searchPlaceholder = 'search.default',
	exportConfig,
	importConfig,
	createPermission = null,
	modules = null,
	customBtnCreate,
	hidden,
	othersQueryParams,
}) => {
	const { checkPermissions } = useContext(AuthContext)
	const { loader } = useContext(TableContext)
	const intlMsg = useFormatMessage()
	const { componentsFiltersConfig } = useContext(FiltersContext)

	const { setDebouncedState, activeColumns, switchOption, filtersQuerieParam } =
		useContext(TableContext) || {}

	const handleChangeSearch = useCallback(
		(e) => {
			setDebouncedState({ ...liveState, searchText: e.target.value?.trim() })
		},
		[liveState]
	)

	const resetSearch = useCallback(() => {
		setDebouncedState({ ...liveState, searchText: '' })
	}, [liveState])

	return (
		<Stack
			direction='row'
			alignItems='center'
			justifyContent='space-between'
			sx={{ mb: 2 }}
			flexWrap={'wrap'}
			gap={2}
		>
			<Stack
				direction='row'
				alignItems='center'
				sx={{ flexWrap: 'wrap', gap: { xs: 2, md: 0 } }}
			>
				{!hidden?.numberItem && numberItem && (
					<Avatar sx={numberSelectedItems}>{numberItem}</Avatar>
				)}

				{!hidden?.title && title && (
					<Typography variant='h2' sx={{ mr: 2 }}>{`${intlMsg(
						title
					)}`}</Typography>
				)}

				{customTitle && customTitle()}

				{(filtersQuerieParam?.period || othersQueryParams?.period) && (
					<Typography sx={periodCSS}>
						{othersQueryParams?.period
							? intlMsg(`misc.${othersQueryParams.period}`)
							: intlMsg(`misc.${filtersQuerieParam.period}`)}
					</Typography>
				)}

				{!hidden?.switchActive && switchOption && (
					<FormControlLabel
						control={
							<Switch
								size='small'
								checked={switchOption?.checked}
								onChange={switchOption?.onChange}
								color='primary'
								data-test-id='Playwright-TableToolBar-handleActive'
							/>
						}
						label={switchOption?.label || <></>}
					/>
				)}
				{!hidden?.filter &&
					componentsFiltersConfig &&
					cloneElement(componentsFiltersConfig)}
				{!hidden?.search && (
					<SearchField
						placeholder={searchPlaceholder}
						onChange={handleChangeSearch}
						resetSearch={resetSearch}
						minCharacters={3}
						loading={loader}
					/>
				)}
			</Stack>

			<Stack
				direction='row'
				alignItems='center'
				spacing={1}
				flexWrap={'wrap'}
				gap={1}
			>
				{!hidden?.tableImport &&
					importConfig &&
					checkPermissions(importConfig.permissions) && (
						<TableImport importConfig={importConfig} />
					)}

				{!hidden?.tableExport && exportConfig && (
					<TableExport
						exportConfig={exportConfig}
						actif={switchOption?.checked}
					/>
				)}

				{!hidden?.columnFilter && activeColumns && (
					<ColumnFilter
						localStorageName={localStorageName}
						tableConfig={tableConfig}
					/>
				)}

				{!hidden?.tableBtnCreate && (modalButton || customBtnCreate) && (
					<TableBtnCreate
						modalButton={modalButton}
						customBtnCreate={customBtnCreate}
						createPermission={createPermission}
						modules={modules}
					/>
				)}
			</Stack>
		</Stack>
	)
}

export default TableToolBar
