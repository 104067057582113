import React, { useContext, useCallback } from 'react'

import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import Chip from '@mui/material/Chip'
import { Stack, Box } from '@mui/material'

import { FormTemplateContext } from '../../../../../../utils/contexts/FormTemplateContext'
import {
	EFormTemplateBasicFields,
	EFormTemplateTitleType,
} from '../../../../../../utils/app-models'
import { updateLimitField } from '../../formEditionsUtils/fieldsLimitation'
import { useFormatMessage } from '../../../../../../utils/hooks'

const requiredCSS = {
	fontSize: '10px',
	p: 0,
	color: ({ palette }) => palette.error.main,
	ml: 1,
	border: ({ palette }) => `solid 1px ${palette.error.main}`,
	height: '16px',
}
const alertCSS = {
	fontSize: '10px',
	p: 0,
	color: ({ palette }) => palette.primary.main,
	ml: 1,
	border: ({ palette }) => `solid 1px ${palette.primary.main}`,
	height: '16px',
}

const specificChipsCSS = {
	...requiredCSS,
	color: ({ palette }) => palette.primary.main,
	border: ({ palette }) => `solid 1px ${palette.primary.main}`,
}

const h1CSS = (titleType) => ({
	p: 1,
	fontSize:
		titleType === EFormTemplateTitleType.mediumTitle
			? '22px'
			: titleType === EFormTemplateTitleType.bigTitle
			? '27px'
			: '17px',
})

interface ITitleField {
	deleteField
	index
	fieldToEdit
	isRequired
	canAlert?: boolean | undefined
	insertField
	values
	fieldType
	titleType?
	specificChips?: any
	forcedTitle?: string
}

const specificInsert = ({
	insertField,
	index,
	name,
	fieldType,
	values,
	specificField,
}) =>
	insertField(index + 1, {
		name,
		fieldType,
		values: {
			...values,
			[specificField]: values[specificField]?.map((value) => ({ ...value })),
		},
	})

const TitleField: React.FC<ITitleField> = ({
	deleteField,
	index,
	fieldToEdit,
	isRequired,
	canAlert,
	insertField,
	values,
	fieldType,
	titleType = null,
	specificChips,
	forcedTitle = null,
}) => {
	const intlMsg = useFormatMessage()

	const {
		drawerUtils: { toggleDrawer },
		setLimitationFields,
		limitationFields,
		setIsOpenLimitModal,
	} = useContext(FormTemplateContext)

	const duplicateField = useCallback(
		(index, values, fieldType, name) => () => {
			switch (fieldType) {
				case EFormTemplateBasicFields.control:
					specificInsert({
						insertField,
						index,
						name,
						fieldType,
						values,
						specificField: 'controlList',
					})
					break

				case EFormTemplateBasicFields.picture:
					if (
						limitationFields[fieldType].currentPhotos +
							values.picturesList?.length >
						limitationFields[fieldType].maxPhotos
					) {
						setIsOpenLimitModal(fieldType)
					} else {
						updateLimitField({
							fieldType,
							fieldAttributes: { picturesList: values.picturesList },
							setLimitationFields,
						})
						specificInsert({
							insertField,
							index,
							name,
							fieldType,
							values,
							specificField: 'picturesList',
						})
					}

					break

				case EFormTemplateBasicFields.selection:
					specificInsert({
						insertField,
						index,
						name,
						fieldType,
						values,
						specificField: 'selectionsList',
					})
					break

				default:
					insertField(index + 1, {
						name,
						fieldType,
						values: {
							...values,
						},
					})
					break
			}
		},
		[limitationFields]
	)

	const onDeleteField = useCallback(
		(index, values, fieldType) => () => {
			switch (fieldType) {
				case EFormTemplateBasicFields.picture:
					updateLimitField({
						fieldType,
						fieldAttributes: { picturesList: values.picturesList },
						setLimitationFields,
						isRemoval: true,
					})
					deleteField(index)
					break

				default:
					deleteField(index)
					break
			}
		},
		[]
	)

	return (
		<Stack direction='row' alignItems='center' justifyContent='space-between'>
			<Typography variant='subtitle2' sx={h1CSS(titleType)}>
				{forcedTitle || values?.titleField}
				{canAlert && (
					<Chip
						variant='outlined'
						size='small'
						label={intlMsg('formTemplate.alert')}
						sx={alertCSS}
					/>
				)}
				{isRequired && (
					<Chip
						variant='outlined'
						size='small'
						label={intlMsg('formTemplate.formRequire')}
						sx={requiredCSS}
					/>
				)}

				{specificChips?.length > 0 &&
					specificChips.map((chipText, i) => (
						<Chip
							key={`${chipText}-${i}`}
							variant='outlined'
							size='small'
							label={chipText}
							sx={specificChipsCSS}
						/>
					))}
			</Typography>
			<Box>
				<IconButton
					aria-label='edit'
					onClick={toggleDrawer('right', true, fieldToEdit)}
					size='large'
				>
					<EditIcon fontSize='small' />
				</IconButton>
				<IconButton
					aria-label='copy'
					onClick={duplicateField(index, values, fieldType, name)}
					size='large'
				>
					<FileCopyIcon fontSize='small' />
				</IconButton>
				<IconButton
					aria-label='delete'
					onClick={onDeleteField(index, values, fieldType)}
					size='large'
				>
					<DeleteIcon fontSize='small' />
				</IconButton>
			</Box>
		</Stack>
	)
}

export default TitleField
