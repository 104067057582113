import React from 'react'

import { Alert } from '@mui/material'

import CatalogueProductBaseSideNavModal from './modal'
import { GET_PRODUCT_BASE_CATEGORIES } from '../../../../../api/gql/queries/catalogProductQueries'
import { useRemoveProductBaseCategory } from '../../../../../api/gql/mutations/catalogProductsMutation'
import TableSideNavItemTitle from '../../../../../components/multiNavTable/multiTableNavView/tableSideNav/tableSideNavList/NavItems/TableSideNavItemTitle'
import TableSideNavItemColor from '../../../../../components/multiNavTable/multiTableNavView/tableSideNav/tableSideNavList/NavItems/TableSideNavItemColor'
import TableSideNavItemCount from '../../../../../components/multiNavTable/multiTableNavView/tableSideNav/tableSideNavList/NavItems/TableSideNavItemCount'
import TableSideNavItemEdit from '../../../../../components/multiNavTable/multiTableNavView/tableSideNav/tableSideNavList/NavItems/TableSideNavItemEdit'
import TableSideNavItemComment from '../../../../../components/multiNavTable/multiTableNavView/tableSideNav/tableSideNavList/NavItems/TableSideNavItemComment'
import TableSideNavButtonCreate from '../../../../../components/multiNavTable/multiTableNavView/tableSideNav/tableSideNavFooter/TableSideNavButtonCreate'
import TableSideNavItemRemove from '../../../../../components/multiNavTable/multiTableNavView/tableSideNav/tableSideNavList/NavItems/TableSideNavItemRemove'
import translateIntlMsgString from '../../../../../utils/translateIntlMsgString'
import { EPermissionScope } from '../../../../../utils/app-models'
import PermissionsHandler from '../../../../../components/permissionsHandler'
import { TSideItemBlock } from '../../../../../utils/typescript/multiNavTableType'

/////////////////////////////////////////////////////////
// ListItemConfig
/////////////////////////////////////////////////////////

const blockLeft: TSideItemBlock[] = [
	({ color }) => <TableSideNavItemColor color={color} />,
	({ name }, isSelected) => (
		<TableSideNavItemTitle title={name} isSelected={isSelected} />
	),
]

const blockRight: TSideItemBlock[] = [
	(data, isSelected) => (
		<TableSideNavItemCount
			isSelected={isSelected}
			count={data?.productBasesActive?.paginationInfo?.totalCount || 0}
		/>
	),
]

const blockAction: TSideItemBlock[] = [
	({ description, name }, isSelected) => (
		<TableSideNavItemComment
			comment={description}
			isSelected={isSelected}
			playwrightId={`Playwright-DescriptionCategory-${name}`}
		/>
	),
	({ id, name }, isSelected) => (
		<PermissionsHandler permissionsName={[EPermissionScope.productBaseManage]}>
			<TableSideNavItemEdit
				isSelected={isSelected}
				playwrightId={`Playwright-EditCategory-edit-${name}`}
				modal={
					<CatalogueProductBaseSideNavModal
						categoryId={id}
						modalUtils={{} as any}
					/>
				}
			/>
		</PermissionsHandler>
	),
	(data, isSelected) => (
		<PermissionsHandler permissionsName={[EPermissionScope.productBaseManage]}>
			{!data?.productBasesActive?.paginationInfo?.totalCount &&
			!data?.productBasesInactive?.paginationInfo?.totalCount ? (
				<TableSideNavItemRemove
					id={data?.id}
					removeMutation={useRemoveProductBaseCategory}
					refetchQueries={['getProductBaseCategories']}
					isSelected={isSelected}
					playwrightId={`Playwright-DeleteCategory-${data?.name}`}
				/>
			) : (
				<></>
			)}
		</PermissionsHandler>
	),
]

/////////////////////////////////////////////////////////
// Export Configs
/////////////////////////////////////////////////////////

export const catalogueProductBaseConfigSideNav = {
	query: GET_PRODUCT_BASE_CATEGORIES,
	queryName: 'productBaseCategories',
	modalCreate: () => (
		<TableSideNavButtonCreate
			permissions={[EPermissionScope.productBaseManage]}
			modal={<CatalogueProductBaseSideNavModal modalUtils={{} as any} />}
		/>
	),
	listItemConfig: { blockLeft, blockAction, blockRight },
	emptyMessage: () => (
		<Alert icon={false} severity='info'>
			{translateIntlMsgString('catalogProduct.emptyCategoriesProductBase')}
		</Alert>
	),
}
