import { createTheme } from '@mui/material'

import { generateDeepMergedObj } from '../../utils/utils'
import { GlobalTheme } from './globalTheme/globalTheme'
import { MuiTheme } from './muiTheme'
import { ISpecificColortheme } from './themeTypes'

export const DarkColorTheme: ISpecificColortheme = {
	primary: '#C985FF',
	white: '#FFFFFF',
	text: {
		primary: '#FFFFFF',
		secondary: '#C5CBD7',
		third: '#C5CBD7',
		disable: '#C5CBD7',
		clicable: '#5768FF',
	},
	actions: {
		active: '#898C96',
		disable: '#494C54',
		hover: '#C985FF14',
		selected: '#C985FF3D',
		border: '#D4DDE9',
		borderCalendar: '#9FB5D2',
		borderSelected: '#C985FF',
	},
	backgrounds: {
		main: '#2C3036v',
		secondary: '#272A2E',
		light: '#C985FF29',
		screen: '#00020F99',
	},
}

export const DarkTheme = {
	colors: DarkColorTheme,

	//old theme version, used until MUI is completely removed
	palette: {
		divider: '#ededed',
		primary: {
			main: DarkColorTheme.primary,
			dark: '#1D2443', //useless
		},
		text: DarkColorTheme.text,
		//todo change this when we know why chips is crashing without this param (contrastText)
		alert: {
			// main: red[700],
			contrastText: '#fff',
		},
	},
	...MuiTheme, //old theme version, used until MUI is completely removed
}

export const HubeeDarkTheme = createTheme(
	generateDeepMergedObj(GlobalTheme, DarkTheme)
)
