import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'

import { useLazyQuery } from '@apollo/client'
import { Typography, Stack } from '@mui/material'

import { useFormatMessage } from '../../../../../../../utils/hooks'
import FormSelectSearch from '../../../../../../../components/forms/formFields/formSelectSearch'
import PermissionsHandler from '../../../../../../../components/permissionsHandler'
import {
	EModule,
	EPermissionScope,
} from '../../../../../../../utils/app-models'
import {
	FormModalContext,
	IFormModalContext,
} from '../../../../../../../components/forms/formModal/FormModalContext'
import { GET_SITES_BY_CUSTOMER_ID } from '../../../../../../../api/gql/queries/sitesQueries'
import { useGetCustomerListForSelect } from '../../../../../../../api/gql/queries/customersQueries'
import { GET_CONTACT_LIST_FOR_SELECT } from '../../../../../../../api/gql/queries/contactsQueries'
import FormTextField from '../../../../../../../components/forms/formFields/FormTextField'
import CreateContactLink from '../../../../interventionControl/interventionModal/interventionModalControlInfos/serviceRequest/CreateContactLink'

interface ITransportServiceRequest {}

const TransportServiceRequest: React.FC<ITransportServiceRequest> = () => {
	const intlMsg = useFormatMessage()
	const { data, setValues } = useContext<IFormModalContext>(FormModalContext)

	const [selectedCustomer, setSelectedCustomer] = useState(
		data?.orderingCustomer?.value?.id || null
	)

	const { customers } = useGetCustomerListForSelect()
	const [getSites, { data: dataSites }] = useLazyQuery(GET_SITES_BY_CUSTOMER_ID)
	const [getContacts, { data: dataContacts }] = useLazyQuery(
		GET_CONTACT_LIST_FOR_SELECT
	)
	const selectedSite = useMemo(
		() => data?.orderingSite?.value?.id,
		[data?.orderingSite]
	)

	// /////////////////////////////////////////////////
	// Format input options
	// /////////////////////////////////////////////////

	const customersOptions = useMemo(
		() =>
			customers?.map((customer) => ({
				key: customer?.id,
				label: customer?.name,
				value: customer,
			})) || [],
		[customers]
	)

	const sitesOptions = useMemo(
		() =>
			dataSites?.sites?.collection?.map((site) => ({
				key: site?.id,
				label: site?.name,
				value: site,
			})) || [],
		[dataSites]
	)

	const contactsOptions = useMemo(
		() =>
			dataContacts?.contacts?.collection?.map((contact) => ({
				key: contact?.id,
				label: `${contact?.firstName} ${contact?.lastName}`,
				value: contact,
			})) || [],
		[dataContacts]
	)

	// /////////////////////////////////////////////////
	// Get & set values
	// /////////////////////////////////////////////////

	useEffect(() => {
		if (selectedCustomer)
			getSites({ variables: { customer: selectedCustomer } })
	}, [selectedCustomer])

	useEffect(() => {
		if (selectedSite || data)
			getContacts({ variables: { sites: selectedSite } })
	}, [selectedSite])

	const handleSelect = useCallback(
		({ fieldName, option }) => {
			if (fieldName === 'orderingCustomer') {
				setValues({
					orderingCustomer: option,
					orderingSite: null,
					orderingContact: null,
					isDeliveryAddressEqualOrder: false,
					isRemovalAddressEqualOrder: false,
				})
				setSelectedCustomer(option?.value?.id)
			} else if (fieldName === 'orderingSite') {
				setValues({
					orderingSite: option,
					orderingContact: null,
					isDeliveryAddressEqualOrder: false,
					isRemovalAddressEqualOrder: false,
				})
			} else if (fieldName === 'orderingContact') {
				setValues({
					orderingContact: option,
					isDeliveryAddressEqualOrder: false,
					isRemovalAddressEqualOrder: false,
				})
			}
		},
		[setValues]
	)

	return (
		<Stack spacing={1.5}>
			<Typography variant='h2' gutterBottom>
				{intlMsg('interventions.transportRequest')}
			</Typography>

			<FormSelectSearch
				name='orderingCustomer'
				label={intlMsg('misc.account')}
				specialOnChange={handleSelect}
				selectElements={customersOptions}
				context={FormModalContext}
				missingText={intlMsg('misc.noResultSearch')}
				isRequired
				trigerred
			/>
			<FormSelectSearch
				name='orderingSite'
				label={intlMsg('misc.sites')}
				specialOnChange={handleSelect}
				selectElements={sitesOptions}
				context={FormModalContext}
				missingText={intlMsg('interventions.noKnownSite')}
				isDisabled={!data['orderingCustomer']}
				isRequired
				trigerred
			/>

			<FormTextField
				name='externalReference'
				label={intlMsg('interventions.customerRef')}
				context={FormModalContext}
			/>

			<PermissionsHandler modulesName={[EModule.crm]}>
				<FormSelectSearch
					name='orderingContact'
					label={intlMsg('misc.contact')}
					specialOnChange={handleSelect}
					selectElements={contactsOptions}
					context={FormModalContext}
					missingText={intlMsg('interventions.noKnownContact')}
					isDisabled={!data['orderingSite']}
				/>
				<PermissionsHandler permissionsName={[EPermissionScope.contactManage]}>
					<CreateContactLink
						forcedCustomerId={data['orderingCustomer']?.value?.id}
						forcedSiteId={data['orderingSite']?.value?.id}
						name='orderingContact'
						isDisabled={!data['orderingSite']}
					/>
				</PermissionsHandler>
			</PermissionsHandler>
		</Stack>
	)
}
export default TransportServiceRequest
