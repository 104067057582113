import React from 'react'

import AccountPermissions from './AccountPermissions'
import AccountType from './AccountType'

const getModalContentConfig: any = (editedAccount) => [
	{
		label: 'misc.permissions',
		component: <AccountPermissions editedAccount={editedAccount} />,
	},
	{
		label: 'misc.accountType',
		component: <AccountType editedAccount={editedAccount} />,
	},
]

export default getModalContentConfig
