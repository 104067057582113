import dayjs from 'dayjs'
import { dayjsMethod } from '../../../../../utils/date'

export const formatDateRange = (argumentQuerieName, startDate, endDate) => {
	const isSameDay = dayjsMethod(startDate).isSame(endDate, 'day')

	const valuesDate =
		argumentQuerieName === 'startFrom'
			? {
					startFrom: dayjs(startDate).toDate(),
					endTo: isSameDay
						? dayjs(startDate).endOf('day').toDate()
						: dayjs(endDate).toDate(),
			  }
			: {
					[argumentQuerieName]: [
						{
							after: dayjs(startDate).toDate(),
							before: isSameDay
								? dayjs(startDate).endOf('day').toDate()
								: dayjs(endDate).toDate(),
						},
					],
			  }

	return valuesDate
}

export const setDateRangeDefaultValue = (
	argumentQuerieName,
	filtersQuerieParam,
	setDateRange
) => {
	// undefined is added to the condition to avoid the error "Cannot read property '0' of undefined"
	// It means that if filtersQuerieParam[argumentQuerieName] is undefined, then filtersQuerieParam can be set without causing a bug
	const startDate =
		argumentQuerieName === 'startFrom'
			? filtersQuerieParam?.startFrom || new Date()
			: filtersQuerieParam[argumentQuerieName] === undefined
			? undefined
			: filtersQuerieParam[argumentQuerieName][0]?.after

	const endDate =
		argumentQuerieName === 'startFrom'
			? filtersQuerieParam.endTo || new Date()
			: filtersQuerieParam[argumentQuerieName] === undefined
			? undefined
			: filtersQuerieParam[argumentQuerieName][0]?.before

	const isSameDay = dayjsMethod(startDate).isSame(endDate, 'day')

	setDateRange(
		argumentQuerieName === 'startFrom'
			? [
					{
						startDate: dayjs(startDate).toDate(),
						endDate: isSameDay
							? dayjs(startDate).endOf('day').toDate()
							: dayjs(endDate).toDate(),
						key: 'selection',
					},
			  ]
			: [
					{
						startDate: dayjs(startDate).toDate(),
						endDate: isSameDay
							? dayjs(startDate).endOf('day').toDate()
							: dayjs(endDate).toDate(),
						key: 'selection',
					},
			  ]
	)
}
