import React, { useCallback } from 'react'

import { Typography, ListSubheader, List, Popover } from '@mui/material'

import { useFormatMessage } from '../../../../utils/hooks'
import UserAvatar from '../../../avatar/UserAvatar'

interface IMembersPopOver {
	id?
	anchorEl?
	setAnchorEl?
	data?
	displayManager?
	zIndexPopover?
	title?: string
}

const MembersPopOver: React.FC<IMembersPopOver> = ({
	id,
	anchorEl,
	setAnchorEl,
	data,
	displayManager = true,
	zIndexPopover,
	title = 'misc.operators',
}) => {
	const intlMsg = useFormatMessage()

	const handleClose = useCallback(() => setAnchorEl(null), [])
	const open = Boolean(anchorEl)
	const popOverId = open ? id : undefined

	const managerIsNotDefine = !data.find((item) => item.isManager === true)

	return (
		<>
			<Popover
				id={`${popOverId}-members`}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				transformOrigin={{ vertical: 'top', horizontal: 'center' }}
				sx={{ zIndex: zIndexPopover || 1200 }}
			>
				<List dense>
					{displayManager && (
						<ListSubheader component='div' sx={{ p: 2, pb: 1, pt: 1 }}>
							<Typography
								variant='body2'
								display='block'
								sx={{ fontWeight: 800 }}
							>
								{intlMsg('misc.teamManager')}
							</Typography>
						</ListSubheader>
					)}

					{displayManager &&
						data.map((value, i) => {
							return (
								value.isManager && (
									<UserAvatar
										key={i}
										userName={{
											firstName: value.firstName,
											lastName: value.lastName,
										}}
										userId={value.id}
										accountId={value?.account?.id}
										displayName
										isListItemButton
									/>
								)
							)
						})}

					{managerIsNotDefine && displayManager && (
						<Typography
							variant='caption'
							display='block'
							gutterBottom
							align='center'
						>
							{intlMsg('misc.teamNotDefine')}
						</Typography>
					)}

					<ListSubheader component='div' sx={{ p: 2, pb: 1, pt: 1 }}>
						<Typography
							variant='body2'
							display='block'
							sx={{ fontWeight: 800 }}
						>
							{intlMsg(title)}
						</Typography>
					</ListSubheader>

					{data.map((value, i) => {
						return !value.isManager ? (
							<UserAvatar
								key={i}
								userName={{
									firstName: value.firstName,
									lastName: value.lastName,
								}}
								userId={value.id}
								accountId={value?.account?.id}
								displayName
								isListItemButton
							/>
						) : (
							''
						)
					})}
				</List>
			</Popover>
		</>
	)
}

export default MembersPopOver
