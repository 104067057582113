import { gql, useMutation } from '@apollo/client'

export const UPDATE_INVOICE = gql`
	mutation updateInvoice($input: updateInvoiceInput!) {
		updateInvoice(input: $input) {
			invoice {
				id
			}
		}
	}
`

export const CREATE_INVOICE = gql`
	mutation createInvoice($input: createInvoiceInput!) {
		createInvoice(input: $input) {
			invoice {
				id
			}
		}
	}
`

export const DELETE_INVOICE = gql`
	mutation deleteInvoice($input: deleteInvoiceInput!) {
		deleteInvoice(input: $input) {
			invoice {
				id
			}
		}
	}
`
export const REMOVE_INVOICE = gql`
	mutation removeInvoice($input: removeInvoiceInput!) {
		removeInvoice(input: $input) {
			invoice {
				id
			}
		}
	}
`

export const BULK_INVOICE_ACTION = gql`
	mutation bulkOperationInvoice($input: bulkOperationInvoiceInput!) {
		bulkOperationInvoice(input: $input) {
			invoice {
				id
			}
		}
	}
`

export const useUpdateInvoice = () => useMutation(UPDATE_INVOICE)
export const useCreateInvoice = () => useMutation(CREATE_INVOICE)
export const useDeleteInvoice = (): any => useMutation(DELETE_INVOICE)
export const useRemoveInvoice = (): any => useMutation(REMOVE_INVOICE)
export const useBulkInvoiceAction = (): any => useMutation(BULK_INVOICE_ACTION)
