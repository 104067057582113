import React from 'react'

import { Route, Routes } from 'react-router-dom'
import LayoutLogin from '../components/layout/LayoutLogin'
import { ROUTES } from './consts'
import Administration from '../view/administration'
import Dashboard from '../view/dashboard'
import ViewModalAlert from '../view/viewModalAlert'
import CrudModal from '../components/crudModal'
import AdminPanel from '../view/adminPanel'
import ViewModalProvider from '../view/viewModal/ViewModalProvider'

const AppRoutes: React.FC = () => {
	return (
		<LayoutLogin>
			<CrudModal />
			<ViewModalProvider />
			<Routes>
				<Route path={ROUTES.ADMINISTRATION.path} element={<Administration />} />
				<Route path={ROUTES.ADMIN_PANEL.path} element={<AdminPanel />} />
				<Route
					path={ROUTES.VEHICLES.FUSION.path}
					element={<ViewModalAlert />}
				/>

				<Route path={ROUTES.HOME.path} element={<Dashboard />} />
			</Routes>
		</LayoutLogin>
	)
}

export default AppRoutes
