import React, { useContext, useState } from 'react'

import { Grid, Box } from '@mui/material'
import { ETransportTripType } from '../../../../utils/app-models'
import WTransportTripSummary from '../../../../components/widget/WTransportTripSummary'
import { ViewModalContentContext } from '../../../../utils/contexts/ViewModalContentContext'
import WTransportTripSummaryCustomerPart from '../../../../components/widget/WTransportTripSummary/WTransportTripSummaryCustomerPart'
import WTransportTripSummaryDriverPart from '../../../../components/widget/WTransportTripSummary/WTransportTripSummaryDriverPart'
import WTransportTripDateSteps from '../../../../components/widget/WTransportTripSummary/WTransportTripDateSteps'
interface IInterventionTransportDashboard {}

const InterventionTransportDashboard: React.FC<
	IInterventionTransportDashboard
> = () => {
	const {
		viewModalData: { interventionTransportTrips: itTrip, forms },
	} = useContext(ViewModalContentContext) || {}
	const [maxHeight, setMaxHeight] = useState(0)

	return (
		<Box p={2}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={12} xl={12}>
					<WTransportTripDateSteps
						removal={itTrip[0]}
						delivery={itTrip[1]}
						forms={forms?.collection}
					/>
				</Grid>
				<Grid item xs={12} md={6} xl={4}>
					<WTransportTripSummary
						itTrip={itTrip[0]}
						transportTripType={ETransportTripType.removal}
						maxHeightUtils={{ maxHeight, setMaxHeight }}
					/>
				</Grid>
				<Grid item xs={12} md={6} xl={4}>
					<WTransportTripSummary
						itTrip={itTrip[1]}
						transportTripType={ETransportTripType.delivery}
						maxHeightUtils={{ maxHeight, setMaxHeight }}
					/>
				</Grid>

				<Grid item xs={12} md={12} xl={4}>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6} xl={12}>
							<WTransportTripSummaryCustomerPart />
						</Grid>
						<Grid item xs={12} md={6} xl={12}>
							<WTransportTripSummaryDriverPart />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	)
}

export default InterventionTransportDashboard
