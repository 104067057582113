export default {
	HClist1Strong: 'Planning des interventions ',
	HClist1: 'par intervenant',

	HClist2Strong: "Création d'interventions ",
	HClist2: 'à la volée',

	HClist3Strong: 'Création de nouveaux véhicules  ',
	HClist3: 'sur le terrain',

	HClist4Strong: "Recueil d’alertes d'état ",
	HClist4: 'depuis les formulaires',

	HClist5Strong: 'Prise de photos avec cerclage et annotation',

	HClist6Strong: 'Interventions horodatées et géolocalisées',

	downloadAndroid: 'Téléchargez l’appli sur Android',
	downloadIos: 'Téléchargez l’appli sur IOS',

	subscribe:
		"Abonnez-vous à HubeeConnect Premium pour bénéficier de l'application mobile HubeeCheck et profitez de 15 jours d'essai gratuit et sans engagement ! Plus d'informations : ",
}
