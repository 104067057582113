export const getInvoicesListAttributes = `
collection {
    id
    reference
    vat
    totalAmountET
    dateCreated
    dueDate
    file
    createdBy {
      id
      lastName
      firstName
    }
    intervention {
      id
      reference
      interventionType
    }
    account {
      id
      name
    }
    invoiceVehicles(itemsPerPage: 100000) {
      collection {
        id
        vehicle {
          id
          vehicleDetail {
            id
            immat
            vin
          }
        }
      }
    }
  }
  paginationInfo {
    totalCount
  }
`

export const invoiceForModalAttributes = `
id
reference
vat
totalAmountET
dateCreated
dueDate
file
invoiceVehicles(itemsPerPage: 99999) {
  collection {
    id
    vehicle {
      id
      vehicleDetail {
        id
        immat
        vin
      }
    }
  }
}
intervention {
  id
  reference
}
`
