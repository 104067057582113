import React, { useContext } from 'react'

import {
	Alert,
	Box,
	Card,
	Divider,
	Rating,
	Stack,
	Typography,
} from '@mui/material'
import { useFormatMessage } from '../../../utils/hooks'
import { ViewModalContentContext } from '../../../utils/contexts/ViewModalContentContext'
import ShowMoreTxt from '../../showMoreTxt'
import { grey } from '@mui/material/colors'
import translateIntlMsgString from '../../../utils/translateIntlMsgString'

const alertCSS = {
	boxShadow: '0 0 11px -4px rgba(0,0,0,0.19)',
	color: '#663d00',
	backgroundColor: '#fdf4e7',
	fontWeight: 'bold',
	'& svg': { color: '#ff9800' },
}

const titleCSS = { fontWeight: 700, fonSize: '20px' }
const labelSignatureCSS = {
	fontSize: '10px',
	position: 'absolute',
	p: '5px',
	top: '0px',
	left: '0px',
}
const imgSignatureCSS = { maxHeight: '45px' }
const containerSignatureCSS = {
	backgroundColor: '#FAFAFA',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}

const WTransportTripSummaryCustomerPart = () => {
	const intlMsg = useFormatMessage()
	const {
		viewModalData: { endingForm },
	} = useContext(ViewModalContentContext) || {}

	return endingForm?.value?.client?.clientSkipped ? (
		<Alert severity='info' sx={alertCSS}>
			{translateIntlMsgString('interventions.missingRecipient')}
		</Alert>
	) : (
		<>
			<Card sx={{ height: 1 }}>
				<Stack spacing={2} p={2}>
					<Typography variant='h3' sx={titleCSS}>
						{intlMsg(`misc.recipient`)}
					</Typography>

					<Divider variant='middle' />
					<Typography variant='body2' sx={{ color: grey[600] }}>
						{intlMsg(`misc.comment2`)}
					</Typography>

					{endingForm?.value?.client?.comment ? (
						<ShowMoreTxt txt={endingForm.value.client.comment} maxSize={150} />
					) : (
						'-'
					)}

					<Divider variant='middle' />

					<Stack spacing={8} direction='row' width='100%'>
						<Box sx={{ width: '50%' }}>
							<Box
								height={63}
								sx={containerSignatureCSS}
								borderRadius={1}
								position='relative'
							>
								<Box sx={labelSignatureCSS}>Signature</Box>
								{endingForm?.value?.client?.signature && (
									<img
										src={endingForm.value.client.signature}
										style={imgSignatureCSS}
										onError={(e) => {
											const imgElement = e.target as HTMLImageElement
											imgElement.onerror = null
											imgElement.src =
												'data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="1" height="1" fill-opacity="0"></svg>'
										}}
									/>
								)}
							</Box>
						</Box>
						<Box>
							<Typography variant='body2' sx={{ color: grey[600] }}>
								{intlMsg(`misc.note`)}
							</Typography>
							<Rating
								value={endingForm?.value?.client?.grade || 0}
								precision={0.5}
								readOnly
								disabled={!endingForm?.value?.client?.grade}
							/>
						</Box>
					</Stack>
				</Stack>
			</Card>
		</>
	)
}

export default WTransportTripSummaryCustomerPart
