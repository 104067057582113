/* eslint-disable react/display-name */
import React, { memo, useEffect, useMemo, useState } from 'react'

import { useQuery } from '@apollo/client'
import { ViewModalContentContext } from '../utils/contexts/ViewModalContentContext'

interface IViewModalContentProvider {
	children: any
	viewModalInfos: any
}

const ViewModalContentProvider: React.FC<IViewModalContentProvider> = memo(
	({ children, viewModalInfos }) => {
		const {
			viewModalConfig: { queryConfig },
		} = viewModalInfos
		const [viewModalData, setViewModalData] = useState<any>(null)
		const headerConfig = useMemo(
			() => viewModalInfos.viewModalConfig.headerConfig,
			[viewModalInfos.viewModalConfig.headerConfig]
		)
		const { data: queryData, loading } = useQuery(queryConfig?.query, {
			variables: queryConfig?.variables,
			fetchPolicy: 'cache-and-network',
		})

		useEffect(() => {
			if (!loading && queryData) setViewModalData(queryData[queryConfig.name])
		}, [queryData, loading, queryConfig])

		const context = useMemo(
			() => ({
				viewModalInfos,
				viewModalData,
				loading,
				setViewModalData,
				headerConfig,
			}),
			[viewModalInfos, viewModalData, loading, headerConfig]
		)

		return (
			<ViewModalContentContext.Provider value={context}>
				{children}
			</ViewModalContentContext.Provider>
		)
	}
)
export default ViewModalContentProvider
