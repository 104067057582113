/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback } from 'react'

import toast from 'react-hot-toast'
import {
	useCreateContact,
	useUpdateContact,
} from '../../../../api/gql/mutations/customerContactMutation'
import { useLazyContactForModal } from '../../../../api/gql/queries/contactsQueries'
import { useLazyCustomerForModalSelectInitialisation } from '../../../../api/gql/queries/customersQueries'
import { useLazySite } from '../../../../api/gql/queries/sitesQueries'
import customToast from '../../../../components/alert/customToast'

import FormModal from '../../../../components/forms/formModal'
import { EFormModalMode } from '../../../../utils/app-models'
import { IModalUtils, useFormatMessage } from '../../../../utils/hooks'
import customerContactModalConfig from './customerContactModalConfig'
import {
	formatCustomerContactModalCreateDefaultValues,
	formatCustomerContactModalEditDefaultValues,
	formatCustomerContactModalOnValidateData,
} from './customerContactModalUtils'
import qs from 'qs'

interface ICustomerContactModal {
	contactId?: string | null
	modalUtils: IModalUtils
	mode?: EFormModalMode
	onClose?: any
	forcedCustomerId?: string
	forcedSiteId?: string
	setCreatedContact?: (value: any) => void
}

const CustomerContactModal: React.FC<ICustomerContactModal> = ({
	modalUtils,
	mode,
	contactId,
	onClose,
	forcedCustomerId,
	forcedSiteId,
	setCreatedContact,
}) => {
	const intlMsg = useFormatMessage()
	const [createContact] = useCreateContact()
	const [updateContact] = useUpdateContact()

	const { getContact, contact: oldContact } = useLazyContactForModal()
	const { getCustomer } = useLazyCustomerForModalSelectInitialisation()
	const { getSite } = useLazySite()

	const queriesObject: any = {
		customer: getCustomer,
		site: getSite,
	}

	const onValidate = useCallback(
		async ({ data, setValidationLoading, closeModal }) => {
			setValidationLoading(true)

			const mutate =
				mode === EFormModalMode.edit ? updateContact : createContact

			await mutate({
				variables: {
					input: formatCustomerContactModalOnValidateData({
						data,
						mode,
						contactId,
					}),
				},
				refetchQueries: [
					'getContactsList',
					'getContactsNumbers',
					'getContactListsForSelect',
					'getContact',
					'getInfosContactSlidePanel',
				],
			})
				.then((res) => {
					setCreatedContact &&
						setCreatedContact(res?.data?.createContact?.contact)
					mode === EFormModalMode.edit
						? toast.success('success.contactUpdate')
						: toast.success('success.contactCreate')

					// Send toast notification only when user canAccessMyContent during the creation or if user update if canAccessMyContent to true
					if (
						(mode === EFormModalMode.edit &&
							!oldContact?.canAccessMyContent &&
							data?.canAccessMyContent) ||
						(data?.canAccessMyContent && !oldContact)
					) {
						customToast('sendEmail', 'success.contactIsShare')
					}

					setValidationLoading(false)
					closeModal()

					// redirectOnClose && navigate(location.pathname)
				})
				.catch(() => setValidationLoading(false))
		},
		[contactId, mode, oldContact]
	)

	const initializeData = useCallback(
		async ({ setValues, setLoading }) => {
			if (mode === EFormModalMode.edit) {
				setLoading(true)
				getContact({ variables: { id: contactId } })
					.then((res) => {
						const data = res?.data?.contact
						if (data) {
							setValues(formatCustomerContactModalEditDefaultValues(data))
						}
						setLoading(false)
					})
					.catch(() => setLoading(false))
			} else {
				setLoading(true)

				let dataPreFilling = null
				let isSite = false
				const { query, varValue } = qs.parse(location.search, {
					ignoreQueryPrefix: true,
				})

				if (mode === EFormModalMode.create && query) {
					const result = await queriesObject[query as string]({
						variables: { id: varValue },
					})
					dataPreFilling = result?.data[query as string]
					isSite = query === 'site'
				}

				const defaultValues =
					await formatCustomerContactModalCreateDefaultValues({
						dataPreFilling,
						isSite,
						forcedCustomerId,
						forcedSiteId,
						getCustomer,
						getSite,
					})
				setValues(defaultValues)
				setLoading(false)
			}
		},
		[mode, contactId, forcedCustomerId, forcedSiteId]
	)

	return (
		<FormModal
			modalUtils={modalUtils}
			formModalTabConfig={customerContactModalConfig}
			formModalName={intlMsg(
				mode === EFormModalMode.edit
					? 'administration.editContact'
					: 'administration.createContact'
			)}
			initialMode={mode}
			onValidate={onValidate}
			validateBtnText={intlMsg('misc.validate')}
			cancelBtnText={intlMsg('misc.cancel')}
			initializeData={initializeData}
			onClose={onClose}
			maxWidth='1100px'
			minHeight='30px'
		/>
	)
}

export default CustomerContactModal
