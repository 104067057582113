import React from 'react'
import CustomerAccountModalInfo from './CustomerAccountModalInfo'
import CustomerAccountModalValidationSchema from './customerAccountModalValidationSchema'

const customerAccountsModalConfig = [
	{
		component: <CustomerAccountModalInfo />,
		schema: CustomerAccountModalValidationSchema,
	},
]

export default customerAccountsModalConfig
