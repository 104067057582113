import React from 'react'

import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { Divider, Box, Stack } from '@mui/material'

import { ListItemsData } from '../../../../components'
import { listCardViewModalCSS as css } from '../../../../styles/css/viewModal'

interface IInformationsVehicle {
	vehicleBrand
	vin
	immat
	designation
	vehicleModel
}

const InformationsVehicle: React.FC<IInformationsVehicle> = ({
	vehicleBrand,
	vin,
	immat,
	designation,
	vehicleModel,
}) => {
	return (
		<Paper sx={css.radius}>
			<Box sx={{ mb: 2 }}>
				<Stack
					direction='row'
					alignItems='center'
					justifyContent='space-between'
					sx={css.space}
				>
					<Typography variant='h3'>{immat || '-'}</Typography>

					<ListItemsData
						nbCol={12}
						items={[
							{
								primaryText: 'vehicle.vin',
								secondaryText: vin || '-',
							},
						]}
					/>
				</Stack>
				<Divider />
				<Box sx={css.spaceRightLeft}>
					<ListItemsData
						nbCol={6}
						items={[
							{
								primaryText: 'vehicle.brand',
								secondaryText: vehicleBrand || '-',
							},
							{
								primaryText: 'vehicle.model',
								secondaryText: vehicleModel || '-',
							},
							{
								primaryText: 'vehicle.designation',
								secondaryText: designation || '-',
								sizeColumn: 12,
							},
						]}
					/>
				</Box>
			</Box>
		</Paper>
	)
}

export default InformationsVehicle
