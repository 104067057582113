import React, { useCallback, useContext, useEffect } from 'react'

import toast from 'react-hot-toast'
import { Typography, Stack, Alert, Box } from '@mui/material'

import Modal from '../../forms/v1'
import { ModalContext } from '../../../utils/contexts/ModalContext'
import { useFormatMessage } from '../../../utils/hooks'
import InputText from '../../forms/v1/formFields/InputText'
import { textBoldCSS } from '../../../styles/sxCSS/_common'
import { useWCreateMileage } from '../../../api/gql/mutations/vehicleMutation'
import { formatDate } from '../../../utils/date'
import { AuthContext } from '../../../utils/contexts/AuthContext'
import UserAvatar from '../../avatar/UserAvatar'

interface IUpdateMileageModal {
	vehicleId?: string
	previousMileage: number
	immatOrVin?: string
}

const todayFormated = formatDate(new Date())

const UpdateMileageModal: React.FC<IUpdateMileageModal> = ({
	vehicleId,
	previousMileage,
	immatOrVin = '',
}) => {
	const intlMsg = useFormatMessage()
	const { currentUser } = useContext(AuthContext)
	const {
		isVisible,
		closeModal,
		resetContext,
		setLoadingModal,
		formUtils: { control, setValue },
	} = useContext(ModalContext)

	const [createVehicleMileage] = useWCreateMileage()

	const handleCloseModal = useCallback(() => {
		resetContext()
		closeModal()
	}, [resetContext])

	const validateModal = useCallback(async (data) => {
		setLoadingModal(true)

		await createVehicleMileage({
			variables: {
				input: {
					...data,
				},
			},
			refetchQueries: ['getWVehicleMileages'],
		})
			.then(() => {
				toast.success('success.mileageCreate')
				setLoadingModal(false)
				resetContext()
				closeModal()
			})
			.catch(() => setLoadingModal(false))
	}, [])

	useEffect(() => {
		setValue('vehicle', vehicleId)
	}, [])

	return (
		<Modal
			isVisible={isVisible}
			closeModal={handleCloseModal}
			modalName={`${intlMsg('vehicle.mileageImmat')} ${immatOrVin}`}
			customModalName
			validateModal={validateModal}
			maxWidth={370}
			minHeight='50px'
		>
			<Stack spacing={2}>
				{previousMileage && (
					<Alert severity='info'>
						<Typography variant='caption'>
							{intlMsg('widget.previousMileage') + ' : '}
							<Box component='span' sx={textBoldCSS}>
								{previousMileage}
							</Box>
							{' KM'}
						</Typography>
					</Alert>
				)}
				<InputText
					name='mileage'
					label={intlMsg('vehicle.mileage')}
					control={control}
					type='number'
				/>

				<Stack direction='row' justifyContent='space-between' spacing={3}>
					<Stack spacing={1}>
						<Typography>{intlMsg('vehicle.dateAdd')} :</Typography>
						<Typography sx={textBoldCSS}>{todayFormated}</Typography>
					</Stack>
					<Stack>
						<Typography>{intlMsg('vehicle.by')} :</Typography>
						<UserAvatar
							userName={{
								firstName: currentUser?.firstName,
								lastName: currentUser?.lastName,
							}}
							displayName
						/>
					</Stack>
				</Stack>
			</Stack>
		</Modal>
	)
}

export default UpdateMileageModal
