/* eslint-disable no-case-declarations */
/* eslint-disable react/react-in-jsx-scope */
import dayjs from 'dayjs'

import { EInterventionTypes, EViewPeriod } from '../../../utils/app-models'
import { getArrOfUniqueObjKey } from '../../../utils/string'

export const formatDateFormView = (date: 'Date', view: string): string => {
	switch (view) {
		case EViewPeriod.month:
			return dayjs(date).format('MMMM YYYY')

		case EViewPeriod.week:
			return `${dayjs(date).startOf('week').format('DD-')}${dayjs(date)
				.endOf('week')
				.format('DD MMMM YYYY')}`

		case EViewPeriod.day:
			return dayjs(date).format('DD MMMM YYYY')

		default:
			return ''
	}
}

export const defaultCalendarFilter = {
	interventionType: 'all',
	isWithoutActor: false,
	actors: [],
	customers: [],
}

export const getMultiSelectValuesDefault: any = ({
	key,
	array,
	localFilters,
}) => {
	const defaultValues: any = []

	localFilters?.[key].forEach((id) => {
		const obj = array?.find((elem) => elem.id === id)

		if (obj)
			defaultValues.push({
				id: obj.id,
				name: key === 'actors' ? `${obj.firstName} ${obj.lastName}` : obj.name,
				value: obj.id,
			})
	})
	return defaultValues
}

/////////////////////////////////////////////////////////
// Search
/////////////////////////////////////////////////////////

export const calendarSearchKey = [
	'orderingCustomer',
	'orderingSite',
	'interventionType',
	'actors',
	'orderingContact',
	'agence',
	'reference',
	'interventionControlProgrammedVehicles',
	'interventionTransportTrips',
	'agencies',
]

const pushToRefInterventions = (searchedEventIntervention, event) => {
	searchedEventIntervention.push({
		ref: event.title,
		start: dayjs(event.start).format('DD'),
	})
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const searchEventsIntervention = ({ searchText, events }) => {
	const searchedEventIntervention: any = []

	events.forEach((event) => {
		let c1 = -1
		const eventEntries: any = Object.entries(event?.resource)
		const lowerSearchText = searchText.toLowerCase()

		while (++c1 < eventEntries?.length) {
			const key = eventEntries[c1][0]
			const value = eventEntries[c1][1]

			if (calendarSearchKey.find((searchKey) => searchKey === key)) {
				switch (key) {
					case 'actors':
						let actorsCpt = -1
						while (++actorsCpt < value?.length) {
							const fullName = `${value[actorsCpt]?.firstName} ${value[actorsCpt]?.lastName}`
							if (fullName?.toLowerCase()?.indexOf(lowerSearchText) !== -1) {
								pushToRefInterventions(searchedEventIntervention, event)
							}
						}
						break

					case 'interventionControlProgrammedVehicles':
						let immatCpt = -1
						while (++immatCpt < value?.length)
							if (
								value?.[immatCpt]?.vehicle?.vehicleDetail?.immat &&
								value[immatCpt].vehicle.vehicleDetail.immat
									?.toLowerCase()
									?.indexOf(lowerSearchText) !== -1
							) {
								pushToRefInterventions(searchedEventIntervention, event)
							}
						break

					case 'orderingSite':
					case 'orderingCustomer':
						if (
							value?.name &&
							value.name.toLowerCase().indexOf(lowerSearchText) !== -1
						) {
							pushToRefInterventions(searchedEventIntervention, event)
						}
						break

					case 'orderingContact':
						const fullName = `${value?.firstName} ${value?.lastName}`
						if (fullName.toLowerCase().indexOf(lowerSearchText) !== -1) {
							pushToRefInterventions(searchedEventIntervention, event)
						}
						break

					case 'reference':
					case 'interventionType':
						if (value === EInterventionTypes.control) {
							if ('inspection'.toLowerCase().indexOf(lowerSearchText) !== -1) {
								pushToRefInterventions(searchedEventIntervention, event)
							}
						} else if (value.toLowerCase().indexOf(lowerSearchText) !== -1) {
							pushToRefInterventions(searchedEventIntervention, event)
						}
						break

					case 'interventionTransportTrips':
						const cities = value
							.map((item) => item?.city?.toLowerCase())
							.filter(Boolean)

						if (cities?.some((city) => city.indexOf(lowerSearchText) !== -1)) {
							pushToRefInterventions(searchedEventIntervention, event)
						}
						break

					case 'agencies':
						if (
							value?.filter(
								({ agencyCustomDetails }) =>
									agencyCustomDetails?.[0]?.agencyName
										?.toLowerCase()
										?.indexOf(lowerSearchText) !== -1
							)?.length
						) {
							pushToRefInterventions(searchedEventIntervention, event)
						}

						break

					default:
						break
				}
			}
		}
	})

	return searchedEventIntervention
}

///////////////////////////////////////////////////////////////////////////////////
// Change opacity of btn viewMore
///////////////////////////////////////////////////////////////////////////////////

const addOrRemoveBgColor = (
	uniqueSelectedInter,
	allBtnShowMore,
	action: 'add' | 'remove'
) => {
	for (const btn of allBtnShowMore) {
		const index = [
			...btn.parentElement.parentElement.parentElement.children,
		].indexOf(btn.parentElement.parentElement)

		const date =
			btn.parentElement.parentElement.parentElement.parentElement.children[0]
				.children[index].innerText

		const matchDate = uniqueSelectedInter.some(({ start }) => start === date)

		action === 'add'
			? (btn.style.opacity = !matchDate ? '0.1' : '1')
			: (btn.style.opacity = '1')
	}
}

export const changeViewMoreOpacityIfMatchWithSearch = (
	selectedInter,
	currentView,
	loadingInterventions,
	previousSelectedBtnDom,
	setPreviousSelectedBtnDom,
	searchText
) => {
	// add opacity
	if (
		selectedInter?.length &&
		currentView === 'month' &&
		!loadingInterventions
	) {
		const allBtnShowMore = document.querySelectorAll(
			'.rbc-button-link :not([rbc-show-more])'
		) as any
		const uniqueSelectedInter = getArrOfUniqueObjKey(selectedInter, 'start')
		setPreviousSelectedBtnDom({ allBtnShowMore, uniqueSelectedInter })

		addOrRemoveBgColor(uniqueSelectedInter, allBtnShowMore, 'add')
	}
	// remove opacity
	else if (currentView === 'month' && previousSelectedBtnDom) {
		addOrRemoveBgColor(
			previousSelectedBtnDom.uniqueSelectedInter,
			previousSelectedBtnDom.allBtnShowMore,
			'remove'
		)
		setPreviousSelectedBtnDom(null)
	}
	// nothing match with search
	else if (currentView === 'month' && !selectedInter?.length) {
		const allBtnShowMore = document.querySelectorAll(
			'.rbc-button-link :not([rbc-show-more])'
		) as any
		for (const btn of allBtnShowMore) {
			btn.style.opacity = searchText?.length ? '0.1' : '1'
		}
	}
}

///////////////////////////////////////////////////////////////////////////////////
// Format interventions and set events
///////////////////////////////////////////////////////////////////////////////////

export const formatInterventionsAndSetEvents = (
	interventions,
	currentView,
	setEvents
) => {
	if (interventions) {
		const events: any = []

		interventions.forEach((intervention) => {
			const {
				reference,
				dateFrom,
				dateTo,
				allDay,
				orderingCustomer,
				interventionType,
				status,
				actors,
				orderingSite,
				orderingContact,
				interventionControlProgrammedVehicles,
				id,
				interventionTransportTrips,
				agencies,
				interventionTransportType,
			} = intervention

			events.push({
				currentView: currentView,
				title: reference,
				start: dayjs(dateFrom).toDate(),
				end: dayjs(dateTo).toDate(),
				allDay: allDay || false,
				resource: {
					id,
					orderingCustomer,
					interventionType,
					status,
					actors: actors?.collection || [],
					agencies: agencies?.collection || [],
					orderingSite,
					orderingContact,
					reference,
					interventionTransportTrips,
					interventionControlProgrammedVehicles:
						interventionControlProgrammedVehicles?.collection || [],
					interventionTransportType,
				},
			})
		})

		setEvents(
			events.sort((a, b): any => {
				const dateA: any = new Date(a.start)
				const dateB: any = new Date(b.start)
				return dateA - dateB
			})
		)
	}
}

/////////////////////////////////////////////////////////
// RESIZE CARD ON HOVER
/////////////////////////////////////////////////////////
const getFirstParentWithClass = (oElement, clsName) => {
	if (!oElement.parentElement) {
		return null
	} else if (
		oElement.parentElement.className &&
		oElement.parentElement.className.split(' ').indexOf(clsName) !== -1
	) {
		return oElement.parentElement
	} else {
		return getFirstParentWithClass(oElement.parentElement, clsName)
	}
}

export const resizePlanningCard = (setSelectorCard, setInheritStyle, e) => {
	const selectorCard = getFirstParentWithClass(e.target, 'rbc-event')
	if (selectorCard) {
		const style = selectorCard.attributes.style.nodeValue
		setSelectorCard(selectorCard)
		setInheritStyle(style)
		selectorCard.style.cssText =
			style +
			' width: calc(100% + 110px); min-height:80px; z-index:900; transition: all 0.1s ease-in; transition-delay: 250ms;'
	}
}

export const resetPlanningCardSize = (selectorCard, inheritStyle) => {
	if (selectorCard) selectorCard.style.cssText = inheritStyle
}
