// Size image full width
const widthImgAnnotation = 1083.7333333 / 7
const heightImgAnnotation = 812.8 / 8

// Size image signature
const widthImgSignature = 25
const heightImgSignature = 12

// to calculate the size of an empty character-> doc.getTextDimensions(' ')
const spaceWidth = 0.8890000000000001

// Global property style table
const margin = 30
const fontSizeTile = 11
const fontSizeBody = 9
const primaryBgColor = '#f5f5f5'
const stylePropertyBlockTable = {
	styles: {
		lineColor: primaryBgColor,
		lineWidth: 0,
	},

	bodyStyles: {
		fillColor: primaryBgColor,
		textColor: 'black',
	},
	alternateRowStyles: {
		fillColor: primaryBgColor,
	},
}
const stylePropertyAdditionalFooter = {
	styles: {
		lineColor: 'white',
		lineWidth: 0,
	},

	bodyStyles: {
		fillColor: 'white',
		textColor: 'black',
	},
	alternateRowStyles: {
		fillColor: 'white',
	},
}

export const globalConfigPDF = {
	widthImgAnnotation,
	heightImgAnnotation,
	margin,
	fontSizeTile,
	fontSizeBody,
	primaryBgColor,
	stylePropertyBlockTable,
	widthImgSignature,
	heightImgSignature,
	spaceWidth,
	stylePropertyAdditionalFooter,
}
