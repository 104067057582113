import React, {
	Context,
	useCallback,
	useContext,
	useMemo,
	useState,
	memo,
} from 'react'

import { Box, Typography, Popover } from '@mui/material'

import getNestedObjectFromKey from 'lodash.get'

import SearchField from '../../../searchField/SearchField'
import {
	useDebouncedState,
	useFormatMessage,
	useModal,
} from '../../../../utils/hooks'
import { IStepperContext } from '../../formStepper/StepperContext'
import { IFormModalContext } from '../../formModal/FormModalContext'
import OptionList from './OptionList'
import { formMultiSelectSearchCSS, mainCSS } from './formMultiSelectSearchCSS'
import FormMultiSelectSearchButton from './FormMultiSelectSearchButton'

interface IFormMultiSelectSearch {
	name: string
	context: Context<IFormModalContext> | Context<IStepperContext>
	label: string
	options: Array<{
		key: string
		label: string
		value: {
			name: string
			categoryName: string
		}
	}>
	isRequired?: boolean
	isDisabled?: boolean
	groupBy?: string
	selectAllText: string
	trigerred?: boolean
	specialOnChange?: any
	width?: string
	bordered?: boolean
	missingText?: string
	messageTopModal?: string
	nbChipsToDisplay?: number
	renderChipsLabel?: any
	maxWidth?: string
}

const FormMultiSelectSearch: React.FC<IFormMultiSelectSearch> = ({
	name,
	context,
	label,
	options,
	isRequired,
	isDisabled = false,
	groupBy,
	selectAllText,
	trigerred = false,
	specialOnChange = null,
	width = 'auto',
	bordered,
	missingText,
	messageTopModal = '',
	nbChipsToDisplay = 1,
	renderChipsLabel = null,
	maxWidth = 'unset',
}) => {
	const intlMsg = useFormatMessage()
	const { isVisible, openModal, closeModal } = useModal(false)
	const [seeSelected, setSeeSelected] = useState(false)
	const { errors, data, triggerError } = useContext<
		IFormModalContext | IStepperContext
	>(context as any)
	const [debouncedState, setDebouncedState] = useDebouncedState('')
	const [anchor, setAnchor] = React.useState<HTMLButtonElement | null>(null)
	const values = useMemo(() => data[name] || {}, [data[name]])
	const error = useMemo(() => errors[name] || null, [errors[name]])

	const sortedOptions = useMemo(() => {
		const lowerDebouncedState = debouncedState?.toLowerCase()
		const filteredOptions = seeSelected
			? options?.filter((option) => values?.[option.key]?.checked)
			: options

		const groupBySort = groupBy
			? (a, b) =>
					getNestedObjectFromKey(a.value, groupBy)
						.toLowerCase()
						.localeCompare(
							getNestedObjectFromKey(b.value, groupBy)?.toLowerCase()
						)
			: null

		const alphabeticalSort = (a, b) =>
			a?.label?.toLowerCase().localeCompare(b.label?.toLowerCase())

		const combinedSort = groupBySort
			? (a, b) => groupBySort(a, b) || alphabeticalSort(a, b)
			: alphabeticalSort

		const filteredAndSortedOptions = filteredOptions?.sort(combinedSort)

		return lowerDebouncedState
			? filteredAndSortedOptions.filter(
					(option) =>
						option.label?.toLowerCase().includes(lowerDebouncedState) ||
						option.value.categoryName
							?.toLowerCase()
							.includes(lowerDebouncedState)
			  )
			: filteredAndSortedOptions
	}, [debouncedState, seeSelected, values, options, groupBy])

	const isAllSelected = useMemo(
		() => sortedOptions?.every(({ key }) => values?.[key]?.checked),
		[sortedOptions, values]
	)

	const isTopPosition = useMemo(
		() =>
			Boolean(
				anchor && anchor.getBoundingClientRect().top > window.innerHeight / 2
			),
		[anchor]
	)

	const formMultiSelectSearchDynamicCSS = useMemo(
		() => ({
			...mainCSS(
				anchor,
				width,
				isDisabled,
				error,
				bordered,
				isRequired,
				maxWidth
			),
		}),
		[anchor, width, isDisabled, error]
	)

	const checkedOptionsCount: any = useMemo(
		() =>
			Object.values(values).reduce(
				(count: number, option: any) => count + (option?.checked ? 1 : 0),
				0
			),
		[values]
	)

	const handleClose = useCallback(() => {
		closeModal()
		setAnchor(null)
		setDebouncedState('')
		setSeeSelected(false)
		trigerred && triggerError(name)
	}, [triggerError])

	const handleOnSearch = useCallback((e) => {
		setDebouncedState(e.target.value)
	}, [])

	const handleSeeSelectedClick = useCallback(
		() => setSeeSelected(!seeSelected),
		[seeSelected]
	)

	return (
		<>
			<Box sx={formMultiSelectSearchDynamicCSS}>
				<Box
					sx={formMultiSelectSearchCSS.boxContainer(
						checkedOptionsCount,
						bordered
					)}
				>
					<fieldset className='boxContainer-fieldset'>
						<legend className='boxContainer-legend'>{label}</legend>
					</fieldset>

					<FormMultiSelectSearchButton
						isDisabled={isDisabled}
						openModal={openModal}
						setAnchor={setAnchor}
						values={values}
						label={label}
						isRequired={isRequired}
						nbChipsToDisplay={nbChipsToDisplay}
						renderChipsLabel={renderChipsLabel}
						name={name}
						specialOnChange={specialOnChange}
						checkedOptionsCount={checkedOptionsCount}
					/>

					<Popover
						open={isVisible}
						anchorEl={anchor}
						onClose={handleClose}
						anchorOrigin={{
							vertical: isTopPosition ? 'top' : 'bottom',
							horizontal: 'left',
						}}
						transformOrigin={{
							vertical: isTopPosition ? 'bottom' : 'top',
							horizontal: 'left',
						}}
						sx={formMultiSelectSearchCSS.formMultiSelectSearchPopoverCSS}
					>
						<Typography sx={formMultiSelectSearchCSS.typography}>
							{messageTopModal}
						</Typography>

						<Box className='PopoverTop'>
							<SearchField autoFocus onChange={handleOnSearch} />
						</Box>

						<Box className='SelectOption'>
							<Typography
								onClick={handleSeeSelectedClick}
								variant='button'
								className='SelectOptionButton'
							>
								{seeSelected
									? intlMsg('misc.showAll')
									: checkedOptionsCount < 1
									? ''
									: intlMsg('misc.showSelectedElements')}
							</Typography>
						</Box>

						<OptionList
							name={name}
							context={context}
							options={options}
							sortedOptions={sortedOptions}
							groupBy={groupBy}
							values={values}
							selectAllText={selectAllText}
							missingText={missingText}
							specialOnChange={specialOnChange}
							seeSelected={seeSelected}
							isAllSelected={isAllSelected}
						/>
					</Popover>
				</Box>
			</Box>
			{error && (
				<Box
					className='messageBottom'
					sx={formMultiSelectSearchCSS.errorParagraphSX}
				>
					{error}
				</Box>
			)}
		</>
	)
}

export default memo(FormMultiSelectSearch)
