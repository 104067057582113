import {
	EFormTemplateBasicFields,
	EFormTemplateModelFields,
	EModule,
} from '../../../../../../utils/app-models'
import { setLimitationPicture } from './fieldLimitation/pictureLimitation'

// ////////////////////////////////////////////////////////////////
// Initial limitation config (this config is set in limitationPicture State in FormTemplateProvider)
// ////////////////////////////////////////////////////////////////

export const defaultLimitation = (checkModules) => {
	return {
		[EFormTemplateBasicFields.picture]: {
			maxPhotos: checkModules([EModule.optionExtraPicture]) ? 40 : 10,
			currentPhotos: 0,
			isLimitReached: false,
		},
	}
}

// ////////////////////////////////////////////////////////////////
// use when add new field (use with the checkFieldsLimitation Function)
// ////////////////////////////////////////////////////////////////

const defaultLimitFields = {
	[EFormTemplateBasicFields.picture]: { nbPhotosToAdd: 1 },
	[EFormTemplateModelFields.pictureVehicle]: { nbPhotosToAdd: 1 },
	[EFormTemplateModelFields.picture4anglesModel]: { nbPhotosToAdd: 4 },
	[EFormTemplateModelFields.picture4anglesAndWheelsModel]: { nbPhotosToAdd: 8 },
}

// ////////////////////////////////////////////////////////////////
// Update the limitation config by field
// ////////////////////////////////////////////////////////////////

export const updateLimitField = ({
	fieldType,
	fieldAttributes,
	setLimitationFields,
	defaultValues = null,
	isRemoval = false,
}) => {
	switch (fieldType) {
		// FIELD PICTURE ---------------------
		case EFormTemplateModelFields.picture4anglesModel:
		case EFormTemplateModelFields.pictureVehicle:
		case EFormTemplateModelFields.picture4anglesAndWheelsModel:
		case EFormTemplateBasicFields.picture:
			setLimitationPicture({
				setLimitationFields,
				fieldType: EFormTemplateBasicFields.picture,
				fieldAttributes,
				defaultValues,
				isRemoval,
			})

			break

		default:
			return
	}
}

// ////////////////////////////////////////////////////////////////
// Verify all fields for update the limitation
// ////////////////////////////////////////////////////////////////

export const verifyAndSetLimitationField = ({
	setLimitationFields,
	pages = null,
	pageFields = null,
}: {
	setLimitationFields: any
	pages?: any
	pageFields?: any
}) => {
	// When we edit the formTemplate updateLimitField with defaultValues
	if (pages) {
		pages.forEach(({ fields }) =>
			fields.forEach(({ type, attributes }) =>
				updateLimitField({
					fieldType: type,
					fieldAttributes: attributes,
					setLimitationFields,
				})
			)
		)
	} else {
		// When a page is deleted updateLimitField
		pageFields.forEach(({ fieldType, values }) =>
			updateLimitField({
				fieldType: fieldType,
				fieldAttributes: values,
				setLimitationFields,
				isRemoval: true,
			})
		)
	}
}

// ////////////////////////////////////////////////////////////////
// check limitation before add the field (event -> onClick insertField  & onDragEndField insertField)
// ////////////////////////////////////////////////////////////////

export const checkFieldsLimitation = (fieldType, limitationFields) => {
	switch (fieldType) {
		// FIELD PICTURE ---------------------
		case EFormTemplateBasicFields.picture:
			return (
				limitationFields[fieldType]?.currentPhotos +
					defaultLimitFields[fieldType]?.nbPhotosToAdd >
				limitationFields[fieldType]?.maxPhotos
			)

		default:
			return false
	}
}
