import React from 'react'

import List from '@mui/material/List'

import NavItemsConfig from '../NavItemsConfig'
import PermissionsHandler from '../../../../permissionsHandler'
import NavItem from './NavItem'

interface INavItems {}

const NavItems: React.FC<INavItems> = () => {
	return (
		<List sx={{ mt: 2 }} dense>
			{NavItemsConfig.map((item: any, i) => (
				<PermissionsHandler
					key={`${item?.label}-${i}`}
					permissionsName={item?.permissions}
					modulesName={item?.modules}
				>
					<NavItem config={item} />
				</PermissionsHandler>
			))}
		</List>
	)
}

export default NavItems
