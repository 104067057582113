import React, { useCallback, useState } from 'react'

import { Button } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import CalendarDisplayByPopover from './CalendarDisplayByPopover'
import { useFormatMessage } from '../../../../utils/hooks'

interface ICalendarDisplayBy {
	currentView
	selectElements
	onChange
}

const CalendarDisplayBy: React.FC<ICalendarDisplayBy> = ({
	currentView,
	selectElements,
	onChange,
}) => {
	const intlMsg = useFormatMessage()
	const [anchorEl, setAnchorEl] = useState(null)
	const handleClick = useCallback((e) => setAnchorEl(e.currentTarget), [])
	const handleClose = useCallback(() => setAnchorEl(null), [])

	return (
		<div>
			<Button
				color='inherit'
				endIcon={<ArrowDropDownIcon />}
				variant='text'
				onClick={handleClick}
			>
				{intlMsg(`misc.${currentView}`)}
			</Button>

			<CalendarDisplayByPopover
				selectElements={selectElements}
				handleClose={handleClose}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onChange={onChange}
				currentView={currentView}
			/>
		</div>
	)
}

export default CalendarDisplayBy
