import React, {
	cloneElement,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react'
import dayjs from 'dayjs'

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Button, Stack, Typography } from '@mui/material'

import { formatDateFormView } from './calendarUtils'
import {
	EModule,
	EPermissionScope,
	EViewPeriod,
} from '../../../utils/app-models'
import { useFormatMessage } from '../../../utils/hooks'
import SearchField from '../../../components/forms/v1/formFields/SearchField'
import PermissionsHandler from '../../../components/permissionsHandler'
import CalendarDisplayBy from './calendarDisplayBy'
import CalendarCreateIntervention from './calendarCreateIntervention'
import { FiltersContext } from '../../../components/filters/filtersContext'

interface ICalendarHeader {
	currentDate
	setCurrentDate
	currentView
	setCurrentView
	liveState
	setDebouncedState
	debouncedState
	loadingInterventions: Boolean
}

const CalendarHeader: React.FC<ICalendarHeader> = ({
	currentDate,
	setCurrentDate,
	currentView,
	setCurrentView,
	liveState,
	setDebouncedState,
	debouncedState,
	loadingInterventions,
}) => {
	const intlMsg = useFormatMessage()
	const { componentsFiltersConfig } = useContext(FiltersContext)

	const [formattedDate, setFormattedDate] = useState('')
	const [loadingDebounced, setLoadingDebounced] = useState(false)

	useEffect(() => {
		if (liveState?.searchText !== debouncedState?.searchText)
			setLoadingDebounced(true)
		else setLoadingDebounced(false)
	}, [debouncedState, liveState])

	useEffect(() => {
		const formattedDate = formatDateFormView(currentDate, currentView)

		if (currentView === EViewPeriod.month)
			setFormattedDate(
				formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)
			)
		else if (currentView === EViewPeriod.week)
			setFormattedDate(
				formattedDate.substring(0, 6) +
					formattedDate.charAt(6).toUpperCase() +
					formattedDate.slice(7)
			)
		else
			setFormattedDate(
				formattedDate.substring(0, 3) +
					formattedDate.charAt(3).toUpperCase() +
					formattedDate.slice(4)
			)
	}, [currentDate, currentView])

	const handleNavigate = useCallback(
		(isBack) => () => {
			if (isBack)
				setCurrentDate(dayjs(currentDate).subtract(1, currentView).toDate())
			else setCurrentDate(dayjs(currentDate).add(1, currentView).toDate())
		},
		[currentDate, currentView]
	)

	const handleSelectView = useCallback(
		(closePopover) => (e) => {
			setCurrentView(e.currentTarget.getElementsByTagName('input')[0].value)
			closePopover()
		},
		[]
	)

	const handleChangeSearch = useCallback(
		(e) => {
			setDebouncedState({
				...liveState,
				searchText: e.target.value.trim(),
			})
		},
		[liveState]
	)

	const handleTodayClick = useCallback(() => {
		setCurrentDate(dayjs())
	}, [])

	return (
		<Stack direction='row' justifyContent='space-between' mb={2}>
			<Stack direction='row' alignItems='center' spacing={2}>
				<Stack direction='row' alignItems='center' sx={{ cursor: 'pointer' }}>
					<ArrowBackIosIcon onClick={handleNavigate(true)} fontSize='small' />
					<ArrowForwardIosIcon
						onClick={handleNavigate(false)}
						fontSize='small'
					/>
				</Stack>
				<Typography variant='h2'>{formattedDate}</Typography>

				<Button variant='outlined' onClick={handleTodayClick}>
					{intlMsg('misc.today')}
				</Button>
			</Stack>

			<Stack direction='row' alignItems='center' spacing={2}>
				<CalendarDisplayBy
					currentView={currentView}
					selectElements={Object.keys(EViewPeriod).map((key) => ({
						label: intlMsg(`misc.${key}`),
						id: key,
						value: key,
					}))}
					onChange={handleSelectView}
				/>

				{cloneElement(componentsFiltersConfig)}

				<SearchField
					onChange={handleChangeSearch}
					loading={loadingDebounced || loadingInterventions}
				/>

				<PermissionsHandler
					permissionsName={[EPermissionScope.interventionCreate]}
					modulesName={[
						EModule.interventionControl,
						EModule.interventionLogistic,
						EModule.interventionMecanic,
						EModule.interventionTransport,
					]}
				>
					<CalendarCreateIntervention />
				</PermissionsHandler>
			</Stack>
		</Stack>
	)
}

export default CalendarHeader
