import React, { useContext, useMemo } from 'react'

import isEqual from 'lodash.isequal'
import { isEmpty } from 'lodash'

import MuiTable from '@mui/material/Table'
import TableContainerMUI from '@mui/material/TableContainer'
import { Box, Fade } from '@mui/material'
import { TableContext } from '../../../../utils/contexts/TableContext'
import LogoConnectIcon from './../../../../svgIcons/LogoConnectIcon'
import NothingToDisplay from '../../../alert/NothingToDisplay'
import TableHeader from './TableHeader'
import TableBody from './TableBody'
import TableFooter from './TableFooter'

import { makeStyles } from '@mui/styles'

const mainCSS = {
	width: 1,
	height: 1,
	flexDirection: 'column',
	display: 'flex',
	flexGrow: 1,

	'& > div': {
		flexDirection: 'column',
		display: 'flex',
		height: '100%',
		flexGrow: '1',
	},

	'& .MuiTableCell-root': {
		borderBottom: 'none !important',
		pl: 0,
		pr: 0,
	},
}

const useStyles = makeStyles({
	nothingToDisplayCSS: {
		flexGrow: '1',
	},
})

const loaderContainerCSS = {
	height: '100%',
}

const TableContainerCSS = (height, stickyToolBar) => ({
	...(stickyToolBar &&
		{
			//height: height || '65vh',
		}),
	overflowY: 'auto !important',
	height: '0px',
	flexGrow: 1,
})

interface ITableComponent {
	height?: string
	colorBorderLeftSelected?: string
	stickyToolBar?: boolean
	disabledCheckbox?: boolean

	data: any
	filteredTableConfig: any
	handleRequestSort: any
	rows: any
	count: number
	rowsPerPage: number
	setRowsPerPage: any
	loading?: boolean
}

const TableComponent: React.FC<ITableComponent> = ({
	height = null,
	colorBorderLeftSelected,
	stickyToolBar = false,
	disabledCheckbox = false,

	data,
	filteredTableConfig,
	handleRequestSort,
	rows,
	count,
	rowsPerPage,
	setRowsPerPage,
}) => {
	const classes = useStyles()

	const {
		selectedRow,
		setSelectedRow,
		activeColumns,
		search,
		page,
		setPage,
		currentOrderBy,
		filtersQuerieParam,
		defaultFilterConfig,
	} = useContext(TableContext) || {}

	const isFiltered = useMemo(() => {
		if (
			isEmpty(filtersQuerieParam) &&
			isEmpty(defaultFilterConfig) &&
			!search?.searchText?.length
		) {
			return false
		}
		return (
			!isEqual(filtersQuerieParam, defaultFilterConfig || {}) ||
			search?.searchText?.length
		)
	}, [filtersQuerieParam, defaultFilterConfig, search?.searchText])

	const currentOrderByName = useMemo(
		() => Object.keys(currentOrderBy || {})?.[0] || '',
		[currentOrderBy]
	)

	return !data ? (
		<Fade in={true} timeout={500}>
			<div style={{ flexGrow: 1 }}>
				<Box sx={loaderContainerCSS}>
					<LogoConnectIcon height='100%' />
				</Box>
			</div>
		</Fade>
	) : count === 0 ? (
		<Fade in={true} timeout={500}>
			<div className={classes.nothingToDisplayCSS}>
				<NothingToDisplay isFiltered={isFiltered} />
			</div>
		</Fade>
	) : (
		<Box sx={mainCSS}>
			<Fade in={!!data} timeout={500}>
				<div>
					<TableContainerMUI
						className='tableContainerCSS'
						sx={TableContainerCSS(height, stickyToolBar)}
					>
						<MuiTable aria-label='simple table'>
							<TableHeader
								tableConfig={filteredTableConfig}
								activeColumns={activeColumns}
								selectedRow={selectedRow}
								setSelectedRow={setSelectedRow}
								rows={rows}
								onRequestSort={handleRequestSort}
								currentOrderByName={currentOrderByName}
								order={currentOrderBy?.[currentOrderByName] || ''}
								disabledCheckbox={disabledCheckbox}
							/>
							<TableBody
								tableConfig={filteredTableConfig}
								rows={rows}
								activeColumns={activeColumns}
								selectedRow={selectedRow}
								setSelectedRow={setSelectedRow}
								colorBorderLeftSelected={colorBorderLeftSelected}
								disabledCheckbox={disabledCheckbox}
							/>
						</MuiTable>
					</TableContainerMUI>
					<TableFooter
						page={page}
						setPage={setPage}
						rowsPerPage={rowsPerPage}
						setRowsPerPage={setRowsPerPage}
						count={count}
					/>
				</div>
			</Fade>
		</Box>
	)
}

export default TableComponent
