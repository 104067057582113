import React from 'react'

import { Stack, Typography, Box } from '@mui/material'

import TitleField from './TitleField'
import { stringifiedColor } from '../../../../../../utils/color'

const fieldControlCSS = {
	borderRadius: 1,
	p: 1,
	mr: 1,
	mb: 1,
	minWidth: '50px',
	textAlign: 'center',
}

interface IFieldControl {
	field
	deleteField
	index
	updateField
	insertField
}

const FieldControl: React.FC<IFieldControl> = ({
	field: { id, name, values, fieldType },
	deleteField,
	index,
	updateField,
	insertField,
}) => {
	const fieldToEdit = {
		id,
		name,
		values,
		fieldType,
		updateField,
		index,
	}

	return (
		<Stack p={1}>
			<TitleField
				deleteField={deleteField}
				index={index}
				fieldToEdit={fieldToEdit}
				isRequired={values?.isRequired}
				canAlert={values?.canAlert}
				insertField={insertField}
				values={values}
				fieldType={fieldType}
			/>
			<Stack direction='row' flexWrap='wrap'>
				{values?.controlList?.map(({ choice, color }, i) => {
					const hexColor = stringifiedColor(color, 500) as any
					return (
						<Box
							sx={fieldControlCSS}
							key={`displayControlList-${choice}-${i}`}
							style={
								i === values?.defaultValue
									? {
											color: 'white',
											backgroundColor: hexColor,
									  }
									: {
											color: hexColor,
											border: `1px solid ${hexColor}`,
									  }
							}
						>
							<Typography variant='body2'>{choice}</Typography>
						</Box>
					)
				})}
			</Stack>
		</Stack>
	)
}

export default FieldControl
