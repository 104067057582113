import React, { useRef, useMemo, useState, useCallback } from 'react'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'
import CircularProgress from '@mui/material/CircularProgress'
import { Box, debounce, InputLabel } from '@mui/material'
import { useFormatMessage } from '../../utils/hooks'

const inputSearchCSS = {
	display: 'inline-flex',
	alignItems: 'center',
	fontSize: ({ typography }) => typography.subtitle2.fontSize,
	p: 1,
	borderRadius: 1,
	fontWeight: ({ typography }) => typography.fontWeightRegular as any,
	background: ({ palette }) => palette.grey['50'],
	color: ({ palette }) => palette.grey['700'],
}

const InputLabelCSS = { position: 'absolute', zIndex: 1, top: 14, right: 14 }

interface iSearchField {
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
	placeholder?: string
	fullWidth?: boolean
	loading?: boolean
	minCharacters?: number
	autoFocus?: boolean
}

const SearchField: React.FC<iSearchField> = ({
	onChange,
	placeholder,
	fullWidth,
	loading = false,
	minCharacters = 0,
	autoFocus,
}) => {
	const intlMsg = useFormatMessage()
	const valueRef = useRef<HTMLInputElement | null>(null)
	const [errorCharacters, setErrorCharacters] = useState(false)

	const checkMinCharacters = useMemo(() => {
		return debounce((nbCharacters) => {
			setErrorCharacters(nbCharacters < minCharacters && nbCharacters !== 0)
		}, 1000)
	}, [minCharacters])

	const onChangeInput = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			onChange(e)
			minCharacters && checkMinCharacters(valueRef?.current?.value?.length)
		},
		[onChange, minCharacters, checkMinCharacters]
	)

	const errorLabel = useMemo(
		() =>
			errorCharacters && (
				<InputLabel sx={InputLabelCSS} color='error'>
					{intlMsg('errors.minCharacters', { minCharacters })}
				</InputLabel>
			),
		[errorCharacters, intlMsg, minCharacters]
	)

	const loadingIndicator = useMemo(
		() =>
			loading ? (
				<CircularProgress
					style={{ width: '25px', height: '20px', marginLeft: '0.5rem' }}
				/>
			) : (
				<div style={{ width: '25px', height: '20px', marginLeft: '0.5rem' }} />
			),
		[loading]
	)

	const placeholderText = useMemo(() => {
		if (errorCharacters) return ''

		if (placeholder) return intlMsg(placeholder)

		return intlMsg('search.default')
	}, [errorCharacters, intlMsg, placeholder])

	return (
		<Box sx={{ position: 'relative' }}>
			{errorLabel}
			<InputBase
				autoFocus={autoFocus}
				sx={inputSearchCSS}
				placeholder={placeholderText}
				onChange={onChangeInput}
				startAdornment={<SearchIcon sx={{ mr: 1 }} />}
				endAdornment={loadingIndicator}
				fullWidth={fullWidth || false}
				error={errorCharacters}
				inputRef={valueRef}
			/>
		</Box>
	)
}

export default SearchField
