import React, { useCallback, useContext } from 'react'

import { Routes, Route, useLocation } from 'react-router-dom'
import Container from '@mui/material/Grid'

import { ROUTES } from '../../routes/consts'
import ListVehicles from './vehicles'
import CustomerAccounts from './customerAccounts'
import CustomerSites from './customerSites'
import ConditionalRoute from '../../routes/ConditionalRoute'
import { AuthContext } from '../../utils/contexts/AuthContext'
import { EModule, EPermission } from '../../utils/app-models'
import InterventionsByReference from './interventions/interventionControl/byReference'
import InterventionsByVehicle from './interventions/interventionControl/byVehicle'
import Calendar from './calendar'
import CustomerContacts from './customerContacts'
import TestRoute from './testRoute'
import Alerts from './alerts'

import NetworksAgencies from './networkAgency'
import ViewDashboard from './home'
import { Error404 } from '..'
import AppHubeecheck from './app'
import Invoices from './invoice'
import VehiclesDocuments from './vehiclesDocuments'
import InterventionControlForms from './interventionForms'
import InterventionTransport from './interventions/interventionTransport'

const Dashboard: React.FC = () => {
	const { checkPermissions, checkModules } = useContext(AuthContext)
	const location = useLocation()

	const checkCondition = useCallback(
		({
			permissions = null,
			modules = null,
		}: {
			permissions?: string[] | null
			modules?: string[] | null
		}) => {
			if (!checkPermissions(permissions) || !checkModules(modules)) return false
			else return true
		},
		[checkPermissions]
	)

	return (
		<Container sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
			<Routes>
				<Route path={ROUTES.HOME.url} element={<ViewDashboard />} />

				<Route
					path={ROUTES.VEHICLES.path}
					element={
						<ConditionalRoute
							component={ListVehicles}
							redirect={ROUTES.HOME.url}
						/>
					}
				/>
				<Route
					path={ROUTES.SITES.path}
					element={
						<ConditionalRoute
							component={CustomerSites}
							redirect={ROUTES.HOME.url}
						/>
					}
				/>
				<Route path={ROUTES.ACCOUNTS.path} element={<CustomerAccounts />} />
				<Route
					path={ROUTES.CONTACTS.path}
					element={
						<ConditionalRoute
							component={CustomerContacts}
							condition={checkCondition({ modules: [EModule.crm] })}
							redirect={ROUTES.HOME.url}
						/>
					}
				/>
				<Route
					path={ROUTES.INTERVENTION.CALENDAR.path}
					element={
						<ConditionalRoute
							component={Calendar}
							condition={checkCondition({ modules: [EModule.planning] })}
							redirect={ROUTES.HOME.url}
						/>
					}
				/>

				<Route
					path={ROUTES.INTERVENTION.REFERENCES.path}
					element={
						<ConditionalRoute
							component={InterventionsByReference}
							condition={checkCondition({
								modules: [EModule.interventionControl],
							})}
							redirect={ROUTES.HOME.url}
						/>
					}
				/>

				<Route
					path={ROUTES.INTERVENTION.VEHICLES.path}
					element={
						<ConditionalRoute
							component={InterventionsByVehicle}
							redirect={ROUTES.HOME.url}
							condition={checkCondition({
								modules: [EModule.interventionControl],
							})}
						/>
					}
				/>

				<Route
					path={ROUTES.INTERVENTION.TRANSPORTS.path}
					element={
						<ConditionalRoute
							component={InterventionTransport}
							redirect={ROUTES.HOME.url}
							condition={checkCondition({
								modules: [EModule.interventionTransport],
							})}
						/>
					}
				/>

				<Route
					path={ROUTES.VEHICLES_DOCUMENTS.path}
					element={
						<ConditionalRoute
							component={VehiclesDocuments}
							redirect={ROUTES.HOME.url}
							condition={checkCondition({
								permissions: [EPermission.document],
							})}
						/>
					}
				/>

				<Route
					path={ROUTES.TEST.path}
					element={
						<ConditionalRoute
							component={TestRoute}
							condition={checkCondition({
								permissions: [EPermission.superMegaAdmin],
							})}
							redirect={ROUTES.HOME.url}
						/>
					}
				/>

				<Route
					path={ROUTES.ALERTS.url}
					element={
						<ConditionalRoute
							component={Alerts}
							condition={checkCondition({ permissions: [EPermission.alert] })}
							redirect={ROUTES.HOME.url}
						/>
					}
				/>

				<Route
					path={ROUTES.INTERVENTION_FORMS.path}
					element={
						<ConditionalRoute
							component={InterventionControlForms}
							condition={checkCondition({
								modules: [
									EModule.interventionControl,
									EModule.interventionLogistic,
									EModule.interventionMecanic,
									EModule.interventionTransport,
								],
							})}
							redirect={ROUTES.HOME.url}
						/>
					}
				/>
				<Route
					path={ROUTES.NETWORKS.path}
					element={
						<ConditionalRoute
							component={NetworksAgencies}
							condition={checkCondition({
								modules: [EModule.network],
								permissions: [EPermission.network],
							})}
							redirect={ROUTES.HOME.url}
						/>
					}
				/>

				<Route
					path={ROUTES.INVOICES.path}
					element={
						<ConditionalRoute
							component={Invoices}
							condition={checkCondition({
								modules: [EModule.invoice],
								permissions: [EPermission.invoice],
							})}
							redirect={ROUTES.HOME.url}
						/>
					}
				/>

				<Route path={ROUTES.APP.url} element={<AppHubeecheck />} />

				<Route path={'/'} element={<ViewDashboard />} />
				{!location?.pathname.includes('/modalview') && (
					<Route path={'*'} element={<Error404 />} />
				)}
			</Routes>
		</Container>
	)
}

export default Dashboard
