import React, { useCallback } from 'react'

import qs from 'qs'
import toast from 'react-hot-toast'

import { usePostMedia } from '../../../../api/gql/mutations/awsMutation'
import {
	useCreateInvoice,
	useRemoveInvoice,
	useUpdateInvoice,
} from '../../../../api/gql/mutations/invoicesMutation'
import { useInterventionInvoice } from '../../../../api/gql/queries/interventionsQueries'
import { useLazyInvoiceForModal } from '../../../../api/gql/queries/invoicesQueries'
import { useVehicleInvoice } from '../../../../api/gql/queries/vehiclesQueries'
import FormModal from '../../../../components/forms/formModal'
import { EFormModalMode } from '../../../../utils/app-models'
import { IModalUtils, useFormatMessage } from '../../../../utils/hooks'
import invoiceModalConfig from './invoiceModalConfig'

import {
	formatInvoiceModalCreateDefaultValues,
	formatInvoiceModalEditDefaultValues,
	formatInvoiceModalOnValidateData,
} from './invoiceModalUtils'

interface IInvoiceModal {
	invoiceId?: string | null
	modalUtils: IModalUtils
	mode?: EFormModalMode
	onClose?: any
}

const InvoiceModal: React.FC<IInvoiceModal> = ({
	modalUtils,
	mode,
	invoiceId,
	onClose,
}) => {
	const intlMsg = useFormatMessage()

	const { query, varValue } = qs.parse(location.search, {
		ignoreQueryPrefix: true,
	}) as any

	// !!! For intervention type "transport", you need to use the queryName = "interventionTransport" in the condition on getViewModalConfig.ts, but "transport" currently uses the query 'intervention'.
	// !!! To find similar problems, search for #currentQueryNameTransport
	const currentQuery = ['interventionTransport'].includes(query)
		? 'intervention'
		: query

	const { getInvoice } = useLazyInvoiceForModal()
	const [createInvoice] = useCreateInvoice()
	const [updateInvoice] = useUpdateInvoice()
	const [postMedia] = usePostMedia()
	const { getIntervention } = useInterventionInvoice()
	const { getVehicle } = useVehicleInvoice()

	const onValidate = useCallback(
		async ({ data, setValidationLoading, closeModal }) => {
			if (data?.importFile?.[0] && !data.importFile[0]?.type) {
				return toast.error('errors.errorFileIsCorrupted')
			}

			setValidationLoading(true)
			const mutate =
				mode === EFormModalMode.edit ? updateInvoice : createInvoice

			try {
				// Create or update invoice ///////////////////////////////////////////////////
				const resultMutateInvoice = await mutate({
					variables: {
						input: formatInvoiceModalOnValidateData({ data, invoiceId, mode }),
					},
					refetchQueries: [
						'getInvoicesCountByVehicle',
						'getInvoicesList',
						'getInvoicesCountByIntervention',
						'invoiceForModal',
					],
				})

				const idInvoiceCreation =
					mode === EFormModalMode.edit
						? resultMutateInvoice.data?.updateInvoice?.invoice?.id
						: resultMutateInvoice.data?.createInvoice?.invoice?.id

				// Successfully create or update invoice & if file exist /////////////////////////
				if (idInvoiceCreation && data?.importFile?.[0]?.type) {
					const { size, name, type } = data.importFile[0]

					// Get url AWS to send file
					const resultPostMedia = await postMedia({
						variables: {
							input: {
								name,
								iri: idInvoiceCreation,
								contentType: type,
								contentLength: size.toString(),
							},
						},
					})

					const urlAwsBucketToPost =
						resultPostMedia?.data?.postAwsMediaUrl?.awsMediaUrl?.url

					// If get the urlAws, send image to AWS bucket
					if (urlAwsBucketToPost) {
						const resultUrlAwsBucketToPost = await fetch(urlAwsBucketToPost, {
							method: 'PUT',
							headers: { 'Content-Type': type },
							body: data.importFile[0],
						})

						// Delete the invoice if error for upload file (only for creation invoice)
						if (
							resultUrlAwsBucketToPost.status !== 200 &&
							EFormModalMode.create
						) {
							const [deleteInvoice] = useRemoveInvoice()

							await deleteInvoice({
								variables: { input: { id: idInvoiceCreation } },
								refetchQueries: [
									'getInvoicesCountByVehicle',
									'getInvoicesList',
									'getInvoicesCountByIntervention',
								],
							}).then(() => {
								toast.error('errors.errorAddFileInvoice')
							})
						}
					}
				}

				mode === EFormModalMode.edit
					? toast.success('success.invoiceUpdate')
					: toast.success('success.invoiceCreate')

				closeModal()
			} catch (error) {
				mode === EFormModalMode.edit
					? toast.error('errors.errorUpdateInvoice')
					: toast.error('errors.errorCreateInvoice')
			}

			setValidationLoading(false)
		},
		[invoiceId, mode]
	)

	const initializeData = useCallback(
		async ({ setValues, setLoading }) => {
			if (mode === EFormModalMode.edit) {
				setLoading(true)
				getInvoice({ variables: { id: invoiceId } })
					.then((res) => {
						const data = res?.data?.invoice
						if (data) {
							setValues(
								formatInvoiceModalEditDefaultValues({
									data,
									currentQuery,
								})
							)
						}
						setLoading(false)
					})
					.catch(() => setLoading(false))
			} else {
				setLoading(true)

				// if currentQuery we are in viewModal
				const viewModalDefaultValue: any = {}
				if (currentQuery) {
					const { data } =
						currentQuery === 'intervention'
							? await getIntervention({ variables: { id: varValue } })
							: await getVehicle({ variables: { id: varValue } })
					viewModalDefaultValue[currentQuery] = data[currentQuery]?.id
				}

				setValues(
					formatInvoiceModalCreateDefaultValues({
						currentQuery,
						viewModalDefaultValue,
					})
				)
				setLoading(false)
			}
		},
		[mode, invoiceId]
	)

	return (
		<FormModal
			modalUtils={modalUtils}
			formModalTabConfig={invoiceModalConfig}
			formModalName={intlMsg(
				mode === EFormModalMode.edit ? 'invoice.edit' : 'invoice.create'
			)}
			initialMode={mode}
			onValidate={onValidate}
			validateBtnText={intlMsg('misc.validate')}
			cancelBtnText={intlMsg('misc.cancel')}
			maxWidth='700px'
			width='70%'
			minHeight='50px'
			initializeData={initializeData}
			onClose={onClose}
		/>
	)
}

export default InvoiceModal
