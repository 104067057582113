/* eslint-disable no-case-declarations */
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Box, Fade } from '@mui/material'

import { stringifiedColor } from '../../../../../utils/color'
import ConvoyingWeekDayEvent from './ConvoyingWeekDayEvent'
import InterventionControlWeekDayEvent from './InterventionControlWeekDayEvent'
import { resetPlanningCardSize, resizePlanningCard } from '../../calendarUtils'

const mainCSS = (isSelected, searchText, borderColor, event) => ({
	height: 1,
	fontSize: '13px',
	opacity: (searchText ? (isSelected ? '1' : '0.1') : '1') + '!important',
	borderLeft: borderColor
		? `4px solid ${stringifiedColor(
				event?.resource?.status?.color || 'grey',
				400
		  )} !important`
		: '',
	paddingLeft: borderColor ? '2px' : '',
})

const renderMonthEventByInterventionType = (
	interventionType,
	transportType
) => {
	switch (interventionType) {
		case 'transport':
			if (transportType === 'CONVOY') return ConvoyingWeekDayEvent
			break

		case 'control':
			return InterventionControlWeekDayEvent
	}
}

interface ICustomWeekDayEvent {
	event?: any
	searchText?: string
	isSelected?: boolean
	handleClick?: any
	setIsMembersCellOpen?: any
	borderColor?: boolean
}

const CustomWeekDayEvent: React.FC<ICustomWeekDayEvent> = ({
	event,
	searchText,
	isSelected,
	handleClick,
	setIsMembersCellOpen,
	borderColor = false,
}) => {
	const { interventionType, interventionTransportType } = event?.resource || {}

	const isWeekView = useMemo(
		() => event.currentView === 'week',
		[event.currentView]
	)

	const CustomMonthComponent = useMemo(() => {
		return renderMonthEventByInterventionType(
			interventionType,
			interventionTransportType?.code
		)
	}, [interventionType, interventionTransportType?.code]) as any

	/////////////////////////////////////////////////////////
	// Resize card onHover
	/////////////////////////////////////////////////////////
	// !!! use functions resizePlanningCard & resetPlanningCardSize inside useEffect and not directly inside onMouseEnter & onMouseLeave event
	// because a children (component MembersCell.tsx) use e.preventDefault() & e.stopPropagation() and events are broken (onMouseEnter,onMouseLeave)

	const [domEvent, setDomEvent]: any = useState()
	const [selectorCard, setSelectorCard]: any = useState()
	const [inheritStyle, setInheritStyle] = useState()
	const [isCardResizing, setIsCardResizing]: any = useState(false)

	const onCardMouse = useCallback(
		(isResizingCard: boolean) => (e) => {
			if (isWeekView) {
				setIsCardResizing(isResizingCard)
				setDomEvent(e)
			}
		},
		[isWeekView]
	)

	useEffect(() => {
		if (isWeekView) {
			isCardResizing
				? resizePlanningCard(setSelectorCard, setInheritStyle, domEvent)
				: inheritStyle && resetPlanningCardSize(selectorCard, inheritStyle)
		}
	}, [isCardResizing])

	return (
		<Fade in>
			<Box
				sx={mainCSS(isSelected, searchText, borderColor, event)}
				onClick={handleClick}
				onMouseEnter={onCardMouse(true)}
				onMouseLeave={onCardMouse(false)}
			>
				<CustomMonthComponent
					setIsMembersCellOpen={setIsMembersCellOpen}
					event={event}
					onCardMouseAction={onCardMouse(false)}
				/>
			</Box>
		</Fade>
	)
}

export default CustomWeekDayEvent
