import React from 'react'

import { Button, Stack } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { useFormatMessage } from '../../../../utils/hooks'

interface ITableImportSubmit {
	handleClose
	loading
	disabled
}

const TableImportSubmit: React.FC<ITableImportSubmit> = ({
	handleClose,
	loading,
	disabled,
}) => {
	const intlMsg = useFormatMessage()

	return (
		<Stack direction='row' justifyContent='end' alignItems='center'>
			<Button
				variant='outlined'
				color='primary'
				onClick={handleClose}
				sx={{ mr: 2 }}
			>
				{intlMsg('misc.cancel')}
			</Button>

			<LoadingButton
				disabled={disabled}
				variant='contained'
				type='submit'
				loading={loading || false}
			>
				{intlMsg('misc.validate')}
			</LoadingButton>
		</Stack>
	)
}

export default TableImportSubmit
