import { createTheme } from '@mui/material'

import { generateDeepMergedObj } from '../../utils/utils'
import { GlobalTheme } from './globalTheme/globalTheme'
import { MuiTheme } from './muiTheme'
import { IMuiTheme, ISpecificColortheme } from './themeTypes'

export const LightColorTheme: ISpecificColortheme = {
	primary: '#5768FF',
	white: '#FFFFFF',
	text: {
		primary: '#191C24',
		secondary: '#222428',
		third: '#697181',
		disable: '#A8AAB5',
		clicable: '#5768FF',
	},
	actions: {
		active: '#6E7482',
		disable: '#DADEE9',
		hover: '#5768FF0A',
		selected: '#EAF1FF',
		border: '#D4DDE9',
		borderCalendar: '#9FB5D2',
		borderSelected: '#5768FF4D',
	},
	backgrounds: {
		main: '#FFFFFF',
		secondary: '#F7F8FA',
		light: '#5768FF14',
		screen: '#00020F99',
	},
}

export const LightTheme: IMuiTheme = {
	colors: LightColorTheme,

	//old theme version, used until MUI is completely removed
	palette: {
		divider: '#ededed',
		primary: {
			main: LightColorTheme.primary,
			dark: '#1D2443', //useless
		},
		text: LightColorTheme.text,
		//todo change this when we know why chips is crashing without this param (contrastText)
		alert: {
			// main: red[700],
			contrastText: '#fff',
		},
	},
	...MuiTheme, //old theme version, used until MUI is completely removed
}

export const HubeeLightTheme = createTheme(
	generateDeepMergedObj(GlobalTheme, LightTheme)
)
