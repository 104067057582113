import React, { useCallback } from 'react'

import { useFormatMessage } from '../../../../utils/hooks'
import PermissionsHandler from '../../../permissionsHandler'
import MultiSelect from '../../../select/MultiSelect'
import Fade from '@mui/material/Fade'
import Skeleton from '@mui/material/Skeleton'

const skeletonSx = {
	height: '56px',
	opacity: 0.5,
}

interface IFilterMultiselect {
	setFiltersQuerieParam
	filtersQuerieParam
	argumentQuerieName?
	modules?
	permissions?
	accountTypes?
	label: string
	resetIfOtherValueExist?: string
	options: any
	defaultValue?: any
	loading?: boolean
}

const FilterMultiselect: React.FC<IFilterMultiselect> = ({
	setFiltersQuerieParam,
	filtersQuerieParam,
	argumentQuerieName,
	modules = null,
	permissions = null,
	accountTypes = null,
	label,
	options,
	defaultValue,
	loading = false,
}) => {
	const intlMsg = useFormatMessage()

	const handleMultiSelectChange = useCallback(
		(selectedData) => {
			const formatData = selectedData?.map((data) => data.id)
			const data = formatData?.length ? formatData : null

			setFiltersQuerieParam({
				...filtersQuerieParam,
				[argumentQuerieName]: data,
			})
		},
		[filtersQuerieParam, argumentQuerieName]
	)

	return (
		<PermissionsHandler
			accountTypesName={accountTypes}
			permissionsName={permissions}
			modulesName={modules}
		>
			{!loading ? (
				<Fade in timeout={250}>
					<span>
						<MultiSelect
							label={intlMsg(label)}
							options={options}
							setValue={handleMultiSelectChange}
							defaultValue={defaultValue}
						/>
					</span>
				</Fade>
			) : (
				<Skeleton animation='wave' sx={skeletonSx} />
			)}
		</PermissionsHandler>
	)
}

export default FilterMultiselect
