import React from 'react'

import translateIntlMsgString from '../../../../utils/translateIntlMsgString'
import VehicleModalInfos from './vehicleModalInfos'
import { VehicleValidationSchema } from './vehiclesModalInfoValidationSchema'
import VehicleTechnicalInfos from './VehicleTechnicalInfos'

const vehicleModalConfig = [
	{
		tabName: translateIntlMsgString('misc.identification'),
		component: <VehicleModalInfos />,
		schema: VehicleValidationSchema,
	},
	{
		tabName: translateIntlMsgString('misc.technicalInformation'),
		component: <VehicleTechnicalInfos />,
		// canNotAccess:
		// 	!editedVehicle?.canEditInfos && mode === EFormModalMode.edit,
	},
]

export default vehicleModalConfig
