import React, { useEffect } from 'react'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import { Typography, Stack } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { Droppable } from 'react-beautiful-dnd'

import FieldsList from './FieldsList'

import {
	fieldsModelListConfig,
	getIconByFieldsType,
	layoutListConfig,
	photoListConfig,
	seizureListConfig,
	vehicleListConfig,
} from './utils'
import { useFormatMessage } from '../../../../../utils/hooks'
import FieldElement from './FieldElement'
import { useSticky } from '../../../../../utils/utils'

// This method is needed for rendering clones of draggables
// eslint-disable-next-line react/display-name
const getRenderItem = (appendField) => (provided, _snapshot, rubric) => {
	const nameField = rubric.draggableId

	return (
		<div ref={provided.innerRef} {...provided.draggableProps}>
			<FieldElement
				type={nameField}
				Icon={getIconByFieldsType(nameField)}
				appendField={appendField}
				draggableZone={{ ...provided.dragHandleProps }}
			/>
		</div>
	)
}

const mainCSS = {
	maxWidth: '400px',
	position: 'sticky',
	overflowY: 'scroll',
	scrollbarWidth: 'none',
	'&::-webkit-scrollbar': {
		display: 'none',
	},
	p: 1,
	transition: 'all 0.5s ease-in',
	transitionTimingFunction: 'linear',
	top: 0,
	mt: 0,
	'& .MuiAccordion-root': {
		margin: '0px!important',
	},
	'& .MuiAccordionSummary-gutters': {
		minHeight: '10px!important',
	},
	'& .MuiAccordionSummary-content': {
		margin: '5px 0px!important',
	},
} as const

const positionFixedCSS = {
	position: 'fixed',
	top: 0,
	maxWidth: '400px',
	right: '48px',
	height: '100%',
	transitionDelay: ' 0.1s',
	transition: 'all 0.2s ease-out',
	transitionTimingFunction: 'linear',
	marginTop: '68px',
	p: 1,
} as const

const accordionSx = {}

interface IFormTemplateFieldsList {
	appendField
}

const FormTemplateFieldsList: React.FC<IFormTemplateFieldsList> = ({
	appendField,
}) => {
	const intlMsg = useFormatMessage()
	const { isElementFixed, handleScroll } = useSticky(408)

	useEffect(() => {
		document.addEventListener('scroll', handleScroll)
		return () => {
			document.removeEventListener('scroll', handleScroll)
		}
	}, [])

	return (
		<Stack sx={[mainCSS, isElementFixed && positionFixedCSS]}>
			<Droppable
				droppableId={'fieldList'}
				isDropDisabled={true}
				renderClone={getRenderItem(appendField)}
			>
				{(provided, snapshot) => (
					<div ref={provided.innerRef} {...provided.droppableProps}>
						<Accordion defaultExpanded elevation={0} sx={accordionSx}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls='panel1a-content'
								id='panel1a-header'
							>
								<Typography variant='body1' sx={{ textTransform: 'uppercase' }}>
									{intlMsg('formTemplate.vehicle')}
								</Typography>
							</AccordionSummary>

							<FieldsList
								appendField={appendField}
								snapshot={snapshot}
								getIcon={getIconByFieldsType}
								fields={vehicleListConfig}
							/>
						</Accordion>

						<Accordion defaultExpanded elevation={0} sx={accordionSx}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls='panel1a-content'
								id='panel1a-header'
							>
								<Typography variant='body1' sx={{ textTransform: 'uppercase' }}>
									{intlMsg('formTemplate.seizure')}
								</Typography>
							</AccordionSummary>

							<FieldsList
								appendField={appendField}
								snapshot={snapshot}
								getIcon={getIconByFieldsType}
								fields={seizureListConfig}
							/>
						</Accordion>

						<Accordion defaultExpanded elevation={0} sx={accordionSx}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls='panel1a-content'
								id='panel1a-header'
							>
								<Typography variant='body1' sx={{ textTransform: 'uppercase' }}>
									{intlMsg('formTemplate.picture')}
								</Typography>
							</AccordionSummary>

							<FieldsList
								appendField={appendField}
								snapshot={snapshot}
								getIcon={getIconByFieldsType}
								fields={photoListConfig}
							/>
						</Accordion>

						<Accordion defaultExpanded elevation={0} sx={accordionSx}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls='panel1a-content'
								id='panel1a-header'
							>
								<Typography variant='body1' sx={{ textTransform: 'uppercase' }}>
									{intlMsg('formTemplate.layout')}
								</Typography>
							</AccordionSummary>

							<FieldsList
								appendField={appendField}
								snapshot={snapshot}
								getIcon={getIconByFieldsType}
								fields={layoutListConfig}
							/>
						</Accordion>

						<Accordion defaultExpanded elevation={0} sx={accordionSx}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls='panel1a-content'
								id='panel1a-header'
							>
								<Typography variant='body1' sx={{ textTransform: 'uppercase' }}>
									{intlMsg('formTemplate.modelField')}
								</Typography>
							</AccordionSummary>

							<FieldsList
								appendField={appendField}
								snapshot={snapshot}
								getIcon={getIconByFieldsType}
								fields={fieldsModelListConfig}
								fullWidth
							/>
						</Accordion>
					</div>
				)}
			</Droppable>
		</Stack>
	)
}

export default FormTemplateFieldsList
