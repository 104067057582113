import React, { cloneElement, useContext, useEffect, useState } from 'react'
import { useLazyAgencyForModal } from '../../../../../api/gql/queries/agencyQueries'
import { StepperContext } from '../../../../../components/forms/formStepper/StepperContext'
import { getAgencyValues } from './agencyUtils'
import { defaultCountry } from '../../../../../utils/tabCountries'

interface IAgencyStepperProvider {
	agencyId?: string | null
	children
	selectedNetwork?
}

const AgencyStepperProvider: React.FC<IAgencyStepperProvider> = ({
	agencyId,
	children,
	selectedNetwork,
}) => {
	const { setValues, setStartingData } = useContext(StepperContext)
	const [isLoading, setIsLoading] = useState(true)
	const { loadingAgency, agency, getAgency } = useLazyAgencyForModal()

	useEffect(() => {
		if (!loadingAgency && agency) {
			const agencyValues = getAgencyValues(agency)
			const tmp: any = []
			Object.entries(agencyValues).forEach(([key, value]) => {
				tmp.push({ key, value })
			})
			setValues(tmp)
			setStartingData(agencyValues)
			setIsLoading(false)
		}
	}, [agency, loadingAgency])

	useEffect(() => {
		// if agencyId is edit mode
		if (agencyId) getAgency({ variables: { id: agencyId } })
		// else is creation mode
		else {
			setValues([
				{
					key: 'networks',
					value: [
						{
							label: selectedNetwork?.name,
							key: selectedNetwork?.name,
							value: selectedNetwork?.id,
						},
					],
				},

				{ key: 'country', value: defaultCountry?.code },
				{ key: 'adminCanLogin', value: true },
				{ key: 'isActive', value: true },
			])
			setIsLoading(false)
		}
	}, [agencyId, selectedNetwork])

	return cloneElement(children, { loading: isLoading })
}

export default AgencyStepperProvider
