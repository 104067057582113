/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import dayjs from 'dayjs'
import { read, utils } from 'xlsx'

const EXTENSIONS = ['xlsx', 'xls', 'csv']

/////////////////////////////////////////////////////
// !IMPORTANT To fix library date format
const ExcelDateToJSDate = (serial) => {
	const utc_days = Math.floor(serial - 25569)
	const utc_value = utc_days * 86400
	const date_info = new Date(utc_value * 1000)

	const fractional_day = serial - Math.floor(serial) + 0.0000001

	let total_seconds = Math.floor(86400 * fractional_day)

	const seconds = total_seconds % 60

	total_seconds -= seconds

	const hours = Math.floor(total_seconds / (60 * 60))
	const minutes = Math.floor(total_seconds / 60) % 60

	return new Date(
		date_info.getFullYear(),
		date_info.getMonth(),
		date_info.getDate(),
		hours,
		minutes,
		seconds
	)
}

/////////////////////////////////////////////////////

const getExention = (file) => {
	const parts = file.name.split('.')
	const extension = parts[parts?.length - 1]
	return EXTENSIONS.includes(extension) // return boolean
}

const convertToJson = (headers, data) => {
	const rows = [] as any
	data.forEach((row) => {
		const rowData = {} as any
		row.forEach((element, index) => (rowData[headers[index]] = element))
		rows.push(rowData)
	})
	return rows
}

// extract data from excel
export const importExcel = (data, setData, setColDefs) => {
	const file = data

	const reader = new FileReader()
	reader.onload = (event) => {
		//parse data
		const bstr = event?.target?.result
		const workBook = read(bstr, {
			type: 'binary',
		})

		//get first sheet
		const workSheetName = workBook.SheetNames[0]
		const workSheet = workBook.Sheets[workSheetName]
		//convert to array
		const fileData = utils.sheet_to_json(workSheet, { header: 1 })

		const headers = fileData[0] as any
		const heads = headers?.map((head) => head)
		setColDefs(heads)

		//removing header
		fileData.splice(0, 1)

		setData(convertToJson(headers, fileData) as any)
	}

	if (file) {
		if (getExention(file)) reader.readAsBinaryString(file)
	} else {
		setData([] as any)
		setColDefs([] as any)
	}
}

const checkIfColumnHaveTheGoodNameExpected = (expectedCol, colSend) => {
	return expectedCol.filter(
		(colA) =>
			!colSend?.map((a) => a.toUpperCase()).includes(colA.colName.toUpperCase())
	)?.length
}

const checkIfDataRequiredAreNotEmpty = (expectedCol, dataTable) => {
	const requiredColumns = expectedCol.filter(({ required }) => required)
	let result = false

	dataTable?.forEach((data: any) => {
		if (Object.keys(data)?.length !== 0) {
			requiredColumns?.forEach(({ colName, conditionalRequire }) => {
				if (!data[colName] && !data[conditionalRequire]) {
					result = true
				}
			})
		}
	})

	return result
}

// check if it's the good template excel use for import
export const checkIfExcelIsValid = (expectedCol, colSend, dataTable) => {
	if (checkIfColumnHaveTheGoodNameExpected(expectedCol, colSend)) {
		return 'errors.errorExcel'
	} else if (checkIfDataRequiredAreNotEmpty(expectedCol, dataTable)) {
		return 'errors.errorExcelRequiredValues'
	}

	return
}

// convert data with the correct type
const convertDataWithTheCorrectType = (data, type) => {
	switch (type) {
		case 'int':
			return data && parseInt(data)
		case 'date':
			return data && dayjs(ExcelDateToJSDate(data)).format(`DD/MM/YYYY`)
		default:
			return data?.toString()
	}
}

// format data to be send to querie mutation
export const finalImport = (
	additionalValues: any,
	dataTable,
	colDefs,
	importConfig
) => {
	const result: any = []

	dataTable?.forEach((data: any) => {
		if (Object.keys(data)?.length !== 0) {
			let row = {} as any
			colDefs?.map((col: any) => {
				const dataConfig = importConfig.find(
					(config) => config?.colName?.toUpperCase() === col?.toUpperCase()
				) as any

				if (dataConfig) {
					row = {
						...row,
						[dataConfig.argumentName]: convertDataWithTheCorrectType(
							data[col],
							dataConfig.type
						),
						...additionalValues,
					}
				}
			}) as any

			result.push(row)
		}
	})

	return result
}

export const sortByKey = (key) => (a, b) => {
	if (a[key] < b[key]) return -1
	if (a[key] > b[key]) return 1
	return 0
}
