import React, { useContext, useMemo } from 'react'
import AlerteMessage from '../../../../../../components/alert/AlerteMessage'
import { useFormatMessage } from '../../../../../../utils/hooks'
import { FormModalContext } from '../../../../../../components/forms/formModal/FormModalContext'

export const BannerFormModalIntervention: React.FC = () => {
	const { curentEditedElement } = useContext(FormModalContext)
	const intlMsg = useFormatMessage()

	const isSharedIntervention = useMemo(
		() => curentEditedElement?.isSharedWithMe || false,
		[curentEditedElement?.isSharedWithMe]
	)

	// Dynamic variable for AlertMessage component
	const name = useMemo(
		() =>
			`<span style='color: black; font-size: 14px;'>${curentEditedElement?.createdByAccount?.name}</span>`,
		[curentEditedElement?.createdByAccount]
	)
	const message = useMemo(
		() => intlMsg('misc.bannerMessageFormModalIntervention', { name }),
		[name]
	)
	return (
		<>
			{isSharedIntervention && (
				<AlerteMessage message={message} sx={{ paddingTop: 2 }} />
			)}
		</>
	)
}
