import React, { useCallback, useContext } from 'react'

import {
	Box,
	Stack,
	Paper,
	Divider,
	Typography,
	Button,
	IconButton,
	Fade,
} from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import EditIcon from '@mui/icons-material/Edit'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

import { useWVehicleMouvment } from '../../../api/gql/queries/vehiclesQueries'
import { useFormatMessage } from '../../../utils/hooks'
import LocationVehiclePopover from '../../popover/locationVehiclePopover'
import {
	EAccountType,
	EFormModalMode,
	EPermissionScope,
} from '../../../utils/app-models'
import { countNumberDaysPeriodInterval, formatDate } from '../../../utils/date'
import WidgetIconTitle from '../elements/WidgetIconTitle'
import { ModalContext } from '../../../utils/contexts/ModalContext'
import WVehicleTrackingModal from './wVehicleTrackingModal'
import PermissionsHandler from '../../permissionsHandler'
import { AuthContext } from '../../../utils/contexts/AuthContext'
import CircularProgressWidget from '../elements/CircularProgressWidget'

interface IWVehicleTrackingContent {
	vehicleId: string
}

const WVehicleTrackingContent: React.FC<IWVehicleTrackingContent> = ({
	vehicleId,
}) => {
	const intlMsg = useFormatMessage()
	const { currentUser } = useContext(AuthContext)
	const { vehicle, loading } = useWVehicleMouvment({
		variables: {
			id: vehicleId,
		},
	})
	const { setMode, isVisible, openModal } = useContext(ModalContext)

	const handleOpenModalClick = useCallback(
		(mode) => () => {
			setMode(mode)
			openModal()
		},
		[setMode]
	)

	const diffDay =
		countNumberDaysPeriodInterval(
			vehicle?.lastVehicleMovement?.entryDate,
			vehicle?.lastVehicleMovement?.exitDate || new Date()
		) + 1

	return (
		<Paper elevation={1} sx={{ height: 1, position: 'relative' }}>
			<Stack p={2} pb={0} justifyContent='center' sx={{ height: 1 }}>
				<Fade in={!loading}>
					<Stack>
						<Stack
							direction='row'
							justifyContent='space-between'
							alignItems='center'
						>
							<WidgetIconTitle
								icon={{
									iconColor: 'yellow',
									Icon: LocationOnIcon,
								}}
								title={intlMsg('widget.locationMonitoring')}
							/>
							{currentUser?.account?.accountType?.code !==
								EAccountType.guest && (
								<Box>
									<LocationVehiclePopover
										location={
											vehicle?.vehicleLocations?.collection?.[0]
												?.location as any
										}
										color={vehicle?.vehicleLocations?.collection?.[0]?.color}
										id={vehicle?.id}
										permissions={[EPermissionScope.vehicleUpdate]}
										displayEmptyLocationLongText
									/>
								</Box>
							)}
						</Stack>

						<Divider sx={{ mb: 0, mt: 2 }} />

						<Stack pt={2} pb={2} spacing={1} position='relative'>
							<Stack
								justifyContent='space-between'
								direction='row'
								alignItems='center'
							>
								<Stack direction='row' alignItems='center'>
									<Typography variant='body2' sx={{ minWidth: '130px' }}>
										{`${intlMsg('widget.dateEntry')} `}
									</Typography>

									<Typography variant='body2' sx={{ fontWeight: 'bold' }}>
										{vehicle?.lastVehicleMovement?.entryDate
											? formatDate(
													vehicle?.lastVehicleMovement?.entryDate as any
											  )
											: '-'}
									</Typography>
								</Stack>

								{vehicle?.lastVehicleMovement?.id && (
									<PermissionsHandler
										permissionsName={[EPermissionScope.vehicleUpdate]}
									>
										<IconButton
											color='primary'
											onClick={handleOpenModalClick(EFormModalMode.edit)}
											sx={{ position: 'absolute', right: 0 }}
										>
											<EditIcon />
										</IconButton>
									</PermissionsHandler>
								)}
							</Stack>

							<Stack direction='row' alignItems='center'>
								<Typography variant='body2' sx={{ minWidth: '130px' }}>
									{`${intlMsg('widget.releaseDate')} `}
								</Typography>
								<Typography variant='body2' sx={{ fontWeight: 'bold' }}>
									{vehicle?.lastVehicleMovement?.exitDate
										? formatDate(vehicle?.lastVehicleMovement?.exitDate as any)
										: '-'}
								</Typography>
							</Stack>

							<Stack direction='row' alignItems='center'>
								<Typography variant='body2' sx={{ minWidth: '130px' }}>
									{intlMsg('widget.presenceTime')}
								</Typography>
								<Typography variant='body2' sx={{ fontWeight: 'bold' }}>{`${
									vehicle?.lastVehicleMovement?.entryDate
										? `${diffDay < 1 ? 0 : diffDay} ${intlMsg('misc.days')}`
										: '-'
								}`}</Typography>
							</Stack>
						</Stack>

						<Divider sx={{ mb: 0, mt: 0 }} />

						<Stack direction='row' justifyContent='space-between' pt={2} pb={2}>
							<PermissionsHandler
								permissionsName={[EPermissionScope.vehicleUpdate]}
							>
								<Button
									variant='text'
									color='primary'
									startIcon={<AddCircleOutlineIcon />}
									size='small'
									onClick={handleOpenModalClick(EFormModalMode.create)}
								>
									{intlMsg('widget.addTracking')}
								</Button>
							</PermissionsHandler>
							<Button
								disabled
								variant='text'
								color='primary'
								endIcon={<ArrowForwardIcon />}
								size='small'
							>
								{intlMsg('widget.historicMileage')}
							</Button>
						</Stack>
					</Stack>
				</Fade>
				{loading && <CircularProgressWidget />}
			</Stack>
			{isVisible && (
				<WVehicleTrackingModal
					editedVehicleTrackingId={vehicle?.lastVehicleMovement?.id}
					vehicleId={vehicle?.id}
				/>
			)}
		</Paper>
	)
}

export default WVehicleTrackingContent
