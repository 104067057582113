import React from 'react'

import { useNavigate, useLocation } from 'react-router-dom'

import { List, ListItemButton } from '@mui/material'
import ListItemText from '@mui/material/ListItemText'

import { handleOpenViewModal } from '../../../../utils/utils'

const ListCSS = {
	'& .MuiListItemButton-root:hover': { color: '#2F5DD1' },
	'& .MuiButtonBase-root.Mui-disabled': { opacity: 1 },
}

interface ISimpleListItems {
	data: any[]
	viewModalLink?
	haveAccess?
	disabledClick?: boolean
	handleClose
}

const SimpleListItems: React.FC<ISimpleListItems> = ({
	data,
	viewModalLink,
	haveAccess,
	disabledClick = false,
	handleClose,
}) => {
	const navigate = useNavigate()
	const location: any = useLocation()

	return (
		<List dense disablePadding sx={ListCSS}>
			{data.map(({ id, name }) => {
				return (
					<ListItemButton
						key={id}
						disabled={!haveAccess || disabledClick}
						onMouseDown={
							!disabledClick &&
							viewModalLink &&
							haveAccess &&
							handleOpenViewModal(
								navigate,
								location,
								viewModalLink + id,
								handleClose
							)
						}
					>
						<ListItemText primary={name} />
					</ListItemButton>
				)
			})}
		</List>
	)
}

export default SimpleListItems
