// ////////////////////////////////////////////////////////////////
// Utils function use to count the nb photos
// ////////////////////////////////////////////////////////////////

// return a positive or negative value
export const countNbPhotos = (fieldAttributes, defaultValues, isRemoval) => {
	const { picturesList, isFieldDeleted } = fieldAttributes?.picturesList
		? fieldAttributes
		: JSON.parse(fieldAttributes)

	// if defaultValues exist we edit a field picture on drawer panel
	if (defaultValues?.picturesList) {
		// if we delete or add a pictures
		if (
			defaultValues.picturesList?.length > picturesList?.length ||
			defaultValues.picturesList?.length < picturesList?.length
		) {
			return picturesList?.length - defaultValues?.picturesList?.length
		}
		// else don't modify nb pictures
		return 0
	}

	// else user add/duplicate new field picture or delete this field(s)
	return isFieldDeleted || isRemoval
		? -picturesList?.length
		: picturesList?.length
}

// ////////////////////////////////////////////////////////////////
// Update limitation picture
// ////////////////////////////////////////////////////////////////

export const setLimitationPicture = ({
	setLimitationFields,
	fieldType,
	fieldAttributes,
	defaultValues,
	isRemoval,
}) => {
	setLimitationFields((prev) => {
		const newCount =
			prev[fieldType].currentPhotos +
			countNbPhotos(fieldAttributes, defaultValues, isRemoval)

		const isLimitReached = newCount >= prev[fieldType].maxPhotos

		return {
			...prev,
			[fieldType]: {
				...prev[fieldType],
				currentPhotos: newCount,
				isLimitReached,
			},
		}
	})
}
