export default {
	profileUpdate: 'Profil mis à jour',
	notificationUpdate: 'Modifications enregistrées',
	userAccess: 'Accès mis à jour',
	statusUpdate: 'Statut mis à jour',
	passwordUpdate: 'Mot de passe modifié',

	siteCreate: 'Site créé avec succès',
	siteUpdate: 'Site mis à jour',
	siteDeleted: 'Site supprimé',
	sitesDeleted: 'Sites supprimés',

	contactCreate: 'Contact ajouté',
	contactUpdate: 'Contact mis à jour',
	contactDeleted: 'Contact supprimé',
	contactsDeleted: 'Contacts supprimés',
	contactIsShare: 'Invitation envoyée',

	accountCreate: 'Compte ajouté',
	accountUpdate: 'Compte mis à jour',
	accountDeleted: 'Compte supprimé',
	accountsDeleted: 'Comptes supprimés',

	teamCreate: 'Equipe créée avec succès',
	teamUpdate: 'Equipe mis à jour',
	teamDeleted: 'Equipe supprimée',
	teamsDeleted: 'Equipes supprimées',

	userCreate: 'Utilisateur créé avec succès',
	userUpdate: 'Utilisateur mis à jour',
	userDeleted: 'Utilisateur supprimé',
	usersDeleted: 'Utilisateurs supprimés',

	vehicleCreate: 'Véhicule créé avec succès',
	vehicleUpdate: 'Véhicule mis à jour',
	vehicleDeleted: 'Véhicule supprimé',
	vehiclesDeleted: 'Véhicules supprimés',

	templateFormCreate: 'Modèle de formulaire créé avec succès',
	templateFormUpdate: 'Modèle de formulaire mis à jour',
	formTemplateDeleted: 'Modèle de formulaire supprimé',
	formTemplatesDeleted: 'Modèles de formulaire supprimés',

	interventionCreate: 'Intervention créée avec succès',
	interventionUpdate: 'Intervention mise à jour',
	interventionStatusUpdate: "Statut de l'intervention mis à jour",
	interventionDeleted: 'Intervention supprimée',
	interventionsDeleted: 'Interventions supprimées',

	transportCreate: 'Transport créé avec succès',
	transportUpdate: 'Transport mis à jour',
	transportStatusUpdate: 'Statut du transport mis à jour',
	transportDeleted: 'Transport supprimé',
	transportsDeleted: 'Transports supprimés',

	importExcel: 'Import réussi',

	adminPanelUpdateAccount: 'Compte mis à jour',

	categoryCreate: 'Catégorie créée avec succès',
	categoryUpdate: 'Catégorie mise à jour',
	productCreate: 'Prestation créée avec succès',
	productUpdate: 'Prestation mise à jour',
	productBaseCreate: 'Gamme créée avec succès',
	productBaseUpdate: 'Gamme mise à jour',
	categoryOrderUpdate: "L'ordre des catégories est mis à jour",
	productOrderUpdate: "L'ordre des prestations est mis à jour",

	alertUpdated: 'Alerte mise à jour',
	alertDeleted: 'Alerte supprimée',
	alertsDeleted: 'Alertes supprimées',

	interventionFormDeleted: 'Intervention supprimée',
	interventionsFormDeleted: 'Interventions supprimées',

	agencyCreate: 'Agence créee avec succès',
	agencyUpdate: 'Agence mise à jour',
	agencyDeleted: 'Agence supprimée',
	agenciesDeleted: 'Agences supprimées',

	networkCreate: 'Réseau créé avec succès',
	networkUpdate: 'Réseau mis à jour',
	networkDeleted: 'Réseau supprimé',

	locationUpdate: 'Emplacement véhicule mis à jour',

	mileageCreate: 'Relevé kilométrique créé avec succès',

	vehicleMovementCreate: 'Suivi du vehicule créé avec succès',
	vehicleMovementUpdate: 'Suivi du vehicule mise à jour',

	invoiceCreate: 'Dépense créé avec succès',
	invoiceUpdate: 'Dépense mise à jour',
	invoiceDeleted: 'Dépense supprimée',
	invoicesDeleted: 'Dépenses supprimées',

	documentVehicleCreate: 'Document créé avec succès',
	documentVehicleUpdate: 'Document mise à jour',
	documentVehicleDeleted: 'Document supprimée',
	documentVehiclesDeleted: 'Documents supprimées',

	customerAccountRestored:
		'Un compte client désactivé déjà existant a été restauré et mise à jour',

	categoryDeleted: 'Catégorie supprimée',
}
