import React, { useCallback, useContext, useMemo, useState } from 'react'

import Toolbar from '@mui/material/Toolbar'
import AppBar from '@mui/material/AppBar'
import { Box, Link, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import AddCircleIcon from '@mui/icons-material/AddCircle'

import NavTopAddActionMenu from './NavTopAddActionMenu'
import NavTopProfileMenu from './NavTopProfileMenu'
import { AuthContext } from '../../../utils/contexts/AuthContext'
import UserAvatar from '../../avatar/UserAvatar'
import PermissionsHandler from '../../permissionsHandler'
import { useFormatMessage } from '../../../utils/hooks'
import { NavTopCreatePermissions, NavTopCss } from './navTopUtils'

interface INavTop {}

const NavTop: React.FC<INavTop> = () => {
	const intlMsg = useFormatMessage()
	const { currentUser, navIsOpen, setNavIsOpen } = useContext(AuthContext)
	const [anchorElProfile, setAnchorElProfile] = useState<
		HTMLElement | undefined
	>(undefined)
	const [anchorElAddAction, setAnchorElAddAction] = useState<
		HTMLElement | undefined
	>(undefined)

	const navIsOpenSx = useMemo(
		() => ({
			iconButtonSx: {
				...NavTopCss.menuButtonCSS,
				...(navIsOpen && NavTopCss.menuButtonTransformCSS),
			},
			appBarSx: {
				...(navIsOpen ? NavTopCss.appBarOpenCSS : NavTopCss.appBarCloseCSS),
			},
		}),
		[navIsOpen]
	)

	const userName = useMemo(
		() => ({
			firstName: currentUser?.firstName,
			lastName: currentUser?.lastName,
		}),
		[currentUser]
	)

	const handleOpenNavSideBar = useCallback(() => {
		setNavIsOpen(!navIsOpen)
	}, [navIsOpen])

	const handleOpenProfileNav = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			setAnchorElProfile((prevAnchorElProfile) =>
				!prevAnchorElProfile ? event.currentTarget : undefined
			)
		},
		[]
	)

	const handleOpenAddAction = useCallback(
		(event: React.MouseEvent<HTMLElement>) =>
			setAnchorElAddAction((prevAnchorElAddAction) =>
				!prevAnchorElAddAction ? event.currentTarget : undefined
			),
		[]
	)

	return (
		<>
			<AppBar
				position='fixed'
				sx={navIsOpenSx.appBarSx}
				color='inherit'
				elevation={6}
			>
				<Toolbar sx={NavTopCss.toolbarSx}>
					<IconButton
						color='inherit'
						aria-label='open drawer'
						onClick={handleOpenNavSideBar}
						sx={navIsOpenSx.iconButtonSx}
						size='large'
					>
						<MenuOpenIcon />
					</IconButton>

					<Box sx={NavTopCss.toolbarBoxSx}>
						{currentUser?.demandOfServiceExtLink && (
							<Link
								className='link'
								target='_blank'
								href={currentUser?.demandOfServiceExtLink}
								sx={NavTopCss.orderServiceButton}
							>
								<ShoppingCartIcon className='icon' />
								<Typography color='initial' className='typo'>
									{intlMsg('misc.orderService')}
								</Typography>
							</Link>
						)}

						<PermissionsHandler permissionsName={NavTopCreatePermissions}>
							<IconButton
								color='inherit'
								sx={NavTopCss.addBtnIconCSS}
								onClick={handleOpenAddAction}
								size='large'
							>
								<AddCircleIcon />
							</IconButton>
						</PermissionsHandler>

						<Box sx={NavTopCss.wrapperAvatarCSS} onClick={handleOpenProfileNav}>
							<UserAvatar userName={userName} displayName />
						</Box>
					</Box>
				</Toolbar>
			</AppBar>
			{!!anchorElAddAction && (
				<NavTopAddActionMenu
					handleOpenAddAction={handleOpenAddAction}
					anchorElAddAction={anchorElAddAction}
				/>
			)}

			{!!anchorElProfile && (
				<NavTopProfileMenu
					handleOpenProfileNav={handleOpenProfileNav}
					anchorElProfile={anchorElProfile}
					displayedId={currentUser?.displayedId}
					emailUser={currentUser?.email}
				/>
			)}
		</>
	)
}

export default NavTop
