/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'

import { ALERT_COUNT_BY_INTERVENTION } from '../../../api/gql/queries/alertsQueries'
import {
	INTERVENTION_FORM_PHOTOS_COUNT,
	INTERVENTION_FORMS_COUNT_BY_INTERVENTION,
} from '../../../api/gql/queries/interventionFormsQueries'

import { INTERVENTION_COUNT } from '../../../api/gql/queries/interventionsQueries'
import { INVOICES_COUNT_BY_INTERVENTION } from '../../../api/gql/queries/invoicesQueries'
import interventionsListAlertsViewModalTable from '../../../components/tableV2/config/dashboard/alerts/interventionsListAlertsViewModalTable'
import interventionsListInterventionControlFormsViewModalTable from '../../../components/tableV2/config/dashboard/interventionForms/interventionsListInterventionControlFormsViewModalTable'
import interventionsControlListVehiclesViewModalTable from '../../../components/tableV2/config/dashboard/vehicles/interventionsControlListVehiclesViewModalTable'
import { EPeriodFilter } from '../../../utils/app-models'
import Alerts from '../../dashboard/alerts'
import Invoices from '../../dashboard/invoice'
import InterventionsByVehicle from '../../dashboard/interventions/interventionControl/byVehicle'
import InterventionControlForms from '../../dashboard/interventionForms'
import PhotosGroupedByVehicle from '../customTabs/interventionFormsPhotos/photosGroupedByVehicle'
import interventionListInvoicesViewTable from '../../../components/tableV2/config/dashboard/invoices/interventionListInvoicesViewTable'

// ////////////////////////////////////////////////////////////////////////////////////////////////////////
// INTERVENTIONS TAB CONFIG
// ////////////////////////////////////////////////////////////////////////////////////////////////////////

export const interventionTabsConfig = [
	{
		label: 'misc.vehicles',
		query: INTERVENTION_COUNT,
		argumentQueryCount: 'intervention',
		queryName: 'interventionProgrammedVehicles',
		component: (
			<InterventionsByVehicle
				overrideConfigTable={{
					tableConfig: interventionsControlListVehiclesViewModalTable,
					argumentQueryViewModal: 'intervention',
					elevationNbr: 0,
					hidden: {
						tableNavTab: true,
						tableToolBar: { tableBtnCreate: true, filter: true },
						tableToolBarAction: { all: true },
					},
					localStorageName: 'interventionsListVehiclesViewModalTableFilters',
					tableContainer: { height: '56vh' },
				}}
				overrideTableProvider={{
					customLocalStorageKeyFilter:
						'interventionsListVehiclesViewModalFilters',
					customDefaultFilterConfig: {
						period: EPeriodFilter.allPeriod,
						startFrom: null,
						endTo: null,
					},
				}}
				isInViewModal
			/>
		),
	},

	{
		label: 'interventionForms.reports',
		query: INTERVENTION_FORMS_COUNT_BY_INTERVENTION,
		argumentQueryCount: 'intervention',
		queryName: 'forms',
		component: (
			<InterventionControlForms
				overrideConfigTable={{
					tableConfig: interventionsListInterventionControlFormsViewModalTable,
					argumentQueryViewModal: 'intervention',
					elevationNbr: 0,
					localStorageName:
						'interventionsListInterventionFormsViewModalTableFilters',
					hidden: { tableToolBar: { filter: true } },
					tableContainer: { height: '56vh' },
				}}
				overrideTableProvider={{
					customLocalStorageKeyFilter:
						'interventionsListInterventionFormsViewModalFilters',
					customDefaultFilterConfig: {
						period: EPeriodFilter.allPeriod,
						startFrom: null,
						endTo: null,
					},
				}}
				isInViewModal
			/>
		),
	},

	{
		label: 'misc.photos',
		query: INTERVENTION_FORM_PHOTOS_COUNT,
		argumentQueryCount: 'intervention',
		queryName: 'picturedForms',
		component: <PhotosGroupedByVehicle queryPropertyName='intervention' />,
	},

	{
		label: 'misc.alerts',
		query: ALERT_COUNT_BY_INTERVENTION,
		argumentQueryCount: 'intervention',
		queryName: 'alerts',
		component: (
			<Alerts
				overrideConfigTable={{
					tableConfig: interventionsListAlertsViewModalTable,
					argumentQueryViewModal: 'intervention',
					elevationNbr: 0,
					localStorageName: 'interventionsListAlertsViewModalTableFilters',
					hidden: { tableToolBar: { filter: true } },
				}}
				overrideTableProvider={{
					customLocalStorageKeyFilter:
						'interventionsListAlertsViewModalFilters',
					customDefaultFilterConfig: {
						period: EPeriodFilter.allPeriod,
						startFrom: null,
						endTo: null,
					},
				}}
				isInViewModal
			/>
		),
	},

	{
		label: 'invoice.invoice',
		query: INVOICES_COUNT_BY_INTERVENTION,
		argumentQueryCount: 'intervention',
		queryName: 'invoices',
		component: (
			<Invoices
				overrideConfigTable={{
					tableConfig: interventionListInvoicesViewTable,
					argumentQueryViewModal: 'intervention',
					elevationNbr: 0,
					localStorageName: 'interventionsListInvoicesViewModalTableFilters',
					hidden: { tableToolBar: { filter: true } },
				}}
				overrideTableProvider={{
					customLocalStorageKeyFilter:
						'interventionsListInvoicesViewModalFilters',
				}}
				isInViewModal
			/>
		),
	},

	// {
	// 	label: 'documentVehicle.documents',
	// 	query: DOCUMENTS_VEHICLES_COUNT_BY_INTERVENTION,
	// 	argumentQueryCount: 'intervention',
	// 	queryName: 'documents',
	// 	component: (
	// 		<VehiclesDocuments
	// 			overrideConfigTable={{
	// 				tableConfig: listDocumentsVehiclesViewModalTable,
	// 				argumentQueryViewModal: 'intervention',
	// 				elevationNbr: 0,
	// 				localStorageName: 'interventionsListDocumentsViewModalTableFilters',
	// 			}}
	// 			overrideTableProvider={{
	// 				customLocalStorageKeyFilter:
	// 					'vehiclesListDocumentsVehiclesViewModalFilters',
	// 			}}
	// 			isInViewModal
	// 		/>
	// 	),
	// },
]
