import React, { useCallback, useState, useContext } from 'react'

import toast from 'react-hot-toast'

import { green as Green, pink as Pink } from '@mui/material/colors'
import Button from '@mui/material/Button'

import { IModalUtils, useFormatMessage, useModal } from '../../utils/hooks'
import { AuthContext } from '../../utils/contexts/AuthContext'
import PopoverBoolean from '../popover/PopoverBoolean'

const booleanBtnCSS = (value, permissions) => {
	return {
		width: 'min-content',
		background: value ? `${Green[500]} !important` : `${Pink[500]} !important`,

		'&:hover': {
			background: `${value ? Green[800] : Pink[800]} !important`,
		},

		'&.MuiButton-contained.Mui-disabled': {
			color: !permissions ? 'white !important' : 'inherit',
		},
	}
}

interface IBooleanColorPopover {
	id?
	value: boolean
	txt?
	queryConfig?
	titlePopover?
	permissions?
	confirmModal?
}

const BooleanColorPopover: React.FC<IBooleanColorPopover> = ({
	id,
	value,
	txt,
	queryConfig,
	titlePopover,
	permissions,
	confirmModal,
}) => {
	const { checkPermissions } = useContext(AuthContext)
	const haveAccess = checkPermissions(permissions)
	const intlMsg = useFormatMessage()

	///////////////////////////////////////////////////////////////

	const [updateMutation] = queryConfig.query()

	const update = useCallback(async (value) => {
		haveAccess &&
			(await updateMutation({
				variables: {
					input: {
						id,
						[queryConfig?.attribute || 'isActive']: value,
					},
				},
				refetchQueries: queryConfig.refreshQuery,
			}).then(() => toast.success(queryConfig.successMessage)))
	}, [])

	///////////////////////////////////////////////////////////////

	const confirmStatusModalUtils: IModalUtils | null = confirmModal
		? useModal()
		: null

	const [anchorEl, setAnchorEl] = useState(null)
	const handleClick = useCallback(
		(e) => haveAccess && setAnchorEl(e.currentTarget),
		[]
	)
	const handleClose = useCallback(() => setAnchorEl(null), [])

	const handleRadioChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			if (confirmModal && event.currentTarget.id === confirmModal.openOnValue) {
				confirmStatusModalUtils?.openModal()
			} else {
				update(event.currentTarget.id === 'true')
				setAnchorEl(null)
			}
		},
		[]
	)

	const onConfirmStatusModal = useCallback(() => {
		update(confirmModal.openOnValue === 'true')
		confirmStatusModalUtils?.closeModal()
		handleClose()
	}, [handleClose, update, confirmStatusModalUtils])

	return (
		<>
			<Button
				onClick={handleClick}
				variant='contained'
				color='secondary'
				size='small'
				fullWidth
				disabled={!haveAccess}
				sx={{ ...booleanBtnCSS(value, haveAccess) }}
			>
				{value
					? intlMsg(txt.true).toUpperCase()
					: intlMsg(txt.false).toUpperCase()}
			</Button>

			{anchorEl && (
				<PopoverBoolean
					title={titlePopover}
					handleChange={handleRadioChange}
					value={value}
					handleClose={handleClose}
					open={Boolean(anchorEl)}
					anchorEl={anchorEl}
					choiceTextContent={txt}
					confirmStatusModalUtils={confirmStatusModalUtils}
					onConfirmStatusModal={onConfirmStatusModal}
					confirmModal={confirmModal}
				/>
			)}
		</>
	)
}

export default BooleanColorPopover
