export default {
	adminAccounts: 'Administration: Accounts',
	admin: 'Administration',
	contactAdmin: 'Contact Administrator',
	nbUsers: 'Number of Users',
	nbVehicles: 'Number of Vehicles',
	nbReportsIntervention: 'Number of Intervention Forms',
	modules: 'Modules',
	editAccount: 'Edit Account',
	createAccount: 'Create Account',
}
