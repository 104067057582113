/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useContext, useMemo } from 'react'

import toast from 'react-hot-toast'

import {
	useUpdateUsers,
	useCreateUsers,
} from '../../../../api/gql/mutations/usersMutations'
import { useLazyUserModal } from '../../../../api/gql/queries/usersQueries'
import FormModal from '../../../../components/forms/formModal'
import { EFormModalMode } from '../../../../utils/app-models'
import { AuthContext } from '../../../../utils/contexts/AuthContext'
import { IModalUtils, useFormatMessage } from '../../../../utils/hooks'
import { userModalConfig, userModalConfigCurrentUser } from './userModalConfigs'
import {
	formatUserModalEditDefaultValues,
	formatUserModalOnValidateData,
} from './userModalUtils'
import customToast from '../../../../components/alert/customToast'

interface IUserModal {
	editedUserId?: string
	modalUtils: IModalUtils
	mode?: EFormModalMode
	onClose?: any
}

const UserModal: React.FC<IUserModal> = ({
	editedUserId,
	modalUtils,
	mode,
	onClose,
}) => {
	const { currentUser } = useContext(AuthContext)
	const intlMsg = useFormatMessage()
	const [updateUser] = useUpdateUsers()
	const [createUser] = useCreateUsers()
	const { getUserModal } = useLazyUserModal()

	const formModalTabConfig = useMemo(
		() =>
			currentUser?.id === editedUserId
				? userModalConfigCurrentUser
				: userModalConfig,
		[currentUser, editedUserId]
	)

	const validateModal = useCallback(
		async ({ data, setValidationLoading, closeModal }) => {
			setValidationLoading(true)

			const mutate = mode === EFormModalMode.edit ? updateUser : createUser

			await mutate({
				variables: {
					input: formatUserModalOnValidateData({
						data,
						mode,
						editedUserId,
						currentUser,
					}),
				},
				refetchQueries: [
					'getUsersList',
					'getProfileUserPermission',
					'getProfileUser',
					'getUserModal',
				],
			})
				.then(() => {
					mode === EFormModalMode.edit
						? toast.success('success.userUpdate')
						: toast.success('success.userCreate') &&
						  customToast('sendEmail', 'success.contactIsShare')

					setValidationLoading(false)
					closeModal()
				})
				.catch(() => {
					setValidationLoading(false)
				})
		},
		[editedUserId, mode]
	)

	const initializeData = useCallback(
		async ({ setValues, setLoading }) => {
			if (mode === EFormModalMode.edit) {
				setLoading(true)
				getUserModal({ variables: { id: editedUserId } })
					.then((res) => {
						const data = res?.data?.profileUser

						if (data) setValues(formatUserModalEditDefaultValues({ data }))

						setLoading(false)
					})
					.catch(() => setLoading(false))
			} else {
				setValues({
					isActive: true,
					canLogin: true,
				})
			}
		},
		[mode]
	)

	return (
		<FormModal
			modalUtils={modalUtils}
			formModalTabConfig={formModalTabConfig}
			formModalName={intlMsg(
				mode === EFormModalMode.edit
					? 'administration.editUser'
					: 'administration.createUser'
			)}
			initialMode={mode}
			onValidate={validateModal}
			validateBtnText={intlMsg('misc.validate')}
			cancelBtnText={intlMsg('misc.cancel')}
			maxWidth='550px'
			height='566px'
			initializeData={initializeData}
			onClose={onClose}
			editedElementId={editedUserId}
		/>
	)
}

export default UserModal
