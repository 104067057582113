/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import {
	LIST_VEHICLES,
	VEHICLES_NUMBERS,
} from '../../../../../../../../api/gql/queries/vehiclesQueries'
import selectVehiclesModalContentTableListConfig from './selectVehiclesModalContentTableConfigList'

////////////////////////////////////////////////////////////////
// Global variable
////////////////////////////////////////////////////////////////

const queryConfig = {
	queryExportTotalCount: VEHICLES_NUMBERS,
	query: LIST_VEHICLES,
	queryName: 'vehicles',
}

////////////////////////////////////////////////////////////////
// TableToolBarHeader CONFIG
////////////////////////////////////////////////////////////////

const tableToolBarConfig = {
	title: 'interventions.vehicles',
	tableConfig: selectVehiclesModalContentTableListConfig,
	queryConfig,
}

////////////////////////////////////////////////////////////////
// tableContainer CONFIG
////////////////////////////////////////////////////////////////

const tableContainerConfig = {
	tableConfig: selectVehiclesModalContentTableListConfig,
	colorBorderLeftSelected: '#4CAF50',
	queryConfig,
	unCard: true,
}

////////////////////////////////////////////////////////////////
// -> EXPORT CONFIG MAIN TABLE
////////////////////////////////////////////////////////////////

export const selectVehiclesModalContentTableConfig = {
	tableToolBarConfig: tableToolBarConfig,
	tableContainerConfig,
}
