import React, { useCallback } from 'react'

import { IconButton, Link, Stack } from '@mui/material'
import ShortTextIcon from '@mui/icons-material/ShortText'
import VehicleTransportPopover from './VehicleTransportPopover'

interface IVehiclesTransport {
	ipv?
}

const btnIconCSS = { borderRadius: 1, p: 0 }

const VehiclesTransport: React.FC<IVehiclesTransport> = ({ ipv }) => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

	const handleClick = useCallback((event) => {
		setAnchorEl(event.currentTarget)
	}, [])
	const handleClose = useCallback(() => {
		setAnchorEl(null)
	}, [])

	const open = Boolean(anchorEl)
	const id = open ? 'simple-popover' : undefined

	return (
		<>
			{ipv?.length ? (
				<>
					<Stack
						direction='row'
						alignItems='center'
						onClick={handleClick}
						spacing={0.25}
						mr={1}
					>
						<Link href='#' underline='hover' color='inherit'>
							{ipv[0]?.vehicle?.vehicleDetail?.immat ||
								ipv[0]?.vehicle?.vehicleDetail?.vin}
						</Link>

						<IconButton sx={btnIconCSS}>
							<ShortTextIcon />
						</IconButton>
					</Stack>
				</>
			) : (
				'-'
			)}

			{open && (
				<VehicleTransportPopover
					ipv={ipv}
					id={id}
					open={open}
					handleClose={handleClose}
					anchorEl={anchorEl}
				/>
			)}
		</>
	)
}

export default VehiclesTransport
