export default {
	form: 'Formulaire',
	ref: 'Référence :',
	name: 'Nom :',
	addBy: 'Rempli par :',
	dateCreate: 'Créé le :',
	horodatage: 'Horodatage :',
	geolocalisation: 'Coordonnées GPS :',
	vehicle: 'Véhicule :',
	modelBrand: 'Marque - Modèle :',
	vin: 'Numéro de châssis :',

	intervention: 'Intervention',
	site: 'Site :',
	address: 'Adresse :',
	dateIntervention: "Date d'intervention :",

	commentRatingTitle: 'Avis / Commentaire :',
	customerRating: 'Avis client :',
	customerComment: 'Commentaire client :',
	customerOperator: 'Commentaire intervenant :',

	signCustomer: 'Signature client',
	signOperator: 'Signature intervenant',
	signCustomerTransport: 'Signature destinataire',

	signTransportConveyer: 'Signature convoyeur',
	signTransportDest: 'Signature destinataire',

	noAlerts: 'AUCUN DÉFAUT DÉTECTÉ',
	alertsTitle: 'SYNTHÈSE',

	transportStep: 'Étape transport :',
	localeDate: 'Date et heure locale :',
	type: 'Type :',
	transport: 'Transport',
	transportRequest: 'Donneur d’ordre :',
	tentativeDate: 'Date et heure indicatives :',
	tentativeAddress: 'Adresse indicative :',
	delivery: 'Livraison',
	removal: 'Enlèvement',

	controlSkipped: 'Client signalé absent.',
	transportSkipped: 'Destintaire indiqué absent',

	contact: 'Interlocuteur :',
}
