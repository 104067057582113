import React, { useRef, useEffect } from 'react'

import { useNavigate, useLocation } from 'react-router-dom'

import { Stack, Typography, Box, Divider } from '@mui/material'
import DateRangeIcon from '@mui/icons-material/DateRange'
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder'
import FmdGoodIcon from '@mui/icons-material/FmdGood'
import BuildIcon from '@mui/icons-material/Build'

import PhotosInterventions from '../PhotosInterventions'
import { getSeparateDateAndHour } from '../../../../../utils/date'
import { ROUTES } from '../../../../../routes/consts'
import { elementIsVisible, useFormatMessage } from '../../../../../utils/hooks'
import { handleOpenViewModal } from '../../../../../utils/utils'
import CustomLink from '../../../../../components/_overrideMui/Link/CustomLink'
import { blue, grey } from '@mui/material/colors'

const borderBottomCSS = {
	borderBottom: 'solid 1px',
	borderColor: ({ colors }) => colors.greySeparator,
	pb: 4,
}
const containerPhotos = {
	flexWrap: 'wrap',
	'& > *': { mb: 1, mr: 1 },
} as const

const linkCSS = {
	textTransform: 'uppercase',
	display: 'flex',
	alignItems: 'center',
	fontSize: '0.875rem',
	gap: '4px',
	color: grey[600],
	'&:hover': {
		color: blue[700],

		'& a': {
			color: blue[700],
		},
	},
}

const iconWidth = {
	width: '1rem',
	height: '1rem',
}
interface ISectionPhotosByForm {
	form
	setTrigger
	loading
	displayInterventionRef
}

const SectionPhotosByForm: React.FC<ISectionPhotosByForm> = ({
	form,
	setTrigger,
	loading,
	displayInterventionRef,
}) => {
	const intlMsg = useFormatMessage()
	const navigate = useNavigate()
	const location = useLocation()

	const { date, hour } = getSeparateDateAndHour(form.formTemplate.dateCreated)

	// Trigger when element is visible (load new query - infiniteScroll )
	const ref: any = useRef(null)
	const inViewport = elementIsVisible(ref, '0px')

	useEffect(() => {
		setTrigger({ ref, inViewport })
	}, [inViewport, loading, ref])

	return (
		<>
			<Typography
				variant='title1'
				sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}
			>
				<CustomLink
					variant='title1'
					variantlink='primaryLink'
					onMouseDown={handleOpenViewModal(
						navigate,
						location,
						`${ROUTES.VIEWMODAL.INTERVENTION_FORMS.url}${form?.formTemplate?.idForm}`
					)}
				>
					{form?.formTemplate.name}
				</CustomLink>
			</Typography>

			<Stack spacing={2} sx={borderBottomCSS} ref={ref}>
				<Stack direction='row' spacing={2} alignItems='center'>
					{displayInterventionRef && (
						<>
							<Typography variant='text1' sx={linkCSS}>
								<BuildIcon />
								{form?.interventionType
									? intlMsg(`interventions.${form.interventionType}`)
									: intlMsg('misc.mission')}{' '}
								{' :'}
								<CustomLink
									variant='text1'
									variantlink='primaryLink'
									onMouseDown={handleOpenViewModal(
										navigate,
										location,
										`${ROUTES.VIEWMODAL.INTERVENTION_REFERENCES.url}${form?.id}`
									)}
								>
									{form?.referenceInter}
								</CustomLink>
							</Typography>

							<Divider orientation='vertical' flexItem />
						</>
					)}

					<CustomLink
						variant='text1'
						variantlink='primaryLink'
						href={`https://www.google.com/maps/?q=${form?.formTemplate.geolocation?.latitude},${form?.formTemplate.geolocation?.longitude}`}
						target='_blank'
					>
						<Typography variant='text1' sx={linkCSS}>
							<FmdGoodIcon sx={iconWidth} />
							{intlMsg('misc.localisation')}
						</Typography>
					</CustomLink>

					<Divider orientation='vertical' flexItem />

					<Typography variant='text1' sx={linkCSS}>
						<DateRangeIcon sx={iconWidth} />
						{date}
					</Typography>

					<Typography variant='text1' sx={linkCSS}>
						<QueryBuilderIcon sx={iconWidth} />
						{hour}
					</Typography>
				</Stack>

				<Box display='flex' sx={containerPhotos}>
					{form?.formTemplate.blockPhotos?.map(
						({ attributeTemplateParse, record }, index) =>
							attributeTemplateParse?.map((_value, i) => (
								<PhotosInterventions
									record={record[i]}
									attributeTemplateParse={attributeTemplateParse}
									type={form?.formTemplate.blockPhotos[index].type}
									indexImg={i}
									key={i}
								/>
							))
					)}
				</Box>
			</Stack>
		</>
	)
}

export default SectionPhotosByForm
