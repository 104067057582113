/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import { ALERT_COUNT_BY_CUSTOMER } from '../../../api/gql/queries/alertsQueries'
import { CONTACTS_NUMBERS } from '../../../api/gql/queries/contactsQueries'
import { INTERVENTION_COUNT_BY_CUSTOMER } from '../../../api/gql/queries/interventionsQueries'
import { SITES_NUMBERS } from '../../../api/gql/queries/sitesQueries'
import { VEHICLES_NUMBERS } from '../../../api/gql/queries/vehiclesQueries'
import customerListAlertsViewModalTable from '../../../components/tableV2/config/dashboard/alerts/customerListAlertsViewModalTable'
import customerListContactViewModalTable from '../../../components/tableV2/config/dashboard/customerContacts/customerListContactViewModalTable'
import customerListSitesViewModalTable from '../../../components/tableV2/config/dashboard/customerSites/customerListSitesViewModalTable'
import customerListInterventionsControlByRefViewModalTable from '../../../components/tableV2/config/dashboard/interventions/byReference/customerListInterventionsControlByRefViewModalTable'
import customerListVehiclesViewModalTable from '../../../components/tableV2/config/dashboard/vehicles/customerListVehiclesViewModalTable'
import { EModule, EPeriodFilter } from '../../../utils/app-models'

import Alerts from '../../dashboard/alerts'
import CustomerContacts from '../../dashboard/customerContacts/CustomerContacts'
import CustomerSites from '../../dashboard/customerSites'
import InterventionsByReference from '../../dashboard/interventions/interventionControl/byReference'
import ListVehicles from '../../dashboard/vehicles'
import InterventionTransport from '../../dashboard/interventions/interventionTransport'
import customerListInterventionsTransportViewModalTable from '../../../components/tableV2/config/dashboard/interventionsTransport/customerListInterventionsTransportViewModalTable'
import { INTERVENTION_TRANSPORT_COUNT_BY_CUSTOMER } from '../../../api/gql/queries/interventionsTransportQueries'

// ////////////////////////////////////////////////////////////////////////////////////////////////////////
// CUSTOMER TAB CONFIG
// ////////////////////////////////////////////////////////////////////////////////////////////////////////

export const customerAccountTabsConfig = [
	{
		label: 'misc.sites',
		query: SITES_NUMBERS,
		argumentQueryCount: 'customer',
		queryName: 'sites',
		component: (
			<CustomerSites
				overrideConfigTable={{
					tableConfig: customerListSitesViewModalTable,
					argumentQueryViewModal: 'customer',
					elevationNbr: 0,
					localStorageName: 'customerListSitesViewModalTableFilters',
					tableContainer: { height: '56vh' },
				}}
				isInViewModal
			/>
		),
	},

	{
		label: 'misc.contacts',
		query: CONTACTS_NUMBERS,
		argumentQueryCount: 'customer',
		queryName: 'contacts',
		component: (
			<CustomerContacts
				overrideConfigTable={{
					tableConfig: customerListContactViewModalTable,
					argumentQueryViewModal: 'customer',
					elevationNbr: 0,
					localStorageName: 'customerListContactViewModalTableFilters',
					tableContainer: { height: '56vh' },
				}}
				isInViewModal
			/>
		),
		modules: [EModule.crm],
	},

	{
		label: 'misc.vehicles',
		query: VEHICLES_NUMBERS,
		argumentQueryCount: 'customer',
		queryName: 'vehicles',
		component: (
			<ListVehicles
				overrideConfigTable={{
					tableConfig: customerListVehiclesViewModalTable,
					argumentQueryViewModal: 'customer',
					elevationNbr: 0,
					localStorageName: 'customerListVehiclesViewModalTableFilters',
					tableContainer: { height: '56vh' },
				}}
				isInViewModal
			/>
		),
	},

	{
		label: 'misc.interventions',
		query: INTERVENTION_COUNT_BY_CUSTOMER,
		argumentQueryCount: 'customer',
		queryName: 'interventions',
		component: (
			<InterventionsByReference
				overrideConfigTable={{
					tableConfig: customerListInterventionsControlByRefViewModalTable,
					argumentQueryViewModal: 'customer',
					elevationNbr: 0,
					hidden: { tableNavTab: true },
					localStorageName:
						'customerListInterventionsByRefViewModalTableFilters',
					tableContainer: { height: '56vh' },
				}}
				overrideTableProvider={{
					customLocalStorageKeyFilter:
						'customerListInterventionsByRefViewModalFilters',
					customDefaultFilterConfig: {
						period: EPeriodFilter.thisMonth,
					},
				}}
				isInViewModal
			/>
		),
	},

	{
		label: 'interventions.transports',
		query: INTERVENTION_TRANSPORT_COUNT_BY_CUSTOMER,
		argumentQueryCount: 'customer',
		queryName: 'interventions',
		modules: [EModule.interventionTransport],
		component: (
			<InterventionTransport
				overrideConfigTable={{
					tableConfig: customerListInterventionsTransportViewModalTable,
					argumentQueryViewModal: 'customer',
					elevationNbr: 0,
					hidden: { tableNavTab: true },
					localStorageName:
						'customerListInterventionsTransportViewModalTableFilters',
					tableContainer: { height: '56vh' },
				}}
				overrideTableProvider={{
					customLocalStorageKeyFilter:
						'customerListInterventionsTransportViewModalFilters',
					customDefaultFilterConfig: {
						period: EPeriodFilter.thisMonth,
					},
				}}
				isInViewModal
			/>
		),
	},

	{
		label: 'misc.alerts',
		query: ALERT_COUNT_BY_CUSTOMER,
		argumentQueryCount: 'customer',
		queryName: 'alerts',
		component: (
			<Alerts
				overrideConfigTable={{
					tableConfig: customerListAlertsViewModalTable,
					argumentQueryViewModal: 'customer',
					elevationNbr: 0,
					localStorageName: 'customerListAlertsViewModalTableFilters',
					tableContainer: { height: '56vh' },
				}}
				overrideTableProvider={{
					customLocalStorageKeyFilter: 'customerListAlertsViewModalFilters',
					customDefaultFilterConfig: {
						period: EPeriodFilter.thisMonth,
					},
				}}
				isInViewModal
			/>
		),
	},
]
