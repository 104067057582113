import React, { useEffect, useState } from 'react'

import qs from 'qs'
import { useLazyQuery } from '@apollo/client'

import { Badge, Box } from '@mui/material'

import { useFormatMessage } from '../../utils/hooks'

const tabLabelCSS = {
	display: 'flex',
	'& >span:first-of-type': {
		marginRight: ({ spacing }) => spacing(1),
	},
} as const

const anchorOriginTopRightCircleCSS = {
	'& .MuiBadge-badge': {
		right: 4,
		top: 8,
		position: 'relative',
		transform: 'scale(0.8) translate(50%, -50%)',
	},
}

interface IRenderTabLabel {
	label: string
	queryName: string
	query: any
	argumentQueryCount
}

const RenderTabLabel: React.FC<IRenderTabLabel> = ({
	label,
	query = null,
	queryName,
	argumentQueryCount,
}) => {
	const intlMsg = useFormatMessage()
	const [getData, { data, loading }] = useLazyQuery(query)
	const [numbers, setNumbers] = useState(0)

	useEffect(() => {
		const { varValue } = qs.parse(location.search, { ignoreQueryPrefix: true })

		getData({
			variables: {
				...(varValue && { [argumentQueryCount]: varValue }),
			},
			fetchPolicy: 'cache-and-network',
		})
	}, [])

	useEffect(() => {
		if (!loading && data) {
			setNumbers(data?.[queryName]?.paginationInfo?.totalCount)
		}
	}, [data, loading, queryName])

	return (
		<Box sx={tabLabelCSS} data-test-id={`tab${intlMsg(label)}`}>
			{numbers ? (
				<Badge
					badgeContent={numbers ? numbers : queryName ? 0 : ''}
					color='primary'
					sx={anchorOriginTopRightCircleCSS}
					max={100000}
				>
					<>{intlMsg(label)}</>
				</Badge>
			) : (
				intlMsg(label)
			)}
		</Box>
	)
}

export default RenderTabLabel
