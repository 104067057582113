import React, { cloneElement, useCallback, useMemo } from 'react'

import { IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'

import { IModalUtils, useModal } from '../../../../../../utils/hooks'
import { EFormModalMode } from '../../../../../../utils/app-models'

interface ITableSideNavItemEdit {
	modal
	isSelected?: boolean
	playwrightId?: string
}

const TableSideNavItemEdit: React.FC<ITableSideNavItemEdit> = ({
	modal,
	isSelected,
	playwrightId,
}) => {
	const modalUtils: IModalUtils = useModal(false)
	const handleEdit = useCallback(() => {
		modalUtils.openModal()
	}, [])

	const btnIconCSS = useMemo(
		() => ({
			borderRadius: 1,
			p: 0.5,
			marginRight: '5px',
			'& svg': { fontSize: '16px' },
			'&:hover': { backgroundColor: isSelected ? '#dcf0fd' : '#ebebeb' },
		}),
		[isSelected]
	)

	return (
		<>
			<IconButton
				size='small'
				onClick={handleEdit}
				sx={btnIconCSS}
				className='hoverDisplay' // display with TableSideNavItem component :hover
				data-test-id={playwrightId}
			>
				<EditIcon />
			</IconButton>

			{modalUtils?.isVisible &&
				cloneElement(modal, { modalUtils, mode: EFormModalMode.edit })}
		</>
	)
}

export default TableSideNavItemEdit
