/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

export const longTextField = (doc, record, position) => {
	const { positionXtextRecord, positionYtextRecord, maxWidth } = position

	doc.setTextColor('black')

	doc.text(record || '', positionXtextRecord, positionYtextRecord, {
		maxWidth,
		baseline: 'middle',
	})
}
