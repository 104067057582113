/* eslint-disable react/display-name */

import React from 'react'
import TasksPopover from '../../../popover/taskCategoryIntervention/tasksPopover/TasksPopover'
import LabelColor from '../../tableCell/LabelColor'

const listCategoriesProductsTableConfig = [
	{
		columnLabel: 'misc.categorie',
		orderBy: 'name',
		personalizedCell: (category) => <LabelColor category={category} />,
	},

	{
		columnLabel: 'interventions.task',
		personalizedCell: (data) => (
			<TasksPopover products={data?.products?.collection} />
		),
	},
]

export default listCategoriesProductsTableConfig
