import React, { useCallback, useMemo, useState } from 'react'

import { useNavigate, useLocation } from 'react-router-dom'
import { Stack } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import { useFormatMessage } from '../../../../utils/hooks'
import { handleOpenViewModal } from '../../../../utils/utils'
import MultiItemsButton from '../../MultiItemsButton'
import CustomLink from '../../../_overrideMui/Link/CustomLink'
import MultiItemsPopover from '..'
import { grey } from '@mui/material/colors'
import FormsListItem from './FormsListItem'
import { ROUTES } from '../../../../routes/consts'

const CheckCircleIconCSS = { fontSize: '17px', color: '#4caf50' }
const CheckCircleOutlineIconCSS = { fontSize: '17px', color: grey[500] }

interface IFormsListPopover {
	data
	text
}

const FormsListPopover: React.FC<IFormsListPopover> = ({ data, text }) => {
	const intlMsg = useFormatMessage()
	const navigate = useNavigate()
	const location: any = useLocation()
	const [anchorEl, setAnchorEl] = useState(null)
	const handleClick = useCallback((e) => setAnchorEl(e.currentTarget), [])
	const handleClose = useCallback(() => setAnchorEl(null), [])
	const open = Boolean(anchorEl)

	/////////////////////////////////////////////////////////
	// format, Merge array & Sort by done
	/////////////////////////////////////////////////////////

	const sortedFormTemplates = useMemo(() => {
		// All completed forms (create directly from app mobile or app desktop)
		const allCompletedForms = data?.forms?.collection?.map((form) => ({
			...form,
			isDone: true,
		}))

		const incomplete = data?.formTemplates?.collection
			?.filter(
				({ formTemplate: { id } }) =>
					!data.forms.collection.some(
						({ formTemplate }) => formTemplate.originalFormTemplate.id === id
					)
			)
			?.map((form) => ({ ...form, isDone: false }))

		const merged = [...allCompletedForms, ...incomplete]

		return merged
	}, [data])

	/////////////////////////////////////////////////////////
	// custom button
	/////////////////////////////////////////////////////////

	const customButton = useCallback(() => {
		const isDone = sortedFormTemplates[0]?.isDone

		return (
			<Stack spacing={0.5} direction='row' alignItems='center'>
				<CustomLink
					variantlink='primaryLink'
					disabled={!isDone}
					onMouseDown={
						isDone
							? handleOpenViewModal(
									navigate,
									location,
									ROUTES.VIEWMODAL.INTERVENTION_FORMS.url +
										sortedFormTemplates[0]?.id
							  )
							: undefined
					}
				>
					<Stack direction='row' spacing={0.5} alignItems='center'>
						{isDone ? (
							<CheckCircleIcon sx={CheckCircleIconCSS} />
						) : (
							<CheckCircleOutlineIcon sx={CheckCircleOutlineIconCSS} />
						)}
						<div>{sortedFormTemplates[0]?.formTemplate?.title}</div>
					</Stack>
				</CustomLink>
			</Stack>
		)
	}, [sortedFormTemplates, navigate, location])

	return (
		<>
			<MultiItemsButton
				buttonType={
					sortedFormTemplates?.length > 1
						? 'chipCountClickable'
						: 'customButton'
				}
				nbItems={sortedFormTemplates?.length}
				label={sortedFormTemplates?.length > 1 ? intlMsg(text) : customButton}
				handleClick={handleClick}
			/>

			{open && (
				<MultiItemsPopover
					open={open}
					handleClose={handleClose}
					anchorEl={anchorEl}
				>
					<FormsListItem data={sortedFormTemplates} handleClose={handleClose} />
				</MultiItemsPopover>
			)}
		</>
	)
}

export default FormsListPopover
