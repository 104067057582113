import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Button } from '@mui/material'

import { useFormatMessage } from '../../../utils/hooks'

const btnCSS = {
	justifyContent: 'start',
	pl: 3,
	textTransform: 'inherit',
	fontSize: ({ typography }) => typography.fontSize,
	fontWeight: ({ typography }) => typography.fontWeightRegular,
	height: '58px',
	position: 'relative',
}

const btnSelectedCSS = {
	position: 'absolute',
	borderRight: ({ palette }) => `solid 4px ${palette.primary.main}!important`,
	top: '15px',
	left: '-5px',
	borderRadius: '268px',
	width: '9px',
	height: '29px',
}

interface IAdministrationMenuRow {
	title: string
	isSelected: boolean
	path: string
	icon
}

const AdministrationMenuRow: React.FC<IAdministrationMenuRow> = ({
	title,
	isSelected,
	path,
	icon,
}) => {
	const intlMsg = useFormatMessage()
	const navigate = useNavigate()

	const handleClickMenu = useCallback(
		(isSelected, newPath) => (e) => {
			if (!isSelected) {
				e.preventDefault()
				e.ctrlKey ? window.open(newPath, '_blank') : navigate(newPath)
			}
		},
		[navigate]
	)

	return (
		<Button
			startIcon={icon}
			fullWidth
			onClick={handleClickMenu(isSelected, path)}
			sx={btnCSS}
			color={!isSelected ? 'inherit' : 'primary'}
		>
			<Box component='span'>
				{isSelected && <Box component='span' sx={btnSelectedCSS}></Box>}
				{intlMsg(title)}
			</Box>
		</Button>
	)
}

export default AdministrationMenuRow
