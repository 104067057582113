import React, { Context, useCallback, useContext, useMemo } from 'react'
import TextField from '@mui/material/TextField'
import { IFormModalContext } from '../formModal/FormModalContext'
import { IStepperContext } from '../formStepper/StepperContext'
import { handleFormOnblur } from '../formsUtils'

const inputCSS = (isDisabled, hidden) => ({
	opacity: isDisabled ? '0.5' : '1',
	display: hidden ? 'none' : 'inherit',
})

interface IFormTextField {
	name: string
	context: Context<IFormModalContext> | Context<IStepperContext>
	label?: string
	multiline?: boolean
	minRows?: number
	isRequired?: boolean
	type?: string
	size?: 'small' | 'medium'
	isDisabled?: boolean
	hidden?: boolean
	onBlur?: any
	maxRows?: number
	specialOnChange?: any
	InputLabelProps?: any
	inputProps?: any
	InputProps?: any
	debouncedStateUtils?: any
	trigerred?: boolean
}

const FormTextField: React.FC<IFormTextField> = ({
	name,
	label,
	isRequired,
	size = 'small',
	isDisabled = false,
	hidden = false,
	multiline = false,
	minRows = 0,
	maxRows = 4,
	type = 'text',
	onBlur,
	specialOnChange,
	InputLabelProps = null,
	inputProps = {},
	InputProps = {},
	debouncedStateUtils = {},
	trigerred = false,
	context,
}) => {
	const { errors, setValue, data, triggerError } = useContext<
		IFormModalContext | IStepperContext
	>(context as any)
	const { setState, liveState } = debouncedStateUtils
	const value = useMemo(() => data[name], [data[name]])

	const sx = useMemo(() => inputCSS(isDisabled, hidden), [isDisabled, hidden])
	const error = useMemo(() => errors[name], [errors[name]])

	const handleChange = useCallback(
		(e) => {
			const formattedValue =
				type === 'number'
					? e.target.value
						? parseFloat(e.target.value)
						: ''
					: e.target.value

			if (specialOnChange)
				specialOnChange({
					fieldName: name,
					option: formattedValue,
				})
			else {
				setValue(name, formattedValue)
				if (setState) setState({ ...liveState, [name]: formattedValue })
			}
		},
		[name, type, specialOnChange, setValue, setState, liveState]
	)

	const getHandleOnblur = useMemo(() => {
		return trigerred || onBlur
			? handleFormOnblur(trigerred, onBlur, name, triggerError)
			: undefined
	}, [trigerred, onBlur, name, triggerError])

	return (
		<TextField
			name={name}
			variant='outlined'
			value={value || ''}
			onChange={handleChange}
			InputLabelProps={InputLabelProps}
			multiline={multiline}
			minRows={minRows}
			maxRows={maxRows}
			error={!!error}
			label={label}
			helperText={error}
			size={size}
			disabled={isDisabled}
			fullWidth
			type={type}
			inputProps={inputProps}
			InputProps={InputProps}
			onBlur={getHandleOnblur}
			sx={sx}
			required={isRequired}
		/>
	)
}

export default FormTextField
