import React, { useCallback } from 'react'

import { Chip, List, Popover, Stack } from '@mui/material'

const StackCSS = { flexWrap: 'wrap', gap: 1 }
const PaperProps = { sx: { maxWidth: '600px' } }
const anchorOrigin: any = { vertical: 'center', horizontal: 'center' }
const transformOrigin: any = { vertical: 'center', horizontal: 'center' }

interface ITasksListItem {
	handleClose
	open
	anchorEl
	products
}

const TasksListItem: React.FC<ITasksListItem> = ({
	handleClose,
	open,
	anchorEl,
	products,
}) => {
	const renderProducts = useCallback(
		({ name }) => (
			<Chip
				key={name}
				label={name}
				color='primary'
				variant='outlined'
				size='small'
			/>
		),
		[]
	)

	return (
		<Popover
			open={open}
			anchorEl={anchorEl}
			onClose={handleClose}
			PaperProps={PaperProps}
			anchorOrigin={anchorOrigin}
			transformOrigin={transformOrigin}
		>
			<Stack p={2}>
				<List>
					<Stack direction='row' sx={StackCSS}>
						{products?.map(renderProducts)}
					</Stack>
				</List>
			</Stack>
		</Popover>
	)
}

export default TasksListItem
