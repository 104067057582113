/* FormSelectSearch.jsx
This component provides a search-select interface using MUI's Autocomplete component.
It integrates form validation, error handling, and support for custom option displays. */

import React, {
	Context,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'

import Autocomplete from '@mui/material/Autocomplete'
import Radio from '@mui/material/Radio'
import { Box, Stack } from '@mui/material'
import { IFormModalContext } from '../../formModal/FormModalContext'
import { IStepperContext } from '../../formStepper/StepperContext'
import FormSelectSearchInput from './FormSelectSearchInput'
import { handleFormOnblur } from '../../formsUtils'
import { stringifiedColor } from '../../../../utils/color'

// Specific styles for the color indicator options

const indicatorColorStatus = {
	height: '11px !important',
	marginRight: '10px',
	borderRadius: '4px',
	aspectRatio: '1/1',
}

interface IFormSelectSearch {
	name: string
	label: string
	selectElements:
		| Array<{
				label: string
				key: string
				value: string | number | boolean
		  }>
		| any

	context: Context<IFormModalContext> | Context<IStepperContext>
	customHelper?: string
	size?: 'medium' | 'small' | undefined
	isDisabled?: boolean
	hidden?: boolean
	missingText?: string
	onBlur?
	specialOnChange?
	withInputValue?: boolean
	isRequired?: boolean
	isLoading?
	prefix?: string
	browserRequired?: boolean
	renderOptionLabel?: any
	trigerred?: boolean
	playwrightId?: string
	displayChip?: boolean
	displayColorInput?: boolean
	disabledSort?: boolean
}

const FormSelectSearch: React.FC<IFormSelectSearch> = ({
	name,
	selectElements,
	label,
	customHelper,
	size,
	isDisabled,
	hidden,
	specialOnChange,
	onBlur,
	missingText,
	isRequired,
	isLoading,
	prefix,
	browserRequired = false,
	context,
	renderOptionLabel = null,
	trigerred = false,
	playwrightId = '',
	displayChip = false,
	displayColorInput = false,
	disabledSort = false,
}) => {
	const { setValue, data, errors, triggerError } = useContext<
		IFormModalContext | IStepperContext
	>(context as any)
	const selectedOption = useMemo(() => data[name] || null, [data[name]])
	const [inputValue, setInputValue] = useState<string>(data[name]?.label || '')

	const error = useMemo(() => errors[name], [name, errors])

	const sx = useMemo(
		() => ({
			'& *': { opacity: '1 !important' },
			'& legend': { opacity: '0 !important' },
			'&:hover': {
				'& .MuiOutlinedInput-notchedOutline': {
					borderColor: isDisabled ? 'rgba(0, 0, 0, 0.3) !important' : '',
				},
			},
		}),
		[isDisabled]
	)

	const getHandleOnblur = useMemo(() => {
		return trigerred || onBlur
			? handleFormOnblur(trigerred, onBlur, name, triggerError)
			: undefined
	}, [trigerred, onBlur, triggerError])

	const sortedSelectElements = useMemo(() => {
		if (!disabledSort) {
			const collator = new Intl.Collator(undefined, {
				numeric: true,
				sensitivity: 'base',
			})

			return selectElements?.slice().sort((a, b) => {
				return collator.compare(a?.label, b?.label)
			})
		}
		return selectElements
	}, [selectElements])

	const [canResetUniqueElement, setCanResetUniqueElement] =
		useState<boolean>(false)
	// Effect for managing the automatic selection of a single option if the field is required.
	useEffect(() => {
		if (isRequired) {
			if (selectElements?.length === 1 && !selectedOption) {
				const uniqueValue = selectElements[0]
				specialOnChange
					? specialOnChange({ fieldName: name, option: uniqueValue })
					: setValue(name, uniqueValue)
				setCanResetUniqueElement(true)
			}
			// to avoid entering the first condition.
			else if (
				canResetUniqueElement &&
				selectElements?.length > 1 &&
				!selectedOption
			) {
				specialOnChange
					? specialOnChange({ fieldName: name, option: null })
					: setValue(name, null)
				setCanResetUniqueElement(false)
			}
		}
	}, [
		selectElements,
		isRequired,
		selectedOption,
		specialOnChange,
		setValue,
		name,
	])

	//allow to trigger error when a value is selected
	useEffect(() => {
		if (isRequired && data[name]) triggerError({ fieldName: name })
	}, [isRequired, data[name]])

	useEffect(() => {
		setInputValue(data[name]?.label || '')
	}, [data[name]])

	const handleInputValueChange = useCallback((e) => {
		setInputValue(e?.target?.value)
	}, [])

	const handleOnChange = useCallback(
		(_e, option) => {
			if (specialOnChange)
				specialOnChange({
					fieldName: name,
					option,
				})
			else setValue(name, option || '')
		},
		[specialOnChange, isRequired, triggerError, errors, selectElements, data]
	)
	// Rendering function for options, including logic for displaying chips or color input.
	const handleRenderOption = useCallback(
		(props, option) => (
			<Box {...props} key={option?.key}>
				<Stack
					direction='row'
					justifyContent='space-between'
					alignItems='center'
					sx={{ width: 1 }}
				>
					{(displayChip || displayColorInput) && (
						<Box
							sx={{
								...indicatorColorStatus,
								backgroundColor: stringifiedColor(
									option.value.color,
									500
								) as any,
							}}
						></Box>
					)}

					<Box sx={{ textAlign: 'left', width: '100%' }}>
						{renderOptionLabel ? (
							renderOptionLabel(option)
						) : (
							<>
								{option?.label}
								{`${prefix ? prefix : ''}`}
							</>
						)}
					</Box>

					<Radio
						sx={{ mr: 1 }}
						checked={option?.key === selectedOption?.key}
						color={option?.key === selectedOption?.key ? 'primary' : 'default'}
						size={size || 'small'}
					/>
				</Stack>
			</Box>
		),
		[selectedOption, renderOptionLabel]
	)
	// Rendering function for the input, passing necessary props to FormSelectSearchInput.
	const handleRenderInput = useCallback(
		(params) => (
			<FormSelectSearchInput
				params={params}
				handleInputValueChange={handleInputValueChange}
				inputValue={inputValue}
				prefix={prefix}
				label={label}
				isRequired={isRequired}
				browserRequired={browserRequired}
				isDisabled={isDisabled}
				error={error}
				size={size}
				customHelper={customHelper}
				hidden={hidden}
				isLoading={isLoading}
				name={name}
				displayChip={displayChip}
				displayColorInput={displayColorInput}
				selectedOption={selectedOption}
			/>
		),
		[inputValue, isDisabled, error, isLoading]
	)

	return (
		// The Autocomplete component is rendered with all the necessary handlers and props.
		<Autocomplete
			id={playwrightId}
			value={selectedOption}
			onChange={handleOnChange}
			options={sortedSelectElements || []}
			inputValue={inputValue}
			fullWidth
			autoHighlight
			disabled={isDisabled}
			onBlur={getHandleOnblur}
			noOptionsText={missingText || ''}
			loading={isLoading}
			renderOption={handleRenderOption}
			renderInput={handleRenderInput}
			getOptionLabel={(option) => option?.label || ''}
			isOptionEqualToValue={(option, value) => option?.key === value?.key}
			sx={sx}
		/>
	)
}

export default FormSelectSearch
