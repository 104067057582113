import React, {
	useContext,
	useState,
	useCallback,
	useEffect,
	useMemo,
} from 'react'

import qs from 'qs'
import { Stack, Divider } from '@mui/material'

import { useDebouncedState, useFormatMessage } from '../../../../utils/hooks'
import { EFormModalMode } from '../../../../utils/app-models'
import {
	useLazyVehicleIPVInvoiceList,
	useLazyVehiclesInvoiceList,
	useVehicleInvoice,
} from '../../../../api/gql/queries/vehiclesQueries'
import {
	useInterventionInvoice,
	useLazyInterventionsIPVInvoiceList,
} from '../../../../api/gql/queries/interventionsQueries'
import {
	formatFormatListOptionsForSelectVEHICLES,
	formatFormatListOptionsForSelectINTERVENTIONS,
	formatFormatListOptionsForSelectDefaultValueVEHICLE,
	acceptDocumentVehicleFile,
} from './vehicleDocumentModalUtils'
import {
	FormModalContext,
	IFormModalContext,
} from '../../../../components/forms/formModal/FormModalContext'
import FormTextField from '../../../../components/forms/formFields/FormTextField'
import FormSelectSearch from '../../../../components/forms/formFields/formSelectSearch'
import FormDropZone from '../../../../components/forms/formFields/FormDropZone'
import FormSelectSearchQuery from '../../../../components/forms/formFields/formSelectSearchQuery'
import { useDocumentCategoriesList } from '../../../../api/gql/queries/documentsVehiclesQueries'
import { formatFormatListOptionsForSelectDefaultValueINTERVENTION } from '../../invoice/invoiceModal/invoiceModalUtils'

interface IDocumentVehicleModalInfos {}
const wrapperModalCSS = { p: 4, overflow: 'auto' }

const DocumentVehicleModalInfos: React.FC<IDocumentVehicleModalInfos> = () => {
	const intlMsg = useFormatMessage()
	const { data, mode, setValues } =
		useContext<IFormModalContext>(FormModalContext)

	const watchVehicleSelected = useMemo(() => data.vehicle, [data.vehicle])
	const watchInterventionSelected = useMemo(
		() => data.intervention,
		[data.intervention]
	)
	const [selectElementsVehicle, setSelectElementsVehicle] = useState([])
	const [selectElementsIntervention, setSelectElementsIntervention] = useState(
		[]
	)

	const [debouncedState, setDebouncedState, liveState] = useDebouncedState({
		searchText: '',
	})
	const { query, varValue } = qs.parse(location.search, {
		ignoreQueryPrefix: true,
	}) as any

	// !!! For intervention type "transport", you need to use the queryName = "interventionTransport" in the condition on getViewModalConfig.ts, but "transport" currently uses the query 'intervention'.
	// !!! To find similar problems, search for #currentQueryNameTransport
	const currentQueryName = ['interventionTransport'].includes(query)
		? 'intervention'
		: query

	// Set the value enter in the SelectRadioSearch (send as argument for query)
	const handleChangeSearch = useCallback(
		(value) => {
			setDebouncedState({ ...liveState, searchText: value.trim() })
		},
		[liveState]
	)

	/////////////////////////////////////////////////////////////////////////////////////////////
	// Queries
	/////////////////////////////////////////////////////////////////////////////////////////////

	// document categories
	const { documentCategories } = useDocumentCategoriesList()

	// Query Get list vehicles
	const { loadingVehicles, vehicles, getVehicles } =
		useLazyVehiclesInvoiceList()

	// Query Get list vehicles IPV
	const { loadingVehiclesIPV, vehiclesIPV, getVehiclesIPV } =
		useLazyVehicleIPVInvoiceList({ fetchPolicy: 'cache-and-network' })

	// Query Get list interventions IPV
	const { loadingInterventionsIPV, interventionsIPV, getInterventionsIPV } =
		useLazyInterventionsIPVInvoiceList()

	// launch the query with argument research
	useEffect(() => {
		if (debouncedState.searchText?.length) {
			getVehicles({ variables: { ...debouncedState } })
		}
	}, [debouncedState])

	// launch the query with argument for the second input selected (vehicle or intervention)
	useEffect(() => {
		if (watchVehicleSelected?.length) {
			getInterventionsIPV({ variables: { vehicle: watchVehicleSelected } })
		} else if (watchInterventionSelected?.length) {
			getVehiclesIPV({ variables: { intervention: watchInterventionSelected } })
		}
	}, [watchVehicleSelected])

	/////////////////////////////////////////////////////////////////////////////////////////////
	// Queries  default value for update (vehicle & intervention) / or set value for viewModalView
	/////////////////////////////////////////////////////////////////////////////////////////////

	// Query Get one vehicle
	const { loadingVehicle, vehicle, getVehicle } = useVehicleInvoice()
	const { loadingIntervention, intervention, getIntervention } =
		useInterventionInvoice()

	// launch the query with default value
	useEffect(() => {
		const vehicleId = data.vehicle
		if (vehicleId) {
			getVehicle({ variables: { id: vehicleId } })
		}
		// for view modal & mode "create'"
		else if (varValue && mode === EFormModalMode.create) {
			const currentQuery =
				currentQueryName === 'intervention' ? getIntervention : getVehicle
			currentQuery({ variables: { id: varValue } })
		}
	}, [])

	/////////////////////////////////////////////////////////////////////////////////////////////
	// Format data list vehicle for the Select input
	/////////////////////////////////////////////////////////////////////////////////////////////

	// List option for select VEHICLES ////////////////////////////////////////
	useEffect(() => {
		!loadingVehicles &&
			!loadingVehiclesIPV &&
			formatFormatListOptionsForSelectVEHICLES(
				vehiclesIPV,
				vehicles,
				'intervention',
				setSelectElementsVehicle
			)
	}, [loadingVehicles, loadingVehiclesIPV])

	// List option for select VEHICLES ////////////////////////////////////////
	useEffect(() => {
		!loadingVehicles &&
			formatFormatListOptionsForSelectVEHICLES(
				vehiclesIPV,
				vehicles,
				'vehicle',
				setSelectElementsVehicle
			)
	}, [loadingVehicles])

	// List option for select INTERVENTIONS //////////////////////////////////////
	useEffect(() => {
		!loadingInterventionsIPV &&
			formatFormatListOptionsForSelectINTERVENTIONS(
				interventionsIPV,
				setSelectElementsIntervention
			)
	}, [loadingInterventionsIPV])

	useEffect(() => {
		if (
			(!loadingIntervention && mode === EFormModalMode.edit && intervention) ||
			currentQueryName === 'intervention'
		) {
			formatFormatListOptionsForSelectDefaultValueINTERVENTION(
				intervention,
				setSelectElementsIntervention
			)
		}
	}, [loadingIntervention])

	// List option for select VEHICLES (DEFAULT VALUE FOR UPDATE) //////////////////
	useEffect(() => {
		if (
			(!loadingVehicle && mode === EFormModalMode.edit && vehicle) ||
			currentQueryName === 'vehicle'
		) {
			formatFormatListOptionsForSelectDefaultValueVEHICLE(
				vehicle,
				setSelectElementsVehicle
			)
		}
	}, [loadingVehicle])

	//////////////////////////////////////////////////////////////////

	const onClearVehicle = useCallback(() => {
		setSelectElementsVehicle([])
		setValues({ intervention: '', vehicle: '' })
	}, [setValues])

	return (
		<Stack sx={wrapperModalCSS} spacing={2}>
			<Stack direction='row' spacing={2}>
				<FormTextField
					name='reference'
					label={intlMsg('documentVehicle.ref')}
					context={FormModalContext}
					isRequired
					trigerred
				/>

				<FormSelectSearch
					name='category'
					label={intlMsg('documentVehicle.typeDocument')}
					selectElements={documentCategories}
					context={FormModalContext}
					isRequired
					trigerred
				/>
			</Stack>

			<Stack direction='row' spacing={2}>
				<FormSelectSearchQuery
					name='vehicle'
					label={intlMsg('misc.vehicle')}
					context={FormModalContext}
					selectElements={selectElementsVehicle}
					isRequired
					specialOnInputChange={handleChangeSearch}
					isLoading={loadingVehicles}
					setDebouncedState={setDebouncedState}
					minCharacters={3}
					placeholder={intlMsg('invoice.searchVehicle')}
					specialOnClear={onClearVehicle}
					triggered
				/>

				<FormSelectSearchQuery
					name='intervention'
					label={intlMsg('misc.mission')}
					context={FormModalContext}
					selectElements={selectElementsIntervention}
					isLoading={loadingInterventionsIPV}
					setDebouncedState={setDebouncedState}
					isDisabled={!watchVehicleSelected}
					minCharacters={3}
					triggered
				/>
			</Stack>

			<Divider variant='middle' />

			<FormDropZone
				name='importFile'
				context={FormModalContext}
				text={{
					dragFileHereText: intlMsg('misc.dragFileHere'),
					dropzoneText: intlMsg('misc.uploadFile'),
					dropzoneTextError: intlMsg('errors.errorTypeFilesCSV'),
					selectedFileText: intlMsg('misc.selectedFile'),
				}}
				accept={acceptDocumentVehicleFile}
			/>
		</Stack>
	)
}

export default DocumentVehicleModalInfos
