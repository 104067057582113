import React, { useCallback, useContext } from 'react'

import { Route, Routes, useLocation } from 'react-router-dom'
import Container from '@mui/material/Grid'

import AdministrationMenu from './administrationMenu'
import { ROUTES } from '../../routes/consts'

import MyProfile from './myProfile'
import Users from './users'
import Teams from './teams'
// import NotificationsPanel from './notificationsPanel'
import ConditionalRoute from '../../routes/ConditionalRoute'
import { AuthContext } from '../../utils/contexts/AuthContext'
import { EModule, EPermissionScope } from '../../utils/app-models'
import FormTemplateList from './formTemplate/formTemplateList'
import FormTemplate from './formTemplate'
import { Error404 } from '..'
import ProductCatalogue from './productCatalogue'

const containerCSS = {
	height: 1,
	display: 'flex',
	flexDirection: { xs: 'column', md: 'row' },
	overflow: 'auto',
	scrollbarWidth: 'none',
	'&::-webkit-scrollbar': {
		display: 'none',
	},
}

const Administration: React.FC = () => {
	const { checkPermissions, checkModules, checkAllModules } =
		useContext(AuthContext)
	const location = useLocation()

	const checkCondition = useCallback(
		(permissions: string[] | null, modules?: string[] | null) => {
			if (!checkPermissions(permissions) || !checkModules(modules)) return false
			else return true
		},
		[checkPermissions, checkModules]
	)

	return (
		<Container sx={containerCSS}>
			{!location.pathname.includes(
				ROUTES.ADMINISTRATION.FORM_TEMPLATE_EDITION.url
			) && <AdministrationMenu />}
			<Routes>
				{/* <Route path={ROUTES.ADMINISTRATION_USERS} element={<Users />} /> */}
				<Route
					path={ROUTES.ADMINISTRATION.MY_PROFILE.path}
					element={<MyProfile />}
				/>
				<Route
					path={ROUTES.ADMINISTRATION.USERS.path}
					element={
						<ConditionalRoute
							component={Users}
							condition={checkCondition([EPermissionScope.userManage])}
							redirect={ROUTES.ADMINISTRATION.MY_PROFILE.path}
						/>
					}
				/>
				<Route
					path={ROUTES.ADMINISTRATION.TEAMS.path}
					element={
						<ConditionalRoute
							component={Teams}
							condition={checkCondition([EPermissionScope.userManage])}
							redirect={ROUTES.ADMINISTRATION.MY_PROFILE.path}
						/>
					}
				/>

				{/* <Route
					path={ROUTES.ADMINISTRATION_NOTIFICATIONS}
					component={NotificationsPanel}
				/> */}

				<Route
					path={ROUTES.ADMINISTRATION.FORM_TEMPLATE_EDITION.path}
					element={
						<ConditionalRoute
							component={FormTemplate}
							condition={checkCondition(
								[EPermissionScope.formTemplateManage],
								[EModule.formTemplate]
							)}
							redirect={ROUTES.ADMINISTRATION.MY_PROFILE.path}
						/>
					}
				/>

				<Route
					path={ROUTES.ADMINISTRATION.FORM_TEMPLATES.path}
					element={
						<ConditionalRoute
							component={FormTemplateList}
							condition={checkCondition(
								[EPermissionScope.formTemplateManage],
								[EModule.formTemplate]
							)}
							redirect={ROUTES.ADMINISTRATION.MY_PROFILE.path}
						/>
					}
				/>

				<Route
					path={ROUTES.ADMINISTRATION.PRODUCT_CATALOGUE.CATALOGUE_PRODUCT.path}
					element={
						<ConditionalRoute
							key='catalogue-product' // !important 3 routes uses sames component 'ProductCatalogue', need key
							component={ProductCatalogue}
							redirect={ROUTES.ADMINISTRATION.MY_PROFILE.url}
							condition={checkAllModules([EModule.productCatalog])}
						/>
					}
				/>
				<Route
					path={
						ROUTES.ADMINISTRATION.PRODUCT_CATALOGUE.CATALOGUE_PRODUCT_BASE.path
					}
					element={
						<ConditionalRoute
							key='catalogue-product-base' // !important 3 routes uses sames component 'ProductCatalogue', need key
							component={ProductCatalogue}
							redirect={ROUTES.ADMINISTRATION.MY_PROFILE.url}
							condition={checkAllModules([EModule.productCatalog, EModule.crm])}
						/>
					}
				/>
				<Route
					path={
						ROUTES.ADMINISTRATION.PRODUCT_CATALOGUE.CATALOGUE_PRODUCT_SHARED
							.path
					}
					element={
						<ConditionalRoute
							key='catalogue-product-shared' // !important 3 routes uses sames component 'ProductCatalogue', need key
							component={ProductCatalogue}
							redirect={ROUTES.ADMINISTRATION.MY_PROFILE.url}
							condition={checkAllModules([EModule.productCatalog])}
						/>
					}
				/>

				{!location?.pathname.includes('/modalview') && (
					<Route path={'*'} element={<Error404 />} />
				)}
			</Routes>
		</Container>
	)
}

export default Administration
