export default {
	profileUpdate: 'Profile Updated',
	notificationUpdate: 'Changes Saved',
	userAccess: 'Access Updated',
	statusUpdate: 'Status Updated',
	passwordUpdate: 'Password Changed',

	siteCreate: 'Site Created Successfully',
	siteUpdate: 'Site Updated',
	siteDeleted: 'Site Deleted',
	sitesDeleted: 'Sites Deleted',

	contactCreate: 'Contact Added',
	contactUpdate: 'Contact Updated',
	contactDeleted: 'Contact Deleted',
	contactsDeleted: 'Contacts Deleted',
	contactIsShare: 'Invitation Sent',

	accountCreate: 'Account Added',
	accountUpdate: 'Account Updated',
	accountDeleted: 'Account Deleted',
	accountsDeleted: 'Accounts Deleted',

	teamCreate: 'Team Created Successfully',
	teamUpdate: 'Team Updated',
	teamDeleted: 'Team Deleted',
	teamsDeleted: 'Teams Deleted',

	userCreate: 'User Created Successfully',
	userUpdate: 'User Updated',
	userDeleted: 'User Deleted',
	usersDeleted: 'Users Deleted',

	vehicleCreate: 'Vehicle Created Successfully',
	vehicleUpdate: 'Vehicle Updated',
	vehicleDeleted: 'Vehicle Deleted',
	vehiclesDeleted: 'Vehicles Deleted',

	templateFormCreate: 'Form Template Created Successfully',
	templateFormUpdate: 'Form Template Updated',
	formTemplateDeleted: 'Form Template Deleted',
	formTemplatesDeleted: 'Form Templates Deleted',

	interventionCreate: 'Intervention Created Successfully',
	interventionUpdate: 'Intervention Updated',
	interventionStatusUpdate: 'Intervention Status Updated',
	interventionDeleted: 'Intervention Deleted',
	interventionsDeleted: 'Interventions Deleted',

	transportCreate: 'Transport created successfully',
	transportUpdate: 'Transport updated',
	transportStatusUpdate: 'Transport status updated',
	transportDeleted: 'Transport deleted',
	transportsDeleted: 'Transports deleted',

	importExcel: 'Import Successful',

	adminPanelUpdateAccount: 'Account Updated',

	categoryCreate: 'Category Created Successfully',
	categoryUpdate: 'Category Updated',
	productCreate: 'Product Created Successfully',
	productUpdate: 'Product Updated',
	productBaseCreate: 'Product range created successfully',
	productBaseUpdate: 'Product range updated',
	categoryOrderUpdate: 'Category Order Updated',
	productOrderUpdate: 'Product Order Updated',

	alertUpdated: 'Alert Updated',
	alertDeleted: 'Alert Deleted',
	alertsDeleted: 'Alerts Deleted',

	interventionFormDeleted: 'Intervention Deleted',
	interventionsFormDeleted: 'Interventions Deleted',

	agencyCreate: 'Agency Created Successfully',
	agencyUpdate: 'Agency Updated',
	agencyDeleted: 'Agency Deleted',
	agenciesDeleted: 'Agencies Deleted',

	networkCreate: 'Network Created Successfully',
	networkUpdate: 'Network Updated',
	networkDeleted: 'Network Deleted',

	locationUpdate: 'Vehicle Location Updated',

	mileageCreate: 'Mileage Entry Created Successfully',

	vehicleMovementCreate: 'Vehicle Movement Entry Created Successfully',
	vehicleMovementUpdate: 'Vehicle Movement Entry Updated',

	invoiceCreate: 'Expense Created Successfully',
	invoiceUpdate: 'Expense Updated',
	invoiceDeleted: 'Expense Deleted',
	invoicesDeleted: 'Expenses Deleted',

	documentVehicleCreate: 'Document Created Successfully',
	documentVehicleUpdate: 'Document Updated',
	documentVehicleDeleted: 'Document Deleted',
	documentVehiclesDeleted: 'Documents Deleted',

	customerAccountRestored:
		'An existing disabled customer account has been restored and updated',

	categoryDeleted: 'Category deleted',
}
