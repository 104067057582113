/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, {
	useContext,
	useState,
	useCallback,
	useEffect,
	useMemo,
} from 'react'
import qs from 'qs'

import { Stack, Divider } from '@mui/material'

import { useDebouncedState, useFormatMessage } from '../../../../utils/hooks'
import { EFormModalMode } from '../../../../utils/app-models'
import {
	useLazyVehicleIPVInvoiceList,
	useLazyVehiclesInvoiceList,
	useVehicleInvoice,
} from '../../../../api/gql/queries/vehiclesQueries'

import {
	useInterventionInvoice,
	useLazyInterventionsInvoiceList,
	useLazyInterventionsIPVInvoiceList,
} from '../../../../api/gql/queries/interventionsQueries'
import {
	formatFormatListOptionsForSelectVEHICLES,
	formatFormatListOptionsForSelectINTERVENTIONS,
	formatFormatListOptionsForSelectDefaultValueVEHICLE,
	formatFormatListOptionsForSelectDefaultValueINTERVENTION,
	vatListForSelect,
	acceptInvoiceFile,
} from './invoiceModalUtils'

import {
	FormModalContext,
	IFormModalContext,
} from '../../../../components/forms/formModal/FormModalContext'
import FormSwitchButton from '../../../../components/forms/formFields/FormSwitchButton'
import FormTextField from '../../../../components/forms/formFields/FormTextField'
import FormDatePicker from '../../../../components/forms/formFields/FormDatePicker'
import FormSelectSearch from '../../../../components/forms/formFields/formSelectSearch'
import FormDropZone from '../../../../components/forms/formFields/FormDropZone'
import FormSelectSearchQuery from '../../../../components/forms/formFields/formSelectSearchQuery'

const wrapperModalCSS = {
	p: 4,
	overflow: 'auto',
}

interface IInvoiceModalInfos {}

const InvoiceModalInfos: React.FC<IInvoiceModalInfos> = () => {
	const intlMsg = useFormatMessage()
	const { data, setValues, setValue, mode, triggerError } =
		useContext<IFormModalContext>(FormModalContext)

	const [debouncedState, setDebouncedState, liveState] = useDebouncedState({
		searchText: '',
	})
	const [selectElementsVehicle, setSelectElementsVehicle] = useState([])
	const [selectElementsIntervention, setSelectElementsIntervention] = useState(
		[]
	)

	const { query, varValue } = qs.parse(location.search, {
		ignoreQueryPrefix: true,
	}) as any

	// !!! For intervention type "transport", you need to use the queryName = "interventionTransport" in the condition on getViewModalConfig.ts, but "transport" currently uses the query 'intervention'.
	// !!! To find similar problems, search for #currentQueryNameTransport
	const currentQuery = ['interventionTransport'].includes(query)
		? 'intervention'
		: query

	const watchVehicleSelected = useMemo(() => data.vehicle, [data.vehicle])
	const watchInterventionSelected = useMemo(
		() => data.intervention,
		[data.intervention]
	)
	const invoiceBy = useMemo(() => data.invoiceSource, [data.invoiceSource])
	const isInvoiceByVehicle = useMemo(() => invoiceBy === 'vehicle', [invoiceBy])
	const isInvoiceByIntervention = useMemo(
		() => invoiceBy === 'intervention',
		[invoiceBy]
	)

	// Select the the source invoice, if user want add a invoice from a vehicle or intervention
	// determine which field of the SelectRadioSearch is in search mode (to send value to query)
	const selectedInvoiceSource = useCallback(
		(invoiceSource) => {
			setValues({
				invoiceSource: invoiceSource.value,
				vehicle: '',
				intervention: '',
			})
			setDebouncedState({ searchText: '' })
			setSelectElementsVehicle([])
			setSelectElementsIntervention([])
		},
		[setValues, triggerError]
	)

	useEffect(() => {
		triggerError({
			fieldName: invoiceBy === 'intervention' ? 'vehicle' : 'intervention',
		})
	}, [invoiceBy])

	// Set the value enter in the SelectRadioSearch (send as argument for query)
	const handleChangeSearch = useCallback(
		(value) => {
			setDebouncedState({
				...liveState,
				searchText: value.trim(),
			})
		},
		[liveState]
	)

	/////////////////////////////////////////////////////////////////////////////////////////////
	// Queries
	/////////////////////////////////////////////////////////////////////////////////////////////

	// Query Get list vehicles
	const { loadingVehicles, vehicles, getVehicles } =
		useLazyVehiclesInvoiceList()

	// Query Get list vehicles IPV
	const { loadingVehiclesIPV, vehiclesIPV, getVehiclesIPV } =
		useLazyVehicleIPVInvoiceList({ fetchPolicy: 'cache-and-network' })

	// Query Get list interventions
	const { loadingInterventions, interventions, getInterventions } =
		useLazyInterventionsInvoiceList({ fetchPolicy: 'cache-and-network' })

	// Query Get list interventions IPV
	const { loadingInterventionsIPV, interventionsIPV, getInterventionsIPV } =
		useLazyInterventionsIPVInvoiceList({ fetchPolicy: 'cache-and-network' })

	// launch the query with argument research
	useEffect(() => {
		if (debouncedState.searchText?.length) {
			invoiceBy === 'vehicle'
				? getVehicles({ variables: { ...debouncedState } })
				: getInterventions({ variables: { ...debouncedState } })
		}
	}, [debouncedState])

	// launch the query with argument for the second input selected (vehicle or intervention)
	useEffect(() => {
		if (watchVehicleSelected?.length || watchInterventionSelected?.length) {
			invoiceBy === 'vehicle'
				? getInterventionsIPV({ variables: { vehicle: watchVehicleSelected } })
				: getVehiclesIPV({
						variables: { intervention: watchInterventionSelected },
				  })
		}
	}, [watchVehicleSelected, watchInterventionSelected])

	/////////////////////////////////////////////////////////////////////////////////////////////
	// Queries  default value for update (vehicle & intervention) / or set value for viewModalView
	/////////////////////////////////////////////////////////////////////////////////////////////

	// Query Get one vehicle
	const { loadingVehicle, vehicle, getVehicle } = useVehicleInvoice()

	// Query Get one intervention
	const { loadingIntervention, intervention, getIntervention } =
		useInterventionInvoice()

	// launch the query with default value
	useEffect(() => {
		const vehicleId = data.vehicle
		const interventionId = data.intervention
		if (vehicleId || interventionId) {
			invoiceBy === 'vehicle'
				? getVehicle({ variables: { id: vehicleId } })
				: getIntervention({ variables: { id: interventionId } })
		}
		// for view modal & mode "create'"
		else if (varValue && mode === EFormModalMode.create) {
			currentQuery === 'vehicle'
				? getVehicle({ variables: { id: varValue } })
				: getIntervention({ variables: { id: varValue } })
		}
	}, [])

	/////////////////////////////////////////////////////////////////////////////////////////////
	// Format data list vehicle for the Select input
	/////////////////////////////////////////////////////////////////////////////////////////////

	// List option for select VEHICLES ////////////////////////////////////////
	useEffect(() => {
		!loadingVehicles &&
			!loadingVehiclesIPV &&
			formatFormatListOptionsForSelectVEHICLES(
				vehiclesIPV,
				vehicles,
				invoiceBy,
				setSelectElementsVehicle
			)
	}, [loadingVehicles, loadingVehiclesIPV])

	// List option for select INTERVENTIONS //////////////////////////////////////
	useEffect(() => {
		!loadingInterventionsIPV &&
			!loadingInterventions &&
			formatFormatListOptionsForSelectINTERVENTIONS(
				interventionsIPV,
				interventions,
				invoiceBy,
				setSelectElementsIntervention
			)
	}, [loadingInterventionsIPV, loadingInterventions])

	// List option for select VEHICLES (DEFAULT VALUE FOR UPDATE) //////////////////
	useEffect(() => {
		if (
			(!loadingVehicle && mode === EFormModalMode.edit && vehicle) ||
			currentQuery === 'vehicle'
		) {
			formatFormatListOptionsForSelectDefaultValueVEHICLE(
				vehicle,
				setSelectElementsVehicle
			)
		}
	}, [loadingVehicle])

	// List option for select INTERVENTION (DEFAULT VALUE FOR UPDATE) //////////////////
	useEffect(() => {
		if (
			(!loadingIntervention && mode === EFormModalMode.edit && intervention) ||
			currentQuery === 'intervention'
		) {
			formatFormatListOptionsForSelectDefaultValueINTERVENTION(
				intervention,
				setSelectElementsIntervention
			)
		}
	}, [loadingIntervention])

	//////////////////////////////////////////////////////////////////

	const onClearVehicle = useCallback(() => {
		if (isInvoiceByIntervention) {
			setValue('vehicle', '')
		} else {
			setSelectElementsVehicle([])
			setValue('intervention', '')
		}
	}, [isInvoiceByIntervention, setValue])

	const onClearIntervention = useCallback(() => {
		if (isInvoiceByVehicle) {
			setValue('intervention', '')
		} else {
			setSelectElementsIntervention([])
			setValue('vehicle', '')
		}
	}, [isInvoiceByVehicle, setValue])

	return (
		<Stack sx={wrapperModalCSS} spacing={2}>
			<FormSwitchButton
				name='invoiceSource'
				config={{
					primary: { text: intlMsg('invoice.fromVehicle'), value: 'vehicle' },
					secondary: {
						text: intlMsg('invoice.fromIntervention'),
						value: 'intervention',
					},
				}}
				context={FormModalContext}
				specialOnChange={selectedInvoiceSource}
			/>

			<Stack direction='row' spacing={2}>
				<FormSelectSearchQuery
					name='vehicle'
					label={intlMsg('misc.vehicle')}
					context={FormModalContext}
					selectElements={selectElementsVehicle}
					isRequired={isInvoiceByVehicle}
					specialOnInputChange={isInvoiceByVehicle ? handleChangeSearch : null}
					valuesDefault={data['vehicle']}
					isLoading={loadingVehicles || loadingVehiclesIPV}
					setDebouncedState={setDebouncedState}
					isDisabled={isInvoiceByIntervention && !data['intervention']}
					minCharacters={3}
					placeholder={
						isInvoiceByVehicle ? intlMsg('invoice.searchVehicle') : ''
					}
					specialOnClear={onClearVehicle}
					triggered
				/>

				<FormSelectSearchQuery
					name='intervention'
					label={intlMsg('misc.mission')}
					context={FormModalContext}
					selectElements={selectElementsIntervention}
					isRequired={isInvoiceByIntervention}
					specialOnInputChange={
						isInvoiceByIntervention ? handleChangeSearch : null
					}
					valuesDefault={data['intervention']}
					isLoading={loadingInterventionsIPV || loadingInterventions}
					setDebouncedState={setDebouncedState}
					isDisabled={isInvoiceByVehicle && !data['vehicle']}
					minCharacters={3}
					placeholder={
						isInvoiceByIntervention ? intlMsg('invoice.searchIntervention') : ''
					}
					specialOnClear={onClearIntervention}
					triggered
				/>
			</Stack>

			<Divider variant='middle' />

			<Stack direction='row' spacing={2}>
				<FormTextField
					name='reference'
					label={intlMsg('invoice.reference')}
					context={FormModalContext}
					isRequired
					trigerred
				/>

				<FormDatePicker
					name='dueDate'
					label={intlMsg('invoice.dueDate')}
					views={['day']}
					isStartOfDay
					conserveHour
					context={FormModalContext}
				/>
			</Stack>

			<Stack direction='row' spacing={2}>
				<FormTextField
					name='totalAmountET'
					type='number'
					label={intlMsg('invoice.priceHT')}
					context={FormModalContext}
					inputProps={{ min: 0, step: 0.01 }}
					trigerred
					isRequired
				/>

				<FormSelectSearch
					name='vat'
					label={intlMsg('invoice.tva')}
					selectElements={vatListForSelect}
					context={FormModalContext}
					isRequired
					trigerred
					prefix='%'
				/>
			</Stack>

			<FormDropZone
				name='importFile'
				context={FormModalContext}
				text={{
					dragFileHereText: intlMsg('misc.dragFileHere'),
					dropzoneText: intlMsg('misc.uploadFile'),
					dropzoneTextError: intlMsg('errors.errorTypeFilesCSV'),
					selectedFileText: intlMsg('misc.selectedFile'),
				}}
				accept={acceptInvoiceFile}
			/>
		</Stack>
	)
}

export default InvoiceModalInfos
