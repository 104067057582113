import React, { Context, useContext, useMemo } from 'react'

import TextField from '@mui/material/TextField'
import { Box, CircularProgress, InputLabel } from '@mui/material'
import { useFormatMessage } from '../../../../utils/hooks'
import { IFormModalContext } from '../../formModal/FormModalContext'
import { IStepperContext } from '../../formStepper/StepperContext'

const InputLabelCSS = { position: 'absolute', zIndex: 1, top: 9, right: 31 }

interface IFormSelectSearchQueryInput {
	params: any
	label: string
	isRequired?: boolean
	isDisabled?: boolean
	isLoading?: boolean
	hidden?: boolean
	size: 'medium' | 'small' | undefined
	customHelper?: string
	placeholder?: string
	name: string
	errorCharacters
	minCharacters?: number
	watchedValue
	context: Context<IFormModalContext> | Context<IStepperContext>
	selectedValue
	inputChangeReason
	errorKey?: string
}

const FormSelectSearchQueryInput: React.FC<IFormSelectSearchQueryInput> = ({
	params,
	label,
	isRequired,
	isDisabled,
	isLoading,
	hidden,
	size,
	customHelper,
	placeholder,
	name,
	errorCharacters,
	minCharacters,
	watchedValue,
	context,
	selectedValue,
	inputChangeReason,
	errorKey,
}) => {
	const intlMsg = useFormatMessage()
	const { errors } = useContext<IFormModalContext | IStepperContext>(
		context as any
	)
	const error = useMemo(
		() => (errorKey ? errors[errorKey] : errors[name]),
		[errors, errorKey]
	)

	// inputProps ////////////////////////////////////////////////
	const inputProps = useMemo(
		() => ({
			...params.inputProps,
			// if reason onInputChange is different to 'input' ('clear' or 'reset')
			...(inputChangeReason !== 'input' && {
				// selectedValue?.labelInputText (type string) is use when label is a react component, (because  inputProps.value can only be type string)
				// ex: search "labelInputText"
				value: watchedValue
					? selectedValue?.labelInputText || selectedValue?.label
					: '',
			}),
			...(inputChangeReason === 'clear' && {
				value: '',
			}),
		}),
		[watchedValue, params.inputProps, inputChangeReason, selectedValue]
	)

	// InputProps ////////////////////////////////////////////////
	const InputProps = useMemo(
		() => ({
			...params.InputProps,
			endAdornment: (
				<>
					{isLoading ? <CircularProgress color='inherit' size={20} /> : null}
					{!isLoading && params.InputProps.endAdornment}
				</>
			),
		}),
		[params.InputProps, isLoading]
	)

	// sx ///////////////////////////////////////////////////////
	const sx = useMemo(
		() => ({
			opacity: isDisabled ? '0.5' : '1',
			display: hidden ? 'none' : 'inherit',
		}),
		[isDisabled, hidden]
	)

	return (
		<Box position='relative'>
			{errorCharacters && (
				<InputLabel sx={InputLabelCSS} color='error' error>
					{intlMsg('errors.minCharacters', { minCharacters })}
				</InputLabel>
			)}
			<TextField
				{...params}
				name={name}
				inputProps={inputProps}
				label={label}
				disabled={isDisabled}
				error={!!error || errorCharacters}
				variant='outlined'
				size={size || 'small'}
				helperText={error || customHelper || ''}
				placeholder={placeholder}
				sx={sx}
				InputProps={InputProps}
				InputLabelProps={{ shrink: true }}
				required={isRequired}
			/>
		</Box>
	)
}

export default FormSelectSearchQueryInput
