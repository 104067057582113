///////////////////////////////////////////////////////////////////////////////////
// Check if it's a valid immat
///////////////////////////////////////////////////////////////////////////////////

export const immatFormats = [
	{
		name: 'frNew',
		regex: /^[A-Z]{1,2}[0-9]{1,3}[A-Z]{1,2}$/,
		maxLength: 7,
	},
	{
		name: 'frOld',
		regex: /^[0-9]{1,4}[A-Z]{1,4}[0-9]{1,2}$/,
		maxLength: 9,
	},
	{
		name: 'german',
		regex: /^[A-ZÄÖÜ]{1,3}[ ]{0,1}[A-Z]{0,2}[0-9]{1,4}[H]{0,1}$/,
		maxLength: 8,
	},
	{
		name: 'belgium',
		regex: /^[0-9]{1}[A-Z]{1,3}[0-9]{1,3}$/,
		maxLength: 9,
	},
	{
		name: 'italy',
		regex: /^[A-Z]{2}[0-9]{3}[A-Z]{2}$/,
		maxLength: 7,
	},
	{
		name: 'denmark',
		regex: /^[A-Z]{2}[0-9]{5}$/,
		maxLength: 7,
	},
	{
		name: 'poland',
		regex: /^[A-Z]{1,3}-[A-Z]{1,2}-[0-9]{1,4}$/,
		maxLength: 7,
	},
]
