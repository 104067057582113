import React, { useMemo, useRef } from 'react'

import { Stack, Typography } from '@mui/material'
import { useFormatMessage, useResizeHeightStyle } from '../../../utils/hooks'
import { grey } from '@mui/material/colors'

const colorGreyCSS = { color: grey[600] }

interface IWTransportTripSummaryGuidelines {
	transportTripType: string
	itTrip
	maxHeightUtils
}

const WTransportTripSummaryGuidelines: React.FC<
	IWTransportTripSummaryGuidelines
> = ({ transportTripType, itTrip, maxHeightUtils }) => {
	const intlMsg = useFormatMessage()

	// Calculated & resize height
	const resizeRef = useRef<HTMLUListElement>(null)
	const componentStyle = useResizeHeightStyle(resizeRef, maxHeightUtils, [
		itTrip,
	])

	const typographyCSS = useMemo(
		() => ({
			maxHeight: '155px',
			overflowY: itTrip?.instruction ? 'auto' : 'inherit',
			overflowWrap: 'break-word',
			paddingRight: '7px',
		}),
		[itTrip?.instruction]
	)

	return (
		<Stack spacing={1} p={2} style={componentStyle} ref={resizeRef}>
			<Typography variant='body2' sx={colorGreyCSS}>
				{intlMsg(`interventions.${transportTripType}Guideline`)}
			</Typography>

			<Typography fontSize={12} sx={typographyCSS} className='smallScrollbar'>
				{itTrip?.instruction ? itTrip.instruction : '-'}
			</Typography>
		</Stack>
	)
}

export default WTransportTripSummaryGuidelines
