/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/display-name */
import React from 'react'

import OpenLinkCell from '../../../tableCell/OpenLinkCell'
import { ROUTES } from '../../../../../routes/consts'
import { EModule, EPermissionScope } from '../../../../../utils/app-models'

import { useRemoveVehicle } from '../../../../../api/gql/mutations/vehicleMutation'
import LocationVehiclePopover from '../../../../popover/locationVehiclePopover'
import VehicleKmCell from '../../../tableCell/VehicleKmCell'
import DateCell from '../../../tableCell/DateCell'
import IntervalDatePark from '../../../tableCell/IntervalDatePark'
import { formatDate } from '../../../../../utils/date'
import StatusVehicle from '../../../tableCell/StatusVehicle'
import ActionsBtn from '../../../tableCell/actionsBtn'
import VehicleModal from '../../../../../view/dashboard/vehicles/vehicleModal'

const customerListVehiclesViewModalTable = [
	{
		columnLabel: 'vehicle.immat',
		orderBy: 'vehicleDetail_immat',
		stickyFirstCol: true,
		personalizedCell: ({ id, vehicleDetail }) => (
			<>
				{vehicleDetail?.immat ? (
					<OpenLinkCell
						txt={vehicleDetail.immat}
						viewModalLink={`${ROUTES.VIEWMODAL.VEHICLES.url}${id}`}
					/>
				) : (
					'-'
				)}
			</>
		),
	},
	{
		columnLabel: 'vehicle.vin',
		orderBy: 'vehicleDetail_vin',
		personalizedCell: ({ id, vehicleDetail }) => (
			<>
				{vehicleDetail?.vin ? (
					<OpenLinkCell
						txt={vehicleDetail.vin}
						viewModalLink={`${ROUTES.VIEWMODAL.VEHICLES.url}${id}`}
					/>
				) : (
					'-'
				)}
			</>
		),
	},

	{
		columnLabel: 'vehicle.km',
		orderBy: 'vehicleDetail_actualMileage',
		cellAlign: 'center',
		personalizedCell: ({ vehicleDetail, vehicleMileages }) => (
			<VehicleKmCell
				vehicleDetail={vehicleDetail}
				vehicleMileages={vehicleMileages}
			/>
		),
	},

	{
		columnLabel: 'misc.site',
		orderBy: 'site_name',
		personalizedCell: ({ site }) => (
			<OpenLinkCell
				viewModalLink={`${ROUTES.VIEWMODAL.SITE.url}${site?.id}`}
				txt={site?.name}
				maxCharacters={40}
				modules={[EModule.site]}
			/>
		),
	},

	{
		columnLabel: 'vehicle.brand',
		orderBy: 'vehicleDetail_vehicleBrand_name',
		dataKey: 'vehicleDetail.vehicleBrand.name',
	},

	{
		columnLabel: 'vehicle.model',
		orderBy: 'vehicleDetail_vehicleModelText',
		dataKey: 'vehicleDetail.vehicleModelText',
	},

	{
		columnLabel: 'vehicle.vehicleCategory',
		orderBy: 'vehicleDetail_vehicleCategory_name',
		dataKey: 'vehicleDetail.vehicleCategory.name',
	},

	{
		columnLabel: 'vehicle.designation',
		orderBy: 'designation',
		dataKey: 'designation',
	},

	{
		columnLabel: 'statistics.lastIntervention',
		//orderBy: 'previousInterventionProgrammedVehicle.completionDate', TODO waiting for back
		personalizedCell: ({ previousInterventionProgrammedVehicle }) =>
			previousInterventionProgrammedVehicle?.completionDate
				? formatDate(
						previousInterventionProgrammedVehicle?.completionDate,
						true
				  )
				: '-',
	},

	{
		columnLabel: 'vehicle.statusVehicle',
		// orderBy: 'previousInterventionProgrammedVehicle.status.code', TODO waiting for back
		personalizedCell: ({ previousInterventionProgrammedVehicle }) =>
			previousInterventionProgrammedVehicle ? (
				<StatusVehicle
					status={previousInterventionProgrammedVehicle?.status}
					comment={previousInterventionProgrammedVehicle?.comment}
				/>
			) : (
				'-'
			),
	},

	{
		columnLabel: 'vehicle.parkEntry',
		orderBy: 'vehicleMovements_entryDate',
		personalizedCell: ({ lastVehicleMovement }) => (
			<DateCell date={lastVehicleMovement?.entryDate} />
		),
	},

	{
		columnLabel: 'vehicle.parkExit',
		orderBy: 'vehicleMovements_exitDate',
		personalizedCell: ({ lastVehicleMovement }) => (
			<DateCell date={lastVehicleMovement?.exitDate} />
		),
	},

	{
		columnLabel: 'vehicle.time',
		personalizedCell: ({ lastVehicleMovement }) => (
			<IntervalDatePark lastVehicleMovement={lastVehicleMovement} />
		),
	},

	{
		columnLabel: 'vehicle.locationShort',
		tableCellAlign: 'center',
		cellAlign: 'center',
		personalizedCell: ({ vehicleLocations, id }) => {
			const vehicleLocation = vehicleLocations?.collection[0]
			// TODO see with gautier to have vehicleLocations review the last
			return (
				<LocationVehiclePopover
					location={vehicleLocation?.location}
					color={vehicleLocation?.color}
					id={id}
				/>
			)
		},
	},
	// TODO search tag #uncommentActiveVehicle (the day we need it)
	// {
	// 	columnLabel: 'misc.active',
	// 	orderBy: 'isActive',
	// 	tableCellAlign: 'center',
	// 	cellAlign: 'center',
	// 	personalizedCell: (row: any) => {
	// 		return (
	// 			<BooleanColorPopover
	// 				value={row.isActive}
	// 				id={row.id}
	// 				txt={{ true: 'misc.active', false: 'misc.desactive' }}
	// 				titlePopover={'misc.vehicleStatusState'}
	// 				queryConfig={{
	// 					query: useUpdateVehicleStatus,
	// 					refreshQuery: ['getVehiclesList', 'getVehiclesNumbers'],
	// 					successMessage: 'success.statusUpdate',
	// 				}}
	// 			/>
	// 		)
	// 	},
	// },

	{
		fixWidth: '50px',
		columnLabel: '',
		visible: true,
		tableCellAlign: 'center',
		cellAlign: 'center',
		sticky: true,
		personalizedCell: (row) => (
			<ActionsBtn
				rowId={row?.id}
				deleted={{
					query: useRemoveVehicle,
					refreshQuery: ['getVehiclesList', 'getVehiclesNumbers'],
					successMessageDelete: 'success.vehicleDeleted',
					messageConfirm: {
						title: 'confirmModal.deleteVehicleSiteTitle',
						message: 'confirmModal.deleteVehicleSiteMessage',
					},
				}}
				editModal={
					<VehicleModal editedVehicleId={row?.id} modalUtils={{} as any} />
				}
				permissions={{
					update: [
						EPermissionScope.vehicleUpdate,
						EPermissionScope.vehicleDelete,
					],
					remove: [EPermissionScope.vehicleRemove],
					delete: [EPermissionScope.vehicleDelete],
				}}
				viewModalLink={`${ROUTES.VIEWMODAL.VEHICLES.url}${row?.id}`}
			/>
		),
	},
]

export default customerListVehiclesViewModalTable
