import { Box } from '@mui/material'
import React, { useContext } from 'react'
import FilterDate from '../../../../../../components/filters/filtersComponents/filterDate'
import { FiltersContext } from '../../../../../../components/filters/filtersContext'
import FiltersBtnProvider from '../../../../../../components/filters/filtersBtn'
import ByVehicleFiltersBtnComponent from './ByVehicleFiltersBtnComponent'

interface IByVehicleFilters {}

const ByVehicleFiltersSX = {
	display: 'flex',
}

const ByVehicleFilters: React.FC<IByVehicleFilters> = () => {
	const { setFilters, filters } = useContext(FiltersContext)

	return (
		<Box sx={ByVehicleFiltersSX}>
			<FilterDate
				setFiltersQuerieParam={setFilters}
				filtersQuerieParam={filters}
				argumentQuerieName='startFrom'
			/>
			<FiltersBtnProvider>
				<ByVehicleFiltersBtnComponent />
			</FiltersBtnProvider>
		</Box>
	)
}

export default ByVehicleFilters
