/* eslint-disable no-case-declarations */
import React from 'react'

import { Box, Stack, Typography } from '@mui/material'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'

import { useFormatMessage } from '../../../../../utils/hooks'
import MembersCell from '../../../../../components/tableV2/tableCell/MembersCell'
import MembersPopOver from '../../../../../components/tableV2/tableCell/tableCellPopOver/MembersPopOver'

const interventionTypeCSS: any = ({ colors }) => ({
	mt: '2px',
	p: '0px 3px',
	borderRadius: '2px',
	backgroundColor: colors.transport,
	fontSize: '10px',
	fontWeight: 400,
	height: 'fit-content',
})

const orderingCSS = { fontSize: '10px', fontWeight: 500 }
const travelCSS = { fontSize: '10px', mt: '2px' }
const avatarCSS = {
	display: 'flex',
	alignItems: 'center',
	ml: 2,
	justifyContent: 'end',
}

interface IConvoyingMonthEvent {
	event?: any
	setIsMembersCellOpen?: any
}

const ConvoyingMonthEvent: React.FC<IConvoyingMonthEvent> = ({
	event,
	setIsMembersCellOpen,
}) => {
	const intlMsg = useFormatMessage()

	const {
		interventionTransportTrips,
		orderingCustomer: { name: customerName },
		orderingSite: { name: siteName },
		actors,
	} = event?.resource || {}

	return (
		<Stack direction='row' spacing={0.5}>
			<Box sx={interventionTypeCSS}>
				{intlMsg(`interventions.CONVOY`).substring(0, 4)}
			</Box>
			<Stack width={1}>
				<Typography sx={travelCSS}>
					<Stack direction='row'>
						<Box>{interventionTransportTrips[0]?.city}</Box>
						<ArrowRightIcon sx={{ fontSize: 15 }} />
						<Box>{interventionTransportTrips[1]?.city}</Box>
					</Stack>
				</Typography>
				<Typography
					sx={orderingCSS}
				>{`${customerName} - ${siteName}`}</Typography>
			</Stack>
			{!!actors?.length && (
				<Box sx={avatarCSS}>
					<MembersCell
						data={actors?.map(({ id, lastName, firstName }) => ({
							firstName,
							lastName,
							id,
						}))}
						Popover={MembersPopOver}
						displayManager={false}
						setIsOpen={setIsMembersCellOpen}
					/>
				</Box>
			)}
		</Stack>
	)
}

export default ConvoyingMonthEvent
