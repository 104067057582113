/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

import dayjs from 'dayjs'

//new
import { byReferenceDefaultFiltersValues } from '../view/dashboard/interventions/interventionControl/byReference/byReferenceFilters/byReferenceDefaultFiltersConfig'
import { interventionFormsDefaultFilterConfigValues } from '../view/dashboard/interventionForms/interventionFormsFilters/interventionFormsDefaultFilterConfig'
import { invoiceDefaultFilterConfigValues } from '../view/dashboard/invoice/invoiceFilters/invoiceDefaultFilterConfig'
import { vehicleDocumentDefaultFilterConfigValues } from '../view/dashboard/vehiclesDocuments/vehicleDocumentFilters/vehicleDocumentDefaultFilterConfig'
import { transportDefaultFiltersValues } from '../view/dashboard/interventions/interventionTransport/transportFilters/transportDefaultFiltersConfig'
import { byVehicleDefaultFiltersValues } from '../view/dashboard/interventions/interventionControl/byVehicle/byVehicleFilters/byVehicleDefaultFiltersConfig'
//new

import { getActiveFiltersNumber } from '../components/filters/filtersBtn/filterPopoverUtils'
import { EPeriodFilter } from './app-models'
import { alertsDefaultFiltersValues } from '../view/dashboard/alerts/alertsFilters/alertsDefaultFiltersConfig'

const tablesFilterConfig = [
	// ////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Table
	// ////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Alerts -----------------------------------------
	{
		keyLocalStorage: 'listAlertsViewTableFilters',
		tableFilterConfig: alertsDefaultFiltersValues,
	},
	{
		keyLocalStorage: 'customerListAlertsViewModalFilters',
		tableFilterConfig: alertsDefaultFiltersValues,
	},
	{
		keyLocalStorage: 'interventionsListAlertsViewModalFilters',
		tableFilterConfig: {
			...alertsDefaultFiltersValues,
			period: EPeriodFilter.allPeriod,
			startFrom: null,
			endTo: null,
		},
	},
	{
		keyLocalStorage: 'sitesListAlertsViewModalFilters',
		tableFilterConfig: alertsDefaultFiltersValues,
	},
	{
		keyLocalStorage: 'vehiclesListAlertsViewModalFilters',
		tableFilterConfig: {
			...alertsDefaultFiltersValues,
			period: EPeriodFilter.allPeriod,
			startFrom: null,
			endTo: null,
		},
	},
	// IPV -----------------------------------------
	{
		keyLocalStorage: 'interventionByVehicleFilter',
		tableFilterConfig: byVehicleDefaultFiltersValues,
	},
	{
		keyLocalStorage: 'vehicleListInterventionsByReferenceViewModalFilters',
		tableFilterConfig: {
			...byVehicleDefaultFiltersValues,
			period: EPeriodFilter.allPeriod,
			startFrom: null,
			endTo: null,
		},
	},
	{
		keyLocalStorage: 'interventionsListVehiclesViewModalFilters',
		tableFilterConfig: {
			...byVehicleDefaultFiltersValues,
			period: EPeriodFilter.allPeriod,
			startFrom: null,
			endTo: null,
		},
	},
	// intervention ref -----------------------------------------
	{
		keyLocalStorage: 'interventionByReferenceFilter',
		tableFilterConfig: byReferenceDefaultFiltersValues,
	},
	{
		keyLocalStorage: 'agenciesListInterventionsByRefViewModalFilters',
		tableFilterConfig: byReferenceDefaultFiltersValues,
	},
	{
		keyLocalStorage: 'customerListInterventionsByRefViewModalFilters',
		tableFilterConfig: byReferenceDefaultFiltersValues,
	},
	{
		keyLocalStorage: 'siteListInterventionsByReferenceViewModalFilters',
		tableFilterConfig: byReferenceDefaultFiltersValues,
	},
	// invoice -----------------------------------------
	{
		keyLocalStorage: 'invoicesFilter',
		tableFilterConfig: invoiceDefaultFilterConfigValues,
	},
	{
		keyLocalStorage: 'interventionsListInvoicesViewModalFilters',
		tableFilterConfig: invoiceDefaultFilterConfigValues,
	},
	{
		keyLocalStorage: 'vehiclesListInvoicesViewModalFilters',
		tableFilterConfig: {
			...invoiceDefaultFilterConfigValues,
			period: EPeriodFilter.thisMonth,
			dateCreated: [
				{
					before: dayjs().endOf('month').toDate(),
					after: dayjs().startOf('month').toDate(),
				},
			],
		},
	},

	// interventionForms -----------------------------------------
	{
		keyLocalStorage: 'interventionFormsFilter',
		tableFilterConfig: interventionFormsDefaultFilterConfigValues,
	},
	{
		keyLocalStorage: 'interventionsListInterventionFormsViewModalFilters',
		tableFilterConfig: {
			...interventionFormsDefaultFilterConfigValues,
			period: EPeriodFilter.allPeriod,
			startFrom: null,
			endTo: null,
		},
	},
	{
		keyLocalStorage: 'vehiclesListInterventionFormsViewModalFilters',
		tableFilterConfig: {
			...interventionFormsDefaultFilterConfigValues,
			period: EPeriodFilter.allPeriod,
			startFrom: null,
			endTo: null,
		},
	},
	// documents vehicles -----------------------------------------
	{
		keyLocalStorage: 'vehiclesDocumentsFilter',
		tableFilterConfig: vehicleDocumentDefaultFilterConfigValues,
	},
	{
		keyLocalStorage: 'vehiclesListDocumentsVehiclesViewModalFilters',
		tableFilterConfig: vehicleDocumentDefaultFilterConfigValues,
	},
	{
		keyLocalStorage: 'interventionsListInvoicesViewModalFilters',
		tableFilterConfig: vehicleDocumentDefaultFilterConfigValues,
	},
	// intervention Transport -----------------------------------------
	{
		keyLocalStorage: 'interventionTransportFilter',
		tableFilterConfig: transportDefaultFiltersValues,
	},
	{
		keyLocalStorage: 'siteListInterventionsTransportViewModalFilters',
		tableFilterConfig: transportDefaultFiltersValues,
	},
	{
		keyLocalStorage: 'customerListInterventionsTransportViewModalFilters',
		tableFilterConfig: transportDefaultFiltersValues,
	},
	{
		keyLocalStorage: 'vehicleListInterventionsTransportViewModalFilters',
		tableFilterConfig: transportDefaultFiltersValues,
	},
	{
		keyLocalStorage: 'agenciesListInterventionsTransportViewModalFilters',
		tableFilterConfig: transportDefaultFiltersValues,
	},
]

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const purgeUnusedTableFilterFromLocalStorage = () => {
	tablesFilterConfig.forEach(({ tableFilterConfig, keyLocalStorage }) => {
		// get the current local storage table filter column
		const getCurrentLocalStorage = JSON.parse(
			localStorage.getItem(keyLocalStorage) as string
		)

		if (getCurrentLocalStorage) {
			const newConfig = {}
			Object.keys(tableFilterConfig).forEach((key) => {
				if (getCurrentLocalStorage[key] !== undefined) {
					newConfig[key] = getCurrentLocalStorage[key]
				} else {
					newConfig[key] = tableFilterConfig[key]
				}
			})

			localStorage.setItem(keyLocalStorage, JSON.stringify(newConfig))

			getActiveFiltersNumber(tableFilterConfig, newConfig)
		}
	})
}
