import React, { useCallback, useContext } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid'

import { useFormatMessage } from '../../utils/hooks'
import { AuthContext } from '../../utils/contexts/AuthContext'
import { handleOpenViewModal } from '../../utils/utils'
import { Divider } from '@mui/material'

const avatarListCSS = {
	backgroundColor: 'white',
	color: ({ palette }) => palette.grey['800'],
}

const hrCSS = {
	width: '80%',
	height: '1px',
	backgroundColor: '#e0e0e0',
}

const dividerCSS = (item) => {
	if (item.primaryText === 'misc.accountType') {
		return {
			width: '40%',
			height: '1px',
			backgroundColor: '#e0e0e0',

			'@media only screen and (max-width: 900px)': {
				width: '80%',
			},
		}
	}

	return hrCSS
}

const listCSS = {
	maxWidth: '750px',

	'& .listItemCSS': {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
}

const listLinkCSS = (item, haveAccess) => {
	const isLink = (item?.viewModalLink || item?.externalLink) && haveAccess

	return {
		cursor: isLink ? 'pointer' : 'inherit',

		wordBreak: 'break-all',

		'& .MuiListItemText-primary': {
			lineHeight: '12px',
			fontSize: ({ typography }) => typography.body2.fontSize as any,
		},
		'& .MuiListItemText-secondary': {
			color: 'black',
			fontSize: ({ typography }) => typography.subtitle2.fontSize as any,
			fontWeight: ({ typography }) => typography.subtitle2.fontWeight as any,
			'&:hover': {
				color: isLink ? ({ palette }) => palette.primary.main : 'inherit',
			},
		},
	}
}

interface IItems {
	sizeColumn?: number
	primaryText?: string
	secondaryText?: string
	icon?: any
	iconSize?: string
	viewModalLink?: string
	externalLink?: string
	externalLinkTarget?: string
	permissions?: any
	modules?: any
	nbColItem?: any
}

interface IListItemsData {
	items: Array<IItems>
	nbCol?: any
	underlined?: boolean
}

const ListItemsData: React.FC<IListItemsData> = ({
	items,
	nbCol,
	underlined = false,
}) => {
	const intlMsg = useFormatMessage()
	const { checkPermissions, checkModules } = useContext(AuthContext)
	const navigate = useNavigate()
	const location = useLocation()

	const haveAccess = (item) => {
		if (item?.createPermission || item?.modules) {
			return (
				(item?.createPermission && checkPermissions(item?.createPermission)) ||
				(item?.modules && checkModules(item?.modules))
			)
		}

		return true
	}

	const openLink = useCallback(
		(externalLink, externalLinkTarget) => () => {
			window.open(externalLink, externalLinkTarget ?? '_blank')
		},
		[]
	)

	return (
		<List component='div' dense sx={listCSS}>
			<Grid container spacing={1}>
				{items?.map((item, i) => (
					<Grid
						item
						sm={12}
						md={item?.sizeColumn || item?.nbColItem || nbCol || 4}
						key={i}
						className='listItemCSS'
					>
						<ListItem
							disableGutters
							onMouseDown={
								(item?.viewModalLink &&
									haveAccess(item) &&
									handleOpenViewModal(
										navigate,
										location,
										item.viewModalLink
									)) ||
								((item?.externalLink &&
									openLink(item.externalLink, item?.externalLinkTarget)) as any)
							}
							sx={listLinkCSS(item, haveAccess(item))}
						>
							{item?.icon && (
								<ListItemAvatar>
									<Avatar
										sx={{
											...avatarListCSS,
											height: underlined ? '64px' : 'auto',
										}}
									>
										{item.icon}
									</Avatar>
								</ListItemAvatar>
							)}

							<ListItemText
								primary={item?.primaryText ? intlMsg(item.primaryText) : ''}
								secondary={item?.secondaryText}
							/>
						</ListItem>
						{underlined && i !== items?.length - 1 && (
							<Divider orientation='vertical' flexItem sx={dividerCSS(item)} />
						)}
					</Grid>
				))}
			</Grid>
		</List>
	)
}

export default ListItemsData
