import React, { useContext, useMemo } from 'react'
import { Card } from '@mui/material'

import MultiTableNav from '../../../components/multiNavTable'
import ProductCatalogueProvider, {
	ProductCatalogueContext,
} from './ProductCatalogueProvider'
import ProductCatalogueConfig from './ProductCatalogueConfig'
import { EInterventionTypes } from '../../../utils/app-models'
import { AuthContext } from '../../../utils/contexts/AuthContext'
import { ROUTES } from '../../../routes/consts'
import { IMultiTableNavConfig } from '../../../utils/typescript/multiNavTableType'

const CardCSS = { width: 1, height: 1 }

interface IProductCatalogue {}

const ProductCatalogue: React.FC<IProductCatalogue> = () => {
	const { checkAllModules } = useContext(AuthContext)
	const { currentUser } = useContext(AuthContext)
	const isAgency: boolean = useMemo(
		() => currentUser.account.networks?.collection?.length,
		[currentUser]
	)

	const filteredConfig: IMultiTableNavConfig[] = useMemo(() => {
		return ProductCatalogueConfig.filter((conf) => {
			// display shared product only for agency
			if (
				conf.tableMainNav.route ===
					ROUTES.ADMINISTRATION.PRODUCT_CATALOGUE.CATALOGUE_PRODUCT_SHARED
						.url &&
				!isAgency
			) {
				return
			}
			return checkAllModules(conf.modulesName)
		})
	}, [ProductCatalogueConfig])

	return (
		<Card elevation={1} sx={CardCSS}>
			<ProductCatalogueProvider moduleName={EInterventionTypes.control}>
				<MultiTableNav
					multiTableNavConfig={filteredConfig}
					mainContext={ProductCatalogueContext}
				/>
			</ProductCatalogueProvider>
		</Card>
	)
}

export default ProductCatalogue
