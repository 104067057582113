import React from 'react'
import { Typography } from '@mui/material'

import { useRemoveDocumentVehicle } from '../../../../../api/gql/mutations/documentsVehiclesMutation'
import ModalProvider from '../../../../../providers/ModalProvider'
import {
	EFormModalMode,
	EPermissionScope,
} from '../../../../../utils/app-models'
import { formatDate } from '../../../../../utils/date'
import translateIntlMsgString from '../../../../../utils/translateIntlMsgString'
import { documentVehicleValidationSchema } from '../../../../../utils/validationSchema'
import VehicleDocumentModal from '../../../../../view/dashboard/vehiclesDocuments/vehicleDocumentModal'
import ActionsBtn from '../../../tableCell/actionsBtn'
import OpenLinkCell from '../../../tableCell/OpenLinkCell'
import TimeCell from '../../../tableCell/TimeCell'
import UploadAwsFile from '../../../tableCell/UploadAwsFile'
import { vmLinkByInterventionType } from '../../../../../routes/linkViewModal'
import UserAvatarSlidePanel from '../../../../avatar/UserAvatarSlidePanel'
import InterventionType from '../../../tableCell/InterventionType'

const listDocumentsVehiclesViewModalTable = [
	{
		columnLabel: 'documentVehicle.ref',
		orderBy: 'reference',
		stickyFirstCol: true,
		dataKey: 'reference',
		maxCharacters: 30,
	},

	{
		columnLabel: 'documentVehicle.type',
		orderBy: 'category_name',
		personalizedCell: ({ category }) => (
			<Typography>
				{translateIntlMsgString(`documentVehicle.${category.code}`)}
			</Typography>
		),
	},

	{
		columnLabel: 'misc.mission',
		orderBy: 'reference',
		personalizedCell: ({ intervention }) => (
			<>
				{intervention?.reference ? (
					<OpenLinkCell
						viewModalLink={vmLinkByInterventionType(
							intervention.interventionType,
							intervention.id
						)}
						txt={intervention.reference}
						isSharedWithMe={intervention.isSharedWithMe}
					/>
				) : (
					'-'
				)}
			</>
		),
	},

	{
		columnLabel: 'misc.missionType',
		personalizedCell: ({ intervention }) => (
			<InterventionType interventionType={intervention?.interventionType} />
		),
	},

	{
		columnLabel: 'misc.addAt',
		orderBy: 'dateCreated',
		personalizedCell: ({ dateCreated }) => (
			<TimeCell date={dateCreated ? formatDate(dateCreated) : '-'} />
		),
	},

	{
		columnLabel: 'invoice.addBy',
		orderBy: 'createdBy_lastName',
		personalizedCell: ({ createdBy }) => (
			<UserAvatarSlidePanel
				firstName={createdBy?.firstName}
				lastName={createdBy?.lastName}
				userId={createdBy?.id}
				displayName={false}
			/>
		),
	},

	{
		columnLabel: 'invoice.document',
		orderBy: 'file',
		personalizedCell: ({ id, file }) => file && <UploadAwsFile id={id} />,
	},

	{
		fixWidth: '50px',
		columnLabel: '',
		visible: true,
		tableCellAlign: 'center',
		cellAlign: 'center',
		sticky: true,
		personalizedCell: ({ id }: any) => (
			<ModalProvider
				validationSchemaFunction={documentVehicleValidationSchema}
				formModalMode={EFormModalMode.edit}
			>
				<ActionsBtn
					rowId={id}
					deleted={{
						query: useRemoveDocumentVehicle,
						refreshQuery: [
							'getDocumentsVehicles',
							'getDocumentsVehicleCountByVehicle',
							'getDocumentsVehicleCountByIntervention',
						],
						successMessageDelete: 'success.documentVehicleDeleted',
						messageConfirm: {
							title: 'confirmModal.deleteDocumentVehicleTitle',
							message: 'confirmModal.deleteDocumentVehicleMessage',
						},
					}}
					viewAction={false}
					editModal={
						<VehicleDocumentModal
							documentVehicleId={id}
							modalUtils={{} as any}
						/>
					}
					permissions={{ update: [EPermissionScope.documentManage] }} // TODO
				/>
			</ModalProvider>
		),
	},
]

export default listDocumentsVehiclesViewModalTable
