import React, { useEffect, useMemo, useState } from 'react'

import toast from 'react-hot-toast'
import { Stack, Grid, CircularProgress } from '@mui/material'

import api from '../../api/axios/axios'
import InvalidToken from './InvalidToken'
import SetPassword from './SetPassword'
import LayoutLogout from '../../components/layout/LayoutLogout'
import { blockLeftCSS } from '../../styles/sxCSS/logout/logout'
import { useParams } from 'react-router-dom'

interface IForgottenPasswordCheck {}

const ForgottenPasswordCheck: React.FC<IForgottenPasswordCheck> = () => {
	const params = useParams()
	const forgottenPasswordToken = useMemo(
		() => params?.forgottenPasswordToken,
		[params]
	)
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(false)

	useEffect(() => {
		if (forgottenPasswordToken) {
			api
				.post('/_ctrl/forgotten-password-token-check', {
					token: forgottenPasswordToken,
				})
				.then(() => {
					setLoading(false)
					setError(false)
				})
				.catch((error) => {
					toast.error(error.message)
					setError(true)
					setLoading(false)
				})
		} else {
			setError(true)
			setLoading(false)
		}
	}, [forgottenPasswordToken])

	return (
		<LayoutLogout>
			<Grid container sx={{ height: 1 }}>
				<Grid item xs={12} md={6} sx={blockLeftCSS}>
					<Stack p={4} sx={{ width: { xs: 300, md: 400 } }}>
						<img src={`/img/illustration/password-2.png`} />
					</Stack>
				</Grid>

				<Grid item xs={12} md={6}>
					<Stack p={4} spacing={2} justifyContent='center' sx={{ height: 1 }}>
						{loading ? (
							<CircularProgress size={24} sx={{ position: 'absolute' }} />
						) : error ? (
							<InvalidToken />
						) : (
							<SetPassword forgottenPasswordToken={forgottenPasswordToken} />
						)}
					</Stack>
				</Grid>
			</Grid>
		</LayoutLogout>
	)
}

export default ForgottenPasswordCheck
