/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'

import CalendarFilters from '.'

export const calendarDefaultFiltersValues = {
	category: 'all',
	interventionType: null,
	customer_list: null,
	actors_list: null,
	agency_list: null,
	isWithoutActor: false,
	status_list: null,
	valueStatusGroup: 'interventionAll',
}

export const calendarDefaultFiltersConfig = {
	componentsFiltersConfig: <CalendarFilters />,
	defaultFiltersValue: calendarDefaultFiltersValues,
	localStorageKeyFilter: 'calendarFilters',
}
