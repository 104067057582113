import React, { useContext, useMemo } from 'react'

import { Grid, Stack } from '@mui/material'

import TransportStatus from './transportStatus'
import TransportServiceRequest from './serviceRequest'
import TransportPublicDescription from './publicDescription'
import TransportActors from './actors'
import TransportVehicles from './vehicles'
import TransportTrips from './transportTrip'
import { FormModalContext } from '../../../../../../components/forms/formModal/FormModalContext'
import AlerteMessage from '../../../../../../components/alert/AlerteMessage'
import { useFormatMessage } from '../../../../../../utils/hooks'

const wrapperModalCSS = {
	p: '20px 8px 32px 32px',
	overflow: 'auto',
}

interface IInterventionModalControlInfos {}

const InterventionModalControlInfos: React.FC<
	IInterventionModalControlInfos
> = () => {
	const { curentEditedElement } = useContext(FormModalContext)
	const intlMsg = useFormatMessage()

	const isSharedTransport = useMemo(
		() => curentEditedElement?.isSharedWithMe || false,
		[curentEditedElement?.isSharedWithMe]
	)
	// Dynamic variable for AlertMessage component
	const name = useMemo(
		() =>
			`<span style='color: black; font-size: 14px;'>${curentEditedElement?.createdByAccount?.name}</span>`,
		[curentEditedElement?.createdByAccount]
	)
	const message = useMemo(
		() => intlMsg('misc.bannerMessageFormModalTransport', { name }),
		[name]
	)
	return (
		<>
			{isSharedTransport && <AlerteMessage message={message} />}
			<Grid container sx={wrapperModalCSS}>
				<Grid item xs={12} md={4} pr={4}>
					<Stack spacing={4}>
						<TransportStatus isSharedTransport={isSharedTransport} />
						{!isSharedTransport && <TransportServiceRequest />}
						<TransportActors isSharedTransport={isSharedTransport} />
						{!isSharedTransport && <TransportPublicDescription />}
					</Stack>
				</Grid>

				<Grid item xs={12} md={8}>
					<Stack spacing={1.5}>
						<TransportVehicles isSharedTransport={isSharedTransport} />
						<TransportTrips isSharedTransport={isSharedTransport} />
					</Stack>
				</Grid>
			</Grid>
		</>
	)
}

export default InterventionModalControlInfos
