import React, { useCallback, useState, useMemo } from 'react'

import { useNavigate, useLocation } from 'react-router-dom'
import { Stack } from '@mui/material'

import { useFormatMessage } from '../../../../utils/hooks'
import VehicleInterventionListItem from './VehicleInterventionListItem'
import { EStatusVehicle } from '../../../../utils/app-models'
import SquareColor from '../../../color/SquareColor'
import { handleOpenViewModal } from '../../../../utils/utils'
import MultiItemsButton from '../../MultiItemsButton'
import CustomLink from '../../../_overrideMui/Link/CustomLink'
import MultiItemsPopover from '..'
import { ROUTES } from '../../../../routes/consts'

interface IVehiclesInterventionPopover {
	data
}

const VehiclesInterventionPopover: React.FC<IVehiclesInterventionPopover> = ({
	data,
}) => {
	const intlMsg = useFormatMessage()
	const navigate = useNavigate()
	const location: any = useLocation()
	const [anchorEl, setAnchorEl] = useState(null)
	const handleClick = useCallback((e) => setAnchorEl(e.currentTarget), [])
	const handleClose = useCallback(() => setAnchorEl(null), [])
	const open = useMemo(() => Boolean(anchorEl), [anchorEl])

	const StatusVehicle = useCallback(
		(status) => {
			switch (status?.code?.toLowerCase().split(' ').join('')) {
				case EStatusVehicle.todo:
					return intlMsg('misc.todo')

				case EStatusVehicle.pending:
					return intlMsg('interventions.running')

				case EStatusVehicle.returned:
					return intlMsg('misc.returned')

				case EStatusVehicle.done:
					return intlMsg('interventions.done')

				case EStatusVehicle.noShow:
					return intlMsg('interventions.noShow')

				case EStatusVehicle.cancel:
					return intlMsg('interventions.canceled')
			}
		},

		[]
	)

	const customButton = useCallback(() => {
		return (
			<Stack spacing={0.5} direction='row' alignItems='center'>
				<CustomLink
					variantlink='primaryLink'
					onMouseDown={
						data[0] &&
						handleOpenViewModal(
							navigate,
							location,
							`${ROUTES.VIEWMODAL.VEHICLES.url}${data[0]?.vehicle?.id}`
						)
					}
				>
					<div>
						{`${
							data[0]?.vehicle?.vehicleDetail?.immat ||
							data[0]?.vehicle?.vehicleDetail?.vin
						}`}
					</div>
				</CustomLink>
				<div> {` - ${StatusVehicle(data[0]?.status)}`}</div>

				<SquareColor statusColor={data[0]?.status?.color} />
			</Stack>
		)
	}, [data])

	return (
		<>
			<MultiItemsButton
				buttonType={data?.length > 1 ? 'chipCountClickable' : 'customButton'}
				nbItems={data?.length}
				label={data?.length > 1 ? intlMsg('misc.vehicles') : customButton}
				handleClick={handleClick}
			/>

			{open && (
				<MultiItemsPopover
					open={open}
					handleClose={handleClose}
					anchorEl={anchorEl}
				>
					<VehicleInterventionListItem
						data={data}
						StatusVehicle={StatusVehicle}
						handleClose={handleClose}
					/>
				</MultiItemsPopover>
			)}
		</>
	)
}

export default VehiclesInterventionPopover
