import React from 'react'

import VmCardExpended from '../../../../components/viewModal/vmCardExpended'
import DataListGrid from '../../../../components/list/dataList/DataListGrid'
import DataList from '../../../../components/list/dataList'
import { EViewModal, dataListType } from '../../../../utils/app-models'

interface IVehicleForm {
	idVehicle: string
	immat: string
	vin: string
	brand: string
	model: string
}

const VehicleForm: React.FC<IVehicleForm> = ({
	idVehicle,
	immat,
	vin,
	brand,
	model,
}) => {
	return (
		<VmCardExpended title='misc.vehicle' titleGutter>
			<DataListGrid>
				<DataList
					dataType={dataListType.vmLink}
					title='vehicle.immat'
					data={[
						{
							text: immat,
							link: { vm: EViewModal.VEHICLES, id: idVehicle },
						},
					]}
				/>

				<DataList
					dataType={dataListType.vmLink}
					title='vehicle.vin'
					data={[
						{
							text: vin,
							link: { vm: EViewModal.VEHICLES, id: idVehicle },
						},
					]}
				/>

				<DataList
					dataType={dataListType.simpleText}
					title='vehicle.brand'
					data={[{ text: brand }]}
				/>

				<DataList
					dataType={dataListType.simpleText}
					title='vehicle.model'
					data={[{ text: model }]}
				/>
			</DataListGrid>
		</VmCardExpended>
	)
}
export default VehicleForm
