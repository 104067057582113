export default {
	interventionLabel: 'Interventions',
	interventionProgrammedVehicles: 'Programmed Vehicles',
	interventionTypes: 'Intervention Types',
	type: 'Type',
	control: 'Intervention',
	transport: 'Transport',
	transports: 'Transports',
	logistic: 'Logistics',
	mecanic: 'Mechanics',
	service: 'Service',
	vehiclesAndServices: 'Vehicles and Services',
	planning: 'Planning',
	create: 'Add Intervention',
	edit: 'Edit Intervention',
	serviceRequest: 'Service Request',
	applicant: 'Applicant',
	interventionPlace: 'Intervention Place',
	automaticEntry: 'Account/Site',
	manualEntry: 'Other Address',
	intervention: 'Intervention',
	dateAndStatus: 'Date and Status',
	intervenants: 'Intervenants',
	comments: 'Comments',
	ref: 'Reference',
	sameLocation: 'Same address as applicant',
	sameOrderLocation: 'Customer address',
	finalAddressText: 'Address',
	finalContactText: 'Contact Name',
	date: 'Date',
	status: 'Status',
	start: 'Start',
	end: 'End',
	hour: 'Hour',
	hours: 'Hours',
	actors: 'Intervenants',
	manager: 'Mission Manager',
	agence: 'Agency',
	agencies: 'Agencies',
	interveningAgencies: 'Intervening Agencies',
	missionDescription: 'Description for the Client',
	actorsDescription: 'Instructions for the Intervenants',
	internalComment: 'Instructions for the Intervenor',
	internalCommentInfo:
		'The instructions are accessible from the mobile application.',
	publicTransportComment: 'This information will be visible to the customer.',
	managerMission: 'Manager',
	vehicles: 'Vehicles',
	task: 'Products',
	globalReportsAndProducts: 'Reports and Products of the Intervention',
	globalProducts: 'Products of the Intervention',
	globalReports: 'Reports of the Intervention',
	selectProduct: 'Select a Product',
	selectReport: 'Select a Report',
	vehicleDone: 'vehicle(s) completed',
	toplan: 'To plan',
	planned: 'Planned',
	running: 'Running',
	pending: 'Pending',
	done: 'Done',
	waiting: 'Waiting',
	canceled: 'Canceled',
	all: 'All',
	actives: 'Active',
	finished: 'Finished',
	productsToAchieve: 'Products to Achieve',
	reportsToAchieve: 'Reports to Complete',
	byReference: 'By Reference',
	byVehicle: 'By Vehicle',
	statusInter: 'Internal Status',
	noShow: 'No Show',
	Ordering: 'Requester',
	statusInt: 'Intervenant Status',
	addContact: 'Add Contact',
	affectVehicle: 'Assign Vehicles',
	noKnownContact: 'No known contacts at this site',
	noKnownSite: 'No known sites on this account',
	interventionWithoutActors: 'Interventions without actor',
	interventionWithoutConveyor: 'Interventions without conveyor',
	emptyReport: 'No available reports',
	open: 'Open',
	treated: 'Treated',
	security: 'Security',
	aesthetic: 'Aesthetic',
	legal: 'Legal',
	other: 'Other',
	dateIntervention: 'Intervention Date',
	endIntervention: 'End of Intervention',
	noAgencies: 'No agencies are associated with this site',
	interventionLocation: 'Intervention Location',
	intervetionRequestBy: 'Intervention Requested By',
	interventionManager: 'Intervention Manager',
	cancelReason_WARNING: 'Dashboard warning lights',
	cancelReason_NO_CLIENT: 'Client absent',
	cancelReason_NO_VEH: 'Vehicle absent',
	cancelReason_FORECAST: 'Weather forecast',
	cancelReason_OTHER: 'Other reason',
	cancelReason_NONE: 'No reason',
	actorsAccessInterventionMobile:
		'Intervenants will be able to access the intervention from the HubeeCheck mobile application.',
	agencyAssignmentActors:
		'If an agency has multiple active users, they will be responsible for selecting the appropriate actors for the intervention. If the agency has only one active actor, the intervention will be automatically assigned.',
	hideInformation: 'Hide Information',
	clickToAddVehicle: 'Click ADD to assign vehicles to your intervention.',
	addVehicles: 'Add Vehicles',
	interventionVehicles: 'Intervention Vehicles',
	editTransport: 'Edit a transport',
	createTransport: 'Add a transport',
	transportType: 'Transport type',
	addressBook: 'Address book',
	transportRequest: 'Orderer',
	customerRef: 'Customer reference',
	delivery: 'Delivery',
	removal: 'Removal',
	confirmAssignmentImmat: 'This vehicle is currently assigned to :',
	confirmAssignment: 'Do you want to assign it to this intervention?',
	confirmAssignmentTitle: 'Assign a vehicle',
	incompleteAssignmentVehicle: 'Required fields not filled',
	nameOrSocialName: 'Name / Social Name',
	removalAddress: 'Removal address',
	deliveryAddress: 'Delivery address',
	removalDateTitle: 'Removal date and time',
	deliveryDateTitle: 'Delivery date and time',
	removalInstruction:
		'Removal instructions. The instructions are visible to the intervenant from the mobile application.',
	deliveryInstruction:
		'Delivery instructions. The instructions are visible to the intervenant from the mobile application.',
	removalGuideline: 'Removal instructions',
	deliveryGuideline: 'Delivery instructions',
	CONVOY: 'Convoy',
	deliveryDate: 'Delivery date',
	removalDate: 'Removal date',
	hourStart: 'From',
	hourEnd: 'To',
	actorsAccess:
		'The transporter accesses the intervention from the mobile application.',
	agencyUnlimited: 'Unlimited Agency',
	agencyUnlimitedDescription: ' : the agency must assign its own conveyors.',
	agencyLimited: 'Limited Agency',
	agencyLimitedDescription: ' : the conveyor is assigned by default.',
	applyAllProducts:
		'The selected services apply to all vehicles involved in the operation, whether they have been scheduled or not.',
	conveyor: 'Conveyor',
	conveyors: 'Conveyors',
	missingCustomer: 'Customer indicated absent',
	missingRecipient: 'Recipient indicated absent',

	missionRunningTitle: 'The mission is underway.',
	missionRunningText:
		'Signatures will be posted once the mission is completed.',
	refAirTableShort: 'Airtable ref',
	refAirTable: 'AirTable reference',
	signOperator: 'Operator signature',
	operatorsConveyors: 'Operators / Conveyors',
	interventionWithoutOperatorsConveyor: 'Missions without operator / conveyor',
	withoutDeliveryAddress: 'Without delivery address',
	withoutRemovalAddress: 'Without removal address',
}
