export default {
	addAgency: 'Add an agency',
	addNetwork: 'Add a network',
	editAgency: 'Edit an agency',
	agencyInformations: 'Agency information',
	referralInformation: 'Referral information',
	socialName: 'Company name',
	agencyName: 'Agency name',
	agencies: 'Agencies',
	typeAgency: 'Agency type',
	nbCustomer: 'Number of customers',
	manager: 'Manager',
	referentAgency: 'Agency referent',
	adminCanLogin: 'Referent can log in to HubeeConnect',
	shareSitesToAgency: 'Shared sites with the agency',
	sharedCategoriesToAgency: 'Shared product categories with the agency',
	sharedSites: 'Shared sites',
	sharedProducts: 'Shared products',
	changeType: 'Change type',
	changeTypeConfirmText: 'Are you sure you want to change the agency type?',
	invalidSiret: 'Please enter a valid SIRET number',
	agencieHelpProcessus:
		'The agency referent will receive an invitation by email to activate their account.',
	afterAgencieHelpProcessus:
		'After activation, you can assign interventions to them.',
	noSharedToAgencies: 'No associated agencies',
	limited1: '1 <em>unique</em> user',
	limited2: 'Limited to the interventions you share with them',
	limited3: 'Export of their shared data',
	limited4: 'Access to their shared activity',
	limited5: 'Access to the mobile application',

	unlimited1: '<em>Unlimited</em> users',
	unlimited2: '<em>Unlimited</em> intervention management',
	unlimited3: '<em>Unlimited</em> access to extensions',
	unlimited4: 'Export of their complete data',
	unlimited5: 'Full management of their activity',
	unlimited6: '<em>Unlimited</em> access to the mobile application',
}
