import React from 'react'
import translateIntlMsgString from '../../../../utils/translateIntlMsgString'
import UserModalInfo from './UserModalInfo'
import UserModalTeams from './UserModalTeams'

import { userModalInfoValidationSchema } from './userModalValidationSchema'
import UserPermissions from './UserPermissions'

export const userModalConfigCurrentUser = [
	{
		tabName: translateIntlMsgString('misc.users'),
		component: <UserModalInfo />,
		schema: userModalInfoValidationSchema,
		validationOnSwitch: true,
	},
	{
		tabName: translateIntlMsgString('misc.teams'),
		component: <UserModalTeams />,
	},
]

export const userModalConfig = [
	...userModalConfigCurrentUser,
	{
		tabName: translateIntlMsgString('misc.permissions'),
		component: <UserPermissions />,
	},
]
