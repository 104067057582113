import React, { useCallback } from 'react'

import dayjs from 'dayjs'
import { Stack, Popover } from '@mui/material'
import CalendarCreateIntervention from './calendarCreateIntervention'

interface ISelectedSlotsPopover {
	openPop: boolean
	setOpenPop: any
	selectedSlotsPosition: any
	setSelectedSlotsObj: any
	selectedSlotsObj: any
	currentView: string
}

const SelectedSlotsPopover: React.FC<ISelectedSlotsPopover> = ({
	openPop,
	setOpenPop,
	selectedSlotsPosition,
	setSelectedSlotsObj,
	selectedSlotsObj,
	currentView,
}) => {
	const handleClose = useCallback(() => {
		setOpenPop(false)
		setSelectedSlotsObj(null)
	}, [])

	return (
		<>
			{selectedSlotsObj && (
				<Popover
					id={'test-popover'}
					onClose={handleClose}
					open={openPop}
					anchorOrigin={{
						vertical: selectedSlotsPosition.y - 50,
						horizontal: selectedSlotsPosition.x - 100,
					}}
				>
					<Stack direction='row' alignItems='center' justifyContent='center'>
						<CalendarCreateIntervention
							startDate={selectedSlotsObj?.start}
							endDate={
								currentView === 'month'
									? dayjs(selectedSlotsObj?.end).subtract(1, 'day').toDate()
									: selectedSlotsObj?.end
							}
							isMonthSelection={currentView === 'month'}
							onCloseActions={handleClose}
							isCreateFromCalendar
						/>
					</Stack>
				</Popover>
			)}
		</>
	)
}

export default SelectedSlotsPopover
