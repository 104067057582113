export default {
	immat: 'Immatriculation',
	vin: 'VIN',
	km: 'km',
	KM: 'KM',
	brand: 'Marque',
	model: 'Modèle',
	designation: 'Désignation',
	box: 'Emplacement',
	lastPass: 'Dernier passage',
	nextPass: 'Prochain passage',
	location: 'Emplacement véhicule',
	locationShort: 'Emplacement',
	locationTitle: 'Emplacement du véhicule ',
	locationColor: 'Couleur',
	vehicleCategory: 'Catégorie',
	category: 'Catégorie',
	vehicleType: 'Classification',
	horsePower: 'Puissance moteur (CH)',
	fiscPower: 'Puissance fiscale (CV)',
	carburant: 'Carburant',
	energy: 'Énergie',
	complementaryInfos: 'Informations complémentaires',
	tireSizeBack: 'Dimension pneu arrière',
	tireSizeRear: 'Dimension pneu avant',
	ptac: 'PTAC',
	nbDoors: 'Nb portes',
	nbSeats: 'Nb sièges',
	transmission: 'Transmission',
	vehicleDetailsDataFound: 'Un véhicule a été trouvé dans la base de données',
	vehicleDetailsDataNotFound:
		"Aucun véhicule n'a été trouvé dans la base de données",
	vehicleDetailsDataNotFoundWithoutPermissions:
		"Aucun véhicule n'a été trouvé dans la base de données. Cependant, vous ne possédez pas les autorisations nécessaires pour pouvoir en créer un nouveau.",
	create: 'Ajouter un véhicule',
	edit: 'Modifier un véhicule',
	identificationTitle: 'Identification du véhicule',
	enterTitle: "Renseignez le VIN et/ou l'immatriculation pour poursuivre",
	denominationTitle: 'Dénomination du véhicule',
	technicalInfos: 'Informations techniques',
	statusPresence: 'Statut lors de la dernière intervention',
	presence: 'Présent',
	restitute: 'Restitué',
	absent: 'Absent',
	technicalFile: 'Fiche technique',
	dateImmat: 'Mise en circulation',
	updateConfirmModalTitle: 'Mettre à jour le véhicule ?',
	updateConfirmModalText:
		'Un véhicule correspondant aux informations saisies a été trouvé.<br/>Souhaitez-vous le mettre à jour ?',
	updateConfirmModalTextWithoutPermissions:
		'Un véhicule correspondant aux informations saisies a été trouvé. Cependant, vous ne possédez pas les autorisations nécessaires pour pouvoir le modifier.',
	createVehicle: 'Créer un véhicule',
	removeSelectedVehicles: 'retirer les véhicules sélectionnés',

	fusionConfirmModalTitle: 'Fusion de véhicules',
	// TODO  delete comment when fusion vehicle will be operational (search #fusion_vehicle in the project)
	// fusionConfirmModalText:
	// 	'Les informations saisies correspondent à 2 véhicules. Voulez-vous les fusionner ?',
	fusionConfirmModalText:
		'Nous avons trouvé 2 véhicules différents avec ces informations, veuillez contacter le support à <strong><a href="mailto:support@hubeecar.com" >support@hubeecar.com</a></strong>',

	fusionConfirmModalTextWithoutPermissions:
		'Les informations saisies correspondent à 2 véhicules. Cependant, vous ne possédez pas les autorisations nécessaires pour pouvoir les fusionner',
	statusVehicle: 'Dernier statut',
	planned: 'Planifié',
	todo: 'A faire',
	pending: 'En cours',
	returned: 'Restitué',
	done: 'Effectué',
	noshow: 'Absent',
	canceled: 'Annulé',
	parkEntry: 'Entrée parc',
	parkExit: 'Sortie parc',
	time: 'Temps',

	// fuel type
	GAZOL: 'Diesel',
	GAS: 'Essence',
	HYBRID: 'Hybride',
	ELECTRIC: 'Electrique',
	GAS_GPL: 'Essence / GPL',
	GAS_BIO: 'Essence / Bioéthanol',
	OTHER: 'Autre',

	// Vehicle type
	VP: 'VP - Véhicule Particulier',
	CTTE: 'CTTE - Camionnette',
	CAM: 'CAM - Camion',
	QM: 'QM - Quadricycle à moteur',
	VASP: 'VASP - Véhicule automoteur spécialisé',
	MAGA: 'MAGA - Machine agricole automotrice',
	REX: 'REX - Remorque',
	MIAR: 'MIAR - Machine et instrument remorqué',

	// Transmission
	manual: 'Manuelle',
	automatic: 'Automatique',
	semiAutomatic: 'Semi automatique',
	sequential: 'Séquentielle',

	mileageImmat: 'Kilométrage de',
	mileage: 'Kilométrage',
	dateAdd: 'Ajouté le ',
	by: 'Par',

	// Category vehicle
	HATCHBACK: 'Citadine',
	SEDAN: 'Berline',
	CABRIOLET: 'Cabriolet',
	WAGON: 'Break',
	MINIVAN: 'Monospace',
	MINIBUS: 'Combi',
	SUV: 'SUV',
	LIMO: 'Limousine',
	VU3M3: '3m3',
	VU6M3: '6m3',
	VU9M3: '9m3',
	VU10M3: '10m3 Van',
	VU11M3: '11m3 Van',
	VU12M3: '12m3 Van',
	VU13M3: '13m3 Van',
	VU14M3: '14m3 Van',
	VU15M3: '15m3 Van',
	VU20M3: '20m3 Van',
	BUS: 'Bus',
	STAKEBODY: 'Plateau',
	DUMP: 'Benne',
	TRACTOR: 'Tracteur',
	HEAVY: 'Poids Lourd',
	TRAILER: 'Remorque',
}
