/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'

import { useBulkCustomerContactAction } from '../../../api/gql/mutations/customerContactMutation'
import {
	LIST_CONTACTS,
	useExportContacts,
} from '../../../api/gql/queries/contactsQueries'
import FormBtnModal from '../../../components/forms/formModalBtn'
import listContactsViewTable from '../../../components/tableV2/config/dashboard/customerContacts/listContactsViewTable'
import { EFormModalMode, EPermissionScope } from '../../../utils/app-models'
import {
	ITableToolBarActionConfig,
	ITableToolBarConfig,
} from '../../../utils/typescript/tableType'
import { ContactValidationSchema } from '../../../utils/validationSchema'
import CustomerContactModal from './customerContactModal'

////////////////////////////////////////////////////////////////
// Global variable
////////////////////////////////////////////////////////////////

const queryConfig = {
	query: LIST_CONTACTS,
	queryName: 'contacts',
}

////////////////////////////////////////////////////////////////
// tableToolBarAction CONFIG
////////////////////////////////////////////////////////////////

const refreshQuerie = ['getContactsList']

const deletedBulk = {
	mutation: useBulkCustomerContactAction,
	operation: 'remove',
	successMessageDelete: {
		singular: 'success.contactDeleted',
		plural: 'success.contactsDeleted',
	},
	messageConfirm: {
		title: 'confirmModal.deleteContactsTitle',
		message: 'confirmModal.deleteContactsMessage',
	},
	deletePermissions: [
		EPermissionScope.contactDelete,
		EPermissionScope.contactManage,
	],
}

const tableToolBarActionConfig: ITableToolBarActionConfig = {
	refreshQuerie,
	deletedBulk,
}

////////////////////////////////////////////////////////////////
// TableToolBarHeader CONFIG
////////////////////////////////////////////////////////////////

const tableToolBarConfig: ITableToolBarConfig = {
	title: 'misc.contacts',
	stickyToolBar: true,
	tableConfig: listContactsViewTable,
	localStorageName: 'listContactsViewTableFilters',
	exportConfig: { exportQuery: useExportContacts },
	queryConfig,
	modalButton: {
		Btn: (
			<FormBtnModal
				modal={<CustomerContactModal modalUtils={{} as any} />}
				mode={EFormModalMode.create}
			/>
		),
	},
	validationSchemaFunction: ContactValidationSchema,
	createPermission: [EPermissionScope.contactManage],
}

////////////////////////////////////////////////////////////////
// tableContainer CONFIG
////////////////////////////////////////////////////////////////

const tableContainerConfig = {
	tableConfig: listContactsViewTable,
	queryConfig,
}

////////////////////////////////////////////////////////////////
// -> EXPORT CONFIG MAIN TABLE
////////////////////////////////////////////////////////////////

export const customerContactConfigTable = {
	tableToolBarConfig,
	tableToolBarActionConfig,
	tableContainerConfig,
}
