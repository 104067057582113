import React from 'react'

import Link from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton/IconButton'
import CallIcon from '@mui/icons-material/Call'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import { Typography } from '@mui/material'

interface IPhoneCell {
	txt?: string
	icon: string
}

const PhoneCell: React.FC<IPhoneCell> = ({ txt, icon }) => {
	return (
		<>
			{txt ? (
				<Link href={`tel:${txt}`} color='inherit'>
					<Tooltip
						title={<Typography style={{ color: 'white' }}>{txt}</Typography>}
					>
						<IconButton size='small'>
							{icon === 'phone' ? (
								<CallIcon fontSize='small' />
							) : (
								<PhoneAndroidIcon fontSize='small' />
							)}
						</IconButton>
					</Tooltip>
				</Link>
			) : (
				<Typography sx={{ p: 1, width: 30, textAlign: 'center' }}>-</Typography>
			)}
		</>
	)
}

export default PhoneCell
