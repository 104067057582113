import translateIntlMsgString from '../../../../../utils/translateIntlMsgString'
import { array, boolean, date, object, ref, string } from 'yup'

const interventionTransportModalValidationSchema = object().shape({
	orderingCustomer: object()
		.required(translateIntlMsgString('validationSchema.require'))
		.nullable(true),
	manager: object()
		.required(translateIntlMsgString('validationSchema.require'))
		.nullable(true),
	orderingSite: object()
		.required(translateIntlMsgString('validationSchema.require'))
		.nullable(true),
	status: object()
		.required(translateIntlMsgString('validationSchema.require'))
		.nullable(true),
	interventionTransportType: object()
		.required(translateIntlMsgString('validationSchema.require'))
		.nullable(true),
	isOrderEqualFinal: boolean(),

	/////////////////////////////////////////////////////////
	// Vehicle
	/////////////////////////////////////////////////////////

	immatOrVin: string().when('selectedVehicles', {
		is: (selectedVehicles) => !!selectedVehicles?.length,
		then: string().nullable(true),
		otherwise: string()
			.required(translateIntlMsgString('validationSchema.require'))
			.nullable(true),
	}),

	selectedVehicles: array()
		.min(1, translateIntlMsgString('validationSchema.require'))
		.required(translateIntlMsgString('validationSchema.require'))
		.nullable(true),
	/////////////////////////////////////////////////////////
	// Removal
	/////////////////////////////////////////////////////////

	removalAddress: string()
		.required(translateIntlMsgString('validationSchema.require'))
		.nullable(),
	removalZipcode: string()
		.required(translateIntlMsgString('validationSchema.require'))
		.nullable(),
	removalCity: string()
		.required(translateIntlMsgString('validationSchema.require'))
		.nullable(),
	removalCountry: object()
		.required(translateIntlMsgString('validationSchema.require'))
		.nullable(),
	removalContactText: string().nullable(),
	removalContactPhone: string().nullable(),
	removalDate: date()
		.required(translateIntlMsgString('validationSchema.require'))
		.nullable(),
	removalTimeFrom: date()
		.required(translateIntlMsgString('validationSchema.hourRequired'))
		.typeError(translateIntlMsgString('errors.dateInvalid'))
		.nullable(),
	removalTimeTo: date()
		.typeError(translateIntlMsgString('errors.dateInvalid'))
		.nullable(),
	removalProducts: object().nullable(),
	removalInstruction: string().nullable(),

	/////////////////////////////////////////////////////////
	// Delivery
	/////////////////////////////////////////////////////////

	deliveryAddress: string()
		.required(translateIntlMsgString('validationSchema.require'))
		.nullable(),
	deliveryZipcode: string()
		.required(translateIntlMsgString('validationSchema.require'))
		.nullable(),
	deliveryCity: string()
		.required(translateIntlMsgString('validationSchema.require'))
		.nullable(),
	deliveryCountry: object()
		.required(translateIntlMsgString('validationSchema.require'))
		.nullable(),
	deliveryContactText: string().nullable(),
	deliveryContactPhone: string().nullable(),
	deliveryDate: date()
		.required(translateIntlMsgString('validationSchema.require'))
		.min(
			ref('removalDate'),
			translateIntlMsgString('validationSchema.invalidTransportDateEnd')
		)
		.nullable(),
	deliveryTimeFrom: date()
		.required(translateIntlMsgString('validationSchema.hourRequired'))
		.typeError(translateIntlMsgString('errors.dateInvalid'))
		.nullable(),
	deliveryTimeTo: date()
		.typeError(translateIntlMsgString('errors.dateInvalid'))
		.nullable(),
	deliveryProducts: object().nullable(),
	deliveryInstruction: string().nullable(),

	/////////////////////////////////////////////////////////
	// AddressBook
	/////////////////////////////////////////////////////////
	deliveryAddressBookSite: object().nullable(true),
	deliveryAddressBookCustomer: object().nullable(true),
	removalAddressBookSite: object().nullable(true),
	removalAddressBookCustomer: object().nullable(true),
})

export default interventionTransportModalValidationSchema
