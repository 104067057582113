import React, { useCallback, useContext } from 'react'
import { Stack } from '@mui/material'
import ModalNetwork from '.'
import BtnAddCircle from '../../../../components/buttons/BtnAddCircle'
import { ModalContext } from '../../../../utils/contexts/ModalContext'

interface ICreateNetworkButton {
	setSelectedNetworkId
	setNetworkIsActive
}

const CreateNetworkButton: React.FC<ICreateNetworkButton> = ({
	setSelectedNetworkId,
	setNetworkIsActive,
}) => {
	const { openModal, isVisible } = useContext(ModalContext)
	const handleAddCategory = useCallback(() => {
		openModal()
	}, [])

	return (
		<>
			<Stack direction='row' justifyContent='center' mt={2} mb={2}>
				<BtnAddCircle
					action={handleAddCategory}
					playwrightId='Playwright-CreateNetworkButton-BtnAddCircle'
				/>
			</Stack>
			{isVisible && (
				<ModalNetwork
					setSelectedNetworkId={setSelectedNetworkId}
					setNetworkIsActive={setNetworkIsActive}
				/>
			)}
		</>
	)
}

export default CreateNetworkButton
