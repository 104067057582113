import React, {
	useCallback,
	useEffect,
	useState,
	useMemo,
	Context,
	useContext,
} from 'react'

import Autocomplete from '@mui/material/Autocomplete'
import { debounce } from '@mui/material'

import { useForceUpdate, useFormatMessage } from '../../../../utils/hooks'
import { IFormModalContext } from '../../formModal/FormModalContext'
import { IStepperContext } from '../../formStepper/StepperContext'
import FormSelectSearchQueryInput from './FormSelectSearchQueryInput'
import FormSelectSearchQueryRenderOption from './FormSelectSearchQueryRenderOption'
import { handleFormOnblurV2 } from '../../formsUtils'

interface IFormSelectSearchQuery {
	name: string
	label: string
	context: Context<IFormModalContext> | Context<IStepperContext>
	selectElements:
		| Array<{
				label: string
				key: string
				value: string | number | boolean
		  }>
		| any

	customHelper?: string
	size?: 'medium' | 'small' | undefined
	hidden?: boolean
	missingText?: string
	onBlur?
	valuesDefault?: any
	isDisabled?: boolean
	isRequired?: boolean
	specialOnInputChange?
	specialOnChange?: any
	isLoading?
	setDebouncedState
	minCharacters?: number
	placeholder?: string
	specialOnClear?: any
	triggered?: boolean
	errorKey?: string
	clearOnBlur?: boolean
	forceUpdateTrigger?: any
}

const FormSelectSearchQuery: React.FC<IFormSelectSearchQuery> = ({
	name,
	label,
	context,
	selectElements,
	customHelper,
	valuesDefault,
	size,
	hidden,
	onBlur,
	missingText,
	isDisabled = false,
	isRequired,
	specialOnInputChange,
	isLoading,
	setDebouncedState,
	minCharacters = 0,
	placeholder,
	specialOnClear,
	triggered,
	errorKey,
	specialOnChange,
	clearOnBlur = true,
	forceUpdateTrigger,
}) => {
	const { setValue, data, triggerError } = useContext<
		IFormModalContext | IStepperContext
	>(context as any)
	const forceUpdate = useForceUpdate()
	const watchedValue = useMemo(() => data[name], [data[name]])
	const [errorCharacters, setErrorCharacters] = useState(false)
	const intlMsg = useFormatMessage()
	const [inputChangeReason, setInputChangeReason] = useState<string>()
	const [selectedValue, setSelectedValue] = useState<any>(
		selectElements.find((item) => item.key === valuesDefault || watchedValue)
	)

	////////////////////////////////////////////////////

	const checkMinCharacters = useMemo(() => {
		return debounce((nbCharacters) => {
			setErrorCharacters(nbCharacters < minCharacters && nbCharacters !== 0)
		}, 1000)
	}, [])

	const handleChange = useCallback(
		(_event, newValue, reason) => {
			setSelectedValue(newValue)
			specialOnChange
				? specialOnChange({
						fieldName: name,
						option: newValue?.value || '',
				  })
				: setValue(name, newValue?.value || '')
			setDebouncedState({ searchText: '' })
			reason === 'clear' && specialOnClear && specialOnClear()
		},
		[setValue, specialOnChange]
	)

	const onInputChange = useCallback(
		(_event, newInputValue, reason) => {
			setInputChangeReason(reason)
			specialOnInputChange &&
				newInputValue?.length >= minCharacters &&
				specialOnInputChange(newInputValue)

			minCharacters && checkMinCharacters(newInputValue?.length)
		},
		[selectedValue, specialOnInputChange]
	)

	const getHandleOnblur = useCallback(() => {
		return triggered || onBlur
			? handleFormOnblurV2(triggered, onBlur, name, triggerError)
			: undefined
	}, [triggered, onBlur, triggerError])

	useEffect(() => {
		if (!watchedValue) {
			const newValue =
				selectElements.find((item) => item?.key === watchedValue) || ''
			setSelectedValue(newValue)
		}
	}, [watchedValue])

	useEffect(() => {
		const newValue =
			selectElements.find((item) => item?.key === watchedValue) || ''
		setSelectedValue(newValue)
	}, [watchedValue, selectElements])

	////////////////////////////////////////////////////

	const renderOption = useCallback(
		(props, option) => (
			<FormSelectSearchQueryRenderOption
				key={option?.key}
				props={props}
				option={option}
				selectedValue={selectedValue}
				size={size}
			/>
		),
		[selectedValue]
	)

	const renderInput = useCallback(
		(params) => (
			<FormSelectSearchQueryInput
				params={params}
				label={label}
				isRequired={isRequired}
				isDisabled={isDisabled}
				size={size}
				customHelper={customHelper}
				hidden={hidden}
				isLoading={isLoading}
				placeholder={placeholder}
				name={name}
				errorCharacters={errorCharacters}
				minCharacters={minCharacters}
				watchedValue={watchedValue}
				context={context}
				selectedValue={selectedValue}
				inputChangeReason={inputChangeReason}
				errorKey={errorKey}
			/>
		),
		[
			watchedValue,
			isDisabled,
			selectedValue,
			isLoading,
			inputChangeReason,
			errorCharacters,
			hidden,
			isRequired,
			errorKey,
		]
	)

	////////////////////////////////////////////////////

	// Force popper resize
	useEffect(() => {
		if (forceUpdateTrigger !== undefined) forceUpdate()
	}, [forceUpdateTrigger])

	return (
		<Autocomplete
			value={selectedValue?.key || watchedValue || null}
			onChange={handleChange}
			id={`id-${name}`}
			options={selectElements}
			filterOptions={(x) => x}
			disabled={isDisabled}
			noOptionsText={missingText ? missingText : intlMsg('misc.noResultSearch')}
			getOptionLabel={() => ''}
			isOptionEqualToValue={(option, value) =>
				//todo need to change here
				option?.key === value?.key || option?.key === value
			}
			onInputChange={onInputChange}
			loading={isLoading}
			renderOption={renderOption}
			renderInput={renderInput}
			onBlur={getHandleOnblur}
			fullWidth
			autoHighlight
			loadingText={intlMsg('misc.loading')}
			clearOnBlur={clearOnBlur}
		/>
	)
}

export default FormSelectSearchQuery
