import React from 'react'

import { Controller } from 'react-hook-form'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select/Select'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'

interface ISelectForm {
	name: string
	label: string
	control: any
	selectElements: any[]
	valuesDefault?: any
	isRequired?: boolean
	disabled?: boolean
	onBlur?
	specialOnChange?
	playwrightId?: string
	displayEmptyValueText?: string
}

//! selectElement.value must have a key: label
const SelectForm: React.FC<ISelectForm> = ({
	name,
	label,
	control,
	valuesDefault = '',
	selectElements,
	isRequired = false,
	onBlur,
	specialOnChange,
	disabled = false,
	playwrightId = '',
	displayEmptyValueText = '',
}) => {
	return (
		<>
			<Controller
				name={name}
				control={control}
				defaultValue={valuesDefault}
				render={({
					field: { onChange, value, ...restField },
					fieldState: { error },
				}) => (
					<FormControl
						size='small'
						variant='outlined'
						disabled={disabled}
						fullWidth
					>
						<InputLabel error={!!error} id={`${name}-select-id`}>
							{label}
						</InputLabel>
						<Select
							id={playwrightId}
							{...restField}
							defaultValue={valuesDefault || value || ''}
							renderValue={() =>
								selectElements.find(
									(e) =>
										e.value === value ||
										(value?.id !== undefined && e.value?.id === value?.id)
								)?.label || ''
							}
							onChange={specialOnChange || onChange}
							onBlur={onBlur}
							error={!!error}
							label={`${label}${isRequired ? ' *' : ''}`}
							variant='outlined'
							labelId={`${name}-select-id`}
							fullWidth
							value={value}
						>
							{displayEmptyValueText ? (
								<MenuItem
									value={''}
									style={label === '' ? { height: '36px' } : {}}
								>
									{displayEmptyValueText}
								</MenuItem>
							) : (
								<></>
							)}

							{selectElements?.map(({ key, value, label }) => (
								<MenuItem
									id={`Playwright-SelectForm-Elements-${label}`}
									key={key}
									value={value}
									style={label === '' ? { height: '36px' } : {}}
								>
									{label}
								</MenuItem>
							))}
						</Select>
						{error && (
							<FormHelperText error={!!error}>{error.message}</FormHelperText>
						)}
					</FormControl>
				)}
			/>
		</>
	)
}

export default SelectForm
