import React, { useState, useEffect, useCallback } from 'react'

import { Stack, IconButton, CircularProgress } from '@mui/material'
import ArticleIcon from '@mui/icons-material/Article'

import { useLazyDownloadAwsMediaUrl } from '../../../api/gql/queries/awsQuries'

interface IUploadAwsFile {
	id
}

const UploadAwsFile: React.FC<IUploadAwsFile> = ({ id }) => {
	const [download, setDownload] = useState(false)

	const { getData, loading, data } = useLazyDownloadAwsMediaUrl({
		fetchPolicy: 'network-only',
	})

	// Get url link
	useEffect(() => {
		if (download) {
			getData({ variables: { iri: id } })
			setDownload(false)
		}
	}, [download])

	// download file if exist
	useEffect(() => {
		if (data?.url && !loading) {
			window.open(data.url, '_blank')
		}
	}, [loading])

	const downloadButton = useCallback(() => {
		setDownload(true)
	}, [download])

	return (
		<Stack direction='row' alignItems='center'>
			<IconButton
				color='primary'
				onClick={downloadButton}
				data-test-id='Playwright-UploadAwsFile-download'
			>
				{download ? <CircularProgress size={25} /> : <ArticleIcon />}
			</IconButton>
		</Stack>
	)
}

export default UploadAwsFile
