import React, {
	lazy,
	useState,
	useCallback,
	Suspense,
	useMemo,
	useContext,
} from 'react'

import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import MapIcon from '@mui/icons-material/Map'
import BuildIcon from '@mui/icons-material/Build'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { useFormatMessage, useModal } from '../../../../utils/hooks'
import {
	EFormModalMode,
	EInterventionTypes,
} from '../../../../utils/app-models'
import { ListItemIcon, ListItemText, MenuList, Typography } from '@mui/material'
import { AuthContext } from '../../../../utils/contexts/AuthContext'

const IconIntervention = {
	transport: <MapIcon fontSize='small' />,
	control: <BuildIcon fontSize='small' />,
}

const componentModalMap = {
	transport: lazy(
		() => import('../../interventions/interventionTransport/interventionModal')
	),
	control: lazy(
		() => import('../../interventions/interventionControl/interventionModal')
	),
}

interface ICalendarCreateIntervention {
	startDate?
	endDate?
	isMonthSelection?
	onCloseActions?
	isCreateFromCalendar?: boolean
}

const CalendarCreateIntervention: React.FC<ICalendarCreateIntervention> = ({
	startDate,
	endDate,
	isMonthSelection,
	onCloseActions,
	isCreateFromCalendar = false,
}) => {
	const intlMsg = useFormatMessage()
	const { checkModules } = useContext(AuthContext)
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const [interventionType, setInterventionType] = useState<string | null>(null)
	const open = Boolean(anchorEl)
	const modalUtils = useModal(false, onCloseActions)

	const ModalIntervention = useMemo(() => {
		return interventionType ? componentModalMap[interventionType] : null
	}, [interventionType])

	const filteredInterventionTypes = useMemo(() => {
		return Object.keys(EInterventionTypes).filter((interventionType) =>
			checkModules([`intervention.${interventionType}`])
		)
	}, [])

	const haveMoreInterventionType = useMemo(
		() => filteredInterventionTypes?.length > 1,
		[filteredInterventionTypes]
	)

	const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}, [])

	const handleClose = useCallback(() => {
		setAnchorEl(null)
	}, [])

	const handleOpenModal = useCallback(
		(interventionTypeSelected: string) => () => {
			setInterventionType(interventionTypeSelected)
			modalUtils.openModal()
			handleClose()
		},
		[modalUtils]
	)

	return (
		<>
			{!isCreateFromCalendar ? (
				<>
					<Button
						aria-haspopup='true'
						aria-expanded={open ? 'true' : undefined}
						variant='contained'
						disableElevation
						onClick={
							haveMoreInterventionType
								? handleClick
								: handleOpenModal(filteredInterventionTypes[0])
						}
						endIcon={
							haveMoreInterventionType ? <KeyboardArrowDownIcon /> : <></>
						}
						startIcon={
							!haveMoreInterventionType ? <AddCircleOutlineIcon /> : <></>
						}
					>
						{intlMsg('misc.add')}
					</Button>

					<Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
						{filteredInterventionTypes?.map((key) => (
							<MenuItem onClick={handleOpenModal(key)} disableRipple key={key}>
								<ListItemIcon>{IconIntervention[key]}</ListItemIcon>
								<ListItemText>{intlMsg(`interventions.${key}`)}</ListItemText>
							</MenuItem>
						))}
					</Menu>
				</>
			) : haveMoreInterventionType ? (
				<MenuList>
					<Typography variant='h4' fontWeight={500} p={1} pr={2} pl={2}>
						{intlMsg('misc.add')}
					</Typography>

					{filteredInterventionTypes?.map((key) => (
						<MenuItem onClick={handleOpenModal(key)} disableRipple key={key}>
							<ListItemIcon>{IconIntervention[key]}</ListItemIcon>
							<ListItemText>{intlMsg(`interventions.${key}`)}</ListItemText>
						</MenuItem>
					))}
				</MenuList>
			) : (
				<Button
					variant='contained'
					disableElevation
					onClick={handleOpenModal(filteredInterventionTypes[0])}
					startIcon={<AddCircleOutlineIcon />}
				>
					{intlMsg('misc.add')}
				</Button>
			)}

			{modalUtils?.isVisible && ModalIntervention && (
				<Suspense>
					<ModalIntervention
						modalUtils={modalUtils}
						mode={EFormModalMode.create}
						startDate={startDate}
						endDate={endDate}
						isMonthSelection={isMonthSelection}
					/>
				</Suspense>
			)}
		</>
	)
}

export default CalendarCreateIntervention
