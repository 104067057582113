import React, { useCallback, useContext, useEffect, useState } from 'react'

import { EPeriodFilter } from '../../../../utils/app-models'
import { TableContext } from '../../../../utils/contexts/TableContext'
import FilterBtn from '../../../tableV2/filters/FilterBtn'
import FilterDatePopover from './FilterDatePopover'

interface IFilterDate {
	setFiltersQuerieParam
	filtersQuerieParam
	customFilterDateConfig?
	argumentQuerieName
	setLocalStorageOnchange?: boolean
	customLocalStorageKey?: string
}

const FilterDate: React.FC<IFilterDate> = ({
	setFiltersQuerieParam,
	filtersQuerieParam,
	customFilterDateConfig,
	argumentQuerieName,
	setLocalStorageOnchange = false,
	customLocalStorageKey,
}) => {
	const { localStorageKeyFilter } = useContext(TableContext) || {}
	const [valueType, setValueType] = useState<any>(
		filtersQuerieParam?.period || EPeriodFilter.allPeriod
	)

	useEffect(() => {
		setValueType(filtersQuerieParam?.period)
	}, [filtersQuerieParam?.period])

	// /////////////////////////////////////////////////////////////////////////
	// OPEN/CLOSE popover
	// /////////////////////////////////////////////////////////////////////////

	const [anchorEl, setAnchorEl] = useState(null)
	const handleClick = useCallback((e) => {
		setAnchorEl(e.currentTarget)
	}, [])
	const handleClose = useCallback(() => setAnchorEl(null), [])
	const popoverIsOpen = Boolean(anchorEl)

	// /////////////////////////////////////////////////////////////////////////
	// Update default value with local storage preference
	// /////////////////////////////////////////////////////////////////////////

	useEffect(() => {
		const interventionFilterPref = JSON.parse(
			localStorage.getItem(
				localStorageKeyFilter || customLocalStorageKey
			) as string
		)
		if (interventionFilterPref?.period) {
			setValueType(interventionFilterPref?.period)
		}
	}, [])

	return (
		<>
			<FilterBtn
				title={`misc.period`}
				value={`misc.${valueType}`}
				onClick={handleClick}
			/>

			{popoverIsOpen && (
				<FilterDatePopover
					handleClose={handleClose}
					open={popoverIsOpen}
					anchorEl={anchorEl}
					setValueType={setValueType}
					valueType={valueType}
					setFiltersQuerieParam={setFiltersQuerieParam}
					filtersQuerieParam={filtersQuerieParam}
					localStorageKeyFilter={localStorageKeyFilter || customLocalStorageKey}
					customFilterDateConfig={customFilterDateConfig}
					argumentQuerieName={argumentQuerieName}
					setLocalStorageOnchange={setLocalStorageOnchange}
				/>
			)}
		</>
	)
}

export default FilterDate
