import React from 'react'

import { Box, Typography } from '@mui/material'

import { useFormatMessage } from '../../../../../../utils/hooks'
import { FormModalContext } from '../../../../../../components/forms/formModal/FormModalContext'
import FormTextField from '../../../../../../components/forms/formFields/FormTextField'

interface IPublicDescription {}

const PublicDescription: React.FC<IPublicDescription> = () => {
	const intlMsg = useFormatMessage()

	return (
		<Box>
			<Typography variant='h2' gutterBottom mb={2}>
				{intlMsg('interventions.missionDescription')}
			</Typography>
			<FormTextField
				name='publicComment'
				label={intlMsg('interventions.comments')}
				context={FormModalContext}
				multiline={true}
				minRows={8}
				maxRows={8}
			/>
		</Box>
	)
}
export default PublicDescription
