import React, { useCallback, useContext, useMemo } from 'react'

import { Button, Stack } from '@mui/material'

import { FormModalContext } from '../FormModalContext'
import LoadingButton from '@mui/lab/LoadingButton'
import { footerModalCSS } from '../../modalSxCss'
import OtherButtons from './OtherButtons'

interface IFormModalFooter {}

const cancelBtnSx = {
	marginRight: '0px !important',
	marginLeft: '8px !important',
}

const FormModalFooter: React.FC<IFormModalFooter> = () => {
	const {
		data,
		mode,
		setSelectedTab,
		curentEditedElement,
		modalUtils: { closeModal },
		canValidate,
		validationLoading,
		triggerAllErrors,
		setValidationLoading,
		formModalConfig: {
			otherButtons,
			formModalName,
			onValidate,
			validateBtnText,
			cancelBtnText,
			onClose,
		},
	} = useContext(FormModalContext)

	const btnByOrder = useMemo(
		() => ({
			left: otherButtons?.filter((btn) => btn?.position === 'left') || [],
			middle: otherButtons?.filter((btn) => btn?.position === 'middle') || [],
			right: otherButtons?.filter((btn) => btn?.position === 'right') || [],
		}),
		[otherButtons]
	)

	const handleClose = useCallback(() => {
		onClose && onClose()
		closeModal()
		setSelectedTab(0)
	}, [closeModal])

	const handleValidateClick = useCallback(async () => {
		const isInError = await triggerAllErrors()
		if (!isInError)
			onValidate({
				data,
				setValidationLoading,
				closeModal: handleClose,
				mode,
				curentEditedElement,
			})
	}, [data, onValidate, triggerAllErrors, mode, curentEditedElement])

	return (
		<Stack
			direction='row'
			justifyContent='flex-end'
			spacing={2}
			sx={footerModalCSS}
		>
			{btnByOrder?.left && <OtherButtons btnList={btnByOrder.left} />}

			<Stack direction='row' spacing={1}>
				{cancelBtnText && (
					<Button
						variant='outlined'
						color='primary'
						onClick={handleClose}
						sx={cancelBtnSx}
					>
						{cancelBtnText}
					</Button>
				)}
				{btnByOrder?.middle && <OtherButtons btnList={btnByOrder.middle} />}

				{validateBtnText && (
					<LoadingButton
						id={`Playwright-FormModal-ValidateBtn-${formModalName}`}
						disabled={!canValidate}
						variant='contained'
						type='submit'
						loading={validationLoading}
						onClick={handleValidateClick}
					>
						{validateBtnText}
					</LoadingButton>
				)}
				{btnByOrder?.right && <OtherButtons btnList={btnByOrder.right} />}
			</Stack>
		</Stack>
	)
}

export default FormModalFooter
