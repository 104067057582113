export default {
	adminAccounts: 'Administration : comptes',
	admin: 'Administration',
	contactAdmin: 'Contact administrateur',
	nbUsers: "Nb d'utilisateurs",
	nbVehicles: 'Nb de véhicules',
	nbReportsIntervention: 'Nb de formulaires',
	modules: 'Modules',
	editAccount: 'Modifier le compte',
	createAccount: 'Créer un compte',
}
