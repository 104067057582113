import React, { useCallback, useContext, useEffect, useState } from 'react'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { Stack, Box } from '@mui/material'

import RenderTabLabel from './RenderTabLabel'
import { useFormatMessage } from '../../utils/hooks'
import PermissionsHandler from '../../components/permissionsHandler'
import { AuthContext } from '../../utils/contexts/AuthContext'

import { tabsCSS, tabCSS } from '../../styles/sxCSS/tabs'
import ViewModalTabProvider from './ViewModalTabProvider'

interface IViewModalMainContainer {
	tabConfig
	customTabConfig?
}

const getFirstSelectedTab = ({ tabConfig, checkModules }) =>
	tabConfig?.find(({ modules }) => checkModules(modules))?.label

const getSelectedTabIndex = ({ tabConfig, tabName, checkModules }) => {
	return tabConfig
		.filter(({ modules }) => checkModules(modules))
		.findIndex(({ label }) => label === tabName)
}
const ViewModalMainContainer: React.FC<IViewModalMainContainer> = ({
	tabConfig,
	customTabConfig = false,
}) => {
	const { checkModules } = useContext(AuthContext)

	const intlMsg = useFormatMessage()
	const [selectedTabName, setSelectedTabName] = useState<string>(
		getFirstSelectedTab({
			tabConfig: tabConfig || customTabConfig,
			checkModules,
		})
	)
	const [filteredTabConfig, setfilteredTabConfig] = useState<any>(null)

	const handleSelectTabName = useCallback(
		(selectedTabName) => () => {
			setSelectedTabName(selectedTabName)
		},
		[]
	)

	useEffect(() => {
		if (tabConfig || customTabConfig) {
			const selectedFilteredTabConfig =
				(tabConfig || customTabConfig).filter(
					({ modules }) => !modules || checkModules(modules)
				) || []
			setfilteredTabConfig(selectedFilteredTabConfig)

			setSelectedTabName(
				getFirstSelectedTab({
					tabConfig: selectedFilteredTabConfig,
					checkModules,
				})
			)
		}
	}, [tabConfig, customTabConfig, checkModules])

	return (
		<Stack sx={{ ...tabsCSS, border: 'none', height: '100%' }}>
			<Tabs
				value={getSelectedTabIndex({
					tabConfig: tabConfig || customTabConfig,
					tabName: selectedTabName,
					checkModules,
				})}
				indicatorColor='primary'
				textColor='primary'
				variant='scrollable'
				scrollButtons='auto'
				sx={tabsCSS}
				TabIndicatorProps={{ children: <span /> }}
			>
				{customTabConfig
					? customTabConfig?.map(({ label }, i) => (
							<Tab
								key={`label-${i}`}
								label={label}
								sx={tabCSS}
								onClick={handleSelectTabName(label)}
								disableRipple
							/>
					  ))
					: filteredTabConfig?.map(
							({ label, query, queryName, modules, argumentQueryCount }, i) => (
								<PermissionsHandler modulesName={modules} key={`${label}-${i}`}>
									<Tab
										label={
											query ? (
												<RenderTabLabel
													label={label}
													query={query}
													queryName={queryName}
													argumentQueryCount={argumentQueryCount}
												/>
											) : (
												intlMsg(label)
											)
										}
										sx={tabCSS}
										onClick={handleSelectTabName(label)}
										disableRipple
									/>
								</PermissionsHandler>
							)
					  )}
			</Tabs>
			<Box sx={{ height: '100%' }}>
				{(tabConfig || customTabConfig).map(({ label, component }, i) => {
					const isSelected = label === selectedTabName
					return (
						<div
							key={`${label}-${i}`}
							style={{ display: isSelected ? '' : 'none', height: '100%' }}
							data-test-id={
								isSelected ? `Playwright-vm-${intlMsg(selectedTabName)}` : ''
							}
						>
							<ViewModalTabProvider isSelected={isSelected}>
								{component}
							</ViewModalTabProvider>
						</div>
					)
				})}
			</Box>
		</Stack>
	)
}

export default ViewModalMainContainer
