import React, { useMemo } from 'react'

import Popover from '@mui/material/Popover'
import List from '@mui/material/List'
import ListSubheader from '@mui/material/ListSubheader'
import Typography from '@mui/material/Typography'
import Radio from '@mui/material/Radio'
import ListItem from '@mui/material/ListItem'
import { Box, ListItemText } from '@mui/material'

import { useFormatMessage } from '../../../utils/hooks'
import { stringifiedColor } from '../../../utils/color'
import { formatGeneric } from '../../../utils/string'

const squareColor = {
	height: '11px',
	width: '11px',
	marginRight: '10px',
	borderRadius: 1,
}

interface IActionPopover {
	title: string | undefined
	inputs: any
	handleChange: (event?) => void
	selected: any
	handleClose: () => void
	open: boolean
	anchorEl: HTMLElement | null
	keyTranslate: string
}

const ActionPopover: React.FC<IActionPopover> = ({
	title,
	inputs,
	handleChange,
	selected,
	handleClose,
	open,
	anchorEl,
	keyTranslate,
}) => {
	const intlMsg = useFormatMessage()
	const inputList: any = formatGeneric(inputs.query()?.data)

	const listItemComponents = useMemo(() => {
		return inputList?.map(({ name, color, id, code }: any) => (
			<ListItem
				key={name}
				button
				onClick={handleChange}
				id={id}
				selected={id === selected?.id || false}
			>
				<Box
					sx={{
						...squareColor,
						backgroundColor: stringifiedColor(color || 'grey', 400) as any,
					}}
				></Box>

				{/* // if code is null it's a custom title (without traduction) */}
				<ListItemText
					primary={intlMsg(
						code
							? `${keyTranslate}.${code.toLowerCase().split(' ').join('')}`
							: name
					)}
				/>
				<Radio
					edge='end'
					size='small'
					color={'primary'}
					disableRipple
					checked={id === selected?.id || false}
				/>
			</ListItem>
		))
	}, [inputList, intlMsg, handleChange, selected, keyTranslate])

	return (
		<Popover
			open={open}
			anchorEl={anchorEl}
			onClose={handleClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
		>
			<List dense>
				<ListSubheader component='div' sx={{ p: 2, pb: 1, pt: 1 }}>
					<Typography variant='body2' display='block' sx={{ fontWeight: 800 }}>
						{intlMsg(title)}
					</Typography>
				</ListSubheader>

				{listItemComponents}
			</List>
		</Popover>
	)
}

export default ActionPopover
