import React, { useCallback, useState, useEffect, useContext } from 'react'

import { useFormatMessage } from '../../../../utils/hooks'
import Switch from '../../../switch'
import { Box } from '@mui/material'
import { FiltersContext } from '../../filtersContext'

interface IFilterSwitch {
	setFiltersQuerieParam
	filtersQuerieParam
	argumentQuerieName
	label
	resetIfOtherValueExist?: string
}

const FilterSwitch: React.FC<IFilterSwitch> = ({
	setFiltersQuerieParam,
	filtersQuerieParam,
	argumentQuerieName,
	label,
	resetIfOtherValueExist = '',
}) => {
	const intlMsg = useFormatMessage()
	const { localStorageKeyFilter } = useContext(FiltersContext) || {}
	const [currentFilter, setCurrentFilter]: any = useState(false)
	const [filterIsDisabled, setFilterIsDisabled]: any = useState(false)

	///////////////////////////////////////////////////////////////////////////

	const handleChange = useCallback(
		(e) => {
			// set to currentFilter
			setCurrentFilter(e.target.checked)

			// send to query params
			setFiltersQuerieParam({
				...filtersQuerieParam,
				[argumentQuerieName]: e.target.checked,
			})
		},
		[filtersQuerieParam]
	)

	///////////////////////////////////////////////////////////////////////////

	useEffect(() => {
		const filterPreference = JSON.parse(
			localStorage.getItem(localStorageKeyFilter) as string
		)

		// get local preference
		if (filterPreference?.[argumentQuerieName]) {
			setCurrentFilter(filterPreference[argumentQuerieName])
		}
	}, [])

	///////////////////////////////////////////////////////////////////////////

	useEffect(() => {
		if (resetIfOtherValueExist) {
			if (filtersQuerieParam?.[resetIfOtherValueExist]) {
				setCurrentFilter(null)
				setFilterIsDisabled(true)
			} else {
				setFilterIsDisabled(false)
			}
		}
	}, [filtersQuerieParam])

	return (
		<Box sx={{ ml: 1 }}>
			<Switch
				label={intlMsg(label)}
				name={argumentQuerieName}
				value={currentFilter}
				onChange={handleChange}
				disabled={filterIsDisabled}
			/>
		</Box>
	)
}

export default FilterSwitch
