import React, { useContext, useMemo } from 'react'

import { Box, Grid } from '@mui/material'

import { useFormatMessage } from '../../../../utils/hooks'
import { FormModalContext } from '../../../../components/forms/formModal/FormModalContext'
import DeboucedFormTextField from '../../../../components/forms/formFields/DebouncedFormTextField'
import { getCountries } from '../../../../utils/tabCountries'
import FormTextField from '../../../../components/forms/formFields/FormTextField'
import FormSelectSearch from '../../../../components/forms/formFields/formSelectSearch'
import { useListGestionnaires } from '../../../../api/gql/queries/listGestionnairesQueries'
import UserAvatarOptionLabel from '../../../../components/formTools/UserAvatarOptionLabel'
import { AuthContext } from '../../../../utils/contexts/AuthContext'

const wrapperModalCSS = {
	p: 4,
	overflow: 'auto',
}

interface ICustomerAccountModalInfo {}

const CustomerAccountModalInfo: React.FC<ICustomerAccountModalInfo> = () => {
	const intlMsg = useFormatMessage()
	const { localLanguage } = useContext(AuthContext)
	const { data: gestionnairesList } = useListGestionnaires()

	const countriesOption = useMemo(
		() =>
			getCountries(localLanguage)?.map((country) => ({
				label: country.label,
				key: country.code,
				value: country.code,
			})),
		[localLanguage]
	)

	const gestionnaireOption = useMemo(
		() =>
			gestionnairesList?.map((gestionnaire) => ({
				label: `${gestionnaire?.firstName} ${gestionnaire?.lastName}`,
				key: gestionnaire.id,
				value: gestionnaire,
			})) || [],
		[gestionnairesList]
	)

	return (
		<Box sx={wrapperModalCSS}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<DeboucedFormTextField
						name='name'
						label={intlMsg('misc.name')}
						isRequired
						context={FormModalContext}
					/>
				</Grid>
				<Grid item xs={12} lg={6}>
					<DeboucedFormTextField
						name='siret'
						label={intlMsg('misc.siretNumber')}
						isRequired
						context={FormModalContext}
					/>
				</Grid>
				<Grid item xs={12} lg={6}>
					<FormTextField
						name='tva'
						label={intlMsg('misc.tavNumber')}
						context={FormModalContext}
					/>
				</Grid>
				<Grid item xs={12}>
					<FormTextField
						name='socialName'
						label={intlMsg('misc.socialName')}
						context={FormModalContext}
					/>
				</Grid>
				<Grid item xs={12}>
					<FormTextField
						name='address'
						label={intlMsg('misc.address')}
						context={FormModalContext}
					/>
				</Grid>
				<Grid item xs={12} lg={6}>
					<FormTextField
						name='zipcode'
						label={intlMsg('misc.zipcode')}
						context={FormModalContext}
					/>
				</Grid>
				<Grid item xs={12} lg={6}>
					<FormTextField
						name='city'
						label={intlMsg('misc.city')}
						context={FormModalContext}
					/>
				</Grid>

				<Grid item xs={12}>
					<FormSelectSearch
						name={'country'}
						label={intlMsg('misc.country')}
						selectElements={countriesOption}
						context={FormModalContext}
						missingText={intlMsg('misc.noResultSearch')}
					/>
				</Grid>

				<Grid item xs={12}>
					<FormSelectSearch
						renderOptionLabel={UserAvatarOptionLabel}
						name={'gestionnaire'}
						label={intlMsg('misc.manager')}
						selectElements={gestionnaireOption}
						context={FormModalContext}
						missingText={intlMsg('misc.noResultSearch')}
						isRequired
						trigerred
					/>
				</Grid>
			</Grid>
		</Box>
	)
}

export default CustomerAccountModalInfo
