/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react'
import { EFormModalMode } from '../../../utils/app-models'
import { IModalUtils } from '../../../utils/hooks'
import { IFormModalConfig } from './formModalType'

type ETriggerError = {
	fieldName?: string
	specificData?: any
}

export type IFormModalContext = {
	selectedTab: number
	setSelectedTab: (newSelectedTab: number) => void
	tabCounter: object
	setTabCounter: (tabCounter: any) => void
	data: any
	setValue: (key: string, value: any) => void
	setValues: (values: any) => void
	curentEditedElement: any
	setCurentEditedElement: (element: any) => void
	mode: EFormModalMode
	setMode: (mode: EFormModalMode) => void
	loading: boolean
	setLoading: (loading: boolean) => void
	validationLoading: boolean
	setValidationLoading: (loading: boolean) => void
	canValidate: boolean
	setCanValidate: (canValidate: boolean) => void
	triggerAllErrors: () => boolean
	triggerError: (params?: ETriggerError) => boolean
	resetError: (fieldToReset: string) => void
	errors: any
	setErrors: (errors: any) => void
	resetContext: () => void
	modalUtils: IModalUtils
	formModalConfig: IFormModalConfig
}

export const FormModalContext: React.Context<IFormModalContext> = createContext(
	{} as IFormModalContext
)
