import React from 'react'

const TestRoute: React.FC = () => {
	return (
		<div>
			<span>ROUTE FOR TEST</span>
		</div>
	)
}

export default TestRoute
