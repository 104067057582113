import React, { useMemo } from 'react'
import { Alert, AlertTitle, Stack } from '@mui/material'

import ReferenceForm from './ReferenceForm'
import VehicleForm from './VehicleForm'
import InformationForm from './InformationForm'
import MissionForm from './MissionForm'
import EndingForm from './EndingForm'
import { formatDate } from '../../../../utils/date'
import translateIntlMsgString from '../../../../utils/translateIntlMsgString'
import { EInterventionStatuses } from '../../../../utils/app-models'

const alertCSS = {
	color: 'white',
	backgroundColor: '#673AB7',
	'& svg': { color: 'white' },
	'& .MuiAlertTitle-root': { fontWeight: 'bold', mb: 0 },
}

interface IInterventionForms {
	data
}

const InterventionForms: React.FC<IInterventionForms> = ({ data }) => {
	const missionIsRunning = useMemo(
		() => data?.intervention?.status?.code === EInterventionStatuses.running,
		[data.intervention.status.code]
	)

	return (
		<Stack padding={2} spacing={1}>
			<ReferenceForm interRef={data?.reference} />

			<VehicleForm
				immat={data?.vehicle?.vehicleDetail?.immat}
				vin={data?.vehicle?.vehicleDetail?.vin}
				brand={data?.vehicle?.vehicleDetail?.vehicleBrand?.name}
				model={data?.vehicle?.vehicleModelText}
				idVehicle={data?.vehicle?.id}
			/>

			<InformationForm
				formTemplateName={data?.formTemplate?.name}
				dateCreated={data?.dateCreated}
				createdBy={data?.createdBy}
				geolocation={
					data?.geolocation?.latitude
						? `${Number.parseFloat(data.geolocation.latitude).toFixed(
								7
						  )}, ${Number.parseFloat(data.geolocation.longitude).toFixed(7)}`
						: ''
				}
				geolocationLink={`https://www.google.com/maps/?q=${data?.geolocation?.latitude},${data?.geolocation?.longitude}`}
			/>

			<MissionForm
				missionType={translateIntlMsgString(
					`interventions.${data?.intervention?.interventionType}`
				)}
				missionReference={data?.intervention?.reference}
				missionId={data?.intervention?.id}
				missionDate={formatDate(data?.intervention?.dateFrom, true)}
			/>

			{missionIsRunning && (
				<Alert severity='info' sx={alertCSS}>
					<strong>
						<AlertTitle>
							{translateIntlMsgString('interventions.missionRunningTitle')}
						</AlertTitle>
					</strong>

					{translateIntlMsgString('interventions.missionRunningText')}
				</Alert>
			)}

			<EndingForm
				endingUserType='client'
				missionType={data?.intervention?.interventionType}
				endingForm={data?.intervention?.endingForm}
				cardIsExpended={!missionIsRunning}
			/>

			<EndingForm
				endingUserType='operator'
				missionType={data?.intervention?.interventionType}
				endingForm={data?.intervention?.endingForm}
				cardIsExpended={!missionIsRunning}
			/>
		</Stack>
	)
}

export default InterventionForms
