/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

import {
	EFormFieldsPDF,
	EFormTemplateBasicFields,
} from '../../../../utils/app-models'

import {
	FilterFieldsWithAlertOrAnnotations,
	generateTabs as matchRecordWithPageFields,
} from '../../../../view/viewModal/customTabs/interventionForms/generateConfig'
import {
	dateToFrom,
	formatAddressIntervention,
	mapAnnotations,
} from '../../utilPdf'
import { formatRecord, row3CellTitle, row3CellValue } from './_formatDataUtils'

///////////////////////////////////////////////////////////////////////////////////////////////////////
// INFOS -> Data [] are use to generate table (row,cell,etc..)
///////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////
// FIRST PAGE :  BLOCK LEFT INTERVENTION (DEPRECATED don't delete !!!)
///////////////////////////////////////////////////////////////////////////////////////////////////////

export const dataBlockLeftIntervention = (data, intlMsg) => {
	// Data
	const { intervention, site } = data

	// generate data row title
	const dataBlockLeftInterventionTITLE = [
		row3CellTitle(),
		row3CellTitle(intlMsg, 'pdfForms.intervention'),
		row3CellTitle(intlMsg, 'pdfForms.ref'),
		row3CellTitle(intlMsg, 'pdfForms.site'),
		row3CellTitle(intlMsg, 'pdfForms.address'),
		row3CellTitle(intlMsg, 'pdfForms.dateIntervention'),
		row3CellTitle(),
	]

	// generate data row records value
	const dataBlockLeftInterventionVALUE = [
		row3CellValue(null, true),
		row3CellValue(null, true),

		row3CellValue({
			value: `${intervention?.reference}`,
			type: EFormFieldsPDF.text,
		}),

		row3CellValue({
			value: site?.name,
			type: EFormFieldsPDF.text,
		}),

		row3CellValue({
			value: formatAddressIntervention(intervention),
			type: EFormFieldsPDF.longText,
		}),

		row3CellValue({
			value: dateToFrom(data, ['intervention.dateTo', 'intervention.dateFrom']),
			type: EFormFieldsPDF.longText,
		}),

		row3CellValue(null, true),
	]

	return {
		title: dataBlockLeftInterventionTITLE,
		record: dataBlockLeftInterventionVALUE,
		option: {
			margin: { right: 110 },
			startingY: 'blockForm',
			columnStyles: {
				0: {},
				1: { columnWidth: 70 },
				2: {},
			},
		},
	}
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
// FIRST PAGE :  BLOCK RIGHT COMMENT & RATING (DEPRECATED don't delete !!!)
///////////////////////////////////////////////////////////////////////////////////////////////////////

export const dataBlockRightRatingComment = (data, intlMsg) => {
	// Data
	const { intervention } = data

	// generate data row title
	const dataBlockRightRatingCommentTITLE = [
		row3CellTitle(),
		row3CellTitle(intlMsg, 'pdfForms.commentRatingTitle'),
		row3CellTitle(intlMsg, 'pdfForms.customerRating'),
		row3CellTitle(intlMsg, 'pdfForms.customerComment'),
		row3CellTitle(intlMsg, 'pdfForms.customerOperator'),
		row3CellTitle(),
		row3CellTitle(),
	]

	// generate data row records value
	const dataBlockRightRatingCommentVALUE = [
		row3CellValue(null, true),
		row3CellValue(null, true),
		row3CellValue(
			{
				value: intervention?.endingForm?.value?.client?.grade,
				type: EFormFieldsPDF.rating,
			},
			true
		),
		row3CellValue({
			value: intervention?.endingForm?.value?.client?.comment,
			type: EFormFieldsPDF.longText,
		}),
		row3CellValue({
			value: intervention?.endingForm?.value?.operator?.comment,
			type: EFormFieldsPDF.longText,
		}),
		row3CellValue(null, true),
		row3CellValue(null, true),
	]

	return {
		title: dataBlockRightRatingCommentTITLE,
		record: dataBlockRightRatingCommentVALUE,
		option: {
			margin: { left: 110 },
			startingY: 'blockForm',
			columnStyles: {
				0: {},
				1: { columnWidth: 70 },
				2: {},
			},
		},
	}
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
// FIRST PAGE :  BLOCK LAST ALERTS
///////////////////////////////////////////////////////////////////////////////////////////////////////

export const dataBlockLastAlerts = (data, intlMsg) => {
	const records = [] as any
	const titles = [] as any
	// filter data with alert
	const lastAlertFilter = FilterFieldsWithAlertOrAnnotations(
		data?.records,
		data?.formTemplate?.pages
	)

	if (lastAlertFilter?.length) {
		// For each alerts
		lastAlertFilter.forEach((alert) => {
			// if alert is type image //////////////////////////////////////////////
			if (alert?.fieldTemplate?.type === EFormFieldsPDF.picture) {
				let data = ''

				alert.record.value.forEach((picture, i) => {
					if (picture?.annotations?.length) {
						data += `${
							JSON.parse(alert.fieldTemplate.attributes[0]).picturesList[i].name
						} (${picture?.annotations?.length} défaut${
							picture?.annotations?.length > 1 ? 's' : ''
						}) ,`
					}
				})

				// push fields in the same temporary array 'records'
				records.push({
					fieldType: alert?.fieldTemplate?.type,
					data: data,
				})
				// push title fields in the same temporary array 'title'
				titles.push({
					titleField: alert?.fieldTemplate?.name,
					dataKey: data,
				})
			}

			// Else alert is type control & text //////////////////////////////////////////
			else {
				// push fields in the same temporary array 'records'
				records.push({
					fieldType: alert?.fieldTemplate?.type,
					data: alert?.record,
				})
				// push title fields in the same temporary array 'title'
				titles.push({
					titleField: alert?.fieldTemplate?.name,
					dataKey: formatRecord(alert?.fieldTemplate?.type, alert?.record),
				})
			}
		})
	}

	return { records, titles, option: { translate: intlMsg } }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
// OTHER PAGES :  ALL RECORDS BLOCK
///////////////////////////////////////////////////////////////////////////////////////////////////////

const handleFields = ({ records, field, titles, dataBlocks, indexPush }) => {
	const { fieldTemplate } = field
	let record = field.record

	switch (fieldTemplate?.type) {
		case EFormTemplateBasicFields.instruction: {
			const { hiddenForOthers, instructionText } =
				JSON.parse(fieldTemplate.attributes) || {}
			if (hiddenForOthers) return
			record = instructionText
			break
		}
	}

	records.push({
		fieldType: fieldTemplate?.type,
		data: record,
	})

	titles.push({
		titleField: fieldTemplate?.name,
		dataKey: formatRecord(fieldTemplate?.type, record),
	})

	dataBlocks[indexPush] = {
		typeBlock: 'records',
		records,
		titles,
	}
}

export const dataBlocksAllRecords = (data) => {
	const dataBlocks = [] as any
	let indexPush = 0

	// join the data to be easier to use the forEach
	const dataPageRecord = matchRecordWithPageFields(
		data?.records,
		data?.formTemplate?.pages
	)

	// For each pages
	dataPageRecord.forEach((page, indexPage) => {
		let records = [] as any
		let titles = [] as any

		// push the page title
		dataBlocks[indexPush] = {
			typeBlock: 'pageTitle',
			data: page?.label,
			nbpage: indexPage + 1,
		}

		indexPush++

		// For each records page
		page?.dataTab?.forEach((field, i) => {
			/////////////////////////////////////////////////////////////
			// If record is PICTURE type, need special block to be display
			/////////////////////////////////////////////////////////////

			if (
				[EFormFieldsPDF.picture, EFormFieldsPDF.document].includes(
					field?.fieldTemplate?.type
				)
			) {
				if (field?.record?.value?.forEach) {
					field?.record?.value?.forEach((rec, indexPhotos) => {
						// if photo exist ///////////////////////////////////
						if (rec?.value) {
							const namePhotos = JSON.parse(
								field?.fieldTemplate?.attributes?.[0]
							)?.picturesList?.[indexPhotos]?.name

							const angleType = JSON.parse(
								field?.fieldTemplate?.attributes?.[0]
							)?.picturesList?.[indexPhotos]?.angleType

							dataBlocks.push({
								typeBlock: EFormFieldsPDF.picture,
								title: [
									{
										value1: field?.fieldTemplate?.name,
									},

									{
										value1: '', // Empty value1 & value2, because jsdpf will addImage in this table cell
										value2: '',
									},
									...(field?.fieldTemplate?.type === EFormFieldsPDF.picture
										? [
												{
													value1: rec?.annotations?.length
														? mapAnnotations(rec?.annotations, namePhotos)
														: 'Aucun défauts',
												},
										  ]
										: []),
								],
								record: { rec, angleType },
							})
							indexPush++
						}
					})
				} // if photo don't exist use  ////////////////////////
				else {
					handleFields({
						records,
						field,
						titles,
						dataBlocks,
						indexPush,
					})
				}

				// increment indexPush & reset records & title
				records = []
				titles = []
				indexPush++
			}

			/////////////////////////////////////////////////////////////
			// Else for another type field
			/////////////////////////////////////////////////////////////
			else {
				handleFields({
					records,
					field,
					titles,
					dataBlocks,
					indexPush,
				})
			}

			// at the end of the data page, need to increment indexPush (to push a new pageTitle in a new block)
			page?.dataTab?.length === i + 1 && indexPush++
		})
	})

	return dataBlocks
}
