import React, {
	useState,
	useEffect,
	useContext,
	useCallback,
	useMemo,
} from 'react'

import { TableContext } from '../../../../../utils/contexts/TableContext'
import TableComponent from '../../tableComponent'
import {
	filterData,
	paginateData,
	sortData,
} from './disconnectedTableContainerProvierUtils'
import CloseIcon from '@mui/icons-material/Close'
import { Avatar, Box, Typography } from '@mui/material'
import IndicatorRows from '../../../tableToolBarAction/indicatorRows'
import { useFormatMessage } from '../../../../../utils/hooks'

const iconDelete = {
	marginLeft: 'auto',
	display: 'flex',
	backgroundColor: 'transparent !important',

	'& svg': {
		fill: '#757575',
	},

	'&:hover': {
		cursor: 'pointer',
		backgroundColor: '#f5f5f5 !important',
	},
}

const toolBarActionCSS = {
	display: 'flex',
	justifyContent: 'space-between',

	'& .toolBarAction-right': {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}

const toolBarActionParagraphCSS = {
	color: '#00000061',
}

interface IDisconnectedTableContainerProvier {
	height?: string
	colorBorderLeftSelected?: string
	stickyToolBar?: boolean
	disabledCheckbox?: boolean
	filteredTableConfig: any
	forcedData?: any
}

const DisconnectedTableContainerProvier: React.FC<
	IDisconnectedTableContainerProvier
> = ({
	height,
	colorBorderLeftSelected,
	stickyToolBar = false,
	disabledCheckbox = false,
	filteredTableConfig,
	forcedData,
}) => {
	/* eslint-disable @typescript-eslint/no-unused-vars */
	const {
		currentOrderBy,
		setCurrentOrderBy,
		search,
		page,
		selectedRow,
		setSelectedRow,
		onMultiDelete,
	} = useContext(TableContext) || {}

	const [rows, setRows] = useState(forcedData)

	const [rowsPerPage, setRowsPerPage] = useState(10)

	const currentOrderByName = useMemo(
		() => Object.keys(currentOrderBy || {})?.[0] || '',
		[currentOrderBy]
	)
	const intlMsg = useFormatMessage()

	useEffect(() => {
		setRows(
			paginateData(
				sortData(filterData(forcedData, search?.searchText), currentOrderBy),
				page,
				rowsPerPage
			)
		)
	}, [
		currentOrderBy,
		forcedData?.length,
		search?.searchText,
		page,
		rowsPerPage,
	])

	const handleRequestSort = useCallback(
		({ orderBy }) => {
			setCurrentOrderBy({
				[orderBy]:
					currentOrderByName !== orderBy
						? 'asc'
						: currentOrderBy[currentOrderByName] === 'asc'
						? 'desc'
						: 'asc',
			})
		},
		[currentOrderByName, currentOrderBy, setCurrentOrderBy]
	)

	const deleteSelected = useCallback(() => {
		onMultiDelete(selectedRow)
		setSelectedRow([])
	}, [selectedRow])

	return (
		<>
			{selectedRow?.length >= 1 && (
				<Box sx={toolBarActionCSS}>
					<IndicatorRows
						setSelectedRow={setSelectedRow}
						selectedRow={selectedRow}
					/>
					<Box className='toolBarAction-right'>
						<Typography sx={toolBarActionParagraphCSS}>
							{intlMsg('vehicle.removeSelectedVehicles').toUpperCase()}
						</Typography>
						<Avatar sx={iconDelete}>
							<CloseIcon onClick={deleteSelected} />
						</Avatar>
					</Box>
				</Box>
			)}
			<TableComponent
				height={height}
				colorBorderLeftSelected={colorBorderLeftSelected}
				stickyToolBar={stickyToolBar}
				disabledCheckbox={disabledCheckbox}
				data={rows}
				filteredTableConfig={filteredTableConfig}
				handleRequestSort={handleRequestSort}
				rows={rows}
				count={forcedData?.length}
				rowsPerPage={rowsPerPage}
				setRowsPerPage={setRowsPerPage}
			/>
		</>
	)
}

export default DisconnectedTableContainerProvier
