/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
	gql,
	OperationVariables,
	QueryHookOptions,
	useQuery,
} from '@apollo/client'

import {
	listAlertsAttributes,
	listAlertsCategory,
	listAlertsStatuses,
} from '../attributes/alertesAttributes'

export const LIST_ALERTS = gql`
query getAlerts(
    $page: Int
    $itemsPerPage: Int
	$searchText: String
	$category: String
	$status: String
	$startFrom: String
	$endTo: String
	$site: String
	$intervention: String
	$customer: String
	$vehicle: String
	$orderBy: [AlertFilter_orderBy]
) {
      alerts(page: $page, itemsPerPage: $itemsPerPage, searchText: $searchText, category : $category, status : $status, startFrom: $startFrom,
			endTo: $endTo,site: $site,intervention: $intervention,customer: $customer, vehicle: $vehicle, orderBy: $orderBy) {
        ${listAlertsAttributes} 
    }
}`

export const EXPORT_ALERTS = gql`
	query exportAlerts(
		$searchText: String
		$category: String
		$status: String
		$startFrom: String
		$endTo: String
		$site: String
		$intervention: String
		$customer: String
		$vehicle: String
		$timezone: Int
		$orderBy: [AlertFilter_orderBy]
	) {
		exportAlerts(
			searchText: $searchText
			category: $category
			status: $status
			startFrom: $startFrom
			endTo: $endTo
			site: $site
			intervention: $intervention
			customer: $customer
			vehicle: $vehicle
			timezone: $timezone
			orderBy: $orderBy
		) {
			id
			exportFile
		}
	}
`

export const ALERT_STATUSES = gql`
	query getAlertStatuses {
        ${listAlertsStatuses}
	}
`

export const ALERT_CATEGORY = gql`
	query getAlertCategory {
        ${listAlertsCategory}
	}
`

export const ALERT_COUNT_BY_SITE = gql`
	query getAlertNumbersBySite(
		$site: String
		$searchText: String
		$category: String
		$status: String
		$startFrom: String
		$endTo: String
	) {
		alerts(
			page: 1
			itemsPerPage: 1
			site: $site
			searchText: $searchText
			category: $category
			status: $status
			startFrom: $startFrom
			endTo: $endTo
		) {
			paginationInfo {
				totalCount
			}
		}
	}
`

export const ALERT_COUNT_BY_CUSTOMER = gql`
	query getAlertNumbersByCustomer($customer: String) {
		alerts(page: 1, itemsPerPage: 1, customer: $customer) {
			paginationInfo {
				totalCount
			}
		}
	}
`
export const ALERT_COUNT_BY_INTERVENTION = gql`
	query getAlertNumbersByIntervention($intervention: String) {
		alerts(page: 1, itemsPerPage: 1, intervention: $intervention) {
			paginationInfo {
				totalCount
			}
		}
	}
`
export const ALERT_COUNT_BY_VEHICLE = gql`
	query getAlertNumbersByVehicle($vehicle: String) {
		alerts(
			page: 1
			itemsPerPage: 1
			vehicle: $vehicle
		) {
			paginationInfo {
				totalCount
			}
		}
	}
`

export const ALERT_WIDGET = gql`
	query getAlertNumbersByVehicle(
		$vehicle: String
		$startFrom: String
		$endTo: String
	) {
		alerts(
			page: 1
			itemsPerPage: 100000
			vehicle: $vehicle
			startFrom: $startFrom
			endTo: $endTo
		) {
			collection {
				id
				status {
					id
					isTreated
				}
				category {
					id
					name
					code
					color
				}
			}
		}
	}
`

export const ALERT_TREATED_WIDGET = gql`
	query getAlertTreatedByVehicle($startFrom: String, $endTo: String, $vehicle: String) {
		alerts(
			page: 1
			itemsPerPage: 100000
			startFrom: $startFrom
			endTo: $endTo
            vehicle: $vehicle
		) {
			collection {
				id
				status {
					id
					isTreated
				}
			}
		}
	}
`

/**  ---------------------HOOKS---------------------- */

export const useAlertStatusesList: any = () => {
	const { loading, data } = useQuery(ALERT_STATUSES)

	return {
		loading,
		data: data?.alertStatuses || null,
	}
}
export const useAlertCategoryList: any = () => {
	const { loading, data } = useQuery(ALERT_CATEGORY)

	return {
		loading,
		data: data?.alertCategories || null,
	}
}

export const useAlertWidget: any = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { loading, data } = useQuery(ALERT_WIDGET, options)

	return {
		loading,
		data,
	}
}
export const useAlertTreatedWidget: any = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { loading, data } = useQuery(ALERT_TREATED_WIDGET, options)

	return {
		loading,
		data,
	}
}

export const useExportAlerts: any = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { data, loading, error } = useQuery(EXPORT_ALERTS, options)

	return {
		exportFile: data?.exportAlerts?.[0]?.exportFile || null,
		loading,
		error,
	}
}
