/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useCallback, useContext } from 'react'

import Stack from '@mui/material/Stack'
import { ModalContext } from '../../../../utils/contexts/ModalContext'
import DatePicker from '../../../forms/v1/formFields/DatePicker'
import { useFormatMessage } from '../../../../utils/hooks'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'
import UserAvatar from '../../../avatar/UserAvatar'
import { formatDate } from '../../../../utils/date'
import { AuthContext } from '../../../../utils/contexts/AuthContext'
import { textBoldCSS } from '../../../../styles/sxCSS/_common'

interface IWVehicleTrackingModalContent {
	entryBy?: any
	dateCreated?: string
}

const WVehicleTrackingModalContent: React.FC<IWVehicleTrackingModalContent> = ({
	dateCreated,
	entryBy,
}) => {
	const intlMsg = useFormatMessage()
	const {
		formUtils: { control, watch },
	} = useContext(ModalContext)
	const { currentUser } = useContext(AuthContext)

	const watchedEntryDate = watch('entryDate')
	const watchedExitDate = watch('exitDate')

	const shouldDisableDate = useCallback(
		(name) => (date) => {
			if (name === 'entryDate') {
				if (!watchedExitDate) return false
				return !!dayjs(date).isAfter(watchedExitDate)
			} else {
				if (!watchedEntryDate) return false
				return !!dayjs(date).isBefore(watchedEntryDate)
			}
		},
		[watchedEntryDate, watchedExitDate]
	)

	return (
		<Stack spacing={4}>
			<Stack direction='row' spacing={2}>
				<DatePicker
					control={control}
					name='entryDate'
					label={intlMsg('widget.entrieDate')}
					shouldDisableDate={shouldDisableDate('entryDate')}
					views={['day']}
				/>
				<DatePicker
					control={control}
					name='exitDate'
					label={intlMsg('widget.exitDate')}
					shouldDisableDate={shouldDisableDate('exitDate')}
					views={['day']}
				/>
			</Stack>
			<Stack direction='row' justifyContent='space-between' spacing={3}>
				<Stack spacing={1}>
					<Typography variant='body2'>{`${intlMsg(
						'misc.addAt'
					)} :`}</Typography>
					<Typography sx={textBoldCSS}>
						{dateCreated ? formatDate(dateCreated) : formatDate(new Date())}
					</Typography>
				</Stack>
				<Stack>
					<Typography variant='body2'>{`${intlMsg(
						'vehicle.by'
					)} :`}</Typography>
					<Stack>
						<Stack
							direction='row'
							justifyContent='space-between'
							alignItems='center'
						>
							<UserAvatar
								userName={{
									firstName: entryBy?.firstName || currentUser?.firstName,
									lastName: entryBy?.lastName || currentUser?.lastName,
								}}
								sx={{ mr: 1 }}
							/>
							<Typography variant='body2'>{`${
								entryBy?.firstName || currentUser?.firstName
							} ${entryBy?.lastName || currentUser?.lastName}`}</Typography>
						</Stack>
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	)
}

export default WVehicleTrackingModalContent
