// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react'

import { useBulkDocumentVehicleAction } from '../../../api/gql/mutations/documentsVehiclesMutation'
import { GET_DOCUMENTS_VEHICLES } from '../../../api/gql/queries/documentsVehiclesQueries'
import listDocumentsVehiclesViewTable from '../../../components/tableV2/config/dashboard/documentsVehicles/listDocumentsVehiclesViewTable'
import { EFormModalMode, EPermissionScope } from '../../../utils/app-models'
import { ITableToolBarActionConfig } from '../../../utils/typescript/tableType'
import { vehicleDocumentDefaultFilterConfigValues } from './vehicleDocumentFilters/vehicleDocumentDefaultFilterConfig'
import VehicleDocumentFilters from './vehicleDocumentFilters'
import FormBtnModal from '../../../components/forms/formModalBtn'
import VehicleDocumentModal from './vehicleDocumentModal'

////////////////////////////////////////////////////////////////
// Global variable
////////////////////////////////////////////////////////////////

const localStorageKeyFilter = 'vehiclesDocumentsFilter'

const queryConfig = {
	query: GET_DOCUMENTS_VEHICLES,
	queryName: 'documents',
}

////////////////////////////////////////////////////////////////
// tableToolBarAction CONFIG
////////////////////////////////////////////////////////////////

const refreshQuerie = [
	'getDocumentsVehicles',
	'getDocumentsVehicleCountByVehicle',
]

const deletedBulk = {
	mutation: useBulkDocumentVehicleAction,
	operation: 'remove',
	successMessageDelete: {
		singular: 'success.documentVehicleDeleted',
		plural: 'success.documentVehiclesDeleted',
	},
	messageConfirm: {
		title: 'confirmModal.deleteIDocumentsVehiclesTitle',
		message: 'confirmModal.deleteDocumentsVehiclesMessage',
	},
	deletePermissions: [EPermissionScope.documentManage],
}

const tableToolBarActionConfig: ITableToolBarActionConfig = {
	refreshQuerie,
	deletedBulk,
}

////////////////////////////////////////////////////////////////
// TableToolBarHeader CONFIG
////////////////////////////////////////////////////////////////

const tableToolBarConfig = {
	title: 'documentVehicle.documents',
	stickyToolBar: true,
	tableConfig: listDocumentsVehiclesViewTable,
	localStorageKeyFilter,
	localStorageName: 'listVehiclesDocumentsViewTableFilters',
	queryConfig,
	modalButton: {
		Btn: (
			<FormBtnModal
				modal={<VehicleDocumentModal modalUtils={{} as any} />}
				mode={EFormModalMode.create}
			/>
		),
	},
	createPermission: [EPermissionScope.documentManage],
}

////////////////////////////////////////////////////////////////
// tableContainer CONFIG
////////////////////////////////////////////////////////////////

const tableContainerConfig = {
	tableConfig: listDocumentsVehiclesViewTable,
	queryConfig,
}

////////////////////////////////////////////////////////////////
// -> EXPORT CONFIG MAIN TABLE
////////////////////////////////////////////////////////////////

const defaultFiltersConfig = {
	componentsFiltersConfig: <VehicleDocumentFilters />,
	defaultFiltersValues: vehicleDocumentDefaultFilterConfigValues,
	localStorageKeyFilter,
}

export const vehiclesDocumentsConfigTable = {
	tableToolBarConfig,
	tableToolBarActionConfig,
	tableContainerConfig,
	defaultFiltersConfig,
}
