import React from 'react'

import { useNavigate, useLocation } from 'react-router-dom'
import { Stack, Typography, Link } from '@mui/material'

import { useFormatMessage } from '../../../utils/hooks'
import { formatDate } from '../../../utils/date'
import ChipColorStatus from '../../chip/ChipColorStatus'
import { ROUTES } from '../../../routes/consts'
import { textBoldCSS } from '../../../styles/sxCSS/_common'
import { handleOpenViewModal } from '../../../utils/utils'
import { EChipColorStatus } from '../../../utils/app-models'

interface IWInterventionItem {
	interventionProgrammedVehicle
	title: string
}

const WInterventionItem: React.FC<IWInterventionItem> = ({
	interventionProgrammedVehicle,
	title,
}) => {
	const intlMsg = useFormatMessage()
	const location: any = useLocation()
	const navigate = useNavigate()

	return (
		<Stack>
			<Typography>{intlMsg(title)}</Typography>
			{interventionProgrammedVehicle ? (
				<Stack direction='row' justifyContent='space-between'>
					<Stack direction='row' justifyContent='space-between' spacing={2}>
						<Typography color='grey.700' sx={textBoldCSS}>
							{formatDate(
								interventionProgrammedVehicle?.intervention.dateFrom as any
							)}
						</Typography>
						<Link underline='hover' color='default' sx={{ cursor: 'pointer' }}>
							<Typography
								onMouseDown={handleOpenViewModal(
									navigate,
									location,
									`${ROUTES.VIEWMODAL.INTERVENTION_REFERENCES.path}${interventionProgrammedVehicle?.intervention.id}`
								)}
								sx={textBoldCSS}
							>{`${interventionProgrammedVehicle?.intervention.reference} - ${interventionProgrammedVehicle?.intervention.interventionType}`}</Typography>
						</Link>
					</Stack>
					<Stack>
						<ChipColorStatus
							size='small'
							label={interventionProgrammedVehicle?.status.name}
							color={interventionProgrammedVehicle?.status.color}
							code={interventionProgrammedVehicle?.status.code}
							type={EChipColorStatus.vehicle}
						/>
					</Stack>
				</Stack>
			) : (
				<Typography>-</Typography>
			)}
		</Stack>
	)
}

export default WInterventionItem
