import React, { useCallback } from 'react'
import { Box, Chip, List, Typography, Popover, Stack } from '@mui/material'
import { useFormatMessage } from '../../../utils/hooks'

const h5CSS = {
	fontWeight: 600,
	textTransform: 'uppercase',
	mb: 2,
}
const popoverCSS = { p: 2, maxWidth: '400px' }
const containerCSS = { display: 'flex', flexWrap: 'wrap' }
const PopoverCSS = {
	'& .MuiPopover-paper': {
		boxShadow: '0 4px 4px 0px rgba(0,0,0,0.19)',
		minWidth: '253px',
		maxWidth: '253px',
	},
}

interface IListChipsNetworksPopover {
	handleClose
	open
	anchorEl
	networks
}

const ListChipsNetworksPopover: React.FC<IListChipsNetworksPopover> = ({
	handleClose,
	open,
	anchorEl,
	networks,
}) => {
	const intlMsg = useFormatMessage()

	const renderNetworks = useCallback(
		({ name }) => (
			<Chip
				key={name}
				size='small'
				variant='outlined'
				color='primary'
				label={intlMsg(name)}
				sx={{ width: 'min-content' }}
			/>
		),
		[]
	)

	return (
		<Popover
			open={open}
			anchorEl={anchorEl}
			onClose={handleClose}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
			transformOrigin={{ vertical: 'top', horizontal: 'left' }}
			sx={PopoverCSS}
		>
			<Box sx={popoverCSS}>
				<List>
					<Typography variant='body2' sx={h5CSS}>
						{intlMsg('misc.networks')}
					</Typography>

					<Box sx={containerCSS}>
						<Stack spacing={1} mb={1}>
							{networks?.map(renderNetworks)}
						</Stack>
					</Box>
				</List>
			</Box>
		</Popover>
	)
}

export default ListChipsNetworksPopover
