import React, { useCallback, useMemo, memo } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { Stack } from '@mui/material'
import { useFormatMessage } from '../../utils/hooks'

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

interface ISelectCheckBoxOption {
	label: string
	key: string
	value: string | number | boolean
}

interface ISelectCheckBox {
	value: any
	onChange: (value: any) => void
	label: string
	options: ISelectCheckBoxOption[] | any
	isrequired?: boolean
	customHelper?: string
	size?: 'medium' | 'small' | undefined
	isDisabled?: boolean
	onBlur?: () => void
	missingText?: string
	error?: any
	name?: string
}

const SelectCheckBox: React.FC<ISelectCheckBox> = ({
	value,
	onChange,
	label,
	options,
	isrequired,
	customHelper,
	size,
	isDisabled,
	onBlur,
	missingText,
	error = null,
	name,
}) => {
	const intlMsg = useFormatMessage()

	const isOptionEqualToValue = useCallback(
		(option: ISelectCheckBoxOption, value: ISelectCheckBoxOption) =>
			value.key === option.key,
		[]
	)

	const handleOnChange = useCallback(
		(_, newValue: ISelectCheckBoxOption[]) => {
			onChange(newValue)
		},
		[onChange]
	)

	const renderOption = useCallback(
		(props, option: ISelectCheckBoxOption, { selected }) => {
			return (
				<li {...props}>
					<Stack
						direction='row'
						justifyContent='space-between'
						alignItems='center'
						sx={{ width: 1 }}
					>
						{option?.label}
						<Checkbox
							icon={icon}
							checkedIcon={checkedIcon}
							sx={{ mr: 1 }}
							checked={selected}
							color={selected ? 'primary' : 'default'}
						/>
					</Stack>
				</li>
			)
		},
		[]
	)

	const noOptionsText = useMemo(
		() => (missingText ? missingText : intlMsg('misc.noResultSearch')),
		[missingText]
	)

	const getOptionLabel = useCallback(
		(option: ISelectCheckBoxOption) => option?.label || '',
		[]
	)

	const renderInput = useCallback(
		(params) => (
			<TextField
				{...params}
				name={name}
				label={`${label} ${isrequired ? '*' : ''}`}
				disabled={isDisabled}
				error={!!error}
				variant='outlined'
				size={size || 'small'}
				inputProps={{ ...params.inputProps }}
				onBlur={onBlur}
				helperText={error ? error.message : customHelper ? customHelper : ''}
				sx={{ opacity: isDisabled ? '0.5' : '1' }}
			/>
		),
		[isDisabled, error, customHelper, name, label, isrequired, size, onBlur]
	)

	return (
		<Autocomplete
			value={value || []}
			multiple
			disabled={isDisabled}
			options={options as any}
			noOptionsText={noOptionsText}
			getOptionLabel={getOptionLabel}
			onChange={handleOnChange}
			isOptionEqualToValue={isOptionEqualToValue}
			renderOption={renderOption}
			renderInput={renderInput}
		/>
	)
}

export default memo(SelectCheckBox)
