import {
	gql,
	OperationVariables,
	QueryHookOptions,
	useLazyQuery,
	useQuery,
} from '@apollo/client'
import {
	interventionsTransportListAttributes,
	interventionTransportForModalAttributes,
	interventionTransportForViewModalAttribute,
	ipvTransportListAttributes,
} from '../attributes/interventionsTransportAttributes'

/////////////////////////////////////////////////////////
// QUERIES
/////////////////////////////////////////////////////////
export const LIST_IPV_TRANSPORT = gql`
	query IpvTransportList(
		$page: Int
		$itemsPerPage: Int
		$intervention_status: String
		$intervention_status_list: [String]
		$intervention_actors_list: [String]
		$searchText: String
		$vehicle: String
		$isWithoutActor: Boolean
		$customer_list: Iterable
		$intervention: String
		$intervention_dateFrom: [InterventionProgrammedVehicleFilter_intervention_dateFrom]
		$intervention_dateTo: [InterventionProgrammedVehicleFilter_intervention_dateTo]
		$startFrom: String,
		$endTo: String,
		$orderBy: [InterventionProgrammedVehicleFilter_orderBy]
		$agency_list: Iterable
	) {
		interventionProgrammedVehicles(
			page: $page
			itemsPerPage: $itemsPerPage
			intervention_status: $intervention_status
			intervention_status_list: $intervention_status_list
			intervention_actors_list: $intervention_actors_list
			searchText: $searchText
			vehicle: $vehicle
			isWithoutActor: $isWithoutActor
			customer_list: $customer_list
			intervention: $intervention
			intervention_dateFrom: $intervention_dateFrom
			intervention_dateTo: $intervention_dateTo,
			startFrom: $startFrom,
			endTo: $endTo,
			orderBy: $orderBy
			intervention_interventionType: "transport"
			agency_list: $agency_list
		) {
			${ipvTransportListAttributes}
		}
	}
`

export const LIST_INTERVENTIONS_TRANSPORT = gql`
	query interventionsTransportList(
		$page: Int
		$itemsPerPage: Int
		$searchText: String
		$customer: String
		$customer_list: Iterable
		$manager: String
		$manager_list: [String]
		$status: String
		$status_list: [String]
		$isDeleted: Boolean
		$dateFrom: [InterventionFilter_dateFrom]
		$site: String
		$startFrom: String,
		$endTo: String,
		$actors_list: [String]
		$isWithoutActor: Boolean
		$agency: String
		$orderBy: [InterventionFilter_orderBy]
	) {
		interventions(
			page: $page
			itemsPerPage: $itemsPerPage
			searchText: $searchText
			customer: $customer
			customer_list: $customer_list
			manager: $manager
			manager_list: $manager_list
			status: $status
			status_list: $status_list
			isDeleted: $isDeleted
			dateFrom: $dateFrom
			site: $site
			startFrom: $startFrom
			endTo: $endTo
			actors_list: $actors_list
			isWithoutActor: $isWithoutActor
			agency: $agency
			orderBy: $orderBy
			interventionType: "transport"
		) {
			${interventionsTransportListAttributes}
		}
	}
`

export const TRANSPORT_TRIP_SITE = gql`
	query getTransportTripSite($siteId: ID!) {
		site(id: $siteId) {
			id
			zipcode
			country
			address
			city
		}
	}
`
export const TRANSPORT_TRIP_CONTACT = gql`
	query getTransportTripContact($contactId: ID!) {
		contact(id: $contactId) {
			id
			lastName
			firstName
			phone
			cellphone
		}
	}
`
// TODO replace TRANSPORT_VEHICLE_SEARCH by VEHICLE_DETAILS_SEARCH (need input immatOrVin)
// export const VEHICLE_DETAILS_SEARCH = gql`
// 	query getVehicleDetails($immat: String, $vin: String) {
// 		vehicleDetails(immat: $immat, vin: $vin) {
// 			id
// 		}
// 	}
// `

export const TRANSPORT_VEHICLE_SEARCH = gql`
	query getTransportVehicleSearch($searchText: String) {
		vehicles(searchText: $searchText, page: 1, itemsPerPage: 100000) {
			collection {
				id
				site {
					id
					name
					customer {
						id
						socialName
						name
					}
				}
				vehicleDetail {
					id
					immat
					vin
					vehicleModelText
					vehicleCategory {
						id
						name
						code
					}
					vehicleBrand {
						id
						name
					}
					vehicleCarburant {
						id
						name
						code
						color
					}
				}
			}
		}
	}
`

export const INTERVENTION_TRANSPORT_TYPE = gql`
	query getInterventionTransportType {
		interventionTransportTypes {
			code
			id
		}
	}
`

export const INTERVENTION_TRANSPORT_FOR_MODAL = gql`
	query interventionTransportForModal($id: ID!) {
		intervention(id: $id) {
			${interventionTransportForModalAttributes}
		}
	}
`

export const EXPORT_INTERVENTION_TRANSPORT = gql`
	query exportTransportInterventions(
		$searchText: String
		$customer: String
		$customer_list: Iterable
		$manager: String
		$manager_list: [String]
		$status: String
		$status_list: [String]
		$isDeleted: Boolean
		$dateFrom: [InterventionFilter_dateFrom]
		$site: String
		$startFrom: String
		$endTo: String
		$actors_list: [String]
		$isWithoutActor: Boolean
		$agency: String
		$timezone: Int
		$orderBy: [InterventionFilter_orderBy]
	) {
		exportTransportInterventions(
			searchText: $searchText
			customer: $customer
			customer_list: $customer_list
			manager: $manager
			manager_list: $manager_list
			status: $status
			status_list: $status_list
			isDeleted: $isDeleted
			dateFrom: $dateFrom
			site: $site
			startFrom: $startFrom
			endTo: $endTo
			actors_list: $actors_list
			isWithoutActor: $isWithoutActor
			agency: $agency
			timezone: $timezone
			orderBy: $orderBy
			interventionType: "transport"
		) {
			id
			exportFile
		}
	}
`

export const INTERVENTION_TRANSPORT_COUNT_BY_CUSTOMER = gql`
	query getInterventionTransportNumbersByCustomer($customer: String) {
		interventions(
			page: 1
			itemsPerPage: 100000
			customer: $customer
			interventionType: "transport"
		) {
			paginationInfo {
				totalCount
			}
		}
	}
`

export const INTERVENTION_TRANSPORT_COUNT_BY_AGENCY = gql`
	query getInterventionTransportNumbersByCustomer($agency: String) {
		interventions(
			page: 1
			itemsPerPage: 100000
			agency: $agency
			interventionType: "transport"
		) {
			paginationInfo {
				totalCount
			}
		}
	}
`

export const INTERVENTION_TRANSPORT_COUNT_BY_SITE = gql`
	query getInterventionTransportNumbersBySite($site: String) {
		interventions(
			page: 1
			itemsPerPage: 1000
			site: $site
			interventionType: "transport"
		) {
			paginationInfo {
				totalCount
			}
		}
	}
`

export const INTERVENTION_TRANSPORT_COUNT_BY_VEHICLE = gql`
	query getInterventionTransportNumbersByVehicle($vehicle: String) {
		interventionProgrammedVehicles(
			page: 1
			itemsPerPage: 100000
			vehicle: $vehicle
			intervention_interventionType: "transport"
		) {
			paginationInfo {
				totalCount
			}
		}
	}
`

export const GET_INTERVENTION_TRANSPORT_FOR_VIEW_MODAL = gql`
query getInterventionTransportForViewModal(
    $id: ID!
) {
    intervention(id: $id) {
        ${interventionTransportForViewModalAttribute}
    }
}`

/////////////////////////////////////////////////////////
// HOOKS
/////////////////////////////////////////////////////////

export const useLazyTransportTripsSite = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { data, loading }] = useLazyQuery(
		TRANSPORT_TRIP_SITE,
		options
	)
	return {
		getTransportTripSite: getData,
		loading,
		site: data?.site,
	}
}
export const useLazyTransportTripsContact = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { data, loading }] = useLazyQuery(
		TRANSPORT_TRIP_CONTACT,
		options
	)
	return {
		getTransportTripContact: getData,
		loading,
		contact: data?.contact,
	}
}

export const useLazyTransportVehicleSearch = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(
		TRANSPORT_VEHICLE_SEARCH,
		options
	)
	return {
		loadingVehicles: loading,
		vehicles: data?.vehicles?.collection || [],
		getVehicles: getData,
	}
}

export const useInterventionTransportType: any = () => {
	const { loading, data } = useQuery(INTERVENTION_TRANSPORT_TYPE)

	return {
		loading,
		interventionTransportType: data?.interventionTransportTypes || null,
	}
}

export const useLazyInterventionTransportForModal: any = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(
		INTERVENTION_TRANSPORT_FOR_MODAL,
		options
	)

	return {
		loading,
		intervention: data?.intervention || null,
		getIntervention: getData,
	}
}

export const useExportInterventionTransport: any = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { data, loading, error } = useQuery(
		EXPORT_INTERVENTION_TRANSPORT,
		options
	)

	return {
		exportFile: data?.exportTransportInterventions?.[0]?.exportFile || null,
		loading,
		error,
	}
}
