import Axios from 'axios'
import { baseUrlAPIOffline } from '../url'

const api = Axios.create({
	baseURL: baseUrlAPIOffline,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
})

export default api
