import React from 'react'

import MultiTableNavProvider from './MultiNavTableProvider'
import MultiTableNavView from './multiTableNavView'
import { IMultiTableNavConfig } from '../../utils/typescript/multiNavTableType'

interface IMultiNavTable {
	multiTableNavConfig: IMultiTableNavConfig[]
	mainContext
}

const MultiNavTable: React.FC<IMultiNavTable> = ({
	multiTableNavConfig,
	mainContext,
}) => {
	return (
		<MultiTableNavProvider
			multiTableNavConfig={multiTableNavConfig}
			mainContext={mainContext}
		>
			<MultiTableNavView />
		</MultiTableNavProvider>
	)
}

export default MultiNavTable
