import { Box } from '@mui/material'
import React, { useContext } from 'react'
import FilterDate from '../../../../../components/filters/filtersComponents/filterDate'
import { FiltersContext } from '../../../../../components/filters/filtersContext'
import FiltersBtnProvider from '../../../../../components/filters/filtersBtn'
import TransportFiltersBtnComponent from './TransportFiltersBtnComponent'

interface ITransportFilters {}

const TransportFiltersSX = {
	display: 'flex',
}

const TransportFilters: React.FC<ITransportFilters> = () => {
	const { setFilters, filters } = useContext(FiltersContext)

	return (
		<Box sx={TransportFiltersSX}>
			<FilterDate
				setFiltersQuerieParam={setFilters}
				filtersQuerieParam={filters}
				argumentQuerieName='startFrom'
			/>
			<FiltersBtnProvider>
				<TransportFiltersBtnComponent />
			</FiltersBtnProvider>
		</Box>
	)
}

export default TransportFilters
