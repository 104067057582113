/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/display-name */
import React from 'react'

import Typography from '@mui/material/Typography'
import LocationAddress from '../../../../locationAddress'

import { ROUTES } from '../../../../../routes/consts'
import { formatDate } from '../../../../../utils/date'

import {
	EFormModalMode,
	EPermission,
	EPermissionScope,
} from '../../../../../utils/app-models'

import {
	useRemoveAgency,
	useUpdateAgencyStatus,
} from '../../../../../api/gql/mutations/agencyMutation'
import BooleanColorPopover from '../../../../popover/BooleanColorPopover'
import ListChipsNetworks from '../../../../popover/listChipsModulePopover/ListChipsNetworks'
import AgencyModal from '../../../../../view/dashboard/networkAgency/agencies/agencyModal'
import StepperProvider from '../../../../forms/formStepper/StepperProvider'
import agencyStepperConfig from '../../../../../view/dashboard/networkAgency/agencies/agencyModal/agencyStepperConfig'
import { Stack } from '@mui/material'
import OpenLinkCell from '../../../../tableV2/tableCell/OpenLinkCell'
import TimeCell from '../../../tableCell/TimeCell'
import PhoneCell from '../../../tableCell/PhoneCell'
import { Box } from '@mui/material'
import ActionsBtn from '../../../tableCell/actionsBtn'

const listAgenciesViewTable = [
	{
		columnLabel: 'misc.generalAccount',
		permissions: [EPermission.superMegaAdmin],
		personalizedCell: ({ createdByAccount }) => (
			<Box>{createdByAccount?.name}</Box>
		),
	},
	{
		columnLabel: 'agencies.agencyName',
		orderBy: 'agencyCustomDetails_agencyName',
		stickyFirstCol: true,
		personalizedCell: ({ id, agencyCustomDetails }) => (
			<OpenLinkCell
				viewModalLink={`${ROUTES.VIEWMODAL.AGENCIES.url}${id}`}
				txt={agencyCustomDetails[0]?.agencyName}
				maxCharacters={30}
			/>
		),
	},

	{
		columnLabel: 'agencies.socialName',
		orderBy: 'name',
		personalizedCell: ({ id, name }) => (
			<OpenLinkCell
				viewModalLink={`${ROUTES.VIEWMODAL.AGENCIES.url}${id}`}
				txt={name}
			/>
		),
	},

	// {
	// 	columnLabel: 'agencies.typeAgency',
	// 	sortId: 'accountType.name',
	// 	personalizedCell: ({ accountType: { name } }) => {
	// 		return <Typography variant='caption'>{name || '-'}</Typography>
	// 	},
	// },

	{
		columnLabel: 'misc.networks',
		personalizedCell: ({ networks: { collection } }) => (
			<ListChipsNetworks networks={collection} />
		),
	},

	{
		columnLabel: 'misc.address',
		orderBy: 'address',
		personalizedCell: ({ address, city, zipcode }) => (
			<LocationAddress
				address={address}
				city={city}
				zipcode={zipcode}
				maxCharacters={30}
			/>
		),
	},

	// {
	// 	columnLabel: 'agencies.nbCustomer',
	// 	sortId: 'customers.totalCount',
	// 	tableCellAlign: 'center',
	// 	cellAlign: 'center',
	// 	personalizedCell: ({ customers: { paginationInfo: { totalCount} } }) => {
	// 		return <Typography variant='caption'>{totalCount}</Typography>
	// 	},
	// },
	// {
	// 	columnLabel: 'misc.nbInterventions',
	// 	sortId: 'null',
	// 	personalizedCell: ({
	// 		createdInterventions: { paginationInfo: {totalCount: nbCreate }},
	// 		executedInterventions: { paginationInfo: totalCount: totalExecuted }},
	// 	}) => {
	// 		return (
	// 			<Typography variant='caption'>{`${
	// 				totalExecuted + nbCreate
	// 			} `}</Typography>
	// 		)
	// 	},
	// },

	{
		columnLabel: 'misc.createdAt',
		orderBy: 'dateCreated',
		tableCellAlign: 'center',
		cellAlign: 'center',
		personalizedCell: ({ dateCreated }) => (
			<TimeCell date={dateCreated ? formatDate(dateCreated) : '-'} />
		),
	},

	{
		columnLabel: 'misc.referent',
		orderBy: 'adminLastName',
		personalizedCell: ({ adminFirstName, adminLastName }) => {
			return (
				<Typography variant='caption'>{`${adminFirstName ?? ''} ${
					adminLastName ?? ''
				}`}</Typography>
			)
		},
	},

	{
		columnLabel: 'misc.phone',
		personalizedCell: ({ adminPhone, adminCellphone }) => (
			<Stack direction='row' alignItems='center' spacing={1}>
				<PhoneCell txt={adminPhone} icon='phone' />
				<PhoneCell txt={adminCellphone} icon='cellphone' />
			</Stack>
		),
	},

	{
		columnLabel: 'misc.active',
		orderBy: 'isActive',
		tableCellAlign: 'center',
		cellAlign: 'center',
		personalizedCell: (row: any) => {
			return (
				<BooleanColorPopover
					value={row.isActive}
					id={row.id}
					txt={{ true: 'misc.active', false: 'misc.desactive' }}
					titlePopover={'misc.status'}
					queryConfig={{
						query: useUpdateAgencyStatus,
						refreshQuery: ['getAgenciesAccounts'],
						successMessage: 'success.statusUpdate',
					}}
				/>
			)
		},
	},

	{
		fixWidth: '50px',
		columnLabel: '',
		visible: true,
		tableCellAlign: 'center',
		cellAlign: 'center',
		sticky: true,
		personalizedCell: ({ id }) => {
			return (
				<StepperProvider
					stepperConfig={agencyStepperConfig}
					stepperMode={EFormModalMode.edit}
				>
					<ActionsBtn
						rowId={id}
						deleted={{
							query: useRemoveAgency,
							refreshQuery: ['getAgenciesAccounts', 'getNetworks'],
							successMessageDelete: 'success.agencyDeleted',
							messageConfirm: {
								title: 'confirmModal.deleteAgencyTitle',
								message: 'confirmModal.deleteAgencyMessage',
							},
						}}
						editModal={<AgencyModal agencyId={id} modalUtils={{}} />}
						viewModalLink={`${ROUTES.VIEWMODAL.AGENCIES.url}${id}`}
						permissions={{
							update: [EPermissionScope.networkManage],
							remove: [EPermissionScope.networkManage],
							delete: [EPermissionScope.networkDelete],
						}}
					/>
				</StepperProvider>
			)
		},
	},
]

export default listAgenciesViewTable
