import React, { useMemo } from 'react'

import { Box } from '@mui/material'
import { Tsize } from '../../styles/type/sizeType'
import { unitsOfWork } from '../../utils/const'

const sizeCSS = {
	small: {
		display: 'flex',
		padding: '4px 8px',
		alignItems: 'center',
		'& .label': {
			fontSize: '12px',
			fontStyle: 'normal',
			fontWeight: 400,
			lineHeight: '8px',
		},
	},
	medium: {
		display: 'flex',
		padding: '3px 4px',
		alignItems: 'center',
		'& .label': {
			fontSize: '13px',
			fontStyle: 'normal',
			fontWeight: 400,
			lineHeight: '18px',
			padding: '0px 6px',
			letterSpacing: '0.16px',
		},
	},
}

interface IChipUnitOfWork {
	unitOfWork: string
	size?: Tsize
	sx?: object
	radius?: 'rounded' | 'square'
	shortenedText?: boolean
}

const ChipUnitOfWork: React.FC<IChipUnitOfWork> = ({
	unitOfWork,
	size = 'medium',
	sx = {},
	radius = 'rounded',
	shortenedText = false,
}) => {
	const unitOfWorkFiltered = useMemo(
		() => unitsOfWork?.filter(({ value }) => value?.name === unitOfWork)?.[0],
		[unitOfWork]
	)

	const chipSx: any = useMemo(
		() => ({
			color: '#40434C',
			backgroundColor: unitOfWorkFiltered?.value.color,
			borderRadius: radius === 'square' ? '4px' : '16px',
			...sizeCSS[size],
			...sx,
		}),
		[sx, unitOfWorkFiltered, size, radius]
	)

	const label = useMemo(
		() =>
			shortenedText
				? unitOfWorkFiltered?.value?.name
				: unitOfWorkFiltered?.label,
		[unitOfWorkFiltered, shortenedText]
	)

	return (
		<>
			{unitOfWork ? (
				<Box sx={chipSx}>
					<div className='label'>{label}</div>
				</Box>
			) : (
				'-'
			)}
		</>
	)
}

export default ChipUnitOfWork
