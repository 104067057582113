import translateIntlMsgString from '../../../../utils/translateIntlMsgString'
import { string, object } from 'yup'

const invoiceModalValidationSchema = object().shape({
	vehicle: string().when('invoiceSource', {
		is: (invoiceSource) => invoiceSource === 'vehicle',
		then: string().required(translateIntlMsgString('validationSchema.require')),
	}),

	intervention: string().when('invoiceSource', {
		is: (invoiceSource) => invoiceSource === 'intervention',
		then: string().required(translateIntlMsgString('validationSchema.require')),
	}),

	reference: string().required(
		translateIntlMsgString('validationSchema.require')
	),
	totalAmountET: string().required(
		translateIntlMsgString('validationSchema.require')
	),

	vat: object()
		.required(translateIntlMsgString('validationSchema.require'))
		.nullable(),
})

export default invoiceModalValidationSchema
