import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'

import SearchIcon from '@mui/icons-material/Search'
import { Box, Typography, Grid } from '@mui/material'

import TextField from '../../../../../../components/inputs/TextField'
import {
	useDebouncedState,
	useFormatMessage,
} from '../../../../../../utils/hooks'
import IconBtn from '../../../../../../components/buttons/IconBtn'
import { getCountries } from '../../../../../../utils/tabCountries'
import SelectRadioSearch from '../../../../../../components/inputs/SelectRadioSearch'
import SkeletonTable from '../../../../../../components/skeleton/SkeletonTable'
import { useLazyCheckSirenAccount } from '../../../../../../api/gql/queries/agencyQueries'
import SwitchButton from '../../../../../../components/inputs/SwitchButton'
import { StepperContext } from '../../../../../../components/forms/formStepper/StepperContext'
import SelectCheckBox from '../../../../../../components/inputs/SelectCheckBox'
import { LIST_NETWORKS } from '../../../../../../api/gql/queries/networkQueries'
import { useQuery } from '@apollo/client'
import { AuthContext } from '../../../../../../utils/contexts/AuthContext'

const mainCSS = {
	display: 'flex',
	p: 4,
	width: 1,
	flexDirection: 'column',
	flexGrow: '1',
}

const AgencyStep: React.FC = () => {
	const { localLanguage } = useContext(AuthContext)
	const { data, setValue, setValues, errors, setCanGoNext, triggerError } =
		useContext(StepperContext)
	const intlMsg = useFormatMessage()

	const [canDisplayRest, setCanDisplayRest] = useState(!!data?.siret)
	const [debouncedState, setDebouncedState] = useDebouncedState({ siret: '' })

	// Get networks by isActive status
	const { data: listNetworks } = useQuery(LIST_NETWORKS, {
		variables: { isActive: true, itemsPerPage: 10000, page: 1 },
		fetchPolicy: 'network-only',
		nextFetchPolicy: 'cache-first',
	})

	const countriesOption = useMemo(
		() =>
			getCountries(localLanguage)?.map((country) => ({
				label: country.label,
				key: country.code,
				value: country.code,
			})),
		[localLanguage]
	)

	const networksOptions = useMemo(
		() =>
			listNetworks?.networks?.collection?.map((network) => ({
				label: network?.name,
				key: network?.name,
				value: network?.id,
			})) || [],
		[listNetworks]
	)

	const { loadingCheckSiren, sirenAccount, checkSirenAccount } =
		useLazyCheckSirenAccount({ fetchPolicy: 'cache-and-network' })

	useEffect(() => {
		setCanGoNext(canDisplayRest)
	}, [canDisplayRest])

	// this useEffect based on a bebounced state is used to avoid calling the method "triggerError" each time a key is pressed
	useEffect(() => {
		if (debouncedState?.siret) {
			triggerError('siret')
		}
	}, [debouncedState])

	useEffect(() => {
		if (!loadingCheckSiren && sirenAccount) {
			const tmp: any = []

			Object.entries(sirenAccount).forEach(([key, value]) => {
				if (key !== 'id' && key !== '__typename') {
					tmp.push({ key, value })
				}
			})

			setValues([
				...tmp,
				{ key: 'agencyName', value: data?.agencyName || sirenAccount.name },
			])
			setCanDisplayRest(true)
			triggerError('siret')
		}
	}, [sirenAccount, loadingCheckSiren])

	const handleChange = useCallback(
		(name) => (e) => {
			if (name === 'siret') {
				setCanDisplayRest(false)
				// Delete space
				const siretValue = e.target.value.replace(/\s+/g, '')
				setDebouncedState({ siret: siretValue })
				setValue(name, siretValue)
			} else {
				setValue(name, e.target.value)
			}
		},
		[setValue, triggerError]
	)

	const handleChangeAutoComplete = useCallback(
		(name) => (value) => {
			setValue(name, value)
		},
		[setValue]
	)

	const handleSearchSiret = useCallback(() => {
		if (!errors?.siret) {
			checkSirenAccount({
				variables: {
					siret: data.siret,
					country: data.country,
				},
			})
		}
	}, [data, errors])

	const handleOnBlur = useCallback(
		(name) => () => {
			triggerError(name)
		},
		[data, triggerError]
	)

	// Set switch value form
	const handleSwitchChange = useCallback(
		(name) => () => {
			setValue(name, !data[name])
		},
		[data]
	)

	return (
		<Box sx={mainCSS}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Typography variant='h2'>
						{intlMsg('agencies.agencyInformations')}
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<TextField
						name='siret'
						value={data?.siret}
						onChange={handleChange('siret')}
						label={intlMsg('misc.siret')}
						isrequired
						error={errors?.siret}
						onBlur={handleOnBlur('siret')}
					/>
				</Grid>
				<Grid item xs={5}>
					<SelectRadioSearch
						name='country'
						onChange={handleChangeAutoComplete('country')}
						value={data?.country}
						error={{ message: errors?.country }}
						options={countriesOption}
						label={intlMsg('misc.country')}
						onBlur={handleOnBlur('country')}
					/>
				</Grid>
				<Grid item xs={1}>
					<IconBtn
						dataTestId='Playwright-AgencyStep-search'
						icon={<SearchIcon />}
						onClick={handleSearchSiret}
						loading={loadingCheckSiren}
						disabled={
							errors?.siret || !debouncedState?.siret || errors?.country
						}
						sx={{ minWidth: 0 }}
					/>
				</Grid>

				{!canDisplayRest || loadingCheckSiren ? (
					<Grid item xs={12} style={{ marginTop: '16px' }}>
						<SkeletonTable nbRow={4} />
					</Grid>
				) : (
					<>
						<Grid item xs={12}>
							<TextField
								name='agencyName'
								value={data?.agencyName || ''}
								onChange={handleChange('agencyName')}
								label={intlMsg('agencies.agencyName')}
								isrequired
								error={errors?.agencyName}
								onBlur={handleOnBlur('agencyName')}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								name='socialName'
								value={data?.name || ''}
								onChange={handleChange('name')}
								label={intlMsg('agencies.socialName')}
								isrequired
								error={errors?.name}
								onBlur={handleOnBlur('name')}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								name='address'
								value={data?.address || ''}
								onChange={handleChange('address')}
								label={intlMsg('misc.address')}
								isrequired
								error={errors?.address}
								onBlur={handleOnBlur('address')}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								name='zipcode'
								value={data?.zipcode || ''}
								onChange={handleChange('zipcode')}
								label={intlMsg('misc.zipcode')}
								isrequired
								error={errors?.zipcode}
								onBlur={handleOnBlur('zipcode')}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								name='city'
								value={data?.city || ''}
								onChange={handleChange('city')}
								label={intlMsg('misc.city')}
								isrequired
								error={errors?.city}
								onBlur={handleOnBlur('city')}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								name='tva'
								value={data?.tva || ''}
								onChange={handleChange('tva')}
								label={intlMsg('misc.tavNumber')}
							/>
						</Grid>
						<Grid item xs={6}>
							<SwitchButton
								config={{
									primary: { text: intlMsg('misc.active'), value: true },
									secondary: { text: intlMsg('misc.inactive'), value: false },
								}}
								value={data?.isActive}
								onChange={handleSwitchChange('isActive')}
							/>
						</Grid>
						<Grid item xs={12}>
							<SelectCheckBox
								name='networks'
								onChange={handleChangeAutoComplete('networks')}
								value={data?.networks || []}
								error={errors?.networks}
								options={networksOptions}
								label={intlMsg('misc.selectNetworks')}
								isrequired
							/>
						</Grid>
					</>
				)}
			</Grid>
		</Box>
	)
}

export default AgencyStep
