import React, { useCallback, useContext, useMemo, useState } from 'react'

import CustomLink from '../../../_overrideMui/Link/CustomLink'
import { Avatar, Stack } from '@mui/material'
import { getIndexesInAlphabet } from '../../../../utils/string'
import { dynamicColor } from '../../../../utils/color'
import { transformNameToInitialName } from '../../../avatar/utils-avatar'
import { AuthContext } from '../../../../utils/contexts/AuthContext'
import { EAccountType } from '../../../../utils/app-models'
import SlidePanelUserInfos from '../../../slidepanel/user'

interface IDataListAvatar {
	firstName: string
	lastName: string
	userId?: string
	accountId?: string
	isContact?: boolean
	displayName?: boolean
	onlyText?: boolean
}

const DataListAvatar: React.FC<IDataListAvatar> = ({
	firstName,
	lastName,
	userId,
	accountId,
	isContact,
	displayName = true,
	onlyText = true,
}) => {
	const { checkAccountsType, currentUser } = useContext(AuthContext)
	const isCurrentUserGuest = useMemo(
		() => checkAccountsType([EAccountType.guest]),
		[]
	)

	const isSlidePanelDisabled = useMemo(
		() =>
			!(userId || isContact) ||
			isCurrentUserGuest ||
			(accountId && currentUser.account.id !== accountId),
		[accountId, userId, isContact, isCurrentUserGuest, currentUser]
	)

	/////////////////////////////////////////////////////////
	// Avatar CSS
	/////////////////////////////////////////////////////////

	const initialName = useMemo(
		() => transformNameToInitialName({ firstName, lastName }),
		[firstName, lastName]
	)

	const backgroundColor = useMemo(() => {
		const initialsIndexes = parseInt(
			`${getIndexesInAlphabet(initialName[0] as any)}${getIndexesInAlphabet(
				initialName[1] as any
			)}`
		)
		return dynamicColor(initialsIndexes, 400, false) as any
	}, [initialName])

	const AvatarCSS = useMemo(
		() => ({
			backgroundColor,
			width: 24,
			height: 24,
			fontSize: '9px',
			fontWeight: 500,
			cursor: isSlidePanelDisabled ? 'inherit' : 'pointer',
		}),
		[backgroundColor, isSlidePanelDisabled]
	)

	/////////////////////////////////////////////////////////
	// User slide panel (optional)
	/////////////////////////////////////////////////////////

	const [slidePanelIsOpen, setSlidePanelIsOpen] = useState(false)

	const toggleOpenSlide = useCallback(
		(open: boolean) => () => {
			setSlidePanelIsOpen(open)
		},
		[]
	)
	const openSlidePanel = !isSlidePanelDisabled
		? { onClick: toggleOpenSlide(true) }
		: {}

	return (
		<>
			<Stack direction='row' spacing={0.5} mb={1}>
				{!onlyText && (
					<Avatar {...openSlidePanel} sx={AvatarCSS}>
						{initialName}
					</Avatar>
				)}
				{displayName && (
					<CustomLink
						{...openSlidePanel}
						variant='text1Bold'
						variantlink='primaryLink'
						disabled={isSlidePanelDisabled}
					>
						{`${firstName ?? ''} ${lastName ?? ''}`}
					</CustomLink>
				)}
			</Stack>

			{slidePanelIsOpen && (
				<SlidePanelUserInfos
					id={userId}
					state={slidePanelIsOpen}
					toggleDrawer={toggleOpenSlide}
					isContact={isContact}
				/>
			)}
		</>
	)
}

export default DataListAvatar
