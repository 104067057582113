import React, { useContext } from 'react'

import { IconButton, Stack, Typography } from '@mui/material'

import { useFormatMessage } from '../../../utils/hooks'
import { footerModalCSS } from '../../forms/modalSxCss'
import { FiltersBtnContext } from './FiltersBtnContext'
import CloseIcon from '@mui/icons-material/Close'

const stackCSS = { width: 1 }

interface IFiltersHeader {}

const FiltersHeader: React.FC<IFiltersHeader> = () => {
	const intlMsg = useFormatMessage()
	const { handleClose } = useContext(FiltersBtnContext)

	return (
		<Stack direction='row' spacing={2} sx={footerModalCSS}>
			<Stack
				direction='row'
				justifyContent='space-between'
				alignItems='center'
				spacing={1}
				sx={stackCSS}
			>
				<Typography variant='h3'>{intlMsg('misc.filter')}</Typography>

				<IconButton
					aria-label='close'
					onClick={handleClose}
					size='small'
					sx={{ padding: '2px' }}
				>
					<CloseIcon />
				</IconButton>
			</Stack>
		</Stack>
	)
}

export default FiltersHeader
