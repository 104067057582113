/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

import { EFormFieldsPDF } from '../../../utils/app-models'
import { globalConfigPDF } from '../globalConfigPDF'
import { longTextField } from '../tableCellField/longTextField'
import { ratingFieldPDF } from '../tableCellField/ratingFieldPDF'
import { textFieldPDF } from '../tableCellField/textFieldPDF'
import { cssTitleBlock, cssTitleCell, drawSeparatorLine } from '../utilPdf'

const nbSpaceToAdd = (doc, txt) => {
	return Math.round(doc.getTextDimensions(txt).w / spaceWidth)
}

const addSpaceBefore = (text, repeat) => ' '.repeat(repeat) + text
const addSpaceAfter = (text, repeat) => text + ' '.repeat(repeat)
const { fontSizeTile, fontSizeBody, stylePropertyBlockTable, spaceWidth } =
	globalConfigPDF

export const blockForm = (doc, dataBlock, lastPositionsY) => {
	return doc.autoTable({
		// Body add only the title (we use didParseCell after to change the title color & didDrawCell to add text with another color or custom field)
		body: dataBlock.title,

		// Block position start Y (default use the last block final position Y)
		startY: dataBlock?.option?.startingY
			? lastPositionsY[dataBlock?.option?.startingY]
			: 32.5,

		margin: { ...dataBlock?.option?.margin },

		...stylePropertyBlockTable,

		allSectionHooks: true,

		columnStyles: { ...dataBlock?.option?.columnStyles },

		// Before autotable draw table
		didParseCell: (data) => {
			// Add style for the title block
			cssTitleBlock(data, fontSizeTile, fontSizeBody, 1)
			// Add style for the title cell row
			cssTitleCell(data, 1)

			// if type longText
			if (
				dataBlock.record[data.row.index][data.column.dataKey]?.type ===
				EFormFieldsPDF.longText
			) {
				// add space characters after title !!important for display the text record value
				data.cell.text = addSpaceAfter(
					data.cell.text,
					nbSpaceToAdd(
						doc,
						dataBlock.record[data.row.index][
							data.column.dataKey
						]?.record?.toString() +
							dataBlock.title[data.row.index][data.column.dataKey]
					)
				)
			}
		},

		// After autotable draw table
		didDrawCell: function ({ row, column, cell }) {
			// get information's of current cell row
			const {
				x: positionXcell,
				y: positionYcell,
				raw,
				height: heightCell,
				width: widthCell,
			} = cell

			// get the current record value
			const valueRowCell =
				dataBlock.record[row.index][column.dataKey]?.record?.toString()

			// get the current additional record value
			const additionalValueRowCell =
				dataBlock.record[row.index][column.dataKey]?.additionalValue

			// get the current type value
			const valueType = dataBlock.record[row.index][column.dataKey]?.type

			// get the width of title
			const { w: widthTitleRowCell } = doc.getTextDimensions(raw)

			// record text position X & Y
			const positionXtextRecord = positionXcell + widthTitleRowCell + 3
			const positionYtextRecord = positionYcell + heightCell / 2 - 0.25

			// Display the record value
			switch (valueType) {
				// cell type = TXT /////////////////////////////
				case EFormFieldsPDF.text:
					textFieldPDF(
						doc,
						valueRowCell,
						{
							positionXtextRecord,
							positionYtextRecord,
							widthCell,
							widthTitleRowCell,
						},
						additionalValueRowCell
					)
					break

				// cell type = LONG - TXT /////////////////////////////
				// addSpaceBefore add blank space characters !!important for display the text
				case EFormFieldsPDF.longText:
					longTextField(
						doc,
						addSpaceBefore(valueRowCell, nbSpaceToAdd(doc, row.raw.value1) + 1),
						{
							positionXtextRecord: positionXcell + 1.75,
							positionYtextRecord: positionYcell + 3.4,
							maxWidth: widthCell - 1.75,
						}
					)
					break

				// cell type  = RATING  ///////////////////////
				case EFormFieldsPDF.rating:
					if (valueRowCell) {
						ratingFieldPDF(
							doc,
							valueRowCell,
							{
								positionYcell,
								positionXcell,
								widthTitleRowCell,
								heightCell,
							},
							2.3
						)
					} else {
						textFieldPDF(doc, '-', {
							positionXtextRecord,
							positionYtextRecord,
							widthCell,
							widthTitleRowCell,
						})
					}

					break

				// cell type = SEPARATOR  /////////////////////
				case 'separator':
					drawSeparatorLine(doc, positionYtextRecord)
					break
			}
		},
	})
}
