/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const subtitleField = (doc, record, img, position) => {
	const {
		positionXtextRecord,
		positionYtextRecord,
		widthCell,
		widthTitleRowCell,
	} = position

	const Xtext = img ? positionXtextRecord + img?.w + 1.5 : positionXtextRecord
	const Ytext = img ? positionYtextRecord : positionYtextRecord

	const Ximg = positionXtextRecord
	const Yimg = positionYtextRecord - img?.h / 2

	// addImage(imageData, format, x, y, width, height, alias, compression, rotation)
	img && doc.addImage(img?.url, 'PNG', Ximg, Yimg, img?.w, img?.h)

	doc
		.setTextColor('black')
		.setFont('helvetica', 'bold')
		.setFontSize(8.8)
		.text(record || '', Xtext, Ytext, {
			maxWidth: widthCell - widthTitleRowCell,
			baseline: 'middle',
		})
}
