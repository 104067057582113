import React, { cloneElement, useContext, useEffect, useState } from 'react'
import qs from 'qs'
import { useLocation, useNavigate } from 'react-router-dom'

import { modalActionConfig } from './creationShortcutsConfig'
import { EFormModalMode } from '../../utils/app-models'
import { AuthContext } from '../../utils/contexts/AuthContext'
import { useModal } from '../../utils/hooks'

interface ICrudModal {}

const CrudModal: React.FC<ICrudModal> = () => {
	const [crudStateModal, setCrudStateModal] = useState<any>(null)
	const modalUtils = useModal(false)

	const navigate = useNavigate()
	const location = useLocation()

	const { checkPermissions, checkModules } = useContext(AuthContext)

	useEffect(() => {
		const { crudmodal } = qs.parse(location.search, {
			ignoreQueryPrefix: true,
		})

		if (crudmodal) {
			const selectedModal: any = modalActionConfig.find(
				({ name }) => name === crudmodal
			)

			if (selectedModal) {
				if (
					!checkPermissions(selectedModal?.createPermission) ||
					!checkModules(selectedModal?.modules)
				)
					navigate(location.pathname)
				else {
					setCrudStateModal(selectedModal)
					modalUtils.openModal()
				}
			}
		} else setCrudStateModal(null)
	}, [location, navigate])

	return (
		<>
			{modalUtils?.isVisible &&
				cloneElement(crudStateModal?.formModalComponent, {
					modalUtils,
					mode: EFormModalMode.create,
				})}
		</>
	)
}

export default CrudModal
