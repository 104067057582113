export default {
	default: 'Rechercher',
	customer: 'Rechercher un compte',
	contacts: 'Rechercher un contact',
	site: 'Rechercher un site',
	vehicle: 'Rechercher un véhicule',
	team: 'Rechercher une équipe',
	user: 'Rechercher un utilisateur',
	formTemplate: 'Rechercher un modèle',
	intervention: 'Rechercher une intervention',
	missingFormSelectSearchQuery:
		'Aucun résultat ne correspond à votre recherche. (3 caractères minimum)',
}
