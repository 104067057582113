export default {
	users: 'Utilisateurs',
	user: 'Utilisateur',
	name: 'Nom',
	firstname: 'Prénom',
	lastname: 'Nom',
	job: 'Fonction',
	email: 'Email',
	password: 'Mot de passe',
	actualPassword: 'Mot de passe actuel',
	newPassword: 'Nouveau mot de passe',
	confirmPassword: 'Confirmation du nouveau mot de passe',
	phone: 'Téléphone',
	cellphone: 'Mobile',
	reference: 'Référence',
	referent: 'Référent',
	referentAccount: 'Référent de compte',
	referentSite: 'Référent de site',
	referentSiteAccount: 'Référent site/compte',
	from: 'de',
	to: 'à',
	yes: 'Oui',
	no: 'Non',
	cancel: 'Annuler',
	validate: 'Valider',
	save: 'Sauvegarder',
	confirm: 'Confirmer',
	backEdit: 'SORTIR DE L’ÉDITEUR',
	edit: 'Modifier',
	deleted: 'Supprimer',
	duplicate: 'Dupliquer',
	see: 'Voir',
	connexion: 'Connexion',
	sitesAccess: 'Accès Sites',
	site: 'Site',
	sites: 'Sites',
	networksAccess: 'Accès Réseaux',
	selectNetworks: 'Sélectionner un ou plusieurs réseaux',
	defectFound: 'Alertes',
	members: 'Membres',
	operators: 'Opérateurs',
	active: 'Actif',
	inactive: 'Inactif',
	desactive: 'Inactif',
	teamsMember: `Liste des équipes`,
	userList: `Liste des utilisateurs`,
	userListTooltips: `Une équipe est un groupe d'utilisateurs dont l'accès à la plateforme est limité aux sites et/ou aux réseaux affectés (Module Réseau).
    Les administrateurs ne sont pas concernés par cette limitation.`,
	teamManager: `Responsable d'équipe`,
	teamNotDefine: `Responsable non défini`,
	teamName: "Nom d'équipe",
	sitesLabel: 'Sites',
	networksLabel: 'Réseaux',
	information: 'Informations',
	informationContact: 'Informations de contact',
	comment: 'Informations complémentaires',
	comment2: 'Commentaire',
	contactRef: 'Contacts référents',
	contactRefSite: 'Référent(s) du site',
	technicalInformation: 'Informations techniques',
	teams: 'Equipes',
	team: 'Equipe',
	teamStatusState: 'Equipe active',
	siteStatusState: 'Site actif',
	customerStatusState: 'Compte actif',
	add: 'Ajouter',
	addAt: 'Ajouté le',
	addBy: 'Ajouté par',
	search: 'Rechercher',
	connectWelcome:
		'Veuillez entrer vos identifiants pour vous connecter à votre compte.',
	schedule: 'Planning',
	dashboard: 'Tableau de bord',
	networks: 'Réseaux',
	network: 'Réseau',
	clients: 'Clients',
	directory: 'Répertoire',
	accounts: 'Comptes',
	account: 'Compte',
	generalAccount: 'Account',
	agency: 'Agence',
	agencies: 'Agences',
	agencyPartner: 'Agences associées',
	interveningAgency: 'Agences intervenantes',
	location: 'Emplacement',
	contact: 'Contact',
	contacts: 'Contacts',
	siteName: 'Nom du site',
	addressSite: 'Adresse du site',
	interventions: 'Interventions',
	intervention: 'Intervention',
	reportIntervention: `Formulaires`,
	vehicles: 'Véhicules',
	vehicle: 'Véhicule',
	vehicleStatusState: 'véhicule actif',
	alerts: 'Alertes',
	permissions: "Droits d'accès",
	nothingToDisplay: 'La page est vide',
	nothingToDisplayBold: 'Aucun résultat ne correspond à votre recherche',
	nothingToDisplayparagraph:
		'Modifiez les filtres ou saisissez une autre recherche.',
	addVehiculesToIntervention:
		'Cliquer sur AJOUTER pour affecter des véhicules à votre intervention',
	noResultSearch: 'Aucun résultat',
	platform: 'plateforme',
	updatedAt: 'Mise à jour le',
	address: 'Adresse',
	city: 'Ville',
	country: 'Pays',
	zipCode: 'Code postal',
	mailSite: 'Mail',
	numberVehicle: 'Nb véhicules',
	infosComplementary: 'Informations complémentaires',
	manager: 'Gestionnaire', // Gestionnaire pour les sites , todo à vérifier
	phoneSite: 'Téléphone',
	mobileSite: 'Téléphone mobile',
	siret: 'SIRET',
	localisation: 'Localisation',
	nbSites: 'Nb sites',
	nbInterventions: 'Nb interventions',
	siretNumber: 'Numéro de siret',
	tavNumber: 'Numéro de tva',
	zipcode: 'Code Postal',
	socialName: 'Raison sociale',
	dateMec: 'Date de première mise en circulation',
	dateParkEntry: "Date d'entrée sur parc",
	dateParkExit: 'Date de sortie du parc',
	accountSite: 'Compte / site',
	visibleRows: 'Colonnes visibles',
	importData: 'Importer des données',
	allExport: 'Tout exporter',
	exportData: 'Export de données',
	exportDataToExcel: 'Exporter au format Excel :',
	export: 'Exporter',
	exportWaiting:
		'Nous préparons votre export, veuillez ne pas quitter la page.',
	close: 'Fermer',
	ref: 'Référence',
	poste: 'Fonction',
	createdAt: 'Date de création',
	createdBy: 'Créé par',
	goBackToDashboard: 'Retourner sur le dashboard',
	uploadFile: 'Cliquer ou déposer un document',
	download: 'Télécharger',
	selectedFile: 'Document sélectionné',
	dragFileHere: 'Déposer le document ici ...',
	factures: 'Factures',
	status: 'Statut',
	progression: 'Progression',
	todo: 'A faire',
	returned: 'Restitué',
	all: 'Toutes',
	allM: 'Tout',
	period: 'Période',
	allPeriod: 'Toutes',
	thisWeek: 'Cette semaine',
	today: "Aujourd'hui",
	lastWeek: 'Semaine dernière',
	thisMonth: 'Ce mois-ci',
	lastMonth: 'Le mois dernier',
	nextMonth: 'Le mois prochain',
	customPeriod: 'Plage Personnalisée',
	custom: 'Personnaliser',
	filter: 'Filtres',
	customerAndSite: 'Compte / Site',
	doneDate: 'Effectué le',
	doBy: 'Effectué par',
	noElement: 'Aucun élément',
	none: 'Aucun',
	categorie: 'Catégories',
	products: 'Prestations',
	month: 'Mois',
	day: 'Jour',
	days: 'Jours',
	week: 'Semaine',
	displayBy: 'Afficher par',
	other: 'Autre',
	others: 'Autres',
	display: 'Afficher',
	leave: 'Quitter',
	deleteFilters: 'Supprimer les filtres',
	order: 'Ordre',
	empty: 'Vide',
	tooltipCustomerResp:
		"Le contact est l'un des responsables du compte selectionné",
	tooltipSiteResp:
		"Le contact est l'un des reponsables du ou des sites selectionnés",
	identification: 'Identification',
	Alert: 'Alerte',
	Alerts: 'Alertes',
	title: 'Titre',
	summary: 'Synthèse',
	next: 'Suivant',
	previous: 'Précédent',
	saveAndQuit: 'Sauvegarder et quitter',
	invalideSiret: 'SIRET invalide',
	type: 'Type',
	limited: 'Limité',
	premium: 'Premium',
	invitation: 'Invitation',
	upgrade: 'Mettre à niveau',
	downgrade: 'Rétrograder',
	selected: 'Selectionné',
	select: 'Sélectionner',
	selection: 'Sélection',
	bySites: 'Par site(s)',
	byNetworks: 'Par réseau(x)',
	affectation: 'Affectation',
	geolocalisation: 'Géolocalisation',
	notInformed: 'Non renseigné',
	accountResponsable: 'Référent(s) du compte',
	noEntry: 'Aucun élément disponible',
	loading: 'Chargement',
	source: 'Source',
	owner: 'Affectation',
	inviteNotSend: 'Aucune invitation envoyée',
	inviteSend: 'Invitation envoyée le ',
	userNotRegister: 'Utilisateur non enregistré',
	useRegister: 'Utilisateur enregistré',
	comingSoon: 'La fonctionnalité est bientôt disponible',
	shared: 'Partagé',
	sharedBy: 'Partagé par',
	selectAll: 'Tout sélectionner',
	myInfos: 'Mes informations',
	passwordForgot: 'Mot de passe oublié ?',
	changeLayout: 'Modifier la disposition',
	photos: 'Photos',
	allAccounts: 'Tous les comptes',
	share: 'Partage',
	discoverHubee: 'Vous souhaitez découvrir HubeeConnect ?',
	askDemo: 'demander une démo',
	createAccount: 'créer un compte',
	accountType: 'Type de compte',
	toUpdate: 'Mettre à jour',
	dateStart: 'Date de début',
	dateEnd: 'Date de fin',
	hourStart: 'Heure de début',
	hourEnd: 'Heure de fin',
	resetAll: 'Tout effacer',
	byStatus: 'Par statut',
	remove: 'Retirer',
	lessOptions: 'Moins d’options',
	moreOptions: 'Plus d’options',
	create: 'Créer',
	emptySelection: 'Vider la sélection',
	noDataFoundSearch: 'Aucune donnée ne correspond à votre recherche',
	french: 'Français',
	english: 'Anglais',
	customerRef: 'Réf client',
	publicDesc: 'Description publique',
	showMore: 'Voir plus',
	showLess: 'Voir moins',
	clientPart: 'Client',
	driverPart: 'Transporteur',
	service: 'Prestation',
	services: 'Prestations',
	orderService: 'Commander un service',
	showAll: 'Tout afficher',
	showSelectedElements: 'Afficher les éléments sélectionnés',
	recipient: 'Destinataire',
	dateMission: 'Date mission',
	missions: 'Missions',
	mission: 'Mission',
	note: 'Note',
	enteredBy: 'Renseigné par',
	planned: 'Prévu',
	step: 'Étape',
	conveyors: 'Convoyeurs',
	commercialLib: 'Libellé',
	lib: 'Libellé',
	label: 'Libellé',
	duration: 'Durée',
	workUnit: 'Unité d’œuvre',
	priceHt: 'Tarif HT',
	dateContractStart: 'Date de début contrat',
	dateContractEnd: 'Date de fin contrat',
	validContractDate: 'Validité contrat',
	permanent: 'Permanent',
	missionType: 'Type Mission',
	swapPickupDeliveryAddresses:
		"Inverser les adresses d'enlèvement et de livraison",
	dateFrom: 'À partir du',
	dateUntil: 'Jusqu’au',
	bannerMessageFormModalIntervention:
		'Cette intervention a été créée par {name}, certains éléments ne sont pas modifiables.',
	bannerMessageFormModalTransport:
		'Ce transport a été créée par {name}, certains éléments ne sont pas modifiables.',
	downloadPdf: 'Télécharger le PDF',
	tomorrow: 'Demain',
	yesterday: 'Hier',
	nextWeek: 'Semaine prochaine',
}
