import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import getNestedObjectFromKey from 'lodash.get'

const duplicateTableRow = ({ mutation, refreshQuery, redirect }, rowId) => {
	const navigate = useNavigate()
	const [duplicateMutation] = mutation()

	const getDuplicatedTableRow = useCallback(async () => {
		await duplicateMutation({
			variables: { input: { id: rowId } },
			refetchQueries: refreshQuery,
		}).then((res) => {
			if (redirect) {
				navigate(
					`${redirect.path}${getNestedObjectFromKey(
						res.data,
						redirect.selectorResponse
					)}`
				)
			}
		})
	}, [])

	return getDuplicatedTableRow
}

export default duplicateTableRow
