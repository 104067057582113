import { boolean, date, object, ref } from 'yup'
import translateIntlMsgString from '../../../../../utils/translateIntlMsgString'

export const interventionModalInfoValidationSchema = object().shape({
	orderingCustomer: object()
		.required(translateIntlMsgString('validationSchema.require'))
		.nullable(true),
	manager: object()
		.required(translateIntlMsgString('validationSchema.require'))
		.nullable(true),
	orderingSite: object()
		.required(translateIntlMsgString('validationSchema.require'))
		.nullable(true),
	status: object()
		.required(translateIntlMsgString('validationSchema.require'))
		.nullable(true),
	finalCustomer: object()
		.nullable(true)
		.when('isOrderEqualFinal', {
			is: false,
			then: object()
				.nullable(true)
				.when('isManualEntry', {
					is: false,
					then: object()
						.nullable()
						.required(translateIntlMsgString('validationSchema.require'))
						.nullable(true),
				}),
		})
		.nullable(true),
	isOrderEqualFinal: boolean(),
	isManualEntry: boolean(),
	finalSite: object()
		.nullable(true)
		.when('isOrderEqualFinal', {
			is: false,
			then: object()
				.nullable(true)
				.when('isManualEntry', {
					is: false,
					then: object()
						.nullable()
						.required(translateIntlMsgString('validationSchema.require'))
						.nullable(true),
				}),
		})
		.nullable(true),

	dateFrom: date()
		.required(translateIntlMsgString('validationSchema.require'))
		.nullable(),

	dateFromHours: date().typeError(translateIntlMsgString('errors.dateInvalid')),
	dateToHours: date().typeError(translateIntlMsgString('errors.dateInvalid')),

	dateTo: date()
		.required(translateIntlMsgString('validationSchema.require'))
		.min(ref('dateFrom'), translateIntlMsgString('errors.errorDateSup'))
		.nullable(),
})
