import { useContext, useMemo } from 'react'
import { useProductsAndCategoriesList } from '../../../../../../../api/gql/queries/catalogProductQueries'
import {
	FormModalContext,
	IFormModalContext,
} from '../../../../../../../components/forms/formModal/FormModalContext'
import { useFormTemplates } from '../../../../../../../api/gql/queries/formTemplateQuery'
import { getCountries } from '../../../../../../../utils/tabCountries'
import { AuthContext } from '../../../../../../../utils/contexts/AuthContext'
import { EInterventionTypes } from '../../../../../../../utils/app-models'

export const useTransportTripData = () => {
	const { data } = useContext<IFormModalContext>(FormModalContext)
	const { localLanguage } = useContext(AuthContext)

	const { productCategories } = useProductsAndCategoriesList({
		variables: {
			customer:
				data?.finalCustomer?.value?.id ||
				data?.orderingCustomer?.value?.id ||
				'',
		},
	})

	const { formTemplates } = useFormTemplates({
		variables: {
			page: 1,
			itemsPerPage: 100000, //todo change itemsPerPage
			isActive: true,
			isDeleted: false,
			interventionType_list: [EInterventionTypes.transport, 'all'],
			customer:
				data?.finalCustomer?.value?.id ||
				data?.orderingCustomer?.value?.id ||
				'',
		},
	})

	const formTemplatesOptions = useMemo(
		() =>
			formTemplates?.map((formTemplate) => ({
				key: formTemplate?.id,
				label: formTemplate?.name,
				value: formTemplate,
			})),
		[formTemplates]
	)

	const productCategoriesOptions = useMemo(() => {
		const tmp: any = []

		productCategories?.forEach(
			({ products: { collection }, name: categoryName }) => {
				collection?.forEach((product) => {
					tmp.push({
						key: product?.id,
						label: product?.name,
						value: {
							...product,
							categoryName,
						},
					})
				})
			}
		)

		return tmp
	}, [productCategories])

	const countriesOptions = useMemo(
		() =>
			getCountries(localLanguage)?.map((country) => ({
				label: country.label,
				key: country.code,
				value: country.code,
			})),
		[localLanguage]
	)

	return {
		formTemplatesOptions,
		productCategoriesOptions,
		countriesOptions,
	}
}
