/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react'

export type IFiltersContext = {
	defaultFiltersValue: any
	localStorageKeyFilter: string
	componentsFiltersConfig: any
	filters: any
	setFilters: (newFilters: any) => void
	setFilter: (newFilter: any) => void
	resetFilters: () => void
}

export const FiltersContext: React.Context<IFiltersContext> = createContext(
	{} as IFiltersContext
)
