/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
	gql,
	QueryHookOptions,
	OperationVariables,
	useLazyQuery,
	useQuery,
} from '@apollo/client'
import {
	listinterventionForms,
	formInterventionAttributes,
	formInterventionPhotosAttributes,
} from '../attributes/interventionFormsAttributes'

export const LIST_FORM_INTERVENTIONS = gql`
query getListFormInterventions(
    $page: Int
    $itemsPerPage: Int
    $searchText: String
    $startFrom: String
	$endTo: String
	$vehicle: String
	$intervention: String
    $orderBy: [FormFilter_orderBy]
	$customer_list: [String]
	$intervention_interventionType: String
) {
    forms(
    page: $page,
    itemsPerPage: $itemsPerPage,
    searchText: $searchText,
    startFrom: $startFrom,
	endTo: $endTo,
    vehicle: $vehicle
    intervention: $intervention
	orderBy: $orderBy
	customer_list: $customer_list
	intervention_interventionType: $intervention_interventionType
    ) {
        ${listinterventionForms}
    }
}`

export const EXPORT_FORMS = gql`
	query exportForms(
		$searchText: String
		$startFrom: String
		$endTo: String
		$vehicle: String
		$intervention: String
		$timezone: Int
		$orderBy: [FormFilter_orderBy]
	) {
		exportForms(
			searchText: $searchText
			startFrom: $startFrom
			endTo: $endTo
			vehicle: $vehicle
			intervention: $intervention
			timezone: $timezone
			orderBy: $orderBy
		) {
			id
			exportFile
		}
	}
`

export const FORM_INTERVENTION_NUMBERS = gql`
	query getFormInterventionNumbers(
		$searchText: String
		$startFrom: String
		$endTo: String
	) {
		forms(
			page: 1
			itemsPerPage: 100000
			searchText: $searchText
			startFrom: $startFrom
			endTo: $endTo
		) {
			paginationInfo {
				totalCount
			}
		}
	}
`

export const FORM_INTERVENTIONS_COUNT_TYPE = gql`
	query formInterventionsCountType {
		forms {
			collection {
				forms {
					paginationInfo {
						totalCount
					}
				}
			}
		}
	}
`

export const GET_FORM_INTERVENTION = gql`
query getForm(
    $id: ID!
) {
    form(id: $id) {
        ${formInterventionAttributes} 
    }
}`

export const INTERVENTION_FORMS_COUNT_BY_VEHICLE = gql`
	query getInterventionFormsNumbersByVehicle($vehicle: String) {
		forms(page: 1, itemsPerPage: 100000, vehicle: $vehicle) {
			paginationInfo {
				totalCount
			}
		}
	}
`

export const INTERVENTION_FORMS_COUNT_BY_INTERVENTION = gql`
	query getInterventionFormsNumbersByIntervention($intervention: String) {
		forms(page: 1, itemsPerPage: 100000, intervention: $intervention) {
			paginationInfo {
				totalCount
			}
		}
	}
`

export const FORM_ANNOTATION_TYPE = gql`
	query formAnnotationTypes {
		formAnnotationTypes {
			id
			code
			name
		}
	}
`

export const GET_FORM_PHOTOS = gql`
query getFormPhotos(
    $page: Int
    $itemsPerPage: Int
	$vehicle: String
	$intervention: String
) {
    picturedForms(
	page: $page,
    itemsPerPage: $itemsPerPage,
	vehicle: $vehicle
	intervention: $intervention
	) {
        ${formInterventionPhotosAttributes} 
    }
}`

export const VEHICLE_FORM_PHOTOS_COUNT = gql`
	query vehicleFormPhotosCount($vehicle: String) {
		picturedForms(vehicle: $vehicle) {
			paginationInfo {
				totalCount
			}
		}
	}
`
export const TRANSPORT_FORM_PHOTOS_COUNT = gql`
	query transportFormPhotoCount($intervention: String) {
		picturedForms(intervention: $intervention) {
			paginationInfo {
				totalCount
			}
		}
	}
`

export const INTERVENTION_FORM_PHOTOS_COUNT = gql`
	query interventionFormPhotoCount($intervention: String) {
		picturedForms(intervention: $intervention) {
			paginationInfo {
				totalCount
			}
		}
	}
`

/**  ---------------------HOOKS---------------------- */

export const useLazyFormInterventionPDF: any = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(
		GET_FORM_INTERVENTION,
		options
	)

	return {
		loading,
		data: data?.form || null,
		getForm: getData,
	}
}

export const useLazyFormAnnotationTypePDF: any = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(
		FORM_ANNOTATION_TYPE,
		options
	)

	return {
		loading,
		annotationTypes: data?.form || null,
		getFormAnnotationTypes: getData,
	}
}

export const useExportForms: any = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { data, loading, error } = useQuery(EXPORT_FORMS, options)

	return {
		exportFile: data?.exportForms?.[0]?.exportFile || null,
		loading,
		error,
	}
}
