import React from 'react'

import FormTemplateProvider from '../../../providers/FormTemplateProvider'
import FormTemplate from './FormTemplate'

import { formTemplateValidationSchema } from '../../../utils/validationSchema'

interface IFormTemplateContainer {}

const FormTemplateContainer: React.FC<IFormTemplateContainer> = () => {
	return (
		<FormTemplateProvider
			validationSchemaFunction={formTemplateValidationSchema}
		>
			<FormTemplate />
		</FormTemplateProvider>
	)
}

export default FormTemplateContainer
