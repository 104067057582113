import React from 'react'

import { Box } from '@mui/material'

const DataListGridCSS = {
	display: 'grid',
	gap: 1,
	gridTemplateColumns: 'repeat(2, 1fr)',
	wordWrap: 'break-word',
	wordBreak: ' break-all',
	maxWidth: '100%',
}

interface IDataListGrid {
	children
}

const DataListGrid: React.FC<IDataListGrid> = ({ children }) => {
	return <Box sx={DataListGridCSS}>{children}</Box>
}
export default DataListGrid
