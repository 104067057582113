import React, { useContext } from 'react'

import { Box, Stack, Typography, Card, Divider } from '@mui/material'
import { useFormatMessage } from '../../../utils/hooks'
import { ViewModalContentContext } from '../../../utils/contexts/ViewModalContentContext'
import ShowMoreTxt from '../../showMoreTxt'
import { grey } from '@mui/material/colors'

const titleCSS = { fontWeight: 700, fonSize: '20px' }
const labelSignatureCSS = {
	fontSize: '10px',
	position: 'absolute',
	p: '5px',
	top: '0px',
	left: '0px',
}
const imgSignatureCSS = { maxHeight: '45px' }
const containerSignatureCSS = {
	backgroundColor: '#FAFAFA',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}

const WTransportTripSummaryDriverPart = () => {
	const intlMsg = useFormatMessage()
	const {
		viewModalData: { endingForm },
	} = useContext(ViewModalContentContext) || {}

	return (
		<Card sx={{ height: 1 }}>
			<Stack spacing={2} p={2}>
				<Typography variant='h3' sx={titleCSS}>
					{intlMsg(`interventions.conveyor`)}
				</Typography>

				<Divider variant='middle' />

				<Typography variant='body2' sx={{ color: grey[600] }}>
					{intlMsg(`misc.comment2`)}
				</Typography>

				{endingForm?.value?.operator?.comment ? (
					<ShowMoreTxt txt={endingForm.value.operator.comment} maxSize={150} />
				) : (
					'-'
				)}

				<Divider variant='middle' />

				<Stack spacing={8} direction='row' width='100%'>
					<Box sx={{ width: '50%' }}>
						<Box
							height={63}
							sx={containerSignatureCSS}
							borderRadius={1}
							position='relative'
						>
							<Box sx={labelSignatureCSS}>Signature</Box>
							{endingForm?.value?.operator?.signature && (
								<img
									src={endingForm.value.operator.signature}
									style={imgSignatureCSS}
									onError={(e) => {
										const imgElement = e.target as HTMLImageElement
										imgElement.onerror = null
										imgElement.src =
											'data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="1" height="1" fill-opacity="0"></svg>'
									}}
								/>
							)}
						</Box>
					</Box>
				</Stack>
			</Stack>
		</Card>
	)
}

export default WTransportTripSummaryDriverPart
