import React from 'react'

import toast from 'react-hot-toast'
import SendIcon from '@mui/icons-material/Send'
import { stringifiedColor } from '../../utils/color'

const customToast = (typeAlert: string, message: string) => {
	const blue = stringifiedColor('blue', 800) as any

	switch (typeAlert) {
		case 'sendEmail':
			return toast(message, {
				icon: <SendIcon sx={{ color: blue }} fontSize='small' />,
				className: typeAlert,
				style: {
					background: 'white',
					borderColor: blue,
					color: blue,
				},
			})
		default:
			return toast(message, { className: typeAlert })
	}
}

export default customToast
