/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'

import { useBulkInterventionAction } from '../../../../../api/gql/mutations/interventionsMutation'
import {
	LIST_INTERVENTIONS_BY_VEHICLE,
	useExportIpv,
} from '../../../../../api/gql/queries/interventionsQueries'
import {
	EFormModalMode,
	EModule,
	EPermissionScope,
} from '../../../../../utils/app-models'

import {
	ITableToolBarActionConfig,
	ITableToolBarConfig,
} from '../../../../../utils/typescript/tableType'
import listInterventionsControlByVehicleViewTable from '../../../../../components/tableV2/config/dashboard/interventions/byVehicle/listInterventionsControlByVehicleViewTable'
import { interventionMenuTabConfig } from '../interventionMenuTabConfig'
import ByVehicleFilters from './byVehicleFilters'
import { byVehicleDefaultFiltersValues } from './byVehicleFilters/byVehicleDefaultFiltersConfig'
import FormBtnModal from '../../../../../components/forms/formModalBtn'
import InterventionModal from '../interventionModal'
import { InterventionValidationSchema } from '../../../../../utils/validationSchema'

////////////////////////////////////////////////////////////////
// Global variable
////////////////////////////////////////////////////////////////

const localStorageKeyFilter = 'interventionByVehicleFilter'

const queryConfig = {
	query: LIST_INTERVENTIONS_BY_VEHICLE,
	queryName: 'interventionProgrammedVehicles',
}

////////////////////////////////////////////////////////////////
// tableNavTab CONFIG
////////////////////////////////////////////////////////////////

const tableNavTabConfig = {
	navTabConfig: interventionMenuTabConfig,
	sxTabs: { mb: 3 },
}

////////////////////////////////////////////////////////////////
// tableToolBarAction CONFIG
////////////////////////////////////////////////////////////////

const refreshQuerie = [
	'interventionProgrammedVehicles',
	'getInterventionNumbersByVehicle',
	'getInterventionNumbers',
]

const deletedBulk = {
	mutation: useBulkInterventionAction,
	operation: 'remove',
	successMessageDelete: {
		singular: 'success.interventionDeleted',
		plural: 'success.interventionsDeleted',
	},
	messageConfirm: {
		title: 'confirmModal.deleteInterventionsTitle',
		message: 'confirmModal.deleteInterventionsMessage',
	},
	deletePermissions: [
		EPermissionScope.vehicleDelete,
		EPermissionScope.vehicleRemove,
	],
}

const tableToolBarActionConfig: ITableToolBarActionConfig = {
	refreshQuerie,
	deletedBulk,
}

////////////////////////////////////////////////////////////////
// TableToolBarHeader CONFIG
////////////////////////////////////////////////////////////////

const tableToolBarConfig: ITableToolBarConfig = {
	title: 'interventions.vehicles',
	stickyToolBar: true,
	tableConfig: listInterventionsControlByVehicleViewTable,
	localStorageKeyFilter,
	localStorageName: 'listInterventionsByVehicleViewTableFilters',
	exportConfig: { exportQuery: useExportIpv },
	queryConfig,
	modalButton: {
		Btn: (
			<FormBtnModal
				modal={<InterventionModal modalUtils={{} as any} />}
				mode={EFormModalMode.create}
			/>
		),
	},
	validationSchemaFunction: InterventionValidationSchema,
	createPermission: [EPermissionScope.interventionCreate],
	modules: [EModule.interventionControl],
}

////////////////////////////////////////////////////////////////
// tableContainer CONFIG
////////////////////////////////////////////////////////////////

const tableContainerConfig = {
	//height: '59vh',
	tableConfig: listInterventionsControlByVehicleViewTable,
	queryConfig,
}

const defaultFiltersConfig = {
	componentsFiltersConfig: <ByVehicleFilters />,
	defaultFiltersValues: byVehicleDefaultFiltersValues,
	localStorageKeyFilter,
}

export const interventionByVehicleConfigTable = {
	tableNavTabConfig,
	tableToolBarConfig,
	tableToolBarActionConfig,
	tableContainerConfig,
	defaultFiltersConfig,
}
