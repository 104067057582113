import React, { useState, useContext } from 'react'

import { Box } from '@mui/material'

import Interventions from './Interventions'
import ActorsInfos from './ActorsInfos'
import { AuthContext } from '../../../../utils/contexts/AuthContext'
import { EAccountType, EModule } from '../../../../utils/app-models'
import CommentIntervention from './CommentIntervention'
import PermissionsHandler from '../../../../components/permissionsHandler'

interface IInterventionsByReferenceInformations {
	data
}

const InterventionsByReferenceInformations: React.FC<
	IInterventionsByReferenceInformations
> = ({ data }) => {
	const { checkAccountsType } = useContext(AuthContext)
	const [currentUserIsGuest] = useState(checkAccountsType([EAccountType.guest]))

	return (
		<Box padding={2}>
			<Interventions
				interRef={data?.reference}
				interventionType={data?.interventionType}
				dateTo={data?.dateTo}
				dateFrom={data?.dateFrom}
				status={data?.status}
				cancelComment={data?.cancelComment}
				cancelReason={data?.cancelReason}
				manualEntry={{
					finalContactText:
						data?.interventionControlFinalCustomer?.finalContactText,
					finalAddressText:
						data?.interventionControlFinalCustomer?.finalAddressText,
				}}
				contact={{
					id: data?.isOrderEqualFinal
						? data?.orderingContact?.id
						: data?.interventionControlFinalCustomer?.finalContact?.id,
					firstName: data?.isOrderEqualFinal
						? data?.orderingContact?.firstName
						: data?.interventionControlFinalCustomer?.finalContact?.firstName,
					lastName: data?.isOrderEqualFinal
						? data?.orderingContact?.lastName
						: data?.interventionControlFinalCustomer?.finalContact?.lastName,
				}}
				customerRequest={data?.orderingCustomer}
				siteRequest={data?.orderingSite}
				customer={{
					id: data?.isOrderEqualFinal
						? data?.orderingCustomer?.id
						: data?.interventionControlFinalCustomer?.finalCustomer?.id,
					name: data?.isOrderEqualFinal
						? data?.orderingCustomer?.name
						: data?.interventionControlFinalCustomer?.finalCustomer?.name,
				}}
				site={{
					id: data?.isOrderEqualFinal
						? data?.orderingSite?.id
						: data?.interventionControlFinalCustomer?.finalSite?.id,
					name: data?.isOrderEqualFinal
						? data?.orderingSite?.name
						: data?.interventionControlFinalCustomer?.finalSite?.name,
					address: {
						city: data?.isOrderEqualFinal
							? data?.orderingSite?.city || ''
							: data?.interventionControlFinalCustomer?.finalSite?.city || '',
						address: data?.isOrderEqualFinal
							? data?.orderingSite?.address || ''
							: data?.interventionControlFinalCustomer?.finalSite?.address ||
							  '',
						zipcode: data?.isOrderEqualFinal
							? data?.orderingSite?.zipcode || ''
							: data?.interventionControlFinalCustomer?.finalSite?.zipcode ||
							  '',
					},
				}}
			/>

			{!currentUserIsGuest && (
				<ActorsInfos
					actors={data?.actors?.collection}
					manager={data?.manager}
					agencies={data?.agencies?.collection}
				/>
			)}

			<PermissionsHandler modulesName={[EModule.crm]}>
				<CommentIntervention
					title='interventions.missionDescription'
					comment={data?.publicComment}
					sx={{ mb: 2 }}
				/>
			</PermissionsHandler>

			<CommentIntervention
				title='interventions.actorsDescription'
				comment={data?.privateComment}
			/>
		</Box>
	)
}

export default InterventionsByReferenceInformations
