import React from 'react'
import { Typography } from '@mui/material'
import { useFormatMessage } from '../../../utils/hooks'

interface IInterventionType {
	interventionType: string
}

const InterventionType: React.FC<IInterventionType> = ({
	interventionType,
}) => {
	const intlMsg = useFormatMessage()
	return (
		<Typography variant='caption'>
			{interventionType ? intlMsg(`interventions.${interventionType}`) : '-'}
		</Typography>
	)
}

export default InterventionType
