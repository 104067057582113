import React from 'react'

import Table from '../../../components/tableV2'
import {
	IOverrideConfigTable,
	IOverrideConfigTableWithArgument,
} from '../../../utils/typescript/tableType'
import { customerContactConfigTable } from './customerContactConfigTable'

interface ICustomerContacts {
	othersQueryParams?
	overrideConfigTable?: IOverrideConfigTable | IOverrideConfigTableWithArgument
	isInViewModal?: boolean
}

const CustomerContacts: React.FC<ICustomerContacts> = ({
	othersQueryParams,
	overrideConfigTable,
	isInViewModal = false,
}) => {
	return (
		<Table
			tableConfig={customerContactConfigTable}
			overrideConfigTable={overrideConfigTable}
			othersQueryParams={othersQueryParams}
			isInViewModal={isInViewModal}
		/>
	)
}

export default CustomerContacts
