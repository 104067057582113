import React from 'react'
import { Box } from '@mui/material'

interface IDataListSignature {
	url: string
}

const containerSignatureCSS = {
	display: 'flex',
	backgroundColor: '#F5F5F5',
	p: 2,
	justifyContent: 'center',
}

const DataListSignature: React.FC<IDataListSignature> = ({ url }) => {
	return (
		<Box sx={containerSignatureCSS}>
			<img src={url} style={{ maxHeight: '45px' }} />
		</Box>
	)
}

export default DataListSignature
