export default {
	log: 'Connexion',
	user: 'Utilisateurs',
	site: 'Sites',
	customer: 'Comptes',
	contact: 'Contacts',
	vehicle: 'Véhicules',
	intervention: 'Interventions',
	tool: 'Outils',
	form: 'Formulaires',
	network: 'Réseaux',
	alert: 'Alertes',
	mega: 'Mega',
	invoice: 'Dépenses',
	product: 'Prestations',
	document: 'Documents',

	'user:manage': 'Gestion des utilisateurs',
	'user:manage:explanation': 'Créer, éditer, supprimer les utilisateurs',

	'site:manage': 'Gestion des sites',
	'site:manage:explanation': 'Créer, éditer, supprimer les sites',
	'site:view': 'Vue des sites',
	'site:view:explanation': 'Voir la liste et le détail des sites',
	'site:remove': 'Suppression des sites',
	'site:remove:explanation': 'supprimer les sites',

	'customer:manage': 'Gestion des comptes',
	'customer:manage:explanation': 'Créer, éditer, supprimer les comptes',
	'customer:view': 'Vue des comptes',
	'customer:view:explanation': 'Voir la liste et le détail des comptes',
	'customer:delete': 'Suppression des comptes',
	'customer:delete:explanation': 'Supprimer les comptes',

	'contact:manage': 'Gestion des contacts',
	'contact:manage:explanation': 'Créer, éditer, supprimer les contacts',
	'contact:view': 'Vue des contacts',
	'contact:view:explanation': 'Voir la liste et le détail des contacts',
	'contact:delete': 'Suppression des contacts',
	'contact:delete:explanation': 'Supprimer les contacts',
	'contact:invite': 'Invitation des contacts',
	'contact:invite:explanation':
		"Partager avec les contacts l'activité liée à leur compte",

	'vehicle:create': 'Création des véhicules',
	'vehicle:create:explanation': 'Créer des véhicules',
	'vehicle:update': 'Edition des véhicules',
	'vehicle:update:explanation': 'Mettre à jour les véhicules',
	'vehicle:remove': 'Suppression des véhicules',
	'vehicle:remove:explanation': 'Supprimer les véhicules',

	'intervention:create': "Création d'interventions",
	'intervention:create:explanation': 'Créer les interventions',
	'intervention:hot-create': "Création d'interventions depuis l'app mobile",
	'intervention:hot-create:explanation':
		"Créer des interventions directement depuis l'app mobile",
	'intervention:update': 'Edition des interventions',
	'intervention:update:explanation': 'Mettre à jour les interventions',
	'intervention:view': 'Vue des interventions',
	'intervention:view:explanation':
		'Voir la liste et le détail des interventions',
	'intervention:remove': 'Suppression des interventions',
	'intervention:remove:explanation': 'Supprimer les interventions',
	'intervention:cancel': 'Annulation des interventions',
	'intervention:cancel:explanation':
		"Indiquer le statut des interventions comme 'annulé'",

	'form_template:manage': 'Gestion des modèles de formulaires',
	'form_template:manage:explanation':
		'Créer, éditer, supprimer les modèles de formulaires',
	'form_template:remove': 'Suppression des modèles de formulaires',
	'form_template:remove:explanation': 'Supprimer les modèles de formulaires',
	'form:view': 'Vue des formulaires',
	'form:view:explanation': 'Voir la liste et le résultat des formulaires',
	'form:remove': 'Suppression des formulaires',
	'form:remove:explanation': 'Supprimer les formulaires',

	'network:manage': 'Gestion des réseaux et des agences',
	'network:manage:explanation':
		'Créer, éditer, supprimer les réseaux et les agences',
	'network:view': 'Vue des réseaux et des agences',
	'network:view:explanation': 'Voir la liste des réseaux et leurs agences',

	'alert:create': 'Création des alertes',
	'alert:create:explanation': 'Créer des alertes sur les véhicules',
	'alert:update': 'Edition des alertes',
	'alert:update:explanation': 'Mettre à jour les alertes',
	'alert:view': 'Vue des alertes',
	'alert:view:explanation': 'Voir la liste et le détail des alertes',
	'alert:remove': 'Archiver les alertes',
	'alert:remove:explanation': "Permet d'archiver les alertes",
	'alert:generate_intervention':
		"Déclenchement d'interventions depuis une alerte",
	'alert:generate_intervention:explanation':
		'Déclencher une intervention depuis une alerte pour améliorer son suivi',
	'alert:close': 'Clotûre des alertes',
	'alert:close:explanation': 'Indiquer une alerte comme traitée',
	'alert:delete': 'Suppression des alertes',
	'alert:delete:explanation': 'Supprimer les alertes',

	'invoice:manage': 'Gestion des dépenses',
	'invoice:manage:explanation': 'Créer, éditer, supprimer les dépenses',
	'invoice:view': 'Vue des dépenses',
	'invoice:view:explanation':
		'Voir la liste et le détail des dépenses liées aux véhicules',
	'invoice:remove': 'Suppression des dépenses',
	'invoice:remove:explanation': 'Supprimer les dépenses',

	'product:manage': 'Gestion du catalogue prestations',
	'product:manage:explanation':
		'Créer, éditer, supprimer les prestations du catalogue',
	'product:remove': 'Suppression des prestations',
	'product:remove:explanation': 'Supprimer les prestations',

	'product_base:manage': 'Gestion du catalogue gammes',
	'product_base:manage:explanation':
		'Créer, éditer, supprimer les gammes du catalogue',

	'movement:view': 'Vue des entrées sorties',
	'movement:view:explanation': 'Voir les entrées sorties des véhicules',
	'movement:manage': 'Edition des entrées sorties',
	'movement:manage:explanation':
		'Mettre a jour les entrée sorties des véhicules',

	'document:manage': 'Gestion des documents',
	'document:manage:explanation': 'Créer, éditer, supprimer les documents',
	'document:view': 'Vue des documents',
	'document:view:explanation':
		'Voir la liste et le détail des documents liées aux véhicules',
	'document:remove': 'Suppression des documents',
	'document:remove:explanation': 'Supprimer les documents',
}
