import React from 'react'

import catalogueProductSideNavModalValidationSchema from './catalogueProductSideNavModalValidationSchema'
import CatalogueProductSideNavModalInfos from './CatalogueProductSideNavModalInfos'

const catalogueProductSideNavModalConfig = [
	{
		component: <CatalogueProductSideNavModalInfos />,
		schema: catalogueProductSideNavModalValidationSchema,
	},
]

export default catalogueProductSideNavModalConfig
