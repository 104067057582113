/* eslint-disable @typescript-eslint/no-unused-vars */
import { EFormModalMode } from '../../../../utils/app-models'
import { getTeamsAndUsersListWithManagerById } from '../../../../utils/utils'

export const formatTeamModalEditDefaultValues = ({ data }) => {
	const { members, networks, sites, name } = data
	const formatedMembers = {}

	members.forEach((member) => {
		formatedMembers[member?.id] = true
		formatedMembers[`isManager-${member?.id}`] = member?.isManager || false
	})

	return {
		name,
		sites: sites?.collection?.reduce((accumulator, site) => {
			accumulator[site.id] = { checked: true, value: site }
			return accumulator
		}, {}),
		networks: networks?.collection?.reduce((accumulator, network) => {
			accumulator[network.id] = { checked: true, value: network }
			return accumulator
		}, {}),
		...formatedMembers,
	}
}

export const formatTeamModalOnValidateData = ({
	data,
	mode,
	editedTeamId,
	currentUser,
}) => {
	const specificModeFields =
		mode === EFormModalMode.edit
			? {
					id: editedTeamId,
			  }
			: {
					account: currentUser?.account?.id,
					isActive: true,
			  }

	const { sites, networks, name } = data

	return {
		...specificModeFields,
		name,
		sites: Object.values(sites || {})
			.filter(({ checked }) => checked)
			.map(({ value }) => value?.id),
		networks: Object.values(networks || {})
			.filter(({ checked }) => checked)
			.map(({ value }) => value?.id),
		members: getTeamsAndUsersListWithManagerById(data, '/api/users/'),
	}
}
