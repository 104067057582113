import React, { useState, useCallback } from 'react'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import LoadingButton from '@mui/lab/LoadingButton'
import { Stack, Typography } from '@mui/material'

import { CapchaGoogle, InputText } from '../../components'
import { useFormatMessage } from '../../utils/hooks'
import api from '../../api/axios/axios'
import toast from 'react-hot-toast'
import { ForgottenPasswordFormRequestSchema } from '../../utils/validationSchema'

interface IForgottenPasswordFormRequest {
	setRequestSend
}

const ForgottenPasswordFormRequest: React.FC<IForgottenPasswordFormRequest> = ({
	setRequestSend,
}) => {
	const intlMsg = useFormatMessage()

	const actualEmail: any = localStorage.getItem('lostPasswordEmail') || ''
	const [loading, setLoading] = useState(false)
	const [capchaValid, setCapchaValid] = useState(false)

	const { handleSubmit, control } = useForm({
		resolver: yupResolver(ForgottenPasswordFormRequestSchema(intlMsg)),
		defaultValues: { email: actualEmail },
	})

	const handleRequestNewPassword = useCallback((data) => {
		setLoading(true)
		api
			.post('/_ctrl/forgotten-password', { email: data.email })
			.then(() => {
				setLoading(false)
				localStorage.setItem('lostPasswordEmail', '')
				setRequestSend(true)
			})
			.catch((error) => {
				toast.error(intlMsg(error.message))
				setLoading(false)
			})
	}, [])

	return (
		<Stack p={8} spacing={2} justifyContent='center' sx={{ height: 1 }}>
			<Typography variant='h1'>{intlMsg('user.lostPasswordTitle')}</Typography>
			<Typography variant='body2'>
				{intlMsg('user.lostPasswordText')}
			</Typography>

			<form onSubmit={handleSubmit((data) => handleRequestNewPassword(data))}>
				<Stack spacing={2} mt={2}>
					<InputText
						key='email'
						name='email'
						label={intlMsg('misc.email')}
						type='mail'
						valuesDefault={actualEmail}
						control={control}
					/>
					<CapchaGoogle setCapchaValid={setCapchaValid} />

					<LoadingButton
						variant='contained'
						type='submit'
						loading={loading}
						disabled={!capchaValid}
					>
						{intlMsg('misc.validate')}
					</LoadingButton>
				</Stack>
			</form>
		</Stack>
	)
}

export default ForgottenPasswordFormRequest
