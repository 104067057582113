import React, { useCallback, useContext, useMemo } from 'react'

import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Typography from '@mui/material/Typography'
import {
	Stack,
	StepConnector,
	StepIconProps,
	stepConnectorClasses,
	styled,
} from '@mui/material'
import { grey } from '@mui/material/colors'

import { ViewModalContentContext } from '../../../utils/contexts/ViewModalContentContext'
import { dayjsLocalizedFormatMethod } from '../../../utils/date'
import { useFormatMessage } from '../../../utils/hooks'
import CustomLink from '../../_overrideMui/Link/CustomLink'

const hourCSS = { color: grey[700], lineHeight: 1.25 }
const plannedCSS = { color: grey[500] }

const QontoConnectorLeft = styled(StepConnector)(() => ({
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: '#4CAF50',
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: '#4CAF50',
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0,
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		borderColor: '#eaeaf0',
		borderTopWidth: 4,
		borderRadius: 60,
	},
}))

const QontoConnectorRight = styled(StepConnector)(() => ({
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: '#e2e2e2',
			borderTopWidth: 4,
			borderRadius: 60,
			borderRadiusLeft: 0,
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: '#4CAF50',
			borderTopWidth: 4,
			borderRadius: 0,
			borderTopRightRadius: 60,
			borderBottomRightRadius: 60,
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		borderColor: '#eaeaf0',
		borderTopWidth: 4,
		borderTopRightRadius: 60,
		borderBottomRightRadius: 60,
	},
}))

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
	({ theme, ownerState }) => ({
		color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
		display: 'flex',
		height: 22,
		alignItems: 'center',
		...(ownerState.active && { color: '#5cb85d5e' }),
		'& .QontoStepIcon-completedIcon': {
			color: '#4CAF50',
			zIndex: 1,
			fontSize: 24,
		},
		'& .QontoStepIcon-circle': {
			zIndex: 1,
			fontSize: 24,
		},
	})
)

const QontoStepIcon = (props: StepIconProps) => {
	const { active, completed, className } = props

	return (
		<QontoStepIconRoot ownerState={{ active }} className={className}>
			{completed ? (
				<Box component='img' src='/icon/stepOn.svg' />
			) : (
				<Box component='img' src='/icon/stepOff.svg' />
			)}
		</QontoStepIconRoot>
	)
}

const cardStepCss = {
	p: 2,
	backgroundColor: '#f9f9f9',
	borderRadius: 2,
	display: 'flex',
	justifyContent: 'center',
}

interface WTransportTripDateSteps {
	removal
	delivery
	forms
}

const WTransportTripDateSteps: React.FC<WTransportTripDateSteps> = ({
	removal,
	delivery,
	forms,
}) => {
	const intlMsg = useFormatMessage()
	const { viewModalData } = useContext(ViewModalContentContext) || {}

	const configSteps = useMemo(() => {
		const dayjs = dayjsLocalizedFormatMethod()

		const formattedDeliveryTimeFrom = dayjs(delivery?.timeFrom).format('LT')
		const formattedDeliveryTimeTo = delivery?.timeTo
			? dayjs(delivery?.timeTo).format('LT')
			: null

		const formattedRemovalTimeFrom = dayjs(removal?.timeFrom).format('LT')
		const formattedRemovalTimeTo = removal?.timeTo
			? dayjs(removal?.timeTo).format('LT')
			: null

		return [
			{
				label: 'Création du transport',
				date: dayjs(viewModalData.dateCreated).format('ll'),
				hour: dayjs(viewModalData.dateCreated).format('LT'),
			},

			{
				label: 'Enlèvement',
				date: removal?.completionDate
					? dayjs(removal.completionDate).format('ll')
					: dayjs(removal.date).format('ll'),
				hour: removal?.completionDate
					? dayjs(removal.completionDate).format('LT')
					: `${formattedRemovalTimeFrom} ${
							formattedRemovalTimeTo ? ' - ' + formattedRemovalTimeTo : ''
					  }`,
				isPlanned: !removal?.completionDate,
				geolocation: forms[0]?.geolocation,
			},

			{
				label: 'Livraison',
				date: delivery?.completionDate
					? dayjs(delivery.completionDate).format('ll')
					: dayjs(delivery.date).format('ll'),
				hour: delivery?.completionDate
					? dayjs(delivery.completionDate).format('LT')
					: `${formattedDeliveryTimeFrom} ${
							formattedDeliveryTimeTo ? ' - ' + formattedDeliveryTimeTo : ''
					  }`,
				isPlanned: !delivery?.completionDate,
				geolocation: forms[1]?.geolocation,
			},
		]
	}, [viewModalData.dateCreated, removal, delivery, forms])

	const activeStep = useMemo(() => {
		if (delivery.completionDate) return 3
		else if (removal.completionDate) return 2
		else return 1
	}, [removal.completionDate, delivery.completionDate])

	const openLink = useCallback(
		(geolocation) => () => {
			window.open(
				`https://www.google.com/maps/?q=${geolocation?.latitude},${geolocation?.longitude}`,
				'_blank'
			)
		},
		[]
	)

	const renderConfigStep = useCallback(
		(step) => (
			<Step key={step.label}>
				<StepLabel StepIconComponent={QontoStepIcon}>
					<Stack>
						<Box>
							<Typography variant='text2' fontWeight='500'>
								{step.label}
							</Typography>
						</Box>
						<Stack direction='row' alignItems='baseline'>
							{step?.isPlanned && (
								<Typography variant='caption' sx={plannedCSS} mr={0.5}>
									{intlMsg('misc.planned')}
								</Typography>
							)}

							<Typography variant='text1' sx={hourCSS}>
								{step?.date}
								<Box component='span' fontWeight={500} ml={1}>
									{step?.hour}
								</Box>
							</Typography>
						</Stack>
						{step?.geolocation?.latitude && (
							<CustomLink
								variant='text1'
								variantlink='primaryLink'
								sx={{ color: grey[700] }}
								onMouseDown={openLink(step.geolocation)}
							>
								{intlMsg('misc.localisation')}
							</CustomLink>
						)}
					</Stack>
				</StepLabel>
			</Step>
		),
		[]
	)

	return (
		<Box width='100%' sx={cardStepCss}>
			<Box width='100%'>
				<Stepper
					activeStep={activeStep}
					connector={
						<>
							<QontoConnectorLeft />
							<QontoConnectorRight />
						</>
					}
				>
					{configSteps.map(renderConfigStep)}
				</Stepper>
			</Box>
		</Box>
	)
}

export default WTransportTripDateSteps
