/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import {
	gql,
	OperationVariables,
	QueryHookOptions,
	useLazyQuery,
	useQuery,
} from '@apollo/client'
import {
	commonUserAttributes,
	listUserAttributes,
	userTypeAttributes,
	userInfosSlidePanel,
} from '../attributes/usersAttributes'

/**  ---------------------QUERIES---------------------- */

export const GET_PROFILE_USER = gql`
query getProfileUser(
    $id: ID
) {
    profileUser(id: $id) {
        ${commonUserAttributes}
    }
}`

export const GET_PROFILE_USER_ID = gql`
	query getProfileUserId($id: ID) {
		profileUser(id: $id) {
			id
		}
	}
`

export const GET_PROFILE_USER_SLIDE_PANEL = gql`
query getProfileUser(
    $id: ID
) {
    profileUser(id: $id) {
        ${userInfosSlidePanel}
    }
}`

export const GET_PROFILE_USER_TEAM_SLIDE_PANEL = gql`
	query getProfileUser($id: ID) {
		profileUser(id: $id) {
			id
			teams
		}
	}
`

export const GET_PROFILE_USER_PERMISSION = gql`
	query getProfileUserPermission($id: ID) {
		profileUser(id: $id) {
			id
			permissions {
				id
				name
				category
			}
		}
	}
`

export const USERS_FILTER_LIST = gql`
query getUsersList(
    $page: Int
    $itemsPerPage: Int
    $searchText: String
    $isActive: Boolean
	$orderBy: [UserFilter_orderBy]
) {
    users(page: $page, itemsPerPage: $itemsPerPage, searchText: $searchText, isActive: $isActive, orderBy: $orderBy) {
        ${listUserAttributes}
    }
}`

export const USERS_FILTER_LIST_FOR_MODAL = gql`
	query getUsersList(
		$page: Int
		$itemsPerPage: Int
		$searchText: String
		$isActive: Boolean
	) {
		users(
			page: $page
			itemsPerPage: $itemsPerPage
			searchText: $searchText
			isActive: $isActive
		) {
			collection {
				id
				firstName
				lastName
			}
		}
	}
`

export const USERS_NUMBERS = gql`
	query getUsersNumbers($searchText: String, $isActive: Boolean) {
		users(
			page: 1
			itemsPerPage: 100000
			searchText: $searchText
			isActive: $isActive
		) {
			paginationInfo {
				totalCount
			}
		}
	}
`

export const GET_USER_PERMISSIONS = gql`
	query getUser($id: ID!) {
		user(id: $id) {
			permissions(itemsPerPage: 99999) {
				collection {
					id
					name
					ref
					category
					description
					_id
					options
				}
			}
		}
	}
`

export const GET_USER_TYPES = gql`
	query getUserTypes {
		userTypes {
			${userTypeAttributes}
		}
	}
`

export const USERS_FOR_SELECT = gql`
	query getUsersFormSelect {
		users(page: 1, itemsPerPage: 100000, isActive: true) {
			collection {
				id
				lastName
				firstName
			}
		}
	}
`

export const GET_USER_MODAL = gql`
	query getUserModal($id: ID) {
		profileUser(id: $id) {
			id
			canLogin
			email
			firstName
			lastName
			phone
			cellphone
			isActive
			job
			permissions {
				id
			}
			userType {
				id
				name
				permissions {
					id
					name
					category
				}
			}
			teams
		}
	}
`

export const GET_PROFILE_USER_MODAL = gql`
	query getUserModal($id: ID) {
		profileUser(id: $id) {
			id
			email
			firstName
			lastName
			phone
			cellphone
			job
		}
	}
`

export const EXPORT_USERS = gql`
	query exportUsers(
		$isActive: Boolean
		$searchText: String
		$timezone: Int
		$orderBy: [UserFilter_orderBy]
	) {
		exportUsers(
			isActive: $isActive
			searchText: $searchText
			timezone: $timezone
			orderBy: $orderBy
		) {
			id
			exportFile
		}
	}
`

/**  ---------------------HOOKS---------------------- */

export const useProfileUser = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { loading, data } = useQuery(GET_PROFILE_USER, options)
	return { loading, data: data?.profileUser || null }
}

export const useProfileUserSlidePanel = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { loading, data } = useQuery(GET_PROFILE_USER_SLIDE_PANEL, options)
	return { loading, data: data?.profileUser || null }
}

export const useProfileUserTeamSlidePanel = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { loading, data } = useQuery(GET_PROFILE_USER_TEAM_SLIDE_PANEL, options)
	return { loading, data: data?.profileUser || null }
}

export const useProfileUserPermissions = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { loading, data } = useQuery(GET_PROFILE_USER_PERMISSION, options)
	return {
		loading,
		userPermissions: data?.profileUser?.permissions || [] || null,
	}
}

export const useUserTypes = () => {
	const { loading, data } = useQuery(GET_USER_TYPES)
	return { loading, userTypes: data?.userTypes || null }
}

export const useUsersForSelect = () => {
	const { loading, data } = useQuery(USERS_FOR_SELECT)

	return { users: data?.users?.collection || null, loading }
}

export const useLazyProfileUserId: any = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(
		GET_PROFILE_USER_ID,
		options
	)

	return {
		loadingProfileUserId: loading,
		profileUserId: data?.profileUser?.id || null,
		getProfileUserId: getData,
	}
}

export const useLazyUserModal: any = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(GET_USER_MODAL, options)

	return {
		loading,
		user: data?.profileUser || null,
		getUserModal: getData,
	}
}

export const useLazyProfileUserModal: any = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(
		GET_PROFILE_USER_MODAL,
		options
	)

	return {
		loading,
		user: data?.profileUser || null,
		getUserModal: getData,
	}
}

export const useExportUsers: any = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { data, loading, error } = useQuery(EXPORT_USERS, options)

	return {
		exportFile: data?.exportUsers?.[0]?.exportFile || null,
		loading,
		error,
	}
}
