import React, { useMemo } from 'react'

import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

interface ISwitchButton {
	value: string
	onChange?: any
	config: {
		primary: {
			text: string
			value: any
		}
		secondary: {
			text: string
			value: any
		}
	}
	fullWidth?: boolean
}

const SwitchButton: React.FC<ISwitchButton> = ({
	config,
	onChange,
	value,
	fullWidth = true,
}) => {
	const toggleBtnCSS: any = useMemo(
		() => (fullWidth ? {} : { padding: '5px 30px' }),
		[]
	)

	return (
		<ToggleButtonGroup
			exclusive
			onChange={onChange}
			size='small'
			value={value}
			color='primary'
			fullWidth={fullWidth}
		>
			<ToggleButton value={config?.primary?.value} sx={toggleBtnCSS}>
				{config?.primary?.text}
			</ToggleButton>
			<ToggleButton value={config?.secondary?.value} sx={toggleBtnCSS}>
				{config?.secondary?.text}
			</ToggleButton>
		</ToggleButtonGroup>
	)
}

export default SwitchButton
