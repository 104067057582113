import React from 'react'

import { Stack, Popover } from '@mui/material'

import DateRangeFilter from './filters/DateRangeFilter'
import PeriodFilter from './filters/PeriodFilter'
import { EPeriodFilter } from '../../../../utils/app-models'

interface IFilterDatePopover {
	handleClose
	open
	anchorEl
	setValueType
	valueType
	setFiltersQuerieParam
	filtersQuerieParam
	localStorageKeyFilter
	customFilterDateConfig
	argumentQuerieName?
	setLocalStorageOnchange?: boolean
}

const FilterDatePopover: React.FC<IFilterDatePopover> = ({
	handleClose,
	open,
	anchorEl,
	setValueType,
	valueType,
	setFiltersQuerieParam,
	filtersQuerieParam,
	localStorageKeyFilter,
	customFilterDateConfig,
	argumentQuerieName,
	setLocalStorageOnchange,
}) => {
	return (
		<Popover open={open} anchorEl={anchorEl} onClose={handleClose}>
			<Stack direction='row'>
				<PeriodFilter
					handleClose={handleClose}
					setValueType={setValueType}
					valueType={valueType}
					setFiltersQuerieParam={setFiltersQuerieParam}
					filtersQuerieParam={filtersQuerieParam}
					localStorageKeyFilter={localStorageKeyFilter}
					customFilterDateConfig={customFilterDateConfig}
					argumentQuerieName={argumentQuerieName}
					setLocalStorageOnchange={setLocalStorageOnchange}
				/>

				{EPeriodFilter.customPeriod === valueType && (
					<DateRangeFilter
						setValueType={setValueType}
						valueType={valueType}
						setFiltersQuerieParam={setFiltersQuerieParam}
						filtersQuerieParam={filtersQuerieParam}
						localStorageKeyFilter={localStorageKeyFilter}
						argumentQuerieName={argumentQuerieName}
						setLocalStorageOnchange={setLocalStorageOnchange}
						handleClose={handleClose}
					/>
				)}
			</Stack>
		</Popover>
	)
}

export default FilterDatePopover
