/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'

import { useBulkAlertAction } from '../../../api/gql/mutations/alertesMutation'
import {
	LIST_ALERTS,
	useExportAlerts,
} from '../../../api/gql/queries/alertsQueries'
import listAlertsViewTable from '../../../components/tableV2/config/dashboard/alerts/listAlertsViewTable'
import { EPermissionScope } from '../../../utils/app-models'
import {
	ITableToolBarActionConfig,
	ITableToolBarConfig,
} from '../../../utils/typescript/tableType'
import AlertsFilters from './alertsFilters'
import { alertsDefaultFiltersValues } from './alertsFilters/alertsDefaultFiltersConfig'



////////////////////////////////////////////////////////////////
// Global variable
////////////////////////////////////////////////////////////////

const localStorageKeyFilter = 'listAlertsViewTableFilters'

const queryConfig = {
	query: LIST_ALERTS,
	queryName: 'alerts',
}

const refreshQuerie = ['getAlerts']

const deletedBulk = {
	mutation: useBulkAlertAction,
	operation: 'remove',
	successMessageDelete: {
		singular: 'success.alertDeleted',
		plural: 'success.alertsDeleted',
	},
	messageConfirm: {
		title: 'confirmModal.deleteAlertTitle',
		message: 'confirmModal.deleteAlertMessage',
	},
	deletePermissions: [
		EPermissionScope.alertDelete,
		EPermissionScope.alertRemove,
	],
}

const tableToolBarActionConfig: ITableToolBarActionConfig = {
	refreshQuerie,
	deletedBulk,
}

const tableToolBarConfig: ITableToolBarConfig = {
	title: 'misc.alerts',
	stickyToolBar: true,
	tableConfig: listAlertsViewTable,
	localStorageKeyFilter,
	localStorageName: 'listAlertsViewTable',
	exportConfig: { exportQuery: useExportAlerts },
	queryConfig,
}

const tableContainerConfig = {
	tableConfig: listAlertsViewTable,
	queryConfig,
}

const defaultFiltersConfig = {
	componentsFiltersConfig: <AlertsFilters />,
	defaultFiltersValues: alertsDefaultFiltersValues,
	localStorageKeyFilter,
}

export const alertsConfigTable = {
	tableToolBarConfig,
	tableToolBarActionConfig,
	tableContainerConfig,
	defaultFiltersConfig,
}
