export const commonUserAttributes = `
    id
    userType{
        id
        name
    }
    displayedId
    firstName
    lastName
    email
    phone
    cellphone
    job
    teams
    lastLogin
    demandOfServiceExtLink
    notificationEmailPreferences(itemsPerPage: 99999) {
        collection {
            id
            name
            category
            listOrder
        }
    }
    notificationAppPreferences(itemsPerPage: 99999) {
        collection {
            id
            name
            category
            listOrder
        }
    }
    accountModules
    account {
        id
        name
        siret
        address
        city
        zipcode
        adminPhone
        adminCellphone
        accountType{
            id
            code
        }
        networks{
           collection{
                    id
                    name
                }
        }
    }
    permissions {
        id
        name
    }
`

export const userTypeAttributes = `
    id
    name
    permissions {
        id
        name
        description
        category
    }
    defaultPermissions {
        id
    }
`

export const listUserAttributes = `
    collection {
        id
        firstName
        lastName
        email
        phone
        cellphone
        canLogin
        canLoginApp
        isActive
        job
        dateCreated
        lastLogin
        account {
            id
            name
        }
        userType {
            id
            name
        }
        teams
    }
    paginationInfo {
        totalCount
    }
`

export const userPayloadAttributes = `
    user {
        id
        firstName
        lastName
        email
        phone
        cellphone
        job
    }
`
export const userPossiblePermissions = `
    collection {
        id
        _id
        name
        description
        category
    }
`

export const deletePayloadAttributes = `
    user {
        id
    }
`

export const userInfosSlidePanel = `
    id
    displayedId
    firstName
    lastName
    email
    phone
    cellphone
    job
    lastLogin
    userType {
        id
        name
    }
    account {
        id
    }
`
