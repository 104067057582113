import dayjs from 'dayjs'
import { EPeriodFilter } from '../../../../../utils/app-models'

export const dashBoardPeriodDefaultValue = {
	period: EPeriodFilter.today,
	date: {
		dateFrom: [
			{
				before: dayjs().endOf('day').toDate(),
				after: dayjs().startOf('day').toDate(),
			},
		],
	},
}
