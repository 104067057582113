import React, { useMemo, memo } from 'react'
import { IconButton, Stack, Typography, Chip } from '@mui/material'
import UpdateIcon from '@mui/icons-material/Update'
import CloseIcon from '@mui/icons-material/Close'
import { green } from '@mui/material/colors'
import { useFormatMessage } from '../../../utils/hooks'
import PermissionsHandler from '../../permissionsHandler'
import UserAvatar from '../../avatar/UserAvatar'
import FormBtnModal from '../../forms/formModalBtn'
import CustomerContactModal from '../../../view/dashboard/customerContacts/customerContactModal'
import UserModal from '../../../view/administration/users/userModal'
import { EFormModalMode, EPermissionScope } from '../../../utils/app-models'
import { timePostAgo } from '../../../utils/date'

const sxStyles = {
	backgroundColor: ({ palette }) => palette.primary.dark,
	chip: {
		backgroundColor: green[500],
		color: 'white',
	},
	typographyWhite: {
		color: 'white',
		textAlign: 'center',
	},
	row: {
		justifyContent: 'space-between',
		alignItems: 'center',
		p: 2,
	},
	centeredStack: {
		justifyContent: 'center',
		alignItems: 'center',
		mt: 1,
	},
}

interface ISlidePanelUserInfosHeader {
	lastName?: string
	firstName?: string
	role?: string
	lastLogin?: string
	onClose: () => void
	formatId?: string
	isContact?: boolean
	isCustomerResp?: boolean
	isSiteResp?: boolean
	id?: string
	dataUser?: any
}

const SlidePanelUserInfosHeader: React.FC<ISlidePanelUserInfosHeader> = ({
	lastName,
	firstName,
	role,
	lastLogin,
	onClose,
	formatId,
	isContact,
	isCustomerResp,
	isSiteResp,
	id,
	dataUser,
}) => {
	const intlMsg = useFormatMessage()
	const slidePaneltype = useMemo(
		() => (isContact ? intlMsg('misc.contact') : intlMsg('misc.user')),
		[isContact]
	)

	const permissions = useMemo(
		() =>
			isContact
				? [EPermissionScope.contactManage]
				: [EPermissionScope.userManage],
		[isContact]
	)

	const formBtnModal = useMemo(
		() =>
			isContact ? (
				<CustomerContactModal modalUtils={{} as any} contactId={id} />
			) : (
				<UserModal modalUtils={{} as any} editedUserId={dataUser?.id} />
			),
		[isContact, dataUser, id]
	)

	return (
		<Stack sx={sxStyles}>
			<Stack direction='row' {...sxStyles.row}>
				<Stack direction='row' spacing={1}>
					<Chip label={slidePaneltype} sx={sxStyles.chip} size='small' />
					{isCustomerResp && (
						<Chip
							label={intlMsg('misc.referentAccount')}
							color='primary'
							size='small'
						/>
					)}
					{isSiteResp && (
						<Chip
							label={intlMsg('misc.referentSite')}
							color='primary'
							size='small'
						/>
					)}
					{!isContact && (
						<Chip
							label={intlMsg(`userTypes.${role}`)}
							color='primary'
							size='small'
						/>
					)}
				</Stack>

				<Stack direction='row' spacing={1}>
					<PermissionsHandler permissionsName={permissions}>
						<FormBtnModal
							modal={formBtnModal}
							mode={EFormModalMode.edit}
							onlyIcon
							color='white'
							playwrightId='Playwright-SlidePanelUserInfosHeader-FormBtnModal'
						/>
					</PermissionsHandler>

					<IconButton
						onClick={onClose}
						size='small'
						sx={{ color: 'white' }}
						id='Playwright-SlidePanelUserInfosHeader-CloseIcon'
					>
						<CloseIcon />
					</IconButton>
				</Stack>
			</Stack>

			<Stack {...sxStyles.centeredStack}>
				<UserAvatar userName={{ firstName, lastName }} size='large' />

				<Typography variant='h4' sx={sxStyles.typographyWhite}>
					{firstName} {lastName}
				</Typography>

				<Typography variant='caption' sx={sxStyles.typographyWhite}>
					{formatId ? `ID : ${formatId}` : ''}
				</Typography>
			</Stack>

			<Stack
				direction='row'
				justifyContent='flex-end'
				alignItems='center'
				p={2}
			>
				<Stack direction='row' {...sxStyles.row}>
					<UpdateIcon fontSize='small' sx={sxStyles.typographyWhite} />
					<Typography variant='caption' sx={sxStyles.typographyWhite}>
						{lastLogin ? timePostAgo(lastLogin) : intlMsg(`user.neverLog`)}
					</Typography>
				</Stack>
			</Stack>
		</Stack>
	)
}

export default memo(SlidePanelUserInfosHeader)
