/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { FiltersContext } from './filtersContext'
import { getIntervalDateByPeriod } from '../../utils/date'

interface IFiltersProvider {
	children: any
	filtersConfig: any
}

const FiltersProvider: React.FC<IFiltersProvider> = ({
	children,
	filtersConfig,
}) => {
	const {
		defaultFiltersValue,
		localStorageKeyFilter,
		componentsFiltersConfig,
	} = filtersConfig || {}
	const [filters, setFilters] = useState(defaultFiltersValue || {})

	const setFilter = useCallback(
		(newFilter: any) => {
			setFilters({ ...filters, newFilter })
		},
		[filters]
	)

	const resetFilters = useCallback(() => {
		const savedValues: any = {}
		if (filters?.period) {
			savedValues.period = filters?.period
			savedValues.endTo = filters?.endTo
			savedValues.startFrom = filters?.startFrom
		}
		setFilters({
			...defaultFiltersValue,
			...savedValues,
		})
	}, [filters])

	//get stored filters values
	useEffect(() => {
		const storedFilters = JSON.parse(
			localStorage.getItem(localStorageKeyFilter) as string
		)
		// updates local storage dates based on user's selected period to match the actual interval
		// ex: period thisWeek but interval bates stored a month ago => update interval with current week
		const { period } = storedFilters || defaultFiltersValue || {}

		if (period)
			setFilters({
				...defaultFiltersValue,
				...storedFilters,
				...getIntervalDateByPeriod(period),
			})
	}, [])

	useEffect(() => {
		localStorage.setItem(localStorageKeyFilter, JSON.stringify(filters))
	}, [filters])

	const context = useMemo(
		() => ({
			defaultFiltersValue,
			localStorageKeyFilter,
			componentsFiltersConfig,
			filters,
			setFilters,
			setFilter,
			resetFilters,
		}),
		[filters, setFilter]
	)

	return (
		<FiltersContext.Provider value={context}>
			{children}
		</FiltersContext.Provider>
	)
}

export default FiltersProvider
