import React, { useMemo } from 'react'

import { Box, Stack, Typography } from '@mui/material'
import { dayjsLocalizedFormatMethod } from '../../../../utils/date'

const typoCSS = { lineHeight: '1.4' }

const formatDate = (dateString: string | null, format: string): string =>
	dateString ? dayjsLocalizedFormatMethod()(dateString).format(format) : ''

interface IDataListPeriod {
	dateStart: any
	dateEnd: any
}

const DataListPeriod: React.FC<IDataListPeriod> = ({ dateStart, dateEnd }) => {
	const formattedPeriod = useMemo(() => {
		return {
			// start ---------------------------------------
			startDate: formatDate(dateStart?.date, 'L'),
			startTimeFrom: formatDate(dateStart?.timeFrom, 'LT'),
			startTimeTo: formatDate(dateStart?.timeTo, 'LT'),
			// end ---------------------------------------
			endDate: formatDate(dateEnd?.date, 'L'),
			endTimeFrom: formatDate(dateEnd?.timeFrom, 'LT'),
			endTimeTo: formatDate(dateEnd?.timeTo, 'LT'),
		}
	}, [dateStart, dateEnd])

	const formattedStartTime = useMemo(() => {
		return `${formattedPeriod.startTimeFrom} ${
			formattedPeriod?.startTimeTo ? `- ${formattedPeriod.startTimeTo}` : ''
		}`
	}, [formattedPeriod])

	const formattedEndTime = useMemo(() => {
		return `${formattedPeriod.endTimeFrom} ${
			formattedPeriod?.endTimeTo ? `- ${formattedPeriod.endTimeTo}` : ''
		}`
	}, [formattedPeriod])

	return (
		<Stack direction='row' alignItems='center'>
			<Stack alignItems='center' mr={0.5}>
				<img src='/icon/period.svg' />
			</Stack>

			<Stack>
				<Typography noWrap variant='text1Bold' sx={typoCSS}>
					<Box component='span' mr={0.5}>
						{formattedPeriod.startDate}
					</Box>
					<Box component='span'>{formattedStartTime}</Box>
				</Typography>

				<Typography noWrap variant='text1Bold' sx={typoCSS}>
					<Box component='span' mr={0.5}>
						{formattedPeriod.endDate}
					</Box>
					<Box component='span'>{formattedEndTime}</Box>
				</Typography>
			</Stack>
		</Stack>
	)
}

export default DataListPeriod
