import { blockForm } from './blockForm'
import { blockLastAlerts } from './blockLastAlerts'
import { blockSignature } from './blockSignature'
import { blocksAllRecords } from './blocksAllRecords'
import { blocksAdditionalFooter } from './blocksAdditionalFooter'
import { blockFormTransport } from './transport/blockFormTransport'
import { blockFormControl } from './control/blockFormControl'

export const blocksPDFtransport = {
	blockForm: blockForm,
	blockTransport: blockFormTransport,
	blockSignature: blockSignature,
	blockLastAlerts: blockLastAlerts,
	blocksAllRecords: blocksAllRecords,
	blocksAdditionalFooter: blocksAdditionalFooter,
}
export const blocksPDFcontrol = {
	blockForm: blockForm,
	blockControl: blockFormControl,
	blockSignature: blockSignature,
	blockLastAlerts: blockLastAlerts,
	blocksAllRecords: blocksAllRecords,
	blocksAdditionalFooter: blocksAdditionalFooter,
}
export const blocksPDFservice = {
	blockForm: blockForm,
	blockControl: blockFormControl,
	blockSignature: blockSignature,
	blockLastAlerts: blockLastAlerts,
	blocksAllRecords: blocksAllRecords,
	blocksAdditionalFooter: blocksAdditionalFooter,
}
