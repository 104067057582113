import React from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { Stack, IconButton, Typography } from '@mui/material'
import { useFormatMessage } from '../../../utils/hooks'

interface IHeaderStepperModal {
	modalName: string
	handleOnClose
}

const HeaderStepperModal: React.FC<IHeaderStepperModal> = ({
	modalName,
	handleOnClose,
}) => {
	const intlMsg = useFormatMessage()

	return (
		<Stack
			direction='row'
			justifyContent='space-between'
			alignItems='center'
			sx={{ width: '100%', p: 3 }}
		>
			<Typography variant='h2'>{intlMsg(modalName)}</Typography>

			<IconButton
				aria-label='close'
				size='medium'
				sx={{
					position: 'absolute',
					right: ({ spacing }) => spacing(2),
				}}
				onClick={handleOnClose}
			>
				<CloseIcon fontSize='inherit' />
			</IconButton>
		</Stack>
	)
}

export default HeaderStepperModal
