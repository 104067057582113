import React, { useContext, useEffect, useState } from 'react'

import { Box } from '@mui/material'

import { AuthContext } from '../../../utils/contexts/AuthContext'
import { EModule } from '../../../utils/app-models'
import CustomerContacts from './CustomerContacts'

interface ICustomerContactsContainer {}

const CustomerContactsContainer: React.FC<ICustomerContactsContainer> = () => {
	const { checkModules } = useContext(AuthContext)
	const [hasAccess, setHasAccess] = useState(false)
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		setHasAccess(checkModules([EModule.crm]))
		setLoading(false)
	}, [])

	//todo maybe improve this
	if (loading) return <></>

	return (
		<>
			{hasAccess ? (
				<CustomerContacts />
			) : (
				<Box sx={{ p: 2 }}>HERE PUT ILLU</Box>
			)}
		</>
	)
}

export default CustomerContactsContainer
