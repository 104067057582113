/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/display-name */
import React from 'react'

import { Chip, Stack } from '@mui/material'

import {
	useRemoveIntervention,
	useUpdateInterventionStatuses,
} from '../../../../../../api/gql/mutations/interventionsMutation'
import { useInterventionStatusesList } from '../../../../../../api/gql/queries/interventionsQueries'
import DateToFrom from '../../../../../date/DateToFrom'
import ActionPopoverBtn from '../../../../../popover/actionPopover'
import TasksAndCategoriesPopover from '../../../../../multiItemsPopoverList/popover/tasksAndCategoriesPopover'
import OpenLinkCell from '../../../../tableCell/OpenLinkCell'
import { ROUTES } from '../../../../../../routes/consts'
import {
	EAccountType,
	EModule,
	EPermissionScope,
} from '../../../../../../utils/app-models'
import { formatDate } from '../../../../../../utils/date'
import ChipCountListPopover from '../../../../../multiItemsPopoverList/popover/simpleListPopover'
import StatusVehicle from '../../../../tableCell/StatusVehicle'
import InterventionType from '../../../../tableCell/InterventionType'

import ActionsBtn from '../../../../tableCell/actionsBtn'
import InterventionModal from '../../../../../../view/dashboard/interventions/interventionControl/interventionModal'
import { vmLinkByInterventionType } from '../../../../../../routes/linkViewModal'
import UsersPopover from '../../../../../multiItemsPopoverList/popover/usersPopover'
import UserAvatarSlidePanel from '../../../../../avatar/UserAvatarSlidePanel'
import FormsListPopover from '../../../../../multiItemsPopoverList/popover/formsListPopover'

const listInterventionsControlByVehicleViewTable = [
	{
		columnLabel: 'vehicle.immat',
		orderBy: 'vehicle_vehicleDetail_immat',
		stickyFirstCol: true,
		personalizedCell: ({ vehicle: { id, vehicleDetail } }) => (
			<>
				{vehicleDetail?.immat ? (
					<OpenLinkCell
						txt={vehicleDetail.immat}
						viewModalLink={`${ROUTES.VIEWMODAL.VEHICLES.url}${id}`}
					/>
				) : (
					'-'
				)}
			</>
		),
	},

	{
		columnLabel: 'vehicle.vin',
		orderBy: 'vehicle_vehicleDetail_vin',
		personalizedCell: ({ vehicle: { id, vehicleDetail } }) => (
			<>
				{vehicleDetail?.vin ? (
					<OpenLinkCell
						txt={vehicleDetail.vin}
						viewModalLink={`${ROUTES.VIEWMODAL.VEHICLES.url}${id}`}
					/>
				) : (
					'-'
				)}
			</>
		),
	},

	{
		columnLabel: 'vehicle.brand',
		orderBy: 'vehicle_vehicleDetail_vehicleBrand_name',
		dataKey: 'vehicle.vehicleDetail.vehicleBrand.name',
	},

	{
		columnLabel: 'vehicle.model',
		orderBy: 'vehicle_vehicleDetail_vehicleModelText',
		dataKey: 'vehicle.vehicleDetail.vehicleModelText',
	},

	{
		columnLabel: 'vehicle.category',
		// orderBy: '', todo wating back
		dataKey: 'vehicle.vehicleDetail.vehicleCategory.name',
	},

	{
		columnLabel: 'misc.account',
		modules: [EModule.crm],
		orderBy: 'intervention_orderingCustomer_name',
		personalizedCell: ({ intervention: { orderingCustomer } }) => (
			<OpenLinkCell
				viewModalLink={`${ROUTES.VIEWMODAL.CUSTOMERS.url}${orderingCustomer?.id}`}
				txt={orderingCustomer?.name}
				maxCharacters={40}
			/>
		),
	},

	{
		columnLabel: 'misc.site',
		orderBy: 'intervention_orderingSite_name',
		personalizedCell: ({ intervention: { orderingSite } }) => (
			<OpenLinkCell
				viewModalLink={`${ROUTES.VIEWMODAL.SITE.url}${orderingSite?.id}`}
				txt={orderingSite?.name}
				modules={[EModule.site]}
			/>
		),
	},

	{
		columnLabel: 'vehicle.km',
		orderBy: 'vehicleMileage_mileage',
		dataKey: 'vehicleMileage.mileage',
	},

	{
		columnLabel: 'interventions.task',
		orderBy: 'products_product_name',
		personalizedCell: ({ products: { collection } }) => (
			<TasksAndCategoriesPopover tasks={collection} />
		),
	},

	{
		columnLabel: 'misc.reportIntervention',
		personalizedCell: ({ forms, formTemplates }) => (
			<FormsListPopover
				text='misc.reportIntervention'
				data={{ forms, formTemplates }}
			/>
		),
	},

	{
		columnLabel: 'misc.alerts',
		personalizedCell: ({ countAlerts }) => (
			<Chip
				size='small'
				color={countAlerts === 0 ? 'default' : ('alert' as any)}
				disabled={countAlerts === 0 || false}
				label={countAlerts}
			/>
		),
	},

	{
		columnLabel: 'vehicle.statusVehicle',
		orderBy: 'status_code',
		personalizedCell: ({ status, comment }) => (
			<StatusVehicle status={status} comment={comment} />
		),
	},

	{
		columnLabel: 'misc.doBy',
		orderBy: 'completionUser_lastName',
		accountsTypeDenied: [EAccountType.guest],
		personalizedCell: ({ completionUser }) => (
			<UserAvatarSlidePanel
				firstName={completionUser?.firstName}
				lastName={completionUser?.lastName}
				userId={completionUser?.id}
				accountId={completionUser?.account?.id}
				displayName={false}
			/>
		),
	},

	{
		columnLabel: 'interventions.ref',
		orderBy: 'intervention_reference',
		personalizedCell: ({
			intervention: { reference, id, isSharedWithMe, interventionType },
		}) => (
			<>
				{reference ? (
					<OpenLinkCell
						viewModalLink={vmLinkByInterventionType(interventionType, id)}
						txt={reference}
						isSharedWithMe={isSharedWithMe}
					/>
				) : (
					'-'
				)}
			</>
		),
	},

	{
		columnLabel: 'misc.type',
		orderBy: 'intervention_interventionType',
		personalizedCell: ({ intervention: { interventionType } }) => (
			<InterventionType interventionType={interventionType} />
		),
	},

	{
		columnLabel: 'misc.doneDate',
		orderBy: 'completionDate',
		personalizedCell: ({ completionDate }) => (
			<>{completionDate ? formatDate(completionDate, true) : '-'}</>
		),
	},

	{
		columnLabel: 'interventions.date',
		orderBy: 'intervention_dateFrom',
		personalizedCell: ({ intervention: { dateTo, dateFrom } }) => (
			<DateToFrom dateTo={dateTo} dateFrom={dateFrom} />
		),
	},

	{
		columnLabel: 'interventions.statusInt',
		orderBy: 'intervention_status_code',
		tableCellAlign: 'center',
		cellAlign: 'center',
		personalizedCell: ({ intervention: { status, id } }) => {
			return (
				<ActionPopoverBtn
					inputs={{ query: useInterventionStatusesList }}
					selected={{
						id: status?.id,
						color: status?.color,
						name: status?.name,
						code: status?.code,
					}}
					keyTranslate='interventions'
					titlePopover={'misc.status'}
					mutation={{
						id,
						query: useUpdateInterventionStatuses,
						refreshQuery: ['interventionProgrammedVehicles'],
						successMessage: 'success.interventionStatusUpdate',
						attribute: 'status',
					}}
					permissions={[EPermissionScope.vehicleUpdate]}
				/>
			)
		},
	},

	{
		columnLabel: 'interventions.intervenants',
		orderBy: 'intervention_actors_lastName',
		accountsTypeDenied: [EAccountType.guest],
		personalizedCell: ({
			intervention: {
				actors: { collection },
			},
		}) => (
			<UsersPopover
				data={collection?.map(({ id, lastName, firstName, account }) => ({
					firstName,
					lastName,
					id,
					account,
				}))}
				title='interventions.actors'
			/>
		),
	},

	{
		columnLabel: 'interventions.managerMission',
		orderBy: 'intervention_manager_lastName',
		accountsTypeDenied: [EAccountType.guest],
		personalizedCell: ({ intervention: { manager } }) => (
			<UserAvatarSlidePanel
				firstName={manager?.firstName}
				lastName={manager?.lastName}
				userId={manager?.id}
				accountId={manager?.account?.id}
				displayName={false}
			/>
		),
	},

	{
		columnLabel: 'misc.agencies',
		orderBy: 'intervention_agencies_agencyCustomDetails_agencyName',
		modules: [EModule.network],
		personalizedCell: ({ intervention: { agencies } }) => (
			<Stack direction='row' alignItems='center'>
				<ChipCountListPopover
					text='misc.agencies'
					data={
						agencies?.collection?.map(({ agencyCustomDetails, id }) => {
							return { name: agencyCustomDetails[0]?.agencyName, id }
						}) || []
					}
					viewModalLink={ROUTES.VIEWMODAL.AGENCIES.url}
					permissions={[
						EPermissionScope.networkView,
						EPermissionScope.networkManage,
					]}
				/>
			</Stack>
		),
	},

	{
		fixWidth: '50px',
		columnLabel: '',
		visible: true,
		tableCellAlign: 'center',
		cellAlign: 'center',
		sticky: true,
		personalizedCell: ({ intervention: { id } }: any) => (
			<ActionsBtn
				duplicate
				rowId={id}
				deleted={{
					query: useRemoveIntervention,
					refreshQuery: ['interventionProgrammedVehicles'], // todo add querynumbers here
					successMessageDelete: 'success.interventionDeleted',
					messageConfirm: {
						title: 'confirmModal.deleteInterventionsTitle',
						message: 'confirmModal.deleteInterventionMessage',
					},
				}}
				editModal={
					<InterventionModal interventionId={id} modalUtils={{} as any} />
				}
				viewModalLink={`${ROUTES.VIEWMODAL.INTERVENTION_REFERENCES.url}${id}`}
				permissions={{
					update: [EPermissionScope.interventionUpdate],
					remove: [EPermissionScope.interventionRemove],
					delete: [EPermissionScope.interventionDelete],
				}}
			/>
		),
	},
]

export default listInterventionsControlByVehicleViewTable
