import React, { useCallback, useContext } from 'react'

import { Routes, Route } from 'react-router-dom'
import Container from '@mui/material/Grid'

import { ROUTES } from '../../routes/consts'

import ConditionalRoute from '../../routes/ConditionalRoute'
import { AuthContext } from '../../utils/contexts/AuthContext'
import { EPermission } from '../../utils/app-models'
import AdminPanelAccounts from './accounts'

const adminPanel = {
	width: '100%',
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	boxSizing: 'border-box',
	flexGrow: '1',
}

const AdminPanel: React.FC = () => {
	const { checkPermissions } = useContext(AuthContext)

	const checkCondition = useCallback(
		(permissions) => checkPermissions(permissions),
		[checkPermissions]
	)

	return (
		<Container sx={adminPanel}>
			<Routes>
				<Route
					path={ROUTES.ADMIN_PANEL.ACCOUNTS.path}
					element={
						<ConditionalRoute
							component={AdminPanelAccounts}
							condition={checkCondition([EPermission.vehicle])}
							redirect={ROUTES.HOME.url}
						/>
					}
				/>
			</Routes>
		</Container>
	)
}

export default AdminPanel
