import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'

import { useLazyQuery } from '@apollo/client'

import Grid from '@mui/material/Grid'
import SearchIcon from '@mui/icons-material/Search'

import IconBtn from '../../../../../components/buttons/IconBtn'

import {
	useLazyVehicle,
	CONTROL_EXSISTING_VEHICLE_DETAILS,
} from '../../../../../api/gql/queries/vehiclesQueries'
import ConfirmModal from '../../../../../components/modal/ConfirmModal'
import { useFormatMessage, useModal } from '../../../../../utils/hooks'
import {
	EFormModalMode,
	EPermissionScope,
} from '../../../../../utils/app-models'
import { AuthContext } from '../../../../../utils/contexts/AuthContext'
import { FormModalContext } from '../../../../../components/forms/formModal/FormModalContext'
import DeboucedFormTextField from '../../../../../components/forms/formFields/DebouncedFormTextField'
import FormTextField from '../../../../../components/forms/formFields/FormTextField'
import { formatVehicleModalEditDefaultValues } from '../vehicleModalUtils'

interface IVinAndImmatManager {
	setCanDisplayRest
	canDisplayRest: boolean
}

const VinAndImmatManager: React.FC<IVinAndImmatManager> = ({
	setCanDisplayRest,
	canDisplayRest,
}) => {
	const intlMsg = useFormatMessage()
	const { checkPermissions } = useContext(AuthContext)
	const { openModal, closeModal, isVisible } = useModal()
	const { setValue, data, errors, setValues, setCurentEditedElement, setMode } =
		useContext(FormModalContext)
	const { getVehicle } = useLazyVehicle()
	const immat = useMemo(() => data?.immat || '', [data?.immat])
	const vin = useMemo(() => data?.vin || '', [data?.vin])
	const isImmatError = useMemo(() => !!errors?.immat || false, [errors?.immat])

	const [getData, { error, loading: vehicleDetailsLoading }] = useLazyQuery(
		CONTROL_EXSISTING_VEHICLE_DETAILS,
		{ onCompleted: () => setCanDisplayRest(true), fetchPolicy: 'network-only' }
	)
	const [cantValidateModal, setCantValidateModal] = useState(false)
	const [modalMessage, setModalMessage] = useState<{
		title?: string
		message?: string
		messageIsHtml?: boolean
	} | null>(null)

	const confirmUpdate = useCallback(() => {
		switch (error?.graphQLErrors[0]?.extensions?.message) {
			case 'errors.vehicleAlreadyOwned': {
				const ownedVehicleId = error?.graphQLErrors[0]?.extensions?.id || null
				if (ownedVehicleId) {
					getVehicle({ variables: { id: ownedVehicleId } }).then((res) => {
						const data = res?.data?.vehicle
						setValues(formatVehicleModalEditDefaultValues({ data }))
						setCurentEditedElement(data)
						setCanDisplayRest(true)
						setMode(EFormModalMode?.edit)
					})
				}
				closeModal()
				break
			}

			// TODO  delete comment when fusion vehicle will be operational (search #fusion_vehicle in the project)
			// case 'errors.vehiclesNeedFusion':
			// 	navigate(ROUTES.VEHICLES.FUSION.url)
			// 	break
			case 'errors.vehiclesNeedFusion':
				window.open('mailto:support@hubeecar.com', 'mail')
				closeModal()
				break

			default:
				break
		}
	}, [error, setValues, setMode])

	const handleGraphQLError = useCallback(
		(error) => {
			const message = error?.graphQLErrors[0]?.extensions?.message
			if (message === 'errors.vehicleAlreadyOwned') {
				setCantValidateModal(
					!checkPermissions([EPermissionScope.vehicleUpdate])
				)
				return {
					title: 'vehicle.updateConfirmModalTitle',
					message: checkPermissions([EPermissionScope.vehicleUpdate])
						? 'vehicle.updateConfirmModalText'
						: 'vehicle.updateConfirmModalTextWithoutPermissions',
					messageIsHtml: true,
				}
			} else if (message === 'errors.vehiclesNeedFusion') {
				setCantValidateModal(
					!checkPermissions([EPermissionScope.vehicleUpdate])
				)
				return {
					title: 'vehicle.fusionConfirmModalTitle',
					message: checkPermissions([EPermissionScope.vehicleUpdate])
						? 'vehicle.fusionConfirmModalText'
						: 'vehicle.fusionConfirmModalTextWithoutPermissions',
					messageIsHtml: true,
				}
			}
			return null
		},
		[checkPermissions]
	)

	useEffect(() => {
		const graphQLError = handleGraphQLError(error)
		if (graphQLError) {
			setModalMessage(graphQLError)
			openModal()
		}
	}, [error, handleGraphQLError])

	const handleIdentifyVehicle = useCallback(() => {
		getData({ variables: { immat: immat || null, vin: vin || null } })
	}, [immat, vin])

	const handleChangeWithDisplayRest = useCallback(
		({ fieldName, option }) => {
			setValue(fieldName, option)
			setCanDisplayRest(false)
		},
		[setValue]
	)

	/////////////////////////////////////////////////////////
	// forced immat or vin
	/////////////////////////////////////////////////////////

	useEffect(() => {
		if (data?.forcedImmat || data?.forcedVin) {
			getData({
				variables: {
					immat: data?.forcedImmat || null,
					vin: data?.forcedVin || null,
				},
			}).then(() => {
				setValues({ forcedImmat: null, forcedVin: null })
			})
		}
	}, [data?.forcedImmat, data?.forcedVin])

	return (
		<>
			<Grid item xs={canDisplayRest ? 6 : 5}>
				<DeboucedFormTextField
					name='immat'
					label={intlMsg('vehicle.immat')}
					isRequired
					context={FormModalContext}
					specialOnChange={handleChangeWithDisplayRest}
				/>
			</Grid>
			<Grid item xs={canDisplayRest ? 6 : 5}>
				<FormTextField
					name='vin'
					label={intlMsg('vehicle.vin')}
					isRequired
					context={FormModalContext}
					specialOnChange={handleChangeWithDisplayRest}
				/>
			</Grid>
			{!canDisplayRest && (
				<Grid item xs={2}>
					<IconBtn
						dataTestId='Playwright-VinAndImmatManager-search'
						icon={<SearchIcon />}
						onClick={handleIdentifyVehicle}
						loading={vehicleDetailsLoading}
						disabled={isImmatError}
					/>
				</Grid>
			)}
			<ConfirmModal
				onClose={closeModal}
				open={isVisible}
				handleConfirm={confirmUpdate}
				message={modalMessage}
				cantValidate={cantValidateModal}
				validationMessage={'misc.toUpdate'}
			/>
		</>
	)
}

export default VinAndImmatManager
