import { extractDataFromKeys } from '../../../../../utils/utils'

const agencyField = [
	'address',
	'adminCellphone',
	'adminFirstName',
	'adminLastName',
	'adminPhone',
	'adminEmail',
	'accountType',
	'adminCanLogin',
	'city',
	'country',
	'isActive',
	'name',
	'agencyName',
	'siret',
	'tva',
	'zipcode',
]

export const getAgencyValues: any = (editedAgency) => {
	const defaultValues: any = extractDataFromKeys(editedAgency, agencyField)
	// const countryValues = getCountries(localLanguage)?.find(
	// 	({ code }) => code === defaultValues?.country
	// )

	return {
		...defaultValues,
		// country: {
		// 	key: countryValues?.code,
		// 	label: countryValues?.label,
		// 	value: countryValues?.code,
		// },
		// accountType: defaultValues?.accountType?.id,
		sharedSites:
			editedAgency?.sharedSites?.collection?.map(({ id }) => id) || [],
		sharedProductCategories:
			editedAgency?.sharedProductCategories?.collection?.map(({ id }) => id) ||
			[],
		networks:
			editedAgency?.networks?.collection?.map(({ name, id }) => ({
				label: name,
				key: name,
				value: id,
			})) || [],

		agencyName: editedAgency.agencyCustomDetails[0]?.agencyName,
	}
}

export const parsPrice: any = (price: string) => {
	const dotIndex = price.indexOf('.')

	return dotIndex !== -1
		? {
				unity: price.slice(0, dotIndex),
				decimal: price.slice(dotIndex + 1),
		  }
		: {
				unity: price,
				decimal: '',
		  }
}
