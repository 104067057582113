import { EInterventionTypes } from '../utils/app-models'

const ROUTES = {
	HOME: {
		path: '/*',
		url: '/',
	},
	ADMIN_PANEL: {
		path: '/admin-panel/*',
		url: '/admin-panel',
		ACCOUNTS: {
			path: '/accounts',
			url: '/admin-panel/accounts',
		},
	},
	VIEWMODAL: {
		path: '/viewmodal*',
		url: '/viewmodal',
		SITE: {
			path: '/modalview?query=site&varType=id&varValue=',
			url: '/modalview?query=site&varType=id&varValue=',
		},
		AGENCIES: {
			path: '/modalview?query=agencyAccount&varType=id&varValue=',
			url: '/modalview?query=agencyAccount&varType=id&varValue=',
		},
		VEHICLES: {
			path: '/modalview?query=vehicle&varType=id&varValue=',
			url: '/modalview?query=vehicle&varType=id&varValue=',
		},
		CUSTOMERS: {
			path: '/modalview?query=customer&varType=id&varValue=',
			url: '/modalview?query=customer&varType=id&varValue=',
		},
		INTERVENTION_REFERENCES: {
			path: '/modalview?query=intervention&varType=id&varValue=',
			url: '/modalview?query=intervention&varType=id&varValue=',
		},
		INTERVENTION_TRANSPORT: {
			path: '/modalview?query=interventionTransport&varType=id&varValue=',
			url: '/modalview?query=interventionTransport&varType=id&varValue=',
		},
		INTERVENTION_FORMS: {
			path: '/modalview?query=form&varType=id&varValue=',
			url: '/modalview?query=form&varType=id&varValue=',
		},
	},
	NETWORKS: {
		path: '/networks/*',
		url: '/networks',
	},

	INVOICES: {
		path: '/invoices/*',
		url: '/invoices',
	},
	SITES: {
		path: '/sites/*',
		url: '/sites',
	},
	TEST: {
		path: '/test/*',
		url: '/test',
	},
	ACCOUNTS: {
		path: '/accounts/*',
		url: '/accounts',
	},
	VEHICLES: {
		path: '/vehicles/*',
		url: '/vehicles',
		FUSION: {
			path: '/fusion',
			url: '/vehicles/fusion',
		},
	},

	VEHICLES_DOCUMENTS: {
		path: '/vehicles-documents/*',
		url: '/vehicles-documents',
	},

	CONTACTS: {
		path: '/contacts/*',
		url: '/contacts',
	},
	ALERTS: {
		path: '/alerts/*',
		url: '/alerts',
	},
	INTERVENTION_FORMS: {
		path: '/intervention-forms/*',
		url: '/intervention-forms',
	},
	INTERVENTION: {
		path: '/interventions/*', // is not used because the components of the routes are different
		url: '/interventions',
		REFERENCES: {
			path: '/interventions/references/*',
			url: '/interventions/references',
		},
		VEHICLES: {
			path: '/interventions/vehicles/*',
			url: '/interventions/vehicles',
		},
		CALENDAR: {
			path: '/interventions/calendar/*',
			url: '/interventions/calendar',
		},
		TRANSPORTS: {
			path: '/interventions/transports/*',
			url: '/interventions/transports',
		},
	},
	LOGIN: {
		path: '/login',
		url: '/login',
	},
	CONFIRMATION: {
		path: '/confirm-user/:confirmationToken',
		url: '/confirm-user/:confirmationToken',
	},
	FORGOTTEN_PASSWORD: {
		path: '/forgotten-password/*',
		url: '/forgotten-password',
		CHECK: {
			path: '/forgotten-password-check/:forgottenPasswordToken',
			url: '/forgotten-password-check/:forgottenPasswordToken',
		},
	},
	APP: {
		path: '/app',
		url: '/app',
	},
	ERROR404: {
		path: '/404',
		url: '/404',
	},
	ADMINISTRATION: {
		path: '/administration/*',
		url: '/administration',
		USERS: {
			path: '/users/*',
			url: '/administration/users',
		},
		MY_PROFILE: {
			path: '/my-profile/*',
			url: '/administration/my-profile',
		},
		TEAMS: {
			path: '/teams/*',
			url: '/administration/teams',
		},
		NOTIFICATIONS: {
			path: '/notifications',
			url: '/administration/notifications',
		},
		FORM_TEMPLATES: {
			path: '/form-templates/*',
			url: '/administration/form-templates',
		},
		FORM_TEMPLATE_EDITION: {
			path: '/form-template-edition/*',
			url: '/administration/form-template-edition',
		},
		CATALOG_PRODUCTS: {
			path: '/catalog-products/*', // TODO is not used now and need to be changed, we can make a layout with a router using the menu
			url: '/catalog-products',
			CONTROLOWN: {
				path: `/catalog-products/${EInterventionTypes.control}-own`,
				url: `/administration/catalog-products/${EInterventionTypes.control}-own`,
			},
			CONTROLSHARED: {
				path: `/catalog-products/${EInterventionTypes.control}-shared`,
				url: `/administration/catalog-products/${EInterventionTypes.control}-shared`,
			},
			TRANSPORT: {
				path: `/catalog-products/${EInterventionTypes.transport}`,
				url: `/administration/catalog-products/${EInterventionTypes.transport}`,
			},
			LOGISTIC: {
				path: `/catalog-products/${EInterventionTypes.logistic}`,
				url: `/administration/catalog-products/${EInterventionTypes.logistic}`,
			},
			MECANIC: {
				path: `/catalog-products/${EInterventionTypes.mecanic}`,
				url: `/administration/catalog-products/${EInterventionTypes.mecanic}`,
			},
		},

		/////////////////////////////////////////////////////////
		// TEST catalogue product
		/////////////////////////////////////////////////////////

		PRODUCT_CATALOGUE: {
			path: '/product-catalogue/*',
			url: '/product-catalogue',
			CATALOGUE_PRODUCT: {
				path: `/product-catalogue/product`,
				url: `/administration/product-catalogue/product`,
			},
			CATALOGUE_PRODUCT_BASE: {
				path: `/product-catalogue/base`,
				url: `/administration/product-catalogue/base`,
			},
			CATALOGUE_PRODUCT_SHARED: {
				path: `/product-catalogue/shared`,
				url: `/administration/product-catalogue/shared`,
			},
		},
	},
}

export { ROUTES }
