import React from 'react'
import { Box } from '@mui/material'

interface IViewModalAlertFooter {}

const ViewModalAlertFooter: React.FC<IViewModalAlertFooter> = () => {
	return <Box sx={{ height: '30px' }}></Box>
}

export default ViewModalAlertFooter
