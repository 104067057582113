import React, { useState, useCallback, useMemo } from 'react'

import { Alert, Stack, Typography } from '@mui/material'

import { searchTable } from '../../../../../../../utils/string'
import SearchField from '../../../../../../../components/searchField/SearchField'
import ProductBaseDrawerTableContainer from './ProductBaseDrawerTableContainer'
import translateIntlMsgString from '../../../../../../../utils/translateIntlMsgString'

const AlertCSS = { mt: 4 }

interface IProductBaseDrawerTable {
	productBase
}

const ProductBaseDrawerTable: React.FC<IProductBaseDrawerTable> = ({
	productBase,
}) => {
	const products = useMemo(
		() => productBase?.products?.collection || [],
		[productBase]
	)
	const [search, setSearch] = useState<any>('')

	const handleChangeSearch = useCallback((e) => {
		setSearch(e.target.value)
	}, [])

	// format row with filter search
	const rows = useMemo(
		() =>
			searchTable(products?.filter(({ isActive }) => isActive) || [], search),
		[products, search]
	) as any

	return (
		<>
			<Stack
				mt={4}
				direction='row'
				justifyContent='space-between'
				alignItems='center'
			>
				<Typography variant='h3'>
					{translateIntlMsgString('catalogProduct.relatedProductBase')}
				</Typography>
				{!!products?.length && <SearchField onChange={handleChangeSearch} />}
			</Stack>

			{rows?.length ? (
				<ProductBaseDrawerTableContainer rows={rows} />
			) : (
				<Alert severity='info' sx={AlertCSS}>
					{translateIntlMsgString('catalogProduct.emptyProductBaseDrawer')}
				</Alert>
			)}
		</>
	)
}

export default ProductBaseDrawerTable
