import React from 'react'

import { Controller } from 'react-hook-form'

import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import ButtonBase from '@mui/material/ButtonBase'
import DoneIcon from '@mui/icons-material/Done'
import ClearIcon from '@mui/icons-material/Clear'

import { limitedHues, dynamicColor } from '../../../../utils/color'

const formControlLabelCSS = {
	height: '36px',
	width: '36px',
	margin: '4px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '50px',
}

interface IColorRadio {
	name: string
	control: any
	valuesDefault?: any
	action?: any
	notEmptyColor?
}

const ColorRadio: React.FC<IColorRadio> = ({
	name,
	control,
	valuesDefault,
	action,
	notEmptyColor,
}) => {
	return (
		<>
			<Controller
				render={({ field: { onChange, value } }) => (
					<RadioGroup
						name='colorLocation'
						onChange={action || onChange}
						value={valuesDefault || value}
						sx={{
							flexDirection: 'row',
							'& .MuiRadio-root': { color: '#fff0' },
						}}
					>
						{!notEmptyColor && (
							<ButtonBase focusRipple sx={{ borderRadius: '50px' }}>
								<FormControlLabel
									value={''}
									control={
										<Radio
											checkedIcon={
												<ClearIcon
													sx={{ color: ({ palette }) => palette.grey[500] }}
												/>
											}
											sx={{ color: 'white' }}
										/>
									}
									label=''
									sx={{
										...formControlLabelCSS,
										backgroundColor: 'white' as any,
										border: ({ palette }) => ` solid 1px ${palette.grey[500]}`,
									}}
								/>
							</ButtonBase>
						)}

						{limitedHues.map((hue, i) => (
							<ButtonBase
								focusRipple
								sx={{ borderRadius: '50px' }}
								key={`colorRadio-${i}`}
							>
								<FormControlLabel
									value={hue}
									control={
										<Radio
											checkedIcon={<DoneIcon sx={{ color: 'white' }} />}
											sx={{ color: 'white' }}
										/>
									}
									label=''
									sx={{
										...formControlLabelCSS,
										backgroundColor: dynamicColor(i, 500) as any,
									}}
								/>
							</ButtonBase>
						))}
					</RadioGroup>
				)}
				name={name}
				control={control}
				defaultValue={valuesDefault}
			/>
		</>
	)
}

export default ColorRadio
