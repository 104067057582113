import React, { useCallback, useContext, useMemo } from 'react'
import {
	Box,
	Select,
	MenuItem,
	ListItemText,
	ListItemIcon,
	Typography,
} from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { AuthContext } from '../../../../utils/contexts/AuthContext'
import { useFormatMessage } from '../../../../utils/hooks'
import { FormSelectNationCSS, useStyles } from './FormSelectNationCSS'

interface IformSelectNation {
	name: string
	label: string
	options: Array<{
		key: string
		label: string
		flag: string
		alt: string
	}>
}

const FormSelectNation: React.FC<IformSelectNation> = ({
	name,
	label,
	options,
}) => {
	const intlMsg = useFormatMessage()
	const classes = useStyles()
	const { localLanguage, setLocalLanguage } = useContext(AuthContext)

	const selectedOption: any = useMemo(
		() => options.find(({ key }) => key === localLanguage),
		[localLanguage, options]
	)

	const handleChange = useCallback(
		(event: SelectChangeEvent<string>) => {
			const newLanguage = event.target.value
			localStorage.setItem('userLanguage', newLanguage)
			setLocalLanguage(newLanguage)
		},
		[setLocalLanguage]
	)

	const menuItems = useMemo(() => {
		return options.map((option) => (
			<MenuItem key={option.key} value={option.key}>
				<ListItemIcon>
					<img
						src={option.flag}
						alt={option.alt}
						className={classes.flagIcon}
					/>
				</ListItemIcon>
				<ListItemText primary={intlMsg(option.label)} />
			</MenuItem>
		))
	}, [options, classes.flagIcon, intlMsg])

	const iconComponent = useCallback(
		(props) => <ExpandMoreIcon {...props} className={classes.whiteIcon} />,
		[classes.whiteIcon]
	)

	return (
		<Box sx={FormSelectNationCSS} id={name}>
			<Box className='textLayout'>
				<Typography variant='h5' className='subtitle'>
					{label}
				</Typography>
				<Typography variant='h3' className='title'>
					{intlMsg(selectedOption?.label)}
				</Typography>
			</Box>
			<Select
				className='selectCSS'
				value={''}
				onChange={handleChange}
				IconComponent={iconComponent}
				inputProps={{ 'aria-label': 'Without label' }}
			>
				{menuItems}
			</Select>
		</Box>
	)
}

export default FormSelectNation
