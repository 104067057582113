/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
	gql,
	OperationVariables,
	QueryHookOptions,
	useLazyQuery,
	useQuery,
} from '@apollo/client'
import { listNetworkAttributes } from '../attributes/networksAttributes'

import {
	listSiteAttributes,
	commonSiteAttributes,
	listSitesAttributes,
} from '../attributes/sitesAttributes'

/**  ---------------------QUERIES---------------------- */

export const GET_SITE = gql`
query getSite(
    $id: ID!
) {
    site(id: $id) {
        ${commonSiteAttributes}
    }
}`

export const GET_SITE_FOR_MODAL = gql`
	query getSite($id: ID!) {
		site(id: $id) {
			id
			name
			phone
			cellphone
			address
			city
			zipcode
			isActive
			country
			siret
			comment
			sharedToAgencies(itemsPerPage: 99999) {
				collection {
					id
					agencyCustomDetails {
						id
						agencyName
					}
					networks(itemsPerPage: 99999) {
						collection {
							id
							name
						}
					}
				}
			}
			customer {
				id
				name
			}
			gestionnaire {
				id
				firstName
				lastName
			}
		}
	}
`

export const SITES_FILTER_LIST = gql`
query getSitesList(
    $page: Int
    $itemsPerPage: Int
    $searchText: String
    $isActive: Boolean
	$customer: String
	$isDeleted: Boolean
	$agency: String
	$orderBy: [SiteFilter_orderBy]		
) {
    sites(page: $page, itemsPerPage: $itemsPerPage, isActive: $isActive, searchText : $searchText, customer: $customer, isDeleted: $isDeleted,agency: $agency, orderBy: $orderBy){
        ${listSiteAttributes}
    }
}`

export const LIST_SITES = gql`
query getSitesList(
    $page: Int
    $itemsPerPage: Int
    $searchText: String
    $isActive: Boolean
	$customer: String
	$isDeleted: Boolean
	$agency: String
	$orderBy: [SiteFilter_orderBy]	
) {
    sites(page: $page, itemsPerPage: $itemsPerPage, isActive: $isActive, searchText : $searchText, customer: $customer, isDeleted: $isDeleted,agency: $agency, orderBy: $orderBy){
        ${listSitesAttributes}
    }
}`

export const GET_SITES_AND_NETWORKS_LIST_FOR_MODAL = gql`
	query getSitesAndNetworksList {
		sites(itemsPerPage: 100000, page: 1) {
			collection {
				id
				name
				customer {
					id
					name
				}
			}
		}
		networks(itemsPerPage: 100000, page: 1) {
			collection {
				id
				name
				agencies {
					collection {
						id
						name
					}
				}
			}
		}
	}
`
export const EXPORT_SITES = gql`
	query exportSites(
		$searchText: String
		$isActive: Boolean
		$customer: String
		$isDeleted: Boolean
		$agency: String
		$timezone: Int
		$orderBy: [SiteFilter_orderBy]
	) {
		exportSites(
			isActive: $isActive
			searchText: $searchText
			customer: $customer
			isDeleted: $isDeleted
			agency: $agency
			timezone: $timezone
			orderBy: $orderBy
		) {
			id
			exportFile
		}
	}
`

export const GET_SITES_AND_NETWORKS_LIST = gql`
query getSitesAndNetworksList{
    sites(itemsPerPage: 100000){
        ${listSiteAttributes}
    }
    networks(itemsPerPage: 100000) {
        ${listNetworkAttributes}
    }
}`

export const GET_SITES_BY_CUSTOMER_ID = gql`
	query getSitesByCustomerId($customer: String) {
		sites(customer: $customer, itemsPerPage: 100000, isActive: true) {
			collection {
				id
				name
			}
		}
	}
`

export const SITES_NUMBERS = gql`
	query getSitesNumbers(
		$customer: String
		$searchText: String
		$isActive: Boolean
	) {
		sites(
			page: 1
			itemsPerPage: 100000
			isDeleted: false
			customer: $customer
			isActive: $isActive
			searchText: $searchText
		) {
			paginationInfo {
				totalCount
			}
		}
	}
`

export const SITES_COUNT_BY_AGENCY = gql`
	query getSitesNumbersByAgency($agency: String) {
		sites(
			page: 1
			itemsPerPage: 100000
			isActive: true
			isDeleted: false
			agency: $agency
		) {
			paginationInfo {
				totalCount
			}
		}
	}
`

export const GET_SITES_FOR_SELECT = gql`
	query getSitesForSelect($customer: String) {
		sites(
			page: 1
			itemsPerPage: 100000
			isActive: true
			isDeleted: false
			customer: $customer
		) {
			collection {
				id
				name
			}
		}
	}
`

export const GET_CUSTOMER_FOR_MODAL_SELECT_INITIALISATION = gql`
	query getSiteForModalSelectInitialisation($id: ID!) {
		site(id: $id) {
			id
			name
		}
	}
`
/**  ---------------------HOOKS---------------------- */

export const useSiteAndNetworksListForModal = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { loading, data } = useQuery(
		GET_SITES_AND_NETWORKS_LIST_FOR_MODAL,
		options
	)

	return {
		loading,
		sites: data?.sites?.collection || null,
		networks: data?.networks?.collection || null,
	}
}

export const useSitesNumbers = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { loading, data } = useQuery(SITES_NUMBERS, options)

	return {
		loading,
		numbers: data?.sites?.paginationInfo.totalCount || null,
	}
}

export const useLazySite = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(GET_SITE, options)
	return { loading, data: data?.site || null, getSite: getData }
}

export const useLazySiteForModal = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(GET_SITE_FOR_MODAL, options)
	return { loading, data: data?.site || null, getSite: getData }
}

export const useLazySitesForSelect = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { data, loading }] = useLazyQuery(
		GET_SITES_FOR_SELECT,
		options
	)
	return { loading, sites: data?.sites?.collection || [], getSites: getData }
}

export const useLazySiteForModalSelectInitialisation = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(
		GET_CUSTOMER_FOR_MODAL_SELECT_INITIALISATION,
		options
	)
	return { loading, data: data?.site || null, getSite: getData }
}

export const useExportSites: any = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { data, loading, error } = useQuery(EXPORT_SITES, options)

	return {
		exportFile: data?.exportSites?.[0]?.exportFile || null,
		loading,
		error,
	}
}
