import React from 'react'

import Table from '../../../components/tableV2'
import {
	IOverrideConfigTable,
	IOverrideConfigTableWithArgument,
	IOverrideTableProvider,
} from '../../../utils/typescript/tableType'
import { vehiclesDocumentsConfigTable } from './vehiclesDocumentsConfigTable'

interface IVehiclesDocuments {
	othersQueryParams?
	overrideConfigTable?: IOverrideConfigTable | IOverrideConfigTableWithArgument
	overrideTableProvider?: IOverrideTableProvider
	isInViewModal?: boolean
}

const VehiclesDocuments: React.FC<IVehiclesDocuments> = ({
	othersQueryParams,
	overrideConfigTable,
	overrideTableProvider,
	isInViewModal = false,
}) => {
	return (
		<Table
			tableConfig={vehiclesDocumentsConfigTable}
			overrideConfigTable={overrideConfigTable}
			othersQueryParams={othersQueryParams}
			overrideTableProvider={overrideTableProvider}
			isInViewModal={isInViewModal}
		/>
	)
}

export default VehiclesDocuments
