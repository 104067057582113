import React from 'react'

import Box from '@mui/material/Box'

interface ITabPanel {
	children: any
	value: number
	index: number
}

const TabPanel: React.FC<ITabPanel> = (props) => {
	const { children, value, index, ...other } = props

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`scrollable-force-tabpanel-${index}`}
			aria-labelledby={`scrollable-force-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={0}>{children}</Box>}
		</div>
	)
}

export default TabPanel
