import React, { useContext, useEffect, useState } from 'react'

import { Stack } from '@mui/material'

import SharedProductCategories from '../../../../../../../components/tableV2/_isolatedTables/sharedProductCategories'
import { StepperContext } from '../../../../../../../components/forms/formStepper/StepperContext'

interface ICategoryStep {}

const CategoryStep: React.FC<ICategoryStep> = () => {
	const { setValue, data } = useContext(StepperContext)

	const [selectedRow, setSelectedRow] = useState<any>(
		data?.sharedProductCategories?.map((sharedProductCategorie) =>
			sharedProductCategorie?.id
				? sharedProductCategorie
				: { id: sharedProductCategorie }
		) || []
	)

	useEffect(() => {
		setValue('sharedProductCategories', selectedRow)
	}, [selectedRow])

	return (
		<Stack mb={2} sx={{ height: '100%' }}>
			<SharedProductCategories
				overrideConfigTable={{
					customStateSelectedRow: {
						selectedRow,
						setSelectedRow,
					},
					elevationNbr: 0,
					hidden: {
						tableToolBar: { columnFilter: true, switchActive: true },
						tableToolBarAction: { all: true },
					},
					tableContainer: { disabledCheckBox: false },
				}}
				isInViewModal
			/>
		</Stack>
	)
}

export default CategoryStep
