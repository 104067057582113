import React from 'react'

import { Stack, SvgIcon } from '@mui/material'
import TitleField from './TitleField'

const fieldBarcodeCSS = {
	border: ({ palette }) => `1px solid ${palette.grey[400]}`,
	background: 'white',
	width: '170px',
}

interface IFieldBareCode {
	field
	deleteField
	index
	updateField
	insertField
}

const FieldBareCode: React.FC<IFieldBareCode> = ({
	field: { id, name, values, fieldType },
	deleteField,
	index,
	updateField,
	insertField,
}) => {
	const fieldToEdit = {
		id,
		name,
		values,
		fieldType,
		updateField,
		index,
	}

	return (
		<Stack p={1}>
			<TitleField
				deleteField={deleteField}
				index={index}
				fieldToEdit={fieldToEdit}
				isRequired={values?.isRequired}
				insertField={insertField}
				values={values}
				fieldType={fieldType}
			/>
			<Stack
				direction='row'
				alignItems='center'
				justifyContent='center'
				sx={fieldBarcodeCSS}
			>
				<SvgIcon style={{ fontSize: '6rem' }}>
					<svg
						width='24'
						height='24'
						viewBox='0 0 24 24'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<rect width='24' height='24' fill='white' />
						<path
							d='M14.0685 6H12.6262C12.4279 6 12.2656 6.16226 12.2656 6.36058V17.9531C12.2656 18.1514 12.4279 18.3137 12.6262 18.3137H14.0685C14.2668 18.3137 14.4291 18.1514 14.4291 17.9531V6.36058C14.4291 6.16226 14.2668 6 14.0685 6Z'
							fill='black'
							fillOpacity='0.54'
						/>
						<path
							d='M9.59736 6H8.8762C8.67788 6 8.51562 6.16226 8.51562 6.36058V17.9531C8.51562 18.1514 8.67788 18.3137 8.8762 18.3137H9.59736C9.79567 18.3137 9.95793 18.1514 9.95793 17.9531V6.36058C9.95793 6.16226 9.79567 6 9.59736 6Z'
							fill='black'
							fillOpacity='0.54'
						/>
						<path
							d='M16.268 6H15.9075C15.7091 6 15.5469 6.16226 15.5469 6.36058V17.9531C15.5469 18.1514 15.7091 18.3137 15.9075 18.3137H16.268C16.4663 18.3137 16.6286 18.1514 16.6286 17.9531V6.36058C16.6286 6.16226 16.4663 6 16.268 6Z'
							fill='black'
							fillOpacity='0.54'
						/>
						<path
							d='M19.6394 6H17.8365C17.6382 6 17.476 6.16226 17.476 6.36058V17.9531C17.476 18.1514 17.6382 18.3137 17.8365 18.3137H19.6394C19.8377 18.3137 20 18.1514 20 17.9531V6.36058C20 6.16226 19.8377 6 19.6394 6Z'
							fill='black'
							fillOpacity='0.54'
						/>
						<path
							d='M7.16346 6H5.36058C5.16226 6 5 6.16226 5 6.36058V17.9531C5 18.1514 5.16226 18.3137 5.36058 18.3137H7.16346C7.36178 18.3137 7.52404 18.1514 7.52404 17.9531V6.36058C7.52404 6.16226 7.36178 6 7.16346 6Z'
							fill='black'
							fillOpacity='0.54'
						/>
					</svg>
				</SvgIcon>
			</Stack>
		</Stack>
	)
}

export default FieldBareCode
