import React from 'react'

import { Box } from '@mui/material'

import InformationsVehicle from './InformationsVehicle'
import DetailsVehicle from './DetailsVehicle'
import Comment from './Comment'
import SiteAccount from './SiteAccount'

interface IVehicleInformations {
	data
}

const VehicleInformations: React.FC<IVehicleInformations> = ({ data }) => {
	return (
		<Box sx={{ p: 2 }}>
			<InformationsVehicle
				vin={data?.vehicleDetail?.vin}
				designation={data?.designation}
				immat={data?.vehicleDetail?.immat}
				vehicleBrand={data?.vehicleDetail?.vehicleBrand?.name}
				vehicleModel={data?.vehicleDetail?.vehicleModelText}
			/>

			{/* <PresenceStatus statusPresence='restitué' /> */}

			<SiteAccount
				site={{ id: data?.site?.id, name: data?.site?.name }}
				customer={{
					id: data?.site?.customer?.id,
					name: data?.site?.customer?.name,
				}}
			/>
			<DetailsVehicle
				fiscPower={data?.vehicleDetail?.fiscPower}
				horsePower={data?.vehicleDetail?.horsePower}
				nbDoors={data?.vehicleDetail?.nbDoors}
				nbSeats={data?.vehicleDetail?.nbSeats}
				ptac={data?.vehicleDetail?.ptac}
				tireSizeBack={data?.vehicleDetail?.tireSizeBack}
				tireSizeRear={data?.vehicleDetail?.tireSizeRear}
				transmission={data?.vehicleDetail?.transmission}
				vehicleCarburant={data?.vehicleDetail?.vehicleCarburant?.code}
				vehicleCategory={data?.vehicleDetail?.vehicleCategory?.code}
				vehicleType={data?.vehicleDetail?.vehicleType?.code}
				dateMec={data?.vehicleDetail?.dateMec}
			/>
			<Comment comment={data?.comment} />
		</Box>
	)
}

export default VehicleInformations
