import React from 'react'

import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline'
import EventNoteIcon from '@mui/icons-material/EventNote'
import TabPanelUserInformation from './../panel/TabPanelUserInformation'
import TabPanelUserTeam from './../panel/TabPanelUserTeam'
import TabPanelUserSchedule from './../panel/TabPanelUserSchedule'

const SlidePanelUserInfosConfig = (dataUser: {}, id: string): [{}, {}, {}] => [
	{
		icon: <PersonOutlineIcon />,
		label: 'misc.information',
		component: <TabPanelUserInformation dataUser={dataUser} />,
	},
	{
		icon: <PeopleOutlineIcon />,
		label: 'misc.teams',
		component: <TabPanelUserTeam id={id} />,
	},
	{
		icon: <EventNoteIcon />,
		label: 'misc.schedule',
		component: <TabPanelUserSchedule />,
	},
]

export default SlidePanelUserInfosConfig
