import React, { useContext, useEffect, useMemo } from 'react'

import { useFormatMessage } from '../../utils/hooks'
import { dispatchTableProps } from './dispatchTableProps'
import TableToolBarAction from './tableToolBarAction'
import TableToolBar from './tableToolBar'
import { TableContext } from '../../utils/contexts/TableContext'
import TableContainer from './tableContainer'
import TableNavTab from './tableTabNav/TableNavTab'
import { Fade } from '@mui/material'
import TableMainContainer from './TableMainContainer'
import { FiltersContext } from '../filters/filtersContext'

interface ITableMain {
	tableMainConfig
	overrideConfigTable?
}

const TableMain: React.FC<ITableMain> = ({
	tableMainConfig,
	overrideConfigTable = null,
}) => {
	const intlMsg = useFormatMessage()
	const {
		selectedRow,
		configIsLoading,
		customStateSelectedRow,
		setPage,
		debouncedState,
	} = useContext(TableContext)
	const { filters } = useContext(FiltersContext)

	//reset page when changing filters
	useEffect(() => {
		setPage(1)
	}, [debouncedState, filters])

	//TODO - refacto needed here - delete dispatchTableProps and find an easier way - use the context....
	const {
		TableToolBarActionPROPS,
		TableToolBarPROPS,
		tableContainerPROPS,
		tableNavTabPROPS,
	} = useMemo(
		() => dispatchTableProps(intlMsg, tableMainConfig, overrideConfigTable),
		[overrideConfigTable]
	)

	return (
		<TableMainContainer
			tableMainConfig={tableMainConfig}
			overrideConfigTable={overrideConfigTable}
		>
			{tableNavTabPROPS && <TableNavTab {...tableNavTabPROPS} />}

			<Fade in={!configIsLoading} timeout={100}>
				<div>
					{TableToolBarPROPS && <TableToolBar {...TableToolBarPROPS} />}
				</div>
			</Fade>

			{!!selectedRow?.length &&
				!TableToolBarActionPROPS?.hidden?.all &&
				(TableToolBarActionPROPS || customStateSelectedRow) && (
					<TableToolBarAction {...TableToolBarActionPROPS} />
				)}

			{tableContainerPROPS && !configIsLoading && (
				<TableContainer {...tableContainerPROPS} />
			)}
		</TableMainContainer>
	)
}

export default TableMain
