import {
	EDocumentType,
	EFormTemplateBasicFields,
	EFormTemplateDateType,
	EFormTemplateModelFields,
	EFormTemplatePictureAngleType,
	EFormTemplatePictureVehicleType,
	EFormTemplateRatingType,
	EFormTemplateSelectionType,
	EFormTemplateTitleType,
	EFormTemplateVehicleFields,
} from '../../../../../utils/app-models'
import translateIntlMsgString from '../../../../../utils/translateIntlMsgString'

// ////////////////////////////////////////////////////////////////////////////////////////////
// fields DefaultValue
// ////////////////////////////////////////////////////////////////////////////////////////////

export const controlDefaultValue = {
	titleField: translateIntlMsgString(
		`formTemplate.${EFormTemplateBasicFields.control}`
	),
	isRequired: false,
	controlList: [
		{
			choice: translateIntlMsgString('misc.yes'),
			color: 'green',
		},
		{
			choice: translateIntlMsgString('misc.no'),
			color: 'red',
		},
	],
	// defaultValue: 0,
	// verificationType: EFormTemplateControlVerificationType.security,
	canAlert: false,
	// selectedChoiceToAlert: 0,
}

// ///////////////////////////////////////////////////////////

export const dateDefaultValue = {
	titleField: translateIntlMsgString(
		`formTemplate.${EFormTemplateBasicFields.date}`
	),
	isRequired: false,
	dateType: EFormTemplateDateType.date,
}

export const dateParkDefaultValue = {
	titleField: translateIntlMsgString(
		`formTemplate.${EFormTemplateBasicFields.parkDate}`
	),
	isRequired: false,
	dateType: EFormTemplateDateType.dateParkEntry,
	specificFieldType: EFormTemplateBasicFields.parkDate,
}

// ///////////////////////////////////////////////////////////

export const pictureDefaultValue = {
	titleField: translateIntlMsgString(
		`formTemplate.${EFormTemplateBasicFields.picture}`
	),
	vehicleType: EFormTemplatePictureVehicleType.car,
	picturesList: [
		{
			name: 'Photo 1',
			isRequired: false,
			angleType: EFormTemplatePictureAngleType.none,
		},
	],
}

export const pictureVehicleDefaultValue = {
	titleField: translateIntlMsgString(
		`formTemplate.${EFormTemplateModelFields.pictureVehicle}`
	),
	vehicleType: EFormTemplatePictureVehicleType.car,
	specificFieldType: EFormTemplateModelFields.pictureVehicle,
	picturesList: [
		{
			name: 'Photo 1',
			isRequired: false,
			angleType: EFormTemplatePictureAngleType.none,
		},
	],
}

export const documentDefaultValue = {
	titleField: translateIntlMsgString(
		`formTemplate.${EFormTemplateBasicFields.document}`
	),
	documentList: [
		{
			name: 'Document 1',
			isRequired: false,
			type: EDocumentType.portrait,
		},
	],
}

export const doubleSidedPhotoDocumentDefaultValue = {
	titleField: translateIntlMsgString(
		`formTemplate.${EFormTemplateModelFields.doubleSidedPhotoDocument}`
	),
	specificFieldType: EFormTemplateModelFields.doubleSidedPhotoDocument,
	documentList: [
		{
			name: 'Recto',
			isRequired: true,
			type: EDocumentType.portrait,
		},
		{
			name: 'Verso',
			isRequired: true,
			type: EDocumentType.portrait,
		},
	],
}

// ///////////////////////////////////////////////////////////

export const textDefaultValue = {
	titleField: translateIntlMsgString(
		`formTemplate.${EFormTemplateBasicFields.text}`
	),
	isRequired: false,
	summaryDisplayField: false,
}

// ///////////////////////////////////////////////////////////

export const titleDefaultValue = {
	titleField: translateIntlMsgString(
		`formTemplate.${EFormTemplateBasicFields.title}`
	),
	titleType: EFormTemplateTitleType.littleTitle,
}

// ///////////////////////////////////////////////////////////

export const selectionDefaultValue = {
	titleField: translateIntlMsgString(
		`formTemplate.${EFormTemplateBasicFields.selection}`
	),
	isRequired: false,

	selectionType: EFormTemplateSelectionType.multiples,
	selectionsList: [
		{
			name: 'Option 1',
			selected: true,
		},
		{
			name: 'Option 2',
			selected: false,
		},
	],
}

// ///////////////////////////////////////////////////////////

export const numbersDefaultValue = {
	titleField: translateIntlMsgString(
		`formTemplate.${EFormTemplateBasicFields.numbers}`
	),
	isRequired: false,
}

// ///////////////////////////////////////////////////////////

export const signatureDefaultValue = {
	titleField: translateIntlMsgString(
		`formTemplate.${EFormTemplateBasicFields.signature}`
	),
	isRequired: false,
}

// ///////////////////////////////////////////////////////////

export const ratingDefaultValue = {
	titleField: translateIntlMsgString(
		`formTemplate.${EFormTemplateBasicFields.rating}`
	),
	isRequired: false,
	ratingType: EFormTemplateRatingType.slider,
	defaultValue: 5,
	min: 0,
	max: 10,
	step: 1,
}

// ///////////////////////////////////////////////////////////

export const instructionDefaultValue = {
	instructionText: '',
	hiddenForOthers: false,
	titleField: translateIntlMsgString('formTemplate.instruction'),
}

// ///////////////////////////////////////////////////////////

export const barCodeDefaultValue = {
	titleField: translateIntlMsgString(
		`formTemplate.${EFormTemplateBasicFields.barCode}`
	),
	isRequired: false,
}

// ///////////////////////////////////////////////////////////

export const mileageDefaultValue = {
	titleField: translateIntlMsgString(
		`formTemplate.${EFormTemplateVehicleFields.mileage}`
	),
	isRequired: false,
}

// // ////////////////////////////////////////////////////////////////////////////////////////////
// // Models fields DefaultValue
// // ////////////////////////////////////////////////////////////////////////////////////////////

export const picture4anglesModel = {
	titleField: translateIntlMsgString(
		`formTemplate.${EFormTemplateModelFields.picture4anglesModel}`
	),
	vehicleType: EFormTemplatePictureVehicleType.car,
	specificFieldType: EFormTemplateModelFields.picture4anglesModel,
	picturesList: [
		{
			name: translateIntlMsgString('formTemplate.frontFace'),
			isRequired: true,
			angleType: EFormTemplatePictureAngleType.frontFace,
		},
		{
			name: translateIntlMsgString('formTemplate.leftFace'),
			isRequired: true,
			angleType: EFormTemplatePictureAngleType.leftFace,
		},
		{
			name: translateIntlMsgString('formTemplate.backFace'),
			isRequired: true,
			angleType: EFormTemplatePictureAngleType.backFace,
		},
		{
			name: translateIntlMsgString('formTemplate.rightFace'),
			isRequired: true,
			angleType: EFormTemplatePictureAngleType.rightFace,
		},
	],
}

// ///////////////////////////////////////////////////////////

export const picture4anglesAndWheelsModel = {
	titleField: translateIntlMsgString(
		`formTemplate.${EFormTemplateModelFields.picture4anglesAndWheelsModel}`
	),
	vehicleType: EFormTemplatePictureVehicleType.car,
	specificFieldType: EFormTemplateModelFields.picture4anglesAndWheelsModel,
	picturesList: [
		{
			name: translateIntlMsgString('formTemplate.frontFace'),
			isRequired: true,
			angleType: EFormTemplatePictureAngleType.frontFace,
		},
		{
			name: translateIntlMsgString('formTemplate.wheelFaceLeft'),
			isRequired: true,
			angleType: EFormTemplatePictureAngleType.wheel,
		},
		{
			name: translateIntlMsgString('formTemplate.leftFace'),
			isRequired: true,
			angleType: EFormTemplatePictureAngleType.leftFace,
		},
		{
			name: translateIntlMsgString('formTemplate.wheelBackLeft'),
			isRequired: true,
			angleType: EFormTemplatePictureAngleType.wheel,
		},
		{
			name: translateIntlMsgString('formTemplate.backFace'),
			isRequired: true,
			angleType: EFormTemplatePictureAngleType.backFace,
		},
		{
			name: translateIntlMsgString('formTemplate.wheelBackRight'),
			isRequired: true,
			angleType: EFormTemplatePictureAngleType.wheel,
		},
		{
			name: translateIntlMsgString('formTemplate.rightFace'),
			isRequired: true,
			angleType: EFormTemplatePictureAngleType.rightFace,
		},
		{
			name: translateIntlMsgString('formTemplate.wheelFaceRight'),
			isRequired: true,
			angleType: EFormTemplatePictureAngleType.wheel,
		},
	],
}

// ///////////////////////////////////////////////////////////
