/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { EFormModalMode } from '../../../utils/app-models'
import { IModalUtils } from '../../../utils/hooks'
import { mergeSchemaValidation } from '../formsUtils'
import FormModalContainer from './formModalContainer'
import { FormModalContext } from './FormModalContext'
import { IFormModalConfig } from './formModalType'

interface IFormModalProviderProps {
	modalUtils: IModalUtils
	formModalConfig: IFormModalConfig
}

const FormModalProvider: React.FC<IFormModalProviderProps> = ({
	modalUtils,
	formModalConfig,
}) => {
	const [loading, setLoading] = useState(false)
	const [validationLoading, setValidationLoading] = useState(false)
	const [data, setData] = useState<any>({})
	const [errors, setErrors] = useState<any>({})
	const [tabCounter, setTabCounter] = useState<any>({})
	const [selectedTab, setSelectedTab] = useState<number>(0)
	const [canValidate, setCanValidate] = useState<boolean>(true)
	const [curentEditedElement, setCurentEditedElement] = useState(null)
	const [mode, setMode] = useState(
		formModalConfig?.initialMode || EFormModalMode.edit
	)

	useEffect(() => {
		if (formModalConfig?.initializeData) {
			formModalConfig.initializeData({
				setValues,
				setLoading,
				setCurentEditedElement,
				setTabCounter,
				mode,
			})
		}
	}, [])

	const setValue = useCallback(
		(name: string, value: any) => {
			setData({ ...data, [name]: value })
		},
		[data]
	)

	const setValues = useCallback(
		(values: any[]) => setData({ ...data, ...values }),
		[data]
	)

	const triggerError = useCallback(
		async (arg) => {
			const { fieldName = null, specificData = null } = arg || {}
			if (fieldName) {
				const res = await formModalConfig.formModalTabConfig[selectedTab].schema
					?.validateAt(fieldName, data, { abortEarly: true }) // abortEarly to true -> Throw on the first error (to avoid error message  ex: "2 errors occurred")
					.then(() => {
						const { [fieldName]: _selectedErrorField, ...restErrors } = errors
						setErrors(restErrors)
						return false
					})
					.catch((err) => {
						setErrors({
							...errors,
							[err?.path]: err?.message,
						})
						return true
					})
				return res
			} else {
				const newErrors = {}

				const isInError = await formModalConfig.formModalTabConfig[
					selectedTab
				].schema
					?.validate(
						{ ...data, ...(specificData || {}) },
						{ abortEarly: false }
					)
					.then(() => {
						setErrors({})
						return false
					})
					.catch((err) => {
						err?.inner?.forEach((error) => {
							newErrors[error?.path] = error?.message
						})
						setErrors({ ...newErrors })
						return true
					})
				return isInError
			}
		},
		[selectedTab, formModalConfig, data, errors]
	)

	const triggerAllErrors = useCallback(async () => {
		const newErrors = {}
		const isInError = await mergeSchemaValidation(
			formModalConfig.formModalTabConfig
		)
			?.validate(data, { abortEarly: false })
			.then(() => {
				return false
			})
			.catch((err) => {
				err?.inner?.forEach((error) => {
					newErrors[error?.path] = error?.message
				})
				return true
			})

		setErrors({ ...newErrors })
		return isInError
	}, [formModalConfig, data])

	const resetContext = useCallback((defaultValues) => {
		setData(defaultValues || {})
		setErrors({})
	}, [])

	const resetError = useCallback(
		(fieldToReset: string): void => {
			const { [fieldToReset]: _, ...restErrors } = errors
			setErrors({ ...restErrors })
		},
		[setErrors, errors]
	)

	const context = useMemo(
		() => ({
			selectedTab,
			setSelectedTab,
			tabCounter,
			setTabCounter,
			data,
			setValue,
			setValues,
			curentEditedElement,
			setCurentEditedElement,
			mode,
			setMode,
			loading,
			setLoading,
			validationLoading,
			setValidationLoading,
			canValidate,
			setCanValidate,
			triggerAllErrors,
			triggerError,
			errors,
			setErrors,
			resetContext,
			modalUtils,
			formModalConfig,
			resetError,
		}),
		[
			validationLoading,
			canValidate,
			loading,
			data,
			resetContext,
			setValue,
			setValues,
			errors,
			modalUtils,
			tabCounter,
			selectedTab,
			curentEditedElement,
			resetError,
		]
	)

	return (
		<FormModalContext.Provider value={context as any}>
			{<FormModalContainer />}
		</FormModalContext.Provider>
	)
}

export default memo(FormModalProvider)
