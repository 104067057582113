import React, { useCallback, useRef } from 'react'

import {
	ListItemIcon,
	ListItemText,
	ListItemButton,
	Popover,
} from '@mui/material'

import { useFormatMessage } from '../../../../utils/hooks'
import PermissionsHandler from '../../../permissionsHandler'
import ActionBtn from './ActionBtn'

const listItemTextCSS = {
	'& span': { fontSize: ({ typography }) => typography.body2.fontSize },
}

interface IActionBtnExpanded {
	permissionsName?
	duplicatedTableRow?
	onClick?
	duplicate?
	open?
	onClose?
	onMouseDown?
	txtIntlMsg
	Icon
	dataTestId?: string
	setDoNotDuplicate?
}

const ActionBtnExpanded: React.FC<IActionBtnExpanded> = ({
	permissionsName,
	duplicatedTableRow,
	onClick,
	duplicate,
	open,
	onClose,
	onMouseDown,
	txtIntlMsg,
	Icon,
	dataTestId = '',
	setDoNotDuplicate,
}) => {
	const intlMsg = useFormatMessage()
	const listItemRef = useRef<HTMLDivElement>(null)

	const handleDoNotDuplicate = useCallback(
		(doNotDuplicate) => () => {
			setDoNotDuplicate(doNotDuplicate)
			duplicatedTableRow()
		},
		[]
	)

	return (
		<>
			<PermissionsHandler permissionsName={permissionsName}>
				<ListItemButton
					ref={listItemRef}
					onClick={duplicate?.length ? onClick : duplicatedTableRow}
					onMouseDown={onMouseDown}
					data-test-id={dataTestId}
				>
					<ListItemIcon sx={{ minWidth: '0px' }}>
						<Icon sx={{ mr: 2 }} />
					</ListItemIcon>
					<ListItemText primary={intlMsg(txtIntlMsg)} sx={listItemTextCSS} />
				</ListItemButton>
			</PermissionsHandler>

			{!!duplicate?.length && (
				<Popover
					open={open}
					anchorEl={listItemRef.current}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					onClose={onClose}
				>
					<ActionBtn
						permissionsName={permissionsName}
						onClick={duplicatedTableRow}
						txtIntlMsg={'misc.allM'}
					/>
					{duplicate.map(({ txtIntlMsg, doNotDuplicate }, index) => (
						<ActionBtn
							key={index}
							permissionsName={permissionsName}
							onClick={handleDoNotDuplicate(doNotDuplicate)}
							txtIntlMsg={txtIntlMsg}
						/>
					))}
				</Popover>
			)}
		</>
	)
}

export default ActionBtnExpanded
