import React from 'react'

import CustomLink from '../../../_overrideMui/Link/CustomLink'

interface IDataListPhone {
	text: string
}

const DataListPhone: React.FC<IDataListPhone> = ({ text }) => {
	return (
		<CustomLink
			variant='text1Bold'
			variantlink='primaryLink'
			disabled={!text}
			href={text ? `tel:${text}` : undefined}
		>
			{text || '-'}
		</CustomLink>
	)
}

export default DataListPhone
