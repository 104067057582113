/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const deleteDuplicateCategoriesTask = (allProductCategoriesAndTask) => {
	const formatValues = {}

	allProductCategoriesAndTask?.forEach(
		({ productCategory: { name: nameCategory }, name: nameProduct }) => {
			// if category exist push name product to existing category
			if (formatValues[nameCategory]) {
				formatValues[nameCategory] = [
					...(new Set([...formatValues[nameCategory], nameProduct]) as any), // insert avoiding duplicate values
				]
			}
			// else add new category & product name
			else {
				formatValues[nameCategory] = [nameProduct]
			}
		}
	)

	return formatValues
}

export const categoriesAndTaskToString = (categoriesAndTask) => {
	let finalString = ''

	Object.entries(categoriesAndTask).map(([key, values]: any) => {
		finalString += key + ' : '
		values.forEach(
			(val, i) =>
				(finalString += `${val} ${values.length === i + 1 ? '; ' : ', '}`)
		)
	})

	return finalString || '-'
}

export const taskToString = (categoriesAndTask) => {
	let finalString = ''

	const tasks: any = []

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	Object.entries(categoriesAndTask).map(([_key, values]: any) => {
		tasks.push(values)
	})

	tasks.forEach(
		(val, i) => (finalString += `${val} ${tasks.length === i + 1 ? '' : ', '}`)
	)

	return finalString || '-'
}
