export default {
	documents: 'Documents',

	ref: 'Title',
	type: 'Type',
	typeDocument: 'Document Type',

	addDocument: 'Add Document',
	editDocument: 'Edit Document',

	// document categories
	PV: 'Contravention Notice',
	CG: 'Vehicle Registration Certificate',
	CSA: 'Administrative Situation Certificate',
	CAS: 'Insurance Certificate',
	CTA: 'Insurance Contract',
	EAS: 'Insurance Schedule',
	CA: 'Amicable Report',
	CLOC: 'Rental Contract',
	ELOC: 'Rental Schedule',
	CT: 'Vehicle Inspection',
	DV: 'Quote',
	OTHER: 'Other',
	CCS: 'Certificate of Transfer',
	PVB: 'Minutes of Proceedings',
}
