import React, { memo } from 'react'
import { Box } from '@mui/material'

interface ITableSideNavItemColor {
	count: number
	isSelected?
}

const TableSideNavItemCount: React.FC<ITableSideNavItemColor> = ({
	count,
	isSelected,
}) => {
	const countItemsCSS = {
		backgroundColor: isSelected ? '#dcf0fd' : '#ebebeb',
		borderRadius: '50%',
		color: '#5e5e5e',
		display: 'inline-block',
		fontWeight: 'bold',
		lineHeight: '28px',
		textAlign: 'center',
		width: '28px',
		minWidth: '28px',
		marginLeft: 'auto!important',
	}

	return <Box sx={countItemsCSS}>{count}</Box>
}

export default memo(TableSideNavItemCount)
