import React, {
	Fragment,
	useCallback,
	useContext,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import { Box, ListItemButton, ListItemText, Stack } from '@mui/material'
import { MultiNavTableContext } from '../../../MultiNavTableProvider'

/////////////////////////////////////////////////////////
// Explication BUG
/////////////////////////////////////////////////////////

// To be able to use onClick inside a parent that already has an onClick handler, you need to use Event.preventDefault()
// and Event.stopPropagation().However, the issue arises when the edit component, which opens the editing modal,
// malfunctions(closes when clicked inside).The solution found is to position BlockAction as absolute (outside the componant with the main onClick)
// and make the component hidden a second time next to BlockLeft so that it can display the text with ellipsis('...')

const containerNavItemCSS = {
	'& .hoverDisplay': {
		opacity: 0,
		transition: '0.1s',
	},
	'&:hover': {
		'& .hoverDisplay': {
			opacity: 1,
			transition: '0.5s',
		},
	},
}

const ListItemButtonCSS = {
	height: '50px',
	'&.MuiButtonBase-root&:hover': { backgroundColor: '#f5f5f5' },
	'&.MuiButtonBase-root.Mui-selected&:hover': { backgroundColor: '#edf7fe' },
	'&.Mui-selected': { backgroundColor: '#edf7fe' },
}

const isSelectedCSS = {
	position: 'absolute',
	borderRight: ({ palette }) => `solid 4px ${palette.primary.main}!important`,
	top: 0,
	left: '-5px',
	borderRadius: '268px',
	width: '9px',
	height: '100%',
}

const BlockActionCSS = {
	position: 'absolute',
	left: 0,
	zIndex: 1,
	top: 0,
	height: '50px',
}

const BlockLeftCSS = {
	display: 'flex',
	alignItems: 'center',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
}

interface ITableSideNavItem {
	indexItem: number
	data: any
}

const TableSideNavItem: React.FC<ITableSideNavItem> = ({ indexItem, data }) => {
	const BlockLeftRef = useRef(null) as any
	const [positionBlockAction, setPositionBlockAction] = useState('')

	const positionBlockActionCSS = useMemo(
		() => ({ left: positionBlockAction }),
		[positionBlockAction]
	)

	const { tableSideNavConfig, handleListItemClick, sideNavSelectedId } =
		useContext(MultiNavTableContext) || {}

	const isSelected: boolean = useMemo(
		() => data.id === sideNavSelectedId,
		[data, sideNavSelectedId]
	)

	const onListItemClick = useCallback(
		(event) => {
			handleListItemClick(event, indexItem)
		},
		[indexItem, handleListItemClick]
	)

	const SelectedBar = useCallback(
		() => (isSelected ? <Box sx={isSelectedCSS} /> : <></>),
		[isSelected]
	)

	const BlockLeft: any = useCallback(
		() => (
			<Box ref={BlockLeftRef} sx={BlockLeftCSS}>
				{tableSideNavConfig?.listItemConfig?.blockLeft?.map((item, i) => (
					<Fragment key={i}>{item(data, isSelected)}</Fragment>
				))}
			</Box>
		),
		[tableSideNavConfig, data, isSelected]
	)

	const BlockAction: any = useCallback(
		() =>
			tableSideNavConfig?.listItemConfig?.blockAction?.map((item, i) => (
				<Fragment key={i}>{item(data, isSelected)}</Fragment>
			)),
		[tableSideNavConfig, data, isSelected]
	)
	const BlockRight: any = useCallback(
		() =>
			tableSideNavConfig?.listItemConfig?.blockRight?.map((item, i) => (
				<Fragment key={i}>{item(data, isSelected)}</Fragment>
			)),
		[tableSideNavConfig, data, isSelected]
	)

	useLayoutEffect(() => {
		// BlockAction have absolute position, need to calculate his position
		if (BlockLeftRef.current) {
			const width = BlockLeftRef.current.offsetWidth
			setPositionBlockAction(`${width + 16}px`)
		}
	}, [positionBlockAction, BlockLeft])

	return (
		<Box position='relative' sx={containerNavItemCSS}>
			<ListItemButton
				dense
				selected={isSelected}
				onClick={onListItemClick}
				sx={ListItemButtonCSS}
				disableTouchRipple
				disableRipple
			>
				<ListItemText>
					<SelectedBar />

					<Stack direction='row' alignItems='center'>
						<BlockLeft />

						{/* !important -> read Explication BUG (top on this page)  */}
						{BlockAction && (
							<Stack direction='row' alignItems='center' visibility='hidden'>
								<BlockAction />
							</Stack>
						)}
						<BlockRight />
					</Stack>
				</ListItemText>
			</ListItemButton>

			{BlockAction && (
				<Stack
					direction='row'
					alignItems='center'
					sx={BlockActionCSS}
					style={positionBlockActionCSS}
				>
					<BlockAction />
				</Stack>
			)}
		</Box>
	)
}

export default TableSideNavItem
