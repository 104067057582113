import React, { useCallback, useContext } from 'react'
import toast from 'react-hot-toast'

import {
	formatCategoryProductModalCreateDefaultValues,
	formatCategoryProductModalEditDefaultValues,
	formatCategoryProductModalOnValidateData,
} from './catalogueProductSideNavModalUtils'
import {
	useCreateCategory,
	useUpdateCategory,
} from '../../../../../../api/gql/mutations/catalogProductsMutation'
import { IModalUtils, useFormatMessage } from '../../../../../../utils/hooks'
import { EFormModalMode } from '../../../../../../utils/app-models'
import FormModal from '../../../../../../components/forms/formModal'
import catalogueProductSideNavModalConfig from './catalogueProductSideNavModalConfig'
import { useLazyCategoryFormModal } from '../../../../../../api/gql/queries/catalogProductQueries'
import { ProductCatalogueContext } from '../../../ProductCatalogueProvider'
import { MultiNavTableContext } from '../../../../../../components/multiNavTable/MultiNavTableProvider'

interface ICatalogueProductSideNavModal {
	categoryId?: string | null
	modalUtils: IModalUtils
	mode?: EFormModalMode
	onClose?: any
}

const CatalogueProductSideNavModal: React.FC<ICatalogueProductSideNavModal> = ({
	modalUtils,
	mode,
	categoryId,
	onClose,
}) => {
	const intlMsg = useFormatMessage()
	const { idModule } = useContext(ProductCatalogueContext)
	const { setSideNavSelectedId } = useContext(MultiNavTableContext)
	const [updateProductCategory] = useUpdateCategory()
	const [createProductCategory] = useCreateCategory()
	const { getProductCategory } = useLazyCategoryFormModal()

	const onValidate = useCallback(
		async ({ data, setValidationLoading, closeModal }) => {
			setValidationLoading(true)
			const mutate =
				mode === EFormModalMode.edit
					? updateProductCategory
					: createProductCategory

			await mutate({
				variables: {
					input: formatCategoryProductModalOnValidateData({
						data,
						mode,
						categoryId,
						idModule,
					}),
				},
				refetchQueries: ['getProductCategories'],
			})
				.then((res) => {
					mode === EFormModalMode.edit
						? toast.success('success.categoryUpdate')
						: toast.success('success.categoryCreate')
					setValidationLoading(false)

					setSideNavSelectedId(
						res?.data?.[
							mode === EFormModalMode.create
								? 'createProductCategory'
								: 'updateProductCategory'
						]?.productBaseCategory?.id
					)

					closeModal()
				})
				.catch(() => setValidationLoading(false))
		},
		[categoryId, mode, idModule]
	)

	const initializeData = useCallback(
		async ({ setValues, setLoading }) => {
			if (mode === EFormModalMode.edit) {
				setLoading(true)
				getProductCategory({ variables: { id: categoryId } })
					.then((res) => {
						const data = res?.data?.productCategory
						if (data) {
							setValues(formatCategoryProductModalEditDefaultValues(data))
						}
						setLoading(false)
					})
					.catch(() => setLoading(false))
			} else {
				setLoading(true)
				setValues(formatCategoryProductModalCreateDefaultValues())
				setLoading(false)
			}
		},
		[mode, categoryId]
	)

	return (
		<FormModal
			modalUtils={modalUtils}
			formModalTabConfig={catalogueProductSideNavModalConfig}
			formModalName={intlMsg(
				mode === EFormModalMode.edit
					? 'catalogProduct.editCategoryProduct'
					: 'catalogProduct.addCategoryProduct'
			)}
			initialMode={mode}
			onValidate={onValidate}
			validateBtnText={intlMsg('misc.validate')}
			cancelBtnText={intlMsg('misc.cancel')}
			maxWidth='595px'
			width='50%'
			minHeight='50px'
			initializeData={initializeData}
			onClose={onClose}
		/>
	)
}

export default CatalogueProductSideNavModal
