import React, { useCallback, useContext, useMemo } from 'react'

import { Stack, Button } from '@mui/material'

import { useFormatMessage } from '../../../../../../../../utils/hooks'
import { FormModalContext } from '../../../../../../../../components/forms/formModal/FormModalContext'
import VehicleAssignmentModalConfirm from './VehicleAssignmentModalConfirm'
import { VehiclesTransportContext } from '../VehiclesTransportProvider'
import VehicleModal from '../../../../../../vehicles/vehicleModal'
import { EFormModalMode } from '../../../../../../../../utils/app-models'
import FormSelectSearchQuery from '../../../../../../../../components/forms/formFields/formSelectSearchQuery'

interface ISearchVehicle {}

const SearchVehicle: React.FC<ISearchVehicle> = () => {
	const intlMsg = useFormatMessage()
	const { data, setValue } = useContext(FormModalContext)

	const {
		modalVehicleAssignmentUtils,
		confirmVehicleAssignment,
		modalCreateVehicleUtils,
		forcedImmatOrVin,
		getResponseVehicleMutation,
		onClearVehicle,
		handleChangeSearch,
		setDebouncedState,
		loadingVehicles,
		selectElementsVehicle,
		onValidateVehicle,
		onCreateNewNewVehicle,
		isNoVehicleFound,
		invalidImmat,
	} = useContext(VehiclesTransportContext)

	const forcedImmat = useMemo(
		() =>
			forcedImmatOrVin && forcedImmatOrVin?.length < 12
				? forcedImmatOrVin
				: null,
		[forcedImmatOrVin]
	)
	const forcedVin = useMemo(
		() =>
			forcedImmatOrVin && forcedImmatOrVin?.length >= 12
				? forcedImmatOrVin
				: null,
		[forcedImmatOrVin]
	)

	const forcedCustomerId = useMemo(
		() => data?.orderingCustomer?.value?.id,
		[data?.orderingCustomer]
	)

	const forcedSiteId = useMemo(
		() => data?.orderingSite?.value?.id,
		[data?.orderingSite]
	)

	const handleOnValidateVehicle = useCallback(
		({ fieldName, option }) => {
			setValue(fieldName, option)
			option && onValidateVehicle(option)
		},
		[setValue, onValidateVehicle]
	)

	const clearSearchField = useCallback(() => {
		setValue('immatOrVin', '') // Supposons que 'immatOrVin' soit le nom du champ de recherche
	}, [setValue])

	return (
		<>
			<Stack direction='row' alignItems='baseline' spacing={1}>
				<FormSelectSearchQuery
					errorKey='selectedVehicles'
					name='immatOrVin'
					label={intlMsg('misc.vehicle')}
					context={FormModalContext}
					selectElements={selectElementsVehicle}
					isRequired
					specialOnInputChange={handleChangeSearch}
					isLoading={loadingVehicles}
					setDebouncedState={setDebouncedState}
					minCharacters={3}
					placeholder={intlMsg('search.vehicle')}
					specialOnClear={onClearVehicle}
					specialOnChange={handleOnValidateVehicle}
					missingText={intlMsg('search.missingFormSelectSearchQuery')}
					triggered
					clearOnBlur={false}
					forceUpdateTrigger={isNoVehicleFound}
				/>

				{isNoVehicleFound && (
					<Button
						variant='contained'
						onClick={onCreateNewNewVehicle}
						disabled={invalidImmat}
					>
						{intlMsg('misc.create')}
					</Button>
				)}
			</Stack>

			{modalVehicleAssignmentUtils?.isVisible && (
				<VehicleAssignmentModalConfirm
					onClose={modalVehicleAssignmentUtils?.closeModal}
					open={modalVehicleAssignmentUtils?.isVisible}
					handleConfirm={confirmVehicleAssignment}
					clearSearchField={clearSearchField}
				/>
			)}

			{modalCreateVehicleUtils?.isVisible && (
				<VehicleModal
					modalUtils={modalCreateVehicleUtils}
					mode={EFormModalMode.create}
					forcedImmat={forcedImmat}
					forcedVin={forcedVin}
					forcedCustomerId={forcedCustomerId}
					forcedSiteId={forcedSiteId}
					getResponseVehicleMutation={getResponseVehicleMutation}
				/>
			)}
		</>
	)
}
export default SearchVehicle
