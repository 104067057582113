import React, { useCallback } from 'react'

import dayjs from 'dayjs'
import { useNavigate, useLocation } from 'react-router-dom'
import { Typography, Stack, Divider, Box } from '@mui/material'

import { useFormatMessage } from '../../../../../utils/hooks'
import MembersCell from '../../../../../components/tableV2/tableCell/MembersCell'
import MembersPopOver from '../../../../../components/tableV2/tableCell/tableCellPopOver/MembersPopOver'
import { ROUTES } from '../../../../../routes/consts'
import { stringifiedColor } from '../../../../../utils/color'
import PermissionsHandler from '../../../../../components/permissionsHandler'
import { EModule, EPermissionScope } from '../../../../../utils/app-models'
import UserAvatar from '../../../../../components/avatar/UserAvatar'
import CalendarEventFooter from '../CalendarEventFooter'
import { useRemoveIntervention } from '../../../../../api/gql/mutations/interventionsMutation'
import VehiclesInterventionPopover from '../../../../../components/multiItemsPopoverList/popover/vehiclesInterventionPopover'
import CalendarEventHeader from '../CalendarEventHeader'

const width33 = { width: '33%' }
const dividerCSS = {
	position: 'relative',
	width: ' 110%',
	marginLeft: '-5%',
	mt: 2,
	mb: 2,
}
const statusCSS = {
	padding: ({ spacing }) => spacing(0, 0.5),
	color: 'white',
	borderRadius: 1,
}

interface IInterventionControlEventInfo {
	event: any
	handleClose: any
	setOpenModalIntervention: any
	setFormModalEdit: any
}

const InterventionControlEventInfo: React.FC<IInterventionControlEventInfo> = ({
	event,
	handleClose,
	setOpenModalIntervention,
	setFormModalEdit,
}) => {
	const intlMsg = useFormatMessage()
	const navigate = useNavigate()
	const location = useLocation()

	const orderingContactObj = event?.resource?.orderingContact
		? {
				firstName: event.resource.orderingContact?.firstName,
				lastName: event.resource.orderingContact?.lastName,
				id: event.resource.orderingContact?.id,
		  }
		: null

	const handleEdit = useCallback(() => {
		setOpenModalIntervention(true)
		handleClose()
	}, [])

	const handleView = useCallback(() => {
		navigate(
			`${location?.pathname}${ROUTES.VIEWMODAL.INTERVENTION_REFERENCES.url}${event?.resource?.id}`
		)
		handleClose()
	}, [event, location?.pathname, navigate])

	return (
		<Stack sx={{ minWidth: '400px' }}>
			<Box p={2}>
				<CalendarEventHeader
					interType={intlMsg(
						`interventions.${event?.resource?.interventionType}`
					)}
					title={event?.title}
					status={event?.resource?.status?.name}
					statusColor={event?.resource?.status?.color}
					handleClose={handleClose}
				/>

				<Stack spacing={1}>
					<Stack direction='row'>
						<Typography color='inherit' variant='caption' sx={width33}>
							{intlMsg('misc.account')}
						</Typography>
						<Typography color='inherit' variant='caption'>
							{event?.resource?.orderingCustomer?.name}
						</Typography>
					</Stack>

					<Stack direction='row'>
						<Typography color='inherit' variant='caption' sx={width33}>
							{intlMsg('misc.site')}
						</Typography>
						<Typography color='inherit' variant='caption'>
							{event?.resource?.orderingSite?.name}
						</Typography>
					</Stack>

					<PermissionsHandler modulesName={[EModule.crm]}>
						<Stack direction='row'>
							<Typography color='inherit' variant='caption' sx={width33}>
								{intlMsg('misc.contact')}
							</Typography>

							{orderingContactObj ? (
								<UserAvatar
									userName={{
										firstName: orderingContactObj.firstName,
										lastName: orderingContactObj.lastName,
									}}
									userId={orderingContactObj.id}
									isContact
								/>
							) : (
								'-'
							)}
						</Stack>
					</PermissionsHandler>

					<Stack direction='row'>
						<Typography color='inherit' variant='caption' sx={width33}>
							{intlMsg('interventions.actors')}
						</Typography>

						<MembersCell
							data={event?.resource?.actors?.map(
								({ id, lastName, firstName, account }) => {
									return {
										firstName,
										lastName,
										id,
										account,
									}
								}
							)}
							Popover={MembersPopOver}
							zIndexPopover={1300}
							displayManager={false}
						/>
					</Stack>
				</Stack>

				<Divider flexItem sx={dividerCSS} variant='fullWidth' />

				<Stack spacing={1}>
					<Stack direction='row'>
						<Typography color='inherit' variant='caption' sx={width33}>
							{intlMsg('misc.status')}
						</Typography>

						<Box
							sx={statusCSS}
							style={{
								background: stringifiedColor(
									event?.resource?.status?.color || 'grey',
									400
								),
							}}
						>
							<Typography color='inherit' variant='caption'>
								{event?.resource?.status?.name}
							</Typography>
						</Box>
					</Stack>

					<Stack direction='row'>
						<Typography color='inherit' variant='caption' sx={width33}>
							{intlMsg('interventions.start')}
						</Typography>

						<Typography color='inherit' variant='caption'>
							{dayjs(event?.start).format('DD/MM/YYYY HH:mm')}
						</Typography>
					</Stack>

					<Stack direction='row'>
						<Typography color='inherit' variant='caption' sx={width33}>
							{intlMsg('interventions.end')}
						</Typography>

						<Typography color='inherit' variant='caption'>
							{dayjs(event?.end).format('DD/MM/YYYY HH:mm')}
						</Typography>
					</Stack>

					<Stack direction='row'>
						<Typography color='inherit' variant='caption' sx={width33}>
							{intlMsg('misc.vehicles')}
						</Typography>

						<VehiclesInterventionPopover
							data={
								event?.resource?.interventionControlProgrammedVehicles || []
							}
						/>
					</Stack>
				</Stack>
			</Box>

			<CalendarEventFooter
				permissionsName={{
					update: [EPermissionScope.interventionUpdate],
					remove: [EPermissionScope.interventionRemove],
					duplicate: [EPermissionScope.interventionCreate],
				}}
				deleted={{
					query: useRemoveIntervention,
					refreshQuery: [
						'getInterventionNumbersByCustomer',
						'getInterventionNumbersBySite',
						'interventionsList',
						'interventionsListForCalendar',
					],
					successMessageDelete: 'success.interventionDeleted',
					messageConfirm: {
						title: 'confirmModal.deleteInterventionTitle',
						message: 'confirmModal.deleteInterventionMessage',
					},
				}}
				handleEdit={handleEdit}
				handleView={handleView}
				handleCloseModalInfos={handleClose}
				interventionId={event?.resource?.id}
				setFormModalEdit={setFormModalEdit}
			/>
		</Stack>
	)
}

export default InterventionControlEventInfo
