import React, { useCallback, useContext, useMemo } from 'react'

import { Box } from '@mui/material'

import Table from '../../../../../../../../components/tableV2'
import { selectVehiclesModalContentTableConfig } from './selectVehiclesModalContentTableConfig'
import {
	FormModalContext,
	IFormModalContext,
} from '../../../../../../../../components/forms/formModal/FormModalContext'
import FormBtnModal from '../../../../../../../../components/forms/formModalBtn'
import VehicleModal from '../../../../../../vehicles/vehicleModal'
import { EFormModalMode } from '../../../../../../../../utils/app-models'
import { useFormatMessage } from '../../../../../../../../utils/hooks'

const overflowCSS = {
	width: '100%',
	height: '100%',
	overflow: 'auto',
	display: 'flex',
	flexDirection: 'column',
	flexWrap: 'nowrap',
	justifyContent: 'start',
	alignContent: 'start',
	alignItems: 'start',
}

interface ISelectVehiclesModalContent {}

const SelectVehiclesModalContent: React.FC<
	ISelectVehiclesModalContent
> = () => {
	const intlMsg = useFormatMessage()
	const { data, setValue } = useContext<IFormModalContext>(FormModalContext)

	const forcedCustomerId = useMemo(
		() => data?.finalCustomer?.value?.id || data?.orderingCustomer?.value?.id,
		[data?.orderingCustomer, data?.finalCustomer]
	)

	const othersQueryParams = useMemo(
		() => ({
			site: data?.finalSite?.value?.id || data?.orderingSite?.value?.id,
		}),
		[data?.orderingSite, data?.finalSite]
	)

	const selectedRow = useMemo(
		() => data?.selectedRow?.map((item) => item.vehicleId 
			? ({ ...item, id: item.vehicleId, ipv: item.id, vehicleId: undefined }) 
			: item
		) || [],
		[data?.selectedRow]
	)

	const setSelectedRow = useCallback(
		(newSelectedRow) => setValue('selectedRow', newSelectedRow),
		[setValue]
	)

	return (
		<Box sx={overflowCSS}>
			<Table
				tableConfig={selectVehiclesModalContentTableConfig}
				overrideConfigTable={{
					othersQueryParams,
					customStateSelectedRow: { selectedRow, setSelectedRow },
					hidden: { tableToolBar: { columnFilter: true, switchActive: true } },
					customBtnCreate: (
						<FormBtnModal
							btnText={intlMsg('vehicle.createVehicle')}
							modal={
								<VehicleModal
									modalUtils={{} as any}
									forcedCustomerId={forcedCustomerId}
									forcedSiteId={othersQueryParams?.site}
								/>
							}
							mode={EFormModalMode.create}
						/>
					),
				}}
			/>
		</Box>
	)
}
export default SelectVehiclesModalContent
