import React from 'react'
import { List } from '@mui/material'
import TableSideNavItem from './TableSideNavItem'

const cardCss = {
	overflowY: 'auto',
	maxHeight: '74%',
}

interface ITableSideNavList {
	navListData
}

const TableSideNavList: React.FC<ITableSideNavList> = ({ navListData }) => {
	return (
		<List component='nav' sx={cardCss}>
			{navListData?.map((data, i) => (
				<TableSideNavItem key={i} indexItem={i} data={data} />
			))}
		</List>
	)
}

export default TableSideNavList
