import React from 'react'

import Paper from '@mui/material/Paper'
import { Grid, Typography, Box } from '@mui/material'

import { ROUTES } from '../../../../routes/consts'
import { useFormatMessage } from '../../../../utils/hooks'
import { listCardViewModalCSS as css } from '../../../../styles/css/viewModal'
import { ListItemsData } from '../../../../components'
import { EModule } from '../../../../utils/app-models'

interface ISiteAccount {
	site
	customer
}

const SiteAccount: React.FC<ISiteAccount> = ({ site, customer }) => {
	const intlMsg = useFormatMessage()

	return (
		<Paper sx={css.radius}>
			<Box sx={{ mb: 2 }}>
				<Grid container spacing={1} sx={{ pt: 1 }}>
					<Grid item xs={12}>
						<Typography variant='body2' sx={css.ListSubheader}>
							{intlMsg('misc.owner')}
						</Typography>
					</Grid>
				</Grid>

				<Box sx={css.spaceRightLeft}>
					<ListItemsData
						nbCol={6}
						items={[
							{
								primaryText: 'misc.account',
								secondaryText: customer?.name || '-',
								...(customer?.id && {
									viewModalLink: `${ROUTES.VIEWMODAL.CUSTOMERS.url}${customer?.id}`,
								}),
								sizeColumn: 6,
								modules: [EModule.crm],
							},
							{
								primaryText: 'misc.site',
								secondaryText: site?.name || '-',
								...(site?.id && {
									viewModalLink: `${ROUTES.VIEWMODAL.SITE.url}${site?.id}`,
								}),

								sizeColumn: 6,
							},
						]}
					/>
				</Box>
			</Box>
		</Paper>
	)
}

export default SiteAccount
