/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

import { globalConfigPDF } from '../globalConfigPDF'

const { stylePropertyBlockTable } = globalConfigPDF

export const blockEmpty = (doc, intlMsg) => {
	return doc.autoTable({
		// Body add only the title (we use didParseCell after to change the title color & didDrawCell to add text with another color or custom field)
		body: [
			{ right: '', value: '', left: '' },
			{ right: '', value: intlMsg('pdfForms.noAlerts'), left: '' },
			{ right: '', value: '', left: '' },
		],

		columnStyles: {
			0: {
				columnWidth: 20,
				valign: 'middle',
			},
			1: { valign: 'middle', halign: 'center' },
			2: {
				columnWidth: 20,
				valign: 'middle',
			},
		},

		// Block position start Y (default use the last block final position Y)
		startY: doc.lastAutoTable.finalY,

		...stylePropertyBlockTable,
	})
}
