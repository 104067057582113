import React from 'react'
import translateIntlMsgString from '../../../../utils/translateIntlMsgString'
import EditUserModalInfo from './EditUserModalInfo'
import { editUserModalVaidationSchema } from './editUserModalVaidationSchema'

export const editUserModalConfig = [
	{
		tabName: translateIntlMsgString('misc.team'),
		component: <EditUserModalInfo />,
		schema: editUserModalVaidationSchema,
	},
]
