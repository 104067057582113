export default {
	selectRows: 'Lignes selectionnées',
	selectRow: 'Ligne selectionnée',
	displayCol: 'Afficher les colonnes',
	rowPerPage: 'Lignes par page',
	beforeImport:
		'Pour effectuer une importation aidez-vous du fichier modèle Excel téléchargeable ici :',
	modelExcel: 'Modèle Excel',
	importTitleSites: 'Importer des sites',
	importTitleVehicle: 'Importer des véhicules',
	alertImport:
		"Attention : les imports se font par compte, n'ajoutez dans votre fichier que les sites appartenant au compte sélectionné ci-dessous.",
	alertImportVehicle:
		"Les imports sont réalisés par compte et site. N'ajoutez dans votre fichier que les véhicules affectés au site sélectionné",
	downloadExcelTemplate: 'Télécharger le modèle',
	chooseAccount: 'Sélection du compte',
	chooseSite: 'Sélection du site',

	footerPagination1: 'affiche de',
	footerPagination2: 'à',
	footerPagination3: 'sur',
	footerPagination4: 'lignes',
}
