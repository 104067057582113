import React, { useMemo } from 'react'

import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { useFormatMessage } from '../../../../utils/hooks'
import { Divider, Grid, List, Box, Stack, Chip } from '@mui/material'

import DateToFrom from '../../../../components/date/DateToFrom'
import { listCardViewModalCSS as css } from '../../../../styles/css/viewModal'
import { ListItemsData } from '../../../../components'
import { ROUTES } from '../../../../routes/consts'
import { formatAdressGoogleMap } from '../../../../utils/string'
import ChipColorStatus from '../../../../components/chip/ChipColorStatus'
import UserAvatar from '../../../../components/avatar/UserAvatar'
import {
	EModule,
	EInterventionStatuses,
	EChipColorStatus,
} from '../../../../utils/app-models'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

interface IInterventions {
	interRef
	interventionType
	dateTo
	dateFrom
	status
	manualEntry
	contact
	customer
	site
	customerRequest
	siteRequest
	cancelComment
	cancelReason
}

const tooltipsSx = {
	tooltipsCSS: {
		padding: '6px 2px',
	},
	tooltipsCSSParagraph: {
		fontSize: '13px',
		'& >b': {
			fontWeight: '700',
		},
	},
	iconColor: {
		color: ({ palette }) => palette.grey[700],
		marginLeft: '10px',
	},
	boxContainer: {
		display: 'flex',
		alignItems: 'center',
	},
}

const Interventions: React.FC<IInterventions> = ({
	interRef,
	interventionType,
	dateTo,
	dateFrom,
	status,
	manualEntry,
	contact,
	customer,
	site,
	customerRequest,
	siteRequest,
	cancelComment,
	cancelReason,
}) => {
	const intlMsg = useFormatMessage()
	const textContent = useMemo(
		() => (
			<Box style={tooltipsSx?.tooltipsCSS}>
				<Box style={tooltipsSx?.tooltipsCSSParagraph} component='p'>
					{
						<Box component='b'>
							{cancelReason
								? intlMsg(`interventions.cancelReason_${cancelReason.code}`)
								: intlMsg(`interventions.cancelReason_NONE`)}
						</Box>
					}
				</Box>
				{cancelComment && (
					<Box style={tooltipsSx?.tooltipsCSSParagraph} component='p'>
						{cancelComment}
					</Box>
				)}
			</Box>
		),
		[cancelComment, cancelReason]
	)

	return (
		<>
			<Paper sx={css.radius}>
				<Box sx={{ mb: 2 }}>
					<Stack
						direction='row'
						alignItems='center'
						justifyContent='space-between'
						sx={css.space}
					>
						<Typography variant='h3'> {interRef || '-'}</Typography>

						<Chip
							variant='outlined'
							color='primary'
							label={intlMsg(`interventions.${interventionType}`)}
							size='small'
						/>
					</Stack>
					<Divider />
					{/* DATE */}
					<Box sx={css.space}>
						<Stack
							direction='row'
							alignItems='center'
							justifyContent='space-between'
						>
							<DateToFrom dateTo={dateTo} dateFrom={dateFrom} fontMd />
							<Box component='div' sx={tooltipsSx.boxContainer}>
								<>
									<ChipColorStatus
										size='small'
										label={status?.label}
										color={status?.color}
										code={status?.code}
										type={EChipColorStatus.intervention}
									/>
									{status?.code === EInterventionStatuses.canceled && (
										<Tooltip placement='right' title={textContent}>
											<InfoOutlinedIcon sx={tooltipsSx.iconColor} />
										</Tooltip>
									)}
								</>
							</Box>
						</Stack>
					</Box>
					<Divider />
					<Typography variant='body2' sx={{ ...css.ListSubheader, mt: 1 }}>
						{intlMsg('interventions.interventionLocation')}
					</Typography>
					<Box sx={css.spaceRightLeft}>
						<ListItemsData
							nbCol={6}
							items={[
								{
									primaryText: 'misc.account',
									secondaryText: customer?.name || '-',
									...(customer?.id && {
										viewModalLink: `${ROUTES.VIEWMODAL.CUSTOMERS.url}${customer?.id}`,
									}),
									sizeColumn: 6,
									modules: [EModule.crm],
								},
								{
									primaryText: 'misc.site',
									secondaryText: site?.name || '-',
									...(site?.id && {
										viewModalLink: `${ROUTES.VIEWMODAL.SITE.url}${site?.id}`,
									}),
									sizeColumn: 6,
								},
								{
									sizeColumn: 12,
									primaryText: 'misc.address',
									secondaryText: manualEntry?.finalAddressText
										? manualEntry.finalAddressText
										: `${site?.address?.address} - ${site?.address?.zipcode} - ${site?.address?.city}`,
									externalLink: manualEntry?.finalAddressText
										? formatAdressGoogleMap(
												manualEntry.finalAddressText,
												'',
												''
										  )
										: formatAdressGoogleMap(
												site?.address?.address,
												site?.address?.city,
												site?.address?.zipcode
										  ),
								},
							]}
						/>
					</Box>
					<Grid container spacing={1} sx={{ pt: 1, pb: 1 }}>
						<Grid item xs={12}>
							<Typography variant='body2' sx={css.ListSubheader}>
								{intlMsg('misc.contact')}
							</Typography>
						</Grid>

						<Grid item xs={6}>
							{contact?.id ? (
								<List>
									<UserAvatar
										userName={{
											firstName: contact?.firstName as string,
											lastName: contact?.lastName as string,
										}}
										userId={contact?.id}
										isContact
										displayName
										isListItemButton
									/>
								</List>
							) : (
								<Typography variant='caption' sx={css.ListSubheader}>
									{manualEntry?.finalContactText
										? manualEntry.finalContactText
										: '-'}
								</Typography>
							)}
						</Grid>
					</Grid>
					<Divider />
					<Typography variant='body2' sx={{ ...css.ListSubheader, mt: 1 }}>
						{intlMsg('interventions.intervetionRequestBy')}
					</Typography>
					<Box sx={css.spaceRightLeft}>
						<ListItemsData
							nbCol={6}
							items={[
								{
									primaryText: 'misc.account',
									secondaryText: customerRequest?.name || '-',
									viewModalLink: `${ROUTES.VIEWMODAL.CUSTOMERS.url}${customerRequest?.id}`,
									sizeColumn: 6,
									modules: [EModule.crm],
								},
								{
									primaryText: 'misc.site',
									secondaryText: siteRequest?.name || '-',
									viewModalLink: `${ROUTES.VIEWMODAL.SITE.url}${siteRequest?.id}`,
									sizeColumn: 6,
								},
							]}
						/>
					</Box>
				</Box>
			</Paper>
		</>
	)
}

export default Interventions
