import React, { useCallback, useEffect } from 'react'

import toast from 'react-hot-toast'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import {
	ClickAwayListener,
	Grid,
	Grow,
	Paper,
	Popper,
	Typography,
	Button,
	IconButton,
	Stack,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

import { useFormatMessage } from '../../../utils/hooks'
import ColorRadio from '../../forms/v1/formFields/ColorRadio'
import { InputText } from '../..'
import { useUpdateVehicleLocation } from '../../../api/gql/mutations/vehicleMutation'
import { locationVehicleSchema } from '../../../utils/validationSchema'

const paperLocationVehicleCSS = {
	p: 4,
	maxWidth: '328px',
	zIndex: 1000,
	position: 'relative',
	backgroundColor: 'white',
}
const positionIconCSS = {
	position: 'absolute',
	right: '30px',
	top: '25px',
}

interface ILocationVehicleForms {
	handleClose
	color
	location
	id
	anchorEl
	open
}

const LocationVehicleForms: React.FC<ILocationVehicleForms> = ({
	handleClose,
	color,
	location,
	id,
	anchorEl,
	open,
}) => {
	const intlMsg = useFormatMessage()
	const { control, handleSubmit, setValue } = useForm({
		resolver: yupResolver(locationVehicleSchema(intlMsg)),
	})

	// Mutation
	const [updateLocationVehicle] = useUpdateVehicleLocation()
	const update = useCallback(async (value) => {
		await updateLocationVehicle({
			variables: {
				input: {
					id,
					location: value?.location,
					locationColor: value?.color,
				},
			},
			refetchQueries: ['getVehiclesList', 'getWVehicle'],
		}).then(() => {
			toast.success('success.locationUpdate')
			handleClose()
		})
	}, [])

	// Set default values
	useEffect(() => {
		setValue('color', color ? color : '')
		location && setValue('location', location)
	}, [setValue])

	// OnSubmit
	const onSubmit = useCallback((data) => {
		update(data)
	}, [])

	// Delete location
	const deleteLocation = useCallback(() => {
		setValue('location', '')
		setValue('color', '')
	}, [])

	return (
		<>
			<Popper
				open={open}
				anchorEl={anchorEl}
				role='locationVehicle'
				transition
				style={{ zIndex: 10000 }}
			>
				{({ TransitionProps }) => (
					<Grow {...TransitionProps}>
						<Paper sx={paperLocationVehicleCSS}>
							<ClickAwayListener onClickAway={handleClose}>
								<div>
									<form onSubmit={handleSubmit(onSubmit)}>
										<Grid container spacing={2}>
											<Stack direction='row' mt={2}>
												<Typography variant='subtitle2' gutterBottom>
													{intlMsg('vehicle.locationTitle')}
												</Typography>
												<IconButton
													sx={positionIconCSS}
													aria-label='delete'
													size='small'
													onClick={deleteLocation}
												>
													<DeleteIcon />
												</IconButton>
											</Stack>
											<Grid item xs={12}>
												<InputText
													name='location'
													control={control}
													label={intlMsg('vehicle.location')}
												/>
											</Grid>
											<Typography
												variant='subtitle2'
												gutterBottom
												sx={{ mt: 2 }}
											>
												{intlMsg('vehicle.locationColor')}
											</Typography>
											<Grid item xs={12}>
												<ColorRadio name='color' control={control} />
											</Grid>
											<Grid item xs={12} sx={{ mr: 0.5 }}>
												<Button variant='contained' fullWidth type='submit'>
													{intlMsg('misc.validate')}
												</Button>
											</Grid>
										</Grid>
									</form>
								</div>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	)
}

export default LocationVehicleForms
