import React, { useState, useCallback } from 'react'
import ListItemIcon from '@mui/material/ListItemIcon'
import { Box, Stack, ListItemText, ListItem, List } from '@mui/material'
import FormCheckBoxOrSwitch from '../forms/formFields/FormCheckBoxOrSwitch'
import { FormModalContext } from '../forms/formModal/FormModalContext'

const listCSS = {
	borderRadius: 1,
	backgroundColor: ({ palette }) => palette.background.paper,
	p: 0,
	height: 1,
}

const listItemCSS = {
	borderBottom: 'solid 1px #dddddd61',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'items-center',
}

const boxContainerCSS = {
	flexGrow: '1',
	display: 'flex',
	'& > ul': {
		width: '100%',
	},
}

interface ICheckboxListIsManager {
	renderLeftPartTitle: (elem: any) => string
	handleOnScroll: (e: any) => void
	dataList: any[] // Add appropriate type for dataList
	playwrightKeySelector: string
}

const CheckboxListIsManager: React.FC<ICheckboxListIsManager> = ({
	renderLeftPartTitle,
	handleOnScroll,
	dataList,
	playwrightKeySelector,
}) => {
	const [checked, setChecked] = useState<number[]>([0])

	const handleToggle = useCallback(
		(value: number) => () => {
			const currentIndex = checked.indexOf(value)
			const newChecked = [...checked]

			if (currentIndex === -1) newChecked.push(value)
			else newChecked.splice(currentIndex, 1)

			setChecked(newChecked)
		},
		[checked]
	)

	const renderListItem = useCallback(
		(elem: any, i: number) => {
			const labelId = `checkbox-list-label-${elem.firstName}`

			return (
				<Box key={i} sx={listItemCSS}>
					<ListItem role={undefined} dense onClick={handleToggle(elem.id)}>
						<ListItemIcon>
							<FormCheckBoxOrSwitch
								name={elem.id}
								// label={intlMsg('administration.platformAcces')}
								context={FormModalContext}
								playwrightId={`Playwright-CheckboxListIsManager-switch-${elem[playwrightKeySelector]}`}
							/>
						</ListItemIcon>
						<ListItemText id={labelId} primary={renderLeftPartTitle(elem)} />
					</ListItem>
					<Stack direction='row' alignItems='center'>
						<FormCheckBoxOrSwitch
							name={`isManager-${elem.id}`}
							// label={intlMsg('administration.platformAcces')}
							context={FormModalContext}
							playwrightId={`Playwright-CheckboxListIsManager-switchIsManager-${elem[playwrightKeySelector]}`}
						/>
					</Stack>
				</Box>
			)
		},
		[handleToggle, renderLeftPartTitle, playwrightKeySelector]
	)

	return (
		<Box sx={boxContainerCSS}>
			<List sx={listCSS} onScroll={handleOnScroll}>
				{dataList?.map(renderListItem)}
			</List>
		</Box>
	)
}

export default CheckboxListIsManager
