import React, { useContext } from 'react'

import { Stack } from '@mui/material'

import { TitleField, RequireFieldTxt } from '../fieldEditForms/editFormsField'
import { FormTemplateContext } from '../../../../../../utils/contexts/FormTemplateContext'
import SwitchForm from '../../../../../../components/forms/v1/formFields/SwitchForm'
import { useFormatMessage } from '../../../../../../utils/hooks'

interface IFieldEditFormLayoutTxt {}

const FieldEditFormLayoutTxt: React.FC<IFieldEditFormLayoutTxt> = () => {
	const {
		drawerUtils: { controlDrawer: control },
	} = useContext(FormTemplateContext)
	const intlMsg = useFormatMessage()

	return (
		<Stack spacing={2}>
			<SwitchForm
				name='summaryDisplayField'
				label={intlMsg('formTemplate.formSummaryDisplay')}
				control={control}
			/>
			<RequireFieldTxt control={control} />
			<TitleField control={control} />
		</Stack>
	)
}

export default FieldEditFormLayoutTxt
