import React, { useState, useCallback, useMemo } from 'react'
import Drawer from '@mui/material/Drawer'

import SlidePanelUserInfosConfig from './config/SlidePanelUserInfosConfig'
import SlidePanelContactInfosConfig from './config/SlidePanelContactInfosConfig'
import SlidePanelUserInfosHeader from './SlidePanelUserInfosHeader'
import SlidePanelUserInfosTab from './SlidePanelUserInfosTab'
import SlidePanelUserInfosTabPanel from './SlidePanelUserInfosTabPanel'

import { useProfileUserSlidePanel } from '../../../api/gql/queries/usersQueries'
import SkeletonPanelUser from '../../skeleton/SkeletonPanelUser'
import { Fade } from '@mui/material'
import { useContactInfosSlidePanel } from '../../../api/gql/queries/contactsQueries'

const drawerCSS = {
	'&.MuiPaper-root ': {
		maxWidth: '500px',
		minWidth: '500px',
		minHeight: '707px',
	},
}

interface ISlidePanelUserInfos {
	id?: any
	state: boolean
	toggleDrawer: (open: boolean) => () => void
	isContact?: boolean
	additionalClose?: () => void
}

const SlidePanelUserInfos: React.FC<ISlidePanelUserInfos> = ({
	id = '',
	state,
	toggleDrawer,
	isContact,
	additionalClose,
}) => {
	const [valueTab, setValueTab] = useState<number>(0)

	const slidePanelUserInfosTab = useMemo(
		() =>
			isContact ? SlidePanelContactInfosConfig : SlidePanelUserInfosConfig,
		[isContact]
	)

	const { data: dataUser } = isContact
		? useContactInfosSlidePanel({
				variables: { id },
		  })
		: useProfileUserSlidePanel({
				variables: { id },
		  })

	const slidePanelUserInfosTabPanel = useMemo(
		() =>
			isContact
				? SlidePanelContactInfosConfig(dataUser)
				: SlidePanelUserInfosConfig(dataUser, id),
		[id, dataUser]
	)

	const onClose = useCallback(() => {
		toggleDrawer(false)()
		additionalClose && additionalClose()
	}, [additionalClose, toggleDrawer])

	return (
		<Drawer
			anchor={'right'}
			open={state}
			onClose={onClose}
			PaperProps={{ sx: drawerCSS }}
			sx={{ zIndex: 1300 }}
		>
			{!dataUser ? (
				<SkeletonPanelUser nbRow={4} />
			) : (
				<Fade in={!!dataUser} timeout={200}>
					<div>
						<SlidePanelUserInfosHeader
							lastName={dataUser?.lastName}
							formatId={dataUser?.displayedId}
							firstName={dataUser?.firstName}
							role={dataUser?.userType?.name}
							lastLogin={dataUser?.lastLogin}
							onClose={onClose}
							isCustomerResp={dataUser?.isCustomerResp}
							isSiteResp={dataUser?.isSiteResp}
							isContact={isContact}
							dataUser={dataUser}
							id={id}
						/>
						<SlidePanelUserInfosTab
							tab={slidePanelUserInfosTab}
							valueTab={valueTab}
							setValueTab={setValueTab}
						/>
						<SlidePanelUserInfosTabPanel
							tab={slidePanelUserInfosTabPanel}
							valueTab={valueTab}
						/>
					</div>
				</Fade>
			)}
		</Drawer>
	)
}

export default SlidePanelUserInfos
