import React from 'react'

import { Typography } from '@mui/material'
import { Stack, Box } from '@mui/material'

import TitleField from './TitleField'
import translateIntlMsgString from '../../../../../../utils/translateIntlMsgString'

const fieldInstructionCSS = {
	p: 1,
	width: 1,
}

interface IFieldInstruction {
	field
	deleteField
	index
	updateField
	insertField
}

const FieldInstruction: React.FC<IFieldInstruction> = ({
	field: { id, name, values, fieldType },
	deleteField,
	index,
	updateField,
	insertField,
}) => {
	return (
		<Stack p={1}>
			<TitleField
				forcedTitle={translateIntlMsgString('formTemplate.instruction')}
				deleteField={deleteField}
				index={index}
				fieldToEdit={{
					id,
					name,
					values,
					fieldType,
					updateField,
					index,
				}}
				isRequired={values?.isRequired}
				insertField={insertField}
				values={values}
				fieldType={fieldType}
			/>
			<Box sx={fieldInstructionCSS}>
				{<Typography variant='body1'>{values?.instructionText}</Typography>}
			</Box>
		</Stack>
	)
}

export default FieldInstruction
