import React, { useMemo } from 'react'
import { Box, Typography } from '@mui/material'

interface IInstruction {
	fieldTemplate
}

const Instruction: React.FC<IInstruction> = ({ fieldTemplate }) => {
	const { instructionText } = useMemo(
		() =>
			typeof fieldTemplate?.attributes?.[0] === 'object'
				? fieldTemplate?.attributes?.[0] || {}
				: JSON.parse(fieldTemplate?.attributes?.[0]),
		[fieldTemplate]
	)

	return (
		<Box sx={{ ml: 0 }}>
			<Typography variant='body2'> {instructionText || '-'}</Typography>
		</Box>
	)
}

export default Instruction
