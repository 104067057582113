import React from 'react'

import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { Controller } from 'react-hook-form'

interface ISwitchButtonForm {
	name: string
	control?: any
	valuesDefault?
	config: {
		primary: {
			text: string
			value: any
		}
		secondary: {
			text: string
			value: any
		}
	}
	size?: 'medium' | 'small' | undefined
}

const SwitchButtonForm: React.FC<ISwitchButtonForm> = ({
	config,
	name,
	control,
	valuesDefault,
	size,
}) => {
	return (
		<>
			<Controller
				name={name}
				control={control}
				defaultValue={valuesDefault}
				render={({ field: { onChange, value } }) => (
					<ToggleButtonGroup
						exclusive
						onChange={(_, data) => data !== null && onChange(data)}
						size={size || 'small'}
						value={value}
						color='primary'
						fullWidth
					>
						<ToggleButton value={config.primary?.value}>
							{config.primary?.text}
						</ToggleButton>
						<ToggleButton value={config.secondary?.value}>
							{config.secondary?.text}
						</ToggleButton>
					</ToggleButtonGroup>
				)}
			/>
		</>
	)
}

export default SwitchButtonForm
