import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'

import { Stack, Alert, Typography } from '@mui/material'

import { useFormatMessage } from '../../../../../../../utils/hooks'
import TitleActors from './TitleActors'
import FormSelectSearch from '../../../../../../../components/forms/formFields/formSelectSearch'
import {
	FormModalContext,
	IFormModalContext,
} from '../../../../../../../components/forms/formModal/FormModalContext'
import { useUsersForSelect } from '../../../../../../../api/gql/queries/usersQueries'
import { useLazyGetAgenciesForSelect } from '../../../../../../../api/gql/queries/agencyQueries'
import FormMultiSelectSearch from '../../../../../../../components/forms/formFields/formMultiSelectSearch'
import { EModule } from '../../../../../../../utils/app-models'
import { AuthContext } from '../../../../../../../utils/contexts/AuthContext'

const alertCSS = { padding: '1px 16px', mt: '0!important' }

interface ITransportActors {
	isSharedTransport: boolean
}

const TransportActors: React.FC<ITransportActors> = ({ isSharedTransport }) => {
	const intlMsg = useFormatMessage()
	const [isInfosDisplayed, setIsInfosDisplayed]: any = useState(false)
	const { checkModules } = useContext(AuthContext)
	const { data } = useContext<IFormModalContext>(FormModalContext)
	const haveAgencyAccess = useMemo(() => checkModules([EModule.network]), [])

	const { users } = useUsersForSelect()
	const { agenciesAccounts, getAgencies } = useLazyGetAgenciesForSelect()
	const selectedSite = useMemo(
		() => data.finalSite?.value?.id || data.orderingSite?.value?.id || null,
		[data.finalSite, data.orderingSite]
	)
	// /////////////////////////////////////////////////
	// Format input options
	// /////////////////////////////////////////////////

	const actors = useMemo(
		() =>
			users?.map((user) => ({
				key: user?.id,
				label: `${user?.firstName} ${user?.lastName}`,
				value: user,
			})) || [],
		[users]
	)

	const formattedAgencies = useMemo(
		() =>
			agenciesAccounts?.map((agenciesAccount) => ({
				key: agenciesAccount?.id,
				label: agenciesAccount?.agencyCustomDetails[0]?.agencyName,
				value: agenciesAccount,
			})) || [],
		[agenciesAccounts]
	)

	// /////////////////////////////////////////////////
	// Render custom mui select chips label
	// /////////////////////////////////////////////////

	const renderChipsActorsLabel = useCallback(
		(value) => `${value?.firstName} ${value?.lastName}`,
		[]
	)

	const renderChipsAgenciesLabel = useCallback(
		(value) => value?.agencyCustomDetails?.[0]?.agencyName,
		[]
	)

	useEffect(() => {
		if (selectedSite && haveAgencyAccess)
			getAgencies({ variables: { sharedSites: selectedSite } })
	}, [selectedSite])

	return (
		<Stack spacing={1.5}>
			<TitleActors
				setIsInfosDisplayed={setIsInfosDisplayed}
				isInfosDisplayed={isInfosDisplayed}
			/>

			<FormSelectSearch
				isDisabled={isSharedTransport}
				name='manager'
				label={intlMsg('interventions.managerMission')}
				selectElements={actors}
				context={FormModalContext}
				playwrightId='Playwright-SelectInterventionStatus'
				isRequired
				trigerred
			/>

			{!isSharedTransport && haveAgencyAccess && (
				<>
					<FormMultiSelectSearch
						name='agenciesList'
						options={formattedAgencies}
						context={FormModalContext}
						label={intlMsg('interventions.agencies')}
						selectAllText={intlMsg('misc.selectAll')}
						missingText={intlMsg('interventions.noAgencies')}
						renderChipsLabel={renderChipsAgenciesLabel}
						bordered
					/>
					{isInfosDisplayed && (
						<Alert severity='info' sx={alertCSS}>
							<Typography>
								{intlMsg('interventions.agencyUnlimited')}
								{intlMsg('interventions.agencyUnlimitedDescription')}
							</Typography>
							<Typography>
								{intlMsg('interventions.agencyLimited')}
								{intlMsg('interventions.agencyLimitedDescription')}
							</Typography>
						</Alert>
					)}
				</>
			)}

			<FormMultiSelectSearch
				name='actors'
				options={actors}
				context={FormModalContext}
				label={intlMsg('interventions.conveyor')}
				selectAllText={intlMsg('misc.selectAll')}
				bordered
				renderChipsLabel={renderChipsActorsLabel}
				nbChipsToDisplay={2}
			/>

			{isInfosDisplayed && (
				<Alert severity='info' sx={alertCSS}>
					{intlMsg('interventions.actorsAccess')}
				</Alert>
			)}
		</Stack>
	)
}
export default TransportActors
