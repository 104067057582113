import React, { useMemo } from 'react'

import Popover from '@mui/material/Popover'
import List from '@mui/material/List'
import ListSubheader from '@mui/material/ListSubheader'
import Typography from '@mui/material/Typography'
import Radio from '@mui/material/Radio'
import ListItem from '@mui/material/ListItem'

import { useFormatMessage } from '../../utils/hooks'
import { Box, ListItemText } from '@mui/material'
import { stringifiedColor } from '../../utils/color'

import { green as Green, pink as Pink } from '@mui/material/colors'
import ConfirmModal from '../modal/confirmModal/index'

const squareColor = {
	height: '11px',
	width: '11px',
	marginRight: '10px',
	marginLeft: '-5px',
	borderRadius: 1,
}

interface IPopoverBoolean {
	title
	handleChange
	value
	handleClose
	open
	anchorEl
	choiceTextContent
	confirmStatusModalUtils?
	onConfirmStatusModal?
	confirmModal?
}

const PopoverBoolean: React.FC<IPopoverBoolean> = ({
	title,
	handleChange,
	value,
	handleClose,
	open,
	anchorEl,
	choiceTextContent,
	confirmStatusModalUtils,
	onConfirmStatusModal,
	confirmModal,
}) => {
	const intlMsg = useFormatMessage()
	const color = useMemo(() => (value ? 'green' : 'pink'), [value])

	return (
		<Popover
			open={open}
			anchorEl={anchorEl}
			onClose={handleClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
		>
			<List dense data-test-id='Playwright-PopoverBoolean'>
				<ListSubheader component='div' sx={{ p: 1 }}>
					<Typography variant='body2' display='block' sx={{ fontWeight: 800 }}>
						{intlMsg(title).toUpperCase()}
					</Typography>
				</ListSubheader>

				<ListItem
					button
					sx={{
						'&.Mui-selected': {
							background: stringifiedColor(color, 50) as any,
						},
					}}
					onClick={handleChange}
					selected={value || false}
					id='true'
				>
					<Box sx={{ ...squareColor, backgroundColor: Green[400] }}></Box>

					<ListItemText primary={intlMsg(choiceTextContent.true)} />
					<Radio
						edge='end'
						size='small'
						color={'primary'}
						disableRipple
						checked={value || false}
					/>
				</ListItem>

				<ListItem
					button
					sx={{
						'&.Mui-selected': {
							background: stringifiedColor(color, 50) as any,
						},
					}}
					onClick={handleChange}
					selected={!value || false}
					id='false'
				>
					<Box sx={{ ...squareColor, backgroundColor: Pink[400] }}></Box>

					<ListItemText primary={intlMsg(choiceTextContent.false)} />
					<Radio
						edge='end'
						size='small'
						color={'primary'}
						disableRipple
						checked={!value || false}
					/>
				</ListItem>
			</List>

			{confirmStatusModalUtils?.isVisible && (
				<ConfirmModal
					onClose={confirmStatusModalUtils?.closeModal}
					open={confirmStatusModalUtils?.isVisible}
					onConfirmStatusModal={onConfirmStatusModal}
					message={confirmModal?.message}
					severity={confirmModal?.severity}
				/>
			)}
		</Popover>
	)
}

export default PopoverBoolean
