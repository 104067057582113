import React, {
	useCallback,
	useEffect,
	useState,
	memo,
	useContext,
	useMemo,
} from 'react'

import List from '@mui/material/List'
import SubjectIcon from '@mui/icons-material/Subject'
import ListSubheader from '@mui/material/ListSubheader'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton/IconButton'
import Badge from '@mui/material/Badge'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'

import ColumnFiltersRow from './ColumnFiltersRow'
import { useFormatMessage } from '../../../../utils/hooks'
import { TableContext } from '../../../../utils/contexts/TableContext'
import { AuthContext } from '../../../../utils/contexts/AuthContext'

const SubjectIconCSS = { transform: 'scale(-1, 1) rotate(90deg)' }

const boxPlaywrightCSS = { with: '100%', height: '2px' }

const badgeCSS = {
	'& .MuiBadge-badge': {
		right: 8,
		top: 8,
		border: ({ palette }) => `3px solid ${palette.background.paper}`,
		padding: '5px 5px',
		fontSize: '10px',
	},
}

interface IColumnFilter {
	localStorageName: string
	tableConfig
}

const ColumnFilter: React.FC<IColumnFilter> = ({
	localStorageName,
	tableConfig,
}) => {
	const intlMsg = useFormatMessage()
	const { checkModules, checkAccountsType, checkPermissions } =
		useContext(AuthContext)
	const { setActiveColumns, activeColumns, setPossibleColumns } =
		useContext(TableContext) || {}

	const filteredColumnTitle = useMemo(
		() =>
			tableConfig
				?.filter(
					({ modules, accountsTypeDenied, permissions, columnLabel }) =>
						(!modules || checkModules(modules)) &&
						columnLabel?.length &&
						(!accountsTypeDenied || !checkAccountsType(accountsTypeDenied)) &&
						(!permissions || checkPermissions(permissions))
				)
				?.map(({ columnLabel }) => columnLabel),
		[tableConfig, checkModules, checkAccountsType, checkPermissions]
	)

	const nbColumnFiltered = useMemo(
		() => filteredColumnTitle?.length - activeColumns?.length,
		[filteredColumnTitle, activeColumns]
	)

	////////////////////////////////////////////
	// GET DEFAULT PREFERENCE IN LOCAL STORAGE && setPossibleColumns (use to export all column)
	////////////////////////////////////////////

	useEffect(() => {
		setPossibleColumns(filteredColumnTitle)

		const usersArrayFilters = JSON.parse(
			localStorage.getItem(localStorageName) as string
		)
		if (!usersArrayFilters) {
			localStorage.setItem(
				localStorageName,
				JSON.stringify(filteredColumnTitle)
			)
			setActiveColumns(filteredColumnTitle)
		} else {
			setActiveColumns(usersArrayFilters)
		}
	}, [])

	////////////////////////////////////////////
	// OPEN POPOVER
	////////////////////////////////////////////

	const [anchorEl, setAnchorEl] = useState(null)
	const handleClick = useCallback((event) => {
		setAnchorEl(event.currentTarget)
	}, [])
	const handleClose = useCallback(() => {
		setAnchorEl(null)
	}, [])
	const open = Boolean(anchorEl)
	const id = open ? 'simple-popover' : undefined

	return (
		<>
			<Tooltip title={intlMsg('table.displayCol')}>
				<Badge badgeContent={nbColumnFiltered} color='error' sx={badgeCSS}>
					<IconButton aria-label='delete' onClick={handleClick} size='medium'>
						<SubjectIcon sx={SubjectIconCSS} />
					</IconButton>
				</Badge>
			</Tooltip>

			{open && (
				<Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose}>
					<List>
						<ListSubheader component='div'>
							<Typography variant='overline' display='block' gutterBottom>
								{intlMsg('table.displayCol')}
							</Typography>
							<Divider />
						</ListSubheader>
						<ColumnFiltersRow
							columnsTitle={filteredColumnTitle as any}
							setActiveColumns={setActiveColumns}
							activeColumns={activeColumns}
							localStorageName={localStorageName}
						/>
					</List>
					{/* this box is only used to close popover with playwright */}
					<Box
						sx={boxPlaywrightCSS}
						id='Playwright-ColumnFilter-closeOnClick'
						onClick={handleClose}
					/>
				</Popover>
			)}
		</>
	)
}

export default memo(ColumnFilter)
