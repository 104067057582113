/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
	gql,
	OperationVariables,
	QueryHookOptions,
	useLazyQuery,
	useQuery,
} from '@apollo/client'
import { formatGeneric } from '../../../utils/string'
import { listCustomersAttributes } from '../attributes/customers'
import {
	listVehiclesAttributes,
	vehicleBrandsAttributes,
	vehicleCarburantsAttributes,
	vehicleCategoriesAttributes,
	vehicleTypesAttributes,
	vehicleViewModal,
} from '../attributes/vehicleAttributes'

export const LIST_VEHICLES = gql`
query getVehiclesList(
    $page: Int
    $itemsPerPage: Int
    $isActive: Boolean
    $searchText: String
	$customer: String
    $site: String
	$orderBy: [VehicleFilter_orderBy]
) {
    vehicles(page: $page, itemsPerPage: $itemsPerPage, isActive: $isActive,searchText: $searchText, customer: $customer, site: $site, orderBy: $orderBy) {
        ${listVehiclesAttributes}
    }
}`

export const VEHICLE_INFO_MODAL_DATA = gql`
    query getVehicleInfoModalData{
        customers(itemsPerPage: 100000, isActive: true) {
			${listCustomersAttributes}
		}
        vehicleCategories{
            ${vehicleCategoriesAttributes}
        }
    }
`
export const VEHICLE_CATEGORIES_FOR_MODAL = gql`
	query getVehicleInfoModalData {
		vehicleCategories {
			id
			name
			code
		}
	}
`

export const CONTROL_EXSISTING_VEHICLE_DETAILS = gql`
	query controlExistingVehicleDetails($immat: String, $vin: String) {
		controlExistingVehicleDetails(immat: $immat, vin: $vin) {
			id
		}
	}
`

export const VEHICLE = gql`
    query getVehicle($id: ID!){
        vehicle(id: $id){
            ${vehicleViewModal}
        }
    }
`

export const VEHICLE_TECHNICAL_INFO_MODAL_DATA = gql`
    query getVehicleTechnicalInfoModalData{
        vehicleTypes{
            ${vehicleTypesAttributes}
        }
    }
`

export const VEHICLE_MODAL_INFO_MODAL_DATA = gql`
    query getVehicleModalInfoModalData{
        vehicleCarburants{
            ${vehicleCarburantsAttributes}
        }
        vehicleCategories{
            ${vehicleCategoriesAttributes}
        }
        vehicleBrands{
            ${vehicleBrandsAttributes}
        }
    }
`

export const VEHICLES_NUMBERS = gql`
	query getVehiclesNumbers(
		$customer: String
		$site: String
		$searchText: String
	) {
		vehicles(
			page: 1
			itemsPerPage: 100000
			isActive: true
			customer: $customer
			site: $site
			searchText: $searchText
		) {
			paginationInfo {
				totalCount
			}
		}
	}
`

export const WGET_VEHICLE = gql`
	query getWVehicle($id: ID!) {
		vehicle(id: $id) {
			id
			lastVehicleMovement {
				id
				entryDate
				exitDate
			}
			vehicleLocations(itemsPerPage: 99999) {
				collection {
					id
					location
					color
				}
			}
		}
	}
`

export const WVEHICLE_MILEAGES = gql`
	query getWVehicleMileages($id: String) {
		vehicleMileages(vehicle: $id) {
			id
			mileage
			vehicle {
				id
			}
			date
			createdBy {
				id
				firstName
				lastName
			}
		}
	}
`

export const WVEHICLE_INTERVENTIONS = gql`
	query getWVehicleInterventions($id: ID!, $vehicle: String) {
		vehicle(id: $id) {
			id
			previousInterventionProgrammedVehicle {
				id
				completionDate
				status {
					id
					code
					name
					color
				}
				intervention {
					id
					reference
					interventionType
					dateFrom
				}
			}
			nextInterventionProgrammedVehicle {
				id
				status {
					id
					code
					name
					color
				}
				intervention {
					id
					reference
					interventionType
					dateFrom
				}
			}
		}
		interventionProgrammedVehicles(vehicle: $vehicle) {
			paginationInfo {
				totalCount
			}
		}
	}
`

export const WVEHICLE_ACTIVE = gql`
	query getWVehicleActive($isActive: Boolean, $itemsPerPage: Int) {
		vehicles(isActive: $isActive, itemsPerPage: $itemsPerPage) {
			paginationInfo {
				totalCount
			}
		}
	}
`

export const WVEHICLE_ = gql`
	query getWVehicleActive($isActive: Boolean, $itemsPerPage: Int) {
		vehicles(isActive: $isActive, itemsPerPage: $itemsPerPage) {
			paginationInfo {
				totalCount
			}
		}
	}
`

export const WVEHICLE_MOVEMENT = gql`
	query getWVehicleMovement($id: ID!) {
		vehicleMovement(id: $id) {
			id
			entryDate
			exitDate
			dateCreated
			entryBy {
				id
				firstName
				lastName
			}
		}
	}
`

export const WVEHICLE_ENTRIE_PARC = gql`
	query getWVehicleEntriePark(
		$entryDate: [VehicleMovementFilter_entryDate]
		$exitDate: [VehicleMovementFilter_exitDate]
	) {
		vehicleMovements(entryDate: $entryDate, exitDate: $exitDate) {
			id
			exitDate
			entryDate
		}
	}
`

export const VEHICLE_INVOICE = gql`
	query getVehicleInvoice($id: ID!) {
		vehicle(id: $id) {
			id
			vehicleDetail {
				id
				immat
				vin
				vehicleModelText
				vehicleBrand {
					name
				}
			}
		}
	}
`

export const VEHICLE_INVOICE_LIST = gql`
	query getVehiclesInvoiceList($searchText: String) {
		vehicles(searchText: $searchText, page: 1, itemsPerPage: 100000) {
			collection {
				id
				vehicleDetail {
					id
					immat
					vin
					vehicleModelText
					vehicleBrand {
						id
						name
					}
				}
			}
		}
	}
`

export const VEHICLE_IPV_INVOICE_LIST = gql`
	query getIPVvehicleInvoiceList($intervention: String) {
		interventionProgrammedVehicles(
			intervention: $intervention
			page: 1
			itemsPerPage: 100000
		) {
			collection {
				id
				vehicle {
					id
					vehicleDetail {
						immat
						vin
						vehicleModelText
						vehicleBrand {
							id
							name
						}
					}
				}
			}
		}
	}
`

export const EXPORT_VEHICLES = gql`
	query exportVehicles(
		$isActive: Boolean
		$searchText: String
		$customer: String
		$site: String
		$timezone: Int
		$orderBy: [VehicleFilter_orderBy]
	) {
		exportVehicles(
			isActive: $isActive
			searchText: $searchText
			customer: $customer
			site: $site
			timezone: $timezone
			orderBy: $orderBy
		) {
			id
			exportFile
		}
	}
`

export const VEHICLE_MODAL_INIT_FORCED = gql`
	query vehicleModalInit($forcedCustomerId: ID!, $forcedSiteId: ID!) {
		customer(id: $forcedCustomerId) {
			id
			name
		}
		site(id: $forcedSiteId) {
			id
			name
		}
	}
`

/**  ---------------------HOOKS---------------------- */

export const useGetVehicleInfoModalData = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { loading, data } = useQuery(VEHICLE_INFO_MODAL_DATA, options)

	return {
		loading,
		customers: data?.customers?.collection || null,
		vehicleCategories: data?.vehicleCategories || null,
	}
}

export const useGetVehicleTechnicalInfoModalData = () => {
	const { loading, data } = useQuery(VEHICLE_TECHNICAL_INFO_MODAL_DATA)

	return {
		loading,
		vehicleTypes: data?.vehicleTypes || null,
	}
}

export const useGetVehicleModalInfoModalData = () => {
	const { loading, data } = useQuery(VEHICLE_MODAL_INFO_MODAL_DATA)

	return {
		loading,
		vehicleCarburants: data?.vehicleCarburants || null,
		vehicleCategories: data?.vehicleCategories || null,
		vehicleBrands: data?.vehicleBrands || null,
	}
}

export const useVehicle = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { loading, data } = useQuery(VEHICLE, options)

	return {
		loading,
		vehicle: data?.vehicle || null,
	}
}

export const useLazyVehicle = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { data, loading }] = useLazyQuery(VEHICLE, options)

	return {
		loading,
		getVehicle: getData,
		vehicle: data?.vehicle || null,
	}
}

export const useVehiclesNumbers = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { loading, data } = useQuery(VEHICLES_NUMBERS, options)

	return {
		loading,
		numbers: data?.vehicles?.paginationInfo.totalCount || null,
	}
}

export const useWVehicleMouvment = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { loading, data } = useQuery(WGET_VEHICLE, options)

	return {
		loading,
		vehicle: data?.vehicle || null,
	}
}

export const useWVehicleMileages = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { loading, data } = useQuery(WVEHICLE_MILEAGES, options)

	return {
		loading,
		data,
	}
}

export const useWVehicleIntervention = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { loading, data } = useQuery(WVEHICLE_INTERVENTIONS, options)

	return {
		loading,
		data: (data?.vehicle ? formatGeneric(data?.vehicle) : null) as any,
		totalVehicles:
			data?.interventionProgrammedVehicles?.paginationInfo?.totalCount,
	}
}

export const useWVehicleActive = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { loading, data } = useQuery(WVEHICLE_ACTIVE, options)

	return {
		loading,
		totalVehicles: data?.vehicles?.paginationInfo?.totalCount || 0,
	}
}

export const useWVehicleEntriePark = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { loading, data } = useQuery(WVEHICLE_ENTRIE_PARC, options)

	return {
		loading,
		vehicles: data?.vehicleMovements || [],
	}
}

export const useLazyVehicleMovementForModal = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { data, loading }] = useLazyQuery(WVEHICLE_MOVEMENT, options)

	return {
		loading: loading,
		vehicleMovement: data?.vehicleMovement,
		getVehicleMovement: getData,
	}
}

export const useVehicleInvoice = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(VEHICLE_INVOICE, options)

	return {
		loadingVehicle: loading,
		vehicle: data?.vehicle || null,
		getVehicle: getData,
	}
}

export const useLazyVehiclesInvoiceList = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(
		VEHICLE_INVOICE_LIST,
		options
	)
	return {
		loadingVehicles: loading,
		vehicles: data?.vehicles?.collection || [],
		getVehicles: getData,
	}
}

export const useLazyVehicleIPVInvoiceList = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(
		VEHICLE_IPV_INVOICE_LIST,
		options
	)
	return {
		loadingVehiclesIPV: loading,
		vehiclesIPV: data?.interventionProgrammedVehicles?.collection || [],
		getVehiclesIPV: getData,
	}
}

export const useExportVehicles: any = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { data, loading, error } = useQuery(EXPORT_VEHICLES, options)

	return {
		exportFile: data?.exportVehicles?.[0]?.exportFile || null,
		loading,
		error,
	}
}

export const useLazyVehicleModalInitForcedData = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { data, loading }] = useLazyQuery(
		VEHICLE_MODAL_INIT_FORCED,
		options
	)
	return {
		getVehicleForcedData: getData,
		loading,
		customer: data?.customer,
		site: data?.site,
	}
}
