import React, { memo } from 'react'

import { CircularProgress } from '@mui/material'

interface ICircularProgressWidget {}

const circularProgressWidgetSx = {
	position: 'absolute',
	right: ' calc(50% - 20px)',
}

const CircularProgressWidget: React.FC<ICircularProgressWidget> = () => (
	<CircularProgress sx={circularProgressWidgetSx} />
)

export default memo(CircularProgressWidget)
