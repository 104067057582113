import React from 'react'

import { Controller } from 'react-hook-form'
import { Stack, Switch, FormControlLabel, Checkbox } from '@mui/material'

import IconToolTips from '../../../icons/IconToolTips'

const FormControlLabelCSS = {
	width: 1,
	'&.MuiFormControlLabel-root': {
		color: ({ palette }) => palette.grey[700],
		borderColor: ({ palette }) => `${palette.grey[400]}!important`,
		border: 'solid 1px',
		borderRadius: 1,
		m: 0,
		width: 1,
		height: '35.6px',
	},
	'&.MuiFormControlLabel-root:hover': {
		color: ({ palette }) => palette.grey[700],
		borderColor: ({ palette }) => `${palette.grey[700]}!important`,
	},
	'&.Mui-disabled': {
		color: ({ palette }) => palette.grey[300],
		'& svg': { color: ({ palette }) => palette.grey[300] },
	},
} as const

interface ICheckBoxOrSwitch {
	name: string
	control: any
	bordered?: boolean
	label?: string
	valuesDefault?: any
	onBlur?
	disabled?: boolean
	toolTip?: { text: string; icon: any } | null
	isCheckbox?: boolean
	playwrightId?: string
}

const CheckBoxOrSwitch: React.FC<ICheckBoxOrSwitch> = ({
	name,
	label,
	control,
	valuesDefault,
	bordered = false,
	onBlur,
	disabled = false,
	toolTip = null,
	isCheckbox = false,
	playwrightId = '',
}) => {
	return (
		<Controller
			render={({ field: { onChange, value, ...params } }) => (
				<FormControlLabel
					onBlur={onBlur}
					control={
						isCheckbox ? (
							<Checkbox
								disabled={disabled}
								color={value ? 'primary' : 'default'}
								size='small'
								onChange={onChange}
								{...params}
								checked={value}
								id={playwrightId}
							/>
						) : (
							<Switch
								disabled={disabled}
								color={value ? 'primary' : 'default'}
								size='small'
								onChange={onChange}
								{...params}
								checked={value}
								id={playwrightId}
							/>
						)
					}
					label={
						<Stack
							direction='row'
							alignItems='center'
							justifyContent='space-between'
							sx={{ lineHeight: '12px' }}
						>
							<div>{label}</div>
							{toolTip && (
								<IconToolTips txt={toolTip.text} Icon={toolTip.icon} />
							)}
						</Stack>
					}
					sx={bordered ? FormControlLabelCSS : {}}
				/>
			)}
			name={name}
			control={control}
			defaultValue={valuesDefault}
		/>
	)
}

export default CheckBoxOrSwitch
