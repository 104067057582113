import React, { useState, useCallback, useEffect } from 'react'

import { useQuery } from '@apollo/client'
import Card from '@mui/material/Card'

import Networks from './networks'
import Agencies from './agencies'
import { LIST_NETWORKS } from '../../../api/gql/queries/networkQueries'
import StepperProvider from '../../../components/forms/formStepper/StepperProvider'
import agencyStepperConfig from './agencies/agencyModal/agencyStepperConfig'
import { EFormModalMode } from '../../../utils/app-models'
import CreateAgencyBtnModal from './agencies/CreateAgencyBtnModal'

interface INetworksAgencies {}

const NetworksAgencies: React.FC<INetworksAgencies> = () => {
	const [selectedNetworkId, setSelectedNetworkId] = useState('')
	const [networksData, setNetworksData] = useState<any>([])
	const [networkIsActive, setNetworkIsActive] = useState(true)

	// //////////////////////////////////
	// NETWORKS
	// //////////////////////////////////

	// Get networks by isActive status
	const { data: queryData, loading } = useQuery(LIST_NETWORKS, {
		variables: { isActive: networkIsActive, itemsPerPage: 1000, page: 1 },
		fetchPolicy: 'network-only',
		nextFetchPolicy: 'cache-first',
	})

	// set data & default selected network
	useEffect(() => {
		if (!loading && queryData) {
			setNetworksData(
				[...queryData.networks.collection].sort(
					(a, b) => a.dateCreated - b.dateCreated
				)
			)

			const networksPref = JSON.parse(
				localStorage.getItem('networksSelected') as string
			)

			if (!networksPref && queryData?.networks?.collection?.length) {
				localStorage.setItem(
					'networksSelected',
					JSON.stringify(queryData?.networks?.collection[0].id)
				)
				setSelectedNetworkId(queryData?.networks?.collection[0].id)
			} else {
				setSelectedNetworkId(networksPref)
			}
		}
	}, [loading, queryData, networkIsActive])

	//  Change network page
	const changeNetworkPage = useCallback(
		(id) => () => {
			setSelectedNetworkId(id)
			localStorage.setItem('networksSelected', JSON.stringify(id))
		},
		[]
	)

	return (
		<Card elevation={1} sx={{ width: 1, display: 'flex', height: 1 }}>
			<Networks
				changeNetworkPage={changeNetworkPage}
				selectedNetworkId={selectedNetworkId}
				setSelectedNetworkId={setSelectedNetworkId}
				networksData={networksData}
				networkIsActive={networkIsActive}
				setNetworkIsActive={setNetworkIsActive}
			/>
			{!loading && (
				<Agencies
					overrideConfigTable={{
						hidden: { tableToolBar: { tableBtnCreate: !selectedNetworkId } },
						elevationNbr: 0,
						othersQueryParams: { networks: selectedNetworkId },
						customBtnCreate: (
							<StepperProvider
								stepperConfig={agencyStepperConfig}
								stepperMode={EFormModalMode.create}
							>
								<CreateAgencyBtnModal
									selectedNetwork={{
										id: selectedNetworkId,
										name: queryData?.networks?.collection?.find(
											({ id }) => id === selectedNetworkId
										)?.name,
									}}
								/>
							</StepperProvider>
						),
					}}
				/>
			)}
		</Card>
	)
}

export default NetworksAgencies
