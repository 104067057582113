import React, { useContext, useMemo } from 'react'

import { Typography, Stack } from '@mui/material'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import ErrorIcon from '@mui/icons-material/Error'

import { useFormatMessage } from '../../../../../../../utils/hooks'
import { FormModalContext } from '../../../../../../../components/forms/formModal/FormModalContext'
import CustomAccordion from '../../../../../../../components/accordion/CustomAccordion'
import SearchVehicle from './searchVehicle/SearchVehicle'
import VehicleAssignedDisplay from './vehicleAssignedDisplay/VehicleAssignedDisplay'
import { VehiclesTransportProvider } from './VehiclesTransportProvider'

interface ITransportVehicles {
	isSharedTransport: boolean
}

const TransportVehicles: React.FC<ITransportVehicles> = ({
	isSharedTransport,
}) => {
	const intlMsg = useFormatMessage()
	const { data } = useContext(FormModalContext)

	const ErrorMessageMissingVehicleInfos = useMemo(() => {
		if (
			data?.selectedVehicles?.[0] &&
			(!data?.selectedVehicles?.[0]?.vehicleBrand ||
				!data?.selectedVehicles?.[0]?.vehicleModelText)
		) {
			return (
				<Stack
					direction='row'
					alignItems='center'
					spacing={1}
					ml={1}
					color='#EF443E'
				>
					<ErrorIcon />
					<Typography variant='subtitle2'>
						{intlMsg('interventions.incompleteAssignmentVehicle')}
					</Typography>
				</Stack>
			)
		}
	}, [data?.selectedVehicles])

	return (
		<VehiclesTransportProvider>
			<CustomAccordion
				title={intlMsg('misc.vehicle')}
				icon={<DirectionsCarIcon />}
				AfterTitleCustomBlock={ErrorMessageMissingVehicleInfos}
				disabledExpand
			>
				{data?.selectedVehicles?.length ? (
					<VehicleAssignedDisplay isSharedTransport={isSharedTransport} />
				) : (
					<SearchVehicle />
				)}
			</CustomAccordion>
		</VehiclesTransportProvider>
	)
}
export default TransportVehicles
