import React from 'react'

import Table from '../../../components/tableV2'
import {
	IOverrideConfigTable,
	IOverrideConfigTableWithArgument,
} from '../../../utils/typescript/tableType'
import { customerAccountsConfigTable } from './customerAccountsConfigTable'

interface ICustomerAccounts {
	othersQueryParams?
	overrideConfigTable?: IOverrideConfigTable | IOverrideConfigTableWithArgument
}

const CustomerAccounts: React.FC<ICustomerAccounts> = ({
	othersQueryParams,
	overrideConfigTable,
}) => {
	return (
		<Table
			tableConfig={customerAccountsConfigTable}
			overrideConfigTable={overrideConfigTable}
			othersQueryParams={othersQueryParams}
		/>
	)
}

export default CustomerAccounts
