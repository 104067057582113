import React from 'react'

import AddIcon from '@mui/icons-material/Add'
import Button from '@mui/material/Button'

const btnAddCircleCSS = {
	borderRadius: '50%',
	padding: '18px',
	minWidth: '30px',
	width: '20px',
	height: '20px',
}

interface IBtnAddCircle {
	action: () => void
	playwrightId?: string
}

const BtnAddCircle: React.FC<IBtnAddCircle> = ({
	action,
	playwrightId = '',
}) => {
	return (
		<Button
			variant='contained'
			color={'primary'}
			onClick={action}
			sx={btnAddCircleCSS}
			id={playwrightId}
		>
			<AddIcon />
		</Button>
	)
}
export default BtnAddCircle
