import isEqual from 'lodash.isequal'

const indexFilterToCount = [
	'category',
	'customer_list',
	'actors_list',
	'status_list',
	'agency_list',
	'isWithoutActor',
	'intervention_status_list',
	'category',
	'status',
	'interventionType',
	'intervention_interventionType',
	'intervention_actors_list',
]

export const getActiveFiltersNumber = (
	defaultFilterConfig,
	filtersQuerieParam
) => {
	if (isEqual(defaultFilterConfig, filtersQuerieParam)) return 0
	let tempCount = 0

	Object?.entries(defaultFilterConfig || {})?.forEach(([key, value]) => {
		if (
			!isEqual(value, filtersQuerieParam[key]) &&
			indexFilterToCount.includes(key)
		) {
			tempCount++
		}
	})

	return tempCount
}
