/* eslint-disable no-case-declarations */
import React from 'react'

import { Box, Stack, Typography } from '@mui/material'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'

import { useFormatMessage } from '../../../../../utils/hooks'
import MembersCell from '../../../../../components/tableV2/tableCell/MembersCell'
import MembersPopOver from '../../../../../components/tableV2/tableCell/tableCellPopOver/MembersPopOver'

const interventionTypeCSS: any = ({ colors }) => ({
	mt: '2px',
	p: '3px',
	borderRadius: '2px',
	backgroundColor: colors.transport,
	fontSize: '10px',
	fontWeight: 400,
	height: 'fit-content',
	width: 'fit-content',
})

const orderingCSS = { fontSize: '10px', fontWeight: 500, textWrap: 'nowrap' }
const travelCSS = { fontSize: '10px', mt: '2px', textWrap: 'nowrap' }
const avatarCSS = { display: 'flex', alignItems: 'center' }

interface IConvoyingWeekDayEvent {
	event?: any
	setIsMembersCellOpen?: any
	onCardMouseAction
}

const ConvoyingWeekDayEvent: React.FC<IConvoyingWeekDayEvent> = ({
	event,
	setIsMembersCellOpen,
	onCardMouseAction,
}) => {
	const intlMsg = useFormatMessage()

	const {
		interventionTransportTrips,
		orderingCustomer: { name: customerName },
		orderingSite: { name: siteName },
		actors,
	} = event?.resource || {}

	return (
		<Stack justifyContent='space-between' height={1}>
			<Stack>
				<Box sx={interventionTypeCSS}>
					{intlMsg(`interventions.CONVOY`).substring(0, 4)}
				</Box>
				<Typography sx={travelCSS}>
					<Stack direction='row'>
						<Box>{interventionTransportTrips[0]?.city}</Box>
						<ArrowRightIcon sx={{ fontSize: 15 }} />
						<Box>{interventionTransportTrips[1]?.city}</Box>
					</Stack>
				</Typography>
				<Typography
					sx={orderingCSS}
				>{`${customerName} - ${siteName}`}</Typography>
			</Stack>
			<div>
				{!!actors?.length && (
					<Box sx={avatarCSS}>
						<MembersCell
							data={actors?.map(({ id, lastName, firstName }) => ({
								firstName,
								lastName,
								id,
							}))}
							Popover={MembersPopOver}
							displayManager={false}
							setIsOpen={setIsMembersCellOpen}
							otherAction={onCardMouseAction}
						/>
					</Box>
				)}
			</div>
		</Stack>
	)
}

export default ConvoyingWeekDayEvent
