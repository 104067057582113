import React, { useCallback } from 'react'

import Tooltip from '@mui/material/Tooltip'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { Box, Stack } from '@mui/material'

const navPageCSS = (selectedPageNbr, i, dragStyle) => ({
	display: 'flex',
	alignItems: 'center',
	backgroundColor: 'white',
	p: 1,
	cursor: 'pointer',
	transition: '0.2s',
	userSelect: 'none',
	'&:hover': {
		backgroundColor: '#f1f1f1',
		transition: '0.2s',
		'& .iconDrag': {
			opacity: 1,
			transition: '0.5s',
		},
	},
	'& .iconDrag': {
		opacity: 0,
		transition: '0.1s',
	},

	...(selectedPageNbr === i && {
		borderRight: '3px #2196F3 solid',
		color: '#2196F3',
		...dragStyle,
	}),
})

interface IPageItem {
	selectedPageNbr: number
	setSelectedPageNbr: (selectedPageNbr: number) => void
	i
	name: any
	draggableZone
	dragStyle
}

const PageItem: React.FC<IPageItem> = ({
	selectedPageNbr,
	setSelectedPageNbr,
	i,
	draggableZone,
	name,
	dragStyle,
}) => {
	const handlePageClick = useCallback(
		(pageNbr) => () => setSelectedPageNbr(pageNbr),
		[]
	)

	return (
		<Box
			onClick={handlePageClick(i)}
			sx={navPageCSS(selectedPageNbr, i, dragStyle)}
		>
			<Box {...draggableZone}>
				<Stack direction='row' alignItems='center' className='iconDrag'>
					<DragIndicatorIcon sx={{ mr: 1 }} />
				</Stack>
			</Box>

			<Tooltip title={name} arrow placement='right'>
				<span>{`PAGE ${i + 1}`}</span>
			</Tooltip>
		</Box>
	)
}

export default PageItem
