import React, { useCallback, useContext } from 'react'

import { Stack, Typography } from '@mui/material'
import Alert from '@mui/material/Alert'

import TextField from '../../../../../../components/inputs/TextField'
import Switch from '../../../../../../components/switch'
import { useFormatMessage } from '../../../../../../utils/hooks'
import { StepperContext } from '../../../../../../components/forms/formStepper/StepperContext'

const ReferentStep: React.FC = () => {
	const intlMsg = useFormatMessage()
	const { data, setValue, errors, triggerError } = useContext(StepperContext)

	const handleChange = useCallback(
		(name) => (e) => {
			setValue(name, e.target.value)
		},
		[setValue]
	)

	const handleOnBlur = useCallback(
		(name) => () => {
			triggerError(name)
		},
		[triggerError]
	)

	const handleSwitchChange = useCallback(() => {
		setValue('adminCanLogin', !data?.adminCanLogin)
	}, [data])

	return (
		<Stack p={4} sx={{ width: 1 }} spacing={3}>
			<Typography variant='h2'>
				{intlMsg('agencies.referralInformation')}
			</Typography>

			<Stack direction='row' justifyContent='space-between' spacing={2}>
				<TextField
					name='adminFirstName'
					value={data?.adminFirstName}
					onChange={handleChange('adminFirstName')}
					label={intlMsg('misc.firstname')}
					isrequired
					error={errors?.adminFirstName}
					onBlur={handleOnBlur('adminFirstName')}
				/>
				<TextField
					name='adminLastName'
					value={data?.adminLastName}
					onChange={handleChange('adminLastName')}
					label={intlMsg('misc.lastname')}
					isrequired
					error={errors?.adminLastName}
					onBlur={handleOnBlur('adminLastName')}
				/>
			</Stack>

			<Stack direction='row' justifyContent='space-between' spacing={2}>
				<TextField
					name='adminPhone'
					value={data?.adminPhone}
					onChange={handleChange('adminPhone')}
					label={intlMsg('misc.phone')}
					error={errors?.adminPhone}
					onBlur={handleOnBlur('adminPhone')}
				/>
				<TextField
					name='adminCellphone'
					value={data?.adminCellphone}
					onChange={handleChange('adminCellphone')}
					label={intlMsg('misc.cellphone')}
					isrequired
					error={errors?.adminCellphone}
					onBlur={handleOnBlur('adminCellphone')}
				/>
			</Stack>

			<TextField
				name='adminEmail'
				value={data?.adminEmail}
				onChange={handleChange('adminEmail')}
				label={intlMsg('misc.email')}
				isrequired
				error={errors?.adminEmail}
				onBlur={handleOnBlur('adminEmail')}
			/>

			<Typography variant='h2'>{intlMsg('misc.invitation')}</Typography>

			<Switch
				label={intlMsg('agencies.adminCanLogin')}
				value={data?.adminCanLogin}
				onChange={handleSwitchChange}
			/>

			<Alert severity='info'>
				{intlMsg('agencies.agencieHelpProcessus')}
				<br></br>
				{intlMsg('agencies.afterAgencieHelpProcessus')}
			</Alert>
		</Stack>
	)
}

export default ReferentStep
