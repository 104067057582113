/* eslint-disable @typescript-eslint/no-unused-vars */
import { EFormModalMode } from '../../../../utils/app-models'
import translateIntlMsgString from '../../../../utils/translateIntlMsgString'
import { getTeamsAndUsersListWithManagerById } from '../../../../utils/utils'

export const formatUserModalEditDefaultValues = ({ data }) => {
	const { id, __typename, permissions, teams, userType, ...values } = data

	permissions?.forEach(({ id }) => {
		values[id] = true
	})

	teams?.forEach(({ id, isManager }) => {
		values[id] = true
		values[`isManager-${id}`] = isManager || false
	})

	return {
		...values,
		userType: {
			key: userType?.id,
			label: translateIntlMsgString(`userTypes.${userType.name}`),
			value: userType,
		},
	}
}

export const formatUserModalOnValidateData = ({
	data,
	mode,
	editedUserId,
	currentUser,
}) => {
	const specificModeFields =
		mode === EFormModalMode.edit
			? {
					id: editedUserId,
			  }
			: {
					account: currentUser?.account?.id,
			  }

	const teams = getTeamsAndUsersListWithManagerById(data, '/api/teams/')

	const permissionIds = Object.entries(data)
		.filter(([key, value]) => key.includes('/api/permissions') && value)
		.map(([key]) => key)

	const {
		canLogin,
		cellphone,
		email,
		firstName,
		isActive,
		lastName,
		job,
		phone,
		userType,
		// canLoginApp,  !** disabled for now **!
	} = data

	return {
		...specificModeFields,
		userType: userType?.value?.id,
		permissions: permissionIds,
		teams,
		firstName,
		lastName,
		job,
		phone,
		cellphone,
		isActive,
		canLogin,
		email,
		// canLoginApp, !** disabled for now **!
	}
}
