/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { gql, useMutation } from '@apollo/client'

/////////////////////////////////////////////////////////
// CATALOGUE-PRODUCT - PRODUCT
/////////////////////////////////////////////////////////

export const REMOVE_PRODUCT_CATEGORY = gql`
	mutation removeProductCategory($input: removeProductCategoryInput!) {
		removeProductCategory(input: $input) {
			productCategory {
				id
			}
		}
	}
`

export const CREATE_PRODUCT_CATEGORY = gql`
	mutation createProductCategory($input: createProductCategoryInput!) {
		createProductCategory(input: $input) {
			productCategory {
				id
			}
		}
	}
`

export const UPDATE_PRODUCT_CATEGORY = gql`
	mutation updateProductCategory($input: updateProductCategoryInput!) {
		updateProductCategory(input: $input) {
			productCategory {
				id
			}
		}
	}
`

export const CREATE_PRODUCT = gql`
	mutation createProduct($input: createProductInput!) {
		createProduct(input: $input) {
			product {
				id
			}
		}
	}
`

export const UPDATE_PRODUCT = gql`
	mutation updateProduct($input: updateProductInput!) {
		updateProduct(input: $input) {
			product {
				id
			}
		}
	}
`

/////////////////////////////////////////////////////////
// CATALOGUE-PRODUCT - PRODUCT BASE
/////////////////////////////////////////////////////////

export const REMOVE_PRODUCT_BASE_CATEGORY = gql`
	mutation removeProductBaseCategory($input: removeProductBaseCategoryInput!) {
		removeProductBaseCategory(input: $input) {
			productBaseCategory {
				id
			}
		}
	}
`

export const CREATE_PRODUCT_BASE_CATEGORY = gql`
	mutation createProductBaseCategory($input: createProductBaseCategoryInput!) {
		createProductBaseCategory(input: $input) {
			productBaseCategory {
				id
			}
		}
	}
`

export const UPDATE_PRODUCT_BASE_CATEGORY = gql`
	mutation updateProductBaseCategory($input: updateProductBaseCategoryInput!) {
		updateProductBaseCategory(input: $input) {
			productBaseCategory {
				id
			}
		}
	}
`

export const CREATE_PRODUCT_BASE = gql`
	mutation createProductBase($input: createProductBaseInput!) {
		createProductBase(input: $input) {
			productBase {
				id
			}
		}
	}
`

export const UPDATE_PRODUCT_BASE = gql`
	mutation updateProductBase($input: updateProductBaseInput!) {
		updateProductBase(input: $input) {
			productBase {
				id
			}
		}
	}
`

/////////////////////////////////////////////////////////
// LIST ORDER DEPRECATED ??
/////////////////////////////////////////////////////////

export const UPDATE_LIST_ORDER_CATEGORIES = gql`
	mutation updateListOrderProductCategory(
		$input: updateListOrderProductCategoryInput!
	) {
		updateListOrderProductCategory(input: $input) {
			productCategory {
				id
			}
		}
	}
`

export const UPDATE_LIST_ORDER_PRODUCT = gql`
	mutation updateListOrderProduct($input: updateListOrderProductInput!) {
		updateListOrderProduct(input: $input) {
			product {
				id
			}
		}
	}
`

/**  ---------------------HOOKS---------------------- */

export const useCreateCategory = () => useMutation(CREATE_PRODUCT_CATEGORY)
export const useUpdateCategory = () => useMutation(UPDATE_PRODUCT_CATEGORY)
export const useCreateProduct = () => useMutation(CREATE_PRODUCT)
export const useUpdateProduct = () => useMutation(UPDATE_PRODUCT)

export const useCreateBaseCategory = () =>
	useMutation(CREATE_PRODUCT_BASE_CATEGORY)
export const useUpdateBaseCategory = () =>
	useMutation(UPDATE_PRODUCT_BASE_CATEGORY)
export const useCreateBaseProduct = () => useMutation(CREATE_PRODUCT_BASE)
export const useUpdateBaseProduct = () => useMutation(UPDATE_PRODUCT_BASE)

export const useRemoveProductCategory = () => useMutation(REMOVE_PRODUCT_CATEGORY)
export const useRemoveProductBaseCategory = () => useMutation(REMOVE_PRODUCT_BASE_CATEGORY)

export const useUpdateListOrderCategories = () =>
	useMutation(UPDATE_LIST_ORDER_CATEGORIES)

export const useUpdateListOrderProduct = () =>
	useMutation(UPDATE_LIST_ORDER_PRODUCT)
