import React, { useCallback, useContext } from 'react'

import { Button } from '@mui/material'

import { FormModalContext } from '../FormModalContext'
import { useFormatMessage } from '../../../../utils/hooks'

interface IOtherButtons {
	btnList: any
}

const OtherButtons: React.FC<IOtherButtons> = ({ btnList }) => {
	const intlMsg = useFormatMessage()
	const { triggerError, setSelectedTab, selectedTab } =
		useContext(FormModalContext)

	const handleOtherFooterBtn = useCallback(
		(i) => async () => {
			const btnObject = btnList[i] || null
			if (btnObject?.action) btnObject?.action()
			if (btnObject?.switchTabIndex) {
				const isInError = await triggerError()
				if (!isInError) setSelectedTab(btnObject?.switchTabIndex)
			}
		},
		[btnList, triggerError]
	)

	return (
		<>
			{btnList?.map(
				({ switchTabIndex, text }, i) =>
					(!switchTabIndex || switchTabIndex !== selectedTab) && (
						<Button
							key={`${text}-${i}`}
							variant='outlined'
							color='primary'
							onClick={handleOtherFooterBtn(i)}
						>
							{intlMsg(text)}
						</Button>
					)
			)}
		</>
	)
}

export default OtherButtons
