import React from 'react'

import { GET_CUSTOMER } from '../../api/gql/queries/customersQueries'
import { GET_SITE } from '../../api/gql/queries/sitesQueries'
import { VEHICLE } from '../../api/gql/queries/vehiclesQueries'
import SiteInformations from './informationsComponents/siteInformations'
import AccountInformations from './informationsComponents/accountInformations'
import VehicleInformations from './informationsComponents/vehicleInformations'
import { EModule, EPermissionScope, EViewModal } from '../../utils/app-models'
import { GET_INTERVENTION_FOR_VIEW_MODAL } from '../../api/gql/queries/interventionsQueries'
import InterventionsByReferenceInformations from './informationsComponents/interventionsByReferenceInformations'
import {
	InterventionValidationSchema,
	siteValidationSchema,
	VehiclesValidationSchema,
} from '../../utils/validationSchema'
import { AGENCY } from '../../api/gql/queries/agencyQueries'
import AgencyInformations from './informationsComponents/agencyInformation'
import AgencyModal from '../dashboard/networkAgency/agencies/agencyModal'
import { interventionFormsTabsConfig } from './customTabs/interventionForms/generateConfig'
import { GET_FORM_INTERVENTION } from '../../api/gql/queries/interventionFormsQueries'
import InterventionForms from './informationsComponents/interventionForms'
import { agencyTabsConfig } from './tabConfig/agencyTabsConfig'
import { interventionTabsConfig } from './tabConfig/interventionTabsConfig'
import { customerAccountTabsConfig } from './tabConfig/customerAccountTabsConfig'
import { siteTabsConfig } from './tabConfig/siteTabsConfig'
import CustomerAccountsModal from '../dashboard/customerAccounts/customerAccountsModal'
import CustomerSiteModal from '../dashboard/customerSites/customerSiteModal'
import VehicleModal from '../dashboard/vehicles/vehicleModal'
import InterventionModal from '../dashboard/interventions/interventionControl/interventionModal'
import { vehicleTabsConfig } from './tabConfig/vehicleTabsConfig'
import InterventionTransportModal from '../dashboard/interventions/interventionTransport/interventionModal'
import interventionTransportModalValidationSchema from '../dashboard/interventions/interventionTransport/interventionModal/interventionTransportModalValidationSchema'
import { interventionTransportTabsConfig } from './tabConfig/interventionTransportTabsConfig'
import InterventionsTransportInformation from './informationsComponents/interventionTransport'
import { GET_INTERVENTION_TRANSPORT_FOR_VIEW_MODAL } from '../../api/gql/queries/interventionsTransportQueries'

//todo this fcn need a refacto, change getConfig system
const getViewModalConfig: any = ({ queryName, varType, varValue }) => {
	switch (queryName) {
		/////////////////////////////////////////////////////////
		// FORM
		/////////////////////////////////////////////////////////

		case 'form':
			return {
				queryConfig: {
					query: GET_FORM_INTERVENTION,
					name: queryName,
					variables: {
						[varType]: varValue,
					},
				},
				customFooter: 'formTemplate.footer',
				customTabConfig: interventionFormsTabsConfig,
				Informations: InterventionForms,
				headerConfig: {
					title: 'interventionForms.report',
					bgColorTitle: '#E8E0DB',
					breadcrumbs: [
						{
							title: 'misc.account',
							selectorsText: ['customer.name'],
							link: {
								vm: EViewModal.CUSTOMERS,
								id: 'customer.id',
								modules: [EModule.crm],
							},
						},
						{
							title: 'misc.site',
							selectorsText: ['site.name'],
							link: {
								vm: EViewModal.SITE,
								id: 'site.id',
								modules: [EModule.site],
							},
						},
						// Display intervention type & link to intervention
						// Depending on the type of intervention (transport,control,..), the exception is processed in the viewModalUtil.ts -> generateBreadcrumbLinks.ts
						{
							isCustomBreadcrumbFor: 'formInterventionReference',
						},
						{
							title: 'misc.vehicle',
							selectorsText: [
								'vehicle.vehicleDetail.immat',
								'vehicle.vehicleDetail.vin',
							],

							link: {
								vm: EViewModal.VEHICLES,
								id: 'vehicle.id',
							},
						},
						{
							title: 'interventionForms.report',
							selectorsText: ['reference'],
						},
					],
				},
				downloadPdf: true,
			}

		/////////////////////////////////////////////////////////
		// SITE
		/////////////////////////////////////////////////////////

		case 'site':
			return {
				queryConfig: {
					query: GET_SITE,
					name: queryName,
					variables: {
						[varType]: varValue,
					},
				},
				tabConfig: siteTabsConfig,
				Informations: SiteInformations,
				headerConfig: {
					title: 'misc.site',
					bgColorTitle: '#EFD6FB',
					breadcrumbs: [
						{
							title: 'misc.account',
							selectorsText: ['customer.name'],
							link: {
								vm: EViewModal.CUSTOMERS,
								id: 'customer.id',
								modules: [EModule.crm],
							},
						},
						{
							title: 'misc.site',
							selectorsText: ['name'],
						},
					],
				},
				modal: {
					component: <CustomerSiteModal modalUtils={{} as any} />,
					varName: 'siteId',
					fieldName: 'id',
					validationSchema: siteValidationSchema,
					permissions: [EPermissionScope.siteManage],
				},
			}

		/////////////////////////////////////////////////////////
		// CUSTOMER
		/////////////////////////////////////////////////////////

		case 'customer':
			return {
				queryConfig: {
					query: GET_CUSTOMER,
					name: queryName,
					variables: {
						[varType]: varValue,
					},
				},
				tabConfig: customerAccountTabsConfig,
				Informations: AccountInformations,
				headerConfig: {
					title: 'misc.account',
					bgColorTitle: '#B0CDFA',
					breadcrumbs: [{ selectorsText: ['name'], title: 'misc.account' }],
				},
				modal: {
					component: <CustomerAccountsModal modalUtils={{} as any} />,
					varName: 'customerId',
					fieldName: 'id',
					permissions: [EPermissionScope.customerManage],
				},
			}

		/////////////////////////////////////////////////////////
		// VEHICLE
		/////////////////////////////////////////////////////////

		case 'vehicle':
			return {
				queryConfig: {
					query: VEHICLE,
					name: queryName,
					variables: {
						[varType]: varValue,
					},
				},
				tabConfig: vehicleTabsConfig,
				Informations: VehicleInformations,
				headerConfig: {
					title: 'misc.vehicle',
					bgColorTitle: '#FAD3B0',
					breadcrumbs: [
						{
							title: 'misc.account',
							selectorsText: ['site.customer.name'],
							link: {
								vm: EViewModal.CUSTOMERS,
								id: 'site.customer.id',
								modules: [EModule.crm],
							},
						},
						{
							title: 'misc.site',
							selectorsText: ['site.name'],
							link: {
								vm: EViewModal.SITE,
								id: 'site.id',
								modules: [EModule.site],
							},
						},
						{
							title: 'misc.vehicle',
							selectorsText: ['vehicleDetail.immat', 'vehicleDetail.vin'],
						},
					],
				},
				modal: {
					component: <VehicleModal modalUtils={{} as any} />,
					varName: 'editedVehicleId',
					fieldName: 'id',
					validationSchema: VehiclesValidationSchema,
					permissions: [EPermissionScope.vehicleUpdate],
				},
			}

		/////////////////////////////////////////////////////////
		// INTERVENTION
		/////////////////////////////////////////////////////////

		case 'intervention':
			return {
				queryConfig: {
					query: GET_INTERVENTION_FOR_VIEW_MODAL,
					name: queryName,
					variables: {
						[varType]: varValue,
					},
				},
				tabConfig: interventionTabsConfig,
				Informations: InterventionsByReferenceInformations,
				headerConfig: {
					title: 'interventions.intervention',
					bgColorTitle: '#B0FACD',
					breadcrumbs: [
						{
							title: 'misc.account',
							selectorsText: ['orderingCustomer.name'],
							link: {
								vm: EViewModal.CUSTOMERS,
								id: 'orderingCustomer.id',
								modules: [EModule.crm],
							},
						},
						{
							title: 'misc.site',
							selectorsText: ['orderingSite.name'],
							link: {
								vm: EViewModal.SITE,
								id: 'orderingSite.id',
								modules: [EModule.site],
							},
						},
						{
							title: 'interventions.intervention',
							selectorsText: ['reference'],
						},
					],
				},
				modal: {
					component: <InterventionModal modalUtils={{} as any} />,
					varName: 'interventionId',
					fieldName: 'id',
					validationSchema: InterventionValidationSchema,
					permissions: [EPermissionScope.interventionUpdate],
				},
			}

		/////////////////////////////////////////////////////////
		// TRANSPORT
		/////////////////////////////////////////////////////////

		case 'interventionTransport':
			return {
				queryConfig: {
					query: GET_INTERVENTION_TRANSPORT_FOR_VIEW_MODAL,
					name: 'intervention',
					variables: { [varType]: varValue },
				},
				tabConfig: interventionTransportTabsConfig,
				Informations: InterventionsTransportInformation,
				headerConfig: {
					title: 'interventions.transport',
					bgColorTitle: '#FFFCAC',
					breadcrumbs: [
						{
							title: 'misc.account',
							selectorsText: ['orderingCustomer.name'],
							link: {
								vm: EViewModal.CUSTOMERS,
								id: 'orderingCustomer.id',
								modules: [EModule.crm],
							},
						},
						{
							title: 'misc.site',
							selectorsText: ['orderingSite.name'],
							link: {
								vm: EViewModal.SITE,
								id: 'orderingSite.id',
								modules: [EModule.site],
							},
						},
						{
							title: 'interventions.transport',
							selectorsText: ['reference'],
						},
					],
				},
				modal: {
					component: <InterventionTransportModal modalUtils={{} as any} />,
					varName: 'interventionId',
					fieldName: 'id',
					validationSchema: interventionTransportModalValidationSchema,
					permissions: [EPermissionScope.interventionUpdate],
				},
			}

		/////////////////////////////////////////////////////////
		// AGENCY
		/////////////////////////////////////////////////////////

		case 'agencyAccount':
			return {
				queryConfig: {
					query: AGENCY,
					name: queryName,
					variables: {
						[varType]: varValue,
					},
				},
				tabConfig: agencyTabsConfig,
				Informations: AgencyInformations,
				headerConfig: {
					title: 'interventions.agence',
					bgColorTitle: '#B0F1FA',
					breadcrumbs: [
						{
							selectorsText: ['agencyCustomDetails[0].agencyName'],
							title: 'interventions.agence',
						},
					],
				},
				modal: {
					component: <AgencyModal modalUtils={{}} />,
					varName: 'agencyId',
					fieldName: 'id',
					isStepper: true,
				},
			}

		default:
			return null
	}
}

export default getViewModalConfig
