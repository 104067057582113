import React from 'react'

import Table from '../../../components/tableV2'
import {
	IOverrideConfigTable,
	IOverrideConfigTableWithArgument,
	IOverrideTableProvider,
} from '../../../utils/typescript/tableType'
import { interventionFormsConfigTable } from './interventionFormsConfigTable'

interface IInterventionControlForms {
	othersQueryParams?
	overrideConfigTable?: IOverrideConfigTable | IOverrideConfigTableWithArgument
	overrideTableProvider?: IOverrideTableProvider
	isInViewModal?: boolean
}

const InterventionControlForms: React.FC<IInterventionControlForms> = ({
	othersQueryParams,
	overrideConfigTable,
	overrideTableProvider,
	isInViewModal = false,
}) => {
	return (
		<Table
			tableConfig={interventionFormsConfigTable}
			overrideConfigTable={overrideConfigTable}
			othersQueryParams={othersQueryParams}
			overrideTableProvider={overrideTableProvider}
			isInViewModal={isInViewModal}
		/>
	)
}

export default InterventionControlForms
