import React, { memo } from 'react'

import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material'

import translateIntlMsgString from '../../../../../../../utils/translateIntlMsgString'
import ChipCountListPopover from '../../../../../../../components/multiItemsPopoverList/popover/simpleListPopover'
import { ROUTES } from '../../../../../../../routes/consts'

const TableRowCSS = {
	'& th': {
		boxShadow: '#e0e0e0 0px -1px',
	},
}

interface IProductBaseDrawerTableContainer {
	rows
}

const ProductBaseDrawerTableContainer: React.FC<
	IProductBaseDrawerTableContainer
> = ({ rows }) => {
	return (
		<TableContainer sx={{ mt: 2 }}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>{translateIntlMsgString('misc.lib')}</TableCell>
						<TableCell>{translateIntlMsgString('misc.clients')}</TableCell>
						<TableCell align='right'>
							{translateIntlMsgString('invoice.priceHT')}
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows?.map(({ rate, name, customers }) => (
						<TableRow key={name} sx={TableRowCSS}>
							<TableCell component='th'>{name}</TableCell>
							<TableCell component='th'>
								<ChipCountListPopover
									text={'misc.clients'}
									data={
										customers?.collection?.map(({ name, id }) => ({
											name,
											id,
										})) || []
									}
									viewModalLink={ROUTES.VIEWMODAL.CUSTOMERS.url}
									maxCharacters={30}
								/>
							</TableCell>
							<TableCell component='th' align='right'>
								{`${rate ? rate.toFixed(2) + '€' : '-'} `}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default memo(ProductBaseDrawerTableContainer)
