import React, { useState, useCallback, cloneElement } from 'react'

import { Link, Stack } from '@mui/material'
import { IModalUtils, useModal } from '../../../../utils/hooks'
import { EFormModalMode } from '../../../../utils/app-models'
import ProductBaseDrawer from '../../../../view/administration/productCatalogue/catalogueProductBase/table/productBaseDrawer'
import TableSideNavItemComment from '../../../multiNavTable/multiTableNavView/tableSideNav/tableSideNavList/NavItems/TableSideNavItemComment'

const linkCSS = {
	cursor: 'pointer',
	whiteSpace: 'nowrap',
}

interface IProductBaseDrawer {
	rowId
	editModal?
	name: string
	description: string
}

const ProductBaseTitleDrawer: React.FC<IProductBaseDrawer> = ({
	rowId,
	editModal = null,
	name,
	description,
}) => {
	const modalUtils: IModalUtils = useModal(false)

	/////////////////////////////////////////////////////////
	// DRAWER PRODUCT BASE
	/////////////////////////////////////////////////////////

	const [drawerIsOpen, setDrawerIsOpen] = useState(false)
	const handleDrawerOpen = useCallback(
		(open: boolean) => () => {
			setDrawerIsOpen(open)
		},
		[open]
	)

	/////////////////////////////////////////////////////////
	// Edit product base
	/////////////////////////////////////////////////////////

	const onEditProductBase = useCallback(() => {
		modalUtils.openModal()
	}, [])

	return (
		<>
			<Stack direction='row' alignItems='center' spacing={0.5}>
				<Link
					variant='caption'
					display='block'
					color='inherit'
					underline='hover'
					onClick={handleDrawerOpen(true)}
					sx={linkCSS}
				>
					{name}
				</Link>

				<TableSideNavItemComment comment={description} />
			</Stack>

			{drawerIsOpen && (
				<ProductBaseDrawer
					rowId={rowId}
					drawerIsOpen={drawerIsOpen}
					handleDrawerOpen={handleDrawerOpen}
					handleEdit={onEditProductBase}
				/>
			)}
			{modalUtils?.isVisible &&
				cloneElement(editModal, { modalUtils, mode: EFormModalMode.edit })}
		</>
	)
}

export default ProductBaseTitleDrawer
