import React, { useMemo } from 'react'

import Grid from '@mui/material/Grid'
import { Box, Stack } from '@mui/material'

import Typography from '@mui/material/Typography'

import { useFormatMessage } from '../../../../utils/hooks'
import FormSelectSearch from '../../../../components/forms/formFields/formSelectSearch'
import { FormModalContext } from '../../../../components/forms/formModal/FormModalContext'
import { useGetVehicleTechnicalInfoModalData } from '../../../../api/gql/queries/vehiclesQueries'
import FormTextField from '../../../../components/forms/formFields/FormTextField'
import FormDatePicker from '../../../../components/forms/formFields/FormDatePicker'

const wrapperModalCSS = {
	p: 4,
	overflow: 'auto',
}

interface IVehicleTechnicalInfos {}

const VehicleTechnicalInfos: React.FC<IVehicleTechnicalInfos> = () => {
	const intlMsg = useFormatMessage()
	const { vehicleTypes } = useGetVehicleTechnicalInfoModalData()

	const vehicleTypesOptions = useMemo(
		() =>
			vehicleTypes?.map((vehicleTypes) => ({
				key: vehicleTypes.id,
				label: intlMsg(`vehicle.${vehicleTypes?.code}`),
				value: vehicleTypes,
			})) || [],
		[vehicleTypes]
	)

	return (
		<Box sx={wrapperModalCSS}>
			<Stack direction='row' alignItems='center' sx={{ mb: 4 }}>
				<Grid container spacing={5}>
					<Grid item xs={12}>
						<Stack direction='row' alignItems='center' spacing={1}>
							<Typography
								variant='subtitle1'
								sx={{ fontWeight: 500 }}
								display='inline'
							>
								{intlMsg('vehicle.technicalInfos')}
							</Typography>
						</Stack>
					</Grid>
				</Grid>
			</Stack>

			<Grid container spacing={5}>
				<Grid item xs={12} lg={6}>
					<Grid container spacing={2}>
						<Grid item xs={12} lg={12}>
							<FormDatePicker
								name='dateMec'
								label={intlMsg('misc.dateMec')}
								views={['day']}
								context={FormModalContext}
							/>
						</Grid>

						<Grid item xs={12}>
							<FormSelectSearch
								name='vehicleType'
								label={intlMsg('vehicle.vehicleType')}
								selectElements={vehicleTypesOptions}
								context={FormModalContext}
								missingText={intlMsg('misc.noResultSearch')}
							/>
						</Grid>

						<Grid item xs={6}>
							<FormTextField
								name='horsePower'
								type='number'
								label={intlMsg('vehicle.horsePower')}
								context={FormModalContext}
							/>
						</Grid>

						<Grid item xs={6}>
							<FormTextField
								name='fiscPower'
								label={intlMsg('vehicle.fiscPower')}
								type='number'
								context={FormModalContext}
							/>
						</Grid>

						<Grid item xs={12}>
							<FormTextField
								name='ptac'
								label={intlMsg('vehicle.ptac')}
								context={FormModalContext}
							/>
						</Grid>

						<Grid item xs={6}>
							<FormTextField
								name='nbDoors'
								label={intlMsg('vehicle.nbDoors')}
								type='number'
								context={FormModalContext}
							/>
						</Grid>

						<Grid item xs={6}>
							<FormTextField
								name='nbSeats'
								label={intlMsg('vehicle.nbSeats')}
								type='number'
								context={FormModalContext}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} lg={6}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<FormTextField
								name='tireSizeRear'
								label={intlMsg('vehicle.tireSizeRear')}
								context={FormModalContext}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormTextField
								name='tireSizeBack'
								label={intlMsg('vehicle.tireSizeBack')}
								context={FormModalContext}
							/>
						</Grid>

						<Grid item xs={12}>
							<FormTextField
								name='comment'
								label={intlMsg('vehicle.complementaryInfos')}
								isRequired
								context={FormModalContext}
								multiline={true}
								minRows={6}
								maxRows={6}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	)
}

export default VehicleTechnicalInfos
