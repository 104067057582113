import React, { useCallback, useState } from 'react'

import { Box, Link, Typography } from '@mui/material'

import { useFormatMessage } from '../../utils/hooks'
import { maxStringDisplay } from '../../utils/string'

interface IWShowMoreTxt {
	txt: string
	maxSize: number
	fontSize?: number
}

const ShowMoreTxt: React.FC<IWShowMoreTxt> = ({
	txt,
	maxSize,
	fontSize = 12,
}) => {
	const intlMsg = useFormatMessage()

	const [maxTxtSize, setMaxTextSize] = useState(maxSize)
	const [isShowMore, setIsShowMore] = useState(false)

	const toggleShowMoreText = useCallback(() => {
		setIsShowMore((prevState) => !prevState)
		setMaxTextSize((prevState) => (prevState === maxSize ? 1000000 : maxSize))
	}, [maxSize])

	return (
		<Box>
			<Typography fontSize={fontSize}>
				{maxStringDisplay(txt, maxTxtSize)}
				{txt?.length > maxSize && (
					<Link href='#' onClick={toggleShowMoreText} ml={1}>
						{isShowMore ? intlMsg('misc.showLess') : intlMsg('misc.showMore')}
					</Link>
				)}
			</Typography>
		</Box>
	)
}

export default ShowMoreTxt
