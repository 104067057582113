import React, { useMemo } from 'react'

import dayjs from 'dayjs'
import { Box, Stack, Typography } from '@mui/material'

import { ResumeProductBase } from '../../../../catalogueProduct/table/modal/catalogProductModalInfos'
import { baseType, unitsOfWork } from '../../../../../../../utils/const'
import ShowMoreTxt from '../../../../../../../components/showMoreTxt'
import translateIntlMsgString from '../../../../../../../utils/translateIntlMsgString'

const TypographyCSS = {
	fontSize: '16px',
	fontWeight: 700,
	lineHeight: '24px',
	letterSpacing: '0.25px',
	textAlign: 'left',
}

interface IProductBaseDrawerResume {
	productBase
}

const ProductBaseDrawerResume: React.FC<IProductBaseDrawerResume> = ({
	productBase,
}) => {
	const resumeProductBaseConfig = useMemo(
		() => [
			{
				title: 'misc.priceHt',
				value: `${
					productBase?.rate ? productBase?.rate.toFixed(2) + ' €' : '-'
				} `,
			},
			{
				title: 'misc.duration',
				value: productBase?.duration
					? dayjs(productBase.duration).format('HH:mm')
					: '',
			},
			{
				title: 'misc.type',
				value: baseType?.filter(
					({ value }) => value?.name === productBase?.type
				)?.[0]?.label,
			},
			{
				title: 'misc.workUnit',
				value: unitsOfWork?.filter(
					({ value }) => value?.name === productBase?.unitOfWork
				)?.[0]?.label,
			},
		],
		[productBase]
	)

	return (
		<>
			<Typography sx={TypographyCSS} variant='h4' gutterBottom>
				{translateIntlMsgString('catalogProduct.productBaseDetails')}
			</Typography>
			<Stack
				direction='row'
				justifyContent='space-between'
				p={2}
				bgcolor='grey.50'
			>
				{resumeProductBaseConfig?.map(({ title, value }, i) => (
					<ResumeProductBase key={i} title={title} value={value} />
				))}
			</Stack>

			{productBase?.description && (
				<Box mt={2}>
					<ShowMoreTxt txt={productBase.description} maxSize={150} />
				</Box>
			)}
		</>
	)
}

export default ProductBaseDrawerResume
