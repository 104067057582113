import React from 'react'

import Grid from '@mui/material/Grid'

import PermissionsHandler from '../../../../../../components/permissionsHandler'
import { EModule } from '../../../../../../utils/app-models'
import GlobalActions from './GlobalActions'
import { useGlobalActionData } from './globalActionsUtils'
import VehiclesAndServicesContent from './VehiclesAndServicesContent'
import { Box } from '@mui/material'
import { BannerFormModalIntervention } from '../interventionModalControlInfos/BannerFormModalIntervention'

const VehiclesAndServicesContainerCSS = {
	flexGrow: '1',
	display: 'flex',
	flexDirection: 'column',
	p: 4,
	overflow: 'auto',
}

interface IVehiclesAndServices {}

const VehiclesAndServices: React.FC<IVehiclesAndServices> = () => {
	const { formTemplatesOptions, productCategoriesOptions } =
		useGlobalActionData()

	return (
		<>
			<BannerFormModalIntervention />
			<Box sx={VehiclesAndServicesContainerCSS}>
				<PermissionsHandler
					modulesName={[EModule.productCatalog, EModule.formTemplate]}
				>
					<Grid container>
						<GlobalActions
							formTemplatesOptions={formTemplatesOptions}
							productCategoriesOptions={productCategoriesOptions}
						/>
					</Grid>
				</PermissionsHandler>
				<VehiclesAndServicesContent />
			</Box>
		</>
	)
}
export default VehiclesAndServices
