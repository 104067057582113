/* eslint-disable react/react-in-jsx-scope */

import { ROUTES } from '../../../routes/consts'
import { EModule } from '../../../utils/app-models'

import { catalogueProductConfigSideNav } from './catalogueProduct/sideNav/catalogueProductConfigSideNav'
import { catalogueProductBaseConfigSideNav } from './catalogueProductBase/sideNav/catalogueProductBaseConfigSideNav'
import { catalogueProductSharedConfigSideNav } from './catalogueProductShared/sideNav/catalogueProductSharedConfigSideNav'

import { catalogueProductConfigTable } from './catalogueProduct/table/catalogueProductConfigTable'
import { catalogueProductBaseConfigTable } from './catalogueProductBase/table/catalogueProductBaseConfigTable'
import { catalogueProductShareConfigTable } from './catalogueProductShared/table/catalogueProductSharedConfigTable'

import EmptyTableMessage from '../../../components/multiNavTable/multiTableNavView/tableView/tableEmptyMessage'
import translateIntlMsgString from '../../../utils/translateIntlMsgString'
import { IMultiTableNavConfig } from '../../../utils/typescript/multiNavTableType'

/////////////////////////////////////////////////////////
// Configuration used with multiNavTable component
/////////////////////////////////////////////////////////

const ProductCatalogueConfig: IMultiTableNavConfig[] = [
	// CATALOGUE PRODUCT -------------------------------------------------------
	{
		modulesName: [EModule.productCatalog],
		tableMainNav: {
			route: ROUTES.ADMINISTRATION.PRODUCT_CATALOGUE.CATALOGUE_PRODUCT.url,
			label: 'catalogProduct.product',
		},
		tableSideNav: catalogueProductConfigSideNav,
		tableView: {
			tableConfig: catalogueProductConfigTable,
			othersQueryParams: 'productCategory',
			emptyMessage: () => (
				<EmptyTableMessage
					message={translateIntlMsgString('catalogProduct.emptyProduct')}
				/>
			),
		},
	},
	// CATALOGUE BASE -------------------------------------------------------
	{
		modulesName: [EModule.productCatalog, EModule.crm],
		tableMainNav: {
			route: ROUTES.ADMINISTRATION.PRODUCT_CATALOGUE.CATALOGUE_PRODUCT_BASE.url,
			label: 'catalogProduct.base',
		},
		tableSideNav: catalogueProductBaseConfigSideNav,
		tableView: {
			tableConfig: catalogueProductBaseConfigTable,
			othersQueryParams: 'productBaseCategory',
			emptyMessage: () => (
				<EmptyTableMessage
					message={translateIntlMsgString('catalogProduct.emptyProductBase')}
				/>
			),
		},
	},
	// CATALOGUE SHARED -------------------------------------------------------
	{
		modulesName: [],
		tableMainNav: {
			route:
				ROUTES.ADMINISTRATION.PRODUCT_CATALOGUE.CATALOGUE_PRODUCT_SHARED.url,
			label: 'catalogProduct.sharedCatalog',
		},
		tableSideNav: catalogueProductSharedConfigSideNav,
		tableView: {
			tableConfig: catalogueProductShareConfigTable,
			othersQueryParams: 'productCategory',
			emptyMessage: () => (
				<EmptyTableMessage
					message={translateIntlMsgString('catalogProduct.emptyProductShared')}
				/>
			),
		},
	},
]

export default ProductCatalogueConfig
