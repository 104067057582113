import React from 'react'

import Grid from '@mui/material/Grid'
import PhoneIcon from '@mui/icons-material/Phone'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'

import DeboucedFormTextField from '../../../../components/forms/formFields/DebouncedFormTextField'
import { FormModalContext } from '../../../../components/forms/formModal/FormModalContext'
import { useFormatMessage } from '../../../../utils/hooks'
import FormTextField from '../../../../components/forms/formFields/FormTextField'

const wrapperModalCSS = { p: 4, overflow: 'auto' }

interface IEditUserModalInfo {}

const EditUserModalInfo: React.FC<IEditUserModalInfo> = () => {
	const intlMsg = useFormatMessage()

	return (
		<Grid container spacing={2} sx={wrapperModalCSS}>
			<Grid item xs={6}>
				<DeboucedFormTextField
					name='firstName'
					label={intlMsg('misc.firstname')}
					isRequired
					context={FormModalContext}
				/>
			</Grid>
			<Grid item xs={6}>
				<DeboucedFormTextField
					name='lastName'
					label={intlMsg('misc.lastname')}
					isRequired
					context={FormModalContext}
				/>
			</Grid>
			<Grid item xs={12}>
				<FormTextField
					name='job'
					label={intlMsg('misc.job')}
					context={FormModalContext}
				/>
			</Grid>
			<Grid item xs={6}>
				<FormTextField
					name='phone'
					label={intlMsg('misc.phone')}
					context={FormModalContext}
					InputProps={{ startAdornment: <PhoneIcon /> }}
					InputLabelProps={{ shrink: true }}
				/>
			</Grid>
			<Grid item xs={6}>
				<FormTextField
					name='cellphone'
					label={intlMsg('misc.cellphone')}
					context={FormModalContext}
					InputProps={{ startAdornment: <PhoneAndroidIcon /> }}
					InputLabelProps={{ shrink: true }}
				/>
			</Grid>
		</Grid>
	)
}

export default EditUserModalInfo
