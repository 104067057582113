import React, { memo } from 'react'
import { Box } from '@mui/material'
import { stringifiedColor } from '../../../../../../utils/color'

interface ITableSideNavItemColor {
	color: string
}

const TableSideNavItemColor: React.FC<ITableSideNavItemColor> = ({ color }) => {
	const colorCSS = {
		height: '11px',
		width: '11px',
		minWidth: '11px',
		marginRight: '10px',
		borderRadius: 1,
		backgroundColor: color ? (stringifiedColor(color, 400) as any) : 'white',
		...(!color && { visibility: 'hidden' }),
	}

	return <Box sx={colorCSS} />
}

export default memo(TableSideNavItemColor)
