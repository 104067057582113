import React, { useCallback } from 'react'

import { Button } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

import { useFormatMessage } from '../../../../../../../utils/hooks'

interface ISelectVehiclesModalBtn {
	openModal: any
}

const SelectVehiclesModalBtn: React.FC<ISelectVehiclesModalBtn> = ({
	openModal,
}) => {
	const intlMsg = useFormatMessage()

	const handleOpenClick = useCallback(() => {
		openModal()
	}, [])

	return (
		<Button
			id='Playwright-SelectVehiclesModalBtn-Button'
			onClick={handleOpenClick}
			color='primary'
			variant='contained'
			startIcon={<AddCircleOutlineIcon />}
		>
			{intlMsg('misc.add')}
		</Button>
	)
}
export default SelectVehiclesModalBtn
