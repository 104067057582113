/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'

import {
	EFormModalMode,
	EPermissionScope,
} from '../../../../../utils/app-models'
import {
	ITableToolBarActionConfig,
	ITableToolBarConfig,
} from '../../../../../utils/typescript/tableType'
import FormBtnModal from '../../../../../components/forms/formModalBtn'
import {
	GET_PRODUCT_BASES_LIST,
	useExportProductBases,
} from '../../../../../api/gql/queries/catalogProductQueries'
import CatalogueProductBaseModal from './modal'
import { listProductBaseCatalogueViewTable } from '../../../../../components/tableV2/config/administration/productCatalogue/listProductBaseCatalogueViewTable'
import CustomTableTitle from '../../../../../components/multiNavTable/multiTableNavView/tableView/CustomTableTitle'


////////////////////////////////////////////////////////////////
// Global variable
////////////////////////////////////////////////////////////////

const queryConfig = {
	query: GET_PRODUCT_BASES_LIST,
	queryName: 'productBases',
}

////////////////////////////////////////////////////////////////
// tableToolBarAction CONFIG
////////////////////////////////////////////////////////////////

const refreshQuerie = ['getProductBasesList']

const tableToolBarActionConfig: ITableToolBarActionConfig = {
	refreshQuerie,
}

////////////////////////////////////////////////////////////////
// TableToolBarHeader CONFIG
////////////////////////////////////////////////////////////////

const tableToolBarConfig: ITableToolBarConfig = {
	customTitle: CustomTableTitle,
	stickyToolBar: true,
	tableConfig: listProductBaseCatalogueViewTable,
	localStorageName: 'listCatalogueProductBaseViewTableFilter',
	queryConfig,
	exportConfig: { exportQuery: useExportProductBases },
	modalButton: {
		Btn: (
			<FormBtnModal
				modal={<CatalogueProductBaseModal modalUtils={{} as any} />}
				mode={EFormModalMode.create}
			/>
		),
	},
	createPermission: [EPermissionScope.productBaseManage],
}

////////////////////////////////////////////////////////////////
// tableContainer CONFIG
////////////////////////////////////////////////////////////////

const tableContainerConfig = {
	tableConfig: listProductBaseCatalogueViewTable,
	queryConfig,
}

////////////////////////////////////////////////////////////////
// -> EXPORT CONFIG MAIN TABLE
////////////////////////////////////////////////////////////////

export const catalogueProductBaseConfigTable = {
	tableToolBarConfig,
	tableToolBarActionConfig,
	tableContainerConfig,
	displaySwitchOption: true,
}
