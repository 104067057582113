/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Stack from '@mui/material/Stack'
import React from 'react'

import OpenLinkCell from '../../../../../../../components/tableV2/tableCell/OpenLinkCell'
import StatusVehicle from '../../../../../../../components/tableV2/tableCell/StatusVehicle'
import { EModule } from '../../../../../../../utils/app-models'
import DeleteSelectedVehicleBtn from './DeleteSelectedVehicleBtn'
import FormTemplatesColumn from './FormTemplatesColumn'
import ProductsColumn from './ProductsColumn'
import { ROUTES } from '../../../../../../../routes/consts'

const selectedVehiclesListTableConfig = [
	{
		columnLabel: 'vehicle.immat',
		orderBy: 'vehicleDetail_immat',
		dataKey: 'vehicleDetail.immat',
	},
	{
		columnLabel: 'vehicle.vin',
		orderBy: 'vehicleDetail_vin',
		personalizedCell: ({ id, vehicleId, vehicleDetail }) => (
			<>
				{vehicleDetail?.vin ? (
					<OpenLinkCell
						txt={vehicleDetail.vin}
						viewModalLink={`${ROUTES.VIEWMODAL.VEHICLES.url}${vehicleId || id}`}
					/>
				) : (
					'-'
				)}
			</>
		),
	},
	{
		columnLabel: 'vehicle.brand',
		orderBy: 'vehicleDetail_vehicleBrand_name',
		personalizedCell: ({ vehicleDetail }) =>
			vehicleDetail?.vehicleBrand?.name || '-',
	},
	{
		columnLabel: 'vehicle.model',
		orderBy: 'vehicleDetail_vehicleModelText',
		personalizedCell: ({ vehicleDetail }) =>
			vehicleDetail?.vehicleModelText || '-',
	},
	{
		columnLabel: 'vehicle.category',
		orderBy: 'vehicle_vehicleDetail_vehicleCategory_name',
		dataKey: 'vehicle.vehicleDetail.vehicleCategory.name',
	},
	{
		columnLabel: 'vehicle.statusVehicle',
		orderBy: 'status_code',
		personalizedCell: ({ status, comment }) => (
			<StatusVehicle status={status} comment={comment} />
		),
	},
	{
		columnLabel: 'interventions.productsToAchieve',
		modules: [EModule.productCatalog],
		personalizedCell: ({ id }) => (
			<Stack p={1} width={'400px'}>
				<ProductsColumn vehicleId={id} />
			</Stack>
		),
	},
	{
		columnLabel: 'interventions.reportsToAchieve',
		modules: [EModule.formTemplate],
		personalizedCell: ({ id }) => (
			<Stack p={1} width={'400px'}>
				<FormTemplatesColumn vehicleId={id} />
			</Stack>
		),
	},
	{
		columnLabel: '',
		sticky: true,
		cellAlign: 'center',
		tableCellAlign: 'center',
		visible: true,
		personalizedCell: (vehicle) => (
			<DeleteSelectedVehicleBtn vehicle={vehicle} />
		),
	},
]

export default selectedVehiclesListTableConfig
