import React, { Context, useCallback, useContext } from 'react'

import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { IFormModalContext } from '../formModal/FormModalContext'
import { IStepperContext } from '../formStepper/StepperContext'

interface IFormSwitchButton {
	name: string
	config: {
		primary: {
			text: string
			value: any
		}
		secondary: {
			text: string
			value: any
		}
	}
	size?: 'medium' | 'small' | undefined
	context: Context<IFormModalContext> | Context<IStepperContext>
	specialOnChange?: any
}

const FormSwitchButton: React.FC<IFormSwitchButton> = ({
	name,
	config,
	size,
	context,
	specialOnChange,
}) => {
	const { setValue, data } = useContext<IFormModalContext | IStepperContext>(
		context as any
	)

	const handleChange = useCallback(
		(_, value) => {
			if (value !== null) {
				specialOnChange
					? specialOnChange({ fieldName: name, value })
					: setValue(name, value)
			}
		},
		[data]
	)

	return (
		<ToggleButtonGroup
			exclusive
			onChange={handleChange}
			size={size || 'small'}
			value={data[name]}
			color='primary'
			fullWidth
		>
			<ToggleButton value={config.primary.value}>
				{config.primary.text}
			</ToggleButton>
			<ToggleButton value={config.secondary.value}>
				{config.secondary.text}
			</ToggleButton>
		</ToggleButtonGroup>
	)
}

export default FormSwitchButton
