
import { Observable } from '@apollo/client'
import toast from 'react-hot-toast'

import { onError } from '@apollo/client/link/error'
import api from '../api/axios/axios'
import { logout } from '../utils/utils'

//TODO - develop a better system
const gqlErrorsToIgnore = ['vehicleAlreadyOwned', 'userUnknown', 'existingCustomerUpdated']

const errorLink = onError((responseObj) => {
	const { networkError, operation, forward, graphQLErrors } = responseObj

	if (
		networkError &&
		'result' in networkError &&
		networkError.result?.message === 'Expired JWT Token'
	) {
		const token = localStorage.getItem('token')
		const refreshToken = localStorage.getItem('refreshToken')

		if (refreshToken && token) {
			return new Observable((observer) => {
				api
					.post('/token_refresh', {
						refresh_token: refreshToken,
						token,
					})
					.then((refreshResponse) => {
						localStorage.setItem('token', refreshResponse?.data?.token)
						localStorage.setItem(
							'refreshToken',
							refreshResponse?.data?.refresh_token
						)
						operation.setContext(({ headers = {} }) => ({
							headers: {
								...headers,
								authorization: `Bearer ${refreshResponse?.data?.token}` || null,
							},
						}))
					})
					.then(() => {
						const subscriber = {
							next: observer.next.bind(observer),
							error: observer.error.bind(observer),
							complete: observer.complete.bind(observer),
						}

						// Retry last failed request
						forward(operation).subscribe(subscriber)
					})
					.catch((error) => {
						logout()
						observer.error(error)
					})
			})
		} else {
			logout()
		}
	} else if (graphQLErrors) {
		console.error('ERROR - graphQLErrors: ', graphQLErrors)
		graphQLErrors.forEach(({ extensions: { display, message } }) => {
			if (display) {
				if (
					!gqlErrorsToIgnore.find(
						(msgToIgnore) => `errors.${msgToIgnore}` === message
					)
				)
					toast.error(message)
			} else toast.error('errors.genericError')
		})
	} else {
		toast.error('errors.genericError')
	}
})

export default errorLink
