import React from 'react'
import { Box } from '@mui/material'
import { formatDate } from '../../../../../utils/date'

interface IDate {
	record
}

const Date: React.FC<IDate> = ({ record }) => {
	return (
		<Box sx={{ ml: 0 }}>
			{record?.value?.value ? formatDate(record?.value?.value) : '-'}
		</Box>
	)
}

export default Date
