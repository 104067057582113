import React, { useCallback } from 'react'

import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import Backdrop from '@mui/material/Backdrop'
import { Box } from '@mui/material'

import FooterStepperModal from './FooterStepperModal'
import HeaderStepperModal from './HeaderStepperModal'
import Stepper from '.'

import { modalCSS } from '../modalSxCss'

interface IStepperModal {
	modalName: string
	loading?: boolean
	onValidate: any
	onClose?: any
	modalUtils: any
}

const StepperModal: React.FC<IStepperModal> = ({
	modalName,
	loading = false,
	onValidate,
	onClose = null,
	modalUtils,
}) => {
	const { closeModal, isVisible } = modalUtils

	const handleCloseModal = useCallback(
		(e, reason) => {
			if (reason !== 'backdropClick') {
				closeModal()
				if (onClose) onClose()
			}
		},
		[closeModal]
	)

	return (
		<>
			{isVisible && (
				<Modal
					open={isVisible}
					onClose={handleCloseModal}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{ timeout: 500 }}
				>
					<Fade in={isVisible}>
						<Box sx={{ ...modalCSS, width: '800px', height: ' 95%' }}>
							<HeaderStepperModal
								handleOnClose={handleCloseModal}
								modalName={modalName}
							/>
							<Stepper loading={loading} />
							<FooterStepperModal onValidate={onValidate} onClose={onClose} />
						</Box>
					</Fade>
				</Modal>
			)}
		</>
	)
}

export default StepperModal
