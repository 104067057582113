export const listContactsAttributes = `
    collection{
        id,
        firstName,
        lastName,
        email,
        cellphone,
        job,
        isCustomerResp,
        isSiteResp,
        canAccessMyContent,
        phone,
        account {
            id,
            name
        }
        customer{
            id,
            name
            },
        sites(itemsPerPage:100000) {
            collection{
                id,
                name,
            }
        }
    } 
    paginationInfo{
        totalCount
    }
`

export const contactInfosSlidepanelAttributes = `
    id,
    firstName,
    lastName,
    email,
    cellphone,
    phone,
    job,
    isCustomerResp,
    isSiteResp,
    dateCreated,
    canAccessMyContent,
    account{id},
`
