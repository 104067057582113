import React from 'react'

import { useLocation } from 'react-router-dom'

import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionSummary, Divider, Box } from '@mui/material'

import { useFormatMessage } from '../../../utils/hooks'
import menuConfig from './administrationMenuConfig'
import AdministrationMenuRow from './AdministrationMenuRow'
import PermissionsHandler from '../../../components/permissionsHandler'

const paperCSS = {
	width: { xs: '100%', md: '290px' },
	p: 0,
	pt: 1,
	mr: 0.1,
	display: 'flex',
	flexDirection: 'column',
	height: { xs: 'auto', md: 1 },
	overflow: 'auto',
}

const AdministrationMenu: React.FC = () => {
	const intlMsg = useFormatMessage()
	const location = useLocation()

	return (
		<Paper sx={paperCSS} elevation={1}>
			{menuConfig.map(({ sectionTitle, subMenu, permissions, modules }) => (
				<PermissionsHandler
					key={sectionTitle}
					permissionsName={permissions}
					modulesName={modules}
				>
					<Box sx={{ mb: 0 }}>
						<Accordion defaultExpanded elevation={0}>
							<AccordionSummary expandIcon={<ExpandMoreIcon />}>
								<Typography variant='body1' sx={{ textTransform: 'uppercase' }}>
									{intlMsg(sectionTitle)}
								</Typography>
							</AccordionSummary>

							{subMenu.map(
								({
									icon,
									title,
									path,
									subPermissions,
									subModules,
									multiPaths,
								}: any) => (
									<PermissionsHandler
										key={title}
										permissionsName={subPermissions}
										modulesName={subModules}
									>
										<AdministrationMenuRow
											title={title}
											isSelected={
												path === location?.pathname ||
												multiPaths?.find((p) => p === location?.pathname) ||
												false
											}
											path={path}
											icon={icon}
										/>
									</PermissionsHandler>
								)
							)}
						</Accordion>
						<Divider sx={{ mt: 0 }} />
					</Box>
				</PermissionsHandler>
			))}
		</Paper>
	)
}

export default AdministrationMenu
