export default {
	//  import vehicle ///////////////
	immat: 'Immatriculation',
	brand: 'Marque',
	model: 'Modèle',
	dateImmat: 'Date de mise en circulation',
	commercialName: 'Dénomination commerciale',
	vin: 'VIN',
	bodywork: 'Classification',
	vehicleType: 'Catégorie',
	fuel: 'Carburant',
	transmission: 'Transmission',
	powerCH: 'Puissance CH',
	powerCV: 'Puissance CV',
	displacement: 'cylindrée',
	nbDoors: 'Nombre portes',
	nbSeats: 'Nombre sièges',
	ptac: 'PTAC',
	dimAv: 'Dimension pneu AV',
	dimAr: 'Dimension pneu AR',
	dateParcEntry: "Date d'entrée sur parc",
	dateParcExit: 'Date de sortie de parc',

	//  import site ///////////////
	siteName: 'Nom du site',
	address: 'Adresse',
	zipCode: 'Code postal',
	city: 'Ville',
	country: 'Pays',
	siret: 'SIRET',
	phone: 'Téléphone',
	cellphone: 'Mobile',
}
