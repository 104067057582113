import React from 'react'

import { EModule, EViewModal, dataListType } from '../../../../utils/app-models'
import VmCardExpended from '../../../../components/viewModal/vmCardExpended'
import DataList from '../../../../components/list/dataList'
import DataListGrid from '../../../../components/list/dataList/DataListGrid'
import PermissionsHandler from '../../../../components/permissionsHandler'

interface ITransportRequest {
	orderingCustomer
	orderingSite
	externalReference
	airTableReference
}

const TransportRequest: React.FC<ITransportRequest> = ({
	orderingCustomer,
	orderingSite,
	externalReference,
	airTableReference,
}) => {
	return (
		<VmCardExpended title='interventions.transportRequest' titleGutter>
			<DataListGrid>
				<DataList
					dataType={dataListType.vmLink}
					title='misc.account'
					data={[
						{
							text: orderingCustomer?.name,
							link: { vm: EViewModal.CUSTOMERS, id: orderingCustomer?.id },
						},
					]}
					modules={[EModule.crm]}
				/>

				<DataList
					dataType={dataListType.vmLink}
					title='misc.site'
					data={[
						{
							text: orderingSite?.name,
							link: { vm: EViewModal.SITE, id: orderingSite?.id },
						},
					]}
				/>

				<DataList
					dataType={dataListType.simpleText}
					title='interventions.customerRef'
					data={[{ text: externalReference }]}
				/>

				<PermissionsHandler modulesName={[EModule.airTable]}>
					<DataList
						dataType={dataListType.simpleText}
						title='interventions.refAirTable'
						data={[{ text: airTableReference }]}
					/>
				</PermissionsHandler>
			</DataListGrid>
		</VmCardExpended>
	)
}
export default TransportRequest
