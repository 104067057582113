import React from 'react'

import { Grid, Typography } from '@mui/material'

import { InputText } from '../../../../components'
import { useFormatMessage } from '../../../../utils/hooks'
import SwitchButtonForm from '../../../../components/forms/v1/formFields/SwitchButtonForm'

interface INetworkForm {
	control
}

const NetworkForm: React.FC<INetworkForm> = ({ control }) => {
	const intlMsg = useFormatMessage()

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant='subtitle2' gutterBottom>
					{intlMsg('network.networkName')}
				</Typography>
				<InputText
					name='name'
					control={control}
					label={intlMsg('network.networkNameLabel')}
					isrequired
				/>
			</Grid>
			<Grid item xs={12}>
				<Typography variant='subtitle2' gutterBottom>
					{intlMsg('misc.status')}
				</Typography>
				<SwitchButtonForm
					name='isActive'
					control={control}
					valuesDefault={true}
					config={{
						primary: { text: intlMsg('misc.active'), value: true },
						secondary: { text: intlMsg('misc.inactive'), value: false },
					}}
				/>
			</Grid>
		</Grid>
	)
}

export default NetworkForm
