/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { gql, useQuery } from '@apollo/client'
import { adminPanelAccountAttributes } from '../attributes/adminPanelAccountsAttribute'

/**  ---------------------QUERIES---------------------- */

export const GET_ADMIN_PANEL_ACCOUNT = gql`
query getAdminPanelAccount(
    $page: Int
    $itemsPerPage: Int
    $isActive: Boolean
    $searchText: String
) {
     accounts(page: $page, itemsPerPage: $itemsPerPage, isActive: $isActive,searchText: $searchText) {
        ${adminPanelAccountAttributes}
    }
}`

export const GET_ADMIN_PANEL_ACCOUNT_LIST_MODULE = gql`
	query getAdminPanelAccountListModule {
		modules {
			id
			name
			title
			description
			inheritance
			icon
			isPublic
			publicPrice
			priceVar
		}
	}
`
export const GET_ADMIN_PANEL_ACCOUNT_TYPE = gql`
	query getAdminPanelAccountTypeList {
		accountTypes {
			id
			name
			code
		}
	}
`

// /**  ---------------------HOOKS---------------------- */
export const getAdminPanelAccountListModule = () => {
	const { loading, data } = useQuery(GET_ADMIN_PANEL_ACCOUNT_LIST_MODULE)
	return { loading, data: data?.modules || null }
}
export const getAdminPanelAccountTypeList = () => {
	const { loading, data } = useQuery(GET_ADMIN_PANEL_ACCOUNT_TYPE)
	return { loading, data: data?.accountTypes || null }
}
