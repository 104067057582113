import React, {
	useCallback,
	useContext,
	useEffect,
	useState,
	useLayoutEffect,
	useRef,
	useMemo,
} from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'

// TODO to delete moment waiting MAJ react-big-calendar https://github.com/jquense/react-big-calendar/pull/2264
import moment from 'moment'
import 'moment/locale/fr'

import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(isBetween)

import Paper from '@mui/material/Paper'

import { useDebouncedState } from '../../../utils/hooks'
import CalendarHeader from './CalendarHeader'
import CustomEvent from './CustomEvent'
import CalendarShowMore from './CalendarShowMore'
import ColoredDateCellWrapper from './ColoredDateCellWrapper'
import SelectedSlotsPopover from './SelectedSlotsPopover'
import PermissionsHandler from '../../../components/permissionsHandler'
import { EPermissionScope } from '../../../utils/app-models'
import { AuthContext } from '../../../utils/contexts/AuthContext'
import { noop } from '../../../utils/utils'
import { useLazyInterventionsListForCalendar } from '../../../api/gql/queries/interventionsQueries'
import {
	searchEventsIntervention,
	changeViewMoreOpacityIfMatchWithSearch,
	formatInterventionsAndSetEvents,
} from './calendarUtils'
import { FiltersContext } from '../../../components/filters/filtersContext'

const calendarCSS = {
	p: 4,
	display: 'flex',
	flexDirection: 'column',
	width: 1,

	'& .rbc-time-content': {
		borderTop: '2px solid #F5F5F5',
		'& >div:first-of-type': {
			'& div': {
				backgroundColor: 'white!important',
			},
		},
	},

	'& div#selected-slot': {
		border: '0',
		marginBottom: '-0.3px',
		marginTop: '-0.6px',
	},

	'& .rbc-event-content': {
		borderRadius: '4px 4px 4px 4px',
		height: '2.145rem',
	},

	'& .rbc-event:focus': { outline: 'none' },

	'& .rbc-event': {
		backgroundColor: ({ palette }) => palette.grey[50],
		color: 'black',
		border: ({ palette }) => `1px solid ${palette.grey[300]}`,
		borderRadius: '4px 4px 4px 4px',
		padding: '0',
		height: '2.145rem',

		'&:hover': { zIndex: '10' },
	},

	'& .rbc-event.rbc-selected': { backgroundColor: '#E2E8F9' },

	'& .rbc-selected-cell': {
		background: ({ palette }) => `${palette.grey[400]}!important`,
	},

	'& .seeMore': { cursor: 'pointer', color: '#2F5DD1' },
	'& .rbc-time-slot': { minHeight: '30px' },

	'& .rbc-month-view ': { border: 0 },
	'& .rbc-time-view': { border: 0, '& .rbc-time-slot': { border: 'none' } },

	'& .rbc-header': { borderBottom: 'solid 2px #F5F5F5', fontWeight: 'bold' },

	'& .rbc-header + .rbc-header': { borderLeft: 'solid 2px #F5F5F5' },
	'& .rbc-month-row + .rbc-month-row': { borderTop: 'solid 2px #F5F5F5' },
	'& .rbc-day-bg + .rbc-day-bg': { borderLeft: 'solid 2px #F5F5F5' },

	'& .rbc-event-label': { display: 'none' },

	'& .rbc-allday-cell': { display: 'none' },
	'& .rbc-time-header-cell .rbc-header': { borderBottom: 'none' },

	'& .rbc-timeslot-group': {
		borderBottom: '2px solid #F5F5F5',
		fontSize: '12px',
		fontWeight: 300,
	},
	'& .rbc-time-content > * + * > *': { borderLeft: '2px solid #F5F5F5' },

	'& .rbc-button-link': { fontSize: '12px', fontWeight: 300 },
}

const localizer = momentLocalizer(moment)
const views = ['month', 'week', 'day']

const CalendarContainer: React.FC = () => {
	const { filters } = useContext(FiltersContext)
	const calendarRef = useRef()
	const { checkPermissions } = useContext(AuthContext)
	const [currentDate, setCurrentDate] = useState<any>(
		dayjs().startOf('day').toDate()
	)

	const [currentView, setCurrentView] = useState<any>('week')
	const [debouncedState, setDebouncedState, liveState] = useDebouncedState({
		searchText: '',
	})
	const [events, setEvents] = useState<any>([])
	const [selectedSlotsObj, setSelectedSlotsObj] = useState<any>(null)
	const [openPop, setOpenPop] = useState(false)
	const [selectedShowMoreEvents, setSelectedShowMoreEvents] =
		useState<any>(null)
	const [selectedSlotsPosition, setSelectedSlotsPosition] = useState({
		x: 0,
		y: 0,
	})
	const [selectedInter, setSelectedInter] = useState<any>([])
	const [previousSelectedBtnDom, setPreviousSelectedBtnDom] = useState<any>()
	const {
		getInterventions,
		interventions,
		loading: loadingInterventions,
	} = useLazyInterventionsListForCalendar({ fetchPolicy: 'cache-and-network' })

	///////////////////////////////////////////////////////////////////////////////////
	// getInterventions
	///////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		getInterventions({
			variables: {
				page: 1,
				itemsPerPage: 10000, //todo change when back rdy
				...filters,
				startFrom: dayjs(currentDate).startOf(currentView).toDate(),
				endTo: dayjs(currentDate).endOf(currentView).toDate(),
			},
		})
	}, [currentView, currentDate, filters])

	///////////////////////////////////////////////////////////////////////////////////
	// Format interventions and set events
	///////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		formatInterventionsAndSetEvents(interventions, currentView, setEvents)
	}, [loadingInterventions, interventions, currentView])

	///////////////////////////////////////////////////////////////////////////////////
	// Calendar Props
	///////////////////////////////////////////////////////////////////////////////////

	// slotPropGetter ///////////////////////////
	const slotPropGetter = useCallback(
		(slotDate) => {
			const isSelected = selectedSlotsObj
				? dayjs(slotDate).isBetween(
						dayjs(selectedSlotsObj?.start).subtract(30, 'minutes').toDate(),
						selectedSlotsObj?.end
				  )
				: false

			return {
				style: {
					backgroundColor: isSelected
						? '#00000091'
						: dayjs(slotDate).startOf('day').toISOString() ===
						  dayjs().startOf('day').toISOString()
						? '#eaf6ff'
						: 'white',
					zIndex: isSelected ? '20' : 0,
				},
				id: isSelected ? `selected-slot` : 'unselected-slot',
			}
		},
		[selectedSlotsObj]
	)

	// handleSelectSlot ///////////////////////////
	const handleSelectSlot = checkPermissions([
		EPermissionScope.interventionCreate,
	])
		? useCallback((selection) => {
				setSelectedSlotsObj(selection)

				setSelectedSlotsPosition({
					x: selection?.box?.x || selection.bounds.x,
					y: selection?.box?.y || selection.bounds.y,
				})

				setOpenPop(true)
		  }, [])
		: null

	// handleOnShowMore ///////////////////////////
	const handleOnShowMore = useCallback((events) => {
		setSelectedShowMoreEvents(events)
	}, [])

	// renderEvent ///////////////////////////
	const renderEvent = useCallback(
		(e) => (
			<CustomEvent
				event={e?.event}
				searchText={debouncedState?.searchText}
				currentView={currentView}
				selectedInter={selectedInter}
			/>
		),

		[debouncedState?.searchText, currentView, selectedInter]
	)

	// messages ///////////////////////////
	const messages = useMemo(
		() => ({
			showMore: (target): any => (
				<CalendarShowMore
					eventsNumber={target}
					selectedShowMoreEvents={selectedShowMoreEvents}
					selectedInter={selectedInter}
					searchText={debouncedState?.searchText}
				/>
			),
		}),
		[selectedShowMoreEvents, selectedInter, debouncedState?.searchText]
	)

	// components ///////////////////////////
	const components = useMemo(
		() => ({
			event: renderEvent,
			dateCellWrapper: ({ value, children }) => (
				<ColoredDateCellWrapper
					value={value}
					selectedSlotsObj={selectedSlotsObj}
				>
					{children}
				</ColoredDateCellWrapper>
			),
		}),
		[selectedSlotsObj, renderEvent]
	)

	// scrollToTime ///////////////////////////
	const scrollToTime = useMemo(() => new Date(new Date().setHours(9, 1)), [])

	///////////////////////////////////////////////////////////////////////////////////
	// Set array of interventions reference find with research
	///////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (debouncedState?.searchText && events?.length) {
			setSelectedInter(
				searchEventsIntervention({
					searchText: debouncedState?.searchText,
					events,
				})
			)
		} else {
			setSelectedInter([])
		}
	}, [debouncedState?.searchText, events])

	///////////////////////////////////////////////////////////////////////////////////
	// change the opacity of the viewMore button if the search filter finds an intervention inside
	///////////////////////////////////////////////////////////////////////////////////

	useLayoutEffect(() => {
		changeViewMoreOpacityIfMatchWithSearch(
			selectedInter,
			currentView,
			loadingInterventions,
			previousSelectedBtnDom,
			setPreviousSelectedBtnDom,
			debouncedState?.searchText
		)
	}, [currentView, loadingInterventions, selectedInter])

	return (
		<Paper sx={calendarCSS}>
			<CalendarHeader
				currentDate={currentDate}
				setCurrentDate={setCurrentDate}
				currentView={currentView}
				setCurrentView={setCurrentView}
				liveState={liveState}
				setDebouncedState={setDebouncedState}
				debouncedState={debouncedState}
				loadingInterventions={loadingInterventions}
			/>
			<Calendar
				ref={calendarRef}
				scrollToTime={scrollToTime}
				date={currentDate}
				events={events}
				dayLayoutAlgorithm='no-overlap'
				view={currentView}
				onView={noop} // // needed to avoid warning
				onNavigate={noop} // needed to avoid warning
				views={views}
				onSelectSlot={handleSelectSlot}
				selectable
				toolbar={false}
				components={components}
				slotPropGetter={slotPropGetter}
				showMultiDayTimes
				localizer={localizer}
				startAccessor='start'
				endAccessor='end'
				style={{ height: '70vh' }}
				messages={messages}
				onShowMore={handleOnShowMore}
			/>

			{openPop && (
				<PermissionsHandler
					permissionsName={[EPermissionScope.interventionCreate]}
				>
					<SelectedSlotsPopover
						openPop={openPop}
						setOpenPop={setOpenPop}
						selectedSlotsPosition={selectedSlotsPosition}
						setSelectedSlotsObj={setSelectedSlotsObj}
						selectedSlotsObj={selectedSlotsObj}
						currentView={currentView}
					/>
				</PermissionsHandler>
			)}
		</Paper>
	)
}

export default CalendarContainer
