export default {
	min: 'Minimum',
	minCharacters: 'Minimum {value} caractères',
	maxCharacters: 'Maximun {value} caractères',
	characters: 'caractères',
	passwordFormat:
		'Le mot de passe doit contenir au minimum 7 caractères, 1 majuscule et 1 minuscule',
	unmatchPassword: 'Les mots de passe ne correspondent pas',
	requirePassword: 'Mot de passe requis',
	require: 'Ce champ est obligatoire',
	invalidEmail: "L'email renseigné est invalide",
	immatRequire: 'Veuillez renseigner au moins un des champs',
	agencyTypeRequired: "Veuillez sélectionner un type d'agence",
	email: 'Veuillez renseigner un email valide',
	phone: 'Veuillez renseigner un numéro de téléphone valide',
	atLeastOnePhone: 'Veuillez renseigner au moins un des champs téléphones',
	invalidSiret: 'Format du Siret invalide',

	invalidTransportDateEnd:
		"La date de livraison doit être ultérieure à la date d'enlèvement",
	invalidTransportDateStart:
		"La date d'enlèvement doit être antérieure à la date de livraison",

	hourRequired: `L'heure est requise`,
}
