import React, { useCallback, useMemo } from 'react'

import { IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import toast from 'react-hot-toast'

interface ITableSideNavItemRemove {
	id: string
	removeMutation
	refetchQueries: string[]
	isSelected?: boolean
	playwrightId?: string
}

const TableSideNavItemRemove: React.FC<ITableSideNavItemRemove> = ({
	id,
	removeMutation,
	refetchQueries,
	isSelected,
	playwrightId,
}) => {
	const [remove] = removeMutation()

	const handleDeleteClick = useCallback(async (event) => {
		event.stopPropagation()
		event.preventDefault()
		await remove({
			variables: { input: { id } },
			refetchQueries,
		}).then(() => {
			toast.success('success.categoryDeleted')
		})
	}, [id, removeMutation])

	const btnIconCSS = useMemo(
		() => ({
			borderRadius: 1,
			p: 0.5,
			'& svg': { fontSize: '16px' },
			'&:hover': { backgroundColor: isSelected ? '#dcf0fd' : '#ebebeb' },
			marginRight: '5px',
		}),
		[isSelected]
	)

	return (
		<>
			<IconButton
				size='small'
				onClick={handleDeleteClick}
				sx={btnIconCSS}
				className='hoverDisplay' // display with TableSideNavItem component :hover
				data-test-id={playwrightId}
			>
				<DeleteIcon />
			</IconButton>
		</>
	)
}

export default TableSideNavItemRemove
