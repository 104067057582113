/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/display-name */
import React from 'react'

import { Chip, Stack } from '@mui/material'

import { useUpdateInterventionStatuses } from '../../../../../../api/gql/mutations/interventionsMutation'
import { useRemoveIntervention } from '../../../../../../api/gql/mutations/interventionsMutation'
import { useInterventionStatusesList } from '../../../../../../api/gql/queries/interventionsQueries'
import TimeCell from '../../../../tableCell/TimeCell'
import ChipCountListPopover from '../../../../../multiItemsPopoverList/popover/simpleListPopover'
import DateToFrom from '../../../../../date/DateToFrom'
import ActionPopoverBtn from '../../../../../popover/actionPopover'
import TasksAndCategoriesPopover from '../../../../../multiItemsPopoverList/popover/tasksAndCategoriesPopover'
import VehiclesInterventionPopover from '../../../../../multiItemsPopoverList/popover/vehiclesInterventionPopover'
import InterventionRangeBar from '../../../../../rangeBar/InterventionRangeBar'
import OpenLinkCell from '../../../../tableCell/OpenLinkCell'
import { ROUTES } from '../../../../../../routes/consts'
import {
	EAccountType,
	EModule,
	EPermissionScope,
} from '../../../../../../utils/app-models'
import { formatDate } from '../../../../../../utils/date'
import ActionsBtn from '../../../../tableCell/actionsBtn'
import InterventionModal from '../../../../../../view/dashboard/interventions/interventionControl/interventionModal'
import UsersPopover from '../../../../../multiItemsPopoverList/popover/usersPopover'
import UserAvatarSlidePanel from '../../../../../avatar/UserAvatarSlidePanel'

const siteListInterventionsByReferenceViewModalTable = [
	{
		columnLabel: 'interventions.ref',
		orderBy: 'reference',
		stickyFirstCol: true,
		personalizedCell: ({ reference, id, isSharedWithMe }) => (
			<>
				{reference ? (
					<OpenLinkCell
						viewModalLink={`${ROUTES.VIEWMODAL.INTERVENTION_REFERENCES.url}${id}`}
						txt={reference}
						isSharedWithMe={isSharedWithMe}
					/>
				) : (
					'-'
				)}
			</>
		),
	},

	{
		columnLabel: 'misc.account',
		modules: [EModule.crm],
		orderBy: 'orderingCustomer_name',
		personalizedCell: ({ orderingCustomer }) => (
			<OpenLinkCell
				viewModalLink={`${ROUTES.VIEWMODAL.CUSTOMERS.url}${orderingCustomer?.id}`}
				txt={orderingCustomer?.name}
				maxCharacters={40}
			/>
		),
	},

	{
		columnLabel: 'interventions.date',
		orderBy: 'dateFrom',
		personalizedCell: ({ dateTo, dateFrom }) => (
			<DateToFrom dateTo={dateTo} dateFrom={dateFrom} />
		),
	},

	{
		columnLabel: 'interventions.statusInter',
		orderBy: 'status_name',
		tableCellAlign: 'center',
		cellAlign: 'center',
		personalizedCell: ({ status, id }) => {
			return (
				<ActionPopoverBtn
					inputs={{ query: useInterventionStatusesList }}
					selected={{
						id: status?.id,
						color: status?.color,
						name: status?.name,
						code: status?.code,
					}}
					titlePopover={'misc.status'}
					keyTranslate='interventions'
					mutation={{
						id,
						query: useUpdateInterventionStatuses,
						refreshQuery: ['interventions'],
						successMessage: 'success.interventionStatusUpdate',
						attribute: 'status',
					}}
					permissions={[EPermissionScope.interventionUpdate]}
				/>
			)
		},
	},

	{
		columnLabel: 'misc.progression',
		orderBy: 'status_progression',
		personalizedCell: ({
			interventionControlProgrammedVehicles: { collection },
			status,
		}) => (
			<InterventionRangeBar data={{ programmedVehicles: collection, status }} />
		),
	},

	{
		columnLabel: 'misc.vehicles',
		orderBy:
			'interventionControlProgrammedVehicles_vehicle_vehicleDetail_immat',
		personalizedCell: ({
			interventionControlProgrammedVehicles: { collection },
		}) => <VehiclesInterventionPopover data={collection} />,
	},

	{
		columnLabel: 'interventions.task',
		orderBy: 'interventionControlProgrammedVehicles_products_product_name',
		personalizedCell: ({
			interventionControlProgrammedVehicles: { collection },
			globalProducts,
		}) => (
			<TasksAndCategoriesPopover
				tasks={collection}
				globalTasks={globalProducts}
				isIpv
			/>
		),
	},

	{
		columnLabel: 'interventions.intervenants',
		orderBy: 'actors_lastName',
		accountsTypeDenied: [EAccountType.guest],
		personalizedCell: ({ actors: { collection } }) => (
			<UsersPopover
				data={collection?.map(({ id, lastName, firstName, account }) => ({
					firstName,
					lastName,
					id,
					account,
				}))}
				title='interventions.actors'
			/>
		),
	},

	{
		columnLabel: 'interventions.managerMission',
		orderBy: 'manager_lastName',
		accountsTypeDenied: [EAccountType.guest],
		personalizedCell: ({ manager }) => (
			<UserAvatarSlidePanel
				firstName={manager?.firstName}
				lastName={manager?.lastName}
				userId={manager?.id}
				accountId={manager?.account?.id}
				displayName={false}
			/>
		),
	},

	{
		columnLabel: 'misc.agencies',
		modules: [EModule.network],
		orderBy: 'agencies_agencyCustomDetails_agencyName',
		personalizedCell: ({ agencies }) => (
			<Stack direction='row' alignItems='center'>
				<ChipCountListPopover
					text='misc.agencies'
					data={
						agencies?.collection?.map(({ agencyCustomDetails, id }) => {
							return { name: agencyCustomDetails[0]?.agencyName, id }
						}) || []
					}
					viewModalLink={ROUTES.VIEWMODAL.AGENCIES.url}
					permissions={[
						EPermissionScope.networkView,
						EPermissionScope.networkManage,
					]}
				/>
			</Stack>
		),
	},

	// {
	// 	columnLabel: 'misc.factures',
	// 	personalizedCell: () => <>(todo)</>,
	// },

	{
		columnLabel: 'misc.alerts',
		personalizedCell: ({
			alerts: {
				paginationInfo: { totalCount },
			},
		}) => (
			<Chip
				size='small'
				color={totalCount === 0 ? 'default' : ('alert' as any)}
				disabled={totalCount === 0 || false}
				label={totalCount}
			/>
		),
	},

	{
		columnLabel: 'misc.addAt',
		orderBy: 'dateCreated',
		tableCellAlign: 'center',
		cellAlign: 'center',
		personalizedCell: ({ dateCreated }) => (
			<TimeCell date={dateCreated ? formatDate(dateCreated) : '-'} />
		),
	},

	{
		fixWidth: '50px',
		columnLabel: '',
		visible: true,
		tableCellAlign: 'center',
		cellAlign: 'center',
		sticky: true,
		personalizedCell: ({ id }: any) => (
			<ActionsBtn
				duplicate
				rowId={id}
				deleted={{
					query: useRemoveIntervention,
					refreshQuery: [
						'interventions',
						'getInterventionNumbersBySite',
						'interventionsList',
					], // todo add querynumbers here
					successMessageDelete: 'success.interventionDeleted',
					messageConfirm: {
						title: 'confirmModal.deleteInterventionsTitle',
						message: 'confirmModal.deleteInterventionMessage',
					},
				}}
				editModal={
					<InterventionModal interventionId={id} modalUtils={{} as any} />
				}
				viewModalLink={`${ROUTES.VIEWMODAL.INTERVENTION_REFERENCES.url}${id}`}
				permissions={{
					update: [EPermissionScope.interventionUpdate],
					remove: [EPermissionScope.interventionRemove],
					delete: [EPermissionScope.interventionDelete],
				}}
			/>
		),
	},
]

export default siteListInterventionsByReferenceViewModalTable
