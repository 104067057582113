/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { memo, useMemo, useState, useEffect, useCallback } from 'react'

import { TableContext } from '../utils/contexts/TableContext'
import { useDebouncedState, useFormatMessage } from '../utils/hooks'
import FiltersProvider from '../components/filters/filtersProvider'

interface ITableProvider {
	//old
	tableProviderConfig?
	//old
	children?
	hidden?
	customStateSelectedRow?
	overrideTableProvider?
	isInViewModal: boolean
	forcedData?: any
	onMultiDelete?: any
	//new
	tableConfig?
	//new
}

const TableProvider: React.FC<ITableProvider> = ({
	children,
	hidden,
	customStateSelectedRow,
	overrideTableProvider,
	isInViewModal,
	forcedData,
	onMultiDelete,
	tableConfig,
}) => {
	//////////////////////////////////////

	const { customLocalStorageKeyFilter, customDefaultFilterConfig } =
		overrideTableProvider || {}
	const { defaultFiltersConfig, displaySwitchOption, switchLabel } =
		tableConfig || {}

	///////////////////////////////////
	const [selectedRow, setSelectedRow] = useState<string[]>([])
	const [page, setPage] = useState(1)

	const intlMsg = useFormatMessage()
	const [isActive, setIsActive] = useState(true)
	const [loader, setLoader] = useState<any>(false)
	const [possibleColumns, setPossibleColumns] = useState([]) // possible columns filtered by permission's & module's
	const [activeColumns, setActiveColumns] = useState([]) // active columns selected with component ColumnFilter & filtered by permission's & module's
	const [debouncedState, setDebouncedState, liveState] = useDebouncedState({
		searchText: '',
	})
	const [visibleRows, setVisibleRows] = useState([])
	const [currentOrderBy, setCurrentOrderBy] = useState<any>(null)

	const search = {
		isActive,
		...(debouncedState.searchText?.length >= 3 && {
			searchText: debouncedState.searchText,
		}),
	}

	//////////////////////////////////////////////////////////////
	// Filter table
	//////////////////////////////////////////////////////////////

	const filtersConfig = useMemo(
		() => ({
			defaultFiltersValue: {
				...defaultFiltersConfig?.defaultFiltersValues,
				...customDefaultFilterConfig,
			},
			localStorageKeyFilter:
				customLocalStorageKeyFilter ||
				defaultFiltersConfig?.localStorageKeyFilter,
			componentsFiltersConfig: defaultFiltersConfig?.componentsFiltersConfig,
		}),
		[]
	)

	//////////////////////////////////////////////////////////////
	// Switch active/inactive btn
	//////////////////////////////////////////////////////////////

	const handleCheckSwitch = useCallback(() => {
		setIsActive(!isActive)
	}, [isActive])

	const switchOption = displaySwitchOption
		? {
				checked: isActive,
				onChange: handleCheckSwitch,
				label: intlMsg(switchLabel || 'administration.active'),
		  }
		: null

	//////////////////////////////////////////////////////////////
	// Context
	//////////////////////////////////////////////////////////////

	const context = useMemo(
		() => ({
			...(!customStateSelectedRow?.selectedRow
				? { selectedRow }
				: { selectedRow: customStateSelectedRow?.selectedRow }),
			...(!customStateSelectedRow?.setSelectedRow
				? { setSelectedRow }
				: { setSelectedRow: customStateSelectedRow?.setSelectedRow }),
			...(!hidden?.tableToolBar?.columnFilter && { activeColumns }),
			...(!hidden?.tableToolBar?.columnFilter && { setActiveColumns }),
			liveState,
			visibleRows,
			isInViewModal,
			setDebouncedState,
			debouncedState,
			setVisibleRows,
			search,
			switchOption,
			customDefaultFilterConfig,
			page,
			setPage,
			possibleColumns,
			setPossibleColumns,
			currentOrderBy,
			setCurrentOrderBy,
			customStateSelectedRow,
			forcedData,
			loader,
			setLoader,
			onMultiDelete,
		}),
		[
			isInViewModal,
			selectedRow,
			liveState,
			activeColumns,
			visibleRows,
			debouncedState,
			search,
			isActive,
			customDefaultFilterConfig,
			page,
			possibleColumns,
			currentOrderBy,
			forcedData,
			loader,
			setLoader,
			onMultiDelete,
		]
	)

	return (
		<TableContext.Provider value={context}>
			<FiltersProvider filtersConfig={filtersConfig}>
				{children}
			</FiltersProvider>
		</TableContext.Provider>
	)
}

export default memo(TableProvider)
