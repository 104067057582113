import React, { useContext, useMemo } from 'react'

import WAlerts from '../../../../components/widget/WAlerts/WAlerts'
import WIntervention from '../../../../components/widget/WInterventions/WInterventions'
import WVehicleMileages from '../../../../components/widget/WVehicleMileages/WVehicleMileages'
import { Grid, Box, Stack } from '@mui/material'
import ModalProvider from '../../../../providers/ModalProvider'
import { mileageSchema } from '../../../../utils/validationSchema'
import { EFormModalMode } from '../../../../utils/app-models'
import WVehicleTracking from '../../../../components/widget/wVehicleTracking'
import { ViewModalContentContext } from '../../../../utils/contexts/ViewModalContentContext'

interface IVehicleDashboard {}

const VehicleDashboard: React.FC<IVehicleDashboard> = () => {
	const { viewModalData } = useContext(ViewModalContentContext)
	const immatOrVin = useMemo(
		() =>
			viewModalData?.vehicleDetail?.immat ||
			viewModalData?.vehicleDetail?.vin ||
			'',
		[viewModalData?.vehicleDetail]
	)

	return (
		<Box sx={{ p: 5 }}>
			<Grid container spacing={4}>
				<Grid item xs={12} md={4}>
					<Stack spacing={4}>
						<ModalProvider
							validationSchemaFunction={mileageSchema}
							formModalMode={EFormModalMode.edit}
							//TODO HERE OLD VERSION OF ModalProvider
							//WE NEED TO CHANGE THIS ON PRODUCTS REFACTO
						>
							<WVehicleMileages
								vehicleId={viewModalData?.id}
								immatOrVin={immatOrVin}
							/>
						</ModalProvider>

						<WVehicleTracking vehicleId={viewModalData?.id} />
					</Stack>
				</Grid>
				<Grid item xs={12} md={4}>
					<WIntervention id={viewModalData?.id} vehicle={viewModalData?.id} />
				</Grid>
				<Grid item xs={12} md={4}>
					<WAlerts
						filterQueries={{
							vehicle: viewModalData?.id,
						}}
					/>
				</Grid>
			</Grid>
		</Box>
	)
}

export default VehicleDashboard
