import React, { useMemo } from 'react'

import Card from '@mui/material/Card'

import { tableCardContainerCSS } from '../../styles/sxCSS/table/tableCardContainer'
import Box from '@mui/material/Box'

interface ITableMainContainer {
	tableMainConfig: any
	overrideConfigTable?: any
	children: any
}

const TableMainContainer: React.FC<ITableMainContainer> = ({
	tableMainConfig,
	overrideConfigTable = null,
	children,
}) => {
	const unCard = useMemo(
		() => tableMainConfig?.tableContainerConfig?.unCard || false,
		[]
	)

	return (
		<>
			{unCard ? (
				<Box sx={tableCardContainerCSS}>{children}</Box>
			) : (
				<Card
					elevation={overrideConfigTable?.elevationNbr ?? 1}
					sx={tableCardContainerCSS}
				>
					{children}
				</Card>
			)}
		</>
	)
}

export default TableMainContainer
