export default {
	interventionLabel: 'Interventions',
	interventionProgrammedVehicles: 'Véhicules programmés',
	interventionTypes: "Type d'intervention",
	type: 'Type',
	control: 'Intervention',
	transport: 'Transport',
	transports: 'Transports',
	logistic: 'Logistique',
	mecanic: 'Mécanique',
	service: 'service',
	vehiclesAndServices: 'Vehicules et prestations',
	planning: 'Planification',
	create: 'Ajouter une intervention',
	edit: 'Modifier une intervention',
	serviceRequest: "Demande d'intervention",
	applicant: 'Demandeur',
	interventionPlace: "Lieu de l'intervention",
	automaticEntry: 'Compte/site',
	manualEntry: 'Autre adresse',
	intervention: 'Intervention',
	dateAndStatus: 'Date et statut',
	intervenants: 'Intervenants',
	comments: 'Description',
	ref: 'référence',
	sameLocation: 'Même adresse que le demandeur',
	sameOrderLocation: 'Adresse du donneur d’ordre',
	finalAddressText: 'Adresse',
	finalContactText: 'Nom du contact',
	date: 'Date',
	status: 'Statut',
	start: 'Début',
	end: 'Fin',
	hour: 'Heure',
	hours: 'Heures',
	actors: 'Intervenants',
	manager: 'Responsable mission',
	agence: 'Agence',
	agencies: 'Agences',
	interveningAgencies: 'Agences intervenantes',
	missionDescription: 'Informations visibles par le client',
	actorsDescription: 'Instructions pour les intervenants',
	internalComment: "Instructions pour l'intervenant",
	internalCommentInfo:
		"Les instructions sont accessibles depuis l'application mobile.",
	publicTransportComment:
		"Ces informations sont visibles par le donneur d'ordre.",
	managerMission: 'Responsable',
	vehicles: 'Véhicules',
	task: 'Prestations',
	globalReportsAndProducts: "Formulaires et prestations de l'intervention",
	globalProducts: "Prestations de l'intervention",
	globalReports: "Formulaires de l'intervention",
	selectProduct: 'Sélectionner une prestation',
	selectReport: 'Sélectionner un formulaire',
	vehicleDone: 'véhicule(s) effectué(s)',
	toplan: 'A planifier',
	planned: 'Planifié',
	running: 'En cours',
	pending: 'En pause',
	done: 'Effectué',
	waiting: 'En attente',
	canceled: 'Annulé',
	all: 'Tous',
	actives: 'Actifs',
	finished: 'Terminés',
	productsToAchieve: 'Prestations à réaliser',
	reportsToAchieve: 'Formulaires à effectuer',
	byReference: 'Par référence',
	byVehicle: 'Par véhicule',
	statusInter: 'Statut inter.',
	noShow: 'Absent',
	Ordering: 'Demandeur ',
	statusInt: 'Statut int.',
	addContact: 'Ajouter un contact',
	affectVehicle: 'Affecter des véhicules',
	noKnownContact: 'Aucun contact connu sur ce site',
	noKnownSite: 'Aucun site connu sur ce compte',
	interventionWithoutActors: 'Interventions sans opérateur',
	interventionWithoutConveyor: 'Interventions sans convoyeur',
	emptyReport: 'Aucun formulaire disponible',
	open: 'Ouvert',
	treated: 'Traitée',
	security: 'Sécurité',
	aesthetic: 'Esthétique',
	legal: 'Légal',
	other: 'Autre',
	dateIntervention: "Date d'intervention",
	endIntervention: 'Fin d’intervention',
	noAgencies: "Aucune agence n'est associée à ce site",
	interventionLocation: 'Lieu d’intervention',
	intervetionRequestBy: 'Demande d’intervention',
	interventionManager: 'Gestionnaire d’intervention',
	cancelReason_WARNING: 'Témoins allumés sur le tableau de bord',
	cancelReason_NO_CLIENT: 'Client absent',
	cancelReason_NO_VEH: 'Véhicule absent',
	cancelReason_FORECAST: 'Météo',
	cancelReason_OTHER: 'Autre motif',
	cancelReason_NONE: 'Aucun motif',

	actorsAccessInterventionMobile:
		"Les intervenants pourront accéder à l'intervention depuis l'application mobile HubeeCheck.",
	agencyAssignmentActors:
		"Si une agence dispose de plusieurs utilisateurs actifs, elle sera responsable de sélectionner les intervenants appropriés pour l'intervention en question. Dans le cas où l'agence ne dispose que d'un seul intervenant actif, l'affectation de l'intervention sera automatique.",

	hideInformation: 'Masquer les informations',
	clickToAddVehicle:
		'Cliquer sur AJOUTER pour affecter des véhicules à votre intervention.',
	addVehicles: 'Ajouter des véhicules',
	editTransport: 'Éditer un transport',
	createTransport: 'Ajouter un transport',

	transportType: 'Type de transport',
	addressBook: 'Carnet d’adresse',
	transportRequest: 'Donneur d’ordre',
	customerRef: 'Référence client',
	delivery: 'Livraison',
	removal: 'Enlèvement',

	confirmAssignmentImmat: 'Ce véhicule est actuellement affecté à :',
	confirmAssignment: 'Voulez-vous ajouter ce véhicule à votre transport ?',
	confirmAssignmentTitle: 'Affecter un véhicule',
	incompleteAssignmentVehicle: 'Champs obligatoires non renseignés',

	nameOrSocialName: 'Nom / Raison sociale',

	removalAddress: 'Adresse d’enlèvement',
	deliveryAddress: 'Adresse de livraison',

	removalDateTitle: 'Date et heure d’enlèvement',
	deliveryDateTitle: 'Date et heure de livraison',

	removalInstruction:
		'Instructions pour l’enlevement. Les instructions sont visibles par l’intervenant depuis l’application mobile.',
	deliveryInstruction:
		'Instructions pour la livraison. Les instructions sont visibles par l’intervenant depuis l’application mobile.',
	interventionVehicles: "Véhicules de l'intervention",

	removalGuideline: "Instructions d'enlèvement",
	deliveryGuideline: 'Instructions de livraison',
	CONVOY: 'Convoyage',
	deliveryDate: 'Date de livraison',
	removalDate: 'Date d’enlèvement',
	hourStart: 'À partir de',
	hourEnd: 'Jusqu’à',

	actorsAccess:
		'Le convoyeur accède à l’intervention depuis l’application mobile.',
	agencyUnlimited: 'Agence illimitée',
	agencyUnlimitedDescription:
		' : l’agence doit affecter ses propres convoyeurs.',
	agencyLimited: 'Agence limitée',
	agencyLimitedDescription: ' : le convoyeur est affecté par défaut.',
	applyAllProducts:
		"Les prestations sélectionnées s'appliquent à l'ensemble des véhicules de l'intervention, qu'ils aient été planifiés ou non",

	conveyor: 'Convoyeur',
	conveyors: 'Convoyeurs',
	missingCustomer: 'Client indiqué absent',
	missingRecipient: 'Destinataire indiqué absent',

	missionRunningTitle: 'La mission est en cours.',
	missionRunningText:
		'Les signatures seront affichées une fois la mission terminée. ',

	refAirTableShort: 'Ref airtable',
	refAirTable: 'Référence AirTable',

	signOperator: 'Signature opérateur',
	operatorsConveyors: 'Opérateurs / Convoyeurs',
	interventionWithoutOperatorsConveyor: 'Mission sans opérateur / convoyeur',
	withoutDeliveryAddress: 'Sans adresse de livraison',
	withoutRemovalAddress: 'Sans adresse d’enlèvement',
}
