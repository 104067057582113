import { GlobalColorsTheme } from './globalColorsTheme'
import { GlobalPoliceTheme } from './globalPoliceTheme'
import { IGlobalTheme } from './globalThemeTypes'

export const GlobalFont = "'Roboto',  sans-serif"

export const GlobalBoxShadows = {
	popover: '0px 2px 5px 0px rgba (0, 0, 0, 0.15)',
	modal: '0px 24px 100px 24px rgba (22, 28, 78, 0.20)',
}

export const GlobalRadius = {
	radSmall: '2px',
	radMid: '4px',
	radLarge: '10px',
	radMain: '26, 10, 10, 26px',
}

export const GlobalGaps = {
	gap1: '2px',
	gap2: '4px',
	gap3: '8px',
	gap4: '16px',
	gap5: '24px',
	gap6: '32px',
	gap7: '40px',
	gap8: '64px',
}

export const GlobalMargins = {
	marg1: '0, 16, 16, 0',
	marg2: '16, 0, 16, 0',
	marg3: '8',
}

export const GlobalPaddings = {
	pad1: '2px',
	pad2: '4px',
	pad3: '8px',
	pad4: '16px',
	pad5: '24px',
	pad6: '32px',
	pad7: '40px',
	pad8: '64px',
}

export const GlobalTheme: IGlobalTheme = {
	boxShadows: GlobalBoxShadows,
	paddings: GlobalPaddings,
	margins: GlobalMargins,
	gaps: GlobalGaps,
	radius: GlobalRadius,
	colors: GlobalColorsTheme,
	typographies: GlobalPoliceTheme,
}
