import React from 'react'

import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import InfoIcon from '@mui/icons-material/Info'
import { Grid, Box } from '@mui/material'

import { listCardViewModalCSS as css } from '../../../../styles/css/viewModal'
import { useFormatMessage } from '../../../../utils/hooks'

interface IComment {
	comment
}

const Comment: React.FC<IComment> = ({ comment }) => {
	const intlMsg = useFormatMessage()

	return (
		<Paper sx={css.radius}>
			<Box sx={{ mb: 2 }}>
				<Grid container spacing={1} sx={{ pt: 1, pb: 1 }}>
					<Grid item xs={12}>
						<Typography variant='body2' sx={css.ListSubheader}>
							{intlMsg('misc.comment')}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant='caption' sx={css.comment}>
							<InfoIcon fontSize='small' sx={{ mr: 1 }} /> {comment || '-'}
						</Typography>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	)
}

export default Comment
