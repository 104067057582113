/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

import { EFormTemplateBasicFields } from '../../../utils/app-models'
import { globalConfigPDF } from '../globalConfigPDF'
import { controlFieldPDF } from '../tableCellField/controlFieldPDF'
import { ratingFieldPDF } from '../tableCellField/ratingFieldPDF'
import { selectionFieldPDF } from '../tableCellField/selectionFieldPDF'
import { signatureFieldPDF } from '../tableCellField/signatureFieldPDF'
import { sliderField } from '../tableCellField/sliderField'
import { textFieldPDF } from '../tableCellField/textFieldPDF'

const { margin, fontSizeBody, heightImgSignature, widthImgSignature } =
	globalConfigPDF

export const blockRecords = (doc, titles, records) => {
	doc.autoTable({
		body: titles,
		margin: { top: margin, bottom: margin },
		startY: doc.lastAutoTable.finalY + 1,
		columnStyles: {
			0: {
				columnWidth: 80,
				valign: 'middle',
				cellPadding: { right: 5, left: 2, top: 2, bottom: 2 },
			},
			1: { valign: 'middle' },
		},

		allSectionHooks: true,
		didParseCell: function ({ row, cell }) {
			// get the current record value
			const valueRowCell = records[row.index]?.data

			// get the current type value
			const valueType = records[row.index]?.fieldType

			// Force height for type signature
			if (valueType === EFormTemplateBasicFields.signature && valueRowCell) {
				row.height = heightImgSignature + 10
			}

			cell.styles.fontSize = fontSizeBody
		},

		didDrawCell: async function ({ row, column, cell }) {
			// get informations of current cell row
			const {
				x: positionXcell,
				y: positionYcell,
				raw,
				height: heightCell,
				width: widthCell,
			} = cell

			// get the current record value
			const valueRowCell = records[row.index]?.data

			// get the current type value
			const valueType = records[row.index]?.fieldType

			// get the width of title
			const { w: widthTitleRowCell } = doc.getTextDimensions(raw || '')

			// record text position X & Y
			const positionXtextRecord = positionXcell + widthTitleRowCell + 3
			const positionYtextRecord = positionYcell + heightCell / 2 - 0.25

			if (column.index === 1 && valueRowCell) {
				// Display the record value
				switch (valueType) {
					// cell type  = RATING  ///////////////////////
					case EFormTemplateBasicFields.rating:
						if (
							JSON.parse(valueRowCell.formTemplateField.attributes[0])
								.ratingType === 'slider'
						) {
							sliderField(doc, valueRowCell, {
								positionYcell,
								positionXcell,
								widthCell,
								heightCell,
							})
						} else {
							ratingFieldPDF(doc, valueRowCell, {
								positionYcell,
								positionXcell,
								widthTitleRowCell,
								heightCell,
							})
						}

						break

					// cell type  = SELECTION MULTIPLE  ///////////////////////
					case EFormTemplateBasicFields.selection:
						selectionFieldPDF(doc, valueRowCell, {
							positionYcell,
							positionXcell,
							heightCell,
						})
						break

					// cell type  = CONTROL  ///////////////////////
					case EFormTemplateBasicFields.control:
						controlFieldPDF(doc, valueRowCell, {
							positionYcell,
							positionXcell,
							heightCell,
						})
						break

					// cell type  = SIGNATURE  ///////////////////////
					case EFormTemplateBasicFields.signature:
						signatureFieldPDF(doc, valueRowCell, {
							positionYcell,
							positionXcell,
							widthImgSignature,
							heightImgSignature,
						})
						break

					// cell type  = TEXT  ///////////////////////
					case EFormTemplateBasicFields.mileage:
						textFieldPDF(doc, valueRowCell?.value?.value, {
							positionXtextRecord,
							positionYtextRecord,
							widthCell,
							widthTitleRowCell,
						})
						break
				}
			}
		},
	})
}
