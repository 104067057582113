import React, { useContext } from 'react'

import getNestedObjectFromKey from 'lodash.get'
import { Typography } from '@mui/material'

import { formatDate } from '../../utils/date'
import TimeCell from '../../components/tableV2/tableCell/TimeCell'
import { ViewModalContentContext } from '../../utils/contexts/ViewModalContentContext'

interface IViewModalFooter {
	customFooter
}

const ViewModalFooter: React.FC<IViewModalFooter> = ({ customFooter }) => {
	const { viewModalData } = useContext(ViewModalContentContext)

	return (
		<div>
			{customFooter ? (
				<Typography variant='caption'>
					{getNestedObjectFromKey(viewModalData, customFooter)}
				</Typography>
			) : (
				<TimeCell date={formatDate(viewModalData?.dateCreated)} createdAt />
			)}
		</div>
	)
}

export default ViewModalFooter
