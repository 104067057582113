import React, { cloneElement, useCallback } from 'react'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import { Button } from '@mui/material'

import { useFormatMessage, useModal } from '../../../utils/hooks'
import { EFormModalMode } from '../../../utils/app-models'

interface IFormBtnModal {
	btnText?: string
	modal: any
	onlyIcon?: boolean
	color?: string
	playwrightId?: string
	mode?: EFormModalMode
	onlyText?: boolean
	onCloseActions?: any
}

const FormBtnModal: React.FC<IFormBtnModal> = ({
	btnText = null,
	modal,
	onlyIcon = false,
	color,
	playwrightId = '',
	mode = EFormModalMode.edit,
	onlyText = false,
	onCloseActions = null,
}) => {
	const intlMsg = useFormatMessage()

	const modalUtils = useModal(false, onCloseActions)

	const handleOpenClick = useCallback(() => {
		modalUtils.openModal()
	}, [])

	return (
		<>
			{onlyIcon ? (
				<IconButton
					onClick={handleOpenClick}
					sx={{ color: color || '' }}
					size='small'
					id={playwrightId}
				>
					{mode === EFormModalMode.edit ? (
						<EditIcon />
					) : (
						<AddCircleOutlineIcon />
					)}
				</IconButton>
			) : (
				<Button
					id={playwrightId}
					onClick={handleOpenClick}
					sx={{ color: color || '' }}
					variant='contained'
					startIcon={
						onlyText ? (
							<></>
						) : mode === EFormModalMode.edit ? (
							<EditIcon />
						) : (
							<AddCircleOutlineIcon />
						)
					}
				>
					{btnText ||
						(mode === EFormModalMode.edit
							? intlMsg('misc.edit')
							: intlMsg('misc.add'))}
				</Button>
			)}
			{modalUtils?.isVisible && cloneElement(modal, { modalUtils, mode })}
		</>
	)
}

export default FormBtnModal
