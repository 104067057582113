import React, { useMemo, useCallback, memo } from 'react'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'

interface ISkeletonTable {
	nbRow: number
}

const SkeletonTable: React.FC<ISkeletonTable> = ({ nbRow }) => {
	const generatedArray = useMemo(() => Array.from(Array(nbRow).keys()), [nbRow])

	const renderGeneratedArray = useCallback(
		(_, i) => (
			<Typography
				key={`${i}`}
				component='div'
				variant={'h1'}
				sx={{ opacity: 0.5 }}
			>
				<Skeleton animation='wave' key={`${i + 2}`} height={80} />
			</Typography>
		),
		[generatedArray]
	)

	return <>{generatedArray.map(renderGeneratedArray)}</>
}

export default memo(SkeletonTable)
