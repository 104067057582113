export enum EPhoneCode {
	fr = '33',
}

export enum ESupportedLanguages {
	fr = 'fr',
	en = 'en',
}

export enum ESortOrder {
	asc = 'asc',
	desc = 'desc',
}

export enum EFormModalMode {
	create = 'create',
	edit = 'edit',
	duplicate = 'duplicate',
}

export enum EPermission {
	user = 'user',
	team = 'team',
	site = 'site',
	customer = 'customer',
	contact = 'contact',
	vehicle = 'vehicle',
	intervention = 'intervention',
	file = 'file',
	formTemplate = 'form_template',
	form = 'form',
	adminPanel = 'adminPanel',
	alert = 'alert',
	network = 'network',
	invoice = 'invoice',
	superMegaAdmin = 'supermegaadmin',
	document = 'document',
	product = 'product',
	productBase = 'product_base',
}

export enum EPermissionScope {
	userManage = 'user:manage',
	teamManage = 'team:manage',
	siteManage = 'site:manage',
	siteDelete = 'site:delete',
	vehicleCreate = 'vehicle:create',
	vehicleUpdate = 'vehicle:update',
	vehicleRemove = 'vehicle:remove',
	vehicleDelete = 'vehicle:delete',
	vehicleManage = 'vehicle:manage',
	contactManage = 'contact:manage',
	contactDelete = 'contact:delete',
	contactInvite = 'contact:invite',
	customerManage = 'customer:manage',
	customerDelete = 'customer:delete',
	formTemplateManage = 'form_template:manage',
	formCreate = 'form:create',
	formUpdate = 'form:update',
	formRemove = 'form:remove',
	formDelete = 'form:delete',
	interventionCreate = 'intervention:create',
	interventionUpdate = 'intervention:update',
	interventionRemove = 'intervention:remove',
	interventionDelete = 'intervention:delete',
	productBaseManage = 'product_base:manage',
	productManage = 'product:manage',
	productDelete = 'product:delete',
	productRemove = 'product:remove',
	alertCreate = 'alert:create',
	alertUpdate = 'alert:update',
	alertView = 'alert:view',
	alertRemove = 'alert:remove',
	alertDelete = 'alert:delete',
	alertGenerateIntervention = 'alert:generate_intervention',
	alertClose = 'alert:close',
	networkManage = 'network:manage',
	networkDelete = 'network:delete',
	networkView = 'network:view',
	invoiceManage = 'invoice:manage',
	invoiceView = 'invoice:view',
	documentManage = 'document:manage',
	documentView = 'document:view',
}

export enum EFormTemplateBasicFields {
	control = 'control',
	date = 'date',
	picture = 'picture',
	text = 'text',
	title = 'title',
	selection = 'selection',
	instruction = 'instruction',
	signature = 'signature',
	numbers = 'numbers',
	// media = 'media',
	rating = 'rating',
	barCode = 'barCode',
	mileage = 'mileage',
	document = 'document',
	parkDate = 'parkDate',
}

export enum EFormTemplateVehicleFields {
	mileage = 'mileage',
}

export enum EFormTemplateModelFields {
	picture4anglesModel = 'picture4anglesModel',
	picture4anglesAndWheelsModel = 'picture4anglesAndWheelsModel',
	doubleSidedPhotoDocument = 'doubleSidedPhotoDocument',
	pictureVehicle = 'pictureVehicle',
}

export enum EFormTemplateControlVerificationType {
	security = 'security',
	legal = 'legal',
	aesthetic = 'aesthetic',
	other = 'other',
}

export enum EFormTemplateSelectionType {
	multiples = 'multiples',
	unique = 'unique',
}

export enum EFormTemplateFormatText {
	simpleText = 'simpleText',
	longText = 'longText',
}

export enum EFormTemplateRatingType {
	slider = 'slider',
	stars = 'stars',
}

export enum EFormTemplatePictureVehicleType {
	car = 'car',
	vul = 'vul',
	bus = 'bus',
	pl = 'pl',
}

export enum EFormTemplatePictureAngleType {
	none = 'none',
	frontFace = 'frontFace',
	leftFace = 'leftFace',
	backFace = 'backFace',
	rightFace = 'rightFace',
	wheel = 'wheel',
}

export enum EFormTemplateTitleType {
	littleTitle = 'littleTitle',
	mediumTitle = 'mediumTitle',
	bigTitle = 'bigTitle',
}

export enum EFormTemplateDateType {
	date = 'date',
	dateParkEntry = 'dateParkEntry',
	dateParkExit = 'dateParkExit',
}

export enum EModalEditType {
	agency = 'agency',
	customer = 'customer',
	contact = 'contact',
	site = 'site',
	vehicle = 'vehicle',
	interventionControl = 'interventionControl',
	interventionTransport = 'interventionTransport',
	interventionLogistic = 'interventionLogistic',
	invoice = 'invoice',
	documentVehicle = 'documentVehicle',
}

export enum EInterventionTypes {
	control = 'control',
	transport = 'transport',
	logistic = 'logistic',
	mecanic = 'mecanic',
	service = 'service',
}

export enum EPeriodFilter {
	allPeriod = 'allPeriod',
	yesterday = 'yesterday',
	today = 'today',
	tomorrow = 'tomorrow',
	thisWeek = 'thisWeek',
	lastWeek = 'lastWeek',
	nextWeek = 'nextWeek',
	thisMonth = 'thisMonth',
	lastMonth = 'lastMonth',
	nextMonth = 'nextMonth',
	customPeriod = 'customPeriod',
}

export enum EStatusVehicle {
	todo = 'todo',
	pending = 'pending',
	returned = 'returned',
	done = 'done',
	noShow = 'noshow',
	cancel = 'canceled',
}

export enum EViewPeriod {
	month = 'month',
	week = 'week',
	day = 'day',
}

export enum EModule {
	crm = 'crm',
	site = 'site',
	network = 'network',
	formTemplate = 'form.template',
	interventionControl = 'intervention.control',
	interventionTransport = 'intervention.transport',
	interventionLogistic = 'intervention.logistic',
	interventionMecanic = 'intervention.mecanic',
	planning = 'planning',
	report = 'report',
	optionWhitemarking = 'option.whitemarking',
	optionExtraPicture = 'option.extrapicture',
	invoice = 'invoice',
	productCatalog = 'product.catalog',
	airTable = 'api.airtable',
}

export enum EAgencyCode {
	limited = 'LIMITED',
	unlimited = 'UNLIMITED',
}

export enum EFormFieldsPDF {
	control = 'control',
	date = 'date',
	parkDate = 'parkDate',
	picture = 'picture',
	document = 'document',
	text = 'text',
	longText = 'longText',
	title = 'title',
	subtitle = 'subtitle',
	selection = 'selection',
	signature = 'signature',
	instruction = 'instruction',
	numbers = 'numbers',
	media = 'media',
	rating = 'rating',
	barCode = 'barCode',
	separator = 'separator',
	pageTitle = 'pageTitle',
}

export enum EFormBlocksPDF {
	separator = 'separator',
	pageTitle = 'pageTitle',
	records = 'records',
	picture = 'picture',
}

export enum ETvaFr {
	tva0 = '0',
	tva20 = '20',
}

export enum EAccountType {
	guest = 'GUEST',
	unlimited = 'UNLIMITED',
	limited = 'LIMITED',
}

export enum ESeverityAlert {
	info = 'info',
	success = 'success',
	error = 'error',
	warning = 'warning',
}

export enum EInterventionStatuses {
	toPlan = 'TO PLAN',
	planned = 'PLANNED',
	running = 'RUNNING',
	pending = 'PENDING',
	done = 'DONE',
	waiting = 'WAITING',
	canceled = 'CANCELED',
}

export enum ETransportTripType {
	removal = 'removal',
	delivery = 'delivery',
}

export enum EDocumentType {
	portrait = 'portrait',
	landscape = 'landscape',
}

export enum EViewModal {
	SITE = 'SITE',
	AGENCIES = 'AGENCIES',
	VEHICLES = 'VEHICLES',
	CUSTOMERS = 'CUSTOMERS',
	INTERVENTION_REFERENCES = 'INTERVENTION_REFERENCES',
	INTERVENTION_TRANSPORT = 'INTERVENTION_TRANSPORT',
	INTERVENTION_FORMS = 'INTERVENTION_FORMS',
}

export enum dataListType {
	simpleText = 'simpleText',
	vmLink = 'vmLink',
	externalLink = 'externalLink',
	phone = 'phone',
	mail = 'mail',
	avatar = 'avatar',
	signature = 'signature',
	notation = 'notation',
	comment = 'comment',
	period = 'period',
}

export enum EChipColorStatus {
	intervention = 'interventions',
	vehicle = 'vehicle',
}

export enum EHubeeTheme {
	hubeeLightTheme = 'hubeeLightTheme',
	hubeeDarkTheme = 'hubeeDarkTheme',
}

export enum EUserTypes {
	admin = 'admin',
	coordinator = 'coordinator',
	operator = 'operator',
}
