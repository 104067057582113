import React, { useCallback, useEffect, useState } from 'react'

import { AvatarGroup } from '@mui/material'
import UserAvatar from '../../avatar/UserAvatar'

const componentsProps = {
	additionalAvatar: {
		sx: {
			width: 24,
			height: 24,
			fontSize: 24 / 2,
		},
	},
}

const AvatarGroupCSS = { cursor: 'pointer', justifyContent: 'flex-end' }

interface IMembersCell {
	data?
	Popover?
	id?
	displayManager?
	setIsOpen?
	zIndexPopover?
	titlePopover?
	otherAction?
}

const MembersCell: React.FC<IMembersCell> = ({
	data,
	Popover,
	id,
	displayManager = true,
	setIsOpen = null,
	zIndexPopover,
	titlePopover,
	otherAction,
}) => {
	const [anchorEl, setAnchorEl] = useState(null)
	const handleClick = useCallback(
		(e) => {
			e.preventDefault()
			e.stopPropagation()
			setAnchorEl(e.currentTarget)
			otherAction && otherAction(e)
		},
		[otherAction]
	)

	useEffect(() => {
		if (setIsOpen) setIsOpen(Boolean(anchorEl))
	}, [anchorEl])

	return (
		<>
			{data?.length ? (
				<>
					<AvatarGroup
						max={3}
						total={data?.length}
						onClick={handleClick}
						sx={AvatarGroupCSS}
						componentsProps={componentsProps}
					>
						{data.map((user, i) => (
							<UserAvatar
								key={i}
								isGrouped
								userName={{
									firstName: user?.firstName,
									lastName: user?.lastName,
								}}
							/>
						))}
					</AvatarGroup>

					<Popover
						id={id}
						anchorEl={anchorEl}
						setAnchorEl={setAnchorEl}
						data={data}
						displayManager={displayManager}
						zIndexPopover={zIndexPopover}
						title={titlePopover}
					/>
				</>
			) : (
				'-'
			)}
		</>
	)
}

export default MembersCell
