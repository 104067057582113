/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

import { alertIcon } from '../imgBase64'
import { displayTitlePDF } from '../utilPdf'
import { blockEmpty } from './blockEmpty'
import { blockRecords } from './blocksRecords'

export const blockLastAlerts = (doc, { titles, records, option }) => {
	doc.autoTable()

	// Display the title bock
	displayTitlePDF(
		doc,
		option.translate('pdfForms.alertsTitle'),
		{
			img: { img: alertIcon, imgHeight: 7, imgWidth: 7 },
		},
		0
	)

	// Display an empty block if no data
	if (!records?.length) {
		blockEmpty(doc, option.translate)
	}

	// Display record with alerts
	else {
		blockRecords(doc, titles, records)
	}
}
