import React, { useState, useCallback } from 'react'

import { useNavigate, useLocation } from 'react-router-dom'

import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { Popover, List } from '@mui/material'

import { useModal } from '../../../../utils/hooks'
import ConfirmDelete from '../../../modal/ConfirmDelete'
import { handleOpenViewModal } from '../../../../utils/utils'
import duplicateTableRow from './actions/duplicateTableRow'
import ActionBtn from './ActionBtn'
import SlidePanelUserInfos from '../../../slidepanel/user'
import { EFormModalMode } from '../../../../utils/app-models'
import ActionBtnDuplicateExpanded from './ActionBtnDuplicateExpanded'

interface IPopoverActionsBtn {
	rowId
	isSharedWithMeEditException?: boolean
	actions
	popover
	viewModalLink?
	permissions?
	editPath?
	isContact?
	withSlide?
	handleEdit?
	isSharedWithMe?: boolean
	setDoNotDuplicate?
}

const PopoverActionsBtn: React.FC<IPopoverActionsBtn> = ({
	rowId,
	isSharedWithMeEditException = false,
	actions: {
		viewAction = true,
		duplicate = null,
		edit = false,
		deleted = null,
	},
	popover: { open, anchorEl, handleClose },
	viewModalLink,
	permissions = null,
	editPath = null,
	isContact,
	withSlide,
	handleEdit,
	isSharedWithMe = false,
	setDoNotDuplicate,
}) => {
	const navigate = useNavigate()
	const location: any = useLocation()

	// Open ActionBtnExpanded Component

	const [isOpen, setIsOpen] = useState(false)
	const openModalBtnExpanded = useCallback(() => {
		setIsOpen(!isOpen)
	}, [isOpen])

	// Duplicate ///////////////////////////////////

	const duplicatedTableRow = useCallback(() => {
		if (duplicate?.mutation) duplicateTableRow(duplicate, rowId)
		else if (edit || editPath) handleEdit(EFormModalMode?.duplicate)
	}, [rowId])

	// Warning Modal /////////////////////////////
	const {
		isVisible: isVisibleWarning,
		openModal: openModalWarning,
		closeModal: closeModalWarning,
	} = useModal()

	const closeActions = useCallback(() => {
		handleClose()
		closeModalWarning()
	}, [])

	// open slidePanel /////////////////////////////
	const [stateSlidePanel, setStateSlidePanel] = useState(false)
	const toggleDrawer = useCallback(
		(open: boolean) => () => {
			setStateSlidePanel(open)
		},
		[stateSlidePanel]
	)

	const handleEditClick = useCallback(
		() => handleEdit(EFormModalMode?.edit),
		[]
	)

	return (
		<>
			<Popover open={open} anchorEl={anchorEl} onClose={handleClose}>
				<List dense={false} component='nav'>
					{viewAction && (
						<ActionBtn
							onMouseDown={
								viewModalLink
									? handleOpenViewModal(
											navigate,
											location,
											viewModalLink,
											handleClose
									  )
									: toggleDrawer(true)
							}
							txtIntlMsg='misc.see'
							Icon={VisibilityIcon}
						/>
					)}

					{(isSharedWithMeEditException || !isSharedWithMe) &&
						(edit || editPath) && (
							<ActionBtn
								permissionsName={permissions?.update}
								onClick={handleEditClick}
								txtIntlMsg='misc.edit'
								Icon={EditIcon}
							/>
						)}
					{duplicate && (
						<ActionBtnDuplicateExpanded
							permissionsName={permissions?.update}
							duplicate={duplicate}
							duplicatedTableRow={duplicatedTableRow}
							onClick={openModalBtnExpanded}
							txtIntlMsg='misc.duplicate'
							Icon={FileCopyIcon}
							open={isOpen}
							onClose={handleClose}
							setDoNotDuplicate={setDoNotDuplicate}
						/>
					)}

					{!isSharedWithMe && deleted && (
						<ActionBtn
							permissionsName={permissions?.remove}
							onClick={openModalWarning}
							txtIntlMsg='misc.deleted'
							Icon={DeleteIcon}
						/>
					)}
				</List>
			</Popover>

			{isVisibleWarning && deleted?.messageConfirm && (
				<ConfirmDelete
					rowId={rowId}
					handleClose={handleClose}
					onClose={closeActions}
					open={isVisibleWarning}
					deleted={deleted}
				/>
			)}

			{stateSlidePanel && !viewModalLink && withSlide && (
				<SlidePanelUserInfos
					id={rowId as string}
					state={stateSlidePanel}
					toggleDrawer={toggleDrawer}
					isContact={isContact}
					additionalClose={handleClose}
				/>
			)}
		</>
	)
}

export default PopoverActionsBtn
