import { Stack, Typography } from '@mui/material'
import React, { useContext, useMemo } from 'react'
import { useFormatMessage } from '../../../../../../utils/hooks'
import { FiltersBtnContext } from '../../../../../../components/filters/filtersBtn/FiltersBtnContext'
import { EAccountType, EModule } from '../../../../../../utils/app-models'
import { useGetCustomerListForSelect } from '../../../../../../api/gql/queries/customersQueries'
import FilterStatusIntervention from '../../../../../../components/filters/filtersComponents/filterStatusIntervention'
import FilterMultiselect from '../../../../../../components/filters/filtersComponents/filterMultiselect'
import { useUsersForSelect } from '../../../../../../api/gql/queries/usersQueries'
import FilterSwitch from '../../../../../../components/filters/filtersComponents/filterSwitch'
import { useGetAllAgenciesForModal } from '../../../../../../api/gql/queries/agencyQueries'
import { AuthContext } from '../../../../../../utils/contexts/AuthContext'

interface IByVehicleFiltersBtnComponent {}

const ByVehicleFiltersBtnComponent: React.FC<
	IByVehicleFiltersBtnComponent
> = () => {
	const intlMsg = useFormatMessage()
	const { setTmpFilters, tmpFilters } = useContext(FiltersBtnContext)
	const { checkAccountsType } = useContext(AuthContext)
	const { users, loading: usersLoading } = useUsersForSelect()
	const { customers, loading: customersLoading } = useGetCustomerListForSelect()
	const { agenciesAccounts, agenciesAccountsLoading } =
		useGetAllAgenciesForModal()

	const isGuestAccount = useMemo(
		() => checkAccountsType([EAccountType?.guest]),
		[checkAccountsType]
	)

	const actorsOptions = useMemo(() => {
		return (
			users?.map((user) => ({
				id: user?.id,
				name: `${user?.firstName} ${user?.lastName}`,
				value: user?.id,
			})) || []
		)
	}, [users])

	const actorsDefaultValue = useMemo(
		() =>
			actorsOptions?.filter((option) =>
				tmpFilters?.intervention_actors_list?.find(
					(actorId) => option?.id === actorId
				)
			),

		[actorsOptions]
	)

	const customersOptions = useMemo(
		() =>
			customers?.map((customer) => ({
				id: customer?.id,
				name: customer?.name,
				value: customer?.id,
			})),
		[customers]
	)

	const customersDefaultValue = useMemo(
		() =>
			customersOptions?.filter((option) =>
				tmpFilters?.customer_list?.find(
					(customerId) => option?.id === customerId
				)
			),
		[customersOptions]
	)

	const agenciesAccountsOptions = useMemo(
		() =>
			agenciesAccounts?.map((agenciesAccount) => ({
				id: agenciesAccount?.id,
				name: agenciesAccount?.agencyCustomDetails[0]?.agencyName,
				value: agenciesAccount?.id,
			})) || [],
		[agenciesAccounts]
	)

	const agenciesAccountsDefaultValue = useMemo(
		() =>
			agenciesAccountsOptions?.filter((option) =>
				tmpFilters?.agency_list?.find((agencyId) => option?.id === agencyId)
			),
		[agenciesAccountsOptions]
	)
	return (
		<Stack spacing={4}>
			<Stack spacing={customersLoading ? 0 : 2}>
				<Typography variant='subtitle2'>
					{intlMsg('misc.intervention')}
				</Typography>

				{!isGuestAccount && (
					<FilterMultiselect
						loading={customersLoading}
						options={customersOptions}
						defaultValue={customersDefaultValue}
						label={'misc.accounts'}
						modules={[EModule.crm]}
						setFiltersQuerieParam={setTmpFilters}
						filtersQuerieParam={tmpFilters}
						argumentQuerieName='customer_list'
					/>
				)}
				<FilterStatusIntervention
					setFiltersQuerieParam={setTmpFilters}
					filtersQuerieParam={tmpFilters}
					argumentQuerieName='intervention_status_list'
				/>
			</Stack>
			{!isGuestAccount && (
				<Stack spacing={2}>
					<Typography variant='subtitle2'>
						{intlMsg('interventions.actors')}
					</Typography>
					<FilterMultiselect
						loading={agenciesAccountsLoading}
						options={agenciesAccountsOptions}
						defaultValue={agenciesAccountsDefaultValue}
						label={'agencies.agencies'}
						modules={[EModule.network]}
						setFiltersQuerieParam={setTmpFilters}
						filtersQuerieParam={tmpFilters}
						argumentQuerieName='agency_list'
					/>
					<FilterMultiselect
						loading={usersLoading}
						options={actorsOptions}
						defaultValue={actorsDefaultValue}
						label={'misc.operators'}
						setFiltersQuerieParam={setTmpFilters}
						filtersQuerieParam={tmpFilters}
						argumentQuerieName='intervention_actors_list'
					/>
					<FilterSwitch
						label={'interventions.interventionWithoutActors'}
						setFiltersQuerieParam={setTmpFilters}
						filtersQuerieParam={tmpFilters}
						argumentQuerieName='isWithoutActor'
						resetIfOtherValueExist='intervention_actors_list'
					/>
				</Stack>
			)}
		</Stack>
	)
}

export default ByVehicleFiltersBtnComponent
