import { createContext } from 'react'
import { EFormModalMode } from '../app-models'

export type IFormTemplateContext = {
	loadingFormTemplate: boolean
	canValidate: boolean
	mode: string
	setLoadingFormTemplate: (isLoading: boolean) => void
	setCanValidate?: (canValidate: boolean) => void
	setMode: (mode: string) => void
	resetContext?
	formUtils?
	drawerUtils?
	fieldUtils?
	setLimitationFields?
	limitationFields?
	isLimitModalOpen?
	setIsOpenLimitModal?
}

const defaultContextValues: IFormTemplateContext = {
	loadingFormTemplate: false,
	canValidate: true,
	mode: EFormModalMode.create,
	setMode: () => null,
	setLoadingFormTemplate: () => null,
}

export const FormTemplateContext: React.Context<IFormTemplateContext> =
	createContext(defaultContextValues)
