import React, { useCallback, useState, useContext, useMemo, memo } from 'react'
import {
	Avatar,
	IconButton,
	Typography,
	Stack,
	Tooltip,
	ListItem,
} from '@mui/material'
import { transformNameToInitialName } from './utils-avatar'
import { dynamicColor } from '../../utils/color'
import { getIndexesInAlphabet } from '../../utils/string'
import SlidePanelUserInfos from '../slidepanel/user'
import { AuthContext } from '../../utils/contexts/AuthContext'
import { EAccountType } from '../../utils/app-models'

const sizeAvatar = { small: 24, medium: 36, large: 90 }

type userNameType = {
	firstName: string | null | undefined
	lastName: string | null | undefined
}

interface IUserAvatar {
	userName: userNameType
	size?: 'medium' | 'small' | 'large' | undefined
	sx?: {}
	userId?: string | null | undefined
	isContact?: boolean
	displayName?: boolean
	isGrouped?: boolean
	accountId?: string | null | undefined
	isListItemButton?: boolean
	listItemSX?: any
}

const UserAvatar: React.FC<IUserAvatar> = ({
	userName,
	size = 'small',
	sx = {},
	userId,
	isContact,
	displayName,
	isGrouped,
	accountId,
	isListItemButton = false,
	listItemSX = {},
}) => {
	const { checkAccountsType, currentUser } = useContext(AuthContext)
	const [slidePanelIsOpen, setSlidePanelIsOpen] = useState(false)
	const isCurrentUserGuest = useMemo(
		() => checkAccountsType([EAccountType.guest]),
		[]
	)
	const isSlidePanelDisabled = useMemo(
		() =>
			!(userId || isContact) ||
			isCurrentUserGuest ||
			(accountId && currentUser.account.id !== accountId),
		[accountId]
	)

	const initialName = useMemo(
		() => transformNameToInitialName(userName),
		[userName]
	)

	const backgroundColor = useMemo(() => {
		const initialsIndexes = parseInt(
			`${getIndexesInAlphabet(initialName[0] as any)}${getIndexesInAlphabet(
				initialName[1] as any
			)}`
		)

		return dynamicColor(initialsIndexes, 400, false) as any
	}, [initialName])

	const toggleOpenSlide = useCallback(
		(open: boolean) => () => {
			setSlidePanelIsOpen(open)
		},
		[]
	)

	const openSlidePanel = useMemo(() => {
		if (isSlidePanelDisabled) return {}

		return { onClick: toggleOpenSlide(true) }
	}, [isSlidePanelDisabled, toggleOpenSlide])

	return (
		<>
			{isGrouped && initialName ? (
				<Avatar
					{...openSlidePanel}
					style={{
						width: sizeAvatar[size],
						height: sizeAvatar[size],
						fontSize: sizeAvatar[size] / 2,
						backgroundColor,
						...sx,
					}}
				>
					{initialName}
				</Avatar>
			) : (
				initialName && (
					<Stack direction='row' alignItems='center'>
						<ListItem
							sx={listItemSX}
							// button={isListItemButton && !isSlidePanelDisabled}
							button={isListItemButton && (!isSlidePanelDisabled as any)}
							{...openSlidePanel}
						>
							<Tooltip
								title={
									!displayName
										? `${userName?.firstName ?? ''} ${userName?.lastName ?? ''}`
										: ''
								}
							>
								<span>
									<IconButton
										color='primary'
										component='span'
										disabled={isSlidePanelDisabled}
										size={size}
										{...openSlidePanel}
									>
										<Avatar
											style={{
												width: sizeAvatar[size],
												height: sizeAvatar[size],
												fontSize: sizeAvatar[size] / 2,
												backgroundColor,
												...sx,
											}}
										>
											{initialName}
										</Avatar>
									</IconButton>
								</span>
							</Tooltip>

							{displayName && (
								<Typography
									style={{
										cursor: !isSlidePanelDisabled ? 'pointer' : 'inherit',
									}}
									{...openSlidePanel}
								>
									{`${userName?.firstName ?? ''} ${userName?.lastName ?? ''}`}
								</Typography>
							)}
						</ListItem>
					</Stack>
				)
			)}

			{slidePanelIsOpen && (
				<SlidePanelUserInfos
					id={userId}
					state={slidePanelIsOpen}
					toggleDrawer={toggleOpenSlide}
					isContact={isContact}
				/>
			)}
		</>
	)
}

export default memo(UserAvatar)
