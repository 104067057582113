import { string, object } from 'yup'
import translateIntlMsgString from '../../../../../../utils/translateIntlMsgString'

const catalogueProductBaseModalValidationSchema = object().shape({
	name: string().required(translateIntlMsgString('validationSchema.require')),
	productBaseCategory: object()
		.required(translateIntlMsgString('validationSchema.require'))
		.nullable(),
	type: object()
		.required(translateIntlMsgString('validationSchema.require'))
		.nullable(),
})

export default catalogueProductBaseModalValidationSchema
