import {
	IOverrideConfigTable,
	overrideConfigTableProps,
} from '../../../../utils/typescript/tableType'
import WlistInterventionsControlByReferenceViewWidget from '../../../tableV2/config/dashboard/interventions/byReference/WlistInterventionsControlByReferenceViewWidget'

export const overrideConfigTable = ({
	othersQueryParams,
}: overrideConfigTableProps): IOverrideConfigTable => ({
	tableConfig: WlistInterventionsControlByReferenceViewWidget,
	othersQueryParams,
	elevationNbr: 1,
	stickyToolBar: true,
	hidden: {
		tableNavTab: true,
		tableToolBar: {
			title: false,
			filter: true,
			search: true,
			columnFilter: false,
			tableBtnCreate: true,
			tableExport: true,
			tableImport: true,
			switchActive: true,
		},
	},
	tableContainer: { height: '365px' },
	localStorageName: 'listInterventionsByReferenceViewTableFiltersWidget',
})
