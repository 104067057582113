/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import { InputText } from '../../../../components/forms/v1/formFields'
import SelectForm from '../../../../components/forms/v1/formFields/SelectForm'
import SelectSearch from '../../../../components/forms/v1/formFields/SelectSearch'

import { useFormatMessage } from '../../../../utils/hooks'
import { EInterventionTypes } from '../../../../utils/app-models'

export const TemlateFormsName = ({ control, ...rest }) => {
	const intlMsg = useFormatMessage()
	return (
		<InputText
			name='name'
			label={intlMsg('formTemplate.modelName')}
			control={control}
			isrequired
			{...rest}
		/>
	)
}

export const ReportTitle = ({ control, ...rest }) => {
	const intlMsg = useFormatMessage()
	return (
		<InputText
			name='title'
			label={intlMsg('formTemplate.reportTitle')}
			control={control}
			{...rest}
		/>
	)
}

export const SelectStatus = ({ control, ...rest }) => {
	const intlMsg = useFormatMessage()
	return (
		<SelectForm
			name='isActive'
			label={intlMsg('formTemplate.status')}
			control={control}
			selectElements={[
				{
					key: intlMsg(`formTemplate.active`),
					label: intlMsg(`formTemplate.active`),
					value: true,
				},
				{
					key: intlMsg(`formTemplate.inactive`),
					label: intlMsg(`formTemplate.inactive`),
					value: false,
				},
			]}
			{...rest}
		/>
	)
}

export const SelectInterventionTypes = ({ control, ...rest }) => {
	const intlMsg = useFormatMessage()
	return (
		<SelectForm
			name='interventionType'
			label={intlMsg('formTemplate.missionType')}
			control={control}
			isRequired
			selectElements={[
				{
					key: intlMsg(`interventions.all`),
					label: intlMsg(`interventions.all`),
					value: intlMsg(`interventions.all`),
				},
				{
					key: intlMsg(`interventions.${EInterventionTypes.control}`),
					label: intlMsg(`interventions.${EInterventionTypes.control}`),
					value: EInterventionTypes.control,
				},
				{
					key: intlMsg(`interventions.${EInterventionTypes.transport}`),
					label: intlMsg(`interventions.${EInterventionTypes.transport}`),
					value: EInterventionTypes.transport,
				},
			]}
			{...rest}
		/>
	)
}

export const SelectCustomerAccount = ({ formUtils, customers }) => {
	const intlMsg = useFormatMessage()
	const customersList =
		customers?.map((customer) => ({
			label: customer?.name,
			key: customer?.id,
			value: customer?.id,
		})) || []

	return (
		<SelectSearch
			name={'customer'}
			label={intlMsg('misc.account')}
			selectElements={[
				{
					label: intlMsg(`misc.allAccounts`),
					key: 'all',
					value: 'all',
				},
				...customersList,
			]}
			formUtils={formUtils}
		/>
	)
}

export const Footer = ({ control, ...rest }) => {
	const intlMsg = useFormatMessage()
	return (
		<InputText
			name='footer'
			label={intlMsg('formTemplate.footer')}
			control={control}
			multiline
			rows={4}
			rowsMax={4}
			{...rest}
		/>
	)
}

export const PageName = ({ control, name, ...rest }) => {
	const intlMsg = useFormatMessage()
	return (
		<InputText
			name={name}
			label={intlMsg('formTemplate.pageName')}
			control={control}
			{...rest}
		/>
	)
}
