import React, { CSSProperties, memo, useState } from 'react'
import {
	TypographyButtonColor,
	TypographyButtonSize,
	TypographyButtonType,
	TypographyButtonVariant,
} from './TypographyButton'
import { GlobalButtonTheme } from './StyleButton'

const buttonDefaultCSS = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	color: 'inherit',
	cursor: 'pointer',
	border: 'none',
	backgroundColor: 'transparent',
	borderRadius: '4px',
	'& > *': {
		margin: 0,
		padding: 0,
	},
}

type ButtonProps = {
	variant?: TypographyButtonVariant | string
	size?: TypographyButtonSize | string
	color?: TypographyButtonColor
	onClick?: () => void
	children: React.ReactNode
	type?: TypographyButtonType
	style?: CSSProperties
	uppercase?: boolean
	gap?: string
}

const Button: React.FC<ButtonProps> = ({
	variant = TypographyButtonVariant.text,
	size = TypographyButtonSize.medium,
	onClick,
	children,
	type = TypographyButtonType.button,
	style,
	uppercase = false,
	gap = '8px',
}) => {
	const [isHovered, setIsHovered] = useState(false)

	const hoverStyles = isHovered
		? GlobalButtonTheme.button.variant[variant]['&:hover']
		: {}

	const buttonCSS = {
		...buttonDefaultCSS,
		...GlobalButtonTheme.button.size[size],
		...GlobalButtonTheme.button.variant[variant],
		...hoverStyles,
		textTransform: uppercase ? 'uppercase' : 'none',
		gap: gap,
		...style,
	}
	// Inline style cannot allow hover effect so we need to use onMouseEnter and onMouseLeave
	const handleMouseEnter = () => setIsHovered(true)
	const handleMouseLeave = () => setIsHovered(false)
	return (
		<button
			style={buttonCSS}
			onClick={onClick}
			type={type}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			{children}
		</button>
	)
}

export default memo(Button)
