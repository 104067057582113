/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useMemo, useState } from 'react'

import Grid from '@mui/material/Grid'
import { Box, Typography } from '@mui/material'

import { useFormatMessage } from '../../../../utils/hooks'
import { useSiteAndNetworksListForModal } from '../../../../api/gql/queries/sitesQueries'
import PermissionsHandler from '../../../../components/permissionsHandler'
import { EModule } from '../../../../utils/app-models'
import { ToggleButtons } from '../../../../components'
import DeboucedFormTextField from '../../../../components/forms/formFields/DebouncedFormTextField'
import { FormModalContext } from '../../../../components/forms/formModal/FormModalContext'
import FormMultiSelectSearch from '../../../../components/forms/formFields/formMultiSelectSearch'

const wrapperModalCSS = {
	p: 4,
	overflow: 'auto',
}

interface ITeamModalInfo {}

const TeamModalInfo: React.FC<ITeamModalInfo> = () => {
	const intlMsg = useFormatMessage()
	const [toggleStateValue, setToggleStateValue] = useState<string | null>(
		'sites'
	)
	const { networks, sites } = useSiteAndNetworksListForModal()
	const sitesAndNetworks = useMemo(
		() => ({
			sites:
				sites?.map((site) => ({
					key: site.id,
					label: site.name,
					value: site,
				})) || [],
			networks:
				networks?.map((network) => ({
					key: network.id,
					label: network.name,
					value: network,
				})) || [],
		}),
		[sites, networks]
	)

	return (
		<Box sx={wrapperModalCSS}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<DeboucedFormTextField
						name='name'
						label={intlMsg('misc.teamName')}
						isRequired
						context={FormModalContext}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography variant='body2' gutterBottom>
						{intlMsg('misc.affectation')} :
					</Typography>
					<PermissionsHandler modulesName={[EModule.network]}>
						<ToggleButtons
							text={{
								primary: { text: intlMsg('misc.bySites'), value: 'sites' },
								secondary: {
									text: intlMsg('misc.byNetworks'),
									value: 'network',
								},
							}}
							setToggleStateValue={setToggleStateValue}
						/>
					</PermissionsHandler>
				</Grid>

				{toggleStateValue === 'sites' ? (
					<Grid item xs={12}>
						<FormMultiSelectSearch
							name='sites'
							options={sitesAndNetworks.sites}
							context={FormModalContext}
							label={intlMsg('misc.sitesLabel')}
							groupBy='customer.name'
							selectAllText={intlMsg('misc.selectAll')}
							nbChipsToDisplay={3}
							bordered
						/>
					</Grid>
				) : (
					<PermissionsHandler modulesName={[EModule.network]}>
						<Grid item xs={12}>
							<FormMultiSelectSearch
								context={FormModalContext}
								selectAllText={intlMsg('misc.selectAll')}
								name='networks'
								options={sitesAndNetworks.networks}
								label={intlMsg('misc.networksLabel')}
								nbChipsToDisplay={3}
								bordered
							/>
						</Grid>
					</PermissionsHandler>
				)}
			</Grid>
		</Box>
	)
}

export default TeamModalInfo
