import React, { cloneElement, useCallback, useContext } from 'react'

import { Box, Stack, StepButton, StepLabel, Step } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import { default as StepperMUI } from '@mui/material/Stepper'

import { StepperContext } from './StepperContext'
import { useFormatMessage } from '../../../utils/hooks'
import LogoConnectIcon from '../../../svgIcons/LogoConnectIcon'

import { stepperHeaderCSS } from '../../../styles/sxCSS/stepper'

interface IStepper {
	loading?: boolean
}

const Stepper: React.FC<IStepper> = ({ loading = false }) => {
	const {
		stepperConfig,
		currentStep,
		setCurrentStep,
		triggerError,
		stepErrors,
	} = useContext(StepperContext)

	const intlMsg = useFormatMessage()

	const handleStepCLick = useCallback(
		(newStep) => async () => {
			await triggerError()
			setCurrentStep(newStep)
		},
		[currentStep, setCurrentStep, triggerError]
	)

	return (
		<>
			<Box sx={stepperHeaderCSS}>
				<StepperMUI nonLinear activeStep={currentStep}>
					{stepperConfig.map(({ name }, i) => (
						<Step key={name}>
							<StepButton
								onClick={handleStepCLick(i)}
								icon={
									stepErrors[i] && (
										<ErrorIcon color='error' sx={{ fontSize: 27 }} />
									)
								}
							>
								<StepLabel>{intlMsg(name)}</StepLabel>
							</StepButton>
						</Step>
					))}
				</StepperMUI>
			</Box>

			<Stack sx={{ overflowY: 'scroll', height: 1, width: 1 }}>
				{loading ? (
					<LogoConnectIcon height='100%' />
				) : (
					cloneElement(stepperConfig[currentStep].component)
				)}
			</Stack>
		</>
	)
}

export default Stepper
