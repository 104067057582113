import React, { useState, useCallback } from 'react'

import { Typography, Box } from '@mui/material'

import ModalAnnotation from './ModalAnnotation'
import PictureAnnotation from './PictureAnnotation'
import { useFormatMessage } from '../../../../../../utils/hooks'

const contentCSS = { position: 'relative', overflow: 'hidden' }

const annotationLayerCSS = (values) =>
	({
		position: 'relative',
		overflow: 'hidden',
		cursor: values?.value ? 'pointer' : 'inherit',
	} as const)

interface IModalAnnotationButton {
	values
	angleType?
	name?
	isDocument?: boolean
}

const ModalAnnotationButton: React.FC<IModalAnnotationButton> = ({
	values,
	angleType,
	name,
	isDocument = false,
}) => {
	const intlMsg = useFormatMessage()
	const [open, setOpen] = useState(false)

	const handleOpen = useCallback(() => {
		values?.value && setOpen(true)
	}, [values])

	const handleClose = useCallback(() => {
		setOpen(false)
	}, [])

	// function to display annotations when image is loading end
	const handleImageLoaded = useCallback(
		(setAnnotationPosition, refImg) => () => {
			const imgSelected = refImg.current as any
			if (imgSelected && values?.annotations?.length) {
				const { clientHeight, clientWidth } = imgSelected

				const annotations: any = []

				values?.annotations?.forEach(({ x1, x2, y1, y2 }) => {
					// defines the values according to the starting point of the strapping
					const y1_m = y2 < y1 ? y2 : y1
					const y2_m = y2 < y1 ? y1 : y2
					const x1_m = x2 < x1 ? x2 : x1
					const x2_m = x2 < x1 ? x1 : x2

					const left = x1_m * clientWidth
					const top = y1_m * clientHeight
					const width = Math.abs((x2_m - x1_m) * clientWidth)
					const height = Math.abs((y2_m - y1_m) * clientHeight)

					annotations.push({
						top,
						left,
						width,
						height,
					})
				})

				setAnnotationPosition(annotations)
			}
		},
		[values?.annotations]
	)

	return (
		<>
			<Box sx={contentCSS}>
				<Box sx={annotationLayerCSS(values)} onClick={handleOpen}>
					<PictureAnnotation
						handleImageLoaded={handleImageLoaded}
						imgURL={values?.value ?? null}
						countAnnotations
						angleType={angleType}
					/>
				</Box>

				<Typography variant='caption' sx={{ fontWeight: 'bold' }}>
					{name ?? (angleType && intlMsg(`formTemplate.${angleType}`))}
				</Typography>
			</Box>

			{open && (
				<ModalAnnotation
					handleClose={handleClose}
					open={open}
					handleImageLoaded={handleImageLoaded}
					imgURL={values?.value}
					angleType={angleType}
					name={name}
					isDocument={isDocument}
					geolocation={values?.geolocation}
					annotations={values?.annotations}
				/>
			)}
		</>
	)
}

export default ModalAnnotationButton
