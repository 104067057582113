import React from 'react'

import { ListItemIcon, ListItemText, ListItemButton } from '@mui/material'

import { useFormatMessage } from '../../../../utils/hooks'
import PermissionsHandler from '../../../permissionsHandler'

const listItemTextCSS = {
	'& span': { fontSize: ({ typography }) => typography.body2.fontSize },
}

interface IActionBtn {
	permissionsName?
	onClick?
	onMouseDown?
	txtIntlMsg
	Icon?
	dataTestId?: string
}

const ActionBtn: React.FC<IActionBtn> = ({
	permissionsName,
	onClick,
	onMouseDown,
	txtIntlMsg,
	Icon,

	dataTestId = '',
}) => {
	const intlMsg = useFormatMessage()

	return (
		<PermissionsHandler permissionsName={permissionsName}>
			<ListItemButton
				onClick={onClick}
				onMouseDown={onMouseDown}
				data-test-id={dataTestId}
			>
				<ListItemIcon sx={{ minWidth: '0px' }}>
					{Icon && <Icon sx={{ mr: 2 }} />}
				</ListItemIcon>
				<ListItemText primary={intlMsg(txtIntlMsg)} sx={listItemTextCSS} />
			</ListItemButton>
		</PermissionsHandler>
	)
}

export default ActionBtn
