import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import Typography from '@mui/material/Typography'
import { Stack } from '@mui/material'

import { handleOpenViewModal } from '../../../../utils/utils'
import CustomLink from '../../../../components/_overrideMui/Link/CustomLink'

interface IBreadcrumbItem {
	breadcrumb
}

const BreadcrumbItem: React.FC<IBreadcrumbItem> = ({ breadcrumb }) => {
	const navigate = useNavigate()
	const location = useLocation()

	return (
		<Stack direction='row' alignItems='center' spacing={0.5}>
			<Typography variant='text1' color='#00000061'>
				{breadcrumb?.title + ' :'}
			</Typography>

			<CustomLink
				variant='text1'
				variantlink='primaryLink'
				disabled={!breadcrumb?.vmLink}
				onMouseDown={
					breadcrumb?.vmLink &&
					handleOpenViewModal(navigate, location, breadcrumb.vmLink)
				}
			>
				{breadcrumb?.text}
			</CustomLink>
		</Stack>
	)
}

export default BreadcrumbItem
