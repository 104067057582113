import React from 'react'

import { useNavigate, useLocation } from 'react-router-dom'
import { List, Stack, ListItemButton } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import { handleOpenViewModal } from '../../../../utils/utils'
import CustomLink from '../../../_overrideMui/Link/CustomLink'
import { grey } from '@mui/material/colors'
import { ROUTES } from '../../../../routes/consts'

const CheckCircleIconCSS = { fontSize: '17px', color: '#4caf50' }
const CheckCircleOutlineIconCSS = { fontSize: '17px', color: grey[500] }

const ListCSS = {
	'& .MuiButtonBase-root.Mui-disabled': { opacity: 1 },
}

interface IFormsListItem {
	handleClose
	data
}

const FormsListItem: React.FC<IFormsListItem> = ({ handleClose, data }) => {
	const navigate = useNavigate()
	const location: any = useLocation()

	return (
		<List dense sx={ListCSS}>
			{data?.map(({ isDone, id, formTemplate }) => {
				return (
					<ListItemButton
						key={id}
						onClick={
							isDone
								? handleOpenViewModal(
										navigate,
										location,
										ROUTES.VIEWMODAL.INTERVENTION_FORMS.url + id,
										handleClose
								  )
								: undefined
						}
						disabled={!isDone}
					>
						<Stack spacing={0.5} direction='row' alignItems='center'>
							<CustomLink variantlink='primaryLink' disabled={!isDone}>
								<Stack direction='row' spacing={0.5} alignItems='center'>
									{isDone ? (
										<CheckCircleIcon sx={CheckCircleIconCSS} />
									) : (
										<CheckCircleOutlineIcon sx={CheckCircleOutlineIconCSS} />
									)}
									<div>{formTemplate?.title}</div>
								</Stack>
							</CustomLink>
						</Stack>
					</ListItemButton>
				)
			})}
		</List>
	)
}

export default FormsListItem
