import React, { useContext, useMemo } from 'react'

import { Stack } from '@mui/material'

import NavLeftSidebar from './nav/NavLeftSidebar'
import NavTop from './nav/NavTop'
import { AuthContext } from '../../utils/contexts/AuthContext'

const contentNavCloseCSS = {
	marginLeft: '220px',
}

const contentNavOpenCSS = {
	marginLeft: '60px',
}

const backgroundCSS = {
	backgroundColor: '#dddddd45',
	height: '100vh',
}

const contentCSS = {
	flexGrow: 1,
	overflow: 'auto',
	marginTop: '69px',
	p: 4,
	transition: '0.3s',
	height: '91.2vh',

	'&:has(.formTemplate)': {
		paddingBottom: '80px',
	},

	'&:has(.appHubeecheck)': {
		height: '100%',
		minHeight: '93vh',

		'& > div': {
			display: 'flex !important',
		},
	},
}

interface ILayoutLogin {
	children: React.ReactNode
}

const LayoutLogin: React.FC<ILayoutLogin> = ({ children }) => {
	const { navIsOpen } = useContext(AuthContext)
	const layoutLoginSx = useMemo(
		() => ({
			...contentCSS,
			...(!navIsOpen ? contentNavOpenCSS : contentNavCloseCSS),
		}),
		[navIsOpen]
	)

	return (
		<Stack direction='row' sx={backgroundCSS}>
			<NavTop />
			<NavLeftSidebar />
			<Stack sx={layoutLoginSx}>{children}</Stack>
		</Stack>
	)
}

export default LayoutLogin
