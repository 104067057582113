/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'

import AccountBoxIcon from '@mui/icons-material/AccountBox'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Stack } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'

import EmailCell from '../../../tableCell/EmailCell'
import ChipCountListPopover from '../../../../multiItemsPopoverList/popover/simpleListPopover'
import IconTooltips from '../../../../icons/IconToolTips'
import OpenSlidePanelCell from '../../../tableCell/OpenSlidePanelCell'
import OpenLinkCell from '../../../tableCell/OpenLinkCell'
import { useRemoveContact } from '../../../../../api/gql/mutations/customerContactMutation'
import CustomerContactModal from '../../../../../view/dashboard/customerContacts/customerContactModal'
import {
	EModule,
	EPermission,
	EPermissionScope,
} from '../../../../../utils/app-models'
import { ROUTES } from '../../../../../routes/consts'
import PhoneCell from '../../../tableCell/PhoneCell'
import { Box } from '@mui/material'
import ActionsBtn from '../../../tableCell/actionsBtn'
import UserAvatarSlidePanel from '../../../../avatar/UserAvatarSlidePanel'
//!  columnLabel is mandatory
//!  dataKey OR personalizedCell is mandatory
// use datakey if you want to just display data
// personalized Cell is a function taking row as parameter

const customerListContactViewModalTable = [
	{
		columnLabel: 'misc.generalAccount',
		permissions: [EPermission.superMegaAdmin],
		personalizedCell: ({ account }) => <Box>{account?.name}</Box>,
	},

	{
		fixWidth: '50px',
		columnLabel: ' ',
		visible: true,
		stickyFirstCol: true,
		personalizedCell: (row: any) => (
			<UserAvatarSlidePanel
				firstName={row?.firstName}
				lastName={row?.lastName}
				userId={row?.id}
				isContact
				displayName={false}
			/>
		),
	},

	{
		columnLabel: 'misc.firstname',
		orderBy: 'firstName',
		personalizedCell: ({ id, firstName }) => (
			<OpenSlidePanelCell txt={firstName} id={id} />
		),
	},
	{
		columnLabel: 'misc.lastname',
		orderBy: 'lastName',
		personalizedCell: ({ id, lastName }) => (
			<OpenSlidePanelCell txt={lastName} id={id} />
		),
	},

	{
		columnLabel: 'misc.account',
		modules: [EModule.crm],
		orderBy: 'customer_name',
		personalizedCell: ({ customer }) => (
			<OpenLinkCell
				viewModalLink={`${ROUTES.VIEWMODAL.CUSTOMERS.url}${customer?.id}`}
				txt={customer?.name}
				maxCharacters={40}
			/>
		),
	},

	{
		columnLabel: 'misc.sitesAccess',
		personalizedCell: (row: any) => (
			<ChipCountListPopover
				text={'misc.sites'}
				data={
					row?.sites?.collection?.map(({ name, id }) => ({
						name,
						id,
					})) || []
				}
				viewModalLink={ROUTES.VIEWMODAL.SITE.url}
			/>
		),
	},

	{
		columnLabel: 'administration.workPosition',
		orderBy: 'job',
		dataKey: 'job',
		maxCharacters: 15,
	},
	{
		columnLabel: 'misc.email',
		orderBy: 'email',
		personalizedCell: (row: any) => <EmailCell txt={row.email} />,
	},

	{
		columnLabel: 'misc.phone',
		personalizedCell: (row: any) => (
			<Stack direction='row' alignItems='center' spacing={1}>
				<PhoneCell txt={row.phone} icon='phone' />
				<PhoneCell txt={row.cellphone} icon='cellphone' />
			</Stack>
		),
	},

	{
		columnLabel: 'misc.referentSiteAccount',
		personalizedCell: (row: any) => {
			return (
				<Stack direction='row' sx={{ minWidth: '100px' }}>
					<Stack direction='row' alignItems='center' mr={1}>
						<IconTooltips
							txt={row?.isSiteResp && 'misc.referentSite'}
							Icon={AccountBoxIcon}
							fontSize='small'
							typeBtn={false}
						/>
					</Stack>
					<Stack direction='row' alignItems='center' ml={1}>
						<IconTooltips
							txt={row?.isCustomerResp && 'misc.referentAccount'}
							Icon={AccountCircleIcon}
							fontSize='small'
							typeBtn={false}
						/>
					</Stack>
				</Stack>
			)
		},
	},

	{
		columnLabel: 'misc.share',
		orderBy: 'canAccessMyContent',
		personalizedCell: ({ canAccessMyContent }) => (
			<CheckCircleIcon
				color={(canAccessMyContent ? 'enabled' : 'disabled') as any}
			/>
		),
	},

	{
		visible: true,
		columnLabel: '',
		tableCellAlign: 'center',
		cellAlign: 'center',
		sticky: true,
		personalizedCell: ({ id }) => (
			<ActionsBtn
				rowId={id}
				deleted={{
					query: useRemoveContact,
					refreshQuery: ['getContactsList', 'getContactsNumbers'],
					successMessageDelete: 'success.contactDeleted',
					messageConfirm: {
						title: 'confirmModal.deleteContactTitle',
						message: 'confirmModal.deleteContactMessage',
					},
				}}
				editModal={
					<CustomerContactModal contactId={id} modalUtils={{} as any} />
				}
				isContact
				withSlide
				permissions={{
					update: [EPermissionScope.contactManage],
					remove: [EPermissionScope.contactManage],
					delete: [EPermissionScope.contactDelete],
				}}
			/>
		),
	},
]

export default customerListContactViewModalTable
