import React from 'react'

import Slider from '@mui/material/Slider'
import Rating from '@mui/material/Rating'
import { Stack } from '@mui/material'

import TitleField from './TitleField'
import { EFormTemplateRatingType } from '../../../../../../utils/app-models'

interface IFieldRating {
	field
	deleteField
	index
	updateField
	insertField
}

const FieldRating: React.FC<IFieldRating> = ({
	field: { id, name, values, fieldType },
	deleteField,
	index,
	updateField,
	insertField,
}) => {
	const { min, max, ratingType, step, defaultValue } = values
	const fieldToEdit = {
		id,
		name,
		values,
		fieldType,
		updateField,
		index,
	}

	return (
		<Stack p={1}>
			<TitleField
				deleteField={deleteField}
				index={index}
				fieldToEdit={fieldToEdit}
				isRequired={values?.isRequired}
				insertField={insertField}
				values={values}
				fieldType={fieldType}
			/>
			<div>
				{ratingType === EFormTemplateRatingType.slider ? (
					<div>
						<Slider
							defaultValue={defaultValue}
							step={step}
							marks
							min={min}
							max={max}
							disabled
							style={{ color: '#2196F3' }}
						/>
					</div>
				) : (
					<Rating value={defaultValue} precision={0.5} readOnly />
				)}
			</div>
			{ratingType === EFormTemplateRatingType.slider && (
				<Stack direction='row' justifyContent='space-between'>
					<span>{min}</span>
					<span>{max}</span>
				</Stack>
			)}
		</Stack>
	)
}

export default FieldRating
