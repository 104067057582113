import React, { useCallback, useContext, useEffect } from 'react'

import { Button, Stack } from '@mui/material'
import ArrowBack from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CheckIcon from '@mui/icons-material/Check'

import { StepperContext } from './StepperContext'
import { useFormatMessage } from '../../../utils/hooks'
import { EFormModalMode } from '../../../utils/app-models'
import { footerModalCSS } from '../modalSxCss'
import LoadingButton from '@mui/lab/LoadingButton'

interface IFooterStepperModal {
	onValidate: any
	onClose?: any
}

const FooterStepperModal: React.FC<IFooterStepperModal> = ({
	onValidate,
	onClose,
}) => {
	const intlMsg = useFormatMessage()
	const {
		closeModal,
		goNext,
		goPrevious,
		currentStep,
		stepperConfig,
		data,
		setFormHaveError,
		formHaveError,
		triggerAllErrors,
		mode,
		loading,
		startingData,
	} = useContext(StepperContext)

	const validateStepper = useCallback(async () => {
		const isInError = await triggerAllErrors(true)()
		if (!isInError) onValidate(data, startingData)
	}, [data, onValidate])

	const handleOnClose = useCallback(() => {
		setFormHaveError(false)
		closeModal()
		if (onClose) onClose()
	}, [onClose, closeModal])

	// Trigger all errors firstTime (to disabled btn saveAndQuit if error)
	useEffect(() => {
		mode !== EFormModalMode.edit && triggerAllErrors(false)()
	}, [])

	return (
		<Stack
			direction='row'
			justifyContent='space-between'
			spacing={2}
			sx={footerModalCSS}
		>
			<Button variant='outlined' color='primary' onClick={handleOnClose}>
				{intlMsg('misc.cancel')}
			</Button>

			<Stack spacing={2} direction='row'>
				<Button
					variant='outlined'
					color='primary'
					onClick={goPrevious}
					disabled={currentStep === 0}
					startIcon={<ArrowBack />}
				>
					{intlMsg('misc.previous')}
				</Button>
				<Button
					variant='outlined'
					color='primary'
					onClick={goNext}
					disabled={currentStep === stepperConfig?.length - 1}
					endIcon={<ArrowForwardIcon />}
				>
					{intlMsg('misc.next')}
				</Button>
				<LoadingButton
					color='primary'
					variant='contained'
					onClick={validateStepper}
					disabled={formHaveError}
					loading={loading}
					endIcon={<CheckIcon />}
				>
					{intlMsg('misc.saveAndQuit')}
				</LoadingButton>
			</Stack>
		</Stack>
	)
}

export default FooterStepperModal
