import React, {
	createContext,
	useState,
	useEffect,
	useCallback,
	useMemo,
	useContext,
} from 'react'

import { useLazyVehicle } from '../../../../../../../api/gql/queries/vehiclesQueries'
import { useLazyTransportVehicleSearch } from '../../../../../../../api/gql/queries/interventionsTransportQueries'
import {
	IModalUtils,
	useDebouncedState,
	useModal,
} from '../../../../../../../utils/hooks'
import { FormModalContext } from '../../../../../../../components/forms/formModal/FormModalContext'
import {
	formatListOptionsForSelectVEHICLES,
	formatRequestedVehicleData,
} from './searchVehicle/VehicleAssignmentUtils'
import { VehicleValidationSchema } from '../../../../../vehicles/vehicleModal/vehiclesModalInfoValidationSchema'
import translateIntlMsgString from '../../../../../../../utils/translateIntlMsgString'

////////////////////////////////////////////////////////////////////////////////////
// CONTEXT
////////////////////////////////////////////////////////////////////////////////////

export type IModalContext = {
	confirmVehicleAssignment?: any
	requestedVehicleData?: any
	setRequestedVehicleData?: any
	modalCreateVehicleUtils?: any
	modalVehicleAssignmentUtils?: any
	forcedImmatOrVin: string | null
	getResponseVehicleMutation?: any
	onClearVehicle?: any
	handleChangeSearch?: any
	setDebouncedState?: any
	loadingVehicles?: any
	selectElementsVehicle?: any
	onValidateVehicle?: any
	isNoVehicleFound?: any
	onCreateNewNewVehicle?: any
	invalidImmat?: any
}

const defaultContextValues: IModalContext = { forcedImmatOrVin: null }

export const VehiclesTransportContext: React.Context<IModalContext> =
	createContext(defaultContextValues)

////////////////////////////////////////////////////////////////////////////////////
// PROVIDER
////////////////////////////////////////////////////////////////////////////////////

export const VehiclesTransportProvider = ({ children }) => {
	const modalVehicleAssignmentUtils: IModalUtils = useModal()
	const modalCreateVehicleUtils: IModalUtils = useModal()
	const { setValue, data, setValues, setErrors, errors, resetError } =
		useContext(FormModalContext)

	const immatOrVin = useMemo(() => data?.immatOrVin || null, [data?.immatOrVin])
	const [requestedVehicleData, setRequestedVehicleData]: any = useState(null)
	const [forcedImmatOrVin, setForcedImmatOrVin]: any = useState(null)
	const [idVehicleMutation, setIdVehicleMutation] = useState(null)
	const [selectElementsVehicle, setSelectElementsVehicle] = useState([])
	const [isNoVehicleFound, setIsNoVehicleFound] = useState(false)
	const [invalidImmat, setIsInvalidImmat] = useState(false)
	const [debouncedState, setDebouncedState, liveState] = useDebouncedState({
		searchText: '',
	})

	/////////////////////////////////////////////////////////
	// Queries
	/////////////////////////////////////////////////////////

	const { loadingVehicles, vehicles, getVehicles } =
		useLazyTransportVehicleSearch()

	const { getVehicle, vehicle } = useLazyVehicle()

	////////////////////////////////////////////////////////
	// Search vehicle
	///////////////////////////////////////////////////////

	// Set the value enter in the SelectRadioSearch (send as argument for query)
	const handleChangeSearch = useCallback(
		(value) => {
			setDebouncedState({ ...liveState, searchText: value.trim() })
		},
		[liveState]
	)

	// launch the query with argument research
	useEffect(() => {
		if (debouncedState?.searchText?.length) {
			getVehicles({
				variables: { ...debouncedState },
			}).then(async (res) => {
				if (!res.data.vehicles.collection?.length) {
					// verify validity immat (> 12, it's a vin)
					if (debouncedState?.searchText?.length < 12) {
						VehicleValidationSchema?.validateAt(
							'immat',
							{ immat: debouncedState?.searchText },
							{ abortEarly: true }
						)
							.then(() => {
								setIsInvalidImmat(false)
								resetError('selectedVehicles')
							})
							.catch(() => {
								setIsInvalidImmat(true)
								setErrors({
									...errors,
									selectedVehicles: translateIntlMsgString(
										'errors.invalidImmat'
									),
								})
							})
					} else {
						setIsInvalidImmat(false)
					}

					setIsNoVehicleFound(true)
				} else {
					resetError('selectedVehicles')
					setIsNoVehicleFound(false)
				}
			})
		} else {
			setIsNoVehicleFound(false)
		}
	}, [debouncedState])

	// format data vehicle
	useEffect(() => {
		!loadingVehicles &&
			formatListOptionsForSelectVEHICLES(vehicles, setSelectElementsVehicle)
	}, [loadingVehicles])

	////////////////////////////////////

	const onValidateVehicle = useCallback(
		(immatOrVin) => {
			const vehicleDataFiltered = vehicles.filter(
				({ vehicleDetail }) =>
					vehicleDetail.immat === immatOrVin || vehicleDetail.vin === immatOrVin
			)[0]

			const isVehicleFromRequestingSite =
				vehicleDataFiltered?.site?.id === data?.orderingSite?.value?.id

			// if the vehicle belongs to orderingSite the confirmation modal is not displayed
			if (isVehicleFromRequestingSite) {
				setValues({
					selectedVehicles: formatRequestedVehicleData(vehicleDataFiltered),
					immatOrVin: null,
				})
				setRequestedVehicleData(null)
				setSelectElementsVehicle([])
			}
			// else open modal confirmation
			else {
				setRequestedVehicleData(vehicleDataFiltered)
				modalVehicleAssignmentUtils.openModal()
			}
		},
		[immatOrVin, vehicles, data]
	)

	const confirmVehicleAssignment = useCallback(() => {
		setValues({
			selectedVehicles: formatRequestedVehicleData(requestedVehicleData),
			immatOrVin: null,
		})
		setRequestedVehicleData(null)
		setSelectElementsVehicle([])
		modalVehicleAssignmentUtils.closeModal()
	}, [requestedVehicleData, setValues])

	const onClearVehicle = useCallback(() => {
		setSelectElementsVehicle([])
		setValues({ immatOrVin: '', selectedVehicles: [] })
	}, [setValues])

	// /////////////////////////////////////////////////////////
	//  vehicle created a updated
	// /////////////////////////////////////////////////////////

	const onCreateNewNewVehicle = useCallback(() => {
		setForcedImmatOrVin(debouncedState?.searchText)
		setRequestedVehicleData(null)
		setValues({ immatOrVin: null })
		modalCreateVehicleUtils.openModal()
	}, [debouncedState])

	const getResponseVehicleMutation = useCallback((res) => {
		setIdVehicleMutation(res.id)
	}, [])

	useEffect(() => {
		// If new vehicle created or updated
		if (idVehicleMutation) {
			getVehicle({ variables: { id: idVehicleMutation } }).then((res) => {
				setValues({
					selectedVehicles: formatRequestedVehicleData(res?.data?.vehicle),
					immatOrVin: null,
				})
				setRequestedVehicleData(null)
			})

			setIdVehicleMutation(null)
		}
	}, [idVehicleMutation])

	/////////////////////////////////////////////////////////
	// Update selected vehicle infos after vehicle update
	/////////////////////////////////////////////////////////

	useEffect(() => {
		if (vehicle && !requestedVehicleData && data?.selectedVehicles?.length) {
			setValue('selectedVehicles', formatRequestedVehicleData(vehicle))
		}
	}, [vehicle])

	/////////////////////////////////////////////////////////
	// Values
	/////////////////////////////////////////////////////////

	const value = useMemo(
		() => ({
			confirmVehicleAssignment,
			requestedVehicleData,
			setRequestedVehicleData,
			modalCreateVehicleUtils,
			modalVehicleAssignmentUtils,
			forcedImmatOrVin,
			getResponseVehicleMutation,
			onClearVehicle,
			handleChangeSearch,
			setDebouncedState,
			loadingVehicles,
			selectElementsVehicle,
			onValidateVehicle,
			onCreateNewNewVehicle,
			isNoVehicleFound,
			invalidImmat,
		}),
		[
			isNoVehicleFound,
			selectElementsVehicle,
			loadingVehicles,
			forcedImmatOrVin,
			requestedVehicleData,
			modalCreateVehicleUtils,
			modalVehicleAssignmentUtils,
			onValidateVehicle,
			invalidImmat,
		]
	)

	return (
		<VehiclesTransportContext.Provider value={value}>
			{children}
		</VehiclesTransportContext.Provider>
	)
}
