import React from 'react'

import Grid from '@mui/material/Grid'

import { useFormatMessage } from '../../../../utils/hooks'
import DeboucedFormTextField from '../../../../components/forms/formFields/DebouncedFormTextField'
import { FormModalContext } from '../../../../components/forms/formModal/FormModalContext'

interface IEditUserPasswordModalInfo {}

const EditUserPasswordModalInfo: React.FC<IEditUserPasswordModalInfo> = () => {
	const intlMsg = useFormatMessage()

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<DeboucedFormTextField
					name='oldPassword'
					label={intlMsg('misc.actualPassword')}
					type='password'
					isRequired
					context={FormModalContext}
				/>
			</Grid>
			<Grid item xs={12}>
				<DeboucedFormTextField
					name='newPassword'
					label={intlMsg('misc.newPassword')}
					type='password'
					isRequired
					context={FormModalContext}
				/>
			</Grid>
			<Grid item xs={12}>
				<DeboucedFormTextField
					name='newRetypedPassword'
					label={intlMsg('misc.confirmPassword')}
					type='password'
					isRequired
					context={FormModalContext}
				/>
			</Grid>
		</Grid>
	)
}

export default EditUserPasswordModalInfo
