import React, { useCallback, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import LoadingButton from '@mui/lab/LoadingButton'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import { Stack, Link, Checkbox } from '@mui/material'

import { useFormatMessage } from '../../utils/hooks'
import { InputText } from '../../components'
import { passwordValidationSchema } from '../../utils/validationSchema'
import api from '../../api/axios/axios'
import { ROUTES } from '../../routes/consts'

interface ISetPassword {
	confirmationToken
}

const SetPassword: React.FC<ISetPassword> = ({ confirmationToken }) => {
	const intlMsg = useFormatMessage()
	const navigate = useNavigate()
	const [message, setMessage] = useState('')
	const [isTermsChecked, setIsTermsChecked] = useState(false)
	const [loading, setLoading] = useState(false)
	const { handleSubmit, control } = useForm({
		resolver: yupResolver(passwordValidationSchema(intlMsg)),
	})

	const handleSetPassword = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		(data, e) => {
			setLoading(true)
			api
				.post('/_ctrl/users/confirm', {
					password: data.newPassword,
					retypedPassword: data.confirmPassword,
					confirmationToken,
				})
				.then(() => {
					setLoading(false)
					navigate(ROUTES.LOGIN.url)
				})
				.catch((error) => {
					setMessage(error.message)
					setLoading(false)
				})
		},
		[confirmationToken, navigate]
	)

	const handleCheck = useCallback(() => {
		setIsTermsChecked(!isTermsChecked)
	}, [isTermsChecked])

	return (
		<Stack justifyContent='center' p={2} sx={{ height: 1 }}>
			<Stack mb={4}>
				<Typography variant='h1'>{intlMsg('user.welcomeHubee')}</Typography>
				<Typography variant='body2'>{intlMsg('user.activeAccount')}</Typography>
			</Stack>

			<form onSubmit={handleSubmit((data, e) => handleSetPassword(data, e))}>
				<Stack spacing={2}>
					{message && (
						<Alert sx={{ width: 1, mb: 2 }} severity='error'>
							{message}
						</Alert>
					)}
					<InputText
						key='newPassword'
						name='newPassword'
						label={intlMsg('user.enterPassword')}
						type='password'
						control={control}
					/>
					<InputText
						key='confirmPassword'
						name='confirmPassword'
						label={intlMsg('user.confirmPassword')}
						type='password'
						control={control}
					/>

					<Stack direction='row' alignItems='center'>
						<Checkbox checked={isTermsChecked} onChange={handleCheck} />

						<Link
							href='/cgvu/CGVU-hubeeconnect.pdf'
							underline='always'
							target={'_blank'}
						>
							{intlMsg('userConfirmation.acceptCGU')}
						</Link>
					</Stack>

					<LoadingButton
						variant='contained'
						type='submit'
						disabled={!isTermsChecked}
						loading={loading}
					>
						{intlMsg('misc.validate')}
					</LoadingButton>
				</Stack>
			</form>
		</Stack>
	)
}

export default SetPassword
