export default {
	selectRows: 'Selected Rows',
	selectRow: 'Selected Row',
	displayCol: 'Display Columns',
	rowPerPage: 'Rows per Page',
	beforeImport:
		'To perform an import, use the downloadable Excel template file here:',
	modelExcel: 'Excel Template',
	importTitleSites: 'Import Sites',
	importTitleVehicle: 'Import Vehicles',
	alertImport:
		'Attention: Imports are done by account, only add the sites belonging to the selected account in your file below.',
	alertImportVehicle:
		'Imports are done by account and site. Only add the vehicles assigned to the selected site in your file.',
	downloadExcelTemplate: 'Download Template',
	chooseAccount: 'Choose Account',
	chooseSite: 'Choose Site',

	footerPagination1: 'Displaying',
	footerPagination2: 'to',
	footerPagination3: 'of',
	footerPagination4: 'rows',
}
