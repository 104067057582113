import React from 'react'

import Table from '../../../components/tableV2'
import {
	IOverrideConfigTable,
	IOverrideConfigTableWithArgument,
	IOverrideTableProvider,
} from '../../../utils/typescript/tableType'
import { customerVehicleConfigTable } from './customerVehicleConfigTable'

interface IListVehicles {
	othersQueryParams?
	overrideConfigTable?: IOverrideConfigTable | IOverrideConfigTableWithArgument
	overrideTableProvider?: IOverrideTableProvider
	isInViewModal?: boolean
}

const ListVehicles: React.FC<IListVehicles> = ({
	othersQueryParams,
	overrideConfigTable,
	overrideTableProvider,
	isInViewModal = false,
}) => {
	return (
		<Table
			tableConfig={customerVehicleConfigTable}
			overrideConfigTable={overrideConfigTable}
			othersQueryParams={othersQueryParams}
			overrideTableProvider={overrideTableProvider}
			isInViewModal={isInViewModal}
		/>
	)
}

export default ListVehicles
