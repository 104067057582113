import React, { useState, useCallback } from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

interface IToggleButtonsProps {
	text?: {
		primary: {
			text: string
			value: any
		}
		secondary: {
			text: string
			value: any
		}
	}
	setToggleStateValue: (value: string | null) => void
}

const ToggleButtons: React.FC<IToggleButtonsProps> = ({
	text,
	setToggleStateValue,
}) => {
	const [alignment, setAlignment] = useState<string | null>(
		text?.primary?.value
	)

	const handleAlignment = useCallback(
		(event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
			if (newAlignment !== null) {
				setAlignment(newAlignment)
				setToggleStateValue(newAlignment)
			}
		},
		[setToggleStateValue]
	)

	return (
		<ToggleButtonGroup
			exclusive
			onChange={handleAlignment}
			size='small'
			value={alignment}
			color='primary'
			fullWidth
		>
			<ToggleButton value={text?.primary?.value}>
				{text?.primary?.text}
			</ToggleButton>
			<ToggleButton value={text?.secondary?.value}>
				{text?.secondary?.text}
			</ToggleButton>
		</ToggleButtonGroup>
	)
}

export default ToggleButtons
