export default {
	HClist1Strong: 'Intervention Schedule ',
	HClist1: 'by intervenor',

	HClist2Strong: 'On-the-fly Intervention ',
	HClist2: 'creation',

	HClist3Strong: 'New Vehicle Creation ',
	HClist3: 'in the field',

	HClist4Strong: 'Status Alert Collection ',
	HClist4: 'from forms',

	HClist5Strong: 'Photo Capture with Circle and Annotation',

	HClist6Strong: 'Timestamped and Geolocated Interventions',

	downloadAndroid: 'Download the app on Android',
	downloadIos: 'Download the app on IOS',

	subscribe:
		'Subscribe to HubeeConnect Premium to benefit from the HubeeCheck mobile app and enjoy a 15-day free trial with no commitment! More information: ',
}
