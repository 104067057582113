export const interventionsListAttributes = `
paginationInfo {
  totalCount
}
collection {
  id
  isSharedWithMe
  createdByAccount {
    id
    name
  }
  agencies(itemsPerPage:100000) {
    collection {
      id
      agencyCustomDetails {
        id
        agencyName
      }
    }
  }
  reference
  interventionType
  dateCreated
  orderingCustomer {
    id
    name
  }
  orderingSite {
    id
    name
  }
  orderingContact {
    id
    firstName
    lastName
  }
  dateTo
  dateFrom
  manager {
    id
    lastName
    firstName
    account{
      id
    }
  }
  status {
    id
    name
    code
    color
    progression
  }
  actors(itemsPerPage: 100000) {
    collection {
      id
      lastName
      firstName
      account{
        id
      }
    }
  }
  alerts{
    paginationInfo{
      totalCount
    }
  }
  interventionControlProgrammedVehicles(itemsPerPage:100000) {
    paginationInfo {
      totalCount
    }
    collection {
      id
      status {
        id,
        code,
        name,
        color
      }
      products(itemsPerPage:100000) {
        collection {
          id
          isDone
          isMandatory
          product {
            id
            name
            productCategory {
              id
              name
              color
            }
          }
        }
      }
      vehicle {
        id
        vehicleDetail {
          id
          immat
          vin
          name
        }
      }
    }
  }

  globalProducts(itemsPerPage: 99999) {
    collection {
      id
      name
      isActive
      productCategory {
        id
        name
      }
    }
  }
}
`

export const interventionsListForCalendarAttributes = `
  paginationInfo {
    totalCount
  }
  collection {
    id
    reference
    interventionType
    interventionTransportType {
      id
      code
    }

    orderingCustomer {
      id
      name
    }
    orderingSite {
      id
      name
    }
    orderingContact {
      id
      firstName
      lastName
      
    }

    agencies(itemsPerPage:100000) {
      collection {
        id
        agencyCustomDetails {
          id
          agencyName
        }
      }
    }

    status {
      id
      code,
      name,
      color
    }
    dateFrom
    dateTo
    actors(itemsPerPage:100000) {
      collection {
        id
        lastName
        firstName
        account{
          id
        }
      }
    }

    interventionControlProgrammedVehicles(itemsPerPage:100000) {
      collection {
        id
        status {
          id,
          code,
          name,
          color
        }
        vehicle {
          id
          vehicleModelText
          vehicleBrand {
            id
            name
          }
          vehicleDetail {
            id
            immat
            vin
          }
        }

      }
    }


    interventionTransportTrips {
      id
      name
      address
      zipcode
      city
      country
      date
      timeFrom
      timeTo
      products {
        collection {
          id
          product {
            id
            name
          }
        }
      }
    }


  }
`

export const interventionForModalAttributes = `
  id
  isSharedWithMe
  createdByAccount {
    id
    name
  }
  interventionType
  orderingCustomer {
    id
    name
  }
  orderingSite {
    id
    name
  }
  orderingContact {
    id
    firstName
    lastName
  }
  isOrderEqualFinal
  status {
    id
    name
    color
    code
  }
  dateFrom
  dateTo
  manager {
    id
    firstName
    lastName
    account {
      id
    }
  }
  publicComment
  privateComment
  actors(itemsPerPage: 99999) {
    collection {
      id
      firstName
      lastName
      account {
        id
      }
    }
  }
  agencies(itemsPerPage: 99999) {
    collection {
      id
      name
      agencyCustomDetails {
        id
        agencyName
      }
    }
  }
  globalFormTemplates(itemsPerPage: 99999) {
    collection {
      id
      name
    }
  }
  globalProducts(itemsPerPage: 99999) {
    collection {
      id
      name
      isActive
      productCategory {
        id
        name
      }
    }
  }
  interventionControlFinalCustomer {
    id
    finalCustomer {
      id
      name
    }
    finalSite {
      id
      name
    }
    finalContact {
      id
      firstName
      lastName
    }
    finalAddressText
    finalContactText
  }
  interventionControlProgrammedVehicles(itemsPerPage: 99999) {
    collection {
      id
      status {
        id
        code
        name
        color
      }
      comment
      vehicle {
        id
        vehicleDetail {
          id
          immat
          vin
          vehicleBrand {
            id
            name
          }
          vehicleCategory{
            id
            name
          }
          vehicleModelText
        }
      }
      formTemplates(itemsPerPage: 99999) {
        collection {
          id
          formTemplate{
            id
            name
          }
          
        }
      }
      products(itemsPerPage: 99999) {
        collection {
            id
            createdByAccount {
              id
            }
            product {
              id
              name
            }
        }
      }
    }
  }
`

export const interventionForViewModalAttribute = `
    id
    reference
    isSharedWithMe
    interventionType
    publicComment
    privateComment
    orderingCustomer {
      id
      name
    }
    orderingSite {
      id
      name
      address
      city
      zipcode
    }
    orderingContact {
      id
      firstName
      lastName
      account {
        id
      }
    }
    status {
      id,
      code,
      name,
      color,
    }
    isOrderEqualFinal
    dateTo
    dateFrom
    manager {
      id
      firstName
      lastName
      account {
        id
      }
    }
    cancelReason{
        id
        code
    }
    cancelComment
    dateCreated
    actors(itemsPerPage: 99999) {
      collection {
        id
        firstName
        lastName
        account {
          id
          name
        }
      }
    }
    interventionControlFinalCustomer {
      id
      finalSite{id,name,address,city,zipcode}
      finalContact {id,firstName,lastName}
      finalCustomer{id,name}
      finalContactText
      finalAddressText
    }
    agencies(itemsPerPage: 99999) {
      collection {
        id
        name
        agencyCustomDetails {
          id
          agencyName
        }
      }
    }
`

export const listInterventionsByVehicleAttribute = `
collection {
  id

  forms(itemsPerPage: 100000) {
    collection {
      id
      formTemplate {
        id
        title
        originalFormTemplate {
          id
        }
      }
    }
  }

  formTemplates(itemsPerPage: 100000) {
    collection {
      id
      formTemplate{ 
        id
        title
      }     
    }
  }

  products(itemsPerPage: 100000) {
    collection {
      id
      product {
        id
        name
        productCategory {
          id
          name
          color
        }
      }
    }
  }
  completionDate
  completionUser {
    id
    firstName
    lastName
    account {
      id
    }
  }
  countAlerts
  intervention {
    id
    isSharedWithMe
    createdByAccount {
      id
      name
    }
    agencies(itemsPerPage: 100000) {
      collection {
        id
        name
        agencyCustomDetails {
          id
          agencyName
        }
      }
    }
    id
    reference
    dateCreated
    interventionType
    dateTo
    dateFrom
    createdByAccount {
      id
      name
    }
    manager {
      id
      firstName
      lastName
      account{
        id
      }
    }

    status {
      id
      code
      color
      name
    }
    orderingCustomer {
      id
      name
    }
    actors(itemsPerPage: 100000) {
      collection {
        id
        lastName
        firstName
        account{
          id
        }
      }
    }
    orderingSite {
      id
      name
      address
      city
      zipcode
    }
  }
  vehicle {
    id
    vehicleDetail {
      id
      immat
      vin
      vehicleModelText
      vehicleCategory {
        id
        name
      }
      vehicleBrand {
        id
        name
      }
    }
  }
  status {
    id
    code
    color
    name
  }

  comment

  vehicleMileage {
    id
    mileage
  }
  
}
paginationInfo {
  totalCount
}
`
