import React, { useMemo } from 'react'
import { Tooltip, Typography } from '@mui/material'

interface ITableSideNavItemTitle {
	title: string
	maxCharacters?: number
	isSelected?: boolean
}

const TableSideNavItemTitle: React.FC<ITableSideNavItemTitle> = ({
	title,
	maxCharacters = 15,
	isSelected,
}) => {
	const TypoCSS = useMemo(
		() => ({
			textTransform: 'uppercase',
			fontWeight: 500,
			color: isSelected ? '#2e5dd1' : '#666',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			marginRight: '5px',
		}),
		[isSelected]
	)

	const renderTitle = useMemo(() => {
		if (title?.length >= maxCharacters) {
			return (
				<Tooltip title={title}>
					<Typography sx={TypoCSS}>{title || '-'}</Typography>
				</Tooltip>
			)
		}

		return <Typography sx={TypoCSS}>{title || '-'}</Typography>
	}, [title, maxCharacters, TypoCSS])

	return renderTitle
}

export default TableSideNavItemTitle
