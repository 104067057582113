import React, {
	cloneElement,
	memo,
	useCallback,
	useContext,
	useState,
} from 'react'

import MuiModal from '@mui/material/Modal'
import Backdrop from '@mui/material/Backdrop'
import Fade from '@mui/material/Fade'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { Box, Divider, Stack, IconButton } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'

import { useFormatMessage } from '../../../utils/hooks'
import ModalPanel from './ModalPanel'
import { ModalContext } from '../../../utils/contexts/ModalContext'
import LogoConnectIcon from '../../../svgIcons/LogoConnectIcon'
import { footerModalCSS, modalCSS } from '../modalSxCss'

const wrapperModalCSS = {
	display: 'flex',
	flexDirection: 'column',
	maxHeight: '60vh',
	overflow: 'auto',
	width: 1,
	p: 4,
}

interface IModal {
	isVisible: boolean
	closeModal: () => void
	modalName: string
	customModalName?: boolean
	validateModal
	modalContentConfig?
	children?
	maxWidth?: number
	minHeight?: string
	width?: string
	greyBg?
	loading?
	formModalType?
	otherFooterBtn?:
		| { action?: any; switchPanelIndex?: number; text: string }
		| any
}

const Modal: React.FC<IModal> = ({
	isVisible,
	closeModal,
	modalName,
	customModalName,
	validateModal,
	modalContentConfig,
	children,
	maxWidth,
	minHeight,
	width,
	greyBg = false,
	loading = false,
	formModalType = null,
	otherFooterBtn,
	...rest
}) => {
	const intlMsg = useFormatMessage()
	const [selectedFormModalType, setSelectedFormModalType] = useState<any>(null)
	const [selectedPanel, setSelectedPanel] = useState(0)
	const [switchPanel, setSwitchPanel] = useState<any>(-1)

	const onError = (errors) => console.log('ERROR ON SUBMIT', errors)

	const {
		loadingModal,
		canValidate,
		formUtils: { handleSubmit, trigger },
	} = useContext(ModalContext)

	const handleClose = useCallback(() => {
		closeModal()
		setSelectedPanel(0)
	}, [closeModal])

	const handleCloseModal = useCallback(
		(e, reason) => {
			if (reason !== 'backdropClick') handleClose()
		},
		[closeModal]
	)

	const validateAndResetPanel = useCallback(
		async (data) => {
			validateModal(data, selectedFormModalType).then(() => {
				if (formModalType) {
					if (selectedFormModalType === formModalType) setSelectedPanel(0)
				} else setSelectedPanel(0)
			})
		},
		[validateModal, selectedFormModalType]
	)

	const handleSubmitBtnClick = useCallback(() => {
		setSelectedFormModalType(formModalType)
	}, [formModalType])

	const handleOtherFooterBtn = useCallback(() => {
		if (otherFooterBtn?.action) otherFooterBtn.action()
		if (otherFooterBtn?.switchPanelIndex) {
			trigger(modalContentConfig?.currentFields)
			setTimeout(() => {
				setSwitchPanel(otherFooterBtn?.switchPanelIndex)
			}, 200)
		}
	}, [otherFooterBtn])

	return (
		<MuiModal
			open={isVisible}
			onClose={handleCloseModal}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={isVisible}>
				<Box
					sx={{
						...modalCSS,
						maxWidth: {
							xs: '90%',
							md: `${maxWidth ? maxWidth + 'px' : '100%'}`,
						},
						width: { xs: '90%', md: `${width || '50%'}` },
					}}
				>
					<Stack
						direction='row'
						justifyContent='space-between'
						alignItems='center'
						sx={{ width: '100%', p: 3 }}
					>
						<Typography variant='h2'>
							{customModalName ? modalName : intlMsg(modalName)}
						</Typography>
						<IconButton
							aria-label='close'
							size='medium'
							sx={{ position: 'absolute', right: ({ spacing }) => spacing(2) }}
							onClick={handleClose}
						>
							<CloseIcon fontSize='inherit' />
						</IconButton>
					</Stack>

					{!modalContentConfig ? (
						<Divider variant='fullWidth' flexItem />
					) : (
						<ModalPanel
							modalContentConfig={modalContentConfig}
							setSelectedPanel={setSelectedPanel}
							selectedPanel={selectedPanel}
							setSwitchPanel={setSwitchPanel}
							switchPanel={switchPanel}
						/>
					)}

					<Box
						sx={{ backgroundColor: greyBg ? '#f9f9f9' : 'inherit', width: 1 }}
					>
						<form
							onSubmit={handleSubmit(
								(data) => validateAndResetPanel(data),
								onError
							)}
						>
							<Box
								sx={{
									...wrapperModalCSS,
									minHeight: minHeight ? minHeight : '60vh',
								}}
							>
								{loading ? (
									<Box sx={{ h: '72vh' }}>
										<LogoConnectIcon height='100%' />
									</Box>
								) : (
									<>
										{(children ||
											modalContentConfig[selectedPanel]?.component) &&
											cloneElement(
												children ||
													modalContentConfig[selectedPanel]?.component,
												{ ...rest }
											)}
									</>
								)}
							</Box>
							<Stack
								direction='row'
								justifyContent='flex-end'
								spacing={2}
								sx={footerModalCSS}
							>
								{otherFooterBtn?.switchPanelIndex ? (
									otherFooterBtn?.switchPanelIndex !== selectedPanel ? (
										<Button
											variant='outlined'
											color='primary'
											onClick={handleOtherFooterBtn}
										>
											{otherFooterBtn?.text}
										</Button>
									) : (
										<></>
									)
								) : (
									otherFooterBtn && (
										<Button
											variant='outlined'
											color='primary'
											onClick={handleOtherFooterBtn}
										>
											{otherFooterBtn?.text}
										</Button>
									)
								)}
								<Stack direction='row' spacing={1}>
									<Button
										variant='outlined'
										color='primary'
										onClick={handleClose}
										sx={{ mr: 1 }}
									>
										{intlMsg('misc.cancel')}
									</Button>

									<LoadingButton
										id={`Playwright-FormModal-ValidateBtn-${modalName}`}
										disabled={!canValidate}
										variant='contained'
										type='submit'
										loading={loadingModal || false}
										onClick={handleSubmitBtnClick}
									>
										{intlMsg('misc.validate')}
									</LoadingButton>
								</Stack>
							</Stack>
						</form>
					</Box>
				</Box>
			</Fade>
		</MuiModal>
	)
}

export default memo(Modal)
