import React, { useState, useEffect, useMemo, useCallback, memo } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { Paper, Stack, Box, Fade, Typography } from '@mui/material'
import { useAlertWidget } from '../../../api/gql/queries/alertsQueries'
import { useFormatMessage } from '../../../utils/hooks'
import { stringifiedColor } from '../../../utils/color'
import { formatGeneric } from '../../../utils/string'
import { optionsDoughnut } from './optionsDoughnut'
import LabelDoughnut from './LabelDoughnut'
import CircularProgressWidget from '../elements/CircularProgressWidget'

ChartJS.register(ArcElement, Tooltip, Legend)

interface IWAlertsDetectedCategory {
	filterQueries
}

const WAlertsDetectedCategory: React.FC<IWAlertsDetectedCategory> = ({
	filterQueries = {},
}) => {
	const intlMsg = useFormatMessage()
	const { data: alerts, loading } = useAlertWidget({
		variables: {
			...filterQueries,
			startFrom: filterQueries.dateFrom?.[0].after,
			endTo: filterQueries.dateFrom?.[0].before,
		},
	})

	const [arrayColorCategoryAlert, setArrayColorCategoryAlert] = useState<any>()
	const [arrayCategoryCountAlert, setArrayCategoryCountAlert] = useState<any>()
	const [arrayCategoryNameAlert, setArrayCategoryNameAlert] = useState<any>()

	const formattedAlerts: any = useMemo(() => {
		if (alerts) {
			return formatGeneric(alerts.alerts.collection)
		}
		return []
	}, [alerts])

	useEffect(() => {
		if (!loading) {
			const alertTreatedByCategory = {}
			const colorCategory = {}

			formattedAlerts?.forEach(({ category: { name, color } }) => {
				alertTreatedByCategory[name] = (alertTreatedByCategory[name] || 0) + 1
				colorCategory[name] = stringifiedColor(color, 400)
			})

			setArrayColorCategoryAlert(Object.values(colorCategory))
			setArrayCategoryCountAlert(Object.values(alertTreatedByCategory))
			setArrayCategoryNameAlert(Object.keys(alertTreatedByCategory))
		}
	}, [loading, formattedAlerts])

	const totalAlerts = useMemo(
		() => alerts?.alerts?.collection?.length || 0,
		[alerts]
	)

	const doughnutData: any = useMemo(
		() => ({
			datasets: [
				{
					data: arrayCategoryCountAlert?.length ? arrayCategoryCountAlert : [1],
					backgroundColor: arrayColorCategoryAlert?.length
						? arrayColorCategoryAlert
						: ['#f4f4f4'],
					borderColor: arrayColorCategoryAlert?.length
						? arrayColorCategoryAlert
						: ['#f4f4f4'],
					borderWidth: 0,
					weight: 0.5,
					hoverOffset: 8,
					borderAlign: 'inner',
				},
			],
		}),
		[arrayCategoryCountAlert, arrayColorCategoryAlert]
	)

	const doughnutOption = useMemo(
		() => ({
			...optionsDoughnut,
			...(arrayCategoryCountAlert?.length === 0 && {
				events: [],
			}), // disabled hover if 0 alerts
		}),
		[arrayCategoryCountAlert]
	)

	const renderArrayCategoryCountAlert = useCallback(
		(countAlert, i) => (
			<LabelDoughnut
				key={i}
				countAlert={countAlert}
				colorCategoryAlert={arrayColorCategoryAlert[i]}
				categoryNameAlert={arrayCategoryNameAlert[i]}
				totalAlert={totalAlerts}
			/>
		),
		[arrayColorCategoryAlert, totalAlerts]
	)

	return (
		<Paper elevation={1} sx={{ height: 1 }} id='widget'>
			<Stack
				p={4}
				sx={{ height: 1 }}
				justifyContent='center'
				alignItems='center'
			>
				<Fade in={!loading}>
					<Box sx={{ width: 1 }}>
						<Stack
							direction='row'
							spacing={1}
							sx={{ position: 'relative', left: '-24px', top: '0px' }}
						>
							<Stack sx={{ height: 130, width: 200 }}>
								<Doughnut
									height={130}
									width={200}
									data={doughnutData}
									options={doughnutOption}
								/>
							</Stack>

							<Stack p={2} justifyContent='center'>
								<Stack direction='row' spacing={0}>
									<Typography
										variant='h4'
										sx={{ fontSize: '0.9rem!important', mb: 0 }}
									>
										{intlMsg('widget.alertsDetectedCategory')}
									</Typography>
								</Stack>

								{arrayCategoryCountAlert?.map(renderArrayCategoryCountAlert)}

								{arrayCategoryCountAlert?.length === 0 && (
									<LabelDoughnut
										colorCategoryAlert={'#f4f4f4'}
										categoryNameAlert={intlMsg('widget.zeroAlert')}
									/>
								)}
							</Stack>
						</Stack>
					</Box>
				</Fade>

				{loading && <CircularProgressWidget />}
			</Stack>
		</Paper>
	)
}

export default memo(WAlertsDetectedCategory)
