import React, { useEffect } from 'react'

import { useLazyQuery } from '@apollo/client'
import { Stack, LinearProgress } from '@mui/material'

import { useFormatMessage } from '../../../../../utils/hooks'
import DropZone from '../../../../forms/v1/formFields/DropZone'
import { GET_CUSTOMERS_LIST_FOR_SELECT } from '../../../../../api/gql/queries/customersQueries'
import TableImportSubmit from '../TableImportSubmit'
import TableImportTitleStep from '../TableImportTitleStep'
import SelectSearch from '../../../../forms/v1/formFields/SelectSearch'

const LinearProgressCSS = { position: 'absolute', width: 1, top: 0, right: 0 }

interface ITableSiteImportForm {
	handleClose
	loading
	watchFields
	formUtils
}

const TableSiteImportForm: React.FC<ITableSiteImportForm> = ({
	handleClose,
	loading,
	watchFields,
	formUtils: { errors, watch, setValue },
}) => {
	const intlMsg = useFormatMessage()
	const [customer, importFile] = watchFields

	const [getCustomers, { data: dataCustomers, loading: loadingCustomers }] =
		useLazyQuery(GET_CUSTOMERS_LIST_FOR_SELECT)

	useEffect(() => {
		getCustomers()
	}, [])

	/////////////////////////////////////////////////////////////////////////////

	return (
		<>
			{loadingCustomers && <LinearProgress sx={LinearProgressCSS} />}

			<Stack p={4} spacing={4}>
				<TableImportTitleStep
					stepNumber={1}
					disabled={!customer}
					title={'table.chooseAccount'}
					tooltipsTxt={'table.alertImport'}
				/>

				<SelectSearch
					name={'customer'}
					label={intlMsg('misc.account')}
					selectElements={
						dataCustomers?.customers?.collection?.map((customer) => ({
							label: customer?.name,
							key: customer?.id,
							value: customer?.id,
						})) || []
					}
					formUtils={{ errors, watch, setValue }}
				/>

				<TableImportTitleStep
					stepNumber={2}
					disabled={!importFile}
					title={'table.importTitleSites'}
				/>

				<DropZone
					name='importFile'
					dropzoneText={intlMsg('misc.uploadFile')}
					dropzoneTextError={intlMsg('errors.errorTypeFilesCSV')}
					accept={{
						'text/*': ['.csv'],
						'application/vnd.ms-excel': ['.xls'],
						'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
							['.xlsx'],
					}}
					setValue={setValue}
				/>

				<TableImportSubmit
					handleClose={handleClose}
					loading={loading}
					disabled={!customer || !importFile?.length}
				/>
			</Stack>
		</>
	)
}

export default TableSiteImportForm
