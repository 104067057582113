import {
	gql,
	OperationVariables,
	QueryHookOptions,
	useLazyQuery,
	useQuery,
} from '@apollo/client'
import {
	interventionsListAttributes,
	interventionForViewModalAttribute,
	listInterventionsByVehicleAttribute,
	interventionsListForCalendarAttributes,
	interventionForModalAttributes,
} from '../attributes/interventionsAttributes'

/**  ---------------------QUERIES---------------------- */

export const LIST_INTERVENTIONS = gql`
	query interventionsList(
		$page: Int
		$itemsPerPage: Int
		$searchText: String
		$customer: String
		$customer_list: Iterable
		$manager: String
		$manager_list: [String]
		$status: String
		$status_list: [String]
		$isDeleted: Boolean
		$dateFrom: [InterventionFilter_dateFrom]
		$interventionType: String
		$site: String
		$startFrom: String,
		$endTo: String,
		$actors_list: [String]
		$isWithoutActor: Boolean
		$agency: String
		$agency_list: Iterable
		$orderBy: [InterventionFilter_orderBy]
	) {
		interventions(
			page: $page
			itemsPerPage: $itemsPerPage
			searchText: $searchText
			customer: $customer
			customer_list: $customer_list
			manager: $manager
			manager_list: $manager_list
			status: $status
			status_list: $status_list
			isDeleted: $isDeleted
			dateFrom: $dateFrom
			interventionType: $interventionType
			interventionType_list:["control","service"]
			site: $site
			startFrom: $startFrom,
			endTo: $endTo,
			actors_list: $actors_list,
			isWithoutActor: $isWithoutActor,
			agency: $agency
			agency_list: $agency_list
			orderBy: $orderBy
		) {
			${interventionsListAttributes}
		}
	}
`

export const INTERVENTIONS_COUNT = gql`
	query getInterventionNumber(
		$agency: String
		$customer: String
		$site: String
		$searchText: String
		$startFrom: String
		$endTo: String
		$interventionType: String
		$dateFrom: [InterventionFilter_dateFrom]
		$status: String
	) {
		interventions(
			page: 1
			itemsPerPage: 100000
			agency: $agency
			customer: $customer
			site: $site
			searchText: $searchText
			startFrom: $startFrom
			endTo: $endTo
			dateFrom: $dateFrom
			interventionType: $interventionType
			interventionType_list: ["control", "service"]
			status: $status
		) {
			paginationInfo {
				totalCount
			}
		}
	}
`

export const INTERVENTIONS_LIST_FOR_CALENDAR = gql`
query interventionsListForCalendar(
	$page: Int
	$itemsPerPage: Int
	$searchText: String
	$customer: String
	$customer_list: Iterable
	$manager: String
	$manager_list: [String]
	$status: String
	$status_list: [String]
	$isDeleted: Boolean
	$dateFrom: [InterventionFilter_dateFrom]
	$interventionType: String
	$site: String
	$startFrom: String,
	$endTo: String,
	$actors_list: [String]
	$isWithoutActor: Boolean
	$agency: String
	$agency_list: Iterable
) {
	interventions(
		page: $page
		itemsPerPage: $itemsPerPage
		searchText: $searchText
		customer: $customer
		customer_list: $customer_list
		manager: $manager
		manager_list: $manager_list
		status: $status
		status_list: $status_list
		isDeleted: $isDeleted
		dateFrom: $dateFrom
		interventionType: $interventionType
		site: $site
		startFrom: $startFrom,
		endTo: $endTo,
		actors_list: $actors_list,
		isWithoutActor: $isWithoutActor,
		agency: $agency
		agency_list: $agency_list
	) {
		${interventionsListForCalendarAttributes}
	}
}
`

export const INTERVENTION_FOR_MODAL = gql`
	query interventionForModal($id: ID!) {
		intervention(id: $id) {
			${interventionForModalAttributes}
		}
	}
`

export const INTERVENTION_STATUSES = gql`
	query getInterventionStatuses {
		interventionStatuses {
			id
			name
			color
			code
		}
	}
`

export const INTERVENTION_STATUSES_LIST = gql`
	query interventionStatusesList {
		interventionStatuses {
			id
			name
			code
			color
		}
	}
`

export const INTERVENTION_COUNT_TYPE = gql`
	query interventionsCountType {
		interventions {
			collection {
				id
				interventionControlProgrammedVehicles {
					paginationInfo {
						totalCount
					}
				}
			}
		}
	}
`

export const GET_INTERVENTION_FOR_VIEW_MODAL = gql`
query getInterventionForViewModal(
    $id: ID!
) {
    intervention(id: $id) {
        ${interventionForViewModalAttribute}
    }
}`

export const LIST_INTERVENTIONS_BY_VEHICLE = gql`
	query interventionProgrammedVehicles(
		$page: Int
		$itemsPerPage: Int
		$intervention_status: String
		$intervention_status_list: [String]
		$intervention_actors_list: [String]
		$searchText: String
		$intervention_interventionType: String
		$vehicle: String
		$isWithoutActor: Boolean
		$customer_list: Iterable
		$intervention: String
		$intervention_dateFrom: [InterventionProgrammedVehicleFilter_intervention_dateFrom]
		$intervention_dateTo: [InterventionProgrammedVehicleFilter_intervention_dateTo]
		$startFrom: String,
		$endTo: String,
		$orderBy: [InterventionProgrammedVehicleFilter_orderBy]
	) {
		interventionProgrammedVehicles(
			page: $page
			itemsPerPage: $itemsPerPage
			intervention_status: $intervention_status
			intervention_status_list: $intervention_status_list
			intervention_actors_list: $intervention_actors_list
			searchText: $searchText
			intervention_interventionType: $intervention_interventionType
			intervention_interventionType_list:["control","service"]
			vehicle: $vehicle
			isWithoutActor: $isWithoutActor
			customer_list: $customer_list
			intervention: $intervention
			intervention_dateFrom: $intervention_dateFrom
			intervention_dateTo: $intervention_dateTo,
			startFrom: $startFrom,
			endTo: $endTo,
			orderBy: $orderBy
		) {
			${listInterventionsByVehicleAttribute}
		}
	}
`

export const STATUS_VEHICLES = gql`
	query getStatusVehicles($intervention: String) {
		interventionProgrammedVehicles(
			page: 1
			itemsPerPage: 100000
			intervention: $intervention
		) {
			collection {
				id
				status {
					id
					code
					color
					name
				}
				comment
				vehicle {
					id
				}
			}
		}
	}
`

export const INTERVENTION_COUNT_BY_CUSTOMER = gql`
	query getInterventionNumbersByCustomer($customer: String) {
		interventions(page: 1, itemsPerPage: 1, customer: $customer) {
			paginationInfo {
				totalCount
			}
		}
	}
`

export const INTERVENTION_COUNT_BY_AGENCY = gql`
	query getInterventionNumbersByAgency($agency: String) {
		interventions(page: 1, itemsPerPage: 1, agency: $agency) {
			paginationInfo {
				totalCount
			}
		}
	}
`

export const INTERVENTION_COUNT_BY_SITE = gql`
	query getInterventionNumbersBySite($site: String) {
		interventions(page: 1, itemsPerPage: 1, site: $site) {
			paginationInfo {
				totalCount
			}
		}
	}
`

export const INTERVENTION_COUNT = gql`
	query getInterventionNumbers(
		$intervention: String
		$customer: String
		$site: String
		$vehicle: String
		$intervention_dateFrom: [InterventionProgrammedVehicleFilter_intervention_dateFrom]
		$intervention_dateTo: [InterventionProgrammedVehicleFilter_intervention_dateTo]
		$intervention_status: String
		$searchText: String
		$intervention_interventionType: String
	) {
		interventionProgrammedVehicles(
			page: 1
			itemsPerPage: 1
			intervention: $intervention
			customer: $customer
			site: $site
			vehicle: $vehicle
			intervention_dateFrom: $intervention_dateFrom
			intervention_dateTo: $intervention_dateTo
			intervention_status: $intervention_status
			searchText: $searchText
			intervention_interventionType: $intervention_interventionType
			intervention_interventionType_list: ["control", "service"]
		) {
			paginationInfo {
				totalCount
			}
		}
	}
`

export const INTERVENTION_COUNT_BY_VEHICLE = gql`
	query getInterventionNumbersByVehicle($vehicle: String) {
		interventionProgrammedVehicles(
			page: 1
			itemsPerPage: 1
			vehicle: $vehicle
			intervention_interventionType_list: ["control", "service"]
		) {
			paginationInfo {
				totalCount
			}
		}
	}
`

export const INTERVENTION_MODULES = gql`
	query getInterventionModules {
		interventionModules {
			id
			name
			title
		}
	}
`

export const WINTERVENTIONS_DONE = gql`
	query getInterventionsTotalDone(
		$itemsPerPage: Int
		$dateFrom: [InterventionFilter_dateFrom]
		$page: Int
	) {
		interventions(
			itemsPerPage: $itemsPerPage
			dateFrom: $dateFrom
			page: $page
		) {
			collection {
				id
				status {
					id
					code
				}
			}
			paginationInfo {
				totalCount
			}
		}
	}
`
export const WINTERVENTIONS_NOT_ASSIGNED = gql`
	query getInterventionsTotalNotAssigned(
		$itemsPerPage: Int
		$dateFrom: [InterventionFilter_dateFrom]
		$page: Int
	) {
		interventions(
			itemsPerPage: $itemsPerPage
			dateFrom: $dateFrom
			page: $page
		) {
			collection {
				id
				actors {
					paginationInfo {
						totalCount
					}
				}
			}
		}
	}
`

export const INTERVENTION_INVOICE_LIST = gql`
	query getInterventionsInvoiceList($searchText: String) {
		interventions(searchText: $searchText, page: 1, itemsPerPage: 100000) {
			collection {
				id
				reference
				dateFrom
			}
		}
	}
`

export const INTERVENTION_IPV_INVOICE_LIST = gql`
	query getInterventionsIpvInvoiceList($vehicle: String) {
		interventionProgrammedVehicles(
			vehicle: $vehicle
			page: 1
			itemsPerPage: 100000
		) {
			collection {
				id
				intervention {
					id
					reference
					dateFrom
				}
			}
		}
	}
`

export const INTERVENTION_INVOICE = gql`
	query getInterventionInvoice($id: ID!) {
		intervention(id: $id) {
			id
			reference
			dateFrom
		}
	}
`

export const EXPORT_INTERVENTION = gql`
	query exportInterventions(
		$searchText: String
		$customer: String
		$customer_list: Iterable
		$manager: String
		$manager_list: [String]
		$status: String
		$status_list: [String]
		$isDeleted: Boolean
		$dateFrom: [InterventionFilter_dateFrom]
		$site: String
		$startFrom: String
		$endTo: String
		$actors_list: [String]
		$isWithoutActor: Boolean
		$agency: String
		$timezone: Int
		$orderBy: [InterventionFilter_orderBy]
	) {
		exportInterventions(
			searchText: $searchText
			customer: $customer
			customer_list: $customer_list
			manager: $manager
			manager_list: $manager_list
			status: $status
			status_list: $status_list
			isDeleted: $isDeleted
			dateFrom: $dateFrom
			interventionType_list: ["control", "service"]
			site: $site
			startFrom: $startFrom
			endTo: $endTo
			actors_list: $actors_list
			isWithoutActor: $isWithoutActor
			agency: $agency
			timezone: $timezone
			orderBy: $orderBy
		) {
			id
			exportFile
		}
	}
`

export const EXPORT_IPV = gql`
	query exportInterventionProgrammedVehicles(
		$intervention_status: String
		$intervention_status_list: [String]
		$searchText: String
		$intervention_interventionType: String
		$vehicle: String
		$intervention: String
		$intervention_dateFrom: [InterventionProgrammedVehicleFilter_intervention_dateFrom]
		$intervention_dateTo: [InterventionProgrammedVehicleFilter_intervention_dateTo]
		$isWithoutActor: Boolean
		$intervention_actors_list: [String]
		$customer_list: Iterable
		$startFrom: String
		$endTo: String
		$timezone: Int
		$orderBy: [InterventionProgrammedVehicleFilter_orderBy]
	) {
		exportInterventionProgrammedVehicles(
			intervention_status: $intervention_status
			intervention_status_list: $intervention_status_list
			searchText: $searchText
			intervention_interventionType: $intervention_interventionType
			intervention_interventionType_list: ["control", "service"]
			vehicle: $vehicle
			intervention: $intervention
			intervention_dateFrom: $intervention_dateFrom
			intervention_dateTo: $intervention_dateTo
			isWithoutActor: $isWithoutActor
			intervention_actors_list: $intervention_actors_list
			customer_list: $customer_list
			startFrom: $startFrom
			endTo: $endTo
			timezone: $timezone
			orderBy: $orderBy
		) {
			id
			exportFile
		}
	}
`

/**  ---------------------HOOKS---------------------- */ /////////////////////////////////////////////////

export const useLazyInterventionsListForCalendar: any = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(
		INTERVENTIONS_LIST_FOR_CALENDAR,
		options
	)
	return {
		loading,
		interventions: data?.interventions?.collection || null,
		getInterventions: getData,
	}
}

export const useLazyInterventionForModal: any = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(
		INTERVENTION_FOR_MODAL,
		options
	)

	return {
		loading,
		intervention: data?.intervention || null,
		getIntervention: getData,
	}
}

export const useInterventionStatuses: any = () => {
	const { loading, data } = useQuery(INTERVENTION_STATUSES)

	return {
		loading,
		interventionStatus: data?.interventionStatuses || null,
	}
}

export const useInterventionStatusesList: any = () => {
	const { loading, data } = useQuery(INTERVENTION_STATUSES_LIST)
	return {
		loading,
		data: data?.interventionStatuses || null,
	}
}

export const useCountTypeInterventions: any = () => {
	const { loading, data } = useQuery(INTERVENTION_COUNT_TYPE)

	return {
		loading,
		data: {
			countTypeControle: data?.interventions?.collection?.length,
			countTypeTransport: 0, // TODO add another count (transport,)
			countTypeLogistics: 0, // TODO add another count (logistics)
			countTypeMecanic: 0, // TODO add another count (mecanic)
		},
	}
}

export const useWTotalInterventionsDone: any = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { loading, data } = useQuery(WINTERVENTIONS_DONE, options)

	return {
		loading,
		totalInterventions: data?.interventions?.paginationInfo?.totalCount || 0,
		status: data?.interventions?.collection || [],
	}
}
export const useWTotalInterventionsNotAssigned: any = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { loading, data } = useQuery(WINTERVENTIONS_NOT_ASSIGNED, options)

	return {
		loading,
		notAssigned: data?.interventions?.collection || [],
	}
}

export const useLazyInterventionsIPVInvoiceList = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(
		INTERVENTION_IPV_INVOICE_LIST,
		options
	)
	return {
		loadingInterventionsIPV: loading,
		interventionsIPV: data?.interventionProgrammedVehicles?.collection || [],
		getInterventionsIPV: getData,
	}
}

export const useLazyInterventionsInvoiceList = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(
		INTERVENTION_INVOICE_LIST,
		options
	)
	return {
		loadingInterventions: loading,
		interventions: data?.interventions?.collection || [],
		getInterventions: getData,
	}
}

export const useInterventionInvoice = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(
		INTERVENTION_INVOICE,
		options
	)

	return {
		loadingIntervention: loading,
		intervention: data?.intervention || null,
		getIntervention: getData,
	}
}

export const useLazyInterventionStatusVehicles = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(STATUS_VEHICLES, options)

	return {
		loadingStatus: loading,
		statusVehicles: data?.interventionProgrammedVehicles?.collection || null,
		getVehiclesStatus: getData,
	}
}

export const useLazyInterventionStatus = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(
		INTERVENTION_STATUSES,
		options
	)

	return {
		loading,
		status: data?.interventionStatuses || null,
		getStatus: getData,
	}
}

export const useExportIntervention: any = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { data, loading, error } = useQuery(EXPORT_INTERVENTION, options)

	return {
		exportFile: data?.exportInterventions?.[0]?.exportFile || null,
		loading,
		error,
	}
}

export const useExportIpv: any = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { data, loading, error } = useQuery(EXPORT_IPV, options)

	return {
		exportFile:
			data?.exportInterventionProgrammedVehicles?.[0]?.exportFile || null,
		loading,
		error,
	}
}
