/* FormSelectSearchAdorment.jsx
This component renders a text field input with enhanced features for the FormModalSelectSearch component. It supports features like prefixing input, displaying loading indicators, chips for selected options, and color-coded status indicators based on selected values. It’s designed to be used as part of a search-select functionality within modals, accommodating dynamic content with custom visual feedback for selection and loading states. Validation and accessibility are also integrated, with options for required fields and disabled states. */

import React, { memo } from 'react'

import Chip from '@mui/material/Chip'

import { stringifiedColor } from '../../../../utils/color'

const chipSelectedOptionCSS = {
	height: '23px',
	backgroundColor: '#64B6F7',
	color: 'white',
}
const indicatorColorStatuts = {
	height: '11px !important',
	width: '11px !important',
	borderRadius: '4px',
}

interface IFormSelectSearchAdorment {
	selectedOption: any
	params: any
	displayChip?: boolean
	displayColorInput?: boolean
}

const FormSelectSearchAdorment: React.FC<IFormSelectSearchAdorment> = ({
	selectedOption,
	params,
	displayChip,
	displayColorInput,
}) => {
	//  handle the chip display logic and prefix attachment.
	return (
		<>
			{displayChip ? (
				<Chip
					sx={{
						...chipSelectedOptionCSS,
						backgroundColor: stringifiedColor(
							selectedOption.value.color,
							500
						) as any,
					}}
					label={selectedOption.label}
				/>
			) : (
				displayColorInput && (
					<Chip
						sx={{
							...indicatorColorStatuts,
							backgroundColor: stringifiedColor(
								selectedOption.value.color,
								500
							) as any,
						}}
					/>
				)
			)}

			{params.InputProps.startAdornment}
		</>
	)
}

export default memo(FormSelectSearchAdorment)
