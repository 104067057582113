import dayjs from 'dayjs'
import { EPeriodFilter } from '../../../../utils/app-models'

export const filterDateConfig = [
	{
		period: EPeriodFilter.allPeriod,
		date: {
			dateFrom: null,
		},
	},
	{
		period: EPeriodFilter.yesterday,
		date: {
			dateFrom: [
				{
					before: dayjs().subtract(1, 'day').endOf('day').toDate(),
					after: dayjs().subtract(1, 'day').startOf('day').toDate(),
				},
			],
		},
	},
	{
		period: EPeriodFilter.today,
		date: {
			dateFrom: [
				{
					before: dayjs().endOf('day').toDate(),
					after: dayjs().startOf('day').toDate(),
				},
			],
		},
	},
	{
		period: EPeriodFilter.tomorrow,
		date: {
			dateFrom: [
				{
					before: dayjs().add(1, 'day').endOf('day').toDate(),
					after: dayjs().add(1, 'day').startOf('day').toDate(),
				},
			],
		},
	},
	{
		period: EPeriodFilter.lastWeek,
		date: {
			dateFrom: [
				{
					before: dayjs().subtract(7, 'day').endOf('week').toDate(),
					after: dayjs().subtract(7, 'day').startOf('week').toDate(),
				},
			],
		},
	},
	{
		period: EPeriodFilter.thisWeek,
		date: {
			dateFrom: [
				{
					before: dayjs().endOf('week').toDate(),
					after: dayjs().startOf('week').toDate(),
				},
			],
		},
	},
	{
		period: EPeriodFilter.nextWeek,
		date: {
			dateFrom: [
				{
					before: dayjs().add(7, 'day').endOf('week').toDate(),
					after: dayjs().add(7, 'day').startOf('week').toDate(),
				},
			],
		},
	},
	{
		period: EPeriodFilter.lastMonth,
		date: {
			dateFrom: [
				{
					before: dayjs().subtract(1, 'month').endOf('month').toDate(),
					after: dayjs().subtract(1, 'month').startOf('month').toDate(),
				},
			],
		},
	},
	{
		period: EPeriodFilter.thisMonth,
		date: {
			dateFrom: [
				{
					before: dayjs().endOf('month').toDate(),
					after: dayjs().startOf('month').toDate(),
				},
			],
		},
	},
	{
		period: EPeriodFilter.nextMonth,
		date: {
			dateFrom: [
				{
					before: dayjs().add(1, 'month').endOf('month').toDate(),
					after: dayjs().add(1, 'month').startOf('month').toDate(),
				},
			],
		},
	},
	{ period: EPeriodFilter.customPeriod },
]

export const filerPeriodType = {
	day: [EPeriodFilter.today, EPeriodFilter.tomorrow, EPeriodFilter.yesterday],
	week: [
		EPeriodFilter.lastWeek,
		EPeriodFilter.thisWeek,
		EPeriodFilter.nextWeek,
	],
	month: [
		EPeriodFilter.thisMonth,
		EPeriodFilter.lastMonth,
		EPeriodFilter.nextMonth,
	],
}
