import React from 'react'

import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import { Grid, Paper, Box } from '@mui/material'

import { listCardViewModalCSS as css } from '../../../../styles/css/viewModal'
import { useFormatMessage } from '../../../../utils/hooks'
import { ListItemsData } from '../../../../components'
import { formatDate } from '../../../../utils/date'

interface IDetailsVehicle {
	fiscPower
	horsePower
	vehicleCarburant
	vehicleCategory
	vehicleType
	nbDoors
	nbSeats
	transmission
	ptac
	tireSizeBack
	tireSizeRear
	dateMec
}

const DetailsVehicle: React.FC<IDetailsVehicle> = ({
	fiscPower,
	horsePower,
	vehicleCarburant,
	vehicleCategory,
	vehicleType,
	nbDoors,
	nbSeats,
	transmission,
	ptac,
	tireSizeBack,
	tireSizeRear,
	dateMec,
}) => {
	const intlMsg = useFormatMessage()

	return (
		<Paper sx={css.radius}>
			<Box sx={{ mb: 2 }}>
				<Grid container spacing={1} sx={{ pt: 1 }}>
					<Grid item xs={12}>
						<Typography variant='body2' sx={css.ListSubheader}>
							{intlMsg('misc.technicalInformation')}
						</Typography>
					</Grid>
				</Grid>

				<Box sx={css.spaceRightLeft}>
					<ListItemsData
						nbCol={6}
						items={[
							{
								primaryText: 'vehicle.dateImmat',
								secondaryText: (dateMec && formatDate(dateMec, false)) || '-',
							},
							{
								primaryText: 'vehicle.vehicleCategory',
								secondaryText: vehicleCategory
									? intlMsg(`vehicle.${vehicleCategory}`)
									: '-',
							},
							{
								primaryText: 'vehicle.vehicleType',
								secondaryText: vehicleType
									? intlMsg(`vehicle.${vehicleType}`)
									: '-',
							},
							{
								primaryText: 'vehicle.ptac',
								secondaryText: ptac || '-',
							},
						]}
					/>
				</Box>

				<Divider />

				<Box sx={css.spaceRightLeft}>
					<ListItemsData
						nbCol={6}
						items={[
							{
								primaryText: 'vehicle.horsePower',
								secondaryText: horsePower || '-',
							},
							{
								primaryText: 'vehicle.fiscPower',
								secondaryText: fiscPower || '-',
							},
							{
								primaryText: 'vehicle.carburant',
								secondaryText: vehicleCarburant
									? intlMsg(`vehicle.${vehicleCarburant}`)
									: '-',
							},
							{
								primaryText: 'vehicle.transmission',
								secondaryText: transmission
									? intlMsg(`vehicle.${transmission}`)
									: '-',
							},
						]}
					/>
				</Box>

				<Divider />

				<Box sx={css.spaceRightLeft}>
					<ListItemsData
						nbCol={6}
						items={[
							{
								primaryText: 'vehicle.nbDoors',
								secondaryText: nbDoors || '-',
							},
							{
								primaryText: 'vehicle.nbSeats',
								secondaryText: nbSeats || '-',
							},
							{
								primaryText: 'vehicle.tireSizeBack',
								secondaryText: tireSizeBack || '-',
							},
							{
								primaryText: 'vehicle.tireSizeRear',
								secondaryText: tireSizeRear || '-',
							},
						]}
					/>
				</Box>
			</Box>
		</Paper>
	)
}

export default DetailsVehicle
