// import React, { useState, useCallback, useEffect } from 'react'
import React from 'react'
import {
	// Divider,
	// FormControlLabel,
	// Switch,
	Typography,
	Box,
} from '@mui/material'
import { useFormatMessage } from '../../../../utils/hooks'

const statusCSS = {
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	mt: 1.5,
	p: 4,
}

interface INetworksStatus {
	// networkIsActive
	// setNetworkIsActive
}

//!important
// everything is commented while waiting to find a solution
// concerning the management of the status of the networks / agencies

const NetworksStatus: React.FC<INetworksStatus> = () =>
	// 	{
	// 	networkIsActive,
	// 	setNetworkIsActive,
	// }
	{
		const intlMsg = useFormatMessage()

		// const [checked, setChecked] = useState(networkIsActive)

		// const handleChangeStatus = useCallback(
		// 	(checked) => () => {
		// 		setChecked((prev) => !prev)
		// 		setNetworkIsActive(!checked)
		// 		localStorage.removeItem('networksSelected')
		// 	},
		// 	[checked, setChecked]
		// )

		// useEffect(() => {
		// 	setChecked(networkIsActive)
		// }, [networkIsActive])

		return (
			<Box sx={statusCSS}>
				<Typography variant='h2'>{intlMsg('misc.networks')}</Typography>
				{/* <Divider orientation='vertical' flexItem />

			<FormControlLabel
				control={
					<Switch
						checked={checked}
						onChange={handleChangeStatus(checked)}
						size='small'
						color='primary'
					/>
				}
				label={intlMsg(checked ? 'misc.active' : 'misc.inactive')}
			/> */}
			</Box>
		)
	}

export default NetworksStatus
