import { createContext } from 'react'

export type IExportContext = {
	setIsProcessingExport
	isProcessingExport
	setExportConfig
	resetOnClose
}

const defaultContextValues: IExportContext = {
	setIsProcessingExport: null,
	isProcessingExport: null,
	setExportConfig: null,
	resetOnClose: null,
}

export const ExportContext: React.Context<IExportContext> =
	createContext(defaultContextValues)
