import React, { useCallback, useState, useEffect, useContext } from 'react'

import { useDropzone } from 'react-dropzone'

import { Box, Fade, Stack, Typography } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import BackupIcon from '@mui/icons-material/Backup'

import { IFormModalContext } from '../formModal/FormModalContext'
import { IStepperContext } from '../formStepper/StepperContext'

const StackCSS = { width: 1 }
const BackupIconCSS = { mb: 1 }
const CancelIconCSS = { ml: 1, cursor: 'pointer' }

const DropzoneAreaCSS = (height, isDragActive, isDragReject) => ({
	width: 1,
	minHeight: `${height}px !important`,
	display: 'flex',
	justifyContent: 'center',
	flexDirection: 'column',
	alignItems: 'center',
	border: 'dashed',
	borderColor: 'rgba(0, 0, 0, 0.12)',
	borderRadius: 1,
	backgroundColor: isDragActive ? (isDragReject ? '#fee' : '#eff8fe') : '#fff',
	cursor: 'pointer',
})

const selectedFileCSS = {
	backgroundColor: '#2196f312',
	borderRadius: 1,
	color: ({ palette }) => palette.primary.main,
}

interface IFormDropZone {
	name?
	text: {
		dropzoneText: string
		dropzoneTextError: string
		selectedFileText: string
		dragFileHereText: string
	}
	accept?
	height?: number
	multiple?: boolean
	context
}

const FormDropZone: React.FC<IFormDropZone> = ({
	name,
	text: { dropzoneText, dropzoneTextError, selectedFileText, dragFileHereText },
	accept = '*',
	height = 200,
	multiple = false,
	context,
}) => {
	const { setValue, data } = useContext<IFormModalContext | IStepperContext>(
		context as any
	)

	const [files, setFiles]: any = useState([])

	const deleteFile = useCallback(
		(indexFileToDelete) => () => {
			const newFiles = [...files]
			newFiles.splice(indexFileToDelete, 1)
			setFiles([...newFiles])
			setValue(name, [...newFiles])
		},
		[files, setValue]
	)

	const onDrop = useCallback(
		(acceptedFiles) => {
			setFiles([...(multiple ? files : []), ...acceptedFiles])
			setValue(name, [...(multiple ? files : []), ...acceptedFiles])
		},
		[files, setValue]
	)

	const { getRootProps, getInputProps, isDragActive, isDragReject } =
		useDropzone({
			onDrop,
			multiple,
			accept,
		})

	useEffect(() => {
		if (data?.[name]) {
			setFiles([{ name: data?.[name] }])
			setValue(name, null)
		}
	}, [])

	return (
		<Stack spacing={1} sx={StackCSS}>
			{!files.length && !multiple && (
				<Box
					sx={DropzoneAreaCSS(height, isDragActive, isDragReject)}
					{...getRootProps()}
				>
					<input {...getInputProps()} />
					<BackupIcon sx={BackupIconCSS} fontSize='large' />
					<Typography variant='h4'>
						{isDragActive
							? isDragReject
								? dropzoneTextError
								: dragFileHereText
							: dropzoneText}
					</Typography>
				</Box>
			)}

			{files?.map(({ name }, i) => (
				<Fade in={true} key={i} timeout={250}>
					<div>
						<Stack
							direction='row'
							alignItems='center'
							justifyContent='space-between'
							p={1}
							mt={1}
							sx={selectedFileCSS}
						>
							{selectedFileText}
							<Stack direction='row' alignItems='center'>
								{name}
								<CancelIcon
									onClick={deleteFile(i)}
									sx={CancelIconCSS}
									data-test-id='Playwright-DropZone-deleteFile'
								/>
							</Stack>
						</Stack>
					</div>
				</Fade>
			))}
		</Stack>
	)
}

export default FormDropZone
