import React, { useCallback, useState } from 'react'

import toast from 'react-hot-toast'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Stack, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import { useFormatMessage } from '../../utils/hooks'
import { InputText } from '../../components'
import { passwordValidationSchema } from '../../utils/validationSchema'
import api from '../../api/axios/axios'
import { ROUTES } from '../../routes/consts'

interface ISetPassword {
	forgottenPasswordToken
}

const SetPassword: React.FC<ISetPassword> = ({ forgottenPasswordToken }) => {
	const intlMsg = useFormatMessage()
	const navigate = useNavigate()

	const [loading, setLoading] = useState(false)
	const { handleSubmit, control } = useForm({
		resolver: yupResolver(passwordValidationSchema(intlMsg)),
	})

	const handleSetPassword = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		(data, _e) => {
			setLoading(true)
			api
				.post('/_ctrl/reset-password', {
					newPassword: data.newPassword,
					newRetypedPassword: data.confirmPassword,
					token: forgottenPasswordToken,
				})
				.then(() => {
					setLoading(false)
					navigate(ROUTES.LOGIN.url)
					toast.success(intlMsg('user.successPasswordUpdated'))
				})
				.catch((error) => {
					toast.error(error.message)
					setLoading(false)
				})
		},
		[forgottenPasswordToken, navigate]
	)

	return (
		<Stack justifyContent='center' p={2} sx={{ height: 1 }}>
			<Stack mb={4}>
				<Typography variant='h1'>{intlMsg('user.newPasswordTitle')}</Typography>
				<Typography variant='body2'>
					{intlMsg('user.newPasswordSubTitle')}
				</Typography>
			</Stack>
			<form onSubmit={handleSubmit((data, e) => handleSetPassword(data, e))}>
				<Stack spacing={2}>
					<InputText
						key='newPassword'
						name='newPassword'
						label={intlMsg('user.enterPassword')}
						type='password'
						control={control}
					/>
					<InputText
						key='confirmPassword'
						name='confirmPassword'
						label={intlMsg('user.confirmPassword')}
						type='password'
						control={control}
					/>

					<LoadingButton
						variant='contained'
						type='submit'
						loading={loading}
						fullWidth
					>
						{intlMsg('misc.validate')}
					</LoadingButton>
				</Stack>
			</form>
		</Stack>
	)
}

export default SetPassword
