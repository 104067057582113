export const formTemplatePayload = `
    formTemplate {
        id
        name
        title
        footer
        isActive
        customer {
            id
        }
        pages {
            id
            name
            listOrder
            fields{
                id
                attributes 
            }
        }
    }
`

export const updateFormTemplatePayloadAttributes = `
    formTemplate {
        id
        isActive
    }
`

export const formTemplateAttributes = `
    id
    name
    title
    footer
    isActive
    interventionType
    customer{
        id
    }
    pages {
        id
        name
        listOrder
        fields {
            id
            name
            type
            model
            attributes
        }
    }

`

export const listFormTemplateAttributes = `
    collection {
        id
        name
        title
        isActive
        dateCreated
        isSharedWithMe
        account {
            id
            name
        }
        createdBy {
            id
            firstName
            lastName
        }
        customer {
            id
            name
        }
    }
    paginationInfo {
        totalCount
    }
`

export const removeFormTemplatePayloadAttributes = `
    formTemplate {
        id
    }
`
