import React, { useMemo } from 'react'

import { Chip } from '@mui/material'

import { stringifiedColor } from '../../utils/color'
import { Tsize } from '../../styles/type/sizeType'

interface ICustomChipColor {
	label: string
	color?
	size?: Tsize
	sx?
	Icon?
}

const CustomChipColor: React.FC<ICustomChipColor> = ({
	label,
	color,
	size = 'medium',
	sx = {},
	Icon,
}) => {
	const chipsCSS = useMemo(
		() => ({
			borderRadius: 1,
			color: 'white',
			backgroundColor: stringifiedColor(color || 'grey', 400) as any,
			...sx,
		}),
		[sx, color]
	)

	return (
		<Chip
			icon={<Icon color='white' />}
			size={size || 'medium'}
			sx={chipsCSS}
			label={label}
		/>
	)
}
export default CustomChipColor
