import React from 'react'

import { Stack, Paper, Divider, Fade } from '@mui/material'
import BuildIcon from '@mui/icons-material/Build'
import { useFormatMessage } from '../../../utils/hooks'

import { useWVehicleIntervention } from '../../../api/gql/queries/vehiclesQueries'
import WidgetIconTitle from '../elements/WidgetIconTitle'
import WInterventionItem from './WInterventionItem'
import CircularProgressWidget from '../elements/CircularProgressWidget'

interface IWIntervention {
	id: string
	vehicle: string
}

const WIntervention: React.FC<IWIntervention> = ({ id, vehicle }) => {
	const intlMsg = useFormatMessage()

	const { data, totalVehicles, loading } = useWVehicleIntervention({
		variables: { id, vehicle },
	})

	return (
		<Paper elevation={1} sx={{ position: 'relative' }}>
			<Stack p={2} justifyContent='center' sx={{ height: 1 }}>
				<Fade in={!loading}>
					<Stack>
						<Stack
							direction='row'
							justifyContent='space-between'
							alignItems='center'
						>
							<WidgetIconTitle
								icon={{
									iconColor: 'indigo',
									Icon: BuildIcon,
								}}
								title={intlMsg('widget.interventions')}
								subTitle={totalVehicles || 0}
							/>
						</Stack>

						<Divider sx={{ mb: 2, mt: 3 }} />

						<Stack spacing={2}>
							<WInterventionItem
								interventionProgrammedVehicle={
									data?.previousInterventionProgrammedVehicle
								}
								title='widget.lastIntervention'
							/>
							<WInterventionItem
								interventionProgrammedVehicle={
									data?.nextInterventionProgrammedVehicle
								}
								title='widget.nextIntervention'
							/>
						</Stack>
					</Stack>
				</Fade>
				{loading && <CircularProgressWidget />}
			</Stack>
		</Paper>
	)
}

export default WIntervention
