/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState, useCallback, useEffect } from 'react'
import { ROUTES } from '../routes/consts'
import {
	defaultLanguage,
	languages,
} from '../providers/IntlProviderWrapper/utils'
import { ESupportedLanguages } from './app-models'

export const logout = (history: any = null) => {
	localStorage.removeItem('token')
	localStorage.removeItem('refreshToken')
	if (history) history.push(ROUTES.LOGIN.url)
}

export const debounce = (func, delay) => {
	let inDebounce
	return function (this: any, ...rest) {
		const context = this
		const args = rest
		clearTimeout(inDebounce)
		inDebounce = setTimeout(() => func.apply(context, args), delay)
	}
}

export const extractDataFromKeys = (data, keys) => {
	if (!data) return {}
	const newData = {}

	keys.forEach((key) => {
		if (data[key] || data[key] === false) newData[key] = data[key]
	})

	return newData
}

export const getTeamsAndUsersListWithManagerById = (data, iriSource) => {
	const members: any = []

	Object.entries(data)
		.filter(([key, value]) => key.includes(iriSource) && value)
		.map(([key]) => key)
		.forEach((e) => {
			const id = e.replace('isManager-', '')
			const index = members.findIndex((member: any) => member.id === id)
			if (e.includes('isManager-'))
				if (index !== -1) members[index].isManager = true
				else members.push({ id, isManager: true })
			else if (index === -1) members.push({ id })
		})

	return members
}

export const useSticky = (position) => {
	const [isElementFixed, setIsElementFixed] = useState(false)
	const stickyRef = React.useRef(isElementFixed)

	const setIsElementFixedRef = (data) => {
		stickyRef.current = data
		setIsElementFixed(data)
	}

	useEffect(() => {
		setIsElementFixedRef(window.scrollY >= position ? true : false)
	}, [])

	const handleScroll = useCallback(() => {
		if (!stickyRef.current && window.scrollY >= position + 1) {
			setIsElementFixedRef(true)
		} else if (stickyRef.current && window.scrollY <= position) {
			setIsElementFixedRef(false)
		}
	}, [stickyRef, position])

	return { isElementFixed, handleScroll }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {}

export const handleOpenViewModal =
	(navigate, location, path, otherAction?) => (e) => {
		e.preventDefault()

		const newPath =
			path.search('modalview') === -1
				? path
				: `${
						location.pathname === '/'
							? ''
							: location.pathname.replace('/modalview', '')
				  }${path}`

		// refused on scroll click
		if (e.button !== 2) {
			e.ctrlKey || e.button == 1 ? window.open(newPath) : navigate(newPath)
		}

		otherAction && otherAction()
	}

export const getDefaultLocalLanguage = () =>
	localStorage.getItem('userLanguage') ||
	(languages
		.map((language) => language.key)
		.includes(navigator.language.substring(0, 2) as ESupportedLanguages)
		? navigator.language.substring(0, 2)
		: defaultLanguage) ||
	defaultLanguage

export const isValidHexColor = (value) =>
	/^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/i.test(value)

//Deep merges two objects and returns the merged object. Obj2 overwrites obj1 when key are identicals
export const generateDeepMergedObj = (obj1, obj2) => {
	const mergedObject = { ...obj1 }

	for (const key in obj2) {
		// eslint-disable-next-line
		if (obj2.hasOwnProperty(key))
			if (
				obj2[key] instanceof Object &&
				// eslint-disable-next-line
				obj1.hasOwnProperty(key) &&
				obj1[key] instanceof Object
			)
				mergedObject[key] = generateDeepMergedObj(obj1[key], obj2[key])
			else mergedObject[key] = obj2[key]
	}

	return mergedObject
}
