import { GlobalFont } from './globalTheme/globalTheme'

//old theme version, used until MUI is completely removed
export const MuiTheme = {
	typography: {
		fontFamily: GlobalFont,
		caption: {
			fontSize: '0.775rem',
		},
		body1: {
			fontSize: '0.813rem',
			fontWeight: 400,
		},
		body2: {
			fontSize: '14px',
			fontWeight: 400,
		},
		h6: { fontSize: '0.75rem', color: '#474747' },
		h5: { fontSize: '1rem' },
		h4: { fontSize: '1.1rem' },
		h3: { fontSize: '18px', fontWeight: 'bold' },
		h2: { fontSize: '20px', fontWeight: 'bold' },
		h1: { fontSize: '1.5', fontWeight: 700, color: '#474747' },
		subtitle2: { fontSize: '14px', fontWeight: 'medium' },
	},

	shadows: ['none', ' 0 0 11px -4px rgba(0,0,0,0.19)'],

	shape: { borderRadius: 4 },
}
