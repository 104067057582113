import React, { useCallback } from 'react'

import { Stack, Typography, Link } from '@mui/material'
import { formatAdressGoogleMap } from '../../../utils/string'

interface ITransportAddress {
	name
	address
	zipCode
	city
	country
}

const TransportAddress: React.FC<ITransportAddress> = ({
	name,
	address,
	zipCode,
	city,
	country,
}) => {
	const openLink = useCallback(() => {
		window.open(formatAdressGoogleMap(address, city, zipCode), '_blank')
	}, [])

	return (
		<Stack>
			{name && <Typography variant='caption'>{name}</Typography>}

			<Link href={'#'} underline='hover' color='inherit' onClick={openLink}>
				{`${address}, ${zipCode}, `}
				<strong>{`${city}, ${country?.substring(0, 2)}`}</strong>
			</Link>
		</Stack>
	)
}

export default TransportAddress
