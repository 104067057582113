import React from 'react'
import CustomerSiteModalInfos from './CustomerSiteModalInfos'
import customerSitetModalValidationSchema from './customerSiteModalValidationSchema'

const customerSiteModalConfig = [
	{
		component: <CustomerSiteModalInfos />,
		schema: customerSitetModalValidationSchema,
	},
]

export default customerSiteModalConfig
