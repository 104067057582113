import React, { useCallback, useContext } from 'react'
import toast from 'react-hot-toast'

import {
	useCreateTeams,
	useUpdateTeams,
} from '../../../../api/gql/mutations/teamsMutations'
import { useLazyTeamsModal } from '../../../../api/gql/queries/teamsQueries'
import FormModal from '../../../../components/forms/formModal'
import { EFormModalMode } from '../../../../utils/app-models'
import { AuthContext } from '../../../../utils/contexts/AuthContext'
import { IModalUtils, useFormatMessage } from '../../../../utils/hooks'
import { teamModalConfig } from './teamModalConfig'
import {
	formatTeamModalEditDefaultValues,
	formatTeamModalOnValidateData,
} from './teamModalUtils'

interface ITeamModal {
	editedTeamId?: string
	modalUtils: IModalUtils
	mode?: EFormModalMode
}

const TeamModal: React.FC<ITeamModal> = ({
	editedTeamId,
	modalUtils,
	mode,
}) => {
	const { currentUser } = useContext(AuthContext)
	const intlMsg = useFormatMessage()
	const { getTeamModal } = useLazyTeamsModal()
	const [updateTeam] = useUpdateTeams()
	const [createTeam] = useCreateTeams()

	const validateModal = useCallback(
		async ({ data, setValidationLoading, closeModal }) => {
			setValidationLoading(true)
			const mutate = mode === EFormModalMode.edit ? updateTeam : createTeam

			await mutate({
				variables: {
					input: formatTeamModalOnValidateData({
						data,
						mode,
						editedTeamId,
						currentUser,
					}),
				},
				refetchQueries: ['getTeamsList', 'getTeamModal'],
			})
				.then(() => {
					mode === EFormModalMode.edit
						? toast.success('success.teamUpdate')
						: toast.success('success.teamCreate')
					setValidationLoading(false)
					closeModal()
				})
				.catch(() => {
					setValidationLoading(false)
				})
		},
		[editedTeamId, mode]
	)

	const initializeData = useCallback(
		async ({ setValues, setLoading }) => {
			if (mode === EFormModalMode.edit) {
				setLoading(true)
				getTeamModal({ variables: { id: editedTeamId } })
					.then((res) => {
						const data = res?.data?.team

						if (data) setValues(formatTeamModalEditDefaultValues({ data }))

						setLoading(false)
					})
					.catch(() => setLoading(false))
			} else {
				//no default values here
			}
		},
		[mode, editedTeamId]
	)

	return (
		<FormModal
			modalUtils={modalUtils}
			formModalTabConfig={teamModalConfig}
			formModalName={intlMsg(
				mode === EFormModalMode.edit
					? 'administration.editTeam'
					: 'administration.createTeam'
			)}
			initialMode={mode}
			onValidate={validateModal}
			validateBtnText={intlMsg('misc.validate')}
			cancelBtnText={intlMsg('misc.cancel')}
			maxWidth='600px'
			minHeight='50px'
			initializeData={initializeData}
			editedElementId={editedTeamId}
		/>
	)
}

export default TeamModal
