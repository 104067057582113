import React, { useCallback, useEffect } from 'react'

import { Droppable, Draggable } from 'react-beautiful-dnd'
import { Stack } from '@mui/material'

import BtnAddCircle from '../../../../../components/buttons/BtnAddCircle'
import PageItem from './PageItem'
import { useSticky } from '../../../../../utils/utils'

const getItemStyle = (isDragging) => ({
	background: isDragging ? '#ddd' : 'white',
	borderRight: `${isDragging ? '0' : '3'}px #2196F3 solid`,
	borderRadius: `${isDragging ? '3' : '0'}px`,
	color: '#2196F3',
})

const positionFixedCSS = {
	position: 'fixed',
	top: '0px',
	marginTop: '94px',
	background: 'transparent',
	height: 'calc(100vh - 184px)',
	width: '140px',
	transitionDelay: ' 0.1s',
	transition: 'all 0.2s ease-out',
	transitionTimingFunction: 'linear',
} as const

const mainCSS = {
	width: '140px',
	marginTop: '1.4rem',
	zIndex: 0,
	transition: 'all 0.5s ease-in',
	transitionTimingFunction: 'linear',
} as const

interface IPagesSelection {
	selectedPageNbr: number
	setSelectedPageNbr: (selectedPageNbr: number) => void
	pages
	insertPage
}

const PagesSelection: React.FC<IPagesSelection> = ({
	selectedPageNbr,
	setSelectedPageNbr,
	pages,
	insertPage,
}) => {
	const { isElementFixed, handleScroll } = useSticky(408)

	const handleAddPage = useCallback(() => {
		const index = pages?.length
		insertPage(index, { name: `Page ${index + 1}`, fields: [] })
		setSelectedPageNbr(index)
	}, [pages, insertPage])

	useEffect(() => {
		document.addEventListener('scroll', handleScroll)
		return () => {
			document.removeEventListener('scroll', handleScroll)
		}
	}, [])

	return (
		<Stack sx={[mainCSS, isElementFixed && positionFixedCSS]}>
			<Droppable droppableId={'page'}>
				{(provided) => (
					<div ref={provided.innerRef} {...provided.droppableProps}>
						{pages.map(({ id }, i) => (
							<Draggable key={id} draggableId={id} index={i}>
								{(provided, snapshot) => (
									<div
										ref={provided.innerRef}
										{...provided.draggableProps}
										data-page={i}
									>
										<PageItem
											key={id}
											i={i}
											setSelectedPageNbr={setSelectedPageNbr}
											selectedPageNbr={selectedPageNbr}
											name={pages[i].name as any}
											draggableZone={{ ...provided.dragHandleProps }}
											dragStyle={getItemStyle(snapshot.isDragging)}
										/>
									</div>
								)}
							</Draggable>
						))}

						{provided.placeholder}
					</div>
				)}
			</Droppable>

			<Stack direction='row' justifyContent='center' mt={2}>
				<BtnAddCircle action={handleAddPage} />
			</Stack>
		</Stack>
	)
}

export default PagesSelection
