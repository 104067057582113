export const commonNetworkAttributes = `
    id
    name
    dateCreated
`

export const listNetworkAttributes = `
    collection {
        id
        name
    }
    paginationInfo {
        totalCount
    }
`
export const sitePayloadAttributes = `
    site {
        ${commonNetworkAttributes}
    }
`

export const listNetworks = `
  paginationInfo {
    totalCount
  }
  collection {
    id
    name
    dateCreated
    isActive
    agencies {
      paginationInfo {
        totalCount
      }
    }
  }

`
