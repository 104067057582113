/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import selectedVehiclesListTableConfig from './selectedVehiclesListTableConfig'

////////////////////////////////////////////////////////////////
// Global variable
////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////
// TableToolBarHeader CONFIG
////////////////////////////////////////////////////////////////

const tableToolBarConfig = {
	title: 'interventions.interventionVehicles',
	tableConfig: selectedVehiclesListTableConfig,
	queryConfig: null,
	localStorageName: 'selectedVehicleListTableColumnFilters',
}

////////////////////////////////////////////////////////////////
// tableContainer CONFIG
////////////////////////////////////////////////////////////////

const tableContainerConfig = {
	tableConfig: selectedVehiclesListTableConfig,
	colorBorderLeftSelected: '#4CAF50',
	queryConfig: null,
	unCard: true,
}

////////////////////////////////////////////////////////////////
// -> EXPORT CONFIG MAIN TABLE
////////////////////////////////////////////////////////////////

export const selectedVehiclesListConfig = {
	tableToolBarConfig: tableToolBarConfig,
	tableContainerConfig: tableContainerConfig,
}
