/* eslint-disable no-case-declarations */
import React from 'react'

import CheckIcon from '@mui/icons-material/Check'
import DateRangeIcon from '@mui/icons-material/DateRange'
import ShortTextIcon from '@mui/icons-material/ShortText'
import TitleIcon from '@mui/icons-material/Title'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import EditIcon from '@mui/icons-material/Edit'
import DescriptionIcon from '@mui/icons-material/Description'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
// import AttachmentIcon from '@mui/icons-material/Attachment'
import InfoIcon from '@mui/icons-material/Info'

import { Icon } from '@mui/material'

import Numbers from './icons/numerotation.svg'
import Slider from './icons/slider.svg'
import Vin from './icons/vin.svg'

import {
	EFormTemplateBasicFields,
	//	EFormTemplateControlVerificationType,
	EFormTemplateVehicleFields,
	EFormTemplateModelFields,
} from '../../../../../utils/app-models'
import { updateLimitField } from '../formEditionsUtils/fieldsLimitation'
import * as formTemplateDefaultValues from './defaultValue'

// { type: EFormTemplateBasicFields.media },

export const vehicleListConfig = [
	{ fieldType: EFormTemplateBasicFields.control },
	{ fieldType: EFormTemplateBasicFields.mileage },
	{
		fieldType: EFormTemplateBasicFields.parkDate,
	},
]

export const seizureListConfig = [
	{ fieldType: EFormTemplateBasicFields.text },
	{ fieldType: EFormTemplateBasicFields.numbers },
	{ fieldType: EFormTemplateBasicFields.date },
	{ fieldType: EFormTemplateBasicFields.barCode },
	{ fieldType: EFormTemplateBasicFields.rating },
	{ fieldType: EFormTemplateBasicFields.selection },
	{ fieldType: EFormTemplateBasicFields.signature },
]

export const photoListConfig = [
	// { fieldType: EFormTemplateBasicFields.picture },
	{
		fieldModel: EFormTemplateModelFields.pictureVehicle,
		fieldType: EFormTemplateBasicFields.picture,
	},
	{ fieldType: EFormTemplateBasicFields.document },
]

export const layoutListConfig = [
	{ fieldType: EFormTemplateBasicFields.title },
	{ fieldType: EFormTemplateBasicFields.instruction },
]

export const fieldsModelListConfig = [
	{
		fieldModel: EFormTemplateModelFields.picture4anglesModel,
		fieldType: EFormTemplateBasicFields.picture,
	},
	{
		fieldModel: EFormTemplateModelFields.picture4anglesAndWheelsModel,
		fieldType: EFormTemplateBasicFields.picture,
	},
	{
		fieldModel: EFormTemplateModelFields.doubleSidedPhotoDocument,
		fieldType: EFormTemplateBasicFields.document,
	},
]

//used in onDragEndField
export const allFieldsModelListConfig = [
	{
		fieldModel: EFormTemplateModelFields.picture4anglesModel,
		fieldType: EFormTemplateBasicFields.picture,
	},
	{
		fieldModel: EFormTemplateModelFields.picture4anglesAndWheelsModel,
		fieldType: EFormTemplateBasicFields.picture,
	},
	{
		fieldModel: EFormTemplateModelFields.doubleSidedPhotoDocument,
		fieldType: EFormTemplateBasicFields.document,
	},
	{
		fieldType: EFormTemplateBasicFields.parkDate,
	},
	{
		fieldModel: EFormTemplateModelFields.pictureVehicle,
		fieldType: EFormTemplateBasicFields.picture,
	},
]

// eslint-disable-next-line react/display-name
const CustomIcon = (CustomI) => () =>
	(
		<Icon>
			<img src={CustomI} />
		</Icon>
	)

export const getIconByFieldsType = (fieldType: string): any => {
	switch (fieldType) {
		case EFormTemplateBasicFields.control:
			return CheckIcon

		case EFormTemplateBasicFields.parkDate:
		case EFormTemplateBasicFields.date:
			return DateRangeIcon

		case EFormTemplateModelFields.pictureVehicle:
		case EFormTemplateModelFields.picture4anglesModel:
		case EFormTemplateModelFields.picture4anglesAndWheelsModel:
		case EFormTemplateBasicFields.picture:
			return DirectionsCarIcon

		case EFormTemplateModelFields.doubleSidedPhotoDocument:
		case EFormTemplateBasicFields.document:
			return DescriptionIcon

		case EFormTemplateBasicFields.text:
			return ShortTextIcon

		case EFormTemplateBasicFields.title:
			return TitleIcon

		case EFormTemplateBasicFields.selection:
			return CheckBoxIcon

		case EFormTemplateBasicFields.numbers:
			return CustomIcon(Numbers)

		case EFormTemplateBasicFields.signature:
			return EditIcon

		case EFormTemplateBasicFields.rating:
			return CustomIcon(Slider)

		// case EFormTemplateBasicFields.media:
		// 	return AttachmentIcon

		case EFormTemplateBasicFields.instruction:
			return InfoIcon

		case EFormTemplateBasicFields.barCode:
			return CustomIcon(Vin)

		case EFormTemplateVehicleFields.mileage:
			return CustomIcon(Numbers)

		default:
			return <></>
	}
}

export const generateTemplateDefaultValue = (
	fieldType: string,
	setLimitationFields
): any => {
	switch (fieldType) {
		////////////////////////////////////////////////////////////////////////////
		// fields
		////////////////////////////////////////////////////////////////////////////

		case EFormTemplateBasicFields.control:
			return formTemplateDefaultValues.controlDefaultValue

		case EFormTemplateBasicFields.parkDate:
			return formTemplateDefaultValues.dateParkDefaultValue

		case EFormTemplateBasicFields.date:
			return formTemplateDefaultValues.dateDefaultValue

		//  !!!Important: if default values is added or deleted, don't forget to update the config 'defaultLimitFields' !!!
		case EFormTemplateBasicFields.picture:
			updateLimitField({
				fieldType: fieldType,
				fieldAttributes: {
					picturesList:
						formTemplateDefaultValues.pictureDefaultValue.picturesList,
				},
				setLimitationFields,
			})

			return formTemplateDefaultValues.pictureDefaultValue

		case EFormTemplateModelFields.pictureVehicle:
			updateLimitField({
				fieldType: fieldType,
				fieldAttributes: {
					picturesList:
						formTemplateDefaultValues.pictureVehicleDefaultValue.picturesList,
				},
				setLimitationFields,
			})

			return formTemplateDefaultValues.pictureVehicleDefaultValue

		case EFormTemplateBasicFields.document:
			return formTemplateDefaultValues.documentDefaultValue
		case EFormTemplateModelFields.doubleSidedPhotoDocument:
			return formTemplateDefaultValues.doubleSidedPhotoDocumentDefaultValue
		case EFormTemplateBasicFields.text:
			return formTemplateDefaultValues.textDefaultValue

		case EFormTemplateBasicFields.title:
			return formTemplateDefaultValues.titleDefaultValue

		case EFormTemplateBasicFields.selection:
			return formTemplateDefaultValues.selectionDefaultValue

		case EFormTemplateBasicFields.numbers:
			return formTemplateDefaultValues.numbersDefaultValue

		case EFormTemplateBasicFields.signature:
			return formTemplateDefaultValues.signatureDefaultValue

		case EFormTemplateBasicFields.rating:
			return formTemplateDefaultValues.ratingDefaultValue

		// case EFormTemplateBasicFields.media:
		// 	return {
		// 		titleField: intlMsg(`formTemplate.${EFormTemplateBasicFields.media}`),
		// 		isRequired: false,
		// 	}

		case EFormTemplateBasicFields.instruction:
			return formTemplateDefaultValues.instructionDefaultValue

		case EFormTemplateBasicFields.barCode:
			return formTemplateDefaultValues.barCodeDefaultValue

		case EFormTemplateVehicleFields.mileage:
			return formTemplateDefaultValues.mileageDefaultValue

		////////////////////////////////////////////////////////////////////////////
		// Models fields
		////////////////////////////////////////////////////////////////////////////

		//  !!!Important: if default values is added or deleted, don't forget to update the config 'defaultLimitFields' !!!
		case EFormTemplateModelFields.picture4anglesModel:
			updateLimitField({
				fieldType: EFormTemplateBasicFields.picture, // 'picture4anglesModel' is a field's model of field 'picture'
				fieldAttributes: {
					picturesList:
						formTemplateDefaultValues.picture4anglesModel.picturesList,
				},
				setLimitationFields,
			})

			return formTemplateDefaultValues.picture4anglesModel

		//  !!!Important: if default values is added or deleted, don't forget to update the config 'defaultLimitFields' !!!
		case EFormTemplateModelFields.picture4anglesAndWheelsModel:
			updateLimitField({
				fieldType: EFormTemplateBasicFields.picture, // 'picture4anglesModel' is a field's model of field 'picture'
				fieldAttributes: {
					picturesList:
						formTemplateDefaultValues.picture4anglesAndWheelsModel.picturesList,
				},
				setLimitationFields,
			})

			return formTemplateDefaultValues.picture4anglesAndWheelsModel

		default:
			return
	}
}
