/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
	gql,
	OperationVariables,
	QueryHookOptions,
	useLazyQuery,
	useQuery,
} from '@apollo/client'
import {
	commonTeamAttributes,
	listTeamAttributes,
} from '../attributes/teamsAttributes'

/**  ---------------------QUERIES---------------------- */

export const GET_TEAM = gql`
query getTeam(
    $id: ID
) {
    team(id: $id) {
        ${commonTeamAttributes}
    }
}`

export const TEAMS_FILTER_LIST = gql`
query getTeamsList(
    $page: Int
    $itemsPerPage: Int
    $searchText: String
    $isActive: Boolean
	$orderBy: [TeamFilter_orderBy]
) {
    teams(page: $page, itemsPerPage: $itemsPerPage, searchText: $searchText, isActive: $isActive, orderBy: $orderBy) {
        ${listTeamAttributes}
    }
}`

export const TEAMS_FILTER_LIST_FOR_MODAL = gql`
	query getTeamsList(
		$page: Int
		$itemsPerPage: Int
		$searchText: String
		$isActive: Boolean
	) {
		teams(
			page: $page
			itemsPerPage: $itemsPerPage
			searchText: $searchText
			isActive: $isActive
		) {
			collection {
				id
				name
			}
		}
	}
`

export const TEAMS_NUMBERS = gql`
	query getTeamsNumbers($searchText: String, $isActive: Boolean) {
		teams(
			page: 1
			itemsPerPage: 100000
			searchText: $searchText
			isActive: $isActive
		) {
			paginationInfo {
				totalCount
			}
		}
	}
`

export const GET_TEAM_MODAL = gql`
	query getTeamModal($id: ID!) {
		team(id: $id) {
			id
			name
			members
			sites(itemsPerPage: 99999) {
				collection {
					id
					name
				}
			}
			networks(itemsPerPage: 99999) {
				collection {
					id
					name
				}
			}
		}
	}
`

/**  ---------------------HOOKS---------------------- */

export const useTeam = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { loading, data } = useQuery(GET_TEAM, options)

	return { loading, data: data?.team || null }
}

export const useLazyTeamsModal: any = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(GET_TEAM_MODAL, options)

	return {
		loading,
		team: data?.team || null,
		getTeamModal: getData,
	}
}
