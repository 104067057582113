/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'

import { useBulkCustomerAction } from '../../../api/gql/mutations/customerMutation'
import {
	LIST_CUSTOMERS,
	useExportCustomers,
} from '../../../api/gql/queries/customersQueries'
import FormBtnModal from '../../../components/forms/formModalBtn'
import listCustomersViewTable from '../../../components/tableV2/config/dashboard/customerAccounts/listCustomersViewTable'
import { EFormModalMode, EPermissionScope } from '../../../utils/app-models'
import { IModalUtils } from '../../../utils/hooks'
import {
	ITableToolBarActionConfig,
	ITableToolBarConfig,
} from '../../../utils/typescript/tableType'
import { CustomerAccountValidationSchema } from '../../../utils/validationSchema'
import CustomerAccountsModal from './customerAccountsModal'

////////////////////////////////////////////////////////////////
// Global variable
////////////////////////////////////////////////////////////////

const queryConfig = {
	query: LIST_CUSTOMERS,
	queryName: 'customers',
}

////////////////////////////////////////////////////////////////
// tableToolBarAction CONFIG
////////////////////////////////////////////////////////////////

const refreshQuerie = ['getCustomersList']

const deletedBulk = {
	mutation: useBulkCustomerAction,
	operation: 'remove',
	successMessageDelete: {
		singular: 'success.accountDeleted',
		plural: 'success.accountsDeleted',
	},
	messageConfirm: {
		title: 'confirmModal.deleteCustomersAccountTitle',
		message: 'confirmModal.deleteCustomersAccountMessage',
	},
	deletePermissions: [
		EPermissionScope.customerDelete,
		EPermissionScope.customerManage,
	],
}

const tableToolBarActionConfig: ITableToolBarActionConfig = {
	refreshQuerie,
	deletedBulk,
}

////////////////////////////////////////////////////////////////
// TableToolBarHeader CONFIG
////////////////////////////////////////////////////////////////

const tableToolBarConfig: ITableToolBarConfig = {
	title: 'misc.accounts',
	stickyToolBar: true,
	tableConfig: listCustomersViewTable,
	localStorageName: 'listCustomersViewTableFilters',
	exportConfig: { exportQuery: useExportCustomers },
	queryConfig,
	modalButton: {
		Btn: (
			<FormBtnModal
				modal={<CustomerAccountsModal modalUtils={{} as IModalUtils} />}
				mode={EFormModalMode.create}
			/>
		),
	},
	validationSchemaFunction: CustomerAccountValidationSchema,
	createPermission: [EPermissionScope.customerManage],
}

////////////////////////////////////////////////////////////////
// tableContainer CONFIG
////////////////////////////////////////////////////////////////

const tableContainerConfig = {
	tableConfig: listCustomersViewTable,
	queryConfig,
}

////////////////////////////////////////////////////////////////
// -> EXPORT CONFIG MAIN TABLE
////////////////////////////////////////////////////////////////

export const customerAccountsConfigTable = {
	tableToolBarConfig,
	tableToolBarActionConfig,
	tableContainerConfig,
	displaySwitchOption: true,
}
