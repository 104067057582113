import React from 'react'

import Transport from './interventionTransportEventInfo'
import Control from './interventionControlEventInfo'

const componentEventMap = {
	transport: Transport,
	control: Control,
}

interface ICalendarEventInfo {
	event: any
	handleClose: any
	setOpenModalIntervention: any
	setFormModalEdit: any
}

const CalendarEventInfo: React.FC<ICalendarEventInfo> = ({
	event,
	handleClose,
	setOpenModalIntervention,
	setFormModalEdit,
}) => {
	const Event = componentEventMap[event?.resource?.interventionType]

	return (
		<Event
			event={event}
			handleClose={handleClose}
			setOpenModalIntervention={setOpenModalIntervention}
			setFormModalEdit={setFormModalEdit}
		/>
	)
}

export default CalendarEventInfo
