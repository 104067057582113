import { EFormBlocksPDF } from '../../../utils/app-models'
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

import { blockImage } from './blockImage'
import { blockRecords } from './blocksRecords'
import { blockTitle } from './blockTitle'

export const blocksAllRecords = (doc, dataBlocks) => {
	doc.autoTable()

	dataBlocks.forEach((block) => {
		switch (block?.typeBlock) {
			case EFormBlocksPDF.pageTitle:
				return blockTitle(doc, block?.data, block?.nbpage)
			case EFormBlocksPDF.records:
				return blockRecords(doc, block?.titles, block?.records)
			case EFormBlocksPDF.picture:
				return blockImage(doc, block?.title, block?.record)
		}
	})
}
