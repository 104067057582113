import React, { useCallback, useContext } from 'react'
import toast from 'react-hot-toast'

import Modal from '../../../forms/v1'
import { EFormModalMode } from '../../../../utils/app-models'
import { ModalContext } from '../../../../utils/contexts/ModalContext'

import WVehicleTrackingModalProvider from './WVehicleTrackingModalProvider'
import {
	useCreateVehicleMovement,
	useUpdateVehicleMovement,
} from '../../../../api/gql/mutations/vehicleMutation'
import WVehicleTrackingModalContent from './WVehicleTrackingModalContent'

interface IWVehicleTrackingModal {
	editedVehicleTrackingId?: string
	vehicleId?: string
}

const WVehicleTrackingModal: React.FC<IWVehicleTrackingModal> = ({
	editedVehicleTrackingId,
	vehicleId,
}) => {
	const [createVehicleMovement] = useCreateVehicleMovement()
	const [updateVehicleMovement] = useUpdateVehicleMovement()

	const { isVisible, closeModal, setLoadingModal, mode, resetContext } =
		useContext(ModalContext)

	const handleCloseModal = useCallback(() => {
		closeModal()
		resetContext()
	}, [])

	const validateModal = useCallback(
		async (data) => {
			setLoadingModal(true)
			const mutate =
				mode === EFormModalMode.edit
					? updateVehicleMovement
					: createVehicleMovement
			const specificModeFields =
				mode === EFormModalMode.edit
					? {
							id: editedVehicleTrackingId,
					  }
					: {
							vehicle: vehicleId,
					  }

			await mutate({
				variables: {
					input: {
						entryDate: data.entryDate,
						exitDate: data.exitDate,
						...specificModeFields,
					},
				},
				refetchQueries: [
					'getWVehicle',
					'getWVehicleMovement',
					'getVehiclesList',
				],
			})
				.then(() => {
					mode === EFormModalMode.create
						? toast.success('success.vehicleMovementCreate')
						: toast.success('success.vehicleMovementUpdate')
					setLoadingModal(false)
					handleCloseModal()
				})
				.catch(() => setLoadingModal(false))
			setLoadingModal(false)
		},
		[mode, editedVehicleTrackingId, vehicleId]
	)

	return (
		<>
			{isVisible && (
				<WVehicleTrackingModalProvider
					editedVehicleTrackingId={editedVehicleTrackingId}
					isVisible={isVisible}
				>
					<Modal
						isVisible={isVisible}
						closeModal={handleCloseModal}
						modalName={
							mode === EFormModalMode.edit
								? 'widget.vehicleTrackingEdit'
								: 'widget.vehicleTrackingCreate'
						}
						validateModal={validateModal}
						width='auto'
						minHeight='auto'
					>
						<WVehicleTrackingModalContent />
					</Modal>
				</WVehicleTrackingModalProvider>
			)}
		</>
	)
}

export default WVehicleTrackingModal
