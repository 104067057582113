import { createContext } from 'react'

export type IAuthContext = {
	currentUserId: string | null
	currentUser
	setCurrentUser?
	setCurrentUserId?: any
	resetContext?: any
	setNavIsOpen
	navIsOpen: boolean
	checkPermissions?
	checkAccountsType?
	checkModules?
	localLanguage: string
	setLocalLanguage
	checkAllModules?: any
}

export const AuthContext: React.Context<IAuthContext> = createContext(
	{} as IAuthContext
)
