import React, { useMemo } from 'react'
import { Box, Tooltip } from '@mui/material'
import { useFormatMessage } from '../../utils/hooks'

const barCSS = {
	backgroundColor: '#1f91eb3d',
	borderRadius: 4,
	width: '100%',
	height: 8,
}

interface IInterventionRangeBar {
	data
	useNode?
}

const InterventionRangeBar: React.FC<IInterventionRangeBar> = ({
	data: { programmedVehicles, status },
	useNode = false,
}) => {
	const intlMsg = useFormatMessage()

	const countVehicleDone = useMemo(
		() =>
			programmedVehicles?.filter((vehicle) => {
				if (useNode) {
					return vehicle?.node?.status?.code === 'DONE'
				} else {
					return vehicle?.status?.code === 'DONE'
				}
			})?.length,
		[programmedVehicles, useNode]
	)

	const totalProgrammedVehicle = useMemo(
		() => programmedVehicles?.length,
		[programmedVehicles]
	)

	const messageTooltip = useMemo(() => {
		return `${countVehicleDone}/${totalProgrammedVehicle} ${intlMsg(
			'interventions.vehicleDone'
		)}`
	}, [countVehicleDone, totalProgrammedVehicle])

	const boxSx = useMemo(
		() => ({
			backgroundColor: ({ palette }) =>
				status?.progression === '100'
					? palette.success.light
					: palette.primary.main,
			borderRadius: 4,
			height: 8,
			width: `${
				status?.progression
					? status?.progression
					: (countVehicleDone / totalProgrammedVehicle) * 100
			}%`,
		}),
		[status, countVehicleDone, totalProgrammedVehicle]
	)

	return (
		<Tooltip title={messageTooltip} arrow>
			<Box sx={barCSS}>
				<Box sx={boxSx}></Box>
			</Box>
		</Tooltip>
	)
}

export default InterventionRangeBar
