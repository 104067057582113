import { EPeriodFilter } from '../../../../../../utils/app-models'

export const byReferenceDefaultFiltersValues = {
	period: EPeriodFilter.thisWeek,
	category: 'all',
	customer_list: null,
	actors_list: null,
	agency_list: null,
	isWithoutActor: false,
	status_list: null,
	valueStatusGroup: 'interventionAll',
}
