import dayjs from 'dayjs'
import { EPeriodFilter } from '../../../../../utils/app-models'

export const dashboardFilterDateConfig = [
	{
		period: EPeriodFilter.today,
		date: {
			dateFrom: [
				{
					before: dayjs().endOf('day').toDate(),
					after: dayjs().startOf('day').toDate(),
				},
			],
		},
	},
	{
		period: EPeriodFilter.thisWeek,
		date: {
			dateFrom: [
				{
					before: dayjs().endOf('week').toDate(),
					after: dayjs().startOf('week').toDate(),
				},
			],
		},
	},

	{
		period: EPeriodFilter.lastWeek,
		date: {
			dateFrom: [
				{
					before: dayjs().subtract(7, 'day').endOf('week').toDate(),
					after: dayjs().subtract(7, 'day').startOf('week').toDate(),
				},
			],
		},
	},

	{
		period: EPeriodFilter.thisMonth,
		date: {
			dateFrom: [
				{
					before: dayjs().endOf('month').toDate(),
					after: dayjs().startOf('month').toDate(),
				},
			],
		},
	},
]
