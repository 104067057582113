import React, { useCallback, useMemo } from 'react'
import { Box, IconButton, Popover } from '@mui/material'
import ShortTextIcon from '@mui/icons-material/ShortText'

interface ITableSideNavItemComment {
	comment: string
	isSelected?: boolean
	playwrightId?: string
}

const TableSideNavItemComment: React.FC<ITableSideNavItemComment> = ({
	comment,
	isSelected,
	playwrightId,
}) => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

	const handleClick = useCallback((event) => {
		event.stopPropagation()
		event.preventDefault()
		setAnchorEl(event.currentTarget)
	}, [])

	const handleClose = useCallback((event) => {
		setAnchorEl(null)
		event.stopPropagation()
		event.preventDefault()
	}, [])

	const open = useMemo(() => Boolean(anchorEl), [anchorEl])

	const btnIconCSS = useMemo(
		() => ({
			borderRadius: 1,
			p: 0,
			'&:hover': { backgroundColor: isSelected ? '#dcf0fd' : '#ebebeb' },
			whiteSpace: 'nowrap',
			marginRight: '5px',
		}),
		[isSelected]
	)

	return comment ? (
		<>
			<IconButton
				size='small'
				onClick={handleClick}
				sx={btnIconCSS}
				data-test-id={playwrightId}
			>
				<ShortTextIcon />
			</IconButton>

			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				elevation={1}
			>
				<Box p={1} maxWidth={300}>
					{comment}
				</Box>
			</Popover>
		</>
	) : (
		<></>
	)
}

export default TableSideNavItemComment
