import React from 'react'

import Chip from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'
import { Stack, Typography, Box } from '@mui/material'

import TitleField from './TitleField'
import { maxStringDisplay } from '../../../../../../utils/string'

const contentCSS = {
	display: ' inline-flex',
	flexWrap: 'wrap',
	gap: 2,
	mt: 2,
}

const txtCSS = {
	position: 'absolute',
	bottom: 12,
}

const contentImgCSS = {
	display: 'flex',
	flexDirection: 'column',
	position: 'relative',
	width: '150px',
	height: '150px',
	border: ({ palette }) => `1px dashed ${palette.grey[300]}`,
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: 'white',
}

const chipCSS = {
	fontSize: '10px',
	p: 0,
	color: ({ palette }) => palette.error.main,
	border: ({ palette }) => `solid 1px ${palette.error.main}`,
	height: '16px',
	position: 'absolute',
	top: 6,
}

interface IField {
	field
	deleteField
	index
	updateField
	insertField
}

const Field: React.FC<IField> = ({
	field: { id, name, values, fieldType },
	deleteField,
	index,
	updateField,
	insertField,
}) => {
	const fieldToEdit = {
		id,
		name,
		values,
		fieldType,
		updateField,
		index,
	}

	return (
		<Stack p={1}>
			<TitleField
				deleteField={deleteField}
				index={index}
				fieldToEdit={fieldToEdit}
				isRequired={values?.isRequired}
				insertField={insertField}
				values={values}
				fieldType={fieldType}
			/>

			<Box sx={contentCSS}>
				{values?.documentList?.map(({ name, isRequired, type }, i) => (
					<Box sx={contentImgCSS} key={`documentList-${name}-${i}`}>
						{isRequired && (
							<Chip
								variant='outlined'
								size='small'
								label='obligatoire'
								sx={chipCSS}
							/>
						)}
						<img src={`/icon/template-form/document-${type}.svg`} />

						<Typography variant='caption' sx={txtCSS}>
							{name?.length > 15 ? (
								<Tooltip title={name} aria-label={name}>
									<span>{maxStringDisplay(name, 15)}</span>
								</Tooltip>
							) : (
								name || '-'
							)}
						</Typography>
					</Box>
				))}
			</Box>
		</Stack>
	)
}

export default Field
