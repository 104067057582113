import React, { useCallback, useMemo, memo, useState } from 'react'

import Autocomplete from '@mui/material/Autocomplete'
import { Stack } from '@mui/material'
import Radio from '@mui/material/Radio'
import TextField from '@mui/material/TextField'

import { useFormatMessage } from '../../utils/hooks'

interface ISelectRadioSearch {
	value: string | number | boolean
	onChange: (value: string | number | boolean) => void
	label: string
	options:
		| Array<{
				label: string
				key: string
				value: string | number | boolean
		  }>
		| any

	isrequired?: boolean
	customHelper?: string
	size?: 'medium' | 'small'
	isDisabled?: boolean
	onBlur?: () => void
	multiple?: boolean
	missingText?: string
	error?: { message: string }
	name?: string
}

const SelectRadioSearch: React.FC<ISelectRadioSearch> = ({
	value,
	onChange,
	label,
	options,
	isrequired,
	customHelper,
	size,
	isDisabled,
	onBlur,
	multiple,
	missingText,
	error = null,
	name,
}) => {
	const intlMsg = useFormatMessage()
	const [inputValueLabel, setInputValueLabel] = useState(
		value ? options?.find((option) => option?.value === value)?.label : ''
	)

	const noOptionsText = useMemo(
		() => missingText || intlMsg('misc.noResultSearch'),
		[missingText]
	)

	const getOptionLabel = useCallback(
		(option: any) => option?.label || option,
		[]
	)

	const handleChange = useCallback(
		(_, selectedValue: any) => {
			setInputValueLabel(selectedValue?.label || '')
			onChange(selectedValue?.value)
		},
		[onChange]
	)

	const isOptionEqualToValue = useCallback(
		(option) => value === option?.key || false,
		[value]
	)

	const handleTextFieldChange = useCallback((e) => {
		setInputValueLabel(e?.target?.value)
	}, [])

	const renderOption = useCallback(
		(props, option, { selected }) => (
			<li {...props}>
				<Stack
					direction='row'
					justifyContent='space-between'
					alignItems='center'
					sx={{ width: 1 }}
				>
					{option?.label}
					<Radio
						sx={{ mr: 1 }}
						checked={selected}
						color={selected ? 'primary' : 'default'}
						size={size || 'small'}
					/>
				</Stack>
			</li>
		),
		[size]
	)

	const renderInput = useCallback(
		(params) => (
			<TextField
				{...params}
				name={name}
				onChange={handleTextFieldChange}
				label={`${label} ${isrequired ? '*' : ''}`}
				disabled={isDisabled}
				error={error?.message}
				variant='outlined'
				size={size || 'small'}
				inputProps={{
					...params.inputProps,
					value: inputValueLabel,
				}}
				onBlur={onBlur}
				helperText={error ? error.message : customHelper ? customHelper : ''}
				sx={{ opacity: isDisabled ? '0.5' : '1' }}
			/>
		),
		[
			name,
			label,
			isrequired,
			isDisabled,
			error,
			customHelper,
			size,
			onBlur,
			inputValueLabel,
		]
	)

	return (
		<>
			{options?.length && (
				<>
					<Autocomplete
						value={value}
						defaultValue={value}
						multiple={multiple}
						disabled={isDisabled}
						options={options as any}
						noOptionsText={noOptionsText}
						getOptionLabel={getOptionLabel}
						onChange={handleChange}
						isOptionEqualToValue={isOptionEqualToValue}
						renderOption={renderOption}
						renderInput={renderInput}
					/>
				</>
			)}
		</>
	)
}

export default memo(SelectRadioSearch)
