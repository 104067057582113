import React, { useCallback, useContext } from 'react'
import toast from 'react-hot-toast'

import {
	useCreateAgency,
	useUpdateAgency,
} from '../../../../../api/gql/mutations/agencyMutation'
import { EFormModalMode } from '../../../../../utils/app-models'
import AgencyStepperProvider from './AgencyStepperProvider'
import StepperModal from '../../../../../components/forms/formStepper/StepperModal'
import { StepperContext } from '../../../../../components/forms/formStepper/StepperContext'
import customToast from '../../../../../components/alert/customToast'

interface IAgencyModal {
	agencyId?: string
	selectedNetwork?
	modalUtils: any
}

const AgencyModal: React.FC<IAgencyModal> = ({
	agencyId,
	selectedNetwork,
	modalUtils,
}) => {
	const { setLoading, mode, resetContext, setCurrentStep } =
		useContext(StepperContext)
	const [createAgencyAccount] = useCreateAgency()
	const [udpatedAgencyAccount] = useUpdateAgency()

	const { closeModal, isVisible } = modalUtils

	const onValidate = useCallback(
		async (data, startingData) => {
			setLoading(true)
			const mutate =
				mode === EFormModalMode.edit
					? udpatedAgencyAccount
					: createAgencyAccount

			const {
				isAdminInvited,
				networks,
				sharedSites,
				sharedProductCategories,
				...restData
			} = data

			const specificModeFields =
				mode === EFormModalMode.edit ? { id: agencyId } : { isAdminInvited }

			await mutate({
				variables: {
					input: {
						...specificModeFields,
						...restData,
						networks: networks?.map(({ value }) => value) || [
							selectedNetwork.id,
						],
						sharedProductCategories:
							sharedProductCategories?.map(
								(sharedProductCategorie) =>
									sharedProductCategorie?.id ?? sharedProductCategorie
							) || [],
						sharedSites:
							sharedSites?.map((sharedSite) => sharedSite?.id ?? sharedSite) ||
							[],
					},
				},
				refetchQueries: [
					'getAgenciesAccounts',
					'getAgency',
					'getNetworks',
					'getSitesList',
					'getCategoriesListForAgencyStep',
				],
			})
				.then(() => {
					mode === EFormModalMode.edit
						? toast.success('success.agencyUpdate')
						: toast.success('success.agencyCreate')

					// Send toast notification only when user canAccessMyContent during the creation or if user update if canAccessMyContent to true
					if (data?.adminCanLogin && !startingData?.adminCanLogin) {
						customToast('sendEmail', 'success.contactIsShare')
					}

					setLoading(false)
					resetContext()
					setCurrentStep(0)
					closeModal()
				})
				.catch(() => setLoading(false))
		},
		[agencyId, selectedNetwork]
	)

	const handleOnClose = useCallback(() => {
		//todo add defaultValue if edit mode
		resetContext()
		setCurrentStep(0)
	}, [resetContext, setCurrentStep])

	return (
		<>
			{isVisible && (
				<AgencyStepperProvider
					agencyId={agencyId}
					selectedNetwork={selectedNetwork}
				>
					<StepperModal
						modalUtils={modalUtils}
						modalName={
							mode === EFormModalMode.create
								? 'agencies.addAgency'
								: 'agencies.editAgency'
						}
						onValidate={onValidate}
						onClose={handleOnClose}
					/>
				</AgencyStepperProvider>
			)}
		</>
	)
}

export default AgencyModal
