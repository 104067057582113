import React, { useCallback, useContext } from 'react'
import toast from 'react-hot-toast'

import Modal from '../../../../components/forms/v1'
import { ModalContext } from '../../../../utils/contexts/ModalContext'
import getModalContentConfig from './getModalContentConfig'
import { useCreateUsers } from '../../../../api/gql/mutations/usersMutations'

import { EFormModalMode } from '../../../../utils/app-models'
import { useUpdateAdminPanelAccount } from '../../../../api/gql/mutations/adminPanelAccountsMutation'

interface IAdminPanelAccountsModal {
	row?
}

const AdminPanelAccountsModal: React.FC<IAdminPanelAccountsModal> = ({
	row,
}) => {
	const { isVisible, closeModal, resetContext, setLoadingModal, mode } =
		useContext(ModalContext)

	const [updateAccount] = useUpdateAdminPanelAccount()
	const [createUser] = useCreateUsers()

	const handleCloseModal = useCallback(() => {
		closeModal()
		resetContext()
	}, [resetContext])

	const validateModal = useCallback(
		async (data) => {
			setLoadingModal(true)

			const permissionModules = Object.entries(data)
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				.filter(([key, value]) => value === true)
				.map(([key]) => key)

			const mutate = mode === EFormModalMode.edit ? updateAccount : createUser

			await mutate({
				variables: {
					input: {
						id: row.id,
						customModules: permissionModules,
						accountType: data?.accountType,
					},
				},
				refetchQueries: [
					'getAdminPanelAccount',
					'getAdminPanelAccountListModule',
				],
			})
				.then(() => {
					mode === EFormModalMode.edit
						? toast.success('success.adminPanelUpdateAccount')
						: toast.success('success.userCreate')
					setLoadingModal(false)
					resetContext()
					closeModal()
				})
				.catch(() => {
					setLoadingModal(false)
				})
		},
		[row]
	)

	return (
		<Modal
			isVisible={isVisible}
			closeModal={handleCloseModal}
			modalName={
				mode === EFormModalMode.edit
					? 'adminPanel.editAccount'
					: 'adminPanel.createAccount'
			}
			validateModal={validateModal}
			modalContentConfig={getModalContentConfig(row)}
			maxWidth={550}
		/>
	)
}

export default AdminPanelAccountsModal
