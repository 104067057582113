import React, { useEffect, useMemo } from 'react'

import { useLazyQuery } from '@apollo/client'
import { LinearProgress, Stack } from '@mui/material'

import TableImportSubmit from '../TableImportSubmit'
import TableImportTitleStep from '../TableImportTitleStep'
import { GET_CUSTOMERS_LIST_FOR_SELECT } from '../../../../../api/gql/queries/customersQueries'
import { GET_SITES_BY_CUSTOMER_ID } from '../../../../../api/gql/queries/sitesQueries'
import { useFormatMessage } from '../../../../../utils/hooks'
import DropZone from '../../../../forms/v1/formFields/DropZone'
import SelectSearch from '../../../../forms/v1/formFields/SelectSearch'

const LinearProgressCSS = { position: 'absolute', width: 1, top: 0, right: 0 }

interface ITableVehicleImportForm {
	handleClose
	loading
	watchFields
	formUtils
}

const TableVehicleImportForm: React.FC<ITableVehicleImportForm> = ({
	handleClose,
	loading,
	watchFields,
	formUtils: { errors, watch, setValue },
}) => {
	const intlMsg = useFormatMessage()
	const [customer, sites, importFile] = watchFields

	const [getCustomers, { data: dataCustomers, loading: loadingCustomers }] =
		useLazyQuery(GET_CUSTOMERS_LIST_FOR_SELECT)

	const [getSites, { data: dataSites, loading: loadingSites }] = useLazyQuery(
		GET_SITES_BY_CUSTOMER_ID
	)

	const customersFormatted = useMemo(
		() =>
			dataCustomers?.customers?.collection?.map((customer) => ({
				label: customer?.name,
				key: customer?.id,
				value: customer?.id,
			})) || [],
		[dataCustomers]
	)

	const sitesFormatted = useMemo(
		() =>
			dataSites?.sites?.collection?.map(({ name, id }) => ({
				label: name,
				key: id,
				value: id,
			})) || [],
		[dataSites]
	)

	useEffect(() => {
		getCustomers()
	}, [])

	useEffect(() => {
		if (customer) getSites({ variables: { customer } })
	}, [customer])

	/////////////////////////////////////////////////////////////////////////////

	return (
		<>
			{(loadingCustomers || loadingSites) && (
				<LinearProgress sx={LinearProgressCSS} />
			)}

			<Stack p={4} spacing={3}>
				<TableImportTitleStep
					stepNumber={1}
					disabled={!customer}
					title={'table.chooseAccount'}
					tooltipsTxt={'table.alertImportVehicle'}
				/>

				<SelectSearch
					name={'customer'}
					label={intlMsg('misc.account')}
					selectElements={customersFormatted}
					formUtils={{ errors, watch, setValue }}
				/>

				<TableImportTitleStep
					stepNumber={2}
					disabled={!sites || !customer}
					title={'table.chooseSite'}
				/>

				<SelectSearch
					name={'site'}
					label={intlMsg('misc.site')}
					selectElements={sitesFormatted}
					formUtils={{ errors, watch, setValue }}
					isDisabled={!customer}
				/>

				<TableImportTitleStep
					stepNumber={3}
					disabled={!importFile}
					title={'table.importTitleVehicle'}
				/>

				<DropZone
					name='importFile'
					dropzoneText={intlMsg('misc.uploadFile')}
					dropzoneTextError={intlMsg('errors.errorTypeFilesCSV')}
					accept={{
						'text/*': ['.csv'],
						'application/vnd.ms-excel': ['.xls'],
						'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
							['.xlsx'],
					}}
					setValue={setValue}
				/>

				<TableImportSubmit
					handleClose={handleClose}
					loading={loading}
					disabled={!customer || !sites || !importFile?.length}
				/>
			</Stack>
		</>
	)
}

export default TableVehicleImportForm
