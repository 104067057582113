export default {
	alertName: 'Alert Name',
	alertCategory: 'Category',
	alertOpen: 'Opened on',
	alertClose: 'Closed on',
	treatedOn: 'Treated on',
	alertSource: 'Source',
	LEGAL: 'Legal',
	OTHER: 'Other',
	AESTHETIC: 'Aesthetic',
	SECURITY: 'Security',
	manualAdd: 'Manual Creation',
	categoryAlert: 'Alert Category',
}
