import React, { useCallback, useEffect } from 'react'

import { Typography, Stack, Chip, IconButton } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import { useFormatMessage } from '../../../../../../../utils/hooks'

interface ITitleActors {
	setIsInfosDisplayed
	isInfosDisplayed: boolean
}

const TitleActors: React.FC<ITitleActors> = ({
	setIsInfosDisplayed,
	isInfosDisplayed,
}) => {
	const intlMsg = useFormatMessage()

	// //////////////////////////////////////////////////////////////////////////
	// Display/Hide information's
	// //////////////////////////////////////////////////////////////////////////

	const handleDisplayComment = useCallback(() => {
		setIsInfosDisplayed(!isInfosDisplayed)
		localStorage.setItem(
			'displayInformationInterventionTransport',
			JSON.stringify({
				isInfosDisplayed: !isInfosDisplayed,
			})
		)
	}, [isInfosDisplayed])

	useEffect(() => {
		const pref = JSON.parse(
			localStorage.getItem('displayInformationInterventionTransport') as string
		)
		if (pref) setIsInfosDisplayed(pref.isInfosDisplayed)
		else setIsInfosDisplayed(true)
	}, [])

	return (
		<Stack spacing={1.5}>
			<Stack direction='row' alignItems='flex-start' spacing={1}>
				<Typography variant='h2' gutterBottom mb={2}>
					{intlMsg('interventions.intervenants')}
				</Typography>

				{isInfosDisplayed ? (
					<Chip
						onClick={handleDisplayComment}
						size='small'
						icon={<InfoOutlinedIcon />}
						label={intlMsg('interventions.hideInformation')}
						color='info'
					/>
				) : (
					<IconButton size='small' onClick={handleDisplayComment}>
						<InfoOutlinedIcon fontSize='inherit' />
					</IconButton>
				)}
			</Stack>
		</Stack>
	)
}
export default TitleActors
