import React, { useCallback } from 'react'

import getNestedObjectFromKey from 'lodash.get'

import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import MuiTableBody from '@mui/material/TableBody'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import { Box } from '@mui/material'

import { maxStringDisplay } from '../../../../utils/string'

const stickyRightCSS = {
	position: 'sticky',
	right: '0px',
	paddingRight: '0px!important',
	height: '48px',
	borderTop: 'solid 1px #dddddd73',
	boxShadow: 'inset #dddddd1f -1px 0px 7px 2px',
	borderBottom: 'solid 0px #dddddd73',
	zIndex: '10',
}

const stickyLeftCSS = {
	position: 'sticky',
	zIndex: 1,
	left: '0px',
	paddingRight: '0px!important',
	borderTop: 'solid 1px #f0f0f0',
	borderBottom: 'solid 1px #f0f0f0',
}

const tableCellCSS = (stickyFirstCol, sticky, tableCellAlign, j) => ({
	fontSize: ({ typography }) => typography.caption.fontSize,
	paddingRight: '12px!important',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	...(stickyFirstCol && stickyLeftCSS),
	...(sticky && stickyRightCSS),
	textAlign: tableCellAlign ?? 'inherit',
	paddingLeft: j === 1 && !stickyFirstCol ? '0.5rem' : 'inherit',
	height: '48px',
})

const checkBoxCSS = (isItemSelected, colorBorderLeftSelected) => ({
	display: 'flex',
	alignItems: 'center',
	minHeight: '48px',
	borderLeft: '4px solid #00000000',
	'& .Mui-checked': {
		color: '#3F51B5',
	},
	...(isItemSelected && {
		borderLeft: ({ palette }) =>
			`4px solid ${
				colorBorderLeftSelected ? colorBorderLeftSelected : palette.primary.main
			}`,
		borderRadius: 1,
	}),
})

const TableRowCSS = {
	borderTop: 'solid 1px #dddddd73',
	borderBottom: 'solid 0px #dddddd73',

	'& .cellCSS': { maxHeight: '48px', verticalAlign: 'middle', height: ' 100%' },
	'&:hover': {
		backgroundColor: '#f6f6f6',
	},
	'&:hover .stickyCellLeftCSS': {
		backgroundColor: '#f6f6f6',
	},
	'&:hover .Mui-selected .stickyCellLeftCSS': {
		backgroundColor: '#e5ebf8!important',
	},
	'&.Mui-selected .stickyCellLeftCSS': {
		backgroundColor: '#edf2ff!important',
	},
}

const isSelected = (row: any, selectedRow: any) =>
	selectedRow.findIndex(({ id }) => id === row.id) !== -1

interface ITableBody {
	rows
	tableConfig
	activeColumns
	selectedRow
	setSelectedRow
	maxCharacters?: number
	colorBorderLeftSelected?: string
	disabledCheckbox?: boolean
}

const TableBody: React.FC<ITableBody> = ({
	tableConfig,
	rows,
	activeColumns,
	selectedRow,
	setSelectedRow,
	maxCharacters = 20,
	colorBorderLeftSelected,
	disabledCheckbox,
}) => {
	const handleClickSelectRow = useCallback(
		(row) => (e) => {
			const newSelected: string[] = [...selectedRow]

			if (e.target.checked) newSelected.push(row)
			else {
				const elemIndex = selectedRow.findIndex(({ id }) => id === row.id)
				newSelected.splice(elemIndex, 1)
			}

			setSelectedRow(newSelected)
		},
		[selectedRow]
	)

	return (
		<MuiTableBody>
			{rows.map((row: any, i) => {
				const isItemSelected = isSelected(row, selectedRow)
				return (
					<TableRow
						key={`row$-${i}-${row.id}`}
						hover
						role='checkbox'
						aria-checked={isItemSelected}
						tabIndex={-1}
						selected={isItemSelected}
						sx={TableRowCSS}
					>
						{!disabledCheckbox && (
							<TableCell padding='checkbox'>
								<Box sx={checkBoxCSS(isItemSelected, colorBorderLeftSelected)}>
									<Checkbox
										checked={isItemSelected}
										size='small'
										onClick={handleClickSelectRow(row)}
									/>
								</Box>
							</TableCell>
						)}
						<>
							{tableConfig?.map(
								(
									{
										dataKey,
										personalizedCell,
										columnLabel,
										visible,
										tableCellAlign,
										sticky = false,
										stickyFirstCol = false,
										maxCharacters: customMaxCharacters,
									},
									j
								) => {
									if (
										!activeColumns ||
										(activeColumns &&
											activeColumns?.find?.((e) => e === columnLabel)) ||
										visible
									) {
										const diplayedData =
											getNestedObjectFromKey(row, dataKey, '-') || '-'

										return dataKey ? (
											<TableCell
												padding='none'
												key={`cell-${j}-${i}`}
												component='th'
												scope='row'
												size='small'
												sx={tableCellCSS(
													stickyFirstCol,
													sticky,
													tableCellAlign,
													j
												)}
											>
												<Box
													className={stickyFirstCol ? 'stickyCellLeftCSS' : ''}
												>
													<Typography color='inherit' variant='caption' noWrap>
														{diplayedData?.length > customMaxCharacters ||
														maxCharacters ? (
															<Tooltip
																title={diplayedData}
																aria-label={diplayedData}
															>
																<span>
																	{maxStringDisplay(
																		diplayedData,
																		customMaxCharacters || maxCharacters
																	)}
																</span>
															</Tooltip>
														) : (
															diplayedData
														)}
													</Typography>
												</Box>
											</TableCell>
										) : (
											<TableCell
												padding='none'
												key={`cell-${j}-${i}`}
												component='th'
												scope='row'
												sx={tableCellCSS(
													stickyFirstCol,
													sticky,
													tableCellAlign,
													j
												)}
											>
												<Box
													display='flex'
													alignItems='center'
													className={
														stickyFirstCol ? 'stickyCellLeftCSS' : 'cellCSS'
													}
												>
													{personalizedCell(row, i)}
												</Box>
											</TableCell>
										)
									}
								}
							)}
						</>
					</TableRow>
				)
			})}
		</MuiTableBody>
	)
}

export default TableBody
