import React, { cloneElement, useCallback, useContext } from 'react'

import { Stack, Popover } from '@mui/material'

import FooterPopover from './FooterPopover'
import { FiltersBtnContext } from './FiltersBtnContext'
import FiltersHeader from './FiltersHeader'

const PaperPropsCSS = { sx: { minWidth: 500, maxWidth: 500 } }

interface IFilterPopover {
	id
	anchorEl
	open
}

const FilterPopover: React.FC<IFilterPopover> = ({ id, anchorEl, open }) => {
	const { handleClose, filtersComponent } = useContext(FiltersBtnContext)

	const onClosePopover = useCallback(() => {
		handleClose()
	}, [])

	return (
		<Popover
			id={id}
			open={open}
			anchorEl={anchorEl}
			onClose={onClosePopover}
			PaperProps={PaperPropsCSS}
		>
			<FiltersHeader />
			<Stack p={2} spacing={2}>
				{cloneElement(filtersComponent)}
			</Stack>
			<FooterPopover />
		</Popover>
	)
}

export default FilterPopover
