import React from 'react'

import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
} from '@mui/material'
import { useFormatMessage } from '../../../../../utils/hooks'

interface IConfirmCancelExport {
	open
	closeConfirmModal
	cancelExport
}

const ConfirmCancelExport: React.FC<IConfirmCancelExport> = ({
	open,
	closeConfirmModal,
	cancelExport,
}) => {
	const intlMsg = useFormatMessage()

	return (
		<Dialog open={open} onClose={closeConfirmModal}>
			<DialogTitle>{intlMsg('exportTable.DialogTitle')}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{intlMsg('exportTable.DialogContentText')}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={closeConfirmModal}>{intlMsg('misc.no')}</Button>
				<Button onClick={cancelExport} autoFocus>
					{intlMsg('misc.yes')}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default ConfirmCancelExport
