import React from 'react'
import { Box } from '@mui/material'
import ReCAPTCHA from 'react-google-recaptcha'

interface ICapchaGoogleProps {
	setCapchaValid: (isValid: boolean) => void
}

const capchaGoogleSx = { width: 1, mb: 2 }

const reCaptchaStyle = { display: 'inline-block' }

const sitekey = process.env.REACT_APP_CAPCHA_GOOGLE || ''

const CapchaGoogle: React.FC<ICapchaGoogleProps> = ({ setCapchaValid }) => {
	const handleCapchaChange = (value: string | null) => {
		setCapchaValid(!!value)
	}

	return (
		<Box sx={capchaGoogleSx}>
			<ReCAPTCHA
				style={reCaptchaStyle}
				sitekey={sitekey}
				onChange={handleCapchaChange}
			/>
		</Box>
	)
}

export default CapchaGoogle
