// ###### 1 => Meteor imports ############

// ###### 2 => React imports #############
import React, { useContext, useMemo } from 'react'
import { IntlProvider } from 'react-intl'

// ###### 3 => Npm imports ###############

// ###### 4 => Local folder imports ######
import AppLocale from './AppLocale'
import { AuthContext } from '../../utils/contexts/AuthContext'

interface IIntlProviderWrapperProps {
	children: React.ReactNode
}

const IntlProviderWrapper: React.FC<IIntlProviderWrapperProps> = (props) => {
	const { localLanguage } = useContext(AuthContext)
	const translations = useMemo(() => AppLocale[localLanguage], [localLanguage])

	return (
		<IntlProvider locale={localLanguage} messages={translations}>
			{props.children}
		</IntlProvider>
	)
}

export default IntlProviderWrapper
