import React, { Context, useCallback, useContext, useMemo } from 'react'

import { Stack, Switch, FormControlLabel, Checkbox } from '@mui/material'
import IconToolTips from '../../icons/IconToolTips'
import { IFormModalContext } from '../formModal/FormModalContext'
import { IStepperContext } from '../formStepper/StepperContext'

const FormControlLabelCSS = {
	width: 1,
	'&.MuiFormControlLabel-root': {
		color: ({ palette }) => palette.grey[700],
		borderColor: ({ palette }) => `${palette.grey[400]}!important`,
		border: 'solid 1px',
		borderRadius: 1,
		m: 0,
		width: 1,
		height: '35.6px',
	},
	'&.MuiFormControlLabel-root:hover': {
		color: ({ palette }) => palette.grey[700],
		borderColor: ({ palette }) => `${palette.grey[700]}!important`,
	},
	'&.Mui-disabled': {
		color: ({ palette }) => palette.grey[300],
		'& svg': { color: ({ palette }) => palette.grey[300] },
	},
} as const

interface IFormCheckBoxOrSwitch {
	name: string
	context: Context<IFormModalContext> | Context<IStepperContext>
	bordered?: boolean
	label?: string
	onBlur?
	disabled?: boolean
	toolTip?: { text: string; icon: any } | null
	isCheckbox?: boolean
	playwrightId?: string
	specialOnChange?: any
}

const FormCheckBoxOrSwitch: React.FC<IFormCheckBoxOrSwitch> = ({
	name,
	label,
	bordered = false,
	onBlur,
	disabled = false,
	toolTip = null,
	isCheckbox = false,
	playwrightId = '',
	specialOnChange,
	context,
}) => {
	const { setValue, data } = useContext<IFormModalContext | IStepperContext>(
		context as any
	)
	const value = useMemo(() => data[name] || false, [data[name]])

	const handleChange = useCallback(
		(e) => {
			const checked = e.target.checked
			specialOnChange ? specialOnChange(name, checked) : setValue(name, checked)
		},
		[specialOnChange, setValue]
	)

	const controlElement = useMemo(
		() =>
			isCheckbox ? (
				<Checkbox
					name={name}
					disabled={disabled}
					color={value ? 'primary' : 'default'}
					size='small'
					onChange={handleChange}
					checked={value}
					id={playwrightId}
				/>
			) : (
				<Switch
					name={name}
					disabled={disabled}
					color={value ? 'primary' : 'default'}
					size='small'
					onChange={handleChange}
					checked={value}
					id={playwrightId}
				/>
			),
		[isCheckbox, name, disabled, value, handleChange, playwrightId]
	)

	const labelComponent = useMemo(
		() => (
			<Stack
				direction='row'
				alignItems='center'
				justifyContent='space-between'
				sx={{ lineHeight: '12px' }}
			>
				<div>{label}</div>
				{toolTip && <IconToolTips txt={toolTip.text} Icon={toolTip.icon} />}
			</Stack>
		),
		[label, toolTip]
	)

	return (
		<FormControlLabel
			onBlur={onBlur}
			control={controlElement}
			label={labelComponent}
			sx={bordered ? FormControlLabelCSS : {}}
		/>
	)
}

export default FormCheckBoxOrSwitch
