import React, { useState, useCallback, useMemo } from 'react'

import {
	Accordion,
	AccordionSummary,
	Typography,
	AccordionDetails,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { useFormatMessage } from '../../../../utils/hooks'

const boldCSS = {
	fontWeight: ({ typography }) => typography.fontWeightBold as any,
}

const AccordionCSS = { borderRadius: 2, pl: 1, '&::before': { opacity: 0 } }

interface ICommentIntervention {
	comment: string
	title: string
	sx?
}

const CommentIntervention: React.FC<ICommentIntervention> = ({
	comment,
	title,
	sx = {},
}) => {
	const intlMsg = useFormatMessage()
	const [expanded, setExpanded] = useState<any>(comment?.length)
	const accordionSx = useMemo(() => ({ ...AccordionCSS, ...sx }), [sx])

	const handleChange = useCallback(
		(_event: React.SyntheticEvent, isExpanded: boolean) => {
			setExpanded(isExpanded)
		},
		[]
	)

	return (
		<Accordion expanded={expanded} onChange={handleChange} sx={accordionSx}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography variant='body2'>{intlMsg(title)}</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Typography variant='caption' sx={boldCSS}>
					{comment || '-'}
				</Typography>
			</AccordionDetails>
		</Accordion>
	)
}
export default CommentIntervention
