import React, { useContext, useMemo, memo } from 'react'

import { Box, Card, Divider, Typography } from '@mui/material'

import WTransportTripSummaryHeader from './WTransportTripSummaryHeader'
import WTransportTripSummaryAddress from './WTransportTripSummaryAddress'
import WTransportTripSummaryFormsProducts from './WTransportTripSummaryFormsProducts'
import WTransportTripSummaryGuidelines from './WTransportTripSummaryGuidelines'
import { AuthContext } from '../../../utils/contexts/AuthContext'
import { EAccountType } from '../../../utils/app-models'
import { useFormatMessage } from '../../../utils/hooks'

const containerTitleCSS = { position: 'absolute', right: 0, display: 'flex' }
const svgLeftTitleCSS = { right: '87px', height: '27px' }

interface IWTransportTripSummary {
	transportTripType: string
	itTrip
	maxHeightUtils: {
		maxHeight: any
		setMaxHeight: any
	}
}

const WTransportTripSummary: React.FC<IWTransportTripSummary> = ({
	transportTripType,
	itTrip,
	maxHeightUtils,
}) => {
	const intlMsg = useFormatMessage()
	const { checkAccountsType } = useContext(AuthContext)
	const currentUserIsGuest = useMemo(
		() => checkAccountsType([EAccountType.guest]),
		[]
	)

	const colorCSS = useMemo(
		() => (transportTripType === 'delivery' ? '#FF4081' : '#64B6F7'),
		[transportTripType]
	)

	const cardCSS = useMemo(
		() => ({
			borderTop: `solid 2px ${colorCSS}`,
			position: 'relative',
		}),
		[colorCSS]
	)

	const transportTypeCSS = useMemo(
		() => ({
			color: 'white',
			backgroundColor: colorCSS,
			fontWeight: 500,
			p: '4px 16px',
			pt: '3px',
			pl: 0,
		}),
		[colorCSS]
	)

	return (
		<Card
			id={`${transportTripType}WidgetSummary`}
			sx={cardCSS}
			data-test-id={`Playwright-card-${transportTripType}`}
		>
			<Box sx={containerTitleCSS}>
				<Box
					component='img'
					src={`/img/view-modal/dashboard-transport/${transportTripType}.svg`}
					sx={svgLeftTitleCSS}
				/>
				<Typography variant='body2' sx={transportTypeCSS}>
					{intlMsg(`interventions.${transportTripType}`)}
				</Typography>
			</Box>

			<WTransportTripSummaryHeader itTrip={itTrip} />
			<Divider variant='middle' />
			<WTransportTripSummaryAddress
				itTrip={itTrip}
				maxHeightUtils={maxHeightUtils}
			/>
			<Divider variant='middle' />
			<WTransportTripSummaryFormsProducts
				itTrip={itTrip}
				maxHeightUtils={maxHeightUtils}
			/>
			{!currentUserIsGuest && (
				<>
					<Divider variant='middle' />
					<WTransportTripSummaryGuidelines
						transportTripType={transportTripType}
						itTrip={itTrip}
						maxHeightUtils={maxHeightUtils}
					/>
				</>
			)}
		</Card>
	)
}

export default memo(WTransportTripSummary)
