import React from 'react'
import { Popover } from '@mui/material'

const anchorOrigin = { vertical: 'bottom', horizontal: 'left' } as any
const transformOrigin = { vertical: 'top', horizontal: 'left' } as any
const PopoverCSS = {
	'& .MuiPopover-paper': {
		boxShadow: '0 4px 4px 0px rgba(0,0,0,0.19)',
		minWidth: '253px',
		maxWidth: '253px',
	},
}

interface IMultiItemsPopover {
	handleClose: () => void
	open: boolean
	anchorEl: null | HTMLElement
	children: any
}

const MultiItemsPopover: React.FC<IMultiItemsPopover> = ({
	handleClose,
	open,
	anchorEl,
	children,
}) => {
	return (
		<Popover
			open={open}
			anchorEl={anchorEl}
			onClose={handleClose}
			anchorOrigin={anchorOrigin}
			transformOrigin={transformOrigin}
			sx={PopoverCSS}
		>
			{children}
		</Popover>
	)
}

export default MultiItemsPopover
