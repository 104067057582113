import React from 'react'

import InterventionModalControlInfos from './interventionModalControlInfos'
import interventionTransportModalValidationSchema from './interventionTransportModalValidationSchema'

const interventionTransportModalConfig = [
	{
		component: <InterventionModalControlInfos />,
		schema: interventionTransportModalValidationSchema,
	},
]

export default interventionTransportModalConfig
