import React from 'react'

import { Stack } from '@mui/material'
import TitleField from './TitleField'
import { useFormatMessage } from '../../../../../../utils/hooks'

const boxSignature = {
	width: '200px',
	height: '100px',
	background: '#2f5dd12e',
	border: '1px dashed #2F5DD1',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
}

interface IFieldSignature {
	field
	deleteField
	index
	updateField
	insertField
}

const FieldSignature: React.FC<IFieldSignature> = ({
	field: { id, name, values, fieldType },
	deleteField,
	index,
	updateField,
	insertField,
}) => {
	const intlMsg = useFormatMessage()

	return (
		<Stack p={1}>
			<TitleField
				deleteField={deleteField}
				index={index}
				fieldToEdit={{
					id,
					name,
					values,
					fieldType,
					updateField,
					index,
				}}
				isRequired={values?.isRequired}
				insertField={insertField}
				values={values}
				fieldType={fieldType}
			/>
			<Stack direction='row' justifyContent='center' p={2} sx={{ width: 1 }}>
				<Stack
					direction='row'
					justifyContent='center'
					alignItems='center'
					sx={boxSignature}
				>
					{intlMsg('formTemplate.signZone')}
				</Stack>
			</Stack>
		</Stack>
	)
}

export default FieldSignature
