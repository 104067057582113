/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react'
import { EHubeeTheme } from '../../utils/app-models'
import { IGlobalTheme } from './globalTheme/globalThemeTypes'
import { IMuiTheme } from './themeTypes'

export type IThemeContext = {
	theme: IGlobalTheme & IMuiTheme
	themeName: EHubeeTheme
	setTheme: any
	setThemeName: any
}

export const ThemeContext: React.Context<IThemeContext> = createContext(
	{} as IThemeContext
)
