import React from 'react'

import { Alert } from '@mui/material'
import VmCardExpended from '../../../../components/viewModal/vmCardExpended'
import DataListGrid from '../../../../components/list/dataList/DataListGrid'
import DataList from '../../../../components/list/dataList'
import { EInterventionTypes, dataListType } from '../../../../utils/app-models'
import translateIntlMsgString from '../../../../utils/translateIntlMsgString'

const alertCSS = {
	boxShadow: '0 0 11px -4px rgba(0,0,0,0.19)',
	color: '#663d00',
	backgroundColor: '#fdf4e7',
	fontWeight: 'bold',
	'& svg': { color: '#ff9800' },
}

const titleByType = {
	[EInterventionTypes.transport]: {
		operator: 'pdfForms.signTransportConveyer',
		client: 'pdfForms.signTransportDest',
		missing: `interventions.missingRecipient`,
	},
	[EInterventionTypes.control]: {
		operator: 'interventions.signOperator',
		client: 'interventionForms.signingCustomer',
		missing: `interventions.missingCustomer`,
	},
	[EInterventionTypes.service]: {
		operator: 'interventions.signOperator',
		client: 'interventionForms.signingCustomer',
		missing: `interventions.missingCustomer`,
	},
}

interface IEndingForm {
	endingForm
	missionType
	endingUserType: 'client' | 'operator'
	cardIsExpended: boolean
}

const EndingForm: React.FC<IEndingForm> = ({
	endingForm,
	missionType,
	endingUserType,
	cardIsExpended,
}) => {
	return (
		<>
			{endingUserType === 'client' &&
			endingForm?.value?.client?.clientSkipped ? (
				<Alert severity='info' sx={alertCSS}>
					{translateIntlMsgString(titleByType?.[missionType]?.missing)}
				</Alert>
			) : (
				<VmCardExpended
					title={titleByType?.[missionType]?.[endingUserType]}
					titleGutter
					defaultExpended={cardIsExpended}
				>
					<DataListGrid>
						<DataList
							dataType={dataListType.signature}
							title='formTemplate.signature'
							data={[{ url: endingForm?.value?.[endingUserType]?.signature }]}
							fullWidth
						/>

						<DataList
							dataType={dataListType.comment}
							title='misc.comment2'
							data={[{ comment: endingForm?.value?.[endingUserType]?.comment }]}
							fullWidth
						/>

						{endingUserType === 'client' && (
							<DataList
								dataType={dataListType.notation}
								title='misc.note'
								data={[{ note: endingForm?.value?.client?.grade }]}
								fullWidth
							/>
						)}
					</DataListGrid>
				</VmCardExpended>
			)}
		</>
	)
}
export default EndingForm
