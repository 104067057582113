/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/display-name */
import React from 'react'

import { Chip } from '@mui/material'

import { useUpdateInterventionStatuses } from '../../../../../../api/gql/mutations/interventionsMutation'
import { useRemoveIntervention } from '../../../../../../api/gql/mutations/interventionsMutation'
import { useInterventionStatusesList } from '../../../../../../api/gql/queries/interventionsQueries'
import TimeCell from '../../../../tableCell/TimeCell'
import ChipCountListPopover from '../../../../../multiItemsPopoverList/popover/simpleListPopover'
import DateToFrom from '../../../../../date/DateToFrom'
import ActionPopoverBtn from '../../../../../popover/actionPopover'
import ChipColorStatus from '../../../../../chip/ChipColorStatus'
import OpenLinkCell from '../../../../tableCell/OpenLinkCell'
import { ROUTES } from '../../../../../../routes/consts'
import {
	EAccountType,
	EChipColorStatus,
	EModule,
	EPermissionScope,
} from '../../../../../../utils/app-models'
import { formatDate } from '../../../../../../utils/date'
import TasksAndCategoriesPopover from '../../../../../multiItemsPopoverList/popover/tasksAndCategoriesPopover'
import ActionsBtn from '../../../../tableCell/actionsBtn'
import InterventionModal from '../../../../../../view/dashboard/interventions/interventionControl/interventionModal'
import UsersPopover from '../../../../../multiItemsPopoverList/popover/usersPopover'
import UserAvatarSlidePanel from '../../../../../avatar/UserAvatarSlidePanel'

const vehicleListInterventionsControlByReferenceViewModalTable = [
	{
		columnLabel: 'interventions.ref',
		orderBy: 'intervention_reference',
		stickyFirstCol: true,
		personalizedCell: ({ intervention: { reference, id, isSharedWithMe } }) => (
			<>
				{reference ? (
					<OpenLinkCell
						viewModalLink={`${ROUTES.VIEWMODAL.INTERVENTION_REFERENCES.url}${id}`}
						txt={reference}
						isSharedWithMe={isSharedWithMe}
					/>
				) : (
					'-'
				)}
			</>
		),
	},

	{
		columnLabel: 'misc.account',
		modules: [EModule.crm],
		orderBy: 'intervention_orderingCustomer_name',
		personalizedCell: ({ intervention: { orderingCustomer } }) => (
			<OpenLinkCell
				viewModalLink={`${ROUTES.VIEWMODAL.CUSTOMERS.url}${orderingCustomer?.id}`}
				txt={orderingCustomer?.name}
			/>
		),
	},

	{
		columnLabel: 'misc.site',
		orderBy: 'intervention_orderingSite_name',
		personalizedCell: ({ intervention: { orderingSite } }) => (
			<OpenLinkCell
				viewModalLink={`${ROUTES.VIEWMODAL.SITE.url}${orderingSite?.id}`}
				txt={orderingSite?.name}
				maxCharacters={40}
				modules={[EModule.site]}
			/>
		),
	},

	{
		columnLabel: 'interventions.date',
		orderBy: 'intervention_dateFrom',
		personalizedCell: ({ intervention: { dateTo, dateFrom } }) => (
			<DateToFrom dateTo={dateTo} dateFrom={dateFrom} />
		),
	},

	{
		columnLabel: 'interventions.statusInter',
		orderBy: 'intervention_status_code',
		tableCellAlign: 'center',
		cellAlign: 'center',
		personalizedCell: ({ intervention: { status, id } }) => {
			return (
				<ActionPopoverBtn
					inputs={{ query: useInterventionStatusesList }}
					selected={{
						id: status?.id,
						color: status?.color,
						name: status?.name,
						code: status?.code,
					}}
					titlePopover={'misc.status'}
					keyTranslate='interventions'
					mutation={{
						id,
						query: useUpdateInterventionStatuses,
						refreshQuery: ['interventionProgrammedVehicles'],
						successMessage: 'success.interventionStatusUpdate',
						attribute: 'status',
					}}
					permissions={[EPermissionScope.interventionUpdate]}
				/>
			)
		},
	},

	{
		columnLabel: 'vehicle.statusVehicle',
		orderBy: 'status_code',
		personalizedCell: ({ status: { name, code, color } }) => {
			return (
				<ChipColorStatus
					label={name}
					color={color}
					code={code}
					sx={{ width: '100px' }}
					type={EChipColorStatus.vehicle}
				/>
			)
		},
	},

	{
		columnLabel: 'interventions.task',
		personalizedCell: ({ products: { collection } }) => (
			<TasksAndCategoriesPopover tasks={collection} />
		),
	},

	{
		columnLabel: 'interventions.intervenants',
		orderBy: 'intervention_actors_lastName',
		accountsTypeDenied: [EAccountType.guest],
		personalizedCell: ({
			intervention: {
				actors: { collection },
			},
		}) => (
			<UsersPopover
				data={collection?.map(({ id, lastName, firstName, account }) => ({
					firstName,
					lastName,
					id,
					account,
				}))}
				title='interventions.actors'
			/>
		),
	},

	{
		columnLabel: 'interventions.managerMission',
		orderBy: 'intervention_manager_lastName',
		accountsTypeDenied: [EAccountType.guest],
		personalizedCell: ({ intervention: { manager } }) => (
			<UserAvatarSlidePanel
				firstName={manager?.firstName}
				lastName={manager?.lastName}
				userId={manager?.id}
				accountId={manager?.account?.id}
				displayName={false}
			/>
		),
	},

	{
		columnLabel: 'misc.agencies',
		modules: [EModule.network],
		orderBy: 'intervention_agencies_agencyCustomDetails_agencyName',
		personalizedCell: ({ agencies }) => (
			<ChipCountListPopover
				text='misc.agencies'
				data={
					agencies?.collection?.map(({ agencyCustomDetails, id }) => {
						return { name: agencyCustomDetails[0]?.agencyName, id }
					}) || []
				}
				viewModalLink={ROUTES.VIEWMODAL.AGENCIES.url}
				permissions={[
					EPermissionScope.networkView,
					EPermissionScope.networkManage,
				]}
			/>
		),
	},

	{
		columnLabel: 'misc.alerts',
		personalizedCell: ({ countAlerts }) => (
			<Chip
				size='small'
				color={countAlerts === 0 ? 'default' : ('alert' as any)}
				disabled={countAlerts === 0 || false}
				label={countAlerts}
			/>
		),
	},

	{
		columnLabel: 'misc.addAt',
		orderBy: 'intervention_dateCreated',
		tableCellAlign: 'center',
		cellAlign: 'center',
		personalizedCell: ({ intervention: { dateCreated } }) => (
			<TimeCell date={dateCreated ? formatDate(dateCreated) : '-'} />
		),
	},

	{
		fixWidth: '50px',
		columnLabel: '',
		visible: true,
		tableCellAlign: 'center',
		cellAlign: 'center',
		sticky: true,
		personalizedCell: ({ intervention: { id } }: any) => (
			<ActionsBtn
				duplicate
				rowId={id}
				deleted={{
					query: useRemoveIntervention,
					refreshQuery: [
						'interventionProgrammedVehicles',
						'getInterventionNumbersByVehicle',
					],
					successMessageDelete: 'success.interventionDeleted',
					messageConfirm: {
						title: 'confirmModal.deleteInterventionsTitle',
						message: 'confirmModal.deleteInterventionMessage',
					},
				}}
				editModal={
					<InterventionModal interventionId={id} modalUtils={{} as any} />
				}
				viewModalLink={`${ROUTES.VIEWMODAL.INTERVENTION_REFERENCES.url}${id}`}
				permissions={{
					update: [EPermissionScope.interventionUpdate],
					remove: [EPermissionScope.interventionRemove],
					delete: [EPermissionScope.interventionDelete],
				}}
			/>
		),
	},
]

export default vehicleListInterventionsControlByReferenceViewModalTable
