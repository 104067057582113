import React from 'react'

import { useNavigate, useLocation } from 'react-router-dom'
import { List, ListItemText, Stack, ListItemButton } from '@mui/material'

import SquareColor from '../../../color/SquareColor'
import { handleOpenViewModal } from '../../../../utils/utils'
import { ROUTES } from '../../../../routes/consts'

const ListCSS = {
	'& .MuiListItemButton-root': {
		paddingTop: 0,
		paddingBottom: 0,
	},
	'& .MuiListItemText-secondary': { lineHeight: '13px' },
}

interface IVehicleInterventionListItem {
	handleClose
	data
	StatusVehicle
}

const VehicleInterventionListItem: React.FC<IVehicleInterventionListItem> = ({
	handleClose,
	data,
	StatusVehicle,
}) => {
	const navigate = useNavigate()
	const location: any = useLocation()

	return (
		<List dense disablePadding sx={ListCSS}>
			{data?.map(
				({
					status: status,
					vehicle: {
						id,
						vehicleDetail: { immat, name, vin },
					},
				}) => {
					return (
						<ListItemButton
							key={id}
							onClick={handleOpenViewModal(
								navigate,
								location,
								`${ROUTES.VIEWMODAL.VEHICLES.url}${id}`,
								handleClose
							)}
						>
							<Stack
								direction='row'
								spacing={1}
								alignItems='center'
								justifyContent='space-between'
								sx={{ width: 1 }}
							>
								<ListItemText primary={immat || vin} secondary={name || '-'} />

								<Stack
									direction='row'
									spacing={0.5}
									alignItems='center'
									justifyContent='flex-end'
								>
									<div>{StatusVehicle(status)}</div>
									<SquareColor statusColor={status.color} />
								</Stack>
							</Stack>
						</ListItemButton>
					)
				}
			)}
		</List>
	)
}

export default VehicleInterventionListItem
