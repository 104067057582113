/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */

import {
	allFieldsModelListConfig,
	generateTemplateDefaultValue,
} from '../formTemplateFieldsList/utils'

import { checkFieldsLimitation } from './fieldsLimitation'

///////////////////////////////////////////////////////////
// DragEnd PAGE
///////////////////////////////////////////////////////////

export const onDragEndPage = (movePage, setSelectedPageNbr) => (result) => {
	const {
		source: { droppableId, index: sourceIndex },
		destination,
	} = result

	if (!result.destination) {
		return
	}

	if (destination.index === sourceIndex) {
		return
	}

	droppableId === 'page' && movePage(sourceIndex, destination.index)
	setSelectedPageNbr(destination.index)
}

///////////////////////////////////////////////////////////
// DragEnd Field
///////////////////////////////////////////////////////////

export const onDragEndField =
	(
		insertField,
		moveField,
		intlMsg,
		setLimitationFields,
		limitationFields,
		setIsOpenLimitModal
	) =>
	(result) => {
		const fieldType =
			allFieldsModelListConfig.find(
				({ fieldModel }) => result?.draggableId === fieldModel
			)?.fieldType || result?.draggableId // if extendDraggableId exist the field is a model - ex 'picture4anglesModel' is a field's model of field 'picture'

		if (
			(result.source.droppableId === 'fieldList' &&
				limitationFields[result.draggableId]?.isLimitReached) ||
			checkFieldsLimitation(result.draggableId, limitationFields)
		) {
			setIsOpenLimitModal(result.draggableId)
		} else {
			const {
				source: { index: sourceIndex, droppableId },
				destination,
				draggableId,
			} = result

			if (!result.destination) return

			if (droppableId === 'fieldItems' && destination?.index === sourceIndex)
				return

			if (droppableId === 'fieldList') {
				insertField(destination?.index, {
					name: intlMsg(`formTemplate.${draggableId}`),
					fieldType: fieldType,
					fieldModel: draggableId,
					values: generateTemplateDefaultValue(
						draggableId,
						setLimitationFields
					),
				})
				return
			}

			moveField(sourceIndex, destination?.index)
		}
	}

///////////////////////////////////////////////////////////
// Custom Placeholder
///////////////////////////////////////////////////////////

const queryAttr = 'data-rbd-draggable-id'

const getMarginByElement = (total, curr) => {
	const style = curr.currentStyle || window.getComputedStyle(curr)
	const marginBottom = parseFloat(style.marginBottom)
	return total + curr.clientHeight + marginBottom
}

export const handleDragStart = (setPlaceholderProps) => (event) => {
	const draggedDOM = getDraggedDom(event.draggableId, queryAttr)

	if (!draggedDOM) {
		return
	}

	const { clientHeight } = draggedDOM
	const sourceIndex = event.source.index

	const clientY =
		parseFloat(
			window.getComputedStyle(draggedDOM.parentNode as any).paddingTop
		) +
		[...(draggedDOM?.parentNode?.children as any)]
			.slice(0, sourceIndex)
			.reduce((total, curr) => getMarginByElement(total, curr), 0)

	setPlaceholderProps({
		clientHeight,
		clientY,
	})
}

export const handleDragUpdate =
	(setPlaceholderProps, queryAttrField) => (event) => {
		if (!event.destination) {
			return
		}

		const draggedDOMField = getDraggedDom(event?.draggableId, queryAttr)
		const draggedDOM = getDraggedDom(event?.destination?.index, queryAttrField)

		if (!draggedDOM) {
			return
		}

		const clientHeight = draggedDOMField?.clientHeight as any

		const destinationIndex = event.destination.index
		const sourceIndex = event.source.index

		const childrenArray = [...(draggedDOM?.parentNode?.children as any)]

		const movedItem = childrenArray[sourceIndex]
		childrenArray.splice(sourceIndex, 1)

		const updatedArray = [
			...childrenArray.slice(0, destinationIndex),
			movedItem,
			...childrenArray.slice(destinationIndex + 1),
		]

		const clientY =
			parseFloat(
				window.getComputedStyle(draggedDOM?.parentNode as any).paddingTop
			) +
			updatedArray
				.slice(0, destinationIndex)
				.reduce((total, curr) => getMarginByElement(total, curr), 0)

		setPlaceholderProps({
			clientHeight,
			clientY,
		})
	}

const getDraggedDom = (draggableId, queryAttr) => {
	const domQuery = `[${queryAttr}='${draggableId}']`
	const draggedDOM = document.querySelector(domQuery)
	return draggedDOM
}
