export default {
	modelEdition: 'Edition du modèle de formulaire',
	modelName: 'Nom du modèle',
	reportTitle: 'Titre du formulaire',
	description: 'Description',
	account: 'Compte',
	footer: 'Pied-de-page',
	pageName: 'Nom de la page',
	vehicle: 'Véhicule',
	seizure: 'Saisie',
	specificField: 'Champs spécifiques',
	modelField: 'Modèles',
	setting: 'Paramètres',
	deletePage: 'Supprimer la page',

	published: 'Publié',
	draft: 'Brouillon',

	control: 'Contrôle',
	date: 'Date',
	parkDate: 'Date parc',
	layout: 'Mise en page',
	picture: 'Photo',
	pictureVehicle: 'Véhicule',
	document: 'Document',
	doubleSidedPhotoDocument: 'Photo document recto/verso',
	text: 'Texte',
	title: 'Titre',
	selection: 'Sélection',
	numbers: 'Numérotation',
	signature: 'Signature',
	rating: 'Notation',
	media: 'Média',
	instruction: 'Instruction',
	barCode: 'Code Barre',
	mileage: 'Kilométrage',

	picture4anglesModel: 'Photo véhicule 4 angles',
	picture4anglesAndWheelsModel: 'Photo véhicule 4 angles + 4 roues',

	formTitleField: 'Intitulé',
	formFormat: 'Format',
	longText: 'Texte long',
	simpleText: 'Texte court',
	formRequire: 'Obligatoire',
	alert: 'Alerte',
	formSummaryDisplay: 'Afficher dans la synthèse',

	choice: 'Choix',
	color: 'Couleur',

	security: 'Sécurité',
	legal: 'Légal',
	aesthetic: 'Esthétique',
	other: 'Autre',

	verificationType: "Type d'alerte",
	canAlert: 'Déclencher une alerte',
	selectedChoiceToAlert: 'Déclencher une alerte sur',
	controlFieldResume:
		'Ce champ de contrôle déclenchera sur le véhicule une alerte de type {type} si la valeur est {value}',
	selectionType: 'Type de sélection',
	multiples: 'Choix multiples',
	unique: 'Choix unique',

	slider: 'Slider',
	stars: 'Étoiles',
	min: 'Minimum',
	max: 'Maximum',
	step: 'Espacement',
	ratingType: 'Type de notation',

	car: 'Automobile',
	vul: 'VUL',
	bus: 'BUS',
	pl: 'PL',

	frontFace: 'Face avant',
	leftFace: 'Latéral gauche',
	rightFace: 'Latéral droit',
	backFace: 'Face arrière',
	wheel: 'Roue',
	wheelFaceLeft: 'Roue avant gauche',
	wheelBackLeft: 'Roue arrière gauche',
	wheelBackRight: 'Roue arrière droite',
	wheelFaceRight: 'Roue avant droite',
	none: 'Aucun angle',
	vehicleType: 'Classification',
	angleType: 'Angle spécifique',

	littleTitle: 'Petit',
	mediumTitle: 'Moyen',
	bigTitle: 'Grand',
	titleType: 'Taille des caractères',

	typeYourText: 'Tapez votre texte',
	clickOrDrop: 'Cliquez ici ou déposez un document',
	import: 'IMPORTER',
	dateFormat: 'JJ/MM/AAAA',

	active: 'Publié',
	inactive: 'Brouillon',
	status: 'Statut',

	displayTop: 'Afficher dans la synthèse',
	displayNone: 'Ne pas afficher',
	displayBriefing: 'Afficher la synthèse des alertes en haut du formulaire',
	defaultValue: 'Valeur par défaut',
	instructionText: 'Texte explicatif',

	fileImport: 'Import de fichier',
	mediaType: 'Type',

	signZone: 'Zone de signature',

	dateType: 'Type de date',
	//here we put type at the start of the variable name because we need to translate type date in a generic way
	typedateParkExit: 'Date de sortie de parc',
	typedateParkEntry: "Date d'entrée sur parc",
	typedate: 'Date générique',

	limitReached: 'Vous avez atteint la limite de 10 photos.',
	unlimitedLimitReached: 'Le nombre de fichiers autorisé a été atteint.',
	limitReachedUpgraded: 'Passez à la vitesse supérieure !',
	addUnlimitedPicture: 'Ajout de photos illimité',
	addUnlimitedPicturePrice: '20€',
	addUnlimitedPicturePrice1: ' HT/mois',
	subscribeOffer: 'souscrire à l’offre',
	skipOffer: 'ignorer',

	SCRATCH: 'Rayure',
	BUMP: 'Enfoncement / Bosse',
	PAINT: 'Peinture abimée',
	BREAK: 'Cassure',
	GLASS: 'Bris de glace',
	MISSING: 'Piece manquante',
	OTHER: 'Autre',

	emptyPageForm:
		'Sélectionnez les champs à droite de l’écran pour construire votre formulaire.',
	dontShowForm:
		'Ne pas afficher cette instruction dans le formulaire envoyé au client',
	missionType: 'Type de mission',
}
