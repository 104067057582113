import React, { Fragment, useContext, useMemo } from 'react'

import { Stack } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import { ViewModalContentContext } from '../../../../utils/contexts/ViewModalContentContext'
import { AuthContext } from '../../../../utils/contexts/AuthContext'
import BreadcrumbItem from './BreadcrumbItem'
import { generateBreadcrumbLinks } from '../../viewModalUtil'

const breadcrumbCSS = {
	'& svg:last-child': { display: 'none' },
	'& svg:first-child': { display: 'none' },
}

const separatorCSS = { fontSize: 12, color: '#00000042' }

interface IViewModalBreadcrumb {}

const ViewModalBreadcrumb: React.FC<IViewModalBreadcrumb> = () => {
	const { checkModules } = useContext(AuthContext)
	const {
		viewModalData,
		headerConfig: { breadcrumbs },
	} = useContext(ViewModalContentContext) || {}

	const formattedBreadcrumb = useMemo(
		() => generateBreadcrumbLinks(breadcrumbs, viewModalData, checkModules),
		[breadcrumbs, viewModalData]
	)

	return (
		<Stack direction='row' alignItems='center' spacing={1} sx={breadcrumbCSS}>
			{formattedBreadcrumb?.map((breadcrumb, i) => (
				<Fragment key={`breadcrumb-${i}`}>
					<BreadcrumbItem breadcrumb={breadcrumb} />
					<ArrowForwardIosIcon sx={separatorCSS} />
				</Fragment>
			))}
		</Stack>
	)
}

export default ViewModalBreadcrumb
