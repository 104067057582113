/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'

//TODO est-ce vraiment utile ?
const Picture = ({ width, src, alt = '' }) => (
	<>
		<img src={`${src}`} style={{ width: `${width}px` }} alt={alt} />
	</>
)

export default Picture
