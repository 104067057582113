import React, { useCallback } from 'react'

import { Stack, Badge } from '@mui/material'
import { useFormatMessage } from '../../../../utils/hooks'
import { USERS_FILTER_LIST } from '../../../../api/gql/queries/usersQueries'
import FormSearchCheckboxListIsManager from '../../../../components/formSearchCheckboxListIsManager'

interface ITeamModalUsers {}

const badgeCSS = {
	ml: 4,
	'& .MuiBadge-colorPrimary': { background: '#e0e0e0' },
}

const TeamModalUsers: React.FC<ITeamModalUsers> = () => {
	const intlMsg = useFormatMessage()

	const renderLeftPartTitle = useCallback(
		(elem) => (
			<Stack direction='row' alignItems='center'>
				<span>{`${elem.firstName} ${elem.lastName}`}</span>
				{!elem?.isActive && (
					<Badge
						sx={badgeCSS}
						badgeContent={intlMsg('misc.inactive')}
						color={'primary'}
					/>
				)}
			</Stack>
		),
		[]
	)

	return (
		<FormSearchCheckboxListIsManager
			query={USERS_FILTER_LIST}
			queryName='users'
			renderLeftPartTitle={renderLeftPartTitle}
			leftColumName='misc.userList'
			leftColumNameTooltips='misc.userListTooltips'
			rightColumnName='misc.teamManager'
			playwrightKeySelector='firstName'
		/>
	)
}

export default TeamModalUsers
