import React from 'react'
import ShowMoreTxt from '../../../showMoreTxt'

interface IDataListComment {
	comment: string
}

const DataListComment: React.FC<IDataListComment> = ({ comment }) => {
	return <ShowMoreTxt txt={comment || '-'} maxSize={140} fontSize={14} />
}

export default DataListComment
