import React, { useCallback, useContext, useMemo } from 'react'

import { Stack } from '@mui/material'
import { ModalContext } from '../../../../utils/contexts/ModalContext'
import { getAdminPanelAccountTypeList } from '../../../../api/gql/queries/adminPanelAccounts'
import { SelectForm } from '../../../../components/forms/v1/formFields'
import { useFormatMessage } from '../../../../utils/hooks'

interface IAccountType {
	editedAccount?
}

const AccountType: React.FC<IAccountType> = ({ editedAccount }) => {
	const intlMsg = useFormatMessage()
	const {
		formUtils: { control, setValue },
	} = useContext(ModalContext)

	const { data: accountTypes, loading: loadingAccountType } =
		getAdminPanelAccountTypeList()

	const handleSelect = useCallback(
		(name) => (e) => {
			setValue(name, e.target.value)
		},
		[]
	)

	const valuesDefault = useMemo(
		() =>
			accountTypes?.filter(({ id }) => id === editedAccount.accountType.id)?.[0]
				?.id,
		[accountTypes, editedAccount]
	)

	const selectElements = useMemo(
		() =>
			accountTypes?.map((accountType) => ({
				key: accountType?.id,
				label: accountType?.name,
				value: accountType?.id,
			})) || [],
		[accountTypes]
	)

	return (
		<Stack p={2}>
			{!loadingAccountType && (
				<SelectForm
					valuesDefault={valuesDefault}
					name='accountType'
					label={intlMsg('misc.accountType')}
					control={control}
					selectElements={selectElements}
					specialOnChange={handleSelect('accountType')}
					isRequired
				/>
			)}
		</Stack>
	)
}

export default AccountType
