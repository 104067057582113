/* eslint-disable no-case-declarations */
import React, {
	cloneElement,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'

import { Popover, Box } from '@mui/material'



import CalendarEventInfoModalIntervention from './CalendarEventInfomodalIntervention'
import CalendarEventInfo from './calendarEventInfo'
import { EFormModalMode } from '../../../utils/app-models'
import CustomWeekDayEvent from './calendarEvents/customWeekEvent'
import CustomMonthEvent from './calendarEvents/CustomMonthEvent'

const anchorOrigin = { vertical: 'bottom', horizontal: 'left' } as any

interface ICustomEvent {
	event: any
	searchText?: string
	currentView: string
	selectedInter?: any
}

const CustomEvent: React.FC<ICustomEvent> = ({
	event,
	searchText,
	currentView,
	selectedInter,
}) => {
	const [isSelected, setIsSelected] = useState(false)
	const [openModalIntervention, setOpenModalIntervention] = useState(false)
	const [formModalEdit, setFormModalEdit] = useState(EFormModalMode.edit)
	const [anchorEl, setAnchorEl] = useState(null)
	const [isMembersCellOpen, setIsMembersCellOpen] = useState(false)
	const [selectedComponent, setSelectedComponent] = useState<any>(null)

	const handleClick = useCallback(
		(event) => {
			if (!isMembersCellOpen) setAnchorEl(event.currentTarget)
		},
		[isMembersCellOpen]
	)

	const handleClose = useCallback(() => {
		setAnchorEl(null)
	}, [])

	const open = useMemo(() => Boolean(anchorEl), [anchorEl])
	const id = useMemo(() => (open ? 'simple-popover' : undefined), [open])

	useEffect(() => {
		setSelectedComponent(
			currentView === 'month' ? (
				<CustomMonthEvent borderColor />
			) : (
				<CustomWeekDayEvent borderColor />
			)
		)
	}, [currentView])

	useEffect(() => {
		if (searchText) {
			setIsSelected(selectedInter?.some((inter) => inter.ref === event.title))
		}
	}, [searchText])

	return (
		<>
			{selectedComponent ? (
				cloneElement(selectedComponent, {
					event,
					searchText,
					isSelected,
					handleClick,
					setIsMembersCellOpen,
				})
			) : (
				// !important -> fix popover CalendarShowMore position
				<Box height='40px'></Box>
			)}

			{open && (
				<Popover
					id={id}
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={anchorOrigin}
				>
					<CalendarEventInfo
						event={event}
						handleClose={handleClose}
						setOpenModalIntervention={setOpenModalIntervention}
						setFormModalEdit={setFormModalEdit}
					/>
				</Popover>
			)}

			<CalendarEventInfoModalIntervention
				event={event}
				handleClose={handleClose}
				openModalIntervention={openModalIntervention}
				setOpenModalIntervention={setOpenModalIntervention}
				formModalEdit={formModalEdit}
			/>
		</>
	)
}

export default CustomEvent
