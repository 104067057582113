import React, { useCallback, useMemo, useRef, useState } from 'react'

import { useNavigate, useLocation } from 'react-router-dom'

import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import { Box, Typography } from '@mui/material'

import { useFormatMessage } from '../../../../../utils/hooks'
import PermissionsHandler from '../../../../permissionsHandler'

const styles = {
	listItem: {
		padding: '15px 30px 15px  17px',
		'&:hover ': {
			backgroundColor: '#061538',
			color: 'white!important',
		},
		'&:hover svg': {
			color: 'white!important',
		},
	},
	isSelected: {
		position: 'absolute',
		borderLeft: `solid 4px #f13a30 !important`,
		top: '0px',
		right: '-5px',
		borderRadius: '268px',
		width: '9px',
		height: '57px',
	},
	paper: {
		backgroundColor: '#061538',
		borderTopLeftRadius: '0px',
		borderBottomLeftRadius: '0px',
		'& ul': { p: 0 },
	},
	title: {
		fontSize: '12px',
		color: 'white',
		padding: '1rem',
		backgroundColor: '#132142',
	},
	menuItem: {
		padding: '15px 30px 15px  17px',
		backgroundColor: '#061538',
		color: 'white',
		borderTopLeftRadius: '0px',
		borderBottomLeftRadius: '0px',
		'&:hover': {
			backgroundColor: '#192A54',
		},
		fontSize: '14px',
		fontWeight: 300,
	},
}

interface INavItemClose {
	config: any
}

const NavItemClose: React.FC<INavItemClose> = ({ config }) => {
	const intlMsg = useFormatMessage()
	const { pathname } = useLocation()
	const [open, setOpen] = useState(false)
	const anchorRef = useRef<HTMLButtonElement>(null)

	const navigate = useNavigate()

	const handleClickMenu = useCallback(
		(newPath) => (e) => {
			e.preventDefault()
			e.ctrlKey ? window.open(newPath, '_blank') : navigate(newPath)
			setOpen(false)
		},
		[navigate]
	)

	const subNav = useMemo(
		() =>
			config?.subnav ? (
				<MenuList autoFocusItem={open} id='menu-list-grow'>
					<Typography variant='body1' sx={styles.title}>
						{intlMsg(config.label)}
					</Typography>

					{config.subnav.map(({ label, path, subPermissions, subModules }) => (
						<PermissionsHandler
							key={`${label}-nav`}
							permissionsName={subPermissions}
							modulesName={subModules}
						>
							<MenuItem onClick={handleClickMenu(path)} sx={styles.menuItem}>
								{intlMsg(label)}
							</MenuItem>
						</PermissionsHandler>
					))}
				</MenuList>
			) : (
				<></>
			),
		[config?.subnav, handleClickMenu]
	)

	const handleToggleClosedSubNav = useCallback(() => {
		if (config?.subnav) setOpen(!open)
		else if (config?.path) navigate(config.path)
	}, [open, config, navigate])

	const handleClose = useCallback(() => setOpen(false), [])

	const parentNavIsSelected = config?.subnav?.some(
		({ path }) => path === pathname
	)

	return (
		<>
			<Popper
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				placement='right'
				sx={{ zIndex: 2000 }}
			>
				{({ TransitionProps }) => (
					<Grow {...TransitionProps}>
						<Paper sx={styles.paper}>
							<ClickAwayListener onClickAway={handleClose}>
								{subNav}
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>

			<ListItem
				button
				sx={styles.listItem}
				ref={anchorRef as any}
				onClick={handleToggleClosedSubNav}
			>
				{parentNavIsSelected && (
					<Box component='span' sx={styles.isSelected}></Box>
				)}

				<ListItemIcon>
					<Box sx={{ color: '#d2d2d2' }}>{config?.icon}</Box>
				</ListItemIcon>
			</ListItem>
		</>
	)
}

export default NavItemClose
