/* eslint-disable react/display-name */
import React from 'react'
import LocationAddress from '../../../../locationAddress'

const listSitesTableConfigForSitesStep = [
	{
		columnLabel: 'misc.sites',
		orderBy: 'name',
		dataKey: 'name',
	},
	{
		columnLabel: 'misc.account',
		orderBy: 'customer_name',
		dataKey: 'customer.name',
	},

	{
		columnLabel: 'misc.address',
		orderBy: 'address',
		personalizedCell: ({ address, city, zipcode }) => (
			<LocationAddress address={address} city={city} zipcode={zipcode} />
		),
	},
]

export default listSitesTableConfigForSitesStep
