import React, { useContext, useMemo } from 'react'

import { useNavigate, useLocation } from 'react-router-dom'
import ShareIcon from '@mui/icons-material/Share'
import { Link, Stack, Tooltip } from '@mui/material'

import { AuthContext } from '../../../utils/contexts/AuthContext'
import { maxStringDisplay } from '../../../utils/string'
import { useFormatMessage } from '../../../utils/hooks'
import { handleOpenViewModal } from '../../../utils/utils'

const shareIconCSS = {
	ml: 1,
	color: ({ palette }) => palette.primary.main,
	fontSize: '1.2rem!important',
}

const linkCSS = (hasAccess) => ({
	...(hasAccess && { cursor: 'pointer' }),
	whiteSpace: 'nowrap',
})

interface IOpenLinkCell {
	viewModalLink?: string
	txt?: string
	isSharedWithMe?: any
	permissions?: string[]
	modules?: string[]
	maxCharacters?: number
	sharedBy?: string
	notClickable?: boolean
}

const OpenLinkCell: React.FC<IOpenLinkCell> = ({
	txt = '',
	viewModalLink,
	isSharedWithMe = null,
	permissions = null,
	modules = null,
	maxCharacters = 200,
	sharedBy = null,
	notClickable = false,
}) => {
	const intlMsg = useFormatMessage()
	const navigate = useNavigate()
	const location: any = useLocation()
	const { checkPermissions, checkModules } = useContext(AuthContext)
	const hasAccess = useMemo(
		() => checkPermissions(permissions) && checkModules(modules),
		[]
	)

	return (
		<Stack direction='row' alignItems='center'>
			<Link
				variant='caption'
				display='block'
				color='inherit'
				underline={hasAccess && !notClickable ? 'hover' : 'none'}
				onMouseDown={
					hasAccess &&
					!notClickable &&
					handleOpenViewModal(navigate, location, viewModalLink)
				}
				sx={linkCSS(hasAccess && !notClickable)}
			>
				{txt?.length > maxCharacters ? (
					<Tooltip title={txt}>
						<span>{maxStringDisplay(txt, maxCharacters)}</span>
					</Tooltip>
				) : (
					txt || '-'
				)}
			</Link>
			{isSharedWithMe && (
				<Tooltip
					title={
						sharedBy
							? `${intlMsg('misc.sharedBy')} ${sharedBy}`
							: intlMsg('misc.shared')
					}
				>
					<ShareIcon sx={shareIconCSS} />
				</Tooltip>
			)}
		</Stack>
	)
}

export default OpenLinkCell
