import React, { useMemo } from 'react'

interface ILogoMain {
	width: number
	complete: boolean
	opacity?: string
	className?: string
	dark?: boolean
}

const LogoMain: React.FC<ILogoMain> = ({
	width,
	complete,
	opacity,
	className,
	dark = false,
}) => {
	const logoSrc = useMemo(
		() =>
			`/img/logo-hubeeconnect${dark ? '-dark' : ''}${
				complete ? '' : '-alveole'
			}.svg`,
		[dark, complete]
	)
	const logoOpacity = useMemo(() => (opacity ? `0.${opacity}` : '1'), [opacity])
	const marginRight = useMemo(() => (complete ? '' : '5px'), [complete])

	const logoStyle = useMemo(
		() => ({
			maxWidth: `${width}px`,
			opacity: logoOpacity,
			marginRight: marginRight,
			width: '80%',
		}),
		[width, logoOpacity, marginRight]
	)

	return (
		<img
			src={logoSrc}
			alt='Logo hubeeconnect'
			style={logoStyle}
			className={className}
		/>
	)
}

export default LogoMain
