export interface IGlobalPoliceTheme {
	h1: {
		fontFeatureSettings: string
		fontFamily: string
		fontSize: string
		fontStyle: string
		fontWeight: string
		lineHeight: string
	}
	h2: {
		fontFeatureSettings: string
		fontFamily: string
		fontSize: string
		fontStyle: string
		fontWeight: string
		lineHeight: string
	}
	h3: {
		fontFeatureSettings: string
		fontFamily: string
		fontSize: string
		fontStyle: string
		fontWeight: string
		lineHeight: string
	}
	titleWidget: {
		fontFeatureSettings: string
		fontFamily: string
		fontSize: string
		fontStyle: string
		fontWeight: string
		lineHeight: string
	}
	tableLine: {
		fontFeatureSettings: string
		fontFamily: string
		fontSize: string
		fontStyle: string
		fontWeight: string
		lineHeight: string
		letterSpacing: string
	}
	tableColumn: {
		fontFeatureSettings: string
		fontFamily: string
		fontSize: string
		fontStyle: string
		fontWeight: string
		lineHeight: string
		letterSpacing: string
	}
	tab: {
		fontFeatureSettings: string
		fontFamily: string
		fontSize: string
		fontStyle: string
		fontWeight: string
		lineHeight: string
	}
	buttons: {
		fontFamily: string
		fontSize: string
		fontStyle: string
		fontWeight: string
		lineHeight: string
	}
	inputs: {
		fontFeatureSettings: string
		fontFamily: string
		fontSize: string
		fontStyle: string
		fontWeight: string
		lineHeight: string
	}
	body14: {
		fontFeatureSettings: string
		fontFamily: string
		fontSize: string
		fontStyle: string
		fontWeight: string
		lineHeight: string
		letterSpacing: string
	}
	body16: {
		fontFeatureSettings: string
		fontFamily: string
		fontSize: string
		fontStyle: string
		fontWeight: string
		lineHeight: string
		letterSpacing: string
	}
	subtitle14: {
		fontFeatureSettings: string
		fontFamily: string
		fontSize: string
		fontStyle: string
		fontWeight: string
		lineHeight: string
	}
	subtitle16: {
		fontFeatureSettings: string
		fontFamily: string
		fontSize: string
		fontStyle: string
		fontWeight: string
		lineHeight: string
	}
	menu: {
		fontFamily: string
		fontSize: string
		fontStyle: string
		fontWeight: string
		lineHeight: string
	}
	Label: {
		fontFeatureSettings: string
		fontFamily: string
		fontSize: string
		fontStyle: string
		fontWeight: string
		lineHeight: string
	}
	helper: {
		fontFeatureSettings: string
		fontFamily: string
		fontSize: string
		fontStyle: string
		fontWeight: string
		lineHeight: string
	}
	chips: {
		fontFeatureSettings: string
		fontFamily: string
		fontSize: string
		fontStyle: string
		fontWeight: string
		lineHeight: string
	}
	tag: {
		fontFeatureSettings: string
		fontFamily: string
		fontSize: string
		fontStyle: string
		fontWeight: string
		lineHeight: string
	}
	titleDataList: {
		// need to remove this
		color: string
		fontSize: string
		fontWeight: 400
		letterSpacing: string
		textAlign: string
		display: string
		alignItems: string
		width: string
	}
}

export const GlobalPoliceTheme: IGlobalPoliceTheme = {
	h1: {
		fontFeatureSettings: "'clig' off, 'liga' off",
		fontFamily: 'Source Sans Pro',
		fontSize: '24px',
		fontStyle: 'normal',
		fontWeight: '700',
		lineHeight: '120%' /* 28.8px */,
	},
	h2: {
		fontFeatureSettings: "'clig' off, 'liga' off",
		fontFamily: 'Source Sans Pro',
		fontSize: '20px',
		fontStyle: 'normal',
		fontWeight: '600',
		lineHeight: '120%' /* 24px */,
	},
	h3: {
		fontFeatureSettings: "'clig' off, 'liga' off",
		fontFamily: 'Source Sans Pro',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: '600',
		lineHeight: '143%' /* 22.88px */,
	},
	titleWidget: {
		fontFeatureSettings: "'clig' off, 'liga' off",
		fontFamily: 'Source Sans Pro',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: '400',
		lineHeight: '24px',
	},
	tableLine: {
		fontFeatureSettings: "'clig' off, 'liga' off",
		fontFamily: 'Source Sans Pro',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: '400',
		lineHeight: '140%' /* 20.02px */,
		letterSpacing: '0.15px',
	},
	tableColumn: {
		fontFeatureSettings: "'clig' off, 'liga' off",
		fontFamily: 'Source Sans Pro',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: '600',
		lineHeight: '24px',
		letterSpacing: '0.15px',
	},
	tab: {
		fontFeatureSettings: "'clig' off, 'liga' off",
		fontFamily: 'Source Sans Pro',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: '600',
		lineHeight: '24px',
	},
	buttons: {
		fontFamily: 'Source Sans Pro',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: '600',
		lineHeight: '25px',
	},
	inputs: {
		fontFeatureSettings: "'clig' off, 'liga' off",
		fontFamily: 'Source Sans Pro',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: '400',
		lineHeight: '24px',
	},
	body14: {
		fontFeatureSettings: "'clig' off, 'liga' off",
		fontFamily: 'Source Sans Pro',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: '400',
		lineHeight: '143%' /* 20.02px */,
		letterSpacing: '0.15px',
	},
	body16: {
		fontFeatureSettings: "'clig' off, 'liga' off",
		fontFamily: 'Source Sans Pro',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: '400',
		lineHeight: '143%' /* 22.88px */,
		letterSpacing: '0.15px',
	},
	subtitle14: {
		fontFeatureSettings: "'clig' off, 'liga' off",
		fontFamily: 'Source Sans Pro',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: '600',
		lineHeight: '24px',
	},
	subtitle16: {
		fontFeatureSettings: "'clig' off, 'liga' off",
		fontFamily: 'Source Sans Pro',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: '600',
		lineHeight: '24px',
	},
	menu: {
		fontFamily: 'Source Sans Pro',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: '400',
		lineHeight: '25px',
	},
	Label: {
		fontFeatureSettings: "'clig' off, 'liga' off",
		fontFamily: 'Source Sans Pro',
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: '400',
		lineHeight: '20px',
	},
	helper: {
		fontFeatureSettings: "'clig' off, 'liga' off",
		fontFamily: 'Source Sans Pro',
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: '400',
		lineHeight: '20px',
	},
	chips: {
		fontFeatureSettings: "'clig' off, 'liga' off",
		fontFamily: 'Source Sans Pro',
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: '400',
		lineHeight: '18px',
	},
	tag: {
		fontFeatureSettings: "'clig' off, 'liga' off",
		fontFamily: 'Source Sans Pro',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: '600',
		lineHeight: '24px',
	},
	titleDataList: {
		// need to remove this
		color: '#9e9e9e',
		fontSize: '14px',
		fontWeight: 400,
		letterSpacing: '0.15px',
		textAlign: 'left',
		display: 'flex',
		alignItems: 'center',
		width: 'fit-content',
	},
}
