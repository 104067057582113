import React, { useMemo } from 'react'

import { Stack } from '@mui/material'

const mainCSS = (height) => ({
	justifyContent: 'center',
	alignItems: 'center',
	height: height || 'calc(100vh - 16px)',
	'& img': { height: '80px', width: 1 },
})

interface LogoConnectIcon {
	height?: string | null
}

const LogoConnectIcon: React.FC<LogoConnectIcon> = ({ height = null }) => {
	const caculatedMainCss = useMemo(() => mainCSS(height), [height])

	return (
		<Stack
			sx={caculatedMainCss}
			data-test-id='Playwright-LogoConnectIcon-visible'
		>
			<img src='/icon/connect.svg' />
		</Stack>
	)
}

export default LogoConnectIcon
