import React from 'react'

import Card from '@mui/material/Card'

import NetworksList from './NetworksList'
import NetworksStatus from './NetworksStatus'

interface INetworks {
	changeNetworkPage
	selectedNetworkId
	setSelectedNetworkId
	networksData
	networkIsActive
	setNetworkIsActive
}

const Networks: React.FC<INetworks> = ({
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	networkIsActive,
	changeNetworkPage,
	selectedNetworkId,
	setSelectedNetworkId,
	networksData,
	setNetworkIsActive,
}) => {
	return (
		<Card
			elevation={0}
			sx={{
				borderRadius: 0,
				width: '100%',
				maxWidth: '300px',
				borderRight: 'solid 1px',
				borderColor: ({ colors }) => colors.greySeparator,
				display: 'flex',
				flexDirection: 'column',
				flexGrow: '1',
			}}
		>
			<NetworksStatus
			// networkIsActive={networkIsActive} //see in this component
			// setNetworkIsActive={setNetworkIsActive}
			/>
			<NetworksList
				networks={networksData}
				changeNetworkPage={changeNetworkPage}
				selectedNetworkId={selectedNetworkId}
				setSelectedNetworkId={setSelectedNetworkId}
				setNetworkIsActive={setNetworkIsActive}
			/>
		</Card>
	)
}

export default Networks
