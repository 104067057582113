import React, { Context, useCallback, useContext, useMemo } from 'react'

import frLocale from 'date-fns/locale/fr'
import dayjs from 'dayjs'

import { DesktopTimePicker as MuiTimePicker } from '@mui/x-date-pickers/DesktopTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TextField } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { IFormModalContext } from '../formModal/FormModalContext'
import { IStepperContext } from '../formStepper/StepperContext'
import { handleFormOnblur } from '../formsUtils'
import { useFormatMessage } from '../../../utils/hooks'

interface IFormTimePicker {
	name: string
	label?: string
	valuesDefault?: any
	context: Context<IFormModalContext> | Context<IStepperContext>
	trigerred?: boolean
	onBlur?: any
	isrequired?
	specialOnChange?
}

const FormTimePicker: React.FC<IFormTimePicker> = ({
	context,
	name,
	label,
	isrequired,
	valuesDefault,
	specialOnChange = null,
	trigerred = false,
	onBlur,
}) => {
	const intlMsg = useFormatMessage()
	const { setValue, data, errors, triggerError, setErrors } = useContext<
		IFormModalContext | IStepperContext
	>(context as any)
	const value = useMemo(() => data[name] || null, [data[name]])
	const error = useMemo(() => errors[name] || null, [errors[name]])

	const handleOnblur = useMemo(() => {
		return trigerred || onBlur
			? handleFormOnblur(trigerred, onBlur, name, triggerError)
			: undefined
	}, [trigerred, onBlur, triggerError])

	const handleOnChange = useCallback(
		(date) => {
			if (dayjs(date).isValid()) {
				if (errors[name])
					setErrors({
						...errors,
						[name]: null,
					})
				if (specialOnChange)
					specialOnChange({
						fieldName: name,
						date,
					})
				else setValue(name, date)
			} else {
				setErrors({
					...errors,
					[name]: intlMsg('errors.dateInvalid'),
				})
				setValue(name, date)
			}
		},
		[specialOnChange, setValue, errors]
	)

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
			<MuiTimePicker
				views={['hours', 'minutes']}
				inputFormat='HH:mm'
				ampm={false}
				mask='__:__'
				label={label}
				value={value || valuesDefault || null}
				onChange={handleOnChange}
				renderInput={(params) => (
					<TextField
						name={name}
						{...params}
						fullWidth
						size='small'
						error={!!error}
						helperText={error || ''}
						required={isrequired}
						onBlur={handleOnblur}
					/>
				)}
			/>
		</LocalizationProvider>
	)
}

export default FormTimePicker
