import React, { useCallback, useEffect, memo } from 'react'

import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'

import { useFormatMessage } from '../../../../utils/hooks'

interface IColumnFiltersRow {
	columnsTitle: []
	setActiveColumns: (columns: any[]) => void
	localStorageName: string
	activeColumns: any[]
}

const ColumnFiltersRow: React.FC<IColumnFiltersRow> = ({
	columnsTitle,
	setActiveColumns,
	activeColumns,
	localStorageName,
}) => {
	const intlMsg = useFormatMessage()

	useEffect(() => {
		const usersArrayFilters = JSON.parse(
			localStorage.getItem(localStorageName) as string
		)

		if (!usersArrayFilters) {
			localStorage.setItem(localStorageName, JSON.stringify(columnsTitle))
			setActiveColumns(columnsTitle)
		} else setActiveColumns(usersArrayFilters)
	}, [])

	const handleCheck = useCallback(
		(columnTitle) => () => {
			const tmpActiveColumns = [...activeColumns]
			const index = activeColumns.findIndex((e) => e === columnTitle)
			if (index !== -1) tmpActiveColumns.splice(index, 1)
			else tmpActiveColumns.push(columnTitle)
			setActiveColumns(tmpActiveColumns)
			localStorage.setItem(localStorageName, JSON.stringify(tmpActiveColumns))
		},
		[activeColumns, setActiveColumns]
	)

	const handleIsChecked = useCallback(
		(columnTitle) => {
			return !!activeColumns?.find((e) => e === columnTitle)
		},
		[activeColumns]
	)

	// translate column to sort by alphabetic
	const columnsTitleSortByAlphabetic = [] as any
	columnsTitle.forEach((col) => {
		columnsTitleSortByAlphabetic.push({
			translate: intlMsg(col).toUpperCase(),
			keyCol: col,
		})
	})

	return (
		<>
			{columnsTitleSortByAlphabetic
				.sort((x, y) => x.translate.localeCompare(y.translate))
				.map(({ keyCol, translate }, i) => {
					if (keyCol && keyCol !== ' ')
						return (
							<ListItem
								id={`Playwrite-ColumnFiltersRow-${keyCol}`}
								key={`columnFilters-${i}-${keyCol}`}
								role={undefined}
								dense
								button
								onClick={handleCheck(keyCol)}
							>
								<ListItemIcon sx={{ minWidth: '30px' }}>
									<Checkbox
										edge='start'
										checked={handleIsChecked(keyCol)}
										disableRipple
										color={'primary'}
										disabled={!activeColumns?.find((e) => e === keyCol)}
									/>
								</ListItemIcon>
								<ListItemText id={'labelId'} primary={translate} />
							</ListItem>
						)
				})}
		</>
	)
}

export default memo(ColumnFiltersRow)
