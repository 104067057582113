export default {
	addAgency: 'Ajouter une agence',
	addNetwork: 'Ajouter un réseau',
	editAgency: 'Modifier une agence',
	agencyInformations: "Informations de l'agence",
	referralInformation: 'Informations du référent',
	socialName: 'Raison sociale',
	agencyName: "Nom de l'agence",
	agencies: 'Agences',
	typeAgency: "Type d'agence",
	nbCustomer: 'NB Clients',
	manager: 'Responsable',
	referentAgency: "Référent de l'agence",
	adminCanLogin: 'Le référent peut se connecter à HubeeConnect',
	shareSitesToAgency: "Sites partagés avec l'agence",
	sharedCategoriesToAgency: "Catégories de produits partagées avec l'agence",
	sharedSites: 'Sites partagés',
	sharedProducts: 'Produits partagées',
	changeType: 'Changer de type',
	changeTypeConfirmText: "Êtes-vous sûr de vouloir changer le type d'agence ?",
	invalidSiret: 'Veuillez entrer un numéro SIRET valide',
	agencieHelpProcessus:
		"Le référent de l'agence recevra une invitation par email pour activer son compte.",
	afterAgencieHelpProcessus:
		'Après son activation vous pourrez lui transmettre des interventions.',
	noSharedToAgencies: 'Aucune agence associée',

	//typeText//
	limited1: '1 utilisateur <em>unique</em>',
	limited2: 'Limité aux interventions que <em>vous lui partagez</em>',
	limited3: '<em>Export</em> de ses données partagées',
	limited4: '<em>Consultation</em> de son activitée partagée',
	limited5: "Accès à l'application mobile",

	unlimited1: 'Utilisateurs <em>illimités</em>',
	unlimited2: 'Gestion des Interventions <em>illimitée</em>',
	unlimited3: 'Accès aux <em>extensions illimité</em>',
	unlimited4: '<em>Export</em> de ses données complètes',
	unlimited5: '<em>Gestion complète</em> de son activité',
	unlimited6: "Accès <em>illimité</em> à l'application mobile",
}
