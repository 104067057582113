import React, { useMemo } from 'react'

import { CardHeader, Stack, Typography } from '@mui/material'
import { dayjsLocalizedFormatMethod } from '../../../utils/date'

const titleCSS = {
	fontWeight: 700,
	fonSize: '20px',
	textTransform: 'uppercase',
}

const MuiCardHeaderActionCSS = {
	'& .MuiCardHeader-action': { margin: 0 },
}

interface IWTransportTripSummaryHeader {
	itTrip
}

const WTransportTripSummaryHeader: React.FC<IWTransportTripSummaryHeader> = ({
	itTrip,
}) => {
	const Title = useMemo(() => {
		return (
			<Stack direction='row' alignItems='center' spacing={0.5}>
				<Typography variant='h3' sx={titleCSS}>
					{`${itTrip?.city} (${itTrip?.zipcode?.slice(
						0,
						2
					)}), ${itTrip?.country?.slice(0, 2)}`}
				</Typography>
			</Stack>
		)
	}, [itTrip])

	const Subheader = useMemo(() => {
		const dayjs = dayjsLocalizedFormatMethod()
		const formattedDate = dayjs(itTrip?.date).format('ll')
		const formattedTimeFrom = dayjs(itTrip?.timeFrom).format('LT')
		const formattedTimeTo = itTrip?.timeTo
			? dayjs(itTrip?.timeTo).format('LT')
			: null

		return (
			<Stack direction='row' spacing={0.5}>
				<Typography variant='body2' sx={{ marginRight: '10px' }}>
					{formattedDate}
				</Typography>
				<Typography variant='body2' fontWeight={500}>{`${formattedTimeFrom} ${
					formattedTimeTo ? ' - ' + formattedTimeTo : ''
				}`}</Typography>
			</Stack>
		)
	}, [itTrip])

	return (
		<CardHeader
			title={Title}
			subheader={Subheader}
			sx={MuiCardHeaderActionCSS}
		/>
	)
}

export default WTransportTripSummaryHeader
