import React, { useCallback, useContext, useMemo } from 'react'

import { Button, Stack } from '@mui/material'

import LoadingButton from '@mui/lab/LoadingButton'
import { footerModalCSS } from '../../../../../../../components/forms/modalSxCss'
import { useFormatMessage } from '../../../../../../../utils/hooks'
import {
	FormModalContext,
	IFormModalContext,
} from '../../../../../../../components/forms/formModal/FormModalContext'

interface ISelectVehiclesModalFooter {
	closeModal: any
}

const SelectVehiclesModalFooter: React.FC<ISelectVehiclesModalFooter> = ({
	closeModal,
}) => {
	const intlMsg = useFormatMessage()
	const { data, setValues, setValue, setTabCounter } =
		useContext<IFormModalContext>(FormModalContext)

	const globalData = useMemo(
		() => ({
			globalProducts: data?.globalProducts || [],
			globalFormTemplates: data?.globalFormTemplates || [],
		}),
		[data?.globalFormTemplates, data?.globalProducts]
	)

	const selectedRow = useMemo(
		() => data?.selectedRow || [],
		[data?.selectedRow]
	)

	const selectedVehicles = useMemo(
		() => data?.selectedVehicles || [],
		[data?.selectedVehicles]
	)

	const handleClose = useCallback(() => {
		setValue('selectedRow', selectedVehicles)
		closeModal()
	}, [selectedRow, selectedVehicles])

	const handleValidateClick = useCallback(async () => {
		// Create an empty object to hold the data that will be set in the state
		const data = {}

		const input = selectedRow.map((item) => ({
			...item,
			id: item.ipv ? item.ipv : item.id,
			vehicleId: item.ipv ? item.id : undefined,
			ipv: undefined,
		}))

		// Filter the selected rows to find any new selected vehicles
		const newSelectedVehicles = input?.filter((row) => {
			// Check if the row is already in the selected vehicles array
			if (
				selectedVehicles?.some(
					(selectedVehicle) => selectedVehicle?.id === row?.id
				)
			) {
				// If the row is already in the selected vehicles array, return false
				return false
			} else {
				// If the row is not in the selected vehicles array, add the data to the data object
				data[`products-${row?.id}`] = globalData?.globalProducts
				data[`formTemplates-${row?.id}`] = globalData?.globalFormTemplates
				// Return true to include the row in the new selected vehicles array
				return true
			}
		})

		// Filter the selected vehicles to find any old selected vehicles that were deselected
		const oldSelectedVehicles = selectedVehicles?.filter((selectedVehicle) => {
			// Check if the selected vehicle is in the selected rows array
			if (input?.some((row) => row?.id === selectedVehicle?.id)) {
				// If the selected vehicle is in the selected rows array, return false
				return false
			} else {
				// If the selected vehicle is not in the selected rows array, set the data to null and return true
				data[`products-${selectedVehicle?.id}`] = null
				data[`formTemplates-${selectedVehicle?.id}`] = null
				return true
			}
		})

		// Filter the selected vehicles to remove any old selected vehicles that were deselected, and any new selected vehicles that were selected
		const filteredSelectedVehicles = selectedVehicles?.filter(
			(selectedVehicle) =>
				!oldSelectedVehicles?.some(
					(oldV) => oldV?.id === selectedVehicle?.id
				) &&
				!newSelectedVehicles?.some((newV) => newV?.id === selectedVehicle?.id)
		)

		// Create a new array of selected vehicles that includes the old selected vehicles and the new ones
		const updatedSelectedVehicles = [
			...filteredSelectedVehicles,
			...(newSelectedVehicles?.map((newSelectedVehicle) => ({
				...newSelectedVehicle,
				products:
					Object.values(globalData?.globalProducts || {})
						.filter(({ checked }) => checked)
						.map(({ value }) => value?.id) || [],
				formTemplates:
					Object.values(globalData?.globalFormTemplates || {})
						.filter(({ checked }) => checked)
						.map(({ value }) => value?.id) || [],
			})) || []),
		]

		//Set the tab conter based on each selectedVehicle
		setTabCounter({
			nbVehiclesSelected: updatedSelectedVehicles?.length || 0,
		})

		// Set the new selected vehicles as the selected vehicles in the state, and close the modal
		setValues({
			...data,
			selectedVehicles: updatedSelectedVehicles,
		})
		closeModal()
	}, [setValues, selectedRow, globalData, selectedVehicles])

	return (
		<Stack
			direction='row'
			justifyContent='flex-end'
			spacing={2}
			sx={footerModalCSS}
		>
			<Stack direction='row' spacing={1}>
				<Button
					variant='outlined'
					color='primary'
					onClick={handleClose}
					sx={{ mr: 1 }}
				>
					{intlMsg('misc.cancel')}
				</Button>
				<LoadingButton
					data-test-id="Playwright-ValidateBtn-SelectVehiclesModalFooter"
					variant='contained'
					onClick={handleValidateClick}
				>
					{intlMsg('misc.validate')}
				</LoadingButton>
			</Stack>
		</Stack>
	)
}

export default SelectVehiclesModalFooter
