import React, { useContext } from 'react'

import { Routes, Route } from 'react-router-dom'

import { Login } from '../view/index'
import AppRoutes from './AppRoutes'
import { ROUTES } from './consts'
import { AuthContext } from '../utils/contexts/AuthContext'
import UserConfirmation from '../view/userConfirmation'
import ForgottenPassword from '../view/forgottenPassword'
import ForgottenPasswordCheck from '../view/forgottenPassword/ForgottenPasswordCheck'
import ConditionalRoute from './ConditionalRoute'

const RoutesHandler: React.FC = () => {
	const { currentUser } = useContext(AuthContext)
	const isUserLogged = !!currentUser

	return (
		<Routes>
			<Route
				path={ROUTES.LOGIN.path}
				element={
					<ConditionalRoute
						condition={!isUserLogged}
						redirect={ROUTES.HOME.url}
						component={Login}
					/>
				}
			/>
			<Route
				path={ROUTES.CONFIRMATION.path}
				element={
					<ConditionalRoute
						component={UserConfirmation}
						condition={!isUserLogged}
						redirect={ROUTES.HOME.url}
					/>
				}
			/>

			<Route
				path={ROUTES.FORGOTTEN_PASSWORD.CHECK.path}
				element={
					<ConditionalRoute
						component={ForgottenPasswordCheck}
						condition={!isUserLogged}
						redirect={ROUTES.HOME.url}
					/>
				}
			/>

			<Route
				path={ROUTES.FORGOTTEN_PASSWORD.path}
				element={
					<ConditionalRoute
						component={ForgottenPassword}
						condition={!isUserLogged}
						redirect={ROUTES.HOME.url}
					/>
				}
			/>

			<Route
				path={ROUTES.HOME.path}
				element={
					<ConditionalRoute
						component={AppRoutes}
						condition={isUserLogged}
						redirect={ROUTES.LOGIN.url}
					/>
				}
			/>
		</Routes>
	)
}

export default RoutesHandler
