import React, { useCallback } from 'react'

import {
	Stack,
	Typography,
	Divider,
	FormControlLabel,
	Switch,
} from '@mui/material'

import { useFormatMessage } from '../../../../utils/hooks'
import PopoverFilterWidgets from './popoverFilterWidgets'

interface IDashboardHeader {
	filterConfig
	onRemoveWidgets
	onAddWidgets
	widgets
	layoutIsDraggable
	setLayoutIsDraggable
}

const DashboardHeader: React.FC<IDashboardHeader> = ({
	filterConfig,
	onRemoveWidgets,
	onAddWidgets,
	widgets,
	layoutIsDraggable,
	setLayoutIsDraggable,
}) => {
	const intlMsg = useFormatMessage()

	const handleSetLayoutDraggable = useCallback(
		(isDrag) => () => {
			setLayoutIsDraggable(isDrag)
		},
		[]
	)

	return (
		<Stack
			direction='row'
			justifyContent='space-between'
			alignItems='center'
			sx={{ width: 1, flexWrap: 'wrap', gap: 2 }}
		>
			<Stack
				direction='row'
				justifyContent='space-between'
				alignItems='center'
				spacing={2}
				divider={<Divider orientation='vertical' flexItem />}
			>
				<Typography variant='h2'>{intlMsg('misc.dashboard')}</Typography>
				{filterConfig.map(({ filter }, i) => (
					<span key={`dashboardFilter-${i}`}>{filter}</span>
				))}
			</Stack>

			<Stack
				direction='row'
				justifyContent='space-between'
				alignItems='center'
				spacing={1}
			>
				<FormControlLabel
					control={
						<Switch
							size='small'
							checked={layoutIsDraggable}
							onChange={handleSetLayoutDraggable(!layoutIsDraggable)}
							color='primary'
						/>
					}
					label={intlMsg('misc.changeLayout')}
				/>

				<PopoverFilterWidgets
					onRemoveWidgets={onRemoveWidgets}
					onAddWidgets={onAddWidgets}
					widgets={widgets}
				/>
			</Stack>
		</Stack>
	)
}
export default DashboardHeader
