import React from 'react'

import { EFormTemplateBasicFields } from '../../../../../../utils/app-models'
import LimitationModalContentPicture from './limitationModalContent/LimitationModalContentPicture'

const getLimitationModalByTypeField = (fieldType) => {
	switch (fieldType) {
		// FIELD PICTURE ---------------------
		case EFormTemplateBasicFields.picture:
			return <LimitationModalContentPicture />

		default:
			return
	}
}

export default getLimitationModalByTypeField
