import React, { useMemo } from 'react'

import { Typography } from '@mui/material'

import { useFormatMessage } from '../../../utils/hooks'
import { countNumberDaysPeriodInterval } from '../../../utils/date'

interface IIntervalDatePark {
	lastVehicleMovement
}

const IntervalDatePark: React.FC<IIntervalDatePark> = ({
	lastVehicleMovement,
}) => {
	const intlMsg = useFormatMessage()

	const intervalDate = useMemo(() => {
		const diffDay =
			countNumberDaysPeriodInterval(
				lastVehicleMovement?.entryDate,
				lastVehicleMovement?.exitDate || new Date()
			) + 1

		return `${
			lastVehicleMovement?.entryDate
				? `${diffDay < 1 ? 0 : diffDay} ${intlMsg('misc.days')}`
				: '-'
		}`
	}, [lastVehicleMovement])

	return (
		<Typography color='inherit' variant='caption'>
			{intervalDate}
		</Typography>
	)
}

export default IntervalDatePark
