/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { useBulkFormTemplatetAction } from '../../../../api/gql/mutations/formTemplateMutation'
import { FORM_TEMPLATE_FILTER_LIST } from '../../../../api/gql/queries/formTemplateQuery'
import listFormTemplateViewTable from '../../../../components/tableV2/config/administration/formTemplateList/listFormTemplateViewTable'
import { EPermissionScope } from '../../../../utils/app-models'
import {
	ITableToolBarActionConfig,
	ITableToolBarConfig,
} from '../../../../utils/typescript/tableType'

////////////////////////////////////////////////////////////////
// Global variable
////////////////////////////////////////////////////////////////

const queryConfig = {
	query: FORM_TEMPLATE_FILTER_LIST,
	queryName: 'formTemplates',
}

////////////////////////////////////////////////////////////////
// tableToolBarAction CONFIG
////////////////////////////////////////////////////////////////

const refreshQuerie = ['getFormTemplateFilterList']

const deletedBulk = {
	mutation: useBulkFormTemplatetAction,
	operation: 'remove',
	successMessageDelete: {
		singular: 'success.formTemplateDeleted',
		plural: 'success.formTemplatesDeleted',
	},
	messageConfirm: {
		title: 'confirmModal.deleteFormTemplatesTitle',
		message: 'confirmModal.deleteFormTemplatesMessage',
	},
	deletePermissions: [EPermissionScope.formTemplateManage],
}

const tableToolBarActionConfig: ITableToolBarActionConfig = {
	refreshQuerie,
	deletedBulk,
}

////////////////////////////////////////////////////////////////
// TableToolBarHeader CONFIG
////////////////////////////////////////////////////////////////

const tableToolBarConfig: ITableToolBarConfig = {
	title: 'administration.formTemplateList',
	stickyToolBar: true,
	tableConfig: listFormTemplateViewTable,
	localStorageName: 'listFormTemplateViewTableFilters',
	queryConfig,
	createPermission: [EPermissionScope.vehicleCreate],
	// textContent: [translateIntlMsgString('formTemplate.published')],
}

////////////////////////////////////////////////////////////////
// tableContainer CONFIG
////////////////////////////////////////////////////////////////

const tableContainerConfig = {
	tableConfig: listFormTemplateViewTable,
	queryConfig,
}

////////////////////////////////////////////////////////////////
// -> EXPORT CONFIG MAIN TABLE
////////////////////////////////////////////////////////////////

export const formTemplateConfigTable = {
	tableToolBarConfig: tableToolBarConfig,
	tableToolBarActionConfig,
	tableContainerConfig,
	displaySwitchOption: true,
	switchLabel: 'formTemplate.published',
}
