export default {
	userAlreadyExists: 'Cet utilisateur existe déjà.',
	accountSirenAlreadyExists: 'Ce numéro SIREN existe déjà.',
	wrongPassword: 'Mauvais mot de passe.',
	passwordsDoNotMatch: 'Les mots de passe ne sont pas identiques.',
	passwordsDoNotRegex: 'Le mot de passe ne respecte pas le format imposé.',
	genericError:
		"Une erreur est survenue, veuillez contacter le support si l'erreur persiste.",
	vehicleAlreadyOwned: `Ce vehicule existe déjà dans l'un de vos sites.`,
	vehiclesNeedFusion:
		'Les informations saisies correspondent à 2 véhicules différents.',
	twoVehiclesAlreadyExist:
		"2  véhicules trouvés. Le VIN et l'immatriculation ne correspondent pas au même véhicule. Vérifiez vos informations ou contactez le support.",
	vehicleImmatTooLong:
		"L'immatriculation du véhicule ne doit pas dépasser 10 caractères.",
	vehicleDoNotExists: "Ce véhicule n'existe pas",
	invalidImmat: 'Immatriculation invalide',
	formTemplateFieldControlList:
		'Un champ de "contrôle" doit posséder au moins 2 choix.',
	formTemplateFieldSelectionsList:
		'Un champ "sélection" doit posséder au moins 1 option.',
	errorExcel:
		"Erreur d'importation. Vérifiez que le fichier Excel d'importation est le bon.",
	errorExcelRequiredValues: 'Veuillez renseigner tous les champs obligatoires',
	errorDateSup: 'La date de fin ne peut être inférieure à la date de début',
	dateInvalid: 'Date invalide',
	invalidCredentials: 'Identifiants invalides',
	userNotEnabled:
		"Cet utilisateur n'est pas activé. Pour activer votre compte, suivez le lien qui vous a été envoyé dans un précédent mail d'invitation.",
	error404: 'Cette page est inconnue',
	errorTypeFilesCSV: 'Les fichiers de type .csv, .xls et .xlsx sont autorisés',
	accountSiretAlreadyExists: 'Cette agence existe déjà dans ce réseau',
	errorAddFileInvoice: "Erreur d'importation de la facture",
	errorFileIsCorrupted: 'Le fichier est corrompu',
	errorCreateInvoice: 'Erreur de création de la dépense',
	errorUpdateInvoice: 'Erreur de mis à jours de la dépense',
	plateOrVINMandatory: 'Le champ immatriculation ou vin est requis',
	brandAndModelMandatory: 'Le champ marque et modèle est requis',
	formatDateMec: "La date n'est pas au bon format",
	minCharacters: 'Minimum {minCharacters} caractères',
	contactAlreadyRegistered: 'Ce contact est déjà inscrit sur la plateforme',
	errorCreateDocumentVehicle: 'Erreur de création du document',
	errorUpdateDocumentVehicle: 'Erreur de mis à jours du document',
	errorAddFileDocumentVehicle: "Erreur d'importation du document",
	customerAlreadyExists: 'Le SIRET de ce compte client existe déjà',
}
