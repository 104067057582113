import {
	gql,
	OperationVariables,
	QueryHookOptions,
	useLazyQuery,
	useQuery,
} from '@apollo/client'
import {
	getInvoicesListAttributes,
	invoiceForModalAttributes,
} from '../attributes/invoicesAttributes'

export const GET_INVOICES_LIST = gql`
query getInvoicesList(
    $page: Int
    $itemsPerPage: Int
    $searchText: String
	$intervention: String
    $vehicle: String
	$dueDate: [InvoiceFilter_dueDate]
	$dateCreated: [InvoiceFilter_dateCreated]
	$orderBy: [InvoiceFilter_orderBy]
	$customer_list: Iterable
) {
    invoices(
		page: $page,
		itemsPerPage: $itemsPerPage,
		searchText: $searchText,
		intervention: $intervention,
		vehicle: $vehicle,
		dueDate: $dueDate,
		dateCreated:$dateCreated,
		orderBy: $orderBy,
		customer_list: $customer_list
		) {
        ${getInvoicesListAttributes}
    }
}`

export const EXPORT_INVOICES = gql`
	query exportInvoices(
		$searchText: String
		$intervention: String
		$dueDate: [InvoiceFilter_dueDate]
		$dateCreated: [InvoiceFilter_dateCreated]
		$immatOrVin: String
		$timezone: Int
		$orderBy: [InvoiceFilter_orderBy]
	) {
		exportInvoices(
			searchText: $searchText
			intervention: $intervention
			dueDate: $dueDate
			dateCreated: $dateCreated
			immatOrVin: $immatOrVin
			timezone: $timezone
			orderBy: $orderBy
		) {
			id
			exportFile
		}
	}
`

export const INVOICES_COUNT = gql`
	query getInvoicesCount($immatOrVin: String, $searchText: String) {
		invoices(
			page: 1
			itemsPerPage: 100000
			immatOrVin: $immatOrVin
			searchText: $searchText
		) {
			paginationInfo {
				totalCount
			}
		}
	}
`

export const INVOICES_COUNT_BY_VEHICLE = gql`
	query getInvoicesCountByVehicle($vehicle: String) {
		invoices(page: 1, itemsPerPage: 100000, vehicle: $vehicle) {
			paginationInfo {
				totalCount
			}
		}
	}
`

export const INVOICES_COUNT_BY_INTERVENTION = gql`
	query getInvoicesCountByIntervention($intervention: String) {
		invoices(page: 1, itemsPerPage: 100000, intervention: $intervention) {
			paginationInfo {
				totalCount
			}
		}
	}
`

export const INVOICE_FOR_MODAL = gql`
	query invoiceForModal($id: ID!) {
		invoice(id: $id) {
			${invoiceForModalAttributes}
		}
	} 
`

export const useLazyInvoiceForModal: any = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(INVOICE_FOR_MODAL, options)

	return {
		loading,
		invoice: data?.invoice || null,
		getInvoice: getData,
	}
}

export const useExportInvoices: any = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { data, loading, error } = useQuery(EXPORT_INVOICES, options)

	return {
		exportFile: data?.exportInvoices?.[0]?.exportFile || null,
		loading,
		error,
	}
}
