import React, { useContext, useMemo } from 'react'

import { Stack } from '@mui/material'

import { FormTemplateContext } from '../../../../../../utils/contexts/FormTemplateContext'
import { TitleField, RequireFieldTxt } from '../fieldEditForms/editFormsField'
import SelectForm from '../../../../../../components/forms/v1/formFields/SelectForm'
import { useFormatMessage } from '../../../../../../utils/hooks'
import {
	EFormTemplateBasicFields,
	EFormTemplateDateType,
} from '../../../../../../utils/app-models'

interface IFieldEditFormLayoutDate {}

const FieldEditFormLayoutDate: React.FC<IFieldEditFormLayoutDate> = () => {
	const {
		drawerUtils: { controlDrawer: control, fieldToUpdate },
	} = useContext(FormTemplateContext)
	const intlMsg = useFormatMessage()
	const specificFieldType = useMemo(
		() => fieldToUpdate?.values?.specificFieldType,
		[]
	)
	const slicedDataType: any = useMemo(
		() => Object.values(EFormTemplateDateType)?.slice(1),
		[]
	)

	return (
		<Stack spacing={2}>
			<RequireFieldTxt control={control} />
			<TitleField control={control} />
			{specificFieldType === EFormTemplateBasicFields.parkDate && (
				<SelectForm
					isRequired
					name='dateType'
					label={intlMsg('formTemplate.dateType')}
					control={control}
					selectElements={slicedDataType?.map((dateType, i) => ({
						key: `dateType-${dateType}-${i}`,
						label: intlMsg(`formTemplate.type${dateType}`),
						value: dateType,
					}))}
				/>
			)}
		</Stack>
	)
}

export default FieldEditFormLayoutDate
