import React, { useCallback } from 'react'

import LockIcon from '@mui/icons-material/Lock'
import {
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	Stack,
} from '@mui/material'

import { useFormatMessage, useModal } from '../../../../utils/hooks'
import EditUserPasswordModal from './'

const ListItemsBtnCSS = {
	color: 'white',
	p: 2,
	borderRadius: 1,
	backgroundColor: '#ffffff0a',
	'&:hover': { backgroundColor: '#ffffff17' },
	minWidth: '235px',
    position: 'relative',
}

interface IEditUserPasswordModalBtn {
	user: any
}

const EditUserPasswordModalBtn: React.FC<IEditUserPasswordModalBtn> = ({
	user,
}) => {
	const intlMsg = useFormatMessage()

	const modalUtils = useModal(false)

	const handleOpenClick = useCallback(() => {
		modalUtils.openModal()
	}, [])

	return (
		<>
			<Stack pt={4}>
				<List>
					<ListItem
						button
						onClick={handleOpenClick}
						disablePadding
						sx={ListItemsBtnCSS}
					>
						<ListItemIcon sx={{ color: 'white' }}>
							<LockIcon />
						</ListItemIcon>
						<ListItemText
							primaryTypographyProps={{
								sx: { color: 'white', fontSize: '13px', fontWeight: 800 },
							}}
							secondaryTypographyProps={{
								sx: { color: 'white', fontSize: '12px' },
							}}
							primary={intlMsg('misc.password')}
							secondary={intlMsg('administration.updatePassword')}
						/>
					</ListItem>
				</List>
				{/* {modalUtils?.isVisible && cloneElement(modal, { modalUtils, mode })} */}
				{modalUtils?.isVisible && (
					<EditUserPasswordModal modalUtils={modalUtils} user={user} />
				)}
			</Stack>
		</>
	)
}

export default EditUserPasswordModalBtn
