import React, { useState, useCallback, cloneElement } from 'react'

import MoreVertIcon from '@mui/icons-material/MoreVert'
import EditIcon from '@mui/icons-material/Edit'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { Box, IconButton, List, Popover } from '@mui/material'

import { EFormModalMode } from '../../../../../../utils/app-models'
import { IModalUtils, useModal } from '../../../../../../utils/hooks'
import ActionBtn from '../../../../../../components/tableV2/tableCell/actionsBtn/ActionBtn'
import ProductBaseDrawer from '../productBaseDrawer'

export const actionBtnCellCSS = {
	height: '48px',
	width: '100%',
	borderLeft: 'solid #f0f0f0 1px',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	paddingRight: '5px!important',
	paddingLeft: '5px!important',
	background: 'white',
	zIndex: 100,
}

interface IProductBaseTableBtn {
	rowId
	editModal?
}

const ProductBaseTableBtn: React.FC<IProductBaseTableBtn> = ({
	rowId,
	editModal = null,
}) => {
	const modalUtils: IModalUtils = useModal(false)

	/////////////////////////////////////////////////////////
	// MENU POPOVER
	/////////////////////////////////////////////////////////
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
	const open = Boolean(anchorEl)

	const handleClick = useCallback(
		(event: React.MouseEvent<HTMLButtonElement>) =>
			setAnchorEl(event.currentTarget),
		[anchorEl]
	)

	const handleClosePopover = useCallback(() => setAnchorEl(null), [anchorEl])

	/////////////////////////////////////////////////////////
	// DRAWER PRODUCT BASE
	/////////////////////////////////////////////////////////

	const [drawerIsOpen, setDrawerIsOpen] = useState(false)
	const handleDrawerOpen = useCallback(
		(open: boolean) => () => {
			setDrawerIsOpen(open)
			open && handleClosePopover()
		},
		[open]
	)

	/////////////////////////////////////////////////////////
	// Edit product base
	/////////////////////////////////////////////////////////

	const onEditProductBase = useCallback(() => {
		modalUtils.openModal()
		handleClosePopover()
	}, [])

	return (
		<>
			<Box sx={actionBtnCellCSS}>
				<IconButton
					size='small'
					onClick={handleClick}
					data-test-id='Playwright-ActionsBtn-moreAction'
				>
					<MoreVertIcon />
				</IconButton>
			</Box>

			<Popover open={open} anchorEl={anchorEl} onClose={handleClosePopover}>
				<List dense={false} component='nav'>
					<ActionBtn
						onClick={handleDrawerOpen(true)}
						txtIntlMsg='misc.see'
						Icon={VisibilityIcon}
					/>

					<ActionBtn
						onClick={onEditProductBase}
						txtIntlMsg='misc.edit'
						Icon={EditIcon}
					/>
				</List>
			</Popover>

			{drawerIsOpen && (
				<ProductBaseDrawer
					rowId={rowId}
					drawerIsOpen={drawerIsOpen}
					handleDrawerOpen={handleDrawerOpen}
					handleEdit={onEditProductBase}
				/>
			)}
			{modalUtils?.isVisible &&
				cloneElement(editModal, { modalUtils, mode: EFormModalMode.edit })}
		</>
	)
}

export default ProductBaseTableBtn
