/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import dayjs from 'dayjs'

import ChipUnitOfWork from '../../../../chip/ChipUnitOfWork'

export const listProductSharedCatalogueViewTable = [
	{
		columnLabel: 'misc.label',
		orderBy: 'name',
		dataKey: 'name',
	},

	{
		columnLabel: 'misc.ref',
		orderBy: 'commercialReference',
		dataKey: 'commercialReference',
	},

	{
		columnLabel: 'misc.duration',
		orderBy: 'duration',
		personalizedCell: ({ duration }) => (
			<>{duration ? dayjs(duration).format('HH:mm') : '-'}</>
		),
	},

	{
		columnLabel: 'misc.workUnit',
		orderBy: 'unitOfWork',
		personalizedCell: ({ unitOfWork }) => (
			<ChipUnitOfWork unitOfWork={unitOfWork} size='medium' />
		),
	},
]
