import React from 'react'

import { Typography, Stack } from '@mui/material'

import { useFormatMessage } from '../../../../../../../utils/hooks'
import { FormModalContext } from '../../../../../../../components/forms/formModal/FormModalContext'
import FormTextField from '../../../../../../../components/forms/formFields/FormTextField'
import CustomIconTooltips from '../../../../../../../components/tooltips'

interface ITransportPublicDescription {}

const TransportPublicDescription: React.FC<
	ITransportPublicDescription
> = () => {
	const intlMsg = useFormatMessage()

	return (
		<Stack spacing={1.5}>
			<Stack direction='row' alignItems='center'>
				<Typography variant='h2'>{intlMsg('misc.publicDesc')}</Typography>
				<CustomIconTooltips
					label='interventions.publicTransportComment'
					type='help'
				/>
			</Stack>

			<FormTextField
				name='publicComment'
				label={intlMsg('interventions.comments')}
				context={FormModalContext}
				multiline={true}
				minRows={8}
				maxRows={8}
			/>
		</Stack>
	)
}
export default TransportPublicDescription
