import React, { useState } from 'react'
import { List, ListItemButton } from '@mui/material'
import UserAvatarSlidePanel from '../../../avatar/UserAvatarSlidePanel'

const ListCSS = {
	'& .MuiButtonBase-root.Mui-disabled': { opacity: 1 },
}

interface IUsersListItems {
	data: any[]
}

const UsersListItems: React.FC<IUsersListItems> = ({ data }) => {
	const [isClickDisabled, setIsClickDisabled] = useState([])
	return (
		<List sx={ListCSS}>
			{data.map(({ firstName, lastName, id, account }, i) => {
				return (
					<ListItemButton key={id} disabled={isClickDisabled[i]}>
						<UserAvatarSlidePanel
							firstName={firstName}
							lastName={lastName}
							userId={id}
							accountId={account?.id}
							displayName
							setIsClickDisabled={setIsClickDisabled}
						/>
					</ListItemButton>
				)
			})}
		</List>
	)
}

export default UsersListItems
