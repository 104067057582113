import React from 'react'
import { Chip, List, Typography, Stack, Box } from '@mui/material'

const StackCSS = { flexWrap: 'wrap', gap: 1 }

interface ITasksAndCategoriesListItem {
	data: Array<any>
}

const TasksAndCategoriesListItem: React.FC<ITasksAndCategoriesListItem> = ({
	data = [],
}) => {
	return (
		<Box p={2}>
			<Stack spacing={2}>
				{Object.entries(data)?.map(([key, values]) => (
					<List key={key} dense disablePadding>
						<Typography variant='text1' gutterBottom>
							{key}
						</Typography>
						<Stack direction='row' sx={StackCSS}>
							{(values as string[]).map((name) => (
								<Chip
									key={name}
									label={name}
									color='primary'
									variant='outlined'
									size='small'
								/>
							))}
						</Stack>
					</List>
				))}
			</Stack>
		</Box>
	)
}

export default TasksAndCategoriesListItem
