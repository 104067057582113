import React, { useContext } from 'react'

import Typography from '@mui/material/Typography'
import { Stack, Button } from '@mui/material'

import { useFormatMessage } from '../../../../../../../utils/hooks'
import { subscribeOfferPictureLink } from '../../../../../../../utils/const'
import { EModule } from '../../../../../../../utils/app-models'
import { AuthContext } from '../../../../../../../utils/contexts/AuthContext'

interface ILimitationModalContentPicture {
	handleClose?
}

const LimitationModalContentPicture: React.FC<
	ILimitationModalContentPicture
> = ({ handleClose }) => {
	const intlMsg = useFormatMessage()
	const { checkModules, localLanguage } = useContext(AuthContext)
	const haveOptionExtraPicture = checkModules([EModule.optionExtraPicture])

	return (
		<Stack
			direction={{ xs: 'column', md: 'row' }}
			sx={{ width: { xs: '100%', md: '950px' }, height: '569px' }}
		>
			<Stack
				sx={{ width: { xs: '100%', md: '50%' }, backgroundColor: '#f8f8f8' }}
				justifyContent='end'
			>
				<img src={'/img/form-template/upgradePicture.svg'} />
			</Stack>

			<Stack
				p={6}
				justifyContent='center'
				sx={{ width: { xs: '100%', md: '50%' } }}
			>
				{!haveOptionExtraPicture ? (
					<>
						<Typography variant='body1' mb={2}>
							{intlMsg('formTemplate.limitReached')}
						</Typography>
						<Typography variant='h1' mb={4}>
							{intlMsg('formTemplate.limitReachedUpgraded')}
						</Typography>
						<Typography component='li'>
							{intlMsg('formTemplate.addUnlimitedPicture')}
						</Typography>
						<Typography component='li'>
							{intlMsg('formTemplate.addUnlimitedPicturePrice')}
							<span style={{ fontSize: '10px' }}>
								{intlMsg('formTemplate.addUnlimitedPicturePrice1')}
							</span>
						</Typography>
						<Button
							variant='contained'
							color='primary'
							fullWidth
							sx={{ mb: 2, mt: 4 }}
							href={subscribeOfferPictureLink[localLanguage]}
							target='_blank'
						>
							{intlMsg('formTemplate.subscribeOffer')}
						</Button>
						<Button
							variant='outlined'
							color='primary'
							fullWidth
							onClick={handleClose}
						>
							{intlMsg('formTemplate.skipOffer')}
						</Button>
					</>
				) : (
					<Typography variant='h1' mb={4}>
						{intlMsg('formTemplate.unlimitedLimitReached')}
					</Typography>
				)}
			</Stack>
		</Stack>
	)
}

export default LimitationModalContentPicture
