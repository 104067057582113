import { EFormTemplateBasicFields } from '../../../../utils/app-models'
import { formatGeneric } from '../../../../utils/string'

const typeField = {
	[EFormTemplateBasicFields.picture]: 'pictures',
	[EFormTemplateBasicFields.document]: 'document',
}

const possiblePhotoTypes = [
	EFormTemplateBasicFields.picture,
	EFormTemplateBasicFields.document,
]

/////////////////////////////////////////////////////////
// Extract records with photos
/////////////////////////////////////////////////////////

export const extractRecordInterventionWithPhotos = (dataList) => {
	const formatRecords = formatGeneric(dataList as any) as any

	return formatRecords?.map(
		({
			id: idForm,
			records,
			intervention,
			geolocation,
			dateCreated,
			formTemplate,
			vehicle,
		}) => {
			const blockPhotos: any = []

			const filteredRecordsByType = records?.filter((item) =>
				possiblePhotoTypes?.includes(item?.formTemplateField?.type)
			)

			filteredRecordsByType?.forEach(({ formTemplateField, value }) => {
				const attributeTemplateParse = JSON.parse(
					formTemplateField.attributes[0]
				)[`${typeField[formTemplateField?.type]}List`]

				blockPhotos.push({
					attributeTemplateParse,
					record: value[0] ? value : [],
					type: formTemplateField?.type,
				})
			})

			return {
				id: intervention?.id,
				referenceInter: intervention?.reference,
				interventionType: intervention?.interventionType,
				formTemplate: {
					idForm,
					geolocation,
					dateCreated,
					blockPhotos,
					name: formTemplate?.name,
				},
				vehicle: {
					id: vehicle?.id,
					immat: vehicle?.vehicleDetail?.immat,
				},
			}
		}
	)
}

/////////////////////////////////////////////////////////
// Group photos by vehicles
/////////////////////////////////////////////////////////

export const groupByVehicle = (data) => {
	const grouped = {}

	data?.forEach((item) => {
		const vehicleId = item.vehicle.id
		const formDetails = {
			idForm: item.formTemplate.idForm,
			geolocation: item.formTemplate.geolocation,
			dateCreated: item.formTemplate.dateCreated,
			blockPhotos: item.formTemplate.blockPhotos,
			name: item.formTemplate.name,
		}
		if (!grouped[vehicleId]) {
			grouped[vehicleId] = {
				id: item.id,
				referenceInter: item.referenceInter,
				vehicle: item.vehicle,
				form: [formDetails],
			}
		} else {
			grouped[vehicleId].form.push(formDetails)
		}
	})

	return Object.values(grouped)
}
