import React from 'react'
import CustomerContactModalInfos from './CustomerContactModalInfos'
import customerContactModalValidationSchema from './customerContactModalValidationSchema'

const customerContactModalConfig = [
	{
		component: <CustomerContactModalInfos />,
		schema: customerContactModalValidationSchema,
	},
]

export default customerContactModalConfig
