import React, { useCallback } from 'react'

import { Controller } from 'react-hook-form'

import TextField from '@mui/material/TextField'

const inputCSS = (isDisabled, hidden) => ({
	opacity: isDisabled ? '0.5' : '1',
	display: hidden ? 'none' : 'inherit',
})

interface IInputText {
	name?
	label?
	control?
	multiline?
	rows?
	isrequired?
	type?
	customHelper?
	valuesDefault?
	size?
	isDisabled?
	hidden?
	onBlur?
	rowsMax?
	specialOnChange?
	InputLabelProps?
	inputProps?
	InputProps?
	browserRequired?: boolean
}

const InputText: React.FC<IInputText> = ({
	control,
	name,
	label,
	isrequired,
	customHelper,
	valuesDefault,
	size,
	isDisabled,
	hidden,
	multiline = false,
	rows = 0,
	rowsMax = 4,
	type,
	onBlur,
	specialOnChange,
	InputLabelProps = null,
	inputProps = {},
	InputProps = {},
	browserRequired = false,
}) => {
	const preventDefault = useCallback((e) => e.preventDefault(), [])
	return (
		<Controller
			name={name}
			defaultValue={valuesDefault || ''}
			control={control}
			render={({
				field: { onChange, ...restField },
				fieldState: { error },
			}) => {
				return (
					<TextField
						{...restField}
						variant='outlined'
						onClick={preventDefault}
						onChange={specialOnChange || onChange}
						InputLabelProps={InputLabelProps || { shrink: !!restField?.value }}
						multiline={multiline}
						minRows={rows}
						maxRows={rowsMax}
						error={!!error}
						label={`${label} ${isrequired && !browserRequired ? '*' : ''}`}
						helperText={
							error ? error.message : customHelper ? customHelper : ''
						}
						size={size || 'small'}
						disabled={isDisabled}
						fullWidth
						type={type || 'text'}
						inputProps={inputProps}
						InputProps={InputProps}
						onBlur={onBlur}
						sx={{ ...inputCSS(isDisabled, hidden) }}
						required={browserRequired && isrequired}
					/>
				)
			}}
		/>
	)
}

export default InputText
