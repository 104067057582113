import React from 'react'

import {
	Typography,
	Stack,
	Dialog,
	DialogContent,
	DialogActions,
	Button,
	Box,
} from '@mui/material'
import { useFormatMessage } from '../../../utils/hooks'

const image = { warning: '/img/illustration/warningSign.svg' }

const footerCSS = { boxShadow: ({ shadows }) => shadows[1], border: 'none' }
const DialogContentCSS = { border: 'none', maxWidth: '800px' }

const messageCSS = {
	margin: 'auto',
	maxWidth: '530px',
	'& span': { fontWeight: 'bold', fontSize: '16px' },
	'& a': { fontWeight: 'bold', color: '#2558a3' },
}

interface IConfirmModal {
	onConfirmStatusModal
	onClose
	open
	message: string
	severity: 'warning'
}

const ConfirmModal: React.FC<IConfirmModal> = ({
	onConfirmStatusModal,
	onClose,
	open,
	message,
	severity,
}) => {
	const intlMsg = useFormatMessage()
	return (
		<Dialog onClose={onClose} aria-labelledby='simple-dialog-title' open={open}>
			<DialogContent dividers sx={DialogContentCSS}>
				<Stack direction='row' spacing={8} p={3} alignItems='center'>
					<Box>
						<img src={image[severity]} />
					</Box>

					<Box sx={messageCSS}>
						<Typography
							variant='body2'
							dangerouslySetInnerHTML={{ __html: message }}
						/>
					</Box>
				</Stack>
			</DialogContent>

			<DialogActions sx={footerCSS}>
				<Stack direction='row' spacing={1.5} justifyContent='flex-end' p={1.5}>
					<Button variant='text' onClick={onClose}>
						{intlMsg('misc.cancel')}
					</Button>
					<Button
						data-test-id='Playwright-ConfirmStatusModal'
						variant='contained'
						onClick={onConfirmStatusModal}
					>
						{intlMsg('misc.validate')}
					</Button>
				</Stack>
			</DialogActions>
		</Dialog>
	)
}
export default ConfirmModal
