import React from 'react'

import { Typography, Stack } from '@mui/material'
import VmCard from '../../../../components/viewModal/vmCard'

interface ITransportRef {
	interRef
}

const ReferenceForm: React.FC<ITransportRef> = ({ interRef }) => {
	return (
		<VmCard>
			<Stack direction='row' alignItems='center' justifyContent='space-between'>
				<Typography variant='h3'> {interRef || '-'}</Typography>
			</Stack>
		</VmCard>
	)
}
export default ReferenceForm
