import React, { memo, useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../utils/contexts/AuthContext'
import { TableContext } from '../../../utils/contexts/TableContext'

import ConnectedTableContainerProvider from './tableContainerProviders/ConnectedTableContainerProvider'
import DisconnectedTableContainerProvier from './tableContainerProviders/disconnectedTableContainerProvier'

interface ItableContainer {
	tableConfig
	queryConfig
	othersQueryParams?: any //allow to pass external and static queryParams
	onDataGetted?: any // allow to execute a function with getted data
	height?: string
	colorBorderLeftSelected?: string
	stickyToolBar?: boolean
	disabledCheckbox?: boolean
	argumentQueryViewModal?: string
}

const TableContainer: React.FC<ItableContainer> = ({
	queryConfig,
	tableConfig,
	othersQueryParams = null,
	onDataGetted = null,
	height,
	colorBorderLeftSelected,
	stickyToolBar = false,
	disabledCheckbox = false,
	argumentQueryViewModal,
}) => {
	const { checkModules, checkAccountsType, checkPermissions } =
		useContext(AuthContext)

	const { forcedData } = useContext(TableContext)
	const [filteredTableConfig, setFilteredTableConfig] =
		useState<any>(tableConfig)

	useEffect(() => {
		setFilteredTableConfig(
			tableConfig?.filter(
				({ modules, accountsTypeDenied, permissions }) =>
					(!modules || checkModules(modules)) &&
					(!accountsTypeDenied || !checkAccountsType(accountsTypeDenied)) &&
					(!permissions || checkPermissions(permissions))
			)
		)
	}, [tableConfig])

	return (
		<>
			{forcedData ? (
				<DisconnectedTableContainerProvier
					height={height}
					colorBorderLeftSelected={colorBorderLeftSelected}
					stickyToolBar={stickyToolBar}
					disabledCheckbox={disabledCheckbox}
					filteredTableConfig={filteredTableConfig}
					forcedData={forcedData}
				/>
			) : queryConfig ? (
				<ConnectedTableContainerProvider
					queryConfig={queryConfig}
					filteredTableConfig={filteredTableConfig}
					othersQueryParams={othersQueryParams}
					onDataGetted={onDataGetted}
					argumentQueryViewModal={argumentQueryViewModal}
					height={height}
					colorBorderLeftSelected={colorBorderLeftSelected}
					stickyToolBar={stickyToolBar}
					disabledCheckbox={disabledCheckbox}
				/>
			) : (
				<>There is a problem in the TAB config.</>
			)}
		</>
	)
}

export default memo(TableContainer)
