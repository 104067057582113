import React, { useCallback, useContext, useEffect, useMemo } from 'react'

import Grid from '@mui/material/Grid'

import { useGetCustomerListForSelect } from '../../../../../api/gql/queries/customersQueries'
import { useLazySitesForSelect } from '../../../../../api/gql/queries/sitesQueries'
import FormSelectSearch from '../../../../../components/forms/formFields/formSelectSearch'
import { FormModalContext } from '../../../../../components/forms/formModal/FormModalContext'
import { useFormatMessage } from '../../../../../utils/hooks'

interface ICustomersForAsyncSites {}

const CustomersForAsyncSites: React.FC<ICustomersForAsyncSites> = () => {
	const { setValues, data } = useContext(FormModalContext)
	const intlMsg = useFormatMessage()
	const { customers } = useGetCustomerListForSelect()
	const { getSites, sites } = useLazySitesForSelect()
	const selectedCustomer = useMemo(
		() => data?.customer || null,
		[data?.customer]
	)

	const customersOptions = useMemo(
		() =>
			customers?.map((customer) => ({
				key: customer?.id,
				label: customer?.name,
				value: customer,
			})) || [],
		[customers]
	)

	const sitesOptions = useMemo(
		() =>
			sites.map((site) => ({
				key: site.id,
				label: site.name,
				value: site,
			})) || [],
		[sites]
	)

	useEffect(() => {
		if (selectedCustomer)
			getSites({ variables: { customer: selectedCustomer?.value?.id } })
	}, [selectedCustomer])

	const handleCustomerChange = useCallback(
		({ option }) => {
			setValues({ customer: option || '', site: null })
		},
		[setValues]
	)

	return (
		<>
			<Grid item xs={12}>
				<FormSelectSearch
					name={'customer'}
					label={intlMsg('misc.account')}
					specialOnChange={handleCustomerChange}
					selectElements={customersOptions}
					context={FormModalContext}
					missingText={intlMsg('misc.noResultSearch')}
					isRequired
					trigerred
				/>
			</Grid>
			<Grid item xs={12}>
				<FormSelectSearch
					name='site'
					label={intlMsg('misc.sitesLabel')}
					selectElements={sitesOptions}
					context={FormModalContext}
					missingText={intlMsg('misc.noResultSearch')}
					isDisabled={!selectedCustomer}
					isRequired
					trigerred
				/>
			</Grid>
		</>
	)
}

export default CustomersForAsyncSites
