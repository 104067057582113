import React, { useEffect, useState } from 'react'

interface IViewModalTabProvider {
	children: any
	isSelected?: boolean
}

const ViewModalTabProvider: React.FC<IViewModalTabProvider> = ({
	children,
	isSelected = false,
}) => {
	const [isItSelectedForAfirstTime, setisItSelectedForAfirstTime] =
		useState(false)

	useEffect(() => {
		if (!isItSelectedForAfirstTime && isSelected)
			setisItSelectedForAfirstTime(true)
	}, [isSelected, isItSelectedForAfirstTime])

	return isItSelectedForAfirstTime ? children : <></>
}

export default ViewModalTabProvider
