import React, { useCallback } from 'react'

import { useQuery } from '@apollo/client'
import { Box, Fade } from '@mui/material'
import Drawer from '@mui/material/Drawer'

import { GET_PRODUCT_BASE_DRAWER } from '../../../../../../api/gql/queries/catalogProductQueries'
import ProductBaseDrawerHeader from './productBaseDrawerHeader'
import ProductBaseDrawerResume from './productBaseDrawerResume'
import ProductBaseDrawerTable from './productBaseDrawerTable'

const drawerCSS = {
	'&.MuiPaper-root ': {
		maxWidth: '550px',
		minWidth: '550px',
		minHeight: '707px',
	},
}

const PaperProps = { sx: drawerCSS }
const DrawerCSS = { zIndex: 1300 }

interface IProductBaseDrawer {
	rowId: string
	drawerIsOpen: boolean
	handleDrawerOpen: (open: boolean) => () => void
	handleEdit
}

const ProductBaseDrawer: React.FC<IProductBaseDrawer> = ({
	rowId,
	drawerIsOpen,
	handleDrawerOpen,
	handleEdit,
}) => {
	const onClose = useCallback(() => {
		handleDrawerOpen(false)()
	}, [handleDrawerOpen])

	const { data, loading } = useQuery(GET_PRODUCT_BASE_DRAWER, {
		variables: { id: rowId },
	})

	return (
		<Fade in={!loading} timeout={200}>
			<Drawer
				anchor='right'
				open={drawerIsOpen}
				onClose={onClose}
				PaperProps={PaperProps}
				sx={DrawerCSS}
			>
				<ProductBaseDrawerHeader
					title={data?.productBase?.name}
					onClose={onClose}
					handleEdit={handleEdit}
				/>
				<Box p={4}>
					<ProductBaseDrawerResume productBase={data?.productBase} />
					<ProductBaseDrawerTable productBase={data?.productBase} />
				</Box>
			</Drawer>
		</Fade>
	)
}

export default ProductBaseDrawer
