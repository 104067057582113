import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'
import Providers from './providers'

document.documentElement.lang = 'fr'

const root = ReactDOM.createRoot(document?.getElementById('root') as any)
root.render(
	// <React.StrictMode>
	<Providers>
		<App />
	</Providers>
	// </React.StrictMode>,
)
