import React, { useMemo } from 'react'
import { useFormatMessage } from '../../utils/hooks'
import { Chip } from '@mui/material'
import { stringifiedColor } from '../../utils/color'
import { Tsize } from '../../styles/type/sizeType'
import { EChipColorStatus } from '../../utils/app-models'

interface IChipColorStatusProps {
	label: string
	color: string
	code?: string
	size?: Tsize
	sx?: object
	type: EChipColorStatus
}

const ChipColorStatus: React.FC<IChipColorStatusProps> = ({
	label,
	color,
	code,
	size = 'medium',
	sx = {},
	type,
}) => {
	const intlMsg = useFormatMessage()

	const title = useMemo(() => {
		const key = code
			? `${type}.${code.toLowerCase().replace(/\s+/g, '')}`
			: label

		return intlMsg(key)
	}, [code, label, intlMsg, type])

	const chipSx: any = useMemo(
		() => ({
			color: 'white',
			backgroundColor: stringifiedColor(color || 'grey', 400),
			...sx,
		}),
		[color, sx]
	)

	return <Chip size={size} sx={chipSx} label={title} />
}

export default ChipColorStatus
