/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { gql, useMutation } from '@apollo/client'
import {
	bulkActionSitePayloadAttributes,
	customerSitePayloadAttributes,
	removeCustomerSitePayloadAttributes,
} from '../attributes/sitesAttributes'

/**  ---------------------MUTATIONS---------------------- */

export const UPDATE_SITE = gql`
	mutation updateSite($input: updateSiteInput!) {
		updateSite(input: $input) {
			${customerSitePayloadAttributes}
		}
	}
`
export const CREATE_SITE = gql`
	mutation createSite($input: createSiteInput!) {
		createSite(input: $input) {
			${customerSitePayloadAttributes} 
		}
	}
`

export const DELETE_SITE = gql`
	mutation deleteSite($input: deleteSiteInput!) {
		deleteSite(input: $input) {
			${customerSitePayloadAttributes}
		}
	}
`

export const REMOVE_SITE = gql`
	mutation removeSite($input: removeSiteInput!) {
		removeSite(input: $input) {
			${removeCustomerSitePayloadAttributes}
		}
	}
`

export const BULK_SITE_ACTION = gql`
	mutation bulkOperationSite($input: bulkOperationSiteInput!) {
		bulkOperationSite(input: $input) {
			${bulkActionSitePayloadAttributes}
		}
	}
`

export const IMPORT_SITE = gql`
	mutation importSite($input: importSiteInput!) {
		importSite(input: $input) {
			site {
				id
			}
		}
	}
`

/**  ---------------------HOOKS---------------------- */

export const useUpdateSite = () => useMutation(UPDATE_SITE)
export const useCreateSite = () => useMutation(CREATE_SITE)
export const useRemoveSite = () => useMutation(REMOVE_SITE)
export const useBulkSiteAction = () => useMutation(BULK_SITE_ACTION)
export const useImportSite = () => useMutation(IMPORT_SITE)
