import React from 'react'
import { Box } from '@mui/material'
import InformationsAccount from './InformationsAccount'

interface IAccountInformations {
	data
}

const AccountInformations: React.FC<IAccountInformations> = ({ data }) => {
	return (
		<Box sx={{ p: 2 }}>
			<InformationsAccount
				name={data?.name}
				gestionnaire={data?.gestionnaire}
				socialName={data?.socialName}
				tva={data?.tva}
				siret={data?.siret}
				city={data?.city}
				zipcode={data?.zipcode}
				address={data?.address}
				customerResp={data?.contacts}
			/>
		</Box>
	)
}

export default AccountInformations
