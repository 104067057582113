/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'

import BooleanColorPopover from '../../../popover/BooleanColorPopover'
import ModalProvider from '../../../../providers/ModalProvider'

import { AdminPanelAccount } from '../../../../utils/validationSchema'

import { useUpdateAdminPanelAccount } from '../../../../api/gql/mutations/adminPanelAccountsMutation'
import ActionsBtn from '../../tableCell/actionsBtn/v1'
import { EFormModalMode } from '../../../../utils/app-models'
import LocationAddress from '../../../locationAddress'
import AdminPanelAccountsModal from '../../../../view/adminPanel/accounts/adminPanelAccountsModal'
import ListChipsmodule from '../../../popover/listChipsModulePopover/ListChipsmodule'

//!  columnLabel is mandatory
//!  dataKey OR personalizedCell is mandatory
// use datakey if you want to just display data
// personalized Cell is a function taking row as parameter

const listAdminPanelAccountViewTable = [
	{
		columnLabel: 'misc.name',
		sortId: 'name',
		dataKey: 'name',
	},

	{
		columnLabel: 'misc.address',
		sortId: 'address',
		personalizedCell: ({ address, city, zipcode }) => (
			<LocationAddress
				address={address}
				city={city}
				zipcode={zipcode}
				maxCharacters={40}
			/>
		),
	},

	{
		columnLabel: 'misc.accountType',
		sortId: 'accountType.name',
		dataKey: 'accountType.name',
	},

	{
		columnLabel: 'adminPanel.contactAdmin',
		sortId: 'contactAdmin',
		personalizedCell: () => (
			<div>
				{/*
				// TODO
				<Avatar
					user={{
						firstName: 'todo',
						lastName: 'todo',
						id: 'gestionnaire.id',
					}}
					clickable={true}
					withName={true}
				/> */}
			</div>
		),
	},

	{
		columnLabel: 'adminPanel.nbUsers',
		sortId: 'nbUsers',
		personalizedCell: ({
			customers: {
				paginationInfo: { totalCount: totalActive },
			},
			customersTotalNb: {
				paginationInfo: { totalCount: customersTotalNb },
			},
		}) => <div>{`${totalActive}/${customersTotalNb}`}</div>,
	},

	{
		columnLabel: 'adminPanel.nbVehicles',
		sortId: 'nbVehicles',
		personalizedCell: () => <div>5 (todo)</div>,
	},

	{
		columnLabel: 'misc.nbInterventions',
		sortId: 'nbInterventions',
		personalizedCell: ({ createdInterventions }) => (
			<div>
				{createdInterventions?.paginationInfo?.totalCount ||
					'TO REVIEW'}
			</div>
		),
	},

	{
		columnLabel: 'adminPanel.nbReportsIntervention',
		sortId: 'nbReportsIntervention',
		personalizedCell: ({
			executedInterventions: {
				paginationInfo: { totalCount },
			},
		}) => <div>{totalCount}</div>,
	},

	{
		columnLabel: 'adminPanel.modules',
		sortId: 'modules',
		personalizedCell: ({ customModules }) => (
			<ListChipsmodule defaultModules={customModules} />
		),
	},

	{
		columnLabel: 'misc.active',
		sortId: 'isActive',
		tableCellAlign: 'center',
		cellAlign: 'center',
		personalizedCell: ({ isActive, id }: any) => {
			return (
				<BooleanColorPopover
					value={isActive}
					id={id}
					txt={{ true: 'misc.active', false: 'misc.desactive' }}
					titlePopover={'misc.status'}
					queryConfig={{
						query: useUpdateAdminPanelAccount,
						refreshQuery: ['getAdminPanelAccount'],
						successMessage: 'success.statusUpdate',
					}}
				/>
			)
		},
	},

	{
		fixWidth: '50px',
		sortId: 'null',
		visible: true,
		columnLabel: '',
		tableCellAlign: 'center',
		cellAlign: 'center',
		sticky: true,
		personalizedCell: (row: any) => (
			//TODO HERE OLD VERSION OF ModalProvider
			//WE NEED TO CHANGE THIS
			<ModalProvider
				validationSchemaFunction={AdminPanelAccount}
				formModalMode={EFormModalMode.edit}
			>
				<ActionsBtn
					rowId={row?.id}
					Edit={<AdminPanelAccountsModal row={row} />}
					viewAction={false}
					key={row.id}
				/>
			</ModalProvider>
		),
	},
]

export default listAdminPanelAccountViewTable
