/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const signatureFieldPDF = (doc, record, position) => {
	if (record?.value?.value) {
		const {
			positionYcell,
			positionXcell,
			widthImgSignature,
			heightImgSignature,
		} = position

		// add image
		// 	HELP -> addImage(imageData, format, positionXcell, positionYcell, width, height, alias, compression, rotation)
		doc.addImage(
			record.value.value,
			'JPEG',
			positionXcell,
			positionYcell + 5,
			widthImgSignature,
			heightImgSignature
		)
	}
}
