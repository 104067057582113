import React, { useCallback, useContext, useEffect, useState } from 'react'

import qs from 'qs'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'
import isEmpty from 'lodash.isempty'

import { Stack, Button, Drawer, Fade } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import LoadingButton from '@mui/lab/LoadingButton'

import FieldEdit from './formsEditions/fieldEdit'
import ModelEdition from './modelEdition'
import FormsEdition from './formsEditions'
import { FormTemplateContext } from '../../../utils/contexts/FormTemplateContext'
import { useFormatMessage, useModal } from '../../../utils/hooks'
import { useCreateFormTemplate } from '../../../api/gql/mutations/formTemplateMutation'
import { useLazyFormTemplate } from '../../../api/gql/queries/formTemplateQuery'
import { ROUTES } from '../../../routes/consts'
import flattenObject from '../../../utils/flattenObject'
import {
	defaultLimitation,
	verifyAndSetLimitationField,
} from './formsEditions/formEditionsUtils/fieldsLimitation'
import InformationModal from '../../../components/modal/InformationModal'
import getLimitationModalByTypeField from './formsEditions/formEditionsUtils/fieldsLimitation/getLimitationModalByTypeField'
import { AuthContext } from '../../../utils/contexts/AuthContext'
import ConfirmModal from '../../../components/modal/ConfirmModal'
import isEqual from 'lodash.isequal'
import translateIntlMsgString from '../../../utils/translateIntlMsgString'

const fixedFooterCSS = {
	position: 'fixed',
	bottom: 0,
	right: 0,
	p: 2,
	width: 1,
	background: 'white',
	boxShadow: '#f3f3f3 0px 0px 8px 8px',
}

const loadingContainerCSS = {
	width: 1,
	height: 'calc(100vH - 149px)',
}

interface IFormTemplate {}

const defaultValues = {
	isActive: true,
	interventionType: translateIntlMsgString('interventions.all'),
	pages: [
		{
			name: 'Page 1',
			fields: [],
		},
	],
}

const FormTemplate: React.FC<IFormTemplate> = () => {
	const intlMsg = useFormatMessage()
	const navigate = useNavigate()
	const location = useLocation()
	const { checkModules } = useContext(AuthContext)
	const { isVisible, openModal, closeModal } = useModal()

	const { id: currentFormTemplateId } = qs.parse(location?.search, {
		ignoreQueryPrefix: true,
	})
	const {
		formUtils: {
			control,
			handleSubmit,
			setValue,
			reset,
			getValues,
			formState: { errors: formTemplateErrors },
		},
		loadingFormTemplate,
		setLoadingFormTemplate,
		setLimitationFields,
		isLimitModalOpen,
		setIsOpenLimitModal,
	} = useContext(FormTemplateContext)
	const {
		drawerUtils: { toggleDrawer, stateDrawer },
	} = useContext(FormTemplateContext)
	const [saveFormTemplate] = useCreateFormTemplate()
	const [isDataFormated, setIsDataFormated] = useState(false)
	const [savedValues, setSavedValues] = useState({})

	const { loadingFormTemplateQuery, getFormTemplate, formTemplate } =
		useLazyFormTemplate()

	useEffect(() => {
		if (!isEmpty(formTemplateErrors)) {
			window.scrollTo({
				left: 0,
				top: 0,
				behavior: 'smooth',
			})
		}
	}, [formTemplateErrors])

	useEffect(() => {
		if (!loadingFormTemplateQuery && formTemplate) {
			// setLimitationFields
			verifyAndSetLimitationField({
				setLimitationFields,
				pages: formTemplate.pages,
			})

			// Set default value for fields
			Object.entries(formTemplate).map(([key, value]: [string, any]) => {
				if (key === 'pages') {
					const pages =
						value.map(({ name, fields }) => ({
							name,
							fields: fields.map(({ name, type, attributes, model }) => ({
								name,
								fieldType: type,
								fieldModel: model,
								values: JSON.parse(attributes),
							})),
						})) || []

					setValue(key, pages)
				} else if (key === 'customer') {
					const customer = { ...value }
					setValue(key, customer?.id || 'all')
				} else if (key === 'interventionType')
					setValue(
						key,
						value === '' || value === null || value === undefined
							? intlMsg(`interventions.all`)
							: value
					)
				else if (key !== '__typename') setValue(key, value)
			})

			setIsDataFormated(true)
		}
	}, [loadingFormTemplateQuery, formTemplate])

	useEffect(() => {
		if (isDataFormated) setSavedValues(JSON.stringify(getValues()))
	}, [isDataFormated])

	useEffect(() => {
		if (currentFormTemplateId) {
			getFormTemplate({ variables: { id: currentFormTemplateId } })
		} else {
			Object.entries(flattenObject(defaultValues) as any).forEach(
				([key, value]) => {
					setValue(key, value)
				}
			)

			setIsDataFormated(true)
		}
	}, [currentFormTemplateId])

	const submitData = useCallback(
		async (data) => {
			setLoadingFormTemplate(true)

			const { pages, isActive, customer, name, interventionType, ...rest } =
				data

			await saveFormTemplate({
				variables: {
					input: {
						name,
						customer: customer === 'all' ? '' : customer,
						id: currentFormTemplateId || null,
						interventionType:
							interventionType === intlMsg(`interventions.all`)
								? ''
								: interventionType,
						...rest,
						isActive: isActive === 'true',
						pagesList: pages.map(({ name, fields }, i) => ({
							name,
							listOrder: i,
							fieldsList: fields.map(
								({ name, fieldType, fieldModel, values }) => ({
									attributes: JSON.stringify(values),
									name,
									type: fieldType,
									model: fieldModel,
								})
							),
						})),
					},
				},
			})
				.then((res) => {
					toast.success(
						currentFormTemplateId
							? 'success.templateFormUpdate'
							: 'success.templateFormCreate'
					)
					setLoadingFormTemplate(false)
					setLimitationFields(defaultLimitation(checkModules))
					if (!currentFormTemplateId)
						navigate(
							`${ROUTES.ADMINISTRATION.FORM_TEMPLATE_EDITION.url}?id=${res?.data?.saveFormTemplate?.formTemplate?.id}`
						)
					setSavedValues(
						JSON.stringify({
							...getValues(),
							title: res?.data?.saveFormTemplate?.formTemplate?.title,
							id: res?.data?.saveFormTemplate?.formTemplate?.id,
						})
					)
				})
				.catch(() => {
					setLoadingFormTemplate(false)
				})
		},
		[currentFormTemplateId, navigate]
	)

	const leaveformTemplate = useCallback(() => {
		if (!isEqual(savedValues, JSON.stringify(getValues()))) openModal()
		else {
			reset()
			navigate(ROUTES.ADMINISTRATION.FORM_TEMPLATES.url)
		}
	}, [navigate, savedValues, getValues])

	const onModalClose = useCallback(() => {
		setIsOpenLimitModal('')
	}, [])

	const onSubmit = useCallback((data) => {
		submitData(data)
	}, [])

	const handleConfirmModal = useCallback(() => {
		reset()
		navigate(ROUTES.ADMINISTRATION.FORM_TEMPLATES.url)
		closeModal()
	}, [navigate])

	return (
		<Stack sx={{ width: 1 }} className='formTemplate'>
			{isDataFormated ? (
				<form onSubmit={handleSubmit(onSubmit)}>
					<Fade in={true} timeout={500}>
						<div>
							<ModelEdition />
							<FormsEdition control={control} />
							<Stack
								direction='row'
								justifyContent='flex-end'
								sx={fixedFooterCSS}
								spacing={1}
							>
								<Button
									variant='outlined'
									color='primary'
									onClick={leaveformTemplate}
								>
									{intlMsg('misc.backEdit')}
								</Button>

								<LoadingButton
									variant='contained'
									type='submit'
									loading={loadingFormTemplate}
								>
									{intlMsg('misc.save')}
								</LoadingButton>
							</Stack>
						</div>
					</Fade>
				</form>
			) : (
				<Stack
					alignItems='center'
					justifyContent='center'
					sx={loadingContainerCSS}
				>
					<CircularProgress sx={{ height: '70px', width: '70px' }} />
				</Stack>
			)}

			<Drawer
				anchor={'right'}
				open={stateDrawer['right']}
				onClose={toggleDrawer('right', false)}
			>
				{stateDrawer.right && <FieldEdit />}
			</Drawer>

			{isLimitModalOpen && (
				<InformationModal onClose={onModalClose} open={!!isLimitModalOpen}>
					{getLimitationModalByTypeField(isLimitModalOpen)}
				</InformationModal>
			)}
			{isVisible && (
				<ConfirmModal
					onClose={closeModal}
					open={isVisible}
					handleConfirm={handleConfirmModal}
					img={'/img/illustration/warningSign.svg'}
					message={{
						title: intlMsg('confirmModal.confirmQuitFOrmTemplateEditorTitle'),
						message: intlMsg(
							'confirmModal.confirmQuitFOrmTemplateEditorMessage'
						),
					}}
					cancelMessage={intlMsg('confirmModal.return')}
					validationMessage={intlMsg('confirmModal.quitWithoutSave')}
				/>
			)}
		</Stack>
	)
}

export default FormTemplate
