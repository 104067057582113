import React, { useMemo, memo } from 'react'

import { Stack, Paper, Fade } from '@mui/material'
import BuildIcon from '@mui/icons-material/Build'
import { useFormatMessage } from '../../../utils/hooks'

//import { useWVehicleIntervention } from '../../../api/gql/queries/vehiclesQueries'
import WidgetIconTitle from '../elements/WidgetIconTitle'
import { useWTotalInterventionsNotAssigned } from '../../../api/gql/queries/interventionsQueries'
import CircularProgressWidget from '../elements/CircularProgressWidget'

interface IWTotalInterventionsAssigned {
	filterQueries
}

const WTotalInterventionsNotAssigned: React.FC<
	IWTotalInterventionsAssigned
> = ({ filterQueries }) => {
	const intlMsg = useFormatMessage()

	const { notAssigned, loading } = useWTotalInterventionsNotAssigned({
		variables: {
			...filterQueries,
		},
	})

	const subTitle = useMemo(
		() =>
			notAssigned?.filter(({ actors }) => !actors?.paginationInfo?.totalCount)
				?.length,
		[notAssigned]
	)

	return (
		<Paper elevation={1} sx={{ height: 1 }} id='widget'>
			<Stack p={4} justifyContent='center' sx={{ height: 1 }}>
				<Fade in={!loading}>
					<Stack
						direction='row'
						justifyContent={loading ? 'center' : 'space-between'}
						alignItems='center'
					>
						<WidgetIconTitle
							icon={{
								iconColor: 'red',
								Icon: BuildIcon,
							}}
							title={intlMsg('widget.interventionNotAssigned')}
							subTitle={subTitle}
							subtitleColor
						/>
					</Stack>
				</Fade>
				{loading && <CircularProgressWidget />}
			</Stack>
		</Paper>
	)
}

export default memo(WTotalInterventionsNotAssigned)
