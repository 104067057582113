export const mergeSchemaValidation = (configArray) => {
	let mergedSchema: any = null

	configArray.forEach((config) => {
		if (config?.schema) {
			mergedSchema = mergedSchema
				? mergedSchema?.concat(config.schema)
				: config.schema
		}
	})

	return mergedSchema
}

export const handleFormOnblur =
	(trigerred, onBlur, name, triggerError) => () => {
		trigerred && triggerError({ fieldName: name })
		onBlur && onBlur()
	}

export const handleFormOnblurV2 = (trigerred, onBlur, name, triggerError) => {
	trigerred && triggerError({ fieldName: name })
	onBlur && onBlur()
}
