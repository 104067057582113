import React, { useCallback, useMemo, useState } from 'react'

import { useFormatMessage } from '../../../../utils/hooks'
import MultiItemsButton from '../../MultiItemsButton'
import MultiItemsPopover from '..'
import UserAvatarSlidePanel from '../../../avatar/UserAvatarSlidePanel'
import UsersListItems from './UsersListItems'

interface IUsersPopover {
	data
	title: string
}

const UsersPopover: React.FC<IUsersPopover> = ({ data, title }) => {
	const intlMsg = useFormatMessage()
	const [anchorEl, setAnchorEl] = useState(null)
	const handleClick = useCallback((e) => {
		setAnchorEl(e.currentTarget)
	}, [])
	const handleClose = useCallback(() => setAnchorEl(null), [])
	const open = useMemo(() => Boolean(anchorEl), [anchorEl])

	const numberUsers = useMemo(() => data?.length, [data])

	const customButton = useCallback(() => {
		return (
			<UserAvatarSlidePanel
				firstName={data[0]?.firstName}
				lastName={data[0]?.lastName}
				userId={data[0]?.id}
				accountId={data[0]?.account?.id}
				displayName={false}
			/>
		)
	}, [data])

	return (
		<>
			<MultiItemsButton
				buttonType={numberUsers > 1 ? 'chipCountClickable' : 'customButton'}
				nbItems={numberUsers}
				label={data?.length > 1 ? intlMsg(title) : customButton}
				handleClick={handleClick}
			/>

			{open && (
				<MultiItemsPopover
					open={open}
					handleClose={handleClose}
					anchorEl={anchorEl}
				>
					<UsersListItems data={data} />
				</MultiItemsPopover>
			)}
		</>
	)
}

export default UsersPopover
