import React, { useCallback } from 'react'

import { Controller } from 'react-hook-form'
import frLocale from 'date-fns/locale/fr'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { TextField } from '@mui/material'
import dayjs from 'dayjs'
import { useFormatMessage } from '../../../../utils/hooks'
import { replaceHoursFromDate } from '../../../../utils/date'

interface IDatePicker {
	name: string
	control: any
	label?: string
	valuesDefault?: any
	isrequired?
	externalValue?
	specialOnChange?
	minDate?
	maxDate?
	openTo?
	views?
	shouldDisableDate?: any
	shouldDisableMonth?: any
	isStartOfDay?: boolean
	conserveHour?: boolean
	setError?
	clearErrors?
}

const DatePicker: React.FC<IDatePicker> = ({
	name,
	control,
	label,
	valuesDefault,
	isrequired,
	externalValue = null,
	specialOnChange = null,
	minDate,
	maxDate,
	openTo = 'day',
	views = ['day', 'month', 'year'],
	shouldDisableDate = null,
	shouldDisableMonth = null,
	isStartOfDay = true,
	conserveHour = false,
	setError,
	clearErrors,
}) => {
	//this function is used to fix a WEIRD bug
	// when we fill a date with keyboard in the input,hours in the values Object is egal to 0
	// when converted into isostring, seems like -2h, so the day decreased
	//we add 2 hours with the fnc to fix this problem
	const intlMsg = useFormatMessage()
	const addOneHours = useCallback(
		(onChange) => (date) => {
			if (dayjs(date).isValid()) {
				let newDate = isStartOfDay ? date.startOf('day').add(2, 'h') : date

				if (conserveHour) {
					newDate = replaceHoursFromDate(dayjs(newDate), dayjs(valuesDefault))
				}

				specialOnChange ? specialOnChange(newDate, onChange) : onChange(newDate)

				clearErrors && clearErrors(name)
			} else {
				setError &&
					setError(name, {
						type: 'custom',
						message: intlMsg('errors.dateInvalid'),
					})
			}
		},
		[specialOnChange, valuesDefault]
	)

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={frLocale}>
			<Controller
				name={name}
				defaultValue={externalValue || valuesDefault}
				control={control}
				render={({ field: { onChange, value }, fieldState: { error } }) => {
					return (
						<DesktopDatePicker
							shouldDisableMonth={shouldDisableMonth}
							shouldDisableDate={shouldDisableDate}
							orientation='landscape'
							openTo={openTo}
							views={views}
							onChange={addOneHours(onChange)}
							value={externalValue || value || valuesDefault || null}
							renderInput={(params) => (
								<TextField
									{...params}
									fullWidth
									size='small'
									error={!!error}
									helperText={error ? error.message : ''}
									required={isrequired}
								/>
							)}
							inputFormat='DD/MM/YYYY'
							label={label}
							minDate={minDate}
							maxDate={maxDate}
						/>
					)
				}}
			/>
		</LocalizationProvider>
	)
}

export default DatePicker
