/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'

import { ALERT_COUNT_BY_INTERVENTION } from '../../../api/gql/queries/alertsQueries'
import {
	TRANSPORT_FORM_PHOTOS_COUNT,
	INTERVENTION_FORMS_COUNT_BY_INTERVENTION,
} from '../../../api/gql/queries/interventionFormsQueries'
import { INVOICES_COUNT_BY_INTERVENTION } from '../../../api/gql/queries/invoicesQueries'
import { EPeriodFilter } from '../../../utils/app-models'
import Alerts from '../../dashboard/alerts'
import Invoices from '../../dashboard/invoice'
import InterventionControlForms from '../../dashboard/interventionForms'
import InterventionTransportDashboard from '../dashBoardComponents/interventionTransportDashboard'
import transportListInterventionControlFormsViewModalTable from '../../../components/tableV2/config/dashboard/interventionForms/transportListInterventionControlFormsViewModalTable'
import transportListAlertsViewModalTable from '../../../components/tableV2/config/dashboard/alerts/transportListAlertsViewModalTable'

import { DOCUMENTS_VEHICLES_COUNT_BY_INTERVENTION } from '../../../api/gql/queries/documentsVehiclesQueries'
import VehiclesDocuments from '../../dashboard/vehiclesDocuments'
import transportListDocumentsViewModalTable from '../../../components/tableV2/config/dashboard/documentsVehicles/transportListDocumentsViewModalTable'
import transportListInvoicesViewTable from '../../../components/tableV2/config/dashboard/invoices/transportListInvoicesViewTable'
import PhotosGroupedByForm from '../customTabs/interventionFormsPhotos/photosGroupedByForm'

// ////////////////////////////////////////////////////////////////////////////////////////////////////////
// INTERVENTIONS TAB CONFIG
// ////////////////////////////////////////////////////////////////////////////////////////////////////////

export const interventionTransportTabsConfig = [
	{
		label: 'misc.dashboard',
		component: <InterventionTransportDashboard />,
	},

	{
		label: 'interventionForms.reports',
		query: INTERVENTION_FORMS_COUNT_BY_INTERVENTION,
		argumentQueryCount: 'intervention',
		queryName: 'forms',
		component: (
			<InterventionControlForms
				overrideConfigTable={{
					tableConfig: transportListInterventionControlFormsViewModalTable,
					argumentQueryViewModal: 'intervention',
					elevationNbr: 0,
					localStorageName:
						'transportListInterventionFormsViewModalTableFilters',
					tableContainer: { height: '56vh' },
					hidden: {
						tableToolBar: { filter: true },
					},
				}}
				overrideTableProvider={{
					customLocalStorageKeyFilter:
						'transportListInterventionFormsViewModalFilters',
					customDefaultFilterConfig: {
						period: EPeriodFilter.allPeriod,
						startFrom: null,
						endTo: null,
					},
				}}
				isInViewModal
			/>
		),
	},

	{
		label: 'misc.photos',
		query: TRANSPORT_FORM_PHOTOS_COUNT,
		argumentQueryCount: 'intervention',
		queryName: 'picturedForms',
		component: <PhotosGroupedByForm queryPropertyName='intervention' />,
	},

	{
		label: 'misc.alerts',
		query: ALERT_COUNT_BY_INTERVENTION,
		argumentQueryCount: 'intervention',
		queryName: 'alerts',
		component: (
			<Alerts
				overrideConfigTable={{
					tableConfig: transportListAlertsViewModalTable,
					argumentQueryViewModal: 'intervention',
					elevationNbr: 0,
					localStorageName: 'transportListAlertsViewModalTableFilters',
					hidden: {
						tableToolBar: { filter: true },
					},
				}}
				overrideTableProvider={{
					customLocalStorageKeyFilter: 'transportListAlertsViewModalFilters',
					customDefaultFilterConfig: {
						period: EPeriodFilter.allPeriod,
						startFrom: null,
						endTo: null,
					},
				}}
				isInViewModal
			/>
		),
	},

	{
		label: 'invoice.invoice',
		query: INVOICES_COUNT_BY_INTERVENTION,
		argumentQueryCount: 'intervention',
		queryName: 'invoices',
		component: (
			<Invoices
				overrideConfigTable={{
					tableConfig: transportListInvoicesViewTable,
					argumentQueryViewModal: 'intervention',
					elevationNbr: 0,
					localStorageName: 'transportListInvoicesViewModalTableFilters',
					hidden: {
						tableToolBar: { filter: true },
					},
				}}
				overrideTableProvider={{
					customLocalStorageKeyFilter: 'transportListInvoicesViewModalFilters',
				}}
				isInViewModal
			/>
		),
	},

	{
		label: 'documentVehicle.documents',
		query: DOCUMENTS_VEHICLES_COUNT_BY_INTERVENTION,
		argumentQueryCount: 'intervention',
		queryName: 'documents',
		component: (
			<VehiclesDocuments
				overrideConfigTable={{
					tableConfig: transportListDocumentsViewModalTable,
					argumentQueryViewModal: 'intervention',
					elevationNbr: 0,
					localStorageName: 'transportListDocumentsViewModalTableFilters',
					hidden: {
						tableToolBar: { filter: true },
					},
				}}
				overrideTableProvider={{
					customLocalStorageKeyFilter: 'transportListDocumentsViewModalFilters',
				}}
				isInViewModal
			/>
		),
	},
]
