export default {
	lastMileage: 'Dernier relevé kilométrique',
	previousMileage: 'Précédent relevé kilométrique',
	updateMileage: 'Mettre à jour le kilométrage',
	historicMileage: 'HISTORIQUE',
	locationMonitoring: 'Suivi parc',
	dateEntry: "Date d'entrée",
	releaseDate: 'Date de sortie',
	parkTime: 'Temps sur parc',
	interventions: 'Interventions',
	lastIntervention: 'Dernière mission',
	nextIntervention: 'Prochaine intervention',
	alertFinish: 'Alertes traitées',
	alertDetected: "Catégories d'alertes détectées",

	interventionDone: 'Interventions effectuées',
	interventionNotAssigned: 'Interventions non affectées',
	vehiclesActive: 'Véhicules actifs',
	availableWidgets: 'Widgets disponibles',
	entriePark: 'Entrées sur parc',
	exitPark: 'Sorties de parc',
	interByRef: 'Interventions par référence',
	alertsDetectedCategory: "Catégories d'alertes détectées",
	zeroAlert: 'Aucune alerte',
	presenceTime: 'Temps sur parc',
	addTracking: 'Nouvelle entrée sur parc',
	vehicleTrackingEdit: 'Modifier une entrée / sortie de parc',
	vehicleTrackingCreate: 'Ajouter une entrée / sortie de parc',
	entrieDate: "Date d'entrée",
	exitDate: 'Date de sortie',
}
