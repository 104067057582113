import React, { useState, useEffect, useMemo, memo } from 'react'

import { Fade, Paper, Stack } from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications'

import WidgetIconTitle from '../elements/WidgetIconTitle'
import { useAlertTreatedWidget } from '../../../api/gql/queries/alertsQueries'
import { useFormatMessage } from '../../../utils/hooks'
import { formatGeneric } from '../../../utils/string'
import CircularProgressWidget from '../elements/CircularProgressWidget'

interface IWAlertsTreated {
	filterQueries
}

const widgetIconTitleIcon: any = {
	iconColor: 'red',
	Icon: NotificationsIcon,
}

const WAlertsTreated: React.FC<IWAlertsTreated> = ({ filterQueries = {} }) => {
	const intlMsg = useFormatMessage()
	const { data: alerts, loading } = useAlertTreatedWidget({
		variables: {
			...filterQueries,
			startFrom: filterQueries.dateFrom?.[0].after,
			endTo: filterQueries.dateFrom?.[0].before,
		},
	})

	const [totalTreated, setTotalTreated] = useState(0)

	const subTitle = useMemo(
		() =>
			alerts?.alerts?.collection?.length
				? `${totalTreated} / ${alerts?.alerts?.collection?.length}`
				: '0',
		[alerts, totalTreated]
	)

	useEffect(() => {
		if (alerts && !loading) {
			const alertFormatted: any = formatGeneric(alerts?.alerts?.collection)
			let count = 0
			alertFormatted?.forEach(({ status: { isTreated } }) => {
				if (isTreated) count++
			})
			setTotalTreated(count)
		}
	}, [loading, alerts])

	return (
		<Paper elevation={1} sx={{ height: 1 }} id='widget'>
			<Stack p={4} justifyContent='center' sx={{ height: 1 }}>
				<Fade in={!loading}>
					<Stack
						direction='row'
						justifyContent='space-between'
						alignItems='center'
					>
						<WidgetIconTitle
							icon={widgetIconTitleIcon}
							title={intlMsg('widget.alertFinish')}
							subTitle={subTitle}
						/>
					</Stack>
				</Fade>
				{loading && <CircularProgressWidget />}
			</Stack>
		</Paper>
	)
}

export default memo(WAlertsTreated)
