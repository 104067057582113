import React, { useMemo } from 'react'

import LocationOnIcon from '@mui/icons-material/LocationOn'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'

import { maxStringDisplay } from '../../utils/string'

const typographyCSS = {
	display: 'inline-flex',
	alignItems: 'center',
	height: '26px',
	wordWrap: 'break-word',
	whiteSpace: 'nowrap',
}

const iconCSS = {
	color: ({ palette }) => palette.error.dark,
	mr: 1,
}

interface ILocationAddress {
	address?: string
	city?: string
	zipcode?: string
	maxCharacters?: number
	size?: string
}

const LocationAddress: React.FC<ILocationAddress> = ({
	address,
	city,
	zipcode,
	maxCharacters = 200,
}) => {
	const completeAddress = useMemo(() => {
		return `${address ? `${address} ` : ''}${city ? `- ${city}` : ''}${
			zipcode ? ` - ${zipcode} ` : ''
		}`
	}, [address, city, zipcode])

	const renderAddress = useMemo(() => {
		if (completeAddress?.length <= maxCharacters) {
			return completeAddress || '-'
		}

		return (
			<Tooltip title={completeAddress} aria-label={completeAddress}>
				<span>{maxStringDisplay(completeAddress, maxCharacters)}</span>
			</Tooltip>
		)
	}, [completeAddress, maxCharacters])

	const mapsLink = useMemo(() => {
		if (completeAddress?.length) {
			return (
				<a
					href={`https://maps.google.com/?q=${completeAddress}`}
					target='_blank'
					rel='noreferrer'
				>
					{renderAddress}
				</a>
			)
		}

		return '-'
	}, [completeAddress, renderAddress])

	return (
		<>
			<Typography variant='caption' sx={typographyCSS}>
				<LocationOnIcon sx={iconCSS} fontSize='small' />
				{mapsLink}
			</Typography>
		</>
	)
}

export default LocationAddress
