export default {
	lostPasswordTitle: `Réinitialisation du mot de passe`,
	lostPasswordText: `Veuillez indiquer votre adresse email pour réinitialiser votre mot de passe.`,
	checkEmailBoxTitle: `Vérifiez vos e-mails`,
	checkEmailBoxText: `Un e-mail a été envoyé. Suivez les instructions pour réinitialiser votre mot de passe.`,
	lastLogin: 'Dernière connexion',
	searchUser: 'Rechercher un utilisateur',
	searchTeam: 'Rechercher une équipe',
	errorTitle: "Le lien n'est plus valide",
	errorText:
		"Le lien permettant la confirmation de votre compte est incorrect ou n'est plus valide.",
	validatedTitle: 'Dernière étape',
	validatedText:
		"Le nombre d'utilisateurs gratuits est dépassé. L'ajout de ce nouvel utilisateur modifiera le prix de votre abonnement.",
	neverLog: `Ne s'est jamais connecté`,
	shareUserLabel: 'Partager avec ce contact mon activité liée à son compte',
	shareUserLabelTooltips:
		'Ce contact recevra une invitation pour consulter votre activité réalisée sur son compte, ses sites et ses véhicules.',
	shareUserActivity: 'Partage de mon activité',
	sendInvitation: 'Renvoyer une invitation',
	newPasswordTitle: 'Nouveau mot de passe.',
	newPasswordSubTitle: 'Renseignez et confirmez votre nouveau mot de passe.',
	successPasswordUpdated: 'Votre mot de passe a été  réinitialisé.',
	welcomeHubee: 'Bienvenue sur HubeeConnect',
	activeAccount: 'Activez votre compte ',
	rememberMe: 'Se souvenir de moi',
	enterPassword: 'Saisissez le mot de passe',
	confirmPassword: 'Confirmez le mot de passe',
	explainActivationTitle:
		'Votre utilisateur recevra un email pour activer son compte.',
	explainActivationParagraph:
		"L’activation du compte est obligatoire pour accéder à la plateforme web et à l'application mobile.",
	readMore: 'En savoir plus',
}
