/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { GET_CATEGORIES_LIST_FOR_AGENCY_STEP } from '../../../../api/gql/queries/catalogProductQueries'
import listCategoriesProductsTableConfig from '../../config/isolatedTables/listCategoriesProductsTableConfig'

////////////////////////////////////////////////////////////////
// Global variable
////////////////////////////////////////////////////////////////

const queryConfig = {
	query: GET_CATEGORIES_LIST_FOR_AGENCY_STEP,
	queryName: 'productCategories',
	isModal: true,
}

////////////////////////////////////////////////////////////////
// TableToolBarHeader CONFIG //
////////////////////////////////////////////////////////////////

const tableToolBarConfig = {
	title: 'agencies.sharedCategoriesToAgency',
	tableConfig: listCategoriesProductsTableConfig,
	queryConfig,
}

////////////////////////////////////////////////////////////////
// tableContainer CONFIG
////////////////////////////////////////////////////////////////

const tableContainerConfig = {
	tableConfig: listCategoriesProductsTableConfig,
	queryConfig,
}

////////////////////////////////////////////////////////////////
// -> EXPORT CONFIG MAIN TABLE
////////////////////////////////////////////////////////////////

export const listCategoriesTableConfig = {
	tableToolBarConfig,
	tableContainerConfig,
}
