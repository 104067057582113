import React, { memo, useCallback, useMemo, useState } from 'react'
import { EFormModalMode } from '../../../utils/app-models'
import { StepperContext } from './StepperContext'

import { useModal } from '../../../utils/hooks'
import { mergeSchemaValidation } from '../formsUtils'

/**
 *  stepperConfig [{
 * 	    name: should be reactIntl string
 * 	    component: should be a react component
 *		schema?: should be a yup schema
 *  }, ...]
 */

interface IStepperProviderProps {
	children?
	stepperMode?
	open?: boolean
	stepperConfig
	initialStep?: number
}

const StepperProvider: React.FC<IStepperProviderProps> = ({
	children,
	stepperMode,
	open = false,
	stepperConfig,
	initialStep = 0,
}) => {
	const [loading, setLoading] = useState(false)
	const { isVisible, openModal, closeModal } = useModal(open)
	const [mode, setMode] = useState(stepperMode || EFormModalMode.edit)
	const [data, setData] = useState<any>({})
	const [startingData, setStartingData] = useState<any>({})
	const [canGoNext, setCanGoNext] = useState(true)
	const [currentStep, setCurrentStep] = useState<number>(initialStep)
	const [errors, setErrors] = useState<any>({})
	const [stepErrors, setStepErrors] = useState({})
	const [formHaveError, setFormHaveError] = useState(false)

	////////////////////////////////////////////////////////////////////////////////////////////
	// Set values
	////////////////////////////////////////////////////////////////////////////////////////////

	const setValue = useCallback(
		(name, value) => {
			setData({
				...data,
				[name]: value,
			})
		},
		[data]
	)

	const setValues = useCallback(
		(values) => {
			const newData = { ...data }
			values.forEach(({ key, value }) => (newData[key] = value))
			setData(newData)
		},
		[data]
	)

	////////////////////////////////////////////////////////////////////////////////////////////
	// Add or remove step error (need to add/remove red color alert to the current step with errors)
	////////////////////////////////////////////////////////////////////////////////////////////

	const thisStepHaveErrors = useCallback(
		(addStepError: Boolean) => {
			setStepErrors({ ...stepErrors, [currentStep]: addStepError })
		},
		[currentStep, stepErrors]
	)

	////////////////////////////////////////////////////////////////////////////////////////////
	// Trigger fields for one Step
	////////////////////////////////////////////////////////////////////////////////////////////

	const triggerError = useCallback(
		async (fieldName = null) => {
			if (fieldName) {
				triggerAllErrors(false)()

				return await stepperConfig[currentStep].schema
					?.validateAt(fieldName, data, { abortEarly: true }) // abortEarly to true -> Throw on the first error (to avoid error message  ex: "2 errors occurred")
					.then(() => {
						// eslint-disable-next-line @typescript-eslint/no-unused-vars
						const { [fieldName]: _selectedErrorField, ...restErrors } = errors
						setErrors(restErrors)
						thisStepHaveErrors(false)

						return false
					})
					.catch((err) => {
						thisStepHaveErrors(true)
						setErrors({
							...errors,
							[err?.path]: err?.message,
						})
						return true
					})
			} else {
				const newErrors = {}

				const isInError = await stepperConfig[currentStep].schema
					?.validate(data, { abortEarly: false })
					.then(() => {
						thisStepHaveErrors(false)
						return false
					})
					.catch((err) => {
						err?.inner?.forEach((error) => {
							newErrors[error?.path] = error?.message
						})
						thisStepHaveErrors(true)
						return true
					})
				setErrors({ ...errors, ...newErrors })
				return isInError
			}
		},
		[currentStep, stepperConfig, data, errors, stepErrors]
	)

	////////////////////////////////////////////////////////////////////////////////////////////
	// Trigger all fields with a merging schema validation to detect error (all steps)
	////////////////////////////////////////////////////////////////////////////////////////////

	const triggerAllErrors = useCallback(
		(triggerAndSetError = false) =>
			async () => {
				const newErrors = {}
				const isInError = await mergeSchemaValidation(stepperConfig)
					?.validate(data, { abortEarly: false })
					.then(() => {
						setFormHaveError(false)
						return false
					})
					.catch((err) => {
						if (triggerAndSetError) {
							err?.inner?.forEach((error) => {
								newErrors[error?.path] = error?.message
							})
						}

						setFormHaveError(true)
						return true
					})

				triggerAndSetError && setErrors({ ...errors, ...newErrors })
				return isInError
			},
		[currentStep, stepperConfig, data, errors, stepErrors]
	)

	////////////////////////////////////////////////////////////////////////////////////////////
	// For btn NEXT / PREVIOUS
	////////////////////////////////////////////////////////////////////////////////////////////

	const goNext = useCallback(async () => {
		await triggerError()
		if (canGoNext && currentStep + 2 <= stepperConfig?.length) {
			setCurrentStep(currentStep + 1)
		}
	}, [currentStep, stepperConfig, data, canGoNext])

	const goPrevious = useCallback(async () => {
		await triggerError()
		if (currentStep - 1 >= 0) setCurrentStep(currentStep - 1)
	}, [currentStep, stepperConfig, data])

	////////////////////////////////////////////////////////////////////////////////////////////
	// Reset context
	////////////////////////////////////////////////////////////////////////////////////////////

	const resetContext = useCallback((defaultValues) => {
		setData(defaultValues || {})
		setErrors({})
		setFormHaveError(false)
		setStepErrors({})
	}, [])

	const context = useMemo(
		() => ({
			isVisible,
			openModal,
			loading,
			closeModal,
			resetContext,
			setLoading,
			mode,
			setMode,
			data,
			stepperConfig,
			currentStep,
			setCurrentStep,
			goNext,
			goPrevious,
			setValue,
			setValues,
			triggerError,
			errors,
			canGoNext,
			setCanGoNext,
			setErrors,
			startingData,
			setStartingData,
			stepErrors,
			setFormHaveError,
			formHaveError,
			triggerAllErrors,
			thisStepHaveErrors,
		}),
		[
			isVisible,
			loading,
			data,
			mode,
			resetContext,
			stepperConfig,
			currentStep,
			goNext,
			goPrevious,
			setValue,
			setValues,
			triggerError,
			errors,
			canGoNext,
			startingData,
			stepErrors,
			setFormHaveError,
			formHaveError,
		]
	)

	return (
		<StepperContext.Provider value={context}>
			{children}
		</StepperContext.Provider>
	)
}

export default memo(StepperProvider)
