import React, { useMemo } from 'react'

import { Badge, Box } from '@mui/material'

const tabLabelCSS = {
	display: 'flex',
	'& >span:first-of-type': {
		marginRight: ({ spacing }) => spacing(1),
	},
} as const

const anchorOriginTopRightCircleCSS = {
	'& .MuiBadge-badge': {
		right: 4,
		top: 8,
		position: 'relative',
		transform: 'scale(0.8) translate(50%, -50%)',
	},
}

interface IFormModalTabsLabel {
	label?: string
	count?: number
	isInError?: boolean
	isSelected: boolean
}

const FormModalTabsLabel: React.FC<IFormModalTabsLabel> = ({
	label,
	count,
	isInError = false,
	isSelected,
}) => {
	const sxStyle = useMemo(
		() => ({
			...tabLabelCSS,
			color: ({ palette }) =>
				isInError
					? palette.error.main
					: isSelected
					? palette.primary.main
					: palette.grey[700],
		}),
		[isInError, isSelected]
	)

	return (
		<Box sx={sxStyle}>
			{count ? (
				<Badge
					badgeContent={count}
					color='primary'
					sx={anchorOriginTopRightCircleCSS}
					max={999}
				>
					<>{label}</>
				</Badge>
			) : (
				label
			)}
		</Box>
	)
}

export default FormModalTabsLabel
