/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { ThemeContext } from './ThemeContext'
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles'

//theme
import { EHubeeTheme } from '../../utils/app-models'
import { HubeeLightTheme } from './hubeeLightTheme'
import { HubeeDarkTheme } from './hubeeDarkTheme'

interface IThemeProvider {
	children: any
}

const Themes = {
	hubeeLightTheme: HubeeLightTheme,
	hubeeDarkTheme: HubeeDarkTheme,
}

const ThemeProvider: React.FC<IThemeProvider> = ({ children }) => {
	const [theme, setTheme] = useState<any>(
		Themes[localStorage.getItem('theme') || EHubeeTheme.hubeeLightTheme]
	)
	const [themeName, setThemeName] = useState<EHubeeTheme>(
		(localStorage.getItem('theme') as EHubeeTheme) ||
			EHubeeTheme.hubeeLightTheme
	)

	useEffect(() => {
		localStorage.setItem('theme', themeName)
		setTheme(Themes[themeName])
	}, [themeName])

	const context = useMemo(
		() => ({
			theme,
			themeName,
			setTheme,
			setThemeName,
		}),
		[themeName, theme]
	)

	return (
		<ThemeContext.Provider value={context}>
			<MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>
		</ThemeContext.Provider>
	)
}

export default ThemeProvider
