import React, { useContext } from 'react'

import { Box, Paper, Typography, Stack, Button } from '@mui/material'
import { useFormatMessage } from '../../utils/hooks'
import { askDemoLink } from '../../utils/const'
import { AuthContext } from '../../utils/contexts/AuthContext'

const mainCSS = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	minHeight: '100vh',
	height: '100%',
	background: 'linear-gradient(74.16deg, #132142 19.69%, #1C3368 110.61%)',
	backgroundImage: "url('/img/bg/background.svg')",
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	backgroundPosition: 'center',
	flexDirection: 'column',
	p: { xs: '1rem', lg: 'inherit' },
}

const paperCSS = {
	height: { xs: '100%', lg: 500 },
	minWidth: { xs: '100%', lg: '900px' },
	maxWidth: { xs: '100%', lg: '900px' },
	borderRadius: '10px',
	marginTop: { xs: '8rem', lg: '1rem' },
	padding: { xs: '0rem', lg: 'inherit' },
}

const logoCSS = { width: 200 }

interface ILayoutLogout {
	displaySubActions?: boolean
	children
}

const LayoutLogout: React.FC<ILayoutLogout> = ({
	children,
	displaySubActions = false,
}) => {
	const intlMsg = useFormatMessage()
	const { localLanguage } = useContext(AuthContext)

	return (
		<Box sx={mainCSS}>
			<Box sx={logoCSS}>
				<a href='/login'>
					<img src={'/img/logo-hubeeconnect.svg'} />
				</a>
			</Box>
			<Paper sx={paperCSS}>{children}</Paper>

			{displaySubActions && (
				<Stack
					mt={4}
					direction={{ xs: 'column', lg: 'row' }}
					justifyContent={{ xs: 'center', lg: 'center' }}
					alignItems='center'
					sx={{ width: { xs: 'auto', lg: '900px' } }}
					flexWrap='wrap'
					gap={2}
				>
					<Typography variant='body2' color='white'>
						{intlMsg('misc.discoverHubee')}
					</Typography>

					<Stack direction='row' spacing={2}>
						<Button
							variant='outlined'
							sx={{ color: 'white' }}
							href={askDemoLink[localLanguage]}
							target='_blank'
						>
							{intlMsg('misc.askDemo')}
						</Button>
					</Stack>
				</Stack>
			)}
		</Box>
	)
}

export default LayoutLogout
