export const listAlertsAttributes = `
paginationInfo {
    totalCount
  }
  collection {
    id
    reference
    description
    vehicle {
      id
      vehicleDetail {
        id
        immat
        vin
        vehicleBrand {
          id
          name
        }
        vehicleModelText
      }
    }
    priority
    severity
    isActive
    isTreated
    dateCreated
    dateTreated
    category{
      id,
      name,
      code,
      color
    }
    status {
      id,
      name,
      isTreated,
      isRenotification,
      color,
      code
    }
    createdBy{
      id,
      lastName,
      firstName
      account{
        id
      }
    }
    createdByAccount{
      id
      name
    }
    form{
      id
      reference
    }
    customer{
      id
      name
    }
    site{
      id
      name
    }
    name
  }
`
export const listAlertsStatuses = `
  alertStatuses {
    id
    name
    code
    isTreated
    color
    isRenotification
  }

`
export const listAlertsCategory = `
  alertCategories {
     id
     code
     color
     name
  }
`
