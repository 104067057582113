/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
	gql,
	QueryHookOptions,
	useQuery,
	OperationVariables,
	useLazyQuery,
} from '@apollo/client'

export const GET_FILE_AWS = gql`
	query getAwsMediaUrl($name: String) {
		getAwsMediaUrl(name: $name) {
			id
			url
		}
	}
`

export const GET_AWS_DOWNLOAD_URL = gql`
	query downloadAwsMediaUrl($iri: ID!) {
		downloadAwsMediaUrl(iri: $iri) {
			id
			url
		}
	}
`

/////////////////////////////////////////////////////////////////////////////

export const useGetAwsMediaUrl = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { loading, data } = useQuery(GET_FILE_AWS, options)
	return { loading, url: data?.url || null }
}

export const useLazyDownloadAwsMediaUrl = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(
		GET_AWS_DOWNLOAD_URL,
		options
	)
	return {
		loading: loading,
		data: data?.downloadAwsMediaUrl || null,
		getData: getData,
	}
}
