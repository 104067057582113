/* eslint-disable @typescript-eslint/no-unused-vars */
import { EFormModalMode } from '../../../../../../utils/app-models'
import { unitsOfWork } from '../../../../../../utils/const'
import { vatListForSelect } from '../../../../../dashboard/invoice/invoiceModal/invoiceModalUtils'

export const formatProductModalEditDefaultValues = (data) => {
	const {
		__typename,
		customers,
		productCategory,
		reference,
		vat,
		unitOfWork,
		productBase,
		...rest
	} = data

	const formattedProductBase = productBase
		? {
				[productBase.id]: {
					checked: true,
					value: {
						id: productBase.id,
						name: productBase.name,
						type: productBase.type,
						unitOfWork: productBase.unitOfWork,
						rate: productBase.rate,
						duration: productBase.duration,
						categoryName: productBase?.productBaseCategory?.name,
					},
				},
		  }
		: {}

	return {
		...rest,
		...(customers && {
			customers: customers?.collection?.reduce((acc, customer) => {
				if (customer) {
					acc[customer.id] = {
						checked: true,
						value: customer,
					}
				}
				return acc
			}, {}),
		}),

		productCategory: {
			key: productCategory.id,
			label: productCategory.name,
			value: productCategory,
		},

		productBase: formattedProductBase,

		...(vat !== undefined &&
			vat !== null && {
				vat: {
					label: vat.toString(),
					key: vat.toString(),
					value: vat.toString(),
				},
			}),

		...(unitOfWork && {
			unitOfWork: unitsOfWork.filter(
				({ value }) => unitOfWork === value?.name
			)?.[0],
		}),
	}
}

export const formatProductModalCreateDefaultValues = ({
	defaultCategory,
	haveCrmModule,
}) => {
	return {
		isActive: true,
		productCategory: {
			key: defaultCategory.id,
			label: defaultCategory.name,
			value: defaultCategory,
		},
		vat: { ...vatListForSelect[1] },
		customers: !haveCrmModule && {},
	}
}

export const formatProductModalOnValidateData = ({ data, mode, productId }) => {
	const { productCategory, productBase, customers, vat, unitOfWork, ...rest } =
		data || {}

	const specificModeFields =
		mode === EFormModalMode.edit ? { id: productId } : {}

	const productBaseValue = productBase
		? Object?.values(productBase)?.[0]
		: ({} as any) // fix typescript error

	return {
		...specificModeFields,
		...rest,
		...(productCategory && { productCategory: productCategory?.value?.id }),
		...(productBase && {
			productBase: productBaseValue?.value?.id,
		}),
		...(unitOfWork && { unitOfWork: unitOfWork?.value?.name }),
		...(vat && { vat: parseFloat(vat?.value) }),
		customers: Object.values(customers || {})
			.filter(({ checked }) => checked)
			.map(({ value }) => value?.id),
	}
}
