import React, { useContext } from 'react'

import {
	Stack,
	Box,
	Typography,
	Button,
	Card,
	Alert,
	Link,
} from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'

import { useFormatMessage } from '../../../utils/hooks'
import AppListTitle from './AppListTitle'
import { AuthContext } from '../../../utils/contexts/AuthContext'
import { EAccountType } from '../../../utils/app-models'

const blockLeftCSS = {
	p: 4,
	pb: 8,
	pt: 8,
	backgroundColor: 'white',
	height: 1,
	width: { xs: 1, lg: '45%' },
	display: 'flex',
	margin: 'auto',
	overflow: 'auto'
}
const blockRightCSS = {
	minHeight: 1,
	width: { xs: 1, lg: '55%' },
	backgroundImage: "url('/img/app/appillustration.jpg')",
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	backgroundPosition: 'center',
}

interface IAppHubeecheck {}

const AppHubeecheck: React.FC<IAppHubeecheck> = () => {
	const { currentUser } = useContext(AuthContext)
	const intlMsg = useFormatMessage()

	return (
		<Card elevation={1} className='appHubeecheck'>
			<Stack
				direction='row'
				sx={{ height: 1, flexDirection: { xs: 'column', lg: 'row' } }}
			>
				<Box sx={blockLeftCSS}>
					<Stack
						justifyContent='center'
						alignItems='center'
						sx={{ margin: 'auto' }}
					>
						<Box
							component='img'
							src='/img/app/hubeecheck.svg'
							sx={{ width: '300px' }}
						/>
						<Stack spacing={2} mt={4}>
							<AppListTitle title='HClist1' titleStrong='HClist1Strong' />
							<AppListTitle title='HClist2' titleStrong='HClist2Strong' />
							<AppListTitle title='HClist3' titleStrong='HClist3Strong' />
							<AppListTitle title='HClist4' titleStrong='HClist4Strong' />
							<AppListTitle titleStrong='HClist5Strong' />
							<AppListTitle titleStrong='HClist6Strong' />
						</Stack>

						{currentUser?.account?.accountType?.code !== EAccountType.guest ? (
							<Stack direction='row' spacing={4} mt={8}>
								<Stack justifyContent='center' alignItems='center' spacing={2}>
									<Box component='img' src='/img/app/googlePlayLogo.png' />
									<Typography variant='h3'>
										{intlMsg('app.downloadAndroid')}
									</Typography>
									<Button variant='contained' endIcon={<DownloadIcon />}>
										<a
											target='_blank'
											href='https://play.google.com/store/apps/details?id=com.hubeecheck&pli=1'
											rel='noreferrer'
										>
											{intlMsg('misc.download')}
										</a>
									</Button>
								</Stack>

								<Stack justifyContent='center' alignItems='center' spacing={2}>
									<Box component='img' src='/img/app/appleLogo.png' />

									<Typography variant='h3'>
										{intlMsg('app.downloadIos')}
									</Typography>
									<Button variant='contained' endIcon={<DownloadIcon />}>
										<a
											target='_blank'
											href='https://apps.apple.com/fr/app/hbcheck/id1635558232'
											rel='noreferrer'
										>
											{intlMsg('misc.download')}{' '}
										</a>
									</Button>
								</Stack>
							</Stack>
						) : (
							<Alert severity='info' sx={{ mt: 5, maxWidth: '70%', mb: 0 }}>
								{intlMsg('app.subscribe')}
								<Link href='mailto:contact@hubeecar.com'>
									contact@hubeecar.com
								</Link>
							</Alert>
						)}
					</Stack>
				</Box>
				<Box sx={blockRightCSS}></Box>
			</Stack>
		</Card>
	)
}
export default AppHubeecheck
