export default {
	min: 'Minimum',
	minCharacters: 'Minimum {value} characters',
	maxCharacters: 'Maximum {value} characters',
	characters: 'characters',
	passwordFormat:
		'The password must contain at least 7 characters, 1 uppercase letter, and 1 lowercase letter',
	unmatchPassword: 'Passwords do not match',
	requirePassword: 'Password is required',
	require: 'This field is required',
	invalidEmail: 'Invalid email',
	immatRequire: 'Please fill in at least one of the fields',
	agencyTypeRequired: 'Please select an agency type',
	email: 'Please enter a valid email',
	phone: 'Please enter a valid phone number',
	atLeastOnePhone: 'Please enter at least one of the phone fields',
	invalidSiret: 'Invalid Siret format',
	invalidTransportDateEnd:
		'The delivery date must be later than the pickup date',
	invalidTransportDateStart: 'The pickup date must be before the delivery date',
	hourRequired: `Hour is required`,
}
