/* eslint-disable react/react-in-jsx-scope */
import PersonIcon from '@mui/icons-material/Person'
import RoomIcon from '@mui/icons-material/Room'
import DriveEtaIcon from '@mui/icons-material/DriveEta'
import BuildIcon from '@mui/icons-material/Build'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import EuroIcon from '@mui/icons-material/Euro'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import MapIcon from '@mui/icons-material/Map'

import {
	CustomerValidationSchema,
	siteValidationSchema,
	ContactValidationSchema,
	VehiclesValidationSchema,
	InterventionValidationSchema,
	invoiceValidationSchema,
	documentVehicleValidationSchema,
} from '../../utils/validationSchema'

import {
	EModalEditType,
	EModule,
	EPermissionScope,
} from '../../utils/app-models'

import CustomerAccountsModal from '../../view/dashboard/customerAccounts/customerAccountsModal'
import CustomerSiteModal from '../../view/dashboard/customerSites/customerSiteModal'
import CustomerContactModal from '../../view/dashboard/customerContacts/customerContactModal'
import InvoiceModal from '../../view/dashboard/invoice/invoiceModal'
import VehicleDocumentModal from '../../view/dashboard/vehiclesDocuments/vehicleDocumentModal'
import InterventionControlModal from '../../view/dashboard/interventions/interventionControl/interventionModal'
import VehicleModal from '../../view/dashboard/vehicles/vehicleModal'
import InterventionTransportModal from '../../view/dashboard/interventions/interventionTransport/interventionModal'
import interventionTransportModalValidationSchema from '../../view/dashboard/interventions/interventionTransport/interventionModal/interventionTransportModalValidationSchema'

export const creationShortcutsConfig = [
	// {
	// 	name: EModalEditType.agency, //TODO a mettre a jours quand agence sont fait
	// 	icon: <BusinessCenterIcon />,
	// 	text: 'navigation.agency',
	// 	createPermission: [EPermissionScope.customerManage],
	// },
	{
		name: EModalEditType.customer,
		icon: <BusinessCenterIcon />,
		text: 'navigation.customer',
		createPermission: [EPermissionScope.customerManage],
		modules: [EModule.crm],
	},
	{
		name: EModalEditType.site,
		icon: <RoomIcon />,
		text: 'navigation.site',
		createPermission: [EPermissionScope.siteManage],
	},
	{
		name: EModalEditType.contact,
		icon: <PersonIcon />,
		text: 'navigation.contact',
		createPermission: [EPermissionScope.contactManage],
		modules: [EModule.crm],
	},
	{
		name: EModalEditType.vehicle,
		icon: <DriveEtaIcon />,
		text: 'navigation.vehicle',
		createPermission: [EPermissionScope.vehicleCreate],
	},
	{
		name: EModalEditType.interventionControl,
		icon: <BuildIcon />,
		text: 'navigation.interventionControl',
		createPermission: [EPermissionScope.interventionCreate],
		modules: [EModule.interventionControl],
	},

	{
		name: EModalEditType.interventionTransport,
		icon: <MapIcon />,
		text: 'navigation.interventionTransport',
		createPermission: [EPermissionScope.interventionCreate],
		modules: [EModule.interventionTransport],
	},

	{
		name: EModalEditType.invoice,
		icon: <EuroIcon />,
		text: 'navigation.invoice',
		createPermission: [EPermissionScope.invoiceManage],
		modules: [EModule.invoice],
	},
	{
		name: EModalEditType.documentVehicle,
		icon: <NoteAddIcon />,
		text: 'navigation.document',
		createPermission: [EPermissionScope.documentManage],
		modules: [EModule.invoice],
	},

	// {
	// 	name: EModalEditType.interventionLogistic,
	// 	icon: <BuildIcon />,
	// 	text: 'navigation.interventionLogistic',
	// 	createPermission: [EPermissionScope.interventionCreate],
	// modules: [EModule.interventionLogistic],

	// },
]

export const modalActionConfig = [
	// {
	// 	name: EModalEditType.agency, //TODO a mettre a jours quand agence sont fait
	// 	schemaValidation: CustomerValidationSchema,
	// 	formModalComponent: <CustomerAccountsModal redirectOnClose />,
	// 	createPermission: [EPermissionScope.customerManage],
	// },
	{
		name: EModalEditType.customer,
		schemaValidation: CustomerValidationSchema,
		formModalComponent: <CustomerAccountsModal modalUtils={{} as any} />,
		createPermission: [EPermissionScope.customerManage],
		modules: [EModule.crm],
	},
	{
		name: EModalEditType.site,
		schemaValidation: siteValidationSchema,
		formModalComponent: <CustomerSiteModal modalUtils={{} as any} />,
		createPermission: [EPermissionScope.siteManage],
	},
	{
		name: EModalEditType.contact,
		schemaValidation: ContactValidationSchema,
		formModalComponent: <CustomerContactModal modalUtils={{} as any} />,
		createPermission: [EPermissionScope.contactManage],
		modules: [EModule.crm],
	},
	{
		name: EModalEditType.vehicle,
		schemaValidation: VehiclesValidationSchema,
		formModalComponent: <VehicleModal modalUtils={{} as any} />,
		createPermission: [EPermissionScope.vehicleCreate],
	},
	{
		name: EModalEditType.invoice,
		schemaValidation: invoiceValidationSchema,
		formModalComponent: <InvoiceModal modalUtils={{} as any} />,
		createPermission: [EPermissionScope.invoiceManage],
	},
	{
		name: EModalEditType.documentVehicle,
		schemaValidation: documentVehicleValidationSchema,
		formModalComponent: <VehicleDocumentModal modalUtils={{} as any} />,
		createPermission: [EPermissionScope.documentManage],
	},
	{
		name: EModalEditType.interventionControl,
		schemaValidation: InterventionValidationSchema,
		formModalComponent: <InterventionControlModal modalUtils={{} as any} />,
		createPermission: [EPermissionScope.interventionCreate],
		modules: [EModule.interventionControl],
	},
	{
		name: EModalEditType.interventionTransport,
		schemaValidation: interventionTransportModalValidationSchema,
		formModalComponent: <InterventionTransportModal modalUtils={{} as any} />,
		createPermission: [EPermissionScope.interventionCreate],
		modules: [EModule.interventionTransport],
	},
	// {
	// 	name: EModalEditType.interventionLogistic,
	// 	schemaValidation: InterventionValidationSchema,
	// 	formModalComponent: (
	// 		<InterventionModal
	// 			redirectOnClose
	// 			interventionType={EInterventionTypes.logistic}
	// 		/>
	// 	),
	// 	createPermission: [EPermissionScope.interventionCreate],
	// 	modules: [EModule.interventionLogistic],
	// },
]
