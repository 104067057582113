import React from 'react'

import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent/DialogContent'
import DialogActions from '@mui/material/DialogActions'

import { Error404 } from '..'
import ViewModalHeader from './viewModalHeader'
import DialogContentLayout from '../viewModal/DialogContentLayout'
import ViewModalFooter from '../viewModal/ViewModalFooter'
import ViewModalContentProvider from '../../providers/ViewModalContentProvider'

const muiDialogPaperCSS = (index) => ({
	zIndex: 1200,
	'& .MuiBackdrop-root': { opacity: index !== 0 ? '0!important' : '' },
	'& .MuiDialog-paper': { height: '100vh' },
	'& .MuiDialogContent-dividers': { p: 0 },
	'& .dialogContent': { background: ({ palette }) => palette.grey[50] },
})

interface IViewModal {
	viewModalInfos: any
	index: number
}

const ViewModal: React.FC<IViewModal> = ({ viewModalInfos, index }) => {
	const { viewModalConfig } = viewModalInfos
	return (
		<ViewModalContentProvider viewModalInfos={viewModalInfos}>
			<Dialog
				sx={muiDialogPaperCSS(index)}
				aria-labelledby='customized-dialog-title'
				data-test-id="Playwright-view-modal"
				open
				fullWidth
				maxWidth={false}
			>
				<ViewModalHeader />

				<DialogContent dividers className='dialogContent'>
					{viewModalConfig ? (
						<DialogContentLayout />
					) : (
						<Error404 notFullScreen />
					)}
				</DialogContent>
				<DialogActions>
					{viewModalConfig && (
						<ViewModalFooter customFooter={viewModalConfig?.customFooter} />
					)}
				</DialogActions>
			</Dialog>
		</ViewModalContentProvider>
	)
}

export default ViewModal
