import { createContext } from 'react'

export type IViewModalContext = {
	goPrevViewModal?: any
	onViewModalClose?: any
}

const defaultContextValues: IViewModalContext = {}

export const ViewModalContext: React.Context<IViewModalContext> =
	createContext(defaultContextValues)
