import React from 'react'

import { Typography, Stack } from '@mui/material'
import { useFormatMessage } from '../../../utils/hooks'

interface ITimeCell {
	date?
	createdAt?: boolean
}

const TimeCell: React.FC<ITimeCell> = ({ date, createdAt }) => {
	const intlMsg = useFormatMessage()

	return (
		<Stack direction='row' alignItems='center'>
			{createdAt && <div>{`${intlMsg('misc.createdAt')}`}&nbsp;</div>}
			<Typography color='inherit' variant='caption'>
				{date}
			</Typography>
		</Stack>
	)
}

export default TimeCell
