import qs from 'qs'
import { EFormModalMode } from '../../../../utils/app-models'
import translateIntlMsgString from '../../../../utils/translateIntlMsgString'
import { extractDataFromKeys } from '../../../../utils/utils'

/* eslint-disable @typescript-eslint/no-unused-vars */

const vehicleTechnicalInfoFieldsList = [
	'vehicleModelText',
	'vehicleBrand',
	'vehicleType',
	'nbSeats',
	'nbDoors',
	'tireSizeRear',
	'tireSizeBack',
	'ptac',
	'horsePower',
	'fiscPower',
	'vehicleCarburant',
	'transmission',
	'dateMec',
	'immat',
	'vin',
]

export const formatInterventionModalOnValidateData = ({
	data,
	mode,
	editedVehicleId,
}) => {
	const {
		dateParkEntry,
		customer,
		color,
		location,
		nbDoors,
		nbSeats,
		fiscPower,
		horsePower,
		site,
		vehicleCategory,
		transmission,
		vehicleBrand,
		vehicleCarburant,
		vehicleType,
		dateMec,
		forcedImmat: _forcedImmat,
		forcedVin: _forcedVin,
		...rest
	} = data

	const specificModeFields =
		mode === EFormModalMode.edit
			? {
					id: editedVehicleId,
					// canEditInfos: editedVehicle?.canEditInfo,
			  }
			: {
					isActive: true,
					dateParkEntry: dateParkEntry || null,
			  }

	// apiPlatform can't interpret "" as null
	const nullabledRes: any = {}
	Object.entries(rest).map(([key, value]) => {
		if (value === '') nullabledRes[key] = null
		else nullabledRes[key] = value
	})
	return {
		...specificModeFields,
		...nullabledRes,
		dateMec: dateMec || null,
		nbDoors: parseInt(nbDoors) || 0,
		nbSeats: parseInt(nbSeats) || 0,
		fiscPower: parseInt(fiscPower) || 0,
		horsePower: parseInt(horsePower) || 0,
		locationColor: color,
		location,
		site: site?.value?.id,
		vehicleCategory: vehicleCategory?.value?.id,
		transmission: transmission?.value,
		vehicleBrand: vehicleBrand?.value?.id,
		vehicleCarburant: vehicleCarburant?.value?.id,
		vehicleType: vehicleType?.value?.id,
	}
}
export const formatCustomerVehicleModalCreateDefaultValues = async ({
	forcedImmat,
	forcedVin,
	forcedCustomerId,
	forcedSiteId,
	queriesObject,
}) => {
	const dataPreFilling: any = {}
	const { query, varValue } = qs.parse(location.search, {
		ignoreQueryPrefix: true,
	})

	if (forcedCustomerId || forcedSiteId) {
		const res = await queriesObject.getVehicleForcedData({
			variables: {
				forcedCustomerId,
				forcedSiteId,
			},
		})

		const vehicleForcedData = res?.data
		dataPreFilling.site = vehicleForcedData?.site
			? {
					key: vehicleForcedData.site?.id,
					label: vehicleForcedData.site?.name,
					value: vehicleForcedData.site,
			  }
			: null
		dataPreFilling.customer = vehicleForcedData?.customer
			? {
					key: vehicleForcedData.customer?.id,
					label: vehicleForcedData.customer?.name,
					value: vehicleForcedData.customer,
			  }
			: null
	} else if (query && varValue) {
		const res = await queriesObject[query as string]({
			variables: { id: varValue },
		})
		const queryData = res?.data?.[query as string]

		const isSite = query === 'site'

		const dataKey = isSite ? queryData.customer?.id : queryData.id
		const dataLabel = isSite ? queryData.customer?.name : queryData.name

		dataPreFilling.customer = queryData
			? {
					key: dataKey,
					label: dataLabel,
					value: { id: dataKey, name: dataLabel },
			  }
			: null

		dataPreFilling.site =
			queryData && isSite
				? {
						key: queryData?.id,
						label: queryData?.name,
						value: { id: queryData?.id, label: queryData?.name },
				  }
				: null
	}

	return {
		...dataPreFilling,
		immat: forcedImmat || '',
		vin: forcedVin || '',
		forcedImmat,
		forcedVin,
	}
}

export const formatVehicleModalEditDefaultValues = ({ data }) => {
	const {
		canEditInfos,
		comment,
		designation,
		site,
		vehicleCategory,
		vehicleDetail,
		vehicleLocations,
	} = data

	const defaultVehicleDetailsValues: any = extractDataFromKeys(
		vehicleDetail,
		vehicleTechnicalInfoFieldsList
	)

	const locationObj = vehicleLocations?.collection[0]

	return {
		...defaultVehicleDetailsValues,
		canEditInfos,
		comment,
		designation,
		color: locationObj?.color,
		location: locationObj?.location,
		site: {
			key: site?.id,
			label: site?.name,
			value: site,
		},
		customer: {
			key: site?.customer?.id,
			label: site?.customer?.name,
			value: site?.customer,
		},
		...(vehicleCategory && {
			vehicleCategory: {
				key: vehicleCategory?.id,
				label: translateIntlMsgString(`vehicle.${vehicleCategory?.code}`),
				value: vehicleCategory,
			},
		}),
		...(defaultVehicleDetailsValues?.vehicleCarburant && {
			vehicleCarburant: {
				key: defaultVehicleDetailsValues?.vehicleCarburant?.id,
				label: translateIntlMsgString(
					`vehicle.${defaultVehicleDetailsValues?.vehicleCarburant?.code}`
				),
				value: defaultVehicleDetailsValues?.vehicleCarburant,
			},
		}),
		...(defaultVehicleDetailsValues?.vehicleType && {
			vehicleType: {
				key: defaultVehicleDetailsValues?.vehicleType?.id,
				label: translateIntlMsgString(
					`vehicle.${defaultVehicleDetailsValues?.vehicleType?.code}`
				),
				value: defaultVehicleDetailsValues?.vehicleType,
			},
		}),
		...(defaultVehicleDetailsValues?.vehicleBrand && {
			vehicleBrand: {
				key: defaultVehicleDetailsValues?.vehicleBrand?.id,
				label: defaultVehicleDetailsValues?.vehicleBrand?.name,
				value: defaultVehicleDetailsValues?.vehicleBrand,
			},
		}),
		transmission: {
			key: defaultVehicleDetailsValues?.transmission,
			label: translateIntlMsgString(
				`vehicle.${defaultVehicleDetailsValues?.transmission}`
			),
			value: defaultVehicleDetailsValues?.transmission,
		},
	}
}
