import React, { useContext, useMemo } from 'react'

import { Switch, FormControlLabel } from '@mui/material'
import FlagIcon from '@mui/icons-material/Flag'

import { useFormatMessage } from '../../../../../../../../utils/hooks'
import {
	FormModalContext,
	IFormModalContext,
} from '../../../../../../../../components/forms/formModal/FormModalContext'
import CustomAccordion from '../../../../../../../../components/accordion/CustomAccordion'
import TransportTripForm from '../TransportTripForm'
import { ETransportTripType } from '../../../../../../../../utils/app-models'

import {
	useLazyTransportTripsContact,
	useLazyTransportTripsSite,
} from '../../../../../../../../api/gql/queries/interventionsTransportQueries'
import { handleSetIsOrderEqualFinal } from '../transportTripsUtils'
import { AuthContext } from '../../../../../../../../utils/contexts/AuthContext'

interface ITransportRemoval {
	isOrderEqualFinal
	setIsOrderEqualFinal
	isSharedTransport: boolean
}

const TransportRemoval: React.FC<ITransportRemoval> = ({
	isOrderEqualFinal,
	setIsOrderEqualFinal,
	isSharedTransport,
}) => {
	const intlMsg = useFormatMessage()
	const { data, setValues, triggerError } =
		useContext<IFormModalContext>(FormModalContext)
	const { getTransportTripSite } = useLazyTransportTripsSite()
	const { getTransportTripContact } = useLazyTransportTripsContact()
	const { localLanguage } = useContext(AuthContext)

	/////////////////////////////////////////////////////////
	// Component switch
	/////////////////////////////////////////////////////////

	const SwitchFinalOrder = useMemo(
		() => (
			<FormControlLabel
				control={
					<Switch
						data-test-id={`Playwright-${ETransportTripType.removal}SwitchFinalOrder`}
						checked={isOrderEqualFinal[ETransportTripType.removal] || false}
						onChange={
							handleSetIsOrderEqualFinal({
								setIsOrderEqualFinal,
								setValues,
								transportTripType: ETransportTripType.removal,
								isOrderEqualFinal,
								getTransportTripSite,
								getTransportTripContact,
								data,
								triggerError,
								localLanguage,
							}) as any
						}
						color='primary'
						size='small'
					/>
				}
				label={intlMsg('interventions.sameOrderLocation')}
				labelPlacement='end'
			/>
		),
		[isOrderEqualFinal, setValues, data, setIsOrderEqualFinal, triggerError]
	)

	return (
		<CustomAccordion
			title={intlMsg('interventions.removal')}
			AfterTitleCustomBlock={SwitchFinalOrder}
			icon={<FlagIcon sx={{ color: '#64B6F7' }} />}
		>
			<TransportTripForm
				transportTripType={ETransportTripType.removal}
				isOrderEqualFinal={isOrderEqualFinal}
				setIsOrderEqualFinal={setIsOrderEqualFinal}
				isSharedTransport={isSharedTransport}
			/>
		</CustomAccordion>
	)
}
export default TransportRemoval
