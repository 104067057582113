import React, { useContext, useCallback, useMemo } from 'react'

import Grid from '@mui/material/Grid'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import Tooltip from '@mui/material/Tooltip'
import PhoneIcon from '@mui/icons-material/Phone'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo'

import { Alert, Box, Link, Stack, Typography } from '@mui/material'
import { AuthContext } from '../../../../utils/contexts/AuthContext'
import { useUserTypes } from '../../../../api/gql/queries/usersQueries'
import { useFormatMessage } from '../../../../utils/hooks'
import { FormModalContext } from '../../../../components/forms/formModal/FormModalContext'
import DeboucedFormTextField from '../../../../components/forms/formFields/DebouncedFormTextField'
import FormSelectSearch from '../../../../components/forms/formFields/formSelectSearch'
import FormTextField from '../../../../components/forms/formFields/FormTextField'

const infoAlertCSS = {
	'& .explainActivationTitle': {
		marginBottom: '4px',
		fontWeight: '500',
		fontSize: '16px',
		color: '#0e3c61',
	},
	'& .explainActivationParagraph': {
		marginBottom: '4px',
		color: '#0e3c61',
		fontWeight: '400',
		fontSize: '14px',
	},
	'& .explainActivationLink': {
		display: 'flex',
		width: 'fit-content',
	},
	'& .explainActivationIcon': {
		width: '16.5px',
		height: 'auto',
		marginLeft: '6px',
	},
}

const wrapperModalCSS = {
	p: 4,
	overflow: 'auto',
}

interface IUserModalInfo {}

const UserModalInfo: React.FC<IUserModalInfo> = () => {
	const { currentUser } = useContext(AuthContext)
	const {
		data,
		setValues,
		formModalConfig: { editedElementId },
	} = useContext(FormModalContext)
	const { userTypes } = useUserTypes()
	const intlMsg = useFormatMessage()

	const selectedUserTypeNameTranslated = useMemo(
		() =>
			data['userType']?.name
				? intlMsg(`userTypes.description${data['userType']?.name}`)
				: '',
		[data['userType']]
	)

	const userTypesElements = useMemo(
		() =>
			userTypes?.map((userType) => ({
				key: userType.id,
				label: intlMsg(`userTypes.${userType.name}`),
				value: userType,
			})) || [],
		[userTypes]
	)
	const handleUserTypeChange = useCallback(
		({ option }) => {
			const defaultPerms: any = {}
			/* 	if userType change, we need to set all new defaultValue */
			Object.entries(data)
				.filter(([key, value]) => key.includes('/api/permissions') && value)
				.forEach(([key]) => {
					defaultPerms[key] = false
				})

			option.value?.defaultPermissions.forEach(({ id }) => {
				defaultPerms[id] = true
			})

			setValues({
				...defaultPerms,
				userType: option,
			})
		},
		[setValues, data]
	)

	return (
		<Box sx={wrapperModalCSS}>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<DeboucedFormTextField
						name='firstName'
						label={intlMsg('misc.firstname')}
						isRequired
						context={FormModalContext}
					/>
				</Grid>
				<Grid item xs={6}>
					<DeboucedFormTextField
						name='lastName'
						label={intlMsg('misc.lastname')}
						isRequired
						context={FormModalContext}
					/>
				</Grid>
				<Grid item xs={12}>
					<DeboucedFormTextField
						name='email'
						label={intlMsg('misc.email')}
						isRequired
						context={FormModalContext}
					/>
				</Grid>
				<Grid item xs={12}>
					<Stack direction='row' alignItems='center' spacing={1}>
						<FormSelectSearch
							name='userType'
							label={intlMsg('administration.userType')}
							selectElements={userTypesElements}
							context={FormModalContext}
							missingText={intlMsg('misc.noResultSearch')}
							isRequired
							trigerred
							isDisabled={currentUser?.id === editedElementId}
							specialOnChange={handleUserTypeChange}
						/>
						{selectedUserTypeNameTranslated && (
							<Tooltip
								title={selectedUserTypeNameTranslated}
								arrow
								placement='top'
								sx={{ color: '#979797' }}
							>
								<HelpOutlineIcon />
							</Tooltip>
						)}
					</Stack>
				</Grid>

				<Grid item xs={12}>
					<FormTextField
						name='job'
						label={intlMsg('misc.job')}
						context={FormModalContext}
					/>
				</Grid>
				<Grid item xs={6}>
					<FormTextField
						name='phone'
						label={intlMsg('misc.phone')}
						context={FormModalContext}
						InputProps={{ startAdornment: <PhoneIcon /> }}
						InputLabelProps={{ shrink: true }}
					/>
				</Grid>
				<Grid item xs={6}>
					<FormTextField
						name='cellphone'
						label={intlMsg('misc.cellphone')}
						context={FormModalContext}
						InputProps={{ startAdornment: <PhoneAndroidIcon /> }}
						InputLabelProps={{ shrink: true }}
					/>
				</Grid>
				{/* <Grid item xs={12}>
					<FormCheckBoxOrSwitch
						name='canLogin'
						label={intlMsg('administration.platformAcces')}
						bordered
						context={FormModalContext}
					/>
				</Grid> */}
				{/* <Grid item xs={6}>
							<FormCheckBoxOrSwitch
								name='canLoginApp'
								label={intlMsg('administration.applicationAcces')}
								bordered
								context={FormModalContext}
							/>
						</Grid> */}
				{/* <Grid item xs={12}>
					<FormSwitchButton
						name='isActive'
						config={{
							primary: { text: intlMsg('misc.active'), value: true },
							secondary: { text: intlMsg('misc.inactive'), value: false },
						}}
						context={FormModalContext}
					/>
				</Grid> */}
				{!editedElementId && (
					<Grid item xs={12}>
						<Alert severity='info' sx={infoAlertCSS}>
							<Typography variant='h4' className='explainActivationTitle'>
								{intlMsg('user.explainActivationTitle')}
							</Typography>
							<Typography
								color='initial'
								className='explainActivationParagraph'
							>
								{intlMsg('user.explainActivationParagraph')}
							</Typography>
							<Link
								href='https://www.loom.com/share/435c4af02c9a4b5d9d54a3c6f706eb6e?sid=22e5517e-7b94-4229-bf06-8953ef3fbcdd'
								color='#0B79D0'
								className='explainActivationLink'
								rel='noreferrer'
								target='_blank'
							>
								{intlMsg('user.readMore')}
								<OndemandVideoIcon className='explainActivationIcon' />
							</Link>
						</Alert>
					</Grid>
				)}
			</Grid>
		</Box>
	)
}

export default UserModalInfo
