/* eslint-disable @typescript-eslint/no-unused-vars */
import { EFormModalMode, ETvaFr } from '../../../../utils/app-models'
import { formatDate } from '../../../../utils/date'

export const vatListForSelect = [
	{
		label: ETvaFr.tva0,
		key: ETvaFr.tva0,
		value: ETvaFr.tva0,
	},
	{
		label: ETvaFr.tva20,
		key: ETvaFr.tva20,
		value: ETvaFr.tva20,
	},
]

export const acceptInvoiceFile = {
	'image/jpeg': [],
	'image/jpg': [],
	'image/png': [],
	'application/pdf': [],
	'image/tiff': [],
}

export const formatInvoiceModalEditDefaultValues = ({ data, currentQuery }) => {
	const {
		reference,
		vat,
		totalAmountET,
		dateFrom,
		dueDate,
		intervention,
		invoiceVehicles,
		file,
	} = data

	const invoiceSource =
		currentQuery ||
		(invoiceVehicles?.collection?.[0]?.vehicle?.id
			? 'vehicle'
			: intervention?.id
			? 'intervention'
			: 'vehicle')

	return {
		reference,
		dateFrom,
		dueDate,
		totalAmountET,
		vat: { label: vat.toString(), key: vat.toString(), value: vat.toString() },
		intervention: intervention?.id || '',
		vehicle: invoiceVehicles?.collection?.[0]?.vehicle?.id || '',
		importFile: file,
		invoiceSource,
	}
}

export const formatInvoiceModalCreateDefaultValues = ({
	currentQuery,
	viewModalDefaultValue,
}) => {
	return {
		vat: { key: ETvaFr.tva20, label: ETvaFr.tva20, value: ETvaFr.tva20 },
		invoiceSource: currentQuery || 'vehicle',
		...viewModalDefaultValue,
	}
}

////////////////////////////////////////////////////////////////////////////////////////////////

export const formatInvoiceModalOnValidateData = ({ data, mode, invoiceId }) => {
	const { name, type } = data?.importFile?.[0] || {}
	const { intervention, vat, totalAmountET, reference, dueDate, vehicle } = data

	return {
		reference,
		dueDate,
		totalAmountET,
		vat: parseInt(vat?.value),
		intervention: intervention !== '' ? intervention : null,
		...(type && { file: name.split('.').at(-1) }), // for have the extension file (ex: 'jpg')
		...(data?.importFile && !type && { file: '' }), // for delete file
		...(vehicle && { vehicles: [{ vehicle: vehicle, amount: 0.0 }] }),
		...(mode === EFormModalMode.edit && { id: invoiceId }),
	}
}

/////////////////////////////////////////////////////////////////////////////////////////////
// Format data for list select input
/////////////////////////////////////////////////////////////////////////////////////////////

export const formatFormatListOptionsForSelectINTERVENTIONS = (
	interventionsIPV,
	interventions,
	invoiceBy,
	setSelectElementsIntervention
) => {
	if (invoiceBy === 'vehicle') {
		const newArrayInterventionsIPV = [...interventionsIPV] // !important need a new table for .sort()
		const formatListInterventionsIPVForInput =
			newArrayInterventionsIPV
				?.sort(
					(a, b): any =>
						(new Date(b.dateFrom) as any) - (new Date(a.dateFrom) as any)
				)
				?.map(({ intervention: { id, reference, dateFrom } }) => ({
					key: id,
					label: `${reference}  ${dateFrom ? formatDate(dateFrom) : ''}`,
					value: id,
				})) || []

		setSelectElementsIntervention(formatListInterventionsIPVForInput || [])
	}
	// (  invoiceBy === 'interventions' )
	else {
		const newArrayInterventions = [...interventions] // !important need a new table for .sort()
		const formatListInterventionsForInput =
			newArrayInterventions
				?.sort(
					(a, b): any =>
						(new Date(b.dateFrom) as any) - (new Date(a.dateFrom) as any)
				)
				?.map(({ id, reference, dateFrom }) => ({
					key: id,
					label: `${reference}  ${dateFrom ? formatDate(dateFrom) : ''}`,
					value: id,
				})) || []

		setSelectElementsIntervention(formatListInterventionsForInput || [])
	}
}

export const formatFormatListOptionsForSelectVEHICLES = (
	vehiclesIPV,
	vehicles,
	invoiceBy,
	setSelectElementsVehicle
) => {
	if (invoiceBy === 'vehicle') {
		const formatListVehiclesForInput =
			vehicles?.map(
				({
					id,
					vehicleDetail: { immat, vin, vehicleBrand, vehicleModelText },
				}) => ({
					key: id,
					label: `${immat || vin}  ${vehicleBrand?.name ?? ''} ${
						vehicleModelText ?? ''
					}`,
					value: id,
				})
			) || []

		setSelectElementsVehicle(formatListVehiclesForInput || [])
	}
	// Else invoiceBy === 'intervention'
	else {
		const formatListVehiclesForInput =
			vehiclesIPV?.map(
				({
					vehicle: {
						id,
						vehicleDetail: { immat, vin, vehicleModelText, vehicleBrand },
					},
				}) => ({
					key: id,
					label: `${immat || vin}  ${vehicleBrand?.name ?? ''} ${
						vehicleModelText ?? ''
					}`,
					value: id,
				})
			) || []

		setSelectElementsVehicle(formatListVehiclesForInput || [])
	}
}

export const formatFormatListOptionsForSelectDefaultValueVEHICLE = (
	vehicle,
	setSelectElementsVehicle
) => {
	const { id, vehicleDetail } = vehicle || {}

	const formatListVehiclesForInput = [
		{
			key: id,
			label: `${vehicleDetail?.immat || vehicleDetail?.vin}  ${
				vehicleDetail?.vehicleBrand?.name ?? ''
			} ${vehicleDetail?.vehicleModelText ?? ''}`,
			value: id,
		},
	]

	setSelectElementsVehicle(formatListVehiclesForInput || [])
}

export const formatFormatListOptionsForSelectDefaultValueINTERVENTION = (
	intervention,
	setSelectElementsIntervention
) => {
	const { id, reference, dateFrom } = intervention || {}

	const formatListInterventionForInput = [
		{
			key: id,
			label: `${reference}  ${dateFrom ? formatDate(dateFrom) : ''}`,
			value: id,
		},
	]

	setSelectElementsIntervention(formatListInterventionForInput || [])
}
