import React from 'react'

import VmCardExpended from '../../../../components/viewModal/vmCardExpended'
import DataListGrid from '../../../../components/list/dataList/DataListGrid'
import DataList from '../../../../components/list/dataList'
import { dataListType } from '../../../../utils/app-models'
import { formatDate } from '../../../../utils/date'

interface IInformationForm {
	formTemplateName: string
	dateCreated: string
	createdBy: any
	geolocation: string
	geolocationLink: string
}

const InformationForm: React.FC<IInformationForm> = ({
	formTemplateName,
	dateCreated,
	createdBy,
	geolocation,
	geolocationLink,
}) => {
	return (
		<VmCardExpended title='interventionForms.report' titleGutter>
			<DataListGrid>
				<DataList
					dataType={dataListType.simpleText}
					title='interventionForms.model'
					data={[{ text: formTemplateName }]}
					fullWidth
				/>

				<DataList
					dataType={dataListType.simpleText}
					title='misc.createdAt'
					data={[{ text: formatDate(dateCreated, true) }]}
				/>

				<DataList
					dataType={dataListType.avatar}
					title='misc.enteredBy'
					data={[
						{
							firstName: createdBy?.firstName,
							lastName: createdBy?.lastName,
							userId: createdBy?.id,
							accountId: createdBy?.account?.id,
							onlyText: true,
						},
					]}
				/>

				<DataList
					dataType={dataListType.simpleText}
					title='interventionForms.horodatage'
					data={[{ text: dateCreated?.split('+')[0] + ' UTC' }]}
					fullWidth
				/>

				<DataList
					dataType={dataListType.externalLink}
					title='misc.geolocalisation'
					data={[
						{
							text: geolocation,
							link: { url: geolocationLink, target: '_blank' },
						},
					]}
					fullWidth
				/>
			</DataListGrid>
		</VmCardExpended>
	)
}
export default InformationForm
