import React from 'react'

import { Stack, AlertTitle, Alert } from '@mui/material'

import { useFormatMessage } from '../../utils/hooks'

const InvalidToken: React.FC = () => {
	const intlMsg = useFormatMessage()

	return (
		<Stack justifyContent='center'>
			<Alert severity='warning'>
				<AlertTitle>{intlMsg('userConfirmation.errorTitle')}</AlertTitle>
				{intlMsg('userConfirmation.errorText')}
			</Alert>
		</Stack>
	)
}

export default InvalidToken
