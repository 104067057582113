/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import {
	gql,
	OperationVariables,
	QueryHookOptions,
	useQuery,
} from '@apollo/client'
import { listGestionnairesAttributes } from '../attributes/listGestionnairesAttributes'

export const LIST_GESTIONNAIRES = gql`
	query getListGestionnaires(
		$page: Int
		$searchText: String
		$account: String
		$account_list: [String]
		$isActive: Boolean
	) {
		users(
			page: $page
			itemsPerPage: 100000,
			isActive: $isActive
			account_list: $account_list
			account: $account
			searchText: $searchText
		)  {
        ${listGestionnairesAttributes}
    }
	}
	`

/*-------------------------- HOOKS ---------------------------------*/

export const useListGestionnaires = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
): any => {
	const { loading, data } = useQuery(LIST_GESTIONNAIRES, options)
	return { loading, data: data?.users?.collection }
}
