import React, { memo } from 'react'

import { ApolloProvider } from '@apollo/client'
import { BrowserRouter } from 'react-router-dom'

import AlertToasts from '../components/alert/AlertToasts'
import IntlProviderWrapper from './IntlProviderWrapper'
import AuthProvider from './AuthProvider'
import { client } from '../apolloClient'
import ExportProvider from './ExportProvider'
import ThemeProvider from '../styles/themes/ThemeProvider'

interface IProvidersProps {
	children
}

const Providers: React.FC<IProvidersProps> = ({ children }) => {
	return (
		<ApolloProvider client={client}>
			<BrowserRouter>
				<AuthProvider>
					<IntlProviderWrapper>
						<ThemeProvider>
							<ExportProvider>
								<AlertToasts />
								{children}
							</ExportProvider>
						</ThemeProvider>
					</IntlProviderWrapper>
				</AuthProvider>
			</BrowserRouter>
		</ApolloProvider>
	)
}

export default memo(Providers)
