import React from 'react'

interface IVehicleKmCell {
	vehicleDetail?
	vehicleMileages?
}

const VehicleKmCell: React.FC<IVehicleKmCell> = ({
	vehicleDetail,
	vehicleMileages,
}) => {
	const km = vehicleMileages?.length
		? vehicleMileages?.[0].mileage
		: vehicleDetail?.actualMileage

	return km ?? '-'
}

export default VehicleKmCell
