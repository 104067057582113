import React from 'react'

import VmCardExpended from '../../../../components/viewModal/vmCardExpended'
import DataListGrid from '../../../../components/list/dataList/DataListGrid'
import DataList from '../../../../components/list/dataList'
import {
	EInterventionTypes,
	EViewModal,
	dataListType,
} from '../../../../utils/app-models'

const modalsMission = {
	[EInterventionTypes.control]: EViewModal.INTERVENTION_REFERENCES,
	[EInterventionTypes.service]: EViewModal.INTERVENTION_REFERENCES,
	[EInterventionTypes.transport]: EViewModal.INTERVENTION_TRANSPORT,
}

interface IMissionForm {
	missionType
	missionReference
	missionId
	missionDate
}

const MissionForm: React.FC<IMissionForm> = ({
	missionType,
	missionReference,
	missionId,
	missionDate,
}) => {
	return (
		<VmCardExpended title='misc.mission' titleGutter>
			<DataListGrid>
				<DataList
					dataType={dataListType.simpleText}
					title='misc.type'
					data={[{ text: missionType }]}
				/>

				<DataList
					dataType={dataListType.vmLink}
					title='misc.ref'
					data={[
						{
							text: missionReference,
							link: { vm: modalsMission[missionType], id: missionId },
						},
					]}
				/>

				<DataList
					dataType={dataListType.simpleText}
					title='misc.dateMission'
					data={[{ text: missionDate }]}
					fullWidth
				/>
			</DataListGrid>
		</VmCardExpended>
	)
}
export default MissionForm
