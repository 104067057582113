import React, {
	useCallback,
	useContext,
	useEffect,
	useState,
	useMemo,
} from 'react'

import FilterBtn from '../../../tableV2/filters/FilterBtn'
import FilterValuesPopover from './FilterValuesPopover'
import { FiltersContext } from '../../filtersContext'

interface IFilterValues {
	setFiltersQuerieParam
	filtersQuerieParam
	title
	query?
	argumentQuerieName?
	forcedData?: any[]
	uncolored?: boolean
	allId?: string | null
}

const FilterValues: React.FC<IFilterValues> = ({
	setFiltersQuerieParam,
	filtersQuerieParam,
	title,
	query = null,
	argumentQuerieName = 'status',
	forcedData = null,
	uncolored = false,
	allId = 'all',
}) => {
	const { data: inputList } = (query && query()) || {}
	const { localStorageKeyFilter } = useContext(FiltersContext) || {}
	const [currentFilter, setCurrentFilter]: any = useState(null)

	const selectedStatus = useMemo(() => {
		return inputList
			? inputList?.filter(
					({ id }) => id === currentFilter?.[argumentQuerieName]
			  )[0] || { categoryAll: true }
			: forcedData?.filter(
					({ id }) => id === currentFilter?.[argumentQuerieName]
			  )[0] || { categoryAll: true }
	}, [currentFilter, inputList])

	const value = useMemo(() => {
		return selectedStatus?.code
			? selectedStatus?.code
				? `interventions.${selectedStatus?.code
						.toLowerCase()
						.split(' ')
						.join('')}`
				: selectedStatus?.name
			: `interventions.all`
	}, [selectedStatus])

	// /////////////////////////////////////////////////////////////////////////
	// OPEN/CLOSE popover
	// /////////////////////////////////////////////////////////////////////////

	const [anchorEl, setAnchorEl] = useState(null)
	const handleClick = useCallback((e) => {
		setAnchorEl(e.currentTarget)
	}, [])
	const handleClose = useCallback(() => setAnchorEl(null), [])
	const popoverIsOpen = Boolean(anchorEl)

	// /////////////////////////////////////////////////////////////////////////
	// handle Select One Status
	// /////////////////////////////////////////////////////////////////////////

	const handleRadioChange = useCallback(
		(id = allId) =>
			() => {
				setFiltersQuerieParam({
					...filtersQuerieParam,
					[argumentQuerieName]: id,
				})

				setCurrentFilter({
					[argumentQuerieName]: id,
				})

				handleClose()
			},
		[filtersQuerieParam, argumentQuerieName]
	)

	// /////////////////////////////////////////////////////////////////////////
	// Init default value
	// /////////////////////////////////////////////////////////////////////////

	useEffect(() => {
		const filterPreference = JSON.parse(
			localStorage.getItem(localStorageKeyFilter) as string
		)

		// get local preference
		if (filterPreference) {
			setCurrentFilter({
				[argumentQuerieName]: filterPreference[argumentQuerieName],
			})
		}
	}, [])

	return (
		<>
			<FilterBtn
				title={title}
				value={value}
				onClick={handleClick}
				colorLabel={selectedStatus?.color}
				withColorLabel
			/>

			{popoverIsOpen && (
				<FilterValuesPopover
					handleClose={handleClose}
					open={popoverIsOpen}
					anchorEl={anchorEl}
					handleRadioChange={handleRadioChange}
					inputList={inputList || forcedData}
					selectedStatus={selectedStatus}
					uncolored={uncolored}
				/>
			)}
		</>
	)
}

export default FilterValues
