/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

import { stringifiedColor } from '../../../utils/color'

export const controlFieldPDF = (doc, data, position) => {
	const { positionYcell, positionXcell, heightCell } = position

	const attribute = JSON.parse(data.formTemplateField.attributes[0])

	// if typeof defaultValue === 'string' -> default value="" (not defined) -> set default value to 0 if field required
	const record =
		data?.value?.value ??
		(typeof attribute?.defaultValue === 'string' && attribute?.isRequired
			? 0
			: attribute?.defaultValue)

	let startingXposition = positionXcell

	attribute.controlList.forEach(({ choice, color }, i) => {
		const elementIsSelected = record === i
		const { h, w } = doc.getTextDimensions(choice)
		const heightSquare = h + 2
		const widthSquare = w + 2

		const centerYtext = positionYcell + heightCell / 2 + (heightSquare - h) / 2
		const centerXtext = 1.9 + startingXposition + (widthSquare - w) / 2

		const centerPositionYSquare =
			positionYcell + (heightCell - heightSquare) / 2

		//Add border color & bg color
		const HexaColor = stringifiedColor(color, 500)
		doc.setDrawColor(HexaColor)
		elementIsSelected && doc.setFillColor(HexaColor)

		// HELP -> roundedRect(positionXcell, positionYcell, w, h, rx, ry, styleopt)
		doc.roundedRect(
			startingXposition + 1.9,
			centerPositionYSquare,
			widthSquare,
			heightSquare,
			0.5,
			0.5,
			elementIsSelected ? 'FD' : 'S'
		)

		// add text to square form
		doc.setTextColor(elementIsSelected ? 'white' : HexaColor)
		doc.text(choice, centerXtext, centerYtext)

		// increment position X for next element
		startingXposition = startingXposition + widthSquare + 1
	})
}
