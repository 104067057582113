import React, { useMemo } from 'react'

import { Typography } from '@mui/material'

import DataListTitle from './DataListTitle'
import DataListSimpleText from './dataListType/DataListSimpleText'
import DataListVmLink from './dataListType/DataListVmLink'
import DataListExternalLink from './dataListType/DataListExternalLink'
import DataListPhone from './dataListType/DataListPhone'
import DataListMail from './dataListType/DataListMail'
import DataListAvatar from './dataListType/DataListAvatar'
import DataListSignature from './dataListType/DataListSignature'
import DataListNotation from './dataListType/DataListNotation'
import DataListComment from './dataListType/DataListComment'
import DataListPeriod from './dataListType/DataListPeriod'

import { EModule, EViewModal, dataListType } from '../../../utils/app-models'

const DataListByType = {
	simpleText: DataListSimpleText,
	vmLink: DataListVmLink,
	externalLink: DataListExternalLink,
	phone: DataListPhone,
	mail: DataListMail,
	avatar: DataListAvatar,
	signature: DataListSignature,
	notation: DataListNotation,
	comment: DataListComment,
	period: DataListPeriod,
}

type VMLink = {
	vm: EViewModal
	id: string
}

type EXTERNALLink = {
	url: string
	target?: '_blank' | 'parent'
}

type TUser = {
	firstName: string
	lastName: string
	userId?: string
	accountId?: string
	onlyText?: boolean
}

type PERIODDate = {
	date: any
	timeFrom: any
	timeTo: any
}

type TPeriod = {
	dateStart: PERIODDate
	dateEnd: PERIODDate
}

/////////////////////////////////////////////////////////
// Type datalist
/////////////////////////////////////////////////////////

// simpleText
type IDataListSimpleText = {
	dataType: dataListType.simpleText
	title: string
	data: [{ text: string }]
	modules?: EModule[]
	fullWidth?: boolean
}

// vmLink
type IDataListVmLink = {
	dataType: dataListType.vmLink
	title: string
	data: [{ text: string; link: VMLink }]
	modules?: EModule[]
	fullWidth?: boolean
}

// externalLink
type IDataListExternalLink = {
	dataType: dataListType.externalLink
	title: string
	data: [{ text: string; link: EXTERNALLink }]
	fullWidth?: boolean

	modules?: undefined
}

// phone
type IDataListPhone = {
	dataType: dataListType.phone
	title: string
	data: [{ text: string }]
	fullWidth?: boolean

	modules?: undefined
}

// mail
type IDataListMail = {
	dataType: dataListType.mail
	title: string
	data: [{ text: string }]
	fullWidth?: boolean

	modules?: undefined
}

// avatar
type IDataListAvatar = {
	dataType: dataListType.avatar
	title: string
	data: TUser[]
	fullWidth?: boolean

	modules?: undefined
}

// signature
type IDataListSignature = {
	dataType: dataListType.signature
	title: string
	data: [{ url: string }]
	fullWidth: true

	modules?: undefined
}

// notation
type IDataListNotation = {
	dataType: dataListType.notation
	title: string
	data: [{ note: string }]
	fullWidth?: boolean

	modules?: undefined
}

// comment
type IDataListComment = {
	dataType: dataListType.comment
	title: string
	data: [{ comment: string; maxCharacters?: boolean }]
	fullWidth?: boolean

	modules?: undefined
}

// notation
type IDataListPeriod = {
	dataType: dataListType.period
	data: TPeriod[]
	fullWidth?: boolean

	modules?: undefined
	title?: undefined
}

type IDataList =
	| IDataListSimpleText
	| IDataListVmLink
	| IDataListExternalLink
	| IDataListPhone
	| IDataListMail
	| IDataListAvatar
	| IDataListSignature
	| IDataListNotation
	| IDataListComment
	| IDataListPeriod

const DataList: React.FC<IDataList> = ({
	dataType,
	title,
	data,
	modules,
	fullWidth,
}) => {
	const RenderListByType = useMemo(() => DataListByType[dataType], [dataType])

	return (
		<DataListTitle title={title} fullWidth={fullWidth}>
			{data?.length ? (
				data.map((dataProps, i) => (
					<RenderListByType
						key={title ?? 'list' + i}
						{...{ title, modules, ...dataProps }}
					/>
				))
			) : (
				<Typography variant='text1Bold'>-</Typography>
			)}
		</DataListTitle>
	)
}

export default DataList
