import React, { useCallback } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemText from '@mui/material/ListItemText'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import Tooltip from '@mui/material/Tooltip'

import { useProfileUserTeamSlidePanel } from '../../../../api/gql/queries/usersQueries'
import SkeletonFormsBar from '../../../skeleton/SkeletonFormsBar'
import NothingToDisplay from '../../../alert/NothingToDisplay'
import { useFormatMessage } from '../../../../utils/hooks'

const ListItemCSS = {
	borderBottom: ({ palette }) => `solid 1px ${palette.grey['200']}`,
	pb: 2,
}

interface ITabPanelUserTeam {
	id: string
}

const TabPanelUserTeam: React.FC<ITabPanelUserTeam> = ({ id }) => {
	const intlMsg = useFormatMessage()
	const { data: dataUser, loading } = useProfileUserTeamSlidePanel({
		variables: { id },
	})

	const renderListItem = useCallback(
		({ name, isManager }, i) => (
			<ListItem key={`${i}-teamList`} sx={ListItemCSS}>
				<ListItemText primary={name} />
				{isManager && (
					<ListItemSecondaryAction sx={{ mt: '-7px' }}>
						<Tooltip
							title={intlMsg('misc.teamManager')}
							aria-label={intlMsg('misc.teamManager')}
						>
							<SupervisorAccountIcon
								color='disabled'
								data-test-id='Playwright-TabPanelUserTeam-supervisor'
							/>
						</Tooltip>
					</ListItemSecondaryAction>
				)}
			</ListItem>
		),
		[]
	)

	if (loading) {
		return <SkeletonFormsBar nbRow={5} />
	}

	if (dataUser?.teams?.length > 0) {
		return <List dense={true}>{dataUser.teams.map(renderListItem)}</List>
	}

	return <NothingToDisplay />
}

export default TabPanelUserTeam
