/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

import { EInterventionTypes } from '../../utils/app-models'
import {
	blocksPDFtransport,
	blocksPDFcontrol,
	blocksPDFservice,
} from './tableBlocks'

const blocksPdfConf = {
	[EInterventionTypes.transport]: blocksPDFtransport,
	[EInterventionTypes.control]: blocksPDFcontrol,
	[EInterventionTypes.service]: blocksPDFservice,
}

export const generateBlockPdf = (interventionType, configDataPDF, doc) => {
	let lastPositionsY = {}

	Object.entries(configDataPDF).forEach(([key]) => {
		// render block
		blocksPdfConf[interventionType][key](
			doc,
			configDataPDF[key],
			lastPositionsY
		)

		// add the last block Y position
		lastPositionsY = { ...lastPositionsY, [key]: doc.lastAutoTable.finalY + 10 }
	})
}
