import React, { useContext } from 'react'

import { Stack } from '@mui/material'

import { FormTemplateContext } from '../../../../../../utils/contexts/FormTemplateContext'
import { TitleField, RequireFieldTxt } from '../fieldEditForms/editFormsField'

interface IFieldEditFormLayoutMileage {}

const FieldEditFormLayoutMileage: React.FC<
	IFieldEditFormLayoutMileage
> = () => {
	const {
		drawerUtils: { controlDrawer: control },
	} = useContext(FormTemplateContext)

	return (
		<Stack spacing={2}>
			<RequireFieldTxt control={control} />
			<TitleField control={control} />
		</Stack>
	)
}

export default FieldEditFormLayoutMileage
