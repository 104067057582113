import React, { useCallback, useContext, useMemo, useRef } from 'react'

import {
	Box,
	Link,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
} from '@mui/material'
import PlaceIcon from '@mui/icons-material/Place'
import StoreIcon from '@mui/icons-material/Store'
import PhoneIcon from '@mui/icons-material/Phone'

import { formatAdressGoogleMap } from '../../../utils/string'
import { grey } from '@mui/material/colors'
import { ViewModalContentContext } from '../../../utils/contexts/ViewModalContentContext'
import { useResizeHeightStyle } from '../../../utils/hooks'

const ListItemIconCSS = { minWidth: '35px', mt: 0 }
const ListItemIcon2CSS = { minWidth: '35px', mt: '5px' }
const IconCSS = { color: '#bdbdbdbd', width: '20px', height: '20px' }
const primaryTypographyProps = { fontWeight: 500, fontSize: '14px' }

interface IWTransportTripSummaryAddress {
	itTrip
	maxHeightUtils: {
		maxHeight: any
		setMaxHeight: any
	}
}

const WTransportTripSummaryAddress: React.FC<IWTransportTripSummaryAddress> = ({
	itTrip,
	maxHeightUtils,
}) => {
	const { viewModalData } = useContext(ViewModalContentContext) || {}

	const openGoogleMap = useCallback(() => {
		window.open(
			formatAdressGoogleMap(itTrip.address, itTrip.city, itTrip.zipcode),
			'_blank'
		)
	}, [])

	// Calculated & resize height
	const resizeRef = useRef<HTMLUListElement>(null)
	const componentStyle = useResizeHeightStyle(resizeRef, maxHeightUtils, [
		itTrip,
	])

	const Address = useMemo(
		() => (
			<Box
				component='span'
				sx={{ display: 'flex !important', flexDirection: 'column !important' }}
			>
				<Typography
					variant='body2'
					component='span'
					color='text.primary'
					fontWeight={500}
					sx={{ width: '100%' }}
				>
					{itTrip?.site?.name ||
						(itTrip?.isAddressEqualOrder && viewModalData?.orderingSite?.name)}
				</Typography>

				<Link
					color='inherit'
					underline='hover'
					href='#'
					onClick={openGoogleMap}
					sx={{
						display: 'flex !important',
						flexDirection: 'column !important',
						color: grey[700],
					}}
				>
					<Typography variant='body2' component='span'>
						{itTrip?.address}
					</Typography>
					<Typography variant='body2' component='span'>
						{itTrip?.zipcode + ', ' + itTrip?.city}
					</Typography>
					<Typography variant='body2' component='span'>
						{itTrip?.country}
					</Typography>
				</Link>
			</Box>
		),
		[itTrip]
	)

	const ContactText = useMemo(
		() => (
			<Box display={'flex'}>
				<Typography
					sx={{ fontWeight: '500', color: grey[700], fontSize: '14px' }}
				>
					{itTrip.contactText}
				</Typography>
				{itTrip?.contactPhone && (
					<>
						<Box sx={{ margin: '0px 5px' }}>-</Box>
						<Link
							color='inherit'
							underline='hover'
							href={`tel:${itTrip.contactPhone}`}
							sx={{ fontWeight: '500', color: grey[700], fontSize: '14px' }}
						>
							{itTrip.contactPhone}
						</Link>
					</>
				)}
			</Box>
		),
		[itTrip]
	)

	return (
		<List ref={resizeRef} style={componentStyle}>
			{itTrip?.name && (
				<ListItem alignItems='center' dense>
					<ListItemIcon sx={ListItemIconCSS}>
						<StoreIcon sx={IconCSS} />
					</ListItemIcon>
					<ListItemText
						sx={{ textTransform: 'uppercase' }}
						primary={itTrip.name}
						primaryTypographyProps={primaryTypographyProps}
					/>
				</ListItem>
			)}

			<ListItem alignItems='flex-start' dense>
				<ListItemIcon sx={ListItemIcon2CSS}>
					<PlaceIcon sx={IconCSS} />
				</ListItemIcon>
				<ListItemText secondary={Address} />
			</ListItem>

			{itTrip?.contactText && (
				<ListItem alignItems='center' dense>
					<ListItemIcon sx={ListItemIconCSS}>
						<PhoneIcon sx={IconCSS} />
					</ListItemIcon>
					<ListItemText
						primary={ContactText}
						sx={{ fontWeight: '500 !important', color: grey[700] }}
					/>
				</ListItem>
			)}
		</List>
	)
}

export default WTransportTripSummaryAddress
