import React, {
	useCallback,
	useState,
	useEffect,
	useContext,
	useMemo,
} from 'react'

import getNestedObjectFromKey from 'lodash.get'
import toast from 'react-hot-toast'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import Popover from '@mui/material/Popover'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton/IconButton'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { Button, Stack, Divider, Typography } from '@mui/material'

import {
	importExcel,
	checkIfExcelIsValid,
	finalImport,
} from '../../../../utils/import'
import { useFormatMessage } from '../../../../utils/hooks'
import { AuthContext } from '../../../../utils/contexts/AuthContext'
import { EUserTypes } from '../../../../utils/app-models'

interface ITableImport {
	importConfig?
}

const TableImport: React.FC<ITableImport> = ({
	importConfig: {
		templateExcelName,
		columnsToImport,
		watchThisFields,
		TableImportForm,
		schemaValidation,
		useMutationImport,
		otherInputForMutation,
		refetchQueries,
		errorNestedAccess,
	},
}) => {
	const intlMsg = useFormatMessage()
	const { currentUser } = useContext(AuthContext)
	const {
		handleSubmit,
		watch,
		setValue,
		reset,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schemaValidation(intlMsg)),
	})
	const watchFields = watch(watchThisFields)
	const [loading, setLoading] = useState(false)
	const { localLanguage } = useContext(AuthContext)

	const canImport = useMemo(
		() => currentUser?.userType?.name !== EUserTypes.operator,
		[currentUser?.userType]
	)

	/////////////////////////////////////////////////////////////////////////////
	// Open/close Popover
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
	const open = Boolean(anchorEl)
	const id = open ? 'simple-popover' : undefined
	const handleClick = useCallback((e) => setAnchorEl(e.currentTarget), [])
	const handleClose = useCallback(() => {
		setAnchorEl(null)
		reset()
	}, [])

	/////////////////////////////////////////////////////////////////////////////
	// Extract and format data from excel
	const [colDefs, setColDefs] = useState<any>()
	const [dataTable, setDataTable] = useState<any>()
	const [otherInputDataForMutation, setOtherDataInputForMutation] =
		useState<any>({})

	const handleImport = useCallback((data) => {
		otherInputForMutation?.forEach((input) => {
			data[input] &&
				setOtherDataInputForMutation((prev) => ({
					...prev,
					[input]: data[input],
				}))
		})

		importExcel(data.importFile[0], setDataTable, setColDefs) as any
	}, [])

	/////////////////////////////////////////////////////////////////////////////
	// Mutation import
	const [mutationImport] = useMutationImport()
	useEffect(() => {
		if (colDefs && dataTable) {
			setLoading(true)

			const errorImportExcel = checkIfExcelIsValid(
				columnsToImport,
				colDefs,
				dataTable
			)

			if (errorImportExcel) {
				setLoading(false)
				toast.error(errorImportExcel)
			} else {
				const importData = async () => {
					await mutationImport({
						variables: {
							input: {
								...otherInputDataForMutation,
								importData: finalImport(
									{},
									dataTable,
									colDefs,
									columnsToImport
								),
							},
						},
						refetchQueries,
					}).then((result) => {
						const errors = errorNestedAccess
							? getNestedObjectFromKey(result, errorNestedAccess)
							: []
						if (errors?.length) {
							errors.forEach(({ error }) => {
								toast.error(error)
							})
						} else {
							toast.success('success.importExcel')
						}
					})
				}

				importData()
				handleClose()
			}
			setDataTable(null)
			setColDefs(null)
			setLoading(false)
		}
	}, [colDefs, dataTable, otherInputDataForMutation])

	return (
		<>
			{canImport && (
				<Tooltip title={intlMsg('misc.importData')}>
					<IconButton onClick={handleClick} size='large'>
						<CloudUploadIcon />
					</IconButton>
				</Tooltip>
			)}

			{open && (
				<Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose}>
					<Stack
						direction='row'
						alignItems='center'
						justifyContent='space-between'
						p={4}
					>
						<Typography variant='body1'>
							{intlMsg('table.downloadExcelTemplate')}
						</Typography>

						<Button
							variant='outlined'
							color='primary'
							href={`/templates-excel/import-${templateExcelName}-${localLanguage}.xlsx`}
						>
							{intlMsg('misc.download')}
						</Button>
					</Stack>

					<Divider variant='fullWidth' />

					<form
						onSubmit={handleSubmit((data) => handleImport(data))}
						style={{ width: '500px', position: 'relative' }}
					>
						<TableImportForm
							handleClose={handleClose}
							loading={loading}
							watchFields={watchFields}
							formUtils={{ errors, watch, setValue }}
						/>
					</form>
				</Popover>
			)}
		</>
	)
}

export default TableImport
