import { ETransportTripType } from '../../../../../../../utils/app-models'
import { getCountries } from '../../../../../../../utils/tabCountries'

type TGetFinalOrder = {
	transportTripType: string
	data: any
	getTransportTripSite: any
	getTransportTripContact: any
	isFromAddressBook?: boolean
	additionalValues?: any
	localLanguage: string
}

export const getFinalOrder = async ({
	transportTripType,
	data,
	getTransportTripSite,
	getTransportTripContact,
	isFromAddressBook,
	additionalValues,
	localLanguage,
}: TGetFinalOrder) => {
	let valuesToSet = { ...(additionalValues && { ...additionalValues }) }

	const finalOrder = {
		orderingCustomer: !isFromAddressBook
			? data?.orderingCustomer
			: data?.[`${transportTripType}AddressBookCustomer`],
		orderingSite: !isFromAddressBook
			? data?.orderingSite
			: data?.[`${transportTripType}AddressBookSite`],
		orderingContact: !isFromAddressBook
			? data?.orderingContact
			: data?.[`${transportTripType}AddressBookContact`],
	}

	/////////////////////////////////////////////////////////
	//  Set orderingCustomer social name
	/////////////////////////////////////////////////////////

	if (finalOrder?.orderingCustomer) {
		valuesToSet = {
			...valuesToSet,
			[`${transportTripType}Name`]: `${
				finalOrder?.orderingCustomer?.label || ''
			} ${finalOrder?.orderingSite?.label || ''}`,
		}
	}

	/////////////////////////////////////////////////////////
	// Get & Set site infos
	/////////////////////////////////////////////////////////

	if (finalOrder?.orderingSite?.value?.id) {
		await getTransportTripSite({
			variables: { siteId: finalOrder.orderingSite?.value?.id },
		}).then((res) => {
			const { zipcode, country, address, city } = res?.data?.site || {}
			const countryValues = getCountries(localLanguage)?.find(
				({ code }) => code === country
			)
			const formattedCountry = {
				key: countryValues?.code,
				label: countryValues?.label,
				value: countryValues?.code,
			}

			valuesToSet = {
				...valuesToSet,
				[`${transportTripType}Address`]: address || null,
				[`${transportTripType}Zipcode`]: zipcode || null,
				[`${transportTripType}City`]: city || null,
				[`${transportTripType}Country`]: formattedCountry || null,
			}
		})
	}

	/////////////////////////////////////////////////////////
	// Get & Set contact infos
	/////////////////////////////////////////////////////////

	if (finalOrder?.orderingContact?.value?.id) {
		await getTransportTripContact({
			variables: { contactId: finalOrder?.orderingContact?.value?.id },
		}).then((res) => {
			const { lastName, firstName, phone, cellphone } = res?.data?.contact || {}
			const contactName = `${firstName} ${lastName}`
			const contactPhone = cellphone || phone || ''

			valuesToSet = {
				...valuesToSet,
				[`${transportTripType}ContactText`]: contactName || null,
				[`${transportTripType}ContactPhone`]: contactPhone || null,
			}
		})
	}
	// isFromAddressBook -> if finalOrder.orderingContact is empty, reset ContactText & ContactPhone
	else if (
		isFromAddressBook &&
		(data[`${transportTripType}ContactText`] ||
			data[`${transportTripType}ContactPhone`])
	) {
		valuesToSet = {
			...valuesToSet,
			[`${transportTripType}ContactText`]: null,
			[`${transportTripType}ContactPhone`]: null,
		}
	}

	return valuesToSet
}

export const resetFinalOrder = ({ transportTripType, setValues }) => {
	setValues({
		[`${transportTripType}Name`]: null,
		[`${transportTripType}ContactText`]: null,
		[`${transportTripType}ContactPhone`]: null,
		[`${transportTripType}Address`]: null,
		[`${transportTripType}Zipcode`]: null,
		[`${transportTripType}City`]: null,
		[`${transportTripType}Country`]: null,
	})
}

/////////////////////////////////////////////////////////
// handleSetIsOrderEqualFinal
/////////////////////////////////////////////////////////
export const handleSetIsOrderEqualFinal =
	({
		setIsOrderEqualFinal,
		setValues,
		transportTripType,
		isOrderEqualFinal,
		getTransportTripSite,
		getTransportTripContact,
		data,
		triggerError,
		localLanguage,
	}) =>
	async () => {
		setIsOrderEqualFinal((prev) => ({
			[transportTripType === ETransportTripType.delivery
				? ETransportTripType.removal
				: ETransportTripType.delivery]: false,
			[transportTripType]: !prev[transportTripType],
		}))

		const orderEquality = {
			[transportTripType === ETransportTripType.delivery
				? 'isRemovalAddressEqualOrder'
				: 'isDeliveryAddressEqualOrder']: false,
			[transportTripType !== ETransportTripType.delivery
				? 'isRemovalAddressEqualOrder'
				: 'isDeliveryAddressEqualOrder']: !isOrderEqualFinal[transportTripType],
		}

		const isDeliveryAddressEqualOrder =
			orderEquality[
				transportTripType === ETransportTripType.delivery
					? 'isDeliveryAddressEqualOrder'
					: 'isRemovalAddressEqualOrder'
			]

		const newValues = isDeliveryAddressEqualOrder
			? await getFinalOrder({
					transportTripType: transportTripType,
					getTransportTripSite,
					getTransportTripContact,
					data,
					additionalValues: orderEquality,
					localLanguage,
			  })
			: orderEquality

		setValues(newValues)

		triggerError({ specificData: newValues })
	}

/**
 * Swap the delivery and removal address details in the given data.
 *
 * @param {Object} data - The current data object containing address details.
 * @param {Function} setValues - The function to update the state with new data.
 */
export const swapDeliveryAndRemovalAddresses = ({ data, setValues }) =>
	setValues({
		deliveryAddress: data.removalAddress,
		removalAddress: data.deliveryAddress,
		deliveryZipcode: data.removalZipcode,
		removalZipcode: data.deliveryZipcode,
		deliveryCity: data.removalCity,
		removalCity: data.deliveryCity,
		deliveryCountry: data.removalCountry,
		removalCountry: data.deliveryCountry,
		deliveryName: data.removalName,
		removalName: data.deliveryName,
		deliveryContactPhone: data.removalContactPhone,
		removalContactPhone: data.deliveryContactPhone,
		deliveryContactText: data.removalContactText,
		removalContactText: data.deliveryContactText,
	})
