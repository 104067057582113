import { Stack, Typography } from '@mui/material'
import React, { useContext, useMemo } from 'react'
import { useFormatMessage } from '../../../../../../utils/hooks'
import { FiltersBtnContext } from '../../../../../../components/filters/filtersBtn/FiltersBtnContext'
import { EAccountType, EModule } from '../../../../../../utils/app-models'
import { useGetCustomerListForSelect } from '../../../../../../api/gql/queries/customersQueries'
import FilterStatusIntervention from '../../../../../../components/filters/filtersComponents/filterStatusIntervention'
import FilterMultiselect from '../../../../../../components/filters/filtersComponents/filterMultiselect'
import { useUsersForSelect } from '../../../../../../api/gql/queries/usersQueries'
import FilterSwitch from '../../../../../../components/filters/filtersComponents/filterSwitch'
import { useGetAllAgenciesForModal } from '../../../../../../api/gql/queries/agencyQueries'
import { AuthContext } from '../../../../../../utils/contexts/AuthContext'
import qs from 'qs'

interface IByReferenceFiltersBtnComponent {}

const ByReferenceFiltersBtnComponent: React.FC<
	IByReferenceFiltersBtnComponent
> = () => {
	const intlMsg = useFormatMessage()
	const { setTmpFilters, tmpFilters } = useContext(FiltersBtnContext)
	const { checkAccountsType } = useContext(AuthContext)
	const { users, loading: usersLoading } = useUsersForSelect()
	const { customers, loading: customersLoading } = useGetCustomerListForSelect()
	const { agenciesAccounts, agenciesAccountsLoading } =
		useGetAllAgenciesForModal()

	//used to know if we are in the view modal
	const viewModalQuery = useMemo(
		() =>
			qs.parse(location.search, {
				ignoreQueryPrefix: true,
			})?.query,
		[location]
	)

	const isGuestAccount = useMemo(
		() => checkAccountsType([EAccountType?.guest]),
		[checkAccountsType]
	)

	const canDisplayCustomerFilter = useMemo(
		() =>
			viewModalQuery !== 'customer' &&
			viewModalQuery !== 'site' &&
			!isGuestAccount,
		[viewModalQuery, isGuestAccount]
	)

	const actorsOptions = useMemo(() => {
		return (
			users?.map((user) => ({
				id: user?.id,
				name: `${user?.firstName} ${user?.lastName}`,
				value: user?.id,
			})) || []
		)
	}, [users])

	const actorsDefaultValue = useMemo(
		() =>
			actorsOptions?.filter((option) =>
				tmpFilters?.actors_list?.find((actorId) => option?.id === actorId)
			),

		[actorsOptions]
	)

	const customersOptions = useMemo(
		() =>
			customers?.map((customer) => ({
				id: customer?.id,
				name: customer?.name,
				value: customer?.id,
			})),
		[customers]
	)

	const customersDefaultValue = useMemo(
		() =>
			customersOptions?.filter((option) =>
				tmpFilters?.customer_list?.find(
					(customerId) => option?.id === customerId
				)
			),
		[customersOptions]
	)

	const agenciesAccountsOptions = useMemo(
		() =>
			agenciesAccounts?.map((agenciesAccount) => ({
				id: agenciesAccount?.id,
				name: agenciesAccount?.agencyCustomDetails[0]?.agencyName,
				value: agenciesAccount?.id,
			})) || [],
		[agenciesAccounts]
	)

	const agenciesAccountsDefaultValue = useMemo(
		() =>
			agenciesAccountsOptions?.filter((option) =>
				tmpFilters?.agency_list?.find((agencyId) => option?.id === agencyId)
			),
		[agenciesAccountsOptions]
	)
	return (
		<Stack spacing={4}>
			<Stack spacing={customersLoading ? 0 : 2}>
				<Typography variant='subtitle2'>
					{intlMsg('misc.intervention')}
				</Typography>

				{canDisplayCustomerFilter && (
					<FilterMultiselect
						loading={customersLoading}
						options={customersOptions}
						defaultValue={customersDefaultValue}
						label={'misc.accounts'}
						modules={[EModule.crm]}
						setFiltersQuerieParam={setTmpFilters}
						filtersQuerieParam={tmpFilters}
						argumentQuerieName='customer_list'
					/>
				)}
				<FilterStatusIntervention
					setFiltersQuerieParam={setTmpFilters}
					filtersQuerieParam={tmpFilters}
					argumentQuerieName='status_list'
				/>
			</Stack>
			{!isGuestAccount && (
				<Stack spacing={2}>
					<Typography variant='subtitle2'>
						{intlMsg('interventions.actors')}
					</Typography>
					{viewModalQuery !== 'agencyAccount' && (
						<FilterMultiselect
							loading={agenciesAccountsLoading}
							options={agenciesAccountsOptions}
							defaultValue={agenciesAccountsDefaultValue}
							label={'agencies.agencies'}
							modules={[EModule.network]}
							setFiltersQuerieParam={setTmpFilters}
							filtersQuerieParam={tmpFilters}
							argumentQuerieName='agency_list'
						/>
					)}
					<FilterMultiselect
						loading={usersLoading}
						options={actorsOptions}
						defaultValue={actorsDefaultValue}
						label={'misc.operators'}
						setFiltersQuerieParam={setTmpFilters}
						filtersQuerieParam={tmpFilters}
						argumentQuerieName='actors_list'
					/>
					<FilterSwitch
						label={'interventions.interventionWithoutActors'}
						setFiltersQuerieParam={setTmpFilters}
						filtersQuerieParam={tmpFilters}
						argumentQuerieName='isWithoutActor'
						resetIfOtherValueExist='actors_list'
					/>
				</Stack>
			)}
		</Stack>
	)
}

export default ByReferenceFiltersBtnComponent
