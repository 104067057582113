import React from 'react'

import DashboardIcon from '@mui/icons-material/Dashboard'
import DeviceHubIcon from '@mui/icons-material/DeviceHub'
import TrackChangesRoundedIcon from '@mui/icons-material/TrackChangesRounded'
import DriveEtaIcon from '@mui/icons-material/DriveEta'
import FolderSharedIcon from '@mui/icons-material/FolderShared'
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'

import { ROUTES } from '../../../../routes/consts'
import { EModule, EPermission } from '../../../../utils/app-models'

const NavItemsConfig = [
	{
		icon: <DashboardIcon />,
		label: 'misc.dashboard',
		path: ROUTES.HOME.url,
	},

	{
		icon: <DeviceHubIcon />,
		label: 'misc.network',
		modules: [EModule.network],
		path: ROUTES.NETWORKS.url,
		permissions: [EPermission.network],
	},
	{
		icon: <FolderSharedIcon />,
		label: 'misc.directory',
		modules: [EModule.crm, EModule.site],
		subnav: [
			{
				label: 'misc.accounts',
				path: ROUTES.ACCOUNTS.url,
				subModules: [EModule.crm],
			},
			{
				label: 'misc.sites',
				path: ROUTES.SITES.url,
				subModules: [EModule.site],
			},
			{
				label: 'misc.contacts',
				path: ROUTES.CONTACTS.url,
				subModules: [EModule.crm],
			},
		],
	},

	{
		icon: <TrackChangesRoundedIcon />,
		label: 'misc.missions',
		modules: [
			EModule.interventionControl,
			EModule.interventionTransport,
			EModule.interventionLogistic,
			EModule.interventionMecanic,
			EModule.planning,
		],
		subnav: [
			{
				label: 'misc.interventions',
				path: ROUTES.INTERVENTION.REFERENCES.url,
				subModules: [EModule.interventionControl],
			},
			{
				label: 'interventions.transports',
				path: ROUTES.INTERVENTION.TRANSPORTS.url,
				subModules: [EModule.interventionTransport],
			},
			{
				label: 'misc.schedule',
				path: ROUTES.INTERVENTION.CALENDAR.url,
				subModules: [EModule.planning],
			},
		],
	},

	{
		icon: <DriveEtaIcon />,
		label: 'misc.vehicles',
		subnav: [
			{
				label: 'misc.vehicles',
				path: ROUTES.VEHICLES.url,
			},
			{
				label: 'misc.defectFound',
				path: ROUTES.ALERTS.url,
				subPermissions: [EPermission.alert],
			},
			{
				label: 'misc.reportIntervention',
				path: ROUTES.INTERVENTION_FORMS.url,
			},
			{
				label: 'invoice.invoice',
				path: ROUTES.INVOICES.url,
				subPermissions: [EPermission.invoice],
			},
			{
				label: 'documentVehicle.documents',
				path: ROUTES.VEHICLES_DOCUMENTS.url,
				subPermissions: [EPermission.document],
			},
		],
	},

	{
		icon: <SupervisedUserCircleIcon />,
		label: 'adminPanel.admin',
		permissions: [EPermission.adminPanel],
		subnav: [
			{
				label: 'misc.accounts',
				path: ROUTES.ADMIN_PANEL.ACCOUNTS.url,
				subPermissions: [EPermission.adminPanel],
			},
		],
	},
]

export default NavItemsConfig
