/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { gql, useMutation } from '@apollo/client'
import { vehiclePayloadAttributes } from '../attributes/vehicleAttributes'

/**  ---------------------MUTATIONS---------------------- */

export const CREATE_VEHICLE = gql`
	mutation createVehicle($input: createVehicleInput!) {
		createVehicle(input: $input) {
			${vehiclePayloadAttributes}
		}
	}
`

export const UPDATE_VEHICLE = gql`
	mutation updateVehicle($input: updateVehicleInput!) {
		updateVehicle(input: $input) {

			${vehiclePayloadAttributes}
		}
	}
`

export const DELETE_VEHICLE = gql`
	mutation deleteVehicle($input: deleteVehicleInput!) {
		deleteVehicle(input: $input) {
			${vehiclePayloadAttributes}
		}
	}
`

export const REMOVE_VEHICLE = gql`
	mutation removeVehicle($input: removeVehicleInput!) {
		removeVehicle(input: $input) {
			${vehiclePayloadAttributes}
		}
	}
`
export const BULK_VEHICLES_ACTION = gql`
	mutation bulkOperationVehicle($input: bulkOperationVehicleInput!) {
		bulkOperationVehicle(input: $input) {
			${vehiclePayloadAttributes}
		}
	}
`

export const UPDATE_VEHICLE_LOCATION = gql`
	mutation updateLocationVehicle($input: updateLocationVehicleInput!) {
		updateLocationVehicle(input: $input) {
			vehicle {
				id
			}
		}
	}
`
export const UPDATE_VEHICLE_STATUS = gql`
	mutation updateStatusVehicle($input: updateStatusVehicleInput!) {
		updateStatusVehicle(input: $input) {
			vehicle {
				id
			}
		}
	}
`

export const W_CREATE_MILEAGE = gql`
	mutation createVehicleMileage($input: createVehicleMileageInput!) {
		createVehicleMileage(input: $input) {
			vehicleMileage {
				id
			}
		}
	}
`

export const IMPORT_VEHICLES = gql`
	mutation importVehicle($input: importVehicleInput!) {
		importVehicle(input: $input) {
			vehicle {
				id
				importErrors
			}
		}
	}
`

export const CREATE_VEHICLE_MOVEMENT = gql`
	mutation createVehicleMovement($input: createVehicleMovementInput!) {
		createVehicleMovement(input: $input) {
			vehicleMovement {
				id
			}
		}
	}
`

export const UPDATE_VEHICLE_MOVEMENT = gql`
	mutation updateVehicleMovement($input: updateVehicleMovementInput!) {
		updateVehicleMovement(input: $input) {
			vehicleMovement {
				id
			}
		}
	}
`

/**  ---------------------HOOKS---------------------- */

export const useUpdateVehicle = () => useMutation(UPDATE_VEHICLE)
export const useCreateVehicle = () => useMutation(CREATE_VEHICLE)
export const useDeleteVehicle = () => useMutation(DELETE_VEHICLE)
export const useRemoveVehicle = () => useMutation(REMOVE_VEHICLE)
export const useBulkVehicleAction = () => useMutation(BULK_VEHICLES_ACTION)
export const useUpdateVehicleLocation = (): any =>
	useMutation(UPDATE_VEHICLE_LOCATION)
export const useUpdateVehicleStatus = (): any =>
	useMutation(UPDATE_VEHICLE_STATUS)
export const useWCreateMileage = () => useMutation(W_CREATE_MILEAGE)
export const useImportVehicles = () => useMutation(IMPORT_VEHICLES)
export const useUpdateVehicleMovement = () =>
	useMutation(UPDATE_VEHICLE_MOVEMENT)
export const useCreateVehicleMovement = () =>
	useMutation(CREATE_VEHICLE_MOVEMENT)
