import { yupResolver } from '@hookform/resolvers/yup'
import React, { memo, useMemo, useState, useCallback, useContext } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import { EFormModalMode } from '../utils/app-models'
import { AuthContext } from '../utils/contexts/AuthContext'

import { FormTemplateContext } from '../utils/contexts/FormTemplateContext'
import { useFormatMessage } from '../utils/hooks'
import { formTemplateFieldsValidationSchema } from '../utils/validationSchema'
import { defaultLimitation } from '../view/administration/formTemplate/formsEditions/formEditionsUtils/fieldsLimitation'

interface IFormTemplateProvider {
	children?
	validationSchemaFunction
	formTemplateMode?
}

const FormTemplateProvider: React.FC<IFormTemplateProvider> = ({
	children,
	validationSchemaFunction,
	formTemplateMode,
}) => {
	const [loadingFormTemplate, setLoadingFormTemplate] = useState(false)
	const [canValidate, setCanValidate] = useState(true)
	const [mode, setMode] = useState(formTemplateMode || EFormModalMode.edit)
	const { checkModules } = useContext(AuthContext)
	const intlMsg = useFormatMessage()

	const formUtils = useForm({
		resolver: yupResolver(validationSchemaFunction(intlMsg)),
	})

	const resetContext = (mode) => {
		// formUtils.reset(defaultValue || modalDefaultValues)
		formUtils.reset()
		setLoadingFormTemplate(false)
		setMode(mode || formTemplateMode)
	}

	///////////////////////////////////////////////////////
	// DRAWER - Edit Fields
	///////////////////////////////////////////////////////

	type Anchor = 'top' | 'left' | 'bottom' | 'right'

	const [stateDrawer, setStateDrawer] = useState({
		right: false,
	})
	const [fieldToUpdate, setFieldToUpdate] = useState({})

	const {
		handleSubmit: handleSubmitDrawer,
		setValue: setValueDrawer,
		control: controlDrawer,
		reset: resetDrawer,
		watch: watchDrawer,
	} = useForm({
		resolver: yupResolver(formTemplateFieldsValidationSchema(intlMsg)),
	})

	// const [drawerUtils, setDrawerUtils] = useState<any>({})

	const toggleDrawer = useCallback(
		(anchor: Anchor, open: boolean, fieldToUpdate) => () => {
			setFieldToUpdate(fieldToUpdate)
			setStateDrawer({ ...stateDrawer, [anchor]: open })
			resetDrawer()
		},
		[setStateDrawer, stateDrawer]
	)

	const drawerUtils = {
		toggleDrawer,
		stateDrawer,
		setStateDrawer,
		handleSubmitDrawer,
		controlDrawer,
		fieldToUpdate,
		setValueDrawer,
		watchDrawer,
	}

	///////////////////////////////////////////////////////
	// FIELDS - utils
	///////////////////////////////////////////////////////

	const fieldUtils = (nb, control) =>
		useFieldArray({
			control,
			name: `pages[${nb}].fields`,
		})

	///////////////////////////////////////////////////////
	// FIELDS - limitation
	///////////////////////////////////////////////////////

	const [limitationFields, setLimitationFields] = useState(
		defaultLimitation(checkModules)
	)
	const [isLimitModalOpen, setIsOpenLimitModal] = useState<string>('')

	///////////////////////////////////////////////////////
	// CONTEXT - useMemo
	///////////////////////////////////////////////////////

	const context = useMemo(
		() => ({
			loadingFormTemplate,
			resetContext,
			setLoadingFormTemplate,
			formUtils,
			canValidate,
			setCanValidate,
			mode,
			setMode,
			drawerUtils,
			fieldUtils,
			limitationFields,
			setLimitationFields,
			isLimitModalOpen,
			setIsOpenLimitModal,
		}),
		[
			loadingFormTemplate,
			formUtils,
			canValidate,
			mode,
			resetContext,
			drawerUtils,
			fieldUtils,
			limitationFields,
			setLimitationFields,
			isLimitModalOpen,
		]
	)

	return (
		<FormTemplateContext.Provider value={context}>
			{children}
		</FormTemplateContext.Provider>
	)
}

export default memo(FormTemplateProvider)
