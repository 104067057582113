/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { gql, useMutation } from '@apollo/client'
import { customerPayloadAttributes } from '../attributes/customers'

/**  ---------------------MUTATIONS---------------------- */

export const UPDATE_CUSTOMER = gql`
	mutation updateCustomer($input: updateCustomerInput!) {
		updateCustomer(input: $input) {
			${customerPayloadAttributes}
		}
	}`

export const CREATE_CUSTOMER = gql`
	mutation createCustomer($input: createCustomerInput!) {
		createCustomer(input: $input) {
			${customerPayloadAttributes}
		}
	}
`

export const REMOVE_CUSTOMER = gql`
	mutation removeCustomer($input: removeCustomerInput!) {
		removeCustomer(input: $input) {
			${customerPayloadAttributes}
		} 
	}
`

export const DELETE_CUSTOMER = gql`
	mutation deleteCustomer($input: deleteCustomerInput!) {
		deleteCustomer(input: $input) {
			${customerPayloadAttributes}
		} 
	}
`

export const BULK_CUSTOMER_ACTION = gql`
	mutation bulkOperationCustomer($input: bulkOperationCustomerInput!) {
		bulkOperationCustomer(input: $input) {
			${customerPayloadAttributes}
		}
	}
`

/**  ---------------------HOOKS---------------------- */

export const useUpdateCustomer = () => useMutation(UPDATE_CUSTOMER)
export const useCreateCustomer = (options?: any) =>
	useMutation(CREATE_CUSTOMER, options)
export const useRemoveCustomer = () => useMutation(REMOVE_CUSTOMER)
export const useDeleteCustomer = () => useMutation(DELETE_CUSTOMER)
export const useBulkCustomerAction = () => useMutation(BULK_CUSTOMER_ACTION)
