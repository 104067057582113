import React, { memo } from 'react'

import { Stack, Typography } from '@mui/material'
import TableSideBarStatus from './TableSideBarStatus'
import { useFormatMessage } from '../../../../../utils/hooks'

const mainCSS = { width: 1, p: ({ spacing }) => spacing(0, 3), mb: 2, mt: 4 }

interface ITableSideBarHeader {
	disabledStatus?: boolean
}

const TableSideBarHeader: React.FC<ITableSideBarHeader> = ({
	disabledStatus = false,
}) => {
	const intlMsg = useFormatMessage()

	return (
		<Stack
			direction='row'
			alignItems='center'
			justifyContent='space-between'
			sx={mainCSS}
		>
			<Typography variant='h2'>{intlMsg('misc.categorie')}</Typography>
			{!disabledStatus && <TableSideBarStatus />}
		</Stack>
	)
}

export default memo(TableSideBarHeader)
