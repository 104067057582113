export default {
	form: 'Form',
	ref: 'Reference:',
	name: 'Name:',
	addBy: 'Filled by:',
	dateCreate: 'Created on:',
	horodatage: 'Timestamp:',
	geolocalisation: 'GPS coordinates :',
	vehicle: 'Vehicle:',
	modelBrand: 'Brand - Model:',
	vin: 'Chassis number:',

	intervention: 'Intervention',
	site: 'Site:',
	address: 'Address:',
	dateIntervention: 'Intervention date:',

	commentRatingTitle: 'Review / Comment:',
	customerRating: 'Customer review:',
	customerComment: 'Customer comment:',
	customerOperator: 'Operator comment:',

	signCustomer: 'Customer signature',
	signOperator: 'Operator signature',
	signCustomerTransport: 'Recipient signature',

	signTransportConveyer: 'Signature conveyor',
	signTransportDest: 'Recipient signature',

	noAlerts: 'NO DEFECTS DETECTED',
	alertsTitle: 'SUMMARY',

	transportStep: 'Transport stage :',
	localeDate: 'Local date and time :',
	type: 'Type :',
	transport: 'Transport',
	transportRequest: 'Transport request :',
	tentativeDate: 'Tentative date and time :',
	tentativeAddress: 'Address for information :',
	delivery: 'Delivery',
	removal: 'Removal',

	controlSkipped: 'Customer reported absent.',
	transportSkipped: 'Consignee absent',

	contact: 'Contact :',
}
