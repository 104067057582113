import React, { useContext } from 'react'

import { Box, Fade, LinearProgress, Stack } from '@mui/material'

import ViewModalMainContainer from './ViewModalMainContainer'
import { useFormatMessage } from '../../utils/hooks'
import { ViewModalContentContext } from '../../utils/contexts/ViewModalContentContext'

const loaderCSS = {
	position: 'absolute',
	width: 1,
	top: '63px',
	height: '2px',
	left: '0px',
}

const mainModalCSS = {
	flexDirection: { xs: 'column', md: 'row' },
	overflow: 'hidden',
	height: { xs: 'auto', md: 1 },
	'& .bgFormField': {
		backgroundColor: '#ebebeb',
	},
}
const modalLeftContainerCSS = {
	p: 1,
	width: { xs: 1, md: '550px' },
	height: '100%',
	overflowY: { xs: 'inherit', md: 'auto' },
	marginRight: '1rem',
	pr: 0,
	backgroundColor: '#f9f9f9',
}
const modalRightContainerCSS = {
	backgroundColor: 'white',
	width: 1,
	overflow: 'auto',

	'& .modalFadeCSS': {
		height: '100%',

		'& > div': {
			height: '100%',
		},
	},
}

interface IDialogContentLayout {}

const DialogContentLayout: React.FC<IDialogContentLayout> = () => {
	const intlMsg = useFormatMessage()
	const {
		loading,
		viewModalData,
		viewModalInfos: {
			viewModalConfig: { tabConfig, Informations, customTabConfig },
		},
	} = useContext(ViewModalContentContext)

	return (
		<Stack direction='row' sx={mainModalCSS}>
			<Stack sx={modalLeftContainerCSS}>
				{loading && <LinearProgress sx={loaderCSS} />}

				<Fade in={!!viewModalData} timeout={800}>
					<Box>{viewModalData && <Informations data={viewModalData} />}</Box>
				</Fade>
			</Stack>
			<Stack sx={modalRightContainerCSS}>
				<Fade in={!!viewModalData} timeout={800} className='modalFadeCSS'>
					<Box>
						{viewModalData && (
							<ViewModalMainContainer
								tabConfig={tabConfig}
								customTabConfig={
									customTabConfig
										? customTabConfig(viewModalData || {}, intlMsg)
										: null
								}
							/>
						)}
					</Box>
				</Fade>
			</Stack>
		</Stack>
	)
}

export default DialogContentLayout
