import React, { useContext, useMemo } from 'react'

import Drawer from '@mui/material/Drawer'
import { Stack } from '@mui/material'

import { LogoMain } from '../../..'
import NavItems from './NavItems'
import { AuthContext } from '../../../../utils/contexts/AuthContext'
import NavItemsClose from './NavItemsClose'

const drawerCSS = {
	'& .MuiDrawer-paper': {
		backgroundColor: '#132142',
		width: 60,
		border: 'none',
		overflowX: 'hidden',
		overflowY: 'auto',
		'&::-webkit-scrollbar': { display: 'none !important' },
		transition: 'width 0.3s',
	},
}

const drawerOpenCSS = {
	'& .MuiDrawer-paper': {
		width: 225,
		transition: 'width 0.3s',
	},
}

interface INavLeftSidebar {}

const NavLeftSidebar: React.FC<INavLeftSidebar> = () => {
	const { navIsOpen } = useContext(AuthContext)

	const navItems = useMemo(
		() => (navIsOpen ? <NavItems /> : <NavItemsClose key='NavItemsClose' />),
		[navIsOpen]
	)

	return (
		<Drawer
			variant='permanent'
			anchor='left'
			sx={[drawerCSS, navIsOpen && drawerOpenCSS]}
			open={navIsOpen}
		>
			<Stack
				direction='row'
				alignItems={'center'}
				justifyContent='center'
				sx={{ minHeight: '68px' }}
			>
				<LogoMain width={navIsOpen ? 177 : 25} complete={navIsOpen} />
			</Stack>

			{navItems}
		</Drawer>
	)
}

export default NavLeftSidebar
