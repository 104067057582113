import React from 'react'
import { Stack } from '@mui/material'
import AgencyInformation from './AgencyInformation'

interface IAgencyInformations {
	data
}

const AgencyInformations: React.FC<IAgencyInformations> = ({ data }) => {
	return (
		<Stack p={2}>
			<AgencyInformation
				customName={data?.agencyCustomDetails[0]?.agencyName}
				socialName={data?.name}
				email={data?.adminEmail}
				phone={data?.adminPhone}
				cellphone={data?.adminCellphone}
				city={data?.city}
				address={data?.address}
				zipcode={data?.zipcode}
				networks={data?.networks?.collection}
				adminFirstName={data?.adminFirstName}
				adminLastName={data?.adminLastName}
				siret={data?.siret}
			/>
		</Stack>
	)
}

export default AgencyInformations
