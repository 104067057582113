import React, { memo } from 'react'

import { Box } from '@mui/material'

import { TabPanel } from '../../../components'
interface ISlidePanelUserInfosTabPanel {
	tab
	valueTab: number
}
const SlidePanelUserInfosTabPanel: React.FC<ISlidePanelUserInfosTabPanel> = ({
	tab,
	valueTab,
}) => {
	return (
		<Box sx={{ p: 4 }}>
			<TabPanel value={valueTab} index={valueTab}>
				{tab[valueTab]?.component}
			</TabPanel>
		</Box>
	)
}

export default memo(SlidePanelUserInfosTabPanel)
