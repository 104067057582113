import React, { useContext, useMemo } from 'react'

import { Button, Badge } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'

import { useFormatMessage } from '../../../utils/hooks'
import FilterPopover from './FilterPopover'
import { FiltersBtnContext } from './FiltersBtnContext'

const badgeCSS = {
	'& .MuiBadge-badge': {
		border: ({ palette }) => `3px solid ${palette.background.paper}`,
		padding: '5px 5px',
		fontSize: '10px',
		right: '12px',
	},
}

interface IFiltersBtnComponent {}

const FiltersBtnComponent: React.FC<IFiltersBtnComponent> = () => {
	const intlMsg = useFormatMessage()

	const { handleOpen, activeFiltersNumber, anchorEl } =
		useContext(FiltersBtnContext)

	const open = useMemo(() => Boolean(anchorEl), [anchorEl])

	const id = useMemo(() => (open ? 'filter-popover' : undefined), [open])

	return (
		<>
			<Badge badgeContent={activeFiltersNumber} color='error' sx={badgeCSS}>
				<Button
					variant='outlined'
					startIcon={<FilterListIcon />}
					onClick={handleOpen}
					sx={{ marginRight: '8px' }}
				>
					{intlMsg('misc.filter')}
				</Button>
			</Badge>

			{open && <FilterPopover id={id} anchorEl={anchorEl} open={open} />}
		</>
	)
}

export default FiltersBtnComponent
