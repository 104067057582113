import React, { useMemo } from 'react'

import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Grid from '@mui/material/Grid'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'

import { useFormatMessage } from '../../utils/hooks'
import { Stack, Button, Box } from '@mui/material'

// CSS Constants
const confirmModalStyle = {
	imageCSS: {
		width: '170px',
	},
	dialogContentCSS: {
		p: 4,
	},
	checkboxStackCSS: {
		direction: 'row',
		alignItems: 'center',
		spacing: 1,
	},
}

interface IConfirmModal {
	onClose: () => void
	open: boolean
	handleConfirm: () => void
	message?: { title?: string; message?: string; messageIsHtml?: boolean } | null
	img?: string
	checkbox?: { message?: string; action?: any }
	cantValidate?: boolean
	playwrightIdConfirmBtn?: string
	validationMessage?: string
	cancelMessage?: string
}

const ConfirmModal: React.FC<IConfirmModal> = ({
	onClose,
	open,
	handleConfirm,
	message,
	img,
	checkbox,
	cantValidate = false,
	playwrightIdConfirmBtn = '',
	validationMessage = null,
	cancelMessage = null,
}) => {
	const intlMsg = useFormatMessage()

	const memoizedContent = useMemo(() => {
		return (
			<DialogContent dividers sx={confirmModalStyle.dialogContentCSS}>
				<Grid container spacing={8}>
					{img && (
						<Grid item xs={4}>
							<img
								src={img}
								style={confirmModalStyle.imageCSS}
								alt='Confirmation'
							/>
						</Grid>
					)}
					<Grid item xs={img ? 8 : 12}>
						<Stack spacing={1} sx={{ height: 1 }} justifyContent='center'>
							<Stack spacing={1}>
								{message?.title && (
									<Typography variant='h2' gutterBottom>
										{intlMsg(message?.title)}
									</Typography>
								)}
								{message?.message &&
									(!message?.messageIsHtml ? (
										<Typography variant='body2' gutterBottom>
											{intlMsg(message?.message)}
										</Typography>
									) : (
										<Typography
											variant='body2'
											gutterBottom
											dangerouslySetInnerHTML={{
												__html: intlMsg(message?.message),
											}}
										/>
									))}
							</Stack>

							{checkbox && (
								<Stack sx={confirmModalStyle.checkboxStackCSS}>
									<Checkbox color='primary' onChange={checkbox?.action} />
									<Box component='span'>{intlMsg(checkbox?.message)}</Box>
								</Stack>
							)}
						</Stack>
					</Grid>
				</Grid>
			</DialogContent>
		)
	}, [img, message, checkbox])

	const memoizedCancelText = useMemo(
		() => (cancelMessage ? intlMsg(cancelMessage) : intlMsg('misc.cancel')),
		[cancelMessage]
	)

	const memoizedValidateText = useMemo(
		() =>
			validationMessage ? intlMsg(validationMessage) : intlMsg('misc.validate'),
		[validationMessage]
	)

	return (
		<Dialog
			onClose={onClose}
			aria-labelledby='simple-dialog-title'
			open={open}
			sx={{ p: 2 }}
		>
			{memoizedContent}
			<DialogActions>
				<Stack spacing={1} direction='row'>
					<Button variant='outlined' color='primary' onClick={onClose}>
						{memoizedCancelText}
					</Button>
					<Button
						color='primary'
						variant='contained'
						onClick={handleConfirm}
						disabled={cantValidate}
						id={playwrightIdConfirmBtn}
					>
						{memoizedValidateText}
					</Button>
				</Stack>
			</DialogActions>
		</Dialog>
	)
}

export default ConfirmModal
