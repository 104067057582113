/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
	gql,
	QueryHookOptions,
	useLazyQuery,
	OperationVariables,
	useQuery,
} from '@apollo/client'

export const GET_CATEGORIES_LIST = gql`
	query getProductCategories(
		$page: Int
		$itemsPerPage: Int
		$isActive: Boolean
		$module: String
		$sharedToAgencies: String
	) {
		productCategories(
			page: $page
			itemsPerPage: $itemsPerPage
			isActive: $isActive
			module: $module
			sharedToAgencies: $sharedToAgencies
		) {
			collection {
				id
				name
				color
				isActive
			}
		}
	}
`

export const GET_CATEGORIES_LIST_FOR_AGENCY_STEP = gql`
	query getCategoriesListForAgencyStep(
		$page: Int
		$itemsPerPage: Int
		$sharedToAgencies: String
		$searchText: String
		$orderBy: [ProductCategoryFilter_orderBy]
	) {
		productCategories(
			page: $page
			itemsPerPage: $itemsPerPage
			sharedToAgencies: $sharedToAgencies
			searchText: $searchText
			orderBy: $orderBy
		) {
			collection {
				id
				name
				color
				products(itemsPerPage: 100000) {
					collection {
						id
						name
					}
				}
			}
			paginationInfo {
				totalCount
			}
		}
	}
`

export const GET_PRODUCTS_LIST = gql`
	query getProducts(
		$page: Int
		$itemsPerPage: Int
		$isActive: Boolean
		$productCategory: String
		$orderBy: [ProductFilter_orderBy]
		$searchText: String
	) {
		products(
			page: $page
			itemsPerPage: $itemsPerPage
			isActive: $isActive
			productCategory: $productCategory
			orderBy: $orderBy
			searchText: $searchText
		) {
			collection {
				id
				name
				reference
				commercialReference
				productCategory {
					id
					name
				}
				isActive
				rate
				vat
				unitOfWork
				duration
				idERP
				description
				productBase {
					id
					name
					type
				}
				customers {
					collection {
						id
						name
					}
				}
				dateContractFrom
				dateContractTo
			}
			paginationInfo {
				totalCount
			}
		}
	}
`
export const GET_CATEGORIES_AND_PRODUCTS = gql`
	query getCategoriesAndProducts($module: String, $customer: String) {
		productCategories(
			page: 1
			itemsPerPage: 10000
			isActive: true
			module: $module
			ownedAndShared: true
			customer: $customer
		) {
			collection {
				id
				name
				products(itemsPerPage: 100000, customer: $customer) {
					collection {
						id
						name
					}
				}
			}
		}
	}
`

export const COUNT_CATEGORIES_AND_PRODUCTS = gql`
	query countProductCategories($sharedToAgencies: String) {
		productCategories(
			page: 1
			itemsPerPage: 100000
			sharedToAgencies: $sharedToAgencies
		) {
			paginationInfo {
				totalCount
			}
		}
	}
`

/////////////////////////////////////////////////////////
// CATALOGUE-PRODUCT - PRODUCT
/////////////////////////////////////////////////////////

export const EXPORT_PRODUCTS = gql`
	query exportProducts(
		$searchText: String
		$lastUpdate: String
		$account: String
		$account_list: [String]
		$productCategory: String
		$productCategory_list: [String]
		$customer: String
		$productBase: String
		$name: String
		$active: String
		$orderBy: [ProductFilter_orderBy]
		$isActive: Boolean
		$timezone: Int
	) {
		exportProducts(
			searchText: $searchText
			lastUpdate: $lastUpdate
			account: $account
			account_list: $account_list
			productCategory: $productCategory
			productCategory_list: $productCategory_list
			customer: $customer
			productBase: $productBase
			name: $name
			active: $active
			orderBy: $orderBy
			isActive: $isActive
			timezone: $timezone
		) {
			id
			exportFile
		}
	}
`

export const GET_PRODUCT_CATEGORY = gql`
	query getProductCategory($id: ID!) {
		productCategory(id: $id) {
			id
			name
			color
			isActive
			description
		}
	}
`
export const GET_PRODUCT_CATEGORIES = gql`
	query getProductCategories(
		$isActive: Boolean
		$module: String
		$sharedToAgencies: String
	) {
		productCategories(
			page: 1
			itemsPerPage: 10000
			isActive: $isActive
			module: $module
			sharedToAgencies: $sharedToAgencies
		) {
			collection {
				id
				name
				color
				description
				productsActive: products(isActive: true) {
					paginationInfo {
						totalCount
					}
				}
				productsInactive: products(isActive: false) {
					paginationInfo {
						totalCount
					}
				}
			}
		}
	}
`

export const GET_PRODUCT = gql`
	query getProduct($id: ID!) {
		product(id: $id) {
			id
			name
			reference
			commercialReference
			productCategory {
				id
				name
			}
			isActive
			rate
			vat
			unitOfWork
			duration
			idERP
			description
			productBase {
				id
				name
				type
				unitOfWork
				rate
				duration
				productBaseCategory {
					id
					name
				}
			}
			customers {
				collection {
					id
					name
				}
			}
			dateContractFrom
			dateContractTo
		}
	}
`

/////////////////////////////////////////////////////////
// CATALOGUE-PRODUCT - PRODUCT BASE
/////////////////////////////////////////////////////////

export const EXPORT_PRODUCT_BASES = gql`
	query exportProductBases(
		$lastUpdate: String
		$searchText: String
		$account: String
		$account_list: [String]
		$productBaseCategory: String
		$productBaseCategory_list: [String]
		$name: String
		$active: String
		$orderBy: [ProductBaseFilter_orderBy]
		$isActive: Boolean
		$timezone: Int
	) {
		exportProductBases(
			lastUpdate: $lastUpdate
			searchText: $searchText
			account: $account
			account_list: $account_list
			productBaseCategory: $productBaseCategory
			productBaseCategory_list: $productBaseCategory_list
			name: $name
			active: $active
			orderBy: $orderBy
			isActive: $isActive
			timezone: $timezone
		) {
			id
			exportFile
		}
	}
`

export const GET_PRODUCT_BASE_CATEGORY = gql`
	query getProductBaseCategory($id: ID!) {
		productBaseCategory(id: $id) {
			id
			name
			color
			isActive
			description
		}
	}
`

export const GET_PRODUCT_BASE_CATEGORIES = gql`
	query getProductBaseCategories($isActive: Boolean, $module: String) {
		productBaseCategories(
			page: 1
			itemsPerPage: 10000
			isActive: $isActive
			module: $module
		) {
			collection {
				id
				name
				color
				description
				productBasesActive: productBases(isActive: true) {
					paginationInfo {
						totalCount
					}
				}
				productBasesInactive: productBases(isActive: false) {
					paginationInfo {
						totalCount
					}
				}
			}
		}
	}
`
export const GET_PRODUCT_BASE_CATEGORIES_FOR_SELECT = gql`
	query getProductBaseCategoriesForSelect($isActive: Boolean, $module: String) {
		productBaseCategories(
			page: 1
			itemsPerPage: 10000
			isActive: $isActive
			module: $module
		) {
			collection {
				id
				name
				color
				description
				productBases {
					collection {
						id
						name
						type
						unitOfWork
						rate
						duration
					}
				}
			}
		}
	}
`

export const GET_PRODUCT_BASE = gql`
	query getProductBase($id: ID!) {
		productBase(id: $id) {
			id
			name
			type
			rate
			unitOfWork
			duration
			description
			idERP
			isActive
			productBaseCategory {
				id
				name
			}
		}
	}
`
export const GET_PRODUCT_BASE_DRAWER = gql`
	query getProductBaseDrawer($id: ID!) {
		productBase(id: $id) {
			id
			name
			type
			rate
			unitOfWork
			duration
			description
			products {
				collection {
					id
					name
					rate
					isActive
					customers {
						collection {
							id
							name
						}
					}
				}
			}
		}
	}
`

export const GET_PRODUCT_BASES_LIST = gql`
	query getProductBasesList(
		$page: Int
		$itemsPerPage: Int
		$isActive: Boolean
		$productBaseCategory: String
		$orderBy: [ProductBaseFilter_orderBy]
		$searchText: String
	) {
		productBases(
			page: $page
			itemsPerPage: $itemsPerPage
			isActive: $isActive
			productBaseCategory: $productBaseCategory
			orderBy: $orderBy
			searchText: $searchText
		) {
			collection {
				id
				name
				reference
				type
				rate
				unitOfWork
				duration
				description
				idERP
				isActive
				products {
					paginationInfo {
						totalCount
					}
					collection {
						id
						isActive
					}
				}
			}
			paginationInfo {
				totalCount
			}
		}
	}
`

/////////////////////////////////////////////////////////
// HOOKS
/////////////////////////////////////////////////////////

export const useLazyProductsAndCategoriesList = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(
		GET_CATEGORIES_AND_PRODUCTS,
		options
	)
	return {
		loading,
		data: data?.productCategories?.collection || null,
		getProductCategories: getData,
	}
}

export const useProductsAndCategoriesList = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { loading, data } = useQuery(GET_CATEGORIES_AND_PRODUCTS, options)
	return {
		loading,
		productCategories: data?.productCategories?.collection || null,
	}
}

export const useLazyCategoryFormModal = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(
		GET_PRODUCT_CATEGORY,
		options
	)
	return {
		loading,
		data: data || null,
		getProductCategory: getData,
	}
}

export const useLazyProductFormModal = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(GET_PRODUCT, options)
	return {
		loading,
		data: data || null,
		getProduct: getData,
	}
}

export const useLazyCategoryBaseFormModal = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(
		GET_PRODUCT_BASE_CATEGORY,
		options
	)
	return {
		loading,
		data: data || null,
		productBaseCategory: getData,
	}
}

export const useLazyProductBaseFormModal = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(GET_PRODUCT_BASE, options)
	return {
		loading,
		data: data || null,
		getProductBase: getData,
	}
}

export const useExportProducts: any = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { data, loading, error } = useQuery(EXPORT_PRODUCTS, options)

	return {
		exportFile: data?.exportProducts?.[0]?.exportFile || null,
		loading,
		error,
	}
}

export const useExportProductBases: any = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { data, loading, error } = useQuery(EXPORT_PRODUCT_BASES, options)

	return {
		exportFile: data?.exportProductBases?.[0]?.exportFile || null,
		loading,
		error,
	}
}
