/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'

import {
	ITableToolBarActionConfig,
	ITableToolBarConfig,
} from '../../../utils/typescript/tableType'
import { interventionFormsDefaultFilterConfigValues } from './interventionFormsFilters/interventionFormsDefaultFilterConfig'
import {
	LIST_FORM_INTERVENTIONS,
	useExportForms,
} from '../../../api/gql/queries/interventionFormsQueries'
import { useBulkInterventionFormsAction } from '../../../api/gql/mutations/interventionFormsMutation'
import listInterventionControlFormsViewTable from '../../../components/tableV2/config/dashboard/interventionForms/listInterventionControlFormsViewTable'
import { EPermissionScope } from '../../../utils/app-models'
import InterventionFormsFilters from './interventionFormsFilters'

////////////////////////////////////////////////////////////////
// Global variable
////////////////////////////////////////////////////////////////

const localStorageKeyFilter = 'interventionFormsFilter'

const queryConfig = {
	query: LIST_FORM_INTERVENTIONS,
	queryName: 'forms',
}

////////////////////////////////////////////////////////////////
// tableToolBarAction CONFIG
////////////////////////////////////////////////////////////////

const refreshQuerie = ['getListFormInterventions']

const deletedBulk = {
	mutation: useBulkInterventionFormsAction,
	operation: 'remove',
	successMessageDelete: {
		singular: 'success.interventionFormDeleted',
		plural: 'success.interventionsFormDeleted',
	},
	messageConfirm: {
		title: 'confirmModal.deleteInterventionFormTitle',
		message: 'confirmModal.deleteInterventionFormMessage',
	},
	deletePermissions: [EPermissionScope.formDelete, EPermissionScope.formRemove],
}

const tableToolBarActionConfig: ITableToolBarActionConfig = {
	refreshQuerie,
	deletedBulk,
}

////////////////////////////////////////////////////////////////
// TableToolBarHeader CONFIG
////////////////////////////////////////////////////////////////

const tableToolBarConfig: ITableToolBarConfig = {
	title: 'interventionForms.reports',
	stickyToolBar: true,
	tableConfig: listInterventionControlFormsViewTable,
	localStorageKeyFilter,
	localStorageName: 'listInterventionFormsViewTableFilters',
	exportConfig: { exportQuery: useExportForms },
	queryConfig,
}

////////////////////////////////////////////////////////////////
// tableContainer CONFIG
////////////////////////////////////////////////////////////////

const tableContainerConfig = {
	tableConfig: listInterventionControlFormsViewTable,
	queryConfig,
}

////////////////////////////////////////////////////////////////
// -> EXPORT CONFIG MAIN TABLE
////////////////////////////////////////////////////////////////

const defaultFiltersConfig = {
	componentsFiltersConfig: <InterventionFormsFilters />,
	defaultFiltersValues: interventionFormsDefaultFilterConfigValues,
	localStorageKeyFilter,
}

export const interventionFormsConfigTable = {
	tableToolBarConfig,
	tableToolBarActionConfig,
	tableContainerConfig,
	defaultFiltersConfig,
}
