import React, { useContext } from 'react'
import FiltersBtnProvider from '../../../../components/filters/filtersBtn'
import InterventionFormsFiltersBtnComponent from './InterventionFormsFiltersBtnComponent'
import { Box } from '@mui/material'
import FilterDate from '../../../../components/filters/filtersComponents/filterDate'
import { FiltersContext } from '../../../../components/filters/filtersContext'

interface IInterventionFormsFilters {}

const ByReferenceFiltersSX = {
	display: 'flex',
}

const InterventionFormsFilters: React.FC<IInterventionFormsFilters> = () => {
	const { setFilters, filters } = useContext(FiltersContext)

	return (
		<Box sx={ByReferenceFiltersSX}>
			<FilterDate
				setFiltersQuerieParam={setFilters}
				filtersQuerieParam={filters}
				argumentQuerieName='startFrom'
			/>
			<FiltersBtnProvider>
				<InterventionFormsFiltersBtnComponent />
			</FiltersBtnProvider>
		</Box>
	)
}

export default InterventionFormsFilters
