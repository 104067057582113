import React, { useRef } from 'react'

import { Stack } from '@mui/material'

import WTransportProducts from './WTransportProducts'
import WTransportForms from './WTransportForms'
import { useResizeHeightStyle } from '../../../../utils/hooks'

interface IWTransportTripSummaryFormsProducts {
	itTrip
	maxHeightUtils: {
		maxHeight: any
		setMaxHeight: any
	}
}

const WTransportTripSummaryFormsProducts: React.FC<
	IWTransportTripSummaryFormsProducts
> = ({ maxHeightUtils, itTrip }) => {
	// Calculated & resize height
	const resizeRef = useRef<HTMLUListElement>(null)
	const componentStyle = useResizeHeightStyle(resizeRef, maxHeightUtils, [
		itTrip,
	])

	return (
		<Stack
			direction='row'
			spacing={1}
			p={2}
			style={componentStyle}
			ref={resizeRef}
		>
			<WTransportForms itTrip={itTrip} nbFormsToDisplay={5} />
			<WTransportProducts itTrip={itTrip} nbProductsToDisplay={5} />
		</Stack>
	)
}

export default WTransportTripSummaryFormsProducts
