/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import { Navigate } from 'react-router-dom'

const ConditionalRoute = ({
	condition = true,
	params = {},
	component: Comp,
	redirect = '/',
}) => {
	if (condition !== false) {
		return <Comp {...params} />
	}

	return <Navigate to={redirect} />
}

export default ConditionalRoute
