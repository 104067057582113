/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'

import { ALERT_COUNT_BY_VEHICLE } from '../../../api/gql/queries/alertsQueries'
import {
	VEHICLE_FORM_PHOTOS_COUNT,
	INTERVENTION_FORMS_COUNT_BY_VEHICLE,
} from '../../../api/gql/queries/interventionFormsQueries'
import { INTERVENTION_COUNT_BY_VEHICLE } from '../../../api/gql/queries/interventionsQueries'
import { INVOICES_COUNT_BY_VEHICLE } from '../../../api/gql/queries/invoicesQueries'
import vehiclesListAlertsViewModalTable from '../../../components/tableV2/config/dashboard/alerts/vehiclesListAlertsViewModalTable'
import vehiclesListInterventionControlFormsViewModalTable from '../../../components/tableV2/config/dashboard/interventionForms/vehiclesListInterventionControlFormsViewModalTable'
import vehicleListInterventionsByReferenceViewModalTable from '../../../components/tableV2/config/dashboard/interventions/byReference/vehicleListInterventionsControlByReferenceViewModalTable'
import vehicleListInvoicesViewTable from '../../../components/tableV2/config/dashboard/invoices/vehiclelistInvoicesViewTable'
import { EModule, EPeriodFilter } from '../../../utils/app-models'

import Alerts from '../../dashboard/alerts'
import Invoices from '../../dashboard/invoice'
import PhotosGroupedByForm from '../customTabs/interventionFormsPhotos/photosGroupedByForm'
import VehicleDashboard from '../dashBoardComponents/vehicleDashboard'
import VehiclesDocuments from '../../dashboard/vehiclesDocuments'
import { DOCUMENTS_VEHICLES_COUNT_BY_VEHICLE } from '../../../api/gql/queries/documentsVehiclesQueries'
import listDocumentsVehiclesViewModalTable from '../../../components/tableV2/config/dashboard/documentsVehicles/listDocumentsVehiclesViewModalTable'
import InterventionsByVehicle from '../../dashboard/interventions/interventionControl/byVehicle'
import InterventionControlForms from '../../dashboard/interventionForms'
import vehicleListInterventionsTransportViewModalTable from '../../../components/tableV2/config/dashboard/interventionsTransport/vehicleListInterventionsTransportViewModalTable'
import { INTERVENTION_TRANSPORT_COUNT_BY_VEHICLE } from '../../../api/gql/queries/interventionsTransportQueries'
import IpvTransport from '../../../components/tableV2/_isolatedTables/ipvTransport'

// ////////////////////////////////////////////////////////////////////////////////////////////////////////
// VEHICLE TAB CONFIG
// ////////////////////////////////////////////////////////////////////////////////////////////////////////

export const vehicleTabsConfig = [
	{
		label: 'misc.dashboard',
		component: <VehicleDashboard />,
	},
	{
		label: 'misc.interventions',
		query: INTERVENTION_COUNT_BY_VEHICLE,
		argumentQueryCount: 'vehicle',
		queryName: 'interventionProgrammedVehicles',
		component: (
			<InterventionsByVehicle
				overrideConfigTable={{
					title: 'misc.interventions',
					tableConfig: vehicleListInterventionsByReferenceViewModalTable,
					argumentQueryViewModal: 'vehicle',
					elevationNbr: 0,
					hidden: { tableNavTab: true },
					localStorageName:
						'vehicleListInterventionsByReferenceViewModalTableFilters',
					tableContainer: { height: '56vh' },
				}}
				overrideTableProvider={{
					customLocalStorageKeyFilter:
						'vehicleListInterventionsByReferenceViewModalFilters',
					customDefaultFilterConfig: {
						period: EPeriodFilter.allPeriod,
					},
				}}
				isInViewModal
			/>
		),
	},

	{
		label: 'interventions.transports',
		query: INTERVENTION_TRANSPORT_COUNT_BY_VEHICLE,
		argumentQueryCount: 'vehicle',
		queryName: 'interventionProgrammedVehicles',
		modules: [EModule.interventionTransport],
		component: (
			<IpvTransport
				overrideConfigTable={{
					tableConfig: vehicleListInterventionsTransportViewModalTable,
					argumentQueryViewModal: 'vehicle',
					elevationNbr: 0,
					hidden: { tableNavTab: true },
					localStorageName:
						'vehicleListInterventionsTransportViewModalTableFilters',
					tableContainer: { height: '56vh' },
				}}
				overrideTableProvider={{
					customLocalStorageKeyFilter:
						'vehicleListInterventionsTransportViewModalFilters',
					customDefaultFilterConfig: {
						period: EPeriodFilter.allPeriod,
					},
				}}
				isInViewModal
			/>
		),
	},

	{
		label: 'misc.alerts',
		query: ALERT_COUNT_BY_VEHICLE,
		argumentQueryCount: 'vehicle',
		queryName: 'alerts',
		component: (
			<Alerts
				overrideConfigTable={{
					tableConfig: vehiclesListAlertsViewModalTable,
					argumentQueryViewModal: 'vehicle',
					elevationNbr: 0,
					localStorageName: 'vehiclesListAlertsViewModalTableFilters',
					tableContainer: { height: '56vh' },
				}}
				overrideTableProvider={{
					customLocalStorageKeyFilter: 'vehiclesListAlertsViewModalFilters',
					customDefaultFilterConfig: {
						period: EPeriodFilter.allPeriod,
					},
				}}
				isInViewModal
			/>
		),
	},

	{
		label: 'misc.photos',
		query: VEHICLE_FORM_PHOTOS_COUNT,
		argumentQueryCount: 'vehicle',
		queryName: 'picturedForms',
		component: (
			<PhotosGroupedByForm displayInterventionRef queryPropertyName='vehicle' />
		),
	},

	{
		label: 'interventionForms.reports',
		query: INTERVENTION_FORMS_COUNT_BY_VEHICLE,
		argumentQueryCount: 'vehicle',
		queryName: 'forms',
		component: (
			<InterventionControlForms
				overrideConfigTable={{
					tableConfig: vehiclesListInterventionControlFormsViewModalTable,
					argumentQueryViewModal: 'vehicle',
					elevationNbr: 0,
					localStorageName:
						'vehiclesListInterventionFormsViewModalTableFilters',
					tableContainer: { height: '56vh' },
				}}
				overrideTableProvider={{
					customLocalStorageKeyFilter:
						'vehiclesListInterventionFormsViewModalFilters',
					customDefaultFilterConfig: {
						period: EPeriodFilter.allPeriod,
					},
				}}
				isInViewModal
			/>
		),
	},

	{
		label: 'invoice.invoice',
		query: INVOICES_COUNT_BY_VEHICLE,
		argumentQueryCount: 'vehicle',
		queryName: 'invoices',
		component: (
			<Invoices
				overrideConfigTable={{
					tableConfig: vehicleListInvoicesViewTable,
					argumentQueryViewModal: 'vehicle',
					elevationNbr: 0,
					localStorageName: 'vehiclesListInvoicesViewModalTableFilters',
				}}
				overrideTableProvider={{
					customLocalStorageKeyFilter: 'vehiclesListInvoicesViewModalFilters',
					customDefaultFilterConfig: {
						period: EPeriodFilter.allPeriod,
					},
				}}
				isInViewModal
			/>
		),
	},

	{
		label: 'documentVehicle.documents',
		query: DOCUMENTS_VEHICLES_COUNT_BY_VEHICLE,
		argumentQueryCount: 'vehicle',
		queryName: 'documents',
		component: (
			<VehiclesDocuments
				overrideConfigTable={{
					tableConfig: listDocumentsVehiclesViewModalTable,
					argumentQueryViewModal: 'vehicle',
					elevationNbr: 0,
					localStorageName:
						'vehiclesListDocumentsVehiclesViewModalTableFilters',
				}}
				overrideTableProvider={{
					customLocalStorageKeyFilter:
						'vehiclesListDocumentsVehiclesViewModalFilters',
					customDefaultFilterConfig: {
						period: EPeriodFilter.allPeriod,
					},
				}}
				isInViewModal
			/>
		),
	},
]
