import React, { useMemo } from 'react'

import { Box } from '@mui/material'
import ModalAnnotationButton from '../Picture/ModalAnnotationButton'

const containerPhotos = {
	flexWrap: 'wrap',
	'& > *': { mb: 1, mr: 1 },
} as const

interface IDocument {
	fieldTemplate?
	record
}

const DocumentField: React.FC<IDocument> = ({ record, fieldTemplate }) => {
	const attributeTemplateParse = useMemo(
		() => JSON.parse(fieldTemplate.attributes[0]),
		[fieldTemplate]
	)

	const values = useMemo(
		() => (record?.value?.length ? record?.value : []),
		[record]
	)

	return (
		<Box display='flex' pt={2}>
			<Box display='flex' sx={containerPhotos}>
				{attributeTemplateParse?.documentList?.map(({ name }, i) => {
					return (
						<ModalAnnotationButton
							name={name}
							values={values[i]}
							key={i}
							isDocument
						/>
					)
				})}
			</Box>
		</Box>
	)
}

export default DocumentField
