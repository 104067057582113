import React from 'react'
import { Box, Stack } from '@mui/material'

export const formatRequestedVehicleData = (requestedVehicleData) => {
	return [
		{
			id: requestedVehicleData?.id,
			immat: requestedVehicleData?.vehicleDetail?.immat || '',
			vin: requestedVehicleData?.vehicleDetail?.vin || '',
			vehicleBrand:
				requestedVehicleData?.vehicleDetail?.vehicleBrand?.name || '',
			vehicleModelText:
				requestedVehicleData?.vehicleDetail?.vehicleModelText || '',
			vehicleCarburant:
				requestedVehicleData?.vehicleDetail?.vehicleCarburant || '',
		},
	]
}

export const formatListOptionsForSelectVEHICLES = (
	vehicles,
	setSelectElementsVehicle
) => {
	const formatListVehiclesForInput =
		vehicles?.map(
			({ vehicleDetail: { immat, vin, vehicleBrand, vehicleModelText } }) => ({
				key: immat || vin,

				label: (
					<Stack direction='row' spacing={2} width='50%'>
						<Box width='20%'>{immat || '-'}</Box>
						<Box width='25%'>{vin || '-'}</Box>
						<Box width='25%'>{vehicleBrand?.name || '-'}</Box>
						<Box width='25%'>{vehicleModelText || '-'}</Box>
					</Stack>
				),
				labelInputText: `${immat || ''} ${vin || ''} ${
					vehicleBrand?.name || ''
				} ${vehicleModelText || ''}`,
				value: immat || vin,
			})
		) || []

	setSelectElementsVehicle(formatListVehiclesForInput || [])
}
