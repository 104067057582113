import React, { memo, useCallback, useContext, useMemo } from 'react'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'

import isEmpty from 'lodash.isempty'

import { FormModalContext } from '../../../FormModalContext'
import { formModalTabCSS, formModalTabsCSS } from '../../../../modalSxCss'
import FormModalTabsLabel from './FormModalTabsLabel'

const formModalTabsSx = {
	tabs: {
		...formModalTabsCSS,
		'& .MuiTabs-scroller': { ml: 2 },
		mt: -2,
		backgroundColor: ({ palette }) => palette.grey[100],
	},
	avatarCSS: {
		bgcolor: '#F10808',
		color: 'white !important',
		width: '17px',
		height: '17px',
		fontSize: ({ typography }) => typography.pxToRem(14),
		backgroundColor: 'red!important',
	},
}

interface IFormModalTabs {}

const FormModalTabs: React.FC<IFormModalTabs> = () => {
	const {
		tabCounter,
		errors,
		selectedTab,
		setSelectedTab,
		triggerError,
		formModalConfig: { formModalTabConfig },
	} = useContext(FormModalContext)

	//todo try to optimize error detection, we enter in this useMemo too many times
	const isTabInErrors: boolean[] = useMemo(() => {
		const res = formModalTabConfig.map(() => false)
		if (!isEmpty(errors)) {
			formModalTabConfig.forEach(({ schema }, i) => {
				if (schema) {
					Object.keys(schema.fields).forEach((field) => {
						if (Object.keys(errors).find((errorName) => errorName === field))
							res[i] = true
					})
				}
			})
		}
		return res
	}, [errors])

	const handleSelectPanel = useCallback(
		(i) => async () => {
			if (formModalTabConfig[0]?.validationOnSwitch) {
				const isInError = await triggerError()
				if (!isInError) setSelectedTab(i)
			} else setSelectedTab(i)
		},
		[errors]
	)

	return (
		<Box sx={{ width: 1 }}>
			<Tabs
				value={selectedTab}
				indicatorColor='primary'
				textColor='primary'
				variant='scrollable'
				scrollButtons='auto'
				sx={formModalTabsSx.tabs}
				TabIndicatorProps={{ children: <span /> }}
			>
				{formModalTabConfig?.map(({ tabName, selectorTabCounter }, i) => {
					const isInError = isTabInErrors[i]
					const isSelected = selectedTab === i

					return (
						<Tab
							key={`${tabName}-${i}`}
							label={
								<>
									<FormModalTabsLabel
										label={tabName}
										count={tabCounter?.[selectorTabCounter || '']}
										isInError={isInError}
										isSelected={isSelected}
									/>
									{isInError && (
										<Avatar sx={formModalTabsSx.avatarCSS}>!</Avatar>
									)}
								</>
							}
							onClick={handleSelectPanel(i)}
							sx={formModalTabCSS({
								isSelected,
							})}
							disableRipple
						/>
					)
				})}
			</Tabs>
		</Box>
	)
}

export default memo(FormModalTabs)
