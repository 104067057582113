import React, {
	memo,
	useMemo,
	useState,
	createContext,
	useCallback,
	useContext,
	ReactNode,
} from 'react'

import { useQuery } from '@apollo/client'
import { useLocation } from 'react-router-dom'

import {
	IMultiTableNavConfig,
	ITableSideNav,
	ITableView,
} from '../../utils/typescript/multiNavTableType'

///////////////////////////////////////////////////////////////////////////
// CONTEXT
///////////////////////////////////////////////////////////////////////////

export type IMultiNavTableContext = {
	multiTableNavConfig: IMultiTableNavConfig[]
	tableViewConfig: ITableView
	tableSideNavConfig: ITableSideNav
	currentIndexConfig: number
	sideNavSelectedId: string | null
	setSideNavSelectedId: (value: null | string) => void
	sideNavIsActive: boolean
	setSideNavIsActive: (value: boolean) => void
	queryDataSideNav: any
	handleListItemClick: (_event: any, index: number) => void
	loading: boolean
	tableTitle?: string
}

export const MultiNavTableContext: React.Context<IMultiNavTableContext> =
	createContext({} as IMultiNavTableContext)

///////////////////////////////////////////////////////////////////////////
// PROVIDER
///////////////////////////////////////////////////////////////////////////

interface IMultiNavTableProvider {
	children: ReactNode
	multiTableNavConfig: IMultiTableNavConfig[]
	mainContext: any
}

const MultiNavTableProvider: React.FC<IMultiNavTableProvider> = ({
	children,
	multiTableNavConfig,
	mainContext,
}) => {
	const location = useLocation()
	const { additionalSideNavVariablesQuery } = useContext(mainContext) as any
	const [sideNavIsActive, setSideNavIsActive] = useState(true)
	const [sideNavSelectedId, setSideNavSelectedId] = useState<null | string>(
		null
	)

	const currentIndexConfig = useMemo(
		() =>
			multiTableNavConfig.findIndex(
				(TableNavConfig) =>
					TableNavConfig.tableMainNav.route === location.pathname
			),
		[location.pathname, multiTableNavConfig]
	)

	const currentMultiNavConfig: IMultiTableNavConfig = useMemo(
		() => multiTableNavConfig[currentIndexConfig],
		[currentIndexConfig, multiTableNavConfig]
	)

	const { data: resultQueryDataSideNav, loading } = useQuery(
		currentMultiNavConfig?.tableSideNav?.query,
		{
			variables: {
				isActive: sideNavIsActive,
				...additionalSideNavVariablesQuery,
			},
		}
	)

	const queryDataSideNav = useMemo(
		() =>
			resultQueryDataSideNav?.[currentMultiNavConfig?.tableSideNav?.queryName]
				?.collection,
		[resultQueryDataSideNav, currentMultiNavConfig]
	)

	const tableTitle = useMemo(() => {
		const currentIdCategory = sideNavSelectedId
			? sideNavSelectedId
			: queryDataSideNav?.[0]?.id

		const name = queryDataSideNav?.find(
			({ id }) => id === currentIdCategory
		)?.name

		return name || ''
	}, [queryDataSideNav, sideNavSelectedId])

	const handleListItemClick = useCallback(
		(_event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
			setSideNavSelectedId(queryDataSideNav?.[index]?.id as string)
		},
		[setSideNavSelectedId, queryDataSideNav]
	)

	const providerValues = useMemo(
		() => ({
			multiTableNavConfig,
			currentIndexConfig,
			tableTitle,
			tableViewConfig: currentMultiNavConfig?.tableView,
			tableSideNavConfig: currentMultiNavConfig?.tableSideNav,
			queryDataSideNav,
			loading,
			handleListItemClick,
			sideNavSelectedId: sideNavSelectedId ?? queryDataSideNav?.[0]?.id,
			setSideNavSelectedId,
			sideNavIsActive,
			setSideNavIsActive,
		}),
		[
			currentIndexConfig,
			sideNavSelectedId,
			sideNavIsActive,
			queryDataSideNav,
			currentMultiNavConfig,
			multiTableNavConfig,
			loading,
			handleListItemClick,
			tableTitle,
		]
	)

	return (
		<MultiNavTableContext.Provider value={providerValues}>
			{children}
		</MultiNavTableContext.Provider>
	)
}

export default memo(MultiNavTableProvider)
