/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

import { EFormFieldsPDF } from '../../../../utils/app-models'
import { formatDate } from '../../../../utils/date'
import { dateTransport, formatAddressTransport } from '../../utilPdf'
import { row5CellTitle, row5CellValue } from './_formatDataUtils'

///////////////////////////////////////////////////////////////////////////////////////////////////////
// INFOS -> Data [] are use to generate table (row,cell,etc..)
///////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////
// FIRST PAGE : BLOCK FORM
///////////////////////////////////////////////////////////////////////////////////////////////////////

export const dataBlockForm = (data, intlMsg) => {
	const { reference, interventionTransportTrip, dateCreated, geolocation } =
		data

	// generate data row title
	const dataBlockFormsTITLE = [
		// 1
		row5CellTitle(),
		// 2
		row5CellTitle(intlMsg, 'pdfForms.form'),
		// 3
		row5CellTitle(intlMsg, 'pdfForms.ref', 'pdfForms.localeDate'),
		// 4
		row5CellTitle(intlMsg, 'pdfForms.geolocalisation', 'pdfForms.horodatage'),
		// 5
		row5CellTitle(intlMsg, 'pdfForms.transportStep'),
		// 6
		row5CellTitle(),
	]

	// generate data row records value
	const dataBlockFormsVALUE = [
		// 1
		row5CellValue(null, null, true),
		// 2
		row5CellValue(null, null, true),
		// 3
		row5CellValue(
			{
				value: reference,
				type: EFormFieldsPDF.text,
			},
			{
				value: formatDate(dateCreated, true),
				type: EFormFieldsPDF.text,
			}
		),
		// 4
		row5CellValue(
			{
				value: geolocation?.latitude
					? `${Number.parseFloat(geolocation.latitude).toFixed(
							7
					  )}, ${Number.parseFloat(geolocation.longitude).toFixed(7)}`
					: '-',
				type: EFormFieldsPDF.text,
			},
			{
				value: dateCreated ? dateCreated.split('+')[0] + ' UTC' : '',
				type: EFormFieldsPDF.text,
			}
		),
		// 5
		row5CellValue({
			value:
				interventionTransportTrip?.listOrder === 0
					? intlMsg('pdfForms.removal')
					: intlMsg('pdfForms.delivery'),
			type: EFormFieldsPDF.text,
			additionalValue: { textBold: true },
		}),
		// 6
		row5CellValue(null, null, true),
	]

	return {
		title: dataBlockFormsTITLE,
		record: dataBlockFormsVALUE,
		option: {
			columnStyles: {
				0: { cellWidth: 9 },
				1: { cellWidth: 77.5 },
				2: { cellWidth: 9 },
				3: { cellWidth: 77.5 },
				4: { cellWidth: 9 },
			},
		},
	}
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
// FIRST PAGE : BLOCK INTERVENTION TRANSPORT
///////////////////////////////////////////////////////////////////////////////////////////////////////

export const dataBlockTransport = (data, intlMsg) => {
	const {
		intervention: {
			reference,
			interventionTransportType,
			interventionTransportTrips,
			orderingCustomer,
			orderingSite,
		},
		vehicle: {
			vehicleDetail: { immat, vehicleBrand, vin },
			vehicleModelText,
		},
	} = data

	// generate data row title
	const dataBlockFormsTITLE = [
		// 1
		row5CellTitle(),
		// 2
		row5CellTitle(intlMsg, 'pdfForms.transport'),
		// 3
		row5CellTitle(intlMsg, 'pdfForms.ref', 'pdfForms.type'),
		// 4
		row5CellTitle(intlMsg, 'pdfForms.transportRequest', 'pdfForms.vehicle'),
		// 5
		row5CellTitle(),
		// 6
		row5CellTitle(),
		// 7
		row5CellTitle(intlMsg, 'pdfForms.tentativeDate', 'pdfForms.tentativeDate'),
		// 8
		row5CellTitle(
			intlMsg,
			'pdfForms.tentativeAddress',
			'pdfForms.tentativeAddress'
		),
		// 9
		row5CellTitle(),
	]

	// generate data row records value
	const dataBlockFormsVALUE = [
		// 1
		row5CellValue(null, null, true),
		// 2
		row5CellValue(null, null, true),
		// 3
		row5CellValue(
			{
				value: reference,
				type: EFormFieldsPDF.text,
			},
			{
				value: intlMsg(`interventions.${interventionTransportType?.code}`),
				type: EFormFieldsPDF.text,
			}
		),
		// 4
		row5CellValue(
			{
				value: `${orderingCustomer?.name} - ${orderingSite?.name}`,
				type: EFormFieldsPDF.longText,
			},

			{
				value: `${immat ?? ''} ${vin ?? ''} - ${vehicleBrand?.name ?? ''} ${
					vehicleModelText ?? ''
				}`,
				type: EFormFieldsPDF.longText,
			}
		),
		// 5
		row5CellValue(
			{ value: null, type: 'separator' },
			{ value: null, type: 'separator' }
		),
		// 6
		row5CellValue(
			{
				value: intlMsg('pdfForms.removal'),
				type: EFormFieldsPDF.subtitle,
				additionalValue: {
					url: '/img/pdf/transport/flag-blue.png',
					w: 2.4,
					h: 2.4,
				},
			},
			{
				value: intlMsg('pdfForms.delivery'),
				type: EFormFieldsPDF.subtitle,
				additionalValue: {
					url: '/img/pdf/transport/flag-red.png',
					w: 2.4,
					h: 2.4,
				},
			}
		),
		// 7
		row5CellValue(
			{
				value: dateTransport({
					date: interventionTransportTrips[0].date,
					timeTo: interventionTransportTrips[0].timeTo,
					timeFrom: interventionTransportTrips[0].timeFrom,
				}),
				type: EFormFieldsPDF.longText,
			},
			{
				value: dateTransport({
					date: interventionTransportTrips[1].date,
					timeTo: interventionTransportTrips[1].timeTo,
					timeFrom: interventionTransportTrips[1].timeFrom,
				}),
				type: EFormFieldsPDF.longText,
			}
		),
		// 8
		row5CellValue(
			{
				value: formatAddressTransport(interventionTransportTrips[0]),
				type: EFormFieldsPDF.longText,
			},
			{
				value: formatAddressTransport(interventionTransportTrips[1]),
				type: EFormFieldsPDF.longText,
			}
		),
		// 9
		row5CellValue(null, null, true),
	]

	return {
		title: dataBlockFormsTITLE,
		record: dataBlockFormsVALUE,
		option: {
			columnStyles: {
				0: { cellWidth: 9 },
				1: { cellWidth: 77.5 },
				2: { cellWidth: 9 },
				3: { cellWidth: 77.5 },
				4: { cellWidth: 9 },
			},
		},
	}
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
// FIRST PAGE :  BLOCK SIGNATURE
///////////////////////////////////////////////////////////////////////////////////////////////////////

export const dataBlockSignature = (data, intlMsg) => {
	// Data
	const { intervention } = data

	// generate data row title
	const dataBlockSignatureTITLE = [
		{
			value1: intlMsg('pdfForms.signTransportConveyer'),
			value2: intlMsg('pdfForms.signTransportDest'),
		},
		{
			value1: '',
			value2: '',
		},
	]

	// generate data row records value
	const dataBlockSignatureVALUE = [
		{
			value1: {},
			value2: {},
		},

		{
			value1: {
				record: intervention?.endingForm?.value?.operator?.signature,
				type: EFormFieldsPDF.signature,
			},
			value2: {
				record: intervention?.endingForm?.value?.client?.signature,
				clientSkipped: intervention?.endingForm?.value?.client?.clientSkipped
					? intlMsg('pdfForms.controlSkipped')
					: '',
				type: EFormFieldsPDF.signature,
				contactName: intervention?.endingForm?.value?.client?.contact,
			},
		},
	]

	return {
		title: dataBlockSignatureTITLE,
		record: dataBlockSignatureVALUE,
		option: {},
	}
}
