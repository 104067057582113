import React, { useCallback, useContext, useMemo } from 'react'
import toast from 'react-hot-toast'

import {
	formatProductModalCreateDefaultValues,
	formatProductModalEditDefaultValues,
	formatProductModalOnValidateData,
} from './catalogueProductModalUtils'
import {
	useCreateProduct,
	useUpdateProduct,
} from '../../../../../../api/gql/mutations/catalogProductsMutation'
import { IModalUtils, useFormatMessage } from '../../../../../../utils/hooks'
import { EFormModalMode, EModule } from '../../../../../../utils/app-models'
import FormModal from '../../../../../../components/forms/formModal'
import catalogueProductModalConfig from './catalogueProductModalConfig'
import {
	useLazyCategoryFormModal,
	useLazyProductFormModal,
} from '../../../../../../api/gql/queries/catalogProductQueries'
import { MultiNavTableContext } from '../../../../../../components/multiNavTable/MultiNavTableProvider'
import { AuthContext } from '../../../../../../utils/contexts/AuthContext'

interface ICatalogueProductModal {
	modalUtils: IModalUtils
	mode?: EFormModalMode
	onClose?: any
	productId?: string
}

const CatalogueProductModal: React.FC<ICatalogueProductModal> = ({
	modalUtils,
	mode,
	productId,
	onClose,
}) => {
	const intlMsg = useFormatMessage()
	const { sideNavSelectedId: categoryId } =
		useContext(MultiNavTableContext) || {}
	const [updateProduct] = useUpdateProduct()
	const [createProduct] = useCreateProduct()
	const { getProductCategory } = useLazyCategoryFormModal()
	const { getProduct } = useLazyProductFormModal()
	const { checkModules } = useContext(AuthContext)

	const haveCrmModule = useMemo(
		() => checkModules([EModule.crm]),
		[checkModules]
	)

	const onValidate = useCallback(
		async ({ data, setValidationLoading, closeModal }) => {
			setValidationLoading(true)
			const mutate =
				mode === EFormModalMode.edit ? updateProduct : createProduct

			await mutate({
				variables: {
					input: formatProductModalOnValidateData({
						data,
						mode,
						productId,
					}),
				},
				refetchQueries: ['getProducts', 'getProductCategories'],
			})
				.then(() => {
					mode === EFormModalMode.edit
						? toast.success('success.productUpdate')
						: toast.success('success.productCreate')
					setValidationLoading(false)
					closeModal()
				})
				.catch(() => setValidationLoading(false))
		},
		[productId, mode]
	)

	const initializeData = useCallback(
		async ({ setValues, setLoading }) => {
			if (mode === EFormModalMode.edit) {
				setLoading(true)
				getProduct({ variables: { id: productId } })
					.then((res) => {
						const data = res?.data?.product
						if (data) {
							setValues(formatProductModalEditDefaultValues(data))
						}
						setLoading(false)
					})
					.catch(() => setLoading(false))
			} else {
				setLoading(true)

				const defaultCategory = await getProductCategory({
					variables: { id: categoryId },
				})

				setValues(
					formatProductModalCreateDefaultValues({
						defaultCategory: defaultCategory?.data?.productCategory,
						haveCrmModule,
					})
				)
				setLoading(false)
			}
		},
		[mode, categoryId, productId]
	)

	return (
		<FormModal
			modalUtils={modalUtils}
			formModalTabConfig={catalogueProductModalConfig}
			formModalName={intlMsg(
				mode === EFormModalMode.edit
					? 'catalogProduct.editProduct'
					: 'catalogProduct.addProduct'
			)}
			initialMode={mode}
			onValidate={onValidate}
			validateBtnText={intlMsg('misc.validate')}
			cancelBtnText={intlMsg('misc.cancel')}
			maxWidth='595px'
			width='50%'
			minHeight='50px'
			initializeData={initializeData}
			onClose={onClose}
		/>
	)
}

export default CatalogueProductModal
