import React, { useCallback, useContext } from 'react'

import {
	Typography,
	Stack,
	Dialog,
	DialogContent,
	DialogActions,
	Button,
	Box,
} from '@mui/material'

import { useFormatMessage } from '../../../../../../../../utils/hooks'
import { VehiclesTransportContext } from '../VehiclesTransportProvider'

const footerCSS = { boxShadow: ({ shadows }) => shadows[1], border: 'none' }

interface IVehicleAssignmentModalConfirm {
	handleConfirm
	onClose
	open
	clearSearchField
}

const VehicleAssignmentModalConfirm: React.FC<
	IVehicleAssignmentModalConfirm
> = ({ handleConfirm, onClose, open, clearSearchField }) => {
	const intlMsg = useFormatMessage()
	const { requestedVehicleData, setRequestedVehicleData } = useContext(
		VehiclesTransportContext
	)

	const handleClose = useCallback(() => {
		onClose()
		setRequestedVehicleData(null)
		clearSearchField()
	}, [])

	return (
		<Dialog
			onClose={handleClose}
			aria-labelledby='simple-dialog-title'
			open={open}
			maxWidth='lg'
		>
			<DialogContent dividers sx={{ border: 'none' }}>
				<Stack direction='row' spacing={8} p={4}>
					<Box mt={-1}>
						<img src='/img/illustration/confirm-vehicle.svg' />
					</Box>

					<Stack>
						<Typography variant='h1' mb={2}>
							{`${
								requestedVehicleData?.vehicleDetail?.immat ||
								requestedVehicleData?.vehicleDetail?.vin
							}`}
						</Typography>
						<Typography mb={2} color='#00000096'>
							{intlMsg('interventions.confirmAssignmentImmat')}
						</Typography>

						<Typography>
							{requestedVehicleData?.site?.customer?.name || '-'}
						</Typography>
						<Typography>{requestedVehicleData?.site?.name || '-'}</Typography>

						<Typography mt={2} color='#00000096'>
							{intlMsg('interventions.confirmAssignment')}
						</Typography>
					</Stack>
				</Stack>
			</DialogContent>

			<DialogActions sx={footerCSS}>
				<Stack direction='row' spacing={1.5} justifyContent='flex-end' p={1.5}>
					<Button variant='outlined' onClick={handleClose}>
						{intlMsg('misc.cancel')}
					</Button>
					<Button
						data-test-id='Playwright-TransportConfirmVehicle'
						variant='contained'
						onClick={handleConfirm}
					>
						{intlMsg('misc.validate')}
					</Button>
				</Stack>
			</DialogActions>
		</Dialog>
	)
}
export default VehicleAssignmentModalConfirm
