import React, { useMemo, useCallback } from 'react'
import { Stack, Typography, Box } from '@mui/material'
import { dayjsMethod, getSeparateDateAndHour } from '../../utils/date'
import translateIntlMsgString from '../../utils/translateIntlMsgString'

const labelCSS = {
	fontSize: '12px',
	fontWeight: 400,
	lineHeight: '20px',
	letterSpacing: '0px',
	textAlign: 'left',
	color: '#666',
}

interface IDateToFromContract {
	dateTo: Date
	dateFrom: Date
	fontMd?: boolean
	noSvg?: boolean
}

const DateToFromContract: React.FC<IDateToFromContract> = ({
	dateTo,
	dateFrom,
	fontMd = false,
	noSvg = false,
}) => {
	const { date: dateDateFrom } = useMemo(
		() => getSeparateDateAndHour(dateFrom),
		[dateFrom]
	)

	const { date: dateDateTo } = useMemo(
		() => getSeparateDateAndHour(dateTo),
		[dateTo]
	)

	const typoLineHeightCSS = useMemo(
		() => ({
			color: dayjsMethod(dateTo).isBefore(new Date()) ? 'red' : '',
		}),
		[dateTo]
	)

	const renderSvg = useMemo(
		() => (
			<Stack alignItems='center' mr={noSvg ? 0 : 0.5}>
				<img src={noSvg ? undefined : '/icon/dateToFrom.svg'} />
			</Stack>
		),
		[noSvg]
	)

	const renderTypography = useCallback(
		(dateValue, canBeLate: boolean) => (
			<Typography
				noWrap
				variant={fontMd ? 'body2' : 'caption'}
				lineHeight='1.38'
				sx={canBeLate ? typoLineHeightCSS : {}}
			>
				<Box component='span' sx={{ mr: 0.5 }}>
					{dateValue}
				</Box>
			</Typography>
		),
		[fontMd, typoLineHeightCSS]
	)

	const renderDifferentDayTypography = useMemo(
		() => (
			<>
				{renderTypography(dateDateFrom, false)}
				{renderTypography(dateDateTo, true)}
			</>
		),
		[dateDateFrom, dateDateTo, renderTypography]
	)

	const RenderDates = useCallback(() => {
		if (dateTo && dateFrom) {
			return (
				<Stack direction='row' alignItems='center'>
					{!noSvg && renderSvg}
					<Stack>{renderDifferentDayTypography}</Stack>
				</Stack>
			)
		} else if (dateFrom) {
			return (
				<Stack ml={1.4}>
					<Typography sx={labelCSS}>
						{translateIntlMsgString('misc.dateFrom')}
					</Typography>
					{renderTypography(dateDateFrom, true)}
				</Stack>
			)
		} else if (dateTo) {
			return (
				<Stack ml={1.4}>
					<Typography sx={labelCSS}>
						{translateIntlMsgString('misc.dateUntil')}
					</Typography>
					{renderTypography(dateDateTo, true)}
				</Stack>
			)
		} else return <Stack ml={1.4}>{'-'}</Stack>
	}, [dateDateFrom, dateDateTo])

	return <RenderDates />
}

export default DateToFromContract
