import React, { useCallback, useContext } from 'react'

import { Stack, Typography, Grid } from '@mui/material'
import { useFormatMessage } from '../../../../../../utils/hooks'
import PermissionsHandler from '../../../../../../components/permissionsHandler'
import { EModule } from '../../../../../../utils/app-models'
import FormMultiSelectSearch from '../../../../../../components/forms/formFields/formMultiSelectSearch'
import {
	FormModalContext,
	IFormModalContext,
} from '../../../../../../components/forms/formModal/FormModalContext'

interface IGlobalActions {
	formTemplatesOptions?: any
	productCategoriesOptions?: any
}

const GlobalActions: React.FC<IGlobalActions> = ({
	formTemplatesOptions = [],
	productCategoriesOptions = [],
}) => {
	const intlMsg = useFormatMessage()
	const { data, setValues } = useContext<IFormModalContext>(FormModalContext)

	const handleOnChange = useCallback(
		(arrayName: string) => (name: string, globalValues: any) => {
			// Find unselected global values to filter them out later
			const unselectedGlobalValues =
				Object.values(data?.[name] || {}).filter(
					(oldGlobalValue: any) =>
						oldGlobalValue?.value &&
						!Object?.values(globalValues)?.find(
							(globalValue: any) =>
								globalValue?.value &&
								oldGlobalValue?.value?.id === globalValue?.value?.id
						)
				) || []
			// Extract selected vehicles to update them later
			const newSelectedVehicles = [...(data?.selectedVehicles || [])]

			// Create a new object for values with updated global values
			const newValues: { [key: string]: any } = {
				...data,
				[name]: { ...globalValues },
			}

			// Iterate through data to update values related to selected vehicles
			Object.entries(data).forEach(([dataKey, dataValues]: [string, any]) => {
				// Check if the key concerns selected vehicles

				if (dataKey.includes(`${arrayName}-`)) {
					const selectedVehicleId = dataKey.replace(`${arrayName}-`, '')

					// Start of merging objects logic
					const updatedDataValues: { [key: string]: any } = {}

					// Retain values from dataValues that are not in unselectedGlobalValues
					for (const key in dataValues) {
						if (
							dataValues[key]?.isDisabled ||
							!unselectedGlobalValues.find(({ value }: any) => value.id === key)
						)
							updatedDataValues[key] = dataValues[key]
					}

					// Add values from globalValues to updatedDataValues
					for (const key in globalValues) {
						if (!dataValues?.[key]?.isDisabled)
							updatedDataValues[key] = globalValues[key]
					}

					// End of merging objects logic

					// Update the values associated with the selected vehicle
					newValues[dataKey] = updatedDataValues

					// Update selected vehicles with new values
					newSelectedVehicles.forEach((newSelectedVehicle: any) => {
						if (selectedVehicleId === newSelectedVehicle?.id) {
							newSelectedVehicle[arrayName] = Object.values(updatedDataValues)
								.filter(({ checked }: any) => checked)
								.map((value: any) => value.value.id)
						}
					})
				}
			})

			// Update state with new values
			setValues({
				...newValues,
				selectedVehicles: newSelectedVehicles,
			})
		},
		[data, setValues]
	)

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={6}>
				<PermissionsHandler modulesName={[EModule.productCatalog]}>
					<Stack>
						<Typography
							variant='h2'
							sx={{
								marginBottom: '16px',
							}}
						>
							{intlMsg('interventions.globalProducts')}
						</Typography>
						<FormMultiSelectSearch
							name='globalProducts'
							options={productCategoriesOptions}
							context={FormModalContext}
							label={intlMsg('interventions.selectProduct')}
							selectAllText={intlMsg('misc.selectAll')}
							groupBy='categoryName'
							specialOnChange={handleOnChange('products')}
							nbChipsToDisplay={4}
							messageTopModal={intlMsg('interventions.applyAllProducts')}
							bordered
						/>
					</Stack>
				</PermissionsHandler>
			</Grid>
			<Grid item xs={12} md={6}>
				<PermissionsHandler modulesName={[EModule.formTemplate]}>
					<Stack>
						<Typography variant='h2' sx={{ marginBottom: '16px' }}>
							{intlMsg('interventions.globalReports')}
						</Typography>
						<FormMultiSelectSearch
							name='globalFormTemplates'
							options={formTemplatesOptions}
							context={FormModalContext}
							label={intlMsg('interventions.selectReport')}
							selectAllText={intlMsg('misc.selectAll')}
							specialOnChange={handleOnChange('formTemplates')}
							nbChipsToDisplay={4}
							messageTopModal="Les formulaires sélectionnés s'appliquent à l'ensemble des véhicules de l'intervention, qu'ils soient planifiés ou ajoutés au court de l'intervention."
							bordered
						/>
					</Stack>
				</PermissionsHandler>
			</Grid>
		</Grid>
	)
}
export default GlobalActions
