import { object, ref, string } from 'yup'

import translateIntlMsgString from '../../../../utils/translateIntlMsgString'

export const editUserPasswordModalValidationSchema = object().shape({
	oldPassword: string().required(
		translateIntlMsgString('validationSchema.requirePassword')
	),
	newPassword: string()
		.matches(
			/^.*(?=.{7,})((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
			translateIntlMsgString('validationSchema.passwordFormat')
		)
		.required(translateIntlMsgString('validationSchema.requirePassword')),
	newRetypedPassword: string()
		.oneOf(
			[ref('newPassword'), null],
			translateIntlMsgString('validationSchema.unmatchPassword')
		)
		.required(translateIntlMsgString('validationSchema.requirePassword')),
})
