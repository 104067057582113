import React, { useCallback, useEffect, useMemo, useState } from 'react'

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { Stack } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

import { useFormatMessage } from '../../utils/hooks'

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

interface IMultiSelect {
	label: string
	options: Array<{
		id: string
		name: string
		value: string | number | boolean
	}>
	isrequired?: boolean
	size?: 'medium' | 'small' | undefined
	isDisabled?: boolean
	hidden?: boolean
	missingText?: string
	defaultValue?
	setValue
	groupBy?: string
	onClose?
	onOpen?
	ChipProps?:
		| {
				color: 'primary'
				size: 'small'
		  }
		| {}
	error?
	onBlur?
}

const MultiSelect: React.FC<IMultiSelect> = ({
	label,
	options,
	isrequired,
	size,
	isDisabled = false,
	hidden,
	missingText,
	defaultValue,
	setValue,
	groupBy,
	onClose = null,
	onOpen = null,
	ChipProps,
	error,
	onBlur,
}) => {
	const intlMsg = useFormatMessage()

	const [selectedData, setSelectedData] = useState(defaultValue || [])
	const filter = useMemo(() => createFilterOptions(), [])

	const handleToggleSelectAll = () => {
		const filteredOption: any = []
		//allow to get filtered options by dom elements

		document.querySelectorAll('[id=select-option]').forEach((Element) => {
			const entry = options.find(
				({ id }) => id === Element.className.split(' ')[0]
			)
			if (entry) filteredOption.push(entry)
		})

		if (filteredOption?.length === selectedData?.length) setSelectedData([])
		else setSelectedData(filteredOption)
	}

	const handleChange = (_event, selectedOptions, reason) => {
		if (reason === 'selectOption' || reason === 'removeOption') {
			if (selectedOptions.find((option) => option.id === 'select-all')) {
				handleToggleSelectAll()
			} else {
				setSelectedData(selectedOptions)
			}
		} else if (reason === 'clear') {
			setSelectedData([])
		}
	}

	const isOptionEqualToValue = useCallback(
		(option, anotherOption) => option.id === anotherOption.id,
		[]
	)

	const filterOptions = useCallback((options, params) => {
		const filtered = filter(options, params)
		if (options?.length) {
			return [
				{ name: intlMsg('misc.selectAll'), id: 'select-all' },
				...filtered,
			]
		} else return []
	}, [])

	const getOptionLabel = useCallback((option) => option?.name || '', [])

	useEffect(() => {
		setValue && setValue(selectedData)
	}, [selectedData])

	return (
		<Autocomplete
			onOpen={onOpen}
			onClose={onClose}
			onBlur={onBlur}
			multiple
			limitTags={2}
			options={options}
			value={selectedData}
			ChipProps={{
				...ChipProps,
				size: size || 'small',
				sx: { margin: '0px !important' },
			}}
			disableCloseOnSelect
			noOptionsText={missingText ? intlMsg(missingText) : intlMsg('misc.empty')}
			getOptionLabel={getOptionLabel}
			isOptionEqualToValue={isOptionEqualToValue}
			filterOptions={filterOptions}
			onChange={handleChange}
			renderOption={(props, option, { selected }) => {
				const selectAllProps =
					option.id === 'select-all' // To control the state of 'select-all' checkbox
						? { checked: options?.length === selectedData?.length }
						: {}

				return (
					<li {...props}>
						<Stack
							id='select-option'
							className={option?.id}
							direction='row'
							justifyContent='space-between'
							alignItems='center'
							sx={{ width: 1 }}
						>
							{option?.name}
							<Checkbox
								icon={icon}
								checkedIcon={checkedIcon}
								sx={{ mr: 1 }}
								checked={selected}
								color={selected ? 'primary' : 'default'}
								{...selectAllProps}
							/>
						</Stack>
					</li>
				)
			}}
			groupBy={(options) => (groupBy ? options?.[groupBy] : null)}
			disabled={isDisabled}
			renderInput={(params) => (
				<TextField
					{...params}
					label={`${label} ${isrequired ? '*' : ''}`}
					variant='outlined'
					size={size || 'small'}
					sx={{ display: hidden ? 'none' : 'inherit' }}
					disabled={isDisabled}
					helperText={error ? intlMsg(error) : ''}
					error={!!error}
				/>
			)}
		/>
	)
}

export default MultiSelect
