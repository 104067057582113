import React from 'react'

import { Box, IconButton, Stack, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'

const containerHeaderCSS = { borderBottom: 'solid 1px #ddd' }

interface IProductBaseDrawerHeader {
	title: string
	onClose
	handleEdit
}

const ProductBaseDrawerHeader: React.FC<IProductBaseDrawerHeader> = ({
	title,
	onClose,
	handleEdit,
}) => {
	return (
		<Box sx={containerHeaderCSS}>
			<Stack
				direction='row'
				justifyContent='space-between'
				alignItems='center'
				p={2}
			>
				<Typography variant='h3'>{title}</Typography>

				<Stack direction='row' spacing={1}>
					<IconButton size='small' onClick={handleEdit}>
						<EditIcon />
					</IconButton>
					<IconButton size='small' onClick={onClose}>
						<CloseIcon />
					</IconButton>
				</Stack>
			</Stack>
		</Box>
	)
}

export default ProductBaseDrawerHeader
