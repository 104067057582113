import React, {
	useCallback,
	useContext,
	useState,
	useEffect,
	useMemo,
	memo,
} from 'react'

import { Stack, Typography, Paper, Divider, Button, Fade } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

import { useWVehicleMileages } from '../../../api/gql/queries/vehiclesQueries'
import WidgetIconTitle from '../elements/WidgetIconTitle'
import { useFormatMessage } from '../../../utils/hooks'
import UserAvatar from '../../avatar/UserAvatar'
import { ModalContext } from '../../../utils/contexts/ModalContext'
import UpdateMileageModal from './UpdateMileageModal'
import { formatDate } from '../../../utils/date'
import { EPermissionScope } from '../../../utils/app-models'
import { AuthContext } from '../../../utils/contexts/AuthContext'
import CircularProgressWidget from '../elements/CircularProgressWidget'
import { ViewModalContentContext } from '../../../utils/contexts/ViewModalContentContext'

const widgetIconTitleIcon: any = {
	iconText: 'KM',
	iconColor: 'orange',
}

interface IWVehicleMileages {
	vehicleId: string
	immatOrVin?: string
}

const WVehicleMileages: React.FC<IWVehicleMileages> = ({
	vehicleId,
	immatOrVin,
}) => {
	const intlMsg = useFormatMessage()
	const [lastMileage, setLastMileage] = useState() as any
	const { checkPermissions } = useContext(AuthContext)
	const { viewModalData } = useContext(ViewModalContentContext)

	const lastMileageData = useMemo(
		() => ({
			userNameObj: {
				firstName: lastMileage?.createdBy?.firstName,
				lastName: lastMileage?.createdBy?.lastName,
			},
			date: lastMileage?.date ? formatDate(lastMileage.date) : '-',
		}),
		[lastMileage]
	)
	// here take the last element in data
	const { data, loading } = useWVehicleMileages({
		variables: {
			id: vehicleId,
		},
	})

	const { openModal, isVisible } = useContext(ModalContext)
	const handleUpdateMileage = useCallback(() => {
		openModal()
	}, [])

	useEffect(() => {
		if (data && !loading) {
			setLastMileage(
				data?.vehicleMileages?.length
					? data?.vehicleMileages?.[0].mileage
					: viewModalData?.vehicleDetail?.actualMileage
			)
		}
	}, [data, loading])

	return (
		<>
			<Paper elevation={1} sx={{ height: 1, position: 'relative' }}>
				<Stack p={2} justifyContent='center' sx={{ height: 1 }}>
					<Fade in={!loading}>
						<Stack>
							<Stack direction='row' justifyContent='space-between'>
								<WidgetIconTitle
									icon={widgetIconTitleIcon}
									title={intlMsg('widget.lastMileage')}
									subTitle={lastMileage ? lastMileage : '-'}
									unit={'KM'}
								/>

								{lastMileage?.createdBy ? (
									<Stack alignItems='flex-end'>
										<UserAvatar userName={lastMileageData?.userNameObj} />
										<Typography variant='caption'>
											{lastMileageData?.date}
										</Typography>
									</Stack>
								) : (
									<></>
								)}
							</Stack>

							<Divider sx={{ mb: 2, mt: 3 }} />

							<Stack direction='row' justifyContent='space-between'>
								<Button
									disabled={!checkPermissions([EPermissionScope.vehicleUpdate])}
									variant='text'
									color='primary'
									startIcon={<AddCircleOutlineIcon />}
									size='small'
									onClick={handleUpdateMileage}
								>
									{intlMsg('widget.updateMileage')}
								</Button>
								<Button
									disabled
									variant='text'
									color='primary'
									endIcon={<ArrowForwardIcon />}
									size='small'
								>
									{intlMsg('widget.historicMileage')}
								</Button>
							</Stack>
						</Stack>
					</Fade>
					{loading && <CircularProgressWidget />}
				</Stack>
			</Paper>

			{isVisible && (
				<UpdateMileageModal
					vehicleId={vehicleId}
					previousMileage={lastMileage?.mileage || 0}
					immatOrVin={immatOrVin}
				/>
			)}
		</>
	)
}

export default memo(WVehicleMileages)
