import React, { useCallback } from 'react'

import toast from 'react-hot-toast'

import { IModalUtils, useFormatMessage } from '../../../../utils/hooks'
import { useUpdateUsers } from '../../../../api/gql/mutations/usersMutations'
import FormModal from '../../../../components/forms/formModal'
import { EFormModalMode } from '../../../../utils/app-models'
import { editUserModalConfig } from './editUserModalConfig'

interface IEditUserModal {
	user: any
	modalUtils: IModalUtils
}

const EditUserModal: React.FC<IEditUserModal> = ({ user, modalUtils }) => {
	const intlMsg = useFormatMessage()
	const [updateUser] = useUpdateUsers()

	const validateModal = useCallback(
		async ({ data, setValidationLoading, closeModal }) => {
			setValidationLoading(true)
			await updateUser({
				variables: {
					input: {
						id: user.id,
						...data,
					},
				},
				refetchQueries: ['getProfileUser'],
			})
				.then(() => {
					toast.success('success.profileUpdate')
					setValidationLoading(false)
					closeModal()
				})
				.catch(() => setValidationLoading(false))
		},
		[user]
	)

	const initializeData = useCallback(
		async ({ setValues }) => {
			const { firstName, lastName, job, phone, cellphone } = user
			setValues({
				firstName,
				lastName,
				job,
				phone,
				cellphone,
			})
		},
		[user]
	)

	return (
		<FormModal
			modalUtils={modalUtils}
			formModalTabConfig={editUserModalConfig}
			formModalName={intlMsg('administration.updateProfil')}
			initialMode={EFormModalMode.edit}
			onValidate={validateModal}
			validateBtnText={intlMsg('misc.validate')}
			cancelBtnText={intlMsg('misc.cancel')}
			maxWidth='600px'
			minHeight='30px'
			initializeData={initializeData}
			editedElementId={user?.id}
		/>
	)
}

export default EditUserModal
