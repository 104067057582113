import {
	ApolloClient,
	InMemoryCache,
	createHttpLink,
	from,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { baseUrlAPIGql } from '../api/url'
import errorLink from './errorLink'
import inMemoryCacheOptionsGenerator from './inMemoryCacheOptionsGenerator'

const httpLink = createHttpLink({
	uri: baseUrlAPIGql,
})

const authLink = setContext((_, { headers }) => {
	const token = localStorage.getItem('token')

	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : '',
		},
	}
})

export const client = new ApolloClient({
	link: from([errorLink, authLink, httpLink]),
	cache: new InMemoryCache(inMemoryCacheOptionsGenerator()),
	defaultOptions: {
		watchQuery: {
			fetchPolicy: 'cache-and-network',
		},
	},
})
