import React, { useCallback, useContext } from 'react'

import { Grid, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'

import { IModalUtils, useModal } from '../../../../../../utils/hooks'
import { FormModalContext } from '../../../../../../components/forms/formModal/FormModalContext'
import FormTextField from '../../../../../../components/forms/formFields/FormTextField'
import ColorRadio from '../../../../../../components/forms/formFields/colorRadio'
import FormSwitchButton from '../../../../../../components/forms/formFields/FormSwitchButton'
import translateIntlMsgString from '../../../../../../utils/translateIntlMsgString'
import ConfirmModal from '../../../../../../components/modal/confirmModal/index'

const typoCSS = { color: grey[600], fontWeight: 500 }
const wrapperModalCSS = {
	p: 4,
	overflow: 'auto',
}
const FormSwitchButtonConfig = {
	primary: { text: translateIntlMsgString('misc.active'), value: true },
	secondary: { text: translateIntlMsgString('misc.inactive'), value: false },
}

interface ICatalogueProductBaseSideNavModalInfos {}

const CatalogueProductBaseSideNavModalInfos: React.FC<
	ICatalogueProductBaseSideNavModalInfos
> = () => {
	const confirmStatusModalUtils: IModalUtils = useModal()
	const { setValue } = useContext(FormModalContext)

	const onConfirmStatusModal = useCallback(() => {
		setValue('isActive', false)
		confirmStatusModalUtils.closeModal()
	}, [setValue])

	const handleSwitchIsActive = useCallback(
		({ value }) => {
			value ? setValue('isActive', true) : confirmStatusModalUtils.openModal()
		},
		[setValue]
	)

	return (
		<Grid container spacing={2} sx={wrapperModalCSS}>
			<Grid item xs={12}>
				<FormTextField
					name='name'
					label={translateIntlMsgString('misc.name')}
					context={FormModalContext}
					isRequired
					trigerred
				/>
			</Grid>
			<Grid item xs={12}>
				<Typography variant='body2' sx={typoCSS} gutterBottom>
					{translateIntlMsgString('formTemplate.description')}
				</Typography>
				<FormTextField
					name='description'
					label={translateIntlMsgString(
						'catalogProduct.descCategoryProductBase'
					)}
					context={FormModalContext}
					multiline={true}
					minRows={6}
					maxRows={6}
				/>
			</Grid>
			<Grid item xs={12}>
				<Typography variant='body2' sx={typoCSS} gutterBottom>
					{translateIntlMsgString('catalogProduct.categoryColor')}
				</Typography>

				<ColorRadio name='color' context={FormModalContext} />
			</Grid>

			<Grid item xs={12}>
				<Typography variant='body2' sx={typoCSS} gutterBottom>
					{translateIntlMsgString('misc.status')}
				</Typography>

				<FormSwitchButton
					name='isActive'
					config={FormSwitchButtonConfig}
					specialOnChange={handleSwitchIsActive}
					context={FormModalContext}
				/>

				{confirmStatusModalUtils?.isVisible && (
					<ConfirmModal
						onClose={confirmStatusModalUtils?.closeModal}
						open={confirmStatusModalUtils?.isVisible}
						onConfirmStatusModal={onConfirmStatusModal}
						message={translateIntlMsgString(
							'catalogProduct.modalConfirmCategoryProductBase'
						)}
						severity='warning'
					/>
				)}
			</Grid>
		</Grid>
	)
}

export default CatalogueProductBaseSideNavModalInfos
