/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import dayjs from 'dayjs'

import BooleanColorPopover from '../../../../popover/BooleanColorPopover'
import { useUpdateProduct } from '../../../../../api/gql/mutations/catalogProductsMutation'
import { EModule, EPermissionScope } from '../../../../../utils/app-models'
import EditModalButton from '../../../../forms/formModal/buttonModal/EditModal'
import CatalogueProductModal from '../../../../../view/administration/productCatalogue/catalogueProduct/table/modal'
import DateToFromContract from '../../../../date/DateToFromContract'
import ChipUnitOfWork from '../../../../chip/ChipUnitOfWork'
import ChipCountListPopover from '../../../../multiItemsPopoverList/popover/simpleListPopover'
import { ROUTES } from '../../../../../routes/consts'
import PermissionsHandler from '../../../../permissionsHandler'
import translateIntlMsgString from '../../../../../utils/translateIntlMsgString'
import { Stack } from '@mui/material'
import ChipProductBaseType from '../../../../chip/ChipProductBaseType'

export const listProductCatalogueViewTable = [
	{
		columnLabel: 'misc.label',
		orderBy: 'name',
		dataKey: 'name',
	},

	{
		modules: [EModule.crm],
		columnLabel: 'catalogProduct.base',
		orderBy: 'productBase_name',
		personalizedCell: ({ productBase }) => (
			<Stack direction='row' alignItems='center' spacing={1}>
				<div>{productBase?.name}</div>
				<ChipProductBaseType
					type={productBase?.type}
					size='small'
					radius='square'
					shortenedText
				/>
			</Stack>
		),
	},

	{
		columnLabel: 'misc.ref',
		orderBy: 'commercialReference',
		dataKey: 'commercialReference',
	},

	{
		modules: [EModule.crm],
		columnLabel: 'misc.clients',
		personalizedCell: (row: any) => (
			<ChipCountListPopover
				text={'misc.clients'}
				data={
					row?.customers?.collection?.map(({ name, id }) => ({ name, id })) ||
					[]
				}
				viewModalLink={ROUTES.VIEWMODAL.CUSTOMERS.url}
			/>
		),
	},

	{
		columnLabel: 'misc.priceHt',
		orderBy: 'rate',
		personalizedCell: ({ rate }) => (
			<>{`${rate ? rate.toFixed(2) + '€' : '-'} `} </>
		),
	},

	{
		columnLabel: 'invoice.tva',
		orderBy: 'vat',
		personalizedCell: ({ vat }) => (
			<>{vat !== undefined && vat !== null ? vat + '%' : '-'}</>
		),
	},

	{
		columnLabel: 'misc.duration',
		orderBy: 'duration',
		personalizedCell: ({ duration }) => (
			<>{duration ? dayjs(duration).format('HH:mm') : '-'}</>
		),
	},

	{
		columnLabel: 'misc.workUnit',
		orderBy: 'unitOfWork',
		personalizedCell: ({ unitOfWork }) => (
			<ChipUnitOfWork unitOfWork={unitOfWork} size='medium' />
		),
	},

	{
		modules: [EModule.crm],
		columnLabel: 'misc.validContractDate',
		orderBy: 'dateContractTo',
		personalizedCell: ({ dateContractFrom, dateContractTo }) => (
			<DateToFromContract dateTo={dateContractTo} dateFrom={dateContractFrom} />
		),
	},

	{
		columnLabel: 'catalogProduct.refERP',
		orderBy: 'idERP',
		dataKey: 'idERP',
	},

	{
		columnLabel: 'misc.active',
		tableCellAlign: 'center',
		cellAlign: 'left',
		width: '20%',
		personalizedCell: (row: any) => {
			return (
				<BooleanColorPopover
					value={row.isActive}
					id={row.id}
					txt={{ true: 'misc.active', false: 'misc.desactive' }}
					titlePopover={'catalogProduct.productActive'}
					queryConfig={{
						query: useUpdateProduct,
						refreshQuery: ['getProducts', 'getProductCategories'],
						successMessage: 'success.statusUpdate',
					}}
					key={row.id}
					permissions={[EPermissionScope.productManage]}
					confirmModal={{
						message: translateIntlMsgString(
							'catalogProduct.modalConfirmProduct'
						),
						severity: 'warning',
						openOnValue: 'false',
					}}
				/>
			)
		},
	},

	{
		visible: true,
		columnLabel: '',
		tableCellAlign: 'center',
		cellAlign: 'center',
		sticky: true,
		personalizedCell: ({ id }: any) => (
			<PermissionsHandler permissionsName={[EPermissionScope.productManage]}>
				<EditModalButton
					modal={
						<CatalogueProductModal modalUtils={{} as any} productId={id} />
					}
				/>
			</PermissionsHandler>
		),
	},
]
