export default {
	btnDownload: 'Download Export',
	messageInfo: ' Your export is in progress',
	messageError: 'An error occurred during the export',
	messageSuccess: 'Your export is ready',
	messageWarning: 'You have no data to export',

	DialogTitle: 'Cancel Export',
	DialogContentText: 'Are you sure you want to cancel the export?',

	cancelExport: 'Cancel Export',
	alertCurrentExport:
		'An export is already in progress or waiting to be downloaded',
}
