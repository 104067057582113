import React, { useCallback } from 'react'

import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

import { useFormatMessage } from '../../../../utils/hooks'
import { ROUTES } from '../../../../routes/consts'

interface ICreateFormTemplate {}

const CreateFormTemplate: React.FC<ICreateFormTemplate> = () => {
	const intlMsg = useFormatMessage()
	const navigate = useNavigate()

	const handleClickCreateFormTemplate = useCallback(
		() => navigate(ROUTES.ADMINISTRATION.FORM_TEMPLATE_EDITION.url),
		[navigate]
	)

	return (
		<Button
			variant='contained'
			onClick={handleClickCreateFormTemplate}
			startIcon={<AddCircleOutlineIcon />}
		>
			{intlMsg('misc.add')}
		</Button>
	)
}

export default CreateFormTemplate
