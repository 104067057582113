import React, { useCallback, useState, useContext, memo, useMemo } from 'react'

import toast from 'react-hot-toast'

import Button from '@mui/material/Button'
import { Tooltip } from '@mui/material'

import { useFormatMessage } from '../../../utils/hooks'
import ActionPopover from './ActionPopover'
import { stringifiedColor } from '../../../utils/color'
import { maxStringDisplay } from '../../../utils/string'
import { AuthContext } from '../../../utils/contexts/AuthContext'

interface IActionPopoverBtn {
	inputs: any
	selected: any
	mutation?: {
		query: () => any
		id?: string
		attribute?: string
		refreshQuery?: any
		successMessage?: string
	}
	titlePopover?: string
	keyTranslate: string
	permissions?: any
}

const actionButtonCSS = (color: string, permissions: any) => {
	return {
		color: 'white',
		background: `${stringifiedColor(color, 500) as any} !important`,
		'&:hover': {
			backgroundColor: `${stringifiedColor(color, 400) as any} !important`,
		},
		width: '120px',
		'&.MuiButton-contained.Mui-disabled': {
			color: !permissions ? 'white !important' : 'white',
		},
	}
}

const ActionPopoverBtn: React.FC<IActionPopoverBtn> = ({
	inputs,
	selected,
	mutation,
	titlePopover,
	keyTranslate,
	permissions,
}) => {
	const { checkPermissions } = useContext(AuthContext)
	const haveAccess = checkPermissions(permissions)
	const intlMsg = useFormatMessage()
	const [updateMutation] = mutation?.query ? mutation.query() : [null]

	const update = useCallback(
		async (value: any) => {
			if (haveAccess && updateMutation) {
				await updateMutation({
					variables: {
						input: {
							id: mutation?.id,
							[mutation?.attribute as string]: value,
						},
					},
					refetchQueries: mutation?.refreshQuery,
				})
				toast.success(mutation?.successMessage || '')
			}
		},
		[updateMutation, haveAccess]
	)

	const [anchorEl, setAnchorEl] = useState<any>(null)
	const handleClick = useCallback(
		(e: React.MouseEvent) => setAnchorEl(e.currentTarget),
		[]
	)
	const handleClose = useCallback(() => setAnchorEl(null), [])
	const handleRadioChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			update(event.currentTarget.id)
			setAnchorEl(null)
		},
		[update]
	)

	const title = intlMsg(
		selected?.code
			? `${keyTranslate}.${selected?.code.toLowerCase().split(' ').join('')}`
			: selected?.name
	)

	const titleDisplay = useMemo(() => {
		if (selected?.name?.length > 13) {
			return (
				<Tooltip title={title} aria-label={title}>
					<span>{maxStringDisplay(title, 13)}</span>
				</Tooltip>
			)
		}
		return title || '-'
	}, [selected?.name, title])

	return (
		<>
			<Button
				onClick={handleClick}
				variant='contained'
				color='secondary'
				size='small'
				disabled={!haveAccess}
				sx={actionButtonCSS(selected?.color || 'grey', permissions)}
			>
				{titleDisplay}
			</Button>

			{anchorEl && haveAccess && (
				<ActionPopover
					inputs={inputs}
					title={titlePopover}
					handleChange={handleRadioChange}
					selected={selected}
					handleClose={handleClose}
					open={Boolean(anchorEl)}
					anchorEl={anchorEl}
					keyTranslate={keyTranslate}
				/>
			)}
		</>
	)
}

export default memo(ActionPopoverBtn)
