export default {
	report: 'Form',
	reports: 'Forms',
	model: 'Form Model',
	typeForm: 'Form Type',
	horodatage: 'Timestamp',
	addBy: 'Filled by',
	footer: 'Footer',
	displayAnnotations: 'Display Annotations',
	spending: 'Expenses',

	adviceCustomer: 'Customer Advice',
	commentIntervenant: 'Intervenor Comment',
	signingIntervenant: 'Intervenor Signature',
	commentCustomer: 'Customer Comment',
	signingCustomer: 'Customer Signature',
	noDefaults: 'No defaults have been detected',

	consultForm: 'Consult Form',
	interventionNotFinishYet: 'Intervention Status: ',
	fromOldRef: 'This form is from an older version. You can ',
	fromOldRefHere: 'view it here.',
}
