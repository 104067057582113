import React from 'react'
import { Alert, Box, Typography } from '@mui/material'

const alertCSS = {
	margin: 'auto',
	maxWidth: '530px',
	'& span': { fontWeight: 'bold', fontSize: '16px' },
	'& a': { fontWeight: 'bold', color: '#2558a3' },
}

interface IEmptyTableMessage {
	message: string
}

const EmptyTableMessage: React.FC<IEmptyTableMessage> = ({ message }) => {
	return (
		<Box p={2} sx={alertCSS}>
			<Alert icon={false} severity='info'>
				<Typography
					variant='body2'
					dangerouslySetInnerHTML={{ __html: message }}
				/>
			</Alert>
		</Box>
	)
}
export default EmptyTableMessage
