export const layoutGridCSS = {
	'& .layout': {
		'& .react-grid-layout': {
			position: 'relative',
			transition: 'height 200ms ease',
		},
		'& .react-grid-item': {
			transition: 'all 200ms ease',
			transitionProperty: 'left, top',
		},
		'& .react-grid-item img': {
			pointerEvents: 'none',
			userSelect: 'none',
		},
		'& .react-grid-item.cssTransforms': {
			transitionProperty: 'transform',
		},
		'& .react-grid-item.resizing': {
			zIndex: '1',
			willChange: 'width, height',
		},

		'& .react-grid-item.react-draggable-dragging': {
			transition: 'none',
			zIndex: '3',
			willChange: 'transform',
		},

		'& .react-grid-item.dropping': {
			visibility: 'hidden',
		},

		'& .react-grid-item.react-grid-placeholder': {
			background: 'rgb(132, 124, 196)',
			opacity: '0.2',
			transitionDuration: '100ms',
			zIndex: '2',
			WebkitUserSelect: 'none',
			MozUserSelect: 'none',
			MsUserSelect: 'none',
			OUserSelect: 'none',
			userSelect: 'none',
			borderRadius: 1,
		},
	},
}
