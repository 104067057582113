import React, { useContext } from 'react'

import { Stack } from '@mui/material'

import { FormTemplateContext } from '../../../../../../utils/contexts/FormTemplateContext'
import {
	SelectTitleTypeField,
	TitleField,
} from '../fieldEditForms/editFormsField'

interface IFieldEditFormLayoutTitle {}

const FieldEditFormLayoutTitle: React.FC<IFieldEditFormLayoutTitle> = () => {
	const {
		drawerUtils: { controlDrawer: control },
	} = useContext(FormTemplateContext)

	return (
		<Stack spacing={2}>
			<TitleField control={control} />
			<SelectTitleTypeField control={control} />
		</Stack>
	)
}

export default FieldEditFormLayoutTitle
