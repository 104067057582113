import React from 'react'

import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'

import { maxStringDisplay } from '../../../utils/string'
import { useFormatMessage } from '../../../utils/hooks'

interface IUserRole {
	role
	maxCharacters?: number
}

const UserRole: React.FC<IUserRole> = ({ role, maxCharacters = 15 }) => {
	const intlMsg = useFormatMessage()
	const roleTraduction = intlMsg(`userTypes.${role}`)
	return (
		<Typography color='inherit' variant='caption' sx={{ mr: 2 }}>
			{roleTraduction?.length > maxCharacters ? (
				<Tooltip title={roleTraduction} aria-label={roleTraduction}>
					<span>{maxStringDisplay(roleTraduction, maxCharacters)}</span>
				</Tooltip>
			) : (
				roleTraduction
			)}
		</Typography>
	)
}

export default UserRole
