import React from 'react'

import MuiSwitch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'

interface ISwitch {
	name?: string
	label?: string
	color?: 'primary' | 'secondary' | 'default' | undefined
	value: boolean
	onChange
	disabled?: boolean
}

const Switch: React.FC<ISwitch> = ({
	label,
	color,
	value,
	onChange,
	name,
	disabled,
}) => {
	return (
		<>
			<FormControlLabel
				control={
					<MuiSwitch
						disabled={disabled}
						onChange={onChange}
						checked={value}
						color={color || 'primary'}
						name={name}
						size='small'
					/>
				}
				label={label}
			/>
		</>
	)
}

export default Switch
