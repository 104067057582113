// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react'

import { useBulkInvoiceAction } from '../../../api/gql/mutations/invoicesMutation'
import {
	GET_INVOICES_LIST,
	useExportInvoices,
} from '../../../api/gql/queries/invoicesQueries'
import listInvoicesViewTable from '../../../components/tableV2/config/dashboard/invoices/listInvoicesViewTable'
import {
	EFormModalMode,
	EModule,
	EPermissionScope,
} from '../../../utils/app-models'
import {
	ITableToolBarActionConfig,
	ITableToolBarConfig,
} from '../../../utils/typescript/tableType'
import { invoiceDefaultFilterConfigValues } from './invoiceFilters/invoiceDefaultFilterConfig'
import InvoiceFilters from './invoiceFilters'
import FormBtnModal from '../../../components/forms/formModalBtn'
import InvoiceModal from './invoiceModal'
import { invoiceValidationSchema } from '../../../utils/validationSchema'

////////////////////////////////////////////////////////////////
// Global variable
////////////////////////////////////////////////////////////////

const localStorageKeyFilter = 'invoicesFilter'

const queryConfig = {
	query: GET_INVOICES_LIST,
	queryName: 'invoices',
}

////////////////////////////////////////////////////////////////
// tableToolBarAction CONFIG
////////////////////////////////////////////////////////////////

const refreshQuerie = ['getInvoicesList']

const deletedBulk = {
	mutation: useBulkInvoiceAction,
	operation: 'remove',
	successMessageDelete: {
		singular: 'success.invoiceDeleted',
		plural: 'success.invoicesDeleted',
	},
	messageConfirm: {
		title: 'confirmModal.deleteInvoicesTitle',
		message: 'confirmModal.deleteInvoicesMessage',
	},
	deletePermissions: [EPermissionScope.invoiceManage],
}

const tableToolBarActionConfig: ITableToolBarActionConfig = {
	refreshQuerie,
	deletedBulk,
}

////////////////////////////////////////////////////////////////
// TableToolBarHeader CONFIG
////////////////////////////////////////////////////////////////

const tableToolBarConfig: ITableToolBarConfig = {
	title: 'invoice.invoice',
	stickyToolBar: true,
	tableConfig: listInvoicesViewTable,
	localStorageKeyFilter,
	localStorageName: 'listInvoicesViewTableFilters',
	exportConfig: { exportQuery: useExportInvoices },
	queryConfig,
	modalButton: {
		Btn: (
			<FormBtnModal
				modal={<InvoiceModal modalUtils={{} as any} />}
				mode={EFormModalMode.create}
			/>
		),
	},
	validationSchemaFunction: invoiceValidationSchema,
	createPermission: [EPermissionScope.invoiceManage],
	modules: [EModule.invoice],
}

////////////////////////////////////////////////////////////////
// tableContainer CONFIG
////////////////////////////////////////////////////////////////

const tableContainerConfig = {
	tableConfig: listInvoicesViewTable,
	queryConfig,
}

////////////////////////////////////////////////////////////////
// -> EXPORT CONFIG MAIN TABLE
////////////////////////////////////////////////////////////////

const defaultFiltersConfig = {
	componentsFiltersConfig: <InvoiceFilters />,
	defaultFiltersValues: invoiceDefaultFilterConfigValues,
	localStorageKeyFilter,
}

export const invoicesConfigTable = {
	tableToolBarConfig,
	tableToolBarActionConfig,
	tableContainerConfig,
	defaultFiltersConfig,
}
