import React, { useCallback, useEffect, useState } from 'react'

import Autocomplete from '@mui/material/Autocomplete'
import Radio from '@mui/material/Radio'
import TextField from '@mui/material/TextField'
import { Stack, CircularProgress } from '@mui/material'

import { useFormatMessage } from '../../../../utils/hooks'

interface ISelectSearch {
	name: string
	label: string
	selectElements:
		| Array<{
				label: string
				key: string
				value: string | number | boolean
		  }>
		| any

	customHelper?: string
	size?: 'medium' | 'small' | undefined
	isDisabled?: boolean
	hidden?: boolean
	missingText?: string
	onBlur?
	specialOnChange?
	valuesDefault?: any
	disabled?: boolean
	formUtils: any
	withInputValue?: boolean
	isrequired?: boolean
	isLoading?
	prefix?: string
	browserRequired?: boolean
}

const SelectSearch: React.FC<ISelectSearch> = ({
	name,
	selectElements,
	label,
	customHelper,
	valuesDefault,
	size,
	isDisabled,
	hidden,
	specialOnChange,
	onBlur,
	missingText,
	disabled = false,
	formUtils: { watch, setValue, errors },
	withInputValue = false,
	isrequired,
	isLoading,
	prefix,
	browserRequired = false,
}) => {
	const watchedValue = watch(name)

	const intlMsg = useFormatMessage()
	const [inputValue, setInputValue] = useState<string>('')
	const [selectedValue, setSelectedValue] = useState<any>(
		selectElements.find((item) => item.key === valuesDefault || watchedValue)
	)

	useEffect(() => {
		const newValue =
			selectElements.find((item) => item?.key === watchedValue) || ''
		setSelectedValue(newValue)
		setInputValue(newValue?.label || '')
	}, [watchedValue, selectElements])

	const handleInputValueChange = useCallback((e) => {
		setInputValue(e?.target?.value)
	}, [])

	const handleOnChange = useCallback(
		(_e, data) => {
			if (specialOnChange) specialOnChange(name, data?.value)
			else setValue(name, data?.value)
		},
		[specialOnChange, name]
	)

	return (
		<Autocomplete
			value={selectedValue}
			onChange={handleOnChange}
			id={`id-${name}`}
			options={selectElements}
			inputValue={withInputValue ? inputValue : undefined}
			fullWidth
			autoHighlight
			disabled={disabled}
			onBlur={onBlur}
			noOptionsText={missingText ? missingText : intlMsg('misc.noResultSearch')}
			getOptionLabel={(option) => option?.label || ''}
			isOptionEqualToValue={(option) =>
				(selectedValue || valuesDefault) === option?.key || false
			}
			loading={isLoading}
			renderOption={(props, option) => (
				<li {...props}>
					<Stack
						direction='row'
						justifyContent='space-between'
						alignItems='center'
						sx={{ width: 1 }}
					>
						{option?.label}
						{`${prefix ? prefix : ''}`}
						<Radio
							sx={{ mr: 1 }}
							checked={option?.key === selectedValue?.key}
							color={option?.key === selectedValue?.key ? 'primary' : 'default'}
							size={size || 'small'}
						/>
					</Stack>
				</li>
			)}
			renderInput={(params) => (
				<TextField
					{...params}
					onChange={handleInputValueChange}
					inputProps={{
						...params.inputProps,
						value: `${inputValue}${prefix ?? ''}`,
					}}
					label={`${label} ${isrequired && !browserRequired ? '*' : ''}`}
					disabled={isDisabled}
					error={!!errors?.[name]?.message}
					variant='outlined'
					size={size || 'small'}
					helperText={errors?.[name]?.message || customHelper || ''}
					sx={{
						opacity: isDisabled ? '0.5' : '1',
						display: hidden ? 'none' : 'inherit',
					}}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<>
								{isLoading ? (
									<CircularProgress color='inherit' size={20} />
								) : null}
								{!isLoading && params.InputProps.endAdornment}
							</>
						),
					}}
					required={browserRequired && isrequired}
				/>
			)}
		/>
	)
}

export default SelectSearch
