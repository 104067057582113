import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
	memo,
} from 'react'

import { Avatar, Box, Chip, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CloseIcon from '@mui/icons-material/Close'
import {
	FormModalContext,
	IFormModalContext,
} from '../../formModal/FormModalContext'
import { formMultiSelectSearchCSS } from './formMultiSelectSearchCSS'

interface IFormMultiSelectSearchButton {
	name: string
	isDisabled?: boolean
	openModal: any
	setAnchor: any
	values: any
	isRequired?: boolean
	label: string
	nbChipsToDisplay?: number
	renderChipsLabel?: any
	specialOnChange?: any
	checkedOptionsCount: number
}

const loaderCSS = {
	position: 'absolute',
	zIndex: '10',
	backgroundColor: 'white',
	width: 'calc(100% - 18px)',
	height: '90%',
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'center',
	opacity: 1, // Initial opacity (visible)
	transition: 'opacity 0.5s', // Duration of the transition

	'& .loaderCircleCSS': {
		width: '25px !important',
		height: '25px !important',
	},
}

const FormMultiSelectSearchButton: React.FC<IFormMultiSelectSearchButton> = ({
	name,
	isDisabled,
	openModal,
	setAnchor,
	values,
	isRequired,
	label,
	nbChipsToDisplay = 1,
	renderChipsLabel = null,
	specialOnChange = null,
	checkedOptionsCount = 0,
}) => {
	const { setValue } = useContext<IFormModalContext>(FormModalContext)
	const chipRefs = useRef<(HTMLDivElement | null)[]>([])
	const contentRef = useRef<HTMLDivElement | null>(null)
	const [chips, setChips] = useState<
		{ id: number; label: string; width: number }[]
	>([])
	const [contentWidth, setContentWidth] = useState<number>(0)
	const [loader, setLoader] = useState(true)

	useEffect(() => {
		let timer

		const updateChips = () => {
			const newChipRefs = chipRefs.current.slice(0, checkedOptionsCount) // Copying the references of the original chips without directly modifying them

			const newChips = newChipRefs.map((ref, index) => ({
				id: index,
				label: ref?.textContent || '', // Retrieving the textual content of the chip if it exists
				width: parseFloat(
					((ref?.getBoundingClientRect().width || 0) + 5).toFixed(2)
				), // Calculation of the chip width taking into account a margin of 5
			}))

			const maxWidthChips = parseFloat(
				(contentWidth / nbChipsToDisplay + 5).toFixed(2)
			) // Calculation of the maximum chip width

			let totalChipWidths = 0

			const finalChips = newChips.map((chip) => {
				const width = chip.width > maxWidthChips ? maxWidthChips : chip.width // Adjusting the chip width if it exceeds the maximum width
				totalChipWidths += width
				return { ...chip, width }
			})

			const remainingSpace = contentWidth - totalChipWidths // Calculation of the remaining available space

			const averageWidthIncrement = remainingSpace / finalChips?.length // Calculate the average width increment per chip

			const adjustedFinalChips = finalChips.map((chip) => {
				const width = chip.width + averageWidthIncrement // Increment the width of each chip by the average width increment
				return { ...chip, width }
			})

			setChips(adjustedFinalChips) // Updating the state with the final chips
			setLoader(false) // Disabling the loader
		}

		if (checkedOptionsCount > 0 && contentWidth > 0) {
			timer = setTimeout(updateChips, 50)
		}

		return () => clearTimeout(timer) // Cleaning up the timer when the component is unmounted
	}, [checkedOptionsCount, contentWidth, nbChipsToDisplay])

	useEffect(() => {
		// Function to update the chips based on the current content width
		const updateChips = () => {
			if (contentRef.current) {
				const newContentWidth = contentRef.current.getBoundingClientRect().width
				// Update the content width state and perform calculations only if it has changed
				setContentWidth((prevContentWidth) => {
					if (newContentWidth !== prevContentWidth) {
						// Calculate the new maximum chip width based on the updated content width
						const newMaxWidthChips = parseFloat(
							(newContentWidth / nbChipsToDisplay + 5).toFixed(2)
						)
						// Update the chips by mapping over the previous chips and adjusting their width if necessary
						setChips((prevChips) =>
							prevChips.map((chip) => ({
								...chip,
								width:
									chip.width > newMaxWidthChips ? newMaxWidthChips : chip.width,
							}))
						)
					}
					return newContentWidth
				})
			}
		}

		const handleResize = () => {
			// Call the function to update the chips on window resize
			updateChips()
		}

		// Delay the execution to ensure the DOM is ready
		setTimeout(() => {
			// Add event listener for window resize
			window.addEventListener('resize', handleResize)
			// Call the function to update the chips initially
			updateChips()
		}, 10)

		return () => {
			// Clean up the event listener on component unmount
			window.removeEventListener('resize', handleResize)
		}
	}, [nbChipsToDisplay])

	const handleClickModal = useCallback(
		(event: React.MouseEvent<HTMLButtonElement>) => {
			if (!isDisabled) {
				openModal(), setAnchor(event.currentTarget)
			}
		},
		[isDisabled]
	)

	const handleChipDelete = useCallback(
		({ idToDelete, isDisabled }) =>
			() => {
				if (!isDisabled) {
					const newValues = {
						...values,
						[idToDelete]: {
							checked: false,
						},
					}
					specialOnChange
						? specialOnChange(name, newValues)
						: setValue(name, newValues)
				}
			},
		[setValue, values, name, specialOnChange]
	)

	const deleteSelected = useCallback(
		(e) => {
			e.preventDefault()
			e.stopPropagation()
			const newValues = Object.keys(values).reduce((obj, key) => {
				//If isDisabled is true, don't modify the object, otherwise set checked to false.
				obj[key] = values[key].isDisabled
					? values[key]
					: { ...values[key], checked: false }
				return obj
			}, {})

			specialOnChange
				? specialOnChange(name, newValues)
				: setValue(name, newValues)
		},
		[setValue, values, name, specialOnChange]
	)

	return (
		<Button
			disableRipple
			className='buttonField'
			variant='contained'
			onClick={handleClickModal}
			name={name}
			disabled={isDisabled}
		>
			<div className='overflow'>
				<Box className='buttonField-content' ref={contentRef}>
					<>
						{checkedOptionsCount >= nbChipsToDisplay + 1 && (
							<Avatar
								className='formMultiSelectSearchAvatar'
								sx={formMultiSelectSearchCSS.formMultiSelectSearchAvatar}
							>
								{checkedOptionsCount}
							</Avatar>
						)}

						{loader && <Box sx={loaderCSS}></Box>}
						{checkedOptionsCount <= nbChipsToDisplay &&
							Object.values(values)
								?.filter(({ checked }: any) => checked)
								?.map(({ value, isDisabled }: any, index: any) => (
									<Chip
										key={value?.id}
										label={
											renderChipsLabel ? renderChipsLabel(value) : value?.name
										}
										variant='outlined'
										onDelete={
											!isDisabled
												? handleChipDelete({
														idToDelete: value?.id,
														isDisabled,
												  })
												: undefined
										}
										className='formMultiSelectSearchChip'
										sx={formMultiSelectSearchCSS.formMultiSelectSearchChip(
											isDisabled
										)}
										ref={(el) => (chipRefs.current[index] = el)}
										style={{
											maxWidth: chips[index]?.width,
											transition: 'max-width .2s ease',
										}}
									/>
								))}

						{checkedOptionsCount >= nbChipsToDisplay + 1 && (
							<Typography className='button-label'>
								Élements sélectionnés
							</Typography>
						)}

						<Typography
							className='button-label-anim'
							sx={formMultiSelectSearchCSS.formMultiSelectSearchLabel(
								checkedOptionsCount
							)}
						>
							{label} {isRequired && '*'}
						</Typography>
					</>
				</Box>
				<Box className='buttonField-indicator'>
					{checkedOptionsCount >= 1 && (
						<Avatar
							onClick={deleteSelected}
							className='formMultiSelectSearchCSSButtonAvatar'
							data-test-id='Playwright-FormMultiSelectSearchButton-deleteAll'
						>
							<CloseIcon />
						</Avatar>
					)}
					<ArrowDropDownIcon />
				</Box>
			</div>
		</Button>
	)
}

export default memo(FormMultiSelectSearchButton)
