import { ESupportedLanguages } from '../../utils/app-models'

export const languages = [{ key: ESupportedLanguages.fr, label: 'Français' }]

export const defaultLanguage = ESupportedLanguages.fr

export const languageProperties = [
	{
		key: ESupportedLanguages.fr,
		label: 'misc.french',
		alt: 'french_flag',
		flag: 'https://upload.wikimedia.org/wikipedia/commons/c/c3/Flag_of_France.svg',
	},
	{
		key: ESupportedLanguages.en,
		label: 'misc.english',
		alt: 'english_flag',
		flag: 'https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_United_Kingdom_%283-5%29.svg',
	},
]
