import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { Paper, Stack, Divider, Fade } from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications'
import WidgetIconTitle from '../elements/WidgetIconTitle'
import { useAlertWidget } from '../../../api/gql/queries/alertsQueries'
import { useFormatMessage } from '../../../utils/hooks'
import { stringifiedColor } from '../../../utils/color'
import { formatGeneric } from '../../../utils/string'
import { optionsDoughnut } from './optionsDoughnut'
import LabelDoughnut from './LabelDoughnut'
import CircularProgressWidget from '../elements/CircularProgressWidget'

ChartJS.register(ArcElement, Tooltip, Legend)

interface IWAlerts {
	filterQueries
}

const WAlerts: React.FC<IWAlerts> = ({ filterQueries = {} }) => {
	const intlMsg = useFormatMessage()

	const [arrayColorCategoryAlert, setArrayColorCategoryAlert] = useState<any>()
	const [arrayCategoryCountAlert, setArrayCategoryCountAlert] = useState<any>()
	const [arrayCategoryNameAlert, setArrayCategoryNameAlert] = useState<any>()
	const [totalTreated, setTotalTreated] = useState<any>(0)

	const { data: alerts, loading } = useAlertWidget({
		variables: filterQueries,
	})

	useEffect(() => {
		if (alerts) {
			const alertFormatted: any = formatGeneric(alerts.alerts.collection)
			const alertTreatedByCategory = {}
			const colorCategory = {}

			alertFormatted?.forEach(
				({ category: { name, color }, status: { isTreated } }) => {
					if (isTreated) setTotalTreated((prevCount) => prevCount + 1)

					alertTreatedByCategory[name] = (alertTreatedByCategory[name] || 0) + 1
					colorCategory[name] = stringifiedColor(color, 400)
				}
			)

			setArrayColorCategoryAlert(Object.values(colorCategory))
			setArrayCategoryCountAlert(Object.values(alertTreatedByCategory))
			setArrayCategoryNameAlert(Object.keys(alertTreatedByCategory))
		}
	}, [alerts])

	const alertsCollection = useMemo(() => alerts?.alerts.collection, [alerts])

	const subTitle = useMemo(
		() =>
			alertsCollection?.length
				? `${totalTreated} / ${alerts?.alerts?.collection?.length}`
				: '0',
		[totalTreated, alerts]
	)

	const doughnutData: any = useMemo(
		() => ({
			datasets: [
				{
					data: arrayCategoryCountAlert,
					backgroundColor: arrayColorCategoryAlert,
					borderColor: arrayColorCategoryAlert,
					borderWidth: 0,
					weight: 0.5,
					hoverOffset: 8,
					borderAlign: 'inner',
				},
			],
		}),
		[arrayCategoryCountAlert, arrayColorCategoryAlert]
	)

	const renderArrayCategoryCountAlert = useCallback(
		(countAlert, i) => (
			<LabelDoughnut
				key={i}
				countAlert={countAlert}
				colorCategoryAlert={arrayColorCategoryAlert[i]}
				categoryNameAlert={arrayCategoryNameAlert[i]}
				totalAlert={alertsCollection?.length}
			/>
		),
		[arrayColorCategoryAlert, arrayCategoryNameAlert, alertsCollection]
	)

	return (
		<Paper elevation={1} sx={{ position: 'relative' }}>
			<Stack
				p={2}
				pb={totalTreated > 0 ? 0 : 2}
				justifyContent='center'
				sx={{ height: 1 }}
			>
				<Fade in={!loading}>
					<Stack>
						<Stack
							direction='row'
							justifyContent='space-between'
							alignItems='center'
						>
							<WidgetIconTitle
								icon={{
									iconColor: 'red',
									Icon: NotificationsIcon,
								}}
								title={intlMsg('widget.alertFinish')}
								subTitle={subTitle}
							/>
						</Stack>

						{totalTreated > 0 && (
							<>
								<Divider sx={{ mb: 2, mt: 3 }} />
								<Stack direction='row' spacing={1}>
									<Stack sx={{ height: 120, width: 120 }}>
										<Doughnut
											height={150}
											width={150}
											data={doughnutData}
											options={optionsDoughnut as any}
										/>
									</Stack>

									<Stack spacing={0.5}>
										{arrayCategoryCountAlert.map(renderArrayCategoryCountAlert)}
									</Stack>
								</Stack>
							</>
						)}
					</Stack>
				</Fade>
				{loading && <CircularProgressWidget />}
			</Stack>
		</Paper>
	)
}

export default WAlerts
