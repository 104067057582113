// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const flattenObject = (obj, parent: any = null, res = {}): unknown => {
	// eslint-disable-next-line no-restricted-syntax
	for (const [key, value] of Object.entries(obj)) {
		const propName = parent ? `${parent}.${key}` : key

		if (typeof value === 'object') {
			flattenObject(value, propName, res)
		} else {
			res[propName] = value
		}
	}

	return res
}

export default flattenObject
