import React from 'react'

import { Stack, Typography, Box } from '@mui/material'

const labelColorCSS = (colorCategoryAlert) => ({
	backgroundColor: colorCategoryAlert,
	height: '11px',
	width: '11px',
	borderRadius: '50%',
	mr: 0.5,
})

interface ILabelDoughnut {
	countAlert
	colorCategoryAlert
	categoryNameAlert
	totalAlert
}

const LabelDoughnut: React.FC<ILabelDoughnut> = ({
	countAlert,
	colorCategoryAlert,
	categoryNameAlert,
	totalAlert,
}) => {
	return (
		<Stack direction='row' alignItems='center' spacing={1}>
			<Box sx={labelColorCSS(colorCategoryAlert)} />
			<Typography sx={{ minWidth: '70px' }}>{categoryNameAlert}</Typography>
			<Typography>{Math.round((countAlert / totalAlert) * 100)}%</Typography>
		</Stack>
	)
}

export default LabelDoughnut
