import React from 'react'

import { Typography, Stack, Box } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import { useFormatMessage } from '../../../../utils/hooks'
import IconToolTips from '../../../icons/IconToolTips'

const numberCSS = (isDisabled) =>
	({
		backgroundColor: ({ palette }) => palette.primary.main,
		borderRadius: '55%',
		width: '30px',
		height: '30px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: 'white',
		marginRight: '10px',
		...(isDisabled && { backgroundColor: '#ddd' }),
	} as const)

const typographyCSS = { fontWeight: 500, display: 'flex', alignItems: 'center' }

interface ITableImportTitleStep {
	stepNumber: number
	disabled
	title: string
	tooltipsTxt?: string
}

const TableImportTitleStep: React.FC<ITableImportTitleStep> = ({
	stepNumber,
	disabled,
	title,
	tooltipsTxt,
}) => {
	const intlMsg = useFormatMessage()

	return (
		<Stack direction='row' justifyContent='space-between' alignItems='center'>
			<Typography sx={typographyCSS} variant='body1'>
				<Box sx={numberCSS(disabled)}>{stepNumber}</Box>
				{intlMsg(title)}
			</Typography>

			{tooltipsTxt && (
				<IconToolTips txt={tooltipsTxt} Icon={ErrorOutlineIcon} />
			)}
		</Stack>
	)
}

export default TableImportTitleStep
