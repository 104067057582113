import React, { useMemo } from 'react'
import { Stack, IconButton, Typography, Box } from '@mui/material'
import MuiDialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'

const MuiDialogTitleCSS = {
	p: [1, 1, 1, 2],
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
}

const closeButtonCSS = { color: ({ palette }) => palette.grey[500] }

interface IDialogTitle {
	id: string
	children: React.ReactNode
	onClose?: () => void
}

const DialogTitle: React.FC<IDialogTitle> = ({
	children,
	onClose,
	...other
}) => {
	const closeButton = useMemo(() => {
		if (onClose)
			return (
				<IconButton
					aria-label='close'
					sx={closeButtonCSS}
					onClick={onClose}
					size='large'
				>
					<CloseIcon />
				</IconButton>
			)

		return null
	}, [onClose])

	return (
		<MuiDialogTitle sx={MuiDialogTitleCSS} {...other}>
			<Box sx={{ width: onClose ? '90%' : 1 }}>
				<Typography variant='h5'>{children}</Typography>
			</Box>
			<Stack direction='row' justifyContent='flex-end'>
				{closeButton}
			</Stack>
		</MuiDialogTitle>
	)
}

export default DialogTitle
