import React, { useCallback, useContext, useState } from 'react'

import dayjs from 'dayjs'
import { Box, Button, Fade, Stack, Typography } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { grey } from '@mui/material/colors'

import { IModalUtils, useModal } from '../../../../../../../utils/hooks'
import {
	FormModalContext,
	IFormModalContext,
} from '../../../../../../../components/forms/formModal/FormModalContext'
import FormTextField from '../../../../../../../components/forms/formFields/FormTextField'
import FormSwitchButton from '../../../../../../../components/forms/formFields/FormSwitchButton'
import translateIntlMsgString from '../../../../../../../utils/translateIntlMsgString'
import FormDatePicker from '../../../../../../../components/forms/formFields/FormDatePicker'
import ConfirmModal from '../../../../../../../components/modal/confirmModal/index'

const typoCSS = { color: grey[600], fontWeight: 500 }

const moreOptionsCSS = (isDisplayMoreOption) => ({
	height: isDisplayMoreOption ? 'inherit' : '0px!important',
	display: isDisplayMoreOption ? 'inherit' : 'none',
})

const FormSwitchButtonConfig = {
	primary: { text: translateIntlMsgString('misc.active'), value: true },
	secondary: { text: translateIntlMsgString('misc.inactive'), value: false },
}

interface IMoreOptionsCatalogProduct {
	haveCrmModule: boolean
}

const MoreOptionsCatalogProduct: React.FC<IMoreOptionsCatalogProduct> = ({
	haveCrmModule,
}) => {
	const confirmStatusModalUtils: IModalUtils = useModal()
	const { setValue, setValues, data } =
		useContext<IFormModalContext>(FormModalContext)

	/////////////////////////////////////////////////////////
	// More/less options
	/////////////////////////////////////////////////////////

	const [isDisplayMoreOption, setIsDisplayMoreOption] = useState(false)

	const handleDisplayMoreOptions = useCallback(() => {
		setIsDisplayMoreOption(!isDisplayMoreOption)
	}, [isDisplayMoreOption])

	const BtnMoreOption = useCallback(
		() => (
			<Box>
				<Button
					size='small'
					color='primary'
					endIcon={
						!isDisplayMoreOption ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />
					}
					onClick={handleDisplayMoreOptions}
				>
					{translateIntlMsgString(
						`misc.${!isDisplayMoreOption ? 'moreOptions' : 'lessOptions'}`
					)}
				</Button>
			</Box>
		),
		[isDisplayMoreOption, handleDisplayMoreOptions]
	)

	/////////////////////////////////////////////////////////
	// Handle change date
	/////////////////////////////////////////////////////////

	const handleChangeDate = useCallback(
		({ fieldName: currentDateNameSelected, date: currentDateSelected }) => {
			const valuesToUpdate = {
				[currentDateNameSelected]: currentDateSelected,
			} as any

			const isDateContractFromSelected =
				currentDateNameSelected === 'dateContractFrom'

			const otherDateExist = isDateContractFromSelected
				? 'dateContractTo'
				: 'dateContractFrom'

			const dateContractFrom = dayjs(
				isDateContractFromSelected
					? currentDateSelected
					: data?.dateContractFrom
			).startOf('d')

			const dateContractTo = dayjs(
				isDateContractFromSelected ? data?.dateContractTo : currentDateSelected
			).startOf('d')

			if (
				data?.[otherDateExist] &&
				dayjs(currentDateSelected)[
					isDateContractFromSelected ? 'isAfter' : 'isBefore'
				](isDateContractFromSelected ? dateContractTo : dateContractFrom, 'm')
			) {
				valuesToUpdate[otherDateExist] = currentDateSelected
			}

			setValues(valuesToUpdate)
		},
		[setValues, data]
	)

	/////////////////////////////////////////////////////////
	// Status confirm modal
	/////////////////////////////////////////////////////////

	const onConfirmStatusModal = useCallback(() => {
		setValue('isActive', false)
		confirmStatusModalUtils.closeModal()
	}, [setValue])

	const handleSwitchIsActive = useCallback(
		({ value }) => {
			value ? setValue('isActive', true) : confirmStatusModalUtils.openModal()
		},
		[setValue]
	)

	return (
		<>
			<BtnMoreOption />

			<Box sx={moreOptionsCSS(isDisplayMoreOption)}>
				<Fade in={isDisplayMoreOption} timeout={500}>
					<Stack spacing={2} width={1}>
						<Box>
							<Typography variant='body2' sx={typoCSS} mb={1}>
								{translateIntlMsgString('formTemplate.description')}
							</Typography>
							<FormTextField
								name='description'
								label={translateIntlMsgString('catalogProduct.descProduct')}
								context={FormModalContext}
								multiline={true}
								minRows={2}
								maxRows={2}
							/>
						</Box>

						{haveCrmModule && (
							<Stack direction='row' spacing={2}>
								<FormDatePicker
									name='dateContractFrom'
									specialOnChange={handleChangeDate}
									label={translateIntlMsgString('misc.dateContractStart')}
									views={['day']}
									isStartOfDay={false}
									conserveHour
									context={FormModalContext}
									trigerred
								/>
								<FormDatePicker
									name='dateContractTo'
									specialOnChange={handleChangeDate}
									label={translateIntlMsgString('misc.dateContractEnd')}
									views={['day']}
									isStartOfDay={false}
									conserveHour
									context={FormModalContext}
									trigerred
								/>
							</Stack>
						)}

						<FormTextField
							name='idERP'
							label={translateIntlMsgString('catalogProduct.idGestionProduct')}
							context={FormModalContext}
						/>

						<Box>
							<Typography variant='body2' sx={typoCSS} gutterBottom>
								{translateIntlMsgString('misc.status')}
							</Typography>

							<FormSwitchButton
								name='isActive'
								config={FormSwitchButtonConfig}
								specialOnChange={handleSwitchIsActive}
								context={FormModalContext}
							/>

							{confirmStatusModalUtils?.isVisible && (
								<ConfirmModal
									onClose={confirmStatusModalUtils?.closeModal}
									open={confirmStatusModalUtils?.isVisible}
									onConfirmStatusModal={onConfirmStatusModal}
									message={translateIntlMsgString(
										'catalogProduct.modalConfirmProduct'
									)}
									severity='warning'
								/>
							)}
						</Box>
					</Stack>
				</Fade>
			</Box>
		</>
	)
}

export default MoreOptionsCatalogProduct
