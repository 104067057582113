/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

import { EFormTemplateBasicFields } from '../../../utils/app-models'
import translateIntlMsgString from '../../../utils/translateIntlMsgString'
import { globalConfigPDF } from '../globalConfigPDF'
import { imageFieldPDF } from '../tableCellField/imageFieldPDF'

const { margin, widthImgSignature, heightImgSignature } = globalConfigPDF

export const blockSignature = (doc, dataBlock, lastPositionsY) => {
	doc.autoTable({
		// Body add only the title (we use didParseCell after to change the title css & didDrawCell to add image signature)
		body: dataBlock.title,

		// Block position start Y (default use the last block final position Y)
		startY: dataBlock?.option?.startingY
			? lastPositionsY[dataBlock?.option?.startingY]
			: doc.lastAutoTable.finalY + 20,

		margin: { top: margin, bottom: margin },

		styles: {
			lineColor: 'white',
			lineWidth: 0,
			halign: 'center',
		},

		bodyStyles: {
			fillColor: 'white',
			textColor: 'black',
		},
		alternateRowStyles: {
			fillColor: 'white',
		},

		columnStyles: {
			0: {
				columnWidth: doc.internal.pageSize.getWidth() / 2 - 13.25,
				halign: 'left',
				valign: 'middle',
				cellPadding: { left: 10 },
			},
			1: {
				columnWidth: doc.internal.pageSize.getWidth() / 2 - 13.25,
				halign: 'left',
				valign: 'middle',
				cellPadding: { left: 5 },
			},
		},

		allSectionHooks: true,

		// Before autotable draw table
		didParseCell: (data) => {
			// Set title CSS
			if (data.row.index === 0) {
				data.cell.styles.fontSize = 11
				data.cell.styles.fontStyle = 'bold'
			}
		},

		// After autotable draw table
		didDrawCell: function ({ row, column, cell }) {
			// get the current type value
			const valueType = dataBlock.record[row.index][column.dataKey]?.type

			// Display the record value
			// cell type = SIGNATURE /////////////////////////////
			if (valueType === EFormTemplateBasicFields.signature) {
				// get informations of current cell row
				const { x: positionXcell, y: positionYcell } = cell

				// get the current record value
				const valueRowCell =
					dataBlock.record[row.index][column.dataKey]?.record?.toString()

				if (dataBlock.record[row.index][column.dataKey]?.clientSkipped) {
					doc.setFontSize(8)
					doc.setTextColor('#767676')
					doc.text(
						dataBlock.record[row.index][column.dataKey]?.clientSkipped || '',
						positionXcell + 5,
						positionYcell + 10,
						{ baseline: 'middle' }
					)
				} else {
					imageFieldPDF(doc, valueRowCell, {
						positionYcell: positionYcell + 5,
						positionXcell: positionXcell + 10,
						widthImg: widthImgSignature,
						heightImg: heightImgSignature,
					})

					if (dataBlock.record[row.index][column.dataKey]?.contactName) {
						doc.setFontSize(8)
						doc.setTextColor('#767676')
						doc.text(
							`${translateIntlMsgString('pdfForms.contact')} ${
								dataBlock.record[row.index][column.dataKey].contactName
							}`,
							positionXcell + 5,
							positionYcell + 12 + heightImgSignature,
							{ baseline: 'middle' }
						)
					}
				}
			}
		},
	})

	// add blank autotable to fix & add margin bottom
	doc.autoTable()
	doc.autoTable()
	doc.autoTable()
}
