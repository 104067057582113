/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
	gql,
	OperationVariables,
	QueryHookOptions,
	useLazyQuery,
	useQuery,
} from '@apollo/client'

import {
	listCustomersAttributes,
	listCustomerAttributes,
} from '../attributes/customers'

/**  ---------------------QUERIES---------------------- */

export const LIST_CUSTOMERS = gql`
query getCustomersList(
    $page: Int
    $itemsPerPage: Int
    $isActive: Boolean
    $searchText: String
	$orderBy: [CustomerFilter_orderBy]
) {
    customers(page: $page, itemsPerPage: $itemsPerPage, isActive: $isActive,searchText: $searchText, orderBy: $orderBy) {
        ${listCustomersAttributes}
    }
}`

export const EXPORT_CUSTOMERS = gql`
	query exportCustomers(
		$isActive: Boolean
		$searchText: String
		$timezone: Int
		$orderBy: [CustomerFilter_orderBy]
	) {
		exportCustomers(
			isActive: $isActive
			searchText: $searchText
			timezone: $timezone
			orderBy: $orderBy
		) {
			id
			exportFile
		}
	}
`

export const CUSTOMERS_NUMBERS = gql`
	query getCustomersNumbers($isActive: Boolean, $searchText: String) {
		customers(
			page: 1
			itemsPerPage: 100000
			isActive: $isActive
			searchText: $searchText
		) {
			paginationInfo {
				totalCount
			}
		}
	}
`

export const GET_CUSTOMER = gql`
query getCustomer(
    $id: ID!
) {
    customer(id: $id) {
        ${listCustomerAttributes} 
    }
}`

export const GET_CUSTOMER_FOR_MODAL = gql`
	query getCustomerForModal($id: ID!) {
		customer(id: $id) {
			id
			name
			address
			zipcode
			siret
			tva
			city
			country
			socialName
			gestionnaire {
				id
				lastName
				firstName
			}
		}
	}
`

export const GET_CUSTOMER_FOR_MODAL_SELECT_INITIALISATION = gql`
	query getCustomerForModalSelectInitialisation($id: ID!) {
		customer(id: $id) {
			id
			name
		}
	}
`

export const GET_CUSTOMERS_LIST_FOR_SELECT = gql`
	query getCustomerListsForSelect($mycust: Boolean) {
		customers(itemsPerPage: 100000, isActive: true, mycust: $mycust) {
			collection {
				id
				name
			}
		}
	}
`

/**  ---------------------HOOKS---------------------- */

export const useListCustomers = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { loading, data } = useQuery(LIST_CUSTOMERS, options)
	return { loading, data: data?.customers?.collection || null }
}

export const useGetCustomerListForSelect = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { loading, data } = useQuery(GET_CUSTOMERS_LIST_FOR_SELECT, options)

	return {
		loading,
		customers: data?.customers?.collection || null,
	}
}

export const useLazyCustomer = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(GET_CUSTOMER, options)
	return { loading, data: data?.customer || null, getCustomer: getData }
}

export const useLazyCustomerForModal = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(
		GET_CUSTOMER_FOR_MODAL,
		options
	)
	return { loading, data: data?.customer || null, getCustomer: getData }
}

export const useLazyCustomerForModalSelectInitialisation = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const [getData, { loading, data }] = useLazyQuery(
		GET_CUSTOMER_FOR_MODAL_SELECT_INITIALISATION,
		options
	)
	return { loading, data: data?.customer || null, getCustomer: getData }
}

export const useExportCustomers: any = (
	options?: QueryHookOptions<any, OperationVariables> | undefined
) => {
	const { data, loading, error } = useQuery(EXPORT_CUSTOMERS, options)

	return {
		exportFile: data?.exportCustomers?.[0]?.exportFile || null,
		loading,
		error,
	}
}
