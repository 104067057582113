export const commonSiteAttributes = `
    id
    name
    phone
    cellphone
    address
    city
    zipcode
    isActive
    country
    siret
    comment
    dateCreated
    isSharedWithMe
    sharedToAgencies(itemsPerPage: 99999) {
        collection {
            id
            agencyCustomDetails {
                id
                agencyName
              }
            networks(itemsPerPage: 99999) {
                collection {
                    id
                    name
                }
            }
        }
      }
    vehicles{
            paginationInfo {
                totalCount
            }
    }
    customer{
        id
        name
    }
    gestionnaire{
        id
        firstName
        lastName
        account {
            id
          }
    }
    contacts(itemsPerPage: 99999) {  
        collection {
            id,
            firstName,
            lastName,
            isSiteResp
        }
    } 
`
export const commonSitesAttributes = `
    id
    name
    phone
    cellphone
    address
    city
    zipcode
    isActive
    country
    siret
    comment
    isSharedWithMe
    vehicles (isActive: true){
        paginationInfo {
            totalCount
        }
    }
    customer{
        id
        name        
        accountGestionnaire {
            id
            name
        }
    }
    gestionnaire{
        id
        firstName
        lastName
    }
 
`

export const listSiteAttributes = `
    collection {
        ${commonSitesAttributes}  
    }
    paginationInfo {
        totalCount
    }
`

export const listSitesAttributes = `
    collection {
        ${commonSitesAttributes}
        sharedToAgencies(itemsPerPage: 100000) {
            collection {
                id
                name
                agencyCustomDetails {
                    id
                    agencyName
                  }
            }
        }
       
    }
    paginationInfo {
        totalCount
    }
`

export const sitePayloadAttributes = `
    site {
        ${commonSiteAttributes}
    }
`

//----------

export const mutationCustomerSiteAttributes = `
    id
    name
    isActive
    dateCreated
`

export const customerSitePayloadAttributes = `
    site {
        ${mutationCustomerSiteAttributes}
    }
`

//-----------

export const removeCustomerSitePayloadAttributes = `
        site {
            id
        }
`

export const bulkActionSitePayloadAttributes = `
    site {
        id
    }
`
