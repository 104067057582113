import React from 'react'

import TableProvider from '../../providers/TableProvider'
import { IOverrideTableProvider } from '../../utils/typescript/tableType'
import TableMain from './TableMain'

interface ITable {
	//old
	tableProviderConfig?
	tableMainConfig?
	//
	overrideConfigTable?
	othersQueryParams?
	overrideTableProvider?: IOverrideTableProvider
	isInViewModal?: boolean
	forcedData?: any
	onMultiDelete?: any
	//new
	tableConfig?
	//new
}

const Table: React.FC<ITable> = ({
	overrideConfigTable,
	tableProviderConfig,
	othersQueryParams,
	overrideTableProvider,
	isInViewModal = false,
	forcedData = null,
	onMultiDelete = null,
	tableConfig,
}) => {
	return (
		<TableProvider
			//old
			tableProviderConfig={tableProviderConfig}
			//old
			hidden={overrideConfigTable?.hidden}
			customStateSelectedRow={overrideConfigTable?.customStateSelectedRow}
			overrideTableProvider={overrideTableProvider}
			isInViewModal={isInViewModal}
			forcedData={forcedData}
			onMultiDelete={onMultiDelete}
			//new
			tableConfig={tableConfig}
			//new
		>
			<TableMain
				//old
				//need refacto - need to use context
				tableMainConfig={tableConfig}
				//old
				overrideConfigTable={
					overrideConfigTable && typeof overrideConfigTable === 'function'
						? overrideConfigTable({ othersQueryParams })
						: overrideConfigTable
				}
			/>
		</TableProvider>
	)
}

export default Table
