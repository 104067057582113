/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/display-name */
import React from 'react'

import Typography from '@mui/material/Typography'
import LocationAddress from '../../../../locationAddress'
import BooleanColorPopover from '../../../../popover/BooleanColorPopover'
import OpenLinkCell from '../../../tableCell/OpenLinkCell'
import {
	useRemoveSite,
	useUpdateSite,
} from '../../../../../api/gql/mutations/customerSitesMutations'
import {
	EModule,
	EPermission,
	EPermissionScope,
} from '../../../../../utils/app-models'
import { ROUTES } from '../../../../../routes/consts'
import { Stack } from '@mui/material'
import PhoneCell from '../../../tableCell/PhoneCell'
import ChipCountListPopover from '../../../../multiItemsPopoverList/popover/simpleListPopover'
import CustomerSiteModal from '../../../../../view/dashboard/customerSites/customerSiteModal'
import ActionsBtn from '../../../tableCell/actionsBtn'
import { Box } from '@mui/material'

const customerListSitesViewModalTable = [
	{
		columnLabel: 'misc.generalAccount',
		permissions: [EPermission.superMegaAdmin],
		personalizedCell: ({ customer }) => (
			<Box>{customer?.accountGestionnaire?.name}</Box>
		),
	},
	{
		columnLabel: 'misc.name',
		orderBy: 'name',
		stickyFirstCol: true,
		personalizedCell: ({ id, name, isSharedWithMe }) => (
			<OpenLinkCell
				viewModalLink={`${ROUTES.VIEWMODAL.SITE.url}${id}`}
				txt={name}
				isSharedWithMe={isSharedWithMe}
				maxCharacters={40}
			/>
		),
	},

	{
		columnLabel: 'misc.address',
		orderBy: 'address',
		personalizedCell: ({ address, city, zipcode }) => (
			<LocationAddress
				address={address}
				city={city}
				zipcode={zipcode}
				maxCharacters={40}
			/>
		),
	},

	{
		columnLabel: 'misc.phone',
		personalizedCell: (row: any) => (
			<Stack direction='row' alignItems='center' spacing={1}>
				<PhoneCell txt={row.phone} icon='phone' />
				<PhoneCell txt={row.cellphone} icon='cellphone' />
			</Stack>
		),
	},

	{
		columnLabel: 'misc.numberVehicle',
		personalizedCell: (row: any) => {
			return <div>{row.vehicles?.paginationInfo?.totalCount || '-'}</div>
		},
	},

	{
		columnLabel: 'misc.manager',
		orderBy: 'gestionnaire_lastName',
		personalizedCell: (row: any) => {
			return (
				<Typography variant='caption'>{`${row?.gestionnaire?.firstName || ''} ${
					row?.gestionnaire?.lastName || ''
				} `}</Typography>
			)
		},
	},

	{
		columnLabel: 'misc.active',
		orderBy: 'isActive',
		tableCellAlign: 'center',
		cellAlign: 'center',
		personalizedCell: (row: any) => {
			return (
				<BooleanColorPopover
					value={row.isActive}
					id={row.id}
					txt={{ true: 'misc.active', false: 'misc.desactive' }}
					titlePopover={'misc.siteStatusState'}
					queryConfig={{
						query: useUpdateSite,
						refreshQuery: ['getSitesList', 'getSitesNumbers'],
						successMessage: 'success.statusUpdate',
					}}
					permissions={[EPermissionScope.siteManage]}
				/>
			)
		},
	},

	{
		columnLabel: 'misc.agencies',
		modules: [EModule.network],
		orderBy: 'sharedToAgencies_agencyCustomDetails_agencyName',
		personalizedCell: ({ sharedToAgencies }) => (
			<ChipCountListPopover
				text='misc.agencies'
				data={
					sharedToAgencies?.collection?.map(({ agencyCustomDetails, id }) => ({
						name: agencyCustomDetails[0]?.agencyName,
						id,
					})) || []
				}
				viewModalLink={ROUTES.VIEWMODAL.AGENCIES.url}
				permissions={[
					EPermissionScope.networkView,
					EPermissionScope.networkManage,
				]}
			/>
		),
	},

	{
		fixWidth: '50px',
		columnLabel: '',
		visible: true,
		tableCellAlign: 'center',
		cellAlign: 'center',
		sticky: true,
		personalizedCell: ({ id, isSharedWithMe }) => (
			<ActionsBtn
				rowId={id}
				deleted={{
					query: useRemoveSite,
					refreshQuery: ['getSitesList', 'getSitesNumbers'],
					successMessageDelete: 'success.siteDeleted',
					messageConfirm: {
						title: 'confirmModal.deleteCustomerSiteTitle',
						message: 'confirmModal.deleteCustomerSiteMessage',
					},
				}}
				editModal={<CustomerSiteModal siteId={id} modalUtils={{} as any} />}
				viewModalLink={`${ROUTES.VIEWMODAL.SITE.url}${id}`}
				permissions={{
					update: [EPermissionScope.siteManage],
					remove: [EPermissionScope.siteManage],
					delete: [EPermissionScope.siteDelete],
				}}
				isSharedWithMe={isSharedWithMe}
			/>
		),
	},
]

export default customerListSitesViewModalTable
