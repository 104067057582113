import { listSiteAttributes } from './sitesAttributes'

export const commonTeamAttributes = `
    id
    name
    isActive
    dateCreated
    members
`
export const listTeamAttributes = `
    collection {
        id
        name
        isActive
        dateCreated
        members
        sites(itemsPerPage: 100000){
            ${listSiteAttributes}
        }
        networks(itemsPerPage: 100000){
            collection{
                id
                name
            }
        }
    }
    paginationInfo {
        totalCount
        lastPage
    }
`

export const mutationTeamAttributes = `
    id
    name
    isActive
    dateCreated
`

export const teamPayloadAttributes = `
    team {
        ${mutationTeamAttributes}
    }
`
