import React from 'react'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import InfoIcon from '@mui/icons-material/Info'
import { Stack, Divider } from '@mui/material'

import { stringifiedColor } from '../../../../../utils/color'
import { useFormatMessage } from '../../../../../utils/hooks'

interface IVehicleModalTitle {
	canDisplayRest: boolean
}

const VehicleModalTitle: React.FC<IVehicleModalTitle> = ({
	canDisplayRest,
}) => {
	const intlMsg = useFormatMessage()

	return (
		<Stack direction='row' alignItems='center' sx={{ mb: 4 }}>
			<Grid container spacing={5}>
				<Grid item xs={canDisplayRest ? 6 : 12}>
					<Stack
						direction='row'
						alignItems='center'
						divider={<Divider orientation='vertical' flexItem />}
						spacing={1}
					>
						<Typography
							variant='subtitle1'
							sx={{ fontWeight: 500 }}
							display='inline'
						>
							{intlMsg('vehicle.identificationTitle')}
						</Typography>
						{!canDisplayRest && (
							<Stack direction='row' alignItems='center'>
								<InfoIcon
									style={{
										marginRight: '0.5rem',
										color: stringifiedColor('orange', 500) as any,
									}}
								/>
								<Typography
									variant='subtitle1'
									display='inline'
									sx={{
										color: stringifiedColor('orange', 500) as any,
									}}
								>
									{intlMsg('vehicle.enterTitle')}
								</Typography>
							</Stack>
						)}
					</Stack>
				</Grid>
				{canDisplayRest && (
					<Grid item xs={6}>
						<Typography
							variant='subtitle1'
							sx={{ fontWeight: 500 }}
							display='inline'
						>
							{intlMsg('vehicle.denominationTitle')}
						</Typography>
					</Grid>
				)}
			</Grid>
		</Stack>
	)
}

export default VehicleModalTitle
