import React, { useMemo } from 'react'
import Typography from '@mui/material/Typography'
import { Box, Stack, Tooltip } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import CheckboxListIsManager from './CheckboxListIsManager'
import {
	useFormatMessage,
	useInfiniteScrollWithSearch,
} from '../../utils/hooks'
import SearchField from '../searchField/SearchField'
import NothingToDisplay from '../alert/NothingToDisplay'

const wrapperModalCSS = { p: 4, overflow: 'auto' }

interface IFormSearchCheckboxListIsManager {
	queryName: string
	leftColumName: string
	leftColumNameTooltips?: string
	rightColumnName: string
	renderLeftPartTitle: (elem: any) => any
	query: any
	playwrightKeySelector: string
}

const FormSearchCheckboxListIsManager: React.FC<
	IFormSearchCheckboxListIsManager
> = ({
	query,
	queryName,
	renderLeftPartTitle,
	leftColumName,
	leftColumNameTooltips,
	rightColumnName,
	playwrightKeySelector,
}) => {
	const intlMsg = useFormatMessage()
	const { handleOnScroll, handleChangeSearch, dataList, loading } =
		useInfiniteScrollWithSearch({
			queryConfig: {
				query,
				queryName,
			},
		})

	const tooltipTitle = useMemo(
		() => (leftColumNameTooltips ? intlMsg(leftColumNameTooltips) : null),
		[leftColumNameTooltips, intlMsg]
	)

	const renderLeftColumn = useMemo(
		() => (
			<Stack direction='row' alignItems='center' spacing={1}>
				<Typography variant='button'>{intlMsg(leftColumName)}</Typography>
				{tooltipTitle && (
					<Tooltip title={tooltipTitle}>
						<InfoOutlinedIcon />
					</Tooltip>
				)}
			</Stack>
		),

		[intlMsg, leftColumName, tooltipTitle]
	)

	const renderRightColumn = useMemo(
		() => <Typography variant='button'>{intlMsg(rightColumnName)}</Typography>,
		[intlMsg, rightColumnName]
	)

	const renderCheckboxList = useMemo(() => {
		if (dataList?.length < 1) return <NothingToDisplay />

		return (
			<CheckboxListIsManager
				renderLeftPartTitle={renderLeftPartTitle}
				handleOnScroll={handleOnScroll}
				dataList={dataList}
				playwrightKeySelector={playwrightKeySelector}
			/>
		)
	}, [dataList, renderLeftPartTitle, handleOnScroll, playwrightKeySelector])

	return (
		<Box sx={wrapperModalCSS}>
			<Stack spacing={2} mb={1}>
				<SearchField
					placeholder={'search.default'}
					onChange={handleChangeSearch}
					fullWidth
					loading={loading}
				/>
				<Stack
					direction='row'
					justifyContent='space-between'
					alignItems='center'
				>
					{renderLeftColumn}
					{renderRightColumn}
				</Stack>
			</Stack>
			{renderCheckboxList}
		</Box>
	)
}

export default FormSearchCheckboxListIsManager
