import React, { useState } from 'react'

import { Stack, Typography, Grid, Button } from '@mui/material'

import ForgottenPasswordFormRequest from './ForgottenPasswordFormRequest'
import LayoutLogout from '../../components/layout/LayoutLogout'
import { useFormatMessage } from '../../utils/hooks'
import { blockLeftCSS } from '../../styles/sxCSS/logout/logout'

const ForgottenPassword: React.FC = () => {
	const [requestSend, setRequestSend] = useState(false)
	const intlMsg = useFormatMessage()

	return (
		<LayoutLogout>
			<Grid container sx={{ height: 1 }}>
				<Grid
					item
					xs={12}
					md={6}
					sx={{ ...blockLeftCSS, alignItems: requestSend ? 'end' : 'center' }}
				>
					<Stack p={0} sx={{ width: { xs: 300, md: 400 } }}>
						<img
							src={`/img/illustration/${
								requestSend ? 'email' : 'password-1'
							}.png`}
						/>
					</Stack>
				</Grid>

				<Grid item xs={12} md={6}>
					{!requestSend ? (
						<ForgottenPasswordFormRequest setRequestSend={setRequestSend} />
					) : (
						<Stack p={8} spacing={2} justifyContent='center' sx={{ height: 1 }}>
							<Typography variant='h1'>
								{intlMsg('user.checkEmailBoxTitle')}
							</Typography>
							<Typography variant='body2'>
								{intlMsg('user.checkEmailBoxText')}
							</Typography>

							<Button variant='contained' href='/login' fullWidth>
								{intlMsg('misc.connexion')}
							</Button>
						</Stack>
					)}
				</Grid>
			</Grid>
		</LayoutLogout>
	)
}

export default ForgottenPassword
