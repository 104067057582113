import React, { useCallback, useMemo, useState } from 'react'

import TasksAndCategoriesListItem from '../tasksAndCategoriesPopover/TasksAndCategoriesListItem'
import { useFormatMessage } from '../../../../utils/hooks'
import MultiItemsButton from '../../MultiItemsButton'
import MultiItemsPopover from '..'

interface ITasksTransport {
	removalProducts?
	deliveryProducts?
}

const TasksTransport: React.FC<ITasksTransport> = ({
	removalProducts = [],
	deliveryProducts = [],
}) => {
	const intlMsg = useFormatMessage()
	const [anchorEl, setAnchorEl] = useState(null)
	const handleClick = useCallback((e) => {
		setAnchorEl(e.currentTarget)
	}, [])
	const handleClose = useCallback(() => setAnchorEl(null), [])
	const open = useMemo(() => Boolean(anchorEl), [anchorEl])
	const numberProducts = useMemo(
		() => removalProducts?.length + deliveryProducts?.length,
		[removalProducts, deliveryProducts]
	)

	const formattedTask = useMemo(
		() => ({
			...(removalProducts?.length && {
				[intlMsg('interventions.removal')]: [
					...removalProducts.map(({ product: { name } }) => name),
				],
			}),
			...(deliveryProducts?.length && {
				[intlMsg('interventions.delivery')]: [
					...deliveryProducts.map(({ product: { name } }) => name),
				],
			}),
		}),
		[removalProducts, deliveryProducts]
	)

	const label = useMemo(() => {
		if (numberProducts > 1) return intlMsg('interventions.task')
		else {
			const [category, task] = Object.entries(formattedTask)?.[0] || []
			return `${category} - ${task}`
		}
	}, [numberProducts, formattedTask, intlMsg])

	return (
		<>
			<MultiItemsButton
				buttonType={numberProducts > 1 ? 'chipCountClickable' : 'chip'}
				nbItems={numberProducts}
				label={label}
				handleClick={handleClick}
			/>

			{open && (
				<MultiItemsPopover
					open={open}
					handleClose={handleClose}
					anchorEl={anchorEl}
				>
					<TasksAndCategoriesListItem data={formattedTask} />
				</MultiItemsPopover>
			)}
		</>
	)
}

export default TasksTransport
