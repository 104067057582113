import { gql, useMutation } from '@apollo/client'

export const CREATE_INTERVENTION = gql`
	mutation createIntervention($input: createInterventionInput!) {
		createIntervention(input: $input) {
			intervention {
				id
				reference
			}
		}
	}
`

export const UPDATE_INTERVENTION = gql`
	mutation updateIntervention($input: updateInterventionInput!) {
		updateIntervention(input: $input) {
			intervention {
				id
				reference
			}
		}
	}
`

export const REMOVE_INTERVENTION = gql`
	mutation removeIntervention($input: removeInterventionInput!) {
		removeIntervention(input: $input) {
			intervention {
				id
			}
		}
	}
`

export const UPDATE_INTERVENTION_STATUSES = gql`
	mutation updateStatusIntervention($input: updateStatusInterventionInput!) {
		updateStatusIntervention(input: $input) {
			intervention {
				id
			}
		}
	}
`

export const BULK_INTERVENTION_ACTION = gql`
	mutation bulkOperationIntervention($input: bulkOperationInterventionInput!) {
		bulkOperationIntervention(input: $input) {
			intervention {
				id
			}
		}
	}
`

export const useCreateIntervention = (): any => useMutation(CREATE_INTERVENTION)
export const useUpdateIntervention = (): any => useMutation(UPDATE_INTERVENTION)
export const useRemoveIntervention = (): any => useMutation(REMOVE_INTERVENTION)

export const useUpdateInterventionStatuses = (): any =>
	useMutation(UPDATE_INTERVENTION_STATUSES)

export const useBulkInterventionAction = (): any =>
	useMutation(BULK_INTERVENTION_ACTION)
