import React, { useState, useCallback } from 'react'

import { useNavigate, useLocation } from 'react-router-dom'

import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { Popover, List } from '@mui/material'

import { useModal } from '../../../../../utils/hooks'
import ConfirmDelete from '../../../../modal/ConfirmDelete'
import { handleOpenViewModal } from '../../../../../utils/utils'
import duplicateTableRow from '../actions/duplicateTableRow'
import ActionBtn from '../ActionBtn'
import SlidePanelUserInfos from '../../../../slidepanel/user'

interface IPopoverActionsBtn {
	rowId
	actions
	popover
	viewModalLink?
	permissions?
	editPath?
	isContact?
	withSlide?
	handleEditClick?
}

const PopoverActionsBtn: React.FC<IPopoverActionsBtn> = ({
	rowId,
	actions: { viewAction = true, duplicate = null, Edit = null, deleted = null },
	popover: { open, anchorEl, handleClose },
	viewModalLink,
	permissions = null,
	editPath = null,
	isContact,
	withSlide,
	handleEditClick,
}) => {
	const navigate = useNavigate()
	const location: any = useLocation()

	// Duplicate ///////////////////////////////////
	const duplicatedTableRow =
		(duplicate && duplicateTableRow(duplicate, rowId)) || {}

	// Warning Modal /////////////////////////////
	const {
		isVisible: isVisibleWarning,
		openModal: openModalWarning,
		closeModal: closeModalWarning,
	} = useModal()

	const closeActions = useCallback(() => {
		handleClose()
		closeModalWarning()
	}, [])

	// open slidePanel /////////////////////////////
	const [stateSlidePanel, setStateSlidePanel] = useState(false)
	const toggleDrawer = useCallback(
		(open: boolean) => () => {
			setStateSlidePanel(open)
		},
		[stateSlidePanel]
	)

	return (
		<>
			<Popover open={open} anchorEl={anchorEl} onClose={handleClose}>
				<List dense={false} component='nav'>
					{viewAction && (
						<ActionBtn
							onMouseDown={
								viewModalLink
									? handleOpenViewModal(
											navigate,
											location,
											viewModalLink,
											handleClose
									  )
									: toggleDrawer(true)
							}
							txtIntlMsg='misc.see'
							Icon={VisibilityIcon}
						/>
					)}

					{(Edit || editPath) && (
						<ActionBtn
							permissionsName={permissions?.update}
							onClick={handleEditClick}
							txtIntlMsg='misc.edit'
							Icon={EditIcon}
						/>
					)}

					{duplicate && (
						<ActionBtn
							permissionsName={permissions?.update}
							onClick={duplicatedTableRow}
							txtIntlMsg='misc.duplicate'
							Icon={FileCopyIcon}
						/>
					)}

					{deleted && (
						<ActionBtn
							permissionsName={permissions?.remove}
							onClick={openModalWarning}
							txtIntlMsg='misc.deleted'
							Icon={DeleteIcon}
						/>
					)}
				</List>
			</Popover>

			{isVisibleWarning && deleted?.messageConfirm && (
				<ConfirmDelete
					rowId={rowId}
					handleClose={handleClose}
					onClose={closeActions}
					open={isVisibleWarning}
					deleted={deleted}
				/>
			)}

			{stateSlidePanel && !viewModalLink && withSlide && (
				<SlidePanelUserInfos
					id={rowId as string}
					state={stateSlidePanel}
					toggleDrawer={toggleDrawer}
					isContact={isContact}
					additionalClose={handleClose}
				/>
			)}
		</>
	)
}

export default PopoverActionsBtn
