export const filterData = (data, searchText) => {
	if (!searchText) return data

	const searchElements = searchText.trim().toLowerCase().split(' ')

	const filteredData = data?.filter((elem) => {
		return searchElements.every((searchElement) => {
			return Object.entries(elem).some(([key, value]: any) => {
				switch (key) {
					case 'vehicleDetail':
						return [
							'vin',
							'immat',
							'vehicleModelText',
							'vehicleBrand.name',
							'vehicleCategory.name',
						].some((subKey) =>
							String(value?.[subKey]).toLowerCase().includes(searchElement)
						)

					case 'products':
						return value?.some((product) =>
							String(product?.value?.name).toLowerCase().includes(searchElement)
						)

					case 'formTemplates':
						return value?.some((formTemplate) =>
							String(formTemplate?.value?.name)
								.toLowerCase()
								.includes(searchElement)
						)

					default:
						return false
				}
			})
		})
	})

	return filteredData
}

export const sortData = (data, orderBy = null) => {
	if (!orderBy || !data?.length) return data

	const orderKey = Object.keys(orderBy)[0]
	const order = Object.values(orderBy)[0]

	const sortByAlphabetical = (prev, next) => {
		const nestedPrev = orderKey
			.split('_')
			.reduce((obj, key) => obj?.[key], prev)
		const nestedNext = orderKey
			.split('_')
			.reduce((obj, key) => obj?.[key], next)

		if (nestedPrev < nestedNext) return order === 'asc' ? -1 : 1
		if (nestedPrev > nestedNext) return order === 'asc' ? 1 : -1
		return 0
	}

	return data.sort(sortByAlphabetical)
}

export const paginateData = (data, page, itemPerPage) => {
	const startIndex = (page - 1) * itemPerPage
	const endIndex = startIndex + itemPerPage
	return data.slice(startIndex, endIndex)
}
