import React, { useContext, useMemo } from 'react'

import { Stack } from '@mui/material'

import { AuthContext } from '../../../../utils/contexts/AuthContext'
import { EAccountType } from '../../../../utils/app-models'
import TransportRef from './TransportRef'
import VehicleInfos from './VehicleInfos'
import PublicComment from './PublicComment'
import TransportRequest from './TransportRequest'
import TransportActors from './TransportActors'

interface IInterventionsTransportInformation {
	data
}

const InterventionsTransportInformation: React.FC<
	IInterventionsTransportInformation
> = ({ data }) => {
	const { checkAccountsType } = useContext(AuthContext)
	const currentUserIsGuest = useMemo(
		() => checkAccountsType([EAccountType.guest]),
		[]
	)
	const isSharedWithMe = useMemo(
		() => data?.isSharedWithMe,
		[data?.isSharedWithMe]
	)

	return (
		<Stack padding={2} spacing={1}>
			<TransportRef
				interRef={data?.reference}
				transportType={data?.interventionTransportType?.code}
				status={data.status}
				interventionTransportTrips={data?.interventionTransportTrips}
			/>
			<VehicleInfos
				ipv={
					data?.interventionControlProgrammedVehicles?.collection[0]?.vehicle
				}
			/>

			{!isSharedWithMe && (
				<TransportRequest
					orderingCustomer={data?.orderingCustomer}
					orderingSite={data?.orderingSite}
					externalReference={data?.externalReference}
					airTableReference={data?.airTableReference}
				/>
			)}
			{!currentUserIsGuest && (
				<TransportActors
					actors={data?.actors?.collection}
					agencies={data?.agencies?.collection}
					manager={data?.manager}
					isSharedWithMe={isSharedWithMe}
				/>
			)}

			<PublicComment comment={data?.publicComment} />
		</Stack>
	)
}

export default InterventionsTransportInformation
