import React from 'react'

import ArrowDropDownTwoToneIcon from '@mui/icons-material/ArrowDropDownTwoTone'
import { Box, IconButton, Stack } from '@mui/material'

import { useFormatMessage } from '../../../utils/hooks'
import { stringifiedColor } from '../../../utils/color'
import { isValidHexColor } from '../../../utils/utils'

const iconButtonCSS = {
	width: '1.2em',
	height: '1.2em',
	mr: '-3px',
}
const iconCSS = { width: '1em', height: '1em' }

const containerFilterCSS = {
	display: 'inline-flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	border: `solid 1px ${'#c4c4c4'}`,
	p: 1,
	height: '36px',
	width: 1,
	borderRadius: 1,
	mr: 1,
	position: 'relative',
	'&:hover': {
		border: `solid 1px ${'#2e2e2e'}`,
		cursor: 'pointer',
	},
}

const titreCSS = { mr: 1, color: '#00000080' }

const color = (colorLabel) => ({
	height: '10px',
	width: '10px',
	marginRight: '5px',
	marginLeft: '-5px',
	borderRadius: 1,
	backgroundColor: isValidHexColor(colorLabel)
		? colorLabel
		: stringifiedColor(colorLabel, 400),
})

interface IFilterBtn {
	title
	value
	onClick
	colorLabel?
	withColorLabel?
}

const FilterBtn: React.FC<IFilterBtn> = ({
	title,
	value,
	onClick,
	colorLabel,
	withColorLabel = false,
}) => {
	const intlMsg = useFormatMessage()

	return (
		<Box sx={containerFilterCSS} onClick={onClick}>
			<Stack direction='row'>
				<Box sx={titreCSS}>{intlMsg(title)} :</Box>
				<Stack direction='row' alignItems='center'>
					{withColorLabel && <Box sx={color(colorLabel)} />}
					{intlMsg(value)}
				</Stack>
			</Stack>

			<IconButton size='medium' sx={iconButtonCSS}>
				<ArrowDropDownTwoToneIcon fontSize='medium' sx={iconCSS} />
			</IconButton>
		</Box>
	)
}

export default FilterBtn
