import React from 'react'
import { EFormModalMode } from '../../../utils/app-models'
import { IModalUtils } from '../../../utils/hooks'
import FormModalProvider from './FormModalProvider'
import {
	IFormModalTabConfig,
	IFormModalOtherButtons,
	IFormModalOnValidateInput,
	IInitializeDataInput,
} from './formModalType'

interface IFormModal {
	modalUtils: IModalUtils
	onValidate: (args: IFormModalOnValidateInput) => void
	formModalTabConfig: IFormModalTabConfig[]
	onClose?: any
	validateBtnText?: string
	cancelBtnText?: string
	maxWidth?: string
	width?: string
	minHeight?: string
	height?: string
	formModalName?: string
	formGetModalName?: (params: any) => string | any
	initialMode?: EFormModalMode
	otherButtons?: IFormModalOtherButtons[]
	initializeData?: (value: IInitializeDataInput) => void
	editedElementId?: string
}

const FormModal: React.FC<IFormModal> = ({
	modalUtils,
	maxWidth,
	width,
	formModalTabConfig,
	formModalName,
	formGetModalName,
	initialMode,
	minHeight,
	otherButtons,
	onValidate,
	validateBtnText,
	cancelBtnText,
	onClose,
	initializeData,
	editedElementId,
	height,
}) => {
	return (
		<FormModalProvider
			modalUtils={modalUtils}
			formModalConfig={{
				modalStyle: { maxWidth, width, minHeight, height },
				formModalTabConfig,
				formModalName,
				formGetModalName,
				initialMode,
				otherButtons,
				onValidate,
				validateBtnText,
				cancelBtnText,
				onClose,
				initializeData,
				editedElementId,
			}}
		/>
	)
}

export default FormModal
