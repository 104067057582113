import React, { cloneElement, useContext, memo } from 'react'

import { AuthContext } from '../../utils/contexts/AuthContext'

interface IPermissionsHandler {
	children
	permissionsName?: any
	modulesName?: string[] | null
	accountTypesName?: any
	errorProps?
}

const PermissionsHandler: React.FC<IPermissionsHandler> = ({
	children,
	permissionsName = null,
	modulesName = null,
	accountTypesName = null,
	errorProps = null,
}) => {
	const { checkPermissions, checkModules, checkAccountsType } =
		useContext(AuthContext)

	if (!permissionsName && !modulesName && !accountTypesName) return children

	let hasPermissions = true
	let hasModules = true
	let hasTypeAccount = true

	if (permissionsName) hasPermissions = checkPermissions(permissionsName)
	if (modulesName) hasModules = checkModules(modulesName)
	if (accountTypesName) hasTypeAccount = checkAccountsType(accountTypesName)

	if (!hasPermissions || !hasModules || !hasTypeAccount) {
		if (errorProps) return cloneElement(children, { ...errorProps })
		else return <></>
	}

	return children
}

export default memo(PermissionsHandler)
