import React from 'react'

import { Typography } from '@mui/material'
import VmCardExpended from '../../../../components/viewModal/vmCardExpended'

interface IPublicComment {
	comment: string
}

const PublicComment: React.FC<IPublicComment> = ({ comment }) => {
	return (
		<VmCardExpended
			title='misc.publicDesc'
			defaultExpended={!!comment?.length}
			titleGutter
		>
			<Typography variant='text1'>{comment || '-'}</Typography>
		</VmCardExpended>
	)
}
export default PublicComment
