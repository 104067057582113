import React, { useCallback, useContext, useMemo } from 'react'

import MuiModal from '@mui/material/Modal'
import { FormModalContext } from '../FormModalContext'
import { Box, Fade } from '@mui/material'
import { modalCSS } from '../../modalSxCss'
import FormModalHeader from './formModalHeader'
import FormModalContents from './FormModalContents'
import FormModalFooter from '../formModalFooter'

interface IFormModalContainer {}

const FormModalContainer: React.FC<IFormModalContainer> = () => {
	const {
		mode,
		modalUtils: { isVisible, closeModal },
		formModalConfig: { modalStyle },
	} = useContext(FormModalContext)

	const { maxWidth, width } = modalStyle || {}

	const sx = useMemo(
		() => ({
			...modalCSS,
			maxWidth: {
				xs: '90%',
				md: `${maxWidth || '100%'}`,
			},
			width: { xs: '90%', md: `${width || '50%'}` },
		}),
		[]
	)

	const handleCloseModal = useCallback((_e, reason) => {
		if (reason !== 'backdropClick') closeModal()
	}, [])

	return (
		<MuiModal
			open={isVisible}
			onClose={handleCloseModal}
			closeAfterTransition
			role={`modal-${mode}`}
		>
			<Fade in={isVisible}>
				<Box sx={sx}>
					<FormModalHeader />
					<FormModalContents />
					<FormModalFooter />
				</Box>
			</Fade>
		</MuiModal>
	)
}

export default FormModalContainer
