import getNestedObjectFromKey from 'lodash.get'
import translateIntlMsgString from '../../utils/translateIntlMsgString'
import { ROUTES } from '../../routes/consts'
import { EInterventionTypes, EModule, EViewModal } from '../../utils/app-models'

/////////////////////////////////////////////////////////
// Conditional breadcrumb item
/////////////////////////////////////////////////////////

const conditionalBreadcrumbLink = (
	BreadcrumbLink,
	viewModalData,
	isCustomBreadcrumbFor,
	checkModules
) => {
	if (isCustomBreadcrumbFor === 'formInterventionReference') {
		const interventionType = viewModalData?.intervention?.interventionType

		const canOpenViewModal = checkModules([
			EModule.interventionTransport,
			EModule.interventionControl,
		])

		BreadcrumbLink.push({
			title: translateIntlMsgString(
				interventionType === EInterventionTypes?.transport
					? 'interventions.transport'
					: 'misc.intervention'
			),
			text: viewModalData?.intervention?.reference,
			...(canOpenViewModal && {
				vmLink: `${
					ROUTES.VIEWMODAL[
						interventionType === EInterventionTypes?.transport
							? EViewModal.INTERVENTION_TRANSPORT
							: EViewModal.INTERVENTION_REFERENCES
					]?.url
				}${viewModalData?.intervention?.id}`,
			}),
		})
	}
}

//////////////////////////////////////////////////////////////////////////////
// Generate BreadcrumbLinks
//////////////////////////////////////////////////////////////////////////////

export const generateBreadcrumbLinks = (
	breadcrumbs,
	viewModalData,
	checkModules
) => {
	const BreadcrumbLink: any = []

	breadcrumbs?.forEach(
		({ title, selectorsText, link, isCustomBreadcrumbFor }) => {
			if (!isCustomBreadcrumbFor) {
				const canOpenViewModal = link?.modules
					? checkModules(link.modules)
					: true

				BreadcrumbLink.push({
					title: translateIntlMsgString(title),
					text:
						getNestedObjectFromKey(viewModalData, selectorsText[0], null) ||
						getNestedObjectFromKey(viewModalData, selectorsText[1], null),
					...(link &&
						canOpenViewModal && {
							vmLink: `${
								ROUTES.VIEWMODAL[link?.vm]?.url
							}${getNestedObjectFromKey(viewModalData, link?.id, '')}`,
						}),
				})
			} else {
				conditionalBreadcrumbLink(
					BreadcrumbLink,
					viewModalData,
					isCustomBreadcrumbFor,
					checkModules
				)
			}
		}
	)

	return BreadcrumbLink
}
