import React from 'react'

import { Typography } from '@mui/material'

interface IDataListSimpleText {
	text: string
}

const DataListSimpleText: React.FC<IDataListSimpleText> = ({ text }) => {
	return <Typography variant='text1Bold'>{text || '-'}</Typography>
}

export default DataListSimpleText
