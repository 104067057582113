import {
	EFormTemplateBasicFields,
	EFormTemplateModelFields,
	EFormTemplateVehicleFields,
} from '../../../../../utils/app-models'
import translateIntlMsgString from '../../../../../utils/translateIntlMsgString'
import { updateLimitField } from '../formEditionsUtils/fieldsLimitation'

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const getFieldEditSubmitConfig = (
	updateField,
	fieldType,
	index,
	data,
	setLimitationFields,
	defaultValues
): any => {
	switch (fieldType) {
		case EFormTemplateBasicFields.text:
			updateField(index, {
				name: data?.titleField,
				fieldType,
				values: {
					titleField: data?.titleField,
					isRequired: data?.isRequired,
					summaryDisplayField: data?.summaryDisplayField,
				},
			})
			break

		case EFormTemplateBasicFields.numbers:
			updateField(index, {
				name: data?.titleField,
				fieldType,
				values: {
					titleField: data?.titleField,
					isRequired: data?.isRequired,
				},
			})
			break

		case EFormTemplateBasicFields.control:
			updateField(index, {
				name: data?.titleField,
				fieldType,
				values: {
					titleField: data?.titleField,
					isRequired: data?.isRequired,
					controlList: data?.controlList,
					selectedChoiceToAlert: data?.selectedChoiceToAlert
						? parseInt(data?.selectedChoiceToAlert)
						: '',
					defaultValue: data?.defaultValue,
					verificationType: data?.verificationType,
					canAlert: data?.canAlert,
				},
			})
			break

		case EFormTemplateBasicFields.selection:
			updateField(index, {
				name: data?.titleField,
				fieldType,
				values: {
					titleField: data?.titleField,
					isRequired: data?.isRequired,
					selectionType: data?.selectionType,
					selectionsList: data?.selectionsList,
				},
			})
			break

		case EFormTemplateBasicFields.rating:
			updateField(index, {
				name: data?.titleField,
				fieldType,
				values: {
					titleField: data?.titleField,
					isRequired: data?.isRequired,
					ratingType: data?.ratingType,
					defaultValue: data?.defaultValue,
					min: data?.min,
					max: data?.max,
					step: data?.step,
				},
			})
			break

		case EFormTemplateModelFields.picture4anglesModel:
		case EFormTemplateModelFields.picture4anglesAndWheelsModel:
		case EFormTemplateModelFields.pictureVehicle:
		case EFormTemplateBasicFields.picture:
			updateLimitField({
				fieldType,
				fieldAttributes: { picturesList: data?.picturesList },
				setLimitationFields,
				defaultValues,
			})
			updateField(index, {
				name: data?.titleField,
				fieldType,
				values: {
					titleField: data?.titleField,
					vehicleType: data?.vehicleType,
					specificFieldType: data?.specificFieldType,
					picturesList: data?.picturesList,
				},
			})
			break

		case EFormTemplateModelFields.doubleSidedPhotoDocument:
		case EFormTemplateBasicFields.document:
			updateField(index, {
				name: data?.titleField,
				fieldType,
				values: {
					titleField: data?.titleField,
					type: data?.type,
					specificFieldType: data?.specificFieldType,
					documentList: data?.documentList,
				},
			})
			break

		case EFormTemplateBasicFields.title:
			updateField(index, {
				name: data?.titleField,
				fieldType,
				values: {
					titleField: data?.titleField,
					titleType: data?.titleType,
				},
			})
			break

		case EFormTemplateBasicFields.signature:
			updateField(index, {
				name: data?.titleField,
				fieldType,
				values: {
					titleField: data?.titleField,
					isRequired: data?.isRequired,
				},
			})
			break

		// case EFormTemplateBasicFields.media:
		// 	updateField(index, {
		// 		name: data?.titleField,
		// 		fieldType,
		// 		values: {
		// 			titleField: data?.titleField,
		// 			isRequired: data?.isRequired,
		// 		},
		// 	})
		// 	break

		case EFormTemplateBasicFields.instruction:
			updateField(index, {
				name: translateIntlMsgString('formTemplate.instruction'),
				fieldType,
				values: {
					instructionText: data?.instructionText,
					hiddenForOthers: data?.hiddenForOthers,
					titleField: data?.titleField,
				},
			})
			break

		case EFormTemplateBasicFields.barCode:
			updateField(index, {
				name: data?.titleField,
				fieldType,
				values: {
					titleField: data?.titleField,
					isRequired: data?.isRequired,
				},
			})
			break

		case EFormTemplateBasicFields.parkDate:
		case EFormTemplateBasicFields.date:
			updateField(index, {
				name: data?.titleField,
				fieldType,
				values: {
					specificFieldType: data?.specificFieldType,
					titleField: data?.titleField,
					isRequired: data?.isRequired,
					dateType: data?.dateType,
				},
			})
			break

		case EFormTemplateVehicleFields.mileage:
			updateField(index, {
				name: data?.titleField,
				fieldType,
				values: {
					titleField: data?.titleField,
					isRequired: data?.isRequired,
				},
			})
			break

		default:
			break
	}
}
