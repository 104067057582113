import React, { useCallback, useContext, useMemo } from 'react'

import { Box, Grid } from '@mui/material'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import PhoneIcon from '@mui/icons-material/Phone'

import { useFormatMessage } from '../../../../utils/hooks'
import { useListGestionnaires } from '../../../../api/gql/queries/listGestionnairesQueries'
import { useListAgenciesNetworksForSite } from '../../../../api/gql/queries/networkQueries'
import DeboucedFormTextField from '../../../../components/forms/formFields/DebouncedFormTextField'
import { FormModalContext } from '../../../../components/forms/formModal/FormModalContext'
import FormTextField from '../../../../components/forms/formFields/FormTextField'
import FormSelectSearch from '../../../../components/forms/formFields/formSelectSearch'
import { getCountries } from '../../../../utils/tabCountries'
import { useGetCustomerListForSelect } from '../../../../api/gql/queries/customersQueries'
import UserAvatarOptionLabel from '../../../../components/formTools/UserAvatarOptionLabel'
import FormSwitchButton from '../../../../components/forms/formFields/FormSwitchButton'
import FormMultiSelectSearch from '../../../../components/forms/formFields/formMultiSelectSearch'
import { AuthContext } from '../../../../utils/contexts/AuthContext'

const wrapperModalCSS = {
	p: 4,
	overflow: 'auto',
}

interface ICustomerSiteModalInfos {}

const CustomerSiteModalInfos: React.FC<ICustomerSiteModalInfos> = () => {
	const intlMsg = useFormatMessage()
	const { localLanguage } = useContext(AuthContext)

	const { customers } = useGetCustomerListForSelect({
		variables: { mycust: true },
	})

	const { data: gestionnaires } = useListGestionnaires({
		variables: { isActive: true, itemsPerPage: 100000 },
	})

	const { agenciesNetworks } = useListAgenciesNetworksForSite({
		variables: { isActive: true, itemsPerPage: 100000 },
	})

	const countriesOptions = useMemo(
		() =>
			getCountries(localLanguage)?.map((country) => ({
				label: country.label,
				key: country.code,
				value: country.code,
			})),
		[localLanguage]
	)

	const customersOptions = useMemo(
		() =>
			customers?.map((customer) => ({
				label: customer?.name,
				key: customer?.id,
				value: customer,
			})) || [],
		[customers]
	)

	const gestionnaireOption = useMemo(
		() =>
			gestionnaires?.map((gestionnaire) => ({
				label: `${gestionnaire?.firstName} ${gestionnaire?.lastName}`,
				key: gestionnaire.id,
				value: gestionnaire,
			})) || [],
		[gestionnaires]
	)

	const renderChipsLabel = useCallback(
		(value) => value?.agencyCustomDetails?.[0]?.agencyName,
		[]
	)

	return (
		<Box sx={wrapperModalCSS}>
			<Grid container spacing={5}>
				<Grid item xs={12} lg={6}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<DeboucedFormTextField
								name='name'
								label={intlMsg('misc.name')}
								isRequired
								context={FormModalContext}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormTextField
								name='address'
								label={intlMsg('misc.addressSite')}
								context={FormModalContext}
							/>
						</Grid>
						<Grid item xs={6}>
							<FormTextField
								name='zipcode'
								label={intlMsg('misc.zipCode')}
								context={FormModalContext}
							/>
						</Grid>
						<Grid item xs={6}>
							<FormTextField
								name='city'
								label={intlMsg('misc.city')}
								context={FormModalContext}
							/>
						</Grid>

						<Grid item xs={12}>
							<FormSelectSearch
								name={'customer'}
								label={intlMsg('misc.account')}
								selectElements={customersOptions}
								context={FormModalContext}
								missingText={intlMsg('misc.noResultSearch')}
								isRequired
								trigerred
							/>
						</Grid>

						<Grid item xs={12}>
							<FormSelectSearch
								name={'country'}
								label={intlMsg('misc.country')}
								selectElements={countriesOptions}
								context={FormModalContext}
								missingText={intlMsg('misc.noResultSearch')}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormTextField
								name='siret'
								label={intlMsg('misc.siret')}
								context={FormModalContext}
							/>
						</Grid>
						<Grid item xs={6}>
							<FormTextField
								name='phone'
								label={intlMsg('misc.phoneSite')}
								InputProps={{ startAdornment: <PhoneIcon /> }}
								InputLabelProps={{ shrink: true }}
								context={FormModalContext}
							/>
						</Grid>
						<Grid item xs={6}>
							<FormTextField
								name='cellphone'
								label={intlMsg('misc.mobileSite')}
								InputProps={{ startAdornment: <PhoneAndroidIcon /> }}
								InputLabelProps={{ shrink: true }}
								context={FormModalContext}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormSwitchButton
								name='isActive'
								config={{
									primary: { text: intlMsg('misc.active'), value: true },
									secondary: { text: intlMsg('misc.inactive'), value: false },
								}}
								context={FormModalContext}
							/>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={12} lg={6}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<FormSelectSearch
								renderOptionLabel={UserAvatarOptionLabel}
								name='gestionnaire'
								label={intlMsg('misc.manager')}
								selectElements={gestionnaireOption}
								context={FormModalContext}
								missingText={intlMsg('misc.noResultSearch')}
								isRequired
								trigerred
							/>
						</Grid>

						<Grid item xs={12}>
							<FormMultiSelectSearch
								name='sharedToAgencies'
								context={FormModalContext}
								options={agenciesNetworks}
								label={intlMsg('administration.agency')}
								groupBy='networkName'
								selectAllText={intlMsg('misc.selectAll')}
								renderChipsLabel={renderChipsLabel}
								nbChipsToDisplay={3}
								bordered
							/>
						</Grid>
						<Grid item xs={12}>
							<FormTextField
								name='comment'
								label={intlMsg('misc.infosComplementary')}
								multiline={true}
								minRows={5}
								context={FormModalContext}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	)
}

export default CustomerSiteModalInfos
