import React, { useCallback } from 'react'

import {
	IconButton,
	ListItem,
	ListItemSecondaryAction,
	Typography,
	Tooltip,
	Box,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

import ModalProvider from '../../../../providers/ModalProvider'
import { EFormModalMode, EPermissionScope } from '../../../../utils/app-models'
import { networkSchema } from '../../../../utils/validationSchema'
import PermissionsHandler from '../../../../components/permissionsHandler'
import EditNetworkButton from '../modaNetwork/EditNetworkButton'
import { maxStringDisplay } from '../../../../utils/string'
import { useDeleteNetwork } from '../../../../api/gql/mutations/networkMutation'
import toast from 'react-hot-toast'

const containerCSS = {
	width: 1,
	height: '48px',
	'& .MuiListItemSecondaryAction-root': { opacity: 0, transition: '0.1s' },
	'&:hover': {
		'& .MuiListItemSecondaryAction-root': { opacity: 1, transition: '0.5s' },
	},
}

const buttonCSS = {
	display: 'flex',
	alignItems: 'center',
	backgroundColor: 'white',
	p: 1,
	pl: 4,
	cursor: 'pointer',
	transition: '0.2s',
	userSelect: 'none',
}

const isSelectedCSS = {
	position: 'absolute',
	borderRight: ({ palette }) => `solid 4px ${palette.primary.main}!important`,
	top: '5px',
	left: '-5px',
	borderRadius: '268px',
	width: '8px',
	height: '29px',
}

interface INetworksListItem {
	name: string
	id: string
	isActive: boolean
	changeNetworkPage: any
	selectedNetworkId: string
	setSelectedNetworkId: any
	setNetworkIsActive: any
	canBeDeleted: boolean
}

const NetworksListItem: React.FC<INetworksListItem> = ({
	name,
	id,
	isActive,
	changeNetworkPage,
	selectedNetworkId,
	setSelectedNetworkId,
	setNetworkIsActive,
	canBeDeleted,
}) => {
	const [deleteNetwork] = useDeleteNetwork()

	const onDeleteClick = useCallback(() => {
		deleteNetwork({
			variables: {
				input: {
					id,
				},
			},
			refetchQueries: ['getNetworks'],
		}).then(() => {
			localStorage.removeItem('networksSelected') // need to remove to add a new networksSelected after the refetchQueries: ['getNetworks']
			toast.success('success.networkDeleted')
		})
	}, [id])

	return (
		<Box sx={containerCSS}>
			<ListItem
				key={name}
				button
				sx={buttonCSS}
				onClick={changeNetworkPage(id)}
				id={id}
			>
				{selectedNetworkId === id && (
					<Box component='span' sx={isSelectedCSS} />
				)}

				<Tooltip
					disableHoverListener={name?.length < 30}
					title={<Typography sx={{ color: 'white' }}>{name}</Typography>}
				>
					<Typography color='inherit' variant='body2'>
						{maxStringDisplay(name, 30)}
					</Typography>
				</Tooltip>

				<ListItemSecondaryAction>
					<PermissionsHandler
						permissionsName={[EPermissionScope.networkManage]}
					>
						<IconButton edge='end' aria-label='delete' size='small'>
							<ModalProvider
								key={`${id}-modal`}
								validationSchemaFunction={networkSchema}
								formModalMode={EFormModalMode.edit}
								//TODO HERE OLD VERSION OF ModalProvider
								//WE NEED TO CHANGE THIS ON PRODUCTS REFACTO
							>
								<EditNetworkButton
									key={`${id}-editForm`}
									defaultValue={{ name, id, isActive }}
									setSelectedNetworkId={setSelectedNetworkId}
									setNetworkIsActive={setNetworkIsActive}
								/>
							</ModalProvider>
						</IconButton>
						{canBeDeleted && (
							<IconButton
								aria-label='delete'
								data-test-id={`Playwright-NetworksListItem-delete-${name}`}
								size='small'
								onClick={onDeleteClick}
								sx={{ ml: 1 }}
							>
								<DeleteIcon sx={{ fontSize: 18 }} />
							</IconButton>
						)}
					</PermissionsHandler>
				</ListItemSecondaryAction>
			</ListItem>
		</Box>
	)
}

export default NetworksListItem
