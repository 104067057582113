import React from 'react'

import { Paper } from '@mui/material'

const PaperCSS = { p: 2, borderRadius: 2 }

interface IVmCard {
	children
}

const VmCard: React.FC<IVmCard> = ({ children }) => {
	return <Paper sx={PaperCSS}>{children}</Paper>
}
export default VmCard
