/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'

import {
	EFormModalMode,
	EModule,
	EPermissionScope,
} from '../../../../utils/app-models'
import {
	ITableToolBarActionConfig,
	ITableToolBarConfig,
} from '../../../../utils/typescript/tableType'
import { useBulkInterventionAction } from '../../../../api/gql/mutations/interventionsMutation'
import listInterventionsTransportViewTable from '../../../../components/tableV2/config/dashboard/interventionsTransport/listInterventionsTransportViewTable'
import {
	LIST_INTERVENTIONS_TRANSPORT,
	useExportInterventionTransport,
} from '../../../../api/gql/queries/interventionsTransportQueries'
import TransportFilters from './transportFilters'
import { transportDefaultFiltersValues } from './transportFilters/transportDefaultFiltersConfig'
import FormBtnModal from '../../../../components/forms/formModalBtn'
import InterventionTransportModal from './interventionModal'

////////////////////////////////////////////////////////////////
// Global variable
////////////////////////////////////////////////////////////////

const localStorageKeyFilter = 'interventionTransportFilter'

const queryConfig = {
	query: LIST_INTERVENTIONS_TRANSPORT,
	queryName: 'interventions',
}

////////////////////////////////////////////////////////////////
// tableNavTab CONFIG
////////////////////////////////////////////////////////////////

// const tableNavTabConfig = {
// 	navTabConfig: interventionMenuTabConfig,
// 	sxTabs: { mb: 3 },
// }

////////////////////////////////////////////////////////////////
// tableToolBarAction CONFIG
////////////////////////////////////////////////////////////////

const refreshQuerie = [
	'interventionsTransportList',
	'getInterventionTransportNumbersByCustomer',
	'getInterventionTransportNumbersBySite',
	'getInterventionTransportNumbersByVehicle',
]

const deletedBulk = {
	mutation: useBulkInterventionAction,
	operation: 'remove',
	successMessageDelete: {
		singular: 'success.transportDeleted',
		plural: 'success.transportsDeleted',
	},
	messageConfirm: {
		title: 'confirmModal.deleteTransportsTitle',
		message: 'confirmModal.deleteTransportsMessage',
	},
	deletePermissions: [
		EPermissionScope.interventionDelete,
		EPermissionScope.interventionRemove,
	],
}

const tableToolBarActionConfig: ITableToolBarActionConfig = {
	refreshQuerie,
	deletedBulk,
}

////////////////////////////////////////////////////////////////
// TableToolBarHeader CONFIG
////////////////////////////////////////////////////////////////

const tableToolBarConfig: ITableToolBarConfig = {
	title: 'interventions.transports',
	stickyToolBar: true,
	tableConfig: listInterventionsTransportViewTable,
	localStorageKeyFilter,
	localStorageName: 'listInterventionsTransportViewTableFilters',
	exportConfig: { exportQuery: useExportInterventionTransport }, // TODO
	queryConfig,
	modalButton: {
		Btn: (
			<FormBtnModal
				modal={<InterventionTransportModal modalUtils={{} as any} />}
				mode={EFormModalMode.create}
			/>
		),
	},
	createPermission: [EPermissionScope.interventionCreate],
	modules: [EModule.interventionTransport],
}

////////////////////////////////////////////////////////////////
// tableContainer CONFIG
////////////////////////////////////////////////////////////////

const tableContainerConfig = {
	height: '59vh',
	tableConfig: listInterventionsTransportViewTable,
	queryConfig,
}

////////////////////////////////////////////////////////////////
// -> EXPORT CONFIG MAIN TABLE
////////////////////////////////////////////////////////////////

const defaultFiltersConfig = {
	componentsFiltersConfig: <TransportFilters />,
	defaultFiltersValues: transportDefaultFiltersValues,
	localStorageKeyFilter,
}

export const interventionTransportConfigTable = {
	tableToolBarConfig,
	tableToolBarActionConfig,
	tableContainerConfig,
	defaultFiltersConfig,
}
