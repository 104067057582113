import React, { useContext } from 'react'
import VehicleDocumentFiltersBtnComponent from './VehicleDocumentFiltersBtnComponent'
import FiltersBtnProvider from '../../../../components/filters/filtersBtn'
import { Box } from '@mui/material'
import { FiltersContext } from '../../../../components/filters/filtersContext'
import FilterDate from '../../../../components/filters/filtersComponents/filterDate'

interface IVehicleDocumentFilters {}

const ByReferenceFiltersSX = {
	display: 'flex',
}

const VehicleDocumentFilters: React.FC<IVehicleDocumentFilters> = () => {
	const { setFilters, filters } = useContext(FiltersContext)

	return (
		<Box sx={ByReferenceFiltersSX}>
			<FilterDate
				setFiltersQuerieParam={setFilters}
				filtersQuerieParam={filters}
				argumentQuerieName='dateCreated'
			/>
			<FiltersBtnProvider>
				<VehicleDocumentFiltersBtnComponent />
			</FiltersBtnProvider>
		</Box>
	)
}

export default VehicleDocumentFilters
