import { string, object, date } from 'yup'
import translateIntlMsgString from '../../../../../../utils/translateIntlMsgString'

const catalogueProductModalValidationSchema = object().shape({
	name: string().required(translateIntlMsgString('validationSchema.require')),
	dateContractFrom: date()
		.typeError(translateIntlMsgString('errors.dateInvalid'))
		.nullable(),
	dateContractTo: date()
		.typeError(translateIntlMsgString('errors.dateInvalid'))
		.nullable(),
})

export default catalogueProductModalValidationSchema
