export const listCardViewModalCSS = {
	space: { padding: ({ spacing }) => spacing(2, 3) },
	spaceSmall: { padding: ({ spacing }) => spacing(1, 1, 0, 3) },
	spaceRightLeft: { padding: ({ spacing }) => spacing(0, 3) },
	subtitle1: {
		fontWeight: 500,
		textTransform: 'uppercase',
		fontSize: '0.8rem',
		height: '32px',
	},
	ListSubheader: {
		color: 'inherit',
		padding: ({ spacing }) => spacing(2, 3),
		lineHeight: 0,
	},
	ListItem: {
		padding: ({ spacing }) => spacing(1, 3),
	},
	ListItemTxt: {
		'& .MuiTypography-root': { fontSize: '0.775rem' },
	},
	ListItemIcon: { minWidth: '29px' },

	ColorPrimary: { color: ({ palette }) => palette.primary.main },
	imgSignature: { maxHeight: '45px' },
	radius: { borderRadius: '8px' },
	subheader: {
		fontSize: '12px',
		color: 'inherit',
		padding: '24px 24px 0px 24px',
		fontWeight: 400,
		lineHeight: 0,
	},
	comment: {
		fontSize: '12px',
		color: 'inherit',
		padding: '0px 24px 0px 24px',
		fontWeight: 400,
		lineHeight: 1,
		display: 'flex',
		alignItems: 'center',
	},
} as const
