import React, { useMemo } from 'react'

import { Typography, Stack, Box } from '@mui/material'
import FlagIcon from '@mui/icons-material/Flag'
import { dayjsLocalizedFormatMethod } from '../../../../../../utils/date'

const titleCSS = {
	fontWeight: 700,
	fonSize: '20px',
	textTransform: 'uppercase',
}

interface IDisplayTransportDate {
	itTrip: any
	transportTripType: string
}

const DisplayTransportDate: React.FC<IDisplayTransportDate> = ({
	itTrip,
	transportTripType,
}) => {
	const dayjs = dayjsLocalizedFormatMethod()

	const IconFlag = useMemo(() => {
		return (
			<Box
				sx={{
					border: `solid 2px ${
						transportTripType === 'delivery' ? '#FF4081' : '#64B6F7'
					}`,
					borderRadius: '100%',
					padding: '4px',
					display: 'flex',
				}}
			>
				<FlagIcon
					sx={{
						color: transportTripType === 'delivery' ? '#FF4081' : '#64B6F7',
						fontSize: '25px',
					}}
				/>
			</Box>
		)
	}, [])

	const AddressTransport = useMemo(() => {
		return (
			<Stack direction='row' alignItems='center' spacing={0.5}>
				<Typography variant='body2' sx={titleCSS}>
					{`${itTrip.city} (${itTrip.zipcode.slice(
						0,
						2
					)}), ${itTrip.country.slice(0, 2)}`}
				</Typography>
			</Stack>
		)
	}, [itTrip])

	const DateTransport = useMemo(() => {
		const formattedDate = dayjs(itTrip.date).format('D MMMM YYYY')
		const formattedTimeFrom = dayjs(itTrip.timeFrom).format('HH:mm')

		const formattedTimeTo = itTrip?.timeTo
			? dayjs(itTrip.timeTo).format('HH:mm')
			: null

		return (
			<Stack direction='row' spacing={0.5}>
				<Typography variant='body2' color='text.secondary'>
					{formattedDate}
				</Typography>
				<Typography
					variant='body2'
					color='text.secondary'
				>{`${formattedTimeFrom} ${
					formattedTimeTo ? ' - ' + formattedTimeTo : ''
				}`}</Typography>
			</Stack>
		)
	}, [itTrip])

	return (
		<Stack direction='row' spacing={2}>
			<Stack>{IconFlag}</Stack>
			<Stack>
				{AddressTransport}
				{DateTransport}
			</Stack>
		</Stack>
	)
}

export default DisplayTransportDate
