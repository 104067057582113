import React from 'react'

import { Stack, Typography, Box } from '@mui/material'

import TitleField from './TitleField'
import { useFormatMessage } from '../../../../../../utils/hooks'

const fieldDateCSS = {
	width: 1,
	border: ({ palette }) => `2px solid ${palette.grey[300]}`,
	p: 1,
	mt: 1,
	color: ({ palette }) => palette.grey[400],
	borderRadius: 1,
}

interface IFieldDate {
	field
	deleteField
	index
	updateField
	insertField
}

const FieldDate: React.FC<IFieldDate> = ({
	field: { id, name, values, fieldType },
	deleteField,
	index,
	updateField,
	insertField,
}) => {
	const intlMsg = useFormatMessage()

	const fieldToEdit = {
		id,
		name,
		values,
		fieldType,
		updateField,
		index,
	}

	return (
		<Stack p={1}>
			<TitleField
				deleteField={deleteField}
				index={index}
				fieldToEdit={fieldToEdit}
				isRequired={values?.isRequired}
				insertField={insertField}
				values={values}
				fieldType={fieldType}
				specificChips={
					values?.dateType
						? [intlMsg(`formTemplate.type${values?.dateType}`)]
						: null
				}
			/>
			<Box sx={fieldDateCSS}>
				<Typography variant='body2'>
					{intlMsg('formTemplate.dateFormat')}
				</Typography>
			</Box>
		</Stack>
	)
}

export default FieldDate
