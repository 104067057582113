/* eslint-disable @typescript-eslint/no-unused-vars */
import { EFormModalMode } from '../../../../utils/app-models'
import { getCountries, defaultCountry } from '../../../../utils/tabCountries'

export const formatCustomerAccountModalEditDefaultValues = (
	data,
	localLanguage
) => {
	const { id, __typename, gestionnaire, country, ...values } = data
	const countryValues = getCountries(localLanguage)?.find(
		({ code }) => code === country
	)

	return {
		...values,
		gestionnaire: {
			key: gestionnaire?.id,
			label: `${gestionnaire?.firstName} ${gestionnaire?.lastName}`,
			value: gestionnaire,
		},
		country: {
			key: countryValues?.code,
			label: countryValues?.label,
			value: countryValues?.code,
		},
	}
}

export const formatCustomerAccountModalCreateDefaultValues = (currentUser) => {
	return {
		gestionnaire: {
			key: currentUser?.id,
			label: `${currentUser?.firstName} ${currentUser?.lastName}`,
			value: {
				id: currentUser?.id,
				lastName: currentUser?.lastName,
				firstName: currentUser?.firstName,
			},
		},
		country: {
			key: defaultCountry.code,
			label: defaultCountry.label,
			value: defaultCountry.code,
		},
	}
}

export const formatCustomerAccountModalOnValidateData = ({
	data,
	mode,
	customerId,
}) => {
	const specificModeFields =
		mode === EFormModalMode.edit
			? {
					id: customerId,
			  }
			: {
					isActive: true,
			  }

	return {
		...specificModeFields,
		...data,
		gestionnaire: data?.gestionnaire?.value?.id || '',
		country: data?.country?.value,
	}
}
