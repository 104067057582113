import React, { useContext } from 'react'

import { Typography } from '@mui/material'
import { MultiNavTableContext } from '../../MultiNavTableProvider'

const TypographyCSS = {
	mr: 2,
	'&': { textTransform: 'lowercase' },
	'&::first-letter': { textTransform: 'uppercase' },
}

interface ICustomTableTitle {}

const CustomTableTitle: React.FC<ICustomTableTitle> = () => {
	const { tableTitle } = useContext(MultiNavTableContext) || {}

	return (
		<Typography variant='h2' sx={TypographyCSS}>
			{tableTitle}
		</Typography>
	)
}

export default CustomTableTitle
