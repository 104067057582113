import React, { useCallback, useContext, useMemo } from 'react'

import { useFormatMessage } from '../../../../../../../utils/hooks'
import FormMultiSelectSearch from '../../../../../../../components/forms/formFields/formMultiSelectSearch'
import {
	FormModalContext,
	IFormModalContext,
} from '../../../../../../../components/forms/formModal/FormModalContext'
import { useProductsAndCategoriesList } from '../../../../../../../api/gql/queries/catalogProductQueries'

interface IProductsColumn {
	vehicleId: string
}

const ProductsColumn: React.FC<IProductsColumn> = ({ vehicleId }) => {
	const intlMsg = useFormatMessage()
	const { setValues, data } = useContext<IFormModalContext>(FormModalContext)
	const { productCategories } = useProductsAndCategoriesList({
		variables: {
			customer:
				data?.finalCustomer?.value?.id ||
				data?.orderingCustomer?.value?.id ||
				'',
		},
		fetchPolicy: 'cache-only',
	})
	const selectedVehicles = useMemo(
		() => data?.selectedVehicles || [],
		[data?.selectedVehicles]
	)

	const productCategoriesOptions = useMemo(() => {
		const tmp: any = []

		productCategories?.forEach(
			({ products: { collection }, name: categoryName }) => {
				collection?.forEach((product) => {
					tmp.push({
						key: product?.id,
						label: product?.name,
						value: {
							...product,
							categoryName,
						},
					})
				})
			}
		)
		return tmp
	}, [productCategories])

	const handleSelectProducts = useCallback(
		(fieldName, value) => {
			const selectedVehicleId = fieldName?.replace('products-', '')
			const selectedVehicleIndex = selectedVehicles?.findIndex(
				({ id }) => selectedVehicleId === id
			)
			selectedVehicles[selectedVehicleIndex].products = Object.values(value)
				.filter(({ checked }: any) => checked)
				.map(({ value }: any) => value?.id)
			setValues({
				//set new products for the field link to FormMultiSelectSearch
				[fieldName]: value,
				//set new products in selectedVehicles
				selectedVehicles,
			})
		},
		[setValues, selectedVehicles]
	)

	return (
		<FormMultiSelectSearch
			width='400px'
			name={`products-${vehicleId}`}
			options={productCategoriesOptions}
			context={FormModalContext}
			label={intlMsg('interventions.selectProduct')}
			selectAllText={intlMsg('misc.selectAll')}
			specialOnChange={handleSelectProducts}
			groupBy='categoryName'
			bordered
			nbChipsToDisplay={3}
		/>
	)
}
export default ProductsColumn
