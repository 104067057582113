import React from 'react'

import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

import { useFormatMessage } from '../../../../utils/hooks'
import {
	Divider,
	Grid,
	List,
	ListItem,
	ListItemText,
	Box,
	Stack,
} from '@mui/material'

// import SendIcon from '@mui/icons-material/Send'
// import CheckCircleIcon from '@mui/icons-material/CheckCircle'
// import ReportProblemIcon from '@mui/icons-material/ReportProblem'

import { listCardViewModalCSS as css } from '../../../../styles/css/viewModal'
import { formatAdressGoogleMap } from '../../../../utils/string'
import { ListItemsData } from '../../../../components'

import { withStyles } from '@mui/styles'
//import Alert from '@mui/lab/Alert'

const StyledListItemText = withStyles((theme) => ({
	primary: {
		fontSize: '13px',
		lineHeight: '12px',
	},
	secondary: {
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: '13px',
		color: 'black',
	},
}))(ListItemText)

interface IAgencyInformation {
	email
	phone
	address
	city
	zipcode
	networks
	customName
	socialName
	adminFirstName
	adminLastName
	cellphone
	siret
}

const AgencyInformation: React.FC<IAgencyInformation> = ({
	email,
	phone,
	address,
	city,
	zipcode,
	networks,
	customName,
	socialName,
	adminFirstName,
	adminLastName,
	cellphone,
	siret,
}) => {
	const intlMsg = useFormatMessage()

	return (
		<Paper sx={css.radius}>
			<Box sx={{ pb: 2 }}>
				<Box sx={css.space}>
					<Typography variant='h3'>
						<Stack
							direction='row'
							alignItems='center'
							justifyContent='space-between'
						>
							{customName}
						</Stack>
					</Typography>
				</Box>
				<Divider />
				<Box sx={css.spaceRightLeft}>
					<ListItemsData
						nbCol={6}
						items={[
							{
								primaryText: 'misc.address',
								secondaryText: `${address ?? ''} - ${zipcode ?? ''} - ${
									city ?? ''
								}`,
								...((address || city || zipcode) && {
									externalLink: formatAdressGoogleMap(address, city, zipcode),
								}),
								sizeColumn: 12,
							},

							{
								primaryText: 'misc.socialName',
								secondaryText: socialName,
							},

							{
								primaryText: 'misc.siret',
								secondaryText: siret,
							},
						]}
					/>
				</Box>
				<Divider />
				<Box sx={css.spaceRightLeft}>
					<List component='div'>
						<Grid container sx={{ overflowWrap: 'anywhere' }}>
							<Grid item xs={6}>
								<ListItem disableGutters>
									<StyledListItemText
										primary={intlMsg('misc.name')}
										secondary={
											adminFirstName && adminLastName
												? `${adminFirstName} ${adminLastName}`
												: '-'
										}
									/>
								</ListItem>
							</Grid>
							<Grid item xs={6}>
								<ListItem disableGutters>
									<StyledListItemText
										primary={intlMsg('misc.email')}
										secondary={email || '-'}
									/>
								</ListItem>
							</Grid>
							<Grid item xs={6}>
								<ListItem disableGutters>
									<StyledListItemText
										primary={intlMsg('misc.phone')}
										secondary={phone || '-'}
									/>
								</ListItem>
							</Grid>
							<Grid item xs={6}>
								<ListItem disableGutters>
									<StyledListItemText
										primary={intlMsg('misc.cellphone')}
										secondary={cellphone || '-'}
									/>
								</ListItem>
							</Grid>
						</Grid>
					</List>
				</Box>
				<Divider />

				<Grid container spacing={1} sx={{ pt: 1 }}>
					<Grid item xs={12}>
						<Typography variant='body2' sx={css.ListSubheader}>
							{intlMsg('misc.networks')}
						</Typography>
					</Grid>
				</Grid>
				<Box sx={{ ...css.spaceRightLeft, mb: 1, pb: 2 }}>
					{networks.map(({ name }) => {
						return (
							<Typography
								key={name}
								variant='caption'
								component='p'
								sx={{ fontWeight: 'bold', pl: 0 }}
							>
								{name}
							</Typography>
						)
					})}
				</Box>

				{/* <div className={`${classes.spaceRightLeft} mb-2`}>
					(TODO)
					<Alert
						icon={<SendIcon fontSize='small' />}
						severity='success'
						className='mb-2'
					>
						<Typography variant='caption'>
							{intlMsg('misc.inviteSend')}
						</Typography>
					</Alert>
					<Alert
						icon={<CheckCircleIcon fontSize='inherit' />}
						severity='success'
						className='mb-2'
					>
						<Typography variant='caption'>
							{intlMsg('misc.useRegister')}
						</Typography>
					</Alert>
					<Alert
						icon={<ReportProblemIcon fontSize='inherit' />}
						severity='warning'
						className='mb-2'
					>
						<Typography variant='caption' className='mb-2'>
							{intlMsg('misc.inviteNotSend')}
						</Typography>
					</Alert>
					<Alert
						icon={<ReportProblemIcon fontSize='inherit' />}
						className='mb-4'
						severity='warning'
					>
						<Typography variant='caption'>
							{intlMsg('misc.userNotRegister')}
						</Typography>
					</Alert>
					<Button
						variant='contained'
						endIcon={<SendIcon />}
						color='primary'
						disableElevation
					>
						{intlMsg('misc.sendInvitation')}
					</Button>
				</div> */}
			</Box>
		</Paper>
	)
}

export default AgencyInformation
