import React, { useCallback, useContext } from 'react'

import Typography from '@mui/material/Typography'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { Stack, Box } from '@mui/material'

import { useFormatMessage } from '../../../../../utils/hooks'
import { generateTemplateDefaultValue } from './utils'
import { FormTemplateContext } from '../../../../../utils/contexts/FormTemplateContext'
import { checkFieldsLimitation } from '../formEditionsUtils/fieldsLimitation'

const fieldCSS = {
	cursor: 'pointer',
	border: 'solid 1px #DDD',
	backgroundColor: 'white',
	p: 1,
	borderRadius: 1,
	transition: '0.2s',
	overflow: 'hidden',
	position: 'relative',
	userSelect: 'none',
	'&:hover': {
		border: 'solid 1px #f1f1f1',
		backgroundColor: '#f1f1f1',
		transition: '0.2s',
		'& .iconDrag': {
			opacity: 1,
			bottom: '0px',
			transition: '0.5s',
			position: 'relative',
		},
		'& .iconField': {
			opacity: 0,
			bottom: '10px',
			position: 'absolute',
			transition: '0.1s',
		},
	},
	'& .iconDrag': {
		opacity: 0,
		bottom: '15px',
		position: 'absolute',
		transition: '0.1s',
	},
	'& .iconField': {
		marginRight: '3px',
		opacity: 1,
		bottom: '0px',
		transition: '0.1s',
		position: 'relative',
	},
}

interface IFieldElement {
	type
	fieldModel?
	Icon
	appendField
	draggableZone
}

const FieldElement: React.FC<IFieldElement> = ({
	type,
	fieldModel,
	Icon,
	appendField,
	draggableZone,
}) => {
	const intlMsg = useFormatMessage()
	const { setLimitationFields, limitationFields, setIsOpenLimitModal } =
		useContext(FormTemplateContext)

	const insertNewField = useCallback(() => {
		if (
			limitationFields[fieldModel || type]?.isLimitReached ||
			checkFieldsLimitation(type, limitationFields)
		) {
			setIsOpenLimitModal(type)
		} else {
			appendField({
				name: intlMsg(`formTemplate.${fieldModel || type}`),
				fieldType: type,
				fieldModel: fieldModel || type,
				values: generateTemplateDefaultValue(
					fieldModel || type,
					setLimitationFields
				),
			})
			setTimeout(() => {
				window.scrollTo({
					left: 0,
					top: document.body.scrollHeight,
					behavior: 'smooth',
				})
			}, 100)
		}
	}, [appendField, type, fieldModel, limitationFields])

	return (
		<Stack
			direction='row'
			alignItems='center'
			sx={fieldCSS}
			onClick={insertNewField}
			id={`Playwright-FieldElement-${fieldModel || type}`}
		>
			<Stack
				direction='row'
				alignItems='center'
				mr={1}
				sx={{ overflow: 'hidden' }}
				{...draggableZone}
			>
				<Stack direction='row' alignItems='center' className='iconField'>
					<Icon />
				</Stack>
				<Stack direction='row' alignItems='center' className='iconDrag'>
					<DragIndicatorIcon />
				</Stack>
			</Stack>

			<Box sx={{ width: 1 }}>
				<Typography variant='body1'>
					{intlMsg(`formTemplate.${fieldModel || type}`)}
				</Typography>
			</Box>
		</Stack>
	)
}

export default FieldElement
