import translateIntlMsgString from '../../../../utils/translateIntlMsgString'
import { string, object } from 'yup'

const documentVehicleModalValidationSchema = object().shape({
	vehicle: string().required(
		translateIntlMsgString('validationSchema.require')
	),

	reference: string().required(
		translateIntlMsgString('validationSchema.require')
	),

	category: object()
		.required(translateIntlMsgString('validationSchema.require'))
		.nullable(),
})

export default documentVehicleModalValidationSchema
