// ////////////////////////////////////////////////////////////////
// External links
// ////////////////////////////////////////////////////////////////

import { GlobalColorsTheme } from '../styles/themes/globalTheme/globalColorsTheme'
import { EInterventionTypes } from './app-models'
import translateIntlMsgString from './translateIntlMsgString'

export const subscribeOfferPictureLink = {
	fr: 'https://forms.clickup.com/4655630/f/4e2ge-7342/OEYUDM1IXDSDE8OICQ',
}

export const askDemoLink = {
	fr: 'https://forms.clickup.com/4655630/f/4e2ge-5622/BFY56Y07CMSXS5SUI0',
}

export const currentInterventionTypesForFilters = [
	{
		code: EInterventionTypes?.control,
		id: EInterventionTypes?.control,
		name: EInterventionTypes?.control,
		color: GlobalColorsTheme.intervention,
	},
	{
		code: EInterventionTypes?.transport,
		id: EInterventionTypes?.transport,
		name: EInterventionTypes?.transport,
		color: GlobalColorsTheme.transport,
	},
]

export const unitsOfWork = [
	{
		label: translateIntlMsgString('catalogProduct.unitOfWorkVEH'),
		key: translateIntlMsgString('catalogProduct.unitOfWorkVEH'),
		value: { name: 'veh', color: '#D5FCFF' },
	},
	{
		label: translateIntlMsgString('catalogProduct.unitOfWorkDAY'),
		key: translateIntlMsgString('catalogProduct.unitOfWorkDAY'),
		value: { name: 'day', color: '#FFD5D5' },
	},
	{
		label: translateIntlMsgString('catalogProduct.unitOfWorkHOUR'),
		key: translateIntlMsgString('catalogProduct.unitOfWorkHOUR'),
		value: { name: 'hr', color: '#E0FFD5' },
	},
	{
		label: translateIntlMsgString('catalogProduct.unitOfWorkEVENT'),
		key: translateIntlMsgString('catalogProduct.unitOfWorkEVENT'),
		value: { name: 'evt', color: '#D6CAF1' },
	},
]

export const baseType = [
	{
		label: translateIntlMsgString('catalogProduct.baseTypeST'),
		key: translateIntlMsgString('catalogProduct.baseTypeST'),
		value: { name: 'ST', color: '#F9D5FF' },
	},
	{
		label: translateIntlMsgString('catalogProduct.baseTypeHS'),
		key: translateIntlMsgString('catalogProduct.baseTypeHS'),
		value: { name: 'HS', color: '#E0E0E0' },
	},
	{
		label: translateIntlMsgString('catalogProduct.baseTypeOP'),
		key: translateIntlMsgString('catalogProduct.baseTypeOP'),
		value: { name: 'OP', color: '#FFEEC3' },
	},
]
