import { Box } from '@mui/material'
import React, { useContext } from 'react'
import FilterDate from '../../../../../../components/filters/filtersComponents/filterDate'
import { FiltersContext } from '../../../../../../components/filters/filtersContext'
import FiltersBtnProvider from '../../../../../../components/filters/filtersBtn'
import ByReferenceFiltersBtnComponent from './ByReferenceFiltersBtnComponent'

interface IByReferenceFilters {}

const ByReferenceFiltersSX = {
	display: 'flex',
}

const ByReferenceFilters: React.FC<IByReferenceFilters> = () => {
	const { setFilters, filters } = useContext(FiltersContext)

	return (
		<Box sx={ByReferenceFiltersSX}>
			<FilterDate
				setFiltersQuerieParam={setFilters}
				filtersQuerieParam={filters}
				argumentQuerieName='startFrom'
			/>
			<FiltersBtnProvider>
				<ByReferenceFiltersBtnComponent />
			</FiltersBtnProvider>
		</Box>
	)
}

export default ByReferenceFilters
