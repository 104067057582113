/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/react-in-jsx-scope */
import {
	agencyReferentStepSchema,
	agencyStepSchema,
	// agencyTypeStepSchema,
} from '../../../../../utils/validationSchema'
import AgencyStep from './agencySteps/AgencyStep'
import CategoryStep from './agencySteps/categoryStep'
import ReferentStep from './agencySteps/ReferentStep'
import SiteStep from './agencySteps/sitesStep'
// import TypeStep from './agencySteps/typeStep/TypeStep'

const agencyStepperConfig = [
	{
		name: 'misc.agency',
		component: <AgencyStep />,
		schema: agencyStepSchema,
	},
	// {
	// 	name: 'misc.type',
	// 	component: <TypeStep />,
	// 	schema: agencyTypeStepSchema(intlMsg),
	// },

	{
		name: 'agencies.sharedSites',
		component: <SiteStep />,
	},
	{
		name: 'agencies.sharedProducts',
		component: <CategoryStep />,
	},
	{
		name: 'misc.referent',
		component: <ReferentStep />,
		schema: agencyReferentStepSchema,
	},
]

export default agencyStepperConfig
