export default {
	bases: 'Bases',
	base: 'Base',
	product: 'Catalog products',
	products: 'Products',
	addCategoryProduct: 'Add a products category',
	editCategoryProduct: 'Edit a products category',
	addCategoryProductBase: 'Add a bases category',
	editCategoryProductBase: 'Edit a bases category',
	addProduct: 'Add a product',
	editProduct: 'Edit a product',
	addProductBase: 'Add a product base',
	editProductBase: 'Edit a product base',
	categoryProductTitle: 'Category Name',
	categoryProductLabel: 'Category',
	categoryColor: 'Color',
	nameProduct: 'Product Name',
	nameProductLabel: 'Product',
	category: 'Category',
	productActive: 'Active Product',
	productBaseActive: 'active product base',
	addCategory: 'Add a Category',
	needCategory: 'You must first create a category',
	myCatalog: 'My Catalog',
	sharedCatalog: 'Shared Catalog',
	descCategoryProduct: 'Product category description',
	descCategoryProductBase: 'Product base category description.',
	descProductBase: 'Product base description',
	descProduct: 'Product description',
	idGestionProduct: 'Product ID in your management tool (ERP)',
	idGestionProductBase: 'Base ID in your management tool (ERP)',

	modalConfirmCategoryProduct: `<span>Product category deactivation</span> <br><br>
	Attached products will also be deactivated, making them inaccessible when creating missions and in the HubeeCheck mobile application.<br><br>
	Would you like to continue?
	`,

	modalConfirmCategoryProductBase: `<span>Product base category deactivation</span> <br><br>
	This category's bases and associated products will also be deactivated, making them inaccessible when creating missions and in the HubeeCheck mobile application.<br><br>
	Would you like to continue?
	`,

	modalConfirmProduct: `<span>Product deactivation</span> <br><br>
	The product will no longer be visible to your teams.<br><br>
	Would you like to continue?
	`,

	modalConfirmProductBase: `<span>Product base deactivation</span> <br><br>
	Products linked to this range will be deactivated, making them inaccessible when creating missions and in the HubeeCheck mobile application.<br><br>
	Would you like to continue?
	`,

	unitOfWorkVEH: 'Vehicle',
	unitOfWorkDAY: 'Day',
	unitOfWorkHOUR: 'Hour',
	unitOfWorkEVENT: 'Event',

	baseTypeST: 'Standard',
	baseTypeHS: 'Non-standard',
	baseTypeOP: 'Option',

	emptyCategoriesProduct: 'Create your first product category.',
	emptyCategoriesProductBase: 'Create your first base category.',
	emptyCategoriesProductShared: 'No categories.',

	emptyProduct: `<span>Welcome to the product catalog!</span> <br><br>
	Before setting up your products, please first create a category.
	`,

	emptyProductBase: `<span>Ranges, what are they for?</span> <br><br>
	Ranges group together identical products assigned to different customers, making it easier to track activity generated by a catalog product.<br><br>
	For example:<br>
	<strong>Range:</strong> Prestige interior wash (duration: 60 min - price: €70)<br>
	<strong>Products:</strong><br>
	Prestige interior washing (duration: 45 min - price: €50 - clients: A and B)<br>
	Prestige interior washing (duration: 50 min - price: €80 - customers: C)<br><br>
`,

	emptyProductShared: `<span>Welcome to the product catalog!</span> <br><br>
No product.
`,

	relatedProduct: 'Related products',
	refERP: 'ERP Ref',

	relatedProductBase: 'Related products base',
	emptyProductBaseDrawer: 'No product linked to this base',

	productBaseDetails: 'Product base details',
}
