import React from 'react'

import { Box, Popover, Stack, Typography } from '@mui/material'

import CustomChipColor from '../../../../chip/CustomChipColor'
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation'
import { useFormatMessage } from '../../../../../utils/hooks'
import OpenLinkCell from '../../OpenLinkCell'
import { ROUTES } from '../../../../../routes/consts'

interface IVehicleTransportPopover {
	ipv?
	id
	open
	handleClose
	anchorEl
}

const VehicleTransportPopover: React.FC<IVehicleTransportPopover> = ({
	ipv,
	id,
	open,
	handleClose,
	anchorEl,
}) => {
	const intlMsg = useFormatMessage()

	return (
		<Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose}>
			<Box p={1} textTransform='uppercase'>
				{ipv.map(({ vehicle }) => (
					<Stack
						direction='row'
						alignItems='center'
						key={vehicle.id}
						spacing={2}
					>
						<Typography
							onClick={handleClose}
							sx={{
								opacity: vehicle?.vehicleDetail?.immat ? '1' : '0.3',
							}}
						>
							{vehicle?.vehicleDetail?.immat ? (
								<OpenLinkCell
									txt={vehicle?.vehicleDetail?.immat}
									viewModalLink={`${ROUTES.VIEWMODAL.VEHICLES.url}${vehicle.id}`}
								/>
							) : (
								intlMsg('vehicle.immat')
							)}
						</Typography>
						<Typography
							onClick={handleClose}
							sx={{
								opacity: vehicle?.vehicleDetail?.vin ? '1' : '0.3',
							}}
						>
							{vehicle?.vehicleDetail?.vin ? (
								<OpenLinkCell
									txt={vehicle?.vehicleDetail?.vin}
									viewModalLink={`${ROUTES.VIEWMODAL.VEHICLES.url}${vehicle.id}`}
								/>
							) : (
								intlMsg('vehicle.vin')
							)}
						</Typography>
						<Typography
							sx={{
								opacity: vehicle?.vehicleDetail?.vehicleModelText ? '1' : '0.3',
							}}
						>
							{vehicle?.vehicleDetail?.vehicleModelText ||
								intlMsg('vehicle.model')}
						</Typography>
						<Typography
							sx={{
								opacity: vehicle?.vehicleDetail?.vehicleBrand?.name
									? '1'
									: '0.3',
							}}
						>
							{vehicle?.vehicleDetail?.vehicleBrand?.name ||
								intlMsg('vehicle.brand')}
						</Typography>

						{vehicle?.vehicleDetail?.vehicleCarburant && (
							<CustomChipColor
								label={intlMsg(
									`vehicle.${vehicle?.vehicleDetail?.vehicleCarburant?.code}`
								)}
								Icon={LocalGasStationIcon}
								color={vehicle?.vehicleDetail?.vehicleCarburant?.color}
							/>
						)}
					</Stack>
				))}
			</Box>
		</Popover>
	)
}

export default VehicleTransportPopover
