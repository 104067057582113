export default {
	dontShowAgain: 'Ne plus afficher ce message',
	deleteCustomerAccountTitle: 'Suppression du compte',
	deleteCustomerAccountMessage:
		'Êtes-vous sûr de vouloir supprimer ce compte ?',
	deleteFormTemplateTitle: 'Suppression du modèle de formulaire',
	deleteFormTemplateMessage:
		'Êtes-vous sûr de vouloir supprimer ce formulaire ?',
	deleteFormTemplatesTitle: 'Suppression des modèles de formulaire',
	deleteFormTemplatesMessage:
		'Êtes-vous sûr de vouloir supprimer ces formulaires ?',

	addUserTitle: `Ajouter un utilisateur`,
	addUserTitleMessage:
		"L'ajout de ce nouvel utilisateur modifiera le prix de votre abonnement.",
	deleteUserTitle: `Suppression de l'utilisateur`,
	deleteUserMessage: 'Êtes-vous sûr de vouloir supprimer cet utilisateur ?',
	deleteUsersTitle: `Suppression des utilisateurs`,
	deleteUsersMessage: 'Êtes-vous sûr de vouloir supprimer ces utilisateurs ?',

	deleteTeamTitle: `Suppression de l'équipe`,
	deleteTeamMessage: 'Êtes-vous sûr de vouloir supprimer cette équipe ?',
	deleteTeamsTitle: `Suppression des équipes`,
	deleteTeamsMessage: 'Êtes-vous sûr de vouloir supprimer ces équipes ?',

	deleteContactTitle: `Suppression du contact`,
	deleteContactMessage: 'Êtes-vous sûr de vouloir supprimer ce contact?',
	deleteContactsTitle: `Suppression des contacts`,
	deleteContactsMessage: 'Êtes-vous sûr de vouloir supprimer ces contacts ?',

	deleteCustomerSiteTitle: `Suppression du site`,
	deleteCustomerSiteMessage: 'Êtes-vous sûr de vouloir supprimer ce site?',
	deleteCustomersSiteTitle: `Suppression des sites`,
	deleteCustomersSiteMessage: 'Êtes-vous sûr de vouloir supprimer ces sites ?',

	deleteCustomersAccountTitle: 'Suppression des comptes',
	deleteCustomersAccountMessage:
		'Êtes-vous sûr de vouloir supprimer ces comptes?',

	deleteInterventionsTitle: `Suppression des interventions`,
	deleteInterventionsMessage:
		'Êtes-vous sûr de vouloir supprimer ces interventions ?',
	deleteInterventionTitle: `Suppression de l'intervention`,
	deleteInterventionMessage:
		'Vous êtes sur le point de supprimer cette intervention. Cette action est irréversible et toutes les données associées seront définitivement effacées.',

	deleteTransportsTitle: `Suppression des transports`,
	deleteTransportsMessage:
		'Êtes-vous sûr de vouloir supprimer ces transports ?',
	deleteTransportTitle: `Suppression du transport`,
	deleteTransportMessage: 'Êtes-vous sûr de vouloir supprimer ce transport ?',

	deleteVehicleSiteTitle: `Suppression des véhicules`,
	deleteVehicleSiteMessage: 'Êtes-vous sûr de vouloir supprimer ce véhicule?',

	vehiclesFusionTitle: 'Fusionner les informations du véhicule',
	featureNotAvailable: "Cette fonctionnalité n'est pas encore disponible",

	deleteAlertTitle: `Suppression de l'alerte`,
	deleteAlertMessage: 'Êtes-vous sûr de vouloir supprimer cette alerte ?',

	deleteInterventionFormTitle: `Suppression du formulaire`,
	deleteInterventionFormMessage:
		'Êtes-vous sûr de vouloir supprimer ce formulaire ?',

	deleteAgencyTitle: `Suppression d'une agence`,
	deleteAgencyMessage: 'Êtes-vous sûr de vouloir supprimer cette agence ?',
	deleteAgenciesTitle: `Suppression d' agences`,
	deleteAgenciesMessage: 'Êtes-vous sûr de vouloir supprimer ces agences ?',

	deleteInvoiceTitle: `Suppression de la dépense`,
	deleteInvoiceMessage: 'Êtes-vous sûr de vouloir supprimer cette dépense ?',
	deleteInvoicesTitle: `Suppression des dépenses`,
	deleteInvoicesMessage: 'Êtes-vous sûr de vouloir supprimer ces dépenses ?',

	deleteVehiclesTitle: `Suppression des véhicules`,
	deleteVehicleMessage: 'Êtes-vous sûr de vouloir supprimer ces véhicules?',

	deleteDocumentVehicleTitle: `Suppression du document`,
	deleteDocumentVehicleMessage:
		'Êtes-vous sûr de vouloir supprimer ce document ?',
	deleteIDocumentsVehiclesTitle: `Suppression des documents`,
	deleteDocumentsVehiclesMessage:
		'Êtes-vous sûr de vouloir supprimer ces documents ?',

	confirmQuitFOrmTemplateEditorTitle: 'Modifications non enregistrées',
	confirmQuitFOrmTemplateEditorMessage:
		'Des modifications apportées sur le modèle de formulaire n’ont pas été sauvegardées.',
	quitWithoutSave: 'Quitter sans sauvegarder',
	return: 'Retour',
}
