/* eslint-disable @typescript-eslint/no-unused-vars */
import { EFormModalMode } from '../../../../utils/app-models'
import { formatDate } from '../../../../utils/date'
import translateIntlMsgString from '../../../../utils/translateIntlMsgString'

export const acceptDocumentVehicleFile = {
	'image/jpeg': [],
	'image/jpg': [],
	'image/png': [],
	'application/pdf': [],
	'image/tiff': [],
}

export const formatDocumentVehicleModalEditDefaultValues = (
	data,
	categories
) => {
	const { reference, intervention, vehicle, file, category } = data

	const filteredCategory = categories?.data?.documentCategories?.filter(
		({ id }) => id === category?.id
	)

	return {
		reference,
		intervention: intervention?.id || '',
		vehicle: vehicle?.id || '',
		importFile: file,
		category:
			filteredCategory?.map((category) => ({
				key: category.id,
				label: translateIntlMsgString(`documentVehicle.${category.code}`),
				value: category.id,
			}))?.[0] || [],
	}
}

////////////////////////////////////////////////////////////////////////////////////////////////

export const formatDocumentVehicleModalOnValidateData = ({
	data,
	mode,
	documentVehicleId,
}) => {
	const { name, type } = data?.importFile?.[0] || {}
	const { intervention, reference, vehicle, category } = data

	return {
		reference,
		vehicle,
		category: category?.value,
		intervention: intervention !== '' ? intervention : null,
		...(type && { file: name.split('.').at(-1) }), // for have the extension file (ex: 'jpg')
		...(data?.importFile && !type && { file: '' }), // for delete file
		...(mode === EFormModalMode.edit && { id: documentVehicleId }),
	}
}

/////////////////////////////////////////////////////////////////////////////////////////////
// Format data for list select input
/////////////////////////////////////////////////////////////////////////////////////////////

export const formatFormatListOptionsForSelectINTERVENTIONS = (
	interventionsIPV,
	setSelectElementsIntervention
) => {
	const newArrayInterventionsIPV = [...interventionsIPV] // !important need a new table for .sort()
	const formatListInterventionsIPVForInput =
		newArrayInterventionsIPV
			?.sort(
				(a, b): any =>
					(new Date(b.dateFrom) as any) - (new Date(a.dateFrom) as any)
			)
			?.map(({ intervention: { id, reference, dateFrom } }) => ({
				key: id,
				label: `${reference}  ${dateFrom ? formatDate(dateFrom) : ''}`,
				value: id,
			})) || []

	setSelectElementsIntervention(formatListInterventionsIPVForInput || [])
}

export const formatFormatListOptionsForSelectVEHICLES = (
	vehiclesIPV,
	vehicles,
	documentBy,
	setSelectElementsVehicle
) => {
	if (documentBy === 'vehicle') {
		const formatListVehiclesForInput =
			vehicles?.map(
				({
					id,
					vehicleDetail: { immat, vin, vehicleBrand, vehicleModelText },
				}) => ({
					key: id,
					label: `${immat || vin}  ${vehicleBrand?.name ?? ''} ${
						vehicleModelText ?? ''
					}`,
					value: id,
				})
			) || []

		setSelectElementsVehicle(formatListVehiclesForInput || [])
	}
	// Else documentBy === 'intervention'
	else {
		const formatListVehiclesForInput =
			vehiclesIPV?.map(
				({
					vehicle: {
						id,
						vehicleDetail: { immat, vin, vehicleModelText, vehicleBrand },
					},
				}) => ({
					key: id,
					label: `${immat || vin}  ${vehicleBrand?.name ?? ''} ${
						vehicleModelText ?? ''
					}`,
					value: id,
				})
			) || []

		setSelectElementsVehicle(formatListVehiclesForInput || [])
	}
}

export const formatFormatListOptionsForSelectDefaultValueVEHICLE = (
	vehicle,
	setSelectElementsVehicle
) => {
	const { id, vehicleDetail } = vehicle || {}

	const formatListVehiclesForInput = [
		{
			key: id,
			label: `${vehicleDetail?.immat || vehicleDetail?.vin}  ${
				vehicleDetail?.vehicleBrand?.name ?? ''
			} ${vehicleDetail?.vehicleModelText ?? ''}`,
			value: id,
		},
	]

	setSelectElementsVehicle(formatListVehiclesForInput || [])
}
