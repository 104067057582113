import React, { useCallback, useContext } from 'react'
import toast from 'react-hot-toast'

import {
	formatCategoryProductBaseModalCreateDefaultValues,
	formatCategoryProductBaseModalEditDefaultValues,
	formatCategoryProductBaseModalOnValidateData,
} from './catalogueProductBaseSideNavModalUtils'
import {
	useCreateBaseCategory,
	useUpdateBaseCategory,
} from '../../../../../../api/gql/mutations/catalogProductsMutation'
import { IModalUtils, useFormatMessage } from '../../../../../../utils/hooks'
import { EFormModalMode } from '../../../../../../utils/app-models'
import FormModal from '../../../../../../components/forms/formModal'
import { useLazyCategoryBaseFormModal } from '../../../../../../api/gql/queries/catalogProductQueries'
import { ProductCatalogueContext } from '../../../ProductCatalogueProvider'
import catalogueProductBaseSideNavModalConfig from './catalogueProductBaseSideNavModalConfig'
import { MultiNavTableContext } from '../../../../../../components/multiNavTable/MultiNavTableProvider'

interface ICatalogueProductBaseSideNavModal {
	categoryId?: string | null
	modalUtils: IModalUtils
	mode?: EFormModalMode
	onClose?: any
}

const CatalogueProductBaseSideNavModal: React.FC<
	ICatalogueProductBaseSideNavModal
> = ({ modalUtils, mode, categoryId, onClose }) => {
	const intlMsg = useFormatMessage()
	const { idModule } = useContext(ProductCatalogueContext)
	const { setSideNavSelectedId } = useContext(MultiNavTableContext)
	const [updateProductBaseCategory] = useUpdateBaseCategory()
	const [createProductBaseCategory] = useCreateBaseCategory()
	const { productBaseCategory } = useLazyCategoryBaseFormModal()

	const onValidate = useCallback(
		async ({ data, setValidationLoading, closeModal }) => {
			setValidationLoading(true)
			const mutate =
				mode === EFormModalMode.edit
					? updateProductBaseCategory
					: createProductBaseCategory

			await mutate({
				variables: {
					input: formatCategoryProductBaseModalOnValidateData({
						data,
						mode,
						categoryId,
						idModule,
					}),
				},
				refetchQueries: ['getProductBaseCategories'],
			})
				.then((res) => {
					mode === EFormModalMode.edit
						? toast.success('success.categoryUpdate')
						: toast.success('success.categoryCreate')
					setValidationLoading(false)

					setSideNavSelectedId(
						res?.data?.[
							mode === EFormModalMode.create
								? 'createProductBaseCategory'
								: 'updateProductBaseCategory'
						]?.productBaseCategory?.id
					)
					closeModal()
				})
				.catch(() => setValidationLoading(false))
		},
		[categoryId, mode, idModule]
	)

	const initializeData = useCallback(
		async ({ setValues, setLoading }) => {
			if (mode === EFormModalMode.edit) {
				setLoading(true)
				productBaseCategory({ variables: { id: categoryId } })
					.then((res) => {
						const data = res?.data?.productBaseCategory
						if (data) {
							setValues(formatCategoryProductBaseModalEditDefaultValues(data))
						}
						setLoading(false)
					})
					.catch(() => setLoading(false))
			} else {
				setLoading(true)
				setValues(formatCategoryProductBaseModalCreateDefaultValues())
				setLoading(false)
			}
		},
		[mode, categoryId]
	)

	return (
		<FormModal
			modalUtils={modalUtils}
			formModalTabConfig={catalogueProductBaseSideNavModalConfig}
			formModalName={intlMsg(
				mode === EFormModalMode.edit
					? 'catalogProduct.editCategoryProductBase'
					: 'catalogProduct.addCategoryProductBase'
			)}
			initialMode={mode}
			onValidate={onValidate}
			validateBtnText={intlMsg('misc.validate')}
			cancelBtnText={intlMsg('misc.cancel')}
			maxWidth='595px'
			width='50%'
			minHeight='50px'
			initializeData={initializeData}
			onClose={onClose}
		/>
	)
}

export default CatalogueProductBaseSideNavModal
