import React, { useCallback, useContext, useState, useEffect } from 'react'

import { Stack, Box, Input, IconButton } from '@mui/material'
import Typography from '@mui/material/Typography/Typography'
import DeleteIcon from '@mui/icons-material/Delete'
import ColorizeIcon from '@mui/icons-material/Colorize'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import EditIcon from '@mui/icons-material/Edit'

import { EFormTemplateControlVerificationType } from '../../../../../../utils/app-models'
import { useFormatMessage } from '../../../../../../utils/hooks'
import {
	TitleField,
	RequireFieldTxt,
	DefaultValueField,
	VerificationTypeField,
	CanAlertField,
	SelectedChoiceToAlertField,
} from '../fieldEditForms/editFormsField'
import { FormTemplateContext } from '../../../../../../utils/contexts/FormTemplateContext'
import BtnAddCircle from '../../../../../../components/buttons/BtnAddCircle'
import ColorRadio from '../../../../../../components/forms/v1/formFields/ColorRadio'
import { stringifiedColor } from '../../../../../../utils/color'

interface IFieldEditFormLayoutControl {}

const choiceConteneurCSS = {
	display: 'flex',
	pl: 1,
	height: '40px',
	background: ({ palette }) => palette.grey['200'],
	marginBottom: '2px',
	borderRadius: 1,
}

const choiceIndexCSS = {
	background: ({ palette }) => palette.grey['500'],
	borderRadius: '50%',
	mr: 1,
	height: '25px',
	width: '25px',
	color: 'white',
	textAlign: 'center',
	paddingTop: '4px',
}

const choiceInputCSS = {
	border: 'none',
	background: 'none',
	'&:focus': {
		outline: 'none',
	},
}

const editIconCSS = {
	marginLeft: '5px',
	color: ({ palette }) => palette.grey['500'],
}

const colorPickerCSS = (color) => ({
	backgroundColor: stringifiedColor(color, 500) as any,
	color: 'white',
	alignItems: 'center',
	display: 'flex',
	justifyContent: 'center',
	width: '50%',
	cursor: 'pointer',
})

const btnDeleteCSS = {
	alignItems: 'center',
	display: 'flex',
	justifyContent: 'center',
	width: '50%',
	'&:hover': { backgroundColor: 'transparent' },
}

const paperCSS = {
	p: 4,
	maxWidth: '329px',
	zIndex: 1000,
	position: 'relative',
	backgroundColor: 'white',
}

const FieldEditFormLayoutControl: React.FC<
	IFieldEditFormLayoutControl
> = () => {
	const intlMsg = useFormatMessage()

	const {
		drawerUtils: { controlDrawer: control, setValueDrawer, watchDrawer },
	} = useContext(FormTemplateContext)
	const watchedCanAlert = watchDrawer('canAlert')
	const watchedControlList = watchDrawer('controlList')
	const watchedDefaultValue = watchDrawer('defaultValue')
	const watchedSelectedChoiceToAlert = watchDrawer('selectedChoiceToAlert')
	const [choiceInput, setChoiceInput] = useState('')

	const [changedIntoInput, setChangedIntoInput] = useState(-1)

	const handleAddChoice = useCallback(() => {
		const newControlList = [
			...watchedControlList,
			{
				choice: `choix ${watchedControlList?.length + 1 || 1}`,
				color: 'green',
			},
		]
		setValueDrawer('controlList', newControlList)
	}, [setValueDrawer, watchedControlList])

	const handleRemoveChoice = useCallback(
		(choiceIndex) => () => {
			const newControlList = [...watchedControlList]
			newControlList.splice(choiceIndex, 1)
			setValueDrawer('controlList', newControlList)

			if (
				choiceIndex === watchedDefaultValue ||
				watchedSelectedChoiceToAlert === choiceIndex
			) {
				choiceIndex === watchedDefaultValue &&
					setValueDrawer('defaultValue', '')
				watchedSelectedChoiceToAlert === choiceIndex &&
					setValueDrawer('canAlert', false)
			} else {
				setValueDrawer(
					'defaultValue',
					watchedDefaultValue > choiceIndex
						? watchedDefaultValue - 1
						: watchedDefaultValue
				)
				setValueDrawer(
					'selectedChoiceToAlert',
					watchedSelectedChoiceToAlert > choiceIndex
						? watchedSelectedChoiceToAlert - 1
						: watchedSelectedChoiceToAlert
				)
			}
		},
		[
			setValueDrawer,
			watchedControlList,
			watchedDefaultValue,
			watchedSelectedChoiceToAlert,
		]
	)

	const handleChangeIntoInput = useCallback(
		(i) => () => {
			setChoiceInput('')
			setChangedIntoInput(i)
		},
		[]
	)

	const handleChangeChoiceInput = useCallback(
		(choiceIndex, selector) => (e) => {
			if (e.target.value !== '') {
				const newControlList = watchedControlList.map((elem) => ({ ...elem }))

				newControlList[choiceIndex][selector] = e.target.value
				setValueDrawer('controlList', newControlList)
			}
			setChangedIntoInput(-1)
		},
		[watchedControlList]
	)

	const handleChangeInput = useCallback(
		(e) => setChoiceInput(e.target.value),
		[]
	)

	const handleOnKeyDown = useCallback(
		(choiceIndex) => (key) => {
			if (key.code === 'Enter') {
				const newControlList = watchedControlList.map((elem) => ({ ...elem }))

				newControlList[choiceIndex].choice = choiceInput
				setValueDrawer('controlList', newControlList)
				setChangedIntoInput(-1)
			}
		},
		[choiceInput]
	)

	// Color popover
	const [open, setOpen] = useState(false)
	const [watchedControlListIndex, setWatchedControlListIndex] = useState(0)
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

	const handleToggleColor = useCallback(
		(index) => (e) => {
			setWatchedControlListIndex(index)
			setAnchorEl(e.currentTarget)
			setOpen((prevOpen) => !prevOpen)
		},
		[]
	)
	const handleClose = useCallback(
		() => setOpen(false),
		[setAnchorEl, setOpen, setWatchedControlListIndex]
	)

	// reset value is !watchedCanAlert
	useEffect(() => {
		if (!watchedCanAlert) {
			setValueDrawer('verificationType', '')
			setValueDrawer('selectedChoiceToAlert', '')
		}
	}, [watchedCanAlert])

	return (
		<Stack spacing={2}>
			<RequireFieldTxt control={control} />
			<TitleField control={control} />

			<Stack pt={2}>
				<Stack direction='row'>
					<Typography variant='caption' sx={{ width: '70%' }}>
						{intlMsg('formTemplate.choice')}
					</Typography>

					<Typography variant='caption'>
						{intlMsg('formTemplate.color')}
					</Typography>
				</Stack>
				{watchedControlList.map(({ choice, color }, i) => (
					<Box key={`choicesList-${choice}-${i}`} sx={choiceConteneurCSS}>
						<Stack
							direction='row'
							alignItems='center'
							sx={{ cursor: 'pointer', width: '70%' }}
							onClick={handleChangeIntoInput(i)}
						>
							<Box sx={choiceIndexCSS}>{i}</Box>

							{changedIntoInput === i ? (
								<Input
									onChange={handleChangeInput}
									sx={choiceInputCSS}
									type='text'
									onBlur={handleChangeChoiceInput(i, 'choice')}
									autoFocus
									onKeyDown={handleOnKeyDown(i)}
								/>
							) : (
								<Stack direction='row' alignItems='center'>
									<Typography variant='body2'>{choice}</Typography>
									<EditIcon fontSize='small' sx={editIconCSS} />
								</Stack>
							)}
						</Stack>

						<Stack direction='row' sx={{ width: '30%' }}>
							<Box sx={colorPickerCSS(color)} onClick={handleToggleColor(i)}>
								<ColorizeIcon />
							</Box>

							<IconButton
								aria-label='delete'
								onClick={handleRemoveChoice(i)}
								size='small'
								sx={btnDeleteCSS}
								disabled={watchedControlList?.length === 2}
							>
								<DeleteIcon fontSize='small' />
							</IconButton>
						</Stack>
					</Box>
				))}

				{open && (
					<Popper
						open={open}
						anchorEl={anchorEl}
						role={undefined}
						transition
						style={{ zIndex: 10000 }}
					>
						{({ TransitionProps }) => (
							<Grow {...TransitionProps}>
								<Paper sx={paperCSS}>
									<ClickAwayListener onClickAway={handleClose}>
										<div>
											<ColorRadio
												name='color'
												control={control}
												action={handleChangeChoiceInput(
													watchedControlListIndex,
													'color'
												)}
												valuesDefault={
													watchedControlList[watchedControlListIndex].color
												}
												notEmptyColor
											/>
										</div>
									</ClickAwayListener>
								</Paper>
							</Grow>
						)}
					</Popper>
				)}
			</Stack>
			<Stack direction='row' justifyContent='center' pb={2}>
				<BtnAddCircle action={handleAddChoice} />
			</Stack>
			<DefaultValueField
				displayEmptyValueText={intlMsg('misc.empty')}
				control={control}
				selectElements={watchedControlList.map((e, i) => ({
					key: `defaultValue-${e?.choice}-${e?.color}-${i}`,
					label: e?.choice,
					value: i,
				}))}
			/>
			<CanAlertField control={control} />
			{watchedCanAlert && (
				<>
					<SelectedChoiceToAlertField
						control={control}
						selectElements={watchedControlList.map((e, i) => ({
							key: `choiceToAlert-${e?.choice}-${e?.color}-${i}`,
							label: e?.choice,
							value: i,
						}))}
					/>
					<VerificationTypeField
						control={control}
						selectElements={Object.values(
							EFormTemplateControlVerificationType
						).map((e, i) => ({
							key: `${e}-${i}`,
							label: intlMsg(`formTemplate.${e}`),
							value: e,
						}))}
					/>

					<Typography
						variant='body2'
						dangerouslySetInnerHTML={{
							__html: intlMsg('formTemplate.controlFieldResume', {
								value: watchedControlList?.[
									watchDrawer('selectedChoiceToAlert')
								]?.choice
									? `<strong>${
											watchedControlList?.[watchDrawer('selectedChoiceToAlert')]
												?.choice
									  }</strong>`
									: '......',
								type: watchDrawer('verificationType')
									? `<strong>${intlMsg(
											`formTemplate.${watchDrawer('verificationType')}`
									  )}</strong>`
									: '......',
							}),
						}}
					/>
				</>
			)}
		</Stack>
	)
}

export default FieldEditFormLayoutControl
