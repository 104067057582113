export default {
	dashboard: 'Dashboard',
	myProfile: 'My Profile',
	myDomain: 'My Company',
	myAccountTitle: 'MY ACCOUNT',
	usersTitle: 'Users',
	users: 'Users',
	groups: 'Groups',
	notifications: 'Notifications',
	myJob: 'My Job',
	myJobCapsLock: 'MY JOB',
	helpApp: 'Help and Support',
	apps: 'Apps',
	logout: 'Logout',
	add: 'Add',
	agency: 'Agency',
	customer: 'Account',
	contact: 'Contact',
	site: 'Site',
	vehicle: 'Vehicle',
	interventionControl: 'Control Intervention',
	interventionTransport: 'Transport Intervention',
	interventionLogistic: 'Logistic Intervention',
	formTemplateList: 'Form Editor',
	catalogProduct: 'Product Catalog',
	whiteBrand: 'White Label',
	invoice: 'Invoice',
	document: 'Document',
}
