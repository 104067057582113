export default {
	invoice: 'Dépenses',
	create: 'Ajouter une dépense',
	edit: 'Editer la dépense',
	reference: 'Référence / Intitulé',
	title: 'Intitulé',
	priceHT: 'Prix HT',
	priceTTC: 'Prix TTC',
	tva: 'TVA',
	dueDate: "Date d'échéance",
	addBy: 'Ajouté par',
	document: 'Document',
	fromVehicle: 'Depuis un véhicule',
	fromIntervention: 'Depuis une mission',
	searchIntervention: 'Tapez une référence, un compte ou site',
	searchVehicle: 'Tapez une immatriculation ou un VIN',
}
