import React, { useCallback } from 'react'

import { Controller } from 'react-hook-form'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'

interface ISwitchForm {
	name: string
	label?: string
	control: any
	color?: 'primary' | 'secondary' | 'default' | undefined
	specialOnChange?
}

const SwitchForm: React.FC<ISwitchForm> = ({
	name,
	label,
	control,
	color,
	specialOnChange,
}) => {
	const handleSwitchChange = useCallback(
		(field) => (e) => {
			if (specialOnChange) specialOnChange()
			field.onChange(e.target.checked)
		},
		[specialOnChange]
	)

	const isChecked = useCallback((field) => (field.value ? true : false), [])

	return (
		<>
			<Controller
				name={name}
				control={control}
				render={({ field }) => (
					<FormControlLabel
						control={
							<Switch
								onChange={handleSwitchChange(field)}
								checked={isChecked(field)}
								color={color || 'primary'}
							/>
						}
						label={label}
					/>
				)}
			/>
		</>
	)
}

export default SwitchForm
