import React, { useCallback, useContext, useState } from 'react'

import { Stack, Input, Box, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import Typography from '@mui/material/Typography/Typography'
import EditIcon from '@mui/icons-material/Edit'

import { TitleField } from '../fieldEditForms/editFormsField'
import { useFormatMessage } from '../../../../../../utils/hooks'
import Switch from '@mui/material/Switch/Switch'
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel'
import { EDocumentType } from '../../../../../../utils/app-models'
import BtnAddCircle from '../../../../../../components/buttons/BtnAddCircle'
import { FormTemplateContext } from '../../../../../../utils/contexts/FormTemplateContext'

interface IFieldEditFormLayoutDocument {}

const editIconCSS = {
	ml: 1,
	color: ({ palette }) => palette.grey['500'],
}

const nameInputCSS = {
	border: 'none',
	background: 'none',
	'&:focus': {
		outline: 'none',
	},
}

const containerPhotoCSS = {
	pb: 2,
	pt: 2,
	borderBottom: ({ palette }) => `2px solid ${palette.grey[200]}`,
}

const FieldEditFormLayoutDocument: React.FC<
	IFieldEditFormLayoutDocument
> = () => {
	const intlMsg = useFormatMessage()
	const [changedIntoInput, setChangedIntoInput] = useState(-1)
	const [documentName, setDocumentName] = useState('')

	const {
		drawerUtils: { controlDrawer: control, watchDrawer, setValueDrawer },
	} = useContext(FormTemplateContext)
	const watchedDocumentsList = watchDrawer('documentList')

	const handleChangeIntoInput = useCallback(
		(i) => () => {
			setDocumentName('')
			setChangedIntoInput(i)
		},
		[]
	)

	const handleChangeInput = useCallback(
		(e) => setDocumentName(e.target.value),
		[]
	)

	const handleChangeChoiceInput = useCallback(
		(documentIndex) => (e) => {
			if (e.target.value !== '') {
				const newDocumentsList = watchedDocumentsList.map((elem) => ({
					...elem,
				}))
				newDocumentsList[documentIndex].name = e.target.value
				setValueDrawer('documentList', newDocumentsList)
			}
			setChangedIntoInput(-1)
		},
		[watchedDocumentsList]
	)

	const handleOnKeyDown = useCallback(
		(documentIndex) => (key) => {
			if (key.code === 'Enter') {
				const newDocumentsList = watchedDocumentsList.map((elem) => ({
					...elem,
				}))
				newDocumentsList[documentIndex].name = documentName
				setValueDrawer('documentList', newDocumentsList)
				setChangedIntoInput(-1)
			}
		},
		[documentName]
	)

	const handleRemoveDocument = useCallback(
		(documentIndex) => () => {
			const newDocumentsList = watchedDocumentsList.map((elem) => ({ ...elem }))
			newDocumentsList.splice(documentIndex, 1)
			setValueDrawer('documentList', newDocumentsList)
		},
		[setValueDrawer, watchedDocumentsList]
	)

	const handleChangeSwitch = useCallback(
		(documentIndex) => () => {
			const newDocumentsList = watchedDocumentsList.map((elem) => ({ ...elem }))
			newDocumentsList[documentIndex].isRequired =
				!newDocumentsList[documentIndex].isRequired
			setValueDrawer('documentList', newDocumentsList)
		},
		[watchedDocumentsList]
	)

	const handleAddDocument = useCallback(() => {
		const newDocumentList = [
			...watchedDocumentsList,
			{
				name: `Document ${watchedDocumentsList?.length + 1 || 1}`,
				isRequired: false,
				type: EDocumentType.portrait,
			},
		]
		setValueDrawer('documentList', newDocumentList)
	}, [setValueDrawer, watchedDocumentsList])

	return (
		<Stack spacing={2}>
			<TitleField control={control} />

			<Stack>
				{watchedDocumentsList?.map(({ name, isRequired }, i) => (
					<Stack key={`documentList-${name}-${i}`} sx={containerPhotoCSS}>
						<Stack
							direction='row'
							justifyContent='space-between'
							alignItems='center'
						>
							<Box
								sx={{ cursor: 'pointer' }}
								onClick={handleChangeIntoInput(i)}
							>
								{changedIntoInput === i ? (
									<Input
										sx={nameInputCSS}
										type='text'
										onBlur={handleChangeChoiceInput(i)}
										autoFocus
										onChange={handleChangeInput}
										onKeyDown={handleOnKeyDown(i)}
									/>
								) : (
									<Stack direction='row' alignItems='center' mb={1}>
										<Typography variant='body2' sx={{ fontWeight: 'bold' }}>
											{name}
										</Typography>
										<EditIcon fontSize='small' sx={editIconCSS} />
									</Stack>
								)}
							</Box>

							<IconButton
								aria-label='delete'
								onClick={handleRemoveDocument(i)}
								size='large'
							>
								<DeleteIcon fontSize='small' />
							</IconButton>
						</Stack>

						<FormControlLabel
							control={
								<Switch
									checked={isRequired}
									color='primary'
									onChange={handleChangeSwitch(i)}
								/>
							}
							label={intlMsg('formTemplate.formRequire')}
						/>
					</Stack>
				))}
			</Stack>

			<Stack direction='row' justifyContent='center' pb={2}>
				<BtnAddCircle action={handleAddDocument} />
			</Stack>
		</Stack>
	)
}

export default FieldEditFormLayoutDocument
