import React from 'react'
import Alert from '@mui/material/Alert'
import { useFormatMessage } from '../../utils/hooks'
import { Box, Typography } from '@mui/material'

const nothingToDisplayCSS = {
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	flexWrap: 'nowrap',
	justifyContent: 'center',
	alignItems: 'center',
	alignContent: 'center',

	'& .nothingToDisplayImg': {
		marginBottom: '40px',
		width: '144.45px',
		height: '100px',
	},

	'& .nothingToDisplayBold': {
		fontFamily: 'Roboto',
		fontSize: '16px',
		fontWeight: '500',
		lineHeight: '24px',
		letterSpacing: '0.15000000596046448px',
		textAlign: 'left',
	},

	'& .nothingToDisplayparagraph': {
		fontFamily: 'Roboto',
		fontSize: '14px',
		fontWeight: '400',
		lineHeight: '20px',
		letterSpacing: '0.15000000596046448px',
		textAlign: 'left',
	},
}

interface INothingToDisplay {
	isFiltered?
}

const NothingToDisplay: React.FC<INothingToDisplay> = ({
	isFiltered = false,
}) => {
	const intlMsg = useFormatMessage()

	return isFiltered ? (
		<Box sx={nothingToDisplayCSS} data-test-id='Playwright-NothingToDisplay'>
			<img
				className='nothingToDisplayImg'
				src='/img/illustration/binoculars.svg'
			/>
			<Alert severity='info' icon={false}>
				<Typography className='nothingToDisplayBold'>
					{intlMsg(`misc.nothingToDisplayBold`)}
				</Typography>
				<Typography className='nothingToDisplayparagraph'>
					{intlMsg(`misc.nothingToDisplayparagraph`)}
				</Typography>
			</Alert>
		</Box>
	) : (
		<Alert severity='info' data-test-id='Playwright-NothingToDisplay'>
			<Typography>{intlMsg(`misc.noResultSearch`)}</Typography>
		</Alert>
	)
}

export default NothingToDisplay
