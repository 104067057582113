import React, { useMemo } from 'react'

import { Typography, Box } from '@mui/material'

import {
	EFormTemplateBasicFields,
	EFormTemplateTitleType,
} from '../../../../../utils/app-models'

const fontSize = {
	[EFormTemplateTitleType.littleTitle]: '16px!important',
	[EFormTemplateTitleType.mediumTitle]: '20px!important',
	[EFormTemplateTitleType.bigTitle]: '24px!important',
}

const mainCSS = (fieldType) => {
	const typeImg = [
		EFormTemplateBasicFields.picture,
		EFormTemplateBasicFields.document,
	].includes(fieldType)

	return {
		p: 2,
		display: 'flex',
		flexDirection: typeImg ? 'column' : 'row',
		alignItems: typeImg ? 'flex-start' : 'center',
		backgroundColor: 'white',
		margin: '1px 0px',
		minHeight: typeImg ? '241px' : 'inherit',
	}
}
interface IFieldInterventionForm {
	children
	title
	fieldType?
	attributes
}

const FieldInterventionForm: React.FC<IFieldInterventionForm> = ({
	children,
	title,
	fieldType,
	attributes,
}) => {
	const attributeTemplateParse = useMemo(
		() =>
			EFormTemplateBasicFields?.title === fieldType
				? JSON.parse(attributes)
				: null,
		[attributes]
	)

	const TypographyCSS = useMemo(() => {
		return {
			fontWeight: EFormTemplateBasicFields?.title === fieldType ? 700 : 500,
			minWidth: '220px',
			maxWidth: '220px',
			fontSize: fontSize?.[attributeTemplateParse?.titleType] ?? 'inherit',
		}
	}, [attributeTemplateParse])

	return (
		<Box sx={mainCSS(fieldType)}>
			<Typography variant='subtitle2' sx={TypographyCSS}>
				{title} {EFormTemplateBasicFields.title === fieldType ? '' : ' :'}
			</Typography>
			{children}
		</Box>
	)
}

export default FieldInterventionForm
