import React, { useEffect, useMemo, useState, useCallback } from 'react'

import { Alert, Snackbar, Button, LinearProgress } from '@mui/material'

import { useFormatMessage } from '../../../../../utils/hooks'
import { ESeverityAlert } from '../../../../../utils/app-models'
import { defaultContainerToastCss } from '../../../../alert/utilsToast'

const anchorOrigin: any = { vertical: 'bottom', horizontal: 'right' }
const AlertCSS = {
	...defaultContainerToastCss,
	width: 1,
	alignItems: 'center',
	'& .MuiAlert-action': { padding: '0px 0 0 16px' },
}

interface IExportSnackbar {
	isProcessingExport
	onClose
	severityAlert
	setSeverityAlert
	messageProcessingExport
	exportConfig
	resetOnClose
}

const ExportSnackbar: React.FC<IExportSnackbar> = ({
	isProcessingExport,
	onClose,
	severityAlert,
	setSeverityAlert,
	messageProcessingExport,
	exportConfig,
	resetOnClose,
}) => {
	const intlMsg = useFormatMessage()
	const [exportFileUrl, setExportFileUrl] = useState(undefined)

	const onDownload = useCallback(() => {
		const link = document.createElement('a') as any
		link.href = exportFileUrl
		link.click()
		setExportFileUrl(undefined)
		resetOnClose()
		document.body.removeChild(link)
	}, [exportFileUrl])

	const btnDownload = useMemo(
		() =>
			severityAlert === ESeverityAlert.success ? (
				<Button
					data-test-id='downloadExport'
					color='inherit'
					size='small'
					onClick={onDownload}
					variant='outlined'
				>
					{intlMsg('exportTable.btnDownload')}
				</Button>
			) : undefined,
		[severityAlert]
	)

	const isProgress = useMemo(
		() => severityAlert === ESeverityAlert.info && <LinearProgress />,
		[severityAlert]
	)

	// ///////////////////////////////////////////////////////////
	// Query export
	// ///////////////////////////////////////////////////////////

	const { exportFile, loading, error } = exportConfig.exportQuery({
		variables: exportConfig.variables,
		fetchPolicy: 'network-only',
	})

	useEffect(() => {
		if (exportFile) {
			setExportFileUrl(exportFile)
			setSeverityAlert(ESeverityAlert.success)
		} else if (!exportFile && error) {
			setSeverityAlert(ESeverityAlert.error)
		} else if (!exportFile && !loading) {
			setSeverityAlert(ESeverityAlert.warning)
		}
	}, [exportFile, loading, error])

	return (
		<Snackbar open={isProcessingExport} anchorOrigin={anchorOrigin}>
			<div>
				<Alert
					onClose={onClose}
					severity={severityAlert}
					sx={AlertCSS}
					action={btnDownload}
					color={severityAlert}
					variant='outlined'
				>
					{messageProcessingExport[severityAlert]}
					{isProgress}
				</Alert>
			</div>
		</Snackbar>
	)
}

export default ExportSnackbar
